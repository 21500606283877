import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { c_TipoDeComprobante } from 'src/app/ventas/interfaces/documentoventa.interface';
import { EfiscoService } from '../../services/efisco.service';
import { Result } from '../../../auth/interfaces/result.interface';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { EnvioEfisco } from '../../interfaces/efisco.interface';

@Component({
  selector: 'app-enviar-meta-data-page',
  templateUrl: './enviar-meta-data-page.component.html',
  styles: [
  ]
})
export class EnviarMetaDataPageComponent implements OnInit {

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<HTMLElement>;

  history: EnvioEfisco[] = [];
  sending: boolean = false;

  public myForm: FormGroup = this.fb.group({
    Empresa: [{} as Empresa],
    TipoComprobante: [{} as c_TipoDeComprobante],
    FechaInicio: [Date],
    FechaFin: [Date],
    // SerieFactura: ['']
  })

  constructor(private fb: FormBuilder, private efiscoService: EfiscoService) {
  }

  get getIsValid(): string {
    const ent = this.myForm.value;
    if (!ent.Empresa || ent.Empresa?.Id == 0) { return "disabled"; }
    if (!ent.TipoComprobante || ent.TipoComprobante?.Id == 0) { return "disabled"; }
    if (!ent.FechaInicio) { return "disabled"; }
    if (!ent.FechaFin) { return "disabled"; }

    return "";
  }


  ngOnInit(): void {
    this.myForm.reset({
      Empresa: null,
      TipoComprobante: null,
      FechaInicio: new Date(),
      FechaFin: new Date(),
    });
    this.getHistory();
  }

  getHistory(){
    this.efiscoService.getHistory().subscribe((list) => {
      this.sending = false;
      this.history = list;
    })
  }

  getDate(date: Date) {
    return moment(date).format("DD/MM/YYYY");
  }

  getComp(tipo:string){
    switch(tipo){
      case 'I': return "INGRESO";
      case 'E': return "EGRESO";
      case 'T': return "TRASLADO";
      case 'P': return "PAGO";
    }
    return "";
  }

  verify(){
    this.sending = true;
    this.efiscoService.verify().subscribe((result)=>{
      this.getHistory();
    });
  }

  sendData() {
    if (this.getIsValid == "") {
      this.sending = true;
      const ent = this.myForm.value;
      this.efiscoService.sendCfdiToEfisco(ent.Empresa.Id, ent.TipoComprobante.Id, moment(ent.FechaInicio).format("DD/MM/YYYY"), moment(ent.FechaFin).format("DD/MM/YYYY")).subscribe((Result) => {
        this.sending = false;
        if (Result.success) {
          Swal.fire({ text: "Se ha enviado a efisco, dé seguimiento en el histórico de envíos de la parte de abajo.", icon: 'success', });
          this.getHistory();
        } else {
          Swal.fire({ text: Result.message, icon: 'error', })
        }
      });
    }
  }

  selectedCombo(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

}
