import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { ContabilidadService } from '../../services/contabilidad.service';
import { MovimientoPolizaCabecera } from '../../interfaces/movimientopoliza.interface';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { FormaCompra } from '../../../compras/interfaces/clasecompra.interface';
import * as moment from 'moment';

@Component({
  selector: 'busqueda-polizas-page',
  templateUrl: './busqueda-polizas-page.component.html',
  styleUrls: ['./busqueda-polizas-page.component.scss']
})
export class BusquedaPolizasPageComponent implements OnInit {
  selectedMonth: number = 0;
  searching: boolean = false;
  @ViewChild('txtBuscar') public txtBuscar!: ElementRef<any>;

  @Input()
  public anio: number = 0;
  @Input()
  public mes: number = 0;
  @Input()
  public idEmpresa: number = 0;
  @Input()
  public idTipoPoliza: number = 0;
  @Input()
  public esPlantilla: boolean = false;

  monthName: string = "";

  listaPolizas: MovimientoPolizaCabecera[] = [];
  public GuiColumnAlign = GuiColumnAlign;

  constructor(private contaService: ContabilidadService,
    private mService: ModalService) {
  }

  ngOnInit(): void {

    this.selectedMonth = this.mes;
    this.filter("");
  }

  get getMontName(): string {
    switch (this.selectedMonth) {
      case 1: return "ENE";
      case 2: return "FEB";
      case 3: return "MZO";
      case 4: return "ABR";
      case 5: return "MAY";
      case 6: return "JUN";
      case 7: return "JUL";
      case 8: return "AGO";
      case 9: return "SEP";
      case 10: return "OCT";
      case 11: return "NOV";
      case 12: return "DIC";
    }
    return "";
  }

  changueMont(month: number) {
    this.selectedMonth = month;
    this.mes = month;
    this.enterSearch();

  }
  enterSearch() {
    let text = this.txtBuscar?.nativeElement?.value;
    if (!text) text = "";
    this.filter(text)
  }

  getIsMonthSelected(index: number): string {
    return this.selectedMonth == index ? 'nav-link active' : 'nav-link ';
  }

  seleccionar(item: MovimientoPolizaCabecera) {
    this.mService.closeModal(item);
  }

  filter(filter: string) {
    this.searching = true;
    if (!this.esPlantilla) {
      this.contaService.getPolicyByYearMonth(this.idEmpresa, this.anio, this.mes, this.idTipoPoliza, filter).subscribe((lista) => {
        this.searching = false;
        this.listaPolizas = lista;
      })
    } else {
      this.contaService.getTemplatePolicy(this.idEmpresa, this.idTipoPoliza, filter).subscribe((lista) => {
        this.searching = false;
        this.listaPolizas = lista;
      })
    }
  }

  getDate(fecha: any) {
    return moment(moment(fecha).toDate()).format("DD/MM/YYYY")
  }

  closeModal() {
    this.mService.closeModal(null);
  }



}
