<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Unidad"
  title="Vehículos" icon="fa fa-building" subTitle="Administra los Vehículos, MontaCargas, etc.">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Unidad'"
            entityName="Unidad" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />


          <component-ui-combo-box [label]="'Sucursal'" [required]="true" formControlName="SucursalPertenece"
            [tabIndex]="3" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedItem($event, 'SucursalPertenece')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Departamento'" formControlName="Departamento" [tabIndex]="4" [zeroMask]="2"
            entityName="Departamento" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedItem($event, 'Departamento')" [listProperty]="'ClaveNombre'" />

          <component-ui-check-box [tabIndex]="5" [widthLabelColumns]="2" formControlName="UtilizarParaEntregas"
            [widthTextColumns]="7" [label]="'Se utiliza para entregas'" />

          <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="2" formControlName="FueraDeServicio"
            [widthTextColumns]="7" [label]="'Fuera de servicio'" />

        </div>
      </app-panel-base>


      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Datos
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Capacidades</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-3" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Seguros</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-4" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Choferes</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-5" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Carta Porte</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-6" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Combustible</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-7" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Servicios Realizados</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-8" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Servicios Programados</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-9" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Neumáticos</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-10" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
      </ul>
      <div class="tab-content panel rounded-0 p-3 me-2 ms-2 mt-0">
        <div id="default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Datos de la unidad">
            <div class="panel-content">
              <components-ui-number-box [tabIndex]="9" [maxLength]="4" [label]="'Año'" formControlName="Anio"
                [widthTextColumns]="1" [widthLabelColumns]="2" />

              <components-ui-text-box [label]="'Marca'" formControlName="Marca" [tabIndex]="10" [widthTextColumns]="3"
                [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-text-box [label]="'Color'" formControlName="Color" [tabIndex]="11" [widthTextColumns]="3"
                [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-text-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Placas'"
                formControlName="Placas" [tabIndex]="12" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="50" />

              <components-ui-text-box [label]="'No serie bateria'" formControlName="NumSerieBateria" [tabIndex]="13"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-text-box [label]="'No de motor'" formControlName="Motor" [tabIndex]="14"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-text-box [label]="'Trasmisión'" formControlName="Trasmision" [tabIndex]="15"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-text-box [label]="'Tipo de llantas'" formControlName="TipoLlantas" [tabIndex]="16"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-number-box helpLine="Peso bruto del vehículo en toneladas."
                [required]="myForm.value.UtilizarParaEntregas" [label]="'Peso Bruto (TONS)'"
                formControlName="PesoBrutoVehicular" [tabIndex]="17" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="12" />

            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-2" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Capacidades">
            <div class="panel-content">
              <components-ui-number-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Carga en Kilos'"
                formControlName="CapacidadCarga" [tabIndex]="18" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="12" />

              <components-ui-number-box [label]="'Combustible en Litros'" formControlName="CapacidadCombustible"
                [tabIndex]="19" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="12" />

              <components-ui-number-box [label]="'Aceite en Litros'" formControlName="CapacidadAceite" [tabIndex]="20"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="12" />
            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-3" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Seguros">
            <div class="panel-content">
              <components-ui-text-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Aseguradora'"
                formControlName="Aseguradora" [tabIndex]="21" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="50" />
              <components-ui-text-box [required]="myForm.value.UtilizarParaEntregas" [label]="'No de póliza'"
                formControlName="NumPoliza" [tabIndex]="22" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="50" />
              <components-ui-date-box formControlName="FechaVencimientoPoliza" label="Fecha vence póliza"
                [tabIndex]="23" [widthLabelColumns]="2" />
            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-4" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Choferes">
            <div class="panel-content">

              <component-ui-combo-box [filterQuery]="getFiltroChofer()" [label]="'Chofer'" formControlName="Chofer"
                [tabIndex]="24" entityName="Chofer" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedItem($event, 'Chofer')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [filterQuery]="getFiltroAyudante()" [label]="'Ayudante'"
                formControlName="Ayudante" [tabIndex]="25" entityName="Chofer" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedItem($event, 'Ayudante')"
                [listProperty]="'ClaveNombre'" />


            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-5" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Carta Porte">
            <div class="panel-content">
              <component-ui-combo-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Tipo permiso SCT'"
                [widthList]="900" formControlName="TipoPermisoSCT" [tabIndex]="26" [entityName]="'cp_TipoPermiso'"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedItem($event,'TipoPermisoSCT')" [listProperty]="'ClaveNombre'" />

              <components-ui-text-box [required]="myForm.value.UtilizarParaEntregas" [label]="'No de permiso'"
                formControlName="NumPersmisoSCT" [tabIndex]="27" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="100" />

              <component-ui-combo-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Configuración'"
                [widthList]="900" formControlName="ConfigAutotransporte" [tabIndex]="28"
                [entityName]="'cp_ConfigAutotransporte'" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedItem($event,'ConfigAutotransporte')"
                [listProperty]="'ClaveNombre'" />

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-truck-ramp-box fa-lg fa-fw me-1"></i>
                Remolque
              </div>

              <component-ui-combo-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Tipo Remolque'"
                [widthList]="900" formControlName="TipoRemolque" [tabIndex]="29" [entityName]="'c_SubTipoRem'"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedItem($event,'TipoRemolque')" [listProperty]="'ClaveNombre'" />

              <components-ui-text-box [required]="myForm.value.UtilizarParaEntregas" [label]="'Placas Remolque'"
                formControlName="PlacasRemolque" [tabIndex]="30" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxLength]="50" />

            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-6" class="tab-pane fade" role="tabpanel"></div>
        <div id="default-tab-7" class="tab-pane fade" role="tabpanel"></div>
        <div id="default-tab-8" class="tab-pane fade" role="tabpanel"></div>
        <div id="default-tab-9" class="tab-pane fade" role="tabpanel"></div>
        <div id="default-tab-10" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">

              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />

            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>
</app-container-base>
