<app-container-base [setIsNew]="getIsNew" (onItemsComboSearched)="onItemSearched($event)" [hasBar]="!editando"
  (onItemSearched)="onItemSearched($event)" [searchItemsCombo]="false" [entity]="myForm"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="Producto" title="Productos"
  icon="fa fa-building" subTitle="Administra la configuración de productos..." [printManual]="true"
  (onClickBarButton)="clickButtonBar($event)">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:850px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;" class="pt-0 pb-0">Familia</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;" class="pt-0 pb-0">Unidad</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Familia!.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base title="Información">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-6">
              <component-ui-combo-box label='Empresa' [enabled]="false" formControlName="Empresa" [tabIndex]="1"
                entityName="Empresa" [zeroMask]="2" [widthTextColumns]="2" [widthLabelColumns]="4"
                (onSelectedItem)="selectedCombo($event,'Empresa')" [required]="true"
                [widthRightColumns]="6"></component-ui-combo-box>
              <components-ui-text-box [enabled]="!editando" label='Clave' formControlName="Clave"
                [enabled]="getEnableText" [maxLength]="this.buscandoProducto?20:8" [required]="true"
                (keydown.enter)="enterField($event)" (blur)="blur($event)" entidadBusqueda="Producto"
                (elementoSeleccionado)="itemSeleccionado($event)" [tabIndex]="2" [widthTextColumns]="2"
                [widthLabelColumns]="4" #txtClave (focus)="onFocus()" />
              <components-ui-text-box label='Nombre' formControlName="Nombre" [tabIndex]="3" [widthTextColumns]="8"
                [widthLabelColumns]="4" [required]="true" #txtNombre [maxLength]="70" />
              <components-ui-text-box label='Segundo Nombre' formControlName="SegundoNombre" [tabIndex]="4"
                [widthTextColumns]="8" [widthLabelColumns]="4" [maxLength]="70" />
              <components-ui-text-box label='Código de barras' formControlName="CodigoBarras" [widthTextColumns]="8"
                [widthLabelColumns]="4" [maxLength]="70" [tabIndex]="5" />
              <components-ui-text-box label='Cuenta predial' formControlName="CuentaPredial" [tabIndex]="6"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box label='Url ficha técnica' formControlName="FichaTecnica" [tabIndex]="7"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box label='Detalle del producto' formControlName="DetalleDelProducto" [tabIndex]="8"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
            </div>
            <div class="col-md-1">
            </div>
            <div class="col-md-5">
              <div class="row">
                <div class="card-body">
                  <div id="dropzone">
                    <div *ngIf="!this.myForm.value.ImagenBase64 else imagenProductodiv">
                      <form action="/upload" class="dropzone needsclick" id="demo-upload" (click)="searchFile()"
                        style="cursor: pointer;">
                        <div class="dz-message needsclick">
                          <b>click</b> para buscar en PC.<br />
                          <span class="dz-note needsclick">
                            presione el botón <strong>Selecione</strong>
                          </span>
                        </div>
                      </form>
                    </div>
                    <ng-template #imagenProductodiv>
                      <div class="card-body">
                        <img [src]="this.myForm.value.ImagenBase64" [attr.height]="'200px'" style="max-width:400px;"
                          (click)="searchFile()" style="cursor: pointer;">
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row">
              </div>
            </div>

          </div>
        </div>
      </app-panel-base>

      <!-- <app-panel-base title="Generales">
        <div class="panel-content"> -->

      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Generales
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Punto de Venta</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-3" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Otras Unidades de Medida</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-4" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Productos Similares/Relacionados</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-5" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Componentes</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-6" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Paquetes/Lotes</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-7" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
      </ul>

      <div class="tab-content panel rounded-0 p-3 me-2 ms-2 mt-0">
        <div id="prod-default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Generales">
            <div class="panel-content">
              <div class="row">
                <div class="col-md-12" style="padding-left:0px;">
                  <div class="card bg-success border-0 text-white">
                    <div class="card-body">
                      <h4 class="card-title">Familia de producto</h4>
                      <p class="card-text">{{getFamilyName}}</p>
                      <div (click)="abrirSelecionarFamilia()" class="btn btn-sm btn-primary">Seleccionar familia</div>
                    </div>
                  </div>
                </div>
              </div>

              <component-ui-combo-box label='Impuesto' formControlName="Impuesto" [required]="true" [tabIndex]="8"
                [zeroMask]="2" entityName="Impuesto" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Impuesto')" />

              <components-ui-text-box (onClicIcon)="abrirBusquedaClaves()" (keydown.f2)="abrirBusquedaClaves()"
                [enableIcon]="true" [tabIndex]="9" [enabled]="false" [maxLength]="20" [label]="'Clave Prod Serv'"
                formControlName="ClaveSAT" [widthTextColumns]="2" [widthLabelColumns]="2" />


              <components-ui-text-box label='Clave SAT Nombre' [enabled]="false" formControlName="ClaveSATNombre"
                [tabIndex]="10" [widthTextColumns]="2" [widthLabelColumns]="2" />
              <component-ui-combo-box label='Clasificación' formControlName="Clasificacion" [tabIndex]="11"
                [zeroMask]="2" entityName="ClasificacionProducto" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Clasificacion')"
                [listProperty]="'ClaveNombre'" #clasificacion />

              <component-ui-combo-box label='Almacén' formControlName="Almacen" [tabIndex]="12" [zeroMask]="2"
                entityName="Almacen" [widthTextColumns]="2" [required]="true" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Almacen')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box label='Tipo' formControlName="Tipo" [tabIndex]="13" [zeroMask]="2"
                entityName="TipoProducto" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'Tipo')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box label='Unidad de Venta' [required]="true" formControlName="Unidad" [tabIndex]="14"
                [zeroMask]="2" entityName="c_Unidad" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Unidad')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box label='Unidad de Compra' [required]="myForm.value.Inventariable"
                formControlName="UnidadCompra" [tabIndex]="15" [zeroMask]="2" entityName="c_Unidad"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'UnidadCompra')" [listProperty]="'ClaveNombre'" />

              <components-ui-number-box label='Unidad Venta - Compra' formControlName="ConversionCompraVenta"
                [tabIndex]="16" helpLine="Número de unidades de VENTA que representa una unidad de COMPRA."
                [widthTextColumns]="2" [widthLabelColumns]="2" [maxLength]="12.5"></components-ui-number-box>

              <!-- <component-ui-combo-box label='Producto Equivalente' formControlName="ProductoEquivalente" [tabIndex]="17"
                [zeroMask]="2" entityName="Producto" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ProductoEquivalente')"
                [listProperty]="'ClaveNombre'" /> -->

              <div class="row">
                <div class="col-md-4">
                  <components-ui-text-box (onClicIcon)="abrirBusquedaProductoEquivalente()"
                    (keydown.f2)="abrirBusquedaProductoEquivalente()" [enableIcon]="true" [tabIndex]="9"
                    [enabled]="false" [maxLength]="20" label="Producto Equivalente"
                    formControlName="ProductoEquivalenteClave" [widthTextColumns]="6" [widthLabelColumns]="6" />
                </div>
                <div class="col-md-4 text-start">
                  <label>
                    {{this.myForm.value.ProductoEquivalenteNombre}}
                  </label>
                </div>
              </div>

              <!-- <div class="col-sm-12">

                </div> -->





              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-screwdriver-wrench fa-lg fa-fw me-1"></i>
                Opciones generales
              </div>
              <div class="row">
                <div class="col-md-4">
                  <component-ui-check-box label='Actualizar descripción' formControlName="ActualizarDescrpcion"
                    [tabIndex]="18" [widthLabelColumns]="6" [widthTextColumns]="6" />
                </div>
                <div class="col-md-4">
                  <component-ui-check-box label='Permite negativos' formControlName="PermitirNegativos"
                    [widthLabelColumns]="7" [widthTextColumns]="2" [tabIndex]="19" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <component-ui-check-box [enabled]="myForm.value.ActualizarDescrpcion"
                    helpLine="Seleccione si solo se podrá actualizar la descripción bajo demanda del usuario que captura"
                    [tabIndex]="20" [widthLabelColumns]="6" formControlName="ActualizarDescrpcionBajoDemanda"
                    [widthTextColumns]="6" [label]="'Bajo demanda'" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <component-ui-check-box label='Inventariable' formControlName="Inventariable" [widthLabelColumns]="6"
                    [widthTextColumns]="6" [tabIndex]="21" />
                </div>
                <div class="col-md-4">
                  <component-ui-check-box label='Solicita Serie' [widthLabelColumns]="7"
                    formControlName="SolicitaSeries" [widthTextColumns]="2" [tabIndex]="22" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">
                  <component-ui-check-box label='Es agape' [widthLabelColumns]="7" formControlName="EsAgape"
                    [widthTextColumns]="2" [tabIndex]="23" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <component-ui-check-box label='Es para uso de la empresa' [widthLabelColumns]="6"
                    formControlName="EsParaUsoEmpresa" [widthTextColumns]="6" [tabIndex]="24" />
                  <component-ui-check-box label='Baja' [widthLabelColumns]="6" formControlName="Baja"
                    [widthTextColumns]="6" [tabIndex]="25" />
                </div>
                <div class="col-md-4">
                  <!-- <component-ui-check-box label='Es agape' [widthLabelColumns]="7" formControlName="EsAgape"
                    [widthTextColumns]="2" [tabIndex]="21" /> -->
                  <component-ui-check-box label='Es anticipo' [widthLabelColumns]="7" formControlName="EsParaAnticipo"
                    [widthTextColumns]="2" [tabIndex]="26" />
                  <component-ui-check-box label='Para factura global' [widthLabelColumns]="7"
                    formControlName="ParaFacturaGlobal" [widthTextColumns]="2" [tabIndex]="27" />
                  <component-ui-check-box [enabled]="!myForm.value.Inventariable" label='Se prorratea en compra'
                    [widthLabelColumns]="7" formControlName="SeProrrateaEnCompras" [widthTextColumns]="2"
                    [tabIndex]="28" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                </div>
              </div>
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-user-tie fa-lg fa-fw me-1"></i>
                Proveedores que tiene el Producto
              </div>
              <component-ui-combo-box helpLine="Primer Proveedor que tiene asignado este producto." label='Proveedor 1'
                formControlName="ProveedorCompra" [tabIndex]="28" [zeroMask]="4" entityName="Proveedor"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'ProveedorCompra')" [listProperty]="'ClaveNombre'" />
              <component-ui-combo-box helpLine="Segundo Proveedor que tiene asignado este producto." label='Proveedor 2'
                formControlName="ProveedorCompra2" [tabIndex]="29" [zeroMask]="4" entityName="Proveedor"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'ProveedorCompra2')" [listProperty]="'ClaveNombre'" />
            </div>
          </app-panel-base>


        </div>
        <div id="prod-default-tab-2" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="POS (Punto de Venta)">
            <div class="panel-content">
              <components-ui-number-box label='Cantidad predefinida' formControlName="CantidadPOS" [tabIndex]="28"
                [widthTextColumns]="2" [widthLabelColumns]="3" [maxLength]="12.5"></components-ui-number-box>

              <component-ui-check-box label='No decimales en cantidad' [widthLabelColumns]="3"
                formControlName="NoDecimalesCantidad" [widthTextColumns]="2" [tabIndex]="25" />

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-cube fa-lg fa-fw me-1"></i>
                Formulación para Pie/Metros Cúbicos
              </div>

              <component-ui-check-box label='Usar calcúlo' [widthLabelColumns]="3" formControlName="ActivaFormulacion"
                [widthTextColumns]="2" [tabIndex]="26" />

              <component-ui-check-box [enabled]="myForm.value.ActivaFormulacion" label='Para Pie Cúbico'
                [widthLabelColumns]="3" formControlName="ActivaFormulacionPieCubico" [widthTextColumns]="2"
                [tabIndex]="27" />

              <component-ui-check-box [enabled]="myForm.value.ActivaFormulacion" label='Para Metro Cuadrado'
                [widthLabelColumns]="3" formControlName="ActivaFormulacionMetroCuadrado" [widthTextColumns]="2"
                [tabIndex]="28" />

              <components-ui-number-box [enabled]="myForm.value.ActivaFormulacion" label='Divisor'
                formControlName="DivisorFormulacion" [tabIndex]="29" [widthTextColumns]="2" [widthLabelColumns]="3"
                [maxLength]="12"></components-ui-number-box>

              <components-ui-number-box label='% Utilidad' formControlName="PorcentajeUtilidad" [tabIndex]="30"
                [widthTextColumns]="2" [widthLabelColumns]="3" [maxLength]="10"></components-ui-number-box>


            </div>
          </app-panel-base>
        </div>
        <div id="prod-default-tab-3" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Otras unidades">
            <div class="panel-content">

              <!-- <components-ui-text-box label='Código de barras' formControlName="CodigoBarras" [widthTextColumns]="8"
            [widthLabelColumns]="4" [maxLength]="70" [tabIndex]="4" Entidad='Producto'/> -->

              <div class="d-flex">
                <div style="width: 45%;">
                  <component-ui-check-box label='Aplica Ventas/Taspasos' [widthLabelColumns]="4"
                    (onClick)="onClickAplicaVentas($event)" formControlName="AplicaVentas" [widthTextColumns]="1"
                    [tabIndex]="29" />
                </div>
              </div>
              <div class="d-flex">
                <div style="width: 45%;" class="text-end">
                  <component-ui-check-box label='Aplica compras' [widthLabelColumns]="4" formControlName="AplicaCompras"
                    [widthTextColumns]="1" [tabIndex]="30" (onClick)="onClickAplicaCompras($event)" />
                </div>
                <div style="width: 10%;">
                  <div class="btn btn-sm btn-primary w-80px" (click)="agregaUnidad()">Agregar</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <br>
                  <div class="form-group row">
                    <div class="col-md-2 me-0 ms-0"></div>
                    <div class="col-md-1 me-0 ms-0">

                    </div>
                  </div>
                  <br>

                  <div formArrayName="OtrasUnidades">
                    <div class="form-group row">
                      <div *ngIf="OtrasUnidades">
                        <ng-template [ngIf]="OtrasUnidades.controls.length > 0">

                          <div class="mb-1" *ngFor="let otras of OtrasUnidades.controls;let i = index"
                            [formGroupName]="i">

                            <div class="d-flex">

                              <div style="width:30%;" class="text-end">
                                <component-ui-check-box label='Es para kilos' formControlName="EsParaKilos"
                                  [widthLabelColumns]="6" [widthTextColumns]="2" />
                              </div>
                              <div style="width:10%;">
                                <components-ui-number-box label='' formControlName="Cantidad" [widthTextColumns]="8"
                                  [required]="true" [maxLength]="12.5"></components-ui-number-box>
                              </div>
                              <div style="width:20%;">
                                <component-ui-combo-box label='' formControlName="Unidad"
                                  (onSelectedItem)="selectedComboOtras($event,otras.value)" [zeroMask]="2"
                                  entityName="c_Unidad" [widthTextColumns]="4" [widthLabelColumns]="1"
                                  [widthRightColumns]="4" [listProperty]="'ClaveNombre'" />
                              </div>
                              <div style="width:10%;">
                                <a class="text-decoration-none cursor-pointer text-red"
                                  (click)="eliminarProductoUnidad(i)">
                                  <i class="fa fa-trash-can"></i>
                                </a>
                              </div>
                            </div>


                            <!-- <div class="row">
                              <div class="col-md-1 mt-0">
                              </div>
                              <div class="col-md-2 mt-0">
                                <component-ui-check-box label='Es para kilos' formControlName="EsParaKilos"
                                  [widthLabelColumns]="6" [widthTextColumns]="2" />
                              </div>
                              <div class="col-md-2 ms-0 me-0">
                                <components-ui-number-box label='' formControlName="Cantidad" [widthTextColumns]="8"
                                  [required]="true" [maxLength]="12.5"></components-ui-number-box>
                              </div>
                              <div class="col-md-4 ms-0 me-0">
                                <component-ui-combo-box label='' formControlName="Unidad"
                                  (onSelectedItem)="selectedComboOtras($event,otras.value)" [zeroMask]="2"
                                  entityName="c_Unidad" [widthTextColumns]="2" [widthLabelColumns]="1"
                                  [widthRightColumns]="4" [listProperty]="'ClaveNombre'" />
                              </div>
                              <div class="col-md-1 mt-1">
                                <a class="text-decoration-none cursor-pointer text-red"
                                  (click)="eliminarProductoUnidad(i)">
                                  <i class="fa fa-trash-can"></i>
                                </a>
                              </div>
                            </div> -->

                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
        <div id="prod-default-tab-4" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Relacionados (agregue los productos relacionados a este producto)"
            id="divRelacionados">
            <div class="panel-content">
              <ng-scrollbar style="height: 100px;border-bottom: 1px solid #AAAAAA;width:650px;">
                <table class="blueTable" style="width:640px">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 15px;"></th>
                      <th style="width: 30px;">Producto</th>
                      <th style="width: 150px;">Descripción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                      *ngFor="let item of sourceRelacionados; let index = index;">
                      <td style="text-align: center;">
                        <i (click)="deleteRowRelacionado(index)" *ngIf="item.Producto"
                          class="fa fa-rectangle-xmark fa-1x" style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td>
                      <td style="width: 65px;min-width:65px;max-width: 65px;">
                        <input [disabled]="loading" (keyup)="buscarCatalogo($event)"
                          (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,1)"
                          (keydown)="navegarProductos($event)" (keydown.arrowdown)="down($event,1)"
                          (keydown.enter)="enterProducto($event)" class="grid-editor text-dark"
                          [id]="'txt_'+index+'_1' + idUnico" (focus)="focusProducto(index, $event, item)"
                          (blur)="blurProducto($event)" type="text" [value]="item.Producto ? item.Producto.Clave: ''" />
                      </td>
                      <td>
                        <span style="margin-left: 3px;">{{item.Descripcion}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
              <!-- <gui-grid [autoResizeWidth]="false" #gridRelacionado [width]="800" [source]="sourceRelacionados"
                [maxHeight]="80" [rowHeight]="20" [virtualScroll]="true" [rowSelection]="false" [theme]="'fabric'"
                (sourceEdited)="sourceRelacionadoEdited($event)" (cellEditEntered)="cellRelacionadoEditEntered($event)"
                [cellEditing]="cellEditing">
                <gui-grid-column index-column="0" [cellEditing]="false" header="" [width]="30"
                  [align]="GuiColumnAlign.CENTER">
                  <ng-template let-item="item" let-index="index">
                    <i (click)="deleteRowRelacionado(index)" *ngIf="item.Producto?.Id>0" class="fa fa-rectangle-xmark"
                      style="color:red;font-weight: bold; cursor: pointer;"></i>
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column index-column="1" header="Producto" [width]="110" [field]="'NoIdentificacion'"
                  [type]="'string'">
                  <ng-template let-item="item" let-index="index">
                    {{item.NoIdentificacion}}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Descripción" [width]="205" [field]="'Descripcion'" [cellEditing]="false">
                  <ng-template let-item="item" let-index="index">
                    {{item.Descripcion}}
                  </ng-template>
                </gui-grid-column>
              </gui-grid> -->
            </div>
          </app-panel-base>

        </div>
        <div id="prod-default-tab-5" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Componentes (agregue los productos que componen a este producto)" id="divCompuestos">
            <div class="panel-content">
              <component-ui-check-box label='Producto Compuesto' [widthLabelColumns]="2" formControlName="Compuesto"
                [widthTextColumns]="2" [tabIndex]="27" />

              <ng-scrollbar style="height: 100px;border-bottom: 1px solid #AAAAAA;width:790px;">
                <table class="blueTable" style="width:740px">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 50px;"></th>
                      <th style="width: 250px;">Producto</th>
                      <th style="width: 70px;">Cantidad</th>
                      <th style="width: 200px;">Almacen</th>
                      <th style="width: 200px;">Concepto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                      *ngFor="let item of listaCompuestos; let index = index;">
                      <td style="text-align: center;">
                        <i (click)="deleteRowCompuesto(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-1x"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td>
                      <td (dblclick)="onDblClickRow(index)">
                        <span style="margin-left: 3px;">{{item.Producto ?
                          (item.Producto.Clave + " => " + item.Producto.Nombre) : '' }}</span>
                      </td>
                      <td>
                        <span style="margin-left: 3px; text-align:right;">{{item.Cantidad > 0 ?
                          (item.Cantidad|number:'1.2-6') : '' }}</span>
                      </td>
                      <td>
                        <span style="margin-left: 3px;">{{item.Almacen ? (item.Almacen.Clave + " => " +
                          item.Almacen.Nombre) : '' }}</span>
                      </td>
                      <td>
                        <span style="margin-left: 3px;">{{item.ConceptoSalida ? (item.ConceptoSalida.Clave + " => " +
                          item.ConceptoSalida.Nombre) : '' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
          </app-panel-base>
        </div>
        <div id="prod-default-tab-6" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Paquetes/Lotes" id="divPaquetes">
            <div class="panel-content">
              <div *ngIf="myForm.value.Id>0 && myForm.value.ManejaPaquetesLotes && myForm.value.Inventariable"
                (click)="openExistencias()" class="btn btn-outline-orange"
                style="margin-left: 160px !important;margin-bottom: 9px !important;">
                Ver Existencias
              </div>
              <component-ui-check-box label='Maneja Paquetes/Lotes' [widthLabelColumns]="2"
                formControlName="ManejaPaquetesLotes" [widthTextColumns]="2" [tabIndex]="50" />
              <component-ui-check-box [enabled]="myForm.value.ManejaPaquetesLotes" label='Maneja Fecha de Caducidad'
                [widthLabelColumns]="2" formControlName="ManejaFechaCaducidad" [widthTextColumns]="2" [tabIndex]="26" />
            </div>
          </app-panel-base>
        </div>
        <div id="prod-default-tab-7" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">
              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />
            </div>
          </app-panel-base>
        </div>
      </div>
      <!-- Ubicaciones -->
      <!-- otras unidades (onSelectedItem)="selectedCombo($event,'DocumentoVenta')" -->
      <ctrl-edicion-producto-compuesto-page *ngIf="CompuestoActual" [item]="getSelectedCompuestoActual" [producto] = "this.myForm.value"
        (onCloseEdition)="onCloseEdit($event)" />
    </form>
  </div>
  <ng-template let-modal let-c="close" #seleccionarFamilia>
    <app-seleccionar-tree [type]="'FamiliaProducto'" #seleccionarFamilia />
  </ng-template>

  <ng-template #busquedaProductosRelacionados let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
      [filter]="getFilterProducto" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'"
      #busquedaProductosRelacionados />
  </ng-template>
  <ng-template #ctrlExistencias let-modal let-c="close">
    <app-ctrl-existencias-page #ctrlExistencias [manejaPaquetes]="myForm.value.ManejaPaquetesLotes"
      [idProducto]="myForm.value.Id" [idEmpresa]="this.myForm.value.Empresa.Id"
      [producto]="myForm.value.Clave + ' ' +myForm.value.Nombre" />
  </ng-template>

  <ng-template #busquedaProductos let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
      [filter]="getFilterProducto" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
  </ng-template>

  <input (change)="onChangeFile($event)" [accept]="'.png,.PNG,.jpg,.JPG,.bmp,.BMP'" id="fileUpload" type="file"
    style="display: none;" #txtFile />

  <ng-template #busquedaProdServ let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Claves de Producto y Servicio" [columns]="getColumns"
      [entityName]="'c_ClaveProdServ'" [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'"
      #busquedaProdServ />
  </ng-template>

  <ng-template #busquedaProdEquivalente let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
      [filter]="getFilterProducto" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'"
      #busquedaProdEquivalente />
  </ng-template>

</app-container-base>

<ng-template #modalImpresionProductos let-modal let-c="close">
  <impresion-productos></impresion-productos>
</ng-template>
