<div class="form-group row">
  <div class="hidden-file">
    <input (change)="onchange($event)" [accept]="accepts" type="file" #txtFile>
  </div>
  <label class="col-sm-{{widthLabelColumns}} col-form-label tb-right">{{label}}{{separator}}</label>
  <div class="col-sm-{{widthTextColumns}}">
    <div class="d-flex">
      <button [tabindex]="tabIndex" type="button" (click)="btnClic()" class="file-upload-button btn btn-primary btn-sm">Seleccione</button>
      <input [ngbTooltip]="helpLine"  tooltipClass="complit-tooltip" style="border-radius: 0px;border:0px;border-bottom: 1px solid #000000;" class="form-control form-control-sm " [value]="text" type="text" readonly #txtFileText />
    </div>
  </div>
