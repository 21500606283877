<div class="card text-center border-0" cdkDrag style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{getEncabezado}}
    </span>
  </div>

  <app-panel-base title="Presupuestos Existentes por Mes">
    <div class="panel-content">
      <gui-grid [source]="lista" [autoResizeWidth]="true" [virtualScroll]="true" [rowHeight]="30" [rowSelection]="false"
        [theme]="'fabric'" [maxHeight]="350">
        <gui-grid-column [width]="120" [cellEditing]="false" [align]="GuiColumnAlign.CENTER">
          <ng-template let-item="item">
            <a (click)="seleccionar(item)" class="btn btn-xs btn-default d-block">
              <i class="fa fa-share-from-square fa-fw"></i>Seleccionar</a>
          </ng-template>
        </gui-grid-column>
        <gui-grid-column header="Año" [width]="80" [cellEditing]="false" [field]="'Anio'"
          [align]="GuiColumnAlign.CENTER">
        </gui-grid-column>
        <gui-grid-column header="Período" [width]="110" [cellEditing]="false" [field]="'Nombre'" [type]="'String'">
        </gui-grid-column>
        <gui-grid-column header="Meta del mes" [align]="GuiColumnAlign.RIGHT" field="cantidad" [width]="150"
          [cellEditing]="false" [field]="'MetaDelMes'">
          <ng-template let-item="item">
            {{item.MetaDelMes | number:'1.2-6'}}
          </ng-template>
        </gui-grid-column>
      </gui-grid>
    </div>
  </app-panel-base>

  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>







<!--
<div (keydown)="keydown($event)" class="card text-center border-0" cdkDrag
  style="position: absolute;top:250px;width: 540px;left: 360px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar/editar producto compuesto
  </div>
  <div class="card-body bg-light">
    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">
        <div class="d-flex">

        <component-ui-combo-box [setItemsList]="listaAnios" listProperty='Nombre' label="Año" [tabIndex]="2"
        [inputProperty]="'Nombre'" (onSelectedItem)="selectAnio($event)"
        [widthLabelColumns]="8" [widthTextColumns]="8" [topLabel]="true" [selectManualItem]="getAnio" #AnioSeleccionado/>

      <component-ui-combo-box [setItemsList]="listaMeses" listProperty='Nombre' label="Mes" [tabIndex]="2"
        [inputProperty]="'Nombre'" (onSelectedItem)="selectMes($event)" [selectManualItem]="getMes"
        [widthLabelColumns]="8" [widthTextColumns]="8" [topLabel]="true" />
      </div>
    </div>
    </div>

  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8] Aceptar
      </div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
    </div>
  </div>
</div> -->
