import { NgModule } from '@angular/core';
import { MediosEntradaPageComponent } from './pages/medios-entrada-page/medios-entrada-page.component';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { NgxEditorModule } from 'ngx-editor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { Select2Module } from 'ng-select2-component';
import { ComponentsModule } from '../components/components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { VentasModule } from '../ventas/ventas.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EstoadosProspectoPageComponent } from './pages/estoados-prospecto-page/estoados-prospecto-page.component';
import { ConfiguracionCrmPageComponent } from './pages/configuracion-crm-page/configuracion-crm-page.component';
import { VistasCrmPageComponent } from './pages/vistas-crm-page/vistas-crm-page.component';
import { ProspectosCrmPageComponent } from './pages/prospectos-crm-page/prospectos-crm-page.component';
import { LlamadasPageComponent } from './pages/llamadas-page/llamadas-page.component';
@NgModule({
  imports: [
    ComponentUiModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateBoxComponent,
    NgxEditorModule,
    NgxDatatableModule,
    GuiGridModule,
    Select2Module,
    ComponentsModule,
    DragDropModule,
    NgScrollbarModule,
    VentasModule,
    NgbTooltipModule
  ],
  exports: [],
  declarations: [
    MediosEntradaPageComponent,
    EstoadosProspectoPageComponent,
    ConfiguracionCrmPageComponent,
    VistasCrmPageComponent,
    ProspectosCrmPageComponent,
    LlamadasPageComponent
  ],
  providers: [],
})
export class CrmModule { }
