import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { VentaAfectaNotaCredito } from '../../interfaces/venta.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from '../../services/facturacion.service';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { EventsService } from 'src/app/service/events.service';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';

@Component({
  selector: 'app-datos-venta-afecta-page',
  templateUrl: './datos-venta-afecta-page.component.html',
  styles: [
  ]
})
export class DatosVentaAfectaPageComponent implements OnInit {


  @Input()
  public afecta: any | null = null;
  private eventsService = inject(EventsService);
  @ViewChild('cboSucursal')
  public cboSucursal!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtImporte')
  public txtImporte!: ElementRef<NumberBoxComponent>;

  ngOnInit(): void {

    setTimeout(() => {
      const t: any = this.cboSucursal;
      t.tagInput.nativeElement.focus();
    }, 100);

    let afClon = this.afecta;
    if (!afClon) {
      afClon = {
        Sucursal: null,
        Serie: '',
        Folio: 0,
        Importe: 0,
      }
    }

    this.myForm.reset(this.afecta);
  }

  public myForm: FormGroup = this.fb.group({
    Sucursal: [null],
    Serie: [''],
    Folio: [0],
    Importe: [0],
  })

  constructor(private fb: FormBuilder, private ms: ModalService, private fServices: FacturacionService) {
  }

  selectedCombo(ent: any, tipo: string) {




    this.myForm.controls[tipo].setValue(ent);
  }

  accept() {
    const t: any = this.txtImporte;
    t.tagInput.nativeElement.blur();
    setTimeout(() => {
      if (!this.myForm.value.Sucursal) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Sucursal.`, cancelButton: false });
        return;
      }
      if (!this.myForm.value.Serie) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Serie.`, cancelButton: false });
        return;
      }

      if (this.myForm.value.Folio <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Folio.`, cancelButton: false });
        return;
      }

      if (this.myForm.value.Importe <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Importre.`, cancelButton: false });
        return;
      }
      this.ms.closeModal(this.myForm.value);
    }, 100);


  }

  close() {

    this.ms.closeModal(null);
  }

}
