<div class="card text-center border-0" cdkDrag style="width: 940px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Captura de Complemento Carta Porte 3.0
  </div>
  <form [formGroup]="myForm" autocomplete="off">
    <div class="card-body bg-light">
      <div class="mb-2">
        <div class="progress h-20px w-100 mb-0 me-2">
          <div [class]="'progress-bar progress-bar-striped progress-bar-animated  bg-success'"
            [style]="'width: '+getPorcentaje+'%;'">
            {{getPorcentaje}}% Completado
          </div>
        </div>
        <app-panel-base title="Generales del Complemento Carta Porte">
          <div class="panel-content">
            <span *ngIf="!EsPlantilla" class="float-end">
              <a (click)="guardarPlantilla()" class="btn btn-sm btn-white me-1 "><i
                  class="fa fa-circle-check t-plus-1 text-green fa-fw fa-lg"></i>
                Marcar
                como plantilla</a>
            </span>
            <component-ui-combo-box [widthList]="500" *ngIf="myForm.value.Id==0 && !EsPlantilla"
              [entityName]="'PlantillaUbicacion'" label="Plantillas disponibles" extras="JSON as JSON"
              [widthTextColumns]="3" [widthLabelColumns]="3" [inputProperty]="'Nombre'" [widthRightColumns]="3"
              (onSelectedItem)="selectPlantilla($event)" [listProperty]="'Nombre'" />
            <components-ui-number-box [formatoMoneda]="true" [enabled]="false" formControlName="TotalDistRec"
              [maxLength]="9" [label]="'Total distancia (kmts)'" [tabIndex]="1" [widthTextColumns]="3"
              [widthLabelColumns]="3" [widthLabelColumns]="3" />

          </div>
        </app-panel-base>
      </div>
      <div class="col-xl-12">
        <div class="accordion" id="accordion">
          <div class="accordion-item border-0">
            <div class="accordion-header" id="headingOne">
              <button class="accordion-button bg-gray-400 text-black px-3 pt-10px pb-10px pointer-cursor collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseTransporte">
                <i class="fa fa-truck-ramp-box fa-fw text-success me-2 fa-lg"></i> Transporte <span
                  class="badge bg-danger" style="margin-left: 70px;">{{getTransporteDatos}}</span>
              </button>
            </div>
            <div id="collapseTransporte" class="accordion-collapse collapse collapse"
              style="border-left: 1px solid;border-bottom: 1px solid;border-right: 1px solid;"
              data-bs-parent="#accordion">
              <div class="accordion-body bg-gray-200 text-white text-start">
                <app-panel-base title="Ubicaciones" id="divUbicacion">
                  <div class="panel-content">
                    <div class="mb-3" *ngIf="myForm.value.Id ==0">
                      <component-ui-combo-box [widthList]="500" [filterQuery]="'UtilizarParaEntregas = true'"
                        [entityName]="'Unidad'" label="Vehículo" [widthTextColumns]="2" [widthLabelColumns]="2"
                        [inputProperty]="'Nombre'" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event)"
                        [listProperty]="'ClaveNombre'" #cboVehiculo />
                    </div>
                    <div class="card border-0 mb-4" *ngIf="myForm.value.Mercancias?.AutoTransporte">
                      <div class="card-body fw-bold">
                        <div class="d-flex">
                          <div style="width: 50%;">
                            <h4>Datos del vehículo</h4>
                            <br>Placas: {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.PlacaVM}}<br>
                            Modelo: {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.AnioModeloVM}}<br>
                            Peso bruto:
                            {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.PesoBrutoVehicular
                            |
                            number:'1.2'}}<br>
                            Config:
                            {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.ConfigVehicular.Clave}}<br>
                            Permiso SCT: {{myForm.value.Mercancias?.AutoTransporte.PermSCT}}<br>
                            Num Permiso: {{myForm.value.Mercancias?.AutoTransporte.NumPermisoSCT}}
                          </div>
                          <div style="width: 50%;">
                            <div *ngIf="myForm.value.Mercancias?.AutoTransporte.Remolques?.length>0">
                              <h3>Remolque</h3>
                              <br>SubTipo:{{myForm.value.Mercancias?.AutoTransporte.Remolques[0].SubTipoRem}}
                              <br>Placas Remolque: {{myForm.value.Mercancias?.AutoTransporte.Remolques[0].Placa}}
                            </div>
                          </div>
                        </div>
                        <hr>
                        <h4>Datos de la aseguradora</h4>
                        <br>Aseguradora: {{myForm.value.Mercancias?.AutoTransporte.Seguros[0].AseguraRespCivil}}
                        <br>Modelo: {{myForm.value.Mercancias?.AutoTransporte.Seguros[0].PolizaRespCivil}}<br>
                      </div>
                    </div>
                  </div>
                </app-panel-base>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <div class="accordion-header" id="headingOne">
              <button class="accordion-button bg-gray-400 text-black px-3 pt-10px pb-10px pointer-cursor collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                <i class="fa fa-map-pin fa-fw text-red me-2 fa-lg"></i> Ubicaciones <span class="badge bg-danger"
                  style="margin-left: 62px;">{{getUbicacionesDatos}}</span>
              </button>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse collapse"
              style="border-left: 1px solid;border-bottom: 1px solid;border-right: 1px solid;"
              data-bs-parent="#accordion">
              <div class="accordion-body bg-gray-200 text-white">
                <app-panel-base title="Ubicaciones" id="divUbicacion">
                  <div class="panel-content">
                    <div class="mb-3">
                      <div class="text-start ">
                        <a class="btn btn-primary px-4" (click)="AgregarUbicacion()"><i
                            class="fa fa-map-pin fa-lg me-2 ms-n2 text-success-900"></i>Agregar ubicación</a>
                      </div>
                    </div>
                    <gui-grid [source]="listaUbicaciones" [autoResizeWidth]="false" [width]="800" [virtualScroll]="true"
                      [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">
                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          <i (click)="deleteRowUbicacion(index)" *ngIf="item.TipoUbicacion"
                            class="fa fa-rectangle-xmark" style="color:red;font-weight: bold; cursor: pointer;"></i>
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          <i (click)="editarUbicacion(index)" *ngIf="item.TipoUbicacion" class="fa fa-pencil"
                            style="color:orange;font-weight: bold; cursor: pointer;"></i>
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="Tipo" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{ item.TipoUbicacion }}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [field]="'IDUbicacion'" header="Id" [width]="150" [cellEditing]="false" />
                      <gui-grid-column [field]="'FechaHoraSalidaLlegada'" header="Fecha" [width]="200"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{getDate(item)}}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column header="Distancia" [width]="100" [cellEditing]="false">
                        <ng-template let-item="item">
                          {{ item.DistanciaRecorrida > 0 ? (item.DistanciaRecorrida | number:'1.2-6') :'' }}
                        </ng-template>
                      </gui-grid-column>
                    </gui-grid>
                  </div>
                </app-panel-base>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <div class="accordion-header" id="headingTwo">
              <button class="accordion-button bg-gray-400 text-black px-3 pt-10px pb-10px pointer-cursor collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                <i class="fa fa-pallet fa-fw text-blue me-2 fa-lg"></i> Mercancías<span class="badge bg-danger"
                  style="margin-left: 65px;">{{getMercanciasDatos}}</span>
              </button>
            </div>
            <div id="collapseTwo" class="accordion-collapse collapse"
              style="border-left: 1px solid;border-bottom: 1px solid;border-right: 1px solid;"
              data-bs-parent="#accordion">
              <div class="accordion-body bg-gray-200 text-start">
                <app-panel-base title="Mercancías">
                  <div class="panel-content" id="divMercancias">
                    <div class="mb-3" *ngIf="myForm.value.Id ==0 || EsPlantilla">
                      <div class="ms-auto">
                        <a (click)="AgregarMercancia()" class="btn btn-primary px-4"><i
                            class="fa fa-truck-ramp-box fa-lg me-2 ms-n2 text-success-900"></i>Agregar mercancía</a>
                        <a (click)="searchFile()" class="btn btn-success px-4 ms-4"><i
                            class="fa fa-file-excel fa-lg me-2 ms-n2 text-success-900"></i>Importar CSV</a>
                      </div>
                      <input (change)="onchange($event)" [accept]="'.csv,.CSV'" id="fileUpload" type="file" style="display: none;"
                      #txtFile />
                    </div>
                    <div class="mb-1 fs-12px">
                      <div class="text-gray-600 flex-1">No total de mercancías: {{getNumeroMercancias | number}}</div>
                      <div class="text-gray-600 flex-1">Peso bruto total: {{getPesoTotal | number:'1.2-6'}}</div>
                    </div>
                    <gui-grid [source]="listaMercancias" [autoResizeWidth]="false" [width]="800" [virtualScroll]="true"
                      [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="110">

                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          <i (click)="deleteRowMercancia(index)" *ngIf="item.BienesTransp" class="fa fa-rectangle-xmark"
                            style="color:red;font-weight: bold; cursor: pointer;"></i>
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          <i (click)="editarMercancia(index)" *ngIf="item.BienesTransp" class="fa fa-pencil"
                            style="color:orange;font-weight: bold; cursor: pointer;"></i>
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [field]="'BienesTransp'" header="BienesTransp" [width]="100"
                        [cellEditing]="false" />
                      <gui-grid-column [field]="'Descripcion'" header="Descripción" [width]="150"
                        [cellEditing]="false" />
                      <gui-grid-column [field]="'Cantidad'" header="Cantidad" [width]="100" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          {{ item.BienesTransp ? (item.Cantidad | number:'1.2-6') : "" }}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [field]="'ClaveUnidad'" header="Unidad" [width]="100" [cellEditing]="false" />
                      <gui-grid-column [field]="'PesoEnKG'" header="Peso KG" [width]="100" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          {{ item.BienesTransp ? (item.PesoEnKG | number:'1.2-6') : "" }}
                        </ng-template></gui-grid-column>
                      <gui-grid-column [field]="'MaterialPeligroso'" header="Peligroso" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          {{ item.BienesTransp ? (item.MaterialPeligroso ? "Si":"No") : "" }}
                        </ng-template>
                      </gui-grid-column>
                    </gui-grid>
                  </div>
                </app-panel-base>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <div class="accordion-header" id="headingThree">
              <button class="accordion-button bg-gray-400 text-black px-3 pt-10px pb-10px pointer-cursor collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                <i class="fa fa-users fa-fw text-orange me-2 fa-lg"></i> Figuras de Transporte<span
                  class="badge bg-danger" style="margin-left: 10px;">{{getFigurasDatos}}</span>
              </button>
            </div>
            <div id="collapseThree" style="border-left: 1px solid;border-bottom: 1px solid;border-right: 1px solid;"
              class="accordion-collapse collapse" data-bs-parent="#accordion">
              <div class="accordion-body bg-gray-200 text-start">
                <app-panel-base title="Figuras de transporte" id="divFiguras">
                  <div class="panel-content">
                    <div class="mb-3" *ngIf="myForm.value.Id ==0 || EsPlantilla">
                      <div class="ms-auto"><a (click)="AgregarFigura()" class="btn btn-primary px-4"><i
                            class="fa fa-user-plus fa-lg me-2 ms-n2 text-success-900"></i>Agregar figura</a></div>
                    </div>
                    <gui-grid [source]="listaFiguras" [autoResizeWidth]="false" [width]="800" [virtualScroll]="true"
                      [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">

                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
                        <ng-template let-item="item" let-index="index">
                          <i (click)="deleteRowFigura(index)" *ngIf="item.RFCFigura" class="fa fa-rectangle-xmark"
                            style="color:red;font-weight: bold; cursor: pointer;"></i>
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [field]="'RFCFigura'" header="RFC" [width]="100" [cellEditing]="false" />
                      <gui-grid-column [field]="'NumLicencia'" header="Licencia" [width]="150" [cellEditing]="false" />
                      <gui-grid-column [field]="'NombreFigura'" header="Nombre" [width]="250" [cellEditing]="false" />
                    </gui-grid>
                  </div>
                </app-panel-base>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer fw-bold">
      <div style="text-align: right; margin-top: 5px;">
        <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar
        </div>
        <div *ngIf="!EsPlantilla" (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
      </div>
    </div>
  </form>
  <ng-template #ctrlFiguras let-modal let-c="close">
    <app-ctrl-edicion-tipo-figura [colocarMargenes]="false" [tabIndex]="100" [item]="getSelectedTipoFigura"       />
    </ng-template>
  <ng-template #ctrlUbicacion let-modal let-c="close">
    <app-ctrl-edicion-ubicacion [colocarMargen]="false" [esOrigen]="listaUbicaciones.length==1" [tabIndex]="50"
      [item]="getSelectedUbicacion" />
  </ng-template>
  <ng-template #ctrlMercancia let-modal let-c="close">
    <app-ctrl-edicion-mercancia [colocarMargen]="false" [tabIndex]="150" [item]="getSelectedMercancia" />
  </ng-template>
</div>
