<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <label (click)="onClickLink()"
    class="col-sm-{{widthLabelColumns}} col-form-label {{(topLabel ? 'text-start height-label':'tb-right')}} {{enableLink?'tb-link text-orange':(IsRequired?'text-red': '')}}"><i
      *ngIf="IsRequired" class="fa fa-asterisk text-red fs-10px me-2"></i>
      {{label}}{{ label.length>0?separator:''}}
    {{label.length==0?"&nbsp;":""}}</label>
  <div class="col-sm-{{widthTextColumns}}">
    <div class="d-flex">
      <input style="border-radius: 0px;border:0px;border-bottom: 1px solid #000000;" (keydown.f9)="F9($event)"
        (keydown.enter)="keydownEnter($event)" (keydown.f2)="triggerClick()" (keydown.arrowdown)="navigate('down')"
        (keydown.arrowup)="navigate('up')" (keyup)="filter($event)" (focus)="focus($event)" (blur)="onBlur()" (click)="triggerClick()"
        (keydown)="onKeyDownEvent($event)" [tabindex]="tabIndex" [disabled]="!this.enabled" [readonly]="!enabled"
        maxLength="{{maxLength}}" class="form-control form-control-sm  {{(IsRequired?'bottom-red': '')}}" type="text"
        placeholder="{{placeHolder}}" [(ngModel)]="value" (keypress)="onKeyPressEvent($event)" #txtTagCombo />
      <div (mousedown)="mousedown($event)" (click)="triggerClick()"
        class="combo-box-trigger  {{(IsRequired?'bottom-red': '')}}">
        <i [class]="getClass"></i>
      </div>
    </div>
  </div>
  <div *ngIf="!isCatalog && widthRightColumns>0" class="combobox-rightlabel col-sm-{{widthRightColumns}} "
    style="padding-left:5px;text-align: left;">
    {{getRightLabel}}
  </div>
</div>
