<!-- BEGIN page-header -->
<!-- <h1 class="page-header">Nombre de la pantalla <small>Esta pantalla ayuda a hacer cosas bonitas...</small></h1> -->
<!-- END page-header -->

<!-- BEGIN panel -->


<div class="row">
  <div class="col-xl-12">
    <div class="card border-0 mb-4">
      <div class="card-header h6 mb-0 bg-none p-3">
        <i class="fa fa-dolly fa-lg fa-fw text-dark text-opacity-50 me-1"></i> está página se encuentra en proceso de pruebas e integración
      </div>
      <div class="card-body">
        <div class="mb-3">
          <h1 style="text-align: center;"> Página en proceso de pruebas</h1>
        </div>
      </div>
    </div>
  </div>



  <!-- END panel -->
