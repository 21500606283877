import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { CartaPorte, c_CodigoPostal } from '../interfaces/cartaporte.interface';

@Injectable({ providedIn: 'root' })

export class CartaPorteService {

  private readonly baseUrl: string = environment.baseUrlApi;

  constructor(private http: HttpClient) {

  }

  saveCartaPorte(ent: any): Observable<Result> {
    const params = new HttpParams().set("jsonCartaPorte", JSON.stringify(ent));
    return this.http.get<Result>(`${this.baseUrl}/Ventas/GuardarCartaPorte`, { params });
  }


  savePlantilla(nombre: string, json: string): Observable<Result> {
    const params = {
      Nombre: nombre, JSON: json
    };
    return this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarPlantillaUbicaion`, params);
  }

  getUnidad(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Fiscal/ObtenerUnidadCartaPorte`, { params });
  }

  getChofer(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Fiscal/ObtenerChoferCartaPorte`, { params });
  }

  getComps(idEmpresa: number, idSucursal: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal).set("soloCartaPorte", true);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Ventas/ObtenerListaComprobantes`, { params });
  }

  getById(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerCartaPorteID`, { params });
  }

  getCodigoPostal(cp: string): Observable<c_CodigoPostal> {
    const params = new HttpParams().set("cp", cp);
    return this.http.get<c_CodigoPostal>(`${this.baseUrl}/Fiscal/ObtenerPorCodigoPostal`, { params });
  }

  obtenerSiguienteFolio(idEmpresa: number, idSucursal: number, idDocumento: number, serie: string): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa)
      .set("idSucursal", idSucursal)
      .set("idDocumento", idDocumento)
      .set("serie", serie);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerSiguienteFolioCartaPorte`, { params });
  }

  print(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirCartaPorte`, { params })
  }

  downloadFiles(id: number, cb: any) {
    const params = new HttpParams().set("id", id);//.set("responseType", "arraybuffer");
    return this.http.get(`${this.baseUrl}/Ventas/DescargarArchivosCartaPorte?id=${id}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      if (cb) { cb(); }
      if (d) {
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url, 'archivo.zip');
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  findEntityByParams(idEmp: number, idSuc: number, idDocto: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("idDoct", idDocto)
      .set("serie", serie)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<CartaPorte>(`${this.baseUrl}/Ventas/ObtenerCartaPortePorParametros`, { params });
  }

  obtenerTipoCartaPorte(): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerTipoCartaPorte`);
  }


}
