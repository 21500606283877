import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'estado-resultados-page',
  templateUrl: './estado-resultados-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstadoResultadosPageComponent implements OnInit, AfterViewInit {


  @ViewChild('txtFileConfiguracion')
  public txtFileConfiguracion!: ElementRef<HTMLInputElement>;


  //* injects
  private utilsService = inject(UtilsService);
  private contabilidadService = inject(ContabilidadService);
  private fb = inject(FormBuilder)
  private reportsService = inject(ReportsService)
  private eventsService = inject(EventsService)

  //* variables globales
  listaMeses: any[] = [];
  listaAnios: any[] = [];
  listaOpciones: any[] = [];
  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  anio1Seleccionado?: ComboBoxEntity;
  anio2Seleccionado?: ComboBoxEntity;
  opcionSeleccionada?: ComboBoxEntity;
  esReporteComparativo: boolean = false;
  menuId: string = "estado-resultados";


  loadComplete: boolean = false;
  file: any;
  mesActual: number = 0;
  private readonly baseUrl: string = environment.baseUrlApi;


  get getMes1(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE RESULTADOS AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE RESULTADOS A ' + this.rangoFechasComparativo);
    }

    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes1Seleccionado) {
      result = this.mes1Seleccionado;
    }
    return result;
  }

  get getMes2(): ComboBoxEntity | null {


    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE RESULTADOS AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE RESULTADOS AL ' + this.rangoFechasComparativo);
    }

    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes2Seleccionado) {
      result = this.mes2Seleccionado;
    }
    return result;
  }





  get rangoFechas() {
    let result = "";
    if (!this.anio1Seleccionado) return result;
    if (!this.mes1Seleccionado) return result;

    const diaUltimo: number = this.utilsService.getUltimoDiaMes(this.anio1Seleccionado.Id, this.mes1Seleccionado.Id);

    result = diaUltimo + " DE " + this.utilsService.getMes(this.mes1Seleccionado!.Id) + " DE " + this.anio1Seleccionado.Clave;
    return result.toUpperCase();
  }

  get rangoFechasComparativo() {
    let result = "";
    if (!this.anio1Seleccionado) return result;
    if (!this.mes1Seleccionado) return result;
    if (!this.anio2Seleccionado) return result;
    if (!this.mes2Seleccionado) return result;

    let diaUltimo: number = this.utilsService.getUltimoDiaMes(this.anio1Seleccionado.Id, this.mes1Seleccionado.Id);
    result = diaUltimo + " DE " + this.utilsService.getMes(this.mes1Seleccionado!.Id) + " DE " + this.anio1Seleccionado.Clave;
    diaUltimo = this.utilsService.getUltimoDiaMes(this.anio2Seleccionado.Id, this.mes2Seleccionado.Id);
    result += " contra " + diaUltimo + " DE " + this.utilsService.getMes(this.mes2Seleccionado!.Id) + " DE " + this.anio2Seleccionado.Clave;
    return result.toUpperCase();
  }




  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<HTMLElement>;;

  public filtrosReporte: ReportFilter =
    {
      menuId:'',
      EsContabilidad:true,
      ReportHeader: {} as ReportHeader,
      NombreReporte: 'Estado de resultados',
      TituloVisor: 'Estado de resultados',
      NombreExcel: 'Estado de resultados.xlsx',
      FilterOptions: [
        { Campo: 'mpactual2.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mpactual2.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Num5: 0,
    Opc1: false,
    Opc2: false,
    Mes1:0,
    Mes2:0,
    Anio1:0,
    Anio2:0
  });



  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }


  ngAfterViewInit(): void {
    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
  }


  ngOnInit(): void {

    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);


    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);

    this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;

    this.myForm.get('Mes1')?.setValue(mesItem.Clave);

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }

        this.anio1Seleccionado = this.listaAnios[0];
        this.myForm.get('Anio1')?.setValue(this.anio1Seleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('ESTADO DE RESULTADOS AL ' + this.rangoFechas);

        if (this.listaAnios.length > 1) {
          this.anio2Seleccionado = this.listaAnios[1];
        } else {
          this.anio2Seleccionado = this.listaAnios[0];
        }
        this.myForm.get('Anio2')?.setValue(this.anio2Seleccionado!.Clave);

        this.listaOpciones.push({ Id: 1, Nombre: "Columna 1 y 2", Clave: 1 },
          { Id: 2, Nombre: "Columna 1", Clave: 2 },
          { Id: 3, Nombre: "Columna 2", Clave: 3 });

        this.opcionSeleccionada = this.listaOpciones[0];
        this.filtrosReporte.menuId = this.menuId;
      }
    });
  }

  ReportHeaderChange(value:any){

    let a: any = 0;
    if (value) {
      if (value.Mes1 && value.Mes2) {
        let mesItem: any = this.listaMeses.find(p => p.Id == value.Mes1);
        this.mes1Seleccionado = mesItem;
        mesItem = this.listaMeses.find(p => p.Id == value.Mes2);
        this.mes2Seleccionado = mesItem;
      }
      if (value.Anio1) {
        let anioItem: any = this.listaAnios.find(p => p.Id == value.Anio1);
        this.anio1Seleccionado = anioItem;
      }
    }
    this.myForm.get('Mes1')?.setValue(this.mes1Seleccionado!.Clave);
    this.myForm.get('Mes2')?.setValue(this.mes2Seleccionado!.Clave);
    this.myForm.get('Anio1')?.setValue(this.anio1Seleccionado!.Clave);
    this.myForm.get('Anio2')?.setValue(this.anio2Seleccionado!.Clave);
    this.myForm.get('NombreReporte')?.setValue('ESTADO DE RESULTADOS AL ' + this.rangoFechas);


  }


  get selectAnio1ManualItem(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE RESULTADOS AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE RESULTADOS A ' + this.rangoFechasComparativo);
    }

    return this.anio1Seleccionado!;
  }

  get selectAnio2ManualItem(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE RESULTADOS AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE RESULTADOS A ' + this.rangoFechasComparativo);
    }
    return this.anio2Seleccionado!;
  }


  get selectOpcion(): ComboBoxEntity | null {
    return this.opcionSeleccionada!;
  }
  clicComparativo(value: boolean) {

    this.esReporteComparativo = value;
  }


  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Mes1') {
      this.mes1Seleccionado = entity;
      this.mes2Seleccionado = entity;
    } else if (field == 'Anio1') {
      this.anio1Seleccionado = entity;
    } else if (field == 'Num5') {
      this.opcionSeleccionada = entity;
    } else if (field == 'Mes2') {
      this.mes2Seleccionado = entity;
    } else if (field == 'Anio2') {
      this.anio2Seleccionado = entity;
    }

    this.myForm.controls[field].setValue(entity.Clave);
  }



  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteEstadoResultados');
  }








}
