import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarraBotonesComponent } from './barra-botones/barra-botones.component';
import { FloatSubMenuComponent } from './float-sub-menu/float-sub-menu.component';
import { GridBusquedaComponent } from './grid-busqueda/grid-busqueda.component';
import { HeaderComponent } from './header/header.component';
import { PanelComponent } from './panel/panel.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarRightComponent } from './sidebar-right/sidebar-right.component';
import { ThemePanelComponent } from './theme-panel/theme-panel.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { RouterModule } from '@angular/router';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TabsNavComponent } from './tabs-nav/tabs-nav.component';
import { Title } from '@angular/platform-browser';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { DialogModule } from 'primeng/dialog';
import { SeleccionarEmpresaSucursalComponent } from './seleccionar-empresa-sucursal/seleccionar-empresa-sucursal.component';

import { PhotoAvatarComponent } from './photo-avatar/photo-avatar.component';
import { PdfJsViewerComponent } from './pdf-viewer/pdfjs-viewer.component';
import { CuentasPagosCxcComponent } from './cuentas-pagos-cxc/cuentas-pagos-cxc.component';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { ChatPageComponent } from './chat-page/chat-page.component';
import { CtrlExistenciasPageComponent } from './ctrl-existencias-page/ctrl-existencias-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxEditorModule } from 'ngx-editor';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { HeaderMobileComponent } from './header copy/header-mobile.component';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    BarraBotonesComponent,
    FloatSubMenuComponent,
    GridBusquedaComponent,
    HeaderComponent,
    HeaderMobileComponent,
    PanelComponent,
    SidebarComponent,
    SidebarRightComponent,
    ThemePanelComponent,
    TopMenuComponent,
    TabsNavComponent,
    TabsNavComponent,
    SeleccionarEmpresaSucursalComponent,
    PhotoAvatarComponent,
    PdfJsViewerComponent,
    CuentasPagosCxcComponent,
    ChatPageComponent,
    CtrlExistenciasPageComponent,
    EllipsisPipe
  ],
  exports: [
    BarraBotonesComponent,
    FloatSubMenuComponent,
    GridBusquedaComponent,
    HeaderComponent,
    HeaderMobileComponent,
    PanelComponent,
    SidebarComponent,
    SidebarRightComponent,
    ThemePanelComponent,
    TopMenuComponent,
    TabsNavComponent,
    SeleccionarEmpresaSucursalComponent,
    PhotoAvatarComponent,
    PdfJsViewerComponent,
    CuentasPagosCxcComponent,
    CtrlExistenciasPageComponent,
    EllipsisPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgScrollbarModule,
    NgxDatatableModule,
    NgbTooltipModule,
    MatMenuModule,
    DialogModule,
    ComponentUiModule,
    DragDropModule,
    NgxEditorModule,
    GuiGridModule,
    ImageCropperModule,
    MatRippleModule
  ],
  providers: [Title]
})
export class ComponentsModule { }
