<app-panel-base title="Vista de mapa de la dirección">
  <div class="panel-content" style="height: 750px;">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26372023.13911858!2d-113.73046575972586!3d36.20934013545441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2smy!4v1624374439018!5m2!1sen!2smy"
      style="border:0;width: 100%;height: 100%;" allowfullscreen="true" loading="lazy"></iframe>
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
    </div>
  </div>
</app-panel-base>
