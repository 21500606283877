<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-rotate"
  subTitle="Saldos de bancos">
  <div class="screen-content p-3" style="height: 100%;">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div class="list-group-item d-flex border-top-0">
      <div class="me-3 fs-16px"><i class="fa fa-rotate text-gray-500 fa-fw"></i></div>
      <div class="flex-fill">
        <div class="fs-14px lh-12 mb-2px fw-bold text-dark">Saldos de Bancos en línea</div>
        <div class="mb-1 fs-12px">
          <div class="text-gray-600 flex-1">Para poder obtener el saldo, es necesario tener una cuenta en la API Prometeo</div>
          <a href="https://dashboard.prometeoapi.com/login" class="alert-link">https://dashboard.prometeoapi.com/login</a>.
        </div>
        <hr class="mb-10px bg-gray-600">
        <div class="d-flex align-items-center mb-5px">
          <div class="fs-12px d-flex align-items-center text-dark fw-bold"> Tienes {{lista.length}} bancos en línea </div>
        </div>
      </div>
    </div>
    <hr class="mb-10px bg-gray-600">
    <div style="margin-left: 15px;" (click)="getBalance()" class="btn btn-primary">Obtener saldos de bancos</div>

    <div class="table-responsive p-3 bg-light">
      <ng-scrollbar style="height: 450px;">
        <table class="table table-bordered mb-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>NOMBRE</th>
              <th>NUM CUENTA</th>
              <th>MONEDA</th>
              <th style="text-align: right;">SALDO</th>

            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of lista">
              <td>{{item.id}}</td>
              <td>{{item.name}}</td>
              <td>{{ item.number }}</td>
              <td>{{ item.currency}}</td>
              <td style="text-align: right;">{{item.balance | number:'1.2'}}</td>
            </tr>

          </tbody>
        </table>
      </ng-scrollbar>
    </div>
  </div>
</app-container-base>
