import { outputAst } from '@angular/compiler';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';



@Component({
  selector: 'components-ui-time-box',
  templateUrl: './time-box.component.html',
  styleUrls: ['./time-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimeBoxComponent),
    }],
})

export class TimeBoxComponent implements AfterViewInit {

  focus() {
    this.tagInput.nativeElement.classList.add("pulse-text");
  }

  @Input()
  public placeHolder: string = "hh:mm";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 100;
  @Input()
  public tabIndex: number = 1;
  @Input()
  public required: boolean = false;
  @Input()
  public enabled: boolean = true;

  @Output()
  blur = new EventEmitter;

  // onSelect: EventEmitter<any> = new EventEmitter<any>();



  value: any | null;

  @ViewChild('txtTag')
  public tagInput!: ElementRef<HTMLInputElement>;

  get IsRequired(): string {
    if (this.tagInput) {
      if (this.tagInput.nativeElement.value) return "";
    }
    return this.required ? "tb-obligate" : "";
  }

  onChange = (value?: string | null) => {
    var ff = 0;

  };

  onTouched = () => { };

  private isDisabled = false;
  inputField = new FormControl();
  constructor(private changeDetectorRef: ChangeDetectorRef,) {
  }

  ngAfterViewInit(): void {
    this.inputField.valueChanges.subscribe(value => {
      //15:00:00
      if (value.length < 8) {
        this.onChange(value! + ':00');
      }
    });

    this.setDisabledState(this.isDisabled);
  }


  blurControl(value: string) {
    this.tagInput.nativeElement.classList.remove("pulse-text");
    this.tagInput.nativeElement.setSelectionRange(0, 1);

    var fechaOut = this.validarDatoLostFocus();

    if (fechaOut !== "") {
      if (this.tagInput.nativeElement.value === "") {
        if (this.required) {
          this.value = fechaOut;
          this.tagInput.nativeElement.value = fechaOut;
        }
      } else {
        this.value = fechaOut;
        this.tagInput.nativeElement.value = fechaOut;
      }
    }

    if (this.tagInput.nativeElement.value !== "") {
      this.value = this.tagInput.nativeElement.value;
      this.onChange(this.value);
    } else {
      this.onChange('');
    }
    this.blur.emit();

  }

  writeValue(value: any): void {
    if (value != null) {
      if (value.length >= 6) {
        value = value.substring(0, 5);
      }
      if (value instanceof Date) {
        const d: Date = value;
        const f = `${d.getHours()}:${d.getMinutes()}`;//:${d.getSeconds()}
        this.inputField.setValue(f);
        this.value = f;
      } else {
        if (value) {
          this.inputField.setValue(value);
          this.value = value;
        }
      }
    } else {
      this.value = "";
    }
    //   const arr = value.substring(0, 10).split(':');
    //   if (arr.length === 3) {
    //     this.inputField.setValue(arr[0] + ':' + arr[1]);
    //     this.value = arr[0] + ':' + arr[1];
    //   }
    // }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  posicionCursor() {
    var pos!: number | null;
    pos = 0;
    pos = this.tagInput.nativeElement.selectionStart;

    if (pos! > 3) {
      this.tagInput.nativeElement.selectionStart = 3;
      this.tagInput.nativeElement.selectionEnd = 3;
    } else {
      this.tagInput.nativeElement.selectionStart = 0;
      this.tagInput.nativeElement.selectionEnd = 0;
    }
  }


  validarDatoLostFocus(): string {

    var tmp: string = '';
    var hora: number = 0;
    var minuto: number = 0;
    var arr: any
    var fechaOut: string;

    tmp = this.tagInput.nativeElement.value;




    hora = parseInt(tmp);
    arr = tmp.split(':');
    if (arr.length > 1) {
      minuto = parseInt(arr[1]);
    }

    if (minuto === 0 || isNaN(minuto)) {
      minuto = 0;
    }
    if (hora === 0 || isNaN(hora)) {
      hora = 0;
    }

    const fechaFmt: string = new Date(0, 0, 0, hora, minuto, 0).toLocaleTimeString('en-GB').substring(0, 5);

    fechaOut = hora.toString().padStart(2, '0') + ':' + (minuto).toString().padStart(2, '0');

    if (this.tagInput.nativeElement.value !== fechaOut) {
      return fechaFmt;
    } else {
      return '';
    }
  }

  onKeyDown(e: any) {

    var key: string = e.key;
    var selstart: number = this.tagInput.nativeElement.selectionStart!;
    var pos: number = 0;

    if (key.toLowerCase() === "backspace") {
      if (selstart > 1) {
        pos = this.tagInput.nativeElement.value.lastIndexOf(':', selstart - 2);
        pos++;
        this.tagInput.nativeElement.setSelectionRange(pos, pos);

      } else if (selstart > 0) {
        this.tagInput.nativeElement.selectionStart = 0;
      }
      e.preventDefault();
    } else if (key.toLowerCase() === "delete") {
      this.value = "";
      this.onChange(null);
    }

  }


  onKeyPress(e: any) {

    var selstart: any;
    var key: any;

    key = String.fromCharCode(e.keyCode);
    selstart = this.tagInput.nativeElement.selectionStart;

    // if (selstart) {
    //   debugger
    //   this.value = "";
    // }


    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
      return;
    }

    if (key === ":") {
      this.posicionCursor();
      return;
    } else if (key > "9" || key < "0") {

      return;
    }

    switch (selstart) {
      case 0:
        if (key > "2") key = "0" + key + ":";
        break;
      case 1:

        if (this.tagInput.nativeElement.value.substring(0, 1) === "2" && key > "3") {
          key = "";
        } else {
          key = key + ":"
        }
        break;
      case 2:
        key = ":";
        break;
      case 3:
        if (key > "5") key = "0" + key;
        break;
      case 4:
        break;
      default:
        key = "";
    }


    // if ((key < "0" || key > '9') && key !== ':') {
    //   e.preventDefault();
    //   return;
    // }



    // If (sKey < "0" Or sKey > "9") And sKey <> ":" Then Exit Sub
    //  If c_text(0).SelLength = 0 Then c_text(0).SelLength = 1
    //  p_iKeyAscii = Asc(sKey)

    if (key.length > 0) {
      this.tagInput.nativeElement.setSelectionRange(this.tagInput.nativeElement.selectionStart, this.tagInput.nativeElement.selectionStart + key.length);
      this.tagInput.nativeElement.setRangeText(key);
      console.log(key);
      this.tagInput.nativeElement.setSelectionRange(this.tagInput.nativeElement.selectionStart + key.length, this.tagInput.nativeElement.selectionStart + key.length);


    }
    e.preventDefault();
  }
}





