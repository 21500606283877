<app-container-base subTitle="Generación de DIOT (Declaración Informativa de Operaciones con Terceros)" (onClickBarButton)="clickBoton($event)"
  [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales" style="width:750px;">
        <div class="panel-content">

          <div class="card-header h6 mb-0 bg-none p-0">
            <i class="fa fa-calendar-check fa-lg fa-fw text-dark text-opacity-50 me-1"></i> Período para generar la
            DIOT
          </div>
          <div class="card-body">
            <div class="d-flex">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Mes1')" [selectManualItem]="getMes1"
                [topLabel]="true" [setItemsList]="listaMeses" listProperty='Nombre' label="Desde" [tabIndex]="2"
                [inputProperty]="'Nombre'" [widthLabelColumns]="12" [widthTextColumns]="12"
                style="width:20%; margin-right: 15px;" />


              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Mes2')" [selectManualItem]="getMes2"
                [topLabel]="true" listProperty='Nombre' [setItemsList]="this.listaMeses" label="Hasta" [tabIndex]="3"
                [inputProperty]="'Nombre'" [widthLabelColumns]="12" [widthTextColumns]="12"
                style="width:20%; margin-right: 15px;" />


              <component-ui-combo-box label='Año' [tabIndex]="4" [zeroMask]="2"
                [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="12"
                [topLabel]="true" [widthLabelColumns]="12" (onSelectedItem)="selectedCombo($event,'Anio1')"
                [inputProperty]="'Nombre'" [listProperty]="'Nombre'" style="width:15%; margin-right: 15px;" />

            </div>
            <div class="d-flex">
              <div style="text-align: right; width: 35%;margin-top: 5px;"></div>
              <div style="text-align: right; width: 35%;margin-top: 5px;"></div>
              <div style="text-align: right; width: 30%;margin-top: 5px;" *ngIf="!this.generado">
                <a (click)="generarContabilidad()" class="btn btn-orange"><i></i>
                  Generar contabilidad </a>
              </div>
            </div>
          </div>
        </div>



        <div class="panel-content" *ngIf="this.generado">
          <div class="card-header h6 mb-0 bg-none p-0" >
            <i class="fa fa-lg fa-fw text-dark text-opacity-50 me-1 text-center"></i> Valor de los actos o actividades
          </div>
          <div class="card-body" >
            <table class="table table-borderless table-sm fw-bold m-0">
              <tbody>
                <tr>
                  <td class="w-150px">IVA Tasa 16%</td>
                  <td></td>
                  <td class="text-end">${{myForm.value.SubTotalIVAGrav | number:'1.2'}}</td>
                </tr>
                <tr>
                  <td>Tasa 0%</td>
                  <td></td>
                  <td class="text-end">${{this.myForm.value.SubTotalIVATasa0| number:'1.2'}}</td>
                </tr>
                <tr >
                  <td >IVA Retenido</td>
                  <td></td>
                  <td class="text-end">${{myForm.value.TotalIVARetenido| number:'1.2'}}</td>
                </tr>
                <tr>
                  <td >IVA Exento</td>
                  <td></td>
                  <td class="text-end">${{this.myForm.value.SubtotalExentoIVA| number:'1.2'}}</td>
                </tr>
                <tr>
                  <td colspan="3">
                    <hr class="m-0">
                  </td>
                </tr>
                <tr>
                  <td class="pb-2" colspan="2"><b>Total</b></td>
                  <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                    <b>${{myForm.value.Total |
                      number:'1.2-6'}}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex">
              <div style="text-align: right; width: 35%;margin-top: 5px;"></div>
              <div style="text-align: right; width: 35%;margin-top: 5px;"></div>
              <div style="text-align: right; width: 30%;margin-top: 5px;">
                <a (click)="Imprimir()" class="btn btn-orange"><i></i>
                  Imprimir</a>
              </div>
            </div>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
