<div *ngIf="isLoading" id="loader" class="app-loader" style="z-index: 99999;">
  <span class="spinner"></span>
</div>


<div class="app-header-fixed">
  <header-mobile (onLogout)="logout()"></header-mobile>
</div>

<router-outlet />
