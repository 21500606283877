<div class="card text-center border-0" cdkDrag style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Time Line de Remisiones
    </span>
  </div>
  <div class="card-body bg-light" style="min-height: 400px; max-height: 400px;">
    <span class="float-end hidden-print">
      <a class="btn btn-sm btn-white mb-10px" (click)="imprimirRemisiones(0)"><i
          class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
        Imprimir</a>
    </span>
    <ng-scrollbar style="height: 320px;margin-top: 35px;">
      <div class="timeline">
        <div *ngFor="let rem of lista" class="timeline-item">
          <div class="timeline-time">
            <span class="date">{{getHour(rem.fecha)}}</span>
            <span class="time">{{getDate(rem.fecha)}}</span>
          </div>
          <div class="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          <div class="timeline-content">
            <div class="timeline-header">
              <div class="username">
                <a href="javascript:;"> {{rem.fechaCancelacion ? ('CANCELADO el ' + getDate(rem.fechaCancelacion) +' '
                  +getHour(rem.fechaCancelacion)):''}}
                  {{rem.idRemision>0?
                  'REMISIÓN':'DEVOLUCIÓN'}}: {{rem.serieFolioRemision}} <i
                    class="fa fa-check-circle text-blue ms-1"></i></a>
              </div>
              <div>
                <a href="#"
                  class="btn btn-lg border-0 rounded-pill w-40px h-40px p-0 d-flex align-items-center justify-content-center"
                  data-bs-toggle="dropdown">
                  <i class="fa fa-ellipsis-h text-gray-600"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <!-- <a href="#" class="dropdown-item d-flex align-items-center">
                    <i class="fa fa-fw fa-bookmark fa-lg"></i>
                    <div class="flex-1 ps-1">
                      <div>Save Post</div>
                      <div class="mt-n1 text-gray-500"><small>Add this to your saved items</small></div>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div> -->
                  <a class="dropdown-item" (click)="imprimirRemisiones(rem.idRemision)"><i
                      class="fa fa-fw fa-print fa-lg me-1"></i>Imprimir</a>
                  <a (click)="verPantalla(rem.idRemision)" class="dropdown-item"><i
                      class="fa fa-fw fa-tablet-screen-button fa-lg me-1"></i>Ver en pantalla</a>

                </div>
              </div>
            </div>
            <div class="timeline-body">
              <div *ngIf="rem.fechaCancelacion" class="text-gray-600 flex-1">{{rem.motivoCancelacion ?
                ('Motivo Cancela: ' + rem.motivoCancelacion): 'No hay motivo de cancelación.'}}</div>
              <div class="mb-3">
                <app-panel-base title="Conceptos">
                  <div class="panel-content">
                    <table class="blueTable" style="width:500px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 100px;">Producto</th>
                          <th style="width: 20px;">Anterior</th>
                          <th style="width: 25px;">{{rem.idRemision>0?'Remisiónado':'Devuelto'}}</th>
                          <th style="width: 20px;">Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
                          *ngFor="let item of rem.detalle; let index = index;">
                          <td class="text-dark" style="width:100px">
                            [{{item.producto.clave}}] {{item.producto.nombre}}
                          </td>
                          <td class="text-dark" style="width:80px;text-align: right;">
                            {{item.cantidadAnterior | number:'1.2-6'}}
                          </td>
                          <td class="text-dark" style="width:30px;padding-right:5px;text-align: right;">
                            {{ rem.idRemision > 0 ? (item.cantidadRemision|number:'1.2-6') :
                            (item.cantidadNotaCredito|number:'1.2-6') }}
                          </td>
                          <td class="text-dark" style="width:30px;padding-right:5px;text-align: right;">
                            {{item.cantidadSaldo | number:'1.2-6'}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <gui-grid [source]="rem.detalle" [autoResizeWidth]="false" [width]="500" [virtualScroll]="true"
                      [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="350">
                      <gui-grid-column header="Producto" [width]="200" [cellEditing]="false">
                        <ng-template let-item="item">
                          [{{item.producto.clave}}] {{item.producto.nombre}}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column header="Anterior" [width]="100" [cellEditing]="false">
                        <ng-template let-item="item">
                          {{item.cantidadAnterior | number:'1.2-6'}}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [header]="(rem.idRemision>0?'Remisiónado':'Devuelto')" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{ rem.idRemision > 0 ? (item.cantidadRemision|number:'1.2-6') :
                          (item.cantidadNotaCredito|number:'1.2-6') }}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column header="Saldo" [width]="100" [cellEditing]="false">
                        <ng-template let-item="item">
                          {{item.cantidadSaldo | number:'1.2-6'}}
                        </ng-template>
                      </gui-grid-column>
                    </gui-grid> -->
                  </div>
                </app-panel-base>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
    <!-- -->
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>
