import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, NgModule } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { EstadoDescargaCfdi, SolicitudDescargaCfdi } from '../../interfaces/solicitud.interface';

@Component({
  selector: 'app-solicitud-descarga-page',
  templateUrl: './solicitud-descarga-page.component.html',
  styles: [
  ]
})
export class SolicitudDescargaPageComponent implements OnInit {
  saving: boolean = false;
  showVerify: boolean = false;
  info: UserLogged = {} as UserLogged;
  list: SolicitudDescargaCfdi[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient, private utileService: UtilsService, private fb: FormBuilder) { }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Empresa2: [{} as Empresa],
    Estado: [{} as EstadoDescargaCfdi],
    FechaInicio: [Date],
    FechaFin: [Date],
  })


  getDate(date: Date) {
    return moment(date).format("DD/MM/YYYY");
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.myForm.reset({
      Empresa: null,
      Empresa2: null,
      FechaInicio: new Date(),
      FechaFin: new Date(),
      Estado: { Id: 4, Clave: '5004', Nombre: 'Todos' }
    })
  }

  autenticar() {

    if (!this.myForm.value.Empresa) {
      Swal.fire({ text: `Indique la empresa.`, icon: 'error', })
      return;
    }

    if (!this.myForm.value.FechaInicio) {
      Swal.fire({ text: `Indique la fecha inicial.`, icon: 'error', })
      return;
    }

    if (!this.myForm.value.FechaFin) {
      Swal.fire({ text: `Indique la fecha final.`, icon: 'error', })
      return;
    }
    this.saving = true;
    const params = new HttpParams().set("idEmpresa", this.myForm.value.Empresa.Id).set("fechaInicio", moment(this.myForm.value.FechaInicio).format("DD-MM-YYYY")).set("fechaFin", moment(this.myForm.value.FechaFin).format("DD-MM-YYYY"));
    return this.http.get<Result>(`${this.baseUrl}/Fiscal/SolicitarDescargas`, { params }).subscribe((result) => {
      this.saving = false
      if (result.success) {
        Swal.fire({ text: "Se hizo la solicitud de descarga, verifique su estatus en la siguiente pestaña.", icon: 'success', })
      } else {
        Swal.fire({ text: result.message, icon: 'error', })
      }
    });
  }

  verifyDownload(pos: number) {
    if (!this.myForm.value.Empresa2) {
      Swal.fire({ text: `Indique la empresa.`, icon: 'error', })
      return;
    }
    const filter = this.list.filter(P => P.codEstatus == '5000');
    if (pos >= filter.length) {
      this.saving = false;
      this.findHistory();
      return;
    }
    const idSolicitud = filter[pos].idSolicitud;
    this.saving = true;
    const params = new HttpParams().set("idEmpresa", this.myForm.value.Empresa2.Id).set("idSolicitud", idSolicitud);
    return this.http.get<void>(`${this.baseUrl}/Fiscal/VerificarSolicitud`, { params }).subscribe(() => { this.verifyDownload(pos + 1) });
  }

  findHistory() {
    if (!this.myForm.value.Empresa2) {
      Swal.fire({ text: `Indique la empresa.`, icon: 'error', })
      return;
    }
    if (!this.myForm.value.Estado) {
      Swal.fire({ text: `Indique el Estatus.`, icon: 'error', })
      return;
    }
    this.saving = true;
    const params = new HttpParams().set("idEmpresa", this.myForm.value.Empresa2.Id).set("idEstatus", this.myForm.value.Estado.Id);
    return this.http.get<SolicitudDescargaCfdi[]>(`${this.baseUrl}/Fiscal/ObtenerSolicitudesPendientes`, { params }).subscribe((result) => {
      this.saving = false

      this.showVerify = result.filter(P => P.codEstatus == '5000').length > 0;

      this.list = result;
    });
  }

  selected(entity: any, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

}
