<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-cloud-arrow-up"
  subTitle="Envía información de CFDIS a efisco (módulo para verificar la salud fiscal de las empresas)">
  <div class="screen-content" style="height: 100%;">
    <span *ngIf="sending" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div class="alert alert-green fade show text-dark">NOTA: Se recomienda hacer este proceso cada fin de mes y cuadrar
      COMPLIT con el SAT a través de efisco</div>
    <app-panel-base title="Filtros para envíar">
      <div class="panel-content">
        <form [formGroup]="myForm" autocomplete="off">

          <component-ui-combo-box [label]="'Empresa'" formControlName="Empresa" [tabIndex]="1" [zeroMask]="2"
            entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Empresa')" [required]="true" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [zeroMask]="0" [required]="true" [label]="'Tipo Comprobante'"
            formControlName="TipoComprobante" [tabIndex]="2" entityName="c_TipoDeComprobante"
            filterQuery='Id = 1 or Id = 2 or Id = 3 or Id = 5' [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoComprobante')"
            [listProperty]="'ClaveNombre'" />

          <components-ui-date-box label='Fecha inicio' formControlName="FechaInicio" [tabIndex]="3"
            [widthLabelColumns]="2" [required]="true" [widthTextColumns]="1" />

          <components-ui-date-box [required]="true" label='Fecha inicio' formControlName="FechaFin" [tabIndex]="4"
            [widthLabelColumns]="2" [widthTextColumns]="1" />

          <div class="form-group row">
            <div class="col-sm-4">
            </div>
            <div class="col-sm-2" style="text-align: right;">
              <div (click)="sendData()" style="margin-right: 15px;" class="btn btn-green {{getIsValid}}">Envíar a efisco
              </div>
            </div>
          </div>
        </form>
      </div>
    </app-panel-base>
    <div class="alert alert-green fade show text-dark">Histórico de envíos, aquí mismo puede ver los estatus de los
      envíos para posteriormente hacer el análisis de información en efisco</div>
    <app-panel-base title="Histórico de envíos a efisco">
      <div class="panel-content">
        <div class="form-group row">
          <div class="col-sm-4">
          </div>
          <div class="col-sm-2" style="text-align: right;">
            <div (click)="verify()" style="margin-right: 15px;margin-bottom: 15px;"
              class="btn btn-green {{history.length> 0 ? '': 'disabled'}}">Verificar en proceso</div>
          </div>
        </div>
        <gui-grid [autoResizeWidth]="false" [width]="800" [source]="history" [rowHeight]="20" [rowSelection]="false"
          [theme]="'fabric'" [maxHeight]="400">
          <gui-grid-column header="Emisor" [width]="130" [field]="'rfcEmpresa'" [cellEditing]="false" />
          <gui-grid-column header="Fecha envío" [width]="100" [field]="'fecha'" [cellEditing]="false">
            <ng-template let-item="item">
              {{getDate(item.fecha)}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Usuario" [width]="100" [field]="'usuario'" [cellEditing]="false">
            <ng-template let-item="item">
              {{item.usuario.nombre}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Fecha inicio" [width]="100" [field]="'fechaInicio'" [cellEditing]="false">
            <ng-template let-item="item">
              {{getDate(item.fechaInicio)}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Fecha Fin" [width]="100" [field]="'fechaFin'" [cellEditing]="false">
            <ng-template let-item="item">
              {{getDate(item.fechaFin)}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Tipo Comprobante" [width]="130" [field]="'tipoComprobante'" [cellEditing]="false">
            <ng-template let-item="item">
              {{getComp(item.tipoComprobante)}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Estatus" [width]="350" [field]="'estatus'" [cellEditing]="false" />
          <gui-grid-column header="" [width]="30" [field]="'estatus'" [cellEditing]="false">
            <ng-template let-item="item">
              <i *ngIf="item.estatus == 'CONSOLIDACIÓN EN PROCESO'" style="color:rgba(255, 235, 20, 0.867);"
                class="fas fa-clock fa-lg"></i>
              <i *ngIf="item.estatus == 'VALIDACION ARCHIVO EN PROCESO'" style="color:rgba(7, 86, 255, 0.867);"
                class="fas fa-clock fa-lg"></i>
              <i *ngIf="item.estatus == 'CONSOLIDACIÓN TERMINADA CON ÉXITO'" style="color:rgb(41, 232, 63);"
                class="fas fa-circle-check fa-lg"></i>
              <i *ngIf="item.estatus == 'ERROR EN PROCESO DE CONSOLIDACIÓN'" style="color:rgba(255, 20, 20, 0.867);"
                class="fas fa-circle-xmark fa-lg"></i>
            </ng-template>
          </gui-grid-column>
        </gui-grid>
      </div>
    </app-panel-base>
  </div>
</app-container-base>
