<!-- BEGIN login -->
<div class="login login-with-news-feed">
	<!-- BEGIN news-feed -->
	<div class="news-feed">
		<div class="news-image" style="background-image: url(/assets/img/login-bg/business.jpg)"></div>
		<div class="news-caption">
			<h4 class="caption-title"><b style="margin-right: 5px;">Complit</b> by Novac Soft</h4>
			<p>
				¿Buscas lo mejor? está a punto de comenzar.
			</p>
		</div>
	</div>
	<!-- END news-feed -->

	<!-- BEGIN login-container -->
	<div class="login-container">
		<!-- BEGIN login-header -->
		<div class="login-header mb-30px">
			<div class="brand">
				<div class="d-flex align-items-center">
					<span class="logo"></span>
					<b style="margin-right: 5px;">Complit</b> by Novac
				</div>
				<small>Información de valor para tu empresa</small>
			</div>
			<div class="icon">
				<i class="fa fa-sign-in-alt"></i>
			</div>
		</div>
		<!-- END login-header -->

		<!-- BEGIN login-content -->
		<div class="login-content">
			<form #f="ngForm" (ngSubmit)="formSubmit(f)" class="fs-13px">
				<div class="form-floating mb-15px">
					<input type="text" class="form-control h-45px fs-13px" placeholder="Email Address" id="emailAddress" />
					<label for="emailAddress" class="d-flex align-items-center fs-13px text-gray-600">Usuario</label>
				</div>
				<div class="form-floating mb-15px">
					<input type="password" class="form-control h-45px fs-13px" placeholder="Password" id="password" />
					<label for="password" class="d-flex align-items-center fs-13px text-gray-600">Password</label>
				</div>
				<div class="form-check mb-30px">
					<input class="form-check-input" type="checkbox" value="1" id="rememberMe" />
					<label class="form-check-label" for="rememberMe">
						Recordarme en este equipo
					</label>
				</div>
				<div class="mb-15px">
					<button type="submit" class="btn btn-theme d-block h-45px w-100 btn-lg fs-14px">Ingresar</button>
				</div>
				<!-- <div class="mb-40px pb-40px text-dark">
					Not a member yet? Click <a routerLink="/register/v3" class="text-primary">here</a> to register.
				</div> -->
				<hr class="bg-gray-600 opacity-2" />
				<div class="text-gray-600 text-center mb-0">
					&copy; Complit Todos los derechos reservados 2023
				</div>
			</form>
		</div>
		<!-- END login-content -->
	</div>
	<!-- END login-container -->
</div>
<!-- END login -->
