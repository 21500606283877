import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';

@Component({
  selector: 'app-diario-compras-page',
  templateUrl: './diario-compras-page.component.html',
  styles: [
  ]
})
export class DiarioComprasPageComponent {
  public get utils(): UtilsService {
    return this._utils;
  }
  public set utils(value: UtilsService) {
    this._utils = value;
  }

  menuId: string = "diariocompras";

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  // Empresa-Sucursal

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-TipoComprobante-Serie-Folio-Estatus', Orden: 'empClave,sucClave,provClave,doc.Clave' }]



  public filtrosDiarioVentas: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Reporte de Diario de Compras',
      NombreExcel: 'Diario de compra.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'doc.Clave', Etiqueta: 'Documento de Compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "DocumentoCompra" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'mon.Clave', Etiqueta: 'Moneda', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Moneda" },
        { Campo: 'clasec.Clave', Etiqueta: 'Clase de compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseCompra" },
        { Campo: 'usu.Clave', Etiqueta: 'Usuario elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseCompra" },
        { Campo: 'c.Vigente', Etiqueta: 'Vigente', Tipo: TypeFilter.list, ListaItems: [{ Nombre: 'Sin selección' }, { Nombre: 'Vigentes' }, { Nombre: 'No vigentes' }] }
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private _utils: UtilsService, private reportsService: ReportsService) { }

  ngOnInit(): void {
    const fecha: string = this._utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('DIARIO DE COMPRAS DEL ' + fecha.toUpperCase());
    this.focus('txtNombreReporte');
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  onclicSinAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc3')?.setValue(!value);
    }
  }

  onclicSoloAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  onclicSinFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc4')?.setValue(!value);
    }
  }

  onclicSoloFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc2')?.setValue(!value);
    }
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }

  clickBoton(event: string) {
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/Compras/ReporteDiarioDeCompras');
  }

}
