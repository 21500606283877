<ng-scrollbar style="height: 300px;">
  <table class="blueTable">
    <thead style="position: sticky;top:0;">
      <tr>
        <th [style]="'width:' + item.width+'px;'" style="cursor: pointer;" *ngFor="let item of columnas">{{item.titulo}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of source">
        <td *ngFor="let col of columnas">
          <input [disabled]="!col.enabled" *ngIf="col.esEditor" [type]="col.tipoEditor"
            [style]="'width:' + col.width+'px;'" />
        </td>
      </tr>

    </tbody>
  </table>
</ng-scrollbar>
