import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HistoricoOrdenManufactura, ProduccionesOrdenManufacturaCabecera } from '../../interfaces/manufacura.interface';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Result } from 'src/app/auth/interfaces';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-historico-orden-page',
  templateUrl: './historico-orden-page.component.html',
  styles: [
  ]
})
export class HistoricoOrdenPageComponent implements OnInit {

  @ViewChild('modalCancelOrdenProduccion')
  public modalCancelOrdenProduccion!: ElementRef<HTMLElement>;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  public lista: any[] = [];

  @Input()
  public listaHistorico: any[] = [];


  private eventsService = inject(EventsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);
  private fServices = inject(FacturacionService);
  private ms = inject(ModalService);
  itemHistorico:any;
  margen:string = "100px";
  indexEditing: number = 0;
  idOrdenCancelar: number = 0;
  constructor() {
  }
  ngOnInit(): void {
    if (this.listaHistorico.length > 0){
    this.itemHistorico = this.listaHistorico[0];
    }



  }

  getDate(fecha: any): string {
    return moment(fecha).format("DD/MM/YYYY HH:mm:ss")
  }

  getListaHtml(value: string) {
    let result: string = value.replaceAll("<p>", "").replaceAll("</p>", "");
    return result;
  }

  openCancel(idOrden: number) {
    const b: any = this.modalCancelOrdenProduccion;
    this.idOrdenCancelar = idOrden;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }


  proceedCancel(e: any) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de Cancelar la Orden de Producción?',
      cancelButton: true,
      textAccept: "Sí, Cancelar",
      textCancel: "Cancelar",
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          const fecha = new Date();
          const date = moment(fecha).format("YYYY-MM-DDTHH:mm:ss");
          const params = new HttpParams()
            .set("idOrden", this.idOrdenCancelar)
            .set("motivoCancelacion", e.MotivoCancelacion)
            .set("fechaCancelacion", date);
          this.http.get<Result>(`${this.baseUrl}/Manufactura/CancelarOrdenProduccion`, { params }).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (result.success) {
              //this.findEntityByParams()
            } else {
              this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
            }
          });
        }
      }
    });
  }

  imprimirOrden(idOrden: number) {

    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idOrden", idOrden);
    this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirOrdenProduccion`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.downloadPdf(result.message);
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    });
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }




  getHour(fecha: any): string {
    return moment(fecha).format("HH:mm:ss")
  }


  closeModal() {
    this.onClose.emit();
  }
}
