import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PresupuestoVentaVendedor, TipoPresupuesto } from '../interfaces/presupuestos.interface';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {

  private readonly baseUrl: string = environment.baseUrlApi;


  constructor(private http: HttpClient) { }


  getPresupuestos(idEmp: number, anio: number, periodo: number, tipo: number): Observable<any[]> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("anio", anio)
      .set("periodo", periodo)
      .set("tipo", tipo);
    return this.http.get<any[]>(`${this.baseUrl}/Ventas/ObtenerPresupuestos`, { params });
  }

  copiarPresupuestos(idDestino: number, idOrigen: number, tipo: TipoPresupuesto): Observable<any> {
    const params = new HttpParams()
      .set("idDestino", idDestino)
      .set("idOrigen", idOrigen)
      .set("tipo", tipo)
    return this.http.get<any>(`${this.baseUrl}/Ventas/CopiarPresupuesto`, { params });
  }

  getListaDeAniosConPresupuestos(tipo: number) {
    const params = new HttpParams()
      .set("tipo", tipo)
    return this.http.get<number[]>(`${this.baseUrl}/Ventas/ObtenerListaDeAniosConPresupuestos`, { params });
  }

  eliminarPresupuestoDetalle(id: number, tipo: number): Observable<Result> {
    const params = new HttpParams().set("id", id).set("tipo", tipo);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EliminarPresupuestoDetalle`, { params });
  }












}
