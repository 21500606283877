<app-container-base [setIsNew]="getIsNew" [activeButtons]="activeButtons"
  (onItemsComboSearched)="onItemsComboSearched($event)" [searchItemsCombo]="false"
  (onItemSearched)="onItemSearched($event)" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  [entity]="myForm" entityName="Cliente" title="Clientes" icon="fa fa-user-tag"
  subTitle="Administra la configuración de Clientes" [printManual]="true">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información Fiscal">
        <div class="panel-content">
          <div *ngIf="getIsBaseCustomer" class="alert alert-success alert-yellow fade show">
            <!-- <strong>IMPORTANTE:</strong> -->
            Este es un cliente de sistema, se utiliza para Facturas al público o Factura Global de Tickets, no se puede
            modificar.
          </div>
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="1+ (esVentana ? 1:0)"
            [widthLabelColumns]="2 + (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)" [tabIndex]="0"
            (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <components-ui-number-box [enabled]="getEnableText" [maxLength]="8" [label]="'Clave'"
            (keydown.enter)="enterField($event)" (blur)="blur($event)" formControlName="Clave" [tabIndex]="1"
            [widthTextColumns]="1+ (esVentana ? 1:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)" #txtClave />

          <components-ui-text-box [enabled]="!getIsBaseCustomer" [required]="true" [tabIndex]="2" [maxLength]="13"
            [upperCase]="true" [required]="true" [label]="'RFC'" [dataType]="EDT.RFC" formControlName="Rfc"
            [widthTextColumns]="2+ (esVentana ? 1:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)" #txtNombre />

          <components-ui-text-box [enabled]="!getIsBaseCustomer" [maxLength]="300" [required]="true" [label]="'Nombre'"
            [required]="true" formControlName="Nombre" [tabIndex]="3" [widthTextColumns]="10- (esVentana ? 1:0)"
            [widthLabelColumns]="2+ (esVentana ? 1:0)" />
        </div>
      </app-panel-base>

      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#cliente-default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Fiscal
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#cliente-default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Dirección</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#cliente-default-tab-3" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Generales</span>
          </a>
        </li>
        <li class="nav-item" role="presentation" *ngIf="!noActivarOpcionesCredito">
          <a href="#cliente-default-tab-4" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Crédito</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#cliente-default-tab-5" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Comercio Exterior</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#cliente-default-tab-6" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#cliente-default-tab-7" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Programación de Visitas</span>
          </a>
        </li>
      </ul>


      <div class="tab-content panel rounded-0 p-3 me-2 ms-2 mt-0">
        <div id="cliente-default-tab-1" class="tab-pane fade active show" role="tabpanel">

          <app-panel-base title="Información Fiscal">
            <div class="panel-content">
              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [required]="true" [label]="'Régimen Fiscal'"
                [filterQuery]="getFiltro" formControlName="RegimenFiscal" [tabIndex]="4" [zeroMask]="2"
                entityName="c_RegimenFiscal" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'RegimenFiscal')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [required]="true" [label]="'Uso de CFDI'"
                [filterQuery]="getFiltro" formControlName="UsoCfdi" [tabIndex]="5" [zeroMask]="2" entityName="c_UsoCfdi"
                [widthTextColumns]="1+ (esVentana ? 2:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                [widthRightColumns]="9 - (esVentana ? 3:0)" (onSelectedItem)="selectedCombo($event,'UsoCfdi')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [required]="true" [label]="'Condición de Pago'" formControlName="CondicionDePago"
                [tabIndex]="6" [zeroMask]="2" entityName="CondicionPago" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'CondicionDePago')" [listProperty]="'ClaveNombre'" />


              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [required]="true" [label]="'Forma de Pago'"
                formControlName="FormaDePago" [tabIndex]="7" [zeroMask]="2" entityName="c_FormaPago"
                [widthTextColumns]="1+ (esVentana ? 2:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                [widthRightColumns]="9 - (esVentana ? 3:0)" (onSelectedItem)="selectedCombo($event,'FormaDePago')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [label]="'Moneda'" [required]="true"
                formControlName="Moneda" [tabIndex]="8" [zeroMask]="2" entityName="Moneda"
                [widthTextColumns]="1+ (esVentana ? 2:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                [widthRightColumns]="9 - (esVentana ? 3:0)" (onSelectedItem)="selectedCombo($event,'Moneda')"
                [listProperty]="'ClaveNombre'" />

              <div *ngIf="!esVentana" class="card bg-success border-0 text-white" style="width: 522px;">
                <div class="card-body">
                  <h4 class="card-title">Cuentas bancarias</h4>
                  <div (click)="openBankAccounts()" class="btn btn-sm btn-primary {{myForm.value.Id==0 && 'disabled'}}">
                    Agregar Cuenta</div>
                </div>
              </div>
            </div>
          </app-panel-base>


        </div>
        <div id="cliente-default-tab-2" class="tab-pane fade" role="tabpanel">
          <component-ui-address [enabled]="!getIsBaseCustomer" [collapsable]="true" [tabIndex]="9"
            formControlName="Direccion"></component-ui-address>
        </div>
        <div id="cliente-default-tab-3" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="General">
            <div class="panel-content">

              <components-ui-text-box [enabled]="!getIsBaseCustomer" [label]="'Comentarios'"
                formControlName="Comentarios" [tabIndex]="21" [widthTextColumns]="10 - (esVentana ? 1:0)"
                [maxLength]="500" [widthLabelColumns]="2+ (esVentana ? 1:0)" />

              <components-ui-text-box [enabled]="!getIsBaseCustomer" [label]="'Teléfonos'" formControlName="Telefonos"
                [tabIndex]="22" [widthTextColumns]="10 - (esVentana ? 1:0)" [maxLength]="500"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" />

              <components-ui-text-box [enabled]="!getIsBaseCustomer" [label]="'Correos'" formControlName="Correos"
                [tabIndex]="23" [widthTextColumns]="10 - (esVentana ? 1:0)" [maxLength]="500"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" />

              <component-ui-combo-box [required]="true" [enabled]="!getIsBaseCustomer" [label]="'Tipo de Cliente'"
                formControlName="Tipo" [tabIndex]="24" [zeroMask]="2" entityName="TipoCliente"
                [widthTextColumns]="1+ (esVentana ? 2:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                [widthRightColumns]="9 - (esVentana ? 3:0)" (onSelectedItem)="selectedCombo($event,'Tipo')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [required]="true" [enabled]="!getIsBaseCustomer" [label]="'Clase de Cliente'"
                formControlName="Clase" [tabIndex]="25" [zeroMask]="2" entityName="ClaseCliente"
                [widthTextColumns]="1+ (esVentana ? 2:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                [widthRightColumns]="9 - (esVentana ? 3:0)" (onSelectedItem)="selectedCombo($event,'Clase')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [required]="true" [label]="'Clase de Venta 1'" formControlName="ClaseVenta"
                [tabIndex]="26" [zeroMask]="2" entityName="ClaseVenta" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'ClaseVenta')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [label]="'Clase de Venta 2'" formControlName="ClaseVenta2" [tabIndex]="27"
                [zeroMask]="2" entityName="ClaseVenta" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'ClaseVenta2')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [label]="'Clase de Venta 3'" formControlName="ClaseVenta3" [tabIndex]="28"
                [zeroMask]="2" entityName="ClaseVenta" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'ClaseVenta3')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box filterQuery="EsVendedor = true" [label]="'Vendedor'" formControlName="Vendedor"
                [tabIndex]="29" [zeroMask]="2" entityName="Usuario" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'Vendedor')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box filterQuery="EsVendedor = true" [label]="'Cobrador'" formControlName="Cobrador"
                [tabIndex]="30" [zeroMask]="2" entityName="Usuario" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'Cobrador')" [listProperty]="'ClaveNombre'" />


              <component-ui-combo-box label='Lista de precios' entityName="ListaPrecio" [tabIndex]="31"
                formControlName="ListaPrecio" [listProperty]="'ClaveNombre'" [widthTextColumns]="1+ (esVentana ? 2:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" [widthRightColumns]="9 - (esVentana ? 3:0)"
                (onSelectedItem)="selectedCombo($event,'ListaPrecio')" />

              <components-ui-number-box [maxLength]="12" [label]="'Descuento'" formControlName="Descuento"
                [tabIndex]="32" [widthTextColumns]="1+ (esVentana ? 1:0)" [widthLabelColumns]="2+ (esVentana ? 1:0)" />

              <component-ui-check-box *ngIf="!esVentana" [tabIndex]="33" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                formControlName="ParaFacturaGlobal" [label]="'Para factura global'" />

              <component-ui-check-box *ngIf="!esVentana" [tabIndex]="34" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                formControlName="ParaTickets" [label]="'Usar para POS'" />

              <component-ui-check-box *ngIf="!esVentana" [tabIndex]="35" [widthLabelColumns]="2+ (esVentana ? 1:0)"
                formControlName="NoAnticipos" [label]="'No facturar anticipo'" />

              <components-ui-text-box
                [helpLine]="'Número de referencia bancaria, se imprime en los documentos de ventas, se autoasigna al guardar el Cliente o se puede modificar.'"
                [label]="'Ref. Bancaria'" formControlName="ReferenciaBancaria" [tabIndex]="36"
                [widthTextColumns]="10 - (esVentana ? 1:0)" [maxLength]="50"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" />

            </div>
          </app-panel-base>
        </div>
        <div id="cliente-default-tab-4" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Crédito" *ngIf="!esVentana">
            <div class="panel-content">
              <components-ui-number-box [enabled]="!getIsBaseCustomer" [maxLength]="12" [label]="'Límite de Crédito'"
                formControlName="LimiteCredito" [tabIndex]="36" [widthTextColumns]="1+ (esVentana ? 1:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" />

              <component-ui-check-box [enabled]="!getIsBaseCustomer" [tabIndex]="37"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" formControlName="CreditoSuspendido"
                [widthTextColumns]="7+ (esVentana ? 1:0)" [label]="'Crédito bloqueado'" />

              <component-ui-check-box [enabled]="!getIsBaseCustomer" [tabIndex]="38"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" formControlName="SuspenderCreditoAlFacturar"
                [widthTextColumns]="7+ (esVentana ? 1:0)" [label]="'Bloquear crédito al facturar'" />

              <component-ui-check-box [enabled]="!getIsBaseCustomer" [tabIndex]="39"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" formControlName="NoValidarCredito"
                [widthTextColumns]="7+ (esVentana ? 1:0)" [label]="'No validar crédito'" />
            </div>
          </app-panel-base>
        </div>
        <div id="cliente-default-tab-5" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Comercio exterior">
            <div class="panel-content">
              <components-ui-text-box [tabIndex]="40" [maxLength]="50" [label]="'NumRegIdTrib'"
                formControlName="NumRegIdTrib" [widthTextColumns]="2+ (esVentana ? 1:0)"
                [widthLabelColumns]="2+ (esVentana ? 1:0)" #txtNombre />
            </div>
          </app-panel-base>
        </div>
        <div id="cliente-default-tab-6" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">
              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />
            </div>
          </app-panel-base>
        </div>
        <div id="cliente-default-tab-7" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Cobranza">
            <div class="panel-content">
              <div class="d-flex dias-semana">
                <input type="checkbox" (change)="changeCheckboxDiasCobro($event, 1)" class="btn-check" id="lunes"
                  [checked]="cobranza.lunes" autocomplete="off">
                <label class="btn btn-outline-green me-5px" for="lunes">
                  <i class="far {{cobranza.lunes ? 'fa-square-check' : 'fa-square'}}"></i>
                  Lunes
                </label>

                <input type="checkbox" (change)="changeCheckboxDiasCobro($event, 2)" class="btn-check" id="martes"
                  [checked]="cobranza.martes" autocomplete="off">
                <label class="btn btn-outline-green me-5px" for="martes">
                  <i class="far {{cobranza.martes ? 'fa-square-check' : 'fa-square'}}"></i>
                  Martes
                </label>

                <input type="checkbox" (change)="changeCheckboxDiasCobro($event, 3)" class="btn-check" id="miercoles"
                  [checked]="cobranza.miercoles" autocomplete="off">
                <label class="btn btn-outline-green me-5px" for="miercoles">
                  <i class="far {{cobranza.miercoles ? 'fa-square-check' : 'fa-square'}}"></i>
                  Miércoles
                </label>

                <input type="checkbox" (change)="changeCheckboxDiasCobro($event, 4)" class="btn-check" id="jueves"
                  [checked]="cobranza.jueves" autocomplete="off">
                <label class="btn btn-outline-green me-5px" for="jueves">
                  <i class="far {{cobranza.jueves ? 'fa-square-check' : 'fa-square'}}"></i>
                  Jueves
                </label>

                <input type="checkbox" (change)="changeCheckboxDiasCobro($event, 5)" class="btn-check" id="viernes"
                  [checked]="cobranza.viernes" autocomplete="off">
                <label class="btn btn-outline-green me-5px" for="viernes">
                  <i class="far {{cobranza.viernes ? 'fa-square-check' : 'fa-square'}}"></i>
                  Viernes
                </label>

                <input type="checkbox" (change)="changeCheckboxDiasCobro($event, 6)" class="btn-check" id="sabado"
                  [checked]="cobranza.sabado" autocomplete="off">
                <label class="btn btn-outline-green me-5px" for="sabado">
                  <i class="far {{cobranza.sabado ? 'fa-square-check' : 'fa-square'}}"></i>
                  Sábado
                </label>
              </div>
            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
    <ng-template #seleccionarCuenta>
      <div style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <app-cuenta-bancaria-cliente-page [idCliente]="myForm.value.Id" #seleccionarCuenta />
      </div>
    </ng-template>
  </div>

</app-container-base>
