import { Component, EventEmitter, Input, Output, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BarraBotonesService } from './barra-botones.service';


@Component({
  selector: 'barra-botones',
  templateUrl: './barra-botones.component.html',
  styleUrls: ['./barra-botones.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class BarraBotonesComponent {

  mensajeError: string = '';

  @Input()
  public Entidad: string = ''

  @Input()
  public Objeto: any;

  @Output()
  Leer = new EventEmitter<string>();

  @Output()
  Cancelar = new EventEmitter();

  @Output()
  Regresar = new EventEmitter();

  @Output()
  Guardar:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  Nuevo: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  formGroup: FormGroup | undefined;

  @Input()
  fococontrol: ElementRef | undefined;

  constructor(private barraBotonesService: BarraBotonesService,
    private cdr: ChangeDetectorRef) {
  }
  getFieldError(field: string): string {

    if (!this.formGroup?.controls[field]) return '';

    const errors = this.formGroup.controls[field].errors || {};

    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'Este campo es requerido';

        case 'minlength':
          return `Mínimo ${errors['minlength'].requiredLength} caracters.`;
      }
    }

    return '';
  }

  validaError(): string {
    var conerror: boolean = false;
    var errorstring: string = '';
    for (const field in this.formGroup?.controls) {
      if (this.formGroup?.controls[field].errors && !this.formGroup?.controls[field].pristine) {
        conerror = true;
        errorstring = this.getFieldError(field);
        break;
      }
    }
    if (!conerror) {
      errorstring = '';
    }
    return errorstring;

  }



  ngOnInit(): void {
    this.formGroup!.valueChanges.subscribe(() => {
      this.cdr.detectChanges()
      this.validaError();
    });
  }

  onNuevo() {
    this.barraBotonesService.nuevo(this.Entidad).subscribe(
      (nuevo: any) => {
        this.Nuevo.emit(nuevo);
      }
    )
  }

  onCancelar() {
    this.Cancelar.emit();
  }
  onGuardar() {
    this.Objeto = Object.assign(this.Objeto ,this.formGroup?.value);
    this.Guardar.emit(this.Objeto);
  }


  onRegresar() {
    this.Regresar.emit();
  }
}
