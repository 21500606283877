import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })

export class LimitesService {

  GetLowerLimit_Amount(Amount: number, valorUnitario: number, decimalNumberCoin: number): number {
    const pow1012 = Math.pow(10, -12);
    return this.TruncateDecimals(((Amount - (Math.pow(10, -(this.GetNumberdecimal(Amount.toString())) / 2)) + pow1012) * (valorUnitario - (Math.pow(10, -(this.GetNumberdecimal(valorUnitario.toString()))) / 2))), decimalNumberCoin);
  }

  GetUpperLimit_Amount(Amount: number, ValorUnitario: number, decimalNumberCoin: number): number {
    const pow1012 = Math.pow(10, -12);
    return this.RoundUp(((Amount + ((Math.pow(10, -(this.GetNumberdecimal(Amount.toString()))) / 2) - pow1012)) * (ValorUnitario + ((Math.pow(10, -(this.GetNumberdecimal(ValorUnitario.toString()))) / 2) - pow1012))), decimalNumberCoin);
  }

  GetUpperLimit_TraslateAmount(baseAmount: number, decimalNumber: number, tasaCuota: number, coinDecimals: number): number {
    const pow1012 = Math.pow(10, -12);
    const res = (baseAmount + ((Math.pow(10, -(decimalNumber)) / 2) - pow1012)) * tasaCuota;
    return this.RoundUp(res, coinDecimals);
  }
  GetLowerLimit_TraslateAmount(baseAmount: number, baseDecimals: number, tasaCuota: number, coinDecimals: number): number {
    const pow1012 = Math.pow(10, -12);
    const res = (baseAmount - ((Math.pow(10, -(baseDecimals)) / 2) + pow1012)) * tasaCuota;
    return this.TruncateDecimals(res, coinDecimals);
  }

  TruncateDecimals(amount: number, coinDecimals: number): number {
    const pres: number = Math.pow(10, coinDecimals);
    amount = Math.trunc(amount * pres);
    return amount / pres;
  }

  RoundUp(number: number, numDecimalPlaces: number): number {
    const multiplier: number = Math.pow(10, numDecimalPlaces);
    return Math.ceil(number * multiplier) / multiplier;
  }

  GetNumberdecimal(amount: string): number {
    let numbeDecimals: number = 2;
    try {
      numbeDecimals = amount.substring(amount.indexOf(".")).length - 1;
    } catch {
    }
    return numbeDecimals;
  }

}
