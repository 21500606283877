import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { FacturacionService } from '../../services/facturacion.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ModalService } from 'src/app/service/modal.service';
import { Validators } from 'ngx-editor';
import Swal from 'sweetalert2';
import { c_MotivoCancelacion } from '../../interfaces/venta.interface';
import { environment } from 'src/environments/environment';
import { Result } from 'src/app/auth/interfaces';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-opcion-cancelacion-page',
  templateUrl: './opcion-cancelacion-page.component.html',
  styleUrls: ['./opcion-cancelacion-page.component.scss']
})
export class OpcionCancelacionPageComponent implements OnInit {


  @Input()
  idDocto: number = 0;
  @Input()
  uuid: string = '';
  @Input()
  esRep: boolean = false;
  requiresSAT: boolean = false;
  private eventsService = inject(EventsService);
  private readonly baseUrl: string = environment.baseUrlApi;


  public myFormCancel: FormGroup = this.fb.group({
    Fecha: [Date, Validators.required],
    MotivoCancelacion: ['', Validators.required],
    MotivoSAT: [{} as c_MotivoCancelacion],
    UUID: [''],
    EsDevolucionCheque: [false]
  })

  constructor(private fServices: FacturacionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private mService: ModalService) {
  }

  accept() {
    debugger
    if (!this.myFormCancel.value.MotivoCancelacion) {
      this.eventsService.publish('home:showAlert', { message: "Indique el motivo de cancelación.", cancelButton: false });
      return;
    }
    if (this.requiresSAT) {
      if (!this.myFormCancel.value.MotivoSAT) {
        setTimeout(() => { this.eventsService.publish('home:showAlert', { message: "Indique el motivo de cancelación del SAT.", cancelButton: false }); }, 50);
        return;
      }
      if (this.myFormCancel.value.MotivoSAT.Id == 1) {
        if (!this.myFormCancel.value.UUID) {
          setTimeout(() => { this.eventsService.publish('home:showAlert', { message: "Indique el UUID que sustituye.", cancelButton: false }); }, 50);
          return;
        }
      }
    }
    this.mService.closeModal(this.myFormCancel.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      MotivoCancelacion: '',
      MotivoSAT: null
    };
    this.myFormCancel.reset(ent);
    if (!this.esRep) {
      this.getDoctoType();
    } else {
      this.requiresSAT = true;
    }
  }

  onSelectedItem(entity: any) {
    this.myFormCancel.controls["MotivoSAT"].setValue(entity);
    if (entity.Id == 1) {
      this.getRelationsUUID();
    } else {
      const ent = this.myFormCancel.value;
      ent.UUID = "";
      this.myFormCancel.reset(ent);
    }
  }

  getRelationsUUID() {
    const params = new HttpParams().set("uuid", this.uuid);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerUUIDSustituye`, { params }).subscribe((result) => {
      if (result.success) {
        if (result.message.length > 0) {
          const ent = this.myFormCancel.value;
          ent.UUID = result.message;
          this.myFormCancel.reset(ent);
        } else {
          //Swal.fire({ text: "No se encontró un documento que lo sustituya, coloque el UUID manualmente.", icon: 'info', })
        }
      } else {
        Swal.fire({ text: result.message, icon: 'error', })
      }
    });
  }

  getDoctoType() {
    const params = new HttpParams().set("idDocto", this.idDocto);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerTipoComprobante`, { params }).subscribe((result) => {
      if (result.message !== "X" && result.message !== "C") {
        this.requiresSAT = true;
      }
    });
  }

}
