<ng-template #tipSidebar>
  <div style="width: 70px;">Lateral</div>
</ng-template>
<ng-template #tipTopMenu>
  <div style="width: 70px;">Arriba</div>
</ng-template>
<ng-template #tipDark>
  <div style="width: 70px;">Oscuro</div>
</ng-template>
<ng-template #tipLight>
  <div style="width: 70px;">Claro</div>
</ng-template>

<!-- BEGIN #header -->
<div id="header" class="app-header" style="border-bottom:2px solid #fd8204;"
  [attr.data-bs-theme]="this.appSettings.appHeaderInverse ? 'dark' : ''">
  <!-- BEGIN navbar-header -->
  <div class="navbar-header" style="width: auto;">
    <label class="navbar-brand">
      <img class="logo-complit-positivo normal" src="../../../../assets/img/logo/SW-COMPLIT-positivo.png"
        alt="logo.png">
      <img class="logo-complit-positivo corto" src="../../../../assets/img/logo/SW-COMPLIT-CORTO-positivo.png"
        alt="logo.png">
    </label>
    <button type="button" class="navbar-mobile-toggler" (click)="toggleAppTopMenuMobile()"
      *ngIf="this.appSettings.appTopMenu && !this.appSettings.appSidebarNone">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-mobile-toggler" (click)="toggleAppHeaderMegaMenuMobile()"
      *ngIf="this.appSettings.appHeaderMegaMenu">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-mobile-toggler" (click)="toggleAppSidebarMobile()"
      *ngIf="!this.appSettings.appSidebarNone">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <button type="button" class="navbar-mobile-toggler" (click)="toggleAppTopMenuMobile()"
      *ngIf="this.appSettings.appTopMenu && this.appSettings.appSidebarNone">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <!-- END navbar-header -->

  <!-- BEGIN nav -->
  <div class="navbar-nav">
    <div class="navbar-item">
      <button type="button" class="btn btn-info me-10px radius" (click)="onClickTipoCambio()">
        TC: {{tipoCambio|number:'1.2-6'}}
      </button>
    </div>

    <div class="navbar-item">
      <button type="button" class="btn btn-warning me-10px radius" *ngIf="canAccesAcceptCancel"
        (click)="onClickFav({menu:'buzontributario'})">
        {{totalSolicitudes | number}} solicitudes de cancelación
      </button>
    </div>

    <div class="navbar-divider"></div>
    <div class="navbar-item">
      <span class="btn-group float-end ms-10px p-2px bg-black bg-opacity-20 rounded">
        <button class="btn btn-sm btn-white border-0" [ngClass]="{'active': !isToggleTopMenu}"
          (click)="topMenuMode(false)" placement="bottom" [ngbTooltip]="tipSidebar" [openDelay]="100"
          [closeDelay]="100">
          <i class="bi bi-layout-sidebar{{darkMode?'-inset':''}}"></i>
        </button>
        <button class="btn btn-sm btn-white border-0" [ngClass]="{'active': isToggleTopMenu}"
          (click)="topMenuMode(true)" placement="bottom" [ngbTooltip]="tipTopMenu" [openDelay]="100" [closeDelay]="100">
          <label class="i-top-menu bi bi-layout-sidebar{{darkMode?'-inset':''}}"></label>
        </button>
      </span>
    </div>
    <div class="navbat-item">
      <span class="btn-group float-end ms-10px me-10px p-2px bg-black bg-opacity-20 rounded">
        <button class="btn btn-sm btn-white border-0" [ngClass]="{'active': lightMode}" (click)="widgetLightMode()"
          placement="bottom" [ngbTooltip]="tipLight" [openDelay]="100" [closeDelay]="100">
          <i class="fa fa-sun" [ngClass]="{'text-blue': lightMode}"></i>
        </button>
        <button class="btn btn-sm btn-white border-0" [ngClass]="{'active': darkMode}" (click)="widgetDarkMode()"
          placement="bottom" [ngbTooltip]="tipDark" [openDelay]="100" [closeDelay]="100">
          <i class="fa fa-moon" [ngClass]="{'text-blue': darkMode}"></i>
        </button>
      </span>
    </div>

    <div class="navbar-divider"></div>
    <div class="navbar-item dropdown">
      <a href="javascript:;" data-bs-toggle="dropdown" class="navbar-link dropdown-toggle icon">
        <i style="top: 3px" class="fa fa-bell"></i>
        <span class="badge">{{notificaciones.length}}</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-end" style="max-height: 450px; overflow-y: auto;">
        <div *ngIf="notificaciones.length>0" class="dropdown-header">Notificaciones ({{notificaciones.length}})</div>
        <div *ngIf="notificaciones.length==0" class="dropdown-header">No hay nada por hoy</div>
        <a class="dropdown-item media" *ngFor="let item of notificaciones">
          <div class="media-left">
            <i class="fa fa-envelope media-object bg-gray-500"></i>
          </div>
          <div class="media-body">
            <h6 class="media-heading">{{item.emisor!.nombre | titlecase}}</h6>
            <p>{{item.mensaje}}</p>
            <div class="text-muted fs-10px">{{item.fecha}}</div>
          </div>
        </a>
        <div class="dropdown-footer text-center" *ngIf="notificaciones.length>0">
          <a (click)="clearNotification()" class="text-decoration-none link">Borrar todo</a>
        </div>
      </div>
    </div>

    <div class="navbar-item dropdown">
      <a [@heartBeat]="animationState" href="javascript:;" data-bs-toggle="dropdown"
        class="navbar-link dropdown-toggle icon">
        <img class="icon-star" src="../../../assets/img/tabs-fav/star-icon-fav.png" alt="">
        <span class="badge bg-primary">{{favoritos.length}}</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-end favoritos">
        <div class="dropdown-header">Favoritos ({{favoritos.length}})</div>
        <a href="javascript:;" class="dropdown-item media" *ngIf="favoritos.length === 0">
          <div class="media-body" style="text-align: center; padding: 10px;">
            <h6 class="media-heading">Sin Favoritos</h6>
          </div>
        </a>
        <a href="javascript:;" class="dropdown-item media" *ngFor="let fav of favoritos">
          <div class="media-body" style="white-space: normal">
            <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
              <button type="button" style="width: 250px;" (click)="onClickFav(fav)"
                class="btn text-dark btn-outline-secondary text-truncate">
                {{fav.title}}
              </button>
              <button type="button" style="width: 40px;" (click)="eliminarFav(fav)" class="btn btn-outline-danger">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </a>
        <div class="dropdown-footer text-center" *ngIf="favoritos.length > 0">
          <a href="javascript:;" class="text-decoration-none" (click)="limpiarFavoritos()">Limpiar Favoritos</a>
        </div>
      </div>
    </div>

    <div class="navbar-divider"></div>
    <div class="navbar-item navbar-user">
      <a href="javascript:;" class="navbar-link dropdown-toggle d-flex align-items-center">
        <photo-avatar [name]="usuarioLogeado.nombreCompletoUsuario"></photo-avatar>
        <span [ngbTooltip]="usuarioLogeado.nombreCompletoUsuario" class="nombre-usuario">
          <span class="d-none d-md-inline">{{usuarioLogeado.nombreCompletoUsuario | titlecase}}</span>
        </span>
      </a>
    </div>

    <div class="navbar-divider"></div>
    <div class="navbar-item" *ngIf="this.appSidebarTwo && permisosTableros?.verChat">
      <a href="javascript:;" (click)="toggleAppSidebarEnd()" class="navbar-link icon">
        <i class="fa fa-users"></i>
      </a>
    </div>
    <div class="navbar-divider"></div>
    <div class="navbar-item">
      <a href="javascript:;" (click)="logOut()" class="navbar-link icon">
        <i class="fa fa-door-open"></i>
      </a>
    </div>
  </div>
  <!-- END nav -->
</div>
<!-- END #header -->
