import { Component, EventEmitter, Input, Output, forwardRef, OnInit, AfterViewInit, AfterContentInit, DoCheck } from '@angular/core';
import { CheckBoxList } from '../../interfaces/checkboxlist.interface';
import { outputAst } from '@angular/compiler';
import { NG_VALUE_ACCESSOR } from '@angular/forms';







@Component({
  selector: 'component-ui-check-box-list',
  templateUrl: './check-box-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxListComponent),
      multi: true,
    }
  ],
  styleUrls: ['./check-box-list.component.css']
})
export class CheckBoxListComponent implements AfterViewInit, DoCheck {


  firstLoad: boolean = false;

  itemSelected: boolean[] = [];

  @Input()
  public label: string = "Etiqueta";

  @Input()
  public widthTextColumns: number = 8;

  @Input()
  public widthLabelColumns: number = 10;

  @Input()
  listItem: CheckBoxList[] = [];

  @Output()
  onChange: EventEmitter<CheckBoxList> = new EventEmitter();

  @Output()
  onSelectItem: EventEmitter<CheckBoxList> = new EventEmitter();

  @Input()
  public enabled: boolean = true;

  @Input()
  public tabIndex: number = 0;

  @Input()
  value: boolean = false;

  @Input()
  listIndex: number = -1;




  ngDoCheck(): void {
    if (!this.firstLoad && this.listItem) {
      if (this.listIndex >= 0) {
        this.itemSelected = new Array(this.listItem.length).fill(false);
        this.itemSelected[this.listIndex] = true;
      }
      this.firstLoad = true;
    }

  }
  ngAfterViewInit(): void {
    if (this.listItem) {
      this.itemSelected = new Array(this.listItem.length).fill(false);
      this.itemSelected[this.listIndex] = true;
    }

  }



  getHighLight(index: any): string {
    return this.itemSelected[index] ? 'highlight' : '';
  }
  onSelect(index: number) {
    this.itemSelected = new Array(this.listItem.length).fill(false);
    this.itemSelected[index] = true;
    this.onSelectItem.emit(this.listItem[index]);

  }

  getStyleTexto(index: number) {
    return this.itemSelected[index] ? 'undeline' : '';
  }

  getStyle(index: number) {
    return this.itemSelected[index] ? 'bold' : '';
  }

  onclick(item: CheckBoxList) {
    const itemCheck: CheckBoxList = { ...item };
    itemCheck.Value = !itemCheck.Value;

    this.onChange.emit(itemCheck);
  }

}
