
import { NgModule } from '@angular/core';
import { MobileLayoutComponent } from './layout/mobile-layout.component';
import { MobileRoutingModule } from './mobile-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { MatRippleModule } from '@angular/material/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { VisitasComponent } from './pages/visitas/visitas.component';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { NgScrollbar } from 'ngx-scrollbar';
import { ProspectosMobilePageComponent } from './pages/prospectos-mobile-page/prospectos-mobile-page.component';
@NgModule({
  declarations: [
    MobileLayoutComponent,
    HomeComponent,
    CalendarComponent,
    VisitasComponent,
    ProspectosMobilePageComponent,
  ],
  imports: [
    MobileRoutingModule,
    MatRippleModule,
    FullCalendarModule,
    NgbTooltipModule,
    CommonModule,
    ComponentsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    FormsModule,
    NgbDropdownModule,
    DirectivesModule,
    NgScrollbar,
  ],
  exports: [],
  providers: [],
})
export class MobileModule { }
