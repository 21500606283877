import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-ventas',
  templateUrl: './verificador-ventas-page.component.html',
  styles: [
  ]
})
export class VerificadorVentasPageComponent {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private reportsService = inject(ReportsService)


  menuId: string = "verificadordeventas";
  filtrosDiarioVentasPredefinidos: ReportFilter = {} as ReportFilter;

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-Tipo Documento', Orden: 'empClave,sucClave,doc.Clave' }]

  public filtrosDiarioVentas: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificador de ventas',
      NombreExcel: 'Verificador de ventas.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'doc.Clave', Etiqueta: 'Tipo Documento', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "DocumentoVenta" },
        { Campo: 'clie.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'mon.Clave', Etiqueta: 'Moneda', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Moneda" },
        { Campo: 'clasev.Clave', Etiqueta: 'Clase de venta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseVenta" },
        { Campo: 'venalta.Clave', Etiqueta: 'Vendedor alta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'vencliente.Clave', Etiqueta: 'Vendedor Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'vehiculo.Clave', Etiqueta: 'Vehiculo', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Unidad" },
        { Campo: 'chofer.Clave', Etiqueta: 'Chofer', Tipo: TypeFilter.number, Entidad: "Chofer" },
        { Campo: 'ven.Vigente', Etiqueta: 'Vigente', Tipo: TypeFilter.boolean, ListaItems: [{ Nombre: 'Sin selección' }, { Nombre: 'Vigentes' }, { Nombre: 'Cancelados' }] },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private utils: UtilsService) { }

  ngOnInit(): void {
      this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE VENTAS ' + this.rangoFechas);
      this.focus('txtNombreReporte');
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE VENTAS ' + this.rangoFechas);
  }


  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }


  onclicSinAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc3')?.setValue(!value);
    }
  }

  onclicSoloAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  onclicSinFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc4')?.setValue(!value);
    }
  }

  onclicSoloFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc2')?.setValue(!value);
    }
  }


  clickBoton(event: string) {

    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/Ventas/ReporteVerificadorDeVentas');

  }
}
