<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Bloqueo de módulos para evitar capturas en meses y años anteriores al actual">
  <div class="screen-content" style="width: 800px;">
    <form [formGroup]="myForm" autocomplete="off">

      <app-panel-base title="Seleccione los módulos que ya están bloqueados, solo aceptaran movimientos del mes y año actual">
        <div class="panel-content">
          <component-ui-check-box helpLine="Bloquear todos las capturas de contabilidad" [tabIndex]="1"
            [widthLabelColumns]="3" formControlName="Contabilidad" [widthTextColumns]="7"
            [label]="'Contabilidad'" />
          <component-ui-check-box helpLine="Bloquear todos las capturas de compras" [tabIndex]="2"
            [widthLabelColumns]="3" formControlName="Compras" [widthTextColumns]="7"
            [label]="'Compras'" />
          <component-ui-check-box helpLine="Bloquear todos las capturas de Cuentas Por Pagar" [tabIndex]="3"
            [widthLabelColumns]="3" formControlName="CXP" [widthTextColumns]="7"
            [label]="'Cuentas Por Cobrar'" />
          <component-ui-check-box helpLine="Bloquear todos las capturas de Cuentas Por Cobrar" [tabIndex]="4"
            [widthLabelColumns]="3" formControlName="CXC" [widthTextColumns]="7"
            [label]="'Cuentas Por Pagar'" />
          <component-ui-check-box helpLine="Bloquear todos las capturas de Inventarios" [tabIndex]="5"
            [widthLabelColumns]="3" formControlName="Inventario" [widthTextColumns]="7"
            [label]="'Inventarios'" />
          <component-ui-check-box helpLine="Bloquear todos las capturas de Finanzas" [tabIndex]="6"
            [widthLabelColumns]="3" formControlName="Finanzas" [widthTextColumns]="7"
            [label]="'Finanzas'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
