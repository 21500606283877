import { Component, ElementRef, ViewChild, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Departamento, HistoricoServicio, Unidad, cp_ConfigAutotransporte, cp_TipoPermiso } from '../../interfaces/unidad.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Chofer } from '../../interfaces/chofer.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { c_SubTipoRem } from '../../interfaces/cartaporte.interface';

@Component({
  selector: 'app-unidades-page',
  templateUrl: './unidades-page.component.html',
  styles: [
  ]
})
export class UnidadesPageComponent implements OnInit {
  public itemsCombo: ComboBoxEntity[] = [];


  private utilsService = inject(UtilsService)

  info: UserLogged = {} as UserLogged;

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    SucursalPertenece: [{} as Sucursal],
    UtilizarParaEntregas: [false],
    FueraDeServicio: [false],
    Expediente: [''],
    Anio: [0],
    PesoBrutoVehicular: [0],
    CapacidadCarga: [0],
    Marca: [''],
    Color: [''],
    Placas: [''],
    NumSerieBateria: [''],
    Motor: [''],
    Trasmision: [''],
    TipoLlantas: [''],
    Aseguradora: [''],
    NumPoliza: [''],
    CapacidadCombustible: [0],
    CapacidadAceite: [0],
    FechaVencimientoPoliza: [Date],
    TipoPermisoSCT: [{} as cp_TipoPermiso],
    Departamento: [{} as Departamento],
    TipoRemolque: [{} as c_SubTipoRem],
    PlacasRemolque: [''],
    Ayudante: [{} as Chofer],
    Chofer: [{} as Chofer],
    NumPersmisoSCT: [''],
    ConfigAutotransporte: [{} as cp_ConfigAutotransporte],
    HistoricoServicio: [null],
    HistoricoCombustible: [null],
    Neumaticos: [null],
    HistoricoNeumaticos: [null],
    Asientos: [[]],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: Unidad | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService) {


  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  getFiltroChofer() {
    let sucursal: number = 0;
    if (this.myForm.get('SucursalPertenece')?.value) {
      sucursal = this.myForm.get('SucursalPertenece')!.value.Id;
    }
    return "Tipo.Clave != 3 and SucursalPertenece.Id ==" + sucursal;
  }

  getFiltroAyudante() {
    let sucursal: number = 0;
    if (this.myForm.get('SucursalPertenece')?.value) {
      sucursal = this.myForm.get('SucursalPertenece')!.value.Id;
    }
    return "Tipo.Clave == 3 and SucursalPertenece.Id ==" + sucursal;
  }


  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Unidad) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: Unidad): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Unidad").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  selectedItem(ent: any, tipo: string) {
    this.myForm.controls[tipo].setValue(ent);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
