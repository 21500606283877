<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="CondicionPago"
  title="Condiciones de Pago" icon="fa fa-angles-down" subTitle="Administra la configuración de Condiciones de Pago">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Condición de Pago'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="CondicionPago" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
        </div>
      </app-panel-base>
      <app-panel-base title="Configuración">
        <div class="panel-content row">
          <component-ui-combo-box [label]="'Tipo'" formControlName="Tipo" [tabIndex]="3" [zeroMask]="2"
            entityName="FormaVenta" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            filterQuery='Clave != 3' (onSelectedItem)="selectedTipo($event)" [listProperty]="'ClaveNombre'" />

            <components-ui-number-box [maxLength]="4" [label]="'Días'" formControlName="Dias"
            [tabIndex]="4" [widthTextColumns]="1" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>



    </form>
  </div>
</app-container-base>
