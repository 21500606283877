<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Opciones de cancelación </div>
  <div class="card-body bg-light">
    <app-panel-base title="Cancelar documento">
      <div class="panel-content">
        <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
          [hasCounter]="false">
          <div class="screen-content" style="overflow-x: hidden;">
            <form [formGroup]="myFormCancel" autocomplete="off">
              <components-ui-date-box [tabIndex]="1" [enabled]="false" formControlName="Fecha" label="Fecha"
                [widthLabelColumns]="3" [widthTextColumns]="3" />
              <components-ui-text-box [tabIndex]="2" formControlName="MotivoCancelacion" [maxlength]="2000"
                label="Motivo" [widthLabelColumns]="3" [widthTextColumns]="9" />
              <component-ui-combo-box [tabIndex]="3" (onSelectedItem)="onSelectedItem($event)" [enabled]="requiresSAT"
                formControlName="MotivoSAT" label="Motivo SAT" entityName="c_MotivoCancelacion" [widthLabelColumns]="3"
                [widthTextColumns]="9" [widthRightColumns]="0" />
              <components-ui-text-box [tabIndex]="4" *ngIf="myFormCancel.value.MotivoSAT?.Id == 1" formControlName="UUID"
                [maxlength]="2000" label="Sustituye" [widthLabelColumns]="3" [widthTextColumns]="9" />

              <component-ui-check-box *ngIf="esRep" [label]="'¿Es Devolución de un Cheque?'"
                formControlName="EsDevolucionCheque" [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="8" />

              <div style="text-align: right; margin-top: 5px;">
                <div (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</div>
                <div (click)="closeModal()" class="btn btn-danger">Cancelar</div>
              </div>
            </form>
          </div>
        </app-container-base>
      </div>
    </app-panel-base>
  </div>
</div>
