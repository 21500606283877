import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColorEvent } from 'ngx-color';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  templateUrl: './costo-ventas-page.component.html',
  styleUrls: ['./costo-ventas-page.component.scss']
})
export class CostoVentasPageComponent {
  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  menuId: string = "costo-ventas";
  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public frmCostoVentas: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: ['COSTO DE VENTAS', Validators.required],
    Orden: []
  });

  ngOnInit() {
    setTimeout(() => {
      this.focus();
    }, 200);
  }

  focus() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

  public filtrosCostoVentas: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'COSTO DE VENTAS'
      } as ReportHeader,
      OrdenadoLista: [
        { Nombre: "Familia", Orden: "fam.ClaveGeneral" },
        { Nombre: "Clasificación", Orden: "class.Clave" },
        { Nombre: "Serie/Folio", Orden: "pc.SerieFolioOrigina" }
      ],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'COSTO DE VENTAS',
      TituloVisor: 'COSTO DE VENTAS',
      NombreExcel: 'CostoVentas.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'alm.Clave', Etiqueta: 'Almacén', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Almacen" },
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'concepto.Clave', Etiqueta: 'Concepto de almacen', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoAlmacen" },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily },
        { Campo: 'prov.Clave', Etiqueta: 'Porcentajes', Tipo: TypeFilter.colorsPercent },
        {
          Campo: 'pc.EraCostoCero', Etiqueta: 'Solo mostrar con costo cero', Tipo: TypeFilter.boolean,
          ListaItems: [{ Nombre: "Sin Seleccion" }, { Nombre: "Si" }, { Nombre: "No" }]
        },
        { Campo: 'pc.SerieOrigina', Etiqueta: 'Serie Factura', Tipo: TypeFilter.text },
        { Campo: 'pc.FolioOrigina', Etiqueta: 'Folio Factura', Tipo: TypeFilter.number },
        { Campo: 'prov.Clave', Etiqueta: 'Vendedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },

      ]
    };

  changeselec(event: any) {
    this.filtrosCostoVentas = event;
  }

  imprimir(event: any) {
    this.filtrosCostoVentas.ReportHeader = this.frmCostoVentas.value;
    this.reportsService.printReport(this.filtrosCostoVentas, '/Inventarios/ReporteCostoVentas');
  }


}
