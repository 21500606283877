import { NgModule } from '@angular/core';
import { TokenPreciosPageComponent } from './pages/token-precios/token-precios.page.component';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { ComponentsModule } from '../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TokenCreditoPageComponent } from './pages/token-credito/token-credito.page.component';
import { AuditoriaTokensPageComponent } from './pages/auditoria-tokens/auditoria-tokens.page.component';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    DateBoxComponent
  ],
  exports: [],
  declarations: [TokenPreciosPageComponent, TokenCreditoPageComponent, AuditoriaTokensPageComponent],
  providers: [],
})
export class TokensModule { }
