import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';


import { Editor } from 'ngx-editor';
import * as Tagify from '@yaireo/tagify';
import { AppSettings } from '../../../services/app-settings.service';

@Component({
  selector: 'email-compose',
  templateUrl: './email-compose.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./email-compose.css']
})

export class EmailComposePage implements OnInit, OnDestroy {
  editor: Editor = new Editor;
  html: string = '';
  subject: string = '';
  cc: boolean = false;
  bcc: boolean = false;

  showCc() {
    this.cc = true;

    var tagsElm = [].slice.call(document.querySelectorAll('[data-render="cctags"]'));

    tagsElm.map(function(tagElm) {
    	new Tagify(tagElm);
    });

    // var inputElement = document.querySelector('[data-render="cctags"]');
    // new Tagify(inputElement);
  }

  showBcc() {
    this.bcc = true;

    var tagsElm = [].slice.call(document.querySelectorAll('[data-render="bcctags"]'));

    tagsElm.map(function(tagElm) {
    	new Tagify(tagElm);
    });

    // var inputElement = document.querySelector('[data-render="bcctags"]');
    // new Tagify(inputElement);
  }

  onTagsChanged(value: any) { }

  constructor(public appSettings: AppSettings) {
  }

  ngOnInit() {
    this.editor = new Editor();
    this.cc = false;
    this.bcc = false;

    var tagsElm = [].slice.call(document.querySelectorAll('[data-render="tags"]'));

    tagsElm.map(function(tagElm) {
    	new Tagify(tagElm);
    });

    // var inputElement = document.querySelector('[data-render="tags"]');
    // new Tagify(inputElement);

    this.appSettings.appContentFullHeight = true;
    this.appSettings.appContentClass = 'p-0';
  }

  ngOnDestroy() {
    this.appSettings.appContentFullHeight = false;
    this.appSettings.appContentClass = '';

    this.editor.destroy();
  }
}
