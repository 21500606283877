import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { environment } from 'src/environments/environment';
import { ActivoFijo, ActivoFijoDocumento } from '../../interfaces/activofijo.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { CuentaContable } from '../../interfaces/cuentacontable.interface';
import { HttpClient } from '@angular/common/http';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { CentroCostoService } from '../../services/centrocosto.service';
import { UtilsService } from 'src/app/service/utils.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActivoFijoService } from '../../services/activofijo.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-activo-fijo-page',
  templateUrl: './activo-fijo-page.component.html',
  styles: [
  ]
})
export class ActivoFijoPageComponent {
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('seleccionarCuenta')
  public ctrlseleccionarCuenta!: ElementRef<HTMLElement>;

  info: any;
  file: any;
  longClave: number = 0;
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  selectedTree: any = null;
  entityAfterSave: any = null;
  reload: boolean = false;
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    id: [0],
    clave: [0],
    nombre: [''],
    abreviacion: [''],
    numeroNivel: [0],
    orden: [0],
    claveGeneral: [''],

    cuentaCargo: [{} as CuentaContable],
    cuentaAbono: [{} as CuentaContable],
    calcularAl: [Date],
    fechaAdquisicion: [Date],
    fechaBaja: [Date],
    polizaAdquisicion: [''],
    polizaBaja: [''],
    fechaPolizaAdquisicion: [Date],
    fechaPolizaBaja: [Date],
    valorDeducible: [0],
    valorNoDeducible: [0],
    porcentajeAnualDepreciacion: [0],
    aniosVidaUtil: [0],
    valorTotal: [0],
    deduccionInmediata: [0],
    depreciacionAcumuladaAnioAnterior: [0],
    valorReposicion: [0],
    autoriza: [''],
    factura: [''],
    proveedor: [''],
    area: [''],
    documentos: [[] as ActivoFijoDocumento[]],
    empresa: [{} as Empresa],
    padre: [{} as ActivoFijo],
    eliminado: [false],
    baja: [false],
  });

  private reportsService = inject(ReportsService);
  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private activoFijoService: ActivoFijoService,
    private ms: ModalService,
    private utileService: UtilsService) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.myForm.reset({});
  }

  get getChargeName(): string {

    if (this.myForm.value.cuentaCargo) {
      if (this.myForm.value.cuentaCargo.Id > 0 || this.myForm.value.cuentaCargo.id) {
        if (this.myForm.value.cuentaCargo.Nombre) return `${this.myForm.value.cuentaCargo.ClaveGeneral} ${this.myForm.value.cuentaCargo.Nombre}`;
        if (this.myForm.value.cuentaCargo.nombre) return `${this.myForm.value.cuentaCargo.claveGeneral} ${this.myForm.value.cuentaCargo.nombre}`;
      }
    }

    return "No ha seleccionado una Cuenta de Cargo";
  }

  get getAbbName(): string {

    if (this.myForm.value.cuentaAbono) {
      if (this.myForm.value.cuentaAbono.Id > 0 || this.myForm.value.cuentaAbono.id) {
        if (this.myForm.value.cuentaAbono.Nombre) return `${this.myForm.value.cuentaAbono.ClaveGeneral} ${this.myForm.value.cuentaAbono.Nombre}`;
        if (this.myForm.value.cuentaAbono.nombre) return `${this.myForm.value.cuentaAbono.claveGeneral} ${this.myForm.value.cuentaAbono.nombre}`;
      }
    }

    return "No ha seleccionado una Cuenta de Abono";
  }

  abrirSelecionarCuenta(type: string) {
    const b: any = this.ctrlseleccionarCuenta;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls[type].setValue(e);
      }
    }, 'widthfamilia')
  }

  selectedObjeto(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

  onLoad() {
    this.reload = false;
    this.entityAfterSave = null;
  }

  onDelete() {
    this.reload = true;
    this.entityAfterSave = null;
  }

  get getReload(): boolean {
    return this.reload;
  }

  get getEntityAfterSave(): ActivoFijo {
    return this.entityAfterSave;
  }

  addSubLevel() {
    let level = this.selectedTree.numeroNivel + 1;
    let idPadre = this.selectedTree.id;
    this.activoFijoService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.activoFijoService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("ActivoFijo").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = level;
          elem.padre = { id: idPadre };
          this.myForm.reset(elem);
          setTimeout(() => {
            this.focus("txtNombre")
          }, 150);
        })
      })
    });

  }


  addLevel() {
    let level = 1;
    let idPadre = 0;
    if (this.selectedTree) {
      level = this.selectedTree.numeroNivel;
    }

    this.activoFijoService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.activoFijoService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("CentroCosto").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = 1;
          this.myForm.reset(elem);
          this.focus("txtNombre")
        })
      })
    })
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      if (txt?.tagInput) {
        txt.tagInput.nativeElement.focus()
      }
    }, 200);
  }

  get showAddFamily(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.padre) {
        return false;
      }
    }
    return true;
  }

  get showAddSubFam(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.numeroNivel <= 3) {
        return true;
      }
    }
    return false;
  }


  onSelectedItem(item: ActivoFijo) {
    this.selectedTree = { ...item };
    this.myForm.reset(item);
    this.focus("");
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    if (!ent) { param.callback(false); return; }
    if (ent.clave == 0) { param.callback(false); return; }

    if (!ent.valorDeducible) { this.myForm.controls["valorDeducible"].setValue(0); }
    if (!ent.valorNoDeducible) { this.myForm.controls["valorNoDeducible"].setValue(0); }
    if (!ent.aniosVidaUtil) { this.myForm.controls["aniosVidaUtil"].setValue(0); }
    if (!ent.valorTotal) { this.myForm.controls["valorTotal"].setValue(0); }
    if (!ent.deduccionInmediata) { this.myForm.controls["deduccionInmediata"].setValue(0); }
    if (!ent.depreciacionAcumuladaAnioAnterior) { this.myForm.controls["depreciacionAcumuladaAnioAnterior"].setValue(0); }
    if (!ent.valorReposicion) { this.myForm.controls["valorReposicion"].setValue(0); }
    if (!ent.porcentajeAnualDepreciacion) { this.myForm.controls["porcentajeAnualDepreciacion"].setValue(0); }
    param.callback(true);
  }

  onAfterSave(entity: ActivoFijo) {
    this.myForm.reset(entity);
    this.selectedTree = null;
    this.entityAfterSave = entity;
    this.reload = true;
  }

  public filtrosCuentasContables: ReportFilter = {
    ReportHeader: {
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Activos Fijos'
    } as ReportHeader,
    NombreReporte: 'Activos Fijos',
    TituloVisor: 'Activos Fijos',
    NombreExcel: 'ActivosFijos.xlsx',
  };
  imprimir(event: any) {
    if (event === "print") {
      this.reportsService.printReport(this.filtrosCuentasContables, '/Contabilidad/ReporteActivosFijos');
    }
  }
}
