<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="TipoMovimientoCXC" title="Tipos de movimientos de cxc" icon="fa fa-pen-ruler"
  subTitle="Administra los Tipos de movimientos de cxc para identificar o clasificar.">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Tipo'"
            entityName="TipoMovimientoCXC" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="100" />

          <components-ui-text-box [label]="'Abreviacion'" [required]="true" formControlName="Abreviacion" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="2" />

          <component-ui-check-box label='Baja' [widthLabelColumns]="2" formControlName="Baja" [widthTextColumns]="6"
            [tabIndex]="4" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
