import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from '../../services/facturacion.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Unidad } from '../../interfaces/unidad.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Chofer } from '../../interfaces/chofer.interface';
import { OrdenCargaConcepto } from '../../interfaces/ordencarga.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { EventsService } from 'src/app/service/events.service';
import Swal from 'sweetalert2';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { c_Unidad } from '../../interfaces/venta.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

interface AccordionOrden {
  Serie: string;
  Folio: number;
  Detalle: AccordionOrdenDetail[]
}

interface AccordionOrdenDetail {
  Clave: string;
  Descripcion: string;
  Unidad: any,
  Cantidad: number;
  TextoConversiones: string;
}

@Component({
  selector: 'app-ordenes-carga-page',
  templateUrl: './ordenes-carga-page.component.html',
  styles: [
  ]
})

export class OrdenesCargaPageComponent implements OnInit {
  @ViewChild('txtFolio')
  public txtFolio!: ElementRef<any>;
  info: UserLogged = {} as UserLogged;
  private eventsService = inject(EventsService);
  public GuiColumnAlign = GuiColumnAlign;
  listadoDocumentos: AccordionOrden[] = [];
  indexEditing:number = -1;
  public filtroOrdenCarga: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      TituloVisor: 'Impresión de Orden de Carga',
      Ordenado: 'det.IdVenta,det.IdTraspaso,det.IdRemision',
      NombreReporte: '',
      NombreExcel: '',
      FilterOptions: [
        { Campo: 'Ord.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  private newEntityObj = {
    id: [0],
    empresa: [{} as Empresa],
    sucursal: [{} as Sucursal],
    serie: [''],
    folio: [0],
    unidad: [{} as Unidad],
    odometroSalida: [0],
    odometroEntrada: [0],
    capacidadCarga: [0],
    cargado: [0],
    disponible: [0],
    usuarioElabora: [{} as Usuario],
    usuarioCancela: [{} as Usuario],
    chofer: [{} as Chofer],
    ayudante: [{} as Chofer],
    ayudante2: [{} as Chofer],
    ayudante3: [{} as Chofer],
    ayudante4: [{} as Chofer],
    odometro: [0],
    fechaEmision: [Date],
    fechaCancelacion: [null],
    enCarga: [false],
    entregado: [false],
    conceptos: [[] as OrdenCargaConcepto[]],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  constructor(private fb: FormBuilder,
    private cService: ContainerBaseService,
    private reportsService: ReportsService,
    private utileService: UtilsService,
    private fService: FacturacionService,
  ) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.focusReceptor();
  }

  enterFolio() {
    this.listadoDocumentos = [];
    this.eventsService.publish('home:isLoading', { isLoading: true });
    setTimeout(() => {
      this.fService.getOrdenCargaPorFolio(this.info.sucursal!.numero, this.myForm.value.folio).subscribe((Orden) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (Orden) {
          Orden.conceptos?.forEach((item:any) => {
            const exist = this.listadoDocumentos.filter(P => P.Serie == item.serie && P.Folio == item.folio).length > 0;
            if (!exist) {
              this.listadoDocumentos = [...this.listadoDocumentos, {
                Serie: item.serie,
                Folio: item.folio,
                Detalle: []
              }];
            }
          })

          this.listadoDocumentos.map((idoc) => {
            Orden.conceptos?.forEach((item: any) => {
              if (idoc.Serie == item.serie && idoc.Folio == item.folio) {
                idoc.Detalle = [...idoc.Detalle, {
                  Clave: item.producto.clave,
                  Descripcion: item.producto.nombre,
                  Unidad: item.unidadMedida,
                  Cantidad: item.cantidad,
                  TextoConversiones: item.textoConversiones,
                }]
              }
            });
            return idoc;
          })

          console.log(this.listadoDocumentos)
          this.myForm.reset(Orden);
        } else {
          Swal.fire({ text: "No se encontró la orden de carga indicada, verifique", icon: 'error', })
        }
      })
    }, 150);
  }

  imprimirOrden() {
    let reportHeader: ReportHeader = {
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      Dato1: ''
    }
    let filtro: FilterOptions | undefined = this.filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'Ord.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.value.id);
    }
    this.filtroOrdenCarga.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroOrdenCarga, '/Ventas/ImprimirOrdenCarga');
  }

  imprimirSalida(){

    let reportHeader: ReportHeader = {
      NombreReporte: '',
      Dato1: ''
    }
    let filtro: FilterOptions | undefined = this.filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'Ord.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.value.id);
    }
    this.filtroOrdenCarga.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroOrdenCarga, '/Ventas/ImprimirFormatoOrdenCarga');

  }


  focusReceptor() {
    setTimeout(() => {
      const txt: any = this.txtFolio;
      txt.tagInput.nativeElement.focus()
    }, 150);
  }

}
