<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="TipoProducto" title="Tipos de Productos" icon="fa fa-box-tissue"
  subTitle="Tipos de Productos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Tipo'" entityName="TipoProducto" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="100" />

        </div>
      </app-panel-base>
      <app-panel-base title="Asientos contables por póliza a generar">
        <div class="panel-content">
          <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
