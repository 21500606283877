import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy, OnInit, inject, SimpleChanges, ElementRef } from '@angular/core';
import { AppSettings } from '../../home/services/app-settings.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AppMenuService } from 'src/app/home/services/app-menus.service';
import { heartBeatAnimation } from 'angular-animations';

import { EventsService } from 'src/app/service/events.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { Favoritos } from '../interfaces/favoritos.interface';
import { PermisosTableros } from 'src/app/configuracion/interfaces/rol.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import * as moment from 'moment';
import { Notificacion } from 'src/app/sistema/interfaces/notificacion.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

declare var slideToggle: any;

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [heartBeatAnimation()]
})
export class HeaderComponent implements OnDestroy, OnInit {
  private authService = inject(AuthService);
  private menuService = inject(AppMenuService);
  private eventsService = inject(EventsService);
  private utilsService = inject(UtilsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  animationState = true;
  lightMode: boolean = true;
  darkMode: boolean = false;
  isToggleTopMenu: boolean = false;
  canAccesAcceptCancel: boolean = false;
  totalSolicitudes: number = 0;
  tipoCambio: number = 0;

  @Input() numeroPaginas: number = 0;
  @Input() permisosTableros!: PermisosTableros;
  @Input() favoritos: Favoritos[] = [];
  @Input() notificaciones: any[] = [];
  @Input() appSidebarTwo: boolean = false;
  @Input()
  set recargarTipoCambio(recargar: boolean) {
    if (recargar) {
      this.getTipoCambio();
    }
  }
  @Input()
  set recargarBuzon(recargar: boolean) {
    if (recargar) {
      this.getBuzonFiscalAcceso();
    }
  }
  @Input()
  set recargarNotificaciones(recargar: boolean) {
    if (recargar) {
      this.getNotificaciones();
    }
  }
  @Output() favoritosChange = new EventEmitter<any>();
  @Output() openPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() appSidebarEndToggled = new EventEmitter<boolean>();
  @Output() appSidebarMobileToggled = new EventEmitter<boolean>();
  @Output() appSidebarEndMobileToggled = new EventEmitter<boolean>();
  @Output() appTopMenuToggle = new EventEmitter<boolean>();
  @Output() onLogout = new EventEmitter<boolean>();

  hostDomain: string = window.location.host;
  usuarioLogeado!: UserLogged;
  toggleAppSidebarMobile() {
    this.appSidebarMobileToggled.emit(true);
  }

  toggleAppSidebarEnd() {
    this.appSidebarEndToggled.emit(true);
  }

  toggleAppSidebarEndMobile() {
    this.appSidebarEndMobileToggled.emit(true);
  }

  toggleAppTopMenuMobile() {
    var target = document.querySelector('.app-top-menu');
    if (target) {
      slideToggle(target);
    }
  }

  toggleAppHeaderMegaMenuMobile() {
    this.appSettings.appHeaderMegaMenuMobileToggled = !this.appSettings.appHeaderMegaMenuMobileToggled;
  }

  ngOnDestroy() {
    this.appSettings.appHeaderMegaMenuMobileToggled = false;
  }

  clearNotification() {

    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de eliminar todas las notificaciones?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.authService.clearNotifications(this.usuarioLogeado.numero).subscribe((lista) => {
            this.getNotificaciones();
          });
        }
      }
    });


  }

  widgetLightMode() {
    this.lightMode = true;
    this.darkMode = false;
    if (localStorage) { localStorage['appDarkMode'] = false; }
    this.appSettings.appDarkMode = false;
    document.documentElement.removeAttribute('data-bs-theme');
  }

  topMenuMode(isTop: boolean) {
    this.isToggleTopMenu = isTop;
    localStorage.setItem("isTop", isTop.toString());
    this.appTopMenuToggle.emit(isTop);
    this.eventsService.publish("container-base:height");
  }

  widgetDarkMode() {
    this.darkMode = true;
    this.lightMode = false;
    this.appSettings.appDarkMode = true;
    if (localStorage) { localStorage['appDarkMode'] = true; }
    document.documentElement.setAttribute('data-bs-theme', 'dark');
  }

  constructor(public appSettings: AppSettings, private http: HttpClient, private fs: FacturacionService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["favoritos"]) {
      this.animationState = false;
      setTimeout(() => {
        this.animationState = true;
      }, 100);
    }
  }

  countries: any[] | undefined;

  selectedCountry: any | undefined;

  ngOnInit(): void {
    this.usuarioLogeado = this.utilsService.getUserLogged();
    this.isToggleTopMenu = localStorage.getItem("isTop")?.toLocaleLowerCase() === "true";
    this.getBuzonFiscalAcceso();
    this.getTipoCambio();
    this.getNotificaciones();
  }

  getNotificaciones() {

    this.authService.getNotifications(this.usuarioLogeado.numero).subscribe((lista) => {
      this.notificaciones = lista;
    });
  }

  onClickTipoCambio() {
    const params = new HttpParams().set("idPantalla", 32500);
    this.http.get<Result>(`${this.baseUrl}/Config/TieneAccesoPantalla`, { params }).subscribe((result) => {
      if (result.success) {
        let fav = { menu: 'tipocambio' };
        let item = { url: fav.menu, title: '' };
        this.openPage.emit(item);
      }
    });
  }

  getTipoCambio() {
    const strFecha = moment(new Date()).format("DD/MM/YYYY");
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.ObtenerTipoDeCambio(0, strFecha).subscribe((result) => {
      this.eventsService.publish("home:isLoading", { isLoading: false });
      if (result.success) {
        if (result.message) {
          const ent = JSON.parse(result.message);
          this.tipoCambio = ent.TipoCambio;
        } else {
          this.tipoCambio = 0;
        }
      }
    })
  }

  getBuzonFiscalAcceso() {
    const params = new HttpParams().set("idPantalla", 110004);
    this.http.get<Result>(`${this.baseUrl}/Config/TieneAccesoPantalla`, { params }).subscribe((result) => {
      if (result.success) { this.getCancelaciones(); }
    });
  }

  getCancelaciones() {
    this.http.get<DescargaCfdi[]>(`${this.baseUrl}/Fiscal/ObtenerSolicitudesCancelacion`).subscribe((result) => {
      this.canAccesAcceptCancel = result.length > 0;
      this.totalSolicitudes = result.length;
      setTimeout(() => { this.getCancelaciones(); }, 600000);
    });
  }

  logOut() {
    this.onLogout.emit(true);
  }

  onClickFav(fav: any) {
    let item = { url: fav.menu, title: fav.title };
    this.openPage.emit(item);
  }

  eliminarFav(tab: any) {
    this.eventsService.publish("home:isLoading", { isLoading: true });
    let nombreUsuario = this.usuarioLogeado.nombreUsuario;
    this.menuService.guardarMenuFavorito(nombreUsuario, tab.menu, false).subscribe(resp => {
      this.favoritos = resp.map((p: any) => {
        let itemMenu = this.menuService.getComponentPages().find(x => x.url === p.menu);
        p = { ...p, title: itemMenu?.title }
        return p;
      });
      this.favoritosChange.emit(this.favoritos);
      this.eventsService.publish("home:isLoading", { isLoading: false });
    });
  }

  limpiarFavoritos() {
    this.eventsService.publish("home:isLoading", { isLoading: true });
    this.menuService.limpiarFavs(this.favoritos).subscribe(res => {
      this.favoritos = res.success ? [] : this.favoritos;
      this.favoritosChange.emit(this.favoritos);
      let title = res.success ? "Correcto" : "Error";
      this.eventsService.publish("home:isLoading", { isLoading: false });
      this.eventsService.publish('home:showAlert', { message: res.message, cancelButton: false });
    });
  }
}
