<div class="p-10px">
  <div class="row row-cols-1 row-cols-md-2 ">
    <div class="col">
      <div class="widget widget-stats bg-blue mb-7px" matRipple routerLink="./calendar">
        <div class="stats-icon stats-icon-lg"><i class="far fa-calendar-days fa-fw"></i></div>
        <div class="stats-content">
          <div class="stats-title">Consulta de actividades registradas.</div>
          <div class="stats-number">Calendario</div>
          <div class="stats-progress progress">
            <div class="progress-bar" style="width: 0;"></div>
          </div>
          <div class="stats-desc">10 actividades registradas para el día de hoy.</div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="widget widget-stats bg-red-600 mb-7px" matRipple routerLink="./visitas">
        <div class="stats-icon stats-icon-lg"><i class="fa fa-house-user fa-fw"></i></div>
        <div class="stats-content">
          <div class="stats-title">Visitar a los clientes para posibles ventas.</div>
          <div class="stats-number">Visitas</div>
          <div class="stats-progress progress">
            <div class="progress-bar" style="width: 0;"></div>
          </div>
          <div class="stats-desc">1 visita realizada en el día.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-md-2 ">
    <div class="col">
      <div class="widget widget-stats bg-orange-600 mb-7px" matRipple routerLink="./prospectos">
        <div class="stats-icon stats-icon-lg"><i class="fa fa-user-plus fa-fw"></i></div>
        <div class="stats-content">
          <div class="stats-title">Dar de alta a posible cliente para la venta de matrerial.</div>
          <div class="stats-number">Alta de Prospectos</div>
          <div class="stats-progress progress">
            <div class="progress-bar" style="width: 0%;"></div>
          </div>
          <div class="stats-desc">3 prospectos registrados en el día.</div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="widget widget-stats bg-green mb-7px" matRipple>
        <div class="stats-icon stats-icon-lg"><i class="fa fa-clipboard-check fa-fw"></i></div>
        <div class="stats-content">
          <div class="stats-title">Verificar clientes que cerraron compra de material.</div>
          <div class="stats-number">Cumplimiento de Prospectación</div>
          <div class="stats-progress progress">
            <div class="progress-bar" style="width: 0%;"></div>
          </div>
          <div class="stats-desc">5 prospectos cerrados.</div>
        </div>
      </div>
    </div>
  </div>
</div>
