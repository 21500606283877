<app-container-base title="Visitas" icon="fa fa-user-check" [hasCounter]="false" [hasBar]="false" [isMobile]="true"
  subTitle="Registro de visitas.">
  <div class="screen-content mb-30px">
    <form [formGroup]="visitaForm" class="visita-frm">
      <app-panel-base title="{{visita.id === 0 ? 'Fecha y hora actual': 'Tiempo transcurrido de la visita'}}">
        <div class="panel-content">
          <div class="card text-center border-0">
            <div class="card-body p-0">
              <h4 class="card-title mb-0" id="clock" *ngIf="visita.id === 0">Fecha y Hora</h4>
              <h4 class="card-title mb-0" id="tiempo-transcurrido" *ngIf="visita.id > 0">Tiempo transcurrido</h4>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Información de la visita">
        <div class="panel-content">
          <div class="tab-pane fade active show" role="tabpanel">

            <div class="row">
              <div class="card text-center border-0">
                <div class="card-body p-0">
                  <p class="card-text mb-5px fs-18px">{{visita.id === 0 ? 'Visitar': 'Visitando'}} a:</p>
                  <h1 class="card-title">{{visitado.nombre}}</h1>
                  <h4 class="fst-italic">{{visitado.rfc}}</h4>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="visita.id === 0">
              <div class="col d-grid mt-3">
                <button type="button" class="btn btn-outline-warning" (click)="openSearch('Prospecto')">
                  Seleccionar Prospecto
                </button>
              </div>
              <div class="col d-grid mt-3">
                <button type="button" class="btn btn-outline-warning" (click)="openSearch('Cliente')">
                  Seleccionar Cliente
                </button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <label class="form-label">Asunto</label>
                <input formControlName="asunto" type="text"
                  class="form-control {{visitaForm.controls['asunto'].getError('required') && visitaForm.controls['asunto'].touched ? 'is-invalid':''}}" />
              </div>
            </div>
            <div class="mb-1 mt-1" *ngIf="visita.id > 0">
              <label for="coments" class="form-label">Comentarios</label>
              <textarea formControlName="comentarios" class="form-control comentarios-visita" id="coments"
                rows="3"></textarea>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Próxima Visita" *ngIf="visita.id > 0">
        <div class="panel-content">
          <div class="row">
            <div class="col">
              <label class="form-label">Fecha</label>
              <div class="input-group">
                <input formControlName="fechaProximaVisita" readonly type="text"
                  class="form-control {{visitaForm.controls['fechaProximaVisita'].getError('required') && visitaForm.controls['fechaProximaVisita'].touched ? 'is-invalid':''}}"
                  (click)="d.toggle()" (dateSelect)="onDateSelect($event)" placeholder="DD/MM/YYYY" name="dp"
                  ngbDatepicker #d="ngbDatepicker" />
                <button class="btn btn-outline-warning btn-calendar" (click)="d.toggle()" type="button">
                  <i class="far fa-calendar-days"></i>
                </button>
              </div>
            </div>
            <div class="col">
              <label class="form-label">Hora</label>
              <div class="input-group">
                <div ngbDropdown class="d-inline-block w-100">
                  <button [disabled]="visitaForm.controls['fechaProximaVisita'].getError('required')" type="button"
                    class="btn btn-hora w-100 text-start" id="dropDownTime" ngbDropdownToggle>
                    {{time}}
                  </button>
                  <div class="w-100 p-0" ngbDropdownMenu aria-labelledby="dropDownTime">
                    <div class="center-content text-center">
                      <div class="container-timepicker">
                        <div class="row row-cols-3">
                          <div class="col">
                            <button type="button" class="btn btn-outline-primary btn-time w-100"
                              (click)="sumarValor(true)">
                              <i class="fas fa-angle-up"></i>
                            </button>
                          </div>
                          <div class="col">
                            <button type="button" class="btn btn-outline-primary btn-time w-100"
                              (click)="sumarValor(false)">
                              <i class="fas fa-angle-up"></i>
                            </button>
                          </div>
                          <div class="col"></div>
                          <div class="col">
                            <input maxlength="2" value="{{hours}}" (focus)="focusInput($event)" #hoursInput
                              (blur)="blurHour($event)" numbersOnly class="form-control text-center" type="text"
                              name="hour" id="hour">
                          </div>
                          <div class="col">
                            <input maxlength="2" value="{{minutes}}" (focus)="focusInput($event)" #minutesInput
                              (blur)="blurMinute($event)" numbersOnly class="form-control text-center" type="text"
                              name="minute" id="minute">
                          </div>
                          <div class="col">
                            <button (click)="cambioMeridian()" type="button" class="btn btn-outline-primary w-100">
                              {{meridian}}
                            </button>
                          </div>
                          <div class="col">
                            <button type="button" class="btn btn-outline-primary btn-time w-100"
                              (click)="restarValor(true)">
                              <i class="fas fa-angle-down"></i>
                            </button>
                          </div>
                          <div class="col">
                            <button type="button" class="btn btn-outline-primary btn-time w-100"
                              (click)="restarValor(false)">
                              <i class="fas fa-angle-down"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Evidencias" *ngIf="visita.id > 0">
        <div class="panel-content content-evidencias">
          <div class="row">
            <div class="col">
              <label for="selectFiles" class="btn btn-primary w-100">
                <i class="far fa-folder-open"></i>
                Seleccionar</label>
            </div>
            <div class="col">
              <div class="card text-center mb-3">
                <div class="btn-group">
                  <button type="button" class="btn btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="fas fa-camera"></i>
                    Capturar
                  </button>
                  <ul class="dropdown-menu w-100">
                    <li>
                      <label class="dropdown-item fs-15px" for="capturePhotoFiles">
                        <i class="fas fa-image"></i>
                        Foto
                      </label>
                    </li>
                    <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li>
                      <label class="dropdown-item fs-15px" for="captureVideoFiles">
                        <i class="fas fa-video"></i>
                        Video
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="accordion">
            <div class="accordion-item border-0">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button bg-white-100 text-silver-900 px-3 py-10px pointer-cursor collapsed"
                  type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                  <span class="badge rounded-pill text-bg-warning bg-orange-500 me-5px">{{evidencias.length}}</span>
                  Evidencias
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordion">
                <div class="accordion-body bg-white-100 text-silver-900">
                  <div class="widget-list rounded">
                    <a href="javascript:;" class="widget-list-item" *ngFor="let file of evidencias" matRipple>
                      <div class="widget-list-media icon position-relative">
                        <i class="fas fa-{{file.icon}} {{file.color}} text-white"></i>
                      </div>
                      <div class="widget-list-content">
                        <h4 class="widget-list-title fs-15px">{{file.name}}</h4>
                      </div>
                      <div class="widget-list-action text-end">
                        <span class="badge rounded-pill text-bg-info fs-12px">{{file.size}}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input type="file" class="display-none"
            accept=".pdf,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*, video/*"
            multiple id="selectFiles" (change)="onChangeFileSelected($event)" />
          <input class="display-none" type="file" id="captureVideoFiles" capture="environment" accept="video/*"
            (change)="onChangeFileCapture($event, 'video')">
          <input class="display-none" type="file" id="capturePhotoFiles" capture="environment" accept="image/*"
            (change)="onChangeFileCapture($event, 'image')">
        </div>
      </app-panel-base>
      <button *ngIf="visita.id > 0" type="button" class="btn btn-danger btn-float-bottom btn-lg"
        (click)="guardarVisita()">
        <i class="fas fa-hourglass-end"></i>
        Terminar Visita
      </button>
      <button *ngIf="visita.id === 0" [disabled]="visitado.id === 0" type="button"
        class="btn btn-green btn-float-bottom btn-lg" (click)="guardarVisita()">
        <i class="fas fa-play"></i>
        Iniciar Visita
      </button>
    </form>
  </div>
</app-container-base>
<ng-template #ctrlBusqueda let-modal let-c="close">
  <app-data-search [isMobile]="true" titulo="Búsqueda de Proveedores" [columns]="getColumns"
    [entityName]="entidadBusqueda" [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'"
    #ctrlBusqueda />
</ng-template>
