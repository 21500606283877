<div class="panel-content">
  <form [formGroup]="myForm" autocomplete="off">
  <div class="row">
    <div class="{{containerIsModal ? 'col-md-12' : 'col-md-6'}}">
        <div class="col" formArrayName="FilterOptions">
          <ng-container *ngFor="let itemControl of FilterOptions.controls; let i = index;"  [formGroupName]="i">
            <div class="row form-group">
              <div class="col-md-4">
                <components-ui-list-box formControlName="Etiqueta" [itemList]="this.listaFiltrosCombo" [columnList]="'Etiqueta'" [widthTextColumns]="12" [tabIndex]="-1"
                               [DefaultIndex]="i"  (select)="onChangeFiltro($event,itemControl.value)"></components-ui-list-box>
              </div>
              <div class="col-md-7">
                <colors-percent *ngIf="itemControl.value.Tipo == 8" (onChange)="onChangeColorsPercent($event, itemControl.value)" #coloresPorcentaje/>
                <app-level-text-box [helpLine]="itemControl.value.HelpLine" [withLabel]="false" *ngIf="itemControl.value.Tipo == 7 || itemControl.value.Tipo == 9" [widthTextColumns]="12" [tabIndex]="this.tabIndex + i + itemControl.value.tabIndex"
                (onBlur)="onBlurLevelText($event,itemControl.value)" (onChangue)="onLevelSelect($event, itemControl.value)" [type]="getNivelNombre(itemControl.value.TipoNiveles)" #busquedaNiveles caracterNivel='_' [allowRanges]="true"
                formControlName="Valor" />


                <div *ngIf="itemControl.value.Tipo != 7 && itemControl.value.Tipo != 8 && itemControl.value.Tipo != 9">
                  <div *ngIf="esNumOrText(itemControl.value) else controlLista">
                    <!-- <components-ui-text-box  formControlName="Valor"    [enableIcon]="itemControl.value.BotonBusqueda" [tabIndex]="this.tabIndex + i + itemControl.value.tabIndex"  [textFilter]="gettextFilter(itemControl.value)" [label]="''" [widthLabelColumns]="1" [widthTextColumns]="12"  (onChangeValue)="onChange($event,itemControl.value)"> </components-ui-text-box> -->
                    <components-ui-text-box [placeHolder]="itemControl.value.Placeholder" [enabled]="itemControl.value.Enabled" [helpLine]="itemControl.value.HelpLine" (keydown.f2)="clickBuscar(itemControl.value.Entidad,itemControl,i)" #inputfiltro  formControlName="Valor" (onClicIcon)="clickBuscar(itemControl.value.Entidad,itemControl,i)"   [enableIcon]="itemControl.value.BotonBusqueda?true:false" [tabIndex]="this.tabIndex + i + itemControl.value.tabIndex"  [textFilter]="gettextFilter(itemControl.value)" [label]="''" [widthLabelColumns]="1" [widthTextColumns]="12"  (onChangeValue)="onChangeValue($event,itemControl.value)"> </components-ui-text-box>
                  </div>
                  <ng-template #controlLista>
                    <components-ui-list-box  formControlName="Nombre" [tabIndex]="this.tabIndex + i + itemControl.value.tabIndex" [itemList]=" getlistaFiltrosComboLista(itemControl.value)" [columnList]="'Nombre'" [widthTextColumns]="12"   [DefaultIndex]="i"  (select)="onChangeFiltroLista($event,itemControl.value,itemControl)"></components-ui-list-box>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
    </div>
    <div class="col-md-6">
       <div *ngIf="this.tieneDesglose">
          <div class="row">
              <label class="col-form-label tb-right">Desglose:</label>
              <components-ui-list-box [itemList]="this.contentList" formControlName='DesgloseLista' columnList='Nombre' [tabIndex]="this.tabIndexOrden"  (select)="onChangeDesglose($event,'DesgloseLista')"   [widthTextColumns]="5" [widthLabelColumns]="2"></components-ui-list-box>
          </div>
       </div>
       <div *ngIf="this.tieneOrdenado" >
          <label class="col-form-label tb-right">Ordenado por:</label>
          <components-ui-list-box [itemList]="this.orderList"  formControlName="OrdenadoLista" columnList='Nombre' [tabIndex]="this.tabIndexOrden+1" [widthTextColumns]="5"  (select)="onChangeOrder($event,'OrdenadoLista')"  [widthLabelColumns]="2"></components-ui-list-box>
       </div>
       <div *ngIf="this.tieneLuegoPor">
          <label class="col-form-label tb-right">Luego por:</label>
          <components-ui-list-box [itemList]="this.orderThen1List" formControlName="LuegoPorLista" columnList='Nombre'  [tabIndex]="this.tabIndexOrden+2"  (select)="onChangeThen1List($event,'LuegoPorLista')"  [widthTextColumns]="4" [widthLabelColumns]="2"></components-ui-list-box>
       </div>
       <div *ngIf="this.tieneDespuesPor">
           <label class="col-form-label tb-right">Después por por:</label>
           <components-ui-list-box [itemList]="this.orderThen2List" formControlName="DespuesPorLista"  columnList='Nombre' [tabIndex]="this.tabIndexOrden+3" (select)="onChangeThen2List($event,'DespuesPorLista')"  [widthTextColumns]="3" [widthLabelColumns]="4"></components-ui-list-box>
       </div>
    </div>
  </div>
</form>
<ng-template #content let-modal let-c="close"  >
  <app-data-search  titulo="Búsqueda" [columns]="getColumns" [entityName]="getEntityName" [filter]="getFilter"
    [propertys]="getPropertys" [orders]="getOrders" #busqueda />
</ng-template>
</div>
