<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <label class="col-sm-{{widthLabelColumns}} col-form-label {{(topLabel ? 'text-left height-label':'tb-right')}}"><i
      class="fs-10px me-2"></i>{{label}}{{ label.length>0?':':''}}
    {{label.length==0?"&nbsp;":""}}</label>
  <div class="form-check form-switch col-sm-{{widthTextColumns}}" style="margin-left: 8px;">
    <input (focus)="onFocus()" (blur)="onBlur()" tooltipClass="complit-tooltip"
      [disabled]="!this.enabled" [tabindex]="tabIndex" (click)="onclick()"
      class="form-check-input combo-box form-check-input" type="checkbox" value="" [(ngModel)]="value" #tagChk>
  </div>
</div>
