import { Component, OnDestroy, Renderer2, OnInit, inject, ViewChild, ElementRef, isDevMode } from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { AppSettings } from '../../../home/services/app-settings.service';
import { AuthResponse, ConexionBaseDatos, Result, UserLogged } from '../../interfaces';
import { EventsService } from 'src/app/service/events.service';
import { BaseDatosService } from '../../services/base-datos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/service/utils.service';
import { SignalrService } from 'src/app/service/signalr.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import Swal from 'sweetalert2';
import { BaseService } from 'src/app/service/base.service';
import * as moment from 'moment';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  visibleAddBdModal: boolean = false;
  visibleActualizarBDModal: boolean = false;
  puedeActualizarBD: boolean = true;
  anio: number = new Date().getFullYear();
  dateLastUpdate = "";

  bdSeleccionada: any | undefined;
  usuarioLogin: UserLogged = { ambienteId: 0, numero: 0, clave: 0, nombreUsuario: "", nombreCompletoUsuario: "" };

  listaBd: ConexionBaseDatos[] = [];
  mensajeError: string = "";
  private formBuilder = inject(FormBuilder);
  private authService = inject(AuthService);
  private bdService = inject(BaseDatosService);
  private router = inject(Router);
  private modalService = inject(NgbModal);
  private utilsService = inject(UtilsService);
  private baseService = inject(BaseService);

  private eventsService = inject(EventsService);
  private signalrService = inject(SignalrService);


  public frmLogin: FormGroup = this.formBuilder.group({
    baseDatos: ['', [Validators.required]],
    nombreUsuario: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    recordar: [false]
  })

  constructor(private renderer: Renderer2, public appSettings: AppSettings) {
    this.signalrService.startConnection();
    // this.signalrService.addSessionUserListener();
  }

  ngOnInit() {
    this.usuarioLogin = this.utilsService.getUserLogged();

    this.appSettings.appEmpty = true;
    this.renderer.addClass(document.body, 'bg-white');
    this.getListaBD();
    this.modalService.dismissAll();
    this.getVersionApp();
  }

  setValoresLogin() {
    if (this.usuarioLogin) {
      setTimeout(() => {
        let bd = this.listaBd.find(b => b.id === this.usuarioLogin.ambienteId);
        this.frmLogin.controls['baseDatos'].setValue(bd);
        this.frmLogin.controls['nombreUsuario'].setValue(this.usuarioLogin.nombreUsuario);
        this.frmLogin.controls['password'].setValue(this.usuarioLogin.password);
        this.frmLogin.controls['recordar'].setValue(this.usuarioLogin.recordar);
        this.loading(false);
      }, 300);
    }
  }

  ngOnDestroy() {
    this.appSettings.appEmpty = false;
    this.renderer.removeClass(document.body, 'bg-white');
  }

  login() {
    localStorage.removeItem("ng2Idle.main.expiry");
    this.visibleActualizarBDModal = false;
    if (this.listaBd.length === 0) {
      this.mensajeError = "Debe seleccionar un ambiente de trabajo."
      return;
    }
    this.mensajeError = "";
    const { nombreUsuario, password, recordar, baseDatos } = this.frmLogin.value;
    const request = { nombreUsuario, password, ambienteId: baseDatos.id, numeroEmpresa: this.usuarioLogin.empresa!.numero, numeroSucursal: this.usuarioLogin.sucursal!.numero };
    this.loading(true);
    localStorage.setItem("iniciandoSesion", "true");
    this.authService.login(request)
      .subscribe({
        next: (response: any) => {
          localStorage.removeItem("iniciandoSesion");
          let lastUserLogged: UserLogged = JSON.parse(localStorage.getItem("userLogged")!);
          let resp: AuthResponse = response;
          if (!resp.actualizarBD) {
            let userLogged: UserLogged = {
              ambienteId: baseDatos.id,
              numero: resp.numeroUsuario,
              clave: resp.claveUsuario,
              nombreCompletoUsuario: resp.nombreCompletoUsuario,
              nombreUsuario,
              password,
              recordar,
              empresa: resp.empresa,
              sucursal: resp.sucursal,
            };
            //Si se está en modo develop, se agrega la contraseña a los datos recordados del usuario
            if (isDevMode()) {
              userLogged = { ...userLogged, password }
            }
            localStorage.setItem("userLogged", JSON.stringify(userLogged));
            this.loading(false);
            let urlLayout = response.esVendedorForaneo ? "/mobile" : "/home";
            return this.router.navigateByUrl(urlLayout)
          } else {
            setTimeout(() => {
              this.puedeActualizarBD = resp.puedeActualizarBaseDatos;
              this.visibleActualizarBDModal = true;
              this.loading(false);
            }, 700);
            return resp.actualizarBD;
          }
        },
        error: (mensaje) => {
          localStorage.removeItem("iniciandoSesion");
          this.mensajeError = mensaje;
          this.loading(false);
        }
      })
  }

  getListaBD() {
    this.loading(true);
    this.bdService.getListaBD().subscribe({
      next: (resp: ConexionBaseDatos[]) => {
        this.listaBd = resp;
        if (this.listaBd.length === 0) {
          this.visibleAddBdModal = true;
          this.loading(false);
        } else {
          this.setValoresLogin();
        }
      },
      error: (err: Error) => {
        console.log(err);
        this.loading(false);
        Swal.fire({
          text: "No fue posible cargar la información, intentelo más tarde o contacte con el administrador.",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    });

  }

  ambienteGuardado() {
    this.getListaBD();
    this.visibleAddBdModal = false;
  }

  ambienteActualizado(actualizado: boolean) {
    this.puedeActualizarBD = true;
    this.visibleActualizarBDModal = false;
    if (actualizado) {
      this.login();
    }
  }

  loading(load: boolean) {
    this.eventsService.publish('home:isLoading', { isLoading: load });
  }

  getVersionApp() {
    this.baseService.getVersionApp().subscribe({
      next: (response: any) => {

        if (isDevMode()) {
          this.dateLastUpdate = moment(response.releaseDate).format("DD/MM/YYYY hh:mm:ss A");
        } else {
          this.dateLastUpdate = moment(response.releaseDate).subtract(7, 'hours').format("DD/MM/YYYY hh:mm:ss A");
        }
      },
      error: (err: Error) => {
        console.log(err);
      }
    })
  }
}
