import { CuentaBancaria } from 'src/app/Finanzas/interfaces/c_banco.interface';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TerminalBancaria } from '../../interfaces/terminalbancaria.interface';
import { FinanzasService } from '../../services/finanzas.service';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { subDays } from 'date-fns';

@Component({
  selector: 'app-terminales-bancarias-page',
  templateUrl: './terminales-bancarias-page.component.html',
  styles: [
  ]
})
export class TerminalesBancariasPageComponent implements OnInit {

  @ViewChild('cboSucursal')
  public cboSucursal!: ElementRef<ComboBoxComponent>;
  @ViewChild('edicionTerminal')
  public edicionTerminal!: ElementRef<HTMLElement>;

  private eventsService = inject(EventsService);
  listaTerminales: TerminalBancaria[] = [];
  itemEdicion: any = null;

  public myForm: FormGroup = this.fb.group({
    Sucursal: [null],
  })

  constructor(private fs: FinanzasService, private ms: ModalService, private fb: FormBuilder, private containerService: ContainerBaseService) {

  }

  ngOnInit(): void {
    this.focusSucursal();
    this.listaTerminalesReset([]);
  }

  listaTerminalesReset(terminales: any = []) {
    this.listaTerminales = terminales;
    for (let index = 0; index < 100; index++) {
      this.listaTerminales = [...this.listaTerminales, {
        Id: 0,
        Clave: 0,
        Nombre: '',
        Banco: null,
        Empresa: null,
        Sucursal: null,
        CuentaBancaria: null,
        NumeroTerminal: '',
        Baja: false,
      }]
    }
    this.listaTerminales = [...this.listaTerminales];
  }

  deleteRow(index: number) {
    const element: any = this.listaTerminales[index];
    this.eventsService.publish('home:showAlert', {
      message: `¿Desea eliminar la Terminal Bancaria <b>${element.Banco.Nombre}/${element.NumeroTerminal}</b>?`,
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {

          this.containerService.deleteEntity("TerminalBancaria", element.Id).subscribe((result) => {
            this.obtenerTerminales(this.myForm.value.Sucursal.Id);
          })
        }
      }
    });
  }


  focusSucursal() {
    setTimeout(() => {
      const txt: any = this.cboSucursal;
      txt.tagInput.nativeElement.focus()
    }, 50);
  }


  selected(ent: any, type: string) {
    let idActual = this.myForm.value.Sucursal ? this.myForm.value.Sucursal.Id : 0;
    this.myForm.controls[type].setValue(ent)
    if (ent) {
      if (idActual != ent.Id) {
        this.obtenerTerminales(ent.Id);
      }
    } else {
      this.listaTerminalesReset([]);
    }
  }

  obtenerTerminales(idSuc: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.buscarTerminalesPorSucursal(idSuc).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.listaTerminalesReset(lista);
    })
  }


  agregarEditar(index: number) {
    const ent = this.myForm.value;

    if (!ent.Sucursal) {
      this.eventsService.publish('home:showAlert', { message: "Primero seleccione una Sucursal.", cancelButton: false });
      return;
    }
    const b: any = this.edicionTerminal;
    if (index >= 0) {
      debugger
      this.itemEdicion = { ... this.listaTerminales[index] };
      this.itemEdicion.Banco = {
        Id: this.itemEdicion.Banco.Id,
        Clave: this.itemEdicion.Banco.Clave,
        Nombre: this.itemEdicion.Banco.Nombre,
        ClaveNombre: `${this.itemEdicion.Banco.Clave} ${this.itemEdicion.Banco.Nombre}`
      };
      this.itemEdicion.CuentaBancaria = {
        Id: this.itemEdicion.CuentaBancaria.Id,
        Clave: this.itemEdicion.CuentaBancaria.Clave,
        Nombre: this.itemEdicion.CuentaBancaria.Nombre,
        ClaveNombre: `${this.itemEdicion.CuentaBancaria.Clave} ${this.itemEdicion.CuentaBancaria.Nombre}`
      };
    } else {
      this.itemEdicion = null;
    }
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.obtenerTerminales(this.myForm.value.Sucursal.Id);
      }
    });
  }

}


