import { Component, inject } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { SistemaService } from '../../services/sistema.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-act-cat-satpage',
  templateUrl: './act-cat-satpage.component.html',
  styles: [
  ]
})
export class ActCatSATPageComponent {
  private eventsService = inject(EventsService);
  public actualizando: boolean = false;

  constructor(private ss: SistemaService) { }

  actualizar(cat: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.actualizando = true;
    switch (cat) {
      //ClaveProdServ
      case 1:
        this.ss.actualizarClaveProdServ().subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.actualizando = false;
          if (result.success) {
            Swal.fire({ text: "Se actualizó el catálogo.", icon: 'success', })
          } else {
            Swal.fire({ text: result.message, icon: 'error', })
          }
        })
        break;
    }

  }

}
