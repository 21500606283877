import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { UserLogged } from 'src/app/auth/interfaces';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FinanzasService } from '../../services/finanzas.service';
import Swal from 'sweetalert2';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';

@Component({
  selector: 'app-config-conciliacion-page',
  templateUrl: './config-conciliacion-page.component.html',
  styles: [
  ]
})
export class ConfigConciliacionPageComponent implements OnInit {

  @ViewChild('seleccionarFamilia')
  public ctrlseleccionarFamilia!: ElementRef<HTMLElement>;
  @ViewChild('ctrlOpciones')
  public ctrlOpciones!: ElementRef<HTMLElement>;

  @ViewChild('txtFechaInicio')
  public txtFechaInicio!: ElementRef<HTMLElement>;

  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }

  constructor(private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private fs: FinanzasService,
    private ms: ModalService,
    private utileService: UtilsService) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    setTimeout(() => {
      const txt: any = this.txtFechaInicio;
      txt.tagInput.nativeElement.focus()
    }, 150);
    this.obtenerUltimaConfiguracion();
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [null],
    FechaInicio: [Date],
    FechaFin: [Date],
    FechaAlta: [Date],
    Comentarios: [''],
    FechaFinString: [''],
    FechaInicioString: [''],
    FechaAltaString: [''],
    Cuenta: [null],
    Usuario: [null],
    Baja: [false],
  });
  info: UserLogged = {} as UserLogged;

  abrirOpciones() {
    const b: any = this.ctrlOpciones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        console.log(e)
        switch (e) {
          case 1:
          this.tabsNavService.closeCurrentTab();
          this.eventsService.publish('home:openPage', 86200); break;
          case 2: break;
          case 3: break;
          case 4: break;
        }
      }
    }, 'md');
  }

  closeOpciones(opcion: number) {
    this.ms.closeModal(opcion);
  }

  abrirSelecionarCuenta() {
    const b: any = this.ctrlseleccionarFamilia;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Cuenta"].setValue(e);
      }
    }, 'widthfamilia');
  }

  get getAccountName(): string {
    if (this.myForm.value.Cuenta) {
      if (this.myForm.value.Cuenta.Id > 0 || this.myForm.value.Cuenta.id) {
        if (this.myForm.value.Cuenta.Nombre) return `${this.myForm.value.Cuenta.ClaveGeneral} ${this.myForm.value.Cuenta.Nombre}`;
        if (this.myForm.value.Cuenta.nombre) return `${this.myForm.value.Cuenta.claveGeneral} ${this.myForm.value.Cuenta.nombre}`;
      }
    }

    return "No ha seleccionado una Cuenta de Bancos";
  }

  get getIsNew(): boolean {
    return this.myForm.value?.Id == 0;
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "save": this.save(); break;
    }
  }

  save() {
    let ent = this.myForm.value;
    if (!ent.FechaInicio) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la fecha inicial.', cancelButton: false });
      return;
    }

    if (!ent.FechaFin) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la fecha final.', cancelButton: false });
      return;
    }

    if (!ent.Cuenta) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar una Cuenta de Bancos.', cancelButton: false });
      return;
    }
    ent.FechaInicioString = moment(ent.FechaInicio).format("YYYY-MM-DD");
    ent.FechaFinString = moment(ent.FechaFin).format("YYYY-MM-DD");
    ent.FechaAltaString = "";
    this.containerService.saveEntity("ConfiguracionConciliacion", JSON.stringify(ent), false).subscribe((item) => {
      if (item.success) {
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
          this.obtenerUltimaConfiguracion();
          this.abrirOpciones();
        })
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: item.message, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });
      }
    });
  }

  obtenerUltimaConfiguracion() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerConfiguracionConciliacion(this.info.empresa!.numero, this.info.numero).subscribe((result) => {
      if (result.success) {
        if (result.message.length > 0) {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          const ent = JSON.parse(result.message);
          this.myForm.reset(ent);
        } else {
          this.containerService.getEmptyEntity("ConfiguracionConciliacion").subscribe((ent) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            ent.FechaInicio = null;
            ent.FechaFin = null;
            ent.FechaAlta = new Date();
            ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre };
            ent.Usuario = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario };
            this.myForm.reset(ent);
          })
        }
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
      }
    });
  }

}
