import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { ConceptoRemisionResguardo, ConsultaResguardo, RemisionResguardo } from '../interfaces/remision.interface';
import { Result } from 'src/app/auth/interfaces';
import { ConsultaHistoricoResguardo, ConsultaSaldoResguardo } from 'src/app/ventas/interfaces/venta.interface';
import { startOfDay } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class ResguardoService {
  constructor(private http: HttpClient) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;

  cancelarRemision(idRemision: number, fecha: string, motivo: string) {
    const params = new HttpParams().set("idRemision", idRemision).set("fecha", fecha).set("motivo", motivo);
    return this.http.get<Result>(`${this.baseUrl}/Resguardo/CancelarRemision`, { params });
  }

  getResguardosPorCliente(idCliente: number) {
    const params = new HttpParams().set("idCliente", idCliente);
    return this.http.get<ConsultaResguardo[]>(`${this.baseUrl}/Resguardo/ObtenerResguardosPendientes`, { params });
  }

  getResguardosporFiltro(doctovtaId: number,serieId:number,folio:number){
    const params = new HttpParams().set("doctovtaId", doctovtaId).set("serieId", serieId).set("folio", folio);
    return this.http.get<ConsultaResguardo[]>(`${this.baseUrl}/Resguardo/ObtenerResguardosPendientesPorFiltros`, { params });
  }


  findById(id: number) {
    const params = new HttpParams()
      .set("id", id);
    return this.http.get<RemisionResguardo>(`${this.baseUrl}/Resguardo/ObtenerRemisionPorId`, { params });
  }

  getSaldosEntregar(id: number) {
    const params = new HttpParams()
      .set("idVenta", id);
    return this.http.get<ConsultaSaldoResguardo[]>(`${this.baseUrl}/Ventas/ObtenerSaldoResguardoPorFactura`, { params });
  }

  findEntityByParamsNavigate(idEmp: number, idSuc: number, serie: string, folio: number, tipo: string) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("serie", serie)
      .set("folio", folio)
      .set("tipo", tipo);
    return this.http.get<RemisionResguardo>(`${this.baseUrl}/Resguardo/ObtenerRemisionPorParametrosNavegacion`, { params });
  }

  findEntityByParams(idEmp: number, idSuc: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("serie", serie)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<RemisionResguardo>(`${this.baseUrl}/Resguardo/ObtenerRemisionPorParametros`, { params });
  }


  getHistoricoRemisiones(idVenta: number) {
    const params = new HttpParams().set("idVenta", idVenta);
    return this.http.get<ConsultaHistoricoResguardo[]>(`${this.baseUrl}/Resguardo/ObtenerHistoricoResguardo`, { params });
  }

  getResguardosPendiente(idFactura: number) {
    const params = new HttpParams().set("idFactura", idFactura);
    return this.http.get<Result>(`${this.baseUrl}/Resguardo/ObtenerDetallePorEntregar`, { params });
  }

  getOtrasUnidades(idProducto: number) {
    const params = new HttpParams().set("idProducto", idProducto);
    return this.http.get<Result>(`${this.baseUrl}/Resguardo/ObtenerOtrasUnidades`, { params });
  }


  getSeries(idEmp: number, idSuc: number) {
    const params = new HttpParams().set("idEmpresa", idEmp).set("idSucursal", idSuc);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Resguardo/ObtenerListaSeries`, { params });
  }

  getNextFolio(idEmp: number, idSuc: number, serie: string) {
    const params = new HttpParams().set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("serie", serie);
    return this.http.get<number>(`${this.baseUrl}/Resguardo/ObtenerSiguienteFolio`, { params });
  }


}
