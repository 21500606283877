<app-container-base [hasExitButton]="true" [filterQuery]="filter" (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="CuentaBancariaCliente" title="Cuentas bancarias" icon="fa fa-bank"
  subTitle="Cuentas bancarias de Clientes">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Cuenta'"
            entityName="CuentaBancariaCliente" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="2"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <component-ui-combo-box [label]="'Banco'" [required]="true" formControlName="Banco" [tabIndex]="3" [zeroMask]="3"
            entityName="c_Banco" [widthTextColumns]="4" [widthLabelColumns]="2" [widthRightColumns]="6"
            (onSelectedItem)="selectedItem($event, 'Banco')" [listProperty]="'ClaveNombre'" />

          <components-ui-text-box [label]="'No de cuenta'" [required]="true" formControlName="NumeroCuenta"
            [tabIndex]="4" [widthTextColumns]="4" [widthLabelColumns]="2" [maxLength]="50" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>

