<app-container-base [isAsync]="true" title="Clientes" icon="fa fa-user-tag"
  subTitle="Administra la configuración de Clientes" [activeButtons]="activeButtons" [hasCounter]="false"
  entityName="Pago" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="formPagosCxC"
  [searchItemsCombo]="false" [getLast]="false">
  <div class="screen-content">
    <form [formGroup]="formPagosCxC" autocomplete="off">
      <app-panel-base title="Filtros de búsqueda de Comprobantes por Cobrar">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-7">
              <div class="d-flex">
                <components-ui-text-box style="width:15%; margin-right: 5px;" [topLabel]="true" [enabled]="false"
                  [maxLength]="60" [required]="true" label="Empresa" [value]="nombreEmpresa" [tabIndex]="1"
                  [widthTextColumns]="12" [widthLabelColumns]="12" />

                <component-ui-combo-box style="width:15%; margin-right: 5px;" [topLabel]="true" entityName="Sucursal"
                  [required]="true" filterQuery="Empresa.Id == {{usuarioLoggeado.empresa!.numero}}"
                  formControlName="Sucursal" inputProperty="Nombre" label="Sucursal" [tabIndex]="2"
                  [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0" #cboSucursal
                  [enabled]="false" (onSelectedItem)="selectedCombo($event,'Sucursal')" />

                <components-ui-text-box class="w-600px" [topLabel]="true" [value]="nombreCliente" [required]="true"
                  label="Cliente" (keydown.f2)="openCustomerSearch()" [tabIndex]="4" [widthTextColumns]="12"
                  [widthLabelColumns]="12" (keydown.enter)="enterCliente($event)" entidadBusqueda="Cliente" #txtCliente
                  (elementoSeleccionado)="itemSeleccionado($event)" />
              </div>
            </div>
            <div class="col-md-5">
              <table class="table table-sm" style="border:0; color: #ffffff">
                <thead>
                  <tr>
                    <td style="width: 25%; color: #ffffff;" class="text-center fw-bolder bg-green-400">Límite</td>
                    <td style="width: 25%; color: #ffffff;" class="text-center fw-bolder bg-blue">Saldo</td>
                    <td style="width: 25%; color: #ffffff;" class="text-center fw-bolder bg-orange">Disponible</td>
                    <td style="width: 25%; color: #ffffff;"
                      class="text-center fw-bolder bg-{{(this.clienteSeleccionado.CreditoSuspendido ?'red':'success')}}">
                      Bloqueado
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <td class="text-center fw-bolder bg-green">{{this.clienteSeleccionado.LimiteCredito | number:'0.00'}}
                  </td>
                  <td class="text-center fw-bolder bg-blue">{{saldo | number: '0.00'}}</td>
                  <td class="text-center fw-bolder bg-orange">{{disponible | number: '0.00'}}</td>
                  <td
                    class="text-center fw-bolder bg-{{(this.clienteSeleccionado.CreditoSuspendido ?'red':'success')}}">
                    {{this.clienteSeleccionado.CreditoSuspendido ? "SÍ":"NO"}}
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Detalle del abono">
        <div class="panel-content">
          <div class="d-flex">
            <components-ui-number-box [formatoMoneda]="true" class="me-5px" [topLabel]="true" [required]="true"
              label="Abonar" formControlName="ImporteAbonar" [tabIndex]="6" [widthTextColumns]="12" #txtAbonar
              (blur)="calcDisponible($event)" [widthLabelColumns]="12" />
            <components-ui-number-box [formatoMoneda]="true" class="me-5px" [topLabel]="true" label="Abonado"
              formControlName="ImporteAbonado" [enabled]="false" [widthTextColumns]="12" [widthLabelColumns]="12" />
            <components-ui-number-box [formatoMoneda]="true" class="me-5px" [topLabel]="true" label="Disponible"
              [enabled]="false" formControlName="ImporteDisponible" [tabIndex]="7" [widthTextColumns]="12"
              [widthLabelColumns]="12" />
            <components-ui-text-box class="me-5px" [topLabel]="true" [required]="true" label="N° Operación"
              [maxLength]="20" formControlName="NumeroOperacion" [tabIndex]="8" [widthTextColumns]="12"
              [widthLabelColumns]="12" />
            <component-ui-combo-box class="me-5px" [topLabel]="true" filterQuery="Abona = true" inputProperty="Nombre"
              entityName="ConceptoCxc" [required]="true" label="Concepto" formControlName="Concepto" [tabIndex]="9"
              [widthTextColumns]="12" [widthLabelColumns]="12" (onSelectedItem)="selectedCombo($event,'Concepto')" />
            <component-ui-combo-box class="me-5px" [topLabel]="true" inputProperty="Nombre" entityName="c_FormaPago"
              [required]="true" (onSelectedItem)="selectedCombo($event,'FormaPago')" label="Forma de pago"
              formControlName="FormaPago" [tabIndex]="10" [widthTextColumns]="12" [widthLabelColumns]="12" />
          </div>

          <div class="d-flex ">
            <components-ui-text-box class="me-5px" [topLabel]="true" label="Comentarios" formControlName="Comentarios"
              [tabIndex]="11" [widthTextColumns]="12" [widthLabelColumns]="12" />
            <component-ui-combo-box class="me-5px" [topLabel]="true" inputProperty="Nombre" [tabIndex]="12"
              entityName="Moneda" [required]="true" (onSelectedItem)="selectedCombo($event,'Moneda')" label="Moneda"
              [extras]="'EsExtranjera as EsExtranjera'" [setIfIsOnlyOneItem]="true" formControlName="Moneda"
              [tabIndex]="13" [widthTextColumns]="12" [widthLabelColumns]="12" />
            <components-ui-number-box class="me-5px" [enabled]="esMonedaExtranjera" [topLabel]="true"
              label="Tipo de cambio" formControlName="TipoCambio" [tabIndex]="14" [widthTextColumns]="12"
              [widthLabelColumns]="12" />
            <components-ui-date-box class="me-20px w-100px" [topLabel]="true" label="Fecha de pago" [required]="true"
              [tabIndex]="15" [widthLabelColumns]="12" formControlName="FechaDelPago" [widthTextColumns]="12" />
            <components-ui-date-box class="me-40px w-250px" [topLabel]="true" label="Fecha de depósito"
              [required]="true" [tabIndex]="15" [widthLabelColumns]="12" formControlName="FechaDelDeposito"
              [widthTextColumns]="12" [tabIndex]="16" />
          </div>
          <div class="row mt-10px">
            <div class="col-md-6">
              <cuentas-pagos-cxc [tabIndex]="17" [disabledBeneficiaria]="false" [disabledOrdenante]="false"
                (selectCuentaOrdenante)="cambioCtaOrdenante($event)"
                (selectCuentaBeneficiaria)="cambioCtaBeneficiaria($event)" [clienteId]="clienteSeleccionado.Id"
                [empresaId]="usuarioLoggeado.empresa!.numero" [cuentaBeneficiaria]="cuentaBeneficiaria"
                [cuentaOrdenante]="cuentaOrdenante" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Comprobantes">
        <div class="panel-content">
          <ul class="nav nav-tabs mt-3" role="tablist">
            <li class="nav-item" role="presentation">
              <a href="#default-tab-1-{{idTabs}}" data-bs-toggle="tab" class="nav-link active" aria-selected="true"
                role="tab">
                <span class="d-sm-block d-none">
                  Con Saldo
                </span>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a href="#default-tab-2-{{idTabs}}" data-bs-toggle="tab" class="nav-link" aria-selected="false"
                role="tab">
                <span class="d-sm-block d-none">Aplicados</span>
              </a>
            </li>
          </ul>

          <div class="tab-content panel rounded-0 p-3 m-0">
            <div id="default-tab-1-{{idTabs}}" class="tab-pane fade active show" role="tabpanel">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Buscar registros..." #inputPendiente />
                <span class="input-group-text"><i class="fas fa-magnifying-glass"></i></span>
              </div>
              <ng-scrollbar class="scroll" [style.height.px]="heightTableSaldos">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="w-40px text-center">
                        <i style="color: #fefefe;"
                          class="align-middle {{selectTodos ? 'text-green fas fs-17px fa-square-check': 'fs-15px fas fa-square-full'}}"
                          (click)="seleccionarTodos($event)"></i>
                      </th>
                      <th style="width: 75px;">Sucursal</th>
                      <th style="width: 75px;">Cuenta</th>
                      <th class="text-center" style="width: 110px;">Serie-Folio</th>
                      <th class="text-center" style="width: 80px;">Emisión</th>
                      <th class="text-center" style="width: 80px;">Vencimiento</th>
                      <th class="text-center" style="width: 80px;">Moneda</th>
                      <th class="text-center" style="width: 60px">T.C.</th>
                      <th class="text-end" style="width: 7%;">Total</th>
                      <th class="text-end" style="width: 7%;">Saldo</th>
                      <th class="text-end" style="width: 7%;">Abonar</th>
                      <th>Comentarios</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let b of balance | filter : inputPendiente.value; let idx = index">
                      <td class="text-center">
                        <i class="align-middle {{b.seleccionado ? 'text-green fas fs-17px fa-square-check':'fs-15px far fa-square-full'}}"
                          (click)="marcar(b)"></i>
                      </td>
                      <td class="text-truncate ">{{b.sucursal.nombre}}</td>
                      <td class="text-truncate ">{{b.cuenta.nombre}}</td>
                      <td class="text-center text-truncate">{{b.serieFolio}}</td>
                      <td class="text-center text-truncate">{{b.emision | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center text-truncate">{{b.vencimiento | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center text-truncate">{{b.moneda}}</td>
                      <td class="text-end text-truncate">{{b.tipoCambio | number: '1.2-2'}}</td>
                      <td class="text-end text-truncate">{{b.total | number: '1.2-2'}}</td>
                      <td class="text-end text-truncate">{{b.saldo | number: '1.2-2'}}</td>
                      <td class="text-end text-truncate {{b.seleccionado?'p-0':''}}">
                        <label class="w-100" *ngIf="!b.editando" (click)="editarRegistro(b, true)">
                          {{b.abono | number: '1.2-2'}}
                        </label>
                        <input (input)="onCalcImportes(b,$event)" *ngIf="b.editando" id="txt-{{b.serieFolio}}"
                          type="text" (blur)="editarRegistro(b, false)"
                          class="form-control form-control-sm txt-table-detalle text-end p-1px" [(ngModel)]="b.abono"
                          [ngModelOptions]="{standalone: true}" numbersOnly (keyup)="abonar($event, idx)" />
                      </td>
                      <td class="td-comment text-truncate ">
                        <div class="" *ngIf="b.abono > 0">
                          <!-- <i class="fas fa-file-invoice-dollar {{b.abono === b.saldo ? 'liquidada': 'parcial'}}"></i> -->
                          <p>{{b.observaciones}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
            <div id="default-tab-2-{{idTabs}}" class="tab-pane fade" role="tabpanel">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Buscar registros..." #inputPagos name="inputPagos"
                  id="inputPagos" />
                <span class="input-group-text"><i class="fas fa-magnifying-glass"></i></span>
              </div>
              <ng-scrollbar class="scroll" [style.height.px]="heightTableAplicados">
                <table class="table table-pagos table-bordered table-sm">
                  <thead>
                    <tr>
                      <th style="width: 75px;" class="text-center">Fecha</th>
                      <th style="width: 6%;" class="text-center">Folio</th>
                      <th style="width: 8%;" class="text-end">Abonado</th>
                      <th style="width: 8%;" class="text-center"> N° Op</th>
                      <th style="width: 8%;">Concepto</th>
                      <th style="width: 8%;">Forma Pago</th>
                      <th style="width: 65px;" class="text-center">Moneda</th>
                      <th style="width: 8%;" class="text-center">T.C.</th>
                      <th style="width: 8%;" class="text-center">Banco</th>
                      <th style="width: 8%;" class="text-center">Cuenta</th>
                      <th style="width: 8%;" class="text-center">Elaboró</th>
                      <th style="width: 8%;" class="text-center">Cancelado</th>
                      <th style="width: 8%;" class="text-center">Canceló</th>
                      <th style="width: 30px;" class="text-center"></th>
                      <th style="width: 30px;" class="text-center"></th>
                      <th style="width: 30px;" class="text-center"></th>
                      <th style="width: 30px;" class="text-center"></th>
                      <th style="width: 30px;" class="text-center"></th>
                      <th style="width: 30px;" class="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let b of pagosAplicados | filter : inputPagos.value; let idx = index"
                      class="{{b.usuarioCancela ? 'text-red':''}}">
                      <td class="text-center">{{b.fecha | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center">{{b.folio}}</td>
                      <td class="text-end">{{b.abonado | number: '1.2-2'}}</td>
                      <td class="text-center">{{b.numeroOperacion}}</td>
                      <td title="{{b.claveConcepto}} {{b.nombreConcepto}}">{{b.claveConcepto}} {{b.nombreConcepto}}</td>
                      <td title="{{b.claveFormaPago + ' '+ b.nombreFormaPago}}">
                        {{b.claveFormaPago}} {{b.nombreFormaPago}}
                      </td>
                      <td class="text-center">{{b.moneda}}</td>
                      <td class="text-center">{{b.tipoCambio | number: '1.2-2'}}</td>
                      <td class="text-center">{{b.banco}}</td>
                      <td class="text-center">{{b.cuenta}}</td>
                      <td class="text-center">{{b.elaboro}}</td>
                      <td class="text-center">{{b.fechaCancelacion | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center">{{b.usuarioCancela}}</td>
                      <td class="text-center padding-for-icon" [openDelay]="300" placement="top" container="body"
                        ngbTooltip="Aplicado">
                        <i class="far {{b.aplicado ? 'fa-circle-check green' : 'fa-circle-xmark red' }}"></i>
                      </td>
                      <td class="text-center padding-for-icon fs-15px" placement="top" container="body"
                        [openDelay]="300" ngbTooltip="{{b.idReciboElectronico > 0? 'Imprimir': 'Sin Recibo'}}"
                        (click)="printReciboAplicados(b.idReciboElectronico)">
                        <i class="fa fa-ban red" *ngIf="b.idReciboElectronico == 0"></i>
                        <i class="fas fa-print blue" *ngIf="b.idReciboElectronico > 0"></i>
                      </td>
                      <td class="text-center padding-for-icon" placement="top" container="body" [openDelay]="300"
                        ngbTooltip="{{b.usuarioCancela ? 'Cancelado' : 'Cancelar'}}">
                        <i class="fas fa-trash-can trash {{b.usuarioCancela ? 'disabled' : ''}}"
                          (click)="eliminarPago(b)"></i>
                      </td>
                      <td class="text-center padding-for-icon" placement="top" container="body" [openDelay]="300"
                        ngbTooltip="Detalle">
                        <i class="fas fa-clipboard-list detail" (click)="obtenerDetalle(b.id)"></i>
                      </td>
                      <td class="text-center padding-for-icon" placement="top" container="body" [openDelay]="300"
                        ngbTooltip="Descargar Recibo">
                        <i class="fas fa-cloud-arrow-down text-blue-400" (click)="download(b.idReciboElectronico)"></i>
                      </td>
                      <td class="text-center padding-for-icon" placement="left" container="body" [openDelay]="300"
                        ngbTooltip="Enviar por Correo">
                        <i class="fas fa-paper-plane text-orange-400"
                          (click)="sendEmail(b.idReciboElectronico, formPagosCxC.value.Cliente.Id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
          </div>
        </div>
      </app-panel-base>
    </form>
    <ng-template #modalCancelOptiones let-modal let-c="close">
      <app-opcion-cancelacion-page [esRep]="true" [idDocto]="0" [uuid]="''" />
    </ng-template>
    <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'"
        [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
    </ng-template>
  </div>
</app-container-base>


<ng-template #detallePago let-modal let-c="close">
  <div class="bg-light animate__animated animate__backInDown animate__faster">
    <div class="modal-header">
      <h4 class="modal-title">Detales del pago</h4>
    </div>
    <div class="modal-body">
      <ng-scrollbar class="scroll" [style.height.px]="heightTablePagosDetalle">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th class="text-center" style="width: 10%;">Emisión</th>
              <th style="width: 25%;">Sucursal</th>
              <th class="text-center" style="width: 10%;">Serie y Folio</th>
              <th class="text-end" style="width: 15%;">Abono</th>
              <th class="text-end" style="width: 15%;">Saldo</th>
              <th class="text-end" style="width: 15%;">Tipo Cambio</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of pagosDetalle; let idx = index">
              <td class="text-center">{{p.emision | date: 'dd/MM/yyyy'}}</td>
              <td>{{p.sucursal.nombre}}</td>
              <td class="text-center">{{p.serie}}{{p.folio}}</td>
              <td class="text-end">{{p.importeAbonado | number: '1.2-2'}}</td>
              <td class="text-end">{{p.saldoActual | number: '1.2-2'}}</td>
              <td class="text-end">{{p.tipoCambio | number: '1.2-2'}}</td>
            </tr>
          </tbody>
        </table>
      </ng-scrollbar>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="aceptar(true)">Aceptar</button>
    </div>
  </div>
</ng-template>
