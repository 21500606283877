<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="c_FormaPago"
  title="Formas de Pago" icon="fa fa-credit-card" subTitle="Administra la configuración de Formas de Pago">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Forma de Pago'" entityName="c_FormaPago" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [maxLength]="120" [required]="true"
            formControlName="Nombre" [tabIndex]="2" [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre />
        </div>
      </app-panel-base>

      <app-panel-base title="Configuracion">
        <div class="panel-content row">

          <component-ui-check-box [tabIndex]="3" [widthLabelColumns]="2"  formControlName="Bancarizado"
          [label]="'¿Bancarizado?'" />

          <components-ui-text-box [label]="'Patron Cta Ord'" [maxLength]="120" [required]="true"
          formControlName="PatronCuentaOrdenante" [tabIndex]="4" [widthTextColumns]="3" [widthLabelColumns]="2"/>

          <components-ui-text-box [label]="'Patron Cta Ben'" [maxLength]="120" [required]="true"
          formControlName="PatronCuentaBeneficiaria" [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2"/>

          <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="2"  formControlName="Baja"
          [label]="'¿Dar de baja?'" />

        </div>
      </app-panel-base>

    </form>
  </div>
</app-container-base>
