<!-- BEGIN error -->
<div class="error">
	<div class="error-code">404</div>
	<div class="error-content">
		<div class="error-message">No pudimos encontrar esto dentro de Complit...</div>
		<div class="error-desc mb-4">
			La página que estás buscando no existe dentro de nuestro sistema.
		</div>
		<div>
			<a routerLink="/" class="btn btn-success px-3">Ir al Inicio</a>
		</div>
	</div>
</div>
<!-- END error -->
