import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { ComboBoxService } from '../../services/combo-box.service';
import { ComboBoxEntity, Coordinates } from '../../interfaces/combo-text.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { columnGroupWidths } from '@swimlane/ngx-datatable';
import { ContainerBaseService } from '../../services/container-base.service';
import { IdentificacionVehicular } from '../../../ventas/interfaces/cartaporte.interface';

@Component({
  selector: 'component-ui-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true
    }
  ]
})
export class ComboBoxComponent implements AfterViewInit, ControlValueAccessor {
  @Input()
  public setIfIsOnlyOneItem: boolean = false;
  @Input()
  public placeHolder: string = "";
  @Input()
  public widthRightColumns: number = 0;
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "";
  @Input()
  public filterQuery: string = "";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 100;
  @Input()
  public tabIndex: number = 1;
  @Input()
  public enableLink: boolean = false;
  @Output()
  public onClicLink = new EventEmitter();
  //  @Input()
  //  public formControlName: string = "";
  @Input()
  public required: boolean = false;
  @Input()
  public enabled: boolean = true;
  @Input()
  public aplicarPermisos: boolean = false;
  @Input()
  public isCatalog: boolean = false;
  @Input()
  public widthList: number = 250;
  @Input()
  public listProperty: string = "ClaveNombre";
  @Input()
  public inputProperty: string = "Clave";
  @Input()
  public rightLabelProperty: string = "Nombre";
  @Input()
  public entityName: string = "";
  @Input()
  public extras: string = "";
  @Input()
  public style: string = "";
  @Input()
  public zeroMask: number = 2;
  @Input()
  public lazyLoad: boolean = true;
  @Input()
  public topLabel: boolean = false;

  @Input()
  public autoOpen: boolean = false;

  @Input()
  public helpLine: string = "";
  public value: string = "";
  loading: boolean = false;

  isDisabled = false;
  private items: ComboBoxEntity[] = [];
  private _filteredItems: ComboBoxEntity[] = [];
  private selectedIndex: number = -1;
  private selectedIndexNavigation: number = -1;
  private selectedItem!: ComboBoxEntity | null;
  private searched = false;
  private isLoaded = false
  private readonly baseUrl: string = environment.baseUrlApi;
  listaAbierta: boolean = false;
  forzarAbrirLista: boolean = false;

  @Output()
  public onSelectedItem: EventEmitter<any> = new EventEmitter();
  @Output()
  public onChangueEntity: EventEmitter<any> = new EventEmitter();
  @Output()
  public onNewCatalog: EventEmitter<any> = new EventEmitter();
  @Output()
  public onKeyPress: EventEmitter<any> = new EventEmitter();

  @Output()
  public onKeyDown: EventEmitter<any> = new EventEmitter();


  @Output()
  blur = new EventEmitter();

  throwBlur: boolean = true;

  public get getRightLabel(): string {
    if (!this.selectedItem) return "";
    return this.selectedItem.Nombre;
  }

  get IsRequired(): string {
    if (this.selectedItem || !this.enabled) return "";
    if (this.tagInput?.nativeElement?.value != "") return "";
    return this.required ? "tb-obligate" : "";
  }
  @Input()
  public set setItems(items: ComboBoxEntity[]) {
    //Aquí solo entra cuando es clave principal
    this.isLoaded = false;
    this.items = items;
    this._filteredItems = items;
    if (this.selectedItem) {
      this.selectEntityValue(this.selectedItem, false);
    }
  }

  @ViewChild('txtTagCombo')
  public tagInput!: ElementRef<HTMLInputElement>;

  private _onChange?: (obj: any) => void;
  private _onTouched?: () => void;
  @Input()
  set isNewItem(item: any) {
    if (this.tagInput) {
      if (item.Clave > 0 && item.Id == 0) {
        this.tagInput.nativeElement.value = item.Clave;
      }
      this.tagInput.nativeElement.disabled = item.Clave > 0 && item.Id == 0;
    }



    if (this.isCatalog && item.Id > 0 && item.Id != this.selectedItem?.Id) {
      this.selectedItem = item;
      //if (!this.isLoaded) {
      //this.isLoaded = true;
      this.selectEntityValue(this.selectedItem, false);
      // }
    }

  }

  @Input()
  set selectManualItem(item: ComboBoxEntity | null) {
    if (item) {
      let index = this.getSelectedIndex(item)
      let lanzarEvento: boolean = this.selectedIndex != index;

      this.onSelected({ ...item, Index: index }, lanzarEvento);
    } else {
      this.reset();
    }
  }

  @Input()
  set setItemsList(list: ComboBoxEntity[]) {
    this.items = list;
    this._filteredItems = list;
  }


  private containerBaseService = inject(ContainerBaseService);
  constructor(private cboService: ComboBoxService, private http: HttpClient, private changeDetectorRef: ChangeDetectorRef, private cbService: ContainerBaseService) {

  }

  ngAfterViewInit(): void {
    let s = 0;
    this.setFirstItemList();
  }

  selectEntityValue(item: any, isNum: boolean) {
    let selectItem: any;
    this.items.forEach((elem, index) => {
      if (!isNum) {
        if (elem.Id == item.Id) {
          selectItem = { ...elem };
          this.selectedIndex = index;
          selectItem.Index = index;
        }
      } else {
        if (index == item) {
          selectItem = { ...elem };
          this.selectedIndex = index;
          selectItem.Index = index;
        }
      }
    });
    this.onSelected(selectItem);
  }
  get getClass(): string {
    return `${this.loading ? 'fas fa-spinner fa-spin combo-loading' : (this.enabled ? 'combo-box-arrow' : 'combo-box-arrow-disabled')}`
  }

  onClickLink() {
    if (this.enableLink) {
      this.onClicLink.emit();
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      if (obj["Id"]) {
        let objSel: any = { Id: obj["Id"], Clave: obj["Clave"], Nombre: obj["Nombre"], ClaveNombre: '', Index: 0, Serie: '' };
        if (obj["Serie"]) {
          objSel.Serie = obj["Serie"];
        }
        if (this.items.filter(P => P.Id == obj["Id"]).length > 0) {
          objSel = this.items.filter(P => P.Id == obj["Id"])[0];
        }
        this.onSelected(objSel, false);
      }
      if (obj["id"]) {
        let objSel: any = { Id: obj["id"], Clave: obj["clave"], Nombre: obj["nombre"], ClaveNombre: '', Index: 0, Serie: '' };
        if (obj["Serie"]) {
          objSel.Serie = obj["Serie"];
        }
        if (this.items.filter(P => P.Id == obj["id"]).length > 0) {
          objSel = this.items.filter(P => P.Id == obj["id"])[0];
        }
        this.onSelected(objSel, false);
      }
    } else {
      this.reset();
    }
  }

  reset() {
    //this.items = [];
    this.selectedIndex = -1;
    this.selectedItem = null;
    if (this.tagInput) {
      this.tagInput.nativeElement.value = "";
    }
  }

  registerOnChange(fn: (any)): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  getSelectedItem() {
    return this.selectManualItem ? this.selectManualItem : this.selectedItem;
  }

  F9(event: any) {
    this.searched = false;
    this.focus(true);
  }
  keydownEnter(event: any) {
    if (this.selectedIndexNavigation < 0 && !this.tagInput.nativeElement.value && !this.isCatalog) {
      this.clearValue();
      this.listaAbierta = false;
      this.cboService.hide();
      this.onSelectedItem.emit(null);
    } else if (this.selectedIndexNavigation >= 0) {
      this.selectedIndex = this.selectedIndexNavigation;

      let item = { ...this.items[this.selectedIndex] };

      if (this._filteredItems.length > 0) {
        item = { ...this._filteredItems[this.selectedIndex] };
        this.selectedIndex = this.getSelectedIndex(item);
      }
      this.onSelected({ ...item, Index: this.selectedIndex })
      this.listaAbierta = false;
      this.cboService.hide();
    } else if (this.isCatalog && this.isHidden() && !isNaN(parseFloat(this.tagInput.nativeElement.value))) {
      this.tagInput.nativeElement.blur();
    } if (this.isCatalog && !this.isHidden() && !isNaN(parseFloat(this.tagInput.nativeElement.value))) {
      let i = this.items.filter(P => P.Clave == this.tagInput.nativeElement.value.toString());
      if (i.length > 0) {
        let index = this.getSelectedIndex(i[0])
        this.onSelected({ ...i[0], Index: index });
      }
    }
  }

  getSelectedIndex(item: ComboBoxEntity): number {
    let i: number = -1
    this.items.forEach((elem, index) => {
      if (elem.Id == item.Id) {
        i = index;
      }
    });
    return i;
  }

  blurEvent() {

    if (this.loading || !this.throwBlur) return;
    if (this.isCatalog && this.isHidden() && !isNaN(parseFloat(this.tagInput.nativeElement.value))) {
      let i = this.items.filter(P => P.Clave == this.tagInput.nativeElement.value.toString());
      if (i.length > 0) {
        let index = this.getSelectedIndex(i[0])
        this.onSelected({ ...i[0], Index: index });
      } else {
        this.onNewCatalog.emit(this.tagInput.nativeElement.value);
      }
    } else if (this.isCatalog && this.isHidden() && isNaN(parseFloat(this.tagInput.nativeElement.value))) {
      if (this.selectedItem) {
        this.tagInput.nativeElement.value = this.selectedItem?.Clave.toString();
      }
    } else if (!this.isCatalog && !this.tagInput.nativeElement.value && this.required) {
      if (this.selectedItem) {
        this.tagInput.nativeElement.value = this.selectedItem?.Clave.toString();
      } else {
        if (this.items.length > 0) {
          let index = this.getSelectedIndex(this.items[0])
          this.onSelected({ ...this.items[0], Index: index });
        }
      }
    } else if (!this.isCatalog && this.tagInput.nativeElement.value) {
      let itemsFiltered = this.items.filter((P: any) => String(P[this.inputProperty]).toLowerCase() == this.tagInput.nativeElement.value.toLowerCase());
      if (itemsFiltered.length == 0) {
        itemsFiltered = this.items.filter(P => P.Clave == this.tagInput.nativeElement.value);
      }

      if (itemsFiltered.length > 0) {
        let i = itemsFiltered[0];
        let index = this.getSelectedIndex(i)
        this.onSelected({ ...i, Index: index });
      } else {
        this.reset();
      }
    } else if (!this.isCatalog && !this.tagInput.nativeElement.value) {
      this.clearValue();
      this.listaAbierta = false;
      this.cboService.hide();
      this.onSelectedItem.emit(null);
    }
    this._filteredItems = this.items;
  }

  isHidden(): boolean {
    const el = document.getElementById("div-combo-box");
    return el == null || el == undefined;
  }

  clearValue() {
    const val = this.tagInput.nativeElement.value;
    if (!val) {
      this.selectedIndex = -1;
      this.selectedItem = null;
    }
  }

  onBlur() {
    this.tagInput.nativeElement.classList.remove("pulse-text");
    this.listaAbierta = false;
    this.cboService.hide();
    this.blurEvent();
    this.selectedIndexNavigation = -1
  }

  hide() {
    this.selectedIndexNavigation = -1;
    this.listaAbierta = false;
    this.cboService.hide();
    //this.clearValue();
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  mousedown(e: any) {
    e?.preventDefault();
  }

  onKeyPressEvent(e: any) {
    this.onKeyPress.emit(e);
  }

  onKeyDownEvent(e: any) {
    this.onKeyDown.emit(e);
  }

  filter(event: any) {
    if (event.key != "Enter" && event.key != "F2" && event.key != "ArrowDown" && event.key != "ArrowUp" && event.key != "Tab" && event.key != "Escape") {
      const value = this.tagInput.nativeElement.value;
      let itemsFiltered = this.items.filter(P => P.Nombre.toLowerCase().includes(value.toLowerCase()) || String(P.Clave).toLowerCase().includes(value.toLowerCase()));
      this._filteredItems = [...itemsFiltered];
      this.listaAbierta = false;
      this.cboService.hide();
      this.makeList(true);
      if (this._filteredItems.length > 0) {
        this.selectedIndexNavigation = 0;
      }
    }
  }

  navigate(type: string) {
    let next: number = 0;
    if (this.selectedIndexNavigation < 0) { this.selectedIndexNavigation = this.selectedIndex; }
    switch (type) {
      case 'down':
        next = this.selectedIndexNavigation + 1
        this.setItemNavigation(next);
        break;
      case 'up':
        next = this.selectedIndexNavigation - 1
        this.setItemNavigation(next);
        break;
    }
  }

  private setItemNavigation(index: number) {
    let num = this._filteredItems.length;// > 0 ? this._filteredItems.length : this.items.length;
    if (index + 1 > num) return;
    if (index < 0) return;
    for (let next = 0; next < num; next++) {
      const el = this.getElementItem(next);
      if (next == index) {
        if (el) {
          el.className = 'combo-box-selected-item';
          this.selectedIndexNavigation = next;
          el?.scrollIntoView();
        }
      } else {
        if (el) {
          el.className = 'combo-box-item text-dark bg-white';
        }
      }

    }

  }

  private getElementItem(index: number): HTMLElement | null {
    const id = `item-cbo-id${index}`;
    const el = document.getElementById(id);
    return el;
  }


  focus(value: any) {
    this.tagInput.nativeElement.classList.add("pulse-text");
    if ((!this.isCatalog && this.entityName && !this.searched) || this.filterQuery !== "") {
      this.loading = true;
      this.searchItemsOnclick()
        .subscribe(
          list => {
            this.loading = false;
            this.searched = true;
            if (list) {
              if (this.zeroMask > 0) {
                list.map(P => { P.ClaveNombre = `${String(P.Clave).padStart(this.zeroMask, '0')} ${P.Nombre}` });
              } else {
                list.map(P => { P.ClaveNombre = `${P.Clave} ${P.Nombre}` });
              }
              if (this.selectedItem) {
                list.forEach((elem, ix) => {
                  if (elem.Id == this.selectedItem!.Id) {
                    this.selectedIndex = ix;
                  }
                });
              }
              this.items = list;
              this._filteredItems = list;;
              if (this.autoOpen || this.forzarAbrirLista) {
                this.makeList();
              } else {
                if (value == true) {
                  this.makeList();
                }
              }
              this.forzarAbrirLista = false;
            } else {
              this.items = this._filteredItems = [];
            }
          }
        );
    } else {
      if (this.autoOpen) {
        this.makeList();
      } else {
        if (value == true) {
          this.makeList();
        }
      }

    }
    let pes = document.querySelectorAll('.lineadeayuda');
    pes.forEach((elem: any) => { elem.innerHTML = this.helpLine ? this.helpLine : ""; });

  }

  searchItemsOnclick() {
    const params = new HttpParams().set("entidad", this.entityName).set("filterQuery", this.filterQuery).set("extra", this.extras).set("aplicarPermisos", this.aplicarPermisos);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Base/ObtenerListaCombo`, { params });
  }

  makeList(filtering: boolean = false) {
    if (!this.enabled) return;
    const box = this.getOffset(this.tagInput.nativeElement);
    this.listaAbierta = true;
    this.cboService.triggerClick({
      selectedIndex: this.selectedIndex,
      coords: box,
      width: this.widthList,
      listProperty: this.listProperty,
      zeroMask: this.zeroMask,
      listData: filtering ? this._filteredItems : this.items,
      onSelected: (item: ComboBoxEntity) => {
        this.throwBlur = false;
        this.onSelected(item);
      }
    });
  }

  onSelected(item: ComboBoxEntity, throwEvent: boolean = true) {
    let index = this.getSelectedIndex(item)
    this.selectedItem = { ...item };
    this.selectedIndex = index;
    switch (this.inputProperty) {
      case 'Serie': this.tagInput.nativeElement.value = this.selectedItem.Serie!; break;
      case 'Nombre':
        if (this.tagInput) {
          this.tagInput.nativeElement.value = this.selectedItem.Nombre;
        }
        break;
      case 'Clave':
        let cve: string = "";
        if (this.zeroMask > 0) {
          cve = this.selectedItem.Clave.toString().padStart(this.zeroMask, '0');
        } else {
          cve = this.selectedItem.Clave.toString();
        }
        if (this.tagInput) {
          this.tagInput.nativeElement.value = cve;
        } else {
          this.value = cve;
        }
        break;
      case "ClaveNombre":
        let clave = this.selectedItem.Clave.toString().padStart(this.zeroMask, '0');
        this.tagInput.nativeElement.value = `${clave} ${this.selectedItem.Nombre}`
        break;
    }

    if (this.isCatalog) {
      if (this.entityName) {
        this.cbService.getEntityById(this.entityName, item.Id).subscribe(item => {
          this.onChangueEntity.emit(item);
          setTimeout(() => {
            this.throwBlur = true;
          }, 250);
        });
      } else {
        console.error('No ha colocado la propiedad: entityName del combo-box.');
      }
    } else {
      this.throwBlur = true;
    }
    throwEvent && this.onSelectedItem.emit(item);
  }

  triggerClick() {
    if (this.listaAbierta) {
      this.listaAbierta = false;
      this.cboService.hide();
    } else {
      if (this.tagInput.nativeElement == document.activeElement) {
        this.focus(true);
      } else {
        if (this.autoOpen) {
          this.focus(true);
        } else {
          this.forzarAbrirLista = true;
          this.tagInput.nativeElement.focus();
        }
      }
    }
  }

  setFirstItemList() {
    if (this.setIfIsOnlyOneItem) {
      this.containerBaseService.getCounter(this.entityName).subscribe({
        next: (counter: number) => {
          if (counter === 1) {
            this.searchItemsOnclick()
              .subscribe(
                list => {
                  let item = list[0]
                  if (item) {
                    this.selectedItem = { ...item };
                    switch (this.inputProperty) {
                      case 'Serie': this.tagInput.nativeElement.value = this.selectedItem.Serie!; break;
                      case 'Nombre':
                        if (this.tagInput) {
                          this.tagInput.nativeElement.value = this.selectedItem.Nombre;
                        }
                        break;
                      case 'Clave':
                        let cve: string = "";
                        if (this.zeroMask > 0) {
                          cve = this.selectedItem.Clave.toString().padStart(this.zeroMask, '0');
                        } else {
                          cve = this.selectedItem.Clave.toString();
                        }
                        if (this.tagInput) {
                          this.tagInput.nativeElement.value = cve;
                        } else {
                          this.value = cve;
                        }
                        break;
                      case "ClaveNombre":
                        let clave = this.selectedItem.Clave.toString().padStart(this.zeroMask, '0');
                        this.tagInput.nativeElement.value = `${clave} ${this.selectedItem.Nombre}`
                        break;
                    }
                    this.onSelectedItem.emit(item);
                  }
                }
              );
          }
        },
        error: (err: Error) => console.log(err)
      })
    }
  }
}
