import { FacturaPendienteRep } from './../../../Inventarios/interfaces/rep.interface';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ModalService } from 'src/app/service/modal.service';
import { c_FormaPago } from '../../interfaces/formapago.interface';
import { c_Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { DocumentoRelacionado, ReciboElectronicoPagoDetalle, TrasladoPago } from 'src/app/Inventarios/interfaces/rep.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { FacturacionService } from '../../services/facturacion.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-venatana-rep-page',
  templateUrl: './venatana-rep-page.component.html',
  styles: [
  ]
})
export class VenatanaRepPageComponent implements OnInit {


  public listaFacturas: FacturaPendienteRep[] = [];
  public buscando: boolean = false;
  public bloquear: boolean = false;
  public GuiColumnAlign = GuiColumnAlign;
  private eventsService = inject(EventsService);
  info: UserLogged = {} as UserLogged;

  @Input()
  public IdCliente: number = 0;

  @Input()
  public pago: ReciboElectronicoPagoDetalle | null = null;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    FechaPago: [Date],
    FormaPagoP: [{} as c_FormaPago],
    Monto: [0],
    MontoSeleccionado: [0],
    MonedaP: [{} as c_Moneda],
    TipoCambioP: [0],
    NumeroOperacion: [''],

    RfcEmisorCtaOrd: [''],
    NomBancoOrdExt: [''],
    CtaOrdenante: [''],
    RfcEmisorCtaBen: [''],
    CtaBeneficiario: [''],

    DocumentosRelacionados: [[] as DocumentoRelacionado[]],
    TraslasdosP: [[] as TrasladoPago[]],
  });


  constructor(private fb: FormBuilder, private fService: FacturacionService, private ms: ModalService, private utileService: UtilsService) { }


  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.buscando = true;
    this.fService.ObtenerFacturasReps(this.info.empresa!.numero, this.IdCliente).subscribe((lista) => {
      this.buscando = false;
      this.listaFacturas = lista;

      if (this.pago) {
        this.myForm.reset({ ...this.pago });
        if (this.pago.Id == 0) {
          let pagar: number = 0;
          this.listaFacturas.map((f) => {
            this.pago?.DocumentosRelacionados?.forEach((dr) => {
              if (f.uuid.toLowerCase() == dr.IdDocumento.toLowerCase()) {
                f.pagar = dr.ImpPagado;
                pagar += f.pagar;
              }
            });
            return f;
          });
          this.myForm.controls["MontoSeleccionado"].setValue(pagar);
          this.listaFacturas = [...this.listaFacturas];
        } else {
          this.bloquear = true;
        }
      }
    });
  }

  cambioCtaOrdenante(event: any) {
    if (event) {
      this.myForm.controls['RfcEmisorCtaOrd'].setValue(event.RfcBanco);
      this.myForm.controls['NomBancoOrdExt'].setValue(event.NombreBanco);
      this.myForm.controls['CtaOrdenante'].setValue(event.NumeroCuenta);
    }
  }

  cambioCtaBeneficiaria(event: any) {
    if (event) {
      this.myForm.controls['RfcEmisorCtaBen'].setValue(event.RfcBanco);
      this.myForm.controls['CtaBeneficiario'].setValue(event.NumeroCuenta);
    }
    //this.myForm.controls['NomBancoBeneficiario'].setValue(event.NombreBanco);
  }

  sourceEdited(e: any) {
    let pagar: number = 0;
    this.listaFacturas.forEach((i) => {
      if (i.pagar != 0) {
        pagar += i.pagar;
      }
    })
    this.myForm.controls["MontoSeleccionado"].setValue(pagar);
    this.listaFacturas = [...this.listaFacturas];
  }

  marcar(fp: FacturaPendienteRep) {
    let pagar: number = 0;
    this.listaFacturas.forEach((i) => {
      if (i.uuid == fp.uuid) {
        i.seleccionado = !i.seleccionado;
        i.pagar = i.seleccionado ? i.saldo : 0;
      }
    })

    this.listaFacturas.filter(P => P.seleccionado).forEach((i) => { pagar += i.pagar; });

    this.myForm.controls["MontoSeleccionado"].setValue(pagar);
    this.listaFacturas = [...this.listaFacturas];
  }

  get getEnableTC(): boolean {
    if (this.myForm.value.MonedaP) {
      if (this.myForm.value.MonedaP["Nombre"]) {
        if (this.myForm.value.MonedaP.Nombre != "Peso Mexicano") {
          return true;
        }
      }
    }
    return false;
  }

  onSelectItem(ent: ComboBoxEntity, type: string) {
    console.log(ent);
    this.myForm.controls[type].setValue(ent);
  }

  accept() {
    const ent = this.myForm.value;
    let pagar: number = 0;
    let tieneNegativo: boolean = false;
    let pagoDemas: boolean = false;
    this.listaFacturas.forEach((i) => {
      if (i.pagar != 0) {
        pagar += i.pagar;
      }
      if (i.pagar < 0) {
        tieneNegativo = true;
      }
      if (i.pagar > i.saldo) {
        pagoDemas = true;
      }
    })

    if (pagar <= 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar el importe a pagar en los CFDIS.', cancelButton: false });
      return false;
    }

    if (tieneNegativo) {
      this.eventsService.publish('home:showAlert', { message: 'No puede colocar importes negativos, verifique.', cancelButton: false });
      return false;
    }

    if (pagoDemas) {
      this.eventsService.publish('home:showAlert', { message: 'No puede pagar más que el saldo insoluto del CFDI.', cancelButton: false });
      return false;
    }

    if (!ent.FechaPago) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la Fecha del Pago.', cancelButton: false });
      return false;
    }

    if (!ent.FormaPagoP || ent.FormaPagoP?.Id == 0 || !ent.FormaPagoP["Id"]) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la Forma de Pago.', cancelButton: false });
      return false;
    }

    if (!ent.MonedaP || ent.MonedaP?.Id == 0 || !ent.MonedaP["Id"]) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la Moneda de Pago.', cancelButton: false });
      return false;
    }


    if (!ent.NumeroOperacion) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar el Número de Operación.', cancelButton: false });
      return false;
    }

    if (ent.Monto <= 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar el monto de Pago.', cancelButton: false });
      return false;
    }

    if (ent.Monto < ent.MontoSeleccionado) {
      this.eventsService.publish('home:showAlert', { message: 'El monto del pago es menor que el monto pagado en los CFDIS, verifique.', cancelButton: false });
      return false;
    }

    ent.DocumentosRelacionados = [];

    this.listaFacturas.forEach((i) => {
      if (i.pagar > 0) {
        ent.DocumentosRelacionados = [...ent.DocumentosRelacionados, {
          IdDocumento: i.uuid,
          Serie: i.serie,
          Folio: i.folio,
          MonedaDR: i.monedaDR,
          ObjetoImpDR: '',
          EquivalenciaDR: (ent.MonedaP.ClaveSAT == i.monedaDR) ? 1 : 1,
          NumParcialidad: i.numRepAplicados + 1,
          ImpSaldoAnt: i.saldo,
          ImpPagado: i.pagar,
          ImpSaldoInsoluto: i.saldo - i.pagar,
        }]

      }
    })

    this.ms.closeModal(ent);
    return;
  }

  closeModal() {
    this.ms.closeModal(null);
  }

}
