import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-diario-reps-page',
  templateUrl: './diario-reps-page.component.html',
  styleUrls: ['./diario-reps-page.component.scss']
})
export class DiarioRepsPageComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;


  private fb = inject(FormBuilder);
  private reportsService = inject(ReportsService);
  private _utils = inject(UtilsService);

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  contenidos: ComboBoxEntity[] = [
    { Id: 1, Nombre: "TOTAL POR RECIBO", Clave: 1, ClaveNombre: "01 TOTAL POR RECIBO" },
    { Id: 2, Nombre: "DETALLE DE FACTURAS", Clave: 2, ClaveNombre: "02 DETALLE DE FACTURAS" },
  ];

  public frmDiarioReps: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: 'DIARIO DE REPS',
    Num1: [this.contenidoSeleccionado?.Id],
  });

  ngOnInit(): void {
    let fecha = new Date();
    this.frmDiarioReps.get('Fecha1')?.setValue(fecha);
    this.frmDiarioReps.get('Fecha2')?.setValue(fecha);
    this.frmDiarioReps.get('NombreReporte')?.setValue('DIARIO DE REPS ' + this.rangoFechas);
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      this.contenidoSeleccionado = this.contenidos[0];
    }, 300);
  }

  contenidoSeleccionado?: ComboBoxEntity;
  selectedCombo(entity: any, tipo: string) {
    this.contenidoSeleccionado = entity;
    this.frmDiarioReps.get('Num1')?.setValue(this.contenidoSeleccionado?.Id);
    let filtrosCopy = _.cloneDeep(this.filtrosDiarioReps);
    filtrosCopy.FilterOptions![4].Enabled = this.selectDetalleItem?.Id === 2;
    filtrosCopy.FilterOptions![5].Enabled = this.selectDetalleItem?.Id === 2;
    filtrosCopy.FilterOptions![4].Placeholder = this.selectDetalleItem?.Id === 2 ? "" : "Filtros disponibles para el contenido a Detalle de Facturas.";
    filtrosCopy.FilterOptions![5].Placeholder = this.selectDetalleItem?.Id === 2 ? "" : "Filtros disponibles para el contenido a Detalle de Facturas.";
    this.filtrosDiarioReps = filtrosCopy;
  }

  get selectDetalleItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  public filtrosDiarioReps: ReportFilter =
    {
      ReportHeader: {
        NombreReporte: 'REPORTE DIARIO DE REPS'
      } as ReportHeader,
      TituloVisor: 'Diario De REPs',
      NombreExcel: 'DiarioDeREPs.xlsx',
      FilterOptions: [
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", HelpLine: "Claves de Sucursales a consultar separadas por coma (1,2,3)." },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente", HelpLine: "Claves de los Clientes a consultar separadas por coma (1,2,3)." },
        { Campo: 'rep.Serie', Etiqueta: 'Serie REP', Tipo: TypeFilter.text, HelpLine: "Serie de los REP que seran consultados en el reporte." },
        { Campo: 'rep.Folio', Etiqueta: 'Folio REP', Tipo: TypeFilter.text, HelpLine: "Folio del REP que seran consultados en el reporte." },
        { Campo: 'doc.Serie', Etiqueta: 'Serie Factura', Tipo: TypeFilter.text, HelpLine: "Serie de las Facturas que seran consultados en el reporte." },
        { Campo: 'doc.Folio', Etiqueta: 'Folio Factura', Tipo: TypeFilter.text, HelpLine: "Folio de las Facturas que seran consultados en el reporte." },
        { Campo: 'rep.FechaCancelacion', Etiqueta: 'Estado', Tipo: TypeFilter.list, ListaItems: [{ Id: "1", Nombre: 'Todos' }, { Id: "2", Nombre: 'Vigente' }, { Id: "3", Nombre: 'Cancelado' }], HelpLine: "Estado actual del REP." },
      ]
    };

  changeselec(event: any) {
    this.filtrosDiarioReps = event;
  }

  clickBoton() {
    this.filtrosDiarioReps.ReportHeader = this.frmDiarioReps.value;
    this.reportsService.printReport(this.filtrosDiarioReps, '/Ventas/ReporteDiarioReps');
  }

  onChangeFecha(value: any) {
    this.frmDiarioReps.get('NombreReporte')?.setValue('DIARIO DE REPS ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this._utils.getEtiquetaRangoPorFechas(this.frmDiarioReps.value.Fecha1, this.frmDiarioReps.value.Fecha2, 2).toUpperCase();
  }

}
