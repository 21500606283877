<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-cloud-arrow-up"
  subTitle="Solicitud y verificación de descargas de XMLS">
  <div class="screen-content" style="height: 100%; width:1150px;">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <form [formGroup]="myForm" autocomplete="off">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation"><a href="#solicitud" data-bs-toggle="tab" class="nav-link active"
            aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span><span
              class="d-sm-block d-none">Solicitud
              de
              descarga</span></a></li>
        <li class="nav-item" role="presentation"><a href="#verificacion" data-bs-toggle="tab" class="nav-link"
            aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-none">Tab 2</span><span
              class="d-sm-block d-none">Verificación de solicitudes</span></a></li>
      </ul>
      <div class="tab-content panel rounded-0 p-3 m-0">
        <div id="solicitud" class="tab-pane fade active show" role="tabpanel">
          <h3 class="mt-10px"><i class="fa fa-calendar-check green"></i>
            Solicitar descargas por periodo</h3>
          <p> El servicio de solicitud de descarga masiva se diseñó para permitir realizar una
            petición de descarga de CFDIs o Metadata por emisor y receptor. Esto mediante
            un servicio publicado en la página del SAT desde internet, siendo que este
            servicio solo puede ser consumido por los emisores y receptores, con lo cual se
            protege esta información.</p>
          <app-panel-base title="Información de la solicitud de descarga (Recibidos)">
            <div class="panel-content">
              <component-ui-combo-box [label]="'Empresa'" formControlName="Empresa" [tabIndex]="1" [zeroMask]="2"
                entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="0"
                (onSelectedItem)="selected($event, 'Empresa')" [listProperty]="'ClaveNombre'" />

              <components-ui-date-box label='Del' formControlName="FechaInicio" [tabIndex]="2" [widthLabelColumns]="2"
                [widthTextColumns]="3" [required]="true"></components-ui-date-box>

              <components-ui-date-box label='Al' formControlName="FechaFin" [tabIndex]="3" [widthLabelColumns]="2"
                [widthTextColumns]="3" [required]="true"></components-ui-date-box>


              <div (click)="autenticar()" class="btn btn-primary" style="margin-left: 170px;width:195px;">
                Solicitar descarga al SAT
              </div>
            </div>
          </app-panel-base>
        </div>
        <div id="verificacion" class="tab-pane fade" role="tabpanel">
          <h3 class="mt-10px"><i class="fa fa-circle-check green"></i>
            Listado de solicitudes</h3>
          <p> Es un servicio web que permite verificar el estatus de las solicitudes de descarga realizadas
            previamente a través del servicio de solicitud de descarga masiva,
            en caso de que la solicitud de descarga haya sido aceptada y se encuentre con estatus de terminado,
            este servicio de verificación devolverá los identificadores de los paquetes que conforman la solicitud de
            descarga.</p>
          <component-ui-combo-box [label]="'Empresa'" formControlName="Empresa2" [tabIndex]="4" [zeroMask]="2"
            entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="0"
            (onSelectedItem)="selected($event, 'Empresa2')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Estatus'" formControlName="Estado" [tabIndex]="5" [zeroMask]="2"
            entityName="EstadoDescargaCfdi" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="0"
            (onSelectedItem)="selected($event, 'Estado')" [listProperty]="'ClaveNombre'" />

          <div (click)="findHistory()" class="btn btn-primary mb-1" style="margin-left: 174px;width:171px;">
            Mostrar
          </div>
          <br />
          <div *ngIf="showVerify" (click)="verifyDownload(0)" class="btn btn-success mb-3" style="margin-left: 174px;width:171px;">
            Verificar (5000)
          </div>
          <gui-grid [autoResizeWidth]="false" [width]="1100" [source]="list" [rowHeight]="20" [rowSelection]="false"
            [theme]="'fabric'" [maxHeight]="400">
            <gui-grid-column header="Emisor" [width]="130" [cellEditing]="false">
              <ng-template let-item="item">
                {{ item.empresa.rfc }}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Tipo" [width]="100" [cellEditing]="false">
              <ng-template let-item="item">
                {{ item.recibidos ? 'RECIBIDOS': 'EMITIDOS' }}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Fecha operación" [width]="120" [cellEditing]="false">
              <ng-template let-item="item">
                {{getDate(item.fechaSolicitud)}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Fecha inicio" [width]="120" [cellEditing]="false">
              <ng-template let-item="item">
                {{getDate(item.fechaInicio)}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Fecha fin" [width]="120" [cellEditing]="false">
              <ng-template let-item="item">
                {{getDate(item.fechaFin)}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Fecha descarga" [width]="130" [cellEditing]="false">
              <ng-template let-item="item">
                {{item.fechaDescargado && getDate(item.fechaDescargado)}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Id Solicitud" [field]="'idSolicitud'" [width]="100" [cellEditing]="false" />
            <gui-grid-column header="Cod Estatus" [field]="'codEstatus'" [width]="100" [cellEditing]="false" />
            <gui-grid-column header="Mensaje" [field]="'mensaje'" [width]="250" [cellEditing]="false" />
          </gui-grid>


        </div>
      </div>
    </form>
  </div>
</app-container-base>
