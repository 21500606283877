import { NgModule } from '@angular/core';
import { NumberDirective } from './number-only.directive';



@NgModule({
  imports: [],
  exports: [
    NumberDirective
  ],
  declarations: [
    NumberDirective
  ],
  providers: [],
})
export class DirectivesModule { }
