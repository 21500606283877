import { Component, Input } from '@angular/core';
import { startOfDay } from 'date-fns';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-direccion-mapa-page',
  templateUrl: './direccion-mapa-page.component.html',
  styles: [
  ]
})
export class DireccionMapaPageComponent {

  @Input()
  public Direccion: string = "";

  constructor(private ms: ModalService) { }
  closeModal() {
    this.ms.closeModal(null);
  }
}
