import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { AlertResponse } from '../../interfaces/alert.interface';

@Component({
  selector: 'app-ctrl-asiento-contable',
  templateUrl: './ctrl-asiento-contable.component.html',
  styles: [
  ]
})
export class CtrlAsientoContableComponent implements OnInit {

  @Input()
  public Asientos: any[] = [];
  itemEdicion: any = null;

  @Output()
  public onClose = new EventEmitter();

  @ViewChild('edicionAsiento')
  public edicionAsiento!: ElementRef<HTMLElement>;
  private eventsService = inject(EventsService);


  constructor(private ms: ModalService) {

  }
  ngOnInit(): void {
    if (!this.Asientos) this.Asientos = [];
  }

  deleteRow(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el Asiento Contable?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.Asientos.splice(index, 1);
          this.Asientos = [...this.Asientos];
          this.onClose.emit({ items: this.Asientos, index });
        }
      }
    });


  }

  agregarEditar(index: number) {
    const b: any = this.edicionAsiento;
    if (index >= 0) {
      this.itemEdicion = { ... this.Asientos[index] };
      this.itemEdicion.Poliza = {
        Id: this.itemEdicion.Poliza.Id,
        Clave: this.itemEdicion.Poliza.Clave,
        Nombre: this.itemEdicion.Poliza.Nombre,
        ClaveNombre: `${this.itemEdicion.Poliza.Clave} ${this.itemEdicion.Poliza.Nombre}`
      };
    } else {
      this.itemEdicion = null;
    }
    this.ms.openModal(b, (e: any) => {
      if (e) {
        if (!this.Asientos) this.Asientos = [];
        if (index >= 0) {
          this.Asientos[index] = { ...e };
          this.Asientos = [...this.Asientos];
        } else {
          this.Asientos = [...this.Asientos, { ...e }]
        }
        this.onClose.emit({ items: this.Asientos, index });
      }
    });
  }

}
