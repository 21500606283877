<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="ConceptoAlmacen"
  title="Conceptos de almacén" icon="fa fa-fill-drip" subTitle="Administra la configuración de Conceptos de almacén para inventario">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Concepto'"
            entityName="ConceptoAlmacen" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="100" />
          <components-ui-text-box [label]="'Nombre corto'" formControlName="NombreCorto" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="20" />

            <component-ui-check-box [tabIndex]="4" [widthLabelColumns]="2" formControlName="EsEntrada"
            [widthTextColumns]="7" [label]="'Entrada'" />

          <component-ui-check-box [tabIndex]="5" [widthLabelColumns]="2" formControlName="EsSalida"
            [widthTextColumns]="7" [label]="'Salida'" />

          <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="2" formControlName="EsInventarioInicial"
            [widthTextColumns]="7" [label]="'Inventario inicial'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>

