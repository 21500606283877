<div class="alert-complit card border-1 mb-panel m-1 panelbasecomplit animate__animated animate__zoomIn animate__faster" cdkDrag
  style="min-width:500px;max-width:500px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div cdkDragHandle style="cursor:move;border-bottom: 1px solid #fd8204 !important;border-radius: 0px;"
    class="card-header widget-header-title p-2 h6 m-0 d-flex align-items-center bottompanel bg-gray-300 text-black">
    SW COMPLIT
  </div>
  <div class="bg-light card-body overflow-auto" style="min-height:100px; max-height:600px;">
    <div class="d-flex" style="width: 468px;">
      <i style="width:60px;" [class]="'fas ' + config.icon + ' fa-5x'"></i>
      <!-- <span style="width:308px;" class="text-black-500 p-3">{{config.message}}</span> -->
      <span style="width:408px;" class="text-dark p-3" [innerHTML]="config.message"></span>
    </div>
  </div>
  <div class="pb-1 pt-2 footer-alert">
    <div style="text-align: right;margin-top:-5px;">
      <button [tabIndex]="1600" *ngIf="config.acceptButton" style="margin-right: 5px;" (click)="accept()"
        class="btn btn-lg btn-success" #btnAcceptAlert>
        {{config.textAccept}}
      </button>
      <button [tabIndex]="1601" *ngIf="config.noButton" style="margin-right: 5px;" (click)="accept()"
        class="btn btn-lg btn-primary">{{config.textNo}}
      </button>
      <button [tabIndex]="1602" *ngIf="config.cancelButton" class="btn  btn-lg btn-danger" (click)="cancel()"
        #txtCancelar>{{config.textCancel}}</button>
    </div>
  </div>
</div>
