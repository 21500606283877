import { Component, inject } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'conteo-efectivo',
  templateUrl: './conteo-efectivo.component.html',
  styleUrls: ['./conteo-efectivo.component.scss']
})
export class ConteoEfectivoComponent {



  bVeinte: number = 0;
  bCincuenta: number = 0;
  bCien: number = 0;
  bDoscientos: number = 0;
  bQuinientos: number = 0;
  bMil: number = 0;


  mDiezCentavos: number = 0;
  mVeinteCentavos: number = 0;
  mCincuentaCentavos: number = 0;
  mUnPeso: number = 0;
  mDosPesos: number = 0;
  mCincoPesos: number = 0;
  mDiezPesos: number = 0;
  mVeintePesos: number = 0;
  mCincuentaPesos: number = 0;
  mCienPesos: number = 0;

  totalEfectivo: number = 0;

  private modalService = inject(ModalService);


  calcularBilletes(event: any, billete: number) {
    switch (billete) {
      case 20:
        this.bVeinte = Number(event.target.value) * billete;
        break;
      case 50:
        this.bCincuenta = Number(event.target.value) * billete;
        break;
      case 100:
        this.bCien = Number(event.target.value) * billete;
        break;
      case 200:
        this.bDoscientos = Number(event.target.value) * billete;
        break;
      case 500:
        this.bQuinientos = Number(event.target.value) * billete;
        break;
      default:
        this.bMil = Number(event.target.value) * billete;
        break;
    }

    this.calcularTotalEfectivo();
  }

  calcularMonedas(event: any, moneda: number) {
    switch (moneda) {
      case .1:
        this.mDiezCentavos = Number(event.target.value) * moneda;
        break;
      case .2:
        this.mVeinteCentavos = Number(event.target.value) * moneda;
        break;
      case .5:
        this.mCincuentaCentavos = Number(event.target.value) * moneda;
        break;
      case 1:
        this.mUnPeso = Number(event.target.value) * moneda;
        break;
      case 2:
        this.mDosPesos = Number(event.target.value) * moneda;
        break;
      case 5:
        this.mCincoPesos = Number(event.target.value) * moneda;
        break;
      case 10:
        this.mDiezPesos = Number(event.target.value) * moneda;
        break;
      case 20:
        this.mVeintePesos = Number(event.target.value) * moneda;
        break;
      case 50:
        this.mCincuentaPesos = Number(event.target.value) * moneda;
        break;
      default:
        this.mCienPesos = Number(event.target.value) * moneda;
        break;
    }
    this.calcularTotalEfectivo();
  }


  calcularTotalEfectivo() {
    this.totalEfectivo = this.bVeinte + this.bCincuenta + this.bCien + this.bDoscientos + this.bQuinientos + this.bMil + this.mDiezCentavos + this.mVeinteCentavos + this.mCincuentaCentavos + this.mUnPeso + this.mDosPesos + this.mCincoPesos + this.mDiezPesos + this.mVeintePesos + this.mCincuentaPesos + this.mCienPesos;
  }

  close() {
    this.modalService.closeModal(null);
  }
}
