import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { AppVariablesService } from 'src/app/home/services/app-variables.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { EventListenersModifier } from '@popperjs/core/lib/modifiers/eventListeners';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { BaseService } from 'src/app/service/base.service';
import { PermisosTableros } from '../../interfaces/rol.interface';
import { AcumuladoSinAnticiposMensual } from 'src/app/ventas/interfaces/venta.interface';
import { PresupuestoVentaSucursalDTO, PresupuestoVentaVendedorDTO } from 'src/app/ventas/interfaces/presupuestos.interface';
import { ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'principal-page',
  templateUrl: './principal-page.html',
  styleUrls: ['./principal-page.css']
})

export class PrincipalPage implements OnInit {
  @Input()
  permisosTableros!: PermisosTableros;

  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective!: DaterangepickerDirective;
  appVariables = this.appVariablesService.getAppVariables();
  info: UserLogged = {} as UserLogged;
  chart2Options: any = { series: undefined };
  chartVendedores: any = { series: undefined };
  chartMisVentas: any = { series: undefined };
  chartMensual: any = { series: undefined };
  //Acumulados
  anticiposFacturados: number = 0;
  totalFacturado: number = 0;
  familias: FamiliaProducto[] = [];
  tienePresupuestoSucursales: boolean = true
  totalPresupuestoSucursales: number = 0;
  tienePresupuestoVendedores: boolean = true
  totalPresupuestoVendedores: number = 0;
  pieChart: any;
  areaChart: any;
  prevDate: any = moment().subtract(15, 'days').format('D MMMM') + ' - ' + moment().subtract(8, 'days').format('D MMMM YYYY');
  selected: { startDate: moment.Moment, endDate: moment.Moment } | null;
  ranges: any = {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
    'Mes anterior': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };
  locale: any = {
    format: 'DD/MM/YYYY',
    displayFormat: 'DD/MM/YYYY',
    separator: ' al ',
    cancelLabel: 'Cancelar',
    applyLabel: 'Aplicar',
    clearLanel: 'Borrar'
  };
  open(e: any) {
    this.pickerDirective.open(e);
  }


  private baseService = inject(BaseService);
  constructor(private appVariablesService: AppVariablesService,
    private facturacionService: FacturacionService,
    private utileService: UtilsService) {
    this.selected = {
      startDate: moment().subtract(1, 'days'),
      endDate: moment().subtract(1, 'days')
    };
  }

  get getPorcentajePresSucs(): number {
    let total: number = 0;

    if (this.chart2Options != undefined) {
      if (this.chart2Options.series != undefined) {
        if (this.chart2Options.series?.length > 0) {
          let totalPres = 0, totalVenta = 0;
          this.chart2Options?.series[0].data.forEach((i: any) => { totalPres += i });
          this.chart2Options?.series[1].data.forEach((i: any) => { totalVenta += i });
          this.totalPresupuestoSucursales = totalPres;
          if (totalPres > 0) {
            total = (totalVenta * 100) / totalPres;
          }
        }
      }
    }
    return total;
  }

  get getPorcentajePresVen(): number {
    let total: number = 0;

    if (this.chartVendedores != undefined) {
      if (this.chartVendedores.series != undefined) {
        if (this.chartVendedores.series?.length > 0) {
          let totalPres = 0, totalVenta = 0;
          this.chartVendedores?.series[0].data.forEach((i: any) => { totalPres += i });
          this.chartVendedores?.series[1].data.forEach((i: any) => { totalVenta += i });
          this.totalPresupuestoVendedores = totalPres;
          if (totalPres > 0) {
            total = (totalVenta * 100) / totalPres;
          }
        }
      }
    }
    return total;
  }

  getChartOptions() {
    this.facturacionService.getPresupuestosSucursales(new Date().getFullYear(), new Date().getMonth() + 1).subscribe((meses) => {
      this.chart2Options = this.convertDataSucursalesPresupuestos(meses);
      this.tienePresupuestoSucursales = meses.length > 0;
    })
  }

  get getAccesos(): boolean {
    return !(!this.permisosTableros?.verTableroVentas && !this.permisosTableros?.verTableroPowerBI && !this.permisosTableros?.verTableroGastos && !this.permisosTableros?.verTableroMisVentas)
  }

  convertDataSucursalesPresupuestos(meses: PresupuestoVentaSucursalDTO[]) {
    let data: any = [];
    let ventas: any = [];
    let sucursales: any = [];
    meses.forEach((i) => {
      data = [...data, i.presupuesto];
      ventas = [...ventas, i.venta];
      sucursales = [...sucursales, i.sucursal];
    })
    return {
      series: [
        { name: 'Presupuesto', data: data },
        { name: 'Venta', data: ventas },
      ],
      title: {
        text: 'PRESUPUESTOS DE VENTAS DE SUCURSALES',
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: this.appVariables.font.bodyFontFamily,
          color: this.appVariables.color.bodyColor
        }
      },
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      legend: {
        fontFamily: this.appVariables.font.bodyFontFamily,
        labels: { colors: this.appVariables.color.bodyColor }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.appVariables.font.bodyFontFamily
        }
      },
      grid: { borderColor: this.appVariables.color.borderColor },
      dataLabels: { enabled: false },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: [this.appVariables.color.primary, this.appVariables.color.success],
      xaxis: {
        categories: sucursales,
        axisBorder: {
          show: true,
          color: this.appVariables.color.borderColor,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: -1
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: this.appVariables.color.borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      yaxis: {
        title: {
          text: '$ (MILES)',
          style: {
            color: 'rgba(' + this.appVariables.color.bodyColorRgb + ', .5)',
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight
          }
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      fill: { opacity: 1 }
    };
  }

  getChartVendedores() {

    this.facturacionService.getPresupuestosVendedores(new Date().getFullYear(), new Date().getMonth() + 1).subscribe((meses) => {
      this.chartVendedores = this.convertDataVendedoresPresupuestos(meses);
      this.tienePresupuestoVendedores = meses.length > 0;
    })
  }

  convertDataVendedoresPresupuestos(meses: PresupuestoVentaVendedorDTO[]) {
    let data: any = [];
    let ventas: any = [];
    let vendedores: any = [];
    meses.forEach((i) => {
      data = [...data, i.presupuesto];
      ventas = [...ventas, i.venta];
      vendedores = [...vendedores, i.vendedor];
    })
    return {
      series: [
        { name: 'Presupuesto', data: data },
        { name: 'Venta', data: ventas },
      ],
      title: {
        text: 'PRESUPUESTOS DE VENTAS DE VENDEDORES',
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: this.appVariables.font.bodyFontFamily,
          color: this.appVariables.color.bodyColor
        }
      },
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      legend: {
        fontFamily: this.appVariables.font.bodyFontFamily,
        labels: { colors: this.appVariables.color.bodyColor }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.appVariables.font.bodyFontFamily
        }
      },
      grid: { borderColor: this.appVariables.color.borderColor },
      dataLabels: { enabled: false },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: [this.appVariables.color.primary, this.appVariables.color.success],
      xaxis: {
        categories: vendedores,
        axisBorder: {
          show: true,
          color: this.appVariables.color.borderColor,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: -1
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: this.appVariables.color.borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      yaxis: {
        title: {
          text: '$ (MILES)',
          style: {
            color: 'rgba(' + this.appVariables.color.bodyColorRgb + ', .5)',
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight
          }
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      fill: { opacity: 1 }
    };
  }



  getChartMisVentas() {
    return {
      series: [
        { name: 'Presupuesto', data: [350] },
        { name: 'Venta', data: [220] },
      ],
      title: {
        text: 'PRESUPUESTO DE VENDEDOR',
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: this.appVariables.font.bodyFontFamily,
          color: this.appVariables.color.bodyColor
        }
      },
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      legend: {
        fontFamily: this.appVariables.font.bodyFontFamily,
        labels: { colors: this.appVariables.color.bodyColor }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.appVariables.font.bodyFontFamily
        }
      },
      grid: { borderColor: this.appVariables.color.borderColor },
      dataLabels: { enabled: false },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: [this.appVariables.color.primary, this.appVariables.color.success],
      xaxis: {
        categories: ['FRANCISCO RIVERA'],
        axisBorder: {
          show: true,
          color: this.appVariables.color.borderColor,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: -1
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: this.appVariables.color.borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      yaxis: {
        title: {
          text: '$ (MILES)',
          style: {
            color: 'rgba(' + this.appVariables.color.bodyColorRgb + ', .5)',
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight
          }
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      fill: { opacity: 1 }
    };
  }

  getChartMemsuales() {
    this.facturacionService.ObtenerVentasMensuales(this.info.empresa!.numero, new Date().getFullYear()).subscribe((meses) => {
      this.chartMensual = this.convertDataMensual(meses);
    })
  }

  convertDataMensual(meses: AcumuladoSinAnticiposMensual[]) {
    let data: any = [];
    meses.forEach((i) => {
      data = [...data, i.importe]
    })
    return {
      series: [
        { name: 'VENTA', data: data },
      ],
      title: {
        text: 'VENTAS MENSUALES',
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: this.appVariables.font.bodyFontFamily,
          color: this.appVariables.color.bodyColor
        }
      },
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      legend: {
        fontFamily: this.appVariables.font.bodyFontFamily,
        labels: { colors: this.appVariables.color.bodyColor }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.appVariables.font.bodyFontFamily
        }
      },
      grid: { borderColor: this.appVariables.color.borderColor },
      dataLabels: { enabled: false },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: [this.appVariables.color.primary, this.appVariables.color.success],
      xaxis: {
        categories: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
        axisBorder: {
          show: true,
          color: this.appVariables.color.borderColor,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: -1
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: this.appVariables.color.borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      yaxis: {
        title: {
          text: '$ (MILES)',
          style: {
            color: 'rgba(' + this.appVariables.color.bodyColorRgb + ', .5)',
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight
          }
        },
        labels: {
          style: {
            colors: this.appVariables.color.bodyColor,
            fontSize: '12px',
            fontFamily: this.appVariables.font.bodyFontFamily,
            fontWeight: this.appVariables.font.bodyFontWeight,
            cssClass: 'apexcharts-xaxis-label',
          }
        }
      },
      fill: { opacity: 1 }
    };
  }


  ngModelChange(e: any) {
    var gap = (e.endDate).diff((e.startDate), 'days');
    this.prevDate = moment(e.startDate).subtract(gap, 'days').format('D MMMM') + ' - ' + moment(e.startDate).subtract(1, 'days').format('D MMMM YYYY');
  }

  handleGetDate(minusDate: number) {
    var d = new Date();
    var a = d.setDate(d.getDate() - minusDate);
    return a;
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.chart2Options = this.getChartOptions();
    this.chartVendedores = this.getChartVendedores();
    this.chartMisVentas = this.getChartMisVentas();
    this.getChartMemsuales();
    const d: Date = new Date();
    this.facturacionService.getAnticipoFacturado(this.info.empresa!.numero, d.getFullYear(), d.getMonth() + 1, d.getDate()).subscribe((anticipos) => {
      this.anticiposFacturados = anticipos;
    })
    this.facturacionService.getFacturado(this.info.empresa!.numero, d.getFullYear(), d.getMonth() + 1, d.getDate()).subscribe((total) => {
      this.totalFacturado = total;
    })
    this.facturacionService.getFamiliasTablero().subscribe((familias) => {
      this.familias = familias;
    })

    this.facturacionService.VerificarCodigosPostales().subscribe(() => { });
  }
}
