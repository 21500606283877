<div class="card text-center border-0" cdkDrag
  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Entregado y Por Entregar
    </span>
  </div>
  <div class="card-body bg-light">
    <div style="text-align: right;margin-right: 3px;">
      <a class="btn btn-sm btn-white mb-3px" (click)="imprimirEntregado()"><i class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
        Imprimir</a>
    </div>
    <app-panel-base title="Conceptos">
      <div class="panel-content">
        <table class="blueTable" style="width:600px;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th style="width: 100px;">Producto</th>
              <th style="width: 20px;">Cantidad</th>
              <th style="width: 20px;">Entregado</th>
              <th style="width: 20px;">Saldo</th>
            </tr>
          </thead>
          <tbody>
            <tr
              [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
              *ngFor="let item of lista; let index = index;">
              <td class="text-dark" style="width:100px">
                [{{item.clave}}] {{item.nombre}}
              </td>
              <td class="text-dark" style="width:80px;text-align: right;">
                {{item.cantidad | number:'1.2-6'}}
              </td>
              <td class="text-dark" style="width:30px;padding-right:5px;text-align: right;">
                {{item.entregado | number:'1.2-6'}}
              </td>
              <td class="text-dark" style="width:30px;padding-right:5px;text-align: right;">
                {{item.restante | number:'1.2-6'}}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <gui-grid [source]="lista" [autoResizeWidth]="false" [width]="500" [virtualScroll]="true" [rowHeight]="20"
          [rowSelection]="false" [theme]="'fabric'" [maxHeight]="250">
          <gui-grid-column header="Producto" [width]="200" [cellEditing]="false">
            <ng-template let-item="item">
              [{{item.clave}}] {{item.nombre}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Cantidad" [width]="100" [cellEditing]="false">
            <ng-template let-item="item">
              {{item.cantidad | number:'1.2-6'}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Entregado" [width]="100" [cellEditing]="false">
            <ng-template let-item="item">
              {{item.entregado | number:'1.2-6'}}
            </ng-template>
          </gui-grid-column>
          <gui-grid-column header="Saldo" [width]="100" [cellEditing]="false">
            <ng-template let-item="item">
              {{item.restante | number:'1.2-6'}}
            </ng-template>
          </gui-grid-column>
        </gui-grid> -->
      </div>
    </app-panel-base>

  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>
