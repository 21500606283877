<div class="tree-container bg-white" style="box-shadow: rgba(0, 0, 0, 0.35) -1px 2px 3px 0px;">
  <input (keydown.escape)="escape()" placeholder="Escriba y presione enter para buscar" (keydown.enter)="find()"
    type="text" class="form-control" #txtBuscar>
  <hr>
  <ng-scrollbar style="height: 450px;width: 350px;">
    <div *ngFor="let item of source; let i = index" style="padding-bottom: 5px;">
      <input (click)="addSelection(item, i)" *ngIf="multiSelect" class="form-check-input" type="checkbox"
        style="margin-left: 4px;" />
      <i (click)="onSelect(item)" [class]="getCaret(item)"
        style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
      <span (click)="onSelect(item)" class="text-dark tree-item {{item.isSelected?'selected-tree':''}}"
        style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item.claveGeneral}} - {{item.nombre |
        uppercase}}</span>
      <span *ngIf="item.numeroHijos>0 && !multiSelect"
        style="right: 0px;position: absolute;">({{item.numeroHijos}})</span>

      <div *ngIf="item.isExpanded" class="animate__animated animate__fadeIn" style="padding-left: 30px;">
        <div *ngFor="let item2 of item.hijos;">
          <i (click)="onSelect2(item2, item)" [class]="getCaret(item2)"
            style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
          <span (click)="onSelect2(item2, item)" class="text-dark tree-item {{item2.isSelected?'selected-tree':''}}"
            style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item2.claveGeneral}} {{item2.nombre |
            uppercase}}</span>
          <span *ngIf="item2.numeroHijos>0" style="right: 0px;position: absolute;">({{item2.numeroHijos}})</span>
          <div *ngIf="item2.isExpanded" class="animate__animated animate__fadeIn" style="padding-left: 30px;">
            <div *ngFor="let item3 of item2.hijos;">
              <i (click)="onSelect2(item3, item2)" style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"
                [class]="getCaret(item3)"></i>
              <i (click)="onSelect2(item3, item2)" style="cursor: pointer;margin-left: 4px;"></i>
              <span (click)="onSelect2(item3, item2)"
                class="text-dark tree-item {{item3.isSelected?'selected-tree':''}}"
                style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item3.claveGeneral}} {{item3.nombre |
                uppercase}}</span>
              <span *ngIf="item3.numeroHijos>0" style="right: 0px;position: absolute;">({{item3.numeroHijos}})</span>
              <div *ngIf="item3.isExpanded" class="animate__animated animate__fadeIn" style="padding-left: 30px;">
                <div *ngFor="let item4 of item3.hijos;">
                  <i (click)="onSelect2(item4, item3)" style="cursor: pointer;margin-left: 4px;"></i>
                  <span (click)="onSelect2(item4, item3)"
                    class="text-dark tree-item {{item4.isSelected?'selected-tree':''}}"
                    style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item4.claveGeneral}} {{item4.nombre |
                    uppercase}}</span>
                  <span *ngIf="item4.numeroHijos>0"
                    style="right: 0px;position: absolute;">({{item4.numeroHijos}})</span>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
