<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Neumatico"
  title="Neumáticos" icon="fa fa-truck-monster" subTitle="Catálogo de neumáticos para los vehículos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Neumático'" entityName="Neumatico" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Marca'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="150" />

          <components-ui-text-box [label]="'Medida'" [required]="true" formControlName="Medida" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />

          <components-ui-number-box [tabIndex]="4" [maxLength]="10" [label]="'Odometro inicial'"
            formControlName="OdometroInicial" [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-number-box [tabIndex]="5" [maxLength]="10" [label]="'Recorrido'" formControlName="Recorrido"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-number-box [tabIndex]="6" [maxLength]="10" [label]="'Milimetros'" formControlName="Milimetros"
            [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box [tabIndex]="7" [maxLength]="20" [required]="true" [label]="'Costo'"
            formControlName="Costo" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <component-ui-check-box [tabIndex]="8" [widthLabelColumns]="2" formControlName="EsRenovacion"
            [widthTextColumns]="7" [label]="'¿Renovación?'" />

          <components-ui-date-box formControlName="Fecha" [required]="true" label="Fecha De Compra" [tabIndex]="9"
            [widthLabelColumns]="2" />

          <component-ui-check-box [tabIndex]="10" [widthLabelColumns]="2" formControlName="Baja" [widthTextColumns]="7"
            [label]="'Baja?'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
