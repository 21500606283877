import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'balance-general-comparativo',
  templateUrl: './balance-general-comparativo.component.html',
})
export class BalanceGeneralComparativoComponent implements AfterViewInit, OnInit {



  @ViewChild('txtFileConfiguracion')
  public txtFileConfiguracion!: ElementRef<HTMLInputElement>;


  //* injects
  private utilsService = inject(UtilsService);
  private contabilidadService = inject(ContabilidadService);
  private fb = inject(FormBuilder)
  private reportsService = inject(ReportsService)
  private eventsService = inject(EventsService)

  //* variables globales
  listaMeses: any[] = [];
  listaAnios: any[] = [];
  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  anio1Seleccionado?: ComboBoxEntity;
  anio2Seleccionado?: ComboBoxEntity;
  loadComplete: boolean = false;
  file: any;
  //mesActual:number=0;
  private readonly baseUrl: string = environment.baseUrlApi;

  get getMes1(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes1Seleccionado) {
      result = this.mes1Seleccionado;
    }
    return result;
  }
  get getMes2(): ComboBoxEntity | null {
    //this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes2Seleccionado) {
      result = this.mes2Seleccionado;
    }
    return result;
  }


  get rangoFechas() {
    let result = "";
    if (!this.anio1Seleccionado) return result;
    if (!this.mes2Seleccionado) return result;

    const diaUltimo: number = this.utilsService.getUltimoDiaMes(this.anio1Seleccionado.Id, this.mes1Seleccionado!.Id);

    result = diaUltimo + " DE " + this.utilsService.getMes(this.mes1Seleccionado!.Id) + " DE " + this.anio1Seleccionado.Clave;
    return result.toUpperCase();
  }



  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<HTMLElement>;;
  public filtrosReporte: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Balance comparativo',
      NombreExcel: 'Balance comparativo.xlsx',
      FilterOptions: [
        { Campo: 'mpactual2.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mpactual2.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Num1: 0,
    Num2: 0,
    Num3: 0,
    Num4: 0,
    Opc1: false,
    Opc2: false,
    Opc3: false,
    Opc4: false
  });



  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }


  ngAfterViewInit(): void {

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);

    //this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;

  }


  ngOnInit(): void {

    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);


    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);

    //this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;



    this.myForm.get('Num1')?.setValue(mesItem.Clave);
    this.myForm.get('Num3')?.setValue(mesItem.Clave);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }

        this.anio1Seleccionado = this.listaAnios[0];
        this.myForm.get('Num2')?.setValue(this.anio1Seleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);

        if (this.listaAnios.length > 1) {
          this.anio2Seleccionado = this.listaAnios[1];
        } else {
          this.anio2Seleccionado = this.listaAnios[0];
        }


        this.myForm.get('Num4')?.setValue(this.anio2Seleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);


      }
    })





  }

  // clicMenu(value: string) {
  //   if (value == 'Cargar') {
  //     this.txtFileConfiguracion.nativeElement.click();
  //   } else if (value == 'Descargar') {


  //   }
  // }

  get selectAnio1ManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    return this.anio1Seleccionado!;
  }

  get selectAnio2ManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    return this.anio2Seleccionado!;
  }


  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Num1') {
      this.mes1Seleccionado = entity;
      this.mes2Seleccionado = entity;
    } else if (field == 'Num2') {
      this.anio1Seleccionado = entity;
    } else if (field == 'Num3') {
      this.mes2Seleccionado = entity;
    } else if (field == 'Num4') {
      this.anio2Seleccionado = entity;
    }

    this.myForm.controls[field].setValue(entity.Clave);
  }



  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {


    this.myForm.get('Opc4')?.setValue(true);

    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteBalanceComparativo');
  }

  CargarConfiguracion() {


  }






}
