import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterContentList, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { CuentasPorCobrarService } from 'src/app/cuentas-por-cobrar/services/cxc.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-relaciones-analiticas-cxc-page',
  templateUrl: './relaciones-analiticas-cxc-page.component.html',
  styles: [
  ]
})
export class RelacionesAnaliticasCxcPageComponent implements AfterViewInit, OnInit {

  private reportsService = inject(ReportsService);
  private utilsService = inject(UtilsService);
  private cuentasPorCobrarService = inject(CuentasPorCobrarService);
  private changeDetector = inject(ChangeDetectorRef);
  private eventsService = inject(EventsService);

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  usuarioLoggeado!: UserLogged;

  listaMeses: any[] = [];
  listaAnios: any[] = [];
  anioSeleccionado?: ComboBoxEntity;
  mesSeleccionado?: ComboBoxEntity;
  menuId: string = "relacionesanaliticascxc";



  public desgloseLista: ReportFilterContentList[] = [{ Nombre: 'Totales por sucursal' },
  { Nombre: 'Totales por cliente' },
  { Nombre: 'Detallado por cliente' }]

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa/Sucursal', Orden: 'empClave,sucClave' }]

  public luegoPorLista: ReportFilterOrderList[] = [
  { Nombre: 'Clave del cliente', Orden: 'cliClave' },
  { Nombre: 'Nombre del cliente', Orden: 'cliNombre' }]

  public filtrosReporte: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      DesgloseLista: this.desgloseLista,
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      Fecha1: new Date(),
      NombreReporte: '',
      TituloVisor: 'Relaciones analíticas CXC',
      NombreExcel: 'Relaciones analíticas CXC.xlsx',
      ExportarExcel: false,
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'ctaCXC.Clave', Etiqueta: 'Cuenta de cxc.', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxc" },
        { Campo: 'conc.clave', Etiqueta: 'Concepto.', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoCxc" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'usu.Clave', Etiqueta: 'Vendedor factura', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
      ],
    };

  public myForm: FormGroup = this.fb.group({
    Fecha1: null,
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
    Opc5: [false],
    Opc6: [false],
    Opc7: [false],
    Num1: [0],
    Num2: [0],
    Num3: [0]
  });

  constructor(private fb: FormBuilder) { }



  get fechaFormatoMesAnio() {
    let anioSeleccionado: number = this.anioSeleccionado ? this.anioSeleccionado.Id : 0;
    let mesSeleccionado: number = this.mesSeleccionado ? this.mesSeleccionado.Id : 0;
    const fecha = new Date(anioSeleccionado, mesSeleccionado - 1, 1);
    return this.utilsService.Date2Str(fecha, 6).toUpperCase();
  }


  ngAfterViewInit(): void {

    this.listaMeses = this.utilsService.getListaMeses();
    const mesActual = new Date().getMonth() + 1;
    this.mesSeleccionado = this.listaMeses.find(p => p.Id == mesActual);
  }


  ngOnInit(): void {
    this.usuarioLoggeado = this.utilsService.getUserLogged();
    const fecha: string = this.utilsService.Date2Str(new Date(), 6);

    this.focus('txtNombreReporte');
    let fecha1: Date = new Date();
    this.listaMeses = this.utilsService.getListaMeses();
    const mesActual = new Date().getMonth() + 1;
    this.mesSeleccionado = this.listaMeses.find(p => p.Id == mesActual);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.cuentasPorCobrarService.obtenerListaDeAniosconMovimientosDeCXC().subscribe(result => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS DE CLIENTE DEL MES ' + this.fechaFormatoMesAnio);
      }
    })
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  selectedCombo(entity: any, tipo: string) {
    if (tipo == 'Mes') {
      this.mesSeleccionado = entity;
    } else {
      this.anioSeleccionado = entity;
    }
  }

  get selectMesManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS DE CLIENTE DEL MES DE ' + this.fechaFormatoMesAnio);
    return this.mesSeleccionado!;
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS DE CLIENTE DEL MES DE ' + this.fechaFormatoMesAnio);
    return this.anioSeleccionado!;
  }

  clickBoton(event: string) {
    let fecha = new Date(this.anioSeleccionado!.Id, this.mesSeleccionado!.Id - 1, 1);
    this.myForm.get('Fecha1')?.setValue(fecha);
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/CuentasPorCobrar/ReporteRelacionesAnaliticas');
  }
}
