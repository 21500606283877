<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="TipoGastoUnidades" title="Tipos de Gasto para las Unidades" icon="fa fa-screwdriver-wrench"
  subTitle="Administra los Tipos de Gastos para las Unidades">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Tipo de Gasto'"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="TipoGastoUnidades" />
          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
            <components-ui-text-box [label]="'Observaciones'" formControlName="Observaciones" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />
        </div>
      </app-panel-base>
      <app-panel-base title="Asientos contables por póliza a generar">
        <div class="panel-content">

          <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
