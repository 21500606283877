<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  [getLast]="false" subTitle="Captura de Movimientos de Cuentas por Cobrar" (onClickBarButton)="onClickBarButton($event)"
  [activeButtons]="activeButtons">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div class="invoice"
          style="width: 1200px;background-color: var(--bs-app-header-input-bg); padding-right: 0px; padding-top: 0px; padding-left: 0px;">
          <div style="display: flex;width: 1028px">
            <app-panel-base title="Información de la Captura de Movimientos de Cuentas por Cobrar" [style]="'height:180px;width:935px'" >
              <div class="panel-content d-flex flex-column" style="height: 40%;">
                <div class="mb-3">
                  <div class="d-flex">
                    <components-ui-date-box label="Fecha" [tabIndex]="1" formControlName="Fecha" [widthLabelColumns]="8" style="width:10%; margin-right: 10px;"
                      [widthTextColumns]="6" [topLabel]="true" [tabIndex]="1"  [required]="true" [enabled]="getBlockCombos"/>

                    <component-ui-combo-box style="width:30%; margin-right: 10px;" formControlName="TipoMovimiento"
                      [topLabel]="true" label="Tipo de movimiento" [inputProperty]="'Nombre'" [zeroMask]="3"
                      [entityName]="'TipoMovimientoCXC'" [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12"
                      (onSelectedItem)="selectTipoMovto($event)" [widthRightColumns]="0" [required]="true"  [enabled]="getBlockCombos"
                      #tipoMovCxc />

                    <components-ui-number-box style="width:10%; margin-right: 10px;" [topLabel]="true"
                      formControlName="Codigo" label='Folio' [tabIndex]="3" [widthLabelColumns]="12"
                      [widthTextColumns]="12" (blur)="blurFolio($event)"  (keypress)="onKeyPressFolio($event)"
                       #txtFolioMovCXC />
                  </div>
                </div>
                <div class="d-flex">
                  <div style="width:60%; align-items:start;">
                    <div class="row form-group">
                      <div style="display: flex;padding-top: 5px;">
                        <div style="width:20%">
                          <label class="text-left height-label">Observaciones:</label>
                        </div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div style="display: flex; justify-content: flex-start;width:100%;">
                        <textarea maxlength="1000" formControlName="Observaciones" class="w-100" rows="2" [tabIndex]="4" (keydown)="onKeyDownObs($event)" (keypress)="onKeyPressFolio($event)"
                           cols="50" #txtObservaciones></textarea>
                      </div>
                    </div>
                  </div>
                  <div style="width:50%; padding-top: 10px;">
                    <div class="widget-list mb-0" style="display: flex; justify-content: flex-end;"
                      *ngIf="myForm.value.UsuarioCaptura">
                      <div class="widget-list-item">
                        <div class="widget-list-media">
                          <i class="fas fa-user-check fa-lg text-green"></i>
                        </div>
                        <div class="widget-list-content">
                          <h4 class="widget-list-title">{{myForm.value.UsuarioCaptura.Nombre}}</h4>
                          <p class="widget-list-desc">Usuario elaboró</p>
                        </div>
                      </div>
                      <div class="widget-list-item" *ngIf="myForm.value.UsuarioCancela">
                        <div class="widget-list-media">
                          <i class="fas fa-user-xmark fa-lg text-red"></i>
                        </div>
                        <div class="widget-list-content">
                          <h3 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h3>
                          <p class="widget-list-desc">Usuario cancela</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-panel-base>
            <div class="invoice-date" style="padding-right: 0px; padding-top: 0px; padding-left:5px;">
              <app-panel-base title="Opciones" [style]="'height:180px;width:232px'" *ngIf="this.myForm.value.Id > 0 ">
                <div class="panel-content">
                  <div class="d-flex" style="width:100%;flex-direction: column;">
                    <div *ngIf="!myForm.value.FechaCancelacion && !myForm.value.FechaAplicacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                    <div *ngIf="!myForm.value.FechaCancelacion && myForm.value.FechaAplicacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                    <div *ngIf="myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelado</div>
                    <div (click)="openCancel()" *ngIf="!myForm.value.FechaCancelacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
                  </div>
                </div>
              </app-panel-base>
            </div>
          </div>
          <div class="detallecxc-header">
            <div class="invoice-detail" id="divMovCxc">
              <app-panel-base title="Conceptos" [style]="'width:1180px; '">
                <div class="panel-content">
                  <gui-grid [rowDetail]="rowDetail" [autoResizeWidth]="false" [width]="1150" [source]="source" [loading]="loading"
                    [maxHeight]="280" [rowHeight]="20" [virtualScroll]="true" [rowSelection]="false" [theme]="'fabric'"
                    (sourceEdited)="sourceEdited($event)" (cellEditSubmitted)="cellEditSubmitted()"
                    (cellEditEntered)="cellEditEntered($event)" [cellEditing]="cellEditing" #gridMovimientos>
                    <gui-grid-column index-column="0" [cellEditing]="false" header="" [width]="30"
                      [align]="GuiColumnAlign.CENTER">
                      <ng-template let-item="item" let-index="index">
                        <i (click)="deleteRow(index)" *ngIf="item.Cliente?.Id>0 && permiteEliminar"  class="fa fa-rectangle-xmark"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column index-column="1" header="Cliente" [width]="60" [field]="'ClienteClave'"
                      [type]="'number'" view="text">
                      <ng-template let-value="value">
                        {{value> 0?value:''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Nombre Cliente." [width]="140" [field]="'ClienteNombre'"
                      [cellEditing]="false" [type]="'string'">
                    </gui-grid-column>
                    <gui-grid-column header="Cuenta" [width]="60" [field]="'CuentaClave'" [cellEditing]="true" view="text"
                      [type]="'number'">
                      <ng-template let-value="value">
                        {{value> 0?value:''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Nombre cta." [width]="140" [field]="'CuentaNombre'" [cellEditing]="false"
                      [type]="'string'"></gui-grid-column>
                    <gui-grid-column header="Concepto" [width]="80" [field]="'ConceptoClave'" [type]="'number'" [cellEditing]="true" view="text">
                      <ng-template let-value="value">
                        {{value> 0?value:''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Nombre conc." [width]="140" [field]="'ConceptoNombre'"
                      [cellEditing]="false" [type]="'string'">
                    </gui-grid-column>
                    <gui-grid-column header="Serie Orig." [width]="70" [field]="'SerieOrigina'" [cellEditing]="true"
                      [type]="'string'">
                    </gui-grid-column>
                    <gui-grid-column header="Folio Orig." [width]="70" [field]="'FolioOrigina'" [cellEditing]="true"
                      [type]="'number'" view="text">
                      <ng-template let-value="value">
                        {{value> 0?value:''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Cargo" [width]="80" [field]="'ImporteCargo'" [type]="'number'"
                      [cellEditing]="true" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Abono" [width]="80" [field]="'ImporteAbono'" [type]="'number'"
                      [cellEditing]="true" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Serie Afec." [width]="70" [field]="'SerieAfecta'" [cellEditing]="true"
                      [type]="'string'">
                    </gui-grid-column>
                    <gui-grid-column header="Folio Afec." [width]="70" [field]="'FolioAfecta'" [cellEditing]="true"
                      [type]="'number'" view="text">
                      <ng-template let-value="value">
                        {{value> 0?value:''}}
                      </ng-template>
                    </gui-grid-column>
                  </gui-grid>
                </div>
              </app-panel-base>
            </div>
          </div>
        </div>
      </div>
      <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">CANCELADO: {{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
    </form>
  </div>

  <ng-template #busquedaClientesMovCxc let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Clientes" [columns]="getClienteColumns" [entityName]="'Cliente'"
      [filter]="getFilterCliente" [propertys]="getClientePropertys" [orders]="'Nombre|asc'" #busquedaClientesMovCxc />
  </ng-template>

  <ng-template #busquedaCuentaMovCxc let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Cuentas de Cuentas por Pagar" [columns]="getCuentaColumns" [entityName]="'CuentaCxc'"
      [filter]="getFilterCuenta" [propertys]="getCuentaPropertys" [orders]="'Nombre|asc'" #busquedaCuentaMovCxc />
  </ng-template>

  <ng-template #busquedaConceptoMovCxc let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Conceptos de Cuentas por Pagar" [columns]="getConceptoColumns" [entityName]="'ConceptoCxc'"
      [filter]="getFilterConcepto" [propertys]="getConceptoPropertys" [orders]="'Nombre|asc'" #busquedaConceptoMovCxc />
  </ng-template>

  <ng-template #modalCancelarMovCxc let-modal let-c="close">
    <opcion-cancelar-page></opcion-cancelar-page>
  </ng-template>


  <!-- <ng-template #modalCancelarInvFisico let-modal let-c="close">
    <app-opcion-cancelacion-traspaso-page />
  </ng-template> -->




</app-container-base>
