import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { NgxEditorModule } from 'ngx-editor';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SeriesResguardosPageComponent } from './pages/series-resguardos-page/series-resguardos-page.component';
import { RemisionesResguardosPageComponent } from './pages/remisiones-resguardos-page/remisiones-resguardos-page.component';
import { ListadoResguardosPageComponent } from './pages/listado-resguardos-page/listado-resguardos-page.component';
import { VentasModule } from '../ventas/ventas.module';
import { ConfiguracionResguardosPageComponent } from './pages/configuracion-resguardos-page/configuracion-resguardos-page.component';
import { CancelaRemisionPageComponent } from './pages/cancela-remision-page/cancela-remision-page.component';
import { VerificadorRemisionesResguardosComponent } from './pages/reportes/verificador-remisiones-resguardos/verificador-remisiones-resguardos.component';
import { CtrlBusquedaFacturasResguardoComponent } from './pages/ctrl-busqueda-facturas-resguardo/ctrl-busqueda-facturas-resguardo.component';

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    DateBoxComponent,
    NgxDaterangepickerMd.forRoot(),
    GuiGridModule,
    NgxEditorModule,
    NgScrollbarModule,
    DragDropModule,
    VentasModule,
  ],
  exports: [],
  declarations: [
    SeriesResguardosPageComponent,
    RemisionesResguardosPageComponent,
    ListadoResguardosPageComponent,
    ConfiguracionResguardosPageComponent,
    CancelaRemisionPageComponent,
    VerificadorRemisionesResguardosComponent,
    CtrlBusquedaFacturasResguardoComponent
  ],
  providers: [],
})
export class ResguardosModule { }
