import { Component, Input, inject, OnInit, forwardRef, ChangeDetectorRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';


@Component({
  selector: 'cuentas-pagos-cxc',
  templateUrl: './cuentas-pagos-cxc.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CuentasPagosCxcComponent),
    }],
  styleUrls: ['./cuentas-pagos-cxc.component.scss'],
})
export class CuentasPagosCxcComponent implements OnInit, ControlValueAccessor {
  private frmBuilder = inject(FormBuilder);
  @Input()
  clienteId: number = 0;

  @Input()
  empresaId: number = 0;

  @Input()
  disabledBeneficiaria: boolean = false;

  @Input()
  disabledOrdenante: boolean = false;

  @Input()
  requiredCuentaBeneficiaria: boolean = false;

  @Input()
  cuentaBeneficiaria: any;

  @Input()
  cuentaOrdenante: any;

  @Input()
  public tabIndex: number = 1;

  @Output()
  public selectCuentaOrdenante: EventEmitter<any> = new EventEmitter();
  @Output()
  public selectCuentaBeneficiaria: EventEmitter<any> = new EventEmitter();

  extrasCuentaBeneficiaria = "NumeroCuenta, Banco.Nombre as NombreBanco, Banco.Rfc as RfcBanco";
  extrasCuentaOrdenante = "NumeroCuenta, Banco.Nombre as NombreBanco, Banco.Rfc as RfcBanco";

  onChange = (_: any) => { }
  onTouch = () => { }
  isDisabled!: boolean;

  public cuentasFrm: FormGroup = this.frmBuilder.group({
    cuentaBeneficiaria: [{}],
    cuentaOrdenante: [{}],
  })

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["clienteId"]) {
      this.cuentasFrm.controls["cuentaOrdenante"].setValue(null);
    }
    if (changes["empresaId"]) {
      this.cuentasFrm.controls["cuentaBeneficiaria"].setValue(null);
    }
    if (changes["cuentaBeneficiaria"]) {
      setTimeout(() => {
        this.cuentaBeneficiaria = this.cuentaBeneficiaria ? this.cuentaBeneficiaria : null;
        this.cuentasFrm.controls["cuentaBeneficiaria"].setValue(this.cuentaBeneficiaria);
      }, 400);
    }
    if (changes["cuentaOrdenante"]) {
      setTimeout(() => {
        this.cuentaOrdenante = this.cuentaOrdenante ? this.cuentaOrdenante : null;
        this.cuentasFrm.controls["cuentaOrdenante"].setValue(this.cuentaOrdenante);
      }, 400);
    }

    if (changes["disabledBeneficiaria"]) {
      if (changes["disabledBeneficiaria"].currentValue) {
        this.selectedCombo(null!, "cuentaBeneficiaria");
      }
    }

    if (changes["disabledOrdenante"]) {
      if (changes["disabledOrdenante"].currentValue) {
        this.selectedCombo(null!, "cuentaOrdenante");
      }
    }
  }

  writeValue(value: any) {
    if (value) {

    } else {
      this.cuentasFrm.reset();
    }
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.cuentasFrm.controls[tipo].setValue(entity);
    this.onChange(this.cuentasFrm.value);
    if (tipo === "cuentaOrdenante") {
      this.selectCuentaOrdenante.emit(entity);
    } else {
      this.selectCuentaBeneficiaria.emit(entity);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
