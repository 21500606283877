<div (keydown)="keydown($event)" class="card text-center border-0" cdkDrag
  style="position: absolute;top:183px;width: 540px;left: 360px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;z-index: 9;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar/editar detalle cheque posfechado
  </div>
  <div class="card-body bg-light">

    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">

        <form [formGroup]="myForm" autocomplete="off">
          <components-ui-text-box [tabIndex]="150" [maxLength]="10" [label]="'Serie'" formControlName="Serie"
            [widthTextColumns]="3" [widthLabelColumns]="4" #txtSerie />

          <components-ui-number-box [tabIndex]="151" [label]="'Folio'" formControlName="Folio" [widthTextColumns]="3"
            [widthLabelColumns]="4" #txtFolio />

          <components-ui-date-box label="Emisión" [tabIndex]="152" formControlName="FechaEmision"
            [widthLabelColumns]="4" [widthTextColumns]="2" />

          <components-ui-number-box [tabIndex]="153" [label]="'Total'" formControlName="Total"
            [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box [tabIndex]="154" [label]="'Saldo'" formControlName="Saldo"
            [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box [tabIndex]="155" [label]="'Abonar'" formControlName="Abonar" [widthTextColumns]="3"
            [widthLabelColumns]="4" #txtAbonar />

        </form>
      </div>
    </div>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div id="divAceptar" tabindex="1000" style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8]
        Aceptar
      </div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
    </div>
  </div>
</div>
