<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="SerieManufactura" title="Series de Manufactura" icon="fa fa-object-ungroup"
  subTitle="Administra la configuración de Series de Manufactura">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie de Manufactura">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="3" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieManufactura" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="4" [required]="true"
            helpLine="Nombre de la Serie de Manufactura, sirve para identificarla" [widthTextColumns]="3"
            [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            helpLine="Serie a utilizar, 10 letras, ejemplo = MAN" [tabIndex]="5" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxlength]="20" />

          <components-ui-text-box [label]="'Texto que imprime'" [required]="true" formControlName="TextoImprimir"
            [maxLength]="50" helpLine="Indique el texto que imprimirá en el encabezado superior derecho del formato."
            [tabIndex]="6" [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Copias'" formControlName="Copias" [maxLength]="50" [tabIndex]="7"
            helpLine="Copias a imprimir, se indican separados por una coma, ejemplo: Origina, Copia, Almacen"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
      <app-panel-base title="Afectación de Almacenes">
        <div class="panel-content">
          <component-ui-combo-box label='Concepto salida' [required]="true" formControlName="ConceptoSalida"
            entityName="ConceptoAlmacen"
            helpLine="Concepto de salida para identificar la manufactura de producto terminado"
            filterQuery="EsSalida = true" [tabIndex]="8" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ConceptoSalida')" [zeroMask]="3" />

          <component-ui-combo-box label='Almacén' [required]="true" formControlName="AlmacenSalida"
            helpLine="Almacén de donde saldrán los productos que conforman" entityName="Almacen" [tabIndex]="9"
            [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'AlmacenSalida')" [zeroMask]="3" />


            <component-ui-combo-box label='Tipo de Movimiento' [required]="true" formControlName="TipoMovimiento"
            helpLine="Almacén de donde saldrán los productos que conforman" entityName="TipoMovimientoAlmacen" [tabIndex]="10"
            [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'TipoMovimiento')" [zeroMask]="3" />


            <component-ui-check-box helpLine="Se usará solamente para ordenes de manufactura, si no está seleccionado se usa para ordenes ya generadas"
            [tabIndex]="11" [widthLabelColumns]="2" formControlName="SeUsaParaOrden" [widthTextColumns]="7"
            [label]="'Es para orden'" />
        </div>
      </app-panel-base>
      <app-panel-base title="Serie y Folio para Ordenes de Producción">
        <div class="panel-content">
          <components-ui-text-box [label]="'Serie'" formControlName="SerieOrdenProduccion" [maxLength]="50" [tabIndex]="12"
          helpLine="Serie a utilizar para las ordenes de producción relacionadas a la Orden de manufactura, 10 letras, ejemplo = PROD"
          [widthTextColumns]="2" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>

      <app-panel-base title="Configuración para el envió de correos">
        <div class="panel-content">
          <component-ui-check-box helpLine="Al guardar una orden de manufactura, se enviará un correo al encargado"
            [tabIndex]="12" [widthLabelColumns]="2" formControlName="EnviarCorreo" [widthTextColumns]="7"
            [label]="'Envíar correo a encargado'" />

          <components-ui-text-box [required]="myForm.value.EnviarCorreo" [label]="'Email encargado'" formControlName="CorreoEncargado" [maxLength]="50"
            [tabIndex]="13" helpLine="Correo del encagrado de la manufactura" [widthTextColumns]="3"
            [widthLabelColumns]="2" />

          <div formGroupName="ConfiguracionCorreo">
            <input type="hidden" formControlName="Id">
            <components-ui-text-box
              helpLine="Indique el servidor que se usará para el envío de correos electrónicos, 100 caracteres"
              [tabIndex]="14" [enabled]="myForm.value.EnviarCorreo" [maxLength]="100" [label]="'ServidorSMTP'"
              formControlName="ServidorSMTP" [widthTextColumns]="2" [widthLabelColumns]="2" />
            <components-ui-number-box helpLine="Puerto a utilizar según el Servidor SMTP, 10 números" [tabIndex]="15"
              [maxLength]="10" [enabled]="myForm.value.EnviarCorreo" [label]="'Puerto'" formControlName="Puerto"
              [widthTextColumns]="2" [widthLabelColumns]="2" />
            <component-ui-check-box helpLine="Seleccione si el Servidor SMTP requiere SSL" [tabIndex]="16"
              [widthLabelColumns]="2" [enabled]="myForm.value.EnviarCorreo" formControlName="SSL" [widthTextColumns]="7"
              [label]="'SSL'" />

            <component-ui-check-box [enabled]="myForm.value.EnviarCorreo"
              helpLine="Seleccione si el Servidor SMTP requiere la versión 1.2 para la seguridad" [tabIndex]="17"
              [widthLabelColumns]="2" formControlName="TLS12" [widthTextColumns]="7" [label]="'TLS 1.2'" />
            <components-ui-text-box [enabled]="myForm.value.EnviarCorreo"
              helpLine="Usuario o correo electrónico, 100 caracteres" [tabIndex]="18" [maxLength]="100"
              [label]="'Usuario'" formControlName="Usuario" [widthTextColumns]="2" [widthLabelColumns]="2" />
            <components-ui-password-box [enabled]="myForm.value.EnviarCorreo"
              helpLine="Indique la contraseña del correo electrónico, 100 caracteres" [tabIndex]="19" [maxLength]="100"
              [label]="'Contraseña'" formControlName="Contrasena" [widthTextColumns]="2" [widthLabelColumns]="2" />
            <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
              <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
              <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" formControlName="CuerpoCorreo"
                [placeholder]="'Cuerpo del correo que se le enviará al encargado de procesar la orden de manufactura'"></ngx-editor>
            </div>
          </div>
        </div>
      </app-panel-base>

      <!-- <app-panel-base title="Comportamiento">
        <div class="panel-content">
        </div>
      </app-panel-base> -->
    </form>
  </div>

</app-container-base>
