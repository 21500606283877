import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class BarraBotonesService {
  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient) { }

  buscar(id: number): Observable<[]> {

    return this.http.get<[]>(`${this.baseUrl}/config/obtenerusuarios`);

  }

   nuevo(entidad: string):Observable<number>{

    return this.http.get<number>(`${this.baseUrl}/navegacion/${entidad}nuevo`);

   }

}
