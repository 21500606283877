import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ConfiguracionWebTicket } from '../../interfaces/webticket.interface';
import { Validators } from 'ngx-editor';
import { UtilsService } from 'src/app/service/utils.service';
import { ClaseVenta } from '../../../ventas/interfaces/claseventa.interface';

@Component({
  selector: 'app-config-web-ticket-page',
  templateUrl: './config-web-ticket-page.component.html',
  styles: [
  ]
})
export class ConfigWebTicketPageComponent implements OnInit {


  public itemsCombo: ComboBoxEntity[] = [];
  info: any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: ['', Validators.required],
    Empresa: [{} as Empresa],
    Url: ['', Validators.required],
    Usuario: ['', Validators.required],
    Password: ['', Validators.required],
    EnviarInmediato: [false]
    // SerieFactura: ['']
  })

  constructor(private utileService: UtilsService, private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    param.callback(true);
  }

  onItemSearched(entity: any): void {
    if (entity) {
      const emp = { Id: this.info.empresa.numero, Clave: this.info.empresa.clave, Nombre: this.info.empresa.nombre }
      entity.Empresa = emp;
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: ConfiguracionWebTicket) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("ConfiguracionWebTicket").subscribe((elem) => {
      elem.Clave = next;
      const emp = { Id: this.info.empresa.numero, Clave: this.info.empresa.clave, Nombre: this.info.empresa.nombre }
      elem.Empresa = emp;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  selectedEmpresa(entity: ComboBoxEntity) {
    this.myForm.controls["Empresa"].setValue(entity);
  }

}
