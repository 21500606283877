<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-file-code"
  subTitle="Búsqueda, descarga e impresión de CFDIS">
  <div class="screen-content" style="height: 100%;">


    <app-ctrl-buscador-cfdi [mostrarAgregados]="false" [title]="''" [anio]="0"
    [mes]="0" [setIsNew]="false" />


  </div>
</app-container-base>
