<app-container-base [setIsNew]="getIsNew" [activeButtons]="activeButtons"
  (onItemsComboSearched)="onItemsComboSearched($event)" [searchItemsCombo]="false"
  (onItemSearched)="onItemSearched($event)" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  [entity]="myForm" entityName="Gasto" title="Gastos" icon="fa fa-user-tag" subTitle="Gastos" [printManual]="true"  (onClickBarButton)="onClickBarButton($event)">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificador del Gasto">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <components-ui-number-box [enabled]="getEnableText" [maxLength]="8" [label]="'Folio'" formControlName="Clave"
            [tabIndex]="3" [widthTextColumns]="1" [widthLabelColumns]="2" #txtClave />


          <components-ui-text-box [maxLength]="100" [required]="true" [label]="'Descripción'" [required]="true"
            formControlName="Nombre" [tabIndex]="4" [widthTextColumns]="5" [widthLabelColumns]="2" #txtNombre />
        </div>
      </app-panel-base>
      <!-- <component-ui-check-box [enabled]="false" [widthLabelColumns]="2" formControlName="Autorizado"
            [label]="'Autorizado'" /> -->
      <app-panel-base title="Generales">
        <div class="panel-content">
          <components-ui-date-box [required]="true" label="Fecha de emisión" [tabIndex]="5" formControlName="Fecha"
            [widthLabelColumns]="2" [widthTextColumns]="6" />

          <component-ui-combo-box [required]="true" [label]="'Tipo'" formControlName="Tipo" [tabIndex]="6"
            [zeroMask]="2" entityName="TipoGasto" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Tipo')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Clase'" formControlName="Clase" [tabIndex]="7"
            [zeroMask]="2" entityName="ClaseGastoCajaChica" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Clase')" [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>

      <app-panel-base title=" Persona que solicita y autoriza el gasto">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [label]="'Solicita'" formControlName="Solicita" [tabIndex]="8"
            [zeroMask]="2" entityName="Usuario" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Solicita')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Autoriza'" formControlName="Autoriza" [tabIndex]="9"
            [zeroMask]="2" entityName="Usuario" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Autoriza')" [listProperty]="'ClaveNombre'" />


          <div class="widget-list mb-0" style="margin-left:-15px;width: 1020px; display: flex;">
            <div class="widget-list-item" style="width:25%;">
              <div class="widget-list-media">
                <i *ngIf="myForm.value.Autorizado" class="fas fa-user-check fa-2x text-green"></i>
                <i *ngIf="!myForm.value.Autorizado" class="fas fa-user-clock fa-2x text-yellow"></i>
              </div>
              <div class="widget-list-content">
                <h4 class="widget-list-title">{{myForm.value.Autorizado ? 'El documento ya fue autorizado':'El documento no ha sido autorizado'}}</h4>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>

      <app-panel-base title="Vehículo">
        <div class="panel-content">
          <component-ui-combo-box [label]="'Vehículo'" formControlName="Vehiculo" [tabIndex]="10" [zeroMask]="2"
            entityName="Unidad" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Vehiculo')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Servicio'" formControlName="TipoServicioVehiculo" [tabIndex]="11"
            [zeroMask]="2" entityName="TipoServicioUnidad" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoServicioVehiculo')"
            [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>

      <app-panel-base title="Desglose del gasto">
        <div class="panel-content">
          <components-ui-number-box (blur)="blurProveedor($event)" [tabIndex]="12" [maxLength]="15"
            (onClicIcon)="openProvSearch()" (keydown.f2)="openProvSearch()" [label]="'Proveedor'" [enableIcon]="true"
            [widthTextColumns]="1" [widthLabelColumns]="2" #txtProveedor />
          <div class="form-group row">
            <div class="col-sm-2" style="text-align: right;">
              Nombre proveedor:
            </div>
            <div class="col-sm-5">
              {{getNombreProveedor | uppercase}}
            </div>
          </div>

          <components-ui-text-box (blur)="blurSerieFolio($event)" formControlName="SerieFolioFactura" [tabIndex]="13"
            [maxLength]="150" [label]="'Serie/Folio'" [widthTextColumns]="2" [widthLabelColumns]="2" #txtSerieFolio />

          <components-ui-text-box (keydown.f2)="openCfdi()" (onClicIcon)="openCfdi()" formControlName="UUID"
            [tabIndex]="14" [maxLength]="40" [enabled]="false" [label]="'UUID'" [widthTextColumns]="2"
            [enableIcon]="true" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="Subtotal" [tabIndex]="15" [required]="true" [maxLength]="15"
            [label]="'Subtotal'" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="IVA" [tabIndex]="16" [maxLength]="15" [label]="'IVA'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="IVARetenido" [tabIndex]="17" [maxLength]="15"
            [label]="'IVA Retenido'" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="ISRRetenido" [tabIndex]="18" [maxLength]="15"
            [label]="'ISR Retenido'" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="ImpLocalTrasladado" [tabIndex]="19" [maxLength]="15"
            [label]="'Imp Local Trasladado'" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="ImpLocalRetenido" [tabIndex]="20" [maxLength]="15"
            [label]="'Imp Local Retenido'" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-number-box formControlName="Total" [tabIndex]="21" [maxLength]="15" [label]="'Total'"
            [widthTextColumns]="1" [widthLabelColumns]="2" #txtTotal />

          <components-ui-text-box formControlName="FormaPago" [required]="true" [tabIndex]="22" [maxLength]="50"
            [label]="'Forma de pago'" [widthTextColumns]="2" [widthLabelColumns]="2" #txtSerieFolio />
        </div>
      </app-panel-base>



      <div *ngIf="myForm.value.Id>0" class="widget-list mb-0" style="margin-left:-15px;width: 1020px; display: flex;">
        <div class="widget-list-item" style="width:25%;">
          <div class="widget-list-media">
            <i class="fas fa-user-check fa-2x text-green"></i>
          </div>
          <div class="widget-list-content">
            <h4 class="widget-list-title">{{myForm.value.Elabora.Nombre}}</h4>
            <p class="widget-list-desc">Usuario elaboró</p>
          </div>
        </div>
        <div *ngIf="myForm.value.Modifica" class="widget-list-item" style="width:25%;">
          <div class="widget-list-media">
            <i class="fas fa-user-pen fa-2x text-yellow"></i>
          </div>
          <div class="widget-list-content">
            <h4 class="widget-list-title">{{myForm.value.Modifica.Nombre}}</h4>
            <p class="widget-list-desc">Usuario modificó</p>
          </div>
        </div>
      </div>


    </form>

    <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
        [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #ctrlBusqueda />
    </ng-template>
    <ng-template let-modal let-c="close" #ctrlAgregarCfdi>
      <app-ctrl-buscador-cfdi [mostrarAgregados]="false" [title]="'CFDI a relacionar al gasto'"
        [anio]="myForm.value.Fecha.getFullYear()" [mes]="myForm.value.Fecha.getMonth()"
        [setIsNew]="true" />
    </ng-template>
  </div>
</app-container-base>
