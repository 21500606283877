<!-- BEGIN breadcrumb -->
<ol class="breadcrumb float-xl-end">
	<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
	<li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
	<li class="breadcrumb-item active">Dashboard v2</li>
</ol>
<!-- END breadcrumb -->
<!-- BEGIN page-header -->
<h1 class="page-header">Dashboard v2 <small>header small text goes here...</small></h1>
<!-- END page-header -->
<!-- BEGIN row -->
<div class="row">
	<!-- BEGIN col-3 -->
	<div class="col-xl-3 col-md-6">
		<div class="widget widget-stats bg-teal">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">TODAY'S VISITS</div>
				<div class="stats-number">7,842,900</div>
				<div class="stats-progress progress">
					<div class="progress-bar" style="width: 70.1%;"></div>
				</div>
				<div class="stats-desc">Better than last week (70.1%)</div>
			</div>
		</div>
	</div>
	<!-- END col-3 -->
	<!-- BEGIN col-3 -->
	<div class="col-xl-3 col-md-6">
		<div class="widget widget-stats bg-blue">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">TODAY'S PROFIT</div>
				<div class="stats-number">180,200</div>
				<div class="stats-progress progress">
					<div class="progress-bar" style="width: 40.5%;"></div>
				</div>
				<div class="stats-desc">Better than last week (40.5%)</div>
			</div>
		</div>
	</div>
	<!-- END col-3 -->
	<!-- BEGIN col-3 -->
	<div class="col-xl-3 col-md-6">
		<div class="widget widget-stats bg-indigo">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">NEW ORDERS</div>
				<div class="stats-number">38,900</div>
				<div class="stats-progress progress">
					<div class="progress-bar" style="width: 76.3%;"></div>
				</div>
				<div class="stats-desc">Better than last week (76.3%)</div>
			</div>
		</div>
	</div>
	<!-- END col-3 -->
	<!-- BEGIN col-3 -->
	<div class="col-xl-3 col-md-6">
		<div class="widget widget-stats bg-dark">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-comment-alt fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">NEW COMMENTS</div>
				<div class="stats-number">3,988</div>
				<div class="stats-progress progress">
					<div class="progress-bar" style="width: 54.9%;"></div>
				</div>
				<div class="stats-desc">Better than last week (54.9%)</div>
			</div>
		</div>
	</div>
	<!-- END col-3 -->
</div>
<!-- END row -->

<!-- BEGIN row -->
<div class="row">
	<!-- BEGIN col-8 -->
	<div class="col-lg-8">
		<div class="widget-chart with-sidebar inverse-mode">
      <div class="widget-chart-content bg-gray-800 flex-1">
        <h4 class="chart-title">
          Visitors Analytics
          <small>Where do our visitors come from</small>
        </h4>
        <div class="widget-chart-full-width dark-mode" style="height: 268px">
        	<div class="h-100">
						<apx-chart
							[series]="areaChart.series"
							[colors]="areaChart.colors"
							[fill]="areaChart.fill"
							[legend]="areaChart.legend"
							[xaxis]="areaChart.xaxis"
							[yaxis]="areaChart.yaxis"
							[tooltip]="areaChart.tooltip"
							[chart]="areaChart.chart"
							[plotOptions]="areaChart.plotOptions"
							[dataLabels]="areaChart.dataLabels"
							[stroke]="areaChart.stroke"
							[grid]="areaChart.grid"
						></apx-chart>
					</div>
        </div>
      </div>
      <div class="widget-chart-sidebar bg-gray-900">
				<div class="chart-number">
					1,225,729
					<small>Total visitors</small>
				</div>
				<div class="flex-grow-1 d-flex align-items-center dark-mode" style="max-height: 229px">
					<apx-chart
						[series]="pieChart.series"
						[chart]="pieChart.chart"
						[colors]="pieChart.colors"
						[dataLabels]="pieChart.dataLabels"
						[stroke]="pieChart.stroke"
						[labels]="pieChart.labels"
						[legend]="pieChart.legend"
					></apx-chart>
				</div>
				<ul class="chart-legend fs-11px">
					<li><i class="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1"></i> 34.0% <span>New Visitors</span></li>
					<li><i class="fa fa-circle fa-fw text-teal fs-9px me-5px t-minus-1"></i> 56.0% <span>Return Visitors</span></li>
				</ul>
			</div>
    </div>
	</div>
	<!-- END col-8 -->
	<!-- BEGIN col-4 -->
	<div class="col-lg-4">
		<panel title="Visitors Originss"  noBody="true">
		  <ng-container noBody>
        <div class="bg-black" style="height: 179px">
        	<iframe class="w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26372023.13911858!2d-113.73046575972586!3d36.20934013545441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2smy!4v1624374439018!5m2!1sen!2smy" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
				<div class="list-group list-group-flush "  data-bs-theme="dark">
					<a href="javascript:;" class="list-group-item list-group-item-action d-flex">
						<span class="flex-1">1. United State</span>
						<span class="badge bg-teal fs-10px">20.95%</span>
					</a>
					<a href="javascript:;" class="list-group-item list-group-item-action d-flex">
						<span class="flex-1">2. India</span>
						<span class="badge bg-blue fs-10px">16.12%</span>
					</a>
					<a href="javascript:;" class="list-group-item list-group-item-action d-flex rounded-bottom">
						<span class="flex-1">3. Mongolia</span>
						<span class="badge bg-gray-600 fs-10px">14.99%</span>
					</a>
				</div>
      </ng-container>
		</panel>
	</div>
	<!-- END col-4 -->
</div>
<!-- END row -->
<!-- BEGIN row -->
<div class="row">
	<!-- BEGIN col-4 -->
	<div class="col-lg-4">
		<!-- BEGIN panel -->
		<panel bodyClass="bg-light" >
			<ng-container header>
				<h4 class="panel-title">Chat History</h4>
				<span class="badge bg-teal">4 message</span>
			</ng-container>

      <ng-scrollbar style="height: 225px">
				<div class="chats">
					<div class="chats-item start">
						<span class="date-time">yesterday 11:23pm</span>
						<a href="javascript:;" class="name">Sowse Bawdy</a>
						<a href="javascript:;" class="image"><img alt="" src="/assets/img/user/user-12.jpg" /></a>
						<div class="message">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat. Praesent mattis interdum arcu eu feugiat.
						</div>
					</div>
					<div class="chats-item end">
						<span class="date-time">08:12am</span>
						<a href="javascript:;" class="name"><span class="badge bg-blue">ADMIN</span> Me</a>
						<a href="javascript:;" class="image"><img alt="" src="/assets/img/user/user-13.jpg" /></a>
						<div class="message">
							Nullam posuere, nisl a varius rhoncus, risus tellus hendrerit neque.
						</div>
					</div>
					<div class="chats-item start">
						<span class="date-time">09:20am</span>
						<a href="javascript:;" class="name">Neck Jolly</a>
						<a href="javascript:;" class="image"><img alt="" src="/assets/img/user/user-10.jpg" /></a>
						<div class="message">
							Euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
						</div>
					</div>
					<div class="chats-item start">
						<span class="date-time">11:15am</span>
						<a href="javascript:;" class="name">Shag Strap</a>
						<a href="javascript:;" class="image"><img alt="" src="/assets/img/user/user-14.jpg" /></a>
						<div class="message">
							Nullam iaculis pharetra pharetra. Proin sodales tristique sapien mattis placerat.
						</div>
					</div>
				</div>
      </ng-scrollbar>

			<ng-container footer>
				<form name="send_message_form" data-id="message-form">
					<div class="input-group">
						<input type="text" class="form-control" name="message" placeholder="Enter your message here.">
						<button class="btn btn-primary" type="button"><i class="fa fa-camera"></i></button>
						<button class="btn btn-primary" type="button"><i class="fa fa-link"></i></button>
					</div>
				</form>
			</ng-container>
		</panel>
		<!-- END panel -->
	</div>
	<!-- END col-4 -->
	<!-- BEGIN col-4 -->
	<div class="col-lg-4">
		<!-- BEGIN panel -->
		<panel title="Today's Schedule"  noBody="true">
		  <ng-container outsideBody>
        <div class="angular-calendar-inline">
          <mwl-calendar-month-view [viewDate]="viewDate" [events]="events">
          </mwl-calendar-month-view>
        </div>
				<div class="list-group list-group-flush">
					<a href="javascript:;" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-ellipsis">
						Sales Reporting
						<span class="badge bg-teal fs-10px">9:00 am</span>
					</a>
					<a href="javascript:;" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-ellipsis rounded-bottom">
						Have a meeting with sales team
						<span class="badge bg-blue fs-10px">2:45 pm</span>
					</a>
				</div>
      </ng-container>
		</panel>
		<!-- END panel -->
	</div>
	<!-- END col-4 -->
	<!-- BEGIN col-4 -->
	<div class="col-lg-4">
		<!-- BEGIN panel -->
		<panel footerClass="text-center"  noBody="true">
			<ng-container header>
				<h4 class="panel-title">New Registered Users</h4>
				<span class="badge bg-teal">24 new users</span>
			</ng-container>
			<ng-container outsideBody>
				<ul class="registered-users-list">
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-5.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Savory Posh
							<small>Algerian</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-3.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Ancient Caviar
							<small>Korean</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-1.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Marble Lungs
							<small>Indian</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-8.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Blank Bloke
							<small>Japanese</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-2.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Hip Sculling
							<small>Cuban</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-6.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Flat Moon
							<small>Nepalese</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-4.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Packed Puffs
							<small>Malaysian</small>
						</h4>
					</li>
					<li>
						<a href="javascript:;"><img src="/assets/img/user/user-9.jpg" alt="" /></a>
						<h4 class="username text-ellipsis">
							Clay Hike
							<small>Swedish</small>
						</h4>
					</li>
				</ul>
			</ng-container>
			<ng-container footer>
				<a href="javascript:;" class="text-decoration-none text-inverse">View All</a>
			</ng-container>
		</panel>
		<!-- END panel -->
	</div>
	<!-- END col-4 -->
</div>
<!-- END row -->
