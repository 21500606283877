import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { CartaPorte, PlantillaUbicacion } from '../../interfaces/cartaporte.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';

@Component({
  selector: 'app-plantillas-carta-porte-page',
  templateUrl: './plantillas-carta-porte-page.component.html',
  styles: [
  ]
})
export class PlantillasCartaPortePageComponent {

  public itemsCombo: ComboBoxEntity[] = [];
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    JSON: [''],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: PlantillaUbicacion | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;


  // @ViewChild('cboPrueba')
  // public cboPrueba!: ElementRef<ComboBoxComponent>;

  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService) {


  }

  onAccept(cp: CartaPorte) {
    let json = JSON.stringify(cp);
    this.myForm.controls["JSON"].setValue(json);
  }


  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: PlantillaUbicacion) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: PlantillaUbicacion): void {
    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("PlantillaUbicacion").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  get getCartaPorte(): CartaPorte | null {
    if (this.myForm.value.JSON) {
      const c: CartaPorte = JSON.parse(this.myForm.value.JSON)
      return c;
    }
    return null
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
