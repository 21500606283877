<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-face-grin-beam-sweat"
  subTitle="Aceptar o Rechazar solicitudes de cancelación de los proveedores">
  <div class="screen-content p-3" style="height: 100%;">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div class="list-group-item d-flex border-top-0">
      <div class="me-3 fs-16px"><i class="fa fa-user-clock text-gray-500 fa-fw"></i></div>
      <div class="flex-fill">
        <div class="fs-14px lh-12 mb-2px fw-bold text-dark">SOLICITUDES de cancelación de tus Proveedores</div>
        <div class="mb-1 fs-12px">
          <div class="text-gray-600 flex-1">No están canceladas hasta que tú las aceptes</div>
        </div>
        <div class="mb-1"><span class="badge bg-indigo">Tienes un lapso de 3 días</span></div>
        <hr class="mb-10px bg-gray-600">
        <div class="d-flex align-items-center mb-5px">
          <div class="fs-12px d-flex align-items-center text-dark fw-bold"> Tienes {{lista.length}} solicitudes </div>
        </div>
        <div class="form-group mb-1">
          <div id="inProgressBoard" class="collapse show">
            <div class="form-check mb-1"><input type="checkbox" id="customCheck4" checked="" disabled
                class="form-check-input"><label for="customCheck4" class="form-check-label">Una vez aceptada, no hay
                proceso para revertirlo</label></div>
            <div class="form-check"><input type="checkbox" id="customCheck5" checked="" disabled
                class="form-check-input"><label for="customCheck5" class="form-check-label">Si la rechazas por error,
                solicita a tu proveedor te reenvie la solicitud de cancelación</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-10px bg-gray-600">
    <div style="margin-left: 15px;" (click)="getCancelaciones()" class="btn btn-primary">Actualizar registros</div>

    <div class="table-responsive p-3 bg-light">
      <ng-scrollbar style="height: 450px;">
        <table class="table table-bordered mb-0 bg-white">
          <thead>
            <tr>
              <th></th>
              <th></th>

              <th>UUID</th>
              <th>Serie y Folio</th>
              <th>Emisión</th>
              <th>RFC Emisor</th>
              <th>Nombre Emisor</th>

              <th>RFC Receptor</th>
              <th>Nombre Receptor</th>
              <th>Total</th>

            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of lista">
              <td>
                <div (click)="acceptQuestion(item, true)" class="btn btn-success btn-xs">Aceptar</div>
              </td>
              <td>
                <div (click)="acceptQuestion(item, false)" class="btn btn-danger btn-xs">Rechazar</div>
              </td>
              <td>{{item.uuid}}</td>
              <td>{{item.serieFolio}}</td>
              <td>{{ getFecha(item.fechaEmision)}}</td>
              <td>{{ item.emisorRfc }}</td>
              <td>{{ item.emisorNombre}}</td>
              <td>{{item.receptorRfc}}</td>
              <td>{{item.receptorNombre}}</td>
              <td>{{(item.totalPago > 0 ? item.totalPago : item.total) | number: '1.2'}}</td>
            </tr>

          </tbody>
        </table>
      </ng-scrollbar>
    </div>
  </div>
</app-container-base>
