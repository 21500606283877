import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'photo-avatar',
  templateUrl: './photo-avatar.component.html',
  styleUrls: ['./photo-avatar.component.scss']
})
export class PhotoAvatarComponent {
  @Input()
  public photoUrl: any = "";

  @Input()
  public name: string = "";

  @Input()
  public size: string = "sm";

  @Input()
  public isImgProfile: boolean = false;

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('imgFile')
  public imgFile!: ElementRef<HTMLInputElement>;
  @ViewChild('cropperImage')
  public cropperImage!: ElementRef<HTMLElement>;

  public showInitials = false;
  public initials: string = "";
  public circleColor: string = "#fefefe";
  loadSpinner: boolean = false;

  private colors = [
    '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6',
    '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#ff8a65',
    '#d4e157', '#673ab7', '#ffb74d', '#a1887f', '#90a4ae', '#e6730f',
    '#1e4026', '#6d1e27', '#9c76ad', '#d31dda', '#aab73c', '#bda744'
  ];

  private sanitizer = inject(DomSanitizer);
  private modalService = inject(NgbModal);

  ngOnInit() {
    this.createInititals();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createInititals();
    if (!this.name) {
      this.loadSpinner = true;
      if (changes["photoUrl"]) {
        // console.log(changes["photoUrl"])
      }
    }
  }

  private createInititals(): void {
    let initials = "";
    for (let i = 0; i < this.name.length; i++) {
      if (this.name.charAt(i) === ' ') {
        continue;
      }
      if (this.name.charAt(i) === this.name.charAt(i)) {
        initials += this.name.charAt(i);

        if (initials.length == 2) {
          break;
        }
      }
    }
    this.circleColor = initials ? this.colors[Math.abs(this.toNumber(initials)) % this.colors.length] : "#fefefe";
    this.initials = initials.toUpperCase();
    this.loadSpinner = false;
  }

  private toNumber(str: string): number {
    let h = 0;
    for (let i = 0; i < str.length; i++) {
      h = 31 * h + str.charCodeAt(i);
      h |= 0; // Convert to 32bit integer
    }
    return h;
  };

  openFile() {
    this.imgFile.nativeElement.click();
  }

  onchange(event: any) {
    if (event.target.value) {
      this.modalService.open(this.cropperImage, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop' })
      this.imageChangedEvent = event;
    }
  }

  onLoad(event: any) {
    // console.log("Load imagen: ", event);
    this.loadSpinner = false;
  }

  evntReturn: any;
  accept() {

    this.onChange.emit(this.evntReturn);
    this.modalService.dismissAll();
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  scale = 1;
  showCropper = false;

  transform: ImageTransform = {
    translateUnit: 'px'
  };
  loading = false;
  allowMoveImage = false;
  hidden = false;

  fileChangeEvent(event: any): void {
    this.loading = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || event.base64 || '');
    this.evntReturn = { photoUrl: event.objectUrl, file: event.blob }
    // console.log("load image: ", event);
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
    this.loading = false;
  }

  loadImageFailed() {
    console.error('Load image failed');
  }

  resetImage() {
    this.scale = 1;
    this.transform = {
      translateUnit: 'px'
    };
  }

  changeZoom(event: any) {
    // console.log(event.target.value);
    this.scale = 1 + (event.target.value * .1);
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}




