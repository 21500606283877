<div class="card text-center border-0" cdkDrag style="width: 600px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar Producto</div>
  <div class="card-body bg-light">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <h6>Seleccion del Producto</h6>
        <div class="input-group" style="margin-top: 10px;">
          <input disabled type="text"
            [value]="(producto ? (producto.Clave + ' '+producto.Nombre) : 'Seleccione el Producto')"
            class="form-control">
          <span (click)="openProductsSearch()" style="cursor: pointer;" class="input-group-text input-group-addon"><i
              class="fa fa-magnifying-glass"></i></span>
        </div>
        <hr>
        <h6>Seleccion las Listas de Precios</h6>
        <ng-scrollbar style="height: 200px;">
          <div class="todolist">
            <div style="font-weight:bold;" *ngFor="let item of listas; let i = index"
              class="todolist-item">
              <div class="todolist-input">
                <div class="form-check">
                  <input [id]="'todolist1' + i" (click)="seleccionar(i, item)" [disabled]="!getEnabledLista(item.Id)" class="form-check-input"
                    type="checkbox" [checked]="item.Seleccionado" value="">
                </div>
              </div>
              <label class="todolist-label" [for]="'todolist1' + i">{{item.Clave}} - {{item.Nombre}}</label>
            </div>
          </div>
        </ng-scrollbar>

        <hr>
        <div style="text-align: right; margin-top: 5px;">
          <button style="margin-right: 5px;" (click)="accept()" class="btn btn-success">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger"> Cancelar</button>
        </div>

      </div>
    </app-container-base>
    <ng-template #busquedaProductos let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
        [filter]="''" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
    </ng-template>
  </div>
</div>
