import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportFilterOrderList } from '../../interfaces/selection-filter.interface';

@Component({
  selector: 'components-ui-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ListBoxComponent),
    }
  ]
})
export class ListBoxComponent implements ControlValueAccessor {

  @ViewChild('txtTag')
  public tagInput!: ElementRef<HTMLInputElement>;


  @Input()
  itemList?: any[] = [];
  value: number = 0;
  @Input()
  public placeHolder: string = "";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 100;
  @Input()
  public tabIndex: number = 1;
  @Input()
  public required: boolean = false;
  @Input()
  public enabled: boolean = true;
  @Input()
  public columnList: string = 'Nombre';
  @Output()
  blur = new EventEmitter();
  @Input()
  public DefaultIndex: number = 0;

  @Input()
  public tabStop: boolean = true;

  @Output()
  select: EventEmitter<ReportFilterOrderList> = new EventEmitter<ReportFilterOrderList>();

  @Input()
  public Hidden: boolean = false;

  public myForm: FormGroup = this.fb.group({
    datos: ['']
  })

  private isDisabled = false;


  onChange = (value?: any | null) => { };

  onTouched = () => { };


  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  get onTabIndex(): number {
    if (this.tabStop) {
      return this.tabIndex;
    } else {
      return -1;
    }
  }



  writeValue(value: any): void {
    if (value) {
      let datos = { datos: value }
      this.myForm.reset(datos);
    }
  }

  onChangeItem(event: any) {
    let index: number = 0;
    index = event.target["selectedIndex"];
    if (this.itemList){
       this.select.emit(this.itemList[index]);
    }

  }

  getColumnList(value: any): string {
    let itemName: string = '';

    if (this.columnList) {
      let colums = this.columnList.split(',');

      colums.forEach((el: any) => {
        if (value[el]) {
          if (itemName.length == 0)
            itemName = value[el];
          else
            itemName = itemName + ' ' + value[el];
        }
      });
    }
    return itemName
  }




}
