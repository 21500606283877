import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-compras-por-surtir-page',
  templateUrl: './verificador-compras-por-surtir-page.component.html',
})
export class VerificadorComprasPorSurtirPageComponent {



  menuId: string = "verificadordecompras";

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
//Serie-Folio-Estatus
  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-TipoComprobante', Orden: 'empClave,sucClave,doc.Clave,c.FechaEmision,c.id' }]


  private reportsService =inject(ReportsService)

  public filtrosDiarioVentas: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{Nombre:'a Detalle'},
                      {Nombre:'a Totales'}],
      OrdenadoLista:this.ordenadoLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor:'Verificador de Compras por Surtir',
      NombreExcel:'Verificador de Compras por Surtir.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'doc.Clave', Etiqueta: 'Documento de compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "DocumentoCompra" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'mon.Clave', Etiqueta: 'Moneda', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Moneda" },
        { Campo: 'clasec.Clave', Etiqueta: 'Clase de compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseCompra" },
        { Campo: 'usu.Clave', Etiqueta: 'Usuario elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'c.Vigente', Etiqueta: 'Vigente', Tipo: TypeFilter.list, ListaItems: [{Nombre: 'Sin selección' }, {Nombre: 'Vigentes' }, {Nombre: 'No vigentes' }] }
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }


  constructor(private fb: FormBuilder, private utils: UtilsService) { }

  ngOnInit(): void {
    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE COMPRAS POR SURTIR ' + fecha.toUpperCase());
    this.focus('txtNombreReporte');
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }


  onclicSinAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc3')?.setValue(!value);
    }
  }

  onclicSoloAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  onclicSinFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc4')?.setValue(!value);
    }
  }

  onclicSoloFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc2')?.setValue(!value);
    }
  }

  clickBoton(event: string) {
    const ReporteDiarioDeVentas = this.myForm.value;
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas,'/Compras/ReporteVerificadorDeComprasPorSurtir');
  }


}
