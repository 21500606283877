import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, computed, inject, signal, NgModule } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';

import { AuthRequest, AuthResponse, RefreshToken, UserLogged } from '../interfaces';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/service/utils.service';
import { Notificacion } from 'src/app/sistema/interfaces/notificacion.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);
  private router = inject(Router);
  private cookieService = inject(CookieService);
  private utilsService = inject(UtilsService);

  //#region Opciones de las cookies
  private FUTURE = (new Date().getTime()) + 5 * 60000;
  private COOKIE_OPTIONS: CookieOptions = {
    expires: this.FUTURE,
    path: '/',
    sameSite: 'Strict'
  };
  //#endregion

  constructor() {

  }

  setToken(token: string) {
    this.cookieService.set('token', token, this.COOKIE_OPTIONS);
  }

  getToken() {
    return this.cookieService.get('token');
  }
  setRefreshToken(refreshToken: string) {
    this.cookieService.set('refreshToken', refreshToken, this.COOKIE_OPTIONS);
  }

  getRefreshToken() {
    return this.cookieService.get('refreshToken');
  }

  login(authRequest: AuthRequest): Observable<boolean> {
    let options = { headers: new HttpHeaders({ 'ambienteId': authRequest.ambienteId, 'hostFrontEnd': window.location.host }) };
    const url = `${this.baseUrl}/autorizacion/Autenticar`;
    const body = authRequest;
    return this.http.post<AuthResponse>(url, body, options)
      .pipe(
        tap((res: AuthResponse) => {
          if (!res.actualizarBD) {
            this.setToken(res.accessToken);
            this.setRefreshToken(res.refreshToken);
          }
        }),
        map((res: any) => { return res }),
        catchError(err => throwError(() => err.error.mensaje))
      );
  }

  logout(): Observable<any> {
    let usuario = this.utilsService.getUserLogged();
    const url = `${this.baseUrl}/autorizacion/logout`;
    let body = { nombreUsuario: usuario.nombreUsuario, numeroEmpresa: usuario.empresa?.numero, numeroSucursal: usuario.sucursal?.numero };
    return this.http.post<any>(url, body)
      .pipe(
        tap((res: any) => {
          let user = this.utilsService.getUserLogged();
          if (!user.recordar) {
            user = {
              ambienteId: 0,
              numero: 0,
              clave: 0,
              nombreUsuario: "",
              nombreCompletoUsuario: "",
              password: "",
              recordar: false,
              sucursal: { numero: 0, clave: 0, nombre: "", rfc: "", alias: "" },
              empresa: { numero: 0, clave: 0, nombre: "", rfc: "", alias: "" }
            };
          }
          localStorage.setItem("userLogged", JSON.stringify(user));
          this.cookieService.deleteAll('/');
          this.router.navigate(['/auth']);
          this.actualizarSesionesActivas(usuario).subscribe();
        }),
        map(() => true),
        catchError(err => throwError(() => {
          this.cookieService.deleteAll('/');
          this.router.navigate(['/auth']);
          console.log(err.error)
          this.actualizarSesionesActivas(usuario).subscribe();
          return err.error.mensaje
        }))
      );
  }

  getNotifications(idUsuarioRecibe: number): Observable<Notificacion[]> {
    const params = new HttpParams().set("idUsuarioRecibe", idUsuarioRecibe);
    return this.http.get<Notificacion[]>(`${this.baseUrl}/Config/ObtenerNotificacionesNoLeidos`, { params });
  }

  clearNotifications(idUsuarioRecibe: number): Observable<Notificacion[]> {
    const params = new HttpParams().set("idUsuarioRecibe", idUsuarioRecibe);
    return this.http.get<Notificacion[]>(`${this.baseUrl}/Config/LimpiarNotificaciones`, { params });
  }

  getUsuarios() {
    const url = `${this.baseUrl}/autorizacion/usuarios`;
    return this.http.get<any[]>(url)
  }

  refreshToken(refreshToken: RefreshToken) {
    return this.http.post<any>(`${this.baseUrl}/autorizacion/refreshToken`, refreshToken)
  }

  getTokenDecoded() {
    const jwtHelper = new JwtHelperService();
    let token = this.getToken();
    return jwtHelper.decodeToken(token);
  }

  getAmbienteId(): string {
    let tokenDecode = this.getTokenDecoded();
    return tokenDecode.ambiente;
  }

  actualizarSesionesActivas(usuario: UserLogged): Observable<boolean> {
    let usuarioConectado = {
      usuario: { id: usuario.numero, clave: usuario.clave, login: usuario.nombreUsuario },
      empresa: { id: usuario.empresa?.numero, nombre: usuario.empresa?.nombre },
      sucursal: { id: usuario.sucursal?.numero, nombre: usuario.sucursal?.nombre },
    }
    return this.http.post<any>(`${this.baseUrl}/autorizacion/ActualizarSesionesActivas`, usuarioConectado)
  }
}
