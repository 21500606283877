import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { Validators } from 'ngx-editor';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { DocumentoCompra, SerieCompra } from '../../interfaces/clasecompra.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EmailComposePage } from '../../../home/pages/email/compose/email-compose';
import { UserLogged } from 'src/app/auth/interfaces';
import { BaseService } from 'src/app/service/base.service';

@Component({
  selector: 'app-series-compras-page',
  templateUrl: './series-compras-page.component.html',
  styles: [
  ]
})
export class SeriesComprasPageComponent {


  private baseService = inject(BaseService);


  public itemsCombo: ComboBoxEntity[] = [];
  formatoSeleccionado?: ComboBoxEntity | null;
  listaFormatosImpresion: any[] = [];


  filter = ``
  info: UserLogged = {} as UserLogged;
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Serie: ['', [Validators.required]],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    DocumentoCompra: [{} as DocumentoCompra, Validators.required],
    Formato: ['', [Validators.required]],
    Copias: [''],
    Poliza: [null],
    PolizaCargos: [null],
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService, private utilsService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero} and Sucursal.Id = ${this.info.sucursal!.numero}`

    this.baseService.getlistadoFormatosImpresion(this.info.empresa?.rfc!).subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((element: string) => {
            this.listaFormatosImpresion.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        }
        if (this.myForm.value.Formato) {
          this.setFormatoImpresion(this.myForm.value.Formato);
        }
      }
    });
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
    ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
    this.myForm.reset(ent);
    param.callback(true);
  }

  onAfterSave(entity: SerieCompra) {
    this.myForm.reset(entity);
  }

  onNewCatalog(next: number) {

    this.containerService.getEmptyEntity("SerieCompra").subscribe((elem) => {
      elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }


  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }


  selectedComboFormato(entity: ComboBoxEntity, tipo: string) {
    if (entity) {
      this.myForm.controls[tipo].setValue(entity.Nombre);
    } else {
      this.myForm.controls[tipo].setValue(null);
    }
  }

  get getFormatoImpresion() {
    if (this.formatoSeleccionado) {
      return this.formatoSeleccionado!;
    } else {
      return null;
    }
  }

  setFormatoImpresion(formato: string| null) {
    if (!formato){
      this.formatoSeleccionado = null;
      return
    }
    let found = false;
    this.listaFormatosImpresion.forEach((item: ComboBoxEntity) => {
      if (item.Nombre == formato) {
        this.formatoSeleccionado = item;
        found = true;
      }
    });

    if (!found) {
      this.formatoSeleccionado = null;
    }

  }


  onItemSearched(ent: any): void {
    if (ent) {
      if (ent.Empresa) {
        this.filter = `Empresa.Id = ${ent.Empresa.Id} and Sucursal.Id = ${ent.Sucursal.Id}`
      } else {
        this.filter = '';
        if (this.myForm.value) {
          if (this.myForm.value.Empresa) {
            ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
            ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
            this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id} and Sucursal.Id = ${this.myForm.value.Sucursal.Id}`
          }
        }

      }
      this.myForm.reset(ent);
      this.setFormatoImpresion(ent.Formato);
      this.focus('txtNombre')
    }
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      console.log(ent)
      this.onItemSearched(ent);
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
