<div class="card text-center border-1" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <app-container-base [containerInnerHeight]="355" [esPopup]="true" [hasHeader]="false" [hasBar]="false" [hasCounter]="false">
    <div class="screen-content">
      <form [formGroup]="myForm" autocomplete="off">
        <component-ui-address [collapsable]="false" [enabled]="activar" [esEntrega]="true" [tabIndex]="1150" formControlName="Direccion">
        </component-ui-address>
      </form>

      <div style="text-align: right; margin-top: 5px;">
        <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
        <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
      </div>
    </div>
  </app-container-base>

</div>
