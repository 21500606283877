import { Component, Input, inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { BaseDatosService } from '../../services/base-datos.service';
import { Result } from '../../interfaces';

@Component({
  selector: 'agregar-ambiente',
  templateUrl: './agregar-ambiente.component.html',
  styleUrls: ['./agregar-ambiente.component.scss']
})
export class AgregarAmbienteComponent {

  @Input()
  visible: boolean = false;

  @Output()
  public ambienteGuardado: EventEmitter<boolean> = new EventEmitter();


  private formBuilder = inject(FormBuilder);
  private eventsService = inject(EventsService);
  private bdService = inject(BaseDatosService);




  mensajeConn: string = "";
  isSuccessConn: boolean = false;

  public frmAgregarBD: FormGroup = this.formBuilder.group({
    nombreBD: ['DEMO', [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]+$")]],
    etiquetaBD: ['demo', [Validators.required, Validators.pattern("^[a-zA-Z0-9_-\\s]+$")]],
    servidor: ['localhost\\sqlexpress', [Validators.required, Validators.pattern("^[^$*%&|<>#]*$")]],
    usuarioServidor: ['sa', [Validators.required, Validators.pattern("^[a-zA-Z0-9-]+$")]],
    password: ['sql2023', [Validators.required, Validators.pattern("^[a-zA-Z0-9@_/]+$")]],
    recordarDatosSesion: [false]
  });



  guardarEntorno() {
    this.mensajeConn = "";
    this.isSuccessConn = false;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const { nombreBD, etiquetaBD, servidor, usuarioServidor, password, recordarDatosSesion } = this.frmAgregarBD.value;
    this.bdService.guardarBD({
      servidorSql: servidor,
      etiqueta: etiquetaBD.toUpperCase(),
      baseDatos: nombreBD.toUpperCase(),
      usuario: usuarioServidor,
      password,
      recordarDatosSesion
    }).subscribe({
      next: (res) => {
        this.mensajeConn = "Ambiente creado correctamente.";
        this.isSuccessConn = true;
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.visible = false;
        this.ambienteGuardado.emit(true);
      },
      error: (mensaje) => {
        this.mensajeConn = mensaje;
        this.isSuccessConn = false;
        this.eventsService.publish('home:isLoading', { isLoading: false });
      }
    })
  }

  checkConnBD() {
    this.mensajeConn = "";
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const { servidor, usuarioServidor, password } = this.frmAgregarBD.value;

    if (servidor && usuarioServidor && password) {
      this.bdService.getCheckBD({ servidorSql: servidor, usuario: usuarioServidor, password }).subscribe((result: Result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.mensajeConn = result.message;
        this.isSuccessConn = result.success;
      })
    } else {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.isSuccessConn = false;
      this.mensajeConn = "Los campos Servidor, Usuario y Contraseña son obligatorios para realizar una prueba de conexión.";
    }
  }
}
