import { inject, Injectable } from '@angular/core';
import * as qz from 'qz-tray';
import * as rs from 'jsrsasign';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';



@Injectable({ providedIn: 'root' })
export class PrinterService {
  private eventsService = inject(EventsService);
  constructor() { }

  initPrinterService(cb: any = null) {
    if (!qz.websocket.isActive()) {
      qz.security.setCertificatePromise((resolve, reject) => {
        resolve("-----BEGIN CERTIFICATE-----\n" +
          "MIID+zCCAuOgAwIBAgIUQ6rxEtl1fS2+qik5csFRS6aNvhIwDQYJKoZIhvcNAQEL\n" +
          "BQAwgYsxCzAJBgNVBAYTAk1YMQ8wDQYDVQQIDAZTb25vcmExEDAOBgNVBAcMB09i\n" +
          "cmVnb24xEDAOBgNVBAoMB0NvbXBsaXQxEDAOBgNVBAsMB0NvbXBsaXQxEDAOBgNV\n" +
          "BAMMB0NvbXBsaXQxIzAhBgkqhkiG9w0BCQEWFGplc3VzQGNvbXBsaXQuY29tLm14\n" +
          "MCAXDTI0MDcxMTE3MDYzOVoYDzIwNTYwMTA0MTcwNjM5WjCBizELMAkGA1UEBhMC\n" +
          "TVgxDzANBgNVBAgMBlNvbm9yYTEQMA4GA1UEBwwHT2JyZWdvbjEQMA4GA1UECgwH\n" +
          "Q29tcGxpdDEQMA4GA1UECwwHQ29tcGxpdDEQMA4GA1UEAwwHQ29tcGxpdDEjMCEG\n" +
          "CSqGSIb3DQEJARYUamVzdXNAY29tcGxpdC5jb20ubXgwggEiMA0GCSqGSIb3DQEB\n" +
          "AQUAA4IBDwAwggEKAoIBAQDt3Mvc2yfmyDvW6JBBqAoTpvJuDggH+EStZZyz/llA\n" +
          "JZedLg1UeeHqXryc9+XgJO16vh+ZQwvz0k0TPxaZ+4yABq0tj/2cijZQQnsOnwvV\n" +
          "eM69BqJ2PEqaYFc3pQypSLoTmsOMW4wMO6Q1SLm13y7d34O2sRgJFlmSb+DAN2PJ\n" +
          "sGFQoWBpyFfnCh+yjiEiUhfCoO7LjksOgkgZwmAOIfaF58ulRE0qqqaeJw4JI1Op\n" +
          "47vG/46zUDOLmMpLj4BMuVmtn9uUEzpGGOOSVJNhe2iKyanA1rnh9Yb88M+VjaLT\n" +
          "Ska0ARvJ2s+w3sZn7cxZK1zcjm7jCW+WilskyATdnyYDAgMBAAGjUzBRMB0GA1Ud\n" +
          "DgQWBBTlHDTy5SG6gvz11ig974K58Q90ATAfBgNVHSMEGDAWgBTlHDTy5SG6gvz1\n" +
          "1ig974K58Q90ATAPBgNVHRMBAf8EBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQBU\n" +
          "ac/VqeT9uKCC8V13KbwlXodDviFowlovn37GYQF9lhwYKQ90uLtuIezw9twOrYcg\n" +
          "yXgnUiFQO6TxXLMzWI05dYkCrQlzVEyZeCC7CLxF5CxUyCPpEdinw9rRheATY3dA\n" +
          "n8zIrhKV1U9KJ0jdTxG9fzZhY+XaA9lCy9Ryf/mDsWHNaioPernOKC4vyaephkH6\n" +
          "DJXM0Qhuyw/oeGobaC7FaD/zW3yxUxpzqbA07g28Z+I5bw9Xeo4t5PDSjZwkn8ay\n" +
          "seCuZzs63Hp8f2xpkGT1BB5IjsXDjw5RQ8A1wEIUM3EtB+mu2hcwxaskNhZq7NfW\n" +
          "4U0rm7T9iVGa2FEdxa+V\n" +
          "-----END CERTIFICATE-----");
      });

      var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
        "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDt3Mvc2yfmyDvW\n" +
        "6JBBqAoTpvJuDggH+EStZZyz/llAJZedLg1UeeHqXryc9+XgJO16vh+ZQwvz0k0T\n" +
        "PxaZ+4yABq0tj/2cijZQQnsOnwvVeM69BqJ2PEqaYFc3pQypSLoTmsOMW4wMO6Q1\n" +
        "SLm13y7d34O2sRgJFlmSb+DAN2PJsGFQoWBpyFfnCh+yjiEiUhfCoO7LjksOgkgZ\n" +
        "wmAOIfaF58ulRE0qqqaeJw4JI1Op47vG/46zUDOLmMpLj4BMuVmtn9uUEzpGGOOS\n" +
        "VJNhe2iKyanA1rnh9Yb88M+VjaLTSka0ARvJ2s+w3sZn7cxZK1zcjm7jCW+Wilsk\n" +
        "yATdnyYDAgMBAAECggEAHOkIuDPE4kZ1C3ZhzjEb5G6Wqay36iwRQNgIV1IzS9ol\n" +
        "1ttZkGpt/Hv+Rb/Vp0wltxTzpz+gbMajSpMQ7q9F0Ou+0bpeE1AzMMcbyNeo6w3P\n" +
        "ZucvCNrkIGZUQ3Wil9K4lxho6x7XY0c+eqF3vPS+8AWyLxrQNo6KXYOOmB5DWSiE\n" +
        "dT+xfibH5oZ4ecXIyBRzmR1RIgiJUwXHszU8Zzbrn59HAYjkZTly6KK6BGkQxZYh\n" +
        "f0vRASytixrSNLdYPTG3UEATJZpDPE4bhlKRT0dirm7RtMHivUC+BDRaK4yx2v8M\n" +
        "gIeKXg5iOhGZnhza7KtUrIn2IAMTPHvBwEbUBmqi3QKBgQD6tZ+2KVlscjHvO7jF\n" +
        "qz0XItl/FjRglfa2qIS++oQCWM85qRBin735Cwfuu3/oxtCj8g64UxnM6juv73fh\n" +
        "B8XZpW3FroAyhY+L56DJaaNmw+DbjYNwEFgH1g1ejgvjd4a1TsZrGUP4FaL+CUfI\n" +
        "K+gD/9CY+4qozmZ8Vz9zQnLrTwKBgQDy4cVVSEAPXIkpFKAmbL3zBwLN8Y61R5Qk\n" +
        "WSeordLb8TmWqtjixwdZSdt3MqvhVDSghQSgB53ZGVNKa/ux9OlT+JiQEOJOKmPX\n" +
        "mMQYU7Sn4AaT0Xn3nrE0fuNdfgGU8gfxX/UIeSh8OMhRnFsA7hYiFhKMMfxuMJta\n" +
        "pdsXS3rdDQKBgQCW9eITZ9aGrERRDx71N2Tzl3JrSc2EEwiZd+Nms6XlW+B7kcdh\n" +
        "+1oCqZe/MnuUiJJ9BXXDm5Y/gxMpnwIKKAoEQc5VZ/udcDq9PV3dqZRQfsXYrd9l\n" +
        "DRJUtZkbRGD4OLKOXb6wImiA61ZXrPLiHz33moPaeixF9PJVEJwOJQbZlQKBgAQh\n" +
        "2TShPQ3F2kOLqUneEcE/vkl4sZ/d7uRLbARPqMW7LeqlT3lUP4jCW+yEPS5hO1/7\n" +
        "w19hocrexm7+tVKKwyl9kkLpNqBz0i0J2VQ8oFc21YXQS43t8j/dZMrjaO16zK3L\n" +
        "nyScwxaR9L6GtXmnk1LTI0+lThEl3I7HQZn828NFAoGADYTDJ3CR7RO2ve0Qar1o\n" +
        "Qt7Y04Fbm7Ey51vciiKHGEUmCsxXRoJJXDMkTn1wAqYuoXj8ZY1/74Pa07JL74Ck\n" +
        "Ce2dphU72sLzY4sKgxh9TAkboPLR7d5ZKldwinPYbnUf7clb7jhae5lmUiNDfKBB\n" +
        "v/nwlgVaOHevtIcFYu3NGTo=\n" +
        "-----END PRIVATE KEY-----\n";

      qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          try {
            var pk = rs.KEYUTIL.getKey(privateKey);
            var sig = new rs.KJUR.crypto.Signature({ "alg": "SHA512withRSA" });  // Use "SHA1withRSA" for QZ Tray 2.0 and older
            sig.init(pk);
            sig.updateString(toSign);
            var hex = sig.sign();
            resolve(rs.stob64(rs.hextorstr(hex)));
          } catch (err: any) {
            console.error(err);
            reject(err);
          }
        };
      });
      setTimeout(() => {
        this.connect(cb);
      }, 500);
    }
  }

  findListPrinter() {
    qz.printers.find().then(function (data) {
      var list = '';
      for (var i = 0; i < data.length; i++) {
        list += "&nbsp; " + data[i] + "<br/>";
      }
    }).catch(function (e) { console.error(e); });
  }

  findPrinter(printerName: string, cb: any = null) {
    qz.printers.find(printerName).then((found: any) => {
      cb(found);
      qz.printers.startListening(found).then(() => {
        return qz.printers.getStatus();
      });
    }).catch((err: any) => {
      console.error(err)
      cb(false);
    });
  }

  connect(cb: any = null) {
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then((data: any) => {
        if (cb) {
          cb(true);
        }
      }).catch((err: any) => {
        this.eventsService.publish('home:showAlert', {
          cancelButton: false,
          message: 'QZ Para Complit no está iniciado o instalado, favor de verificar.',
          onConfirm: (data: AlertResponse) => {
            if (cb) {
              cb(false);
              if (qz.websocket.isActive()) {
                qz.websocket.disconnect().then().catch((err: Error) => console.error(err));
              }
            }
          }
        });
        console.error(err);
      });
    }
  };

  printTicket(ticket: string, impresora: string) {
    var config = qz.configs.create(impresora);
    let data: qz.PrintData[] = [{
      type: 'pixel',
      format: 'html',
      flavor: 'plain',
      data: ticket,
    },
      //cutPaper()
    ];
    qz.print(config, data).catch((e) => {
      console.error(e)
    });
  }

  printPdfB64(b64String: string, impresora: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    if (qz.websocket.isActive()) {
      this.findPrinter(impresora, (found: any) => {
        if (found) {
          var config = qz.configs.create(impresora);
          let data: qz.PrintData[] = [{
            type: 'pixel',
            format: 'pdf',
            flavor: 'base64',
            data: b64String,
          },
            //cutPaper()
          ];
          qz.print(config, data).then(() => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
          }).catch((e) => {
            console.error(e)
            this.eventsService.publish('home:isLoading', { isLoading: false });
          });
        } else {
          this.eventsService.publish('home:showAlert', {
            cancelButton: false,
            message: `La impresora <strong>${impresora}</strong> no está instalada en este equipo, favor de verificar para realizar la impresión directa del documento.`,
          });
          this.eventsService.publish('home:isLoading', { isLoading: false });
        }
      })
    } else {
      this.initPrinterService((resp: boolean) => {
        if (resp) {
          this.printPdfB64(b64String, impresora);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
        }
      })
    }
  }

  printerStatus(cb: any) {
    qz.printers.setPrinterCallbacks((evt: any) => {
      let texto = "Inicializando";
      let success = false;
      switch (evt.statusText) {
        case 'WARMING_UP': texto = 'Sobre calientamiento'; break;
        case 'TONER_LOW': texto = 'Nivel de tinta bajo'; break;
        case 'POWER_SAVE': texto = 'Ahorrando energía'; break;
        case 'PAPER_JAM': texto = 'Papel atorado'; break;
        case 'PAPER_PROBLEM': texto = 'Problema con el papel'; break;
        case 'DELETED': texto = 'Encendida'; success = true; break;
        case 'OK': texto = 'Encendida'; success = true; break;
        case 'OFFLINE': texto = 'Apagada/No Disponible'; break;
        case 'NOT_AVAILABLE': texto = 'Apagada/No Disponible'; break;
        case 'DOOR_OPEN': texto = 'Caja Abierta/Sin Papel'; break;
        case 'PAPER_OUT': texto = 'Caja Abierta/Sin Papel'; break;
        case 'PRINTING': texto = 'Imprimiendo'; success = true; break;
        case 'RETAINED': texto = 'Imprimiendo'; success = true; break;
        case 'SENT': texto = 'Imprimiendo'; success = true; break;
        case 'MANUAL_FEED': texto = 'Sacando papel'; break;
        case 'COMPLETE': texto = 'Fin impresión'; success = true; break;
        default: break;
      }
      cb({ texto, success });
    });
    qz.printers.getStatus().then()
      .catch(err => console.error(err));
  }
}
