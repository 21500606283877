<div class="card text-center border-0" cdkDrag style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Comentarios</div>
  <app-panel-base title="Comentarios">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
          <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
          <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" formControlName="Comentario"
            [placeholder]="''"></ngx-editor>
        </div>
      </form>
      <div style="text-align: right; margin-top: 5px;">
        <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
        <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
      </div>
    </div>
  </app-panel-base>
</div>
