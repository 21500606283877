import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  templateUrl: './kardex-page.component.html',
  styleUrls: ['./kardex-page.component.scss']
})
export class KardexPageComponent implements OnInit {

  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  menuId: string = "kardex";

  ordenados: any[] = [{
    Id: 1,
    Clave: 1,
    Nombre: "Familia",
    ClaveNombre: "1 Familia",
  },
  {
    Id: 2,
    Clave: 2,
    Nombre: "Clasificación",
    ClaveNombre: "2 Clasificación",
  }];


  public frmKardex: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: ['Kardex', Validators.required],
    Orden: []
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  ngOnInit() {
    setTimeout(() => {
      this.frmKardex.controls['Orden'].setValue(this.ordenados[0]);
      this.focus();
    }, 200);
  }

  focus() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

  public filtrosKardex: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Kardex'
      } as ReportHeader,
      OrdenadoLista: [{ Nombre: "Familia", Orden: "fam.Clave" }, { Nombre: "Clasificación", Orden: "clasif.Clave" }],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Kardex',
      TituloVisor: 'Kárdex',
      NombreExcel: 'Kardex.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'alm.Clave', Etiqueta: 'Almacén', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Almacen" },
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'concepto.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoAlmacen" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
      ]
    };

  changeselec(event: any) {
    this.filtrosKardex = event;
  }

  imprimir(event: any) {
    this.filtrosKardex.ReportHeader = this.frmKardex.value;
    this.reportsService.printReport(this.filtrosKardex, '/Inventarios/ReporteKardex');
  }
}
