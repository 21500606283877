import { Component, OnInit } from '@angular/core';
import { DescargaCfdi } from '../../interfaces/solicitud.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Result } from 'src/app/auth/interfaces';

@Component({
  selector: 'app-aceptar-rechazar-page',
  templateUrl: './aceptar-rechazar-page.component.html',
  styles: [
  ]
})
export class AceptarRechazarPageComponent implements OnInit {
  private readonly baseUrl: string = environment.baseUrlApi;
  saving: boolean = false;
  lista: DescargaCfdi[] = [];
  ngOnInit(): void {

  }

  constructor(private http: HttpClient) {
    this.getCancelaciones();
  }


  getFecha(fecha: any): string {
    if (!fecha) return "";

    return moment(fecha).format("DD/MM/YYYY")
  }

  acceptQuestion(item: DescargaCfdi, aceptar: boolean) {
    Swal.fire({
      title: (aceptar ? '¿Esta seguro de aceptar la cancelación?' : '¿Esta seguro de rechazar la cancelación?'),
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((res) => {
      if (res.isConfirmed) {
        this.acceptCancel(item, aceptar);
      }
    })
  }

  acceptCancel(item: DescargaCfdi, aceptar: boolean) {
    this.saving = true;
    const params = new HttpParams().set("uuid", item.uuid).set("aceptar", aceptar);
    this.http.get<Result>(`${this.baseUrl}/Fiscal/AceptarRechazarSolicitud`, { params }).subscribe((result) => {
      this.saving = false;
      if (result.success) {
        Swal.fire({ text: `Se ${(aceptar ? 'aceptó' : 'rechazó')} la cancelación`, icon: 'success' }).then(() => { this.getCancelaciones(); })
      } else {
        Swal.fire({ text: result.message, icon: 'error' }).then(() => { this.getCancelaciones(); })
      }
    });
  }

  getCancelaciones() {
    this.saving = true;
    this.http.get<DescargaCfdi[]>(`${this.baseUrl}/Fiscal/ObtenerSolicitudesCancelacion`).subscribe((result) => {
      this.saving = false;
      this.lista = result;
    });
  }

}
