import { Component, ElementRef, HostListener, ViewChild, inject } from '@angular/core';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { EfiscoService } from '../../services/efisco.service';
import { error } from 'jquery';
import { DescargaCfdi } from '../../interfaces/solicitud.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { addDays } from 'date-fns';
import { Result } from 'src/app/auth/interfaces';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import Swal from 'sweetalert2';
import { orderBy } from 'lodash';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-admin-cfdi-page',
  templateUrl: './admin-cfdi-page.component.html',
  styleUrls: ['./admin-cfdi-page.component.scss']
})
export class AdminCfdiPageComponent {
  private readonly baseUrl: string = environment.baseUrlApi;
  columns = [{ name: 'Sel' }, { name: 'PDF' }, { name: 'XML' }, { name: 'Tipo' }, { name: 'UUID' }, { name: 'Emisor' }, { name: 'Receptor' }, { name: 'EmisSerie/Folioor' }, { name: 'Fecha' }, { name: 'Total' }];
  ColumnMode = ColumnMode;
  lista: DescargaCfdi[] = []
  efiscoService = inject(EfiscoService);
  eventsService = inject(EventsService);
  mobileSidebarToggled: boolean = false;
  recibidos: boolean = true;
  emitidos: boolean = false;

  ordenados = [
    { name: 'tipoDeComprobante', order: 'desc' },
    { name: 'uuid', order: 'desc' },
    { name: 'emisorRfc', order: 'desc' },
    { name: 'receptorRfc', order: 'desc' },
    { name: 'serieFolio', order: 'desc' },
    { name: 'fechaEmision', order: 'desc' },
    { name: 'total', order: 'desc' },
  ];

  lastOrder: string = "";
  lastOrderAsc: string = "";
  todos: boolean = false;
  ninguno: boolean = true;

  @ViewChild('txtBuscarCfdi')
  public txtBuscarCfdi!: ElementRef<HTMLElement>;

  toggleMobileSidebar(): void {
    this.mobileSidebarToggled = !this.mobileSidebarToggled;
  }

  scrollHeihgt: number = 0;
  public appSettings = inject(AppSettings);
  constructor(private http: HttpClient, private fb: FormBuilder, private fServices: FacturacionService) {
    this.appSettings.appSidebarMinified = true;
    this.appSettings.appContentFullHeight = true;
    this.appSettings.appContentClass = 'd-flex flex-column';
    this.recalcHeight({ target: window });
  }

  public myForm: FormGroup = this.fb.group({
    busqueda: [''],
    tipoDeComprobante: '',
    fechaInicioString: [''],
    fechaFinString: [''],
    fechaInicio: [Date],
    fechaFin: [Date],
  })

  ngOnInit() {
    this.sendFocus();
    this.myForm.reset({
      busqueda: '',
      tipoDeComprobante: '',
      fechaInicio: new Date(),
      fechaFin: new Date(),
      fechaInicioString: moment(new Date()).format("01/MM/YYYY"),
      fechaFinString: moment(new Date()).format("DD/MM/YYYY"),
    });
    console.log(this.myForm.value);
  }

  get getActivarOpciones(): boolean {
    return !(this.lista.filter(P => P.seleccionado).length > 0);
  }

  seleccionar(pos: number) {
    this.lista[pos].seleccionado = !this.lista[pos].seleccionado;
    this.lista = [...this.lista];
  }

  ordenar(campo: string) {
    this.lastOrder = campo;
    let order = this.ordenados.filter(P => P.name == campo)[0];
    let ordenado = orderBy(this.lista, [campo], [order.order == "desc" ? "asc" : "desc"]);
    order.order == "asc" ? order.order = "desc" : order.order = "asc";
    this.lastOrderAsc = order.order;
    this.lista = [...ordenado];
  }

  selTodos() {
    if (this.lista.length == 0) {
      return;
    }
    this.todos = !this.todos;
    this.ninguno = !this.ninguno;

    this.lista.map((i: DescargaCfdi) => {
      i.seleccionado = this.todos;
      return i;
    })

    this.lista = [...this.lista];

  }

  selEmitidos() {
    this.lista = [];
    this.emitidos = !this.emitidos;
    this.recibidos = !this.recibidos;
  }

  sendEmail() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.obtenerCorreosCliente(this.myForm.value.Cliente.Id).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      const correos = result.message;
      Swal.fire({
        title: "Envíar email para:",
        input: "text",
        inputValue: correos,
        inputAttributes: { autocapitalize: "off" },
        showCancelButton: true,
        confirmButtonText: "Enviar",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.fServices.sendEmail(this.myForm.value.Id, result.value).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              if (result.success) {
                this.eventsService.publish('home:showAlert', { message: "Se ha enviado por e-mail", cancelButton: false });
              } else {
                this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
              }
            })
          } else {
            this.eventsService.publish('home:showAlert', { message: "Debe de indicar por lo menos un e-mail.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
          }
        }
      });
    });
  }




  tableToCSV() {
    // Variable to store the final csv data
    let csv_data: any = [];

    // Get each row data
    let rows = document.getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i++) {

      // Get each column data
      let cols = rows[i].querySelectorAll('td,th');

      // Stores each csv row data
      let csvrow = [];
      for (let j = 0; j < cols.length; j++) {

        // Get the text data of each cell of
        // a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }
    // Combine each row data with new line character
    csv_data = csv_data.join('\n');
    this.downloadCSVFile(csv_data);
    /* We will use this function later to download
    the data in a csv file
    */
  }

  downloadCSVFile(csv_data: any) {

    // Create CSV file object and feed our
    // csv_data into it
    const CSVFile = new Blob([csv_data], { type: "text/csv" });

    // Create to temporary link to initiate
    // download process
    let temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  selectedCombo(val: string) {
    if (val == "TODOS") {
      this.myForm.controls['tipoDeComprobante'].setValue("");
    } else {
      this.myForm.controls['tipoDeComprobante'].setValue(val);
    }
  }

  print(uuid: string) {
    if (uuid) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      const params = new HttpParams().set("uuid", uuid);
      this.http.get<Result>(`${this.baseUrl}/Fiscal/ImprimirCfdiRecibido`, { params }).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          this.fServices.printDocument(this.fServices.base64ToArrayBuffer(result.message));
        } else {
          Swal.fire({ text: `${result.message}`, icon: 'error', })
        }
      })
    }
  }

  downloadXML(uuid: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    return this.http.get(`${this.baseUrl}/Fiscal/DescargarXml?uuid=${uuid}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (d) {
        let blob = new Blob([d], { type: "application/xml" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  sendFocus() {
    setTimeout(() => {
      debugger
      const txt: any = this.txtBuscarCfdi;
      txt.nativeElement.focus()
    }, 250);
  }

  search() {
    this.todos = false;
    this.ninguno = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("filtro", this.myForm.value.busqueda)
      .set("tipoComprobante", this.myForm.value.tipoDeComprobante)
      .set("fechaInicio", moment(this.myForm.value.fechaInicio).format("DD/MM/YYYY"))
      .set("fechaFin", moment(this.myForm.value.fechaFin).format("DD/MM/YYYY"))
      .set("emitidos", this.emitidos);
    return this.http.get<DescargaCfdi[]>(`${this.baseUrl}/Fiscal/BusquedaContabilidad`, { params }).subscribe((list) => {
      this.lastOrder = "";
      this.lastOrderAsc = "";
      this.eventsService.publish('home:isLoading', { isLoading: false });
      list.map((item) => {

        switch (item.tipoDeComprobante) {
          case 'i': item.tipoDeComprobante = "INGRESO"; break;
          case 'e': item.tipoDeComprobante = "EGRESO"; break;
          case 't': item.tipoDeComprobante = "TRASLADO"; break;
          case 'p':
            item.total = item.totalPago;
            item.tipoDeComprobante = "PAGO";
            break;
          case 'n': item.tipoDeComprobante = "NÓMINA"; break;
        }

        return item;
      })
      this.lista = list;

    })
  }

  ngAfterViewInit(): void {
    var containerBase = document.getElementById("file-manager-component");
    containerBase!.style.padding = "0px";
    console.log("El ContainerBase", containerBase);
    //this.getCfdis();
  }

  @HostListener('window:resize', ['$event'])
  onChangeHeight(event: any) {
    this.recalcHeight(event);
  }

  recalcHeight(event: any) {
    this.scrollHeihgt = event.target.innerHeight - 178;
  }

  ngOnDestroy() {
    this.appSettings.appSidebarMinified = false;
    this.appSettings.appContentFullHeight = false;
    this.appSettings.appContentClass = '';
  }

  // getCfdis() {
  //   this.efiscoService.getListCfdis().subscribe({
  //     next: (result: any) => {
  //       console.log(result);
  //     },
  //     error: (err: Error) => console.log(err.message)
  //   });
  // }
}
