import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { SaldoCXP } from "../interfaces/saldocxp.interface";
import { ItemEstadoCuentaCXP } from "../interfaces/cuentacxp.interface";

@Injectable({ providedIn: 'root' })
export class CuentasPorPagarService {
  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);

  getCustomerBalance(idEmpresa: number, idSucursal: number, idProvedor: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal).set("idProveedor", idProvedor);
    return this.http.get<SaldoCXP[]>(`${this.baseUrl}/CuentasPorPagar/ObtenerMovimientosCliente`, { params });
  }

  obtenerListaDeAniosconMovimientosDeCXP() {
    return this.http.get<number[]>(`${this.baseUrl}/CuentasPorPagar/ObtenerListaDeAniosConMovtosDeCXP`);
  }

  getEstadoDeCuenta(idProveedor: number, idCuenta:number,anio: number) {
    const params = new HttpParams().set("idProveedor", idProveedor).set("idCuenta",idCuenta).set("anio",anio);
    return this.http.get<ItemEstadoCuentaCXP[]>(`${this.baseUrl}/CuentasPorPagar/ConsultaEstadoCuentaPorProveedor`, { params });
  }


}
