import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CajaChicaDetalle, ClaseGastoCajaChica } from '../../interfaces/tipotransferencia.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';
import { Proveedor } from 'src/app/compras/interfaces/proveedores.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { FamiliaService } from '../../../Inventarios/services/familia.service';
import { FinanzasService } from '../../services/finanzas.service';
import Swal from 'sweetalert2';
import { Producto } from '../../../Inventarios/interfaces/producto.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';

@Component({
  selector: 'app-ctrl-edicion-caja',
  templateUrl: './ctrl-edicion-caja.component.html',
  styles: [
  ]
})
export class CtrlEdicionCajaComponent implements OnInit {

  @ViewChild('txtProveedor')
  public txtProveedor!: ElementRef<any>;
  @ViewChild('txtConcepto')
  public txtConcepto!: ElementRef<any>;
  @ViewChild('txtTotal')
  public txtTotal!: ElementRef<any>;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('ctrlAgregarCfdi') public ctrlAgregarCfdi!: ElementRef<HTMLElement>;
  @Input()
  public item!: any | null;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();

  searchConfigurationCliente: SearchConfiguration | null = null;
  info: UserLogged = {} as UserLogged;
  addingcfdi: boolean = false;
  fechaActual: Date = new Date();

  public myForm: FormGroup = this.fb.group({
    id: [0],
    orden: [0],
    proveedor: [{} as Proveedor],
    clase: [{} as ClaseGastoCajaChica],
    concepto: [''],
    serieFolioFactura: [''],
    referencia: [''],
    uuid: [''],
    subtotal: [0],
    iva: [0],
    iVARetenido: [0],
    iSRRetenido: [0],
    impLocalTrasladado: [0],
    impLocalRetenido: [0],
    total: [0],
  })

  constructor(private fb: FormBuilder, private ms: ModalService, private utileService: UtilsService, private fService: FinanzasService) { }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    setTimeout(() => {
      const t: any = this.txtProveedor;
      if (this.item) {
        if (this.item.clase) {
          if (this.item.clase.id) {
            this.item.clase.Id = this.item.clase.id;
            this.item.clase.Clave = this.item.clase.clave;
            this.item.clase.Nombre = this.item.clase.nombre;
          }
        }
        if (this.item.proveedor) {
          if (this.item.proveedor.Clave) {
            t.tagInput.nativeElement.value = this.item.proveedor.Clave;
          } else {
            t.tagInput.nativeElement.value = this.item.proveedor.clave;
          }
          this.myForm.reset(this.item);
        }
      }
      t.tagInput.nativeElement.focus();
    }, 150);
  }


  selectedItem(ent: ComboBoxEntity) {
    this.myForm.controls["clase"].setValue(ent);
  }

  onCloseCfdi(lista: DescargaCfdi[] | null) {
    this.addingcfdi = false;
    if (lista) {
      if (lista.length > 0) {
        if (lista.length > 1) {
          Swal.fire({ text: 'Solo debe de seleccionar un CFDI.', icon: 'error', })
          return;
        }
        const i: DescargaCfdi = lista[0];
        this.setCfdi(i);
      }
    }
    const t: any = this.txtTotal;
    t.tagInput.nativeElement.focus();
  }

  setCfdi(i: DescargaCfdi) {
    this.myForm.controls["subtotal"].setValue(i.subtotal);
    this.myForm.controls["iva"].setValue(i.ivaTrasladado);

    this.myForm.controls["iva"].setValue(i.ivaTrasladado);
    this.myForm.controls["iVARetenido"].setValue(i.ivaRetenido);
    this.myForm.controls["iSRRetenido"].setValue(i.isrRetenido);
    this.myForm.controls["total"].setValue(i.total);
    this.myForm.controls["uuid"].setValue(i.uuid);
  }

  blurSerieFolio(value: any) {
    if (value.after != value.before) {
      let rfc = "";
      const prov = this.myForm.value.proveedor;
      if (prov) {
        if (prov.Rfc) {
          rfc = prov.Rfc;
        } else {
          rfc = prov.rfc;
        }
        this.fService.buscarCfdi(value.after, rfc).subscribe((result) => {
          if (result) {
            this.setCfdi(result);
          } else {
            Swal.fire({ text: 'No se encontró el CFDI indicado.', icon: 'error', })
          }
        })
      }
    }
    if (value.after == "") {
      this.myForm.controls["uuid"].setValue("");
    }
  }

  blurProveedor(value: any) {
    if (value.after != value.before) {
      if (value.after) {
        this.fService.buscarProveedor(this.info.empresa!.numero, value.after).subscribe((prov) => {
          if (prov) {
            this.myForm.controls["proveedor"].setValue(prov);
            const t: any = this.txtConcepto;
            t.tagInput.nativeElement.focus();
          } else {
            const t: any = this.txtProveedor;
            t.tagInput.nativeElement.value = "";
            Swal.fire({ text: `No se encontró el proveedor indicado.`, icon: 'error', }).then(() => {
              setTimeout(() => {
                t.tagInput.nativeElement.focus();
              }, 150);
            })
          }
        })
      } else {
        this.myForm.controls["proveedor"].setValue(null);
      }
    }
  }

  openProvSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["proveedor"].setValue(e);
        const t: any = this.txtProveedor;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtConcepto;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtProveedor;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getNombreProveedor(): string {

    if (this.myForm.value.proveedor) {
      if (this.myForm.value.proveedor.Nombre) {
        return this.myForm.value.proveedor.Nombre;
      }
      if (this.myForm.value.proveedor.nombre) {
        return this.myForm.value.proveedor.nombre;
      }
    }
    return "";
  }

  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  openCfdi() {
    const b: any = this.ctrlAgregarCfdi;
    this.ms.openModal(b, (e: DescargaCfdi[]) => {
      this.onCloseCfdi(e);
    });
  }

  closeModal() {

    this.onCloseEdition.emit(null)
  }

  accept() {

    const t: any = this.txtTotal;
    t.tagInput.nativeElement.blur();

    const ent = this.myForm.value;

    let idProv = 0, idClase = 0;

    if (ent.proveedor) {
      if (ent.proveedor.Id > 0) { idProv = ent.proveedor.Id; }
      if (ent.proveedor.id > 0) { idProv = ent.proveedor.id; }
    }

    if (ent.clase) {
      if (ent.clase.Id > 0) { idClase = ent.clase.Id; }
      if (ent.clase.id > 0) { idClase = ent.clase.id; }
    }
    if (idProv == 0) {
      Swal.fire({ text: 'Debe de indicar un proveedor.', icon: 'error', })
      return;
    }
    if (idClase == 0) {
      Swal.fire({ text: 'Debe de indicar una Clase de Gasto.', icon: 'error', })
      return;
    }

    if (!ent.concepto) {
      Swal.fire({ text: 'Debe de indicar un concepto.', icon: 'error', })
      return;
    }

    if (!ent.referencia) {
      Swal.fire({ text: 'Debe de indicar una referencia.', icon: 'error', })
      return;
    }

    if (ent.subtotal == 0) {
      Swal.fire({ text: 'Debe de indicar el Subtotal.', icon: 'error', })
      return;
    }

    if (ent.total == 0) {
      Swal.fire({ text: 'Debe de indicar el total.', icon: 'error', })
      return;
    }

    if (ent.total != ent.subtotal) {
      if ((ent.iva + ent.iVARetenido + ent.iSRRetenido + ent.impLocalTrasladado + ent.impLocalRetenido) == 0) {
        Swal.fire({ text: 'Debe de indicar por lo menos un impuesto.', icon: 'error', })
        return;
      }
    }

    if (!ent.iVARetenido) ent.iVARetenido = 0;
    if (!ent.iSRRetenido) ent.iSRRetenido = 0;
    this.onCloseEdition.emit(ent)
  }

}
