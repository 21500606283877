import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserLogged } from 'src/app/auth/interfaces';
import { AuthService } from 'src/app/auth/services/auth.service';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss']
})
export class MobileLayoutComponent {

  isLoading: boolean = false;
  usuarioLogeado!: UserLogged;
  private authService = inject(AuthService);
  private utilsService = inject(UtilsService);
  private idle = inject(Idle);
  private keepalive = inject(Keepalive);
  private cd = inject(ChangeDetectorRef);
  private eventsService = inject(EventsService);

  //#region Some fileds to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown: number | null = null;
  lastPing: Date | null = null;
  timeIdle = 1200; //Tiempo de espera para cerrar sesión (20 min pero en segundos)
  //#endregion

  OnInit() {
    this.usuarioLogeado = this.utilsService.getUserLogged();
  }


  ngAfterViewInit() {
    this.isLoading = false;
    this.eventsService.subscribe(('home:isLoading'), (data) => {
      this.isLoading = data.isLoading
    });
  }

  initIdleParams() {
    this.idle.setIdle(1);
    this.idle.setTimeout(this.timeIdle);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      this.cd.detectChanges();
    });

    this.idle.onTimeout.subscribe((data: any) => {
      this.idleState = "TIME_OUT";
      this.timerLogout();
    });
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  timerLogoutIsOpen: boolean = false;
  timerLogout() {
    let timerInterval: any = null;
    Swal.fire({
      title: 'Sesión Expirada',
      html: "Su sesión expirará en <b></b> segundos.",
      icon: 'warning',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Cerrar Sesión',
      cancelButtonText: 'Mantener Sesión',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        let b: any = Swal.getHtmlContainer()?.querySelector('b');
        timerInterval = setInterval(() => {
          b.textContent = (Number(Swal.getTimerLeft()) / 1000).toFixed(0)
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed || (String(result.dismiss) === "timer")) {
        this.logout();
      } else {
        this.resetIdleParams();
      }
      this.timerLogoutIsOpen = false;
    });
  }

  resetIdleParams() {
    console.log("Last Ping: " + this.lastPing);
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  logout() {
    this.utilsService.isLoad(true);
    this.authService.logout().subscribe({
      next: () => {
        this.idle.stop();
        this.utilsService.isLoad(false);
      },
      error: (err: Error) => console.error(err)
    })
  }
}
