import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CartaPorte, ParteTransporte, TipoFigura, c_FiguraTransporte, c_Pais } from '../../interfaces/cartaporte.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CartaPorteService } from '../../services/cartaporte.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Chofer } from '../../interfaces/chofer.interface';
import Swal from 'sweetalert2';
import { ModalService } from 'src/app/service/modal.service';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-ctrl-edicion-tipo-figura',
  templateUrl: './ctrl-edicion-tipo-figura.component.html',
  styles: [
  ]
})
export class CtrlEdicionTipoFiguraComponent implements OnInit {
  private eventsService = inject(EventsService);
  saving: boolean = false;
  @Input()
  public colocarMargenes: boolean = true;
  @Input()
  public tabIndex: number = 0;
  @Input()
  public item!: TipoFigura;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void { }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Tipo: [{} as c_FiguraTransporte],
    TipoNombre: [''],
    RFCFigura: [''],
    NumLicencia: [''],
    NombreFigura: [''],
    NumRegIdTribFigura: [''],
    ResidenciaFiscalFigura: [{} as c_Pais],
    Partes: [[] as ParteTransporte[]],
    CartaPorte: [{} as CartaPorte],
  })

  constructor(private fb: FormBuilder, private cpService: CartaPorteService, private ms: ModalService) {
  }

  selectedCombo(ent: ComboBoxEntity) {
    if (ent) {
      this.cpService.getChofer(ent.Id).subscribe((result) => {
        if (result.success) {
          const chofer: Chofer = JSON.parse(result.message);

          this.myForm.reset({
            NombreFigura: chofer.Nombre,
            Tipo: chofer.Figura,
            TipoNombre: chofer.Figura.Nombre,
            RFCFigura: chofer.Rfc,
            NumLicencia: chofer.Licencia
          })
        }
      })
    }
  }

  get getStyle(): string {
    let t = "width: 540px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
    return t;
  }

  accept() {

    if (!this.myForm.value.RFCFigura || !this.myForm.value.NumLicencia || !this.myForm.value.NombreFigura) {
      this.eventsService.publish('home:showAlert', { message: `Seleccione una Figura.`, cancelButton: false });
      return false;
    }

    this.onCloseEdition.emit(this.myForm.value)
    this.ms.closeModal(this.myForm.value);
    return;
  }

  closeModal() {
    this.onCloseEdition.emit(null)
    this.ms.closeModal(null);
  }

}
