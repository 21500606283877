import { Component, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'anticipos-facturados-page.component',
  templateUrl: './anticipos-facturados-page.component.html',
  styles: [
  ]
})
export class AnticiposFacturadosPageComponent implements OnInit {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;


  private reportsService = inject(ReportsService)

  public filtrosAnticiposFacturados: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'Saldos' }],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Anticipos facturados',
      NombreExcel: 'Anticipos facturados.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'v.Vigente', Etiqueta: 'Estado', Tipo: TypeFilter.boolean, ListaItems: [{ Nombre: 'Sin selección' }, { Nombre: 'Vigentes' }, { Nombre: 'No vigentes' }] },
        { Campo: 'v.SerieString', Etiqueta: 'Serie', Tipo: TypeFilter.text },
        { Campo: 'v.Folio', Etiqueta: 'Folio', Tipo: TypeFilter.number },
      ],
    };

  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  constructor(private fb: FormBuilder, private utils: UtilsService) { }

  ngOnInit(): void {
    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('ANTICIPOS FACTURADOS AL ' + fecha.toUpperCase());
    this.focus('txtNombreReporte');
    let fecha1: Date = new Date();
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onclicSinAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc3')?.setValue(!value);
    }
  }

  onclicSoloAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  onclicSinFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc4')?.setValue(!value);
    }
  }

  onclicSoloFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc2')?.setValue(!value);
    }
  }

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  // changeselec(event: any) {
  //   this.filtrosAnticiposFacturados = event;
  // }

  clickBoton(event: string) {
    const ReporteDiarioDeVentas = this.myForm.value;
    this.filtrosAnticiposFacturados.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosAnticiposFacturados, '/Ventas/ReporteAnticiposFacturados');

  }
}









