<form [formGroup]="frmColor" class="col-sm-12">
  <div ngbDropdown class="input-group">
    <div [ngStyle]="{'background-color': this.color}" class="input-group-text color-indicator" id="colorPickerSelector"
      ngbDropdownToggle>
    </div>
    <input (blur)="blurControl(txtTag.value)" #txtTag formControlName="textValue" style="border-radius: 0px;" type="text" class="form-control form-control-sm" />
    <div style="padding: 0;" ngbDropdownMenu aria-labelledby="colorPickerSelector">
      <color-chrome [disableAlpha]="true" [color]="color" (onChange)="handleChange($event)"></color-chrome>
    </div>
  </div>
</form>
