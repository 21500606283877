<div class="screen-content">
  <app-panel-base title="Dirección">
    <div class="panel-content">
      <form [formGroup]="this.myForm" autocomplete="off">
        <components-ui-text-box *ngIf="esEntrega" [required]="esEntrega && enabled" label="Nombre"
          helpLine="Nombre de la persona que recibe la mercancía, 100 caracteres." formControlName="NombreRecibe"
          [tabIndex]="this.tabIndex" [widthTextColumns]="4" [widthLabelColumns]="2" (blur)="onBlurDireccion()"
          [enabled]="this.enabled" [maxLength]="100" />

        <div class="form-group row">
          <div class="col-md-4">
            <component-ui-combo-box label="País" [required]="true" [tabIndex]="this.tabIndex+1"
              helpLine="País de procedencia." listProperty='ClaveNombre' [setItemsList]=" this.paisesList"
              [widthTextColumns]="3" [widthLabelColumns]="6" [widthRightColumns]="3"
              (onSelectedItem)="onSelectPais($event)" (blur)="onBlurDireccion()" [required]="true"
              [selectManualItem]="selectPaisManualItem" [zeroMask]="2" [enabled]="this.enabled" />
          </div>
          <div class="col-md-3">
            <components-ui-number-box label='C.P.' formControlName="CodigoPostal" [tabIndex]="this.tabIndex + 2"
              helpLine="Código Postal o PO BOX, al indicar el CP se busca de forma automática los datos correspondientes."
              [widthTextColumns]="4" [widthLabelColumns]="4" (blur)="onBlurCP()" [enabled]="this.enabled"
              [required]="getCPrequerido" [maxLength]="5" (onFocus)="tomoFoco()"></components-ui-number-box>
          </div>
        </div>
        <!--  -->
        <div class="form-group row" [hidden]="!(tomoFocoCP)">
          <div class="col-md-2 pe-0 ps-0">
          </div>
          <div class="col-md-6 ps-0">
            <components-ui-list-box [columnList]="'d_tipo_asenta,d_asenta,d_ciudad,d_estado'"
              [itemList]="this.codigosList" [tabIndex]="this.tabIndex + 3" label='' [widthTextColumns]="8"
              [widthLabelColumns]="4" (select)="selectItemCodigo($event)" [enabled]="this.enabled"
              #listBoxDirecciones></components-ui-list-box>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <components-ui-text-box [required]="esEntrega" label="Calle" formControlName="Calle"
              helpLine="Calle, 200 caracteres." [tabIndex]="this.tabIndex + 4" [widthTextColumns]="8"
              [widthLabelColumns]="4" (blur)="onBlurDireccion()" [enabled]="this.enabled" [maxLength]="200" #calle />
          </div>
          <div class="col-md-3">
            <components-ui-text-box [required]="esEntrega" label="Número ext." formControlName="NumeroExterior"
              helpLine="Número Exterior, 10 caracteres." [tabIndex]="this.tabIndex + 5" [widthTextColumns]="6"
              [widthLabelColumns]="5" (blur)="onBlurDireccion()" [enabled]="this.enabled" [maxLength]="10" />
          </div>
          <div class="col-md-3">
            <components-ui-text-box label="Número int." formControlName="NumeroInterior" [tabIndex]="this.tabIndex + 6"
              helpLine="Número Interior, 10 caracteres." [widthTextColumns]="6" [widthLabelColumns]="5"
              (blur)="onBlurDireccion()" [enabled]="this.enabled" [maxLength]="10" />
          </div>
        </div>
        <components-ui-text-box [required]="esEntrega" label="Colonia" formControlName="Colonia"
          [tabIndex]="this.tabIndex + 7" [widthTextColumns]="4" [widthLabelColumns]="2" (blur)="onBlurDireccion()"
          helpLine="Colonia, 100 caracteres." [enabled]="this.enabled" [maxLength]="100" />
        <components-ui-text-box [required]="esEntrega" label="Ciudad/Localidad" formControlName="Localidad"
          [tabIndex]="this.tabIndex + 8" [widthTextColumns]="4" [widthLabelColumns]="2" (blur)="onBlurDireccion()"
          helpLine="Ciudad, 100 caracteres." [enabled]="this.enabled" [maxLength]="100" />
        <components-ui-text-box [required]="esEntrega" label="Municipio" formControlName="Municipio"
          helpLine="Municipio, 100 caracteres." [tabIndex]="this.tabIndex + 9" [widthTextColumns]="4"
          [widthLabelColumns]="2" (blur)="onBlurDireccion()" [enabled]="this.enabled" [maxLength]="100" />
        <component-ui-combo-box [required]="esEntrega" [required]="true" label="Estado" [tabIndex]="this.tabIndex + 10"
          helpLine="Seleccione un estado de la lista." listProperty='ClaveNombre' [setItemsList]="this.estadossList"
          [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="4"
          (onSelectedItem)="onSelectEstado($event)" (blur)="onBlurDireccion()"
          [selectManualItem]="selectEstadoManualItem" [zeroMask]="2" [enabled]="this.enabled" />
        <components-ui-text-box label="Teléfono" formControlName="Telefono" [tabIndex]="this.tabIndex + 11"
          helpLine="Teléfonos, 300 caracteres." [widthTextColumns]="4" [widthLabelColumns]="2"
          (blur)="onBlurDireccion()" [enabled]="this.enabled" [maxLength]="300" />
        <components-ui-text-box label="Referencias" formControlName="Descripcion" [tabIndex]="this.tabIndex + 12"
          helpLine="Referencias, 500 caracteres." [widthTextColumns]="4" [widthLabelColumns]="2"
          (blur)="onBlurDireccion()" [enabled]="this.enabled" [maxLength]="100" />
        <!-- {{this.myForm.value |json}} -->
      </form>
    </div>
  </app-panel-base>
</div>
