import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';
import { c_FormaPago } from '../../interfaces/formapago.interface';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType } from '@generic-ui/ngx-grid';
import { VentaFormaPago, VentaFormaPagoDatosCheques, VentaFormaPagoDatosTarjeta, VentaFormaPagoDatosTransferencia } from '../../interfaces/venta.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-forma-pago-page',
  templateUrl: './forma-pago-page.component.html',
  styleUrls: ['./forma-pago-page.component.scss']
})

export class FormaPagoPageComponent implements OnInit {

  private readonly baseUrl: string = environment.baseUrlApi;
  private eventsService = inject(EventsService);


  @ViewChild('edicionTarjeta')
  public edicionTarjeta!: ElementRef<HTMLElement>;
  @ViewChild('edicionTransferencia')
  public edicionTransferencia!: ElementRef<HTMLElement>;
  @ViewChild('edicionCheque')
  public edicionCheque!: ElementRef<HTMLElement>;


  constructor(private http: HttpClient,
    private mService: ModalService) {
  }


  payments: c_FormaPago[] = [];

  @Input()
  serieFolio: string = "";
  @Input()
  formasPago: VentaFormaPago[] = [];
  @Input()
  total: number = 0;
  @Input()
  idSucursal: number = 0;
  @Input()
  anticipoAplicar: number = 0;
  datosTarjeta: VentaFormaPagoDatosTarjeta | null = null;
  datosTransferencia: VentaFormaPagoDatosTransferencia | null = null;
  datosCheque: VentaFormaPagoDatosCheques | null = null;

  source: Array<VentaFormaPago> = [];
  blockPayment: boolean = false;


  ngOnInit(): void {
    if (!this.formasPago) {
      this.formasPago = [];
    } else {
      if (this.formasPago.length > 0) {
        if (this.formasPago[0].FormaPago?.Id == 22) {
          this.blockPayment = true;
        }
      }
    }
    this.sourceReset([...this.formasPago]);
    this.getPayments();
  }

  enter(e: any) {
    e.target.blur();
  }

  focusImporte(e: any) {
    e.target.classList.add("focus-editor-grid");
  }
  blurImporte(e: any, index: number) {
    e.target.classList.remove("focus-editor-grid");
    let importe = Number(e.target.value);
    if (!importe) {
      e.target.value = "0.00"
      importe = 0;
      if (this.source[index].FormaPago) {
        this.source.splice(index, 1);
      }
      this.source = [...this.source];
      this.sourceReset(this.source.filter(P => P.FormaPago));
      return;
    }
    this.source[index].Importe = importe;
    this.source = [...this.source];
  }

  deleteRow(index: number) {
    if (this.blockPayment) return;
    this.eventsService.publish('home:showAlert', {
      message: `¿Desea eliminar la Forma de Pago?`,
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.source.splice(index, 1);
          this.source = [...this.source];
          this.sourceReset(this.source.filter(P => P.FormaPago));
        }
      }
    });


  }


  selectPayment(index: number, item: any) {
    const selected: number = parseInt(item.target.value);
    this.source[index].FormaPago = this.payments.filter(P => P.Id == selected)[0];
    this.source = [...this.source]
  }


  closeModal() {
    this.mService.closeModal(null);
  }

  get getDiff(): number {
    let dif = this.total - this.getTotalCapturado;
    if (dif < 0) dif = 0;
    return dif;
  }

  get getTotalCapturado(): number {
    let importe: number = 0;
    this.source.filter(P => P.Importe > 0).forEach((i) => { importe += parseFloat(i.Importe.toString()) });
    return importe;
  }

  accept() {
    let importe: number = 0;
    this.source.filter(P => P.FormaPago).forEach((i) => { importe += parseFloat(i.Importe.toString()) });

    if (importe > this.total) {
      this.eventsService.publish('home:showAlert', { message: `Las formas de pago son mayor al total del Documento.`, cancelButton: false });
      return;
    }

    if (importe < (this.total - this.anticipoAplicar)) {
      this.eventsService.publish('home:showAlert', { message: `Las formas de pago son menores al total del Documento.`, cancelButton: false });
      return;
    }
    let final: VentaFormaPago[] = this.source.filter(P => P.FormaPago);
    let errorTarjetas = false;
    let errorTransferencias = false;
    let errorCheques = false;
    let errorTarjetaImporte = false;
    let errorTransferenciaImporte = false;
    let errorChequeImporte = false;
    final.map((l) => {
      if (l.Importe > 0) {
        if (l.FormaPago?.Clave == 4 || l.FormaPago?.Clave == 28) {
          if (!l.DatosTarjeta) {
            errorTarjetas = true;
          } else {
            l.DatosCheque = null;
            l.DatosTransferencia = null;

            if (l.Importe != l.DatosTarjeta.Importe) {
              errorTarjetaImporte = true;
            }

          }
        }
        if (l.FormaPago?.Clave == 3) {
          if (!l.DatosTransferencia) {
            errorTransferencias = true;
          } else {
            if (l.Importe != l.DatosTransferencia.ImporteAplicar) {
              errorTransferenciaImporte = true;
            }
            l.DatosCheque = null;
            l.DatosTarjeta = null;
          }
        }
        if (l.FormaPago?.Clave == 2) {
          if (!l.DatosCheque) {
            errorCheques = true;
          } else {
            if (l.Importe != l.DatosCheque.ImporteAplicar) {
              errorChequeImporte = true;
            }
            l.DatosTransferencia = null;
            l.DatosTarjeta = null;
          }
        }
      }
      return importe;
    })

    if (errorTarjetaImporte) {
      this.eventsService.publish('home:showAlert', { message: `El importe de la Forma de Pago con Tarjeta es diferente al indicado enla información capturada.`, cancelButton: false });
      return;
    }

    if (errorTransferenciaImporte) {
      this.eventsService.publish('home:showAlert', { message: `El importe de la Forma de Pago con Transferencia es diferente al indicado enla información capturada.`, cancelButton: false });
      return;
    }

    if (errorChequeImporte) {
      this.eventsService.publish('home:showAlert', { message: `El importe de la Forma de Pago con Cheque es diferente al indicado enla información capturada.`, cancelButton: false });
      return;
    }

    if (errorTarjetas) {
      this.eventsService.publish('home:showAlert', { message: `Hay formas de pago con Tarjeta de Crédito o Débito, por favor capture los datos de Terminal donde se cobrará.`, cancelButton: false });
      return;
    }

    if (errorTransferencias) {
      this.eventsService.publish('home:showAlert', { message: `Hay formas de pago con Transferencia Electrónica de Fondos, por favor capture los datos de la transferencia.`, cancelButton: false });
      return;
    }

    if (errorCheques) {
      this.eventsService.publish('home:showAlert', { message: `Hay formas de pago con Cheque, por favor capture los datos del Cheque.`, cancelButton: false });
      return;
    }


    this.mService.closeModal([...final]);
  }

  sourceReset(formasPago: any = []) {
    this.source = formasPago;
    for (let index = 0; index < 25; index++) {
      this.source = [...this.source, {
        Id: 0,
        FormaPago: null,
        FormaPagoNombre: '',
        DatosTarjeta: null,
        DatosTransferencia: null,
        DatosCheque: null,
        Importe: 0,
      }]
    }
    this.source = [...this.source];
  }


  getPayments() {
    return this.http.get<c_FormaPago[]>(`${this.baseUrl}/Ventas/ObtenerFormasPagoActivas`).subscribe((lista) => {
      this.payments = lista;
    })
  }

  clickButton(item: c_FormaPago) {
    this.mService.closeModal(item);
  }

  editarCheque(index: number) {
    const item: VentaFormaPago = { ...this.source[index] };
    if (item.DatosCheque) {
      this.datosCheque = { ...item.DatosCheque };
    } else {
      this.datosCheque = {
        Id: 0,
        Banco: null,
        CuentaBancaria: null,
        Fecha: new Date(),
        ImporteAplicar: item.Importe,
        ImporteOriginal: item.Importe,
        NumeroCheque: '',
        NumeroAutorizacion: '',
        CuentaProteccion: '',
        Referencia: this.serieFolio,
      };
    }
    const b: any = this.edicionCheque;
    this.mService.openModal(b, (e: any) => {
      if (e) {
        this.source[index].DatosCheque = { ...e }
        this.source = [...this.source];
      }
    });

  }

  editarTransferencia(index: number) {
    const item: VentaFormaPago = { ...this.source[index] };
    if (item.DatosTransferencia) {
      this.datosTransferencia = { ...item.DatosTransferencia };
    } else {
      this.datosTransferencia = {
        Id: 0,
        Banco: null,
        CuentaBancaria: null,
        FechaDeposito: new Date(),
        ImporteAplicar: item.Importe,
        ImporteOriginal: item.Importe,
        ReferenciaBancaria: '',
        Referencia: this.serieFolio,
      };
    }
    const b: any = this.edicionTransferencia;
    this.mService.openModal(b, (e: any) => {
      if (e) {
        this.source[index].DatosTransferencia = { ...e }
        this.source = [...this.source];
      }
    });

  }

  editarTarjeta(index: number) {
    const item: VentaFormaPago = { ...this.source[index] };
    if (item.DatosTarjeta) {
      this.datosTarjeta = { ...item.DatosTarjeta };
    } else {
      this.datosTarjeta = {
        Id: 0,
        Banco: null,
        CuentaBancaria: null,
        Debito: item.FormaPago?.Clave == 28,
        Credito: item.FormaPago?.Clave == 4,
        TerminalBancaria: null,
        Importe: item.Importe,
        NumeroLote: '',
        Referencia: this.serieFolio,
      };
    }
    const b: any = this.edicionTarjeta;
    this.mService.openModal(b, (e: any) => {
      if (e) {
        this.source[index].DatosTarjeta = { ...e }
        this.source = [...this.source];
      }
    });

  }


}
