<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" (keydown)="keydown($event)">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Captura de Póliza/Movimiento #{{item!.index}}
  </div>
  <div class="card-body bg-light">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <form [formGroup]="myForm" autocomplete="off">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation"><a href="#asientos" data-bs-toggle="tab" class="nav-link active"
                aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span>
                <div class="btn-group">
                  <a class="btn btn-dark"><i class="fa fa-backward"></i></a>
                  <a class="btn btn-dark"><i class="fa fa-forward"></i></a>
                  <!-- <a *ngIf="solicitaDIOT" style="margin-left: 319px;" class="btn btn-green float-end"><i
                      class="fa fa-user-plus"></i></a>
                  <a *ngIf="solicitaDIOT" class="btn btn-danger float-end"><i class="fa fa-user-minus"></i></a> -->
                </div>
              </a></li>
          </ul>
          <div class="tab-content panel rounded-0 p-3 m-0">
            <div id="asientos" class="tab-pane fade active show" role="tabpanel">
              <span *ngIf="nombreCuenta" class="badge bg-dark rounded-0">{{nombreCuenta}}</span>
              <app-level-text-box (onEscape)="closeModal()" (onSelect)="onSelectCuenta($event)" (onLastLevel)="onLastLevelCuenta()"
                [idEmpresa]="myForm.value.empresa.id" [setClaveGeneral]="getClaveGeneral" [captureMode]="true"
                (onChangue)="onChangueCuenta($event)" label="Cuenta" [widthLabelColumns]="3" [widthTextColumns]="9"
                [tabIndex]="tabIndex" [type]="'CuentaContable'" #txtCuentas />
              <app-level-text-box [idEmpresa]="myForm.value.empresa.id" [captureMode]="true" [enabled]="false"
                [setClaveGeneral]="myForm.value.claveCentroCostoNivelGlobal" [captureMode]="true" label="Centro Costo"
                (onChangue)="onChangueCentro($event)" [widthLabelColumns]="3" [widthTextColumns]="9"
                [type]="'CentroCosto'" />

              <components-ui-text-box [label]="'Concepto'" [required]="true" formControlName="conceptoDetalle"
                [maxLength]="500" [tabIndex]="tabIndex + 6" [widthTextColumns]="9" [widthLabelColumns]="3"
                #txtConcepto />
              <components-ui-text-box [label]="'Referencia'" formControlName="referencia" [maxLength]="100"
                [tabIndex]="tabIndex + 7" [widthTextColumns]="9" [widthLabelColumns]="3" />

              <components-ui-number-box [editNegativo]="true" (keydown.enter)="enterImporte(1)" [label]="'Cargo'"
                formControlName="importeCargo" [tabIndex]="tabIndex + 8" [widthTextColumns]="3" [widthLabelColumns]="3"
                #txtCargo />
              <components-ui-number-box [editNegativo]="true" (keydown.enter)="enterImporte(2)" [label]="'Abono'"
                formControlName="importeAbono" [tabIndex]="tabIndex + 9" [widthTextColumns]="3" [widthLabelColumns]="3"
                #txtAbono />

              <div style="width: 361px; border: 1px dashed;margin-left: 111px;">
                <div class="d-flex">
                  <span style="width: 95px;text-align: right;" class="text-dark fw-bold fs-11px">Sumas iguales:</span>
                  <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ totalCargos |
                    number:'1.2'}}
                  </div>
                  <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ totalAbonos |
                    number:'1.2'}}
                  </div>
                </div>
                <div class="d-flex ">
                  <span style="width: 95px;text-align: right;" class="text-dark fw-bold fs-11px">Diferencia:</span>
                  <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ totalDiferencia |
                    number:'1.2'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="card-footer fw-bold">
          <div style="text-align: right; margin-top: 5px;">
            <button [disabled]="searching" style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8] Aceptar</button>
            <button [disabled]="searching" (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</button>
          </div>
        </div>
      </div>

      <ng-template #ctrlDIOT let-modal let-c="close">
        <app-captura-diotpage [fechaPoliza]="fechaPoliza" [idDiot]="item.idDiot" />
      </ng-template>

    </app-container-base>

  </div>
</div>
