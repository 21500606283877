<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="ClaseCliente"
  title="Clases de Clientes" icon="fa fa-house-user" subTitle="Administra la configuración de Clases de Clientes">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Clase de Cliente'" entityName="ClaseCliente" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre />
          <!-- <app-level-text-box label="Cuenta contable" [widthTextColumns]="4" [tabIndex]="3" [type]="'CuentaContable'" #busquedaNiveles/> -->
        </div>
      </app-panel-base>

    </form>
  </div>
</app-container-base>
