import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-ordenes-manufactura-page',
  templateUrl: './verificador-ordenes-manufactura-page.component.html',
})
export class VerificadorOrdenesManufacturaPageComponent {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  private reportsService =inject(ReportsService);
  private utils = inject(UtilsService);

  menuId: string = "verificadorordenesmanufactura";

  public filtrosReporte: ReportFilter =
    {


      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{Nombre:'a Detalle'},
                      {Nombre:'a Totales'}],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor:'Verificador de Ordenes de Manufactura',
      NombreExcel:'Verificador de Ordenes de Manufactura.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda:true, Entidad:'Empresa' },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number,BotonBusqueda:true, Entidad:'Sucursal' },
        { Campo: 'ord.SerieString', Etiqueta: 'Serie documento', Tipo: TypeFilter.text},
        { Campo: 'ord.Folio', Etiqueta: 'Folio documento', Tipo: TypeFilter.number },
        { Campo: 'ord.SerieFolioDocumentoVentaOrigen', Etiqueta: 'Serie y folio origen', Tipo: TypeFilter.text },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number,BotonBusqueda:true, Entidad:'Cliente'  },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE ÓRDENES DE MANUFACTURA ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  onChangeFecha(value:any)
  {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE ÓRDENES DE MANUFACTURA ' + this.rangoFechas);
  }

  get rangoFechas(){
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1,this.myForm.value.Fecha2,2).toUpperCase();
   }

  clickBoton(event: string) {

    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte,'/Manufactura/VerificadorOrdenManufactura');
  }



 }
