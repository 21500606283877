<p-dialog class="panel-loading" header="Agregar ambiente de trabajo" [(visible)]="visible" [modal]="true"
  [draggable]="true" [resizable]="false" [closeOnEscape]="false" [closable]="false" [style]="{height: '650px'}">
  <div class="pt-10px pb-10px">
    <form autocomplete="off" [formGroup]="frmAgregarBD" (ngSubmit)="guardarEntorno()">
      <div class="form-floating mb-15px">
        <input type="text" formControlName="nombreBD" class="form-control text-uppercase h-45px fs-13px"
          placeholder="Nombre de la base de datos" id="nombreBD" />
        <label for="nombreBD" class="d-flex align-items-center fs-13px text-gray-600">Nombre de la base de datos</label>
      </div>
      <div class="form-floating mb-15px">
        <input type="text" formControlName="etiquetaBD" class="form-control text-uppercase h-45px fs-13px"
          placeholder="Etiqueta del ambiente de trabajo" id="etiquetaBD" />
        <label for="etiquetaBD" class="d-flex align-items-center fs-13px text-gray-600">Etiqueta del ambiente de
          trabajo</label>
      </div>
      <div class="form-floating mb-15px">
        <input type="text" formControlName="servidor" class="form-control h-45px fs-13px" placeholder="Servidor"
          id="servidor" />
        <label for="servidor" class="d-flex align-items-center fs-13px text-gray-600">Servidor</label>
      </div>
      <div class="form-floating mb-15px">
        <input type="text" formControlName="usuarioServidor" class="form-control h-45px fs-13px" placeholder="Usuario"
          id="usuarioSevidor" />
        <label for="usuarioServidor" class="d-flex align-items-center fs-13px text-gray-600">Usuario</label>
      </div>
      <div class="input-group mb-3">
        <div class="form-floating mb-15px">
          <input type="password" formControlName="password" class="form-control h-45px fs-13px" placeholder="Contraseña"
            id="password" />
          <label for="password" class="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
        </div>
        <span class="input-group-text" style="height: 58px;padding: 0; background-color: #ffffff;">
          <button (click)="checkConnBD()" style="height: 58px; border-radius: 0px 5px 5px 0px;" type="button"
            class="btn btn-theme btn-lg">
            Probar Conexión
          </button>
        </span>
      </div>
      <div class="mb-15px">
        <button [disabled]="frmAgregarBD.invalid" type="submit"
          class="btn btn-theme d-block h-45px w-100 btn-lg fs-14px">Guardar ambiente de trabajo</button>
      </div>
    </form>

    <div *ngIf="mensajeConn" class="alert alert-{{isSuccessConn ? 'success':'danger'}} fade show text-center">
      {{mensajeConn}}</div>
  </div>
</p-dialog>
