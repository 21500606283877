<app-container-base subTitle="Verificador de Ordenes de Carga" (onClickBarButton)="clickBoton($event)"
  [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
              [widthLabelColumns]="2" [widthTextColumns]="8" [maxLength]="100" (onChangeValue)="onChangeFecha($event)"
              #txtNombreReporte />
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="Fecha1" [tabIndex]="2"
                [widthLabelColumns]="6" [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <component-ui-check-box label='Incluir Traspasos entre Sucursales' formControlName="Opc1" [tabIndex]="5"
                [widthLabelColumns]="6" [widthTextColumns]="2" (onClick)="incluirTraspasosEntreSUcursales($event)" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosDiarioVentas" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
