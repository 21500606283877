<div class="btn-toolbar justify-content-between mt-4" role="toolbar" aria-label="Toolbar with button groups">

  <div class="btn-group mr-2" role="group" aria-label="First group" *ngIf="formGroup !== undefined">
    <button class="btn btn-outline-danger w-100px me-5px" *ngIf="!formGroup!.dirty" (click)="onNuevo()">
      <i class="fas fa-file fa-lg"></i>
      Nuevo
    </button>
  </div>


  <div class="alert alert-danger alert-dismissible fade show rounded-0 mb-0" *ngIf="validaError().length > 0">
    <div class="d-flex">
      <i class="fa fa-circle-xmark fa-2x me-1"></i>
      <div class="mb-0 ps-2">
        {{ this.validaError() }}
      </div>
    </div>
    <button type="button" class="btn-close ms-3" data-bs-dismiss="alert"></button>
  </div>
  <div class="btn-group mr-2" role="group" aria-label="First group">
    <button class="btn btn-outline-danger w-100px me-5px" *ngIf="!formGroup!.dirty" (click)="onRegresar()">
      <i class="fas fa-reply fa-lg"></i>
      Regresar
    </button>
    <div *ngIf="formGroup !== undefined">
      <button *ngIf="formGroup.dirty" class="btn btn-outline-danger w-100px me-5px" (click)="onCancelar()">
        <i class="far fa-circle-xmark fa-lg"></i>
        Cancelar
      </button>

      <button *ngIf="formGroup.dirty" class="btn btn-green w-100px" (click)="onGuardar()">
        <i class="far fa-floppy-disk fa-lg"></i>
        Guardar
      </button>
    </div>
  </div>









  <!-- <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
  <div class="btn-group" role="group" aria-label="First group">
    <button type="button" class="btn btn-secondary">1</button>
    <button type="button" class="btn btn-secondary">2</button>
    <button type="button" class="btn btn-secondary">3</button>
    <button type="button" class="btn btn-secondary">4</button>
  </div>
  <div class="input-group">
    <div class="input-group-prepend">
      <div class="input-group-text" id="btnGroupAddon2">@</div>
    </div>
    <input type="text" class="form-control" placeholder="Input group example" aria-label="Input group example" aria-describedby="btnGroupAddon2">
  </div>
</div> -->
