<div class="card text-center border-0" style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" cdkDrag>
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Direcciones de entrega de {{nombreCliente |
    uppercase }}
  </div>
  <div class="w-100 ">
    <span class="float-end mt-1">
      <a (click)="addEditConsignado()" class="btn btn-sm btn-white me-1 "><i
          class="fa fa-folder-plus t-plus-1 text-green fa-fw fa-lg"></i>
        Agregar dirección</a>
    </span>
  </div>

  <app-panel-base title="Listado de direcciones de entrega" style="height: 350px;">
    <div class="panel-content">
      <div class="widget-input-container">
        <div class="widget-input-box">
          <input (keydown)="keydown($event)" type="text" class="form-control"
            placeholder="Teclee y presione enter para buscar" #txtBuscar>
        </div>
        <div class="widget-input-icon">
          <a class="text-body text-opacity-50" data-id="widget-elm" data-light-class="text-body text-opacity-50"
            data-dark-class="text-white">
            <i (click)="clicBuscar()" class="fa fa-magnifying-glass-location" style="cursor: pointer;"></i>
          </a>
        </div>
      </div>
      <ng-scrollbar style="height: 60px; width:1105px; border-bottom: 1px solid #AAAAAA;">
        <table class="blueTable" style="width:1050px;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th style="width: 10px;"></th>
              <th style="width: 10px;"></th>
              <th style="width: 60px;">Nombre</th>
              <th style="width: 20px;">RFC</th>
              <th style="width: 130px;">Dirección</th>
            </tr>
          </thead>
          <tbody>
            <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
              *ngFor="let item of lista; let index = index;">
              <td style="text-align: center;cursor: pointer;" (click)="delete(index)">
                <i class="fa fa-rectangle-xmark fa-1x"
                  style="color:red;font-weight: bold; "></i>
              </td>
              <td style="text-align: center;  cursor: pointer;" (click)="edit(index)">
                <i class="fa fa-pencil fa-1x"
                  style="color:rgb(255, 166, 0);font-weight: bold;"></i>
              </td>
              <td>
                <span style="margin-left: 3px;">{{item.Nombre|uppercase}}</span>
              </td>
              <td>
                <span style="margin-left: 3px;">{{item.Rfc|uppercase}}</span>
              </td>
              <td>
                <span style="margin-left: 3px;">
                  {{item.Direccion!.Calle?item.Direccion!.Calle:''}} {{item.Direccion!.NumeroExterior?item.Direccion!.NumeroExterior:''}}, {{item.Direccion!.Colonia?item.Direccion!.Colonia:''}}
                  {{item.Direccion!.Localidad?item.Direccion!.Localidad:''}}, {{item.Direccion!.Estado?item.Direccion!.Estado:''}} {{item.Direccion!.Pais ?item.Direccion!.Pais:''}}, CP:
                  {{item.Direccion!.CodigoPostal?item.Direccion!.CodigoPostal:''}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-scrollbar>

<!--
      <gui-grid [autoResizeWidth]="false" [loading]="buscando" [source]="lista" [width]="1080" [virtualScroll]="true" [rowHeight]="20"
        [rowSelection]="false" [theme]="'fabric'" [maxHeight]="250">
        <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
          <ng-template let-item="item" let-index="index">
            <i (click)="delete(index)" class="fa fa-rectangle-xmark"
              style="color:red;font-weight: bold; cursor: pointer;"></i>
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
          <ng-template let-item="item" let-index="index">
            <i (click)="edit(index)" class="fa fa-pencil" style="color:orange;font-weight: bold; cursor: pointer;"></i>
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.LEFT" header="Nombre" [width]="270" [cellEditing]="false">
          <ng-template let-item="item">
            {{ item.Nombre | uppercase }}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.LEFT" header="RFC" [width]="110" [cellEditing]="false">
          <ng-template let-item="item">
            {{ item.Rfc | uppercase}}
          </ng-template>
        </gui-grid-column>

        <gui-grid-column [align]="GuiColumnAlign.LEFT" header="Dirección" [width]="600" [cellEditing]="false">
          <ng-template let-item="item">
            {{item.Direccion.Calle}} {{item.Direccion.NumeroExterior}}, {{item.Direccion.Colonia}}
            {{item.Direccion.Localidad}}, {{item.Direccion.Estado}} {{item.Direccion.Pais}}, CP:
            {{item.Direccion.CodigoPostal}}
          </ng-template>
        </gui-grid-column>

      </gui-grid> -->

    </div>
  </app-panel-base>

  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-danger" #txtAceptar>Cerrar </div>

    </div>
  </div>

  <ng-template #modalConisgnado let-modal let-c="close">
    <app-alta-consignado-page [idCliente]="idCliente" [idConsignado]="idConsignadoEdicion" />
  </ng-template>

</div>
