import { Component, OnInit, inject } from '@angular/core';
import { ConceptoOrdenManufactura, HistoricoOrdenManufactura, OrdenManufactura } from '../../interfaces/manufacura.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-tablero-ordnes-page',
  templateUrl: './tablero-ordnes-page.component.html',
  styles: [
  ]
})
export class TableroOrdnesPageComponent implements OnInit {


  lista: any[] = [];
  abrirHistorico: boolean = false;
  abrirHistoricoProduccion: boolean = false;
  abrirProduccion: boolean = false;
  historicos: HistoricoOrdenManufactura[] = [];
  conceptos: ConceptoOrdenManufactura[] = [];
  idOrden: number = 0;
  private eventsService = inject(EventsService);
  constructor(private fs: FacturacionService) {

  }

  elaborar(item: OrdenManufactura) {
    this.idOrden = item.id;
    this.conceptos = item.conceptos;
    this.conceptos.map((i: ConceptoOrdenManufactura) => {
      i.producir = i.cantidadPendiente;
      return i;
    })

    this.abrirProduccion = true;
  }

  onCloseProduccion(produjo: boolean) {
    this.abrirProduccion = false;
    if (produjo) {
      this.obtenerOrdenes();
    }
  }

  verHistoricoProducciones(id: number) {
    this.idOrden = id;
    this.abrirHistoricoProduccion = true;
  }
  onCloseHistoricoProduccion() {
    this.idOrden = 0;
    this.abrirHistoricoProduccion = false;
  }

  ngOnInit(): void {
    this.obtenerOrdenes();
  }

  onCloseHistorico() {
    this.historicos = [];
    this.abrirHistorico = false;
  }

  getDate(date: any): string {
    return moment(date).format("DD/MM/YYYY")
  }

  verHistorico(hist: any) {
    this.historicos = hist;
    this.abrirHistorico = true;
  }



  obtenerOrdenes() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.getTableroOrdenes().subscribe((list) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.lista = list;
    });
  }

  imprimirOrdenManufactura(id: number) {
    this.fs.imprimirOrdenManufactura(id);
  }


}
