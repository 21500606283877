
import { ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { AppSettings } from '../services/app-settings.service';
import { AppVariablesService } from '../services/app-variables.service';
import { AppMenuService } from '../services/app-menus.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { AuthService } from 'src/app/auth/services/auth.service';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import { SignalrService } from 'src/app/service/signalr.service';
import { Favoritos } from 'src/app/components/interfaces/favoritos.interface';
import { Menu } from 'src/app/components/interfaces/menu.interface';
import { MenuFavoritosUsuario } from 'src/app/components/interfaces/menu-favoritos-usuario.interface';
import { PermisosTableros } from 'src/app/configuracion/interfaces/rol.interface';
import { BaseService } from 'src/app/service/base.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ModalService } from 'src/app/service/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ListadoChequesPostfechadosPageComponent } from '../../cuentas-por-cobrar/pages/reports/listado-cheques-postfechados-page/listado-cheques-postfechados-page.component';
import { AlertService } from 'src/app/component-ui/services/alert-base.service';
import { Alert } from 'src/app/component-ui/interfaces/alert.interface';
import { EmailInboxPage } from '../pages/email/inbox/email-inbox';
import { SolicitudDescargaCfdi } from '../../efisco/interfaces/solicitud.interface';
import { VoiceRecognitionService } from '../services/app-voice-recognition.service';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent {

  @ViewChild('alertObj')
  public alertObj!: ElementRef<HTMLElement>;

  @ViewChild('cambioPass')
  public cambioPass!: ElementRef<HTMLElement>;

  @ViewChild('txtCaptchaSAT')
  public txtCaptchaSAT!: ElementRef<HTMLElement>;

  @ViewChild('ctrlbusquedamenu')
  public ctrlbusquedamenu!: ElementRef<HTMLElement>;

  //#region Variables
  isLoading: boolean = false;
  appHasScroll: any;
  appVariables = this.appVariablesService.getAppVariables();
  recargarTipoCambio: boolean = false;
  recargarBuzon: boolean = false;
  recargarNotificaciones: boolean = false;
  appContentClass: string = '';
  appEmpty: boolean = false;
  canceloDescarga: boolean = false;
  paginas: any[] = [];
  minimized: any[] = [];
  favoritos: Favoritos[] = [];
  menu: Menu[] = []
  @ViewChild('ctrlCaptcha')
  public ctrlCaptcha!: ElementRef<HTMLElement>;
  permisosTableros!: PermisosTableros;
  errorMessagge: string = "";
  alertConfig: Alert | null = null;
  //#endregion

  //#region Injections
  private authService = inject(AuthService);
  private eventsService = inject(EventsService);
  private utilsService = inject(UtilsService);
  private signalrService = inject(SignalrService);
  private appMenuService = inject(AppMenuService);
  private modalService = inject(NgbModal);
  private tabsNavService = inject(TabsNavService);
  private ms = inject(ModalService);
  private formBuilder = inject(FormBuilder);
  private configuracionService = inject(ConfiguracionService);
  private pantallas: Menu[] = [];
  public sanitizer = inject(DomSanitizer);
  private baseService = inject(BaseService);
  altKeyPressed: boolean = false;
  b64Image: string = "";
  hub: signalR.HubConnection | null = null;
  descargandoXmls: boolean = false;
  busquedaAbierta: boolean = false;
  mensajeSAT: string = "Espere un momento...";
  encontrados: number = 0;
  totalEncontrados: number = 0;
  porcentajeGuardado: number = 0;
  porcentajeDescargado: number = 0;
  private idle = inject(Idle);
  private keepalive = inject(Keepalive);
  private cd = inject(ChangeDetectorRef);
  //#endregion

  //#region Some fileds to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown: number | null = null;
  lastPing: Date | null = null;
  timeIdle = 1200; //Tiempo de espera para cerrar sesión (20 min pero en segundos)
  //#endregion

  public myForm: FormGroup = this.fb.group({
    Captcha: ['', [Validators.required]]
  });

  constructor(private titleService: Title,
    //private vr: VoiceRecognitionService,
    private fb: FormBuilder,
    private router: Router, private alertS: AlertService, private renderer: Renderer2, public appSettings: AppSettings, private appVariablesService: AppVariablesService) {
    this.signalrService.startConnection();
    this.signalrService.addSessionUserListener();
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (window.innerWidth < 768) {
          this.appSettings.appSidebarMobileToggled = false;
          this.appSettings.appSidebarEndMobileToggled = false;
        }
      }
    });
    this.initIdleParams();
  }

  expandAll() {
    //this.paginas = [...this.minimized];
    this.minimized = [];
  }

  isNullOrUndefinded(prop: any) {
    return prop == undefined || prop == null;
  }

  ngAfterViewInit() {
    this.eventsService.subscribe(("signalr:close"), () => {
      this.errorConexionApi();
    });

    this.eventsService.subscribe(('home:isLoading'), (data) => {
      this.isLoading = data.isLoading
    });


    this.eventsService.subscribe(('home:openPage'), (numPantalla: number) => {
      this.utilsService.tieneAccesoPantalla(numPantalla).subscribe((result) => {
        debugger
        if (result.success) {
          const result = this.pantallas.filter(P => P.id == numPantalla);
          if (result.length > 0) {
            this.onOpenPageMenu(result[0]);
          }
        } else {
          this.eventsService.publish('home:showAlert', { message: 'No tiene permiso a esta parte del menú, verifique con el administrador del sistema.', cancelButton: false });
        }
      })
    });

    this.eventsService.subscribe(('home:showAlert'), (m: Alert) => {
      if (this.isNullOrUndefinded(m.acceptButton)) { m.acceptButton = true; }
      if (this.isNullOrUndefinded(m.cancelButton)) { m.cancelButton = true; }
      if (this.isNullOrUndefinded(m.noButton)) { m.noButton = false; }
      if (this.isNullOrUndefinded(m.textAccept)) { m.textAccept = 'Aceptar'; }
      if (this.isNullOrUndefinded(m.textCancel)) { m.textCancel = 'Cancelar'; }
      if (this.isNullOrUndefinded(m.textNo)) { m.textNo = 'No'; }
      if (this.isNullOrUndefinded(m.icon)) { m.icon = 'fa-circle-info text-blue'; }
      this.alertConfig = m;

      this.alertS.Show(this.alertObj, m);
    });



    this.eventsService.subscribe(('home:changeEmpSuc'), (data: any) => {
      this.paginas = [];
      this.minimized = [];
    });
    this.configuracionService.solicitaCambiarPassword(this.userLogged.numero).subscribe({
      next: (response: boolean) => {
        if (response) {
          this.modalService.open(this.cambioPass, { centered: true, backdropClass: 'light-blue-backdrop' })
        }
      },
      error: (err: Error) => {
        console.error(err);
      }
    });
  }

  pagesChange(e: any) {
    // this.paginas = [];
    // if (e.min?.length > 0) {
    // }
  }

  openBusquedaMenu() {
    if (this.busquedaAbierta) return;
    this.busquedaAbierta = true;
    const b: any = this.ctrlbusquedamenu;
    this.ms.openModal(b, (e: any) => {
      this.busquedaAbierta = false;
      if (e) {
        this.onOpenPageMenu(e);
      }
    }, 'md');
  }

  get getIsAltKey(): boolean {
    return this.altKeyPressed;
  }

  ngOnInit() {

    //this.vr.start()
    window.onkeydown = (event) => {
      if (event.altKey && !this.busquedaAbierta && event.key == "Alt") {
        this.altKeyPressed = true;
        event.preventDefault();
      }
    }
    window.onkeyup = (event) => {
      this.altKeyPressed = false;
      if (event.altKey && event.key.toLocaleLowerCase() == "t") {
        event.preventDefault();
        if (this.menu.length > 0) {
          this.openBusquedaMenu();
        }
      }
      if (event.altKey && event.key.toLocaleLowerCase() == "u") {
        event.preventDefault();

      }
    };
    this.checkTimeOutExpired();
    this.getMenuAndFavoritos();
    this.signalrService.startConnectionMenuHub((type: string, args: any) => {
      switch (type) {
        case "notificacion":
          if (args.idUsuario == this.userLogged.numero) {
            setTimeout(() => {
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                  toast.addEventListener('click', () => Swal.close());
                }
              });
              Toast.fire({
                icon: "info",
                title: args.mensaje
              });
            }, 2000);
            this.recargarNotificaciones = true;
            setTimeout(() => {
              this.recargarNotificaciones = false;
            }, 250);
          }
          break;
        case "downloadXmlSat":
          if (args.idUsuario == this.userLogged.numero) {
            if (args.esImagen) {
              this.mensajeSAT = "Espere un momento...";
              this.descargandoXmls = false;
              this.b64Image = args.mensaje;
              this.hub = args.hub;
              const b: any = this.ctrlCaptcha;
              this.ms.openModal(b, (e: any) => {
                if (!e && this.hub) {
                  this.descargandoXmls = false;
                  this.encontrados = this.totalEncontrados = 0;
                  this.canceloDescarga = true;
                  this.hub!.invoke("SendCaptcha", this.userLogged.empresa!.rfc, this.userLogged.numero, "CANCELADO");
                  this.hub = null;
                  this.b64Image = "";
                }
              }, 'xl');
              setTimeout(() => {
                this.txtCaptchaSAT.nativeElement.focus();
              }, 500);
            } else {
              this.mensajeSAT = args.mensaje;
              this.encontrados = args.encontrados;
              this.totalEncontrados += this.encontrados;
              this.porcentajeDescargado = args.porcentajeDescargado;
              this.porcentajeGuardado = args.porcentajeGuardado;
              if (this.porcentajeGuardado > 0) {
                this.porcentajeDescargado = 0;
              }
              if (!args.esError) {
                if (this.mensajeSAT == "FIN DESCARGA") {
                  this.ms.closeModal(null);
                }
              } else {
                this.ms.closeModal(null);
                this.descargandoXmls = false;
                this.encontrados = this.totalEncontrados = 0;
                this.hub = null;
                this.b64Image = "";
                this.eventsService.publish('home:showAlert', { message: args.mensaje, cancelButton: false });
              }
            }
          }
          break;
        case "usuariocambio":
          if (args.idUsuario == this.userLogged.numero) {
            setTimeout(() => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                  toast.addEventListener('click', () => Swal.close());
                }
              });
              Toast.fire({
                icon: "info",
                title: "Se hicieron cambios a tu Usuario."
              });
            }, 2000);
            this.getMenuAndFavoritos();
            this.obtenerPermisosTableros();
          }
          break;
        case "menu":
          //if (args.idRol == this.userLogged.numeroRol) {
          setTimeout(() => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
                toast.addEventListener('click', () => Swal.close());
              }
            });
            Toast.fire({
              icon: "info",
              title: "Tus permisos han sido actualizados."
            });
          }, 2000);
          this.getMenuAndFavoritos();
          this.obtenerPermisosTableros();
          //}
          break;
        case "tipoCambio":
          this.recargarTipoCambio = true;
          setTimeout(() => {
            this.recargarTipoCambio = false;
          }, 250);
          break;
        case "buzon":
          this.recargarBuzon = true;
          setTimeout(() => {
            this.recargarBuzon = false;
          }, 250);
          break;
        case "mensaje":
          if (args.idUsuario == 0 || args.idUsuario == this.userLogged.numero) {
            Swal.fire({
              title: args.mensaje,
              showClass: {
                popup: `
                  animate__animated
                  animate__bounceInLeft
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__bounceOutLeft
                  animate__faster
                `
              }
            });
          }
          break;
      }
    });



    let isToggleTopMenu = localStorage["isTop"]?.toLocaleLowerCase() === "true";
    this.toggleTopMenu(isToggleTopMenu);

    this.userLogged = this.utilsService.getUserLogged();
    this.signalrService.subscribeToSession(this.userLogged.numero);
    this.authService.actualizarSesionesActivas(this.userLogged).subscribe();
    this.obtenerPermisosTableros();

    this.appContentClass = this.appSettings.appContentClass;
    this.appEmpty = this.appSettings.appEmpty;
    this.titleService.setTitle("Complit | Inicio");
    // page settings
    if (this.appSettings.appDarkMode) {
      this.onAppDarkModeChanged();
    }

    if (localStorage) {
      if (localStorage['appDarkMode']) {
        this.appSettings.appDarkMode = (localStorage['appDarkMode'] === 'true') ? true : false;
        if (this.appSettings.appDarkMode) {
          this.onAppDarkModeChanged();
        }
      }
      if (localStorage['appHeaderFixed']) {
        this.appSettings.appHeaderFixed = (localStorage['appHeaderFixed'] === 'true') ? true : false;
      }
      if (localStorage['appHeaderInverse']) {
        this.appSettings.appHeaderInverse = (localStorage['appHeaderInverse'] === 'true') ? true : false;
      }
      if (localStorage['appSidebarFixed']) {
        this.appSettings.appSidebarFixed = (localStorage['appSidebarFixed'] === 'true') ? true : false;
      }
      if (localStorage['appSidebarMinified']) {
        this.appSettings.appSidebarMinified = (localStorage['appSidebarMinified'] === 'true') ? true : false;
      }
      if (localStorage['appSidebarGrid']) {
        this.appSettings.appSidebarGrid = (localStorage['appSidebarGrid'] === 'true') ? true : false;
      }
      if (localStorage['appGradientEnabled']) {
        this.appSettings.appGradientEnabled = (localStorage['appGradientEnabled'] === 'true') ? true : false;
      }
    }

    this.resetIdleParams();
  }

  acceptCaptcha() {
    const ent = this.myForm.value;
    if (!ent.Captcha) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar el Captcha de la imagen.', cancelButton: false });
    } else {
      this.canceloDescarga = false;
      this.b64Image = "";
      this.descargandoXmls = true;
      this.hub!.invoke("SendCaptcha", this.userLogged.empresa!.rfc, this.userLogged.numero, ent.Captcha);
      this.myForm.reset({ Captcha: '' });
      //this.ms.closeModal(txt.value);
    }
  }

  closeModalCaptcha() {
    setTimeout(() => {
      this.myForm.reset({ Captcha: '' });
      this.ms.closeModal(null);
    }, 150);
  }


  initIdleParams() {
    this.idle.setIdle(1);
    this.idle.setTimeout(this.timeIdle);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      this.cd.detectChanges();
    });

    this.idle.onTimeout.subscribe((data: any) => {
      this.idleState = "TIME_OUT";
      this.timerLogout();
    });
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  resetIdleParams() {
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  userLogged!: UserLogged;

  get getRecargarTipoCambio(): boolean {
    return this.recargarTipoCambio;
  }

  get getRecargarNotificaciones(): boolean {
    return this.recargarNotificaciones;
  }

  get getRecargarBuzon(): boolean {
    return this.recargarBuzon;
  }

  getMenuAndFavoritos() {
    this.appMenuService.getAppMenusPermisosUsuarios().subscribe({
      next: (accesos: MenuFavoritosUsuario) => {
        if (accesos.success) {
          this.menu = accesos.menu;
          this.loadFavoritos(accesos.favoritos);
        } else {
          this.errorConexionApi(accesos.message);
        }
      },
      error: (error) => {
        console.log("No fue posible cargar el menu: ", error);
        this.errorConexionApi();
      }
    })
  }

  errorConexionApi(message: string = "Se ha producido un error en la conexión.") {
    // this.utilsService.isLoad(false);
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
        toast.addEventListener('click', () => Swal.close());
      }
    }).fire({
      icon: "warning",
      title: message
    });

    // Swal.fire({
    //   title: 'Error.',
    //   text: "Se ha producido un error en la conexión.",
    //   icon: 'warning',
    //   confirmButtonColor: '#3085d6',
    //   confirmButtonText: 'Aceptar',
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.logout();
    //   }
    // });
  }

  loadFavoritos(favoritos: Favoritos[]) {
    this.favoritos = favoritos.map(p => {
      let itemMenu = this.appMenuService.getComponentPages().find(x => x.url === p.menu);
      p = { ...p, title: itemMenu!.title }
      return p;
    });
  }

  timerLogoutIsOpen: boolean = false;
  timerLogout() {
    let timerInterval: any = null;
    Swal.fire({
      title: 'Sesión Expirada',
      html: "Su sesión expirará en <b></b> segundos.",
      icon: 'warning',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Cerrar Sesión',
      cancelButtonText: 'Mantener Sesión',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        let b: any = Swal.getHtmlContainer()?.querySelector('b');
        timerInterval = setInterval(() => {
          b.textContent = (Number(Swal.getTimerLeft()) / 1000).toFixed(0)
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed || (String(result.dismiss) === "timer")) {
        this.logout();
      } else {
        this.resetIdleParams();
      }
      this.timerLogoutIsOpen = false;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 0 && this.appSettings.appHeaderFixed) {
      this.appHasScroll = true;
    } else {
      this.appHasScroll = false;
    }
  }

  // hide mobile sidebar
  onAppSidebarMobileToggled(): void {
    this.appSettings.appSidebarMobileToggled = !this.appSettings.appSidebarMobileToggled;
  }

  // set app sidebar end toggled
  onAppSidebarEndToggled(): void {
    this.appSettings.appSidebarEndToggled = !this.appSettings.appSidebarEndToggled;
  }

  // toggle right mobile sidebar
  onAppSidebarEndMobileToggled(): void {
    this.appSettings.appSidebarEndMobileToggled = !this.appSettings.appSidebarEndMobileToggled;
  }

  // set page minified
  onAppSidebarMinifiedToggled(): void {
    this.appSettings.appSidebarMinified = !this.appSettings.appSidebarMinified;
    if (localStorage) {
      localStorage['appSidebarMinified'] = this.appSettings.appSidebarMinified;
    }
  }

  onAppDarkModeChanged(): void {
    if (this.appSettings.appDarkMode) {
      document.documentElement.setAttribute('data-bs-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-bs-theme');
    }
    this.appVariables = this.appVariablesService.getAppVariables();
    this.appVariablesService.variablesReload.emit();
    document.dispatchEvent(new CustomEvent('theme-change'));
  }

  onAppThemeChanged(): void {
    const newTheme = 'theme-' + this.appSettings.appTheme;
    for (let x = 0; x < document.body.classList.length; x++) {
      if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
        document.body.classList.remove(document.body.classList[x]);
      }
    }
    document.body.classList.add(newTheme);
    this.appVariables = this.appVariablesService.getAppVariables();
    this.appVariablesService.variablesReload.emit();
  }

  onMenuLoad(menu: Menu[]) {
    this.pantallas = menu;
  }

  onOpenPageMenu(item: any) {
    let itemMenu = this.appMenuService.getItemOpenPages(item);
    itemMenu = {
      ...itemMenu,
      idTab: `${item.url}-${(this.paginas.length - 1)}`,
      active: true,
      isFav: this.favoritos.filter((f: any) => f.menu === itemMenu.url).length > 0,
      inputs: item?.inputs ? item?.inputs : { ...itemMenu?.inputs }
    }

    if (this.paginas.find(p => p.url === "cortecaja") && item.url === "cortecaja") {
      this.tabsNavService.selectTab(itemMenu);
    } else {
      this.paginas.forEach((t) => (t.active = false));
      this.paginas = [...this.paginas, itemMenu];
    }
    this.titleService.setTitle(`Complit | ${item.title}`);

    this.minimized = [];
  }

  toggleTopMenu(isTop: boolean) {
    this.appSettings.appTopMenu = isTop;
    this.appSettings.appSidebarNone = isTop;
  }

  obtenerPermisosTableros() {
    this.baseService.getPermisosTableros(this.userLogged.numero).subscribe({
      next: (permisos: PermisosTableros) => {
        this.permisosTableros = {
          verTableroVentas: permisos.verTableroVentas,
          verTableroPowerBI: permisos.verTableroPowerBI,
          verTableroGastos: permisos.verTableroGastos,
          verTableroMisVentas: permisos.verTableroMisVentas,
          urlPowerBI: permisos.urlPowerBI,
          verChat: permisos.verChat,
          enviarMensajesChat: permisos.enviarMensajesChat,
          urlPowerBISanitizer: this.sanitizer.bypassSecurityTrustResourceUrl(permisos.urlPowerBI),
          noVerCostos: permisos.noVerCostos
        }

        localStorage.setItem("noVerCostos", permisos.noVerCostos.toString());

        if (!permisos.verChat) {
          this.appSettings.appSidebarEndToggled = false
        }
      },
      error: (error: Error) => {
        console.error(error.message);
      }
    });
  }

  logout(event: any = true) {
    this.utilsService.isLoad(true);
    this.authService.logout().subscribe(() => {
      //this.vr.stop()
      this.modalService.dismissAll();
      this.idle.stop();
      this.utilsService.isLoad(false);
    });
  }

  public formCambioPass: FormGroup = this.formBuilder.group({
    CambioPass: ['', Validators.required],
  });

  onClickCambioPassword() {
    this.errorMessagge = "";
    if (this.formCambioPass.valid) {
      this.configuracionService.cambiarPassword(this.formCambioPass.controls["CambioPass"].value, this.userLogged.numero).subscribe({
        next: (response: Result) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.errorMessagge = "";
          } else {
            this.errorMessagge = response.message;
          }
        },
        error: (err: Error) => {
          console.error(err);
        }
      });
    } else {
      this.errorMessagge = "Formato de contraseña inválido. Longitud mínima debe ser 6 caracteres.";
    }
  }

  checkTimeOutExpired() {
    let lastPing = localStorage["ng2Idle.main.expiry"];
    let today = new Date();
    let lastPingTodate = new Date(Number(lastPing));
    if ((today > lastPingTodate) && this.authService.getToken()) {
      Swal.fire({
        title: 'La sesión ha expirado.',
        text: "Por su seguridad y la de su información Complit ha cerrado la sesión.",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.logout();
        }
      });
    }
  }
}
