<div class="form-group row">
  <label *ngIf="withLabel" style="text-align: right;" class="col-sm-{{widthLabelColumns}} col-form-label ">{{label}}{{
    label.length>0?separator:''}}
    {{label.length==0?"&nbsp;":""}}
  </label>
  <div class="d-flex col-sm-{{widthTextColumns}}">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <components-ui-text-box (keydown)="find(1)" (keydown.escape)="escape()" [helpLine]="helpLine" [isLevel]="true"
          [enabled]="enabled" [widthTextColumns]="12" (blur)="blur($event,1)" (keydown.f2)="openSearch()"
          [tabIndex]="tabIndex" [label]="''" formControlName="Level1" [widthLabelColumns]="0" [separator]="''"
          [textFilter]="this.textFilter" #txt1 />
        <components-ui-text-box (keydown)="find(2)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,2)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+1"
          [label]="''" formControlName="Level2" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt2 />
        <components-ui-text-box (keydown)="find(3)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,3)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+2"
          [label]="''" formControlName="Level3" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt3 />
        <components-ui-text-box (keydown)="find(4)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,4)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+3"
          [label]="''" formControlName="Level4" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt4 />
        <components-ui-text-box (keydown)="find(5)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,5)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+4"
          [label]="''" formControlName="Level5" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt5 />
      </div>
    </form>
  </div>


  <ng-scrollbar style="max-height:200px;background-color: #ffffff;" [style]="getListStyle"
    *ngIf="listaAbierta && source.length>0">
    <div class="todolist" style="max-height:200px;">
      <div (mousedown)="mousedown($event, item)" *ngFor="let item of source; let i = index;" class="todolist-item">
        <div class="todolist-input">
          <span>{{item.claveGeneral}}</span>
        </div>
        <label style="cursor:pointer;" class="todolist-label" [for]="'todolist'+ i">{{item.nombre}}</label>
      </div>
    </div>
  </ng-scrollbar>




  <ng-template let-modal let-c="close" #seleccionar>
    <app-seleccionar-tree [type]="type" #seleccionar />
  </ng-template>
</div>
