import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CajaChica } from '../interfaces/tipotransferencia.interface';
import { Observable } from 'rxjs';
import { Proveedor } from 'src/app/compras/interfaces/proveedores.interface';
import { Result } from 'src/app/auth/interfaces';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { Diot } from '../interfaces/diot.interface';
import { TerminalBancaria } from '../interfaces/terminalbancaria.interface';

@Injectable({ providedIn: 'root' })



export class FinanzasService {

  constructor(private http: HttpClient) { }
  private readonly baseUrl: string = environment.baseUrlApi;

  buscarTerminalesPorSucursal(idSucursal: number): Observable<Result> {
    const params = new HttpParams().set("idSucursal", idSucursal);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObteneTerminalesPorSucursal`, { params })
  }

  buscarCfdi(seriefolio: string, rfcProveedor: string): Observable<DescargaCfdi> {
    const params = new HttpParams().set("serieFolio", seriefolio).set("rfcProveedor", rfcProveedor);
    return this.http.get<DescargaCfdi>(`${this.baseUrl}/Fiscal/ObtenerCfdiPorSerieFolio`, { params })
  }

  obtenerDiotPorId(id: number): Observable<Diot> {
    const params = new HttpParams()
      .set("id", id)
    return this.http.get<Diot>(`${this.baseUrl}/Tesoreria/ObtenerDiotPorId`, { params })
  }

  guardarDiot(jsonDiot: string, idPoliza: number, numeroPoliza: number, idTipoPoliza: number): Observable<Result> {
    const params = new HttpParams()
      .set("jsonDiot", jsonDiot)
      .set("idPoliza", idPoliza)
      .set("numeroPoliza", numeroPoliza)
      .set("idTipoPoliza", idTipoPoliza);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/GuardarDiot`, { params })
  }

  buscarProveedor(idEmpresa: number, clave: number): Observable<Proveedor> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("clave", clave);
    return this.http.get<Proveedor>(`${this.baseUrl}/Compras/BuscarProveedorPorClave`, { params })
  }

  eliminarDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/EliminarDetalle`, { params });
  }

  buscarCajaChica(idTipo: number, fecha: string): Observable<CajaChica> {
    const params = new HttpParams().set("idTipo", idTipo).set("strFecha", fecha);
    return this.http.get<CajaChica>(`${this.baseUrl}/Tesoreria/ObtenerCajaChica`, { params });
  }

  obtenerConfiguracionConciliacion(idEmpresa: number, idUsuario: number): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idUsuario", idUsuario);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerUltimaConfiguracionPorEmpresaUsuario`, { params });
  }

  generarDiot(mesini: number, mesfin: number, anio: number): Observable<any> {
    const params = new HttpParams().set("mesini", mesini).set("mesfin", mesfin).set("anio", anio);
    return this.http.get(`${this.baseUrl}/Fiscal/GenerarDiot`, { params });
  }

}
