import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from '../../services/facturacion.service';
import { EventsService } from 'src/app/service/events.service';
import { Consignado } from '../../interfaces/venta.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-consignado-page',
  templateUrl: './consignado-page.component.html',
  styles: [
  ]
})

export class ConsignadoPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  public GuiColumnAlign = GuiColumnAlign;
  @Input()
  public idCliente: number = 0;
  @Input()
  public nombreCliente: string = "";
  idConsignadoEdicion: number = 0;
  indexEditing: number =0;
  @ViewChild('modalConisgnado')
  public modalConisgnado!: ElementRef<HTMLElement>;
  @ViewChild('txtBuscar')
  public txtBuscar!: ElementRef<HTMLInputElement>;
  buscando: boolean = false;
  lista: Consignado[] = [];
  constructor(private ms: ModalService, private fs: FacturacionService) {

  }

  ngOnInit(): void {
    if (this.idCliente > 0) {
      setTimeout(() => {
        this.txtBuscar.nativeElement.focus();
      }, 250);
      this.cargar("");
    }
  }

  cargar(text: string) {
    this.buscando = true;
    this.fs.getConsignados(this.idCliente, text).subscribe((result) => {
      this.buscando = false;
      if (result.success) {
        this.lista = JSON.parse(result.message);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  keydown(key: any) {
    if (key.keyCode == 13) {
      const text = this.txtBuscar.nativeElement.value;
      this.cargar(text);
    }
  }

  edit(index: number) {
    this.idConsignadoEdicion = this.lista[index].Id;
    setTimeout(() => {
      this.addEditConsignado();
    }, 150);
  }

  delete(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: "¿Desea eliminar la Dirección de Entrega?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.fs.deleteConsignados(this.lista[index].Id).subscribe((result) => {
            if (result.success) {
              this.lista.splice(index, 1);
              this.lista = [...this.lista];
            } else {
              this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
            }
          })
        }
      }
    });
  }

  clicBuscar() {
    const text = this.txtBuscar.nativeElement.value;
    this.cargar(text);
  }

  addEditConsignado() {
    const b: any = this.modalConisgnado;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.cargar("");
      }
      this.idConsignadoEdicion = 0;
    }, 'xl')

  }

  accept() {
    this.ms.closeModal(null);
  }

}
