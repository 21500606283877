import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { TipoServicioUnidad } from '../../interfaces/unidad.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';

@Component({
  selector: 'app-tipo-servicio-unidad-page',
  templateUrl: './tipo-servicio-unidad-page.component.html',
  styles: [
  ]
})
export class TipoServicioUnidadPageComponent {
  public itemsCombo: ComboBoxEntity[] = [];

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Baja: [false],
  })


  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService,) {
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    console.log(items)
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {


    param.callback(true);
  }

  onItemSearched(entity: TipoServicioUnidad): void {
    if (entity) {
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: TipoServicioUnidad) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("TipoServicioUnidad").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
}
