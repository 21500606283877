import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ChangeDetectorRef, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType, GuiRowDetail } from '@generic-ui/ngx-grid';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { EmpresaSucursalDto, Result, UserLogged } from 'src/app/auth/interfaces';
import { environment } from 'src/environments/environment';
import { EventsService } from 'src/app/service/events.service';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';

import { PresupuestoService } from '../../services/presupuesto.service';
import { PresupuestoVentaVendedor, PresupuestoVentaVendedorDetalle, TipoPresupuesto } from '../../interfaces/presupuestos.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { PanelBaseDropDownMenu } from '../../../component-ui/interfaces/panelbase.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';


@Component({
  selector: 'app-presupuestos-vendedores-page',
  templateUrl: './presupuestos-vendedores-page.component.html',
  styles: [
  ]
})
export class PresupuestosVendedoresPageComponent implements OnInit {
  // variables de referencia e elementos HTML
  @ViewChild('busquedaUsuariosPresupuesto')
  public ctrlBusquedaUsuarios!: ElementRef<HTMLElement>;

  @ViewChild('copiarPresupuestoVendedor')
  public copiarPresupuestoVendedor!: ElementRef<HTMLElement>;

  @ViewChild('periodoPptoVendedor')
  public ctrlperiodoPptoVen!: ElementRef<HTMLElement>;

  // injects
  private fb = inject(FormBuilder)
  private utilsService = inject(UtilsService)
  private ms = inject(ModalService);
  private http = inject(HttpClient);
  private eventsService = inject(EventsService);
  private cService = inject(ContainerBaseService);
  private reportsService = inject(ReportsService)
  private presupuestoService = inject(PresupuestoService)
  private cd = inject(ChangeDetectorRef)
  public appSettings = inject(AppSettings);
  private configService = inject(ConfiguracionService)

  //variables para el encabezdo, listas que se llenan manualmene
  listaMeses: any[] = [];
  listaAnios: any[] = [];
  PeriodoSeleccionado?: ComboBoxEntity;
  anioSeleccionado?: ComboBoxEntity;
  oldPeriodo?: ComboBoxEntity;
  oldAnio?: ComboBoxEntity;

  // Ambiente desarrollo o produccion
  private readonly baseUrl: string = environment.baseUrlApi;

  //  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  usuarioActual: UserLogged = {} as UserLogged;

  //*nvariables para el grid
  sourceGrid: Array<PresupuestoVentaVendedorDetalle> = [];
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  loading: boolean = false;

  sourceVendedores: any[] = [];
  cords: any = null;
  selectedIndex: number = -1;
  idUnico: string = '';
  esEscapeVendedor: boolean = false;
  esEntervendedor: boolean = false;
  enviarFocoMeta: boolean = false;
  enviarFocoVendedor: boolean = false;
  busquedaAbierta: boolean = false;
  enviarOtroRenglon: boolean = false;
  busquedaVendedorAbierta: boolean = false;
  esEnterVendedor: boolean = false;

  // public GuiColumnAlign = GuiColumnAlign;
  // public GuiCellView = GuiCellView;
  // public GuiDataType = GuiDataType;


  // variables de busqueda del usuario(vendedor)
  searchConfigurationVendedor: SearchConfiguration | null = null;


  //variables de control de flujo de la pantalla
  saving: boolean = false;

  menuItems: PanelBaseDropDownMenu[] = [{ Id: 'copiar', Nombre: 'Copiar Presupuesto' },
  { Id: 'cargar', Nombre: 'Cargar todos los Vendedores' }]

  constructor() {

  }

  clicMenu(value: string) {
    if (value == 'copiar') {
      this.onOpencopiar();
    } else {
      this.cargarTodosVendedores();
    }
  }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  public filtroPptos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Verificador de Ppto. por vendedor',
      NombreExcel: 'Verificador de Ppto. por vendedor.xlsx',
      FilterOptions: [
        { Campo: 'ppto.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };


  public myFormTotalesVen: FormGroup = this.fb.group({
    TotalPpto: [''],
  });



  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Anio: [{} as ComboBoxEntity],
    Periodo: [{} as ComboBoxEntity],
    Nombre: [''],
    MetaDelMes: [0],
    UsuarioElabora: [null],
    Detalle: [[] as PresupuestoVentaVendedorDetalle[]],
    Eliminado: [false],
    Baja: [false],
  })




  // rowDetail: GuiRowDetail = {
  //   enabled: false,
  //   template: (item) => {
  //     if (!item.Vendedor) return "Sin contenido disponible";
  //     if (item.Vendedor.Id == 0) return "Sin contenido disponible";
  //     return `
  //     <app-panel-base title="Descripción">
  //         <div class="panel-content">
  //             sdkjfsjkdfhskjdfhskjdfhkj
  //         </div>
  //     </app-panel-base>
  //     `;
  //   }
  // };


  ngOnInit(): void {

    this.info = this.utilsService.getUserLogged();
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.usuarioActual = this.utilsService.getUserLogged();

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.PeriodoSeleccionado = mesItem;

    this.searchConfigurationVendedor = this.ms.GetSearchConfiguration("Usuario", `EmpresaPertenece.Id = ${this.empresaActual?.numero}`);
    this.presupuestoService.getListaDeAniosConPresupuestos(1).subscribe(result => {
      if (result) {
        //this.loading = false;
        let proximoAnio: number = new Date().getUTCFullYear();
        proximoAnio++;
        if (result.length > 0) {
          let anioSig: boolean = false;
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
            if (element == proximoAnio) {
              anioSig = true;
            }
          });
          if (!anioSig) {
            this.listaAnios.push({ Id: proximoAnio + 1, Nombre: proximoAnio.toString(), Clave: proximoAnio })
          }
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
          this.listaAnios.push({ Id: proximoAnio, Nombre: proximoAnio.toString(), Clave: proximoAnio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('Empresa')?.setValue(this.utilsService.getUserLoggedEmpresa())
        this.myForm.get('Anio')?.setValue(this.anioSeleccionado!.Id);
        this.myForm.get('Periodo')?.setValue(this.PeriodoSeleccionado!.Id);
        this.findEntityByParams(true);
      }
    })
  }

  //* Inicializcion de variables para la pantalla y el grid

  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  clicTablaVen(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;


    let found = false;
    if (item.Clave > 0) {
      this.sourceGrid.forEach((element, idx) => {
        if (element.Vendedor?.Clave == item.Clave && this.indexEditing != idx) {
          this.eventsService.publish('home:showAlert', {
            textAccept: "Aceptar",
            cancelButton: false,
            message: 'No puede repetir el Vendedor en el presupuesto mensual, por favor verifique.',
            onConfirm: (data: AlertResponse) => {
              let item: PresupuestoVentaVendedorDetalle = this.sourceGrid[this.indexEditing];
              e.target.value = item.Vendedor?.Clave ? item.Vendedor?.Clave : "";
              this.sourceGrid = [...this.sourceGrid];
              this.initEditor(this.indexEditing, 1);
            }
          });
          found = true;
        }
      });
    }

    if (found) {
      return;
    }

    const input: any = document.getElementById(`txt_${this.indexEditing}_0${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoMeta = true;
    }
  }

  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }

  escapeVendedor(e: any) {
    this.esEscapeVendedor = true;
    let item: PresupuestoVentaVendedorDetalle = this.sourceGrid[this.indexEditing];
    e.target.value = item.Vendedor?.Clave ? item.Vendedor?.Clave : "";
    if (this.indexEditing > 0) {
      this.indexEditing = this.indexEditing - 1;
      this.initEditor(this.indexEditing, 1);
    } else {
      const txt: any = this.ctrlperiodoPptoVen;
      txt.tagInput.nativeElement.focus()
    }
  }



  up(e: any, celda: number) {
    if (this.sourceVendedores.length > 0) { return; }
    e.preventDefault();
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeVendedor = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeVendedor = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  getLastItem(): number {
    return this.sourceGrid.filter(P => P.Vendedor && P.Meta > 0).length;
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeVendedor = true;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  enterVendedor(e: any) {
    this.esEnterVendedor = true;
    this.enviarFocoMeta = true;
    let value = '';
    if (this.sourceVendedores.length > 0) {
      value = this.sourceVendedores[this.selectedIndex].Clave;
    } else {
      value = e.target.value.trim();
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  focusVendedor(index: number, e: any, item: PresupuestoVentaVendedorDetalle) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();
    e.preventDefault();
    if (e.target.value == "") {
      this.buscarVendedor(e);
      return;
    }


    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }
  setIndexEdit(index: number, e: any, item: PresupuestoVentaVendedorDetalle) {
    /*Esto es lo nuevo*/
    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  blurVendedor(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    if (!this.esEscapeVendedor) {
      let found = false;
      if (e.target.value.length > 0) {
        this.sourceGrid.forEach((element, idx) => {
          if (element.Vendedor?.Clave == parseInt(e.target.value) && index != idx) {
            this.eventsService.publish('home:showAlert', {
              textAccept: "Aceptar",
              cancelButton: false,
              message: 'No puede repetir el Vendedor en el presupuesto mensual, por favor verifique.',
              onConfirm: (data: AlertResponse) => {
                let item: PresupuestoVentaVendedorDetalle = this.sourceGrid[this.indexEditing];
                e.target.value = item.Vendedor?.Clave ? item.Vendedor?.Clave : "";
                this.sourceGrid = [...this.sourceGrid];
                this.initEditor(this.indexEditing, 1);
              }
            });
            found = true;
          }
        });
      }

      if (found) {
        return;
      }
    }


    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeVendedor) {
        this.esEnterVendedor = false;
        this.esEscapeVendedor = false;
        return;
      }
      let item = { ...this.sourceGrid[index] };
      if (item.Vendedor) {
        if (!e.target.value) {
          e.target.value = item.Vendedor.Clave;
          this.enviarFocoVendedor = true;
          this.sendFocus();
          return;
        }

        if (item.Vendedor.Clave != parseInt(e.target.value)) {
          this.searchVendedor(e.target.value);
        } else {
          if (this.enviarFocoMeta) {
            this.sendFocus();
          }
        }
      } else {
        this.searchVendedor(e.target.value);
      }
      this.esEnterVendedor = false;
      this.esEscapeVendedor = false;
    }, 100);
  }

  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoMeta) {
        this.initEditor(this.indexEditing, 3);
        this.enviarFocoMeta = false;
      }

      if (this.enviarFocoVendedor) {
        this.initEditor(this.indexEditing, 1);
        this.enviarFocoVendedor = false;
      }


      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }


  searchVendedor(value: string) {  //*revisar

    if (!value) {
      if (this.myForm.value.Total > 0 && this.navigateColumns) {
        this.Guardar();
      }
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }

    // const params = new HttpParams().set("entidad", "Sucursal").set("clave", value);
    //  this.http.get<Sucursal>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params });


    let item = { ...this.sourceGrid[this.indexEditing] };
    this.eventsService.publish('home:isLoading', { isLoading: true });

    const params = new HttpParams().set("entidad", "Usuario").set("clave", value).set("filtro","EsVendedor = true");
     this.http.get<Usuario>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe(usuario =>{
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (usuario) {
        let item = this.sourceGrid[this.indexEditing];
        item.Vendedor = usuario;
        item.Meta = 0;
        this.sourceGridReset(this.sourceGrid);
        setTimeout(() => {
          this.initEditor(this.indexEditing, 3);
        }, 50);
        this.navigateColumns = true;
      } else {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: 'No se encontró el Vendedor indicado.',
          onConfirm: (data: AlertResponse) => {
            this.sourceGrid = [...this.sourceGrid];
            this.initEditor(this.indexEditing, 1);
          }
        });
      }
    });
  }


  escapeMeta(e: any) {
    let item: PresupuestoVentaVendedorDetalle = this.sourceGrid[this.indexEditing];
    e.target.value = item.Meta ? item.Meta : 0;
    this.initEditor(this.indexEditing, 1);
  }

  enterMeta(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }


  blurMeta(e: any) {

    e.target.classList.remove("focus-editor-grid");
    const meta = parseFloat(e.target.value);
    let item = { ...this.sourceGrid[this.indexEditing] };
    if (!item.Vendedor) { return; }
    if (meta != item.Meta) {
      this.sourceGrid[this.indexEditing].Meta = meta;
      this.sourceGrid = [...this.sourceGrid];
      this.indexEditing = this.indexEditing + 1;
      this.initEditor(this.indexEditing, 1);
      this.enviarOtroRenglon = false;
    } else {
      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }
    this.calcTotales();
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }


  navegarVendedor(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceVendedores.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }


  buscarVendedor(e: any) {
    // if (e.target.value == "" || !isNaN(e.target.value)) {
    //   this.cords = null;
    //   this.sourceVendedores = [];
    //   return;
    // }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }

    if (e.keyCode == "40") {
      e.preventDefault();
      if (this.selectedIndex < this.sourceVendedores.length - 1) {
        this.selectedIndex = this.selectedIndex + 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }
    if (row) {
      row.scrollIntoView({ block: "center" });
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 147;

    if (this.appSettings.appSidebarMinified) {
      cords.left = cords.left - 70;
    } else {
      cords.left = cords.left - 290;
    }
    this.cords = cords;


    this.configService.busquedaVendedores(e.target.value).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceVendedores = lista;
      if (this.sourceVendedores.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });


    // this.configService.busquedaSucursales(e.target.value).subscribe((result) => {
    //   const lista = JSON.parse(result.message);
    //   this.sourceVendedores = lista;
    //   if (this.sourceVendedores.length > 0) {
    //     this.selectedIndex = 0;
    //     row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
    //     if (row) {
    //       row.scrollIntoView({ block: "center" });
    //     }
    //   } else {
    //     this.selectedIndex = -1;
    //   }
    // });
  }




  onKeyPressMes(e: any) {
    if (e.keyCode == 13) {
      this.indexEditing = 0
      e.preventDefault();
      this.initEditor(this.indexEditing, 1);
    }
  }

  onKeyDownMes(e: any) {
    if (e.keyCode == 9) {
      this.indexEditing = 0
      e.preventDefault();
      this.initEditor(this.indexEditing, 1);
    }
  }

  get getMes(): ComboBoxEntity | null {
    return this.PeriodoSeleccionado!;
  }

  get getAnio(): ComboBoxEntity | null {
    return this.anioSeleccionado!;
  }

  selectAnio(entity: ComboBoxEntity) {
    this.myForm.controls["Anio"].setValue(entity.Id);
    this.anioSeleccionado = entity;
    if (this.oldAnio?.Id! != this.anioSeleccionado.Id) {
      this.oldAnio = this.anioSeleccionado;
      this.findEntityByParams();
    }

  }

  selectMes(entity: ComboBoxEntity) {
    this.myForm.controls["Periodo"].setValue(entity.Id);
    this.PeriodoSeleccionado = entity;
    if (this.oldPeriodo?.Id! != this.PeriodoSeleccionado.Id) {
      this.oldPeriodo = this.PeriodoSeleccionado;
      this.findEntityByParams();
    }
  }

  sourceGridReset(detalle: any = []) {
    if (!detalle) {
      this.sourceGrid = [];
    } else {
      this.sourceGrid = detalle;
    }

    this.sourceGrid.map(p => {
      if (p.Vendedor) {
        p.Nombre = p.Vendedor?.Nombre!;
        p.Clave = p.Vendedor?.Clave!.toString();
        p.Vendedor = { Id: p.Vendedor!.Id, Clave: p.Vendedor!.Clave, Nombre: p.Vendedor!.Nombre } as Usuario;
        p.Meta = p.Meta * 1;
      }
    });
    let totalRegs = 1;
    if (this.sourceGrid.length < 20) {
      totalRegs = 20;
    }
    for (let index = 0; index < totalRegs; index++) {
      this.sourceGrid = [...this.sourceGrid, this.estructuraVacia()];
    }
  }

  estructuraVacia() {
    return {
      Id: 0,
      Vendedor: null,
      Nombre: '',
      Clave: '',
      Meta: 0
    };
  }

  get getUserColumns(): any {
    if (this.searchConfigurationVendedor) {
      return this.searchConfigurationVendedor.columns;
    }
    return [];
  }

  get getUserFilter(): any {
    if (this.searchConfigurationVendedor) {
      return this.searchConfigurationVendedor.filter;
    }
    return "";
  }

  get getUserPropertys(): string {
    if (this.searchConfigurationVendedor) {
      let props = "";
      this.searchConfigurationVendedor.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  esCapturaValida(throwMessage: boolean = true): boolean {
    let detalles: PresupuestoVentaVendedorDetalle[] = [...this.sourceGrid.filter(P => P.Vendedor?.Id! > 0)];
    let countAnt = detalles.filter(P => P.Meta <= 0).length;

    if (countAnt > 0 && throwMessage) {
      Swal.fire({ text: `Captura inválida, debe de indicar el Vendedor y la Meta de Ventas, por favor verifique.`, icon: 'info', })
      return false;
    }

    let vendedorDuplicado: boolean = false;
    detalles.some((value, indice) => {
      if (detalles.findIndex((item) => item.Vendedor!.Id == value.Vendedor!.Id) != indice) {
        vendedorDuplicado = true;
      }
    })

    if (vendedorDuplicado) {
      Swal.fire({ text: `Captura inválida, debe de indicar el Vendedor solo una vez, por favor verifique.`, icon: 'info', })
      return false;
    }
    return true;
  }

  Guardar() {
    //if (this.saving) return;

    let ent: PresupuestoVentaVendedor = this.myForm.value;
    ent.Nombre = ent.Anio + " " + this.utilsService.getMes(ent.Periodo);

    ent.Detalle = this.sourceGrid.filter(P => P.Vendedor?.Id! > 0);

    if (!this.esCapturaValida()) { return; }

    if (ent.Detalle.length == 0) {
      Swal.fire({ text: `Debe de indicar el listado de Vendedores y sus Metas.`, icon: 'error', })
      return;
    }

    if (this.myForm.value.Id == 0) {
      ent.UsuarioElabora = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario } as Usuario;
    }

    this.saving = true;
    const json = JSON.stringify(ent);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarPresupuestoVendedores`, ent).subscribe((result) => {
      this.saving = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: PresupuestoVentaVendedor = JSON.parse(result.message);
        this.setEntity(t);
        // this.myForm.reset(t);
        // this.sourceGridReset(t.Detalle);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
  }




  // searchUserDetalle(value: number): Observable<any> {
  //   const params = new HttpParams().set("entidad", "Usuario").set("clave", value);
  //   return this.http.get<Usuario>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params });
  // }


  // searchUser(value: number) {
  //   if (!value) {
  //     if (this.myForm.value.Total > 0 && this.navigateColumns) {
  //       this.Guardar();
  //     }
  //     return;
  //   };
  //   this.loading = true;
  //   this.searchUserDetalle(value).subscribe(vendedor => {
  //     this.loading = false;
  //     if (vendedor) {
  //       let item = this.sourceGrid[this.indexEditing];
  //       item.Vendedor = vendedor;
  //       item.Meta = 0;
  //       this.sourceGridReset(this.sourceGrid);
  //       this.initEditor(this.indexEditing, 3);
  //       this.navigateColumns = true;
  //     } else {
  //       Swal.fire({
  //         text: "No se encontró el Vendedor indicado.",
  //         icon: 'error',
  //       }).then(() => {
  //         this.sourceGrid[this.indexEditing] = this.estructuraVacia();
  //         this.sourceGrid = [...this.sourceGrid];
  //         this.initEditor(this.indexEditing, 1);
  //       })
  //     }
  //   })
  // }


  // openUsersSearch() {
  //   const b: any = this.ctrlBusquedaUsuarios;
  //   this.ms.openModal(b, (e: any) => {
  //     if (this.sourceGrid.find(p => p.Vendedor?.Id! == e.Id)) {
  //       Swal.fire({
  //         text: "La Sucursal que está capturando ya se encuentra en la lista.",
  //         icon: 'error',
  //       }).then(() => {
  //         this.sourceGrid[this.indexEditing].Clave = e.before.Clave;
  //         this.sourceGrid = [...this.sourceGrid];
  //         this.initEditor(this.indexEditing, 1);
  //       })
  //     } else {
  //       this.searchUser(e.Clave);
  //     }

  //   }, 'xl')
  // }

  get PptoDetalle(): FormArray {
    return this.myForm.get('Detalle') as FormArray;
  }


  deleteRow(indx: number) {
    const idEliminar = this.sourceGrid[indx].Id;
    Swal.fire({
      title: `¿Desea eliminar el Presupuesto del Vendedor? <br> ${idEliminar > 0 ? "La información no se podrá recuperar." : ""}`,
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (idEliminar > 0) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.presupuestoService.eliminarPresupuestoDetalle(idEliminar, TipoPresupuesto.vendedores).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.sourceGrid.splice(indx, 1);
            this.sourceGrid = [...this.sourceGrid];
            this.calcTotales();
            let ent: PresupuestoVentaVendedor = this.myForm.value;
            ent.Detalle?.splice(indx, 1);
            this.myForm.reset(ent);
            //this.PptoDetalle.removeElement  (indx);
            this.cd.detectChanges();
          })
        } else {
          this.sourceGrid.splice(indx, 1);
          this.sourceGrid = [...this.sourceGrid];
          this.calcTotales();
          let ent: PresupuestoVentaVendedor = this.myForm.value;
          ent.Detalle?.splice(indx, 1);
          this.myForm.reset(ent);
          this.cd.detectChanges();
        }
      }
    });
  }



  /*Grid events */
  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: PresupuestoVentaVendedorDetalle, index: number) => {
      this.indexEditing = -1;


      if (index > 0) {
        if (!this.sourceGrid[index - 1].Vendedor) {
          return false;
        }
        if (this.sourceGrid[index - 1].Vendedor!.Id == 0) {
          return false;
        }
      }

      this.indexEditing = index;

      return true;
    },
    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }


  calcTotales() {
    let ent: PresupuestoVentaVendedor = this.myForm.value;
    ent.MetaDelMes = 0;
    let totalMeta: number = 0, subTotalVenta = 0, totalConceptos = 0;

    this.sourceGrid.map((detalle, index) => {
      if (detalle.Vendedor?.Id! > 0) {
        totalMeta += detalle.Meta * 1;
      }
    });

    this.myFormTotalesVen.get('TotalPpto')!.setValue(totalMeta);
    ent.MetaDelMes = totalMeta;
    this.myForm.reset(ent);
    this.sourceGrid = [...this.sourceGrid];
  }


  navigate(type: string) {
    let mes: number = this.PeriodoSeleccionado?.Id!;

    switch (type) {
      case "first": mes = 1;
        break;
      case "left":
        mes--;
        if (mes < 1) mes = 1;
        break;
      case "right":
        mes++;
        if (mes > 12) mes = 12;
        break;
      case "last":
        mes = 12;
        break;
    }
    this.PeriodoSeleccionado = { Id: mes, Clave: mes, Nombre: this.utilsService.getMes(mes) } as ComboBoxEntity;
    this.findEntityByParams();
  }

  setEntity(presupuesto: PresupuestoVentaVendedor, enviarFoco: boolean = false) {
    if (!presupuesto) {
      this.nuevo();
    } else {
      let ent = this.myForm.value;
      this.PeriodoSeleccionado = { Id: ent.Periodo, Clave: ent.Periodo, Nombre: this.utilsService.getMes(ent.Periodo) } as ComboBoxEntity;
      this.anioSeleccionado = { Id: ent.Anio, Clave: ent.Anio, Nombre: ent.Anio.toString() } as ComboBoxEntity;
      ent = { ...presupuesto };
      this.sourceGridReset(ent.Detalle);
      this.myForm.reset(ent);
      this.calcTotales();
      if (enviarFoco) {
        setTimeout(() => {
          let txt: any = this.ctrlperiodoPptoVen;
          txt.tagInput.nativeElement.focus();
        }, 100);
      }
    }
  }

  nuevo() {
    const ent: PresupuestoVentaVendedor = this.myForm.value;
    this.cService.getEmptyEntity("PresupuestoVentaVendedor").subscribe((ent) => {
      this.sourceGrid = [];
      this.initializeEntity(ent)
    });
  }

  initializeEntity(ent: any, idSerie: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Anio = this.anioSeleccionado!.Id;
    ent.Periodo = this.PeriodoSeleccionado!.Id;
    this.myForm.reset(ent);
    this.sourceGridReset(ent.Detalle);
    this.calcTotales();
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.nuevo(); break;
      case "save": this.Guardar(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
      case "return": this.findEntityByParams(); break;
      case "delete": this.delete(); break;
    }
  }

  delete() {
    const anio: number = this.myForm.value.Anio;
    const periodo: number = this.myForm.value.Periodo;

    Swal.fire({
      title: `¿Desea Eliminar el Presupuesto del Período ${anio} - ${this.utilsService.getMes(periodo)}.?'`,
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.cService.deleteEntity("PresupuestoVentaVendedor", this.myForm.value.Id).subscribe(res => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.nuevo();
          this.menuItems = [
            { Id: 'copiar', Nombre: 'Copiar Presupuesto' },
            { Id: 'cargar', Nombre: 'Cargar todos los Vendedores' }
          ]
        })
      }
    });
  }

  print() {
    let nombre: string = "PRESUPUESTOS POR VENDEDOR " + this.anioSeleccionado?.Id! + " - " + this.utilsService.getMes(this.PeriodoSeleccionado?.Id!).toUpperCase();
    let reportHeader: ReportHeader = {
      NombreReporte: nombre,
      Dato1: '',
      Opc1: true
    }
    let filtro: FilterOptions | undefined = this.filtroPptos.FilterOptions?.find(p => p.Campo == 'ppto.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroPptos.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroPptos, '/Ventas/ReporteVerificadorDePresupuestoVentasVendedor');
  }

  findEntityByParams(enviarFoco: boolean = false) {
    if (this.anioSeleccionado && this.PeriodoSeleccionado) {
      const ent = this.myForm.value;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.presupuestoService.getPresupuestos(ent.Empresa.Id, this.anioSeleccionado!.Id, this.PeriodoSeleccionado!.Id, TipoPresupuesto.vendedores).subscribe((entity) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        let pptoVta: PresupuestoVentaVendedor | null = null;
        if (entity && entity.length > 0) {
          pptoVta = entity[0];
          this.setEntity(pptoVta!, enviarFoco);
        } else {
          this.nuevo();
        }
        if (!pptoVta || pptoVta!.Id === 0) {
          this.menuItems = [
            { Id: 'copiar', Nombre: 'Copiar Presupuesto' },
            { Id: 'cargar', Nombre: 'Cargar todos los Vendedores' }
          ]
        } else {
          this.menuItems = [];
        }
      });
    }
  }

  onOpencopiar() {
    this.ms.openModal(this.copiarPresupuestoVendedor, (item: PresupuestoVentaVendedor) => {
      if (item) {
        Swal.fire({
          title: `¿Desea Copiar el Presupuesto del Período ${item.Nombre} ${item.Anio}?`,
          showDenyButton: true,
          confirmButtonText: 'Si, copiar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            item.Detalle?.map(d => { d.Id = 0 });
            item = {
              ...item,
              Id: 0,
              Detalle: item.Detalle
            }
            this.setEntity(item);
          }
        });
      }
    });
  }

  cargarTodosVendedores() {
    Swal.fire({
      title: "¿Desea cargar la lista completa de Vendedores?.",
      showDenyButton: true,
      confirmButtonText: 'Si, cargar la lista de Vendedores',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        const params = new HttpParams().set("entidad", "Usuario").set("filterQuery", ` EsVendedor=true `);
        this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Base/ObtenerListaCombo`, { params }).subscribe((lista) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          let found: boolean = false;
          let pptoVta: any = this.myForm.value;
          if (!pptoVta.Detalle) {
            pptoVta.Detalle = [];
          }
          if (lista && lista.length > 0) {
            lista.forEach(item => {
              if (pptoVta.Detalle) {
                found = pptoVta.Detalle.find((p: any) => p.Vendedor?.Id == item.Id)?.Vendedor?.Id! > 0;
              }
              if (!found) {
                pptoVta.Detalle!.push({ Id: 0, Meta: 0, Vendedor: { Id: item.Id, Clave: item.Clave, Nombre: item.Nombre } as Usuario })
              }
            })
            this.setEntity(pptoVta)
          }
        });
      }
    })
  }
}
