<app-container-base subTitle="Verificadro de CargosAdicionales" (onClickBarButton)="clickBoton()" [activeButtons]="activeButtons"
  icon="fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmVerificadorAdicionales" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="col-md-7">
            <components-ui-text-box helpLine="Nombre para el encabezado del reporte impreso." label='Nombre del Reporte'
              formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="100" #txtNombreReporte />
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Del' formControlName="Fecha1" [tabIndex]="2" [widthLabelColumns]="7"
                [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Al' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosVerificadorAdicionales" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
