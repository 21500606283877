import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipoPoliza } from '../../interfaces/tipopoliza.interface';
import { ModalService } from 'src/app/service/modal.service';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import { PolizaEdicion } from '../../interfaces/movimientopoliza.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { ContabilidadService } from '../../services/contabilidad.service';
import { ex } from '@fullcalendar/core/internal-common';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-edicion-encabezado-page',
  templateUrl: './edicion-encabezado-page.component.html',
  styles: [
  ]
})
export class EdicionEncabezadoPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  @ViewChild('txtFecha') public txtFecha!: ElementRef<DateBoxComponent>;
  @Input()
  PolizaEdicion: PolizaEdicion | null = null;

  public myFormCancel: FormGroup = this.fb.group({
    Empresa: [{} as Empresa, Validators.required],
    TipoPoliza: [{} as TipoPoliza, Validators.required],
    Fecha: [Date],
    Folio: [0],
  })

  constructor(private mService: ModalService, private fb: FormBuilder, private cs: ContabilidadService) {
  }

  accept() {
    const ent = this.myFormCancel.value;
    if (!ent.Fecha) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar una Fecha.", cancelButton: false });
      return;
    }

    if (!ent.TipoPoliza) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Tipo de Póliza.", cancelButton: false });
      return;
    }

    if (!ent.Folio) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Folio.", cancelButton: false });
      return;
    }

    this.eventsService.publish('home:isLoading', { isLoading: true });
    let fecha = moment(this.myFormCancel.value.Fecha).format("DD/MM/YYYY");
    this.cs.existePoliza(this.myFormCancel.value!.Empresa.Id, this.myFormCancel.value!.TipoPoliza.Id, fecha, this.myFormCancel.value!.Folio).subscribe((existe) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (existe) {
        this.eventsService.publish('home:showAlert', { message: "Ya existe otra Póliza con esos datos de encabezado, verifique.", cancelButton: false });
      } else {
        this.mService.closeModal(this.myFormCancel.value);
      }
    });

  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {

    this.myFormCancel.reset(this.PolizaEdicion);
    setTimeout(() => {
      const t: any = this.txtFecha;
      t.tagInput.nativeElement.focus();
    }, 150);
  }

  onSelectedItem(entity: any) {
    this.myFormCancel.controls["TipoPoliza"].setValue(entity);
  }


}
