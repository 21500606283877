<div class="form-group row">
  <form [formGroup]="this.myForm" autocomplete="off" class="ps-1">
    <div class="col-sm-6">
      <ng-container *ngIf="label.length > 0">
        <label
          class="col-sm-{{widthLabelColumns}} col-form-label tb-right">{{label}}{{label.length>0?separator:''}}{{label.length==0?"&nbsp;":""}}</label>
      </ng-container>
    </div>
    <div class="d-flex col-sm-{{widthTextColumns}}">
      <select #txtTag style="border: 0px;border-bottom: 1px solid rgb(196, 196, 196);border-radius: 0px;" class="form-select form-select-sm" (change)="onChangeItem($event)" formControlName="datos" [tabIndex]="this.onTabIndex">
        <option class="item-select" *ngFor="let item of itemList;let i = index;" (value)="i" [ngValue]="item[this.columnList]">
          {{getColumnList(item)}}</option>
        <!-- <option class="item-select"  *ngFor="let item of itemList;let i = index;" (value)="i" >{{getColumnList(item)}}</option> -->
        {{this.myForm.value|json}}
      </select>
    </div>
  </form>
</div>

<!-- <div class="d-flex col-sm-{{widthTextColumns}}"> -->
