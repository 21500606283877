<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-file-code"
  subTitle="[Contabilidad Electrónica] => Generar el XML del catálogo de cuentas contables">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Llene la información para generar el XML">
        <div class="panel-content">

          <components-ui-number-box [required]="true" [tabIndex]="1" [maxLength]="4" [label]="'Año'"
            formControlName="Anio" [widthTextColumns]="1" [widthLabelColumns]="1" #txtAnio />

          <component-ui-combo-box [required]="true" [label]="'Mes'" formControlName="Mes" [tabIndex]="2" [zeroMask]="2"
            entityName="Mes" [widthTextColumns]="1" [widthLabelColumns]="1" [widthRightColumns]="0"
            [inputProperty]="'Nombre'" (onSelectedItem)="selectedCombo($event, 'Mes')" [listProperty]="'ClaveNombre'" />


          <component-ui-combo-box [required]="true" [label]="'Nivel'" formControlName="Nivel" [tabIndex]="3"
            [zeroMask]="2" entityName="NivelEnviar" [widthTextColumns]="1" [widthLabelColumns]="1"
            [widthRightColumns]="0" [inputProperty]="'Nombre'" (onSelectedItem)="selectedCombo($event, 'Nivel')"
            [listProperty]="'Nombre'" />

          <hr>
          <div style="margin-left: 112px;margin-top:10px; width: 150px;">
            <a (click)="print()" class="btn btn-primary btn-rounded px-4 rounded-pill d-block mt-10px">
              <i class="fa fa-print fa-lg me-2 ms-n2"></i>
              Imprimir
            </a>
            <a (click)="verificarErrores()" class="btn btn-danger btn-rounded px-4 rounded-pill d-block mt-10px">
              <i class="fa fa-check fa-lg me-2 ms-n2"></i>
              Buscar errores
            </a>
            <a (click)="generar()" class="btn btn-success btn-rounded px-4 rounded-pill d-block mt-10px">
              <i class="fa fa-file-export fa-lg me-2 ms-n2"></i>
              Generar
            </a>
          </div>


        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
