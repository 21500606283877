import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActiveButtons, ButtonParam } from '../../interfaces/container-base.interface';

@Component({
  selector: 'bar-base',
  templateUrl: './bar-base.component.html',
  styles: [
  ]
})
export class BarBaseComponent {

  @Input()
  public isNewItem: boolean = false;
  @Input()
  public saving: boolean = false;
  @Input()
  public activeButtons: ActiveButtons = {
    new: true,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: true,
    print: true,
    export: true,
    exit: false
  };


  @Output()
  public onClickButton: EventEmitter<ButtonParam> = new EventEmitter();

  clicBoton(param: ButtonParam) {
    this.onClickButton.emit(param);
  }

  constructor() {

  }



}
