<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-rotate"
  subTitle="Actualiza los movimientos bancarios directamente desde el banco">
  <div class="screen-content p-3" style="height: 100%;">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div class="list-group-item d-flex border-top-0">
      <div class="me-3 fs-16px"><i class="fa fa-rotate text-gray-500 fa-fw"></i></div>
      <div class="flex-fill">
        <div class="fs-14px lh-12 mb-2px fw-bold text-dark">Actualizar movimientos bancarios</div>
        <div class="mb-1 fs-12px">
          <div class="text-gray-600 flex-1">COMPLIT se conecta a los Bancos directamente a través de la API Prometeo
          </div>
          <div class="text-gray-600 flex-1">Descarga los movimientos para después poder imprimir estados de cuenta
            bancarios</div>
          <div class="text-gray-600 flex-1">Descarga los movimientos para después poder utilizarlos al elaborar Facturas
            o capturar pagos de CXC</div>
        </div>
      </div>
    </div>

    <form [formGroup]="myForm" autocomplete="off">
      <component-ui-combo-box [required]="true" [label]="'Cuenta Bancaria'" filterQuery='Usuario != ""'
        formControlName="Cuenta" [tabIndex]="1" [zeroMask]="2" entityName="CuentaBancaria" [widthTextColumns]="2"
        [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event, 'Cuenta')"
        [listProperty]="'ClaveNombre'" />

      <components-ui-date-box [required]="true" label="Fecha Inicio" [tabIndex]="2" formControlName="FechaInicio"
        [widthLabelColumns]="2" [widthTextColumns]="6" />

      <components-ui-date-box [required]="true" label="Fecha Fin" [tabIndex]="3" formControlName="FechaFin"
        [widthLabelColumns]="2" [widthTextColumns]="6" />
      <div style="margin-left: 15px;margin-top:10px;" (click)="getBalance()" class="btn btn-primary">Obtener estado de cuenta bancario</div>
    </form>

    <div class="table-responsive p-3 bg-light" *ngIf="lista.length>0">
      <div class="mb-1"><span class="badge bg-indigo">Movimientos Nuevos:{{getMovimientos | number}} </span></div>
      <ng-scrollbar style="height: 350px;">
        <table class="table table-bordered mb-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>REFERENCIA</th>
              <th>CONCEPTO</th>
              <th>FECHA</th>
              <th style="text-align: right;">CARGO</th>
              <th style="text-align: right;">ABONO</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of lista">
              <td>{{item.idMovimiento}}</td>
              <td>{{item.referencia}}</td>
              <td>{{item.detalle}}</td>
              <td>{{ item.fecha }}</td>
              <td style="text-align: right;">{{ item.cargo | number: '1.2'}}</td>
              <td style="text-align: right;">{{ item.abono | number: '1.2'}}</td>
              <td>{{ item.esNuevo ? 'Nuevo':'' }}</td>
            </tr>

          </tbody>
        </table>
      </ng-scrollbar>
    </div>
  </div>
</app-container-base>
