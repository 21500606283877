<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="ReceptorRetencion" title="Receptores Constancias Pagos" icon="fa fa-user-plus"
  subTitle="Administra el catálogo de Receptores para la emisión de constancias de pagos y retenciones">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Receptor'" entityName="ReceptorRetencion" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <components-ui-text-box [label]="'RFC'" [required]="true" formControlName="Rfc" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="13" [upperCase]="true" />

          <components-ui-text-box [label]="'CURP'" [required]="true" formControlName="Curp" [tabIndex]="4"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" [upperCase]="true" />

          <components-ui-text-box [label]="'Código Postal'" [required]="true" formControlName="DomicilioFiscal"
            [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="10" />

          <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="2" formControlName="EsNacional"
            [widthTextColumns]="7" [label]="'Nacional'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
