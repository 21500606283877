import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { environment } from 'src/environments/environment';
import { ResguardoService } from '../../services/resguardo.servuice';
import { ConsultaResguardo } from '../../interfaces/remision.interface';


@Component({
  selector: 'ctrl-busqueda-facturas-resguardo',
  templateUrl: './ctrl-busqueda-facturas-resguardo.component.html',
})
export class CtrlBusquedaFacturasResguardoComponent {

  listaComprobantes: ComboBoxEntity[] = [];
  listaSeries: ComboBoxEntity[] = [];
  info: UserLogged = {} as UserLogged;

  private utileService = inject(UtilsService);
  private http = inject(HttpClient);
  private eventsService = inject(EventsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  private mService = inject(ModalService)
  private rService = inject(ResguardoService);


  @ViewChild('txtFecha')
  public txtFecha!: ElementRef<DateBoxComponent>;
  @ViewChild('cboComp')
  public cboComp!: ElementRef<any>;
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  lista: any[] = [];
  indexEditing: number = 0;

  public myForm: FormGroup = this.fb.group({
    DocumentoVenta: null,
    Serie: null,
    Folio: 0
  })

  constructor(private fServices: FacturacionService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();


    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero);
    this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Ventas/ObtenerListaComprobantes`, { params }).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaComprobantes = lista;
        this.listaComprobantes.map(P => { P.ClaveNombre = `${String(P.Clave).padStart(3, '0')} ${P.Nombre}` });
        if (lista.length == 1) {
          this.getSeries(lista[0].Id);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          const t: any = this.cboComp;
          setTimeout(() => {
            t.tagInput.nativeElement.focus();
          }, 100);
        }
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: "No se encontró ningúna Serie de Ventas configurada para la Emp/Suc actuales.", cancelButton: false });
      }
    });
  }

  getSeries(idDocumento: number) {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.fServices.getSeries(this.info.empresa!.numero, this.info.sucursal!.numero, idDocumento).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaSeries = lista;
      }
    });
  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }




  onSelectedItem(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
    if (tipo == "DocumentoVenta") {
      this.myForm.controls[tipo].setValue(entity);
      this.myForm.get("Serie")?.setValue(null);
      this.getSeries(entity.Id);
    }
  }



  get getItemsComps(): ComboBoxEntity[] {
    return this.listaComprobantes;
  }

  buscar(e: any = null) {
    if (e) {
      e.target.blur();
    }
    if (!this.myForm.value.DocumentoVenta) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Documento de Venta.`, cancelButton: false });
      return;
    }
    if (!this.myForm.value.Serie) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Serie.`, cancelButton: false });
      return;
    }

    if (this.myForm.value.Folio <= 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Folio.`, cancelButton: false });
      return;
    }



    let DoctoVentaId: number = this.myForm.value.DocumentoVenta ? this.myForm.value.DocumentoVenta.Id : 0;
    let SerieId = this.myForm.value.Serie ? this.myForm.value.Serie.Id : 0;

    this.rService.getResguardosporFiltro(DoctoVentaId, SerieId, this.myForm.value.Folio).subscribe((lista: ConsultaResguardo[]) => {
      this.lista = lista;
      if (lista.length == 0) {
        this.eventsService.publish('home:showAlert', { message: `El Documento indicado no existe o no es de resguardo verifique.`, cancelButton: false });
      } else {
        this.mService.closeModal(lista[0]);
      }
    });
  }

  seleccionar(orden: any) {
    //    this.mService.closeModal(orden)
    this.onClose.emit(orden);
    this.mService.closeModal(orden);

  }

  getDate(fecha: any): string {
    if (fecha) {
      //    return moment(fecha).format("DD/MM/YYYY HH:mm:ss")
    }
    return "";
  }

  closeModal() {
    this.mService.closeModal(null);
  }
}
