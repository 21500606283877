<div (keydown)="keydown($event)" class="card text-center border-0" cdkDrag
  style="position: absolute;top:250px;width: 540px;left: 360px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar/editar producto compuesto
  </div>
  <div class="card-body bg-light">
    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">
        <form [formGroup]="myForm" autocomplete="off">
          <div class="d-flex">
            <div style="width:315px;margin-right: -35px;">
              <components-ui-text-box (keydown.escape)="closeModal()" [required]="true" (blur)="blurProducto($event)"
                [tabIndex]="100" [maxLength]="15" (onClicIcon)="openProdSearch()" (keydown.f2)="openProdSearch()"
                [enableIcon]="true" [label]="'Producto'" [widthTextColumns]="4" [widthLabelColumns]="6" #txtProducto />
            </div>
            <span style="height: 22px; padding: 7px; width: 200x;" class="badge bg-info rounded-0">{{getNombreProducto |
              uppercase}}</span>
          </div>
          <components-ui-number-box formControlName="Cantidad" [tabIndex]="101" [required]="true" [maxLength]="15"
            [label]="'Cantidad'" [widthTextColumns]="2" [widthLabelColumns]="4" #txtUnidades />
          <div class="d-flex">
            <div style="width:315px;margin-right: -35px;">
              <components-ui-number-box [required]="true" (blur)="blurAlmacen($event)" [tabIndex]="102" [maxLength]="15"
                (onClicIcon)="openAlmacenSearch()" (keydown.f2)="openAlmacenSearch()" [enableIcon]="true"
                [label]="'Almacen'" [widthTextColumns]="4" [widthLabelColumns]="6" #txtAlmacen />
            </div>
            <span style="height: 22px; padding: 7px; width: 200Px;" class="badge bg-info rounded-0">{{getNombreAlmacen |
              uppercase}}</span>
          </div>

          <div class="d-flex">
            <div style="width:315px;margin-right: -35px;">
              <components-ui-number-box [required]="true" (blur)="blurConceptoAlmacen($event)" [tabIndex]="103" [maxLength]="15"
                (onClicIcon)="openConceptoAlmacenSearch()" (keydown.f2)="openConceptoAlmacenSearch()" [enableIcon]="true"
                [label]="'Concepto salida'" [widthTextColumns]="4" [widthLabelColumns]="6" #txtConcepto />
            </div>
            <span style="height: 22px; padding: 7px; width: 200Px;" class="badge bg-info rounded-0">{{getNombreConceptoAlmacen |
              uppercase}}</span>
          </div>

        </form>
      </div>
    </div>

    <!-- <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
        [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #ctrlBusqueda />
    </ng-template> -->

    <ng-template #busquedaProductos let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
        [filter]="getFilter" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
    </ng-template>

    <ng-template #busquedaAlmacen let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Almacenes" [columns]="getAlmacenColumns" [entityName]="'Almacen'"
        [filter]="getAlmacenFilter" [propertys]="getAlmacenPropertys" [orders]="'Nombre|asc'" #busquedaAlmacen />
    </ng-template>

    <ng-template #busquedaConceptoAlmacen let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Conceptos de salida de Almacen" [columns]="getConceptoAlmacenColumns" [entityName]="'ConceptoAlmacen'"
        [filter]="getConceptoAlmacenFilter" [propertys]="getConceptoAlmacenPropertys" [orders]="'Nombre|asc'" #busquedaConceptoAlmacen />
    </ng-template>






  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8] Aceptar
      </div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
    </div>
  </div>
</div>
