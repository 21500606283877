import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { CuentasPorCobrarService } from '../../services/cxc.service';
import { SaldoCXC } from '../../interfaces/saldocxc.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { GuiColumnAlign, GuiRowSelection, GuiRowSelectionMode, GuiRowSelectionType } from '@generic-ui/ngx-grid';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { BaseService } from 'src/app/service/base.service';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Pago, PagoDetalle } from '../../interfaces/pago.interface';
import { PagoAplicadoCXC } from '../../interfaces/pagos-aplicados-cxc.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { debounceTime, Subject } from 'rxjs';
import * as _ from 'lodash';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import Swal from 'sweetalert2';
import { CfdiEstadoSAT } from 'src/app/ventas/interfaces/venta.interface';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
@Component({
  templateUrl: './captura-pagos-cxc.page.component.html',
  styleUrls: ['./captura-pagos-cxc.page.component.scss']
})
export class CapturaPagosCxcPageComponent implements OnInit {

  propsPagosAplicadosFilter = ['folio', 'abonado', 'numeroOperacion', 'claveFormaPago', 'nombreFormaPago', 'claveConcepto', 'nombreConcepto', 'moneda', 'tipoCambio', 'banco', 'cuenta', 'elaboro'];

  @ViewChild('detallePago')
  public detallePago!: ElementRef<HTMLElement>;

  idTabs: number = new Date().getTime();

  ColumnMode = ColumnMode;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<TextBoxComponent>;
  @ViewChild('txtAbonar')
  public txtAbonar!: ElementRef<TextBoxComponent>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;

  nombreEmpresa: string = "";
  nombreCliente: string = "";
  tabSelected: string = "conSaldo";
  cuentaBeneficiaria: any;
  cuentaOrdenante: any;

  private eventsService = inject(EventsService);
  private frmBuilder = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private fService = inject(FacturacionService);
  private containerService = inject(ContainerBaseService);
  private ms = inject(ModalService);
  private cxcService = inject(CuentasPorCobrarService);
  private baseService = inject(BaseService);
  saldo: number = 0;
  esMonedaExtranjera: boolean = false;
  disponible: number = 0;
  usuarioLoggeado!: UserLogged;
  clienteSeleccionado: any = {
    Id: 0,
    LimiteCredito: 0,
    CreditoSuspendido: 0,
  };
  loading: boolean = false;
  pago!: Pago;

  balance: any[] = []
  pagosAplicados: PagoAplicadoCXC[] = []
  public GuiColumnAlign = GuiColumnAlign;


  searchConfigurationCliente!: SearchConfiguration;
  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getFilter(): any {
    // if (this.searchConfigurationCliente) {
    //   return this.searchConfigurationCliente.filter;
    // }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  public formPagosCxC: FormGroup = this.frmBuilder.group({
    Id: [0],
    Empresa: [],
    Sucursal: ['', Validators.required],
    Cliente: ['', Validators.required],
    Folio: [0],
    ImporteAbonar: [0, Validators.min(1)],
    ImporteDisponible: [0],
    ImporteAbonado: [0],
    Disponible: [0],
    Diferencia: [0],
    NumeroOperacion: [""],
    Concepto: [null],
    FormaPago: [null],
    Moneda: [null],
    Comentarios: [""],
    FechaDelDeposito: [new Date(), Validators.required],
    FechaDelPago: [new Date(), Validators.required],
    FechaElabora: [new Date(), Validators.required],
    FechaCancelacion: [null],
    MotivoCancelacion: [''],
    TipoCambio: [0, Validators.min(0)],
    RfcEmisorCtaOrd: [],
    NomBancoOrdExt: [],
    NomBancoBeneficiario: [],
    CtaOrdenante: [],
    RfcEmisorCtaBen: [],
    CtaBeneficiario: [],
    Detalle: [[]],
    IdReciboElectronico: [0],
    Eliminado: [false],
    Baja: [false],
    Aplicado: [true]
  })

  activeButtons: ActiveButtons = {
    all: false,
    save: true,
  }

  ngOnInit() {
    this.usuarioLoggeado = this.utilsService.getUserLogged();
    this.nombreEmpresa = this.usuarioLoggeado.empresa!.nombre;
    this.precargaInformacion();
  }

  precargaInformacion() {
    setTimeout(() => {
      this.formPagosCxC.controls["Sucursal"].setValue({ Id: this.usuarioLoggeado.sucursal!.numero, Nombre: this.usuarioLoggeado.sucursal!.nombre });
      const txt: any = this.txtCliente;
      txt.tagInput.nativeElement.focus();
    }, 0);
    this.formPagosCxC.controls["Empresa"].setValue({ Id: this.usuarioLoggeado.empresa?.numero });
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.usuarioLoggeado.empresa!.numero}`);
    this.calcularImportes();
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (cliente: any) => {
      if (cliente) {
        this.loading = true;
        this.clienteSeleccionado = cliente;
        this.nombreCliente = `${cliente.Clave} ${cliente.Nombre}`;
        // this.formPagosCxC.controls["NombreCliente"].setValue(`${cliente.Clave} ${cliente.Nombre}`);
        this.formPagosCxC.controls["Cliente"].setValue({ Id: this.clienteSeleccionado.Id });
        this.getCustomerBalance(cliente.Id);
        this.getPagosAplicados(cliente.Id);
      }
    }, 'lg')
  }

  selectedCombo(entity: any, tipo: string) {
    this.formPagosCxC.controls[tipo].setValue({ Id: entity.Id, Clave: entity.Clave, Nombre: entity.Nombre, ClaveNombre: `${entity.Clave} ${entity.Nombre}` });
    if (tipo == "Moneda") {
      this.esMonedaExtranjera = entity.EsExtranjera;
      if (!this.esMonedaExtranjera) {
        this.formPagosCxC.controls["TipoCambio"].setValue(0);
      }
    }
  }

  buscarCliente(clave: any) {
    this.utilsService.isLoad(true);
    this.baseService.getClientePorClave(this.usuarioLoggeado.empresa!.numero, clave).subscribe({
      next: (c: Cliente) => {
        if (c) {
          this.clienteSeleccionado = c;
          this.nombreCliente = `${c.Clave} ${c.Nombre}`;
          // this.formPagosCxC.controls["NombreCliente"].setValue(`${c.Clave} ${c.Nombre}`);
          this.formPagosCxC.controls["Cliente"].setValue({ Id: c.Id });
          this.getCustomerBalance(c.Id);
          this.getPagosAplicados(c.Id);
          this.cxcService.obtenerSaldoCliente(this.usuarioLoggeado.empresa!.numero, c.Id).subscribe((saldo) => {
            this.saldo = saldo;
            this.disponible = c.LimiteCredito - saldo;
          })
          const txt: any = this.txtAbonar;
          txt.tagInput.nativeElement.focus();
        } else {
          this.eventsService.publish('home:showAlert', {
            message: `No se encontró el Cliente indicado.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
              this.balance = [];
              this.clienteSeleccionado = {
                Id: 0,
                LimiteCredito: 0,
                CreditoSuspendido: 0,
              };
              this.formPagosCxC.controls["Cliente"].setValue(null);
              this.nombreCliente = "";
              this.saldo = 0;
              this.disponible = 0;
            }
          });
        }
        this.utilsService.isLoad(false);
      },
      error: () => { }
    })
  }

  enterCliente(event: any) {
    if (event.target.value) {
      let claveCliente = event.target.value.includes(" ") ? event.target.value.split(" ")[0] : event.target.value;
      this.buscarCliente(claveCliente);
    }
  }

  itemSeleccionado(elemento: any) {
    this.buscarCliente(elemento.Clave);
  }

  heightTableSaldos: number = 27;
  heightTableAplicados: number = 27;
  heightTablePagosDetalle: number = 27;

  getCustomerBalance(clienteId: number) {
    const { Sucursal } = this.formPagosCxC.value;
    this.cxcService.getCustomerBalance(this.usuarioLoggeado.empresa!.numero, Sucursal.Id, clienteId).subscribe({
      next: (lista: SaldoCXC[]) => {
        this.balance = lista.map(b => { return { ...b, seleccionado: false, editando: false } });
        this.loading = false;
        this.heightTableSaldos = this.balance.length === 1 ? 53 : (this.balance.length > 0 ? (this.balance.length < 10 ? ((this.balance.length) * 24.7) : 300) : 27.5);
      }, error: (error: Error) => {
        this.loading = false;
      }
    });
  }

  getPagosAplicados(clienteId: number) {
    this.cxcService.getPagosAplicados(clienteId).subscribe({
      next: (lista: PagoAplicadoCXC[]) => {
        this.pagosAplicados = lista;
        this.heightTableAplicados = this.pagosAplicados.length === 1 ? 53 : (this.pagosAplicados.length > 0 ? (this.pagosAplicados.length < 10 ? ((this.pagosAplicados.length + 1) * 24.7) : 300) : 27.5);
        this.loading = false;
      }, error: (error: Error) => {
        this.loading = false;
      }
    });
  }

  marcar(item: any) {
    let montoAbonado: number = Number(this.formPagosCxC.value.ImporteAbonado);
    let disponible: number = Number(this.formPagosCxC.value.ImporteDisponible);
    if (disponible <= 0 && !item.seleccionado) {
      this.eventsService.publish('home:showAlert', {
        message: `El monto Disponible debe de ser mayor a cero para poder seguir liquidando/abonando facturas.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
          this.balance = this.balance.map(b => {
            if (b.serieFolio === item.serieFolio) {
              b.seleccionado = false;
            }
            return b;
          })
        }
      });
      return;
    }
    if (this.formPagosCxC.value.ImporteAbonar > 0) {
      this.balance.map((i) => {
        i.editando = false;
        if (i.serieFolio == item.serieFolio) {
          item.seleccionado = !item.seleccionado;
          if (item.seleccionado) {
            if (i.saldo > disponible) {
              i.observaciones = "Pago Parcial";
              i.abono = Number(disponible);
            } else {
              i.observaciones = "Factura Liquidada";
              i.abono = i.saldo;
            }
            montoAbonado += i.abono;
          } else {
            montoAbonado -= i.abono;
            i.abono = 0;
            i.observaciones = "";
          }
          this.formPagosCxC.controls['ImporteAbonado'].setValue(Number(montoAbonado).toFixed(2));
          disponible = Number(this.formPagosCxC.value.ImporteAbonar) - Number(montoAbonado);
          this.formPagosCxC.controls['ImporteDisponible'].setValue(Number(disponible).toFixed(2));
        }
        return i;
      })
    } else {
      this.eventsService.publish('home:showAlert', {
        message: `Debe indicar un monto a Abonar mayor a cero.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
          this.balance = this.balance.map(b => {
            return { ...b, seleccionado: false }
          })
        }
      });
    }
  }

  selectTodos: boolean = false;
  seleccionarTodos(item: any) {
    this.selectTodos = !this.selectTodos;
    if (this.formPagosCxC.value.ImporteAbonar > 0) {
      this.balance.map(i => {
        let disponible: number = Number(this.formPagosCxC.value.ImporteDisponible);
        if (this.selectTodos) {
          if (Number(disponible) >= i.saldo) {
            i.abono = i.saldo;
            i.abonoOriginal = i.saldo;
            i.observaciones = "Factura Liquidada";
            i.seleccionado = true;
          } else {
            i.abono = disponible;
            i.abonoOriginal = disponible;
            i.observaciones = "Pago Parcial";
            i.seleccionado = !(disponible === 0);
          }
        } else {
          i.abono = 0;
          i.abonoOriginal = 0;
          i.observaciones = "";
          i.seleccionado = false;
        }
        this.procesoDeCalculo();
        return i;
      });
    } else {
      this.eventsService.publish('home:showAlert', {
        message: `Debe indicar un monto a Abonar mayor a cero.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
          this.selectTodos = false;
        }
      });
    }
  }

  editarRegistro(item: any, editar: boolean) {
    this.balance.map((i) => {
      if (i.serieFolio == item.serieFolio) {
        if (item.seleccionado) {
          item.editando = editar;
        } else {
          item.editando = false;
        }
      }
      return i;
    });
    setTimeout(() => {
      document.getElementById("txt-" + item.serieFolio)?.focus()
    }, 300);
  }

  private calcImportes = new Subject<string>();
  onCalcImportes(item: any, event: any) {
    this.procesoDeCalculo();
    let disponible: number = Number(this.formPagosCxC.value.ImporteDisponible);
    let abono = Number(event.target.value);

    if (abono <= item.saldo) {
      item.abono = abono;
      this.calcImportes.next("");
    } else {
      this.eventsService.publish('home:showAlert', {
        message: `El abono no debe ser mayor al monto del saldo.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
          item.abono = item.saldo;
          this.calcImportes.next("");
        }
      });
    }

    if (disponible < 0) {
      this.eventsService.publish('home:showAlert', {
        message: `El abono no debe ser mayor al monto disponible.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
          item.abono = item.abonoOriginal;
          this.calcImportes.next("");
        }
      });
    }
  }

  calcularImportes() {
    this.calcImportes.pipe(debounceTime(300)).subscribe(() => {
      this.procesoDeCalculo();
    });
  }

  procesoDeCalculo() {
    let montoAbonado: number = 0;
    let disponible: number = Number(this.formPagosCxC.value.ImporteDisponible);
    for (const item of this.balance) {
      montoAbonado += Number(item.abono);
    }
    this.formPagosCxC.controls['ImporteAbonado'].setValue(Number(montoAbonado).toFixed(2));
    disponible = Number(this.formPagosCxC.value.ImporteAbonar) - Number(montoAbonado);
    this.formPagosCxC.controls['ImporteDisponible'].setValue(Number(disponible).toFixed(2));
  }


  onBeforeSave(param: EventReturn) {
    const {
      RfcEmisorCtaOrd,
      NomBancoOrdExt,
      CtaOrdenante,
      RfcEmisorCtaBen,
      CtaBeneficiario
    } = this.formPagosCxC.value;

    if (CtaBeneficiario) {
      let detallePagos = this.balance.filter(b => b.seleccionado);
      if (detallePagos.length === 0) {
        this.eventsService.publish('home:showAlert', { message: "Debe seleccionar al menos un comprobante de la lista.", cancelButton: false });
        param.callback(false);
      } else {
        if (detallePagos.filter(d => Number(d.abono) === 0).length > 0) {
          this.eventsService.publish('home:showAlert', { message: "El monto a abonar de los comprobantes debe de ser mayor a cero, favor de verificar.", cancelButton: false });
          param.callback(false);
        } else {
          this.agregarDetalle(detallePagos);
          const ent: Pago = this.formPagosCxC.value;
          ent.FechaDelDepositoString = moment(ent.FechaDelDeposito).format("YYYY-MM-DDTHH:mm:ss");
          ent.FechaDelPagoString = moment(ent.FechaDelPago).format("YYYY-MM-DDTHH:mm:ss");
          param.callback(true);
        }
      }
    } else {
      this.eventsService.publish('home:showAlert', { message: "Debe seleccionar la cuenta beneficiaria.", cancelButton: false });
    }
  }

  onAfterSave(entity: Pago) {
    setTimeout(() => {
      this.utilsService.isLoad(true);
      this.limpiarDatos();
      if (entity.IdReciboElectronico > 0) {
        let idCliente = entity.Cliente.Id;
        let idRep = entity.IdReciboElectronico;
        setTimeout(() => {
          this.printRecibo(idRep, () => {
            this.sendEmail(idRep, idCliente);
          });
        }, 300);
      }

      setTimeout(() => {
        this.precargaInformacion();
        let clave = this.nombreCliente.split(" ")[0];
        this.buscarCliente(Number(clave));
        this.selectTodos = false;
      }, 700);
    }, 300);
  }

  limpiarDatos() {
    let today = new Date();
    let todayWOutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    //this.formPagosCxC.controls['RfcEmisorCtaOrd'].setValue(event.RfcCliente);
    this.formPagosCxC = this.frmBuilder.group({
      Id: [0],
      Empresa: [],
      Sucursal: ['', Validators.required],
      Cliente: ['', Validators.required],
      Folio: [0],
      ImporteAbonar: [0, Validators.min(1)],
      ImporteDisponible: [0],
      ImporteAbonado: [0],
      Disponible: [0],
      Diferencia: [0],
      NumeroOperacion: [""],
      Concepto: [null],
      FormaPago: [null],
      Moneda: [null],
      Comentarios: [""],
      FechaDelDeposito: [todayWOutTime, Validators.required],
      FechaDelPago: [todayWOutTime, Validators.required],
      FechaElabora: [todayWOutTime, Validators.required],
      FechaCancelacion: [null],
      MotivoCancelacion: [''],
      TipoCambio: [0, Validators.min(0)],
      RfcEmisorCtaOrd: [],
      NomBancoOrdExt: [],
      NomBancoBeneficiario: [],
      CtaOrdenante: [],
      RfcEmisorCtaBen: [],
      CtaBeneficiario: [],
      Detalle: [[]],
      IdReciboElectronico: [0],
      Eliminado: [false],
      Baja: [false],
      Aplicado: [true]
    });
    this.cuentaBeneficiaria = null;
    this.cuentaOrdenante = null;
  }

  cambioCtaOrdenante(event: any) {
    this.formPagosCxC.controls['RfcEmisorCtaOrd'].setValue(event ? event.RfcBanco : "");
    this.formPagosCxC.controls['NomBancoOrdExt'].setValue(event ? event.NombreBanco : "");
    this.formPagosCxC.controls['CtaOrdenante'].setValue(event ? event.NumeroCuenta : "");
    this.cuentaOrdenante = event;
    console.log(this.formPagosCxC.value)
  }

  cambioCtaBeneficiaria(event: any) {
    this.formPagosCxC.controls['RfcEmisorCtaBen'].setValue(event ? event.RfcBanco : "");
    this.formPagosCxC.controls['CtaBeneficiario'].setValue(event ? event.NumeroCuenta : "");
    this.formPagosCxC.controls['NomBancoBeneficiario'].setValue(event ? event.NombreBanco : "");
    this.cuentaBeneficiaria = event;
    console.log(this.formPagosCxC.value)
  }

  agregarDetalle(detalles: SaldoCXC[]) {
    let pagoDetalle = detalles.map((item: any) => {
      return {
        Id: 0,
        Sucursal: { Id: item.sucursal.id },
        CuentaCxc: { Id: item.cuenta.id },
        Serie: item.serie,
        Folio: item.folio,
        SaldoAnterior: item.saldo,
        ImporteAbonado: Number(item.abono),
        SaldoActual: Number(item.saldo) - Number(item.abono),
        Diferencia: 0,
        Comentarios: item.comentarios,
        UUID: item.uuid,
        MonedaDocumento: { Id: item.monedaDocumento },
        Emision: item.emision,
        TipoCambio: item.tipoCambio
      }
    });
    this.formPagosCxC.controls['Detalle'].setValue(pagoDetalle);
  }

  eliminarPago(pago: PagoAplicadoCXC) {
    if (!pago.usuarioCancela) {
      console.log(pago.usuarioCancela);
      this.eventsService.publish('home:showAlert', {
        message: "¿Está seguro de cancelar el Pago? también se cancelará el REP relacionado.", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.fService.verifyStatusRep(pago.idReciboElectronico, (r: CfdiEstadoSAT) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              setTimeout(() => { this.openCancel(pago); }, 151);
            })
          }
        }
      });
    }
  }

  openCancel(pago: PagoAplicadoCXC) {
    const b: any = this.modalCancelOptiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e, pago)
      }
    })
  }

  proceedCancel(e: any, pago: PagoAplicadoCXC) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.cancelarPagoCXC(e, pago.id, pago.idReciboElectronico, () => {
      let clave = this.nombreCliente.split(" ")[0];
      this.buscarCliente(Number(clave));
      this.limpiarDatos();
    });
  }


  printRecibo(idRep: number, cb: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.printRep(idRep, () => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      cb && cb();
    });
  }

  sendEmail(idRep: number, idCliente: number) {
    if (idRep > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fService.obtenerCorreosCliente(idCliente).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const correos = result.message;
        Swal.fire({
          title: "Envíar email para:",
          input: "text",
          inputValue: correos,
          inputAttributes: { autocapitalize: "off" },
          showCancelButton: true,
          confirmButtonText: "Enviar",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              this.eventsService.publish('home:isLoading', { isLoading: true });
              this.fService.sendRepEmail(idRep, result.value).subscribe((result) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                if (result.success) {
                  this.eventsService.publish('home:showAlert', { message: "Se ha enviado por e-mail", cancelButton: false });
                } else {
                  this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
                }
              })
            } else {
              this.eventsService.publish('home:showAlert', { message: "Debe de indicar por lo menos un e-mail.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
            }
          }
        });
      });
    }
  }

  mostrarComprobantes(tipo: string) {
    this.tabSelected = tipo;
  }

  abonar(evnt: any, idx: number) {
    var reg = this.balance[idx];
    var abono = Number(evnt.target.value);
    reg.abono = abono;
    if (abono === reg.saldo) {
      reg.observaciones = "Factura Liquidada";
    } else if (abono > 0 && abono < reg.saldo) {
      reg.observaciones = "Factura Parcial";
    }
    this.balance[idx] = reg;
  }

  calcDisponible(evnt: any) {
    let valor = evnt.after === "" ? 0 : Number(evnt.after).toFixed(2);
    this.formPagosCxC.controls['ImporteDisponible'].setValue(valor);
  }

  printReciboAplicados(idReciboElectronico: number) {
    if (idReciboElectronico > 0) {
      this.printRecibo(idReciboElectronico, null);
    }
  }


  download(idRep: number) {
    this.fService.downloadFilesRep(idRep);
  }

  pagosDetalle: any[] = []
  obtenerDetalle(idPago: number) {
    this.cxcService.getPagoDetalle(idPago).subscribe({
      next: (detalle: PagoDetalle[]) => {
        this.pagosDetalle = detalle;
        this.heightTablePagosDetalle = this.pagosDetalle.length > 0 ? (this.pagosDetalle.length < 10 ? ((this.pagosDetalle.length + 1) * 21.95) : 300) : 27;

        this.ms.openModal(this.detallePago, (result: any) => {
          console.log(result)
        }, 'lg');
        console.log(detalle);
      }, error: (err: Error) => {
        console.error(err);
      }
    });
  }

  aceptar(boleano: boolean) {
    this.ms.closeModal({ cerro: true });
  }

  habilitar = false;
}
