import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSettings } from '../../../services/app-settings.service';



@Component({
	selector: 'extra-error',
  templateUrl: './extra-error.html'
})

export class ExtraErrorPage implements OnDestroy, OnInit {
	constructor(public appSettings: AppSettings) {
	}

	ngOnInit() {
    this.appSettings.appEmpty = true;
  }

  ngOnDestroy() {
    this.appSettings.appEmpty = false;
  }
}
