import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { EventsService } from 'src/app/service/events.service';
import { Empresa } from '../../interfaces/empresa.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bloqueo-modulos-page',
  templateUrl: './bloqueo-modulos-page.component.html',
  styles: [
  ]
})
export class BloqueoModulosPageComponent implements OnInit {

  info: UserLogged = {} as UserLogged;
  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }
  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Contabilidad: [false],
    Compras: [false],
    CXP: [false],
    CXC: [false],
    Inventario: [false],
    Finanzas: [false],
    Baja: [false],
    Eliminado: [false],

  })

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private utileService: UtilsService) {
  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "save": this.save(); break;
    }
  }

  save() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let ent = this.myForm.value;
    ent.Empresa = { Id: this.info.empresa?.numero };
    this.containerService.saveEntity("BloqueoModulo", JSON.stringify(ent)).subscribe((result) => {
      if (result.success) {
        this.containerService.getLastEntity("BloqueoModulo").subscribe((ent) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 })
          if (ent) {
            this.myForm.reset(ent);
          }
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        Swal.fire({ icon: 'error', text: result.message }).then(() => { });
      }
    })
  }

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.info = this.utileService.getUserLogged();
    this.containerService.getLastEntity("BloqueoModulo").subscribe((ent) => {
      if (!ent) {
        this.containerService.getEmptyEntity("BloqueoModulo").subscribe((newE) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.myForm.reset(newE);
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.myForm.reset(ent);
      }
    });
  }


}
