import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Producto, ProductoUnidad, Unidad } from 'src/app/Inventarios/interfaces/producto.interface';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { EventsService } from 'src/app/service/events.service';
import { CfdiEstadoSAT } from 'src/app/ventas/interfaces/venta.interface';
import { environment } from 'src/environments/environment';
import { ClaseCompra, CondicionPagoProveedor, DocumentoCompra, SerieCompra } from '../../interfaces/clasecompra.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Proveedor } from '../../interfaces/proveedores.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { MetodoPago } from 'src/app/ventas/interfaces/claseventa.interface';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { Compra, CompraCargoAdicional, CompraConcepto, CompraConceptoImpuesto, CompraFormaPago, ConsignadoProveedor, Paqueteria } from '../../interfaces/compra.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType, GuiInfoPanel, GuiRowDetail, GuiRowStyle, GuiSorting } from '@generic-ui/ngx-grid';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { CompraService } from '../../services/compras.service';
import { Chofer } from 'src/app/ventas/interfaces/chofer.interface';
import { Departamento } from '../../../ventas/interfaces/unidad.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { addHours, addMinutes, addSeconds } from 'date-fns';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { CheckBoxComponent } from 'src/app/component-ui/components/check-box/check-box.component';
import { timer } from 'rxjs';

@Component({
  selector: 'app-compras-page',
  templateUrl: './compras-page.component.html',
  styleUrls: ['./compras-page.component.css'],

})
export class ComprasPageComponent {
  info: UserLogged = {} as UserLogged;
  anticipoDisponible: number = 0;
  listaComprobantes: ComboBoxEntity[] = [];
  listaSeries: ComboBoxEntity[] = [];
  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';
  esMonedaExtranjera: boolean = false;
  utilizaSegundoNombre: boolean = false;
  afectaBackOrder: boolean = false;
  totalCargoProrrateado: number = 0;

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  private comprasService = inject(CompraService);

  private readonly baseUrl: string = environment.baseUrlApi;

  blockCombos: boolean = false;
  address: string = "";
  saving: boolean = false;
  showDescription: boolean = false;
  loading: boolean = false;
  estadoSAT: CfdiEstadoSAT | null = null;
  documentosPuedeCopiar: string = '';
  cargoEditar: CompraCargoAdicional | null = null;
  afectaAlmacen: boolean = false;
  afectaCartera: boolean = false;
  cords: any = null;
  //*variables para el grid
  sourceProducts: any[] = [];
  idUnico: string = '';
  selectedIndex: number = -1;
  esEscapeProducto: boolean = false;
  esEscapeCosto: boolean = false;
  esEscapeDescuento: boolean = false;
  esEscapeCantidad: boolean = false;
  esEscapeSubTotal: boolean = false;
  esEnterProducto: boolean = false
  esEnterSubtotal: boolean = false
  esEnterCosto: boolean = false
  esEnterPaqueteLote: boolean = false
  esEnterDescuento: boolean = false
  enviarFocoProducto: boolean = false
  enviarFocoCantidad: boolean = false
  enviarFocoPaqueteLote: boolean = false
  enviarFocoCosto: boolean = false
  enviarOtroRenglon: boolean = false;
  enviarFocoDescuento: boolean = false;
  enviarFocoSubtotal: boolean = false;
  esEscapeLotePaquete: boolean = false;
  colCantidad: number = 4;
  colProducto: number = 2;
  colCosto: number = 7;
  colDescuento: number = 9;
  colPaqueteLote: number = 10;
  colSubTotal: number = 13;
  indexCargoEditar: number = -1;


  @ViewChild('chkMantenimiento')
  public chkMantenimiento!: ElementRef<CheckBoxComponent>;
  @ViewChild('txtNoDeGuia')
  public txtNoDeGuia!: ElementRef<TextBoxComponent>;
  @ViewChild('txtProveedor')
  public txtProveedor!: ElementRef<NumberBoxComponent>;
  @ViewChild('ctrlConversiones')
  public ctrlConversiones!: ElementRef<HTMLElement>;
  @ViewChild('txtFechaEmision')
  public txtFechaEmision!: ElementRef<HTMLElement>;
  @ViewChild('anticiposDisponibles')
  public anticiposDisponibles!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('modalFormaPago')
  public ctrlFormaPago!: ElementRef<HTMLElement>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('cboComp')
  public cboComp!: ElementRef<any>;
  @ViewChild('cboClase')
  public cboClase!: ElementRef<any>;
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;
  @ViewChild('cfdiRelacionado')
  public cfdiRelacionado!: ElementRef<HTMLElement>;
  @ViewChild('comentarioGlobal')
  public comentarioGlobal!: ElementRef<HTMLElement>;
  @ViewChild('copiarComprobante')
  public copiarComprobante!: ElementRef<HTMLElement>;
  @ViewChild('pendienteEntregar')
  public pendienteEntregar!: ElementRef<HTMLElement>;
  @ViewChild('modalConisgnado')
  public modalConisgnado!: ElementRef<HTMLElement>;
  @ViewChild('modalCargoAdicional')
  public modalCargoAdicional!: ElementRef<HTMLElement>;
  @ViewChild('ctrlAgregarCfdi') public ctrlAgregarCfdi!: ElementRef<HTMLElement>;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    DocumentoCompra: [{} as DocumentoCompra],
    Clase: [{} as ClaseCompra],
    Serie: [{} as SerieCompra],
    SerieString: [''],
    Folio: [0],
    Exportacion: [false],
    FechaEmision: [],
    FechaEmisionString: [''],
    FechaCancelacionString: [''],
    NumeroPedimento: [''],
    UUIDFactura: [''],
    UUIDNotaCredito: [''],
    FechaCancelacion: [],
    FechaUsuarioAlta: [],
    FechaUsuarioCancela: [],
    Vigente: [false],
    Estado: [false],
    TieneBackOrder: [false],
    UsuarioAlta: [{} as Usuario],
    UsuarioCancela: [{} as Usuario],
    Consignado: [{} as ConsignadoProveedor],
    Almacen: [null],
    Impreso: [false],
    Proveedor: [null],
    FacturaProveedor: [''],
    FechaFacturaProveedor: [null],
    FechaEstimadaEntrega: [null],
    Moneda: [{} as Moneda],
    Paqueteria: [{} as Paqueteria],
    NumeroGuia: [''],
    TipoCambio: [0],
    MetodoPago: [{} as MetodoPago],
    Condicion: [{} as CondicionPagoProveedor],
    Departamento: [{} as Departamento],
    Vehiculo: [null],
    TipoServicio: [null],
    Chofer: [{} as Chofer],
    Preventivo: [false],
    Correctivo: [false],
    Mantenimiendo: [false],
    FechaVencimiento: [],
    Observaciones: [''],
    DescripcionAdicional: [''],
    Direccion: [{} as Direccion],
    SubTotal: [0],
    SubTotalAntesDescuento: [0],
    SubTotalNotaCredito: [0],
    SubTotalGrabaIvaTrasladado: [0],
    SubTotalGrabaIvaRetenido: [0],
    SubTotalGrabaIsrRetenido: [0],
    SubTotalGrabaIeps: [0],
    SubTotalGrabaIvaCero: [0],
    SubTotalGrabaIvaExento: [0],
    Total: [0],
    TotalAntesDescuento: [0],
    TotalDescuento: [0],
    TotalReciboPago: [0],
    Odometro: [0],
    TotalNotaCredito: [0],
    TotalIvaTrasladado: [0],
    TotalIvaRetenido: [0],
    TotalIsrRetenido: [0],
    TotalIeps: [0],
    TotalImpuestosTrasladados: [0],
    TotalImpuestosLocalesTrasladados: [0],
    TotalImpuestosLocalesRetenidos: [0],
    FormasPago: [[] as CompraFormaPago[]],
    LugarExpedicion: [''],
    Conceptos: [[] as CompraConcepto[]],
    CargosAdicionales: [[] as CompraCargoAdicional[]],
    RutaDocumento: [''],
    NombreDocumento: [''],
    Eliminado: [false],
    Baja: [false],
    MotivoCancelacion: [''],
    CantidadGlobal: [0],
    CantidadRestanteGlobal: [0],
    IdsCopiados: [''],
    UUID: [''],
    Seleccionado: [false],
    ComprasCopiadas: [null],
    SeriesFoliosCopiados: [''],
    TipoGastoUnidad: [null]
  })

  constructor(private fServices: FacturacionService,
    private compraService: CompraService,
    private fb: FormBuilder,
    private cService: ContainerBaseService,
    private http: HttpClient,
    private ms: ModalService,
    private utileService: UtilsService,) {
  }

  sorting: GuiSorting = {
    enabled: true
  };

  public GuiColumnAlign = GuiColumnAlign;
  public GuiCellView = GuiCellView;
  public GuiDataType = GuiDataType;
  searchConfigurationCliente: SearchConfiguration | null = null;
  searchConfigurationProducto: SearchConfiguration | null = null;
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,
    schemaManager: true
  };


  rowStyle: GuiRowStyle = {
    styleFunction: (data: CompraConcepto, index: number) => {
      if (data.Producto) {
        if (data.Producto.SeProrrateaEnCompras) {
          return 'color: red; font-weight: bold;background-color:#d3f1d3;';
        }
      }
      return '';
    }
  };

  //*Control de Flujo del Grid

  escapeProducto(e: any) {
    let index = this.indexEditing;
    this.esEscapeProducto = true;
    let item: CompraConcepto = this.source[index];
    e.target.value = item.Producto?.Clave ? item.Producto?.Clave : "";
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.source[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoProducto = true;
        this.sendFocus();
      } else {
        if (this.getMostrarVehiculo) {// si hay vehiculo deb de regresar el foco al check mantenimiento
          const txt: any = this.chkMantenimiento;
          txt.tagInput.nativeElement.focus()
        } else { // si no hay vehiculo a el no de guia.
          const txt: any = this.txtNoDeGuia;
          txt.tagInput.nativeElement.focus()
        }
      }
    }, 50);
  }

  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: CompraConcepto = this.source[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : 0;
    this.initEditor(this.indexEditing, this.colProducto);
  }

  escapeCosto(e: any) {
    this.esEscapeCosto = true;
    let item: CompraConcepto = this.source[this.indexEditing];
    e.target.value = item.CostoUnitario ? item.CostoUnitario : 0;
    this.initEditor(this.indexEditing, this.colCantidad);
  }

  escapeDescuento(e: any) {
    this.esEscapeDescuento = true;
    let item: CompraConcepto = this.source[this.indexEditing];
    e.target.value = item.DescuentoPorcentaje ? item.DescuentoPorcentaje : 0;
    this.initEditor(this.indexEditing, this.colCosto);
  }

  escapePaquete(e: any) {
    this.esEscapeLotePaquete = true;
    let item: CompraConcepto = this.source[this.indexEditing];
    e.target.value = item.NumeroLotePaquete ? item.NumeroLotePaquete : "";
    this.initEditor(this.indexEditing, this.colDescuento);
  }

  escapeSubTotal(e: any) {
    this.esEscapeSubTotal = true;
    let item: CompraConcepto = this.source[this.indexEditing];
    e.target.value = item.Total ? item.Total : 0;
    this.initEditor(this.indexEditing, this.colDescuento);
  }


  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }
  enterCantidad(e: any) {
    if (e.target.value == "" || parseInt(e.target.value) == 0) {
      return;
    }
    this.enviarFocoCosto = true;
    e.target.blur();
  }

  enterCosto(e: any) {
    this.enviarFocoDescuento = true;
    e.target.blur();
  }

  enterDescuento(e: any) {
    let item: CompraConcepto = { ...this.source[this.indexEditing] };
    if (item.ManejaPaqueteLote) {
      this.enviarFocoPaqueteLote = true;
    } else {
      this.enviarFocoSubtotal = true;
    }
    e.target.blur();
  }

  enterPaquete(e: any) {
    this.enviarFocoSubtotal = true;
    e.target.blur();
  }

  enterSubTotal(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  focusProducto(index: number, e: any, item: CompraConcepto) {
    let c = this.getLastItem();
    if (index > 0 && index > (c)) {
      return;
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  blurProducto(e: any, index: number) {
    e.target.classList.remove("focus-editor-grid");
    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let c = this.getLastItem();
      if (index > c) {
        return;
      }
      let item: CompraConcepto = { ...this.source[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }
        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.esEnterProducto) {
            if (this.enviarFocoCantidad) {
              this.sendFocus();
            }
          } else {
            if (this.enviarOtroRenglon) { //*aplica para el renglon 1
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 50);
  }



  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarFocoCosto || this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (cantidad != totalEnConversiones) {
          item.Cantidad = totalEnConversiones;
        } else {
          item.Cantidad = cantidad;
        }
      } else {
        item.Cantidad = cantidad;
        item.Conversiones = [];
        item.TextoConversiones = "";
      }
    } else {
      item.Cantidad = cantidad;
      item.Conversiones = [];
      item.TextoConversiones = "";
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
    this.calcTax();
  }



  blurDescuento(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeDescuento) {
      this.esEscapeDescuento = false;
      return;
    }
    // if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterDescuento) {
    //   e.target.classList.remove("focus-editor-grid");
    //   return;
    // }
    const descuento = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.DescuentoPorcentaje)) == descuento) {
      if (item.ManejaPaqueteLote || this.enviarFocoSubtotal || this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };

    let costoActual = item.CostoUnitario;
    if (item.CostoUnitarioAntesDescuento > 0) {
      costoActual = item.CostoUnitarioAntesDescuento;
    }
    let valUnitario: number = 0;
    let costoUnitarioAntesDescuento = 0;
    let porcentajeDescuento: number = 0;
    let importeDescuento: number = 0;
    if (descuento > 0) {
      costoUnitarioAntesDescuento = costoActual;
      porcentajeDescuento = descuento;
      valUnitario = parseFloat((costoActual * (1 - (descuento / 100))).toFixed(2));
      importeDescuento = costoActual - valUnitario;
    } else {
      costoUnitarioAntesDescuento = 0;
      valUnitario = item.CostoUnitarioAntesDescuento;
      porcentajeDescuento = 0;
      importeDescuento = 0;
    }
    item.DescuentoPorcentaje = descuento;
    this.source[this.indexEditing].CostoUnitario = valUnitario;
    this.source[this.indexEditing].CostoUnitarioAntesDescuento = costoUnitarioAntesDescuento;
    this.source[this.indexEditing].DescuentoPorcentaje = porcentajeDescuento;
    this.source[this.indexEditing].DescuentoImporte = importeDescuento;
    this.source = [...this.source];
    this.sendFocus();
    this.calcTax(false);


    // if (this.columnEditing == 3 || this.columnEditing == 6 || this.columnEditing == 8) {
    //   this.calcTax(this.columnEditing == 6 && e.after.ValorUnitario != e.before.ValorUnitario);
    // }


    // this.source[this.indexEditing] = { ...item };
    // this.source = [...this.source];

  }

  blurCosto(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCosto) {
      this.esEscapeCosto = false;
      return;
    }
    const costo = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.CostoUnitario)) == costo) {
      if (this.enviarFocoDescuento || this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.CostoUnitario = costo;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
    this.calcTax();
  }

  blurPaquete(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeLotePaquete) {
      this.esEscapeLotePaquete = false;
      return;
    }

    const lote: string = e.target.value;
    let item: CompraConcepto = this.source[this.indexEditing];
    if (item.NumeroLotePaquete == lote) {
      if (this.enviarFocoSubtotal || this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.NumeroLotePaquete = lote;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  blurSubtotal(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeSubTotal) {
      this.esEscapeSubTotal = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterSubTotal) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const total = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Total)) == total) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.Total = total;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  buscarProducto(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value)) {
      this.cords = null;
      this.sourceProducts = [];
      return;
    }
    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;
    //todo si no ha seleccionado el tipode movimineto o el folio
    this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProducts = lista;
      if (this.sourceProducts.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }

  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoCantidad = true;
    }
  }



  navegarProductos(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }





  //* Metodos Genericos del Grid


  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }

    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 1 && this.sourceProducts.length > 0) {
      return;
    }

    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      this.enviarOtroRenglon = false;
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.enviarOtroRenglon = true;
        e.target.blur();
      }
    }
  }








  sendFocus() {
    setTimeout(() => {
      let cc = 0;
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
        cc++;
      }

      if (this.enviarFocoProducto) {
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarFocoProducto = false;
        cc++;
      }

      if (this.enviarFocoCosto) {
        this.initEditor(this.indexEditing, this.colCosto);
        this.enviarFocoCosto = false;
        cc++;
      }

      if (this.enviarFocoDescuento) {
        this.initEditor(this.indexEditing, this.colDescuento);
        this.enviarFocoDescuento = false;
        cc++;
      }

      if (this.enviarFocoPaqueteLote) {
        this.initEditor(this.indexEditing, this.colPaqueteLote);
        this.enviarFocoPaqueteLote = false;
        cc++;
      }

      if (this.enviarFocoSubtotal) {
        this.initEditor(this.indexEditing, this.colSubTotal);
        this.enviarFocoSubtotal = false;
        cc++;
      }



      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarOtroRenglon = false;
        cc++;
      }


      if (cc > 1) {
        let ff = 0;

      }









    }, 50);
  }

  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }


  getLastItem(): number {
    return this.source.filter(P => P.Producto).length;
  }

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  setIndexEdit(index: number, e: any, item: CompraConcepto, col: number | null = null) {


    if (col) {
      if (col == 9) // columna paquete o lote
        if (!item.ManejaPaqueteLote) {
          e.target.blur();
          return;
        }
    }


    // this.indexEditing = index;
    // e.target.select();
    // e.target.classList.add("focus-editor-grid");
    // return;

    const countAnticipo = this.getCountAnticipo();
    if (!item.NoIdentificacion && countAnticipo == 1) {
      return;
    }

    if (this.myForm.value.FechaCancelacion) {
      return;
    }

    if (this.myForm.value.Proveedor?.Id == 0 || !this.myForm.value.Proveedor) {
      this.eventsService.publish('home:showAlert', {
        message: 'Primero indique un Proveedor.',
        cancelButton: false,
        onConfirm: (data: AlertResponse) => {
          // this.esEscapeProducto = true;
          // this.focusCliente();
          setTimeout(() => {
            this.focusCliente();
          }, 100);
        }
      });
      return;
    }

    if (index > 0) {
      if (!this.source[index - 1].Producto) {
        return;
      }
      if (this.source[index - 1].Producto!.Id == 0) {
        return;
      }
    }

    const docto: ComboBoxEntity = this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoCompra.Id)[0];
    if (docto.ObligarCopiar) {
      if (!this.source[index].Producto || this.source[index].Producto?.Id == 0) {
        this.eventsService.publish('home:showAlert', { message: `No se pueden agregar Productos, debe de copiar.`, cancelButton: false });
        return;
      }
    }

    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }




  // /*Grid events */
  // cellEditing: GuiCellEdit = {
  //   enabled: true,
  //   rowEdit: (value: any, item: CompraConcepto, index: number) => {
  //     this.indexEditing = -1;

  //     const countAnticipo = this.getCountAnticipo();
  //     if (!item.NoIdentificacion && countAnticipo == 1) {
  //       return false;
  //     }

  //     if (this.myForm.value.FechaCancelacion) {
  //       return false;
  //     }

  //     if (this.myForm.value.Proveedor?.Id == 0 || !this.myForm.value.Proveedor) {
  //       this.eventsService.publish('home:showAlert', {
  //         message: 'Primero indique un Proveedor.',
  //         cancelButton: false,
  //         onConfirm: (data: AlertResponse) => {
  //           this.focusCliente();
  //         }
  //       });
  //       return false;
  //     }

  //     if (index > 0) {
  //       if (!this.source[index - 1].Producto) {
  //         return false;
  //       }
  //       if (this.source[index - 1].Producto!.Id == 0) {
  //         return false;
  //       }
  //     }

  //     const docto: ComboBoxEntity = this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoCompra.Id)[0];
  //     if (docto.ObligarCopiar) {
  //       if (!this.source[index].Producto || this.source[index].Producto?.Id == 0) {
  //         this.eventsService.publish('home:showAlert', { message: `No se pueden agregar Productos, debe de copiar.`, cancelButton: false });
  //         return false;
  //       }
  //     }
  //     // debugger
  //     // if (this.source[index].Producto) {
  //     //   if (!this.source[index].Producto?.ManejaPaquetesLotes) {
  //     //     return false;
  //     //   }
  //     // }

  //     this.indexEditing = index;
  //     if (index == 0) {
  //       return true;
  //     }

  //     return true;
  //   },

  //   cellEdit: (value: any, item: any, indexCell: number) => {
  //     return true;
  //   }
  // }

  source: Array<CompraConcepto> = [];

  changueDescription(i: number, item: CompraConcepto) {
    if (item.Producto?.ActualizarDescrpcion) {
      this.indexEditing = i;
      this.showDescription = true;
      setTimeout(() => {
        //this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);
        this.txtDescription.nativeElement.focus();
      }, 150);
    }
  }

  focusCliente() {
    const txt: any = this.txtProveedor;
    txt.tagInput.nativeElement.focus()
  }

  sourceEdited(e: any) {

    if (this.columnEditing == 1) {

      if ((e.after.NoIdentificacion != e.before.NoIdentificacion) && e.after.NoIdentificacion) {
        this.searchProduct(e.after.NoIdentificacion);
      } else {
        if (e.after.NoIdentificacion) {

          this.navigateColumns && this.initEditor(this.indexEditing, 3);

        } else {
          const el = this.source[this.indexEditing];
          if (el.Cantidad > 0) {
            this.source.splice(this.indexEditing, 1);
            this.source = [...this.source];
            this.calcTax();
          }
        }
      }
    }

    if (this.columnEditing == 3) {
      if (e.after.Cantidad != e.before.Cantidad) {
        let item = this.source[this.indexEditing];
        if (item.Conversiones) {
          if (item.Conversiones.length > 0) {
            let totalEnConversiones = 0;
            item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
            if (e.after.Cantidad != totalEnConversiones) {
              item.Cantidad = e.before.Cantidad;
            }
          } else {
            item.Conversiones = [];
            item.TextoConversiones = "";
          }
        } else {
          item.Conversiones = [];
          item.TextoConversiones = "";
        }
        // item.Conversiones = e.items;
        // item.TextoConversiones = e.conversiones;
        // item.Cantidad = e.cantidad;
      }
    }

    //Validación de los candados de precios
    // if (this.columnEditing == 6) {
    //   if (e.after.CostoUnitario != e.before.CostoUnitario) {
    //     // let min: number = this.source[this.indexEditing].Producto!.PrecioMinimo;
    //     let costoOriginal: number = this.source[this.indexEditing].Producto!.Precio;
    //     let porcentajeDescuento: number = 0;
    //     let importeDescuento: number = 0;
    //     // if (e.after.CostoUnitario < min) {
    //     //   this.source[this.indexEditing].CostoUnitario = e.before.CostoUnitario;
    //     //   this.source[this.indexEditing].DescuentoPorcentaje = 0;
    //     //   this.source[this.indexEditing].ImporteAntesDescuento = 0;
    //     // } else if ((e.after.CostoUnitario != costoOriginal) && costoOriginal > 0) {
    //     let dif: number = costoOriginal - e.after.ValorUnitario;
    //     if (dif > 0) {
    //       porcentajeDescuento = parseFloat(((dif / costoOriginal) * 100).toFixed(2));
    //       importeDescuento = parseFloat((costoOriginal * (1 - (porcentajeDescuento / 100))).toFixed(2));
    //     } else {
    //       porcentajeDescuento = 0;
    //       importeDescuento = 0;
    //     }
    //     this.source[this.indexEditing].DescuentoPorcentaje = porcentajeDescuento;
    //     this.source[this.indexEditing].ImporteAntesDescuento = importeDescuento;
    //     // }
    //     this.source = [...this.source];
    //   }
    // }
    if (this.columnEditing == 8) {
      if (e.after.DescuentoPorcentaje != e.before.DescuentoPorcentaje) {
        let precioActual = e.after.CostoUnitario;
        if (e.after.CostoUnitarioAntesDescuento > 0) {
          precioActual = e.after.CostoUnitarioAntesDescuento;
        }
        let valUnitario: number = 0;
        let costoUnitarioAntesDescuento = 0;
        let porcentajeDescuento: number = 0;
        let importeDescuento: number = 0;
        if (e.after.DescuentoPorcentaje > 0) {
          costoUnitarioAntesDescuento = precioActual;
          porcentajeDescuento = e.after.DescuentoPorcentaje;
          valUnitario = parseFloat((precioActual * (1 - (e.after.DescuentoPorcentaje / 100))).toFixed(2));
          importeDescuento = e.before.Producto.Precio - valUnitario;
        } else {
          costoUnitarioAntesDescuento = 0;
          valUnitario = e.after.CostoUnitarioAntesDescuento;
          porcentajeDescuento = 0;
          importeDescuento = 0;
        }

        this.source[this.indexEditing].CostoUnitario = valUnitario;
        this.source[this.indexEditing].CostoUnitarioAntesDescuento = costoUnitarioAntesDescuento;
        this.source[this.indexEditing].DescuentoPorcentaje = porcentajeDescuento;
        this.source[this.indexEditing].DescuentoImporte = importeDescuento;
        this.source = [...this.source];
      }
    }

    if (this.columnEditing == 3 || this.columnEditing == 6 || this.columnEditing == 8) {
      this.calcTax(this.columnEditing == 6 && e.after.ValorUnitario != e.before.ValorUnitario);
    }

  }

  cellEditSubmitted() {
    if (this.navigateColumns) {
      switch (this.columnEditing) {
        case 3:
          this.initEditor(this.indexEditing, 6);
          break;
        case 6:
          this.initEditor(this.indexEditing, 8);
          break;
        case 8:
          if (this.source[this.indexEditing].Producto?.ManejaPaquetesLotes) {
            this.initEditor(this.indexEditing, 9);
          } else {
            this.indexEditing++; this.initEditor(this.indexEditing, 1);
          }
          break;
        case 9: this.indexEditing++; this.initEditor(this.indexEditing, 1); break;
      }
      this.navigateColumns = false;
    }
  }

  cellEditEntered(e: any) {
    setTimeout(() => {
      this.setColumnEditor();
    }, 100);
  }

  setColumnEditor() {
    const elems: any = document.getElementById("divcompras")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement, indexRow: number) => {
      renglon.childNodes.forEach((nodeRow: any) => {
        let colNum = -1;
        nodeRow.childNodes.forEach((nodeColumna: any) => {
          if (nodeColumna.childNodes && nodeColumna.className) {
            colNum++;
            nodeColumna.childNodes.forEach((nodoCelda: any) => {
              if (nodoCelda.className == "gui-cell-edit-mode ng-star-inserted") {
                this.columnEditing = colNum;//parseInt(col);
                const elem: any = nodeColumna.childNodes[1].childNodes[0];
                elem.addEventListener("keydown", (e: any) => {

                  this.navigateColumns = (e.keyCode == 13);
                  if (this.columnEditing == 1 && e.keyCode == 113) {
                    this.openProductsSearch();
                  }

                  // if (this.columnEditing == 3 && e.keyCode == 113) {
                  //   this.openExistencias();
                  // }

                  if (e.keyCode == 27) {
                    switch (this.columnEditing) {
                      case 6: this.initEditor(this.indexEditing, 3); break;
                      case 5: this.initEditor(this.indexEditing, 3); break;
                      case 3: this.initEditor(this.indexEditing, 1); break;
                      case 9: this.initEditor(this.indexEditing, 8); break;
                      case 8: this.initEditor(this.indexEditing, 6); break;
                      case 1:
                        if (this.indexEditing >= 1) {
                          this.initEditor(this.indexEditing - 1, 1);
                        }
                        break;
                    }
                  }

                });
              }
            });
          }
        });
      })
    });
    if (this.columnEditing > 1) {
      if (!this.source[this.indexEditing].Producto || this.source[this.indexEditing].Producto?.Id == 0) {
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  // initEditor(row: number, col: number) {
  //   const elem: any = this.getElemEditor(row, col);
  //   if (elem) {
  //     setTimeout(() => {
  //       elem.firstElementChild?.click();
  //     }, 250);
  //   }
  // }
  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  getElemEditor(row: number, col: number) {

    let elem: any = null;
    const elems: any = document.getElementById("divcompras")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement) => {
      renglon.childNodes.forEach((nodeRow: any, indexRow: number) => {
        if (indexRow == row && !elem) {
          let colNum = -1;
          nodeRow.childNodes.forEach((nodeColumna: any) => {
            if (nodeColumna.childNodes && nodeColumna.className && !elem) {
              colNum++;
              if (col == colNum) {
                elem = nodeColumna;
              }
            }
          });
        }
      })
    });
    return elem;
  }
  /**/

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.fServices.utilizaSegundoNombre(this.info.sucursal!.numero).subscribe((usa) => {
      this.utilizaSegundoNombre = usa;
    })
    this.sourceReset();
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.getComps();

  }

  changueTitle(title: string) {
    this.eventsService.publish('home:changeTabTitle', { tabTitle: `Emitiendo => [${title}]`, url: "compras" });
  }

  sourceReset(conceptos: any = []) {
    this.source = conceptos;
    for (let index = 0; index < 300; index++) {
      this.source = [...this.source, {
        Id: 0,
        LlevaBackOrder: false,
        AfectaBackOrder: false,
        IdDetalleOrdenComproOrdenado: 0,
        IdDetalleOrdenCompra: 0,
        IdOrdenCompra: 0,
        Producto: null,
        Cantidad: 0,
        CantidadRestante: 0,
        CantidadEntregada: 0,
        NoIdentificacion: '',
        Unidad: null,
        UnidadNombre: '',
        ClaveProdServ: '',
        ClaveUnidad: '',
        ObjetoImpuesto: '',
        NumeroLotePaquete: '',
        NumeroPedimento: '',
        Descripcion: '',
        DescripcionConvesion: '',
        CostoUnitario: 0,
        CostoReposicion: 0,
        PorcentajeUtilidad: 0,
        CostoUltimaCompra: 0,
        CostoProrreateado: 0,
        CostoFinal: 0,
        CostoUnitarioAntesDescuento: 0,
        DescuentoPorcentaje: 0,
        DescuentoImporte: 0,
        Importe: 0,
        ImporteAntesDescuento: 0,
        TotalIvaTrasladado: 0,
        TotalIvaRetenido: 0,
        TotalIsrRetenido: 0,
        TotalIeps: 0,
        Total: 0,
        Impuestos: null,
        Conversiones: null,
        Orden: 0,
        TextoConversiones: '',
        ManejaPaqueteLote: false
      }]

    }
  }

  get getAgregados(): string {
    if (this.myForm.value.UUIDFactura) {
      return this.myForm.value.UUIDFactura;
    }
    if (this.myForm.value.UUIDNotaCredito) {
      return this.myForm.value.UUIDNotaCredito;
    }
    return "";
  }

  get gettotalCargoProrrateado(): number {
    let totalImporteProrratear = 0;
    if (this.source) {
      this.source.filter(P => P.Producto?.SeProrrateaEnCompras && !P.Producto?.Inventariable).forEach((i) => {
        totalImporteProrratear += i.Importe;
      });
      let cargos = this.myForm.value.CargosAdicionales;
      if (cargos) {
        cargos.forEach((item: CompraCargoAdicional) => {
          if (!item.NoProrratear) {
            totalImporteProrratear += parseFloat(String(item.Subtotal));
          }
        })
      }
    }
    return totalImporteProrratear;
  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  get getItemsComps(): ComboBoxEntity[] {
    return this.listaComprobantes;
  }

  get getEntity(): Compra {
    return this.myForm.value;
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getFilterClase(): string {
    let filter = "";
    // if (this.myForm.value.Cliente) {
    //   if (this.myForm.value.Cliente.Id > 0) {
    //     if (this.myForm.value.Cliente.ClaseVenta?.Id > 0) {
    //       filter += ` Id = ${this.myForm.value.Cliente.ClaseVenta?.Id} `;
    //     }
    //     if (this.myForm.value.Cliente.ClaseVenta2?.Id > 0) {
    //       filter += ` or Id = ${this.myForm.value.Cliente.ClaseVenta2?.Id} `;
    //     }
    //     if (this.myForm.value.Cliente.ClaseVenta3?.Id > 0) {
    //       filter += ` or Id = ${this.myForm.value.Cliente.ClaseVenta3?.Id} `;
    //     }
    //   }
    // }
    return filter;
  }

  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }


  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getEstadoSAT(): CfdiEstadoSAT | null {
    return this.estadoSAT;
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }


  calcTax(desglosar: boolean = false) {
    let ent: Compra = this.myForm.value;
    ent.SubTotal = 0;
    ent.SubTotalGrabaIvaTrasladado = 0;
    ent.SubTotalGrabaIvaExento = 0;
    ent.SubTotalGrabaIvaCero = 0;
    ent.TotalIvaTrasladado = 0;
    ent.TotalIvaRetenido = 0;
    ent.TotalIsrRetenido = 0;
    ent.Total = 0;
    let totalConcepto: number = 0, subTotalVenta = 0, totalConceptos = 0;
    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          if (concepto.CostoProrreateado == 0 || !concepto.CostoProrreateado) {
            concepto.CostoFinal = concepto.CostoUnitario;
          } else {
            concepto.CostoFinal = concepto.CostoUnitario + concepto.CostoProrreateado;
          }
          const imp = concepto.Producto!.Impuesto;
          if (concepto.CostoUnitario > 0) {
            concepto.TotalIvaTrasladado = 0;
            concepto.TotalIvaRetenido = 0;
            concepto.TotalIsrRetenido = 0;
            concepto.Total = 0;
            if (!concepto.Impuestos) {
              concepto.Impuestos = [];
            }
            concepto.Importe = parseFloat((concepto.Cantidad * concepto.CostoUnitario).toFixed(2));
            totalConcepto = concepto.Importe;


            if (imp) {
              //#region Calculo de IVA Trasladado
              if (imp.IVATrasladado) {
                let propIVA: number = 0;

                // if (tieneIVaRetIncluido) {
                //   propIVA = propIVA - _impuestoActual.IVARetenido.Tasa;
                // }

                // if (tieneISRRetIncluido) {
                //   propIVA = propIVA - _impuestoActual.ISRRetenido.Tasa;
                // }

                // if (propIVA > 0 && _impuestoActual.IEPSTrasladado == null) {
                //   propIVA = propIVA / 100;
                //   ctlPrecioBase.Text = (precioBaseEscrito / propIVA).ToString();
                //   ctrlImporte.Text = (decimal.Parse(ctlPrecioBase.Text) * cantidad).ToString("");
                // }

                if (imp.IncluidoEnPrecioIvaTrasladado) {
                  propIVA = 100 + (imp.IVATrasladado.TasaCuota);
                }

                if (propIVA > 0 && desglosar) {
                  propIVA = propIVA / 100;
                  concepto.CostoUnitario = parseFloat((concepto.CostoUnitario / propIVA).toFixed(6));
                  concepto.Importe = parseFloat((concepto.Cantidad * concepto.CostoUnitario).toFixed(6));
                  totalConcepto = concepto.Importe;
                  subTotalVenta += concepto.Importe;
                } else {
                  subTotalVenta += concepto.Importe;
                }

                const tipoImpuesto = imp.IVATrasladado.Factor.Clave == "3" ? "EXENTO" : "IVA";
                const impIvaExists = concepto.Impuestos.filter(P => P.TipoImpuesto == tipoImpuesto && P.TasaOCuota == imp.IVATrasladado.TasaCuota);
                let idImpIva = 0;
                if (impIvaExists.length > 0) {
                  idImpIva = impIvaExists[0].Id;
                  concepto.Impuestos = concepto.Impuestos.filter(P => P.Id != idImpIva);
                }

                let impIva: CompraConceptoImpuesto = {
                  Id: idImpIva,
                  TipoImpuesto: tipoImpuesto,
                  Importe: parseFloat((concepto.Importe * (imp.IVATrasladado.TasaCuota / 100)).toFixed(3)),
                  TasaOCuota: imp.IVATrasladado.TasaCuota,
                  TipoFactor: imp.IVATrasladado.Factor,
                  Impuesto: imp,
                  Base: concepto.Importe,
                }; concepto.Impuestos = [...concepto.Impuestos, impIva];

                concepto.ObjetoImpuesto = "02";
                concepto.TotalIvaTrasladado += impIva.Importe;

                totalConcepto += concepto.TotalIvaTrasladado;

                if (imp.IVATrasladado.Factor.Clave != "3") {
                  if (imp.IVATrasladado.TasaCuota > 0) {
                    ent.SubTotalGrabaIvaTrasladado += concepto.Importe;
                  } else {
                    ent.SubTotalGrabaIvaCero += concepto.Importe;
                  }
                } else {
                  ent.SubTotalGrabaIvaExento += concepto.Importe;
                }
                ent.TotalIvaTrasladado += concepto.TotalIvaTrasladado;

              }

              //#endregion

              //#region Calculo del IVA Retenido
              if (imp.IVARetenido) {
                if (!imp.IncluidoEnPrecioIVARetenido) {
                  let impIvaRet: CompraConceptoImpuesto = {
                    Id: 0,
                    TipoImpuesto: "IVARET",
                    Importe: parseFloat((concepto.Importe * (imp.IVARetenido.TasaCuota / 100)).toFixed(2)),
                    TasaOCuota: imp.IVARetenido.TasaCuota,
                    TipoFactor: imp.IVARetenido.Factor,
                    Impuesto: imp,
                    Base: concepto.Importe,
                  };

                  concepto.Impuestos = [...concepto.Impuestos, impIvaRet];
                  concepto.ObjetoImpuesto = "02";
                  concepto.TotalIvaRetenido += impIvaRet.Importe;

                  totalConcepto -= concepto.TotalIvaRetenido;

                  ent.SubTotalGrabaIvaRetenido += concepto.Importe;
                  ent.TotalIvaRetenido += concepto.TotalIvaRetenido;
                }
              }
              //#endregion

              //#region Calculo del ISR Retenido
              if (imp.ISRRetenido) {
                if (!imp.IncluidoEnPrecioISRRetenido) {
                  let impIsrRet: CompraConceptoImpuesto = {
                    Id: 0,
                    TipoImpuesto: "ISRRET",
                    Importe: parseFloat((concepto.Importe * (imp.ISRRetenido.TasaCuota / 100)).toFixed(2)),
                    TasaOCuota: imp.ISRRetenido.TasaCuota,
                    TipoFactor: imp.ISRRetenido.Factor,
                    Impuesto: imp,
                    Base: concepto.Importe,
                  };


                  concepto.Impuestos = [...concepto.Impuestos, impIsrRet];
                  concepto.ObjetoImpuesto = "02";
                  concepto.TotalIsrRetenido += impIsrRet.Importe;

                  totalConcepto -= concepto.TotalIsrRetenido;

                  ent.SubTotalGrabaIsrRetenido += concepto.Importe;
                  ent.TotalIsrRetenido += concepto.TotalIsrRetenido;
                }
              }
              //#endregion


              //ent.SubTotal += parseFloat(subTotalVenta.toFixed(2));
              concepto.Total += this.round(totalConcepto);
              totalConceptos += totalConcepto;
              //ent.Total += parseFloat(totalConcepto.toFixed(2));
            }
          } else {
            concepto.Impuestos = [];
          }
        }
      }
    });
    ent.SubTotal = this.round(subTotalVenta);
    ent.Total = this.round(totalConceptos);
    let totalImporteNormal = 0;
    let totalImporteProrratear = 0;
    this.source.filter(P => !P.Producto?.SeProrrateaEnCompras && P.Producto?.Inventariable).forEach((i) => {
      totalImporteNormal += i.Importe;
    });

    this.source.filter(P => P.Producto?.SeProrrateaEnCompras && !P.Producto?.Inventariable).forEach((i) => {
      totalImporteProrratear += i.Importe;
    });
    let cargos = this.myForm.value.CargosAdicionales;
    if (cargos) {
      cargos.forEach((item: CompraCargoAdicional) => {
        if (!item.NoProrratear) {
          totalImporteProrratear += parseFloat(String(item.Subtotal));
        }
      })
    }
    if (totalImporteProrratear > 0) {
      this.source.map((concepto, index) => {
        if (concepto) {
          if (concepto.Producto) {
            if (!concepto.Producto.SeProrrateaEnCompras && concepto.Producto.Inventariable) {
              const cant = concepto.Cantidad;
              if (cant != 0) {
                let prorrateado = ((((concepto.Importe * 100) / totalImporteNormal) / 100) * totalImporteProrratear) / cant;
                concepto.CostoProrreateado = parseFloat(prorrateado.toFixed(4));
                concepto.CostoFinal = parseFloat(String(concepto.CostoUnitario)) + concepto.CostoProrreateado;
                console.log(concepto)
              }
            }
          }
        }
      });
    } else {
      this.source.map((item) => {
        item.CostoProrreateado = 0;
        item.CostoFinal = item.CostoUnitario;
        return item;
      });
    }
    setTimeout(() => {
      this.myForm.reset(ent);
      this.source = [...this.source];
    }, 100);

  }

  round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  cellFormatter(v: any, column: string) {

  }

  searchProduct(value: string) {
    if (!value) {
      if (this.myForm.value.Total > 0 && this.navigateColumns) {
        this.save();
      }
      return;
    };

    if (!this.myForm.value.Proveedor) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el Proveedor.", cancelButton: false });
      return;
    }

    this.loading = true;
    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero)
      .set("idSucursal", this.info.sucursal!.numero)
      .set("idCliente", 0)
      .set("esCompra", true)
      .set("clave", value)
      .set("obtenerUltimoCosto", true);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        if (!producto.UnidadCompra) {
          this.eventsService.publish('home:showAlert', { message: "El Producto no tiene una Unidad de Compra asignada, verifique.", cancelButton: false });
          return;
        }
        let item = this.source[this.indexEditing];
        let idsProveedores = "";
        if (producto.ProveedorCompraId > 0) { idsProveedores += `,${producto.ProveedorCompraId},`; }
        if (producto.ProveedorCompraId2 > 0) {
          if (idsProveedores.length > 0) {
            idsProveedores += `${producto.ProveedorCompraId2},`;
          } else {
            idsProveedores += `,${producto.ProveedorCompraId2},`;
          }
        }

        if (idsProveedores.length > 0) {
          if (!idsProveedores.includes(`,${this.myForm.value.Proveedor.Id},`)) {
            this.eventsService.publish('home:showAlert', { message: `El Producto no pertenece al proveedor ${this.myForm.value.Proveedor.Nombre}. verifique con departamento de Compras.`, cancelButton: false });
            return;
          }
        }


        item.Producto = producto;
        item.Orden = this.indexEditing;
        item.NoIdentificacion = producto.Clave;
        if (!this.utilizaSegundoNombre) {
          item.Descripcion = producto.Nombre;
        } else {
          item.Descripcion = producto.SegundoNombre;
        }
        item.Cantidad = 0;
        item.DescuentoPorcentaje = 0;
        item.DescuentoImporte = 0;
        item.CostoUltimaCompra = producto.UltimoCosto;
        item.CostoUnitario = producto.UltimoCosto;
        item.Unidad = producto.UnidadCompra;
        item.UnidadNombre = producto.UnidadCompra.Nombre;
        item.ClaveUnidad = producto.UnidadCompra.Clave;
        item.ClaveProdServ = producto.ClaveSAT;
        this.sourceProducts = [];
        item.ManejaPaqueteLote = producto.ManejaPaquetesLotes;
        if (producto.ManejaPaquetesLotes) {
          const ent: Compra = this.myForm.value;
          if (ent.FechaFacturaProveedor && ent.FacturaProveedor) {
            item.NumeroLotePaquete = `${moment(ent.FechaFacturaProveedor).format("YYYYMMDD")}-${ent.FacturaProveedor}-${ent.Proveedor.Clave}-${item.Orden}`;
          }
        }

        this.source = [...this.source];
        this.showDescription = producto.ActualizarDescrpcion;
        this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);
        if (!producto.ActualizarDescrpcion) {
          if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0 && producto.AplicaCompras) {
            this.conversiones = producto.OtrasUnidades;
            this.unidadFinal = item.UnidadNombre;
            this.enviarFocoCantidad = false;
            this.openConvertions((e: any) => this.aceptConversions(e));
          } else {
            this.conversiones = [];
            this.unidadFinal = '';
            //this.initEditor(this.indexEditing, 3);
            this.sendFocus()
          }
        } else {
          this.navigateColumns = true;
          setTimeout(() => {
            this.txtDescription.nativeElement.focus();
          }, 150);
        }
        this.calcTax();
      } else {

        this.eventsService.publish('home:showAlert', {
          message: 'No se encontró el Producto indicado: No existe o está dado de baja.',
          cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacion = "";
            this.source = [...this.source];
            this.enviarFocoProducto = true;
            this.sendFocus();
            //this.initEditor(this.indexEditing, 1);
          }
        });
      }
    })
  }

  openCfdi(esNc: boolean = false) {
    if (!this.myForm.value.Proveedor) {
      this.eventsService.publish('home:showAlert', { message: `Primero debe de indicar el Proveedor.`, cancelButton: false });
      return;
    }
    const b: any = this.ctrlAgregarCfdi;
    this.ms.openModal(b, (e: DescargaCfdi[]) => {
      this.onCloseCfdi(e, esNc);
    });
  }
  onCloseCfdi(lista: DescargaCfdi[] | null, esNc: boolean) {
    if (lista) {
      if (lista.length > 0) {
        if (lista.length > 1) {
          this.eventsService.publish('home:showAlert', { message: `Solo debe de seleccionar un CFDI.`, cancelButton: false });
          return;
        }
        const i: DescargaCfdi = lista[0];
        this.myForm.controls[!esNc ? "UUIDFactura" : "UUIDNotaCredito"].setValue(i.uuid);
        // this.setCfdi(i);
      }
    }
  }

  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        item.Conversiones = e.items;
        item.TextoConversiones = e.conversiones;
        item.Cantidad = e.cantidad;
        this.source = [...this.source];
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
      }
      this.calcTax();
      if (!isclick) {
        if (e.cantidad == 0) {
          this.enviarFocoCantidad = true;
        } else {
          this.enviarFocoCosto = true;
        }
        this.sendFocus();
      }
    } else {
      this.source = [...this.source];
      if (!isclick) {
        this.enviarFocoCantidad = true;
      }
      this.sendFocus();
    }
  }

  acceptDescription() {
    this.source[this.indexEditing].Descripcion = this.myForm.value.DescripcionAdicional;
    this.showDescription = false;
    this.source = [...this.source];
    this.enviarFocoCantidad = true;
    this.sendFocus();
    //this.navigateColumns && this.initEditor(this.indexEditing, 3);

  }

  cancelDescription() {
    this.showDescription = false;
    this.enviarFocoProducto = true;
    this.sendFocus();
    //this.navigateColumns && this.initEditor(this.indexEditing, 1);
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
    }
  }

  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  findEntityByParamsNavigate(type: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("idDoct", this.myForm.value.DocumentoCompra.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.http.get<Compra>(`${this.baseUrl}/Compras/ObtenerCompraPorParametrosNavegacion`, { params }).subscribe((venta) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(venta);
    });
  }

  setEntity(venta: Compra) {
    this.cargoEditar = null;
    if (!venta) {
      this.new();
    } else {
      let ent = this.myForm.value;
      ent = { ...venta };
      this.sourceReset(ent.Conceptos);
      ent.Conceptos.map((item: CompraConcepto) => {
        if (item.Unidad) {
          item.UnidadNombre = item.Unidad!.Nombre;
        } else {
          item.UnidadNombre = '';
        }
      });
      if (ent.Proveedor) {
        this.getCustomerAdvanceBalance(ent.Empresa.Id, ent.Proveedor.Id);
        const txt: any = this.txtProveedor;
        this.setAdress(ent.Proveedor.Direccion);
        txt.tagInput.nativeElement.value = venta.Proveedor.Clave;
      }
      this.myForm.reset(ent);
    }
  }

  deleteRow(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.source.splice(index, 1);
          this.source = [...this.source];
          this.calcTax();
        }
      }
    });


  }

  new() {
    this.anticipoDisponible = 0;
    this.blockCombos = false;
    const ent: Compra = this.myForm.value;
    this.getEmptyEntity(ent.Serie.Id, ent.DocumentoCompra.Id);
    this.sourceReset();
    const txt: any = this.txtProveedor;
    txt.tagInput.nativeElement.value = '';
  }

  enterGuia(esMto: boolean) {
    if (!this.getMostrarVehiculo) {
      this.initEditor(0, 2);
    } else if (esMto) {
      this.initEditor(0, 2);
    }
  }

  get getFiltroConsignado(): string {
    if (this.myForm.value.Proveedor) {
      if (this.myForm.value.Proveedor.Id > 0) {
        return `IdProveedorPertenece = ${this.myForm.value.Proveedor.Id}`;
      }
    }
    return `IdProveedorPertenece = 0`;
  }

  clicCargoAdicional(index: number = -1) {
    //const ent = this.myForm.value;

    // if (index >= 0) {
    //   this.cargoEditar = this.myForm.value.CargosAdicionales[index];
    // }

    const ent = this.myForm.value;
    if (index >= 0) {
      this.cargoEditar = this.myForm.value.CargosAdicionales[index];
      this.indexCargoEditar = index;
    } else {
      this.indexCargoEditar = -1;
      this.cargoEditar = null;
    }


    if (ent.Proveedor?.Id > 0) {
      const b: any = this.modalCargoAdicional;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          let cargos = this.myForm.value.CargosAdicionales;
          if (!cargos) {
            cargos = [];
          }
          if (!this.cargoEditar) {
            cargos = [...cargos, e];
          } else {
            cargos[this.indexCargoEditar] = e;
          }

          this.myForm.controls["CargosAdicionales"].setValue(cargos);
          setTimeout(() => {
            this.calcTax();
          }, 150);
        }
      }, 'xl');
    } else {
      this.eventsService.publish('home:showAlert', { message: "Primero debe de indicar un Proveedor.", cancelButton: false });
    }
  }






  clicConsignado() {
    const ent = this.myForm.value;
    if (ent.Proveedor?.Id > 0) {
      const b: any = this.modalConisgnado;
      this.ms.openModal(b, (e: any) => {

      }, 'xl')

    } else {
      this.eventsService.publish('home:showAlert', { message: "Primero debe de indicar un Proveedor.", cancelButton: false });
    }
  }

  onSelectedItem(entity: any, tipo: string) {
    switch (tipo) {
      case "Serie":
        let entrar = true;
        if (this.myForm.value.Serie) {
          if (this.myForm.value.Serie.Id == entity.Id) {
            entrar = false;
          }
        }
        if (entrar) {
          this.getEmptyEntity(entity.Id, this.myForm.value.DocumentoCompra.Id);
        }
        break;
      case "DocumentoCompra":
        this.myForm.controls[tipo].setValue(entity);
        if (entity) {
          this.compraService.afectaBackOrder(entity.Id).subscribe((afecta) => { this.afectaBackOrder = afecta; });
          this.getSeries(entity.Id);
          this.changueTitle(entity.Nombre);
          this.afectaAlmacen = entity.TipoMovimientoAlmacen != null
          this.afectaCartera = entity.TipoMovimientoCXC != null
        }
        break;

      case "Moneda":
        this.esMonedaExtranjera = entity.EsExtranjera;
        if (this.esMonedaExtranjera) {
          this.fServices.obtenerTipoCambioCompras(moment(this.myForm.value.FechaEmision).format("DD/MM/YYYY")).subscribe((tc) => {
            this.myForm.controls["TipoCambio"].setValue(tc);
          })
        } else {
          this.myForm.controls["TipoCambio"].setValue(0);
        }
        this.myForm.controls[tipo].setValue(entity);
        break;
      case "Condicion":
      case "Vehiculo":
      case "Chofer":
      case "TipoServicio":
      case "Paqueteria":
      case "Consignado":
      case "TipoGastoUnidad":
        this.myForm.controls[tipo].setValue(entity);
        break;
      case "Almacen":
        this.myForm.controls[tipo].setValue(entity);
        break;
      case "Clase":
        const t: any = this.cboClase;
        let fechaVencimiento = new Date();
        if (t.selectedItem.metodopago == 2) {
          if (t.selectedItem.condicionpago) {
            this.myForm.controls["Condicion"].setValue(t.selectedItem.condicionpago);
            if (t.selectedItem.condicionpago.Dias > 0) {
              fechaVencimiento = this.addDays(fechaVencimiento, t.selectedItem.condicionpago.Dias);
            }
          } else if (this.myForm.value.Cliente.CondicionDePago.Dias > 0) {
            this.myForm.controls["Condicion"].setValue(this.myForm.value.Cliente.CondicionDePago);
            fechaVencimiento = this.addDays(fechaVencimiento, this.myForm.value.Cliente.CondicionDePago.Dias);
          }
        } else if (t.selectedItem.condicionpago) {
          this.myForm.controls["Condicion"].setValue(t.selectedItem.condicionpago);
        }
        this.myForm.controls["FechaVencimiento"].setValue(fechaVencimiento);
        this.myForm.controls[tipo].setValue(entity);
        break;
    }

  }

  //TODO: Validar si esta impreso que no permita imprimir, a menos que el rol diga que si

  print(cb: any = null) {
    let ent = this.myForm.value;
    if (ent.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: "Primero debe de guardar el Documento", cancelButton: false });
      return;
    }

    if (this.gettotalCargoProrrateado > 0) {
      this.eventsService.publish('home:showAlert', {
        message: '¿Que tipo de impresión desea generar?',
        textAccept: 'Inventario',
        textCancel: 'Compras',
        onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            //Para Inventario
            this.imprimirDocumento(ent.Id, false)
          } else {
            this.imprimirDocumento(ent.Id, true)
          }
        }
      });
    } else {
      //Se imprime la opción como COMPRA
      this.imprimirDocumento(ent.Id, true)

    }

  }

  imprimirDocumento(id: number, esParaCompras: boolean) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idCompra", id).set("esParaCompras", esParaCompras);
    this.http.get<Result>(`${this.baseUrl}/Compras/ImprimirDocumento`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.downloadPdf(result.message);
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    })
  }


  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }

  clicPrinted(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.setDocumentPrinted(id, false).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.myForm.controls["Impreso"].setValue(false);
      this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });

    })
  }

  openComments() {
    const b: any = this.comentarioGlobal;
    this.ms.openModal(b, (e: any) => {
      if (e != null && e != undefined) {
        this.myForm.controls["Observaciones"].setValue(e);
      }
    }, 'md')
  }


  openHistoricoBackOrder() {
    const b: any = this.pendienteEntregar;
    this.ms.openModal(b, (e: any) => {

    }, 'lg')
  }

  openCopy() {
    const b: any = this.copiarComprobante;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["IdsCopiados"].setValue(e.idsCopy);
        this.myForm.controls["SeriesFoliosCopiados"].setValue(e.seriesFoliosCopiados);
        this.sourceReset(e.conceptos);
        this.calcTax();
      }
    }, 'lg')
  }

  getCountAnticipo(): number {

    const filtro = this.source.filter(P => P.Cantidad > 0);
    const conceptos: CompraConcepto[] = [...filtro];
    let countAnt = conceptos.filter(P => P.Producto?.EsParaAnticipo).length;
    return countAnt;
  }

  isValidConcepts(throwMessage: boolean = true): boolean {
    const conceptos: CompraConcepto[] = [...this.source.filter(P => P.Cantidad > 0)];
    let countAnt = conceptos.filter(P => P.Producto?.EsParaAnticipo).length;
    if (countAnt > 1 && throwMessage) {
      this.eventsService.publish('home:showAlert', { message: `Solo puede existir un concepto de Anticipo, verifique.`, cancelButton: false });
      return false;
    }
    return true;
  }

  esAnticipo() {
    const conceptos: CompraConcepto[] = [...this.source.filter(P => P.Cantidad > 0)];

    let countAnt = conceptos.filter(P => P.Producto?.EsParaAnticipo).length;
    return countAnt == 1;
  }

  elementoSeleccionado(elemento: any) {
    this.searchProv(elemento.Clave, true);
  }

  save(validar: boolean = true) {
    if (this.saving) return;
    let ent = this.myForm.value;
    let errorPaquetesDuplicados = false;
    let errorPaquetesVacios = false;
    let errorCostosCero = false;
    let orden: number = 0;
    this.source.map((item) => {
      if (item.Cantidad > 0) {
        item.Orden = orden;
        orden++;
      }
    });
    this.source.map((item) => {
      if (item.Cantidad > 0) {
        if (parseFloat(String(item.CostoUnitario)) == 0 && !errorCostosCero) {
          errorCostosCero = true;
        }
        if (!item.Producto?.ManejaPaquetesLotes) {
          item.NumeroLotePaquete = "";
        } else {
          if (!errorPaquetesDuplicados) {
            if (item.NumeroLotePaquete?.length > 0) {
              errorPaquetesDuplicados = this.source.filter(P => P.Producto?.ManejaPaquetesLotes && P.Orden != item.Orden).filter(P => P.NumeroLotePaquete == item.NumeroLotePaquete).length > 0;
            } else if (!errorPaquetesVacios) {
              errorPaquetesVacios = true;
            }
          }
        }
      }
      return item;
    })

    if (errorCostosCero) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el costo en todos los Productos, verifique.`, cancelButton: false });
      return;
    }

    if (errorPaquetesVacios) {
      this.eventsService.publish('home:showAlert', { message: `No ha indicado el  identificador del Paquete/Lote para algunos conceptos, verifique.`, cancelButton: false });
      return;
    }

    if (errorPaquetesDuplicados) {
      this.eventsService.publish('home:showAlert', { message: `No puede colocar el mismo identificador del Paquete/Lote para varios conceptos, verifique.`, cancelButton: false });
      return;
    }

    ent.Conceptos = this.source.filter(P => P.Cantidad > 0);

    if (!this.isValidConcepts()) { return; }


    if (ent.Conceptos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar los conceptos del documento.`, cancelButton: false });
      return;
    }

    if (ent.Proveedor?.Id == 0 || !ent.Proveedor) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Proveedor.`, cancelButton: false });
      return;
    }

    if (!ent.Almacen && this.afectaAlmacen) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar un Almacén.`, cancelButton: false });
      return;
    }

    if (this.getMostrarVehiculo) {
      if (!ent.Vehiculo) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar un Vehículo.`, cancelButton: false });
        return;
      }
      if (!ent.TipoServicio) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar un Tipo De Servicio.`, cancelButton: false });
        return;
      }
      if (ent.Odometro <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Odometro actual del Vehículo.`, cancelButton: false });
        return;
      }


      if (!ent.Preventivo && !ent.Correctivo && !ent.Mantenimiendo) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar si es Preventivo, Correctivo o de Mantenimiento.`, cancelButton: false });
        return;
      }
    }

    if (this.afectaCartera) {
      if (validar) {
        if (!ent.FacturaProveedor) {
          this.eventsService.publish('home:showAlert', {
            message: 'No ha indicado la Factura del Proveedor, ¿Desea Continuar?',
            onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.saving = false;
                setTimeout(() => { this.save(false); }, 100);
              }
            }
          });
          return;
        }
        if (!ent.FechaFacturaProveedor) {
          this.eventsService.publish('home:showAlert', {
            message: 'No ha indicado la Fecha de la Factura del Proveedor, ¿Desea Continuar?',
            onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.saving = false;
                setTimeout(() => { this.save(false); }, 100);
              }
            }
          });
          return;
        }
      }
    }

    // if (ent.DocumentoCompra.Clasificacion == 6) {
    //   if(!)
    //   this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Proveedor.`, cancelButton: false });
    //   return;
    // }


    this.setPayments();

    this.openPaymentsDetails((payments: CompraFormaPago[] | null) => {
      if (payments) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        ent = this.myForm.value;
        ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");
        ent.Conceptos = this.source.filter(P => P.Cantidad > 0);
        ent.FormasPago = payments;
        this.http.post<Result>(`${this.baseUrl}/Compras/GuardarCompra`, ent).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          if (result.success) {
            const v: Compra = JSON.parse(result.message);
            this.myForm.reset(v);
            this.getCustomerAdvanceBalance(v.Empresa.Id, v.Proveedor.Id);
            Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
              this.print(() => {
                this.sendEmail();
              });
            });
          } else {
            this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
          }
        })
      }
    });
  }

  get getCantidad(): number {
    let x = 0;
    this.source.filter(P => P.Producto && P.Cantidad != 0).forEach((i) => { if (i.Producto?.Inventariable) x += parseFloat(String(i.Cantidad)); });
    return parseFloat(x.toFixed(2));
  }

  get getAnticipoAplicado(): number {
    let suma = 0;

    // if (this.myForm.value.FacturasAnticipos) {
    //   if (this.myForm.value.FacturasAnticipos.length > 0) {
    //     this.myForm.value.FacturasAnticipos.forEach((el: FacturaAnticipoRelacionada) => {
    //       suma += el.ImporteAplicado;
    //     });
    //   }
    // }

    return suma;
  }

  setPayments() {
    if (this.myForm.value.Proveedor) {
      const t: any = this.cboClase;
      let metodopago = t.selectedItem.metodopago;

      if (!metodopago) {
        if (this.myForm.value.Clase.Metodo) {
          metodopago = this.myForm.value.Clase.Metodo.Id;
        }
      }

      //if (metodopago == 1) {
      if (!this.myForm.value.FormasPago || this.myForm.value.FormasPago?.length == 0) {
        this.myForm.controls["FormasPago"].setValue([{
          Id: 0,
          FormaPago: this.myForm.value.Proveedor.FormaDePago,
          FormaPagoNombre: this.myForm.value.Proveedor.FormaDePago.Nombre,
          Importe: this.myForm.value.Total - this.getAnticipoAplicado,
        }]);
      }
      //}


      // if (metodopago == 2) {
      //   this.myForm.controls["FormasPago"].setValue([{
      //     Id: 0,
      //     FormaPago: { Id: 22, Clave: '99', Nombre: 'Por definir' },
      //     FormaPagoNombre: "Por Definir",
      //     Importe: this.myForm.value.Total - this.getAnticipoAplicado,
      //   }]);
      // }

    }
  }

  openPaymentsSearch() {
    this.setPayments();
    this.openPaymentsDetails((e: any) => {
      if (e) {
        this.myForm.controls["FormasPago"].setValue(e);
      }
    })
  }

  openPaymentsDetails(cb: any) {
    const b: any = this.ctrlFormaPago;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }


  conversionClick(index: number) {
    this.indexEditing = index;
    let item = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    this.unidadFinal = item.UnidadNombre;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }

  canCancel() {
    this.openCancel();
  }

  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let fc: Date = e.Fecha;
    const dateA: Date = new Date(fc.getFullYear(), fc.getMonth(), fc.getDate())
    fc = addHours(fc, dateA.getHours())
    fc = addMinutes(fc, dateA.getMinutes())
    fc = addSeconds(fc, dateA.getSeconds())
    const date = moment(fc).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date)
    return this.http.get<Result>(`${this.baseUrl}/Compras/CancelarComprobante`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }


  openCancel() {
    const b: any = this.modalCancelOptiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }

  sendEmail() {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.comprasService.obtenerCorreosProveedor(this.myForm.value.Proveedor.Id).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const correos = result.message;
        Swal.fire({
          title: "Envíar email para:",
          input: "text",
          inputValue: correos,
          inputAttributes: { autocapitalize: "off" },
          showCancelButton: true,
          confirmButtonText: "Enviar",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              this.eventsService.publish('home:isLoading', { isLoading: true });
              this.comprasService.sendEmail(this.myForm.value.Id, result.value).subscribe((result) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                if (result.success) {
                  this.eventsService.publish('home:showAlert', { message: "Se ha enviado por e-mail", cancelButton: false });
                } else {
                  this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
                }
              });
            } else {
              this.eventsService.publish('home:showAlert', { message: "Debe de indicar por lo menos un e-mail.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
            }
          }
        });
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: "El documento no se ha guardado.", cancelButton: false });
    }
  }

  downloadFiles() {
    // if (this.myForm.value.Id > 0) {
    //   this.fServices.canPrint(this.myForm.value.DocumentoCompra.Id).subscribe((result) => {
    //     if (result.success) {
    //       this.fServices.downloadFiles(this.myForm.value.Id);
    //     }
    //   })

    // } else {
    //   Swal.fire({
    //     text: "El documento no se ha guardado.", icon: 'error',
    //   })
    // }
  }


  get getIdDocto(): number {
    return this.myForm.value.DocumentoCompra.Id;
  }

  getComps() {
    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Compras/ObtenerListaComprobantes`, { params }).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaComprobantes = lista;
        this.listaComprobantes.map(P => { P.ClaveNombre = `${String(P.Clave).padStart(3, '0')} ${P.Nombre}` });
        if (lista.length == 1) {
          this.documentosPuedeCopiar = lista[0].DocumentosPuedeCopiar!;
          this.getSeries(lista[0].Id);
          this.changueTitle(lista[0].Nombre);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          const t: any = this.cboComp;
          setTimeout(() => {
            t.tagInput.nativeElement.focus();
          }, 100);
        }
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });

        this.eventsService.publish('home:showAlert', {
          message: 'No se encontró ninguna Serie de compra configurado.',
          onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      }
    })
  }

  getSeries(idDocumento: number) {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.compraService.getSeries(this.info.empresa!.numero, this.info.sucursal!.numero, idDocumento).subscribe((lista) => {

      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          this.getEmptyEntity(this.listaSeries[0].Id, idDocumento);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          setTimeout(() => {
            txt.tagInput.nativeElement.focus()
          }, 150);
        }
      }
    })


  }

  getEmptyEntity(idSerie: number = 0, idDocto: number = 0) {
    this.cService.getEmptyEntity("Compra").subscribe((ent) => {
      this.initializeEntity(ent, idSerie, idDocto)
    })
  }

  getNextFolio(ent: Compra) {
    this.compraService.getNextFolio(ent.Empresa.Id, ent.Sucursal.Id, ent.DocumentoCompra.Id, ent.SerieString).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    });
  }

  getDate(ent: Compra) {

    this.fServices.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
      //2024-01-29T10:23:49
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      console.log(ent.FechaEmision)
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.myForm.reset(ent);
      this.focusCliente();
    })



  }

  blurProv(values: any) {
    if (values.after != values.before) {
      this.searchProv(values.after);
    }
  }

  enterProv() {
    const txt: any = this.txtProveedor;
    this.searchProv(txt.tagInput.nativeElement.value);
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.compraService.findEntityByParams(ent.Empresa.Id, ent.Sucursal.Id, ent.DocumentoCompra.Id, ent.SerieString, ent.Folio).subscribe((compra) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(compra);
    });
  }

  deleteCargos(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el Cargo?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          let cargos = this.myForm.value.CargosAdicionales;
          cargos.splice(index, 1);
          cargos = [...cargos];
          this.myForm.controls["CargosAdicionales"].setValue(cargos);
          setTimeout(() => {
            this.calcTax();
          }, 150);
        }
      }
    });
  }

  addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  getCustomerAdvanceBalance(idEmp: number, idCli: number) {
    this.fServices.getCustomerAdvanceBalance(idEmp, idCli).subscribe((saldo) => this.anticipoDisponible = saldo);
  }

  searchProv(clave: string, sendFocus: boolean = false) {
    this.blockCombos = true;
    const params = new HttpParams().set("idEmpresa", this.myForm.value.Empresa.Id).set("clave", clave);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<Proveedor>(`${this.baseUrl}/Compras/BuscarProveedorPorClave`, { params }).subscribe((proveedor) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      let ent = this.myForm.value;
      if (proveedor) {
        ent.Proveedor = proveedor;
        //ent.UsoCfdi = cliente.UsoCfdi;
        const t: any = this.cboComp;

        ent.Condicion = proveedor.CondicionDePago;
        ent.FechaVencimiento = new Date();
        if (ent.Condicion) {
          ent.FechaVencimiento = this.addDays(ent.FechaVencimiento, proveedor.CondicionDePago.Dias);
        } else {
          this.eventsService.publish('home:showAlert', {
            textAccept: "Si",
            cancelButton: false,
            message: 'El proveedor no tiene configurado las condiciones de pago.',
            onConfirm: (data: AlertResponse) => { }
          })
          return;
        }
        ent.Clase = proveedor.ClaseCompra;
        ent.Moneda = proveedor.Moneda;
        this.setAdress(ent.Proveedor.Direccion);
        const txt: any = this.txtProveedor;
        txt.tagInput.nativeElement.value = proveedor.Clave;

        setTimeout(() => {
          const txt: any = this.txtFechaEmision;
          txt.tagInput.nativeElement.focus()
        }, 100);


      } else {
        this.anticipoDisponible = 0;
        ent.Proveedor = null;
        ent.Condicion = null;
        ent.Clase = null;

        ent.Moneda = null;
        this.setAdress(undefined);
        const txt: any = this.txtProveedor;
        txt.tagInput.nativeElement.value = "";
        setTimeout(() => { this.eventsService.publish('home:showAlert', { icon: ' fa-triangle-exclamation text-yellow', message: "No se encontró el proveedor indicado.", cancelButton: false }); }, 150);

      }
      this.myForm.reset(ent);
    })
  }

  get getBlockCombos(): boolean {
    return this.blockCombos;
  }

  setAdress(address: Direccion | undefined) {
    if (!address) {
      this.address = "";
    } else {
      this.address = `${address.Calle} ${address.NumeroExterior}, ${address.Colonia} ${address.Localidad}, ${address.Estado} ${address.Pais}, CP: ${address.CodigoPostal}`
    }

  }

  clicIcon(value: any) {
    this.openCustomerSearch();
  }

  openProductsSearch() {
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      this.searchProduct(e.Clave);
    }, 'xl')
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      this.searchProv(e.Clave);
    }, 'lg')
  }

  get getMostrarVehiculo(): boolean {
    if (this.myForm.value.DocumentoCompra?.Id > 0) {
      return this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoCompra.Id)[0].SolicitarInfoVehiculos!;
    }
    return false;
  }

  initializeEntity(ent: any, idSerie: number = 0, idDocto: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero };
    ent.FechaVencimiento = new Date();
    if (idSerie > 0) {
      ent.Serie = { Id: idSerie };
      ent.SerieString = this.listaSeries.filter((P) => P.Id == idSerie)[0].Serie;
    }
    ent.DocumentoCompra = { Id: idDocto };
    const txt: any = this.txtProveedor;
    txt.tagInput.nativeElement.value = '';
    this.sourceReset();
    this.getNextFolio(ent);
  }
}
