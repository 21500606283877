<app-container-base subTitle="Listado de ordenes pendientes" [hasBar]="false" [hasCounter]="false"
  icon=" fas fa-list-check">
  <div class="screen-content p-3 bg-light" style="flex-direction: column;border: 1px solid #d2d2d2; cursor: pointer;">
    <ng-scrollbar style="height: 80vh; margin-bottom: 35px;">
      <app-panel-base title="Listado de ordenes pendientes de manufacturar">
        <div class="panel-content">
          <div class="row">
            <!-- <div (click)="obtenerOrdenes()" class="btn btn-primary d-block">Actualizar</div> -->
            <div *ngFor="let item of lista" class="col-xl-4 col-md-4 m-1 p-1"
              style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
              <div class="flex-fill">
                <div class="d-flex">
                  <div class="w-50">
                    <div class="mb-3 text-gray-900"><b>Orden: {{item.serieString}}
                        {{item.folio}}</b><span class="ms-2"><i class="fa fa-info-circle"></i></span>
                    </div>
                  </div>
                  <div class="w-50" style="text-align: right;">
                    <span class="badge h-15px bg-blue me-1">Emitida: {{getDate(item.fechaEmision)}}</span>
                    <span class="badge h-15px bg-success">Compromiso: {{getDate(item.fechaCompromisoEntrega)}}</span>
                  </div>
                </div>
                <hr>
                <div class="d-flex">
                  <div class="w-50">

                    <div class="d-flex mb-2">
                      <div class="d-flex align-items-center"><i class="fa fa-circle text-green fs-8px me-2"></i> Creada
                        por: {{item.usuarioElabora.nombre}}</div>
                    </div>

                    <div class="d-flex mb-2">
                      <div class="d-flex align-items-center"><i class="fa fa-circle text-green fs-8px me-2"></i>
                        Factura: {{item.serieFolioDocumentoVentaOrigen}}</div>
                    </div>

                    <div class="d-flex mb-2">
                      <div class="d-flex align-items-center"><i class="fa fa-circle text-green fs-8px me-2"></i>
                        Solicitado: {{item.cantidad | number:'1.2-6'}}</div>
                    </div>

                    <div class="d-flex mb-2">
                      <div class="d-flex align-items-center"><i class="fa fa-circle text-green fs-8px me-2"></i>
                        Producido: {{item.cantidadProducida|number:'1.2-6'}}</div>
                    </div>

                    <div class="d-flex mb-2">
                      <div class="d-flex align-items-center"><i class="fa fa-circle text-red fs-8px me-2"></i>
                        Pendiente: {{item.cantidadPendiente|number:'1.2-6'}}</div>
                    </div>

                  </div>
                  <div class="w-50">
                    <div class="mb-1">
                      <div class="fs-10px fw-bold">{{item.porcentajeAvance}}% de avance</div>
                      <div class="d-flex align-items-center mb-5px">
                        <div class="progress h-10px w-100 mb-0 me-2">
                          <div class="progress-bar progress-bar-striped bg-success"
                            [style]="'width: '+item.porcentajeAvance+'%;'">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <hr>
                <div class="form-group mb-1">
                  <div id="todoBoard" class="collapse show">
                    <div class="btn-group w-100">
                      <a (click)="imprimirOrdenManufactura(item.id)" class="btn btn-sm btn-outline-primary">Imprimir</a>
                      <a (click)="verHistorico(item.historicos)" class="btn btn-sm btn-outline-primary">Histórico</a>
                      <a (click)="verHistoricoProducciones(item.id)" class="btn btn-sm btn-outline-primary">Inventario</a>
                      <a (click)="elaborar(item)" class="btn btn-sm btn-outline-primary">Elaborar</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </app-panel-base>

    </ng-scrollbar>

    <ng-template #noitems>
      <div class="card-body">
        <div>
          <form novalidate="" class="dropzone needsclick ng-untouched ng-pristine ng-valid">
            <div class="dz-message needsclick"> <b>{{'No hay ordenes para mostrar' | titlecase}} </b><br><span
                class="dz-note needsclick">Cuando haya ordenes, apareceran aquí</span></div>
          </form>
        </div>
      </div>
    </ng-template>
    <!-- <div *ngIf="abrirHistorico">
      <app-historico-orden-page [lista]="historicos" (onClose)="onCloseHistorico()" />
    </div> -->

    <div *ngIf="abrirHistoricoProduccion">
      <app-producciones-por-orden-page [idOrden]="idOrden" (onClose)="onCloseHistoricoProduccion()" />
    </div>

    <div *ngIf="abrirProduccion">
      <app-generacion-orden-page [idOrden]="idOrden" [lista]="conceptos" (onClose)="onCloseProduccion($event)" />
    </div>


  </div>
</app-container-base>
