<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Búsqueda Menú (escape = cerrar)</div>
  <div>
    <div class="widget-input-container">
      <div class="widget-input-box">
        <input (keydown)="keydown($event)" (keyup)="appSidebarSearch($event)" type="text" class="form-control"
          placeholder="Teclee para buscar en el menú" #txtBuscar>
      </div>
      <div class="widget-input-icon">
        <a class="text-body text-opacity-50" data-id="widget-elm" data-light-class="text-body text-opacity-50"
          data-dark-class="text-white">
          <i class="fa fa-magnifying-glass-location" style="cursor: pointer;"></i>
        </a>
      </div>
    </div>
    <ng-scrollbar style="height: 150px;">
      <table class="table table-sm">
        <tbody>
          <tr [id]="'rows-' + i" [style]="i == index ? 'background-color:orange;color:#ffffff':''"
            (click)="clickMenu(item)" style="cursor:pointer;" *ngFor="let item of results; let i = index;">
            <td style="font-weight: bold;">

              <ol class="breadcrumb">
                <li [style]="i == index ? 'color:#ffffff':''" class="breadcrumb-item"><a>{{item.root}}</a></li>
                <li [style]="i == index ? 'color:#ffffff':''" class="breadcrumb-item active">{{item.title}}</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-scrollbar>
  </div>
</div>
