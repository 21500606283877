import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { Caja } from '../../interfaces/caja.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';

@Component({
  selector: 'app-caja-page',
  templateUrl: './caja-page.component.html',
  styles: [
  ]
})
export class CajaPageComponent implements OnInit {



  filterQuery = "";
  public itemsCombo: ComboBoxEntity[] = [];
  info: any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: ['', Validators.required],
    CodigoCajero: [''],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    FondoFijo: [0, Validators.required],
    Baja: [false]
  })

  constructor(private utileService: UtilsService,
    private fb: FormBuilder,
    private containerService: ContainerBaseService) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.filterQuery = ``;
  }

  get getFilterQuery(): string{
    return this.filterQuery;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    param.callback(true);
  }

  onItemSearched(entity: any): void {
    if (entity) {
      const emp = { Id: this.info.empresa.numero, Clave: this.info.empresa.clave, Nombre: this.info.empresa.nombre }
      entity.Empresa = emp;
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: Caja) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Caja").subscribe((elem) => {
      elem.Clave = next;
      const emp = { Id: this.info.empresa.numero, Clave: this.info.empresa.clave, Nombre: this.info.empresa.nombre }
      elem.Empresa = emp;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
  selectedItem(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }


}
