import { Component, HostListener, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { PdfJsViewerComponent } from 'src/app/components/pdf-viewer/pdfjs-viewer.component';
import { UtilsService } from 'src/app/service/utils.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ReportFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
@Component({
  templateUrl: './reportes.page.component.html',
  styleUrls: ['./reportes.page.component.scss']
})
export class ReportesPageComponent {
  private titleService = inject(Title);
  private utilsService = inject(UtilsService);
  private reportsService = inject(ReportsService);


  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: PdfJsViewerComponent = {} as PdfJsViewerComponent;
  heightScreen: number = window.innerHeight;

  downloadExcel: boolean = true;

  private route = inject(ActivatedRoute);
  private cbService = inject(ContainerBaseService);
  base64Pdf: string = "base64";
  nombreArchivo!: string;
  entity: string = '';
  excelFileName: any;
  reportFilter: any;
  urlReporte: string = '';



  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    localStorage.removeItem('pdfblob');
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      setTimeout(() => {
        this.titleService.setTitle('Complit | ' + params['title']);
        this.nombreArchivo = params['title'] + '.pdf';
        this.entity = params['entity'];
        this.pdfViewerOnDemand.pdfSrc = localStorage['pdfblob'];
        this.excelFileName = params['excelFileName']
        this.pdfViewerOnDemand.cursor = "";
        this.reportFilter = params['reportFilter'];
        this.urlReporte = params['urlReporte'];
        this.pdfViewerOnDemand.refresh();
      }, 700);
    });
  }
  documentLoaded(event: any) {
  }

  descargarExcel() {
    if (this.entity.length > 0) {
      this.cbService.printEntity(this.entity, true).subscribe((result) => {
        let link = document.createElement('a');
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(result.message);
        link.setAttribute('download', this.nombreArchivo.replace(".pdf", ""));
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      let reportFilter: ReportFilter = JSON.parse(this.reportFilter);
      reportFilter.ExportarExcel = true;
      this.reportsService.printReport(reportFilter, this.urlReporte, true);
    }
  }
}
