<div id="app" class="app" [ngClass]="{
  'has-scroll' : appHasScroll,
  'app-sidebar-minified': appSettings.appSidebarMinified,
  'app-sidebar-fixed': !appSettings.appSidebarNone && appSettings.appSidebarFixed,
  'app-header-fixed': !appSettings.appHeaderNone && appSettings.appHeaderFixed,
  'app-without-sidebar': appSettings.appSidebarNone || appSettings.appEmpty,
  'app-without-header': appSettings.appHeaderNone || appSettings.appEmpty,
  'app-with-end-sidebar': appSettings.appSidebarEnd,
  'app-with-two-sidebar': appSettings.appSidebarTwo,
  'app-with-wide-sidebar': appSettings.appSidebarWide,
  'app-sidebar-end-toggled': appSettings.appSidebarEndToggled,
  'app-sidebar-mobile-toggled': appSettings.appSidebarMobileToggled,
  'app-content-full-height': appSettings.appContentFullHeight,
  'app-with-top-menu': appSettings.appTopMenu,
  'app-sidebar-end-mobile-toggled': appSettings.appSidebarEndMobileToggled,
  'app-gradient-enabled': appSettings.appGradientEnabled,
  'p-0': appSettings.appEmpty}">

  <ng-template #alertObj let-modal let-c="close">
    <app-alert [config]="alertConfig!" />
  </ng-template>

  <top-menu [menus]="menu" *ngIf="appSettings.appTopMenu && !appSettings.appEmpty"
    (openPage)="onOpenPageMenu($event)"></top-menu>

  <header [recargarNotificaciones]="getRecargarNotificaciones" [recargarBuzon]="getRecargarBuzon"
    [recargarTipoCambio]="getRecargarTipoCambio" (appSidebarMobileToggled)="onAppSidebarMobileToggled()"
    (appSidebarEndToggled)="onAppSidebarEndToggled()" (appSidebarEndMobileToggled)="onAppSidebarEndMobileToggled()"
    [appSidebarTwo]="appSettings.appSidebarTwo" [(favoritos)]="favoritos"
    *ngIf="!appSettings.appEmpty && !appSettings.appHeaderNone" (openPage)="onOpenPageMenu($event)"
    (appTopMenuToggle)="toggleTopMenu($event)" [permisosTableros]="permisosTableros" [numeroPaginas]="paginas.length"
    (onLogout)="logout($event)">
  </header>

  <sidebar (appSidebarMinifiedToggled)="onAppSidebarMinifiedToggled()"
    (appSidebarMobileToggled)="onAppSidebarMobileToggled()" [appSidebarTransparent]="appSettings.appSidebarTransparent"
    (openPage)="onOpenPageMenu($event)" (onMenuLoad)="onMenuLoad($event)"
    [appSidebarMinified]="appSettings.appSidebarMinified" [appSidebarGrid]="appSettings.appSidebarGrid"
    [appSidebarFixed]="appSettings.appSidebarFixed" *ngIf="!appSettings.appSidebarNone && !appSettings.appEmpty"
    (loadFavoritos)="loadFavoritos($event)" [menus]="menu" [numeroPaginas]="paginas.length" (onLogout)="logout($event)">
  </sidebar>

  <div id="content" [class]="'app-content'"
    [ngClass]="appSettings.appContentClass + ' ' + (appSettings.appEmpty ? 'p-0 m-0' : '')">
    <!-- <div cdkDrag *ngIf="minimized.length>0" class="bg-gradient-orange text-center p-2"
      style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;border-radius: 100px;width:50px;cursor:pointer;position: fixed;margin-left: 21px;margin-top: 5px;z-index: 9;">
      <i (dblclick)="expandAll()" cdkDragHandle class="fas fa-maximize text-light fa-3x animate__animated animate__rotateIn animate__faster"></i>
    </div> -->
    <tabs-nav [altKeyPressed]="getIsAltKey" *ngIf="paginas.length > 0 || minimized.length>0" [(pagesminimized)]="minimized" [(pages)]="paginas"
      [(favoritos)]="favoritos" />
    <principal-page [class]="(paginas.length === 0 || minimized.length>0) ? 'd-block': 'd-none' "
      [permisosTableros]="permisosTableros" />
  </div>


  <sidebar-right class="" [enviarMensajeChat]="permisosTableros.enviarMensajesChat"
    (appSidebarEndMobileToggled)="onAppSidebarEndMobileToggled()"
    *ngIf="appSettings.appSidebarEndToggled && permisosTableros.verChat">
  </sidebar-right>

  <!-- <theme-panel (appDarkModeChanged)="onAppDarkModeChanged()" (appThemeChanged)="onAppThemeChanged()"
    *ngIf="!appSettings.appThemePanelNone"></theme-panel> -->
</div>


<div *ngIf="isLoading" id="loader" class="app-loader" style="z-index: 99999;">
  <span class="spinner"></span>
</div>

<ng-template #ctrlbusquedamenu let-modal let-c="close">
  <app-busqueda-menu-page [menu]="menu" />
</ng-template>

<ng-template #ctrlCaptcha let-modal let-c="close">
  <div class="card text-center border-0" cdkDrag
    style="width: 423px;zoom:200%;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
    <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">{{!descargandoXmls ? 'Solicitud de Captcha':'Descargando XMLS Recibidos'}}</div>
    <div class="card-body bg-light">
      <div *ngIf="!descargandoXmls else mensaje" class="bg-light image gallery-group-1">
        <div class="image-inner">
          <a data-lightbox="gallery-group-1">
            <img style="image-rendering:pixelated;width:389px;" class="img" [src]="b64Image">
          </a>
        </div>
        <div class="image-info">
          <h5 class="title">Mensaje enviado desde SAT (tiene 1 minuto para ingresar el captcha)</h5>
          <div class="desc">
            Ingrese el captcha para proceder con la descarga de XMLS desde el SAT
          </div>
          <form [formGroup]="myForm" autocomplete="off">
            <input type="text" (keydown.enter)="acceptCaptcha()" class="form-control" formControlName="Captcha"
              placeholder="Captcha de la imagen" #txtCaptchaSAT />
          </form>
        </div>
      </div>

      <ng-template #mensaje>
        <div class="email-from text-dark fs-14px mb-3px fw-bold">
          <div *ngIf="porcentajeDescargado == 0 && porcentajeGuardado == 0">
            {{mensajeSAT}}
            <hr *ngIf="totalEncontrados>0">
            <span *ngIf="totalEncontrados>0">Total encontrados: {{totalEncontrados | number}}</span>
          </div>

          <div class="col-12" *ngIf="porcentajeDescargado >0">
            <div class="mb-2px text-truncate">Proceso de descarga de los XML</div>
            <div class="mb-2px  text-gray-500  small">Descarga SAT 1.0 COMPLIT ERP</div>
            <div class="d-flex align-items-center mb-2px">
              <div class="flex-grow-1">
                <div class="progress h-5px rounded-pill bg-white bg-opacity-10">
                  <div class="progress-bar progress-bar-striped bg-orange" data-animation="width"
                    [style]="'width:' + porcentajeDescargado +'%;'"></div>
                </div>
              </div>
              <div class="ms-2 small w-30px text-center"><span data-animation="number">{{porcentajeDescargado}}</span>%
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="porcentajeGuardado >0">
            <div class="mb-2px text-truncate">Guardando los XML en la base de datos</div>
            <div class="mb-2px  text-gray-500  small">Descarga SAT 1.0 COMPLIT ERP</div>
            <div class="d-flex align-items-center mb-2px">
              <div class="flex-grow-1">
                <div class="progress h-5px rounded-pill bg-white bg-opacity-10">
                  <div class="progress-bar progress-bar-striped bg-orange" data-animation="width"
                    [style]="'width:' + porcentajeGuardado +'%;'"></div>
                </div>
              </div>
              <div class="ms-2 small w-30px text-center"><span data-animation="number">{{porcentajeGuardado}}</span>%
              </div>
            </div>
          </div>

        </div>
      </ng-template>

      <div style="text-align: right; margin-top: 5px;">
        <div *ngIf="!descargandoXmls" style="margin-right: 5px;" (click)="acceptCaptcha()" class="btn btn-success"
          #txtAceptar>Aceptar</div>
        <div *ngIf="!descargandoXmls" (click)="closeModalCaptcha()" class="btn btn-danger">Cancelar</div>
      </div>

    </div>
  </div>
</ng-template>
<ng-template #cambioPass let-modal let-c="close">
  <div style="background-color: #ffffff;">
    <div class="modal-body">
      <div class="note alert-warning">
        <div class="note-icon"><i class="fas fa-user-lock"></i></div>
        <div class="note-content">
          <h4><b>Cambiar Contraseña</b></h4>
          <p>Es necesario cambiar su contraseña para continuar</p>
        </div>
      </div>

      <div class="alert alert-danger fade show" *ngIf="errorMessagge">
        {{errorMessagge}}
      </div>

      <form [formGroup]="formCambioPass" autocomplete="off">
        <components-ui-password-box [topLabel]="true" label="Nueva Contraseña" formControlName="CambioPass"
          [tabIndex]="1" [widthTextColumns]="12" [widthLabelColumns]="12" [required]="true" #txtNombre
          [minlength]="6" />
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-green" (click)="onClickCambioPassword()">
        Aceptar
      </button>
      <button type="button" class="btn btn-outline-red" (click)="logout(true)">Cancelar</button>
    </div>
  </div>
</ng-template>
