
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padStart'
})
export class PadStartPipe implements PipeTransform {
  transform(value: string, targetLength: number, padString = ' ') {
    return this.pad(value, targetLength, padString);
  }

  pad(num: string, size: number, padString: string): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}
