<div class="card text-center border-0" cdkDrag
  style="position: absolute;top:183px;width: 540px;left: 360px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Póliza edición [Movimiento #{{item!.Orden}}]
  </div>
  <div class="card-body bg-light" style="min-height: 428px; max-height: 428px;">
    <form [formGroup]="myForm" autocomplete="off">


      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation"><a href="#asientos" data-bs-toggle="tab" class="nav-link active"
            aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span><span
              class="d-sm-block d-none">Asiento</span></a></li>
      </ul>
      <div class="tab-content panel rounded-0 p-3 m-0">
        <div id="asientos" class="tab-pane fade active show" role="tabpanel">

          <components-ui-number-box [label]="'Orden'" formControlName="Orden" [maxLength]="500" [tabIndex]="tabIndex"
            [widthTextColumns]="2" [maxLength]="3" [widthLabelColumns]="4" />

          <component-ui-check-box [tabIndex]="tabIndex+1" [widthTextColumns]="1" [widthLabelColumns]="4"
            formControlName="EsComentario" [label]="'Es Comentario'" />

          <component-ui-check-box [widthTextColumns]="1" [tabIndex]="tabIndex+2" [widthLabelColumns]="4"
            formControlName="InHabilitada" [label]="'Desactivar'" />

          <components-ui-text-box [enabled]="!myForm.value.EsComentario" [label]="'Cuenta Contable'"
            formControlName="CuentaContable" [maxLength]="500" [tabIndex]="tabIndex + 3" [widthTextColumns]="8"
            [widthLabelColumns]="4" #txtCuenta />

          <components-ui-text-box [enabled]="!myForm.value.EsComentario" [label]="'Centro de Costo'"
            formControlName="CentroCosto" [maxLength]="500" [tabIndex]="tabIndex + 4" [widthTextColumns]="8"
            [widthLabelColumns]="4" />

          <components-ui-text-box [enabled]="!myForm.value.EsComentario" [label]="'Referencia'"
            formControlName="Referencia" [maxLength]="300" [tabIndex]="tabIndex + 5" [widthTextColumns]="8"
            [widthLabelColumns]="4" />

          <components-ui-text-box [label]="'Concepto'" formControlName="ConceptoDetalle" [maxLength]="300"
            [tabIndex]="tabIndex + 6" [widthTextColumns]="8" [widthLabelColumns]="4" />

          <component-ui-combo-box [enabled]="!myForm.value.EsComentario" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="tabIndex+7" inputProperty="Nombre" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="4"
            [widthLabelColumns]="4" [widthRightColumns]="0" (onSelectedItem)="selectedCombo($event, 'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box inputProperty="Nombre" [label]="'Banco'" formControlName="Banco"
            [tabIndex]="tabIndex+8" [zeroMask]="2" entityName="c_Banco" [enabled]="!myForm.value.EsComentario"
            [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0"
            (onSelectedItem)="selectedCombo($event, 'Banco')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box inputProperty="Nombre" [enabled]="!myForm.value.EsComentario" [label]="'ClaseVenta'"
            formControlName="ClaseVenta" [tabIndex]="tabIndex+9" [zeroMask]="2" entityName="ClaseVenta"
            [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0"
            (onSelectedItem)="selectedCombo($event, 'ClaseVenta')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box inputProperty="Nombre" [enabled]="!myForm.value.EsComentario" [label]="'ClaseCompra'"
            formControlName="ClaseCompra" [tabIndex]="tabIndex+10" [zeroMask]="2" entityName="ClaseCompra"
            [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0"
            (onSelectedItem)="selectedCombo($event, 'ClaseCompra')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box inputProperty="Nombre" [enabled]="!myForm.value.EsComentario"
            [label]="'Concepto Cargo'" formControlName="TipoConceptoCargo" [tabIndex]="tabIndex+11" [zeroMask]="2"
            entityName="TipoConceptoPoliza" [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0"
            (onSelectedItem)="selectedCombo($event, 'TipoConceptoCargo')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box inputProperty="Nombre" [enabled]="!myForm.value.EsComentario"
            [label]="'Concepto Abono'" formControlName="TipoConceptoAbono" [tabIndex]="tabIndex+12" [zeroMask]="2"
            entityName="TipoConceptoPoliza" [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0"
            (onSelectedItem)="selectedCombo($event, 'TipoConceptoAbono')" [listProperty]="'ClaveNombre'" />

          <component-ui-check-box [enabled]="!myForm.value.EsComentario" [widthTextColumns]="1" [tabIndex]="tabIndex+13"
            [widthLabelColumns]="4" formControlName="ColocarEnNegativo" [label]="'Cambiar signo'" />

          <component-ui-check-box [widthTextColumns]="1" [tabIndex]="tabIndex+14" [widthLabelColumns]="4"
            formControlName="Detalle" [label]="'A detalle'" />

        </div>
      </div>
    </form>

  </div>
  <div *ngIf="!searching" class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
    </div>
  </div>
</div>
