import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterContentList, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { CuentasPorPagarService } from 'src/app/cuentas-por-pagar/services/cxp.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-relaciones-analiticas-cxp-page',
  templateUrl: './relaciones-analiticas-cxp-page.component.html',
  styles: [
  ]
})
export class RelacionesAnaliticasCxpPageComponent {


  private reportsService = inject(ReportsService);
  private utilsService = inject(UtilsService);
  private cuentasPorPagarService = inject(CuentasPorPagarService);
  private changeDetector = inject(ChangeDetectorRef);

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  usuarioLoggeado!: UserLogged;
  menuId: string = "relacionesanaliticascxp";

  listaMeses: any[] = [];
  listaAnios: any[] = [];
  anioSeleccionado?: ComboBoxEntity;
  mesSeleccionado?: ComboBoxEntity;

  loading: boolean = false;

  public desgloseLista: ReportFilterContentList[] = [{ Nombre: 'Totales por sucursal' },
  { Nombre: 'Totales por cliente' },
  { Nombre: 'Detallado por cliente' }]

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa/Sucursal', Orden: 'empClave,sucClave,provClave' },
  { Nombre: 'Empresa/Sucursal/Cuenta', Orden: 'empClave,sucClave,ctaClave' }]

  public luegoPorLista: ReportFilterOrderList[] = [{ Nombre: 'Ninguno', Orden: '' },
  { Nombre: 'Clave del proveedor', Orden: '' },
  { Nombre: 'Orden del proveedor', Orden: '' }]

  public filtrosReporte: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      DesgloseLista: this.desgloseLista,
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      NombreReporte: '',
      Fecha1: new Date(),
      TituloVisor: 'Relaciones analiticas CXP',
      NombreExcel: 'Relaciones analiticas CXP.xlsx',
      ExportarExcel: false,
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'clase.Clave', Etiqueta: 'Cuenta de CxP', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxp" },
        { Campo: 'clase.clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoCxp" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'usu.Clave', Etiqueta: 'Usuario Elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
      ],
    };

  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Fecha1: null,
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
    Opc5: [false],
    Opc6: [false],
    Opc7: [false],
    Num1: [0],
    Num2: [0],
    Num3: [0]
  });

  constructor(private fb: FormBuilder) { }

  get isLoading(): boolean {
    return this.loading;
  }

  get fechaFormatoMesAnio() {
    let anioSeleccionado: number = this.anioSeleccionado ? this.anioSeleccionado.Id : 0;
    let mesSeleccionado: number = this.mesSeleccionado ? this.mesSeleccionado.Id : 0;
    const fecha = new Date(anioSeleccionado, mesSeleccionado - 1, 1);
    return this.utilsService.Date2Str(fecha, 6).toUpperCase();

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }


  ngOnInit(): void {
    this.usuarioLoggeado = this.utilsService.getUserLogged();
    this.focus('txtNombreReporte');
    let fecha1: Date = new Date();
    this.listaMeses = this.utilsService.getListaMeses();
    const mesActual = new Date().getMonth() + 1;
    this.mesSeleccionado = this.listaMeses.find(p => p.Id == mesActual);
    this.loading = true;
    this.cuentasPorPagarService.obtenerListaDeAniosconMovimientosDeCXP().subscribe(result => {
      if (result) {
        this.loading = false;
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS DE PROVEEDOR DEL MES DE ' + this.fechaFormatoMesAnio);
      }
    })

  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  selectedCombo(entity: any, tipo: string) {

    if (tipo == 'Mes') {
      this.mesSeleccionado = entity;
    } else {
      this.anioSeleccionado = entity;
    }

  }

  get selectMesManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS DE PROVEEDOR DEL MES DE ' + this.fechaFormatoMesAnio);
    return this.mesSeleccionado!;

  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS DE PROVEEDOR DEL MES DE ' + this.fechaFormatoMesAnio);
    return this.anioSeleccionado!;
  }

  clickBoton(event: string) {
    let fecha = new Date(this.anioSeleccionado!.Id, this.mesSeleccionado!.Id - 1, 1);
    this.myForm.get('Fecha1')?.setValue(fecha);
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/CuentasPorPagar/ReporteRelacionesAnaliticasCXP');
  }


}
