<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="ConfiguracionWebTicket" title="Configuración Web Ticket (CEPDI)" icon="fa fa-angles-down"
  subTitle="Administra la configuración para que los clientes facturen en línea su ticket de compra">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="ConfiguracionWebTicket" />
          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />

          <component-ui-combo-box [label]="'Empresa'" [enabled]="false" formControlName="Empresa" [tabIndex]="3" [zeroMask]="2"
            entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedEmpresa($event)" [listProperty]="'ClaveNombre'" />

          <components-ui-text-box [tabIndex]="4" [label]="'Url'" formControlName="Url" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" [maxLength]="1000" />

          <!-- <components-ui-text-box [tabIndex]="4" [label]="'SerieFactura'" formControlName="SerieFactura" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" [maxLength]="20" /> -->

          <components-ui-text-box [tabIndex]="5" [label]="'Usuario'" formControlName="Usuario" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" />

          <components-ui-password-box [tabIndex]="6" [label]="'Password'" formControlName="Password"
            [widthTextColumns]="4" [widthLabelColumns]="2" [required]="true" />

          <component-ui-check-box [tabIndex]="7" [widthLabelColumns]="2" formControlName="EnviarInmediato"
            [widthTextColumns]="7" [label]="'Enviar por WS de inmediato'" />
          <div class="alert alert-yellow fade show">Si no se envía de inmediato, se enviará a las 2 horas después de la
            emisión del ticket</div>


        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
