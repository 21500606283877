<form class="d-flex" [formGroup]="frmPorcentajes" autocomplete="off">
  <div class="pe-1">
    <color-picker [disabled]="disabled" (onBlurText)="onBlur($event, 'porcentaje1')" formControlName="porcentaje1"
      (onChangeColor)="changeColorPicker($event,'porcentaje1')" />
  </div>
  <div class="pe-1">
    <color-picker (onBlurText)="onBlur($event, 'porcentaje2')" formControlName="porcentaje2"
      (onChangeColor)="changeColorPicker($event,'porcentaje2')" />
  </div>
  <div class="pe-1">
    <color-picker (onBlurText)="onBlur($event, 'porcentaje3')" formControlName="porcentaje3"
      (onChangeColor)="changeColorPicker($event,'porcentaje3')" />
  </div>
  <color-picker (onBlurText)="onBlur($event, 'porcentaje4')" formControlName="porcentaje4"
    (onChangeColor)="changeColorPicker($event,'porcentaje4')" />
</form>
