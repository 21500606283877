import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'colors-percent',
  templateUrl: './colors-percent.component.html',
  styleUrls: ['./colors-percent.component.scss']
})
export class ColorsPercentComponent {
  private frmBuilder = inject(FormBuilder);

  disabled: boolean = true;

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();

  @Input()
  public options: optionPercent[] = [
    { color: "#FF0004", text: "<0" },
    { color: "#0800FF", text: "0-30" },
    { color: "#D4AF37", text: "31-60" },
    { color: "#FF8300", text: "61-100" },
  ];

  public frmPorcentajes: FormGroup = this.frmBuilder.group({
    porcentaje1: [this.options[0]],
    porcentaje2: [this.options[1]],
    porcentaje3: [this.options[2]],
    porcentaje4: [this.options[3]],
  });

  ngOnInit() {
    let strPorcentajes = this.getStrPorcentajes();
    this.onChange.emit(strPorcentajes);
  }

  changeColorPicker(value: any, controlName: string) {
    this.frmPorcentajes.controls[controlName].setValue(value);
    let strPorcentajes = this.getStrPorcentajes();
    this.onChange.emit(strPorcentajes);
  }

  onBlur(value: any, controlName: string) {
    this.frmPorcentajes.controls[controlName].setValue(value);
    let strPorcentajes = this.getStrPorcentajes();
    this.onChange.emit(strPorcentajes);
  }

  getStrPorcentajes() {
    let p = this.frmPorcentajes.value;
    let strPorcentajes =
      p.porcentaje1.color + "$" + p.porcentaje1.text + "|" +
      p.porcentaje2.color + "$" + p.porcentaje2.text + "|" +
      p.porcentaje3.color + "$" + p.porcentaje3.text + "|" +
      p.porcentaje4.color + "$" + p.porcentaje4.text;
    return strPorcentajes;
  }

}

export interface optionPercent {
  color: string;
  text: string;
}
