import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { ConceptoAlmacen } from 'src/app/home/interfaces/almacen.interface';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuracion-inv-fisico-page',
  templateUrl: './configuracion-inv-fisico-page.component.html',
  styles: [
  ]
})
export class ConfiguracionInvFisicoPageComponent {
  info: UserLogged = {} as UserLogged;
  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }
  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Empresa: [{} as Empresa],
    ConceptoSalida: [{} as ConceptoAlmacen],
    ConceptoEntrada: [{} as ConceptoAlmacen],
    Eliminado: [false],
    Baja: [false],
  })

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private utileService: UtilsService) {
  }

  selectedCombo(ent: any, type: string){
    this.myForm.controls[type].setValue(ent);
  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "save": this.save(); break;
      //case "print": this.print(); break;
    }
  }

  save() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let ent = this.myForm.value;
    ent.Empresa = { Id: this.info.empresa?.numero };
    ent.Sucursal = { Id: this.info.sucursal?.numero };
    this.containerService.saveEntity("ConfiguracionInventarioFisico", JSON.stringify(ent)).subscribe((result) => {
      if (result.success) {
        this.containerService.getLastEntity("ConfiguracionInventarioFisico", `Empresa.Id = ${this.info.empresa?.numero}`).subscribe((ent) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 })
          if (ent) {
            this.myForm.reset(ent);
          }
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        Swal.fire({ icon: 'error', text: result.message }).then(() => { });
      }
    })
  }

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.info = this.utileService.getUserLogged();
    this.containerService.getLastEntity("ConfiguracionInventarioFisico", `Empresa.Id = ${this.info.empresa?.numero}`).subscribe((ent) => {
      if (!ent) {
        this.containerService.getEmptyEntity("ConfiguracionInventarioFisico").subscribe((newE) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.myForm.reset(newE);
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.myForm.reset(ent);
      }
    });
  }
}
