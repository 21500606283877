<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-address-book" [hasBar]="false"
  subTitle="Generación de Órdenes de Entrega">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div style="width:950px;">
          <div class="d-flex" style="height: 265px;">
            <div style="width:950px;margin-right:10px;height: 300px;">
              <app-panel-base title="Selección de Vehículo" [itemsMenuDropDown]="menuItems"
                (onClicMenu)="clicMenu($event)">
                <div class="panel-content">
                  <div class="form-group row">
                    <div class="col-md-6">
                      <component-ui-combo-box label="Vehículo" [inputProperty]="'Nombre'" [zeroMask]="3"
                        [entityName]="'Unidad'" [tabIndex]="1" [widthLabelColumns]="4" [widthTextColumns]="8"
                        [widthRightColumns]="0" (onSelectedItem)="selectVehiculo($event)" formControlName="unidad"
                        [filterQuery]="filtroVehiculos" extras="CapacidadCarga,Chofer,Ayudante" #cboVehiculo />
                    </div>
                    <div class="col-md-6">
                      <component-ui-combo-box label="Chofer" [inputProperty]="'Nombre'" [entityName]="'Chofer'"
                        [tabIndex]="2" [widthLabelColumns]="4" [widthTextColumns]="8" [required]="true"
                        [filterQuery]="filtroChofer" [widthRightColumns]="0" formControlName="chofer"
                        (onSelectedItem)="selectChofer($event,'chofer')" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <components-ui-number-box [enabled]="false" [tabIndex]="3" [maxLength]="500" [label]="'Cantidad '"
                        [widthLabelColumns]="4" [widthTextColumns]="8" formControlName="capacidadCarga" />
                    </div>
                    <div class="col-md-6">
                      <component-ui-combo-box label="Ayudante 1" [inputProperty]="'Nombre'" formControlName="ayudante"
                        [entityName]="'Chofer'" [tabIndex]="4" [widthLabelColumns]="4" [widthTextColumns]="8"
                        [widthRightColumns]="0" filterQuery='Tipo.Clave == 3' [filterQuery]="this.filtroAyudante1"
                        (onSelectedItem)="selectAyudante($event,1)" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <components-ui-number-box [enabled]="false" [tabIndex]="5" [maxLength]="500" [label]="'Cargado'"
                        [widthLabelColumns]="4" [widthTextColumns]="8" formControlName="cargado" />
                    </div>
                    <div class="col-md-6">
                      <component-ui-combo-box label="Ayudante 2" [inputProperty]="'Nombre'" [entityName]="'Chofer'"
                        [tabIndex]="6" [widthLabelColumns]="4" [widthTextColumns]="8"
                        [filterQuery]="this.filtroAyudante2" (onSelectedItem)="selectAyudante($event,2)"
                        formControlName="ayudante2" [widthRightColumns]="0" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <components-ui-number-box [enabled]="false" [tabIndex]="7" [maxLength]="500"
                        [label]="'Disponible'" [widthLabelColumns]="4" [widthTextColumns]="8"
                        formControlName="disponible" />
                    </div>
                    <div class="col-md-6">
                      <component-ui-combo-box label="Ayudante 3" [inputProperty]="'Nombre'" [entityName]="'Chofer'"
                        [tabIndex]="8" [widthLabelColumns]="4" [widthTextColumns]="8"
                        [filterQuery]="this.filtroAyudante3" (onSelectedItem)="selectAyudante($event,3)"
                        formControlName="ayudante3" [widthRightColumns]="0" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <component-ui-combo-box label="Ayudante 4" [inputProperty]="'Nombre'" [entityName]="'Chofer'"
                        [tabIndex]="9" [widthLabelColumns]="4" [widthTextColumns]="8"
                        [filterQuery]="this.filtroAyudante4" (onSelectedItem)="selectAyudante($event,4)"
                        formControlName="ayudante4" [widthRightColumns]="0" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <components-ui-number-box [enabled]="true" [tabIndex]="10" [maxLength]="500" [label]="'Odómetro'"
                        [widthLabelColumns]="4" [widthTextColumns]="8" formControlName="odometroSalida" />
                    </div>
                  </div>
                  <!-- {{this.myForm.value|json}} -->
                  <!-- {{this.myForm.value.detalleCarga|json}} -->
                  <!-- {{this.listaConceptos| json}} -->
                </div>
              </app-panel-base>
              <!-- <app-panel-base title="Selección de facturas para la generacion de Órdenes" [style]="'width:940px;height:500px'"> -->
              <app-panel-base title="Selección de facturas para la generacion de Órdenes" [style]="'height:500px'">
                <div class="panel-content">

                  <div class="d-flex">

                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group" style="width:30%">
                      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked
                        (click)="mostrarComprobantes('ventas')">
                      <label class="btn btn-outline-primary" for="btnradio1">Ventas</label>

                      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                        (click)="mostrarComprobantes('traspasos')">
                      <label class="btn btn-outline-primary" for="btnradio2">Traspasos</label>

                      <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"
                        (click)="mostrarComprobantes('remisiones')">
                      <label class="btn btn-outline-primary" for="btnradio3">Remisiones</label>
                    </div>
                    <div class="text-end fw-bold" style="width:70%;">
                      <div class="dropdown-toggle mb-0">
                        <a data-bs-toggle="dropdown" class="text-decoration-none text-dark"><i
                            class="fa fa-filter fa-fw me-1 text-dark"></i> Filtrar <b class="caret"></b></a>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:;">Action</a>
                          <a class="dropdown-item" href="javascript:;">Another action</a>
                          <a class="dropdown-item" href="javascript:;">Something else here</a>
                          <div role="separator" class="dropdown-divider"></div>
                          <a class="dropdown-item" href="javascript:;">Separated link</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-scrollbar class="scroll" style="height: 370px;">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 5%;">Sel.</th>
                          <th class="text-center" style="width: 7%;">Fecha</th>
                          <th class="text-center" style="width: 20%;">{{getEncabezadoClienteTraspaso()}}</th>
                          <th class="text-end" style="width: 8%;">Serie-Folio</th>
                          <th class="text-end" style="width: 10%;">Serie-Folio Orig</th>
                          <th class="text-center" style="width: 5%;">Volumen /kilos</th>
                          <th class="text-center" style="width: 5%;">Cargado</th>
                          <th class="text-center" style="width: 30px;">Ciudad</th>
                          <th class="text-center" style="width: 80px;">Dirección entrega</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let b of getListaFacturasFiltradas(); let idx = index">
                          <td class="text-center">
                            <input type="checkbox" (click)="selectFactura(b)" [checked]="getSeleccionado(b)"
                              class="form-check-input cursor-pointer" />
                          </td>
                          <td class="text-center">{{b.fechaEmision| date: 'dd/MMM/yyyy'}}</td>
                          <td class="text-center">{{b.clienteNombre}}</td>
                          <td class="text-center"> <a routerLink="" class="fw-bold">{{b.serieFolio}}</a> </td>
                          <td class="text-center">{{b.serieFolioCopiado}}</td>
                          <td class="text-end">{{b.kilos |number: '1.2-2' }} </td>
                          <td class="text-end">
                            <div class="progress h-5px w-80px mb-0 me-2">
                              <div class="progress-bar progress-bar-striped"
                                [style.width]="b.cantidadTotalCargaPorcentaje">
                              </div>
                            </div>
                            <div class="fs-10px fw-bold"> {{b.cantidadTotalCargada | number:
                              '1.2-2'}}/{{b.cantidadTotal|
                              number: '1.2-2'}}</div>
                          </td>
                          <td class="text-center">{{b.ciudad}}</td>
                          <td class="text-center">
                            <div triggers="click:blur" [ngbTooltip]="getDireccion(b)">{{b.direccion}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                      <a class="btn btn-primary mt-2 d-block" (click)="save()"> <i
                          class="fas fa-download"></i>Agregar</a>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                </div>
              </app-panel-base>
            </div>
          </div>
        </div>
        <div style="width:250px;">
          <app-panel-base title="Información de la orden " [style]="'width:400px'">
            <div class="panel-content">
              <ng-scrollbar class="scroll" [style.height.px]="600">
                <ng-container noBody>
                  <div *ngFor="let b of listaOrdenCarga">
                    <div class="list-group list-group-flush rounded-bottom overflow-hidden panel-body p-0 me-0">
                      <div class="list-group-item list-group-item-action d-flex">
                        <div class="flex-fill">
                          <div class="d-flex align-items-center mb-5px me-0">
                            <button class="btn btn-default btn-icon" style="margin-right: 5px"
                              (click)="imprimirOrden(b)">
                              <i class="fas fa-print"></i>
                            </button>
                            <div style="width: 15%;" class="text-end">
                              <a routerLink="" (click)="generarOrdenSalida(b)" class="fw-bold fs-13px cursor-pointer">
                                {{b.folio}} </a>
                            </div>
                            <div style="width: 2%;">
                            </div>
                            <div style="width: 60%;">
                              {{b.unidad.nombre}}
                            </div>

                            <div class="ms-auto" style="width: 5%;">
                              <a href="#" class="btn btn-outline-default text-gray-900  fs-12px px-2"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#' + getIdOrdencarga(b)">
                                <i class="fas fa-angle-down"></i>
                              </a>
                            </div>
                          </div>
                          <div class="form-group mb-1">
                            <div class="collapse" [id]="getIdOrdencarga(b)">
                              <div class="form-check">
                                <div class="d-flex align-items-center mb-1px"
                                  *ngFor="let fac of b.conceptos; let idx = index">
                                  <div style="width: 25%;" class="text-end">
                                    <a routerLink=""  (click)="consultarDetalle(b,fac)"
                                      class="fw-bold fs-14px cursor-pointer">
                                      {{fac.serie}}{{fac.folio}}</a>
                                  </div>
                                  <div style="width: 2%;">
                                  </div>
                                  <div style="width: 85%;">
                                    <label  class="fw-bold fs-12px">{{getNombreDestino(fac)}}</label>
                                  </div>
                                  <button class="btn btn-outline-danger w-25px btn-icon btn-sm ms-1"
                                    (click)="aliminarFacturaOrdenCarga(b,fac,idx)">
                                    <i class="fa fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="mb-10px bg-gray-600" />
                  </div>
                </ng-container>
              </ng-scrollbar>
            </div>
          </app-panel-base>
        </div>
      </div>

    </form>

    <ng-template #ctrlBusqueda let-modal let-c="close">
      <ctrl-edicion-carga-entrega [factura]="getlistaConceptos"
        [SoloConsulta]="this.mostrarDetalleSoloConsulta"></ctrl-edicion-carga-entrega>
    </ng-template>
    <ng-template #ctrlRegresarProducto let-modal let-c="close">
      <ctrl-edicion-carga-entrega-regresar-productos  [EsCancelacion]="this.EsCancelacion"  ></ctrl-edicion-carga-entrega-regresar-productos>
    </ng-template>

  </div>
</app-container-base>
