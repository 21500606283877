<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  [getLast]="false" subTitle="Traspasos entre Almacenes" (onClickBarButton)="onClickBarButton($event)"
  [activeButtons]="activeButtons">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off" style="display: flex;">
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:700px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important; width:670px;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:150px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:70px;text-align: center;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base *ngIf="sourceProductsEntrada.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:250px;margin-top:80px;width:700px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;width: 670px;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:150px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:70px;text-align: center;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProductsEntrada; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <div style="width: 960px;">
        <app-panel-base title="Información del traspaso" [style]="'width:950px'">
          <div class="panel-content">
            <div class="d-flex">
              <component-ui-combo-box style="width:15%; margin-right: 10px;" formControlName="Serie" [enabled]="true"
                [topLabel]="true" label="Serie" [inputProperty]="'Serie'" [zeroMask]="3" [tabIndex]="1"
                [widthLabelColumns]="12" [widthTextColumns]="12" [entityName]="'SerieTraspaso'"
                (onSelectedItem)="selectSerie($event)" [widthRightColumns]="0" #cboSerieAlm [required]="true"
                helpLine="Serie del traspaso, es un agrupador para los movimientos por Traspasos."
                [filterQuery]="'EsEntreSucursales==false'" [extras]="getExtras" [listProperty]="'SerieNombre'" />

              <components-ui-number-box style="width:7%; margin-right: 10px;" [topLabel]="true" formControlName="Folio"
                helpLine="Folio a emitir o Folio a buscar, indique el número de folio para buscar o bien, presione el botón Nuevo de la barra de botones."
                label='Folio' [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12" (blur)="blurFolio($event)"
                #txtFolioTrasAlm />

              <component-ui-combo-box style="width:27%; margin-right: 10px;" formControlName="AlmacenSalida"
                [topLabel]="true" label="Almacén origen" [inputProperty]="'Nombre'" [zeroMask]="3"
                [entityName]="'Almacen'" [tabIndex]="3" [widthLabelColumns]="12" [widthTextColumns]="12"
                (onSelectedItem)="selectAlmacenSalida($event)" [filterQuery]="getFiltroSalida" [widthRightColumns]="0"
                helpLine="Es el Almacén desde donde salen los productos." [required]="true" #almacenSalida />

              <component-ui-combo-box style="width:27%; margin-right: 10px;" [enabled]="true"
                formControlName="AlmacenDestino" [topLabel]="true" label="Almacén destino" [inputProperty]="'Nombre'"
                [filterQuery]="getFiltroDestino" [zeroMask]="3" [entityName]="'Almacen'" [tabIndex]="4"
                [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0"
                (onSelectedItem)="selectAlmacenDestino($event)" [required]="true" #almacenDestino
                helpLine="Es el Almacén al que entran los productos." />

              <components-ui-date-box style="width:20%; display: flex; justify-content: flex-end;" label="Emisión"
                [tabIndex]="5" formControlName="FechaEmision" [widthLabelColumns]="12" [widthTextColumns]="6"
                [topLabel]="true" helpLine="Es la Fecha del movimiento de Traspaso." #txtFechaEmisionTrasAlm
                (KeyDown.enter)="enterFecha($event)" (KeyDown.tab)="tabFecha($event)" />
            </div>
            {{this.indexEditing}}
            {{this.enviarOtroRenglon}}

          </div>
        </app-panel-base>
        <app-panel-base title="Productos" [style]="'width:950px'">
          <div class="panel-content">
            <ng-scrollbar style="height: 300px; width:915px; border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width:910px;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 60px;">Producto Salida</th>
                    <th style="width: 60px;">Producto Entrada</th>
                    <th style="width: 180px;">Descripción</th>
                    <th style="width: 60px;min-width: 60px;max-width: 60px;text-align:right;padding-right:2px;">Cantidad
                    </th>
                    <th style="width: 80px;">Unidad</th>
                    <th style="width: 120px;">Conversión</th>
                    <th style="width: 60px;">Costo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                    (keydown)="keyDownRow($event, index)" *ngFor="let item of source; let index = index;">
                    <td style="width: 60px;min-width:60px;max-width: 60px;">
                      <input [disabled]="loading" (keyup)="buscarProducto($event)"
                        (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,0)"
                        (keydown)="navegarProductos($event)" (keydown.arrowdown)="down($event,0)"
                        (keydown.enter)="enterProducto($event)" class="grid-editor text-dark"
                        (keydown.tab)="enterProducto($event)"
                        [id]="'txt_'+index+'_0' + idUnico" (focus)="focusProducto(index, $event, item)"
                        (blur)="blurProducto($event,index)" type="text" [value]="item.Producto ? item.Producto.Clave: ''" />
                    </td>
                    <td style="width: 60px;min-width:60px;max-width: 60px;">
                      <input [disabled]="loading" (keyup)="buscarProductoEntrada($event)"
                        (keydown.escape)="escapeProductoEntrada($event)" (keydown.arrowup)="up($event,1)"
                        (keydown)="navegarProductosEntrada($event)" (keydown.arrowdown)="down($event,1)"
                        (keydown.enter)="enterProductoEntrada($event)" class="grid-editor text-dark"
                        (keydown.tab)="enterProductoEntrada($event)"
                        [id]="'txt_'+index+'_1' + idUnico" (focus)="focusProductoEntrada(index, $event, item)"
                        (blur)="blurProductoEntrada($event,index)" type="text"
                        [value]="item.ProductoEntrada ? item.ProductoEntrada.Clave: ''" />
                    </td>
                    <td>
                      <span style="margin-left: 3px;">{{item.Descripcion}}</span>
                    </td>
                    <td style="width: 60px;min-width:60px;max-width: 60px;"><input *ngIf="item.Producto"
                        [disabled]="loading" (keydown)="keyDownCantidad($event)"
                        (keydown.escape)="escapeCantidad($event)" (keydown.arrowup)="up($event,3)"
                        (keydown.arrowdown)="down($event,3)" (keydown.enter)="enterCantidad($event)"
                        class="grid-editor text-dark" [id]="'txt_'+index+'_3' + idUnico" (blur)="blurCantidad($event)"
                        (focus)="setIndexEdit(index, $event, item)" type="number"
                        [style]="'width:100%;text-align:right;'" [value]="item.Producto ? item.Cantidad: 0" />
                    </td>
                    <td class="text-dark">
                      <span style="margin-left: 3px;"> {{item.UnidadNombre}}</span>
                    </td>
                    <td>
                      <a *ngIf="item.TextoConversiones" class="alert-link text-dark" (click)="conversionClick(index)"
                        style="cursor:pointer;margin-left: 3px;">{{item.TextoConversiones}} </a>
                    </td>
                    <td class="text-dark">
                      <span>{{item.Costo != 0 ? (item.Costo| number:'1.2-6'):''}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
            <div class="d-flex">
              <div style="width:60%; padding-top: 10px;">
                <div class="widget-list mb-0" style="display: flex" *ngIf="myForm.value.UsuarioElabora">
                  <div class="widget-list-item" style="width:25%;">
                    <div class="widget-list-media">
                      <i class="fas fa-user-check fa-1x text-green"></i>
                    </div>
                    <div class="widget-list-content">
                      <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre}}</h4>
                      <p class="widget-list-desc">Usuario elaboró</p>
                    </div>
                  </div>
                  <div class="widget-list-item" style="width:40%;" *ngIf="myForm.value.UsuarioCancela">
                    <div class="widget-list-media">
                      <i class="fas fa-user-xmark fa-2x text-red"></i>
                    </div>
                    <div class="widget-list-content">
                      <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                      <p class="widget-list-desc">Usuario canceló</p>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table table-borderless table-sm fw-bold m-0 mt-2" style="width:39%;">
                <tbody>
                  <tr>
                    <td class="pb-2" colspan="2"><b>Registros</b></td>
                    <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                      <b>${{this.totalRegistros|number:'1.2-6'}}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2" colspan="2"><b>Cantidad</b></td>
                    <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                      <b>${{this.totalCantidad|number:'1.2-6'}}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                  <tr *ngIf="!getNoVerCostos">
                    <td class="pb-2" colspan="2"><b>Cantidad</b></td>
                    <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                      <b>${{this.totalCantidad|number:'1.2-6'}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="!getNoVerCostos">
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </app-panel-base>
        <div class="additional-desc" *ngIf="showDescription">
          <app-panel-base title="Descripción">
            <div class="form-group panel-content">
              <textarea class="form-control" formControlName="DescripcionAdicional" rows="10"
                #txtDescription></textarea>
              <div style="text-align: right; margin-top: 5px;">
                <button (click)="acceptDescription()" class="btn btn-success"
                  style="margin-right: 5px;">Aceptar</button>
                <button (click)="cancelDescription()" class="btn btn-danger">Cancelar</button>
              </div>
            </div>
          </app-panel-base>
        </div>
        <app-panel-base title="Comentarios" [style]="'width:950px;height:110px;'">
          <div class="panel-content">
            <textarea maxlength="1000" formControlName="Comentarios" class="w-100" rows="2" cols="50"
              (focus)="focoComentarios($event)"></textarea>
          </div>
        </app-panel-base>
      </div>
      <div style="width:250px;">
        <app-panel-base title="Información del traspaso" *ngIf="myForm.value.Id>0">
          <div class="panel-content">
            <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">
              <div *ngIf="!myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
              <div *ngIf="myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelado</div>
              <div (click)="openCancel()" *ngIf="!myForm.value.FechaCancelacion"
                class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
            </div>
          </div>
        </app-panel-base>
        <app-panel-base title="Estado del Traspaso" *ngIf="myForm.value.Id>0">
          <div class="panel-content">
            <div class="widget-list mb-0" style="display: flex; flex-direction: column;">
              <div class="widget-list-item" style="width:100%;">
                <div class="widget-list-media">
                  <i class="fas fa-car-side fa-2x text-green"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario Envía</p>
                </div>
              </div>
              <div class="widget-list-item" style="width:100%;" *ngIf="myForm.value.UsuarioRecibe">
                <div class="widget-list-media">
                  <i class="fas fa-cart-flatbed-suitcase fa-2x text-green"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.UsuarioRecibe.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario Recibe En Sucursal</p>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div class="widget-list mb-0" style="display: flex; flex-direction: column;">
                <div class="widget-list-item" style="width:100%;" *ngIf="!myForm.value.UsuarioRecibe">
                  <div class="widget-list-media">
                    <i class="fas fa-clock fa-2x text-red"></i>
                  </div>
                  <div class="widget-list-content">
                    <h4 class="widget-list-title">Sin Recibir</h4>
                  </div>
                </div>
                <div class="widget-list-item" style="width:100%;" *ngIf="myForm.value.UsuarioRecibe">
                  <div class="widget-list-media">
                    <i class="fas fa-circle-check fa-2x text-green"></i>
                  </div>
                  <div class="widget-list-content">
                    <p class="widget-list-title">Recibido en Destino</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>
      </div>
    </form>
    <div   class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
      <div class="cancelled">&nbsp;</div>
      <div class="cancelled-detail">{{getCancelDate}}</div>
      <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
    </div>
  </div>
  <ng-template #modalCancelTraspasoAlmacenes let-modal let-c="close">
    <app-opcion-cancelacion-traspaso-page />
  </ng-template>

  <ng-template #ctrlConversiones let-modal let-c="close">
    <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
  </ng-template>


</app-container-base>
