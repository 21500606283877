<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Configuración a tomar en cuenta al emitir documentos">
  <div class="screen-content" style="width: 800px;">
    <form [formGroup]="myForm" autocomplete="off">

      <app-panel-base title="Configuración de Impresión">
        <div class="panel-content">
          <component-ui-check-box helpLine="Seleccione para imprimir después de guardar el documento." [tabIndex]="1"
            [widthLabelColumns]="3" formControlName="ImprimirAlGrabar" [widthTextColumns]="7"
            [label]="'Imprimir al guardar'" />
          <component-ui-check-box helpLine="Seleccione para evitar la reimpresión de documentos." [tabIndex]="2"
            [widthLabelColumns]="3" formControlName="NoPermitirReimprimir" [widthTextColumns]="7"
            [label]="'No reimprimir'" />
          <components-ui-text-box [enabled]="myForm.value.NoPermitirReimprimir" [maxLength]="100" [tabIndex]="3"
            [required]="myForm.value.NoPermitirReimprimir" [label]="'Aplica para'"
            formControlName="NoPermitirReimprimirDoctosAplican" placeHolder="Ejemplo: 10,20,30"
            [helpLine]="'Indique los documentos de ventas para los que aplica la NO reimpresión.'"
            [widthTextColumns]="9" [widthLabelColumns]="3" />
        </div>
      </app-panel-base>
      <app-panel-base title="Resguardos">
        <div class="panel-content">
          <component-ui-check-box helpLine="Seleccione generar un contrato de resguardo de la mercancía." [tabIndex]="4"
            [widthLabelColumns]="3" formControlName="BackOrderImprimirContrato" [widthTextColumns]="7"
            [label]="'Imprimir Contrato'" />
        </div>
      </app-panel-base>

      <app-panel-base title="Cancelación">
        <div class="panel-content">
          <component-ui-check-box helpLine="Seleccione para cancelar el documento copiado de forma automática."
            [tabIndex]="5" [widthLabelColumns]="3" formControlName="CancelarDoctoRelacionadoAlCopiar"
            [widthTextColumns]="7" [label]="'Cancelar docto copiado'" />
          <components-ui-text-box [enabled]="myForm.value.CancelarDoctoRelacionadoAlCopiar" [maxLength]="100"
            [tabIndex]="6" [Entidad]="'DocumentoVenta'" [required]="myForm.value.CancelarDoctoRelacionadoAlCopiar"
            [label]="'Aplica para'" formControlName="CancelarDoctoRelacionadoAlCopiarDoctosAplican"
            placeHolder="Ejemplo: 10,20,30"
            [helpLine]="'Indique los documentos de ventas para los que aplica la cancelación automática.'"
            [widthTextColumns]="9" [widthLabelColumns]="3" />

          <component-ui-check-box
            helpLine="Al cancelar un documento, el sistema enviará el documento a un proceso de cancelación donde otro usuario procesará dicha cancelación."
            [tabIndex]="7" [widthLabelColumns]="3" formControlName="UsarProcesoSolicitudCancelacion"
            [widthTextColumns]="7" [label]="'Usar Solicitud Cancelación'" />

          <components-ui-text-box [enabled]="myForm.value.UsarProcesoSolicitudCancelacion" [maxLength]="100"
            [tabIndex]="8" [Entidad]="'DocumentoVenta'" [required]="myForm.value.UsarProcesoSolicitudCancelacion"
            [label]="'Aplica para'" formControlName="DocumentosAplicanSolicitudCancelacion"
            placeHolder="Ejemplo: 10,20,30"
            [helpLine]="'Indique los documentos de ventas para los que aplica la cancelación solicitud de cancelación interna.'"
            [widthTextColumns]="9" [widthLabelColumns]="3" />

        </div>
      </app-panel-base>

      <app-panel-base title="Captura">
        <div class="panel-content">
          <component-ui-check-box
            helpLine="Al momento de capturar un documento, el sistema no permitira que el usuario pueda modificar el precio unitario."
            [tabIndex]="9" [widthLabelColumns]="3" formControlName="NoModificarPrecio" [widthTextColumns]="7"
            [label]="'Bloquear precio'" />

          <component-ui-check-box
            helpLine="Al momento de capturar un documento, el sistema no permitira que el usuario pueda modificar el descuento."
            [tabIndex]="10" [widthLabelColumns]="3" formControlName="NoModificarDescuento" [widthTextColumns]="7"
            [label]="'Bloquear descuento'" />

          <components-ui-number-box [enabled]="!myForm.value.NoModificarDescuento" [maxLength]="4" [tabIndex]="11"
            [label]="'Máximo % descuento'" formControlName="MaximoDescuento"
            [helpLine]="'Máximo % de descuento que se otorgará.'" [widthTextColumns]="2" [widthLabelColumns]="3" />

          <component-ui-check-box
            helpLine="El sistema permitirá modificar el cálculo automático de otras unidades, esto por diferencia entre el peso real y el teórico."
            [tabIndex]="12" [widthLabelColumns]="3" formControlName="PermitirCambiarConversiones" [widthTextColumns]="7"
            [label]="'Cambiar conversiones'" />

          <components-ui-number-box [enabled]="myForm.value.PermitirCambiarConversiones" [maxLength]="100"
            [tabIndex]="13" [required]="myForm.value.PermitirCambiarConversiones" [label]="'% variación'"
            formControlName="PorcentajeDiferencia" [helpLine]="'Porcentaje de diferencia hacía arriba o abajo.'"
            [widthTextColumns]="2" [widthLabelColumns]="3" />

          <component-ui-check-box helpLine="Al guardar un documento, el sistema obligará a que capture comentarios."
            [tabIndex]="14" [widthLabelColumns]="3" formControlName="ComentariosObligatorios" [widthTextColumns]="7"
            [label]="'Cambiar conversiones'" />

          <components-ui-text-box [enabled]="myForm.value.ComentariosObligatorios" [maxLength]="100" [tabIndex]="15"
            [required]="myForm.value.ComentariosObligatorios" [label]="'Aplica para'" [Entidad]="'DocumentoVenta'"
            formControlName="ComentariosObligatoriosDoctosAplican" placeHolder="Ejemplo: 10,20,30"
            [helpLine]="'Indique qué al guardar un documento, el sistema obligará a que capture comentarios.'"
            [widthTextColumns]="9" [widthLabelColumns]="3" />

          <component-ui-check-box
            helpLine="El sistema no permitirá capturar ningún documento de venta si no tiene un precio configurado en la lista de precios."
            [tabIndex]="16" [widthLabelColumns]="3" formControlName="NoPermitirPrecioCero" [widthTextColumns]="7"
            [label]="'No permite precio cero'" />

          <component-ui-check-box helpLine="El sistema mostrará la opción para consultar las listas de precios."
            [tabIndex]="16" [widthLabelColumns]="3" formControlName="MostrarListaPrecios" [widthTextColumns]="7"
            [label]="'Mostrar Listas de Precios'" />

          <component-ui-check-box helpLine="El sistema validará el uso de una caja de cobro." [tabIndex]="17"
            [widthLabelColumns]="3" formControlName="UsarModoPuntoDeVenta" [widthTextColumns]="7"
            [label]="'Usar Modo POS (Corte de Caja)'" />




        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
