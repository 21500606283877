import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-diot',
  templateUrl: './verificador-diot.component.html',
})
export class VerificadorDiotComponent {

  public get utils(): UtilsService {
    return this._utils;
  }
  public set utils(value: UtilsService) {
    this._utils = value;
  }

  menuId: string = "diot-verificador";

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  // Empresa-Sucursal

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Proveedor-Fecha', Orden: 'pro.Clave ,diot.tasa,diot.fecha ' },{Nombre: 'Proveedor-Tasa Impto.', Orden: 'pro.Clave'}]
  public filtrosReporte: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificador DIOT',
      NombreExcel: 'Verificador DIOT.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'pro.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'ter.Clave', Etiqueta: 'Tipo Tercero', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "TipoTerceroDIOT" },
        { Campo: 'ope.Clave', Etiqueta: 'Tipo Operación', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "TipoOperacionDIOT" },
        { Campo: 'diot.Factura', Etiqueta: 'Factura', Tipo: TypeFilter.number, BotonBusqueda: false },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private _utils: UtilsService, private reportsService: ReportsService) { }

  ngOnInit(): void {
    const fecha: string = this._utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DECLARACIÓN INFORMATIVA DE OPERACIONES CON TERCEROS ' + fecha.toUpperCase());
    this.focus('txtNombreReporte');
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  onclicSinAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc3')?.setValue(!value);
    }
  }

  onclicSoloAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  onclicSinFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc4')?.setValue(!value);
    }
  }

  onclicSoloFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc2')?.setValue(!value);
    }
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Fiscal/VerificadorDiot');
  }

 }
