<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; position: absolute;top:183px;width: 1160px;" [style.left] = "this.margen">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Productos utilizados en la elaboración de los productos finales
    </span>
  </div>
  <div class="card-body" style="min-height: 320px; max-height: 320px;">
    <app-panel-base title="Relación de componentes utilizados">
      <div class="panel-content">
        <ng-scrollbar style="height: 130px; width:1120px; border-bottom: 1px solid #AAAAAA;">
          <table class="blueTable" style="width:1110px;">
            <thead style="position: sticky;top:0;">
              <tr>
                <th style="width: 70px;">Fecha</th>
                <th style="width: 50px;">Referencia</th>
                <th style="width: 140px;">Producto final</th>
                <th style="width: 40px;">Orden Prod.</th>
                <th style="width: 120px;">Producto usado</th>
                <th style="width: 30px;">Cantidad</th>
                <th style="width: 40px;">Unidad</th>
                <th style="width: 40px;">Usuario</th>
              </tr>
            </thead>
            <tbody>
              <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
                *ngFor="let item of lista; let index = index;">
                <td class="text-dark" style="width:70px">
                  {{getDate(item.fecha)}}
                </td>
                <td class="text-dark" style="width:50px">
                  {{item.referencia }}
                </td>
                <td class="text-dark" style="width:140px">
                  {{item.productoProducido }}
                </td>
                <td class="text-dark" style="width:40px">
                  {{item.serieFolio }}
                </td>
                <td class="text-dark" style="width:120px">
                  {{item.producto }}
                </td>
                <td class="text-dark" style="width:30px;padding-right:5px;">
                  {{item.cantidad|number:'1.2-6'}}
                </td>
                <td class="text-dark" style="width:40px">
                  {{item.unidad }}
                </td>
                <td class="text-dark" style="width:40px">
                  {{item.usuario }}
                </td>

              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
        <!-- <gui-grid [source]="lista" [autoResizeWidth]="false" [width]="1100" [virtualScroll]="true" [rowHeight]="20"
      [rowSelection]="false" [theme]="'fabric'" [maxHeight]="500">
      <gui-grid-column header="Fecha" [width]="150" [cellEditing]="false">
        <ng-template let-item="item">
          {{getDate(item.fecha)}}
        </ng-template>
      </gui-grid-column>
      <gui-grid-column header="Referencia" field="referencia" [width]="100" [cellEditing]="false" />
      <gui-grid-column header="Producto Final" field="productoProducido" [width]="250" [cellEditing]="false" />
      <gui-grid-column header="Orden" field="serieFolio" [width]="100" [cellEditing]="false" />
      <gui-grid-column header="Producto usado" field="producto" [width]="150" [cellEditing]="false" />
      <gui-grid-column header="Cantidad" field="cantidad" [width]="100" [cellEditing]="false">
        <ng-template let-item="item">
          {{item.cantidad | number:'1.2-6'}}
        </ng-template>
      </gui-grid-column>
      <gui-grid-column header="Unidad" field="unidad" [width]="100" [cellEditing]="false" />
      <gui-grid-column header="Usuario" field="usuario" [width]="150" [cellEditing]="false" />
    </gui-grid> -->
      </div>
    </app-panel-base>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>
