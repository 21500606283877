import { group, animate, query, style, trigger, transition, state } from '@angular/animations';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'sidebar-right',
  templateUrl: './sidebar-right.component.html'
})

export class SidebarRightComponent {
  @Input() enviarMensajeChat: boolean = false;

  @Output() appSidebarEndMobileToggled = new EventEmitter<boolean>();

  toggleAppSidebarEndMobile() {
    this.appSidebarEndMobileToggled.emit(true);
  }

  constructor(public appSettings: AppSettings) {

  }

  public get getMode(): string {
    return this.appSettings.appDarkMode ? "dark" : "";
  }

}
