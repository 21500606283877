<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="SerieVenta"
  title="Series de Ventas" icon="fa fa-building" subTitle="Administra la configuración de Series de Ventas">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie de Venta">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Serie de Venta'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="3" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieVenta" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="4" [required]="true"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2" [maxlength]="20" />

          <component-ui-combo-box label='Documento de venta' formControlName="DocumentoVenta"
            entityName="DocumentoVenta" [required]="true" [tabIndex]="6" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'DocumentoVenta')" [zeroMask]="3" />

          <components-ui-text-box [label]="'Clase de ventas'" formControlName="ClasesDeVentas" [maxLength]="100"
            [tabIndex]="7" [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Copias'" formControlName="Copias" [maxLength]="100" [tabIndex]="8"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-number-box [label]="'Inicia con folio'" formControlName="IniciarConFolio" [tabIndex]="9"
            [required]="false" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <component-ui-check-box [tabIndex]="10" [widthLabelColumns]="2" formControlName="ParaFacturaGlobal"
            [label]="'Para factura global'" />

          <component-ui-check-box [tabIndex]="11" [widthLabelColumns]="2" formControlName="ParaAplicacionAnticipo"
            [label]="'Para aplicación de anticipos'" />
        </div>

      </app-panel-base>



      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#serieVentas-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Web Ticket
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#serieVentas-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Formato</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#serieVentas-tab-3" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Manufactura</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#serieVentas-tab-4" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#serieVentas-tab-5" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Observaciones</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#serieVentas-tab-6" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Impresión</span>
          </a>
        </li>
      </ul>

      <div class="tab-content panel rounded-0 p-3 me-2 ms-2 mt-0">
        <div id="serieVentas-tab-1" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Web Ticket">
            <div class="panel-content">
              <component-ui-check-box [tabIndex]="12" [widthLabelColumns]="2" formControlName="EnviarWebTicket"
                [label]="'Envíar a web tickets'" />

              <component-ui-check-box [tabIndex]="13" [widthLabelColumns]="2" formControlName="UsarPOS"
                [label]="'Usar para POS'" />
            </div>
          </app-panel-base>
        </div>
        <div id="serieVentas-tab-2" class="tab-pane" role="tabpanel">
          <app-panel-base title="Formatos">
            <div class="panel-content">
              <!-- <components-ui-text-box [label]="'CFDI'" formControlName="Formato" [maxLength]="50" [tabIndex]="14"
                [widthTextColumns]="3" [widthLabelColumns]="2" /> -->
                <component-ui-combo-box  (onSelectedItem)="selectedComboFormato($event,'Formato')" [selectManualItem]="getFormatoImpresion"
                [setItemsList]="listaFormatosImpresion" listProperty='Nombre' label="CFDI" [tabIndex]="14"  [inputProperty]="'Nombre'"
                [widthLabelColumns]="2" [widthTextColumns]="3" [required]="false"/>

              <components-ui-text-box [label]="'Autotransporte'" formControlName="FormatoAutoTransporte"
                [maxLength]="50" [tabIndex]="15" [widthTextColumns]="3" [widthLabelColumns]="2" />

              <components-ui-text-box [label]="'Marítimo'" formControlName="FormatoMaritimo" [maxLength]="50"
                [tabIndex]="16" [widthTextColumns]="3" [widthLabelColumns]="2" />

              <components-ui-text-box [label]="'Aéreo '" formControlName="FormatoAereo" [maxLength]="50" [tabIndex]="17"
                [widthTextColumns]="3" [widthLabelColumns]="2" />

              <components-ui-text-box [label]="'Comercio exterior'" formControlName="FormatoComercioExterior"
                [maxLength]="50" [tabIndex]="18" [widthTextColumns]="3" [widthLabelColumns]="2" />

            </div>
          </app-panel-base>

        </div>
        <div id="serieVentas-tab-3" class="tab-pane" role="tabpanel">

          <app-panel-base title="Manufactura">
            <div class="panel-content">
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-object-ungroup fa-lg fa-fw"></i>
                Seleccione la serie de manufactura, para generar una orden de producción
              </div>
              <component-ui-combo-box label='Serie' formControlName="SerieManufactura" entityName="SerieManufactura"
                [tabIndex]="19" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'SerieManufactura')" [zeroMask]="3"
                [placeHolder]="'Escriba las observaciones'" />
            </div>
          </app-panel-base>
        </div>
        <div id="serieVentas-tab-4" class="tab-pane" role="tabpanel">
          <app-panel-base title="Pólizas automáticas">
            <div class="panel-content">
              <component-ui-combo-box [label]="'Póliza'" filterQuery="DisponibleParaRegenerar = true"
                formControlName="Poliza" [tabIndex]="20" [zeroMask]="2" entityName="PolizaAutomatica"
                [widthTextColumns]="1" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'Poliza')" [listProperty]="'ClaveNombre'" />
            </div>
          </app-panel-base>
        </div>
        <div id="serieVentas-tab-5" class="tab-pane" role="tabpanel">
          <app-panel-base title="Observaciones a imprimir">
            <div class="panel-content">
              <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false"
                  formControlName="Observaciones"></ngx-editor>
              </div>
            </div>
          </app-panel-base>
        </div>
        <div id="serieVentas-tab-6" class="tab-pane" role="tabpanel">
          <app-panel-base title="Imprimir en Ticketera (Solo aplica para documentos internos)">
            <div class="panel-content">
              <component-ui-check-box [tabIndex]="21" [widthLabelColumns]="2" formControlName="UsaTicketera"
                [label]="'Imprimir En Ticketera'" />
              <components-ui-text-box [label]="'Nombre Ticketera'" formControlName="NombreTicketera" [tabIndex]="22"
                [required]="myForm.value.UsaTicketera" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxlength]="100" />
            </div>
          </app-panel-base>
          <app-panel-base title="Imprimir directo en impresora">
            <div class="panel-content">
              <component-ui-check-box [tabIndex]="23" [widthLabelColumns]="2" formControlName="UsaImpresora"
                [label]="'Usa Impresora'" />
              <components-ui-text-box [label]="'Nombre Impresora'" formControlName="NombreImpresora" [tabIndex]="24"
                [required]="myForm.value.UsaTicketera" [widthTextColumns]="3" [widthLabelColumns]="2"
                [maxlength]="100" />
            </div>
          </app-panel-base>
        </div>
      </div>









    </form>
  </div>

</app-container-base>
