import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ClaseCliente, TipoCliente } from '../../interfaces/tipocliente.interface';
import { Cliente, c_RegimenFiscal, c_UsoCfdi } from '../../interfaces/cliente.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Direccion, DireccionSAT } from 'src/app/configuracion/interfaces/direccion.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { CondicionPago } from '../../interfaces/condicionpago.interface';
import { c_FormaPago } from '../../interfaces/formapago.interface';
import { ClaseVenta } from '../../interfaces/claseventa.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ListaPrecio } from '../../interfaces/listaprecio.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { UserLogged } from 'src/app/auth/interfaces';

@Component({
  selector: 'app-clientes-page',
  templateUrl: './clientes-page.component.html',
  styleUrls: ['./clientes-page.component.scss'],
})
export class ClientesPageComponent implements OnInit {
  cobranza: any = {
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false
  }

  noActivarOpcionesCredito: boolean = false;
  public itemsCombo: ComboBoxEntity[] = [];
  filter = "";
  info: UserLogged = { ambienteId: 0, numero: 0, clave: 0, nombreUsuario: "", nombreCompletoUsuario: "" };
  public EDT = EnumDataType;
  activeButtons: ActiveButtons = {
    new: true,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: true,
    print: false,
    exit: false
  }

  @Input()
  public esVentana: boolean = false;

  private newEntityObj = {
    Empresa: [{} as Empresa, Validators.required],
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Rfc: ['', [Validators.required, Validators.pattern(this.configuracionService.RegExRfc)]],
    RegimenFiscal: [{} as c_RegimenFiscal, Validators.required],
    UsoCfdi: [{} as c_UsoCfdi, Validators.required],
    Direccion: [{} as Direccion, Validators.required],
    DireccionSAT: [{} as DireccionSAT],
    NumRegIdTrib: [''],
    Moneda: [{} as Moneda, Validators.required],
    CondicionDePago: [{} as CondicionPago, Validators.required],
    FormaDePago: [{} as c_FormaPago, Validators.required],
    Telefonos: [''],
    ReferenciaBancaria: [''],
    Correos: [''],
    Tipo: [{} as TipoCliente, Validators.required],
    Clase: [{} as ClaseCliente, Validators.required],
    ClaseVenta: [{} as ClaseVenta, Validators.required],
    ClaseVenta2: [{} as ClaseVenta],
    ClaseVenta3: [{} as ClaseVenta],
    ListaPrecio: [{} as ListaPrecio],
    Vendedor: [{} as Usuario],
    Cobrador: [{} as Usuario],
    LimiteCredito: [0],
    Descuento: [0],
    NoValidarCredito: [false],
    ParaTickets: [false],
    CreditoSuspendido: [false],
    SuspenderCreditoAlFacturar: [false],
    Comentarios: [''],
    ParaFacturaGlobal: [false],
    ClienteBaseSistema: [false],
    NoAnticipos: [false],
    Asientos: [[]],
    Baja: [false],
    LunesCobranza: [false],
    MartesCobranza: [false],
    MiercolesCobranza: [false],
    JuevesCobranza: [false],
    ViernesCobranza: [false],
    SabadoCobranza: [false],


  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('txtClave')
  public txtClave!: ElementRef<TextBoxComponent>;

  @ViewChild('seleccionarCuenta')
  public ctrlCuenta!: ElementRef<HTMLElement>;



  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService,
    private utilsService: UtilsService,
    private ms: ModalService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero}`
    if (this.esVentana) {
      this.activeButtons = {
        new: true,
        delete: true,
        return: true,
        save: true,
        first: true,
        left: true,
        right: true,
        last: true,
        search: true,
        print: true,
        exit: true
      }
    }
    this.containerService.obtenerValorPropiedad("Usuario", "Rol.NoActivarOpcionesCredito", this.info.numero).subscribe((value) => {
      this.noActivarOpcionesCredito = value.toLowerCase() == "true";
    });
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Cliente) {
    this.myForm.reset(entity);
  }

  onItemSearched(ent: Cliente): void {
    if (ent) {
      if (ent.Empresa) {
        this.filter = `Empresa.Id = ${ent.Empresa.Id}`;
      } else {
        this.filter = '';
        if (this.myForm.value) {
          if (this.myForm.value.Empresa) {
            ent.Empresa = this.myForm.value.Empresa;
            if (!ent.Empresa["Id"]) {
              this.myForm.controls["Empresa"].setValue({ Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre })
              ent.Empresa = this.myForm.value.Empresa;
              this.filter = `Empresa.Id = ${this.info.empresa!.numero}`;
            } else {
              this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id}`
            }
          }
        }
      }
      this.myForm.reset(ent);
      this.focus('txtNombre')

      this.cobranza = {
        lunes: ent.LunesCobranza,
        martes: ent.MartesCobranza,
        miercoles: ent.MiercolesCobranza,
        jueves: ent.JuevesCobranza,
        viernes: ent.ViernesCobranza,
        sabado: ent.SabadoCobranza
      }
    }
  }

  enterField(e: any) {
    const txt: any = this.txtClave;
    txt.tagInput.nativeElement.blur();
  }

  openBankAccounts() {
    const b: any = this.ctrlCuenta;
    this.ms.openModal(b, (e: any) => {
    }, 'lg')
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  blur(values: any) {
    const txt: any = this.txtClave;
    if (!txt.value) {
      this.containerService.getLastEntity("Cliente", this.filter).subscribe((ent) => {
        this.onChangueEntity(ent);
      });
      return;
    }
    if (values.before != values.after) {
      this.searchByField(txt.value);
    }
  }

  searchByField(value: any) {
    this.containerService.getEntityByClave("Cliente", parseInt(value), this.filter).subscribe((ent) => {
      if (ent) {
        this.onChangueEntity(ent);
      } else {
        this.containerService.getEmptyEntity("Cliente")
          .subscribe((ent) => {
            this.onChangueEntity({ ...ent, Clave: parseInt(value) });
          });
      }
    });
  }

  get getIsNew(): any {
    return this.myForm.value.Id == 0;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.onItemSearched(ent);
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  get getEnableText(): boolean {

    if (this.myForm.value) {
      if (this.myForm.value.Id == 0) {
        return false;
      }
    }

    return true;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  get getIsBaseCustomer(): boolean {
    if (this.myForm.value) {
      return this.myForm.value.ClienteBaseSistema;
    }
    return false;
  }

  get getFiltro(): string {
    if (this.myForm.value.Rfc) {
      if (this.myForm.value.Rfc?.length == 12) {
        return "Moral = true";
      } else if (this.myForm.value.Rfc?.length == 13) {
        return "Fisica = true";
      }
    }
    return "";
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  changeCheckboxDiasCobro(event: any, dia: number) {
    let value = event.srcElement.checked;
    switch (dia) {
      case 1:
        this.myForm.value.LunesCobranza = value;
        this.cobranza.lunes = value;
        break;
      case 2:
        this.myForm.value.MartesCobranza = value;
        this.cobranza.martes = value;
        break;
      case 3:
        this.myForm.value.MiercolesCobranza = value;
        this.cobranza.miercoles = value;
        break;
      case 4:
        this.myForm.value.JuevesCobranza = value;
        this.cobranza.jueves = value;
        break;
      case 5:
        this.myForm.value.ViernesCobranza = value;
        this.cobranza.viernes = value;
        break;
      default:
        this.myForm.value.SabadoCobranza = value;
        this.cobranza.sabado = value;
        break;
    }
  }
}
