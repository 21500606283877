import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, AfterContentInit, AfterViewChecked, AfterViewInit, AfterContentChecked, ElementRef } from '@angular/core';
import { ColumnMode, DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { BarraBotonesService } from '../barra-botones/barra-botones.service';



@Component({
  selector: 'grid-busqueda',
  templateUrl: './grid-busqueda.component.html',
  styles: [
  ]
}) export class GridBusquedaComponent implements OnInit, AfterContentChecked {


  @ViewChild('modal') modalDialog: ElementRef<HTMLInputElement> | any;

  actioncols: [] | any = [];
  public temp: [] = [];
  public rows: [] = [];
  public objEliminar: any;

  public firstSearch: boolean = false;

  @ViewChild(DatatableComponent) table!: DatatableComponent;

  @ViewChild('editTmpl', { static: true }) editTmpl!: TemplateRef<any>;
  @ViewChild('hdrTpl', { static: true }) hdrTpl!: TemplateRef<any>;

  @ViewChild('deletehdrTpl', { static: true }) deletehdrTpl!: TemplateRef<any>;
  @ViewChild('deleteTmpl', { static: true }) deleteTmpl!: TemplateRef<any>;


  @Input()
  public ColumnsConfig: TableColumn[] = [];

  @Input()
  public ColumnMode: ColumnMode = ColumnMode.force;

  @Input()
  public registros: [] = [];

  @Input()
  public Entidad: string = '';

  @Input()
  public EntidadVacia: any;

  @Output()
  Eliminar: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  Editar: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  Nuevo: EventEmitter<any> = new EventEmitter<any>();

  constructor(private barraBotonesService: BarraBotonesService) {
  }

  ngAfterContentChecked(): void {
    if ((!this.firstSearch && this.registros.length > 0) || this.registros.length !== this.temp.length){
      this.temp = [...this.registros];
      this.rows = this.registros as [];
      this.firstSearch = true;
    }
  }

  ngOnInit(): void {


    this.actioncols = [{
      cellTemplate: this.editTmpl,
      headerTemplate: this.hdrTpl,
      name: 'Editar'
    },
    {
      cellTemplate: this.deleteTmpl,
      headerTemplate: this.deletehdrTpl,
      name: 'Eliminar'
    }]

    this.ColumnsConfig = [...this.ColumnsConfig, ...this.actioncols]
  }

  updateFilter(event: any) {

    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d: any) {
      if (typeof (d.Nombre) !== 'string') {
        return false;
      }
      return d.Nombre.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp as [];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }


  onEliminar(value: any) {

    const obj = this.rows.find(x => x['Id'] == value);
    this.objEliminar = obj;
  }

  onAceptarEliminar() {

    this.Eliminar.emit(this.objEliminar.Id);
  }



  onEditar(value: any) {

    const obj = this.rows.find(x => x['Id'] == value);

    this.Editar.emit(obj);

  }

  onNuevo() {
    this.barraBotonesService.nuevo(this.Entidad).subscribe(
      (nuevoEntidad: any) => {
        this.Nuevo.emit(nuevoEntidad);
      }
    )
  }






}
