<ng-template #tipDark>
  <div style="width: 70px;">Oscuro</div>
</ng-template>
<ng-template #tipLight>
  <div style="width: 70px;">Claro</div>
</ng-template>

<!-- BEGIN #header -->
<div id="header" class="app-header" style="border-bottom:2px solid #fd8204;"
  [attr.data-bs-theme]="this.appSettings.appHeaderInverse ? 'dark' : ''">
  <!-- BEGIN nav -->
  <div class="navbar-nav">
    <label class="navbar-brand" routerLink="/mobile/" matRipple>
      <img class="logo-complit-positivo corto" src="../../../../assets/img/logo/SW-COMPLIT-CORTO-positivo.png"
        alt="logo.png">
    </label>
    <div class="navbar-item">
      <button type="button" class="btn btn-info me-10px radius" (click)="onClickTipoCambio()">
        TC: {{tipoCambio|number:'1.2-6'}}
      </button>
    </div>
    <div class="navbar-divider"></div>
    <div class="navbat-item">
      <span class="btn-group float-end ms-10px me-10px p-2px bg-black bg-opacity-20 rounded">
        <button class="btn btn-sm btn-white border-0" [ngClass]="{'active': lightMode}" (click)="widgetLightMode()"
          placement="bottom" [ngbTooltip]="tipLight" [openDelay]="100" [closeDelay]="100">
          <i class="fa fa-sun" [ngClass]="{'text-blue': lightMode}"></i>
        </button>
        <button class="btn btn-sm btn-white border-0" [ngClass]="{'active': darkMode}" (click)="widgetDarkMode()"
          placement="bottom" [ngbTooltip]="tipDark" [openDelay]="100" [closeDelay]="100">
          <i class="fa fa-moon" [ngClass]="{'text-blue': darkMode}"></i>
        </button>
      </span>
    </div>

    <div class="navbar-divider"></div>
    <div class="navbar-item navbar-user">
      <a href="javascript:;" class="navbar-link dropdown-toggle d-flex align-items-center">
        <photo-avatar [name]="usuarioLogeado.nombreCompletoUsuario"></photo-avatar>
        <span [ngbTooltip]="usuarioLogeado.nombreCompletoUsuario" class="nombre-usuario">
          <span class="d-none d-md-inline">{{usuarioLogeado.nombreCompletoUsuario | titlecase}}</span>
        </span>
      </a>
    </div>

    <div class="navbar-divider"></div>
    <div class="navbar-item" *ngIf="this.appSidebarTwo && permisosTableros?.verChat">
      <a href="javascript:;" (click)="toggleAppSidebarEnd()" class="navbar-link icon">
        <i class="fa fa-users"></i>
      </a>
    </div>
    <div class="navbar-divider"></div>
    <div class="navbar-item">
      <a href="javascript:;" (click)="logOut()" class="navbar-link icon">
        <i class="fa fa-door-open"></i>
      </a>
    </div>
  </div>
  <!-- END nav -->
</div>
<!-- END #header -->
