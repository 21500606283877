import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-cargos-adicionales-page',
  templateUrl: './verificador-cargos-adicionales-page.component.html',
  styleUrls: ['./verificador-cargos-adicionales-page.component.scss']
})
export class VerificadorCargosAdicionalesPageComponent {

  private fb = inject(FormBuilder);
  private utils = inject(UtilsService);
  private reportsService = inject(ReportsService);

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public frmVerificadorAdicionales: FormGroup = this.fb.group({
    NombreReporte: 'VERIFICADOR DE CARGOS ADICIONALES',
    Fecha1: new Date(),
    Fecha2: new Date(),
  });

  onChangeFecha(value: any) {
    this.frmVerificadorAdicionales.get('NombreReporte')?.setValue('VERIFICADOR DE CARGOS ADICIONALES ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this.utils.getEtiquetaRangoPorFechas(this.frmVerificadorAdicionales.value.Fecha1, this.frmVerificadorAdicionales.value.Fecha2, 2).toUpperCase();
  }

  public filtrosVerificadorAdicionales: ReportFilter =
    {
      ReportHeader: {
        NombreReporte: 'VERIFICADOR DE CARGOS ADICIONALES'
      } as ReportHeader,
      TituloVisor: 'Verificador de Cargos Adicionales',
      NombreExcel: 'VerificadorCargosAdicionales.xlsx',
      FilterOptions: [
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", HelpLine: "Claves de Sucursales a consultar separadas por coma (1,2,3)." },
        { Campo: 'dc.Clave', Etiqueta: 'Documento Compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "DocumentoCompra", HelpLine: "Claves de los Clientes a consultar separadas por coma (1,2,3)." },
        { Campo: 'compra.SerieString', Etiqueta: 'Serie Compra', Tipo: TypeFilter.text, HelpLine: "Serie de las Compras a consultar." },
        { Campo: 'compra.Folio', Etiqueta: 'Folio Compra', Tipo: TypeFilter.text, HelpLine: "Folios de las Compras a consultar." },
        { Campo: 'provcompra.clave', Etiqueta: 'Proveedor Compra', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Proveedor", HelpLine: "Claves de los Proveedores a consultar separadas por coma (1,2,3)." },
        { Campo: 'provcargo.Clave', Etiqueta: 'Proveedor Cargo', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Proveedor", HelpLine: "Claves de los Proveedores a consultar separadas por coma (1,2,3)." },
        { Campo: 'compra.FechaCancelacion,', Etiqueta: 'Estado', Tipo: TypeFilter.list, ListaItems: [{ Id: "1", Nombre: 'Todos' }, { Id: "2", Nombre: 'Vigente' }, { Id: "3", Nombre: 'Cancelado' }], HelpLine: "Estado actual del REP." },
        { Campo: 'cac.Clave', Etiqueta: 'Cargos Adicionales', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "CargoAdicionalCompra", HelpLine: "Clave de Cargos Adicionales separados por cosa (1,2,3)." },
        { Campo: 'almacen.Clave', Etiqueta: 'Almacén', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Almacen", HelpLine: "Claves de los ALmacenes a consultar separadas por coma (1,2,3)." },
        { Campo: 'usuElabora.Clave', Etiqueta: 'UsuarioElabora', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Usuario", HelpLine: "Claves de los Usuarios a consultar separadas por coma (1,2,3)." },
      ]
    };

  changeselec(event: any) {
    this.filtrosVerificadorAdicionales = event;
  }

  clickBoton() {
    this.filtrosVerificadorAdicionales.ReportHeader = this.frmVerificadorAdicionales.value;
    this.reportsService.printReport(this.filtrosVerificadorAdicionales, '/Compras/ReporteVerificadorCargosAdicionales');
  }
}
