import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileLayoutComponent } from './layout/mobile-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { VisitasComponent } from './pages/visitas/visitas.component';
import { ProspectosMobilePageComponent } from './pages/prospectos-mobile-page/prospectos-mobile-page.component';

export const routes: Routes = [
  {
    path: '',
    component: MobileLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent, data: { title: 'Inicio' }
      },
      {
        path: 'calendar',
        component: CalendarComponent, data: { title: 'Calendario de Actividades' },
      },
      {
        path: 'visitas',
        component: VisitasComponent, data: { title: 'Registrar Visita' },
      },
      {
        path: 'prospectos',
        component: ProspectosMobilePageComponent, data: { title: 'Registrar Prospecto' },
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MobileRoutingModule { }
