import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styles: [
  ]
})
export class DropDownComponent {
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public items: string[] = [];
  @Input()
  public icon: string = "tags";
}
