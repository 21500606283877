import { Component, inject } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'terminales',
  templateUrl: './terminales.component.html',
  styleUrls: ['./terminales.component.scss']
})
export class TerminalesComponent {
  private modalService = inject(ModalService);

  terminales: any[] = [];

  close() {
    this.modalService.closeModal(null);
  }

  agregarEditar() {

  }
}
