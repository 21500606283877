<app-container-base (onDelete)="onDelete()" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [setIsNew]="getIsNew"
  [searchItemsCombo]="false" [getLast]="false" entityName="TipoDeCambio" [entity]="myForm" title="Tipos de Cambio"
  (onClickBarButton)="onClickBarButton($event)" icon="fa fa-coins" [activeButtons]="activeButtons"
  subTitle="Administra los Tipos de Cambio">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información" style="width:700px" [itemsMenuDropDown]="menuItems" (onClicMenu)="clicMenu($event)">
        <div class="panel-content">

          <component-ui-combo-box [filterQuery]="'EsExtranjera = true'" [label]="'Moneda'" [required]="true"
            formControlName="Moneda" [tabIndex]="1" [zeroMask]="2" entityName="Moneda" [widthTextColumns]="3"
            [widthLabelColumns]="3" [widthRightColumns]="4" (onSelectedItem)="selectedCombo($event,'Moneda')"
            [listProperty]="'ClaveNombre'" #cboMoneda />

          <components-ui-date-box (onChangeValue)="blurFecha($event)" label="Fecha" [tabIndex]="2" [required]="true"
            formControlName="Fecha" [widthLabelColumns]="3" [widthTextColumns]="6" />


          <components-ui-number-box [required]="true" [maxLength]="12" [label]="'Tipo De Cambio Ventas'"
            formControlName="TipoCambio" [tabIndex]="3" [widthTextColumns]="2" [widthLabelColumns]="3" />

          <components-ui-number-box [maxLength]="12" [label]="'Tipo De Cambio Compras'"
            formControlName="TipoCambioCompra" [tabIndex]="4" [widthTextColumns]="2" [widthLabelColumns]="3" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
