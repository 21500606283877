<div cdkDrag class="data-search card border-1 impresion-productos">
  <div class="card-header" cdkDragHandle>
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    <h5>Impresión de Productos</h5>
  </div>
  <div class="card-body bg-white-100 p-0">
    <app-container-base [hasBar]="false" [hasCounter]="false" [hasHeader]="false" [containerInnerHeight]="0"
      [esPopup]="true">
      <div class="screen-content">
        <app-panel-base title="">
          <div class="panel-content">
            <form [formGroup]="frmImpresionProductos" autocomplete="off">
              <app-panel-base title="Generales">
                <div class="panel-content">
                  <div class="row">
                    <div class="col-md-12">
                      <components-ui-text-box [required]="true" label='Nombre del Reporte'
                        formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="3" [widthTextColumns]="8"
                        [maxLength]="100" #txtNombreReporte />
                    </div>

                    <div class="col-md-12">
                      <component-ui-combo-box label='Tipo de Reporte' [tabIndex]="2" [zeroMask]="2"
                        [setItemsList]="this.tipoReporte" [tabIndex]="3" [widthTextColumns]="4" [widthLabelColumns]="3"
                        (onSelectedItem)="selectedCombo($event,'Tipo')" inputProperty="ClaveNombre"
                        listProperty="ClaveNombre" [selectManualItem]="selectTipoReporteItem" formControlName="Num1"
                        helpLine="Tipo de reporte para la impresión del reporte." />
                    </div>
                  </div>
                </div>
              </app-panel-base>
              <app-panel-base title="Generales">
                <div class="panel-content">
                  <div class="w-100">
                    <component-ui-selection-filter [containerIsModal]="true"
                      [(FiltrosSeleccion)]="this.filtrosImpresionProductos" [tabIndex]="3"
                      (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
                  </div>
                  <div class="text-end">
                    <button class="btn btn-info" (click)="print()">
                      <i class="fas fa-print"></i>
                      Imprimir
                    </button>
                  </div>
                </div>
              </app-panel-base>
            </form>
          </div>
        </app-panel-base>
      </div>
    </app-container-base>
  </div>
</div>
