import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { environment } from "src/environments/environment";
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { RecepcionTraspaso, Traspaso } from "../interfaces/traspaso.interface";
import { ExistenciaProducto, ExistenciaProductoPaquete } from "src/app/ventas/interfaces/venta.interface";
import { Result } from "src/app/auth/interfaces";
import { Observable } from "rxjs";
import { InventarioFisicoCabecera, InventarioFisicoDetalle } from "../interfaces/fisico.interface";
import { ReportFilter } from "src/app/component-ui/interfaces/selection-filter.interface";
import { InventarioCabecera } from "../interfaces/inventario.interface";


@Injectable({ providedIn: 'root' })
export class InventarioService {

  private http = inject(HttpClient);
  private readonly baseUrl: string = environment.baseUrlApi;

  constructor() { }

  getSeriesTraspasos(esSolicituddeTraspaso: boolean, esEntreSucursales: boolean) {
    const params = new HttpParams().set("esSolicituddeTraspaso", esSolicituddeTraspaso).set("esEntreSucursales", esEntreSucursales);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Inventarios/ObtenerListaSeriesTraspasos`, { params });
  }

  procesarRecepcionTraspasos(ids: string): Observable<Result> {
    const params = new HttpParams().set("ids", ids);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/ProcesarRecepcionTraspasos`, { params });
  }

  ObtenerExistencas(idEmpresa: number, idProducto: number, idAlmacen: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idProducto", idProducto).set("idAlmacen", idAlmacen);
    return this.http.get<ExistenciaProducto[]>(`${this.baseUrl}/Inventarios/ObtenerExistencias`, { params });
  }

  ObtenerExistenciasPaquetes(idEmpresa: number, idProducto: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idProducto", idProducto);
    return this.http.get<ExistenciaProductoPaquete[]>(`${this.baseUrl}/Inventarios/ObtenerExistenciasPaquetes`, { params });
  }

  obtenerTraspaso(idEmp: number, idSuc: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("serie", serie)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<Traspaso>(`${this.baseUrl}/Inventarios/ObtenerTraspasoPorParametros`, { params });
  }

  obtenerTraspasosPendientes(idSucRecibe: number): Observable<RecepcionTraspaso[]> {
    const params = new HttpParams().set("idSucursalRecibe", idSucRecibe);
    return this.http.get<RecepcionTraspaso[]>(`${this.baseUrl}/Inventarios/ObtenerTraspasosPendientes`, { params });
  }

  obtenerInvFisico(idEmp: number, idSuc: number, folio: number, cancelados: boolean = true): Observable<InventarioFisicoCabecera> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<InventarioFisicoCabecera>(`${this.baseUrl}/Inventarios/ObtenerInventarioFisico`, { params });
  }

  obtenerInventario(idEmp: number, idSuc: number, folio: number, cancelados: boolean = true): Observable<InventarioCabecera> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<InventarioCabecera>(`${this.baseUrl}/Inventarios/ObtenerInventario`, { params });
  }








  obtenerUltimoTraspaso(idEmp: number, esEntreSucursales: boolean) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
    return this.http.get<Traspaso>(`${this.baseUrl}/Inventarios/ObtenerUltimoTraspaso`, { params });
  }

  getUltimoCostoPorProducto(idEmpresa: number, idSucursal: number, idAlmacen: number, idProducto: number) {
    const params = new HttpParams()
      .set("idEmpresa", idEmpresa)
      .set("idSucursal", idSucursal)
      .set("idAlmacen", idAlmacen)
      .set("idProducto", idProducto);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerUltimoCostoPorProducto`, { params });
  }

  eliminarTraspasoDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/EliminarTraspasoDetalle`, { params });
  }

  eliminarInvFisicoDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/EliminarInvFisicoDetalle`, { params });
  }

  sendInvFisicoMigration(fileName: string, almacenId: number, deleteItems: boolean): Observable<InventarioFisicoDetalle[]> {
    const params = new HttpParams().set("fileName", fileName).set("almacenId", almacenId).set("borrarRegistros", deleteItems);
    return this.http.get<InventarioFisicoDetalle[]>(`${this.baseUrl}/Inventarios/CargarLayoutInfFisico`, { params });
  }

  getConteoInventarioParaCSV(reportFilter: ReportFilter) {
    return this.http.post<any[]>(`${this.baseUrl}/inventarios/GetConteoInventarioParaCSV`, reportFilter);
  }

  busquedaAlmacen(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/BuscarAlmacenes`, { params });
  }

  busquedaConceptos(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/BuscarConceptos`, { params });
  }


}
