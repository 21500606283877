import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FinanzasService } from 'src/app/Finanzas/services/finanzas.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { EventsService } from 'src/app/service/events.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { CompraCargoAdicional } from '../../interfaces/compra.interface';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';

@Component({
  selector: 'app-agregar-cargo-adicional-compra-page',
  templateUrl: './agregar-cargo-adicional-compra-page.component.html',
  styles: [
  ]
})
export class AgregarCargoAdicionalCompraPageComponent implements OnInit {


  @ViewChild('cboTipo')
  public cboTipo!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtTotal')
  public txtTotal!: ElementRef<TextBoxComponent>;
  @ViewChild('txtSerieFolio')
  public txtSerieFolio!: ElementRef<TextBoxComponent>;
  @ViewChild('txtProveedor')
  public txtProveedor!: ElementRef<TextBoxComponent>;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('ctrlAgregarCfdi')
  public ctrlAgregarCfdi!: ElementRef<HTMLElement>;

  @ViewChild('dtFecha')
  public dtFecha!: ElementRef<DateBoxComponent>;






  @Input()
  public item: CompraCargoAdicional | null = null;

  @Input()
  public tabIndex: number = 0;





  info: UserLogged = { ambienteId: 0, numero: 0, clave: 0, nombreUsuario: "", nombreCompletoUsuario: "" };
  searchConfigurationCliente: SearchConfiguration | null = null;
  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Subtotal: [0],
    SubtotalGravaIVA: [0],
    SubtotalExento: [0],
    SubtotalTasaCero: [0],
    IVA: [0],
    Total: [0],
    UUID: [''],
    SerieFolio: [''],
    IVARetenido: [0],
    ISRRetenido: [0],
    ImpLocalTrasladado: [0],
    ImpLocalRetenido: [0],
    Moneda: [null],
    Fecha: [null],
    Tipo: [null],
    Proveedor: [null],
    TipoCambio: [0],
    NoGeneraCarteraCXP: [false],
    NoProrratear: [false],
  });

  constructor(private utilsService: UtilsService, private fService: FinanzasService, private ms: ModalService, private fb: FormBuilder, private containerService: ContainerBaseService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Proveedor", ``);

    setTimeout(() => {
      if (this.item) {
        this.myForm.reset({ ...this.item });
        const t: any = this.txtProveedor;
        t.tagInput.nativeElement.value = this.item.Proveedor!.Clave;
      }
      const txt: any = this.cboTipo;
      txt.tagInput.nativeElement.focus()
    }, 150);
  }

  get getNombreProveedor(): string {
    if (this.myForm.value.Proveedor) {
      return this.myForm.value.Proveedor.Nombre;
    }
    return "";
  }

  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  setCfdi(i: DescargaCfdi) {
    this.myForm.controls["Subtotal"].setValue(i.subtotal);
    this.myForm.controls["SubtotalGravaIVA"].setValue(i.subtotalGravaIva);
    this.myForm.controls["SubtotalExento"].setValue(i.subtotalExento);
    this.myForm.controls["SubtotalTasaCero"].setValue(i.subtotalTasaCero);
    this.myForm.controls["IVA"].setValue(i.ivaTrasladado);
    this.myForm.controls["IVARetenido"].setValue(i.ivaRetenido);
    this.myForm.controls["ISRRetenido"].setValue(i.isrRetenido);
    this.myForm.controls["Total"].setValue(i.total);
    this.myForm.controls["UUID"].setValue(i.uuid);
    this.myForm.controls["SerieFolio"].setValue(i.serieFolio);
  }

  blurProveedor(value: any) {
    if (value.after != value.before) {
      if (value.after) {
        this.fService.buscarProveedor(this.info.empresa!.numero, value.after).subscribe((prov) => {
          if (prov) {
            this.myForm.controls["Proveedor"].setValue(prov);
            const t: any = this.txtSerieFolio;
            t.tagInput.nativeElement.focus();
          } else {
            const t: any = this.txtProveedor;
            t.tagInput.nativeElement.value = "";
            this.eventsService.publish('home:showAlert', { message: `No se encontró el proveedor indicado.`, cancelButton: false });
          }
        })
      } else {
        this.myForm.controls["Proveedor"].setValue(null);
      }
    }
  }

  blurSerieFolio(value: any) {
    if (value.after != value.before) {
      const prov = this.myForm.value.Proveedor;
      if (prov) {
        this.fService.buscarCfdi(value.after, prov.Rfc).subscribe((result) => {
          if (result) {
            this.setCfdi(result);
          } else {
            this.eventsService.publish('home:showAlert', { message: `No se encontró el CFDI indicado.`, cancelButton: false });
          }
        })
      }
    }
    if (value.after == "") {
      this.myForm.controls["UUID"].setValue("");
    }
  }

  openProvSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Proveedor"].setValue(e);
        const t: any = this.txtProveedor;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.dtFecha;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtProveedor;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }


  openCfdi() {
    const b: any = this.ctrlAgregarCfdi;
    this.ms.openModal(b, (e: DescargaCfdi[]) => {
      this.onCloseCfdi(e);
    });
  }
  onCloseCfdi(lista: DescargaCfdi[] | null) {
    if (lista) {
      if (lista.length > 0) {
        if (lista.length > 1) {
          this.eventsService.publish('home:showAlert', { message: `Solo debe de seleccionar un CFDI.`, cancelButton: false });
          return;
        }
        const i: DescargaCfdi = lista[0];
        this.setCfdi(i);
      }
    }
    setTimeout(() => {
      const t: any = this.txtTotal;
      t.tagInput.nativeElement.focus();
    }, 250);
  }


  onSelectedItem(entity: any, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  accept() {
    let ent = this.myForm.value;
    if (!ent.Tipo) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar el Tipo de Cargo.`, cancelButton: false });
      return;
    }
    if (!ent.Moneda) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar la Moneda.`, cancelButton: false });
      return;
    }

    if (!ent.Proveedor) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar el Proveedor.`, cancelButton: false });
      return;
    }

    if (!ent.Fecha) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar la Fecha.`, cancelButton: false });
      return;
    }


    this.ms.closeModal(ent);
  }

  closeModal() {
    this.ms.closeModal(null);
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

}
