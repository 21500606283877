import { ConceptoAlmacen, TipoMovimientoAlmacen } from 'src/app/home/interfaces/almacen.interface';
import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import { InventarioService } from '../../services/inventario.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/service/utils.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { environment } from 'src/environments/environment';
import { InventarioFisicoCabecera, InventarioFisicoDetalleConversiones } from '../../interfaces/fisico.interface';
import { Producto, ProductoUnidad } from '../../interfaces/producto.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import * as moment from 'moment';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { InventarioCabecera, InventarioDetalle } from '../../interfaces/inventario.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import Swal from 'sweetalert2';
import { IdentificacionVehicular } from '../../../ventas/interfaces/cartaporte.interface';
import { isColPropsEqual } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-movimientos-inventario',
  templateUrl: './movimientos-inventario.component.html',
  styleUrls: ['./movimientos-inventario.component.css']
})
export class MovimientosInventarioComponent {

  //* Referencia a elementos de la pantalla
  @ViewChild('almacenInv')
  public almacenInv!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioMovInv')
  public txtFolioMovInv!: ElementRef<TextBoxComponent>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelarInventario')
  public modalCancelarInventario!: ElementRef<HTMLElement>;
  @ViewChild('ctrlConversionesInventario')
  public ctrlConversionesInventario!: ElementRef<HTMLElement>;
  @ViewChild('TipoMovimientoInv')
  public TipoMovimientoInv!: ElementRef<HTMLElement>;
  @ViewChild('txtFechaEmision')
  public txtFechaEmision!: ElementRef<DateBoxComponent>;

  //*  Variables globales del usuario
  info: UserLogged = {} as UserLogged;
  usuarioActual: UserLogged = {} as UserLogged;
  almacenActual: ComboBoxEntity = {} as ComboBoxEntity;
  tipoMovimientoActual: ComboBoxEntity = {} as ComboBoxEntity;
  listaSeries: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  showDescription: boolean = false;
  loading: boolean = false;
  downloading: boolean = false;
  file: any = null;
  saving: boolean = false;
  blockCombos: boolean = false;
  veces: number = 0;
  totalRegistros: number = 0;
  totalImporte: number = 0;
  totalEntradas: number = 0;
  totalSalidas: number = 0;



  //* injects
  private eventsService = inject(EventsService);
  private invService = inject(InventarioService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  private cd = inject(ChangeDetectorRef)
  private http = inject(HttpClient);
  private containerService = inject(ContainerBaseService)
  private fServices = inject(FacturacionService)

  //* variables del grid
  source: Array<InventarioDetalle> = [];
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  cords: any = null;
  sourceProducts: any[] = [];
  sourceAlmacen: any[] = [];
  sourceConcepto: any[] = [];
  selectedIndex: number = -1;
  idUnico: string = '';
  esEscapeProducto: boolean = false;
  esEscapeConcepto: boolean = false;
  esEscapeAlmacen: boolean = false;
  esEscapeReferencia: boolean = false;
  esEscapeCantidad: boolean = false;
  esEscapeSerieOrigina: boolean = false;
  esEscapeFolioOrigina: boolean = false;
  esEscapeCosto: boolean = false;
  esEnterProducto: boolean = false
  esEnterAlmacen: boolean = false
  esEnterConcepto: boolean = false
  esEnterCantidad: boolean = false
  esEnterFolio: boolean = false
  esEnterCosto: boolean = false
  enviarFocoProducto: boolean = false
  enviarFocoSerie: boolean = false
  enviarFocoConcepto: boolean = false
  enviarFocoCantidad: boolean = false
  enviarFocoCosto: boolean = false
  enviarFocoFolio: boolean = false;
  enviarFocoReferencia: boolean = false;
  enviarFocoAlmacen: boolean = false;
  enviarOtroRenglon: boolean = false;
  existencia: number = 0;
  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';
  abrirBusqueda: boolean = false;
  importing: boolean = false;
  busquedaProductoAbierta: boolean = false;
  busquedaAlmacenAbierta: boolean = false;
  busquedaConceptoAbierta: boolean = false;
  buscandoProducto: boolean = false;
  colProducto: number = 4;
  colConcepto: number = 2;
  colAlmacen: number = 1;



  //* oppciones del menu del panel

  public filtroInvFisico: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de Movtos de inventario',
      NombreExcel: 'Reporte de Movtos de inventario.xlsx',
      FilterOptions: [
        { Campo: 'Inv.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  // public myFormTotales: FormGroup = this.fb.group({
  //   TotalRegistros: [''],
  // });

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [null, Validators.required],
    Sucursal: [null, Validators.required],
    TipoMovimiento: [null, Validators.required],
    Fecha: null,
    Codigo: [0],
    Observaciones: [''],
    Detalle: [[] as InventarioDetalle[]],
    UsuarioCaptura: [null],
    UsuarioCancela: [null],
    FechaCancelacion: [null],
    Eliminado: false,
    Baja: false,
    MotivoCancelacion: '',
    Automatico: false
  })

  constructor() {
  }

  ngAfterViewInit(): void {
    let elem = this.myForm.value;
    elem.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre };
    elem.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre };
    elem.Fecha = new Date();
    setTimeout(() => {
      this.myForm.reset(elem);
      const txtMovimiento: any = this.TipoMovimientoInv;
      txtMovimiento.tagInput.nativeElement.focus();
    }, 150);
  }

  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utilsService.getUserLogged();
    this.usuarioActual = this.utilsService.getUserLogged();
    this.sourceReset();
  }

  //* ///////////////////////////////////////////////////
  //* 1.- metodos de inicializacion de la pantalla y grid
  get getExtras() {
    return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  }

  //* 2.- metodos de control cambio para los campos principales, (cancelaciones
  get permiteEliminar() {
    return !(this.myForm.value.FechaAplicacion || this.myForm.value.FechaCancelacion);
  }

  openCancel() {
    const b: any = this.modalCancelarInventario;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }

  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date2 = new Date();
    const d = { year: e.Fecha.getFullYear(), month: e.Fecha.getMonth(), day: e.Fecha.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
    e.Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
    const date = moment(e.Fecha).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/CancelarMovimientoInventario`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        Swal.fire({
          text: result.message, icon: 'info',
        })
      }
    });
  }

  //* 3.- metodos de control de flujo del grid, (eliminar row)
  //* ////////////////////////////////////////////

  sourceReset(detalle: any = []) {
    if (!detalle) {
      this.source = [];
    } else {
      this.source = detalle;
    }

    this.source!.map((item: InventarioDetalle) => {
      if (item.UnidadMedida) {
        item.UnidadDescripcion = item.UnidadMedida!.Nombre;
      } else {
        item.UnidadDescripcion = '';
      }
      if (item.Producto) {
        item.ProdDescripcion = item.Producto!.Nombre;
        item.NoIdentificacion = item.Producto!.Clave;
      } else {
        item.ProdDescripcion = '';
      }
      if (item.Almacen) {
        item.AlmDescripcion = item.Almacen!.Nombre;
        item.NoAlmacen = item.Producto!.Clave;
      } else {
        item.AlmDescripcion = '';
      }
      if (item.Concepto) {
        item.Tipo = item.Concepto.EsEntrada ? 'E' : 'S';
      } else {
        item.Tipo;
      }

      if (item.Conversiones && item.Conversiones.length > 0) {
        item.TextoConversiones = `${parseFloat(item.Conversiones[0].CantidadUnidadConversion.toFixed(2))} - ${item.Conversiones[0].NombreUnidad}\r\n`;;
      }

    });
    let totalRegs = this.source.length;
    if (this.source.length < 100) {
      totalRegs = 100;
    }

    for (let index = 0; index < totalRegs; index++) {
      this.source = [...this.source, this.estructuraVacia()]
    }
  }

  estructuraVacia(): any {
    return {
      Id: 0,
      Almacen: null,
      AlmDescripcion: '',
      Concepto: null,
      ConDescripcion: '',
      Producto: null,
      UnidadMedida: null,
      ProdDescripcion: '',
      UnidadDescripcion: '',
      NoIdentificacion: '',
      TextoConversiones: '',
      Costo: 0,
      Tipo: '',
      Cantidad: 0,
      Baja: false,
      TotalCosto: 0
    };
  }

  deleteRow(indx: number) {
    Swal.fire({
      title: '¿Desea eliminar el Producto?.',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const idEliminar = this.source[indx].Id;
        if (idEliminar > 0) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.invService.eliminarInvFisicoDetalle(idEliminar).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: InventarioFisicoCabecera = this.myForm.value;
            ent.Detalle?.splice(indx, 1);
            this.myForm.reset(ent);
            this.cd.detectChanges();
          })
        } else {
          this.source.splice(indx, 1);
          this.source = [...this.source];
          this.calcTotales();
          let ent: InventarioFisicoCabecera = this.myForm.value;
          ent.Detalle?.splice(indx, 1);
          this.myForm.reset(ent);
          this.cd.detectChanges();
        }
      }
    });
  }

  calcTotales() {
    let ent: InventarioFisicoCabecera = this.myForm.value;
    let regs: number = 0;
    let totalE: number = 0;
    let totalS: number = 0;
    let importe: number = 0

    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          regs++;
        }
        if (concepto.Tipo == 'E') {
          totalE++;
        } else {
          totalS++;
        }
        importe += concepto.TotalCosto;
      }
    });

    //this.myFormTotales.get('TotalRegistros')!.setValue(regs);

    this.totalRegistros = regs
    this.totalEntradas = totalE;
    this.totalSalidas = totalS;
    this.totalImporte = importe;

    this.myForm.reset(ent);
    this.source = [...this.source];
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  conversionClick(index: number) {
    if (!this.permiteEliminar) {
      return;
    }
    this.indexEditing = index;
    let item: InventarioDetalle = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    this.unidadFinal = item.UnidadDescripcion;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }

  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item: InventarioDetalle = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        if (item.Conversiones && item.Conversiones.length > 0) {
          item.Conversiones.map((p: InventarioFisicoDetalleConversiones) => {
            p.CantidadUnidadConcepto = e.items[0].CantidadUnidadConcepto;
            p.CantidadUnidadConversion = e.items[0].CantidadUnidadConversion;
            p.NombreUnidad = e.items[0].NombreUnidad;
          });
        } else {
          item.Conversiones = e.items;
        }
        item.TextoConversiones = e.conversiones;
        item.Cantidad = e.cantidad;
        this.source = [...this.source];
        //this.indexEditing++;
        this.enviarFocoCosto = true;
        !isclick && this.sendFocus();
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
        this.enviarFocoCantidad = true;
        !isclick && this.sendFocus();
      }
      //this.calcTotales();
      /*
      ! isclick && this.initEditor(this.indexEditing, e.cantidad == 0 ? 3 : 6);
      */
    } else {
      this.source = [...this.source];
      this.enviarFocoCantidad = true;
      !isclick && this.sendFocus();
    }
  }


  //* 4.- matodos de consulta a el backend

  get getFileName(): string {
    return this.file ? this.file.name : '';
  }

  onChangeFile(file: any) {
    this.file = file;
  }

  downloadFile(name: string) {
    this.downloading = true;
    return this.http.get(`${this.baseUrl}/Utilerias/DescargarArchivosImportacion?tipo=${name}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.downloading = false;
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          Swal.fire({
            text: 'Tienes bloqueadas las descargas de esta página, habilitalas.',
            icon: 'info',
          })
        }
      }
    })
  }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  get getBlockCombos() {
    return !this.blockCombos;
  }

  selectTipoCaptura(entity: ComboBoxEntity) {
    this.myForm.get('TipoMovimiento')!.setValue(entity);
    this.tipoMovimientoActual = entity;
    const ent: InventarioCabecera = this.myForm.value;
    this.getNextFolio(ent).subscribe(folio => {
      ent.Codigo = folio;
      //      this.blockCombos = true;
      this.setEntity(ent);
      setTimeout(() => {
        const txtFolio: any = this.txtFolioMovInv;
        txtFolio.tagInput.nativeElement.focus();
      }, 100);
    });
  }


  findEntityByParamsNavigate(type: string) {
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("folio", this.myForm.value.Codigo)
      .set("tipo", type);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<InventarioCabecera>(`${this.baseUrl}/Inventarios/ObtenerInvFisicoNavegacion`, { params }).subscribe((invFisico) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(invFisico);
    });
  }

  setEntity(inventario: InventarioCabecera) {
    if (!inventario) {
      this.new();
    } else {
      let ent: InventarioCabecera = this.myForm.value;
      this.cd.detectChanges();
      ent = { ...inventario };
      this.sourceReset(ent.Detalle);
      this.calcTotales();
      this.myForm.reset(ent);
      this.cd.detectChanges();
    }
  }

  //todo revisar el boton nuevo
  new() {
    const elem: InventarioCabecera = this.myForm.value;
    this.cService.getEmptyEntity("InventarioCabecera").subscribe((ent: InventarioCabecera) => {
      ent.Detalle = [];
      this.sourceReset();
      ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
      ent.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
      ent.Fecha = new Date();
      ent.TipoMovimiento = { Id: this.tipoMovimientoActual.Id, Clave: this.tipoMovimientoActual.Clave, Nombre: this.tipoMovimientoActual.Nombre } as TipoMovimientoAlmacen
      this.getNextFolio(ent).subscribe((folio: number) => {
        ent.Codigo = folio;
        this.setEntity(ent);
        // setTimeout(() => {
        //   const txtFolio: any = this.txtFolioMovInv;
        //   txtFolio.tagInput.nativeElement.focus();
        // }, 150);
      });
    });
  }




  getNextFolio(ent: InventarioCabecera): Observable<number> {
    const params = new HttpParams().set("tipoMovimiento", this.myForm.value.TipoMovimiento.Id);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerSiguienteFolioInventario`, { params });
  }

  searchProduct(value: string) {
    if (!value || this.busquedaProductoAbierta) {
      return;
    };

    this.loading = true;
    const params = new HttpParams().set("idEmpresa", this.info.empresa?.numero!).set("idSucursal", this.info.sucursal?.numero!).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        if (!producto.Inventariable) {
          this.eventsService.publish('home:showAlert', {
            message: "El producto seleccionado no es Inventariable, por favor verifique.",
            cancelButton: false,
            icon: "",
            onConfirm: (data: AlertResponse) => {
              this.source[this.indexEditing].NoIdentificacion = "";
              this.source = [...this.source];
              this.enviarFocoProducto = true;
              this.sendFocus();
            }
          });
          return;
        }

        this.invService.getUltimoCostoPorProducto(this.info.empresa?.numero!, this.info.sucursal?.numero!, producto.Almacen.Id, producto.Id).subscribe(prod => {
          let item = this.source[this.indexEditing];
          item.Producto = producto;
          item.NoIdentificacion = producto.Clave;
          item.ProdDescripcion = producto.Nombre;
          item.Cantidad = 0;
          item.SerieOrigina = "";
          item.FolioOrigina = 0;
          item.Referencia = "";
          item.UnidadMedida = producto.Unidad;
          if (prod != 0) {
            item.Costo = prod;
          } else {
            item.Costo = 0;
          }
          item.UnidadDescripcion = producto.Unidad.Nombre;
          this.source = [...this.source];
          producto.OtrasUnidades = producto.OtrasUnidades.filter(p => p.EsParaKilos == false);
          this.sourceProducts = [];
          if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0) {
            this.conversiones = producto.OtrasUnidades;
            this.unidadFinal = item.UnidadDescripcion
            this.openConvertions((e: any) => this.aceptConversions(e));
          } else {
            this.conversiones = [];
            this.unidadFinal = '';
            item.TextoConversiones = '';
            this.enviarFocoCantidad = true;
            this.sendFocus();
          }
        });
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Producto indicado.", cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacion = "";
            this.source = [...this.source];
            this.enviarFocoProducto = true;
            this.sendFocus();
            //this.initEditor(this.indexEditing, 3);
          }
        });
        return;
      }
    })
  }

  navegarConcepto(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceConcepto.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  navegarAlmacen(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceAlmacen.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  navegarProducto(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }



  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoConcepto) {
        this.initEditor(this.indexEditing, this.colConcepto);
        this.enviarFocoConcepto = false;
      }
      if (this.enviarFocoAlmacen) {
        this.initEditor(this.indexEditing, this.colAlmacen);
        this.enviarFocoAlmacen = false;
      }

      if (this.enviarFocoProducto) {
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarFocoProducto = false;
      }
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, 6);
        this.enviarFocoCantidad = false;
      }
      if (this.enviarFocoCosto) {
        this.initEditor(this.indexEditing, 9);
        this.enviarFocoCosto = false;
      }
      if (this.enviarFocoSerie) {
        this.initEditor(this.indexEditing, 10);
        this.enviarFocoSerie = false;
      }
      if (this.enviarFocoFolio) {
        this.initEditor(this.indexEditing, 11);
        this.enviarFocoFolio = false;
      }
      if (this.enviarFocoReferencia) {
        this.initEditor(this.indexEditing, 12);
        this.enviarFocoReferencia = false;
      }

      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.enviarOtroRenglon = false;
        this.enviarFocoAlmacen = true;
        this.sendFocus();
      }
    }, 50);
  }


  searchAlmacen(value: string) {
    if (!value) {
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }
    this.loading = true;
    const params = new HttpParams().set("idEmpresa", this.info.empresa?.numero!)
      .set("idSucursal", this.info.sucursal?.numero!).set("idCliente", 0).set("clave", value);

    this.containerService.getEntityByClave("Almacen", value).subscribe((almacen) => {
      this.loading = false;
      if (almacen) {
        let item = this.source[this.indexEditing];
        item.Almacen = almacen;
        this.source = [...this.source];
        this.enviarFocoConcepto = true;
        this.sendFocus();
        this.sourceAlmacen = [];
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Almacén indicado.", cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            //this.source[this.indexEditing].NoAlmacen = "";
            this.source = [...this.source];
            this.enviarFocoAlmacen = true;
            this.sendFocus();
            // this.initEditor(this.indexEditing, 1);
          }
        });
      }
    })
  }


  openConvertions(cb: any) {
    const b: any = this.ctrlConversionesInventario;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }
  save() {
    this.existencia = 0;
    if (this.saving) return;

    if (!this.permiteGuardar) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "No es posible realizar modificaciones a la captura del Inventario Físico.", cancelButton: false, onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    let ent: InventarioCabecera = this.myForm.value;
    if (this.myForm.value.Id == 0) {
      ent.UsuarioCaptura = { Id: this.usuarioActual.numero, Clave: this.usuarioActual.numero, Nombre: this.usuarioActual.nombreUsuario } as Usuario;
      ent.Fecha = new Date();
    }

    ent.Detalle = this.source.filter(P => P.NoIdentificacion != '');

    if (ent.Detalle.length == 0) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "Debe de indicar por lo menos un Producto para capturar el Movimiento de Inventario.", cancelButton: false, onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    if (ent.Detalle.filter((P: any) => P.Cantidad == 0).length > 0) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "No puede haber Productos con la cantidad conteo menor o igual a cero.", cancelButton: false, onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    // revisamos que solo tenga una conversion por detalle de la captura
    ent.Detalle.forEach((el: InventarioDetalle) => {
      if (el.Conversiones.length > 1) {
        el.Conversiones.pop();
      }
    });

    this.saving = true;
    ent.Automatico = false;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Inventarios/GuardarMovimientoInventario`, ent).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: InventarioFisicoCabecera = JSON.parse(result.message);
        this.myForm.reset(t);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
      this.saving = false;
    })
  }

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new":
        this.new();
        setTimeout(() => {
          const txtFolio: any = this.txtFolioMovInv;
          txtFolio.tagInput.nativeElement.focus();
        }, 150);
        this.blockCombos = false;
        break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.findEntityByParamsNavigate(button); break;
      case "left": this.findEntityByParamsNavigate(button); break;
      case "right": this.findEntityByParamsNavigate(button); break;
      case "last": this.findEntityByParamsNavigate(button); break;
      case "return": this.findEntityByParams();
        this.blockCombos = false;
        setTimeout(() => {
          const txtFolio: any = this.txtFolioMovInv;
          txtFolio.tagInput.nativeElement.focus();
        }, 100);
        break;
    }
  }


  get permiteGuardar() {
    return (this.myForm.value.Id == 0);
  }


  // todo impesion
  print() {

    if (this.myForm.value.Id == 0) {
      this.eventsService.publish('home:showAlert', {
        message: "Es necesario que primero guarde su captura antes de poder imprimirlo, por favor verifique.", cancelButton: false,
        onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }


    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE INVENTARIO FÍSICO',
      Dato1: '',
      Opc1: true,
    }
    let filtro: FilterOptions | undefined = this.filtroInvFisico.FilterOptions?.find(p => p.Campo == 'Inv.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroInvFisico.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroInvFisico, '/Inventarios/VerificadorDeInventarioFisico');
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
      this.blockCombos = true;
    }
  }

  findEntityByParams() {
    const ent: InventarioCabecera = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.invService.obtenerInventario(ent.Empresa!.Id, ent.Sucursal!.Id, ent.Codigo).subscribe((invFisico) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(invFisico);
    });
  }

  //* eventos para el control del grid
  ////// *1 eliminar renglon
  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }


  buscarCatalogo(e: any, catalogo: string, busquedaCompleta: boolean = false) {
    if ((e.target.value == "" || !isNaN(e.target.value)) && catalogo == "Producto") {
      //   this.cords = null;
      //   this.sourceAlmacen = [];
      //   this.sourceProducts = [];
      //   this.sourceConcepto = [];
      return;
    }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (row) {
      //row.scrollIntoView({ block: "center" });
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Cliente) { return }
    this.sourceAlmacen = [];
    this.sourceProducts = [];
    this.sourceConcepto = [];
    let valorABuscar = e.target.value;

    if (busquedaCompleta) {
      valorABuscar = "";
    }
    if (catalogo == "Almacen") {
      this.invService.busquedaAlmacen(valorABuscar).subscribe((result) => {
        const lista = JSON.parse(result.message);
        this.sourceAlmacen = lista;
        if (this.sourceAlmacen.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }
    if (catalogo == "Producto") {
      this.fServices.busquedaProductos(valorABuscar, 0).subscribe((result) => {
        const lista = JSON.parse(result.message);
        this.sourceProducts = lista;
        if (this.sourceProducts.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }

    if (catalogo == "Concepto") {


      this.invService.busquedaConceptos(valorABuscar).subscribe((result) => {
        const lista = JSON.parse(result.message);
        this.sourceConcepto = lista;
        if (this.sourceConcepto.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }


  }

  buscarConcepto(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value)) {
      this.cords = null;
      this.sourceAlmacen = [];
      return;
    }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }

    if (e.keyCode == "40") {
      e.preventDefault();
      if (this.selectedIndex < this.sourceAlmacen.length - 1) {
        this.selectedIndex = this.selectedIndex + 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }
    if (row) {
      row.scrollIntoView({ block: "center" });
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Cliente) { return }

    this.containerService.getEntityByClave("Almacen", e.target.value).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceAlmacen = lista;
      if (this.sourceAlmacen.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }


  escapeAlmacen(e: any) {
    let index = this.indexEditing;
    let item: InventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Almacen ? item.Almacen.Clave : "";
    this.esEscapeAlmacen = true;
    setTimeout(() => {
      this.esEscapeAlmacen = true;
      const item = { ...this.source[index] };
      if (!item.Almacen) {
        e.target.value = "";
      } else {
        e.target.value = item.Almacen.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoAlmacen = true;
        this.sendFocus();
      } else {
        const txt: any = this.txtFechaEmision;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }

  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceAlmacen.length > 0) {
      return;
    }
    if (celda == 1 && this.sourceConcepto.length > 0) {
      return;
    }
    if (celda == 3 && this.sourceProducts.length > 0) {
      return;
    }

    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeAlmacen = true;
      }
      if (celda == 1) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeConcepto = false;
        }, 50);
      }

      if (celda == 0) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeAlmacen = false;
        }, 50);
      }

      if (celda == 3) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeProducto = false;
        }, 50);
      }

    } else {
      this.initEditor(this.indexEditing, celda + 1);
    }
  }

  getLastItem(): number {
    return this.source.filter(P => P.Producto && P.Cantidad > 0 && P.Almacen).length;
  }



  // downAlmacen(e: any, celda: number) {
  //   e.preventDefault();
  //   let c = this.getLastItem();
  //   if (this.indexEditing < c - 1) {
  //     e.target.blur();
  //     setTimeout(() => {
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, celda + 1);
  //     }, 50);
  //   } else {
  //     if (this.indexEditing + 1 < c + 1) {
  //       this.esEscapeAlmacen = true;
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, 2);
  //     }
  //   }
  // }

  // downConcepto(e: any, celda: number) {
  //   e.preventDefault();
  //   let c = this.getLastItem();
  //   if (this.indexEditing < c - 1) {
  //     e.target.blur();
  //     setTimeout(() => {
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, celda + 1);
  //     }, 50);
  //   } else {
  //     if (this.indexEditing + 1 < c + 1) {
  //       this.esEscapeConcepto = true;
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, 3);
  //     }
  //   }
  // }

  // downProducto(e: any, celda: number) {
  //   e.preventDefault();
  //   let c = this.getLastItem();
  //   if (this.indexEditing < c - 1) {
  //     e.target.blur();
  //     setTimeout(() => {
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, celda + 1);
  //     }, 50);
  //   } else {
  //     if (this.indexEditing + 1 < c + 1) {
  //       this.esEscapeProducto = true;
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, 5);
  //     }
  //   }
  // }






  enterAlmacen(e: any) {
    this.esEnterAlmacen = true;
    this.enviarFocoConcepto = true;
    let value = '';
    if (this.sourceAlmacen.length > 0) {
      value = this.sourceAlmacen[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  enterConcepto(e: any) {
    this.esEnterConcepto = true;
    this.enviarFocoProducto = true;
    let value = '';
    if (this.sourceConcepto.length > 0) {
      value = this.sourceConcepto[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  enterPrecio(e: any) {
    this.enviarFocoSerie = true;
    e.target.blur();
  }



  blurCosto(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const costo = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Costo)) == costo) {
      if (this.enviarFocoSerie) {
        this.sendFocus();
      }
      return;
    };

    item.Costo = costo;
    item.TotalCosto = costo * item.Cantidad;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
    this.calcTotales()
  }


  blurSerie(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const serie: string = e.target.value;
    let item = this.source[this.indexEditing];
    if (item.SerieOrigina == serie) {
      if (this.enviarFocoFolio) {
        this.sendFocus();
      }
      return;
    };
    item.SerieOrigina = serie;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  blurFolioOrigina(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const folio: number = parseInt(e.target.value);
    let item = this.source[this.indexEditing];
    if (item.FolioOrigina == folio) {
      if (this.enviarFocoReferencia) {
        this.sendFocus();
      }
      return;
    };
    item.FolioOrigina = folio;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }

  blurReferencia(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const referencia: string = e.target.value;
    let item = this.source[this.indexEditing];
    if (item.Referencia == referencia) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.Referencia = referencia;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  enterReferencia(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }


  // this.source[this.indexEditing].ValorUnitario = valUnitario;
  //     this.source[this.indexEditing].ValorUnitarioAntesDescuento = valorUnitarioAntesDescuento;
  //     e.target.value = porcentajeDescuento;
  //     this.source[this.indexEditing].DescuentoPorcentaje = porcentajeDescuento;
  //     this.source[this.indexEditing].DescuentoImporte = importeDescuento;
  //     this.source = [...this.source];

  //     this.calcTax(true);

  //   } else {
  //     if (this.enviarOtroRenglon) {
  //       this.indexEditing = this.indexEditing + 1;
  //       this.initEditor(this.indexEditing, 1);
  //       this.enviarOtroRenglon = false;
  //     }

  enterFolio(e: any) {
    this.enviarFocoReferencia = true;
    e.target.blur();
  }


  enterSerie(e: any) {
    this.enviarFocoFolio = true;
    e.target.blur();
  }


  enterCantidad(e: any) {
    if (e.target.value == "" || parseInt(e.target.value) == 0) {
      return;
    }
    this.enviarFocoCosto = true;
    e.target.blur();
  }

  enterCosto(e: any) {
    this.enviarFocoSerie = true;
    e.target.blur();
  }

  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }


  setIndexEdit(index: number, e: any, item: InventarioDetalle) {

    /*Esto es lo nuevo*/
    //this.indexEditing = -1;

    if (index >= 0) {
      let item = { ...this.source[index] };
      if (e.srcElement.id == `txt_${index}_8${this.idUnico}`) {
        if (item.Tipo === 'S') {
          this.indexEditing = -1;
          e.target.blur();
          return;
        }
      }
    }

    if (this.myForm.value.Id != 0) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }


    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.TipoMovimiento == null || this.myForm.value.TipoMovimiento.Id == 0) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "Primero indique el Tipo de Movimiento de Almacén.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          this.focusTipoMovimiento();
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.Codigo == 0) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "Primero indique el Folio del Movimiento de Almacén.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          this.focusTipoMovimiento();
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }



    // const docto: ComboBoxEntity = this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoVenta.Id)[0];
    // if (docto.ObligarCopiar) {
    //   if (!item.Producto || item.Producto?.Id == 0) {
    //     this.eventsService.publish('home:showAlert', { message: "No se pueden agregar Productos, debe de copiar.", cancelButton: false });
    //     this.indexEditing = -1;
    //     e.target.blur();
    //     return;
    //   }
    // }

    /**/



    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  focusTipoMovimiento() {
    const txt: any = this.TipoMovimientoInv;
    txt.tagInput.nativeElement.focus()
  }

  focusFolioMovtoInv() {
    const txt: any = this.txtFolioMovInv;
    txt.tagInput.nativeElement.focus()
  }


  focusAlmacen(index: number, e: any, item: InventarioDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c + 1)) {
      return;
    }

    this.setIndexEdit(index, e, item);
    if (e.target.value == "") {
      this.buscarCatalogo(e, "Almacen", true);
      e.preventDefault();
      return;
    } else {
      this.sourceAlmacen = [];
    }

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  focusConcepto(index: number, e: any, item: InventarioDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c + 1)) {
      return;
    }

    if (e.target.value == "") {
      this.buscarCatalogo(e, "Concepto", true);
      e.preventDefault();
    } else {
      this.sourceConcepto = [];
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  focusProducto(index: number, e: any, item: InventarioDetalle) {
    let c = this.getLastItem();

    if (index > 0 && index > (c + 1)) {
      return;
    }

    this.setIndexEdit(index, e, item);


    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  blurAlmacen(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeAlmacen) {
        this.esEnterAlmacen = false;
        this.esEscapeAlmacen = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Almacen) {
        if (!e.target.value) {
          e.target.value = item.Almacen.Clave;
          this.enviarFocoAlmacen = true;
          this.sendFocus();
          return;
        }

        if (item.Almacen.Clave != e.target.value) {
          this.searchAlmacen(e.target.value);
        } else {
          if (this.esEnterAlmacen) {
            if (this.enviarFocoConcepto) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchAlmacen(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterAlmacen = false;
      this.esEscapeAlmacen = false;
    }, 100);
  }



  blurConcepto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeConcepto) {
        this.esEnterConcepto = false;
        this.esEscapeConcepto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Concepto) {
        if (!e.target.value) {
          e.target.value = item.Concepto.Clave;
          this.enviarFocoConcepto = true;
          this.sendFocus();
          return;
        }

        if (item.Concepto.Clave != e.target.value) {
          this.searchConcepto(e.target.value);
        } else {
          if (this.esEnterConcepto) {
            if (this.enviarFocoProducto) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchConcepto(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterConcepto = false;
      this.esEscapeConcepto = false;
    }, 100);
  }

  blurProducto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }
        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.esEnterProducto) {
            if (this.enviarFocoCantidad) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 100);
  }


  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const cantidad = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarFocoCosto) {
        if (item.Tipo === 'S') {
          this.enviarFocoCosto = false;
          this.enviarFocoSerie = true;
        } else {
          this.enviarFocoCosto = true;
          this.enviarFocoSerie = false;
        }
        this.sendFocus();
      }
      return;
    };
    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (cantidad != totalEnConversiones) {
          item.Cantidad = item.Cantidad;
        } else {
          item.Cantidad = cantidad;
        }
      } else {
        item.Cantidad = cantidad;
        item.TotalCosto = cantidad * item.Costo;
        item.Conversiones = [];
        item.TextoConversiones = "";
        if (item.Tipo === 'S') {
          this.enviarFocoCosto = false;
          this.enviarFocoSerie = true;
        } else {
          this.enviarFocoCosto = true;
          this.enviarFocoSerie = false;
        }
        //this.sendFocus();
      }
    } else {
      item.Cantidad = cantidad;
      item.TotalCosto = cantidad * item.Costo;
      item.Conversiones = [];
      item.TextoConversiones = "";
      if (item.Tipo === 'S') {
        this.enviarFocoCosto = false;
        this.enviarFocoSerie = true;
      } else {
        this.enviarFocoCosto = true;
        this.enviarFocoSerie = false;
      }
      // this.sendFocus();
      // return;
    }
    this.sendFocus();
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.calcTotales()
  }



  searchConcepto(value: string) {
    if (!value || this.busquedaConceptoAbierta) {
      // if (this.myForm.value.Total > 0 && this.navigateColumns) {
      //   this.save();
      // }
      return;
    };

    let clave = 0;
    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }

    this.loading = true;
    this.containerService.getEntityByClave("ConceptoAlmacen", value).subscribe((concepto: ConceptoAlmacen) => {
      this.loading = false;
      if (concepto) {
        let item = this.source[this.indexEditing];
        item.Concepto = concepto
        item.Tipo = concepto.EsEntrada ? 'E' : 'S';
        this.source = [...this.source];
        this.enviarFocoProducto = true;
        this.sendFocus();
        this.sourceConcepto = [];
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Concepto de Almacén.", cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            //this.source[this.indexEditing] = "";
            this.source = [...this.source];
            this.enviarFocoConcepto = true;
            this.sendFocus();
          }
        });
      }
    })
  }


  clicTabla(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_0${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoConcepto = true;
    }
  }

  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoConcepto = true;
    }
  }


  clicTablaConcepto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_1${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoProducto = true;
    }
  }

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }

  escapeCantidad(e: any) {
    let item: InventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : 0;
    this.esEscapeCantidad = true;
    this.initEditor(this.indexEditing, 4);
  }

  escapeConcepto(e: any) {
    let item: InventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Concepto ? item.Concepto.Clave : "";
    this.esEscapeConcepto = true;
    this.initEditor(this.indexEditing, 1);
  }

  escapeCosto(e: any) {
    let item: InventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Costo ? item.Costo : 0;
    this.esEscapeCosto = true;
    this.initEditor(this.indexEditing, 6);
  }
  escapeReferencia(e: any) {
    let item: InventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Referencia ? item.Referencia : "";
    this.esEscapeReferencia = true;
    this.initEditor(this.indexEditing, 11);
  }

  escapeSerie(e: any) {
    let item = { ...this.source[this.indexEditing] };
    e.target.value = item.SerieOrigina ? item.SerieOrigina : "";
    this.esEscapeSerieOrigina = true;
    if (item.Tipo === 'S') {
      this.initEditor(this.indexEditing, 6);
    } else {
      this.initEditor(this.indexEditing, 9);
    }
  }



  escapeFolio(e: any) {
    let item = { ...this.source[this.indexEditing] };
    e.target.value = item.FolioOrigina ? item.FolioOrigina : 0;
    this.esEscapeFolioOrigina = true;
    this.initEditor(this.indexEditing, 10);
  }

  escapeProducto(e: any) {
    let item: InventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Producto ? item.Producto.Clave : "";
    this.esEscapeProducto = true;
    this.initEditor(this.indexEditing, 2);
  }

  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceAlmacen.length > 0) {
      return;
    }
    if (celda == 1 && this.sourceConcepto.length > 0) {
      return;
    }
    if (celda == 3 && this.sourceProducts.length > 0) {
      return;
    }

    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeProducto = true;
        this.indexEditing = this.indexEditing + 1;
        this.source = [...this.source, this.estructuraVacia()];
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  enterFecha(e: any) {
    e.preventDefault();
    if (this.myForm.value.Id == 0) {
      this.initEditor(0, 1);
    }
  }

  tabFecha(e: any) {
    e.preventDefault();
    if (this.myForm.value.Id == 0) {
      this.initEditor(0, 1);
    }
  }




}
