import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumDataType } from 'src/app/component-ui/components/text-box/text-box.component';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';

@Component({
  selector: 'app-alta-consignado-compra-page',
  templateUrl: './alta-consignado-compra-page.component.html',
  styles: [
  ]
})
export class AltaConsignadoCompraPageComponent {
  public EDT = EnumDataType;
  private eventsService = inject(EventsService);
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<HTMLElement>;

  ngOnInit(): void {
    setTimeout(() => {
      const t: any = this.txtNombre;
      t.tagInput.nativeElement.focus();
    }, 150);
    if (this.idConsignado > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fs.getConsignadoProveedorPorId(this.idConsignado).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const c = JSON.parse(result.message);
        this.myForm.reset(c);
      });
    }
  }

  @Input()
  public idConsignado: number = 0;

  @Input()
  public idProveedor: number = 0;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Rfc: [''],
    Direccion: [{} as Direccion],
    IdProveedorPertenece: [0],
  })

  constructor(private ms: ModalService, private fb: FormBuilder, private fs: FacturacionService) { }

  accept() {
    let ent = this.myForm.value;
    if (!ent.Nombre) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Nombre.", cancelButton: false });
      return;
    }
    if (!ent.Rfc) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el RFC.", cancelButton: false });
      return;
    }
    if (!ent.Direccion) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Dirección.", cancelButton: false });
      return;
    }

    if (!ent.Direccion.Pais) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el País.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.CodigoPostal) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Código Postal.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.NumeroExterior) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Número Exterior.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.Calle) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Calle.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.Colonia) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Colonia.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.Localidad) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Localidad.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.Municipio) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Municipio.", cancelButton: false });
      return;
    }
    if (!ent.Direccion.Estado) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Estado.", cancelButton: false });
      return;
    }
    ent.IdProveedorPertenece = this.idProveedor;
    if (!ent.Direccion.Id) {
      ent.Direccion.Id = 0;
    }
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.saveConsignadosProveedor(JSON.stringify(ent)).subscribe((result) => {
      if (result.success) {
        this.ms.closeModal(true);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  closeModal() {
    this.ms.closeModal(null);
  }
}
