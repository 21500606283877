import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-corte-caja-page',
  templateUrl: './corte-caja-page.component.html',
  styleUrls: ['./corte-caja-page.component.scss'],
  styles: [
  ]
})
export class CorteCajaPageComponent {
  @ViewChild('modalCorteCaja')
  public modalCorteCaja!: ElementRef<HTMLElement>;
  @ViewChild('modalConteoEfectivo')
  public modalConteoEfectivo!: ElementRef<HTMLElement>;
  @ViewChild('modalRetirosCaja')
  public modalRetirosCaja!: ElementRef<HTMLElement>;
  @ViewChild('modalTerminales')
  public modalTerminales!: ElementRef<HTMLElement>;

  labelAnticipoFacturado: string = 'Anticipo Facturado';
  labelAnticipoAplicado: string = 'Anticipo Aplicado';
  labelNotasCreditoContado: string = 'Notas de Crédito';
  labelAnticiposAplicados: string = 'Anticipos Aplicados';
  labelNotasCreditoCredito: string = 'Notas de Crédito';

  private fb = inject(FormBuilder);

  private modalService = inject(ModalService);
  private tabsNavService = inject(TabsNavService);
  private eventsService = inject(EventsService);

  private newEntityFrm = {
    Efectivo: [],
    Terminales: [],
    Cheques: [],
    Transferencias: [],
    RetirosCaja: [],
    FondoFijo: [],
    Entregado: [],
    FacturasOtrosDias: [],
    VentasSinAnticipo: [],
    AnticipoFacturado: [],
    AnticipoAplicado: [],
    NotasCreditoContado: [],
    TotalContado: [],
    VentasCredito: [],
    AnticiposAplicados: [],
    NotasCreditoCredito: [],
    TotalCredito: [],
    TotalDeclarar: [],
  };

  public frmCorteCaja: FormGroup = this.fb.group(this.newEntityFrm);

  ngOnInit(): void {
    this.tabsNavService.getIsOpenTab("facturacion", (resp: any) => {
      console.log(resp)
      if (resp) {
        this.eventsService.publish('home:showAlert', {
          message: `No se puede mantener abiertas las pantallas de Corte de Caja y Facturación al mismo tiempo.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      }
    })
  }

  onBeforeSave(event: any) {

  }

  onItemsComboSearched(event: any) {

  }

  onItemSearched(event: any) {

  }

  onAfterSave(event: any) {

  }

  seleccionarCorte() {
    this.modalService.openModal(this.modalCorteCaja, (e: any) => {
      if (e) {

      }
    }, 'sm')
  }

  openConteoEfectivo() {
    this.modalService.openModal(this.modalConteoEfectivo, (e: any) => {
      if (e) { }
    })
  }

  openRetirosCaja() {
    this.modalService.openModal(this.modalRetirosCaja, (e: any) => {
      if (e) { }
    }, 'lg')
  }

  openTerminales() {
    this.modalService.openModal(this.modalTerminales, (e: any) => {
      if (e) { }
    }, 'lg')
  }

  onSelectedItem(item: any, tipo: string) {

  }

  close() {
    this.modalService.closeModal(null);
  }

  buscarCorte() {

  }
}
