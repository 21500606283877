import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './layout/home-layout.component';
import { ProductoPageComponent } from '../Inventarios/pages/producto-page/producto-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    data: { title: 'Inicio' },
    children: [
      // { path: 'empresas', component: EmpresaPageComponent, data: { title: 'Empresas' } },
      // { path: 'usuarios', component: UsuarioPageComponent, data: { title: 'Usuarios' } },
      // { path: 'puestos', component: PuestoPageComponent, data: { title: 'Puestos' } },


      // { path: 'login/v3', component: LoginV3Page, data: { title: 'Login V3 Page' } },


      // { path: 'email/inbox', component: EmailInboxPage, data: { title: 'Email Inbox' } },
      // { path: 'email/compose', component: EmailComposePage, data: { title: 'Email Compose' } },
      // { path: 'email/detail', component: EmailDetailPage, data: { title: 'Email Detail' } },


      // { path: 'dashboard/v1', component: DashboardV1Page, data: { title: 'Dashboard V1' } },
      // { path: 'dashboard/v2', component: DashboardV2Page, data: { title: 'Dashboard V2' } },
      // { path: 'dashboard/v3', component: DashboardV3Page, data: { title: 'Dashboard V3' } },

      // { path: 'widget', component: WidgetPage, data: { title: 'Widgets' } },

      // { path: 'page-blank', component: PageBlank, data: { title: 'Blank Page' } },

      // { path: 'calendar', component: CalendarPage, data: { title: 'Calendar' } },

      // { path: 'extra/timeline', component: ExtraTimelinePage, data: { title: 'Timeline' } },
      // { path: 'extra/coming-soon', component: ExtraComingSoonPage, data: { title: 'Coming Soon Page' } },
      // { path: 'extra/search-results', component: ExtraSearchResultsPage, data: { title: 'Search Results Page' } },
      // { path: 'extra/error-page', component: ExtraErrorPage, data: { title: 'Error Page' } },
      // { path: 'extra/invoice', component: ExtraInvoicePage, data: { title: 'Invoice' } },
      // { path: 'extra/profile', component: ExtraProfilePage, data: { title: 'Profile Page' } },
      // { path: 'extra/scrum-board', component: ExtraScrumBoardPage, data: { title: 'Scrum Board' } },
      // { path: 'extra/cookie-acceptance-banner', component: ExtraCookieAcceptanceBannerPage, data: { title: 'Cookie Acceptance Banner' } },
      // { path: 'extra/orders', component: ExtraOrdersPage, data: { title: 'Orders' } },
      // { path: 'extra/order-details', component: ExtraOrderDetailsPage, data: { title: 'Order Details' } },
      // { path: 'extra/products', component: ExtraProductsPage, data: { title: 'Products' } },
      // { path: 'extra/product-details', component: ExtraProductDetailsPage, data: { title: 'Product Details' } },


      // { path: 'pos/counter-checkout', component: PosCounterCheckoutPage, data: { title: 'POS - Counter Checkout' } },
      // { path: 'pos/kitchen-order', component: PosKitchenOrderPage, data: { title: 'POS - Kitchen Order' } },
      // { path: 'pos/customer-order', component: PosCustomerOrderPage, data: { title: 'POS - Customer Order' } },
      // { path: 'pos/menu-stock', component: PosMenuStockPage, data: { title: 'POS - Menu Stock' } },
      // { path: 'pos/table-booking', component: PosTableBookingPage, data: { title: 'POS - Table Booking' } },

      // { path: 'sin-url', component: PageBlank, data: { title: 'En Construcción' } },

      // { path: '**', redirectTo: 'dashboard/v1' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
