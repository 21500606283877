import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'retiros-caja',
  templateUrl: './retiros-caja.component.html',
  styleUrls: ['./retiros-caja.component.scss']
})
export class RetirosCajaComponent {

  @ViewChild('modalAgregarRetiroCaja')
  public modalAgregarRetiroCaja!: ElementRef<HTMLElement>;

  private modalService = inject(ModalService);
  private eventsService = inject(EventsService);
  private fb = inject(FormBuilder);

  retiros: any[] = [];
  retiro: any = null



  public agregarRetiroCajaFrm: FormGroup = this.fb.group({
    Importe: [0, [Validators.required]],
    Tipo: [0, [Validators.required]],
    Banco: [0, [Validators.required]],
    CuentaBancaria: [0, [Validators.required]],
    Referencia: ["", [Validators.required]],
  })

  deleteRow(index: number) {

  }

  agregarEditar(index: number) {
    this.modalService.openModal(this.modalAgregarRetiroCaja, () => {
    });
  }

  close() {
    this.modalService.closeModal(null);
    this.agregarRetiroCajaFrm.reset();
  }

  onSelectedItem(entity: any, tipo: string) {
    switch (tipo) {
      case "TipoRetiro":
        this.retiro = {
          ...this.retiro,
          tipoRetiro: entity
        }
        break
      case "Banco":
        this.retiro = {
          ...this.retiro,
          banco: entity
        }
        break
      case "CuentaBancaria":
        this.retiro = {
          ...this.retiro,
          cuentaBancaria: entity
        }
        break
      default:
        break;
    }
  }

  accept(isEnterInput: boolean = false, event: any = null) {
    let idDate = new Date().getTime();
    let importe = this.agregarRetiroCajaFrm.controls['Importe'].value;
    let referencia = isEnterInput ? event.target.value : this.agregarRetiroCajaFrm.controls['Referencia'].value;
    if ((!importe || !referencia) || !this.retiro || (!this.retiro!.tipoRetiro && !this.retiro!.banco || !this.retiro!.cuentaBancaria)) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar todos los campos indicados con asterisco en rojo.", cancelButton: false });
      return;
    }
    this.retiro = {
      ...this.retiro,
      importe,
      referencia
    }
    if (this.retiro!.id) {
      this.retiros = this.retiros.map((r: any) => {
        if (r.id === this.retiro.id) {
          return this.retiro;
        } return r;
      })
    } else {
      this.retiro = {
        ...this.retiro,
        id: this.retiro!.id ? this.retiro!.id : idDate,
      }
      this.retiros.push(this.retiro);
    }
    this.close();
    this.retiro = null;
  }

  deleteRetiro(retiro: any) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.retiros = this.retiros.filter((r: any) => r.id !== retiro.id);
        }
      }
    });
  }

  editRetiro(retiro: any) {
    this.modalService.openModal(this.modalAgregarRetiroCaja, () => {
    });
    setTimeout(() => {
      this.agregarRetiroCajaFrm.reset({
        Importe: retiro.importe,
        Tipo: retiro.tipoRetiro,
        Banco: retiro.banco,
        CuentaBancaria: retiro.cuentaBancaria,
        Referencia: retiro.referencia,
      });
      this.retiro = retiro;
    }, 0);
  }
}
