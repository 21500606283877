import { Component, ElementRef, OnInit, ViewChild, inject, NgModule } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { ConceptoOrdenManufactura, HistoricoOrdenManufactura, OrdenManufactura, ProduccionesOrdenManufactura, ProduccionesOrdenManufacturaCabecera, SerieManufactura } from '../../interfaces/manufacura.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { GuiColumnAlign, GuiLocalization } from '@generic-ui/ngx-grid';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import Swal from 'sweetalert2';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import * as moment from 'moment';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { ModalService } from 'src/app/service/modal.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-ordenes-manufactura-page',
  templateUrl: './ordenes-manufactura-page.component.html',
  styleUrls: ['./ordenes-manufactura-page.component.scss']
})
export class OrdenesManufacturaPageComponent implements OnInit {

  @ViewChild('modalDireccion')
  public modalDireccion!: ElementRef<HTMLElement>;
  @ViewChild('modalMapa')
  public modalMapa!: ElementRef<HTMLElement>;

  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolio')
  public txtFolio!: ElementRef<TextBoxComponent>;
  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<TextBoxComponent>;
  public GuiColumnAlign = GuiColumnAlign;
  info: UserLogged = {} as UserLogged;
  listaSeries: ComboBoxEntity[] = [];
  listaConceptos: any[] = [];
  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);


  abrirHistorico: boolean = false;
  abrirHistoricoProduccion: boolean = false;
  direccionentrega: Direccion | null = null;
  abrirMarcarTerminada: boolean = false;
  abrirBusqueda: boolean = false;
  abrirProduccion: boolean = false;
  indexEditing: number = -1;
  private readonly baseUrl: string = environment.baseUrlApi;
  localization: GuiLocalization = {
    'translation': {
      'sourceEmpty': 'No hay elementos para mostrar',
    }
  };

  public myForm: FormGroup = this.fb.group({
    id: [0],
    empresa: [{} as Empresa],
    sucursal: [{} as Sucursal],
    serie: [{} as SerieManufactura],
    serieString: [''],
    folio: [0],
    direccionEntrega: [{} as Direccion],
    usuarioElabora: [{} as Usuario],
    usuarioCancela: [{} as Usuario],
    fechaEmision: [Date],
    porcentajeAvance: [0],
    fechaCompromisoEntrega: [Date],
    fechaCancelacion: [Date],
    cliente: [{} as Cliente],
    comentarios: [''],
    motivoCancelacion: [''],
    comentariosMarcoTerminada: [''],
    usuarioMarcoTerminada: [{} as Usuario],
    marcadaTerminada: [false],
    fechaMarcoTerminada: [Date],
    conceptos: [[] as ConceptoOrdenManufactura[]],
    historicos: [[] as HistoricoOrdenManufactura[]],
    producciones: [[] as ProduccionesOrdenManufacturaCabecera[]],
    cantidad: [0],
    cantidadProducida: [0],
    cantidadPendienteTerminada: [0],
    cantidadPendiente: [0],
    esOrden: [false],
    esManufactura: [false],
    idOrdenManufactura: [0],
    serieFolioDocumentoVentaOrigen: [''],
    serieFolioOrdenOrigen: [''],
    idDocumentoVentaOrigen: [0],
    idMovimientoInventario: [0],
    eliminado: [false],
    baja: [false],
  })

  constructor(private fServices: FacturacionService, private fb: FormBuilder, private utilsService: UtilsService, private containerService: ContainerBaseService, private ms: ModalService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.containerService.getEmptyEntityNoJson("OrdenManufactura").subscribe((elem) => {
      elem.empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      this.getSeries(elem);
    })
  }

  onClicEdit() {
    const ent = this.myForm.value.direccionEntrega;

    const dFinal = {
      Id: ent.id,
      NombreRecibe: ent.nombreRecibe,
      Telefono: ent.telefono,
      Calle: ent.calle,
      NumeroExterior: ent.numeroExterior,
      NumeroInterior: ent.numeroInterior,
      Localidad: ent.localidad,
      Municipio: ent.municipio,
      CodigoPostal: ent.codigoPostal,
      Descripcion: ent.descripcion,
      Pais: ent.pais,
      Estado: ent.estado,
      Colonia: ent.colonia,
      Eliminado: false,
      Baja: false,
    }

    this.direccionentrega = { ...dFinal };
    const b: any = this.modalDireccion;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.aceptarDireccion(e);
      }
    }, 'xl')
  }

  openMap() {
    const b: any = this.modalMapa;
    this.ms.openModal(b, (e: any) => {

    }, 'xl')

  }

  aceptarDireccion(ent: any) {
    const dFinal = {
      id: ent.Id,
      nombreRecibe: ent.NombreRecibe,
      telefono: ent.Telefono,
      calle: ent.Calle,
      numeroExterior: ent.NumeroExterior,
      numeroInterior: ent.NumeroInterior,
      localidad: ent.Localidad,
      municipio: ent.Municipio,
      codigoPostal: ent.CodigoPostal,
      descripcion: ent.Descripcion,
      pais: ent.Pais,
      estado: ent.Estado,
      colonia: ent.Colonia,
      eliminado: false,
      baja: false,
    }
    const json = JSON.stringify(dFinal);
    this.fServices.actualizarDireccionEntrega(json, this.myForm.value.id).subscribe((result) => {
      if (result.success) {
        Swal.fire({ position: 'center', icon: 'success', title: 'Se actualizó correctamente', showConfirmButton: false, timer: 1000 });
        this.BuscarOrden();
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }


  // get getCancelDate(): string {
  //   if (this.myForm.value.fechaCancelacion) {
  //     return moment(this.myForm.value.fechaCancelacion).format("DD/MM/YYYY");
  //   }
  //   return "";
  // }

  getCancelDate(fecha?: any): string {
    let fechaStr = "";
    if (this.myForm.value.id > 0) {
      if (fecha) {
        return moment(fecha).format("DD/MM/YYYY")
      }
    }
    return fechaStr;
  }

  verHistorico() {
    this.abrirHistorico = true;
  }
  verHistoricoProducciones() {
    this.abrirHistoricoProduccion = true;
  }
  onCloseHistoricoProduccion() {
    this.abrirHistoricoProduccion = false;
  }

  onCloseProduccion(produjo: boolean) {
    this.abrirProduccion = false;
    if (produjo) {
      const elem = this.myForm.value;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      let idEmpresa = 0;
      let idSucursal = 0;
      idEmpresa = elem.empresa.Id ? elem.empresa.Id : elem.empresa.id;
      idSucursal = elem.sucursal.Id ? elem.sucursal.Id : elem.sucursal.id;
      const txtCliente: any = this.txtCliente
      this.fServices.getOrdenManufactura(idEmpresa, idSucursal, elem.serieString, elem.folio).subscribe((orden: any) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        txtCliente.tagInput.nativeElement.value = `${orden.cliente.clave} - ${orden.cliente.nombre}`;
        this.listaConceptos = orden.conceptos;
        this.myForm.reset(orden);
      });
    }
  }

  onCloseBusqueda(orden: any) {
    this.abrirBusqueda = false;
    if (orden) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      const txtCliente: any = this.txtCliente
      this.fServices.getOrdenManufactura(orden.empresa.id, orden.sucursal.id, orden.serieString, orden.folio).subscribe((orden: any) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        txtCliente.tagInput.nativeElement.value = `${orden.cliente.clave} - ${orden.cliente.nombre}`;
        this.listaConceptos = orden.conceptos;
        this.myForm.reset(orden);
      });
    }
  }

  onCloseHistorico() {
    this.abrirHistorico = false;
  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  abrirBuscador() {
    this.abrirBusqueda = true;
  }

  elaborar() {
    let c = this.myForm.value.conceptos;
    c.map((i: ConceptoOrdenManufactura) => {
      i.producir = i.cantidadPendiente;
      return i;
    })
    this.abrirProduccion = true;
  }

  imprimirOrdenManufactura() {
    this.fServices.imprimirOrdenManufactura(this.myForm.value.id);
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }


  closeMarcarTerminada() {
    this.abrirMarcarTerminada = false;
    this.BuscarOrden();
  }

  preguntarMarcarTerminada() {
    const elem = this.myForm.value;
    if (elem.cantidadPendiente > 0 && !elem.fechaCancelacion) {
      this.abrirMarcarTerminada = true;
    }
  }

  get getFechaTerminada(): string {
    const elem = this.myForm.value;
    if (elem.id > 0 && elem.fechaMarcoTerminada) {
      return moment(elem.fechaMarcoTerminada).format("DD/MM/YYYY HH:mm:ss")
    }
    return "";
  }



  BuscarOrden() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const elem = this.myForm.value;
    let idEmpresa = 0;
    let idSucursal = 0;
    idEmpresa = elem.empresa.Id ? elem.empresa.Id : elem.empresa.id;
    idSucursal = elem.sucursal.Id ? elem.sucursal.Id : elem.sucursal.id;
    const txtCliente: any = this.txtCliente
    this.fServices.getOrdenManufactura(idEmpresa, idSucursal, elem.serieString, elem.folio).subscribe((orden: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (orden) {
        txtCliente.tagInput.nativeElement.value = `${orden.cliente.clave} - ${orden.cliente.nombre}`;
        this.listaConceptos = orden.conceptos;
        this.myForm.reset(orden);
      } else {
        this.limpiar();
        this.eventsService.publish('home:showAlert', { message: 'No se encontró la orden indicada.', cancelButton: false });
      }
    });
  }

  get getTextoOrden(): string {
    const elem = this.myForm.value;
    if (elem.id > 0) {
      if (elem.cantidadPendiente == 0) { return "[Manufacturado: Completa]"; }
      if (elem.cantidadProducida == 0) { return "[Manufacturado: Sin iniciar]"; }
      if (elem.cantidadPendiente > 0 && elem.cantidadProducida > 0) { return "[Manufacturado: Parcial]"; }
    }
    return "";
  }

  limpiar() {
    const form = this.myForm.value;
    const txtCliente: any = this.txtCliente;
    txtCliente.tagInput.nativeElement.value = '';
    this.listaConceptos = [];
    this.containerService.getEmptyEntityNoJson("OrdenManufactura").subscribe((elem) => {
      elem.empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.serie = { Id: form.serie.Id, Clave: 0, Serie: form.serieString, Nombre: form.serie.Nombre };
      elem.serieString = form.serieString;
      this.myForm.reset(elem);
    })
  }

  getSeries(elem: any) {
    const txt: any = this.cboSerie;
    const txtFolio: any = this.txtFolio;
    txt.tagInput.nativeElement.value = "";
    this.fServices.getSeriesManufactura(true).subscribe((lista) => {
      this.listaSeries = lista;
      if (lista.length == 1) {
        const serie: ComboBoxEntity = this.listaSeries[0];
        elem.serie = { Id: serie.Id, Clave: 0, Serie: serie.Serie, Nombre: serie.Nombre };
        elem.serieString = serie.Serie;
        this.myForm.reset(elem);
        txtFolio.tagInput.nativeElement.focus();
      } else if (lista.length > 0) {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        setTimeout(() => {
          txt.tagInput.nativeElement.focus()
        }, 150);
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });

        this.eventsService.publish('home:showAlert', {
          message: 'No se encontraron Series para consultar.',
          cancelButton: false,
          onConfirm: (r: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      }

    })
  }
}
