<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Llamada"
  title="Llamadas" icon="fa fa-bullseye" subTitle="Alta de Llamadas recibidas o realizadas.">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Llamada'" entityName="Llamada" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Asunto'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            helpLine="Asunto de la llamada, 100 caracteres." [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre
            [maxLength]="100" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
