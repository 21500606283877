import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-traspasos-page',
  templateUrl: './verificador-traspasos-page.component.html',
})
export class VerificadorTraspasosPageComponent {


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  menuId: string = "verificadortraspasos";

  private _reportsService = inject(ReportsService);
  private eventsService = inject(EventsService);
  public get reportsService() {
    return this._reportsService;
  }
  public set reportsService(value) {
    this._reportsService = value;
  }

  private utils = inject(UtilsService);

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Sucursal-Serie Traspaso', Orden: 'tras.SucursalId,serTras.Clave,tras.id, det.id' }]


  public filtrosReporte: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' }],
      OrdenadoLista: this.ordenadoLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificador de Traspasos',
      NombreExcel: 'Verificador de Traspasos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, Entidad: "Sucursal" },
        { Campo: 'sucDest.Clave', Etiqueta: 'Sucursal Destino', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'tras.SerieString', Etiqueta: 'Serie del Traspaso', Tipo: TypeFilter.text },
        { Campo: 'tras.Folio', Etiqueta: 'Folio del Traspaso', Tipo: TypeFilter.number },
        { Campo: 'pro.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'usuElabora.Clave', Etiqueta: 'Usuario Elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'usuRecibe.Clave', Etiqueta: 'Usuario Recibe', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'usuCancela.Clave', Etiqueta: 'Usuario Cancela', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc3: false,
    Opc4: false,
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {

    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE TRASPASOS ' + this.rangoFechas);
    this.focus('txtNombreReporte');
    this.myForm.get('Opc3')?.setValue(false);
    this.myForm.get('Opc4')?.setValue(true);


  }

  selectOpcion1(value: boolean) {
    this.myForm.get('Opc3')?.setValue(value);
    this.myForm.get('Opc4')?.setValue(!value);
  }

  selectOpcion2(value: boolean) {
    this.myForm.get('Opc4')?.setValue(value);
    this.myForm.get('Opc3')?.setValue(!value);
  }




  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE TRASPASOS ' + this.rangoFechas);
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }

  clickBoton(event: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.filtrosReporte.ReportHeader = this.myForm.value;
    //this.reportsService.setUserFilter(this.menuId, JSON.stringify(this.filtrosReporte)).subscribe(P => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.filtrosReporte.ReportHeader = this.myForm.value;
      this.reportsService.printReport(this.filtrosReporte, '/Inventarios/VerificadorDeTraspasos');
    //})

  }
}
