<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Factura a afectar en Cuentas Por Cobrar</div>
  <div class="bg-light">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <form [formGroup]="myForm" autocomplete="off">
          <app-panel-base title="Datos de la Factura">
            <div class="panel-content">

              <component-ui-combo-box [required]="true" [label]="'Sucursal'" [widthList]="300"
                formControlName="Sucursal" [tabIndex]="1" [entityName]="'Sucursal'" [widthTextColumns]="3"
                [widthLabelColumns]="4" [widthRightColumns]="5" (onSelectedItem)="selectedCombo($event,'Sucursal')"
                [listProperty]="'ClaveNombre'" #cboSucursal />


              <components-ui-text-box [maxLength]="10" [label]="'Serie'" [required]="true" formControlName="Serie"
                [tabIndex]="2" [widthTextColumns]="3" [widthLabelColumns]="4" />

              <components-ui-text-box [maxLength]="15" [label]="'Folio'" [required]="true" formControlName="Folio"
                [tabIndex]="3" [widthTextColumns]="3" [widthLabelColumns]="4" />

              <components-ui-number-box [tabIndex]="8" formControlName="Importe" [widthLabelColumns]="4" label="Importe"
                [widthTextColumns]="3" (keydown.enter)="accept()" #txtImporte/>

            </div>
          </app-panel-base>



          <div style="text-align: right; margin-top: 5px;">
            <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
            <div (click)="close()" class="btn btn-danger" #txtCancelar>Cancelar</div>
          </div>
        </form>
      </div>
    </app-container-base>
  </div>
</div>
