import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { FinanzasService } from '../../services/finanzas.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { ConfiguracionConciliacion } from '../../interfaces/conciliacion.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-estado-cuenta-conciliacion-page',
  templateUrl: './estado-cuenta-conciliacion-page.component.html',
  styles: [
  ]
})
export class EstadoCuentaConciliacionPageComponent implements OnInit {


  info: UserLogged = {} as UserLogged;
  seleccionado: number = 1;
  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  private config: any;
  titulo: string = "";
  public myForm: FormGroup = this.fb.group({

  });

  constructor(private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private fs: FinanzasService,
    private ms: ModalService,
    private utileService: UtilsService) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.obtenerUltimaConfiguracion();
  }

  obtenerUltimaConfiguracion() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerConfiguracionConciliacion(this.info.empresa!.numero, this.info.numero).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        if (result.message.length > 0) {
          const ent = JSON.parse(result.message);
          this.config = { ...ent };
          this.titulo = `Conciliando: ${this.config?.Cuenta?.Nombre}  Del ${moment(this.config?.FechaInicio).format("DD/MM/YYYY")} al ${moment(this.config?.FechaFin).format("DD/MM/YYYY")}`;
        } else {
          this.enviarMensajeError();
        }
      } else {
        this.enviarMensajeError();
      }
    });
  }

  seleccionar(tipo: number) {
    this.seleccionado = tipo;
  }

  enviarMensajeError() {
    this.eventsService.publish('home:showAlert', {
      message: "Primero configure la conciliación.", cancelButton: false, onConfirm: (data: AlertResponse) => {
        this.tabsNavService.closeCurrentTab();
        this.eventsService.publish('home:openPage', 86100);
      }
    });
  }


}
