import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { GuardarSucursalSeleccionada, SeleccionEmpresa } from "../components/interfaces";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Cliente } from "../ventas/interfaces/cliente.interface";
import { PermisosTableros } from "../configuracion/interfaces/rol.interface";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private readonly baseUrl: string = environment.baseUrlApi;

  private http = inject(HttpClient);

  constructor() {

  }

  getEmpresasSucursales(): Observable<SeleccionEmpresa[]> {
    return this.http.get<SeleccionEmpresa[]>(`${this.baseUrl}/Base/ObtenerEmpresasSucursales`, {});
  }

  getlistadoFormatosImpresion(rfc:string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/Base/ObtenerListadoFormatos/${rfc}`, {});
  }


  getEmpresasSucursalesPorUsuario(id: number): Observable<SeleccionEmpresa[]> {
    return this.http.get<SeleccionEmpresa[]>(`${this.baseUrl}/Base/ObtenerEmpresasSucursalesPorUsuario/${id}`, {});
  }

  guardarSucursalSeleccionada(sucursalSeleccionada: GuardarSucursalSeleccionada): Observable<GuardarSucursalSeleccionada> {
    return this.http.post<GuardarSucursalSeleccionada>(`${this.baseUrl}/Base/GuardarSucursalSeleccionada`, sucursalSeleccionada);
  }

  getClientePorClave(empresaId: number, clave: number, isTicket: boolean = false): Observable<Cliente> {
    const params = new HttpParams().set("idEmpresa", empresaId).set("esParaTickets", isTicket).set("clave", clave);
    return this.http.get<Cliente>(`${this.baseUrl}/Ventas/BuscarClientePorClave`, { params })
  }

  getPermisosTableros(id: number): Observable<PermisosTableros> {
    return this.http.get<PermisosTableros>(`${this.baseUrl}/Base/GetPermisosTableros/${id}`, {});
  }

  getVersionApp(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/Base/GetVersionApp`);
  }
}
