import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { PresupuestoService } from '../../services/presupuesto.service';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { EmpresaSucursalDto, Result, UserLogged } from 'src/app/auth/interfaces';
import { PresupuestoVentaSucursalDetalle, PresupuestoVentaSucursal, TipoPresupuesto } from '../../interfaces/presupuestos.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import Swal from 'sweetalert2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';


@Component({
  selector: 'presupuestos-sucursales-page',
  templateUrl: './presupuestos-sucursales-page.component.html',
  styleUrls: ['./presupuestos-sucursales-page.component.css']

})
export class PresupuestosSucursalesPageComponent {


  // variables de referencia e elementos HTML
  @ViewChild('busquedaSucursalesPresupuesto')
  public ctrlBusquedaSucursales!: ElementRef<HTMLElement>;

  @ViewChild('copiarPresupuestoSucursal')
  public copiarPresupuestoSucursal!: ElementRef<HTMLElement>;

  @ViewChild('periodoPptoSuc')
  public ctrlperiodoPptoSuc!: ElementRef<HTMLElement>;


  // injects
  private fb = inject(FormBuilder)
  private utilsService = inject(UtilsService)
  private ms = inject(ModalService);
  private http = inject(HttpClient);
  private eventsService = inject(EventsService);
  private cService = inject(ContainerBaseService);
  private reportsService = inject(ReportsService)
  private presupuestoService = inject(PresupuestoService)
  private cd = inject(ChangeDetectorRef)





  //* variables para el nuevo grid
  public appSettings = inject(AppSettings);
  private configService = inject(ConfiguracionService)

  sourceSucursal: any[] = [];
  cords: any = null;
  selectedIndex: number = -1;
  idUnico: string = '';
  esEscapeSucursal: boolean = false;
  esEnterSucursal: boolean = false;
  enviarFocoMeta: boolean = false;
  enviarFocoSucursal: boolean = false;
  busquedaAbierta: boolean = false;
  enviarOtroRenglon: boolean = false;
  busquedaSucursalAbierta: boolean = false;




  //variables para el encabezdo, listas que se llenan manualmene
  listaMeses: any[] = [];
  listaAnios: any[] = [];
  PeriodoSeleccionado?: ComboBoxEntity;
  anioSeleccionado?: ComboBoxEntity;
  oldPeriodo?: ComboBoxEntity;
  oldAnio?: ComboBoxEntity;


  // Ambiente desarrollo o produccion
  private readonly baseUrl: string = environment.baseUrlApi;


  //  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  usuarioActual: UserLogged = {} as UserLogged;


  // *variables para el grid
  sourceGrid: Array<PresupuestoVentaSucursalDetalle> = [];
  indexEditing: number = -1;

  //columnEditing: number = -1;
  navigateColumns: boolean = false;
  loading: boolean = false;

  // variables de busqueda de la sucursal
  searchConfigurationSucursal: SearchConfiguration | null = null;


  //variables de control de flujo de la pantalla
  saving: boolean = false;
  menuItems: PanelBaseDropDownMenu[] = [{ Id: 'copiar', Nombre: 'Copiar Presupuesto' },
  { Id: 'cargar', Nombre: 'Cargar todas las Sucursales' }]


  constructor() {
  }



  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  public filtroPptos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Verificador de Ppto por Sucursal',
      NombreExcel: 'Verificador de Ppto por Sucursal.xlsx',
      FilterOptions: [
        { Campo: 'ppto.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  public myFormTotales: FormGroup = this.fb.group({
    TotalPpto: [''],
  });


  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Anio: [{} as ComboBoxEntity],
    Periodo: [{} as ComboBoxEntity],
    Nombre: [''],
    MetaDelMes: [0],
    UsuarioElabora: {} as Usuario,
    Detalle: [[] as PresupuestoVentaSucursalDetalle[]],
    Eliminado: [false],
    Baja: [false],
  })
  ngOnInit(): void { //*OK

    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utilsService.getUserLogged();
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.usuarioActual = this.utilsService.getUserLogged();

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.PeriodoSeleccionado = mesItem;

    this.searchConfigurationSucursal = this.ms.GetSearchConfiguration("Sucursal", '');
    this.presupuestoService.getListaDeAniosConPresupuestos(2).subscribe(result => {
      if (result) {
        this.loading = false;
        let proximoAnio: number = new Date().getUTCFullYear();
        proximoAnio++;
        if (result.length > 0) {
          let anioSig: boolean = false;
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
            if (element == proximoAnio) {
              anioSig = true;
            }
          });
          if (!anioSig) {
            this.listaAnios.push({ Id: proximoAnio + 1, Nombre: proximoAnio.toString(), Clave: proximoAnio })
          }
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
          this.listaAnios.push({ Id: proximoAnio, Nombre: proximoAnio.toString(), Clave: proximoAnio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('Empresa')?.setValue(this.utilsService.getUserLoggedEmpresa())
        this.myForm.get('Anio')?.setValue(this.anioSeleccionado!.Id);
        this.myForm.get('Periodo')?.setValue(this.PeriodoSeleccionado!.Id);
        this.findEntityByParams(true);
      }
    })
  }


  //* 1.- Métodos de inicialización de la pantalla y del grid

  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }


  //* 2.- Métodos de control de flujo de la pantalla

  onKeyPressMes(e: any) { //*OK
    if (e.keyCode == 13) {
      this.indexEditing = 0
      this.initEditor(this.indexEditing, 1);
    }
  }

  onKeyDownMes(e: any) { //*OK
    if (e.keyCode == 9) {
      this.indexEditing = 0
      this.initEditor(this.indexEditing, 1);
    }
  }


  get getMes(): ComboBoxEntity | null { //*OK
    return this.PeriodoSeleccionado!;
  }

  get getAnio(): ComboBoxEntity | null { //*OK
    return this.anioSeleccionado!;
  }

  selectAnio(entity: ComboBoxEntity) { //*OK
    this.myForm.controls["Anio"].setValue(entity.Id);
    this.anioSeleccionado = entity;
    if (this.oldAnio?.Id! != this.anioSeleccionado.Id) {
      this.oldAnio = this.anioSeleccionado;
      this.findEntityByParams();
    }
  }

  selectMes(entity: ComboBoxEntity) { //*OK
    this.myForm.controls["Periodo"].setValue(entity.Id);
    this.PeriodoSeleccionado = entity;
    if (this.oldPeriodo?.Id! != this.PeriodoSeleccionado.Id) {
      this.oldPeriodo = this.PeriodoSeleccionado;
      this.findEntityByParams();
    }
  }

  get getUserSucursales(): any { //*OK
    if (this.searchConfigurationSucursal) {
      return this.searchConfigurationSucursal.columns;
    }
    return [];
  }

  get getUserFilter(): any { //*OK
    if (this.searchConfigurationSucursal) {
      return this.searchConfigurationSucursal.filter;
    }
    return "";
  }


  get getSucursalPropertys(): string { //*OK
    if (this.searchConfigurationSucursal) {
      let props = "";
      this.searchConfigurationSucursal.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  sourceGridReset(detalle: any = []) {
    if (!detalle) {
      this.sourceGrid = [];
    } else {
      this.sourceGrid = detalle;
    }

    this.sourceGrid.map(p => {
      if (p.Sucursal) {
        p.Nombre = p.Sucursal?.Nombre!;
        p.Clave = p.Sucursal?.Clave!.toString();
        p.Sucursal = { Id: p.Sucursal!.Id, Clave: p.Sucursal!.Clave, Nombre: p.Sucursal!.Nombre } as Sucursal;
        p.Meta = p.Meta * 1;
      }
    });
    let totalRegs = 1;
    if (this.sourceGrid.length < 20) {
      totalRegs = 20;
    }
    for (let index = 0; index < totalRegs; index++) {
      this.sourceGrid = [...this.sourceGrid, this.estructuraVacia()];
    }
  }

  estructuraVacia() {
    return {
      Id: 0,
      Sucursal: null,
      Nombre: '',
      Clave: '',
      Meta: 0
    };

  }

  esCapturaValida(throwMessage: boolean = true): boolean {//*revisar

    let detalles: PresupuestoVentaSucursalDetalle[] = [...this.sourceGrid.filter(P => P.Sucursal?.Id! > 0)];

    let countAnt = detalles.filter(P => P.Meta <= 0).length;

    if (countAnt > 0 && throwMessage) {
      Swal.fire({ text: `Captura inválida, debe de indicar la Sucursal y la Meta de ventas, por favor verifique.`, icon: 'info', })
      return false;
    }

    let sucursalDuplicada: boolean = false;
    detalles.some((value, indice) => {
      if (detalles.findIndex((item) => item.Sucursal!.Id == value.Sucursal!.Id) != indice) {
        sucursalDuplicada = true;
      }
    })

    if (sucursalDuplicada) {
      Swal.fire({ text: `Captura inválida, debe de indicar la Sucursal solo una vez, por favor verifique.`, icon: 'info', })
      return false;
    }
    return true;
  }

  Guardar() {

    let ent = this.myForm.value;

    ent.Nombre = ent.Anio + " " + this.utilsService.getMes(ent.Periodo);

    ent.Detalle = this.sourceGrid.filter(P => P.Sucursal?.Id! > 0);

    if (!this.esCapturaValida()) { return; }

    if (ent.Detalle.length == 0) {
      Swal.fire({ text: `Debe de indicar el listado de Sucursales y sus Metas.`, icon: 'error', })
      return;
    }

    if (this.myForm.value.Id == 0) {
      ent.UsuarioElabora = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario } as Usuario;
    }


    this.saving = true;
    const json = JSON.stringify(ent);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarPresupuestoSucursales`, ent).subscribe((result) => {
      this.saving = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: PresupuestoVentaSucursal = JSON.parse(result.message);
        this.setEntity(t);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
  }

  searchSucursalDetalle(value: number): Observable<any> { //*revisar
    const params = new HttpParams().set("entidad", "Sucursal").set("clave", value);
    return this.http.get<Sucursal>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params });
  }

  searchSucursal(value: string) {  //*revisar

    if (!value) {
      if (this.myForm.value.Total > 0 && this.navigateColumns) {
        this.Guardar();
      }
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }

    let item = { ...this.sourceGrid[this.indexEditing] };
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.searchSucursalDetalle(parseFloat(value)).subscribe(sucursal => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (sucursal) {
        let item = this.sourceGrid[this.indexEditing];
        item.Sucursal = sucursal;
        item.Meta = 0;
        this.sourceGridReset(this.sourceGrid);
        setTimeout(() => {
          this.initEditor(this.indexEditing, 3);
        }, 50);
        this.navigateColumns = true;
      } else {
        // this.sourceGrid = [...this.sourceGrid];
        //     if (e) {
        //       e.target.value = item.Clave;
        //     }
        //     this.initEditor(this.indexEditing, 1);
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: 'No se encontró la Sucursal indicada.',
          onConfirm: (data: AlertResponse) => {
            this.sourceGrid = [...this.sourceGrid];
            this.initEditor(this.indexEditing, 1);
            // this.sourceGrid = [...this.sourceGrid];
            // if (e) {
            //   e.target.value = item.Clave;
            // }
            // this.initEditor(this.indexEditing, 1);
          }
        });
      }
    });
  }

  get PptoDetalle(): FormArray {
    return this.myForm.get('Detalle') as FormArray;
  }

  deleteRow(indx: number) {
    const idEliminar = this.sourceGrid[indx].Id;
    this.eventsService.publish('home:showAlert', {
      message: `¿Desea eliminar el Presupuesto la Sucursal? <br> ${idEliminar > 0 ? "La información no se podrá recuperar." : ""}`,
      textAccept: "Si, eliminar",
      textCancel: "Cancelar",
      cancelButton: true,
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.presupuestoService.eliminarPresupuestoDetalle(idEliminar, TipoPresupuesto.sucursales).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.sourceGrid.splice(indx, 1);
              this.sourceGrid = [...this.sourceGrid];
              this.calcTotales();
              let ent: PresupuestoVentaSucursal = this.myForm.value;
              ent.Detalle?.splice(indx, 1);
              this.myForm.reset(ent);
              this.cd.detectChanges();
            })
          } else {
            this.sourceGrid.splice(indx, 1);
            this.sourceGrid = [...this.sourceGrid];
            this.calcTotales();
            let ent: PresupuestoVentaSucursal = this.myForm.value;
            ent.Detalle?.splice(indx, 1);
            this.myForm.reset(ent);
            this.cd.detectChanges();
          }
        }
      }
    });


    // Swal.fire({
    //   title: `¿Desea eliminar el Presupuesto la Sucursal? <br> ${idEliminar > 0 ? "La información no se podrá recuperar." : ""}`,
    //   showDenyButton: true,
    //   confirmButtonText: 'Si, eliminar',
    //   denyButtonText: `Cancelar`,
    // }).then((result) => {
    //   if (result.isConfirmed) {

    //   }
    // });
  }


  calcTotales() {  //*revisar
    let ent: PresupuestoVentaSucursal = this.myForm.value;
    ent.MetaDelMes = 0;
    let totalMeta: number = 0, subTotalVenta = 0, totalConceptos = 0;

    this.sourceGrid.map((detalle, index) => {
      if (detalle.Sucursal?.Id! > 0) {
        totalMeta += detalle.Meta * 1;
      }
    });

    this.myFormTotales.get('TotalPpto')!.setValue(totalMeta);
    ent.MetaDelMes = totalMeta;
    this.myForm.reset(ent);
    this.sourceGrid = [...this.sourceGrid];
  }


  navigate(type: string) {
    let mes: number = this.PeriodoSeleccionado?.Id!;

    switch (type) {
      case "first": mes = 1;
        break;
      case "left":
        mes--;
        if (mes < 1) mes = 1;
        break;
      case "right":
        mes++;
        if (mes > 12) mes = 12;
        break;
      case "last":
        mes = 12;
        break;
    }
    this.PeriodoSeleccionado = { Id: mes, Clave: mes, Nombre: this.utilsService.getMes(mes) } as ComboBoxEntity;
    this.findEntityByParams();
  }

  setEntity(presupuesto: PresupuestoVentaSucursal, enviarFoco: boolean = false) {
    if (!presupuesto) {
      this.nuevo();
    } else {
      let ent = this.myForm.value;
      this.PeriodoSeleccionado = { Id: ent.Periodo, Clave: ent.Periodo, Nombre: this.utilsService.getMes(ent.Periodo) } as ComboBoxEntity;
      this.anioSeleccionado = { Id: ent.Anio, Clave: ent.Anio, Nombre: ent.Anio.toString() } as ComboBoxEntity;
      ent = { ...presupuesto };
      this.sourceGridReset(ent.Detalle);
      this.myForm.reset(ent);
      this.calcTotales();
      if (enviarFoco) {
        setTimeout(() => {
          let txt: any = this.ctrlperiodoPptoSuc;
          txt.tagInput.nativeElement.focus();
        }, 100);
      }
    }

  }


  nuevo() {
    const ent: PresupuestoVentaSucursal = this.myForm.value;
    this.cService.getEmptyEntity("PresupuestoVentaSucursal").subscribe((ent) => {
      this.sourceGrid = [];
      this.initializeEntity(ent)
    });
  }


  initializeEntity(ent: any, idSerie: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Anio = this.anioSeleccionado!.Id;
    ent.Periodo = this.PeriodoSeleccionado!.Id;
    this.myForm.reset(ent);
    this.sourceGridReset(ent.Detalle);
    this.calcTotales();
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.nuevo(); break;
      case "save": this.Guardar(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
      case "return": this.findEntityByParams(); break;
      case "delete": this.delete(); break;
    }
  }

  delete() {
    const anio: number = this.myForm.value.Anio;
    const periodo: number = this.myForm.value.Periodo;
    Swal.fire({
      title: `¿Desea eliminar el Presupuesto del Período ${anio} - ${this.utilsService.getMes(periodo)}.?'`,
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.cService.deleteEntity("PresupuestoVentaSucursal", this.myForm.value.Id).subscribe(res => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.nuevo();
          this.menuItems = [
            { Id: 'copiar', Nombre: 'Copiar Presupuesto' },
            { Id: 'cargar', Nombre: 'Cargar todas las Sucursales' }
          ]
        })
      }
    });
  }

  print() {
    let nombre: string = "PRESUPUESTOS POR SUCURSAL " + this.anioSeleccionado?.Id! + " - " + this.utilsService.getMes(this.PeriodoSeleccionado?.Id!).toUpperCase();

    let reportHeader: ReportHeader = {
      NombreReporte: nombre,
      Dato1: '',
      Opc1: true
    }
    let filtro: FilterOptions | undefined = this.filtroPptos.FilterOptions?.find(p => p.Campo == 'ppto.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroPptos.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroPptos, '/Ventas/ReporteVerificadorDePresupuestoVentasSucursal');
  }

  findEntityByParams(enviarFoco: boolean = false) {
    if (this.anioSeleccionado && this.PeriodoSeleccionado) {
      const ent = this.myForm.value;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.presupuestoService.getPresupuestos(ent.Empresa.Id, this.anioSeleccionado!.Id, this.PeriodoSeleccionado!.Id, TipoPresupuesto.sucursales).subscribe((entity) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        let pptoVta: PresupuestoVentaSucursal | null = null;
        if (entity && entity.length > 0) {
          pptoVta = entity[0];
          this.setEntity(pptoVta!, enviarFoco);
        } else {
          this.nuevo();
        }

        if (!pptoVta || pptoVta!.Id === 0) {
          this.menuItems = [
            { Id: 'copiar', Nombre: 'Copiar Presupuesto' },
            { Id: 'cargar', Nombre: 'Cargar todas las Sucursales' }
          ]
        } else {
          this.menuItems = [];
        }
      });
    }
  }

  onOpencopiar() {
    this.ms.openModal(this.copiarPresupuestoSucursal, (item: PresupuestoVentaSucursal) => {
      console.log(item);
      if (item) {
        Swal.fire({
          title: `¿Desea Copiar el Presupuesto del Período ${item.Nombre} ${item.Anio}?'`,
          showDenyButton: true,
          confirmButtonText: 'Si, copiar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            item.Detalle?.map(d => { d.Id = 0 });
            item = {
              ...item,
              Id: 0,
              Detalle: item.Detalle
            }
            this.setEntity(item);
          }
        });
      }
    });
  }

  clicMenu(value: string) {
    if (value == 'copiar') {
      this.onOpencopiar();
    } else {
      this.cargarTodosSucursales();
    }
  }

  cargarTodosSucursales() {
    Swal.fire({
      title: "¿Desea cargar la lista completa de Sucursales?",
      showDenyButton: true,
      confirmButtonText: 'Si, cargar la lista de Sucursales',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        const params = new HttpParams().set("entidad", "Sucursal");
        this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Base/ObtenerListaCombo`, { params }).subscribe((lista) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          let found: boolean = false;
          let pptoSuc: PresupuestoVentaSucursal = this.myForm.value;
          if (!pptoSuc.Detalle) {
            pptoSuc.Detalle = [];
          }
          if (lista && lista.length > 0) {
            lista.forEach(item => {
              if (pptoSuc.Detalle) {
                found = pptoSuc.Detalle.find((p: any) => p.Sucursal?.Id == item.Id)?.Sucursal?.Id! > 0;
              }
              if (!found) {
                let nombre: any = item.Nombre;
                pptoSuc.Detalle!.push({ Id: 0, Meta: 0, Sucursal: { Id: item.Id, Clave: item.Clave, Nombre: nombre } as Sucursal })
              }
            })
            this.setEntity(pptoSuc)
          }
        });
      }
    })
  }

  //* metodos para el nuevo grid

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }


  buscarSucursal(e: any) {
    // if (e.target.value == "" || !isNaN(e.target.value)) {
    //   this.cords = null;
    //   this.sourceSucursal = [];
    //   return;
    // }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }

    if (e.keyCode == "40") {
      e.preventDefault();
      if (this.selectedIndex < this.sourceSucursal.length - 1) {
        this.selectedIndex = this.selectedIndex + 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }
    if (row) {
      row.scrollIntoView({ block: "center" });
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 147;

    if (this.appSettings.appSidebarMinified) {
      cords.left = cords.left - 70;
    } else {
      cords.left = cords.left - 290;
    }
    this.cords = cords;
    let valorBuscar: string = e.target.value;

    this.configService.busquedaSucursales(valorBuscar).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceSucursal = lista;
      if (this.sourceSucursal.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }

  navegarSucursal(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceSucursal.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        e.scrollIntoView({ block: "center" });
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }


  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }

  escapeSucursal(e: any) {
    this.esEscapeSucursal = true;
    let item: PresupuestoVentaSucursalDetalle = this.sourceGrid[this.indexEditing];
    e.target.value = item.Sucursal?.Clave ? item.Sucursal?.Clave : "";
    if (this.indexEditing > 0) {
      this.indexEditing = this.indexEditing - 1;
      this.initEditor(this.indexEditing, 1);
    } else {
      const txt: any = this.ctrlperiodoPptoSuc;
      txt.tagInput.nativeElement.focus()
    }
  }

  up(e: any, celda: number) {
    if (this.sourceSucursal.length > 0) { return; }
    e.preventDefault();
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeSucursal = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeSucursal = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  getLastItem(): number {
    return this.sourceGrid.filter(P => P.Sucursal && P.Meta > 0).length;
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeSucursal = true;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  enterSucursal(e: any) {
    this.esEnterSucursal = true;
    this.enviarFocoMeta = true;
    let value = '';
    if (this.sourceSucursal.length > 0) {
      value = this.sourceSucursal[this.selectedIndex].Clave;
    } else {
      value = e.target.value.trim();
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  focusSucursal(index: number, e: any, item: PresupuestoVentaSucursalDetalle) {
    this.setIndexEdit(index, e, item);
    if (e.target.value == "") {
      this.buscarSucursal(e);
      e.preventDefault();
      return;
    }
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }



  setIndexEdit(index: number, e: any, item: PresupuestoVentaSucursalDetalle) {
    /*Esto es lo nuevo*/
    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  clicTabla(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_0${this.idUnico}`)!;


      let found = false;
      if (item.Clave.len > 0) {
        this.sourceGrid.forEach((element, idx) => {
          if (element.Sucursal?.Clave == parseInt(e.target.value) && this.indexEditing != idx) {
            this.eventsService.publish('home:showAlert', {
              textAccept: "Aceptar",
              cancelButton: false,
              message: 'No puede repetir la Sucursal en el presupuesto mensual, por favor verifique.',
              onConfirm: (data: AlertResponse) => {
                let item: PresupuestoVentaSucursalDetalle = this.sourceGrid[this.indexEditing];
                e.target.value = item.Sucursal?.Clave ? item.Sucursal?.Clave : "";
                this.sourceGrid = [...this.sourceGrid];
                this.initEditor(this.indexEditing, 1);
              }
            });
            found = true;
          }
        });
      }

      if (found) {
        return;
      }



    if (input) {
      input.value = item.Clave;
      this.enviarFocoMeta = true;
    }
  }




  blurSucursal(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    if (!this.esEscapeSucursal) {
      let found = false;
      if (e.target.value.length > 0) {
        this.sourceGrid.forEach((element, idx) => {
          if (element.Sucursal?.Clave == parseInt(e.target.value) && index != idx) {
            this.eventsService.publish('home:showAlert', {
              textAccept: "Aceptar",
              cancelButton: false,
              message: 'No puede repetir la Sucursal en el presupuesto mensual, por favor verifique.',
              onConfirm: (data: AlertResponse) => {
                let item: PresupuestoVentaSucursalDetalle = this.sourceGrid[this.indexEditing];
                e.target.value = item.Sucursal?.Clave ? item.Sucursal?.Clave : "";
                this.sourceGrid = [...this.sourceGrid];
                this.initEditor(this.indexEditing, 1);
              }
            });
            found = true;
          }
        });
      }

      if (found) {
        return;
      }
    }


    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeSucursal) {
        this.esEnterSucursal = false;
        this.esEscapeSucursal = false;
        return;
      }
      let item = { ...this.sourceGrid[index] };
      if (item.Sucursal) {
        if (!e.target.value) {
          e.target.value = item.Sucursal.Clave;
          this.enviarFocoSucursal = true;
          this.sendFocus();
          return;
        }

        if (item.Sucursal.Clave != parseInt(e.target.value)) {
          this.searchSucursal(e.target.value);
        } else {
          if (this.enviarFocoMeta) {
            this.sendFocus();
          }
        }
      } else {
        this.searchSucursal(e.target.value);
      }
      this.esEnterSucursal = false;
      this.esEscapeSucursal = false;
    }, 100);
  }

  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoMeta) {
        this.initEditor(this.indexEditing, 3);
        this.enviarFocoMeta = false;
      }

      if (this.enviarFocoSucursal) {
        this.initEditor(this.indexEditing, 1);
        this.enviarFocoSucursal = false;
      }


      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  //* validaciones grid columna META
  escapeMeta(e: any) {
    let item: PresupuestoVentaSucursalDetalle = this.sourceGrid[this.indexEditing];
    e.target.value = item.Meta ? item.Meta : 0;
    this.initEditor(this.indexEditing, 1);
  }

  enterMeta(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }


  // blurPrecio22(e: any) {
  //   e.target.classList.remove("focus-editor-grid");
  //   const precio = parseFloat(e.target.value);
  //   let item = { ...this.sourceGrid[this.indexEditing] };
  //   this.sourceGrid[this.indexEditing] = { ...item };
  //   this.sourceGrid = [...this.sourceGrid];
  // }


  blurMeta(e: any) {

    e.target.classList.remove("focus-editor-grid");
    const meta = parseFloat(e.target.value);
    let item = { ...this.sourceGrid[this.indexEditing] };
    if (!item.Sucursal) { return; }
    if (meta != item.Meta) {
      this.sourceGrid[this.indexEditing].Meta = meta;
      this.sourceGrid = [...this.sourceGrid];
      this.indexEditing = this.indexEditing + 1;
      this.initEditor(this.indexEditing, 1);
      this.enviarOtroRenglon = false;
    } else {
      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }
    this.calcTotales();
  }
}

