import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  templateUrl: './impresion-lista-precios-page.component.html',
  styleUrls: []
})
export class ImpresionListaPreciosPageComponent {
  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  listaContenidoCbo: any[] = [{
    Clave: 1,
    Id: 1,
    Nombre: "Precios",
    ClaveNombre: "01 Precios",
  }];

  contenidoSeleccionado?: ComboBoxEntity;

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  get selectContenidoManualItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  public frmListaPrecios: FormGroup = this.frmBuilder.group({
    NombreReporte: ['LISTA DE PRECIOS', Validators.required],
    Contenido: [this.contenidoSeleccionado]
  });


  ngOnInit() {
    this.contenidoSeleccionado = this.listaContenidoCbo[0];
    setTimeout(() => {
      this.frmListaPrecios.controls["Contenido"].setValue(this.contenidoSeleccionado);
      this.focusControl();
    }, 300);
  }

  public filtrosListaPrecios: ReportFilter =
    {
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Kardex'
      } as ReportHeader,
      OrdenadoLista: [{ Nombre: "Clave de producto", Orden: "prod.Clave" }, { Nombre: "Nombre de producto", Orden: "prod.Nombre" }],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Lista DE PRECIOS',
      TituloVisor: 'Lista de Precios',
      NombreExcel: 'ListaPrecios.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'lista.Clave', Etiqueta: 'Lista de precio', Tipo: TypeFilter.text , BotonBusqueda: true, Entidad: "ListaPrecio"},
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Línea y Sublínea', Tipo: TypeFilter.levels, TipoNiveles: LevelType.lineSubLine },

      ]
    };

  selectedCombo(entity: any, tipo: string) {
    this.frmListaPrecios.controls[tipo].setValue(entity);

  }

  changeSelect(event: any) {
    this.filtrosListaPrecios = event;
  }

  imprimir(event: any) {
    this.filtrosListaPrecios.ReportHeader = this.frmListaPrecios.value;
    this.reportsService.printReport(this.filtrosListaPrecios, '/Ventas/ReporteListaPrecios');
  }

  focusControl() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

}
