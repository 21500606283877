<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="Moneda"
  title="Monedas" icon="fa fa-coins" subTitle="Administra la configuración de Monedas">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Moneda'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="Moneda" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />

          <components-ui-text-box [tabIndex]="3" [label]="'Abreviación'" formControlName="Abreviacion"
            [widthTextColumns]="4" [widthLabelColumns]="2" [required]="true" />

          <component-ui-check-box helpLine="Indica que la Moneda es extranjera, por ejemplo: Dólares" [tabIndex]="4"
            [widthLabelColumns]="2" formControlName="EsExtranjera" [widthTextColumns]="7" [label]="'Extranjera'" />

        </div>
      </app-panel-base>
      <app-panel-base title="configuración">
        <div class="panel-content row">
          <components-ui-text-box [tabIndex]="5" [label]="'Singular'" formControlName="NombreSingular"
            [widthTextColumns]="4" [widthLabelColumns]="2" [required]="true" />

          <components-ui-text-box [tabIndex]="6" [label]="'Plural'" formControlName="NombrePlurar"
            [widthTextColumns]="4" [widthLabelColumns]="2" [required]="true" />

          <component-ui-combo-box [label]="'Moneda SAT'" [required]="true" formControlName="MonedaSAT" [tabIndex]="7"
            [zeroMask]="2" entityName="c_Moneda" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedMoneda($event)" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Idioma'" [required]="true" formControlName="Idioma" [tabIndex]="8"
            [zeroMask]="2" entityName="IdiomaMoneda" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedIdioma($event)" [listProperty]="'ClaveNombre'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
