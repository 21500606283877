<app-container-base subTitle="Terminales Bancarias" [hasBar]="false" [hasCounter]="false" icon=" fas fa-list-check">
  <div class="screen-content">
    <app-panel-base title="Terminales Bancarias por Sucursal" style="width: 900px;">
      <div class="panel-content">
        <form [formGroup]="myForm" autocomplete="off">

          <component-ui-combo-box [label]="'Sucursal'" formControlName="Sucursal" [tabIndex]="1" [zeroMask]="3"
            entityName="Sucursal" [widthTextColumns]="3" [widthLabelColumns]="2" [widthRightColumns]="7"
            (onSelectedItem)="selected($event, 'Sucursal')" #cboSucursal />
        </form>

        <div class="pt-2 pb-2 text-start" style="width:100%;margin-left: 115px;">
          <a (click)="agregarEditar(-1)" class="btn btn-primary btn-rounded px-4 rounded-pill"><i
              class="fa fa-plus fa-lg me-2 ms-n2"></i>Agregar</a>
        </div>
        <ng-scrollbar cdkVirtualScrollViewport style="height: 200px;margin-left: 115px;" class="mt-2">
          <table class="blueTable" style="width:550px !important;">
            <thead style="position: sticky;top:0;">
              <tr>
                <th style="width: 50px;"> </th>
                <th style="width: 50px;"> </th>
                <th style="width: 150px;text-align: center;">Banco</th>
                <th style="width: 150px;text-align: center;">Cuenta Bancaria</th>
                <th style="width: 150px;text-align: center;">Número Terminal</th>
                <th style="width: 150px;text-align: center;">Nombre</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of listaTerminales; let i = index;">
                <td style="text-align: center;">
                  <i (click)="deleteRow(i)" *ngIf="item.Nombre" class="fa fa-rectangle-xmark fa-lg"
                    style="color:red;font-weight: bold; cursor: pointer;"></i>
                </td>
                <td style="text-align: center;">
                  <i (click)="agregarEditar(i)" *ngIf="item.NumeroTerminal" class="fa fa-pencil text-yellow fa-lg"
                    style="color:red;font-weight: bold; cursor: pointer;"></i>
                </td>
                <td style="text-align: center;">
                  <span>{{item.Banco ? item.Banco.Nombre : ''}}</span>
                  <span *ngIf="!item.CuentaBancaria">&nbsp;</span>
                </td>
                <td style="text-align: center;">
                  <span>{{item.CuentaBancaria ? item.CuentaBancaria.NumeroCuenta : ''}}</span>
                  <span *ngIf="!item.CuentaBancaria">&nbsp;</span>
                </td>
                <td style="text-align: center;">
                  <span *ngIf="item.CuentaBancaria">
                    {{item.NumeroTerminal}}
                  </span>
                  <span *ngIf="!item.CuentaBancaria">&nbsp;</span>
                </td>
                <td style="text-align: center;">
                  <span *ngIf="item.CuentaBancaria">
                    {{item.Nombre}}
                  </span>
                  <span *ngIf="!item.Nombre">&nbsp;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
        <ng-template #edicionTerminal let-modal let-c="close">
          <app-alta-terminales-bancarias-page [item]="itemEdicion" [idSucursal]="myForm.value.Sucursal!.Id" />
        </ng-template>
      </div>
    </app-panel-base>
  </div>
</app-container-base>
