import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppSettings } from '../../home/services/app-settings.service';



declare var slideToggle: any;

@Component({
  selector: 'float-sub-menu',
  templateUrl: './float-sub-menu.component.html'
})

export class FloatSubMenuComponent {
  @Input() menus: any = null;
  @Input() top: any = null;
  @Input() left: any = null;
  @Input() right: any = null;
  @Input() bottom: any = null;
  @Input() lineTop: any = null;
  @Input() lineBottom: any = null;
  @Input() arrowTop: any = null;
  @Input() arrowBottom: any = null;

  @Output() remainAppSidebarFloatSubMenu = new EventEmitter();
  @Output() hideAppSidebarFloatSubMenu = new EventEmitter();
  @Output() calculateFloatSubMenuPosition = new EventEmitter();
  @Output() openPage: EventEmitter<any> = new EventEmitter<any>();

  constructor(public appSettings: AppSettings) {
  }

  expandCollapseSubmenu(e: any) {
    e.preventDefault();
    var targetItem = (e.target).closest('.menu-item');
    var target = <HTMLElement>targetItem.querySelector('.menu-submenu');
    slideToggle(target);
    this.calculateFloatSubMenuPosition.emit();
  }

  remainMenu() {
    this.remainAppSidebarFloatSubMenu.emit(true);
  }

  hideMenu() {
    this.hideAppSidebarFloatSubMenu.emit(true);
  }

  onClickMenu(item: any) {
    this.openPage.emit(item);
  }
}
