import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FamiliaService {
  constructor(private http: HttpClient) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;

  getNextClave(idEmpresa: number, nivel: number, idPadre: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel).set("idPadre", idPadre);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerSiguienteClave`, { params });
  }

  actualizarFamilias(): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/ActualizarFamilias`);
  }

  getLongLevel(idEmpresa: number, nivel: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerLongitudNivel`, { params });
  }




}
