import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { ClasesProveedoresPageComponent } from './pages/clases-proveedores-page/clases-proveedores-page.component';
import { ClasesComprasPageComponent } from './pages/clases-compras-page/clases-compras-page.component';
import { CondicionesPagoProveedoresPageComponent } from './pages/condiciones-pago-proveedores-page/condiciones-pago-proveedores-page.component';
import { TiposProveedoresPageComponent } from './pages/tipos-proveedores-page/tipos-proveedores-page.component';
import { TiposDocumentosComprasPageComponent } from './pages/tipos-documentos-compras-page/tipos-documentos-compras-page.component';
import { SeriesComprasPageComponent } from './pages/series-compras-page/series-compras-page.component';
import { ProveedoresPageComponent } from './pages/proveedores-page/proveedores-page.component';
import { ComprasPageComponent } from './pages/compras-page/compras-page.component';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { NgxEditorModule } from 'ngx-editor';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CompraFormaPagoPageComponent } from './pages/compra-forma-pago-page/compra-forma-pago-page.component';
import { CompraComentarioGlobalPageComponent } from './compra-comentario-global-page/compra-comentario-global-page.component';
import { CompraOpcionCancelacionPageComponent } from './compra-opcion-cancelacion-page/compra-opcion-cancelacion-page.component';
import { DiarioComprasPageComponent } from './pages/reports/diario-compras-page/diario-compras-page.component';
import { VerificadorComprasPageComponent } from './pages/reports/verificador-compras-page/verificador-compras-page.component';
import { ConversionesPageComponent } from '../ventas/pages/conversiones-page/conversiones-page.component';
import { VentasModule } from '../ventas/ventas.module';
import { CargosExtrasComprasPageComponent } from './pages/cargos-extras-compras-page/cargos-extras-compras-page.component';
import { ConsignadoCompraPageComponent } from './pages/consignado-compra-page/consignado-compra-page.component';
import { AltaConsignadoCompraPageComponent } from './pages/alta-consignado-compra-page/alta-consignado-compra-page.component';
import { AgregarCargoAdicionalCompraPageComponent } from './pages/agregar-cargo-adicional-compra-page/agregar-cargo-adicional-compra-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EfiscoModule } from '../efisco/efisco.module';
import { VerificadorCargosAdicionalesPageComponent } from './pages/reports/verificador-cargos-adicionales-page/verificador-cargos-adicionales-page.component';
import { CopiarCompraPageComponent } from './pages/copiar-compra-page/copiar-compra-page.component';
import { PendienteEntregarCompraPageComponent } from './pages/pendiente-entregar-compra-page/pendiente-entregar-compra-page.component';
import { VerificadorComprasPorSurtirPageComponent } from './pages/reports/verificador-compras-por-surtir-page/verificador-compras-por-surtir-page.component';


@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    DateBoxComponent,
    NgxDaterangepickerMd.forRoot(),
    GuiGridModule,
    NgxEditorModule,
    NgScrollbarModule,
    VentasModule,
    DragDropModule,
    EfiscoModule
  ],
  exports: [AgregarCargoAdicionalCompraPageComponent],
  declarations: [
    ClasesProveedoresPageComponent,
    ClasesComprasPageComponent,
    CondicionesPagoProveedoresPageComponent,
    TiposProveedoresPageComponent,
    TiposDocumentosComprasPageComponent,
    SeriesComprasPageComponent,
    ProveedoresPageComponent,
    ComprasPageComponent,
    CompraFormaPagoPageComponent,
    CompraComentarioGlobalPageComponent,
    CompraOpcionCancelacionPageComponent,
    DiarioComprasPageComponent,
    VerificadorComprasPageComponent,
    CargosExtrasComprasPageComponent,
    ConsignadoCompraPageComponent,
    AltaConsignadoCompraPageComponent,
    AgregarCargoAdicionalCompraPageComponent,
    VerificadorCargosAdicionalesPageComponent,
    CopiarCompraPageComponent,
    PendienteEntregarCompraPageComponent,
    VerificadorComprasPorSurtirPageComponent

  ],
  providers: [],
})
export class ComprasModule { }
