import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Prospecto } from '../../interfaces/prospecto.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-prospectos-crm-page',
  templateUrl: './prospectos-crm-page.component.html',
  styles: [
  ]
})
export class ProspectosCrmPageComponent implements OnInit {

  public itemsCombo: ComboBoxEntity[] = [];
  info: UserLogged = {} as UserLogged;
  public EDT = EnumDataType;
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Empresa: [null],
    Sucursal: [null],
    Propietario: [null],
    Correos: [''],
    NumeroMovil: [''],
    Cargo: [''],
    MedioEntrada: [null],
    Estado: [null],
    Rfc: [''],
    FechaAlta: [Date],
    NombreEmpresa: [''],
    Direccion: [null],
    Observaciones: [''],
    FamiliasInteres: [null],
    Baja: [false],
    Eliminado: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService, private utileService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Prospecto) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: any): void {

    if (entity) {
      if (entity.Id == 0) {
        entity.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre, ClaveNombre: `${this.info.sucursal!.clave} ${this.info.sucursal!.nombre}` };
        entity.Propietario = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario, ClaveNombre: `${this.info.clave} ${this.info.nombreUsuario}` };
        entity.FechaAlta = new Date();
      }
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.info = this.utileService.getUserLogged();
    this.containerService.getEmptyEntity("Prospecto").subscribe((elem) => {
      elem.FechaAlta = new Date();
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      if (ent.Id == 0) {
        ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre, ClaveNombre: `${this.info.sucursal!.clave} ${this.info.sucursal!.nombre}` };
        ent.Propietario = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario, ClaveNombre: `${this.info.clave} ${this.info.nombreUsuario}` };
        ent.FechaAlta = new Date();
      }
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
}
