<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="false" subTitle="Ordenes de Manufactura">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off" style="display: flex;">
      <div style="width: 860px;">
        <app-panel-base title="Búsqueda de orden" [style]="'width:850px'">
          <div class="panel-content">
            <div class="d-flex">
              <component-ui-combo-box style="width:15%; margin-right: 5px;" [enabled]="false" formControlName="empresa"
                [topLabel]="true" label="Empresa" [inputProperty]="'Clave'" [zeroMask]="3" [entityName]="'Empresa'"
                [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0" />

              <component-ui-combo-box style="width:15%; margin-right: 5px;" [enabled]="false" formControlName="sucursal"
                [topLabel]="true" label="Sucursal" [inputProperty]="'Nombre'" [zeroMask]="3" [entityName]="'Sucursal'"
                [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0" />

              <component-ui-combo-box
                [enabled]="!abrirBusqueda && !abrirProduccion && !abrirHistorico && !abrirHistoricoProduccion"
                style="width:15%; margin-right: 5px;" [enabled]="listaSeries.length>0" formControlName="serie"
                [setItemsList]="getItemsSerie" [enabled]="true" [topLabel]="true" label="Serie"
                [inputProperty]="'Serie'" [zeroMask]="3" [tabIndex]="3" [widthLabelColumns]="12" [widthTextColumns]="12"
                [widthRightColumns]="0" #cboSerie />

              <components-ui-number-box
                [enabled]="!abrirBusqueda && !abrirProduccion && !abrirHistorico && !abrirHistoricoProduccion"
                style="width:15%;" [topLabel]="true" formControlName="folio" label='Folio' [tabIndex]="4"
                [widthLabelColumns]="12" [widthTextColumns]="12" (keydown.f2)="abrirBuscador()"
                (onClicIcon)="abrirBuscador()"
                [enableIcon]="!abrirBusqueda && !abrirProduccion && !abrirHistorico && !abrirHistoricoProduccion"
                #txtFolio />
              <div style="width: 40%;">
                <span class="float-end hidden-print">
                  <a *ngIf="!abrirBusqueda && !abrirProduccion && !abrirHistorico && !abrirHistoricoProduccion"
                    (click)="BuscarOrden()" href="javascript:;" class="btn btn-sm btn-white mb-10px me-2"><i
                      class="fa fa-search t-plus-1 text-danger fa-fw fa-lg"></i> Buscar</a>
                </span>
              </div>
            </div>
          </div>
        </app-panel-base>
        <app-panel-base title="Información de la orden {{getTextoOrden}}" [style]="'width:850px'">
          <div class="panel-content">

            <components-ui-text-box formControlName="serieFolioDocumentoVentaOrigen" [enabled]="false" [tabIndex]="5"
              [maxLength]="500" [upperCase]="true" [label]="'Factura origen'" [widthTextColumns]="10"
              [widthLabelColumns]="2" />

            <components-ui-date-box label="Fecha Emisión" [enabled]="false" [tabIndex]="7"
              formControlName="fechaEmision" [widthLabelColumns]="2" [widthTextColumns]="6" />

            <components-ui-date-box label="Fecha Compromiso" [enabled]="false" [tabIndex]="7"
              formControlName="fechaCompromisoEntrega" [widthLabelColumns]="2" [widthTextColumns]="6" />

            <components-ui-date-box label="Cancelación" [enabled]="false" [tabIndex]="8"
              formControlName="fechaCancelacion" [widthLabelColumns]="2" [widthTextColumns]="6" />

            <components-ui-text-box [enabled]="false" [tabIndex]="9" [maxLength]="500" [upperCase]="true"
              [label]="'Motivo cancelación'" formControlName="motivoCancelacion" [widthTextColumns]="10"
              [widthLabelColumns]="2" />
            <hr>
            <components-ui-text-box [enabled]="false" [tabIndex]="10" [maxLength]="500" [upperCase]="true"
              [label]="'Cliente'" [widthTextColumns]="10" [widthLabelColumns]="2" #txtCliente />

          </div>
        </app-panel-base>
        <app-panel-base title="Productos finales a producir" [style]="'width:850px'">
          <div class="panel-content">
            <div *ngIf="myForm.value.id>0" class="fs-10px fw-bold">{{myForm.value.porcentajeAvance}}% de avance</div>
            <div *ngIf="myForm.value.id>0" class="d-flex align-items-center mb-5px">
              <div class="progress h-10px w-100 mb-0 me-2">
                <div class="progress-bar progress-bar-striped bg-success"
                  [style]="'width: '+myForm.value.porcentajeAvance+'%;'">
                </div>
              </div>
            </div>
            <div class="mb-2">
              <ng-scrollbar style="height: 120px; width:820px; border-bottom: 1px solid #AAAAAA;">
                <table class="blueTable" style="width:810px;">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 250px;">Producto</th>
                      <th style="width: 80px;">Unidad</th>
                      <th style="width: 70px;">Solicitado</th>
                      <th style="width: 70px;">Producido</th>
                      <th style="width: 70px;">M/Term</th>
                      <th style="width: 55px;">Pendiente</th>
                      <th style="width: 55px;">O/Unidades</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
                      *ngFor="let item of listaConceptos; let index = index;">
                      <td class="text-dark" style="width:250px">
                        {{item.producto ? (item.producto.clave + " => " + item.producto.nombre) : '' }}
                      </td>
                      <td class="text-dark" style="width:80px">
                        {{item.unidad.nombre }}
                      </td>
                      <td class="text-dark" style="width:70px; text-align: right;">
                        {{item.cantidad > 0 ? (item.cantidad|number:'1.2-6') : '' }}
                      </td>
                      <td class="text-dark" style="width:70px;text-align: right;">
                        {{item.cantidadProducida|number:'1.2-6' }}
                      </td>
                      <td class="text-dark" style="width:70px;text-align: right;">
                        {{item.cantidadMarcadaPendiente|number:'1.2-6' }}
                      </td>
                      <td class="text-dark" style="width:55px;text-align: right;">
                        {{item.cantidadPendiente|number:'1.2-6' }}
                      </td>
                      <td class="text-dark" style="width:55px">
                        {{item.conversiones}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
              <!--
              <gui-grid [localization]="localization" [source]="listaConceptos" [autoResizeWidth]="false" [width]="803"
                [virtualScroll]="true" [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">

                <gui-grid-column header="Producto" [width]="300" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.producto ? (item.producto.clave + " => " + item.producto.nombre) : '' }}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Unidad" [width]="80" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.unidad.nombre }}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Solicitado" [width]="80" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.cantidad > 0 ? (item.cantidad|number:'1.2-6') : '' }}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Producido" [width]="100" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.cantidadProducida|number:'1.2-6' }}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="M/Term" [width]="80" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.cantidadMarcadaPendiente|number:'1.2-6' }}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Pendiente" [width]="80" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.cantidadPendiente|number:'1.2-6' }}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="O/unidades" field="conversiones" [width]="80" [cellEditing]="false" />
              </gui-grid> -->
            </div>
            <components-ui-number-box formControlName="cantidad" [enabled]="false" [tabIndex]="11" [maxLength]="500"
              [label]="'Cantidad Total'" [widthTextColumns]="2" [widthLabelColumns]="10" />

            <components-ui-number-box formControlName="cantidadProducida" [enabled]="false" [tabIndex]="11"
              [maxLength]="500" [label]="'Cantidad Producida'" [widthTextColumns]="2" [widthLabelColumns]="10" />

            <components-ui-number-box *ngIf="myForm.value.usuarioMarcoTerminada"
              formControlName="cantidadPendienteTerminada" [enabled]="false" [tabIndex]="11" [maxLength]="500"
              [label]="'Cantidad Marcada Como Terminada'" [widthTextColumns]="2" [widthLabelColumns]="10" />
            <hr>
            <components-ui-number-box formControlName="cantidadPendiente" [enabled]="false" [tabIndex]="11"
              [maxLength]="500" [label]="'Cantidad Pendiente'" [widthTextColumns]="2" [widthLabelColumns]="10" />
          </div>
        </app-panel-base>



        <app-panel-base title="Usuarios" [style]="'width:850px'" *ngIf="myForm.value.usuarioElabora">
          <div class="panel-content">
            <div class="widget-list mb-0" style="display: flex;">
              <div class="widget-list-item" style="width:25%;">
                <div class="widget-list-media">
                  <i class="fas fa-user-check fa-2x text-green"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.usuarioElabora.nombre}}</h4>
                  <p class="widget-list-desc">Usuario elaboró</p>
                </div>
              </div>
              <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.usuarioCancela">
                <div class="widget-list-media">
                  <i class="fas fa-user-xmark fa-2x text-red"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.usuarioCancela.nombre}}</h4>
                  <p class="widget-list-desc">Usuario canceló</p>
                </div>
              </div>
              <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.usuarioMarcoTerminada">
                <div class="widget-list-media">
                  <i class="fas fa-users-gear fa-2x text-blue"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.usuarioMarcoTerminada.nombre}}</h4>
                  <p class="widget-list-desc">Usuario marco terminada el {{getFechaTerminada}}</p>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>
      </div>
      <div style="width: 280px;">
        <app-panel-base title="Dirección de entrega"
          *ngIf="myForm.value.direccionEntrega && myForm.value.id>0 && !abrirBusqueda && !abrirProduccion && !abrirHistorico && !abrirHistoricoProduccion"
          [activeIconEdit]="true" [toolTipIcon]="'Editar dirección de entrega'" (onClicIcon)="onClicEdit()">
          <div class=" panel-content">
            <address>
              <strong class="text-dark">Recibe: {{myForm.value.direccionEntrega.nombreRecibe | titlecase}}</strong>
              <hr>
              {{myForm.value.direccionEntrega.calle}} No. {{myForm.value.direccionEntrega.numeroExterior}}
              {{myForm.value.direccionEntrega.numeroInterior ? ', Interior: ':''}}
              {{myForm.value.direccionEntrega.numeroInterior}}<br>
              {{myForm.value.direccionEntrega.localidad}}, {{myForm.value.direccionEntrega.estado}}<br>
              {{myForm.value.direccionEntrega.pais}}, C.P. {{myForm.value.direccionEntrega.codigoPostal}}<br>
              Teléfono: <a
                href="tel:+52{{myForm.value.direccionEntrega.telefono}}">{{myForm.value.direccionEntrega.telefono}}</a>
              <br> Referencias:
              {{myForm.value.direccionEntrega.descripcion}}
            </address>
            <a (click)="openMap()" class="btn btn-success d-block mb-10px me-2"><i
                class="fa fa-map-pin t-plus-1 text-white fa-fw fa-lg"></i>
              Ver En Mapa</a>
          </div>
        </app-panel-base>

        <app-panel-base title="Opciones"
          *ngIf="myForm.value.id>0 && !abrirBusqueda && !abrirProduccion && !abrirHistorico && !abrirHistoricoProduccion">
          <div class="panel-content d-flex" style="flex-direction: column;">
            <a (click)="imprimirOrdenManufactura()" [ngbTooltip]="'Imprime PDF con la información de la orden'"
              class="btn btn-sm btn-white mb-10px" style="text-align: left;"><i
                class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
              Imprimir</a>
            <a [ngbTooltip]="'Visualiza el histórico de movimientos relacionados a la orden'" (click)="verHistorico()"
              style="text-align: left;" class="btn btn-sm btn-white mb-10px"><i
                class="fa fa-sitemap t-plus-1 fa-fw fa-lg"></i>
              Ver Histórico</a>
            <a [ngbTooltip]="'Marca como terminada la orden, se usa cuando ya no se va a terminar de elaborar el producto final'"
              (click)="preguntarMarcarTerminada()"
              *ngIf="!myForm.value.fechaCancelacion && !myForm.value.usuarioMarcoTerminada && myForm.value.cantidadPendiente >0"
              style="text-align: left;" class="btn btn-sm btn-white mb-10px"><i
                class="fa fa-circle-check t-plus-1 fa-fw fa-lg"></i>
              Marcar como terminada</a>
            <a (click)="verHistoricoProducciones()" *ngIf="myForm.value.producciones?.length>0"
              [ngbTooltip]="'Muestra todo lo que se ha ocupado de inventario'" style="text-align: left;"
              class="btn btn-sm btn-white mb-10px"><i class="fa fa-fill-drip t-plus-1 fa-fw fa-lg"></i>
              Ver usos de inventario</a>
            <a (click)="elaborar()" [ngbTooltip]="'Genera las salidas de inventario para elaborar el producto final'"
              *ngIf="myForm.value.cantidadPendiente>0 && !myForm.value.fechaCancelacion" style="text-align: left;"
              class="btn btn-sm btn-white mb-10px"><i class="fa fa-truck-ramp-box t-plus-1 fa-fw fa-lg"></i>
              Elaborar producto final</a>
          </div>
        </app-panel-base>
        <app-panel-base title="Motivo de terminar la orden" *ngIf="myForm.value.comentariosMarcoTerminada">
          <div class="panel-content">
            <div class="lead mb-3">
              <i class="fa fa-quote-left fa-fw float-start opacity-5 me-3 mb-3 mt-3 fa-lg"></i>
              {{myForm.value.comentariosMarcoTerminada}}
              <i class="fa fa-quote-right fa-fw opacity-5 me-3 mb-3 mt-3 fa-lg"></i>
            </div>
          </div>
        </app-panel-base>
        <app-panel-base title="Comentarios desde la factura" *ngIf="myForm.value.comentarios">
          <div class="panel-content">
            <div class="lead mb-3 pulse">
              <i class="fa fa-quote-left fa-fw float-start opacity-5 me-3 mb-3 mt-3 fa-lg"></i>
              {{myForm.value.comentarios}}
              <i class="fa fa-quote-right fa-fw opacity-5 me-3 mb-3 mt-3 fa-lg"></i>
            </div>
          </div>
        </app-panel-base>
      </div>
      <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.fechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div style="margin-top:150px;" class="cancelled-detail">{{getCancelDate(myForm.value.fechaCancelacion)}}</div>
        <div class="cancelled-detail">{{ myForm.value.motivoCancelacion | uppercase}}</div>
      </div>
    </form>
    <div *ngIf="abrirHistorico">
      <app-historico-orden-page [lista]="myForm.value.producciones" [listaHistorico]="myForm.value.historicos"
        (onClose)="onCloseHistorico()" />
    </div>
    <div *ngIf="abrirBusqueda">
      <app-busqueda-orden-page (onClose)="onCloseBusqueda($event)" />
    </div>
    <div *ngIf="abrirProduccion">
      <app-generacion-orden-page [idOrden]="myForm.value.id" [lista]="myForm.value.conceptos"
        (onClose)="onCloseProduccion($event)" />
    </div>
    <div *ngIf="abrirHistoricoProduccion">
      <app-producciones-por-orden-page [idOrden]="myForm.value.id" (onClose)="onCloseHistoricoProduccion()" />
    </div>
    <div *ngIf="abrirMarcarTerminada">
      <app-marca-orden-terminada-page [idOrden]="myForm.value.id" (onClose)="closeMarcarTerminada()" />
    </div>
    <ng-template #modalDireccion let-modal let-c="close">
      <app-direccion-entrega-page [Direccion]="direccionentrega" />
    </ng-template>
    <ng-template #modalMapa let-modal let-c="close">
      <app-direccion-mapa-page [Direccion]="''" />
    </ng-template>
  </div>

</app-container-base>
