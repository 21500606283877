import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResguardoService } from '../../services/resguardo.servuice';
import { ConsultaResguardo } from '../../interfaces/remision.interface';
import * as moment from 'moment';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-listado-resguardos-page',
  templateUrl: './listado-resguardos-page.component.html',
  styles: [
  ]
})
export class ListadoResguardosPageComponent implements OnInit {


  lista: ConsultaResguardo[] = [];
  indexEditing:number =0;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  public idCliente: number = 0;

  constructor(private rService: ResguardoService, private ms: ModalService) {
  }

  seleccionar(reg: ConsultaResguardo){
    this.onClose.emit(reg);
    this.ms.closeModal(reg);
  }

  ngOnInit(): void {
    this.rService.getResguardosPorCliente(this.idCliente).subscribe((lista) => {
      this.lista = lista;
    });
  }

  getDate(fecha: any): string {
    return moment(fecha).format("DD/MM/YYYY HH:mm:ss")
  }

  closeModal() {
    this.ms.closeModal(null);
    this.onClose.emit(null);
  }

}
