import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-de-polizas-page',
  templateUrl: './verificador-de-polizas-page.component.html',
  styles: [
  ]
})
export class VerificadorDePolizasPageComponent {

  private utils = inject(UtilsService);
  private eventsService = inject(EventsService);
  menuId: string = "verificadordepolizas";

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa', Orden: 'clave' }
  ]

  public luegoPorLista: ReportFilterOrderList[] = [{ Nombre: 'Póliza', Orden: 'Abreviacion, Folio, Fecha,orden ' },
    { Nombre: 'Cuenta Subcuenta', Orden: 'ClaveCuentaNivelGlobal ' }
  ];





  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      EsContabilidad: true,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista:this.luegoPorLista,
      NombreReporte: '',
      TituloVisor: 'Verificador de pólizas',
      NombreExcel: 'Verificador de pólizas.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'tp.Abreviacion', Etiqueta: 'Tipo de póliza', Tipo: TypeFilter.text },
        { Campo: 'mp.Folio', Etiqueta: 'Número de póliza', Tipo: TypeFilter.number },
        { Campo: 'mp.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mp.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
        { Campo: 'usu.Clave', Etiqueta: 'Usuario elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'mp.Referencia', Etiqueta: 'Factura', Tipo: TypeFilter.text },
      ]
    };

  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: false
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }


  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE PÓLIZAS ' + this.rangoFechas);
  }

  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.focus('txtNombreReporte');
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE PÓLIZAS ' + this.rangoFechas);
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteVerificadorDePolizas');
  }
}
