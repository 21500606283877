<div class="bg-light m-2" [style]="getGlobalStyle" cdkDrag style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" >
  <div class="widget rounded" style="margin-bottom: 0px;" data-bs-theme="" *ngIf="title.length>0">
    <div class="widget-header" style="cursor:move;" cdkDragHandle>
      <h4 class="widget-header-title text-center">{{title}}</h4>
    </div>
  </div>
  <ul class="nav nav-tabs" role="tablist" *ngIf="anio>0">
    <li class="nav-item" role="presentation"><a href="#busqueda{{anio>0?'conta':''}}" data-bs-toggle="tab"
        class="nav-link active" aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span><span
          class="d-sm-block d-none">Búsqueda</span></a></li>
    <li *ngIf="mostrarAgregados" class="nav-item" role="presentation"><a href="#agregados{{anio>0?'conta':''}}"
        data-bs-toggle="tab" class="nav-link" aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span><span
          class="d-sm-block d-none">Ya agregados
          <span
            class="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px">{{listaAgregados.length}}</span>
        </span></a></li>
  </ul>
  <div class="tab-content panel p-3 rounded-0 rounded-bottom">
    <div id="busqueda{{anio>0?'conta':''}}" class="tab-pane fade active show" [style]="getPanelStyle">
      <app-container-base subTitle="" [containerInnerHeight]="403" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
        [hasCounter]="false">
        <div class="screen-content">
          <form [formGroup]="myForm" autocomplete="off">
            <div class="card-body">
              <div class="row gx-2 pb-5px">
                <div class="col-4">

                  <components-ui-text-box placeHolder="Escriba el texto a buscar en los CFDI" formControlName="busqueda"
                    [label]="''" [widthTextColumns]="12" [tabIndex]="1" [widthLabelColumns]="0" #txtBuscarCfdi />


                </div>
                <div class="col-2" style="margin-top: -27px;margin-right: -45px;">
                  <components-ui-date-box formControlName="fechaInicio" separator="" [topLabel]="false" label=""
                    [tabIndex]="2" [widthLabelColumns]="0" [widthTextColumns]="12" />
                </div>
                <div class="col-2" style="margin-right: -42px;">
                  <components-ui-date-box formControlName="fechaFin" [widthLabelColumns]="2" [label]="'al'"
                    [tabIndex]="3" [topLabel]="false" [widthTextColumns]="8" />
                </div>
                <div class="col-2">
                  <component-ui-combo-box [label]="'Comp'" [zeroMask]="0" entityName="c_TipoDeComprobante"
                    [widthTextColumns]="7" [inputProperty]="'Nombre'" [widthLabelColumns]="5" [widthRightColumns]="0"
                    [tabIndex]="4" (onSelectedItem)="selectedCombo($event,'TipoDeComprobante')"
                    [listProperty]="'ClaveNombre'" />
                </div>
                <div class="col-2">
                  <a (click)="searchEnter()" class="btn btn-primary btn-xs d-block">Buscar</a>
                </div>
              </div>
              <div class="row gx-2 pb-1px">
                <div [style]="getDivStyle">
                  <ng-scrollbar class="h-100  m-2">
                    <table class="table table-bordered table-sm table-striped mb-0 "
                      style="font-family: monospace;table-layout: fixed; ">
                      <thead style="position: sticky;top: 0; background: var(--bs-component-bg);">
                        <tr class="border-bottom">
                          <th class="text-center" style="width:30px !important;">Sel</th>
                          <th class="text-center" style="width:30px !important;">PDF</th>
                          <th class="text-center" style="width:30px !important;">XML</th>
                          <th class="text-start">Tipo</th>
                          <th class="text-start">UUID</th>
                          <th class="text-start">Emisor RFC</th>
                          <th class="text-start">Emisor Nom</th>
                          <th class="text-start">SerieFolio</th>
                          <th class="text-center">Fecha</th>
                          <th class="text-end">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of lista; let i = index;">
                          <td class="text-center"><input (click)="seleccionar(i,1)" class="form-check-input"
                              type="checkbox" /></td>
                          <td class="text-center"><i (click)="print(item.uuid)" style="cursor: pointer;"
                              class="fas fa-print fa-lg"></i>
                          </td>
                          <td class="text-center"><i (click)="downloadXML(item.uuid)" style="cursor: pointer;"
                              class="fas fa-file-code fa-lg"></i></td>
                          <td class="text-truncate">{{ item.tipoDeComprobante | uppercase }}</td>
                          <td class="text-truncate">{{item.uuid}}</td>
                          <td class="text-truncate">{{item.emisorRfc}}</td>
                          <td class="text-truncate">{{item.emisorNombre}}</td>
                          <td class="text-truncate">{{item.serieFolio}}</td>
                          <td class="text-truncate">{{item.fechaEmision}}</td>
                          <td class="text-truncate text-end">{{item.total | number:'1.2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                  <p class="pt-4px">Solo se muestran 200 registros [mostrando: {{lista.length}}]</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-container-base>
    </div>
    <div *ngIf="mostrarAgregados" id="agregados{{anio>0?'conta':''}}" class="tab-pane fade"
      style="width: 900px;height: 350px;">
      <div class="card-body">
        <div class="row gx-2 pb-5px">
          <div style="border: 1px dotted;width: 907px;height: 319px;margin-left: 5px">
            <div style="text-align: right;" class="m-2">
              <div *ngIf="listaAgregados.length>0" (click)="clearAdded()" class="btn btn-danger"
                style="margin-right: 5px;">Eliminar todos</div>
            </div>

            <ng-scrollbar class="h-100  m-2">
              <table class="table table-bordered table-sm table-striped mb-0 "
                style="font-family: monospace;table-layout: fixed; ">
                <thead style="position: sticky;top: 0; background: var(--bs-component-bg);">
                  <tr class="border-bottom">
                    <th class="text-center" style="width:30px !important;"></th>
                    <th class="text-center" style="width:30px !important;">PDF</th>
                    <th class="text-center" style="width:30px !important;">XML</th>
                    <th class="text-start">Tipo Comp</th>
                    <th class="text-start">UUID</th>
                    <th class="text-start">Emisor RFC</th>
                    <th class="text-start">Emisor Nom</th>
                    <th class="text-start">SerieFolio</th>
                    <th class="text-center">Fecha</th>
                    <th class="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listaAgregados; let i = index;">
                    <td> <i (click)="deleteRow(i)" class="fa fa-rectangle-xmark"
                        style="color:red;font-weight: bold; cursor: pointer;"></i></td>
                    <td class="text-center"><i (click)="print(item.uuid)" style="cursor: pointer;"
                        class="fas fa-print fa-lg"></i>
                    <td> <i (click)="downloadXML(item.uuid)" style="cursor: pointer;"
                        class="fas fa-file-code fa-lg"></i></td>
                    <td class="text-truncate"> {{ item.tipoDeComprobante | uppercase }}</td>
                    <td class="text-truncate">{{item.uuid}}</td>
                    <td class="text-truncate">{{item.emisorRfc}}</td>
                    <td class="text-truncate">{{item.emisorNombre}}</td>
                    <td class="text-truncate">{{item.serieFolio}}</td>
                    <td class="text-truncate">{{item.fechaEmision}}</td>
                    <td class="text-truncate text-end"> {{item.total| number:'1.2'}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: right; margin-top: -11px;">
    <button *ngIf="!searching && anio>0 && mes >0" (click)="accept()" class="btn btn-success"
      style="margin-right: 5px;">Aceptar</button>
    <button *ngIf="!searching && anio>0 && mes >0" (click)="cancel()" class="btn btn-danger">Cancelar</button>
  </div>
</div>
