<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" (keydown)="keydown($event)">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar Cargo Adicional a la Compra</div>
  <app-container-base subTitle="" [containerInnerHeight]="650" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
    [hasCounter]="false">
    <div class="screen-content">
      <form [formGroup]="myForm" autocomplete="off">
        <app-panel-base title="Generales del Cargo">
          <div class="panel-content">
            <component-ui-combo-box formControlName="Tipo" [required]="true" label='Tipo' [inputProperty]="'Nombre'"
              (onSelectedItem)="onSelectedItem($event,'Tipo')" entityName="CargoAdicionalCompra" [tabIndex]="this.tabIndex + 1" helpLine="Es el tipo del cargo"
              [widthLabelColumns]="4" [widthTextColumns]="3" [widthRightColumns]="5" #cboTipo />

            <component-ui-combo-box formControlName="Moneda" [required]="true" label='Moneda' [inputProperty]="'Nombre'"
              (onSelectedItem)="onSelectedItem($event,'Moneda')" entityName="Moneda" [tabIndex]="this.tabIndex + 2"
              [widthLabelColumns]="4" [widthTextColumns]="3" [widthRightColumns]="5" />

            <components-ui-number-box formControlName="TipoCambio" [tabIndex]="this.tabIndex + 3" [maxLength]="15"
              [label]="'Tipo Cambio'" [widthTextColumns]="3" [widthLabelColumns]="4" />

          </div>
        </app-panel-base>
        <app-panel-base title="Datos DIOT">
          <div class="panel-content">

            <components-ui-number-box (blur)="blurProveedor($event)" [tabIndex]="this.tabIndex + 4" [maxLength]="15"
              (onClicIcon)="openProvSearch()" (keydown.f2)="openProvSearch()" [label]="'Proveedor'" [enableIcon]="true"
              [widthTextColumns]="3" [widthLabelColumns]="4" #txtProveedor />
            <div class="form-group row">
              <div class="col-sm-4" style="text-align: right;">
                Nombre:
              </div>
              <div class="col-sm-8 text-truncate" style="text-align: left;">
                {{getNombreProveedor | uppercase}}
              </div>
            </div>
            <components-ui-date-box [required]="true" label="Fecha" [tabIndex]="this.tabIndex + 5" formControlName="Fecha"
              [widthLabelColumns]="4" [widthTextColumns]="3" #dtFecha/>

            <components-ui-text-box (blur)="blurSerieFolio($event)" formControlName="SerieFolio" [tabIndex]="this.tabIndex + 6"
              [maxLength]="150" [label]="'Factura'" [widthTextColumns]="3" [widthLabelColumns]="4" #txtSerieFolio />

            <components-ui-text-box (keydown.f2)="openCfdi()" (onClicIcon)="openCfdi()" formControlName="UUID"
              [tabIndex]="this.tabIndex + 7" [maxLength]="40" [enabled]="false" [label]="'UUID'" [widthTextColumns]="8"
              [enableIcon]="true" [widthLabelColumns]="4" />



            <components-ui-number-box [formatoMoneda]="true" formControlName="Subtotal" [tabIndex]="this.tabIndex + 8" [maxLength]="15"
              [label]="'Subtotal'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="SubtotalGravaIVA" [tabIndex]="this.tabIndex + 9"
              [maxLength]="15" [label]="'Subtotal IVA'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="SubtotalExento" [tabIndex]="this.tabIndex + 10"
              [maxLength]="15" [label]="'Subtotal Exento'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="SubtotalTasaCero" [tabIndex]="this.tabIndex + 11"
              [maxLength]="15" [label]="'Subtotal Tasa 0'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="IVARetenido" [tabIndex]="this.tabIndex + 12"
              [maxLength]="15" [label]="'IVA Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="ISRRetenido" [tabIndex]="this.tabIndex + 13"
              [maxLength]="15" [label]="'ISR Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="ImpLocalTrasladado" [tabIndex]="this.tabIndex + 14"
              [maxLength]="15" [label]="'Imp Loc Trasladado'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="ImpLocalRetenido" [tabIndex]="this.tabIndex + 15"
              [maxLength]="15" [label]="'Imp Loc Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="IVA" [tabIndex]="this.tabIndex +16" [maxLength]="16"
              [label]="'IVA'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="Total" [tabIndex]="this.tabIndex +17" [maxLength]="16"
              [label]="'Total'" [widthTextColumns]="3" [widthLabelColumns]="4" #txtTotal />

            <component-ui-check-box [label]="'No Generar CXP'" formControlName="NoGeneraCarteraCXP" [tabIndex]="this.tabIndex +  18"
              [widthTextColumns]="3" [widthLabelColumns]="4" />

            <component-ui-check-box [label]="'No Prorratear'" formControlName="NoProrratear" [tabIndex]="19"
              [widthTextColumns]="3" [widthLabelColumns]="4" />

          </div>
        </app-panel-base>
        <div style="text-align: right; margin-top: 5px;">
          <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8] Agregar</div>
          <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
        </div>
        <ng-template #content let-modal let-c="close">
          <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
            [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #ctrlBusqueda />
        </ng-template>
        <ng-template let-modal let-c="close" #ctrlAgregarCfdi>
          <app-ctrl-buscador-cfdi [mostrarAgregados]="false" [title]="'CFDI a relacionar al Cargo'"
            [anio]="myForm.value.Fecha.getFullYear()" [mes]="myForm.value.Fecha.getMonth()" [setIsNew]="true" />
        </ng-template>
      </form>

    </div>
  </app-container-base>
</div>
