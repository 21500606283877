<div class="card text-center border-0" cdkDrag style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      FACTURAS EN RESGUARDO
    </span>
  </div>

  <app-panel-base title="FACTURAS EN RESGUARDO DEL CLIENTE">
    <div class="panel-content">
      <ng-scrollbar style="height: 150px; width:720px; border-bottom: 1px solid #AAAAAA;">
        <table class="blueTable" style="width:710px;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th style="width: 50px;"></th>
              <th style="width: 50px;">Serie/Folio</th>
              <th style="width: 50px;">Fecha</th>
              <th style="width: 30px;text-align:right">Cantidad</th>
              <th style="width: 30px;text-align:right">Entregado</th>
              <th style="width: 30px;text-align:right">Restante</th>
            </tr>
          </thead>
          <tbody>
            <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
              *ngFor="let item of lista; let index = index;">
              <td style="text-align: center;">
                <a (click)="seleccionar(item)" class="btn m-1 btn-sm btn-green btn-xs d-block"><i
                    class="fa fa-share-from-square fa-fw"></i>Seleccionar</a>
              </td>
              <td class="text-dark" style="width:70px">
                <span>{{item.serie}} / {{item.folio}}</span>
              </td>
              <td class="text-dark">
                <span>{{getDate(item.fecha)}}</span>
              </td>
              <td style="width: 70px;text-align:right;padding-right:5px" class="text-dark">
                <span>{{item.cantidad | number:'1.2-6'}}</span>
              </td>
              <td style="width: 70px;text-align:right;padding-right:5px" class="text-dark">
                <span>{{item.entregado | number:'1.2-6'}}</span>
              </td>
              <td style="width: 70px;text-align:right;padding-right:5px" class="text-dark">
                <span>{{item.restante | number:'1.2-6'}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-scrollbar>
      <!-- <gui-grid [source]="lista" [autoResizeWidth]="false" [width]="640" [virtualScroll]="true" [rowHeight]="30"
        [rowSelection]="false" [theme]="'fabric'" [maxHeight]="350">
        <gui-grid-column [width]="120" [cellEditing]="false">
          <ng-template let-item="item">
            <a (click)="seleccionar(item)" class="btn btn-sm btn-white btn-xs"><i
                class="fa fa-share-from-square fa-fw"></i>Seleccionar</a>
          </ng-template>
        </gui-grid-column>
        <gui-grid-column header="Serie/Folio" [width]="110" [cellEditing]="false">
          <ng-template let-item="item">
            {{item.serie}} / {{item.folio}}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column header="Fecha" [width]="110" [cellEditing]="false">
          <ng-template let-item="item">
            {{getDate(item.fecha)}}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column header="Cantidad" field="cantidad" [width]="100" [cellEditing]="false">
          <ng-template let-item="item">
            {{item.cantidad | number:'1.2-6'}}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column header="Entregado" field="entregado" [width]="100" [cellEditing]="false">
          <ng-template let-item="item">
            {{item.entregado | number:'1.2-6'}}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column header="Restante" field="restante" [width]="100" [cellEditing]="false">
          <ng-template let-item="item">
            {{item.restante | number:'1.2-6'}}
          </ng-template>
        </gui-grid-column>
      </gui-grid> -->
    </div>
  </app-panel-base>

  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>
