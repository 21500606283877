<div class="card text-center border-0" cdkDrag style="width: 900px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Historico de Ventas: {{nombreCliente}}</div>
  <div class="card-body bg-light p-1">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <form [formGroup]="myForm" autocomplete="off">
          <app-panel-base title="Seleccione los filtros" style="height: 450px;">
            <div class="panel-content">

              <component-ui-combo-box [label]="'Documento'" formControlName="DocumentoVenta" [tabIndex]="1"
                [zeroMask]="2" entityName="DocumentoVenta" [widthTextColumns]="4" [widthLabelColumns]="3"
                (onSelectedItem)="selectedCombo($event, 'DocumentoVenta')" [listProperty]="'ClaveNombre'" />
              <div class="row" style="margin-left:35px;">
                <div class="col-md-6">
                  <components-ui-date-box label='Fecha inicio' formControlName="FechaInicio" [tabIndex]="2"
                    [widthLabelColumns]="5" [widthTextColumns]="2" [required]="true" #txtFecha />
                </div>
                <div class="col-md-6">
                  <components-ui-date-box label='Fecha Fin' formControlName="FechaFin" [tabIndex]="3"
                    [widthLabelColumns]="5" [widthTextColumns]="2" [required]="true" />
                </div>
              </div>

              <div (click)="buscar()" class="btn btn-primary d-block mt-2 mb-2">Buscar</div>


              <ng-scrollbar style="height: 250px; width:850px; border-bottom: 1px solid #AAAAAA;">
                <table class="blueTable" style="width:810px;">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 40px;"></th>
                      <th style="width: 100px;">Sucursal</th>
                      <th style="width: 100px;">Documento</th>
                      <th style="width: 90px;text-align:center;">SerieFolio</th>
                      <th style="width: 90px;text-align:right;">Total</th>
                      <th style="width: 150px;text-align:center;">Emisión</th>
                      <th style="width: 90px;">Cancelación</th>
                      <th style="width: 150px;">Elaboró</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of lista; let index = index;">
                      <td style="text-align: center;">
                        <div (click)="ver(item.idVenta)" class="btn btn-success btn-xs">Ver</div>
                      </td>
                      <td class="text-dark">
                        <span class="ms-1">{{item.sucursalNombre}}</span>
                      </td>
                      <td class="text-dark">
                        <span class="ms-1">{{item.documentoNombre}}</span>
                      </td>
                      <td class="text-dark" style="width: 90px;text-align:center;">
                        <span>{{item.serieFolio}}</span>
                      </td>
                      <td class="text-dark" style="width: 90px;text-align:right;">
                        <span class="me-1"> {{item.total|number:'1.2'}}</span>
                      </td>
                      <td class="text-dark" style="width: 90px;text-align:center;">
                        <span> {{item.fechaEmision}} </span>
                      </td>
                      <td class="text-dark" style="width: 90px;text-align:center;">
                        <span> {{item.fechaCancelacion}} </span>
                      </td>
                      <td class="text-dark">
                        <span class="ms-1">{{item.usuarioNombre}} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
          </app-panel-base>
        </form>
      </div>
    </app-container-base>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cerrar</div>
    </div>
  </div>
</div>
