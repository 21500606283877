import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Gasto, TipoGasto } from '../../interfaces/gasto.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { ClaseGastoCajaChica } from '../../interfaces/tipotransferencia.interface';
import { Proveedor } from 'src/app/compras/interfaces/proveedores.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Unidad } from 'src/app/Inventarios/interfaces/producto.interface';
import { TipoServicioUnidad } from 'src/app/ventas/interfaces/unidad.interface';
import { FinanzasService } from '../../services/finanzas.service';
import Swal from 'sweetalert2';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { ModalService } from 'src/app/service/modal.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import * as moment from 'moment';

@Component({
  selector: 'app-gastos-page',
  templateUrl: './gastos-page.component.html',
  styles: [
  ]
})
export class GastosPageComponent implements OnInit {

  private reportsService = inject(ReportsService)


  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  @ViewChild('txtClave')
  public txtClave!: ElementRef<TextBoxComponent>;
  @ViewChild('txtTotal')
  public txtTotal!: ElementRef<TextBoxComponent>;
  @ViewChild('txtSerieFolio')
  public txtSerieFolio!: ElementRef<TextBoxComponent>;
  @ViewChild('txtProveedor')
  public txtProveedor!: ElementRef<TextBoxComponent>;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('ctrlAgregarCfdi') public ctrlAgregarCfdi!: ElementRef<HTMLElement>;
  addingcfdi: boolean = false;
  public itemsCombo: ComboBoxEntity[] = [];
  info: UserLogged = { ambienteId: 0, numero: 0, clave: 0, nombreUsuario: "", nombreCompletoUsuario: "" };
  searchConfigurationCliente: SearchConfiguration | null = null;
  activeButtons: ActiveButtons = {
    new: true,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: true,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    Fecha: [Date],
    Tipo: [{} as TipoGasto],
    Clase: [{} as ClaseGastoCajaChica],
    Proveedor: [{} as Proveedor],
    Elabora: [{} as Usuario],
    Modifica: [{} as Usuario],
    Solicita: [{} as Usuario],
    Autoriza: [{} as Usuario],
    Vehiculo: [{} as Unidad],
    TipoServicioVehiculo: [{} as TipoServicioUnidad],
    Comentarios: [''],
    FormaPago: [''],
    SerieFolioFactura: [''],
    UUID: [''],
    Subtotal: [0],
    IVA: [0],
    IVARetenido: [0],
    ISRRetenido: [0],
    ImpLocalTrasladado: [0],
    ImpLocalRetenido: [0],
    Total: [0],
    Autorizado: [false],
    Baja: [false],
  });



  public filtroCajaChica: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de Gastos',
      NombreExcel: 'Reporte de Gastos.xlsx',
      FilterOptions: [
        { Campo: 'gasto.Id', Etiqueta: '', Tipo: TypeFilter.number, Valor:'0' },
      ]
    };


  constructor(private fService: FinanzasService, private ms: ModalService,
    private fb: FormBuilder, private containerService: ContainerBaseService,
    private utilsService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", ``);
  }

  get getEnableText(): boolean {

    if (this.myForm.value) {
      if (this.myForm.value.Id == 0) {
        return false;
      }
    }

    return true;
  }

  onItemSearched(ent: any): void {
    const t: any = this.txtProveedor;
    if (ent) {
      ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      if (ent.Id == 0) {
        ent.Fecha = new Date();
        t.tagInput.nativeElement.value = "";
      }else{
        ent.Fecha = moment(ent.Fecha).toDate();
      }
      if (ent.Proveedor) {
        t.tagInput.nativeElement.value = ent.Proveedor.Clave;
      }

      this.myForm.reset(ent);
      this.focus('txtNombre')
    }
  }

  blurSerieFolio(value: any) {
    if (value.after != value.before) {
      const prov = this.myForm.value.Proveedor;
      if (prov) {
        this.fService.buscarCfdi(value.after, prov.Rfc).subscribe((result) => {
          if (result) {
            this.setCfdi(result);
          } else {
            Swal.fire({ text: 'No se encontró el CFDI indicado.', icon: 'error', })
          }
        })
      }
    }
    if (value.after == "") {
      this.myForm.controls["UUID"].setValue("");
    }
  }

  setCfdi(i: DescargaCfdi) {
    this.myForm.controls["Subtotal"].setValue(i.subtotal);
    this.myForm.controls["IVA"].setValue(i.ivaTrasladado);
    this.myForm.controls["IVARetenido"].setValue(i.ivaRetenido);
    this.myForm.controls["ISRRetenido"].setValue(i.isrRetenido);
    this.myForm.controls["Total"].setValue(i.total);
    this.myForm.controls["UUID"].setValue(i.uuid);
    this.myForm.controls["SerieFolioFactura"].setValue(i.serieFolio);
  }

  openCfdi() {
    const b: any = this.ctrlAgregarCfdi;
    this.ms.openModal(b, (e: DescargaCfdi[]) => {
      this.onCloseCfdi(e);
    });
  }
  onCloseCfdi(lista: DescargaCfdi[] | null) {
    this.addingcfdi = false;
    if (lista) {
      if (lista.length > 0) {
        if (lista.length > 1) {
          Swal.fire({ text: 'Solo debe de seleccionar un CFDI.', icon: 'error', })
          return;
        }
        const i: DescargaCfdi = lista[0];
        this.setCfdi(i);
      }
    }
    const t: any = this.txtTotal;
    t.tagInput.nativeElement.focus();
  }


  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Gasto) {
    this.myForm.reset(entity);
  }

  get getIsNew(): any {
    return this.myForm.value.Id == 0;
  }

  blurProveedor(value: any) {
    if (value.after != value.before) {
      if (value.after) {
        this.fService.buscarProveedor(this.info.empresa!.numero, value.after).subscribe((prov) => {
          if (prov) {
            this.myForm.controls["Proveedor"].setValue(prov);
            const t: any = this.txtSerieFolio;
            t.tagInput.nativeElement.focus();
          } else {
            const t: any = this.txtProveedor;
            t.tagInput.nativeElement.value = "";
            Swal.fire({ text: `No se encontró el proveedor indicado.`, icon: 'error', }).then(() => {
              setTimeout(() => {
                t.tagInput.nativeElement.focus();
              }, 150);
            })
          }
        })
      } else {
        this.myForm.controls["Proveedor"].setValue(null);
      }
    }
  }

  openProvSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Proveedor"].setValue(e);
        const t: any = this.txtProveedor;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtSerieFolio;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtProveedor;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getNombreProveedor(): string {
    if (this.myForm.value.Proveedor) {
      return this.myForm.value.Proveedor.Nombre;
    }
    return "";
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "print": this.imprimir(); break;
    }
  }

  imprimir(){

    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE GASTOS',
      Dato1: '',
      Opc1:true
    }
    let filtro: FilterOptions | undefined = this.filtroCajaChica.FilterOptions?.find(p => p.Campo == 'gasto.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroCajaChica.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroCajaChica, '/Tesoreria/ReporteVerificadorGastos');
  }

}
