import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';;
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { ImportacionPageComponent } from './pages/importacion-page/importacion-page.component';
import { UsuariosConectadosPageComponent } from './pages/usuarios-conectados-page/usuarios-conectados-page.component';
import { RegistroActividadComponent } from './pages/registroActividad/registro-actividad-page.component';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ActCatSATPageComponent } from './pages/act-cat-satpage/act-cat-satpage.component';
import { UtileriasComplitPageComponent } from './pages/utilerias-complit-page/utilerias-complit-page.component';


@NgModule({
  declarations: [
    ImportacionPageComponent,
    UsuariosConectadosPageComponent,
    RegistroActividadComponent,
    ActCatSATPageComponent,
    UtileriasComplitPageComponent,
  ],
  imports: [
    ComponentUiModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateBoxComponent,
    NgScrollbarModule,
    ComponentUiModule
  ],
  providers: [],
})
export class SistemaModule { }
