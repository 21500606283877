<!-- BEGIN breadcrumb -->
<ol class="breadcrumb float-xl-end">
	<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
	<li class="breadcrumb-item active">Calendar</li>
</ol>
<!-- END breadcrumb -->
<!-- BEGIN page-header -->
<h1 class="page-header">Calendar <small>header small text goes here...</small></h1>
<!-- END page-header -->
<hr />


<div class="row">
	<!-- BEGIN event-list -->
	<div class="d-none d-lg-block" style="width: 215px">
		<div #externalEvents class="fc-event-list">
			<h5 class="mb-3">Draggable Events</h5>
			<div class="fc-event" data-color="#00acac">
				<div class="fc-event-text">Meeting with Client</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-success"></i></div>
			</div>
			<div class="fc-event" data-color="#348fe2">
				<div class="fc-event-text">IOS App Development</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-blue"></i></div>
			</div>
			<div class="fc-event" data-color="#f59c1a">
				<div class="fc-event-text">Group Discussion</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-warning"></i></div>
			</div>
			<div class="fc-event" data-color="#ff5b57">
				<div class="fc-event-text">New System Briefing</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-danger"></i></div>
			</div>
			<div class="fc-event">
				<div class="fc-event-text">Brainstorming</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-dark"></i></div>
			</div>
			<hr class="my-3" />
			<h5 class="mb-3">Other Events</h5>
			<div class="fc-event" data-color="#b6c2c9">
				<div class="fc-event-text">Other Event 1</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-gray-500"></i></div>
			</div>
			<div class="fc-event" data-color="#b6c2c9">
				<div class="fc-event-text">Other Event 2</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-gray-500"></i></div>
			</div>
			<div class="fc-event" data-color="#b6c2c9">
				<div class="fc-event-text">Other Event 3</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-gray-500"></i></div>
			</div>
			<div class="fc-event" data-color="#b6c2c9">
				<div class="fc-event-text">Other Event 4</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-gray-500"></i></div>
			</div>
			<div class="fc-event" data-color="#b6c2c9">
				<div class="fc-event-text">Other Event 5</div>
				<div class="fc-event-icon"><i class="fas fa-circle fa-fw fs-9px text-gray-500"></i></div>
			</div>
		</div>
	</div>
	<!-- END event-list -->
	<div class="col-lg">
  	<full-calendar [options]="calendarOptions"></full-calendar>
	</div>
</div>
