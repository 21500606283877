<div class="header-custom-calendar container">
  <div class="row">
    <h2 class="text-center m-0 pt-5px">{{titleCalendar | titlecase}}</h2>
    <div class="col-12">
      <button class="btn btn-primary" (click)="navHeader(3)">
        Mes
      </button>
      <button class="btn btn-primary" (click)="navHeader(4)">
        Semana
      </button>
      <button class="btn btn-primary" (click)="navHeader(5)">
        Día
      </button>
      <button class="btn btn-primary float-end" (click)="navHeader()">
        Hoy
      </button>
      <button class="btn btn-primary float-end" (click)="navHeader(1)">
        <i class="fas fa-angle-right"></i>
      </button>
      <button class="btn btn-primary float-end" (click)="navHeader(2)">
        <i class="fas fa-angle-left"></i>
      </button>
    </div>
  </div>
</div>
<full-calendar [options]="calendarOptions"></full-calendar>
<!-- 1: En Proceso, 2: Terminada, 3: Próxima  -->
<ng-template #modalEventCalendar let-modal let-c="close">
  <div class="card animate__animated animate__zoomIn animate__faster h-100">
    <h4 class="card-header">
      {{
      eventSelecionado.tipoVisita === 1 ? 'Visita En Proceso':
      (eventSelecionado.tipoVisita === 2 ? 'Visita Realizada':'Próxima Visita')
      }}
      <!-- <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button> -->
    </h4>
    <div class="card-body pb-0">
      <h5>
        <div class="icons-events">
          <i class="fas fa-circle" [style]="{color: eventSelecionado.color, borderRadius: '50%'}"></i>
        </div>
        {{eventSelecionado.title}}
      </h5>
      <h5 class="fw-normal">
        <div class="icons-events">
          <i class="far fa-handshake"></i>
        </div>
        {{eventSelecionado.visitado}}
      </h5>
      <h5>
        <div class="icons-events">
          <i class="far fa-calendar"></i>
        </div>
        {{eventSelecionado.fechaInicio | date:'fullDate' | titlecase}}
      </h5>
      <h5>
        <div class="icons-events">
          <i class="fas fa-hourglass-start"></i>
        </div>
        {{eventSelecionado.fechaInicio | date:'h:mm a'}}
      </h5>
      <h5 *ngIf="eventSelecionado.tipoVisita === 2">
        <div class="icons-events">
          <i class="fas fa-hourglass-end"></i>
        </div>
        {{eventSelecionado.fechaFin | date:'h:mm a'}}
      </h5>
      <h5 *ngIf="eventSelecionado.tipoVisita === 2">
        <div class="icons-events">
          <i class="far fa-folder"></i>
        </div>
        {{eventSelecionado.visita.evidencias.length }}
        {{eventSelecionado.visita.evidencias.length === 1 ? 'Evidencia': 'Evidencias'}}
      </h5>
      <h5 class="mb-0">
        <div class="icons-events">
          <i class="fas fa-map-location-dot"></i>
        </div>
        Localización
      </h5>
    </div>
    <div class="map" id="map" class="match-parent">
      <button type="button" class="btn btn-white fly" (click)="flyToMarker()">
        <i class="fas fa-location-dot"></i>
      </button>
    </div>
    <button type="button" class="btn btn-danger btn-close-visit" (click)="close()">Aceptar</button>
  </div>
</ng-template>
