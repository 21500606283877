<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Configuración a tomar para la entrega en domicilio">
  <div class="screen-content" style="width: 800px;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Configuración por entrega a domicilio">
        <div class="panel-content">

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fas fa-truck-ramp-box fa-lg fa-fw me-1"></i>
            Configuración para salida del Almacén de Entregas a Domicilio
          </div>
          <component-ui-combo-box [label]="'Tipo Movimiento'" formControlName="TipoMovimientoSalida" [tabIndex]="1"
            [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="2" [widthLabelColumns]="3"
            [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'TipoMovimientoSalida')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Concepto De Salida'" formControlName="ConceptoSalida" [tabIndex]="2"
            [zeroMask]="2" entityName="ConceptoAlmacen" filterQuery="EsSalida = true" [widthTextColumns]="2"
            [widthLabelColumns]="3" [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'ConceptoSalida')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Almacén'" formControlName="AlmacenSalida" [tabIndex]="3" [zeroMask]="2"
            entityName="Almacen" [widthTextColumns]="2" [widthLabelColumns]="3" [widthRightColumns]="7"
            (onSelectedItem)="selectedCombo($event,'AlmacenSalida')" [listProperty]="'ClaveNombre'" />

          <components-ui-text-box [maxLength]="200" [label]="'Copias'" formControlName="CopiasFormatoEntrega"
            [tabIndex]="4" [widthTextColumns]="2" [widthLabelColumns]="3" />
          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fas fa-truck fa-lg fa-fw me-1"></i>
            Conceptos para entrada al Almacén de Entregas a Domicilio por Traspaso y Remisiones por Resguardo
          </div>

          <component-ui-combo-box [label]="'Entrada por Traspasos'" formControlName="ConceptoEntradaPorTraspaso" [tabIndex]="5"
            [zeroMask]="2" entityName="ConceptoAlmacen" filterQuery="EsEntrada = true" [widthTextColumns]="2"
            [widthLabelColumns]="3" [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'ConceptoEntradaPorTraspaso')"
            [listProperty]="'ClaveNombre'" />

            <component-ui-combo-box label='Entrada por Remisión' formControlName="ConceptoEntradaPorRemision" [tabIndex]="6"
            [zeroMask]="2" entityName="ConceptoAlmacen" filterQuery="EsEntrada = true" [widthTextColumns]="2"
            [widthLabelColumns]="3" [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'ConceptoEntradaPorRemision')"
            [listProperty]="'ClaveNombre'" />


        </div>
      </app-panel-base>
      <app-panel-base title="Cancelación">
        <div class="panel-content">
          <component-ui-combo-box [label]="'Concepto de cancelación'" formControlName="ConceptoCancelacion"
            [tabIndex]="7" [zeroMask]="2" entityName="ConceptoAlmacen" filterQuery="EsEntrada = true"
            [widthTextColumns]="2" [widthLabelColumns]="3" [widthRightColumns]="7"
            (onSelectedItem)="selectedCombo($event,'ConceptoCancelacion')" [listProperty]="'ClaveNombre'" #cboConceptoCancelacion/>
        </div>
      </app-panel-base>
      <div class="invoice-detail" id="divConfigCargaEntrega">
        <app-panel-base title="Folios por sucursal para la impresión de la Salida">
          <div class="panel-content">
            <ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width:600px;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 50px;text-align:right; padding-right:4px;">No.</th>
                    <th style="width: 150px;text-align:left; padding-right:4px;">Descripción</th>
                    <th style="width: 50px;text-align:right; padding-right:4px;">Folio Salida</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                    (keydown)="keyDownRow($event, index)" *ngFor="let item of sourceGrid; let index = index;">
                    <td style="width: 50px;min-width:50px;max-width: 50px;">
                      <input (keyup)="buscarSucursal($event)"
                        (keydown.escape)="escapeSucursal($event)" (keydown.arrowup)="up($event,0)" (keydown)="navegarSucursal($event)"
                        (keydown.arrowdown)="down($event,0)"
                        (keydown.enter)="enterSucursal($event)" class="grid-editor text-dark"
                        [id]="'txt_'+index+'_0' + idUnico" (focus)="focusSucursal(index, $event, item)"
                        (blur)="blurSucursal($event)" type="text" [value]="item.Clave ? item.Clave: ''"
                        [style]="'text-align:right;padding-right:5px;width:100%'" />
                    </td>
                    <td class="text-dark" style="padding-left: 5px;">{{item.Sucursal?item.Sucursal!.Nombre:''}}
                    <td>
                      <input (keydown.arrowup)="up($event,5)" (keydown.arrowdown)="down($event,5)"
                        (keydown.escape)="escapeFolio($event)" *ngIf="item.Sucursal" (keydown.enter)="enterFolio($event)"
                        class="grid-editor text-dark" [id]="'txt_'+index+'_2' + idUnico" (blur)="blurFolio($event)"
                        (focus)="setIndexEdit(index, $event, item)" type="number" style="text-align:right;height: 22px;"
                        [value]="item.Sucursal ? item.FolioSalida: 0"
                        [style]="'text-align:right;padding-right:5px;width:100%'" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>

            <!-- <gui-grid [rowDetail]="rowDetail" [autoResizeWidth]="false" [loading]="loading" [width]="600"
              [source]="sourceGrid" [maxHeight]="280" [rowHeight]="20" [virtualScroll]="true" [rowSelection]="false"
              [theme]="'fabric'" (sourceEdited)="sourceGridEdited($event)" (cellEditSubmitted)="cellEditSubmitted()"
              (cellEditEntered)="cellEditEntered($event)" [cellEditing]="cellEditing">
              <gui-grid-column index-column="1" header="No." [width]="110" field='Clave' [type]="'number'"
                [align]="GuiColumnAlign.RIGHT">
              </gui-grid-column>
              <gui-grid-column header="Nombre" [width]="480" field='Nombre' [cellEditing]="false">
              </gui-grid-column>
              <gui-grid-column header="Folio Salida" [width]="150" field='FolioSalida' [type]="'number'"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-value="value">
                  {{value > 0 ? (value | number:'1.2-6'): ''}}
                </ng-template>
              </gui-grid-column>
            </gui-grid> -->
          </div>
        </app-panel-base>
      </div>
    </form>
  </div>
</app-container-base>
