<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="AuthPrometeo"
  title="Autenticación con Prometeo API" icon="fa fa-wrench"
  subTitle="Configure la autenticación con la API bancaria Prometeo, ahora conciliar es mucho más fácil">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="AuthPrometeo" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />

          <component-ui-combo-box [label]="'Empresa'" [enabled]="false" formControlName="Empresa" [tabIndex]="3"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Ambiente'" formControlName="Ambiente" [tabIndex]="4" [zeroMask]="2"
            entityName="AmbientePrometeo" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Ambiente')" [listProperty]="'ClaveNombre'" />

          <components-ui-text-box [required]="true" [tabIndex]="7" [maxLength]="500" [label]="'ApiKey'" formControlName="ApiKey"
            [widthTextColumns]="2" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
