<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Prospecto"
  title="Prospectos" icon="fa fa-user-check"
  subTitle="Alta de Prospectos que en un momento se convertiran en Clientes potenciales">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <div id="solicitud" class="tab-pane fade active show" role="tabpanel">
            <h3 class="mt-10px"><i class="fa fa-user-check green"></i>
              Un prospecto o lead es un usuario que ha dejado sus datos a través de un formulario, y pasa a ser un
              contacto de tu empresa.</h3>
            <p>Los prospectos suelen tener cierto tipo de interés en tu marca o producto
              pero aún no se han convertido en clientes. Así que tener sus datos de contacto te ayuda
              a desarrollar estrategias de marketing que logren llevarlo a través de las distintas fases del embudo de
              ventas.</p>
            <p>Solo se muestran los Prospectos del usuario en sesión.</p>
          </div>
          <hr>
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Prospecto'" entityName="Prospecto" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            helpLine="Listado de prospectos pertenecientes al usuario actual." [widthTextColumns]="1"
            [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            helpLine="Nombre del Prospecto, 100 caracteres." [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre
            [maxLength]="100" />

          <component-ui-combo-box [label]="'Propietario'" [widthList]="300" [enabled]="false"
            formControlName="Propietario" [tabIndex]="3" [entityName]="'Usuario'" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Propietario')"
            [listProperty]="'ClaveNombre'" />

          <components-ui-text-box [label]="'Correos'" [required]="true" formControlName="Correos" [tabIndex]="4"
            helpLine="Correos electrónicos, separados por una coma, 100 caracteres." [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxLength]="100" />

          <components-ui-text-box [label]="'Número móvil'" [required]="true" formControlName="NumeroMovil"
            helpLine="Número de celular del Prospecto, 100 caracteres." [tabIndex]="5" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxLength]="100" />

          <component-ui-combo-box [required]="true" [label]="'Medio de Entrada'" [widthList]="300"
            helpLine="Seleccione el medio de entrada del Prospecto." formControlName="MedioEntrada" [tabIndex]="6"
            [entityName]="'MedioEntradaProspecto'" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'MedioEntrada')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Estado'" [widthList]="300" formControlName="Estado"
            helpLine="Seleccione el Estado en el que se encuentra el Prospecto." [tabIndex]="7"
            [entityName]="'EstadoProspecto'" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Estado')" [listProperty]="'ClaveNombre'" />

          <components-ui-date-box formControlName="FechaAlta" label="Fecha de Alta" [enabled]="false" [tabIndex]="8"
            [required]="true" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Observaciones'" [required]="true" formControlName="Observaciones"
            helpLine="Indique las observaciones relacionadas al Prospecto, 500 caracteres." [tabIndex]="9"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="500" />

          <component-ui-check-box [enabled]="myForm.value.Id>0" [label]="'¿Dado de baja?'" formControlName="Baja"
            helpLine="Seleccione para dar de baja al Prospecto." [tabIndex]="10" [widthTextColumns]="3"
            [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
      <app-panel-base title="Información de la Empresa">
        <div class="panel-content">
          <components-ui-text-box [label]="'Cargo que desempeña'" formControlName="Cargo" [tabIndex]="11"
            helpLine="Cargo que desempeña la persona dentro de la Empresa donde trabaja, 100 caracteres."
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />

          <components-ui-text-box
            helpLine="RFC de la empresa, tal cual está en la constancia de situación fiscal, 12-13 caracteres"
            [tabIndex]="12" [maxLength]="13" [upperCase]="true" [label]="'RFC'" [dataType]="EDT.RFC"
            formControlName="Rfc" [widthTextColumns]="2" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Razón Social'" formControlName="NombreEmpresa" [tabIndex]="13"
            helpLine="Razón Social de la empresa donde trabaja el Prospecto, indique tal cual se encuenta en la constancia de situación	 fiscal, 200 caracteres."
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="200" />
        </div>
      </app-panel-base>
      <component-ui-address formControlName="Direccion" [tabIndex]="14" />
    </form>
  </div>
</app-container-base>
