import { NgModule } from '@angular/core';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CuentasCxcPageComponent } from './pages/cuentas-cxc-page/cuentas-cxc-page.component';
import { ConceptoCxcPageComponent } from './pages/concepto-cxc-page/concepto-cxc-page.component';
import { EstadoCuentaCxcPageComponent } from './pages/estado-cuenta-cxc-page/estado-cuenta-cxc-page.component';
import { NgxEditorModule } from 'ngx-editor';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { HistoricoDocumentoPageComponent } from './pages/historico-documento-page/historico-documento-page.component';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { RelacionesAnaliticasCxcPageComponent } from './pages/reports/relaciones-analiticas/relaciones-analiticas-cxc-page.component';
import { CapturaPagosCxcPageComponent } from './pages/captura-pagos-cxc/captura-pagos-cxc.page.component';
import { Select2Module } from 'ng-select2-component';
import { AntiguedadSaldosPageComponent } from './pages/reports/antiguedad-saldos/antiguedad-saldos-cxc-page.component';
import { ComponentsModule } from '../components/components.module';
import { TipoMovimientoCxcPageComponent } from './pages/tipo-movimiento-cxc-page/tipo-movimiento-cxc-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NumberDirective } from '../directives/number-only.directive';
import { VerificadorCargosAbonosCXCComponent } from './pages/reports/verificador-cargos-abonos-cxc/verificador-cargos-abonos-cxc.component';
import { ReporteEstadoDeCuentaCXCComponent } from './pages/reporte-estado-de-cuenta-cxc/reporte-estado-de-cuenta-cxc.component';
import { ChequePosfechadoPageComponent } from './pages/cheque-posfechado-page/cheque-posfechado-page.component';
import { CtrlEdicionChequePosfechadoComponent } from './pages/ctrl-edicion-cheque-posfechado/ctrl-edicion-cheque-posfechado.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ListadoChequesPostfechadosPageComponent } from './pages/reports/listado-cheques-postfechados-page/listado-cheques-postfechados-page.component';
import { MovimientoscxcPageComponent } from './pages/movimientoscxc-page/movimientoscxc-page.component';
import { OpcionCancelarCxcPageComponent } from './pages/opcion-cancelar-page/opcion-cancelar-page.component';
import { VentasModule } from '../ventas/ventas.module';
import { EllipsisPipe } from '../components/pipes/ellipsis.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { DiarioPagosComponent } from './pages/reports/diario-pagos/diario-pagos.component';
import { CtrlEdicionRangosAntiguedadSaldosCxcComponent } from './pages/ctrl-edicion-rangos-antiguedad-saldos-cxc-page/ctrl-edicion-rangos-antiguedad-saldos-cxc.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    FilterPipe,
    CuentasCxcPageComponent,
    ConceptoCxcPageComponent,
    EstadoCuentaCxcPageComponent,
    HistoricoDocumentoPageComponent,
    RelacionesAnaliticasCxcPageComponent,
    CapturaPagosCxcPageComponent,
    AntiguedadSaldosPageComponent,
    TipoMovimientoCxcPageComponent,
    RelacionesAnaliticasCxcPageComponent,
    VerificadorCargosAbonosCXCComponent,
    ReporteEstadoDeCuentaCXCComponent,
    ChequePosfechadoPageComponent,
    CtrlEdicionChequePosfechadoComponent,
    ListadoChequesPostfechadosPageComponent,
    MovimientoscxcPageComponent,
    OpcionCancelarCxcPageComponent,
    DiarioPagosComponent,
    CtrlEdicionRangosAntiguedadSaldosCxcComponent,
  ],
  imports: [
    ComponentUiModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateBoxComponent,
    NgxEditorModule,
    NgxDatatableModule,
    GuiGridModule,
    Select2Module,
    ComponentsModule,
    DragDropModule,
    NgScrollbarModule,
    VentasModule,
    NgbTooltipModule,
    DirectivesModule
  ],

})
export class CuentasPorCobrarModule { }
