import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ConfiguracionSMTP, Empresa } from '../../interfaces/empresa.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import Swal from 'sweetalert2';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { Direccion, DireccionSAT } from '../../interfaces/direccion.interface';
import { ConfiguracionService } from '../../service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { c_RegimenFiscal } from 'src/app/ventas/interfaces/cliente.interface';
import { Editor } from 'ngx-editor';
import { TipoCosteo } from 'src/app/Inventarios/interfaces/producto.interface';
import { PermisosTableros } from '../../interfaces/rol.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-empresa-page',
  templateUrl: './empresa-page.component.html',
  styleUrls: ['./empresa-page.component.scss']
})
export class EmpresaPageComponent implements OnInit {

  @Input() empresa: number = 0;
  @Input() permisosTableros!: PermisosTableros;
  private eventsService = inject(EventsService);
  public itemsCombo: ComboBoxEntity[] = [];
  private fileFielCer: any = null;
  private fileFielKey: any = null;
  private fileCer: any = null;
  private fileKey: any = null;
  private fileLogo: any = null;
  private esprimero: boolean = true;
  public EDT = EnumDataType;
  editor: Editor = new Editor;
  editorCompra: Editor = new Editor;
  html: string = "";
  htmlCompra: string = "";
  saving: boolean = false;
  info: UserLogged = {} as UserLogged;
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Rfc: ['', [Validators.required, Validators.pattern(this.configService.RegExRfc)]],
    Curp: ['', [Validators.pattern(this.configService.RegExCurp)]],
    RepresentanteLegal: [''],
    AplicaDecretoFranjaFronteriza: [false],
    UsuarioPac: ['', [Validators.required]],
    ContrasenaPac: ['', [Validators.required]],
    PacDemo: [false],
    ContrasenaCsd: ['', [Validators.required]],
    RutaKey: [''],
    RutaCsd: [''],
    CsdBase64: [''],
    KeyBase64: [''],
    RutaLogo: [''],
    ClaveCiec: [''],
    Logo: [''],
    EstructuraCuentasContables: ['', [Validators.required]],
    EstructuraActivosFijos: ['', [Validators.required]],
    EstructuraFamilias: ['', [Validators.required]],
    EstructuraCentrosCostos: ['', [Validators.required]],
    TipoCosteo: [{} as TipoCosteo, [Validators.required]],
    VigenciaCsdDesde: null,
    VigenciaCsdHasta: null,
    CancelarSoloMesActual: [false],
    NumeroUsuarios: [0, [Validators.required]],
    MinimoTimbres: [0, [Validators.required, Validators.min(1)]],
    RegimenFiscal: [{} as c_RegimenFiscal, Validators.required],
    EmpresaCatalogos: [{} as Empresa],
    Direccion: [{} as Direccion],
    DireccionSAT: [{} as DireccionSAT],
    Eliminado: [false],
    Baja: [false],
    CartaPorteNacional: [false],
    GenerarPolizasPorTransaccion: [false],
    TokenInfinito: [''],
    FielCerBase64: [''],
    FielKeyBase64: [''],
    RutaCsdFiel: [''],
    RutaKeyFiel: [''],
    passFiel: [''],
    ConfiguracionCorreo: this.fb.group({
      Id: [0],
      ServidorSMTP: [''],
      Puerto: [''],
      SSL: [false],
      TLS12: [false],
      Usuario: [''],
      Contrasena: [''],
      CuerpoCorreo: ['']
    }),
    ConfiguracionCorreoCompra: this.fb.group({
      Id: [0],
      ServidorSMTP: [''],
      Puerto: [''],
      SSL: [false],
      TLS12: [false],
      Usuario: [''],
      Contrasena: [''],
      CuerpoCorreo: ['']
    })
  };
  public myForm: FormGroup = this.fb.group(this.newEntityObj);




  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  constructor(private fb: FormBuilder, private fileService: FileUploadService, private configService: ConfiguracionService, private containerService: ContainerBaseService, private utileService: UtilsService) {

  }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  get getFiltro(): string {
    if (this.myForm.value.Rfc) {
      if (this.myForm.value.Rfc?.length == 12) {
        return "Moral = true";
      } else if (this.myForm.value.Rfc?.length == 13) {
        return "Fisica = true";
      }
    }
    return "";
  }

  get getFilter(): string {
    if (this.myForm.value.Id > 0) {
      return `Id != ${this.myForm.value.Id}`;
    }
    return "Id !=0 ";
  }

  onBeforeSave(param: EventReturn) {
    //Aquí valido mi pantalla, que tenga lo que tenga que tener

    const ent: Empresa = this.myForm.value;
    if (this.fileFielCer) { ent.RutaCsdFiel = this.fileFielCer.name; }
    if (this.fileFielKey) { ent.RutaKeyFiel = this.fileFielKey.name; }
    if (this.fileCer) { ent.RutaCsd = this.fileCer.name; }
    if (this.fileKey) { ent.RutaKey = this.fileKey.name; }
    if (this.fileLogo) { ent.RutaLogo = this.fileLogo.name; }

    if (!ent.Direccion) {
      Swal.fire({ icon: 'error', text: 'Debe de indicar una Dirección.' }).then(() => { });
      param.callback(false);
      return;
    }

    if (!ent.RutaCsd) {
      Swal.fire({ icon: 'error', text: 'Debe de indicar el archivo .CSD' }).then(() => { });
      param.callback(false);
      return;
    }

    if (!ent.RutaKey) {
      Swal.fire({ icon: 'error', text: 'Debe de indicar el archivo .KEY' }).then(() => { });
      param.callback(false);
      return;
    }
    this.saving = true;
    param.callback(true);
  }

  onAfterSave(entity: Empresa) {
    this.saving = false;
    this.myForm.reset(entity);
    this.html = entity.ConfiguracionCorreo.CuerpoCorreo;
    this.htmlCompra = entity.ConfiguracionCorreoCompra.CuerpoCorreo;
    if (this.fileCer) { this.fileService.upload(this.fileCer, entity.Id); }
    if (this.fileKey) { this.fileService.upload(this.fileKey, entity.Id); }
    if (this.fileFielCer) { this.fileService.uploadFiel(this.fileFielCer, entity.Id); }
    if (this.fileFielKey) { this.fileService.uploadFiel(this.fileFielKey, entity.Id); }
    if (this.fileLogo) { this.fileService.uploadLogo(this.fileLogo, entity.Id); }
    this.fileCer = this.fileKey = this.fileLogo = null;

    let usuarioLogin: UserLogged = this.utileService.getUserLogged();
    if (Number(entity.Id) === Number(usuarioLogin.empresa?.numero)) {
      usuarioLogin.empresa!.rfc = entity.Rfc;
      usuarioLogin.empresa!.nombre = entity.Nombre.toUpperCase();
      localStorage.setItem("userLogged", JSON.stringify(usuarioLogin));
      this.eventsService.publish('selectEmpresaSucursal:refreshInfo', { isLoading: true });
    }
  }

  onSaveError() {
    this.saving = false;
  }

  //Entra ya que cargó la última entidad y el combo principal que es la Clave
  onItemSearched(entity: Empresa): void {
    if (entity) {
      if (entity.Id == 0) {
        this.setBaseConfig(entity);
      }
      this.myForm.reset(entity);
      this.html = entity.ConfiguracionCorreo.CuerpoCorreo;
      this.htmlCompra = entity.ConfiguracionCorreoCompra.CuerpoCorreo;
    }
    this.focus("txtNombre");
  }

  onChangeFile(file: any, type: number) {
    if (type == 1) {
      this.fileKey = file;
    } else if (type == 2) {
      this.fileCer = file;
    } else if (type == 3) {
      this.fileLogo = file;
    } else if (type == 4) {
      this.fileFielKey = file;
    } else {
      this.fileFielCer = file;
    }
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Empresa").subscribe((elem: Empresa) => {
      elem.Clave = next;
      this.setBaseConfig(elem);
      this.myForm.reset(elem);
      this.html = elem.ConfiguracionCorreo.CuerpoCorreo;
      this.htmlCompra = elem.ConfiguracionCorreoCompra.CuerpoCorreo;
      this.focus("txtNombre")
    })
  }

  setBaseConfig(elem: Empresa) {
    this.fileFielCer = null;
    this.fileFielKey = null;
    this.fileCer = null;
    this.fileKey = null;
    this.fileLogo = null;
    elem.MinimoTimbres = 1;
    elem.PacDemo = false;
    //elem.UsuarioPac = "francisco@complit.com.mx";
    //elem.ContrasenaPac = "cvwvv&WhZ$KC5#V";
    //elem.TokenInfinito = "T2lYQ0t4L0RHVkR4dHZ5Nkk1VHNEakZ3Y0J4Nk9GODZuRyt4cE1wVm5tbXB3YVZxTHdOdHAwVXY2NTdJb1hkREtXTzE3dk9pMmdMdkFDR2xFWFVPUXpTUm9mTG1ySXdZbFNja3FRa0RlYURqbzdzdlI2UUx1WGJiKzViUWY2dnZGbFloUDJ6RjhFTGF4M1BySnJ4cHF0YjUvbmRyWWpjTkVLN3ppd3RxL0dJPQ.T2lYQ0t4L0RHVkR4dHZ5Nkk1VHNEakZ3Y0J4Nk9GODZuRyt4cE1wVm5tbFlVcU92YUJTZWlHU3pER1kySnlXRTF4alNUS0ZWcUlVS0NhelhqaXdnWTRncklVSWVvZlFZMWNyUjVxYUFxMWFxcStUL1IzdGpHRTJqdS9Zakw2UGROekRjR2ZPVEwyMGl5L2h6cnFFSTNlV0hkc25MTXRZVTlwamJPdlBVWGlmVFhpeFZpSlgzUlh3SzFrOXRpZXZUejRTOXFmeHdwUGFSWHRSRzFzcmxCVVU4WWRVTUMxU3V4cVVIRnFGVFdIQW95R0drV2pBRlY4dERKREtHZldOaENabWZScmZHQ09BaVFuNEF5aGdyTnNKNEVweG9Od3YxWnRlOW9NZWFkakFvdlZtVVFmUU5jYUo1bDNxNlFTVnh6UzFGZnJpckxnbHRwMUpNaEhWZzdYSjBTNitJL1JDUEtBK1g0ckZQRnFkZGhHREUzME5vU3BYNWJXM1lkeWVJVjRJbTk4Lzd1VVlRYXhrb2x5L2JnSGdWU2tvTXpUa2NLTUlrRlZmanJYQkNhZDdiUnFvTGxlaXk5NVFaOHpYTGlISFdNS0s2NFpzU3hocTQ4K2haaFp4KzNrQ09VTVEzN1JjVUFxUVlRa3pLbnE1Z29RbGVQM3V3MFNJZngzRUNBdGNzM0dqaUNWVWZZcEQ2V0VkdCtBPT0.hMcMYetnuJolFNgYU3c3MH35DzO7wApbTwD3PXKkcC8";
    elem.EstructuraCuentasContables = '3-3-3';
    elem.EstructuraActivosFijos = '3-3-3';
    elem.EstructuraFamilias = '3-3-3';
    elem.EstructuraCentrosCostos = '3-3-3';
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onItemsLoadFirstTime() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    setTimeout(() => {
      this.containerService.getEntityById("Empresa", this.info.empresa!.numero).subscribe(item => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.onChangueEntity(item);
      });
    }, 250);
  }

  onChangueEntity(ent: any) {
    this.myForm.reset(ent);
    // // // this.myFormSmtp.reset(ent.ConfiguracionCorreo);
    this.html = ent.ConfiguracionCorreo.CuerpoCorreo;
    this.htmlCompra = ent.ConfiguracionCorreoCompra.CuerpoCorreo;
    this.focus("txtNombre");
  }

  //Entra aquí cuando el container trae la lista de objetos a mostrar en el combo clave
  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}



