<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Configuración a tomar en el Inventario Físico">
  <div class="screen-content" style="width: 50%;margin: auto;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="row p-1">
        <div id="bsSpyContent" class="col-xl-8">

          <div id="notifications" class="mb-4 pb-3">
            <h4><span class="iconify" data-icon="solar:lightbulb-bolt-bold-duotone"></span>Configuraciones</h4>
            <div class="card">
              <div class="list-group list-group-flush fw-bold">
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Concepto de salida</div>
                    <div class="text-body text-opacity-60">Seleccione para identificar las salidas por inventario físico</div>

                    <component-ui-combo-box [label]="''" formControlName="ConceptoSalida" inputProperty="Nombre"
                      [tabIndex]="19" [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="10"
                      [widthLabelColumns]="0" [widthRightColumns]="0"
                      (onSelectedItem)="selectedCombo($event,'ConceptoSalida')" [listProperty]="'ClaveNombre'" />

                  </div>
                </div>
              </div>
              <div class="list-group list-group-flush fw-bold">
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Concepto de entrada</div>
                    <div class="text-body text-opacity-60">Seleccione para identificar las entradas por inventario físico</div>

                    <component-ui-combo-box [label]="''" formControlName="ConceptoEntrada" inputProperty="Nombre"
                      [tabIndex]="19" [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="10"
                      [widthLabelColumns]="0" [widthRightColumns]="0"
                      (onSelectedItem)="selectedCombo($event,'ConceptoEntrada')" [listProperty]="'ClaveNombre'" />

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</app-container-base>
