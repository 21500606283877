<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  (onClickBarButton)="onClickBarButton($event)" [printManual]="true" entityName="ChequePosfechado"
  title="Captura de Cheques Posfechados" icon="fa fa-clock"
  subTitle="Capture aquellos cheques que su cobro es en fechas futuras">
  <div class="screen-content">
    <!-- <div class="card text-white border-0 bg-teal text-center m-1">
      <div class="card-body">
        <blockquote class="blockquote">
          <h2>¿Que es un cheque posfechado?</h2>
        </blockquote>
        <figcaption class="blockquote-footer mt-n2 mb-1 text-white">
          Un cheque posdatado es un cheque que se otorga en una fecha, pero su fecha de expedición es una fecha
          posterior.
          Por ejemplo. Se emite un cheque físicamente el 3 de Febrero, pero la fecha que figura en él es el 20 de
          Febrero.
        </figcaption>
      </div>
    </div> -->
    <form [formGroup]="myForm" autocomplete="off">


      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="ChequePosfechado" />

          <components-ui-text-box [tabIndex]="2" [label]="'Comentarios'" [maxLength]="500" formControlName="Nombre"
            [widthTextColumns]="4" [widthLabelColumns]="2" [required]="true" #txtNombre />

        </div>
      </app-panel-base>
      <app-panel-base title="Información del Cheque">
        <div class="panel-content">

          <components-ui-number-box (onClicIcon)="openClienteSearch()" (keydown.f2)="openClienteSearch()"
            (blur)="blurCliente($event)" [tabIndex]="3" [label]="'Cliente relacionado'" [widthTextColumns]="1"
            [widthLabelColumns]="2" [enableIcon]="true" [required]="true" #txtCliente />
          <div class="form-group row">
            <div class="col-sm-2" style="text-align: right;font-weight: bold;">
              Nombre:
            </div>
            <div class="col-sm-5" style="font-weight: bold;">
              {{ myForm.value.Cliente ? myForm.value.Cliente.Nombre: '' | uppercase}}
            </div>
          </div>
          <components-ui-number-box [tabIndex]="4" [label]="'Folio'" formControlName="Folio" [widthTextColumns]="1"
            [widthLabelColumns]="2" [required]="true" />

          <components-ui-number-box [tabIndex]="5" [label]="'Importe'" formControlName="Importe" [widthTextColumns]="1"
            [widthLabelColumns]="2" [required]="true" />



          <component-ui-combo-box [required]="true" [label]="'Banco'" formControlName="Banco" [tabIndex]="6"
            [zeroMask]="2" entityName="c_Banco" [widthTextColumns]="1" [widthLabelColumns]="2" [widthRightColumns]="2"
            (onSelectedItem)="selectedItem($event,'Banco')" [listProperty]="'ClaveNombre'" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-calendar fa-lg fa-fw me-1"></i>
            Fechas de emisión y aplicación
          </div>

          <components-ui-date-box [required]="true" label="Emisión" [tabIndex]="7" formControlName="FechaEmision"
            [widthLabelColumns]="2" [widthTextColumns]="6" />

          <components-ui-date-box [required]="true" label="Aplicación" [tabIndex]="8" formControlName="FechaAplicacion"
            [widthLabelColumns]="2" [widthTextColumns]="6" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-code-compare fa-lg fa-fw me-1"></i>
            Tipo y Estado
          </div>
          <component-ui-combo-box [required]="true" [label]="'Tipo'" formControlName="Tipo" [tabIndex]="9"
            [zeroMask]="2" entityName="TipoChequePosfechado" [widthTextColumns]="1" [widthLabelColumns]="2"
            [widthRightColumns]="2" (onSelectedItem)="selectedItem($event,'Tipo')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Estado'" formControlName="Estado" [tabIndex]="10"
            [zeroMask]="2" entityName="EstadoChequePosfechado" [widthTextColumns]="1" [widthLabelColumns]="2"
            [widthRightColumns]="2" (onSelectedItem)="selectedItem($event,'Estado')" [listProperty]="'ClaveNombre'" />

        </div>


      </app-panel-base>


      <app-panel-base title="Documentos relacionados">
        <div class="panel-content">
          <div id="divChequePosFechado">
            <ng-scrollbar style="height: 400px;border-bottom: 1px solid #AAAAAA;width:1200px;">
              <table class="blueTable" style="width:1180px">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 30px;"></th>
                    <th style="width: 80px;">Serie</th>
                    <th style="width: 80px;">Folio</th>
                    <th style="width: 80px;">Emisión</th>
                    <th style="width: 180px;">Total</th>
                    <th style="width: 180px;">Saldo</th>
                    <th style="width: 180px;">Abonar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                    *ngFor="let item of listaDetalles; let index = index;">
                    <td style="text-align: center;">
                      <i (click)="deleteRow(index)" *ngIf="item.Serie" class="fa fa-rectangle-xmark fa-1x"
                        style="color:red;font-weight: bold; cursor: pointer;"></i>
                    </td>
                    <td (dblclick)="onDblClickRow(index)">
                      <span style="margin-left: 3px;">
                        {{item.Serie }}</span>
                    </td>
                    <td style="text-align:right;">
                      <span style="margin-right:2px;">
                        {{item.Folio > 0 ?item.Folio:''}}
                      </span>
                    </td>
                    <td>
                      <span style="margin-left:2px;">
                        {{getDate(item)}}
                      </span>
                    </td>
                    <td style="text-align: right;">
                      <span style="margin-right:2px;">
                        {{ (item.Total??0) > 0 ? (item.Total|number:'1.2-6'):''}}
                      </span>
                    </td>
                    <td style="text-align: right;">
                      <span style="margin-right:2px;">
                        {{(item.Saldo??0) > 0? (item.Saldo|number:'1.2-6'):''}}</span>
                    </td>
                    <td style="text-align: right;">
                      <span style="margin-right:2px;">
                        {{(item.Abonar ??0) > 0? (item.Abonar| number:'1.2-6'):''}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
            <!-- <gui-grid [autoResizeWidth]="false" [source]="listaDetalles" [rowHeight]="20" [rowSelection]="true"
              [theme]="'fabric'" [maxHeight]="400">
              <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
                <ng-template let-item="item" let-index="index">
                  <i (click)="deleteRow(index)" *ngIf="item.Folio>0" class="fa fa-rectangle-xmark"
                    style="color:red;font-weight: bold; cursor: pointer;"></i>
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Serie" [field]="'Serie'" [width]="120" [cellEditing]="false" />
              <gui-grid-column header="Folio" [field]="'Folio'" [width]="120" [cellEditing]="false"  [align]="GuiColumnAlign.RIGHT">
                <ng-template let-item="item">
                  {{item.Folio > 0 ? (item.Folio):''}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Emisión" [width]="120" [cellEditing]="false">
                <ng-template let-item="item">
                  {{getDate(item)}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Total" [field]="'Total'" [width]="120" [cellEditing]="false"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-item="item">
                  {{item.Total > 0 ? (item.Total | number:'1.2'):''}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Saldo" [field]="'Saldo'" [width]="120" [cellEditing]="false"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-item="item">
                  {{item.Saldo > 0 ? (item.Saldo | number:'1.2'):''}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Abonar" [field]="'Abonar'" [width]="120" [cellEditing]="false"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-item="item">
                  {{item.Abonar > 0 ? (item.Abonar | number:'1.2'):''}}
                </ng-template>
              </gui-grid-column>
            </gui-grid> -->
          </div>
        </div>
      </app-panel-base>

    </form>
    <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'"
        [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
    </ng-template>
    <div *ngIf="editing">
      <app-ctrl-edicion-cheque-posfechado [item]="getSelected" (onCloseEdition)="onCloseEdit($event)" />
    </div>
  </div>
</app-container-base>
