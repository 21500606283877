import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-motivo-re-impresion-page',
  templateUrl: './motivo-re-impresion-page.component.html',
  styles: [
  ]
})
export class MotivoReImpresionPageComponent {
  private eventsService = inject(EventsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public myForm: FormGroup = this.fb.group({
    MotivoReimpresion: ['', Validators.required],
  })

  constructor(private fb: FormBuilder, private mService: ModalService) {
  }

  accept() {
    if (!this.myForm.value.MotivoCancelacion) {
      this.eventsService.publish('home:showAlert', { message: `Indique el motivo de reimpresión del documento.`, cancelButton: false });
      return;
    }
    this.mService.closeModal(this.myForm.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      MotivoReimpresion: '',
    };
    this.myForm.reset(ent);
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

}
