import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, forwardRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBoxEntity } from '../../interfaces/combo-text.interface';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { ComponentsUiService } from '../../services/components-ui.service';
import { CodigoPostal } from '../../interfaces/codigopostal.interface';
import { ContainerBaseService } from '../../services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { ListBoxComponent } from '../list-box/list-box.component';
import { CartaPorteService } from 'src/app/ventas/services/cartaporte.service';

@Component({
  selector: 'component-ui-address',
  templateUrl: './address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AddressComponent),
    }],
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  public itemsCombo: ComboBoxEntity[] = [];

  PropNombre: string = '';
  private isDisabled = false;
  // PaisSeleccionado?: ComboBoxEntity;
  // EstadoSeleccionado?: ComboBoxEntity;

  private eventsService = inject(EventsService);
  private cpService = inject(CartaPorteService);
  tomoFocoCP: boolean = false;


  @ViewChild('listBoxDirecciones')
  public listBoxDirecciones!: ElementRef<ListBoxComponent>;

  @ViewChild('calle')
  public txtCalle!: ElementRef<HTMLElement>;

  codigosList: [] = [];
  // estadosList: ComboBoxEntity[] = [];
  paisesList: ComboBoxEntity[] = [];
  PaisSeleccionado?: ComboBoxEntity | null;

  estadossList: ComboBoxEntity[] = [];
  EstadoSeleccionado?: ComboBoxEntity | null;

  isLoading: boolean = false;
  public direccionActual?: Direccion;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Pais: [''],
    NombreRecibe: [''],
    CodigoPostal: [''],
    Calle: [''],
    NumeroExterior: [''],
    NumeroInterior: [''],
    Localidad: [''],
    Municipio: [''],
    Descripcion: [''],
    Telefono: [''],
    Estado: [''],
    Colonia: ['']
  })

  // @ViewChild('calle')
  // public calle!: ElementRef<HTMLInputElement>;

  @ViewChild('pais')
  public txtpais!: ElementRef<HTMLInputElement>;

  @Input()
  public tabIndex: number = 1
  @Input()
  public esEntrega: boolean = false;
  @Input()
  public enabled: boolean = true;

  @Input()
  public collapsable: boolean = false;

  @Input()
  public CodigoPostalRequerido: boolean = true;





  ActivarDireccion: boolean = false

  onChangePass(value: any) {
    this.ActivarDireccion = value.currentTarget.checked;
  }

  onChange = (value?: Direccion | null) => { };

  onTouched = () => { };

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef, private ComponentsUiService: ComponentsUiService, private containerBase: ContainerBaseService) {
  }

  ngOnInit(): void {

    this.containerBase.getItemsByName('Pais')
      .subscribe(paises => {
        paises.map(p => {
          p.ClaveNombre = `${p.Clave} ${p.Nombre}`
        })
        this.paisesList = paises;
        if (this.myForm.get('Id')?.value > 0) {
          this.PaisSeleccionado = this.paisesList.find(p => p.Nombre === this.myForm.get('Pais')?.value);
        }
      });
    this.containerBase.getItemsByName('Estado')
      .subscribe(estados => {
        estados.map(p => {
          p.ClaveNombre = `${p.Clave} ${p.Nombre}`
        })
        this.estadossList = estados;
        this.EstadoSeleccionado = this.estadossList.find(p => p.Nombre === this.myForm.get('Estado')?.value);
      });
  }

  get selectPaisManualItem(): ComboBoxEntity | null {
    return this.PaisSeleccionado!;
  }

  get selectEstadoManualItem(): ComboBoxEntity | null {
    return this.EstadoSeleccionado!;
  }

  get getCPrequerido():boolean{
    return this.CodigoPostalRequerido;

  }

  writeValue(value: Direccion): void {

    if (value) {
      this.PaisSeleccionado = this.paisesList.find(p => p.Nombre === value.Pais);
      this.EstadoSeleccionado = this.estadossList.find(p => p.Nombre === value.Estado);
      this.myForm.reset(value)
    } else {
      this.PaisSeleccionado = null;
      this.EstadoSeleccionado = null;
      this.myForm.reset({} as Direccion)
      this.myForm.controls["Id"].setValue(0);
    }
  }

  onSelectEstado(value: any) {
    this.myForm.controls['Estado'].setValue(value.Nombre);
    this.direccionActual = this.myForm.value;
    this.onChange(this.direccionActual);
  }

  selectItemCodigo(value: any) {
    this.tomoFocoCP = false;
    if (value) {
      this.myForm.controls["Municipio"].setValue(value['d_mnpio']);
      this.myForm.controls["Localidad"].setValue(value['d_ciudad']);
      this.myForm.controls["Colonia"].setValue(value['d_asenta']);


      if (value['d_estado']) {
        let estado: string = value['d_estado'];
        this.EstadoSeleccionado = this.estadossList.find(p => p.Nombre.toLowerCase() === estado.toLowerCase())
        this.myForm.controls["Estado"].setValue(value['d_estado']);
      }


      if (!this.PaisSeleccionado) {
        this.PaisSeleccionado = this.paisesList.find(p => p.Nombre.toLowerCase() === "méxico");
        this.myForm.controls["Pais"].setValue("México");
      }

      this.onChange(this.myForm.value)
    }
    let txt: any = this.txtCalle
    this.codigosList = [];
    setTimeout(() => {
      txt.tagInput.nativeElement.focus();
    }, 100);

  }

  onSelectPais(value: ComboBoxEntity) {
    this.myForm.controls['Pais'].setValue(value.Nombre);
    this.direccionActual = this.myForm.value;
    this.onChange(this.direccionActual);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  onBlurDireccion() {

    if (this.myForm.controls["Pais"].value === "") {
      this.myForm.controls["Pais"].setValue("México");
      this.txtpais.nativeElement.value = "México"
    }
    this.onChange(this.myForm.value);
  }

  // onKeyPressCP(event:any){
  //     if (event.keyCode == 13){
  //       this.onBlurCP();
  //     }
  // }

  tomoFoco() {
    this.tomoFocoCP = true;
  }

  onBlurCP1() {
    this.codigosList = [];
    if (this.myForm.controls['CodigoPostal'].value !== "") {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.ComponentsUiService.getPostalCodes(this.myForm.controls['CodigoPostal'].value)
        .subscribe((codigos: any) => {
        let a = 0;

      });

    }
  }


  onBlurCP() {


    this.codigosList = [];
    this.onBlurDireccion();
    if (this.myForm.controls['CodigoPostal'].value !== "") {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.ComponentsUiService.getPostalCodes(this.myForm.controls['CodigoPostal'].value)
        .subscribe((codigos: any) => {

          let root = codigos;
          let codigosTmp: [] = [];
          if (codigos.length > 1) {
            let item: CodigoPostal = {} as CodigoPostal;
            root = codigos[0];
            item.d_asenta = "Sin selección";
            codigos.unshift(item);

            this.codigosList = codigos;

            let txt: any = this.listBoxDirecciones;
            txt.tagInput.nativeElement.focus();
          } else if (codigos.length === 1) {
            codigosTmp = codigos;
            root = codigos[0];
            this.tomoFocoCP = false;
            let txt: any = this.txtCalle;
            txt.tagInput.nativeElement.focus();
          } else {
            this.codigosList = [];
            this.tomoFocoCP = false;
            let txt: any = this.txtCalle;
            txt.tagInput.nativeElement.focus();
          }
          this.eventsService.publish('home:isLoading', { isLoading: false });
          let cambio: boolean = false;
          if (codigosTmp.length > 0) {
            // if (this.myForm.controls["Municipio"].value === "") {
            this.myForm.controls["Municipio"].setValue(root['d_mnpio']);
            // }
            // if (this.myForm.controls["Localidad"].value === "") {
            this.myForm.controls["Localidad"].setValue(root['d_ciudad']);
            // }

            // if (this.myForm.controls["Colonia"].value === "") {
            this.myForm.controls["Colonia"].setValue(root['d_asenta']);
            // }


            let estado: string = codigos[0]['d_estado'];
            this.EstadoSeleccionado = this.estadossList.find(p => p.Nombre.toLowerCase() === estado.toLowerCase())
            if (this.myForm.controls["Estado"].value === "") {
              this.myForm.controls["Estado"].setValue(root[0]['d_estado']);
            }
            this.onChange(this.myForm.value)
          }
        })
    }
  }
  onBlurNumExt() {
    this.onChange(this.direccionActual);
  }

  onBlurNumInt() {
    this.onChange(this.direccionActual);
  }
  onItemSearched(entity: any): void {
    if (entity) {
      this.myForm.reset(entity);
    }
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
}
