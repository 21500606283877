<app-container-base [hasCounter]="false" icon="fa fa-money-bill-1" [hasBar]="true" [activeButtons]="activeButtons"
  (onClickBarButton)="onClickBarButton($event)" subTitle="Estado de cuenta por cliente" [printManual]="true">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="row">
        <div class="col-md-8">
          <div style="width:100%">
            <app-panel-base title="Filtros del estado de cuenta del cliente">
              <div class="panel-content">
                <div class="row">
                  <div class="col-md-10">
                    <component-ui-combo-box [label]="'Cuenta a revisar'" [tabIndex]="1" [zeroMask]="2"
                      entityName="CuentaCxc" formControlName="cuenta" (onSelectedItem)="selectedCombo($event,'cuenta')"
                      [widthTextColumns]="3" [required]="true" [widthLabelColumns]="3" [listProperty]="'ClaveNombre'"
                      [tabIndex]="1" [widthRightColumns]="5" #cuenta />
                  </div>
                </div>
                <div class="d-flex mt-1">
                  <div style="width:35%;">
                    <components-ui-text-box [tabIndex]="2" [placeHolder]="'F2: buscar'" label="Cliente"
                      formControlName="clienteClave" [widthLabelColumns]="7" (keydown.f2)="buscarCliente()"
                      (onClicIcon)="buscarCliente()" (blur)="buscarClientePorClave()" [enableIcon]="true"
                      [widthTextColumns]="5" #txtCliente />
                  </div>
                  <div *ngIf="myForm.value.cliente?.Id>0">
                    <div class="fs-13px lh-12 mb-2px fw-bold text-dark">[{{this.myForm.value.cliente.Rfc |
                      uppercase}}]
                      {{ this.myForm.value.cliente.Nombre | uppercase}}
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-10">
                    <component-ui-combo-box label='Año' [tabIndex]="3" [zeroMask]="2"
                      [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="3"
                      [widthLabelColumns]="3" (onSelectedItem)="selectedCombo($event,'anio')" [inputProperty]="'Nombre'"
                      [listProperty]="'Nombre'" />
                  </div>
                </div>
              </div>
            </app-panel-base>
          </div>
          <div style="width:100%;margin-left:4px; padding-right: 7px;">
          <div class="panel-content">
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="header">
                  <th class="text-left w-150px">Mes</th>
                  <th class="text-end w-150px">Anterior</th>
                  <th class="text-end w-150px">Cargos</th>
                  <th class="text-end w-150px">Abonos</th>
                  <th class="text-end w-150px">Actual</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of listaMeses" class="table-info">
                  <td>{{item.mes}}</td>
                  <td class="text-end">{{item.anterior |number:'1.2-6'}}</td>
                  <td class="text-end">{{item.cargos |number:'1.2-6'}}</td>
                  <td class="text-end">{{item.abonos |number:'1.2-6'}}</td>
                  <td class="text-end">{{item.actual|number:'1.2-6'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
      <ng-template #content let-modal let-c="close">
        <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'"
          [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
      </ng-template>

      <ng-template #reporteEdoCuenta let-modal let-c="close">
        <reporte-estado-de-cuenta-cxc [idCliente]="this.clienteSeleccionado" [anio]="this.anioSeleccionado!"
          [idCuenta]=" this.myForm.value.cuenta.Id" />
      </ng-template>
    </form>
  </div>
</app-container-base>
