import { HttpClient } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private readonly baseUrl: string = environment.baseUrlApi;


  constructor(private http: HttpClient, private authService: AuthService) {

  }


  uploadLogo(file: File, idEmpresa: number, urlSend: string = 'Base/UploadFileLogo'): void {

    const url = `${this.baseUrl}/${urlSend}?idEmpresa=${idEmpresa}`;
    const xhr = this.createCORSRequest('POST', url);
    const fd = new FormData();
    fd.append('file', file);
    xhr.send(fd);
  }



  UploadImagenProducto(file: File, idProducto: number, urlSend: string = 'Base/UploadImagenProducto', cb: any = null): void {

    let url = `${this.baseUrl}/${urlSend}?idEmpresa=${idProducto}`;
    const xhr = this.createCORSRequest('POST', url);

    const fd = new FormData();
    fd.append('file', file);

    xhr.onload = () => {
      cb && cb();
    };
    xhr.send(fd);
  }


  uploadFiel(file: File, idEmpresa: number, urlSend: string = 'Base/UploadFileFiel', cb: any = null): void {

    let url = `${this.baseUrl}/${urlSend}?idEmpresa=${idEmpresa}`;
    const xhr = this.createCORSRequest('POST', url);

    const fd = new FormData();
    fd.append('file', file);

    xhr.onload = () => {
      cb && cb();
    };
    xhr.send(fd);
  }


  upload(file: File, idEmpresa: number, urlSend: string = 'Base/UploadFile', cb: any = null): void {

    let url = `${this.baseUrl}/${urlSend}?idEmpresa=${idEmpresa}`;
    const xhr = this.createCORSRequest('POST', url);

    const fd = new FormData();
    fd.append('file', file);

    xhr.onload = () => {
      cb && cb();
    };
    xhr.send(fd);
  }

  uploadProfilePhoto(file: File, idUsuario: number, cb: any = null): void {
    let url = `${this.baseUrl}/Config/UploadLogoUsuario?idUsuario=${idUsuario}`;
    const xhr = this.createCORSRequest('POST', url);
    const fd = new FormData();
    fd.append('file', file);
    xhr.onload = (event: any) => {
      cb && cb(event.target.response);
    };

    xhr.send(fd);
  }

  createCORSRequest(method: string, url: string) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(method, url, true);
    xhr.setRequestHeader("authorization", `Bearer ${this.authService.getToken()}`);
    xhr.setRequestHeader("ambienteId", this.authService.getAmbienteId());
    return xhr;
  }
}
