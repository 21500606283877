<!-- #modal-dialog -->
<div class="modal fade" id="modal-dialog">
  <div class="modal-dialog">
    <div class="modal-content" #modal>
      <div class="modal-header">
        <h4 class="modal-title">{{Entidad}}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
      </div>
      <div class="modal-body">
        <p>
          ¿Eliminar registro?
        </p>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-white" data-bs-dismiss="modal">Cancelar</a>
        <a  class="btn btn-success" data-bs-dismiss="modal" (click)="onAceptarEliminar()">Aceptar</a>
      </div>
    </div>
  </div>
</div>



<div class="mb-2">
  <div class="row">
    <div class="col-lg-4">
      <input class="form-control" placeholder="Capture para filtrar por nombre..." (keyup)="updateFilter($event)" />
    </div>
    <div class="col-lg-8 text-end">
      <button type="button" class="btn btn-outline-purple" (click)="onNuevo()">
        <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
        Nuevo
      </button>
    </div>
  </div>
</div>
<ng-container outsideBody>
  <hr class="m-0 bg-gray-600" />
  <div class="table-responsive text-nowrap">
    <ngx-datatable #table class="bootstrap" [columns]="ColumnsConfig" [columnMode]="ColumnMode" [headerHeight]="50"
      [footerHeight]="50" rowHeight="auto" [limit]="10" [rows]="this.rows">


      <!-- <ng-template #hdrTpl let-column="column"> {{ ColumnsConfig['name'] }} </ng-template> -->

      <ng-template #editTmpl let-row="row" let-value="value">
        <button class="btn btn-purple" (click)="onEditar(row['Id'])">Editar</button>
      </ng-template>
      <!-- <ng-template #deletehdrTpl let-column="column"> {{ ColumnsConfig['name'] }} </ng-template> -->

      <ng-template #deleteTmpl let-row="row" let-value="value">
        <!-- <button class="btn btn-danger" (click)="onEliminar(row['Id'])">Eliminar</button> -->
        <!-- <button class="btn btn-danger" (click)="clickeliminar(row['Id'])"  >Eliminar</button> -->
        <td width="1%"><a href="#modal-dialog" class="btn btn-sm btn-danger w-100px" data-bs-toggle="modal" (click)="onEliminar(row['Id'])">Eliminar</a>
        <!-- </td> -->
      </ng-template>


    </ngx-datatable>
    <!-- BEGIN hljs-wrapper -->
    <div class="hljs-wrapper">
      <!-- <pre><code class="xml" [highlight]="code1"></code></pre> -->
    </div>
    <!-- END hljs-wrapper -->
  </div>
</ng-container>
