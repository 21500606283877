<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="SerieReciboElectronico" title="Series de Recibos Electrónicos de Pagos" icon="fa fa-building"
  subTitle="Administra las Series para poder emitir Recibos Electrónicos de Pago">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie de Venta">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Serie de REP'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="3" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieReciboElectronico" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="4" [required]="true"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2" [maxlength]="20" />
        </div>
      </app-panel-base>
    </form>
  </div>

</app-container-base>
