import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy, OnInit, inject, SimpleChanges, ElementRef } from '@angular/core';
import { AppSettings } from '../../home/services/app-settings.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AppMenuService } from 'src/app/home/services/app-menus.service';
import { heartBeatAnimation } from 'angular-animations';
import Swal from 'sweetalert2'
import { EventsService } from 'src/app/service/events.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { Favoritos } from '../interfaces/favoritos.interface';
import { PermisosTableros } from 'src/app/configuracion/interfaces/rol.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import * as moment from 'moment';

declare var slideToggle: any;

@Component({
  selector: 'header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  animations: [heartBeatAnimation()]
})
export class HeaderMobileComponent implements OnDestroy, OnInit {
  private eventsService = inject(EventsService);
  private utilsService = inject(UtilsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  animationState = true;
  lightMode: boolean = true;
  darkMode: boolean = false;
  isToggleTopMenu: boolean = false;
  canAccesAcceptCancel: boolean = false;
  totalSolicitudes: number = 0;
  tipoCambio: number = 0;

  @Input() numeroPaginas: number = 0;
  @Input() permisosTableros!: PermisosTableros;
  @Input() favoritos: Favoritos[] = [];
  @Input() notificaciones: any[] = [];
  @Input() appSidebarTwo: boolean = false;
  @Input()
  set recargarTipoCambio(recargar: boolean) {
    if (recargar) {
      this.getTipoCambio();
    }
  }

  @Output() favoritosChange = new EventEmitter<any>();
  @Output() openPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() appSidebarEndToggled = new EventEmitter<boolean>();
  @Output() appSidebarMobileToggled = new EventEmitter<boolean>();
  @Output() appSidebarEndMobileToggled = new EventEmitter<boolean>();
  @Output() appTopMenuToggle = new EventEmitter<boolean>();
  @Output() onLogout = new EventEmitter<boolean>();


  usuarioLogeado!: UserLogged;
  toggleAppSidebarMobile() {
    this.appSidebarMobileToggled.emit(true);
  }

  toggleAppSidebarEnd() {
    this.appSidebarEndToggled.emit(true);
  }

  toggleAppSidebarEndMobile() {
    this.appSidebarEndMobileToggled.emit(true);
  }

  toggleAppTopMenuMobile() {
    var target = document.querySelector('.app-top-menu');
    if (target) {
      slideToggle(target);
    }
  }

  toggleAppHeaderMegaMenuMobile() {
    this.appSettings.appHeaderMegaMenuMobileToggled = !this.appSettings.appHeaderMegaMenuMobileToggled;
  }

  ngOnDestroy() {
    this.appSettings.appHeaderMegaMenuMobileToggled = false;
  }

  widgetLightMode() {
    this.lightMode = true;
    this.darkMode = false;
    if (localStorage) { localStorage['appDarkMode'] = false; }
    this.appSettings.appDarkMode = false;
    document.documentElement.removeAttribute('data-bs-theme');
  }

  topMenuMode(isTop: boolean) {
    this.isToggleTopMenu = isTop;
    localStorage.setItem("isTop", isTop.toString());
    this.appTopMenuToggle.emit(isTop);
    this.eventsService.publish("container-base:height");
  }

  widgetDarkMode() {
    this.darkMode = true;
    this.lightMode = false;
    this.appSettings.appDarkMode = true;
    if (localStorage) { localStorage['appDarkMode'] = true; }
    document.documentElement.setAttribute('data-bs-theme', 'dark');
  }

  constructor(public appSettings: AppSettings, private http: HttpClient, private fs: FacturacionService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["favoritos"]) {
      this.animationState = false;
      setTimeout(() => {
        this.animationState = true;
      }, 100);
    }
  }

  countries: any[] | undefined;

  selectedCountry: any | undefined;

  ngOnInit(): void {
    this.usuarioLogeado = this.utilsService.getUserLogged();
    this.isToggleTopMenu = localStorage.getItem("isTop")?.toLocaleLowerCase() === "true";
    this.getTipoCambio();
  }

  onClickTipoCambio() {
    const params = new HttpParams().set("idPantalla", 32500);
    this.http.get<Result>(`${this.baseUrl}/Config/TieneAccesoPantalla`, { params }).subscribe((result) => {
      if (result.success) {
        let fav = { menu: 'tipocambio' };
        let item = { url: fav.menu, title: '' };
        this.openPage.emit(item);
      }
    });
  }

  getTipoCambio() {
    const strFecha = moment(new Date()).format("DD/MM/YYYY");
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.ObtenerTipoDeCambio(0, strFecha).subscribe((result) => {
      this.eventsService.publish("home:isLoading", { isLoading: false });
      if (result.success) {
        if (result.message) {
          const ent = JSON.parse(result.message);
          this.tipoCambio = ent.TipoCambio;
        } else {
          this.tipoCambio = 0;
        }
      }
    })
  }

  logOut() {
    this.onLogout.emit(true);
  }
}
