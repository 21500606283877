<p-dialog class="actualizar-bd panel-loading" header="Actualizar ambiente de trabajo" [(visible)]="visible" [modal]="true"
  [style]="{width: '400px', height:((puedeActualizar ?'370px': '395px')) }" [draggable]="true" [resizable]="false"
  [closeOnEscape]="false" [closable]="false">
  <div class="pt-10px pb-10px">
    <div class="container-icons">
      <div class="icon-database">
        <i class="fas fa-database fa-5x"></i>
      </div>
      <div class="icon-spin">
        <i class="fas fa-sync {{(actualizando? 'fa-spin':'')}} fa-2x"></i>
      </div>
    </div>

    <div class="fs-20px text-justify">
      <p class="text-center" *ngIf="actualizado">
        {{mensaje}}
        <br>
        Iniciando Sesión...
      </p>
      <p *ngIf="!actualizado"> Es necesario actualizar la base de datos para poder ingresar al sistema.
        {{!puedeActualizar ? "El Usuario no está autorizado para actualizar la Base De Datos.": ""}}
      </p>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button [disabled]="actualizando || actualizado" class="btn btn-theme d-block h-45px w-100 btn-lg fs-14px"
      (click)="actualizar()">
      {{puedeActualizar ? (actualizando ? "Actualizando..." : "Actualizar") : "Aceptar"}}
    </button>
  </ng-template>
</p-dialog>
