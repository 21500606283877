<app-container-base subTitle="Administrador de CFDIs" icon="fas fa-folder-tree" [hasCounter]="false" [hasBar]="false"
  idComponent="file-manager-component">
  <div class="screen-content" [style.height.px]="scrollHeihgt">
    <div class="file-manager h-100" [ngClass]="{ 'file-manager-sidebar-mobile-toggled': mobileSidebarToggled }">
      <div class="file-manager-toolbar align-content-center">
        <!-- <button type="button" class="btn shadow-none text-body border-0"><i
            class="fa fa-lg me-1 fa-plus"></i>File</button>
        <button type="button" class="btn shadow-none text-body border-0"><i
            class="fa fa-lg me-1 fa-plus"></i>Folder</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-copy"></i>
          Copy</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-move"></i>
          Move</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-upload"></i>
          Upload</button> -->
        <button [disabled]="getActivarOpciones" type="button" class="btn shadow-none text-body border-0"><i
            class="fa fa-lg me-1 fa-download text-green"></i>
          Descargar .zip</button>
        <button (click)="tableToCSV()" [disabled]="getActivarOpciones" type="button"
          class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file-excel text-green"></i>
          Exportar</button>
          <button *ngIf="emitidos" (click)="sendEmail()"  [disabled]="getActivarOpciones" type="button"
          class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-envelopes-bulk text-green"></i>
          Enviar por correo</button>
        <!-- <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-xmark"></i>
          Delete</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-rotate-left"></i>
          Restore</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file"></i>
          Rename</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-pen"></i>
          Edit</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-pen-to-square"></i>
          HTML Editor</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-key"></i>
          Permissions</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file"></i>
          View</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-lock-open"></i>
          Extract</button>
        <button type="button" class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file-zipper"></i>
          Compress</button> -->

      </div>
      <div class="file-manager-container">
        <div class="file-manager-sidebar">
          <div class="file-manager-sidebar-mobile-toggler">
            <button type="button" class="btn" (click)="toggleMobileSidebar()">
              <i class="fa fa-lg fa-right-from-bracket"></i></button>
          </div>
          <div class="file-manager-sidebar-content">
            <ng-scrollbar>
              <form [formGroup]="myForm" autocomplete="off">
                <div class="p-2">
                  <div class="position-relative mb-1">
                    <input type="text" class="form-control ps-35px rounded-3" formControlName="busqueda"
                      placeholder="Texto a buscar" #txtBuscarCfdi>
                    <button class="btn position-absolute start-0 top-0 shadow-none"><i
                        class="fa fa-search fa-lg text-orange"></i></button>
                  </div>
                  <div class="col-lg-12 d-lg-block d-none">
                    <a href="#" class="btn btn-white d-flex align-items-center rounded-3" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-lg fa-tags me-10px text-body text-opacity-50 d-none"></i>
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                        viewBox="0 0 24 24" data-icon="solar:tag-linear"
                        class="iconify fs-20px my-n1 me-2 ms-n1 text-theme iconify--solar">
                        <g fill="none" stroke="currentColor" stroke-width="1.5">
                          <path
                            d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302c.614-.613 1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033z">
                          </path>
                          <circle cx="8.607" cy="8.879" r="2" transform="rotate(-45 8.607 8.879)"></circle>
                          <path stroke-linecap="round" d="m11.542 18.5l6.979-6.98"></path>
                        </g>
                      </svg>
                      Tipo Comprobante = {{!myForm.value.tipoDeComprobante ? 'Todos': myForm.value.tipoDeComprobante}}
                      <i class="fa ms-auto fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li (click)="selectedCombo('I')"><a class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i> Ingreso</a></li>
                      <li (click)="selectedCombo('E')"><a class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-body text-opacity-25"></i> Egreso</a></li>
                      <li (click)="selectedCombo('T')"><a class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-danger"></i> Traslado</a></li>
                      <li (click)="selectedCombo('N')"><a class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-warning"></i> Nómina</a></li>
                      <li (click)="selectedCombo('P')"><a class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Pago</a></li>
                      <li (click)="selectedCombo('TODOS')"><a class="dropdown-item d-flex align-items-center"><i
                            class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Todos</a></li>
                    </ul>
                  </div>
                  <div class="col-lg-12">
                    <span>Del:</span>
                    <input type="date" class="form-control mt-1" [value]="myForm.value.fechaInicioString"
                      formControlName="fechaInicio">
                  </div>
                  <div class="col-lg-12">
                    <span>Al:</span>
                    <input type="date" class="form-control mt-1" [value]="myForm.value.fechaFinString"
                      formControlName="fechaFin">
                  </div>
                  <hr>
                  <div class="btn-group me-2 w-100">
                    <div (click)="selEmitidos()" type="button"
                      [class]="'w-50 btn btn-sm btn-white' + (!emitidos?'':' active')"><i
                        [class]="'fa'+(!emitidos?'r':'')+' fa-fw fa-'+ (emitidos?'check':'square')+' ms-n1'"></i>
                      Emitidos</div>
                    <div (click)="selEmitidos()" type="button"
                      [class]="'w-50 btn btn-sm btn-white' + (!recibidos?'':' active')"><i
                        [class]="'fa'+(!recibidos?'r':'')+' fa-fw fa-'+ (recibidos?'check':'square')+' ms-n1'"></i>
                      Recibidos</div>
                  </div>
                  <hr>
                  <a (click)="search()" class="btn btn-primary btn-rounded px-4 btn-lg d-block">Buscar</a>
                </div>
              </form>
            </ng-scrollbar>
          </div>
          <div class="file-manager-sidebar-footer">
            <div class="d-flex align-items-center">
              <div class="mx-n1">
                <span class="iconify fa-3x" data-icon="solar:smartphone-rotate-orientation-bold-duotone"></span>
              </div>
              <div class="flex-1 ps-3 small">
                <div class="fw-bold text-body text-opacity-75">
                  <b class="text-body">Registros encontrados:</b> {{ lista.length | number}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file-manager-content d-flex flex-column">
          <div class="btn-group me-2 m-2 w-200px">
            <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!todos?'':' active')"><i
                [class]="'fa'+(!todos?'r':'')+' fa-fw fa-'+ (todos?'check':'square')+' ms-n1'"></i>Todos</div>
            <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!ninguno?'':' active')">
              <i [class]="'fa'+(!ninguno?'r':'')+' fa-fw fa-'+ (ninguno?'check':'square')+' ms-n1'"></i>
              Ningúno
            </div>
          </div>
          <div class="flex-1 overflow-hidden">

            <!-- <ng-scrollbar class="h-100  m-2">
              <ngx-datatable #table class="bootstrap" [columns]="columns" [columnMode]="ColumnMode.force"
                [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="200" [rows]="lista"></ngx-datatable>
            </ng-scrollbar> -->

              <ng-scrollbar class="h-100  m-2">
                <table class="table table-bordered table-sm table-striped mb-0 " style="font-family: monospace;">
                  <thead style="position: sticky;top: 0; background: var(--bs-component-bg);box-shadow: 0 0 0 1px var(--bs-component-table-border-color);">
                    <tr class="border-bottom">
                      <th class="text-center" style="width:30px !important;">Sel</th>
                      <th class="text-center" style="width:30px !important;">PDF</th>
                      <th class="text-center" style="width:30px !important;">XML</th>
                      <th
                        [style]="'cursor: pointer;width:67px !important;'+((lastOrder=='tipoDeComprobante'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('tipoDeComprobante')" class="px-10px">Tipo
                        <i *ngIf="lastOrder=='tipoDeComprobante'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th [style]="'cursor: pointer;width:259px !important;'+((lastOrder=='uuid'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('uuid')" class="px-10px">UUID<i *ngIf="lastOrder=='uuid'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th
                        [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='emisorRfc'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('emisorRfc')" class="px-10px">Emisor<i *ngIf="lastOrder=='emisorRfc'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th
                        [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='receptorRfc'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('receptorRfc')" class="px-10px">Receptor<i *ngIf="lastOrder=='receptorRfc'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th
                        [style]="'cursor: pointer;'+((lastOrder=='serieFolio'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('serieFolio')" class="px-10px text-center">Serie/Folio<i
                          *ngIf="lastOrder=='serieFolio'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th
                        [style]="'cursor: pointer;'+((lastOrder=='fechaEmision'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('fechaEmision')" class="px-10px text-center">Emisión<i
                          *ngIf="lastOrder=='fechaEmision'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th
                        [style]="'cursor: pointer;'+((lastOrder=='total'?'background-color:orange;color:#ffffff;':''))"
                        (click)="ordenar('total')" class="px-10px">Total<i *ngIf="lastOrder=='total'"
                          [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                      </th>
                      <th class="px-10px">Póliza</th>
                      <th class="px-10px">F/Póliza</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of lista; let i = index;">
                      <td class="text-center" style="width:30px !important;">
                        <input (click)="seleccionar(i)" class="form-check-input" type="checkbox"
                          [checked]="item.seleccionado" value="">
                      </td>
                      <td class="text-center" style="width:30px !important;"><i (click)="print(item.uuid)"
                          class="fa fa-file-pdf text-warning fa-lg" style="cursor:pointer;"></i></td>
                      <td style="width:30px !important;"><i (click)="downloadXML(item.uuid)"
                          class="fa fa-file-code text-warning fa-lg" style="cursor:pointer;"></i></td>
                      <td class="px-10px"> {{ item.tipoDeComprobante | uppercase }}</td>
                      <td class="px-10px">{{item.uuid}}</td>
                      <td class="px-10px"><b>{{item.emisorRfc}}</b>/{{item.emisorNombre}}</td>
                      <td class="px-10px"><b>{{item.receptorRfc}}</b>/{{item.receptorNombre}}</td>
                      <td class="px-10px text-center">{{item.serieFolio}}</td>
                      <td class="px-10px text-center">{{item.fechaEmision}}</td>
                      <td class="px-10px text-end">{{item.total| number:'1.2'}}</td>
                      <td class="px-10px">{{item.numeroPoliza>0 ? (item.tipoPoliza + item.numeroPoliza):''}}</td>
                      <td class="px-10px">{{item.fechaPoliza}}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>

          </div>
        </div>
      </div>
    </div>
  </div>
</app-container-base>
