<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Tarjeta de {{myForm.value.Debito
    ?'Débito':'Crédito'}} Por {{myForm.value.Importe | number:'1.2-6'}}</div>


  <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
    [hasCounter]="false">
    <div class="screen-content">
      <app-panel-base title="Indique la información solicitada">
        <div class="panel-content">
          <form [formGroup]="myForm" autocomplete="off">

            <component-ui-combo-box [label]="'Terminal'" [tabIndex]="1" formControlName="TerminalBancaria"
              entityName="TerminalBancaria" [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0"
              [inputProperty]="'Nombre'" [filterQuery]="'Sucursal.Id = ' + idSucursal"
              (onSelectedItem)="onSelectedItem($event,'TerminalBancaria')" [required]="true"
              [listProperty]="'ClaveNombre'" #cboTerminal />

            <div class="mb-1" *ngIf="myForm.value.Banco">
              <hr>
              <span class="badge bg-blue">{{myForm.value.Banco ? myForm.value.Banco.Nombre:''}}</span>
              <span class="badge bg-green">{{myForm.value.CuentaBancaria.Nombre ?
                myForm.value.CuentaBancaria.Nombre:''}}</span>
              <hr>
            </div>

            <components-ui-text-box [enabled]="myForm.value.Banco" [label]="'Num Lote'" [required]="true"
              formControlName="NumeroLote" [tabIndex]="2" [widthTextColumns]="4" [widthLabelColumns]="4"
              [maxLength]="100" />

            <components-ui-text-box [enabled]="false" [label]="'Referencia'" formControlName="Referencia" [tabIndex]="2"
              [widthTextColumns]="4" [widthLabelColumns]="4" [maxLength]="100" />

          </form>
          <hr>
          <div style="text-align: right; margin-top: 5px;">
            <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
            <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
          </div>
        </div>
      </app-panel-base>
    </div>
  </app-container-base>


</div>
