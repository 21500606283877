import { Component, ViewEncapsulation } from '@angular/core';
import 'lity';

@Component({
	selector: 'extra-timeline',
	templateUrl: './extra-timeline.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './extra-timeline.css' ]
})

export class ExtraTimelinePage {
	
}