<app-panel-base title="Cancelar documento" style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div class="panel-content">
    <form [formGroup]="myForm" autocomplete="off">
      <components-ui-date-box formControlName="Fecha" label="Fecha" [widthLabelColumns]="3" [widthTextColumns]="3" />
      <components-ui-text-box formControlName="MotivoCancelacion" [maxlength]="2000" label="Motivo" [widthLabelColumns]="3" [widthTextColumns]="9" />
      <!-- <component-ui-combo-box (onSelectedItem)="onSelectedItem($event)" [enabled]="requiresSAT" formControlName="MotivoSAT" label="Motivo SAT" entityName="c_MotivoCancelacion" [widthLabelColumns]="3" [widthTextColumns]="9" [widthRightColumns]="0" />
      <components-ui-text-box *ngIf="myForm.value.MotivoSAT.Id == 1" formControlName="UUID" [maxlength]="2000" label="Sustituye" [widthLabelColumns]="3" [widthTextColumns]="9" /> -->
      <div style="text-align: right; margin-top: 5px;">
        <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
        <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
      </div>
    </form>
  </div>
</app-panel-base>
