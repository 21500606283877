import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { TokenCredito, TokenPrecio } from 'src/app/configuracion/interfaces/tokens.interface';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TokensService {

  private readonly baseUrl: string = environment.baseUrlApi;

  private http = inject(HttpClient);

  constructor() { }

  generarTokenPrecio(token: TokenPrecio): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Config/GenerarTokenPrecio`, token);
  }

  generarTokenCredito(token: TokenCredito): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Config/GenerarTokenCredito`, token);
  }

  validarToken(token: string): Observable<Result> {
    const params = new HttpParams().set("token", token);
    return this.http.get<Result>(`${this.baseUrl}/Config/ValidarToken`, { params });
  }
}
