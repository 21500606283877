
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], query: string): any {
    if (!items || !query) {
      return items;
    }
    let x = items.filter(e => JSON.stringify(e).toLowerCase().includes(query.toLowerCase()));
    return x
  }
}
