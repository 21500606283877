import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { ChequePosfechadoDetalle } from '../../interfaces/chequeposfechado.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';

@Component({
  selector: 'app-ctrl-edicion-cheque-posfechado',
  templateUrl: './ctrl-edicion-cheque-posfechado.component.html',
  styles: [
  ]
})
export class CtrlEdicionChequePosfechadoComponent implements OnInit {

  @ViewChild('txtSerie')
  public txtSerie!: ElementRef<TextBoxComponent>;

  @ViewChild('txtFolio')
  public txtFolio!: ElementRef<NumberBoxComponent>;

  @Input()
  public item!: ChequePosfechadoDetalle;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Serie: [''],
    Folio: [0],
    FechaEmision: [Date],
    Total: [0],
    Saldo: [0],
    Abonar: [0],
  })


  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    setTimeout(() => {
      const t: any = this.txtSerie;
      t.tagInput.nativeElement.focus();

      if (this.item?.Folio > 0) {
        this.myForm.reset(this.item);
      }

    }, 150);
  }


  keydown(e: any) {
    if (e.code == "F8") {
      document.getElementById("divAceptar")!.focus();
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  accept() {

    // if (!this.myForm.value.FechaHoraSalidaLlegada) {
    //   Swal.fire({ text: "Indique fecha y hora.", icon: 'info', })
    //   return false;
    // }
    // if (this.myForm.value.EsDestino && this.myForm.value.DistanciaRecorrida == 0) {
    //   Swal.fire({ text: `Indique la distancia recorrida.`, icon: 'info', })
    //   return false;
    // }


    this.onCloseEdition.emit(this.myForm.value)
    return;
  }

  closeModal() {
    this.onCloseEdition.emit(null)
  }

}
