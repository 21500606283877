import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Chofer, TipoChofer, c_FiguraTransporte } from '../../interfaces/chofer.interface';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { subDays } from 'date-fns';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-choferes-page',
  templateUrl: './choferes-page.component.html',
  styles: [
  ]
})
export class ChoferesPageComponent implements OnInit {

  private expedienteFile: any = null;
  public EDT = EnumDataType;
  public itemsCombo: ComboBoxEntity[] = [];
  public info: any;
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    SucursalPertenece: [{} as Sucursal],
    Tipo: [{} as TipoChofer],
    Licencia: [''],
    FechaVencimientoLicencia: [Date],
    Anotaciones: [''],
    Certificaciones: [''],
    Habilidades: [''],
    TipoExpediente: [''],
    Expedienteb64: [''],
    ExpedienteNombre: [''],
    Figura: [{} as c_FiguraTransporte],
    Rfc: [''],
    NumRegIdTribFigura: [''],
    PaisResidencia: [''],
    Asientos: [[]],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: Chofer | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;


  // @ViewChild('cboPrueba')
  // public cboPrueba!: ElementRef<ComboBoxComponent>;

  constructor(private utileService: UtilsService,
    private fb: FormBuilder, private containerService: ContainerBaseService) {
  }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  get getFilter(): string {

    if (this.info) {
      return `Empresa.Id = ${this.info.empresa.numero}`;
    }

    return "";
  }


  async onChangeFile(file: any, type: number) {
    this.expedienteFile = file;
    this.myForm.controls["ExpedienteNombre"].setValue(file.name);
    let result: any = await this.toBase64(file);
    let fileb64: string = result;
    let tipoExp = "";
    let index = fileb64.indexOf('base64,') + 7;
    tipoExp = fileb64.substring(0, index).split(';')[0].replace('data:', '');
    fileb64 = fileb64.substring(index, fileb64.length - index);
    this.myForm.controls["Expedienteb64"].setValue(fileb64);
    this.myForm.controls["TipoExpediente"].setValue(tipoExp);
  }


  download() {

    let blob = new Blob([this.myForm.value.Expedienteb64], {
      type: 'application/pdf'
    });

    const blobUrl = window.URL.createObjectURL((blob));
    const iframe: any = document.createElement('iframe');
    //iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();


    // debugger
    // let blob = new Blob([this.myForm.value.Expedienteb64], { type: 'application/pdf;base64' });
    // let url = window.URL.createObjectURL(blob);
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert('Por favor desactiva el bloqueo de popups e intentalo de nuevo.');
    // }
  }

  toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    })
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Chofer) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: Chofer): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Chofer").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  selectedItem(ent: any, tipo: string) {
    this.myForm.controls[tipo].setValue(ent);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }


}
