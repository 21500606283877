<app-container-base [hasCounter]="false" icon="fa fa-money-bill-1" [hasBar]="false"
  subTitle="Verifique el historial completo de un documento de venta en Cuentas Por Cobrar">
  <div class="screen-content">
    <app-panel-base title="Filtros">
      <div class="panel-content">
        <components-ui-date-box label="Emisión" [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="1" />
        <components-ui-text-box label="Empresa" [tabIndex]="2" [widthLabelColumns]="2" [widthTextColumns]="4" />
        <components-ui-text-box label="Sucursal" [tabIndex]="3" [widthLabelColumns]="2" [widthTextColumns]="4" />
        <components-ui-text-box label="Cuenta de CXC" [tabIndex]="5" [widthLabelColumns]="2" [widthTextColumns]="4" />
        <components-ui-text-box label="Cliente" [tabIndex]="4" [widthLabelColumns]="2" [widthTextColumns]="4" />
        <components-ui-text-box label="Serie" [tabIndex]="5" [widthLabelColumns]="2" [widthTextColumns]="4" />
        <components-ui-text-box label="Folio" [tabIndex]="6" [widthLabelColumns]="2" [widthTextColumns]="4" />
        <div class="form-group row">
          <div class="col-sm-6" style="text-align: right;">
            <div style="margin-right: 15px;" class="btn btn-primary">Imprimir</div>
          </div>
        </div>
      </div>
    </app-panel-base>
  </div>
</app-container-base>
