<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-list-check" [activeButtons]="activeButtons"
  [setIsNew]="esNuevo" (onClickBarButton)="onClickBarButton($event)"
  subTitle="Emisión de Recibos Electrónicos de Pago (REP)">
  <div class="screen-content">
    <div>
      <form [formGroup]="myForm" autocomplete="off">
        <div class="card border-0 mb-0" style="height: 20px;background-color: var(--bs-app-header-input-bg);">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-2" style="text-align: left;">
                <div class="mb-0 d-md-flex fw-bold">
                  <div class="ms-md-0 mt-md-0 mt-0 dropdown-toggle">
                    <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Archivos <b
                        class="caret"></b></a>
                    <div class="dropdown-menu">
                      <a (click)="downloadFiles()" class="dropdown-item">Descargar XML y PDF</a>
                      <a (click)="sendEmail()" class="dropdown-item">Envíar REP por correo</a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-4" style="text-align: right;margin-top:-5px;">
                <div class="invoice-company" *ngIf="myForm.value.Id==0">
                  <span class="float-end hidden-print">
                    <div href="javascript:;" style="background-color: rgba(255, 255, 0, 0.575);"
                      class="btn btn-sm btn-white mb-10px me-2"><i class="fa fa-plus t-plus-1 text-black fa-fw fa-lg"></i>
                      NUEVO</div>
                  </span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div style="width: 860px;">
            <app-panel-base title="Información General del REP" [style]="'width:850px;height:170px;'">
              <div class="panel-content">
                <div class="d-flex">
                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Empresa"
                    [enabled]="false" [topLabel]="true" label="Empresa" [inputProperty]="'Clave'" [zeroMask]="3"
                    [entityName]="'Empresa'" [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12"
                    [widthRightColumns]="0" />

                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Sucursal"
                    [enabled]="false" [topLabel]="true" label="Sucursal" [inputProperty]="'Nombre'" [zeroMask]="3"
                    [entityName]="'Sucursal'" [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12"
                    [widthRightColumns]="0" />

                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Serie"
                    [enabled]="listaSeries.length>0" [setItemsList]="getItemsSerie" [enabled]="true" [topLabel]="true"
                    label="Serie" [inputProperty]="'Serie'" [zeroMask]="3" [tabIndex]="3" [widthLabelColumns]="12"
                    [widthTextColumns]="12" [widthRightColumns]="0" #cboSerie />

                  <components-ui-number-box style="width:15%;" [topLabel]="true" label='Folio' formControlName="Folio"
                    (blur)="blurFolio($event)" [tabIndex]="4" [widthLabelColumns]="12" [widthTextColumns]="12"
                    #txtFolio />
                </div>
                <div class="d-flex mt-1">
                  <div style="width:70%;margin-left:7px;">
                    <components-ui-text-box (onClicLink)="openCustomerCatalog()" (blur)="blurCustomer($event)"
                      (onClickIcon)="clicIcon($event)" (keydown.enter)="enterCliente()"
                      (keydown.f2)="openCustomerSearch()" [tabIndex]="5" label="Cliente" entidadBusqueda="Cliente"
                      (elementoSeleccionado)="elementoSeleccionado($event)" [widthLabelColumns]="8" [enableLink]="true"
                      [widthTextColumns]="3" #txtCliente />
                  </div>
                  <div *ngIf="myForm.value.Cliente?.Id>0" style="width:30%;text-align: left;margin: auto;">
                    <div class="fs-13px lh-12 mb-2px fw-bold text-dark">[{{this.myForm.value.Cliente.Rfc | uppercase}}]
                      [{{this.myForm.value.Cliente.Nombre | uppercase}}]
                    </div>
                  </div>
                </div>
              </div>
            </app-panel-base>
          </div>
          <div style="width: 280px;">
            <app-panel-base title="Otra información" [style]="'width:250px;height:170px;'">
              <div class="panel-content d-flex" style="flex-direction:column;">
                <components-ui-date-box [enabled]="false" formControlName="FechaEmision" label="Emisión" [tabIndex]="6"
                  [widthLabelColumns]="4" [widthTextColumns]="6" />
                <div class="btn btn-sm btn-white mb-10px me-2 mt-2"
                  *ngIf="myForm.value.Id>0 && !myForm.value.FechaCancelacion"><i
                    class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                <div class="btn btn-sm btn-white mb-10px me-2" (click)="tienePagoRelacionado()"
                  *ngIf="myForm.value.Id>0 && !myForm.value.FechaCancelacion"><i
                    class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
              </div>
            </app-panel-base>
          </div>
        </div>
        <div class="d-flex">
          <div style="width: 858px;;">
            <app-panel-base title="Información de los pagos a realizar" [style]="'height:250px;'">
              <div class="panel-content">
                <a *ngIf="myForm.value.Cliente?.Id>0 && myForm.value.Id==0" (click)="openVentanaPago()"
                  class="btn btn-default me-5px mb-2"><i class="fa fa-circle-plus"></i>
                  Agregar pago</a>

                <gui-grid [autoResizeWidth]="false" [width]="800" [source]="listaPagos" [rowHeight]="25"
                  [rowSelection]="false" [theme]="'fabric'" [maxHeight]="150">
                  <gui-grid-column [width]="20" [cellEditing]="false">
                    <ng-template let-item="item" let-index="index">
                      <a *ngIf="myForm.value.Id == 0" (click)="deleteRow(index)" class="btn btn-xs btn-danger"><i
                          class="fa fa-trash-can"></i></a>
                    </ng-template>
                  </gui-grid-column>
                  <gui-grid-column [width]="30" [cellEditing]="false">
                    <ng-template let-item="item" let-index="index">
                      <a *ngIf="myForm.value.Id == 0" (click)="editar(item, index)" class="btn btn-xs btn-yellow"><i
                          class="fa fa-pencil"></i></a>
                    </ng-template>
                  </gui-grid-column>
                  <gui-grid-column header="Fecha" [width]="120" [cellEditing]="false">
                    <ng-template let-item="item">
                      {{getDateFormat(item.FechaPago)}}
                    </ng-template>
                  </gui-grid-column>
                  <gui-grid-column header="Monto" [align]="GuiColumnAlign.RIGHT" [width]="120" [cellEditing]="false">
                    <ng-template let-item="item">
                      {{item.Monto|number:'1.2-6'}}
                    </ng-template>
                  </gui-grid-column>
                  <gui-grid-column header="Núm Op" field="NumeroOperacion" [width]="120" [cellEditing]="false" />
                  <gui-grid-column header="Moneda" [width]="100" [cellEditing]="false">
                    <ng-template let-item="item">
                      {{item.MonedaP.ClaveSAT}}
                    </ng-template>
                  </gui-grid-column>
                  <gui-grid-column header="Tipo Cambio" [align]="GuiColumnAlign.RIGHT" [width]="100"
                    [cellEditing]="false">
                    <ng-template let-item="item">
                      {{item.MonedaP.TipoCambioP|number:'1.2-6'}}
                    </ng-template>
                  </gui-grid-column>
                </gui-grid>
              </div>
            </app-panel-base>
          </div>
          <div style="width: 252px;margin-left: 2px">
            <app-panel-base title="Monto total de pagos" style="width: 250px;height: 252px;" *ngIf="getTotalPagos>0">
              <div class="panel-content">
                <h2 class="mb-0 pulse text-green" style="text-align: center;">$<span
                    data-animation="number">{{getTotalPagos|number:'1.2'}}</span>
                </h2>
              </div>
            </app-panel-base>
          </div>
        </div>
        <app-panel-base title="Usuarios" [style]="'width:1110px'" *ngIf="myForm.value.Id>0">
          <div class="panel-content">
            <div class="widget-list mb-0" style="display: flex;">
              <div class="widget-list-item" style="width:25%;">
                <div class="widget-list-media">
                  <i class="fas fa-user-check fa-2x text-green"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.UsuarioAlta.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario elaboró</p>
                </div>
              </div>
              <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.UsuarioCancela">
                <div class="widget-list-media">
                  <i class="fas fa-user-xmark fa-2x text-red"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario canceló</p>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>
        <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
          <div class="cancelled">&nbsp;</div>
          <div class="cancelled-detail">{{getCancelDate}}</div>
          <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
        </div>
        <ng-template #ventanaPagos class="p-3">
          <app-venatana-rep-page [pago]="itemEdicion" [IdCliente]="myForm.value.Cliente.Id" #ventanaPagos />
        </ng-template>
        <ng-template #ctrlCatClientes class="p-3">
          <app-clientes-page #ctrlCatClientes [esVentana]="true" />
        </ng-template>
        <ng-template #content let-modal let-c="close">
          <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'"
            [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
        </ng-template>
        <ng-template #modalCancelOptiones let-modal let-c="close">
          <app-opcion-cancelacion-page [esRep]="true" [idDocto]="0" [uuid]="myForm.value.UUID" />
        </ng-template>
      </form>
    </div>
  </div>
</app-container-base>
