<!-- BEGIN #sidebar -->
<div id="sidebar" class="app-sidebar" [attr.data-bs-theme]="" [ngClass]="{
	'app-sidebar-transparent': this.appSidebarTransparent,
	'app-sidebar-grid': this.appSidebarGrid
}">
  <!-- BEGIN scrollbar -->
  <ng-scrollbar class="app-sidebar-content" [ngClass]="{ 'h-100': appSettings.appSidebarFixed }" #sidebarScrollbar
    (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </ng-scrollbar>
  <!-- END scrollbar -->

  <ng-template #sidebarNav>
    <!-- BEGIN menu -->
    <seleccionar-empresa-sucursal [numeroPaginas]="numeroPaginas" (onLogout)="onLogout.emit(true)" />
    <div class="menu">
      <!-- <div class="menu-search mb-n3">
        <input (blur)="blur($event)" type="text" class="form-control" placeholder="Busque en el menú..."
          (keyup)="appSidebarSearch($event)" />
        <div *ngIf="results.length>0"
          class="todolist position-absolute bg-light animate__animated animate__fadeIn animate__faster"
          style="border: 1px solid #ccc4c4;z-index: 2; width: 240px; margin-top:3px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
          <ng-scrollbar style="max-height: 150px;height: 150px;">
            <div (click)="onClickMenu(item)" class="todolist-item item-search-menu" *ngFor="let item of results">
              <label class="todolist-label text-dark" style="cursor:pointer;">{{item.title}}</label>
            </div>
          </ng-scrollbar>
        </div>
      </div> -->
      <div class="menu-header">Menú COMPLIT:</div>

      <!-- render sidebarMenuNav -->
      <ng-template #sidebarMenuNav let-menu="menu">
        <div class="menu-icon-img" *ngIf="menu.img"><img src="{{ menu.img }}" /></div>
        <div class="menu-icon" *ngIf="menu.icon"><i class="{{ menu.icon }}"></i></div>
        <div class="menu-text" *ngIf="menu.title">
          {{ menu.title }}
          <span class="menu-label pulse" *ngIf="menu.label">{{menu.label}}</span>
        </div>
        <div class="menu-badge" *ngIf="menu.badge">{{ menu.badge }}</div>
        <div class="menu-caret" *ngIf="menu.caret"></div>
      </ng-template>

      <!-- render sidebarSubMenuNav -->
      <ng-template #sidebarSubMenuNav let-menu="menu">
        <div class="menu-text" *ngIf="menu.title">
          {{ menu.title }}
          <i class="fa fa-paper-plane text-theme ms-5px" *ngIf="menu.highlight"></i>
        </div>
        <div class="menu-caret" *ngIf="menu.caret"></div>
      </ng-template>

      <!-- sidebar menu -->
      <div class="menu-item" *ngFor="let menu of menus" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"
        [ngClass]="{ 'expand': menu.state == 'expand', 'has-sub': menu.submenu, 'd-none': menu.hide }"
        #rla="routerLinkActive">
        <a class="menu-link" *ngIf="menu.submenu" (mouseenter)="showAppSidebarFloatSubMenu(menu.submenu, $event)"
          [ngbTooltip]="menu.description" [openDelay]="1000" [closeDelay]="100" placement="top" container="body"
          tooltipClass="tooltip-description-menu" (mouseleave)="hideAppSidebarFloatSubMenu()">
          <ng-container *ngTemplateOutlet="sidebarMenuNav; context: {menu: menu}"></ng-container>
        </a>
        <a class="menu-link" *ngIf="!menu.submenu" (click)="onClickMenu(menu)">
          <ng-container *ngTemplateOutlet="sidebarMenuNav; context: {menu: menu}"></ng-container>
        </a>

        <!-- submenu lvl 1 -->
        <div class="menu-submenu " *ngIf="menu.submenu"
          [style.display]="(menu.state == 'expand' && !appSettings.appSidebarMinified) ? 'block' : (menu.state == 'collapse') ? 'none' : ''">
          <div class="menu-item" *ngFor="let menu1 of menu.submenu" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [ngClass]="{ 'expand': menu1.state == 'expand', 'closed': menu1.state == 'collapsed', 'has-sub': menu1.submenu, 'd-none': menu1.hide }"
            #rla1="routerLinkActive">
            <a class="menu-link" [ngbTooltip]="menu1.description" [openDelay]="1000" [closeDelay]="100" placement="top"
              container="body" tooltipClass="tooltip-description-menu" *ngIf="menu1.submenu"
              (click)="(menu1.submenu) ? expandCollapseSubmenu($event) : ''">
              <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu1}"></ng-template>
            </a>
            <a class="menu-link" *ngIf="!menu1.submenu" (click)="onClickMenu(menu1)" [ngbTooltip]="menu1.description"
              [openDelay]="1000" [closeDelay]="100" placement="top" container="body"
              tooltipClass="tooltip-description-menu">
              <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu1}"></ng-template>
            </a>

            <!-- submenu lvl 2 -->
            <div class="menu-submenu" *ngIf="menu1.submenu"
              [style.display]="(menu1.state == 'expand' && !appSettings.appSidebarMinified) ? 'block' : (menu1.state == 'collapse') ? 'none' : ''">
              <div class="menu-item" *ngFor="let menu2 of menu1.submenu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="{ 'expand': menu2.state == 'expand', 'closed': menu2.state == 'collapsed', 'has-sub': menu2.submenu, 'd-none': menu2.hide }"
                #rla2="routerLinkActive">
                <a class="menu-link" *ngIf="menu2.submenu" [ngbTooltip]="menu2.description" [openDelay]="1000"
                  [closeDelay]="100" placement="top" container="body" tooltipClass="tooltip-description-menu"
                  (click)="(menu2.submenu) ? expandCollapseSubmenu($event) : ''">
                  <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu2}"></ng-template>
                </a>
                <a class="menu-link" *ngIf="!menu2.submenu" (click)="onClickMenu(menu2)"
                  [ngbTooltip]="menu2.description" [openDelay]="1000" [closeDelay]="100" placement="top"
                  container="body" tooltipClass="tooltip-description-menu">
                  <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu2}"></ng-template>
                </a>

                <!-- submenu lvl 3 -->
                <div class="menu-submenu" *ngIf="menu2.submenu"
                  [style.display]="(menu2.state == 'expand' && !appSettings.appSidebarMinified) ? 'block' : (menu2.state == 'collapse') ? 'none' : ''">
                  <div class="menu-item" *ngFor="let menu3 of menu2.submenu" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    [ngClass]="{ 'expand': menu3.state == 'expand', 'closed': menu3.state == 'collapsed', 'has-sub': menu3.submenu, 'd-none': menu3.hide }"
                    #rla3="routerLinkActive">
                    <a class="menu-link" *ngIf="menu3.submenu" [ngbTooltip]="menu3.description" [openDelay]="1000"
                      [closeDelay]="100" placement="top" container="body" tooltipClass="tooltip-description-menu"
                      (click)="(menu3.submenu) ? expandCollapseSubmenu($event) : ''">
                      <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu3}"></ng-template>
                    </a>
                    <a class="menu-link" *ngIf="!menu3.submenu" (click)="onClickMenu(menu3)"
                      [ngbTooltip]="menu3.description" [openDelay]="1000" [closeDelay]="100" placement="top"
                      container="body" tooltipClass="tooltip-description-menu">
                      <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu3}"></ng-template>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BEGIN minify-button -->
      <div class="menu-item d-flex">
        <a href="javascript:;" class="app-sidebar-minify-btn ms-auto " (click)="toggleAppSidebarMinified()"><i
            class="fa fa-angle-double-left"></i></a>
      </div>
      <!-- END minify-button -->
    </div>
    <!-- END menu -->
  </ng-template>
</div>
<div class="app-sidebar-bg" [attr.data-bs-theme]=""></div>
<div class="app-sidebar-mobile-backdrop"><a href="javascript:;" (click)="toggleAppSidebarMobile()"
    class="stretched-link"></a></div>

<!-- END #sidebar -->

<float-sub-menu *ngIf="appSettings.appSidebarMinified" [menus]="appSidebarFloatSubMenu"
  [top]="appSidebarFloatSubMenuTop" [left]="appSidebarFloatSubMenuLeft" [right]="appSidebarFloatSubMenuRight"
  [bottom]="appSidebarFloatSubMenuBottom" [arrowTop]="appSidebarFloatSubMenuArrowTop"
  [arrowBottom]="appSidebarFloatSubMenuArrowBottom" [lineTop]="appSidebarFloatSubMenuLineTop"
  [lineBottom]="appSidebarFloatSubMenuLineBottom" (remainAppSidebarFloatSubMenu)="remainAppSidebarFloatSubMenu()"
  (hideAppSidebarFloatSubMenu)="hideAppSidebarFloatSubMenu()"
  (calculateAppSidebarFloatSubMenuPosition)="calculateAppSidebarFloatSubMenuPosition()"
  (openPage)="onClickMenu($event)"></float-sub-menu>
