<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="Impuesto"
  title="Tipos de Impuestos" icon="fa fa-angles-down" subTitle="Administra la configuración de Impuestos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Impuesto'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="Impuesto" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
        </div>
      </app-panel-base>
      <app-panel-base title="Impuestos Trasladados">
        <div class="panel-content row">
          <component-ui-combo-box [label]="'IVA Trasladado'" formControlName="IVATrasladado" [tabIndex]="3"
            [zeroMask]="2" entityName="TipoImpuesto" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" filterQuery='Tipo.Clave="002" and Retiene = false' (onSelectedItem)="selectedIVA($event)"
            [listProperty]="'ClaveNombre'" />

          <component-ui-check-box [tabIndex]="4" [widthLabelColumns]="2" formControlName="IncluidoEnPrecioIvaTrasladado"
            [label]="'¿Incluido en el precio?'" />


          <component-ui-combo-box [label]="'IEPS'" filterQuery='Tipo.Clave="003"' formControlName="IEPS" [tabIndex]="5"
            [zeroMask]="2" entityName="TipoImpuesto" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedIEPS($event)" [listProperty]="'ClaveNombre'" />

          <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="2" formControlName="IncluidoEnPrecioIEPS"
            [label]="'¿Incluido en el precio?'" />

          <component-ui-combo-box [label]="'Imp Local'" filterQuery='Traslada = true and EsImpuestoLocal = true'
            formControlName="ImpuestoLocalTrasladado" [tabIndex]="7" [zeroMask]="2" entityName="TipoImpuesto"
            [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedImpLocal($event)" [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>
      <app-panel-base title="Impuestos Retenidos">
        <div class="panel-content row">
          <component-ui-combo-box [label]="'IVA Retenido'"
            filterQuery='Retiene = true and EsImpuestoLocal = false and Tipo.Clave="002"' formControlName="IVARetenido"
            [tabIndex]="8" [zeroMask]="2" entityName="TipoImpuesto" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedIVARet($event)" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'ISR Retenido'" formControlName="ISRRetenido"
            filterQuery='Retiene = true and EsImpuestoLocal = false and Tipo.Clave="001"' [tabIndex]="9" [zeroMask]="2"
            entityName="TipoImpuesto" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedISRRet($event)" [listProperty]="'ClaveNombre'" />

            <component-ui-check-box [tabIndex]="10" [widthLabelColumns]="2" formControlName="IncluidoEnPrecioISRRetenido"
            [label]="'¿Incluido en el precio?'" />

          <component-ui-combo-box *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]" filterQuery='Retiene = true and EsImpuestoLocal = true' label="Imp Local {{item}}"
            formControlName="ImpuestoLocalRetenido{{item}}" [tabIndex]="11 + (item-1)" [zeroMask]="2"
            entityName="TipoImpuesto" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedImpLocalRet($event,item)" [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>


    </form>
  </div>
</app-container-base>
