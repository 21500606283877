import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MovimientoPoliza, MovimientoPolizaComprobantesNacionales } from '../../interfaces/movimientopoliza.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ContabilidadService } from '../../services/contabilidad.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { TipoPoliza } from '../../interfaces/tipopoliza.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { CuentaContable } from '../../interfaces/cuentacontable.interface';
import { CentroCosto } from '../../interfaces/centrocosto.interface';
import { LevelTextBoxComponent } from 'src/app/component-ui/components/level-text-box/level-text-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';
import { EventsService } from 'src/app/service/events.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Diot } from 'src/app/Finanzas/interfaces/diot.interface';
import { UtilsService } from 'src/app/service/utils.service';
import * as moment from 'moment';
import { FinanzasService } from 'src/app/Finanzas/services/finanzas.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'ctrl-edicion-poliza-page',
  templateUrl: './ctrl-edicion-poliza-page.component.html',
  styleUrls: ['./ctrl-edicion-poliza-page.component.scss']
})
export class CtrlEdicionPolizaPageComponent implements OnInit {

  searching: boolean = false;

  @ViewChild('txtCuentas') public txtCuentas!: ElementRef<LevelTextBoxComponent>;
  @ViewChild('txtAceptar') public txtAceptar!: ElementRef<HTMLElement>;
  @ViewChild('txtConcepto') public txtConcepto!: ElementRef<TextBoxComponent>;

  @ViewChild('txtCargo') public txtCargo!: ElementRef<NumberBoxComponent>;
  @ViewChild('txtAbono') public txtAbono!: ElementRef<NumberBoxComponent>;
  @ViewChild('ctrlDIOT') public ctrlDIOT!: ElementRef<HTMLElement>;
  private diot: Diot | null = null;

  private eventsService = inject(EventsService);
  @Input()
  public tabIndex: number = 0;
  @Input()
  public totalCargos: number = 0;
  @Input()
  public totalAbonos: number = 0;
  @Input()
  public totalDiferencia: number = 0;

  solicitaDIOT: boolean = false;
  info: UserLogged = {} as UserLogged;
  @Input()
  public item!: MovimientoPoliza;
  @Input()
  public fechaPoliza!: Date;
  nombreCuenta: string = "";
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    if (!this.item.tipoMovimiento) {
      this.item.importeCargo = this.item.importe;
    } else {
      this.item.importeAbono = this.item.importe;
    }
    if (this.item.orden == 0) {
      this.item.orden = 1;
    }
    this.myForm.reset(this.item)
    if (this.item?.cuenta) {
      this.nombreCuenta = this.item?.cuenta.nombre;
    }
    setTimeout(() => {
      //this.setCaretMember();
      const txt: any = this.txtCuentas;
      txt.txt1.tagInput.nativeElement.focus();
    }, 50);
  }

  public myForm: FormGroup = this.fb.group({
    id: [0],
    index: [0],
    empresa: [{} as Empresa],
    tipoPoliza: [{} as TipoPoliza],
    fecha: [Date],
    periodo: [0],
    ejercicio: [0],
    folio: [0],
    conceptoGeneral: [''],
    diferencia: [0],
    usuarioAlta: [{} as Usuario],
    usuarioModifica: [{} as Usuario],
    //Detalle de la póliza
    cuenta: [{} as CuentaContable],
    claveCuentaNivel1: [''],
    claveCuentaNivel2: [''],
    claveCuentaNivel3: [''],
    claveCuentaNivel4: [''],
    claveCuentaNivelGlobal: [''],
    centroCosto: [{} as CentroCosto],
    claveCentroCostoNivel1: [''],
    claveCentroCostoNivel2: [''],
    claveCentroCostoNivel3: [''],
    claveCentroCostoNivel4: [''],
    claveCentroCostoNivelGlobal: [''],
    tipoMovimiento: [false], //true = Abono, false = cargo
    importe: [0],
    importeCargo: [0],
    importeAbono: [0],
    orden: [0],
    conceptoDetalle: [''],
    referencia: [''],
    uuids: [''],
    comprobantesNacionales: [[]],
    eliminado: [false],
    esPlantilla: [false],
    baja: [false],

  })

  constructor(private contaService: ContabilidadService, private utileService: UtilsService, private fs: FinanzasService,
    private mService: ModalService, private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  get getClaveGeneral(): string {
    return this.myForm.value.claveCuentaNivelGlobal;
  }

  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  enterImporte(tipo: number) {
    if (tipo == 1) {
      if (this.myForm.value.importeCargo != 0) {
        const txtc: any = this.txtCargo;
        txtc.tagInput.nativeElement.blur()
      }
    }
    if (tipo == 2) {
      const txta: any = this.txtAbono;
      if (txta.tagInput.nativeElement.value) {
        if (parseFloat(txta.tagInput.nativeElement.value) != 0) {
          txta.tagInput.nativeElement.blur()
        }
      }
    }
    setTimeout(() => {
      if (tipo == 1) {
        if (this.myForm.value.importeCargo != 0) {
          this.accept();
        }
      } else if (this.myForm.value.importeAbono != 0) {
        this.accept();
      }
    }, 10);

  }

  setCaretMember() {
    let variant: boolean = true;
    const inputs = document.querySelectorAll('.form-control.form-control-sm');
    inputs.forEach((input: any) => {
      input!.addEventListener('focus', () => {
        input.setSelectionRange(0, 1);
      });
      input!.addEventListener('keydown', (e: any) => {
        const s = input.selectionStart;
        if (e.keyCode == 8) {
          input.setSelectionRange(s - 1, s);
        }
      }, false);
      input!.addEventListener('keyup', (e: any) => {
        const s = input.selectionStart;
        if (e.keyCode == 8) {
          input.setSelectionRange(s, s + 1);
          e.preventDefault();
          return;
        }
        if (e.keyCode != 13) {
          if (e.keyCode == 37) {
            input.setSelectionRange(s - 1, s);
          } else {
            input.setSelectionRange(s, s + 1);
          }
        }
      }, false);
      input!.addEventListener('keypress', (e: any) => {
        const s = input.selectionStart;
        if (e.keyCode == 8) {
          e.preventDefault();
          return;
        }
        if (e.keyCode != 13) {
          input.setSelectionRange(s, s + 1);
        }
      }, false);
    });

  }

  onChangueCentro(cuenta: CentroCosto) {
    this.myForm.controls["centroCosto"].setValue(cuenta);
    this.myForm.controls["claveCentroCostoNivelGlobal"].setValue(cuenta.claveGeneral);

    this.myForm.controls["claveCentroCostoNivel1"].setValue("");
    this.myForm.controls["claveCentroCostoNivel2"].setValue("");
    this.myForm.controls["claveCentroCostoNivel3"].setValue("");
    this.myForm.controls["claveCentroCostoNivel4"].setValue("");
    const claves = cuenta.claveGeneral.split(' ');
    claves.forEach((element: any, index: number) => {
      this.myForm.controls["claveCentroCostoNivel" + (index + 1)].setValue(element);
    });

  }

  onChangueCuenta(cuenta: CuentaContable) {
    this.myForm.controls["cuenta"].setValue(cuenta);
    this.myForm.controls["claveCuentaNivelGlobal"].setValue(cuenta.claveGeneral);

    this.myForm.controls["claveCuentaNivel1"].setValue("");
    this.myForm.controls["claveCuentaNivel2"].setValue("");
    this.myForm.controls["claveCuentaNivel3"].setValue("");
    this.myForm.controls["claveCuentaNivel4"].setValue("");
    const claves = cuenta.claveGeneral.split(' ');
    this.nombreCuenta = cuenta.nombre;
    claves.forEach((element: any, index: number) => {
      this.myForm.controls["claveCuentaNivel" + (index + 1)].setValue(element);
    });

  }


  accept(validarImportes: boolean = true) {
    const c: any = this.txtCuentas;
    const claveCuenta = this.getClaveGeneralCuentaContable(c.myForm.value);
    if (validarImportes) {
      if (this.myForm.value.importeCargo == 0 && this.myForm.value.importeAbono == 0) {

        this.eventsService.publish('home:showAlert', {
          message: "No ha indicado importe Cargo o Abono ¿desea continuar?.", onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              this.accept(false);
            }
          }
        });
        return false;
      }
    }

    if (this.myForm.value.importeCargo != 0 && this.myForm.value.importeAbono != 0) {
      this.eventsService.publish('home:showAlert', { message: 'Solo indique el Importe Cargo o Abono.', cancelButton: false });
      return false;
    }

    if (!claveCuenta) {
      this.eventsService.publish('home:showAlert', { message: 'Indique una Cuenta Contable.', cancelButton: false });
      return false;
    }
    if (!this.myForm.value.conceptoDetalle) {
      this.eventsService.publish('home:showAlert', { message: 'Indique un Concepto.', cancelButton: false });
      return false;
    }

    if (this.myForm.value.importeCargo != 0) {
      this.myForm.controls["importe"].setValue(this.myForm.value.importeCargo);
      this.myForm.controls["tipoMovimiento"].setValue(false);
    } else if (this.myForm.value.importeAbono != 0) {
      this.myForm.controls["importe"].setValue(this.myForm.value.importeAbono);
      this.myForm.controls["tipoMovimiento"].setValue(true);
    }


    this.searching = true;
    // this.contaService.getAccount(this.myForm.value.empresa.id, claveCuenta, true).subscribe((cuenta) => {
    //   if (cuenta) {
    //this.onChangueCuenta(cuenta);
    this.myForm.controls["claveCuentaNivelGlobal"].setValue(claveCuenta);
    const json = JSON.stringify(this.myForm.value);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let idAnterior = this.myForm.value.id;
    this.contaService.saveEntity("MovimientoPoliza", json).subscribe((mp: any) => {
      if (!mp.message) {
        if (!this.diot) {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.searching = false;
          this.mService.closeModal({ ...mp, modificado: true, index: this.myForm.value.index });
        } else {
          //guardamos la diot y la relacionamos a la poliza
          this.diot.fechaString = moment(this.diot.fecha).format("DD/MM/YYYY");
          if (this.diot.fechaAplicado) {
            this.diot.fechaAplicadoString = moment(this.diot.fechaAplicado).format("DD/MM/YYYY");
          }
          const json = JSON.stringify(this.diot);
          this.fs.guardarDiot(json, mp.id, mp.folio, mp.tipoPoliza!.id).subscribe((re) => {
            this.searching = false;
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (re.success) {
              mp.idDiot = parseInt(re.message);
              this.mService.closeModal({ ...mp, modificado: true, index: this.myForm.value.index });
            } else {
              if (idAnterior == 0) {
                this.contaService.deleteEntity(mp.id).subscribe(() => {
                  this.eventsService.publish('home:showAlert', { message: re.message, cancelButton: false });
                })
              } else {
                this.eventsService.publish('home:showAlert', { message: re.message, cancelButton: false });
              }
            }
          })
        }
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.searching = false;
        this.eventsService.publish('home:showAlert', { message: mp.message, cancelButton: false });
      }
      // })
      // } else {
      //   this.eventsService.publish('home:isLoading', { isLoading: false });
      //   this.searching = false;
      //   this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      // }
    });
    //   } else {
    //     this.searching = false;
    //     this.eventsService.publish('home:showAlert', { message: 'La cuenta contable indicada no existe o no está a último nivel, verifique.', cancelButton: false });
    //   }
    // })

    return;
  }


  onSelectCuenta(cuenta: string) {
    if (cuenta) {
      this.nombreCuenta = cuenta;
    } else {
      this.nombreCuenta = "";
    }
  }

  onLastLevelCuenta() {
    const c: any = this.txtCuentas;
    const claveCuenta = this.getClaveGeneralCuentaContable(c.myForm.value);
    this.contaService.solicitaDIOT(this.myForm.value.empresa.id, claveCuenta).subscribe((solicitaIva) => {
      this.solicitaDIOT = false;
      if (!solicitaIva) {
        const txt: any = this.txtConcepto;
        txt.tagInput.nativeElement.focus()
        txt.tagInput.nativeElement.select()
      } else {
        this.openDIOT();
      }
    });
  }

  openDIOT() {
    this.solicitaDIOT = true;
    const b: any = this.ctrlDIOT;
    this.mService.openModal(b, (e: any) => {
      if (e) {
        e.idEmpresa = this.info.empresa!.numero;
        this.diot = e;
        let ref = `${e.factura} ${moment(e.fecha).format("DDMMYY")} ${e.proveedor.nombre}`
        if (ref.length > 50) {
          ref = ref.substring(0, 50);
        }
        e.referencia = ref;
        this.myForm.controls["conceptoDetalle"].setValue(ref);
        this.myForm.controls["referencia"].setValue(e.factura);
        if (e.uuid) {
          if (!this.myForm.value.comprobantesNacionales) { this.myForm.controls["comprobantesNacionales"].setValue([]); }
          let comps: any = this.myForm.value.comprobantesNacionales;
          let existe = comps.filter((P: any) => P.uuid.toLowerCase() == e.uuid.toLowerCase()).length > 0;
          if (!existe) {
            comps = [...comps, { id: 0, uuid: e.uuid }];
            this.myForm.controls["comprobantesNacionales"].setValue(...[comps]);
            this.myForm.controls["uuids"]
          }
          this.myForm.controls["importeAbono"].setValue(e.total);
        }
      }
      //this.onCloseCfdi(e);
    });
  }

  getClaveGeneralCuentaContable(obj: any): string {

    let clave = "";

    if (obj.Level1) {
      clave += obj.Level1;
    }

    if (obj.Level2) {
      clave += ` ${obj.Level2}`;
    }

    if (obj.Level3) {
      clave += ` ${obj.Level3}`;
    }

    if (obj.Level4) {
      clave += ` ${obj.Level4}`;
    }

    if (obj.Level5) {
      clave += ` ${obj.Level5}`;
    }

    return clave;

  }

  closeModal() {
    this.mService.closeModal(null);
  }


}
