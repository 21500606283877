<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Transferencia Electrónica de Fondos</div>


  <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
    [hasCounter]="false">
    <div class="screen-content">
      <app-panel-base title="Indique la información solicitada">
        <div class="panel-content">
          <form [formGroup]="myForm" autocomplete="off">


            <component-ui-combo-box [label]="'Banco'" [tabIndex]="1" formControlName="Banco" entityName="c_Banco"
              [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0" [inputProperty]="'Nombre'"
              (onSelectedItem)="onSelectedItem($event,'Banco')" [required]="true" [listProperty]="'ClaveNombre'"
              #cboBanco />

            <component-ui-combo-box [label]="'Cuenta'" [tabIndex]="2" formControlName="CuentaBancaria"
              entityName="CuentaBancaria" [filterQuery]="'Banco.Id = ' + (myForm.value.Banco ? myForm.value.Banco.Id:0)"
              [widthTextColumns]="4" [widthLabelColumns]="4" [widthRightColumns]="0" [inputProperty]="'Nombre'"
              (onSelectedItem)="onSelectedItem($event,'CuentaBancaria')" [required]="true"
              [listProperty]="'ClaveNombre'" #cboPoliza />

            <components-ui-date-box [label]="'Fecha Depósito'" [tabIndex]="3" formControlName="FechaDeposito"
              [widthTextColumns]="4" [widthLabelColumns]="4" [required]="true" />

            <components-ui-number-box [label]="'Importe Aplicar'" [required]="true" formControlName="ImporteAplicar"
              [tabIndex]="4" [widthTextColumns]="4" [widthLabelColumns]="4" />

            <components-ui-number-box [label]="'Importe Original'" [required]="true" formControlName="ImporteOriginal"
              [tabIndex]="5" [widthTextColumns]="4" [widthLabelColumns]="4" />

            <components-ui-text-box [label]="'Ref Bancaria'" [required]="true" formControlName="ReferenciaBancaria"
              [tabIndex]="6" [widthTextColumns]="4" [widthLabelColumns]="4" [maxLength]="100" />

            <components-ui-text-box [label]="'Referencia'" [enabled]="false" formControlName="Referencia"
              [tabIndex]="6" [widthTextColumns]="4" [widthLabelColumns]="4" [maxLength]="100" />

          </form>
          <hr>
          <div style="text-align: right; margin-top: 5px;">
            <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
            <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
          </div>
        </div>
      </app-panel-base>
    </div>
  </app-container-base>


</div>
