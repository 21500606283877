import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { ReciboElectronicoPago, ReciboElectronicoPagoDetalle, SerieReciboElectronico } from 'src/app/Inventarios/interfaces/rep.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ModalService } from 'src/app/service/modal.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { EventsService } from 'src/app/service/events.service';
import Swal from 'sweetalert2';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Cliente } from '../../interfaces/cliente.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { BaseService } from 'src/app/service/base.service';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { FormaVenta } from '../../interfaces/claseventa.interface';
import * as moment from 'moment';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { ClaseCliente } from '../../interfaces/tipocliente.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CfdiEstadoSAT } from '../../interfaces/venta.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-recibo-elec-pago-page',
  templateUrl: './recibo-elec-pago-page.component.html',
  styles: [
  ]
})
export class ReciboElecPagoPageComponent implements OnInit {
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;
  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<any>;
  @ViewChild('ctrlCatClientes')
  public ctrlCatClientes!: ElementRef<HTMLElement>;
  @ViewChild('ventanaPagos')
  public ventanaPagos!: ElementRef<HTMLElement>;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;
  estadoSAT: CfdiEstadoSAT | null = null;
  private tabsNavService = inject(TabsNavService);
  private eventsService = inject(EventsService);
  private baseService = inject(BaseService);
  indiceEdicion: number = -1;
  itemEdicion: ReciboElectronicoPagoDetalle | null = null;
  private readonly baseUrl: string = environment.baseUrlApi;
  info: UserLogged = {} as UserLogged;
  searchConfigurationCliente: SearchConfiguration | null = null;
  public listaSeries: ComboBoxEntity[] = [];
  public listaPagos: ReciboElectronicoPagoDetalle[] = [];
  buscarCliente: boolean = false;
  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }
  public GuiColumnAlign = GuiColumnAlign;
  private newEntityObj = {
    Id: [0],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    Serie: [{} as SerieReciboElectronico, Validators.required],
    FechaEmisionString: [''],
    SerieString: [''],
    MotivoCancelacion: [''],
    Folio: [0],
    Estado: [true],
    EstadoSAT: [null],
    FechaEmision: [Date],
    FechaCancelacion: [null],
    Cliente: [{} as Cliente],
    UsuarioAlta: [{} as Usuario],
    UsuarioCancela: [{} as Usuario],
    MontoTotalPago: [0],
    TotalTrasladosBaseIVA16: [0],
    TotalTrasladosImpuestoIVA16: [0],
    TotalTrasladosBaseIVA8: [0],
    TotalTrasladosImpuestoIVA8: [0],
    TotalTrasladosBaseIVA0: [0],
    TotalTrasladosImpuestoIVA0: [0],
    TotalTrasladosBaseIVAExento: [0],
    Pagos: [[] as ReciboElectronicoPagoDetalle[]],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);


  constructor(private fb: FormBuilder, private cService: ContainerBaseService, private ms: ModalService, private utileService: UtilsService, private fService: FacturacionService, private http: HttpClient) { }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }
  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  get getTotalPagos(): number {
    let total: number = 0;
    this.listaPagos.forEach((i) => {
      total += i.Monto;
    })

    return total;
  }

  downloadFiles() {
    if (this.myForm.value.Id > 0) {
      this.fService.downloadFilesRep(this.myForm.value.Id);
    } else {
      this.eventsService.publish('home:showAlert', { message: "El REP no se ha guardado.", cancelButton: false });
    }
  }
  elementoSeleccionado(elemento: any) {
    this.searchCustomer(elemento.Clave, true);
  }
  getDateFormat(ent: any): string {
    return moment(ent).format("DD/MM/YYYY");
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getFilter(): any {
    return "";
  }

  onCloseEdition(ent: ReciboElectronicoPagoDetalle | null) {
    if (ent) {
      if (this.indiceEdicion == -1) {
        this.listaPagos = [...this.listaPagos, ent];
      } else {
        this.listaPagos[this.indiceEdicion] = ent;
        this.listaPagos = [...this.listaPagos];
      }

      this.indiceEdicion = -1;
      this.itemEdicion = null;
    }
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    setTimeout(() => {
      this.getSeries();
    }, 150);
  }

  getEmptyEntity(idSerie: number = 0) {
    this.cService.getEmptyEntity("ReciboElectronicoPago").subscribe((ent) => {
      this.initializeEntity(ent, idSerie)
    })
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
    }
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }


  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  findEntityByParamsNavigate(type: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.http.get<ReciboElectronicoPago>(`${this.baseUrl}/Ventas/ObtenerRepPorParametrosNavegacion`, { params }).subscribe((venta) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(venta);
    });
  }

  findEntityByParams() {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.findRepByParams(ent.Empresa.Id, ent.Sucursal.Id, ent.SerieString, ent.Folio).subscribe((rep) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(rep);
    });
  }

  setEntity(venta: ReciboElectronicoPago) {
    if (!venta) {
      this.new();
    } else {
      let ent = this.myForm.value;
      ent = { ...venta };
      this.sourceReset(ent.Pagos);

      if (ent.Cliente) {
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = venta.Cliente!.Clave;
      }
      this.myForm.reset(ent);
    }
  }
  downloadPdf(base64: string) {
    this.fService.printDocument(this.fService.base64ToArrayBuffer(base64));
  }
  print(cb: any = null) {
    let ent = this.myForm.value;
    if (ent.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Primero debe de guardar el Documento.`, cancelButton: false });
      return;
    }

    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idRep", ent.Id);
    this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirRep`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.downloadPdf(result.message);
        cb && cb();
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })

  }


  sendEmail() {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fService.sendRepEmail(this.myForm.value.Id, "").subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          this.eventsService.publish('home:showAlert', { message: "Se ha enviado por correo electrónico", cancelButton: false });
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    } else {
      this.eventsService.publish('home:showAlert', { message: "El documento no se ha guardado", cancelButton: false });
    }
  }

  save() {
    let ent = this.myForm.value;
    if (ent.Id > 0) {
      this.eventsService.publish('home:showAlert', { message: "No se puede actualizar un REP.", cancelButton: false });

      return;
    }
    if (!ent.Cliente || ent.Cliente?.Id == 0 || !ent.Cliente["Id"]) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar un Cliente.", cancelButton: false });
      return;
    }
    if (this.listaPagos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar los Pagos.", cancelButton: false });
      return;
    }
    ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");
    ent.Pagos = this.listaPagos;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.saveRep(ent).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
          const v: ReciboElectronicoPago = JSON.parse(result.message);
          this.myForm.reset(v);
          this.print(() => {
            this.sendEmail();
          });
        });
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  new() {
    const ent: ReciboElectronicoPago = this.myForm.value;
    this.getEmptyEntity(ent.Serie!.Id);
    this.sourceReset([]);
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.value = '';
  }

  initializeEntity(ent: any, idSerie: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
    ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
    if (idSerie > 0) {
      const serie = this.listaSeries.filter((P) => P.Id == idSerie)[0];
      ent.Serie = { Id: idSerie };
      ent.SerieString = serie.Serie;
    }
    this.sourceReset([]);
    this.getNextFolio(ent);
  }

  getNextFolio(ent: ReciboElectronicoPago) {
    this.fService.getNextFolioRep(ent.Empresa!.Id, ent.Sucursal!.Id, ent.SerieString).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  getDate(ent: ReciboElectronicoPago) {
    this.fService.ObtenerFechaPorSucursal(ent.Sucursal!.Id).subscribe((result) => {
      //2024-01-29T10:23:49
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.myForm.reset(ent);
      this.focusCliente();
    })

  }

  openVentanaPago() {
    const b: any = this.ventanaPagos;
    this.ms.openModal(b, (e: any) => {
      this.onCloseEdition(e);
    }, 'lg')
  }

  openCustomerCatalog() {
    const b: any = this.ctrlCatClientes;
    this.ms.openModal(b, (e: any) => {

    }, 'lg')
  }

  blurCustomer(values: any) {
    if ((values.after != values.before) && this.buscarCliente) {
      this.searchCustomer(values.after);
    }
    this.buscarCliente = true;
  }

  enterCliente() {
    const txt: any = this.txtCliente;
    this.searchCustomer(txt.tagInput.nativeElement.value);
  }

  clicIcon(value: any) {
    this.openCustomerSearch();
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      this.searchCustomer(e.Clave);
    }, 'lg')
  }

  searchCustomer(clave: string, sendFocus: boolean = false) {

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.baseService.getClientePorClave(this.myForm.value.Empresa.Id, Number(clave), false).subscribe((cliente) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      let ent = this.myForm.value;
      if (cliente) {
        ent.Cliente = cliente;
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = cliente.Clave;
        this.buscarCliente = false;
        if (this.myForm.value.Id === 0) {
          this.openVentanaPago();
        }
      } else {
        ent.Cliente = null;
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = "";
        this.eventsService.publish('home:showAlert', { message: "No se encontró el cliente indicado.", cancelButton: false });
      }
      this.myForm.reset(ent);
    })
  }


  focusCliente() {
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.focus()
  }
  sourceReset(pagos: any = []) {
    this.listaPagos = pagos;
  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  onSelectedItem(entity: any, type: string) {
    switch (type) {
      case "Serie":
        let entrar = true;
        if (this.myForm.value.Serie) {
          if (this.myForm.value.Serie.Id == entity.Id) {
            entrar = false;
          }
        }
        if (entrar) {
          this.getEmptyEntity(entity.Id);
        }
        break;
    }
  }

  editar(item: ReciboElectronicoPagoDetalle, index: number) {
    this.indiceEdicion = index;
    this.itemEdicion = { ...item };
    this.openVentanaPago();
  }

  deleteRow(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el pago?',
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.listaPagos.splice(index, 1);
          this.listaPagos = [...this.listaPagos];
        }
      }
    });
  }


  tienePagoRelacionado() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.tieneRepPagoRelacionado(this.myForm.value.Id).subscribe((tiene) => {
      if (!tiene) {
        this.verifyStatus()
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: "No puede cancelar el REP aquí, tiene un pago relacionado, vaya a la Captura de Pagos de CXC y cancele el pago, el REP se cancelará de forma automática.", cancelButton: false });
      }
    })
  }

  verifyStatus() {
    const params = new HttpParams().set("idRep", this.myForm.value.Id);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/VerificarEstadoSATRep`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const r: CfdiEstadoSAT = JSON.parse(result.message);
        if (r.EsCancelable == "No cancelable") {
          this.eventsService.publish('home:showAlert', { message: `El documento no es Cancelable, verifique que no esté relacionado a otro CFDI.`, cancelButton: false });
          return;
        }
        if (r.Estado == "No Encontrado") {
          this.eventsService.publish('home:showAlert', { message: `El CFDI no fue encontrado en los servidores del SAT, por favor intente de nuevo mas tarde.`, cancelButton: false });
          return;
        }
        this.estadoSAT = r;
        this.openCancel();
      } else {

        this.eventsService.publish('home:showAlert', {
          message: "No se pudo consultar el estatus del CFDI ¿desea continuar con la cancelación?", onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              const r: CfdiEstadoSAT = JSON.parse(result.message);
              this.estadoSAT = r;
              setTimeout(() => { this.openCancel(); }, 151);
            }
          }
        });
      }
    });
  }


  openCancel() {
    const b: any = this.modalCancelOptiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }

  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date = moment(e.Fecha).format("DD/MM/YYYY HH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("idMotivoSAT", e.MotivoSAT ? e.MotivoSAT.Id : 0)
      .set("fechaCancelacion", date)
      .set("uuidSustituye", e.UUID);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/CancelarREP`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.estadoSAT = null;
        this.findEntityByParams()
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }



  getSeries() {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.fService.getSeriesRep(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          this.getEmptyEntity(this.listaSeries[0].Id);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          setTimeout(() => {
            let ent = this.myForm.value;
            ent.Empresa = { Id: this.info.empresa!.numero };
            ent.Sucursal = { Id: this.info.sucursal!.numero };
            txt.tagInput.nativeElement.value = '';
            //this.sourceReset([]);
            txt.tagInput.nativeElement.focus()
            this.myForm.reset(ent);

          }, 150);
        }
      } else {
        this.eventsService.publish('home:showAlert', { message: "Primero debe Configurar una Serie de Recibos Electrónicos de Pago.", cancelButton: false });
        this.tabsNavService.closeCurrentTab();
      }
    })


  }

}
