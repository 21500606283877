<div (keydown)="keydown($event)" class="card text-center border-0" cdkDrag
  style="position: absolute;top:183px;width: 540px;left: 360px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar/editar detalle de caja chica
  </div>
  <div class="card-body bg-light">
    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">
        <form [formGroup]="myForm" autocomplete="off">
          <div class="d-flex">
            <div style="width:267px;margin-right: -35px;">
              <components-ui-number-box (keydown.escape)="closeModal()" [required]="true" (blur)="blurProveedor($event)"
                [tabIndex]="100" [maxLength]="15" (onClicIcon)="openProvSearch()" (keydown.f2)="openProvSearch()"
                [label]="'Proveedor'" [enableIcon]="true" [widthTextColumns]="4" [widthLabelColumns]="7"
                #txtProveedor />
            </div>
            <span style="height: 22px; padding: 7px;" class="badge bg-info rounded-0">{{getNombreProveedor |
              uppercase}}</span>
          </div>

          <components-ui-text-box formControlName="concepto" [tabIndex]="101" [required]="true" [maxLength]="100"
            [label]="'Concepto'" [widthTextColumns]="8" [widthLabelColumns]="4" #txtConcepto />

          <components-ui-text-box formControlName="referencia" [tabIndex]="102" [required]="true" [maxLength]="50"
            [label]="'Referencia'" [widthTextColumns]="8" [widthLabelColumns]="4" />

          <component-ui-combo-box [label]="'Clase'" [required]="true" formControlName="clase" [tabIndex]="103"
            [zeroMask]="2" entityName="ClaseGastoCajaChica" [widthTextColumns]="3" [widthLabelColumns]="4"
            [widthRightColumns]="0" (onSelectedItem)="selectedItem($event)" [listProperty]="'ClaveNombre'" />

          <components-ui-text-box (blur)="blurSerieFolio($event)" formControlName="serieFolioFactura" [tabIndex]="104"
            [maxLength]="150" [label]="'Serie/Folio'" [widthTextColumns]="8" [widthLabelColumns]="4" />

          <components-ui-text-box (keydown.f2)="openCfdi()" (onClicIcon)="openCfdi()" formControlName="uuid"
            [tabIndex]="105" [maxLength]="40" [enabled]="false" [label]="'UUID'" [widthTextColumns]="8"
            [enableIcon]="true" [widthLabelColumns]="4" />

          <components-ui-number-box formControlName="subtotal" [tabIndex]="106" [required]="true" [maxLength]="15"
            [label]="'Subtotal'" [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box formControlName="iva" [tabIndex]="107" [maxLength]="15" [label]="'IVA'"
            [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box formControlName="iVARetenido" [tabIndex]="108" [maxLength]="15"
            [label]="'IVA Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box formControlName="iSRRetenido" [tabIndex]="109" [maxLength]="15"
            [label]="'ISR Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box formControlName="impLocalTrasladado" [tabIndex]="110" [maxLength]="15"
            [label]="'Imp Local Trasladado'" [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box formControlName="impLocalRetenido" [tabIndex]="111" [maxLength]="15"
            [label]="'Imp Local Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />

          <components-ui-number-box (keydown.enter)="accept()" formControlName="total" [tabIndex]="112" [maxLength]="15"
            [label]="'Total'" [widthTextColumns]="3" [widthLabelColumns]="4" #txtTotal />

        </form>
      </div>
    </div>

    <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
        [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #ctrlBusqueda />
    </ng-template>

    <ng-template let-modal let-c="close" #ctrlAgregarCfdi>
      <app-ctrl-buscador-cfdi [mostrarAgregados]="false" [title]="'CFDIS a relacionar al gasto'"
        [anio]="fechaActual.getFullYear()" [mes]="fechaActual.getMonth()"
        [setIsNew]="true" />
    </ng-template>

  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8] Aceptar
      </div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
    </div>
  </div>
</div>
