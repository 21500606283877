import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import Swal from 'sweetalert2';
import { ChequePosfechado, ChequePosfechadoDetalle, EstadoChequePosfechado, TipoChequePosfechado } from '../../interfaces/chequeposfechado.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { c_Banco } from 'src/app/Finanzas/interfaces/c_banco.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { EventsService } from 'src/app/service/events.service';
import { BaseService } from 'src/app/service/base.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { en } from '@fullcalendar/core/internal-common';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-cheque-posfechado-page',
  templateUrl: './cheque-posfechado-page.component.html',
  styles: [
  ]
})
export class ChequePosfechadoPageComponent implements OnInit {
  getDate(item: ChequePosfechadoDetalle): string {
    if (item?.FechaEmision) {
      return moment(item.FechaEmision).format("DD/MM/YYYY")
    }
    return "";
  }

  public itemsCombo: ComboBoxEntity[] = [];
  info: UserLogged = {} as UserLogged;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<TextBoxComponent>;

  @ViewChild('txtAbonar')
  public txtAbonar!: ElementRef<TextBoxComponent>;

  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  public GuiColumnAlign = GuiColumnAlign;
  selectedItemDetalle: ChequePosfechadoDetalle | null = null;
  listaDetalles: ChequePosfechadoDetalle[] = []
  selectedIndex: number = -1;
  editing: boolean = false;
  indexEditing: number = -1;

  searchConfigurationCliente: SearchConfiguration | null = null;
  private eventsService = inject(EventsService);
  private baseService = inject(BaseService);
  private reportsService = inject(ReportsService);
  private readonly baseUrl: string = environment.baseUrlApi;



  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Comentarios: [''],
    Cliente: [{} as Cliente, Validators.required],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    Banco: [{} as c_Banco, Validators.required],
    Folio: [0, Validators.required],
    Importe: [0, Validators.required],
    FechaEmision: [Date, Validators.required],
    FechaAplicacion: [Date, Validators.required],
    Tipo: [{} as TipoChequePosfechado, Validators.required],
    Estado: [{} as EstadoChequePosfechado, Validators.required],
    Usuarioalta: [{} as Usuario],
    UsuarioModifica: [{} as Usuario],
    Detalle: [[] as ChequePosfechadoDetalle[]],
    Baja: [false]
  })



  public filtroCajaChica: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de cheques posfechados',
      NombreExcel: 'Reporte de cheques posfechados.xlsx',
      FilterOptions: [
        { Campo: 'cheq.Id', Etiqueta: 'Tipo caja chica', Tipo: TypeFilter.number },
      ]
    };


  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private utileService: UtilsService, private ms: ModalService, private http: HttpClient) {
  }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
  }

  openClienteSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Cliente"].setValue(e);
        const t: any = this.txtCliente;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtCliente;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtCliente;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  blurCliente(values: any) {
    if (values.after != values.before) {
      this.searchCustomer(values.after);
    }
  }


  resetSource(details: ChequePosfechadoDetalle[] | null, continueEditing: boolean = false) {
    if (details) {
      this.listaDetalles = [...details.filter(P => P.Folio > 0)];
    } else {
      this.listaDetalles = []
    }
    const length = this.listaDetalles.length;
    //for (let index = 0; index < length + 1; index++) {
    this.listaDetalles = [...this.listaDetalles, {
      Id: 0,
      Serie: '',
      Folio: 0,
      FechaEmision: null,
      Total: 0,
      Saldo: 0,
      Abonar: 0,
      Padre: null,
    }];
    //}

    setTimeout(() => {
      const elems: any = document.getElementById("divChequePosFechado")!.querySelectorAll('.gui-content')[0];
      elems.childNodes.forEach((renglon: HTMLElement, indexRow: number) => {
        if (renglon.classList) {
          renglon.classList.remove("editing-row");
          renglon.classList.remove("pulse-row");
          renglon.addEventListener("dblclick", () => {
            this.onDblClickRow(indexRow);
          });
        }
      });
      if (continueEditing) {
        this.initEdit(this.selectedIndex + 1);
      } else {
        this.editing = false;
        this.selectedIndex = -1;
        this.selectedItemDetalle = null;
      }

    }, 100);
  }


  onDblClickRow(index: number) {
    if (this.editing) return;
    let idCliente = 0;

    const form = this.myForm.value;
    if (form.Cliente) {
      if (form.Cliente.Id > 0) {
        idCliente = form.Cliente.Id;
      }
    }

    if (idCliente == 0) {
      Swal.fire({ text: 'Primero indique un Cliente.', icon: 'info', })
      return;
    }

    // const elems: any = document.getElementById("divChequePosFechado")!.querySelectorAll('.gui-content')[0];
    // elems.childNodes.forEach((renglon: HTMLElement, indexRow: number) => {
    //   if (renglon.classList) {
    //     renglon.classList.remove("editing-row");
    //     renglon.classList.remove("pulse-row");
    //   }
    // });
    this.initEdit(index);
  }


  initEdit(index: number) {
    setTimeout(() => {
      this.selectedIndex = index;
      const sel: any = this.listaDetalles[this.selectedIndex];
      this.selectedItemDetalle = sel;
      this.editing = true;
      const elems: any = document.getElementById("divChequePosFechado")!.querySelectorAll('.gui-content')[0];
      elems.childNodes.forEach((renglon: HTMLElement, indexRow: number) => {
        if (indexRow == index && renglon.classList) {
          renglon.classList.add("editing-row");
          renglon.classList.add("pulse-row");
          renglon.scrollIntoView({ block: "center" });
        }
      });
    }, 100);
  }


  onCloseEdit(mov: ChequePosfechadoDetalle): void {
    if (!mov) {
      this.editing = false;
      this.selectedIndex = -1;
      this.selectedItemDetalle = null;
      this.resetSource(this.listaDetalles);
    } else {
      this.listaDetalles[this.selectedIndex] = mov;
      this.resetSource(this.listaDetalles, true);
      this.editing = false;
    }
  }

  deleteRow(index: number) {
    Swal.fire({
      title: '¿Desea eliminar el registro?',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const idEliminar = this.listaDetalles[index].Id;
        if (idEliminar > 0) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.deleteDetail(idEliminar, () => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.listaDetalles.splice(index, 1);
            this.listaDetalles = [...this.listaDetalles];
          });
        } else {
          this.listaDetalles.splice(index, 1);
          this.listaDetalles = [...this.listaDetalles];
        }
      }
    })
  }

  deleteDetail(id: number, cb: any) {
    const params = new HttpParams().set("id", id);
    this.http.get<void>(`${this.baseUrl}/CuentasPorCobrar/EliminarDetalleChequePosfechado`, { params }).subscribe(() => { cb(); })
  }

  searchCustomer(clave: string) {
    const t: any = this.txtCliente;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.baseService.getClientePorClave(this.info.empresa!.numero, Number(clave), false).subscribe((cliente) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (cliente) {
        this.myForm.controls["Cliente"].setValue(cliente);
        t.tagInput.nativeElement.value = cliente.Clave;
      } else {
        Swal.fire({ text: "No se encontró el Cliente indicado.", icon: 'error', }).then(() => {
          t.tagInput.nativeElement.value = "";
          t.tagInput.nativeElement.focus();
        });
      }
    })
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    console.log(items)
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {

    const ent = this.myForm.value;
    const details = this.listaDetalles.filter(P => P.Folio > 0);

    if (details.length == 0) {
      Swal.fire({ icon: 'error', text: 'Debe de indicar los Documentos relacionados.' }).then(() => { });
      param.callback(false);
      return;
    }

    this.myForm.controls["Detalle"].setValue(details);
    // if ((ent.Retiene && ent.Traslada) || (!ent.Retiene && !ent.Traslada)) {
    //   Swal.fire({ icon: 'error', text: 'Solo indique una opción, Traslada o Retiene' }).then(() => { });
    //   param.callback(false);
    //   return;
    // }


    param.callback(true);
  }

  selectedItem(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

  onItemSearched(entity: ChequePosfechado): void {

    if (entity) {
      if (entity.Id == 0) {
        entity.FechaEmision = new Date();
        entity.FechaAplicacion = new Date();
        this.resetSource([]);
      } else {
        this.resetSource(entity.Detalle);
        const t: any = this.txtCliente;
        t.tagInput.nativeElement.value = entity.Cliente.Clave;
      }
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: ChequePosfechado) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {

    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    const t: any = this.txtCliente;
    t.tagInput.nativeElement.value = '';
    this.containerService.getEmptyEntity("ChequePosfechado").subscribe((elem) => {
      elem.Clave = next;
      elem.FechaEmision = new Date();
      elem.FechaAplicacion = new Date();
      this.resetSource(elem.Detalle);
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }
  get getSelected(): ChequePosfechadoDetalle {
    return { ...this.selectedItemDetalle! };
  }
  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }


  onClickBarButton(button: string): void {
    switch (button) {
      case "print": this.imprimir(); break;
    }
  }


  imprimir(){

    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'RELACIÓN DE CHEQUES CON FOLIO DE CAPTURA '  + this.myForm.get('Folio')?.value ,
      Dato1: '',
      Opc1:true
    }
    let filtro: FilterOptions | undefined = this.filtroCajaChica.FilterOptions?.find(p => p.Campo == 'cheq.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroCajaChica.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroCajaChica, '/CuentasPorCobrar/ReporteChequesPostFechados');


  }


}
