<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-list-check" [activeButtons]="activeButtons"
  [setIsNew]="esNuevo" (onClickBarButton)="onClickBarButton($event)"
  subTitle="Emisión de Constancias de Pagos y Retenciones">
  <div class="screen-content">
    <div>
      <form [formGroup]="myForm" autocomplete="off">
        <div class="card border-0 mb-0" style="height: 20px;background-color: var(--bs-app-header-input-bg);">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-2" style="text-align: left;">
                <div class="mb-0 d-md-flex fw-bold">
                  <div class="ms-md-0 mt-md-0 mt-0 dropdown-toggle">
                    <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Archivos <b
                        class="caret"></b></a>
                    <div class="dropdown-menu">
                      <a (click)="downloadFiles()" class="dropdown-item">Descargar XML y PDF</a>
                      <a class="dropdown-item">Envíar por correo</a>
                    </div>
                  </div>
                  <div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                    <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Acciones <b
                        class="caret"></b></a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" (click)="openCfdiRelations()">Relaciones con otros CFDI</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div style="width: 610px;">
            <app-panel-base title="Información General de la Constancia" [style]="'width:600px;height:220px;'">
              <div class="panel-content">
                <div class="d-flex">
                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Empresa"
                    [enabled]="false" [topLabel]="true" label="Empresa" [inputProperty]="'Clave'" [zeroMask]="3"
                    [entityName]="'Empresa'" [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12"
                    [widthRightColumns]="0" />

                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Serie"
                    [enabled]="listaSeries.length>0" [setItemsList]="getItemsSerie" [enabled]="true" [topLabel]="true"
                    label="Serie" [inputProperty]="'Serie'" [zeroMask]="3" [tabIndex]="3" [widthLabelColumns]="12"
                    [widthTextColumns]="12" [widthRightColumns]="0" #cboSerie />

                  <components-ui-number-box [required]="true" style="width:15%; margin-right: 5px;" [topLabel]="true"
                    label='Folio' formControlName="Folio" [tabIndex]="4" [widthLabelColumns]="12"
                    [widthTextColumns]="12" #txtFolio (blur)="blurFolio($event)" />

                  <component-ui-combo-box [required]="true" style="width:15%;" formControlName="Receptor"
                    [topLabel]="true" label="Receptor" (onSelectedItem)="onSelectedItem($event,'Receptor')"
                    [zeroMask]="3" [tabIndex]="5" [widthLabelColumns]="12" [widthTextColumns]="12"
                    [widthRightColumns]="0" entityName="ReceptorRetencion" #cboReceptor />
                  <span style="width:40%;margin-top: 20px;padding-left: 12px;" *ngIf="myForm.value.Receptor?.Id>0">
                    {{myForm.value.Receptor.Nombre | uppercase}}
                  </span>
                </div>
                <div class="card-header h6 mb-1 bg-none p-2 text-orange mt-3">
                  <i class="fa fa-calendar-check fa-lg fa-fw me-1"></i>
                  Periodo que ampara la Constancia y Complemento a capturar
                </div>
                <div class="d-flex">
                  <div style="width: 30%;">
                    <components-ui-number-box [required]="true" [maxLength]="4" [label]="'Ejercicio'"
                      formControlName="Ejercicio" [tabIndex]="6" [widthTextColumns]="5" [widthLabelColumns]="7" />
                    <components-ui-number-box [required]="true" [maxLength]="2" [label]="'Mes inicial'"
                      formControlName="MesInicial" [tabIndex]="7" [widthTextColumns]="5" [widthLabelColumns]="7" />
                    <components-ui-number-box [required]="true" [maxLength]="2" [label]="'Mes final'"
                      formControlName="MesFinal" [tabIndex]="8" [widthTextColumns]="5" [widthLabelColumns]="7" />
                  </div>
                  <div style="width: 70%;">
                    <component-ui-combo-box (onSelectedItem)="onSelectedItem($event,'Retencion')" [widthList]="650"
                      formControlName="Retencion" [required]="true" label="Cve Retención" [zeroMask]="0" [tabIndex]="9"
                      [widthLabelColumns]="4" [widthTextColumns]="3" [widthRightColumns]="5" entityName="c_CveRetenc" />

                    <component-ui-combo-box [required]="true"
                      (onSelectedItem)="onSelectedItem($event,'TipoComplemento')" [widthList]="650"
                      formControlName="TipoComplemento" label="Complemento" [zeroMask]="0" [tabIndex]="10"
                      [widthLabelColumns]="4" [widthTextColumns]="3" [widthRightColumns]="5"
                      entityName="TipoComplementoRetencion" />

                  </div>
                </div>
              </div>
            </app-panel-base>
          </div>
          <div style="width: 240px;">
            <app-panel-base title="Otra información" [style]="'width:240px;height:220px;'">
              <div class="panel-content d-flex" style="flex-direction:column;">
                <components-ui-date-box [enabled]="false" formControlName="FechaEmision" label="Emisión"
                  [tabIndex]="99999" [widthLabelColumns]="5" [widthTextColumns]="6" />
                <components-ui-date-box *ngIf="myForm.value.FechaCancelacion" [enabled]="false"
                  formControlName="FechaCancelacion" label="Cancelación" [tabIndex]="99999" [widthLabelColumns]="5"
                  [widthTextColumns]="6" />
                <div class="btn btn-sm btn-white mb-10px me-2 mt-2"
                  *ngIf="myForm.value.Id>0 && !myForm.value.FechaCancelacion"><i
                    class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                <div class="btn btn-sm btn-white mb-10px me-2" (click)="cancelar()"
                  *ngIf="myForm.value.Id>0 && !myForm.value.FechaCancelacion"><i
                    class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>

                <div class="btn btn-sm btn-white mb-10px me-2 mt-5"
                  *ngIf="myForm.value.Id>0 && myForm.value.FechaCancelacion"><i
                    class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelado</div>
              </div>
            </app-panel-base>
          </div>
        </div>

        <app-panel-base title="Captura de Complemento Seleccionado" style="width: 850px;">
          <div class="panel-content">
            <div *ngIf="myForm.value.TipoComplemento?.Id != 2" class="alert alert-danger fade show">No ha seleccionado
              un Complemento a capturar o el complemento seleccionado no ha sido habilitado</div>
            <app-panel-base title="Dividendos" *ngIf="myForm.value.TipoComplemento?.Id == 2">
              <div class="panel-content">
                <form [formGroup]="myFormDividendo" autocomplete="off">
                  <div class="d-flex">
                    <div style="width: 50%;">
                      <component-ui-combo-box (onSelectedItem)="onSelectedItemDiv($event,'TipoDividendoUtilidad')"
                        formControlName="TipoDividendoUtilidad" label="Tipo Dividendo" [zeroMask]="0" [tabIndex]="11"
                        [widthLabelColumns]="5" [required]="true" [widthTextColumns]="2" [widthRightColumns]="5"
                        entityName="TipoDividendoUtilidad" />

                      <components-ui-number-box [formatoMoneda]="true" [maxLength]="22" (blur)="calcularRet($event)"
                        [label]="'ISR Acred Ret México'" formControlName="MontISRAcredRetMexico" [tabIndex]="12"
                        [widthTextColumns]="5" [widthLabelColumns]="5" />

                      <components-ui-number-box [formatoMoneda]="true" [maxLength]="22" (blur)="calcularRet($event)"
                        [label]="'ISR Acred Ret Ext'" formControlName="MontISRAcredRetExtranjero" [tabIndex]="13"
                        [widthTextColumns]="5" [widthLabelColumns]="5" />

                      <component-ui-combo-box (onSelectedItem)="onSelectedItemDiv($event,'TipoSociedad')"
                        formControlName="TipoSociedad" label="Tipo Sociedad" [zeroMask]="0" [tabIndex]="14"
                        [widthLabelColumns]="5" [required]="true" [widthTextColumns]="2" [widthRightColumns]="5"
                        entityName="TipoSociedad" />

                    </div>
                    <div style="width: 50%;">

                      <components-ui-number-box [formatoMoneda]="true" [maxLength]="22" [label]="'Retención Extranjero'"
                        formControlName="RetencionExtranjero" [tabIndex]="15" [widthTextColumns]="5"
                        [widthLabelColumns]="5" />

                      <components-ui-number-box [formatoMoneda]="true" [maxLength]="22" [label]="'ISR Acred Nacional'"
                        formControlName="MontISRAcredNal" [tabIndex]="16" [widthTextColumns]="5"
                        [widthLabelColumns]="5" />

                      <components-ui-number-box [formatoMoneda]="true" [maxLength]="22"
                        [label]="'Dividendo Acum Nacional'" formControlName="MontDivAcumNal" [tabIndex]="17"
                        [widthTextColumns]="5" [widthLabelColumns]="5" />

                      <components-ui-number-box [formatoMoneda]="true" [maxLength]="22" [label]="'Dividendo Acum Ext'"
                        formControlName="MontDivAcumExtranjero" [tabIndex]="18" [widthTextColumns]="5"
                        [widthLabelColumns]="5" />

                    </div>
                  </div>
                </form>
              </div>
            </app-panel-base>
          </div>
        </app-panel-base>
        <app-panel-base title="Totales" style="width: 850px;">
          <div class="panel-content">
            <div class="d-flex">
              <div style="width: 50%;">
                <component-ui-combo-box (onSelectedItem)="onSelectedItem($event,'TipoImpuesto')"
                  formControlName="TipoImpuesto" label="Tipo Impuesto" [zeroMask]="0" [tabIndex]="19"
                  [widthLabelColumns]="4" [required]="true" [widthTextColumns]="2" [widthRightColumns]="5"
                  entityName="c_TipoImpuesto" />

                <component-ui-combo-box (onSelectedItem)="onSelectedItem($event,'TipoPagoRetencion')"
                  formControlName="TipoPagoRetencion" label="Tipo Pago" [zeroMask]="0" [tabIndex]="20"
                  [widthLabelColumns]="4" [required]="true" [widthTextColumns]="2" [widthRightColumns]="5"
                  entityName="c_TipoPagoRet" />
              </div>
              <div style="width: 50%;">
                <components-ui-number-box [formatoMoneda]="true" [maxLength]="20" [label]="'Total Gravado'" (blur)="calcTotal($event)"
                  formControlName="MontoTotGrav" [tabIndex]="21" [widthTextColumns]="4" [widthLabelColumns]="4" />

                <components-ui-number-box [formatoMoneda]="true" [maxLength]="21" [label]="'Total Exento'" (blur)="calcTotal($event)"
                  formControlName="MontoTotExent" [tabIndex]="22" [widthTextColumns]="4" [widthLabelColumns]="4" />

                <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [maxLength]="22" [label]="'Total Retenido'"
                  formControlName="MontoTotRet" [tabIndex]="23" [widthTextColumns]="4" [widthLabelColumns]="4" />

                <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [maxLength]="22" [label]="'Total Operación'"
                  formControlName="MontoTotOperacion" [tabIndex]="24" [widthTextColumns]="4" [widthLabelColumns]="4" />
              </div>
            </div>
          </div>
        </app-panel-base>
        <app-panel-base title="Usuarios" [style]="'width:850px'" *ngIf="myForm.value.Id>0">
          <div class="panel-content">
            <div class="widget-list mb-0" style="display: flex;">
              <div class="widget-list-item" style="width:25%;">
                <div class="widget-list-media">
                  <i class="fas fa-user-check fa-2x text-green"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.UsuarioAlta.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario elaboró</p>
                </div>
              </div>
              <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.UsuarioCancela">
                <div class="widget-list-media">
                  <i class="fas fa-user-xmark fa-2x text-red"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario canceló</p>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>
        <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
          <div class="cancelled">&nbsp;</div>
        </div>
        <ng-template #cfdiRelacionado let-modal let-c="close">
          <app-cfdi-relacionado-page [soloUUID]="true" [idEmpresa]="myForm.value.Empresa.Id" />
        </ng-template>
        <ng-template #modalCancelOptiones let-modal let-c="close">
          <app-opcion-cancelacion-retencion-page  />
        </ng-template>
      </form>
    </div>
  </div>
</app-container-base>
