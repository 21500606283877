<div class="card text-center border-0" cdkDrag
  style="z-index:99;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Indique la fecha y motivo de cancelación
    </span>
  </div>
  <div class="card-body bg-light">
    <app-panel-base title="Cancelar Remisión">
      <div class="panel-content">
        <form [formGroup]="myForm" autocomplete="off">
          <components-ui-date-box [tabIndex]="100" [required]="true" formControlName="Fecha" label="Fecha" [widthLabelColumns]="3"
            [widthTextColumns]="3" />
          <components-ui-text-box [tabIndex]="101" [required]="true" formControlName="MotivoCancelacion" [maxlength]="200" label="Motivo"
            [widthLabelColumns]="3" [widthTextColumns]="9" #txtMotivo />
        </form>
      </div>
    </app-panel-base>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;" *ngIf="!cancelando">
      <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
      <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
    </div>
  </div>
</div>
