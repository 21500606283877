<app-container-base title="Prospectos" icon="fa fa-user-check" [hasCounter]="false" [hasBar]="false" [isMobile]="true"
  subTitle="Registro de Prospectos.">
  <div class="screen-content mb-30px">
    <form [formGroup]="prospectoForm" class="prospecto-frm">
      <app-panel-base title="Información General">
        <div class="panel-content">
          <div class="row mb-20px">
            <div class="col-6 d-grid">
              <button class="btn btn-warning btn-search" type="button" (click)="openSearch('Prospecto')">
                <i class="fas fa-magnifying-glass"></i>
                Buscar Prospecto
              </button>
            </div>
            <div class="col-6 d-grid">
              <button class="btn btn-green btn-search" type="button" (click)="nuevo()">
                <i class="fas fa-plus"></i>
                Registrar Prospecto
              </button>
            </div>
          </div>
          <div class="col-12 d-grid">
            <div class="form-check form-switch pt-5px">
              <input class="form-check-input" id="dadoBaja" type="checkbox" formControlName="dadoBaja" />
              <label class="form-check-label" for="dadoBaja">¿Dado de baja?</label>
            </div>
          </div>

          <div class="d-grid">
            <label class="form-label">Nombre</label>
            <input formControlName="nombre" type="text"
              class="form-control {{prospectoForm.controls['nombre'].invalid ? 'is-invalid':''}}" />
          </div>

          <div class="d-grid">
            <label class="form-label">Propietario</label>
            <input formControlName="propietario" type="text" class="form-control" />
          </div>
          <div class="d-grid">
            <label class="form-label">Correos</label>
            <input formControlName="correos" type="text"
              class="form-control {{prospectoForm.controls['correos'].invalid ? 'is-invalid':''}}" />
          </div>
          <div class="d-grid">
            <label class="form-label">Número móvil</label>
            <input formControlName="numeroMovil" type="text"
              class="form-control {{prospectoForm.controls['numeroMovil'].invalid ? 'is-invalid':''}}" />
          </div>

          <div class="d-grid">
            <label class="form-label">Medio de Entrada</label>
            <div class="input-group">
              <input formControlName="medioEntrada" type="text" readonly
                class="form-control {{prospectoForm.controls['medioEntrada'].invalid ? 'is-invalid':''}}" />
              <button class="btn btn-warning btn-search" type="button" (click)="openSearch('MedioEntradaProspecto')">
                <i class="fas fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
          <div class="d-grid">
            <label class="form-label">Estado</label>
            <div class="input-group">
              <input formControlName="estadoProspecto" type="text" readonly
                class="form-control {{prospectoForm.controls['estadoProspecto'].invalid ? 'is-invalid':''}}" />
              <button class="btn btn-warning btn-search" type="button" (click)="openSearch('EstadoProspecto')">
                <i class="fas fa-magnifying-glass"></i>
              </button>
            </div>
          </div>

          <div class="d-grid">
            <label class="form-label">Fecha Alta</label>
            <input formControlName="fechaAlta" type="text" class="form-control" placeholder="DD/MM/YYYY" />
          </div>

          <div class="d-grid">
            <label class="form-label">Observaciones</label>
            <textarea formControlName="observaciones"
              class="form-control observaciones {{prospectoForm.controls['observaciones'].invalid ? 'is-invalid':''}}"
              rows="3"></textarea>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Información de la Empresa">
        <div class="panel-content">
          <div class="d-grid">
            <label class="form-label">Cargo que desempeña</label>
            <input formControlName="cargo" type="text" class="form-control" />
          </div>
          <div class="d-grid">
            <label class="form-label">RFC</label>
            <input formControlName="RFC" type="text" class="form-control text-uppercase" />
          </div>
          <div class="d-grid">
            <label class="form-label">Razón Social</label>
            <input formControlName="razonSocial" type="text" class="form-control" />
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Dirección">
        <div class="panel-content">
          <div class="row">
            <div class="d-grid col-6">
              <label class="form-label">País</label>
              <input formControlName="pais" type="text" class="form-control" />
            </div>
            <div class="d-grid col-6">
              <label class="form-label">C.P.</label>
              <input formControlName="codigoPostal" type="text" (blur)="searchCP($event)" numbersOnly
                class="form-control [class] {{prospectoForm.controls['codigoPostal'].invalid ? 'is-invalid':''}}" />
            </div>
          </div>
          <div class="d-grid">
            <label class="form-label">Calle</label>
            <input formControlName="calle" type="text" class="form-control" />
          </div>
          <div class="row">
            <div class="d-grid col-6">
              <label class="form-label">Núm. Exterior</label>
              <input formControlName="noExterior" type="text" class="form-control" />
            </div>
            <div class="d-grid col-6">
              <label class="form-label">Núm. Interior</label>
              <input formControlName="noInterior" type="text" class="form-control" />
            </div>
          </div>

          <div class="d-grid">
            <label class="form-label">Colonia</label>
            <input formControlName="colonia" type="text" class="form-control" />
          </div>
          <div class="d-grid">
            <label class="form-label">Ciudad/Localidad</label>
            <input formControlName="ciudad" type="text" class="form-control" />
          </div>
          <div class="d-grid">
            <label class="form-label">Municipio</label>
            <input formControlName="municipio" type="text" class="form-control" />
          </div>
          <div class="d-grid">
            <label class="form-label">Estado</label>
            <div class="input-group">
              <input formControlName="estado" type="text" class="form-control" />
              <button class="btn btn-warning btn-search" type="button" (click)="openSearchEstados()">
                <i class="fas fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
          <div class="d-grid">
            <label class="form-label">Teléfono</label>
            <input formControlName="telefono" type="text" class="form-control" />
          </div>
          <div class="d-grid">
            <label class="form-label">Referencias</label>
            <textarea formControlName="referencias" class="form-control observaciones" rows="3"></textarea>
          </div>
        </div>
      </app-panel-base>

      <button type="button" (click)="guardar()" class="btn btn-blue btn-float-bottom btn-lg"
        [disabled]="prospectoForm.invalid">
        <i class="fas fa-floppy-disk"></i>
        Guardar Prospecto
      </button>
    </form>
  </div>
</app-container-base>


<ng-template #ctrlBusquedaModal let-modal let-c="close">
  <app-data-search [isMobile]="true" titulo="Búsqueda de Prospectos" [columns]="getColumns"
    [entityName]="entidadBusqueda" [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'"
    #ctrlBusqueda />
</ng-template>




<ng-template #ctrlCodigoPostal let-modal let-c="close">
  <div class="data-search card border-1 animate__animated animate__faster animate__fadeInDownBig">
    <div style="cursor: move;" cdkDragHandle class="card-header text-center">
      <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
      Consulta de Códigos Postales
    </div>
    <div class="card-body bg-light p-0">
      <ng-scrollbar [style]="scrollbarStyle">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngIf="searchingCP">Cargando registros...</li>

          <li class="list-group-item cursor-pointer" *ngIf="!searchingCP && cpList.length === 0" matRipple>
            No se encontró nungún registro con el Código postal <b>{{entidad.Direccion.CodigoPostal}}</b>
          </li>
          <li (click)="selectCP(cp)" class="list-group-item cursor-pointer" *ngFor="let cp of cpList" matRipple>
            {{cp.d_tipo_asenta + " " + cp.d_asenta + " " + cp.d_ciudad + " " + cp.d_estado}}</li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>
</ng-template>


<ng-template #ctrlEstados let-modal let-c="close">
  <div class="data-search card border-1 animate__animated animate__faster animate__fadeInDownBig">
    <div style="cursor: move;" cdkDragHandle class="card-header text-center">
      <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
      Estados de la República
    </div>
    <div class="card-body bg-light p-0">
      <ng-scrollbar [style]="scrollbarStyle">
        <ul class="list-group list-group-flush">
          <li (click)="selectEstado(e)" class="list-group-item cursor-pointer" *ngFor="let e of estadosRepublica"
            matRipple>
            {{e.Nombre}}</li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>
</ng-template>
