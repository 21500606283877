import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ConfiguracionService } from '../../../configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import Swal from 'sweetalert2';
import { TipoImpuesto } from '../../interfaces/tipodeimpuesto.interface';
import { Impuesto } from '../../interfaces/impuesto.interface';

@Component({
  selector: 'app-impuestos-page',
  templateUrl: './impuestos-page.component.html',
  styles: [
  ]
})
export class ImpuestosPageComponent {


  public itemsCombo: ComboBoxEntity[] = [];

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    IVATrasladado: [{} as TipoImpuesto],
    IncluidoEnPrecioIvaTrasladado: false,
    IEPS: [{} as TipoImpuesto],
    IncluidoEnPrecioIEPS: false,
    IVARetenido: [{} as TipoImpuesto],
    IncluidoEnPrecioIVARetenido: false,
    ISRRetenido: [{} as TipoImpuesto],
    IncluidoEnPrecioISRRetenido: false,
    ImpuestoLocalTrasladado: [{} as TipoImpuesto],
    ImpuestoLocalRetenido1: [{} as TipoImpuesto],
    ImpuestoLocalRetenido2: [{} as TipoImpuesto],
    ImpuestoLocalRetenido3: [{} as TipoImpuesto],
    ImpuestoLocalRetenido4: [{} as TipoImpuesto],
    ImpuestoLocalRetenido5: [{} as TipoImpuesto],
    ImpuestoLocalRetenido6: [{} as TipoImpuesto],
    ImpuestoLocalRetenido7: [{} as TipoImpuesto],
    ImpuestoLocalRetenido8: [{} as TipoImpuesto],
    ImpuestoLocalRetenido9: [{} as TipoImpuesto],
    ImpuestoLocalRetenido10: [{} as TipoImpuesto],
    Baja: false,
  })

  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService,) {
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {

    const ent = this.myForm.value;
    // if ((ent.Retiene && ent.Traslada) || (!ent.Retiene && !ent.Traslada)) {
    //   Swal.fire({ icon: 'error', text: 'Solo indique una opción, Traslada o Retiene' }).then(() => { });
    //   param.callback(false);
    //   return;
    // }
    param.callback(true);
  }

  onItemSearched(entity: Impuesto): void {
    if (entity) {
      this.myForm.reset(entity);
      console.log(entity)
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: Impuesto) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Impuesto").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  selectedIVA(entity: ComboBoxEntity) {
    this.myForm.controls["IVATrasladado"].setValue(entity);
  }

  selectedIVARet(entity: ComboBoxEntity) {
    this.myForm.controls["IVARetenido"].setValue(entity);
  }

  selectedISRRet(entity: ComboBoxEntity) {
    this.myForm.controls["ISRRetenido"].setValue(entity);
  }

  selectedIEPS(entity: ComboBoxEntity) {
    this.myForm.controls["IEPS"].setValue(entity);
  }

  selectedImpLocal(entity: ComboBoxEntity) {
    this.myForm.controls["ImpuestoLocalTrasladado"].setValue(entity);
  }

  selectedImpLocalRet(entity: ComboBoxEntity, num: number) {
    this.myForm.controls[`ImpuestoLocalRetenido${num}`].setValue(entity);
  }


}
