import { Component, ElementRef, Inject, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterListItem, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-cargos-abonos-cxp',
  templateUrl: './verificador-cargos-abonos-cxp.component.html',
  styles: [
  ]
})
export class VerificadorCargosAbonosCXPComponent {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;


  private utils = inject(UtilsService);

  listapoliza: ReportFilterListItem[] = [];
  menuId: string = "verificadorcargosabonoscxp";

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Tipo movimiento-Fecha', Orden: 'movcxc.Clave,cab.fecha' }]

  public filtrosDiarioVentas: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificados de cargos y abonos',
      NombreExcel: 'Verificados de cargos y abonos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'movcxc.Clave', Etiqueta: 'Tipo movimiento', Tipo: TypeFilter.entityNumber, Entidad: "TipoMovimientoCxp" },
        { Campo: 'cta.Clave', Etiqueta: 'Cuenta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxp" },
        { Campo: 'prov.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'conc.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad:"ConceptoCxp"  },
        { Campo: 'SerieString', Etiqueta: 'Serie', Tipo: TypeFilter.text },
        { Campo: 'vta.Folio', Etiqueta: 'Folio', Tipo: TypeFilter.number },
        { Campo: '(det.SerieAfecta + convert(varchar(100),det.FolioAfecta))', Etiqueta: 'Comprobante afecta', Tipo: TypeFilter.text },
        { Campo: 'IIF(det.ImporteCargo>0,det.ImporteCargo,det.ImporteAbono)', Etiqueta: 'Importe', Tipo: TypeFilter.number },
        { Campo: 'convert(int,fpago.Id)', Etiqueta: 'Forma de pago', Tipo: TypeFilter.number },
        { Campo: 'pag.CtaBeneficiario', Etiqueta: 'Número de cuenta de pago', Tipo: TypeFilter.text }
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE MOVIMIENTOS DE CUENTAS POR PAGAR ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE MOVIMIENTOS DE CUENTAS POR PAGAR ' + this.rangoFechas);
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }


  get listaTipoPoliza() {
    return this.listapoliza;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }

  clickBoton(event: string) {
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/CuentasPorPagar/ReporteCargosyAbonosCXP');
  }

}
