<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="frmCorteCaja" [hasCounter]="false"
  [hasBar]="false" title="Prospectos" icon="fa fa-user-check" subTitle="Corte de Caja">
  <div class="screen-content">
    <form class="frmCorteCaja" [formGroup]="frmCorteCaja" autocomplete="off">
      <app-panel-base title="General">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-2">
              <button type="button" class="btn btn-primary btn-lg mb-10px" (click)="seleccionarCorte()">
                <i class="fa fa-cash-register"></i>
                Seleccionar Corte
              </button>
              <component-ui-combo-box label="Cuenta de Depósito" inputProperty="Nombre" [zeroMask]="3" [tabIndex]="1"
                [topLabel]="true" [widthLabelColumns]="12" [widthTextColumns]="12"
                (onSelectedItem)="onSelectedItem($event,'DocumentoVenta')" [entityName]="'CuentaBancaria'" />
            </div>
            <div class="col">
              <div class="note alert-primary w-50">
                <div class="note-icon"><i class="fas fa-box-archive"></i></div>
                <div class="note-content">
                  <h4><b>Corte de caja</b></h4>
                  <p class="fs-15px">26/08/2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <div class="row m-0">
        <div class="col p-0">
          <app-panel-base title="Ingresos Caja">
            <div class="panel-content">
              <components-ui-text-box [enabled]="false" label="Efectivo" [enableLink]="true"
                (onClicLink)="openConteoEfectivo()" formControlName="Efectivo" [tabIndex]="1" [widthTextColumns]="7"
                [widthLabelColumns]="5" />
              <components-ui-text-box [enabled]="false" label="Terminales" formControlName="Terminales" [tabIndex]="1"
                [enableLink]="true" (onClicLink)="openTerminales()" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-text-box [enabled]="false" label="Cheques" formControlName="Cheques" [tabIndex]="1"
                [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-text-box [enabled]="false" label="Transferencias" formControlName="Transferencias"
                [tabIndex]="1" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-text-box [enabled]="false" label="Retiros de Caja" formControlName="RetirosCaja"
                [enableLink]="true" (onClicLink)="openRetirosCaja()" [tabIndex]="1" [widthTextColumns]="7"
                [widthLabelColumns]="5" />
              <hr class="my-3">
              <components-ui-text-box [enabled]="false" label="Fondo Fijo" formControlName="FondoFijo" [tabIndex]="1"
                [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-text-box [enabled]="false" label="Entregado" formControlName="Entregado" [tabIndex]="1"
                [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-text-box [enabled]="false" label="Facturas Pagadas de Otros Días"
                formControlName="FacturasOtrosDias" [tabIndex]="1" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <div class="widget widget-stats bg-green-600 mb-0 p-8px">
                <div class="stats-icon stats-icon-lg">
                  <i class="fa fa-cash-register fs-70px" style="top:-19px"></i>
                </div>
                <div class="stats-content">
                  <div class="stats-number m-0 fs-20px">TOTAL CAJA</div>
                  <div class="stats-progress progress m-0 w-50">
                    <div class="progress-bar" style="width: 0;"></div>
                  </div>
                  <div class="stats-number fs-20px m-0">$0.00</div>
                </div>
              </div>

            </div>
          </app-panel-base>
        </div>
        <div class="col p-0">
          <app-panel-base title="Ventas Contado">
            <div class="panel-content">
              <components-ui-text-box [enabled]="false" label="Ventas Sin Anticipos" formControlName="VentasSinAnticipo"
                [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" [label]="labelAnticipoFacturado"
                formControlName="AnticipoFacturado" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" [label]="labelAnticipoAplicado"
                formControlName="AnticipoAplicado" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" [label]="labelNotasCreditoContado"
                formControlName="NotasCreditoContado" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" label="TOTAL CONTADO" formControlName="TotalContado"
                [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
            </div>
          </app-panel-base>
          <app-panel-base title="Ventas Crédito">
            <div class="panel-content">
              <components-ui-text-box [enabled]="false" label="Ventas Crédito" formControlName="VentasCredito"
                [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" [label]="labelAnticiposAplicados"
                formControlName="AnticiposAplicados" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" [label]="labelNotasCreditoCredito"
                formControlName="NotasCreditoCredito" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" label="TOTAL CRÉDITO" formControlName="TotalCredito"
                [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-text-box [enabled]="false" label="TOTAL DECLARAR" formControlName="TotalDeclarar"
                [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>
</app-container-base>
<ng-template #modalCorteCaja let-modal let-c="close">
  <div class="data-search card border-1 animate__animated animate__faster animate__fadeInDownBig">
    <div class="card-header">
      <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
      <h5>Selección de Corte de Caja</h5>
    </div>
    <div class="card-body bg-light">
      <component-ui-combo-box label="Caja" inputProperty="Nombre" [zeroMask]="3" [tabIndex]="1" [widthLabelColumns]="4"
        [widthTextColumns]="8" (onSelectedItem)="onSelectedItem($event,'DocumentoVenta')" [entityName]="'Caja'" />
      <components-ui-date-box label="Fecha" [widthLabelColumns]="4" [widthTextColumns]="6" />
      <button type="button" class="btn btn-primary float-end" (click)="buscarCorte()">
        <i class="fa fa-magnifying-glass"></i>
        Buscar
      </button>
    </div>
  </div>
</ng-template>


<ng-template #modalConteoEfectivo let-modal let-c="close">
  <conteo-efectivo></conteo-efectivo>
</ng-template>

<ng-template #modalRetirosCaja let-modal let-c="close">
  <retiros-caja></retiros-caja>
</ng-template>

<ng-template #modalTerminales let-modal let-c="close">
  <terminales></terminales>
</ng-template>
