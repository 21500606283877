<div class="d-lg-block d-none"><a data-bs-toggle="dropdown"
    class="btn btn-white d-flex align-items-center rounded-1">
    <i
      class="fa fa-lg fa-{{icon}} me-10px text-body text-opacity-50">
    </i>
     {{label}}
     <i class="fa ms-auto fa-chevron-down"></i></a>
  <ul class="dropdown-menu" >
    <li *ngFor="let item of items">
      <a class="dropdown-item d-flex align-items-center">
        <i class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i>{{item}}
      </a>
    </li>
  </ul>
</div>
