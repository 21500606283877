<ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;">
  <div class="pt-2 pb-2 text-start" style="width:100%;">
    <a (click)="agregarEditar(-1)" class="btn btn-success btn-rounded px-4 rounded-pill"><i
        class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>Agregar</a>
  </div>
  <table class="blueTable">
    <thead style="position: sticky;top:0;">
      <tr>
        <th style="width: 30px;"></th>
        <th style="width: 30px;"></th>
        <th style="width: 150px;">Póliza</th>
        <th style="width: 230px;">Código Contable</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of Asientos; let i = index">
        <td style="width: 30px;text-align:center;"><i (click)="deleteRow(i)" class="fas fa-circle-xmark text-red" style="cursor: pointer;"></i></td>
        <td style="width: 30px;text-align:center;"><i (click)="agregarEditar(i)" class="fas fa-pencil text-yellow" style="cursor: pointer;"></i></td>
        <td style="width: 150px;">{{item.Poliza.Nombre}}</td>
        <td style="width: 230px;">{{item.CodigoContable}}</td>
      </tr>
    </tbody>
  </table>
</ng-scrollbar>

<ng-template #edicionAsiento let-modal let-c="close">
  <app-ctrl-asiento-contable-edicion [item]="itemEdicion" />
</ng-template>
