<app-container-base [hasCounter]="false" [hasBar]="false" icon="fa fa-cloud-arrow-down"
  subTitle="Solicitar descarga de XMLS al SAT, descarga hasta 2,000 por día">
  <div class="screen-content" style="height: 100%; width:1150px;">
    <form [formGroup]="myForm" autocomplete="off">
      <!-- <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation"><a href="#solicitud" data-bs-toggle="tab" class="nav-link active"
            aria-selected="true" role="tab"><span class="d-sm-none">Tab 1</span><span
              class="d-sm-block d-none">Solicitar Descarga</span></a></li>
      </ul> -->
      <div class="tab-content panel rounded-0 p-3 m-0">
        <div id="solicitud" class="tab-pane fade active show" role="tabpanel">
          <h3 class="mt-10px"><i class="fa fa-calendar-check green"></i>
            Solicitar descargas de XML recibidos por periodo</h3>
          <p>COMPLIT se conecta al SAT, para esto debe de tener registrada la clave CIEC en el catálogo de Empresas, al
            solicitar
            la descarga, COMPLIT solicitará que ingrese el texto mostrado en el captcha que el SAT solicita, una vez
            ingresado correctamente
            se hará la descarga de los XMLS Recibidos.</p>
          <hr>
          <div class="form-group mb-2">
            <div id="inProgressBoard" class="collapse show">
              <div class="form-check mb-1">
                <i class="fa fa-lg text-green fa-circle-check me-2 "></i><label>Solo se descargan hasta
                  2,000 XMLS diarios</label>
              </div>
              <div class="form-check"><i class="fa fa-lg text-green fa-circle-check me-2 "></i><label>No se descargan
                  XMLS
                  cancelados</label></div>
              <div class="form-check"><i class="fa fa-lg text-green fa-circle-check me-2 "></i><label>Se descargan
                  XMLS de los
                  tipos: Ingreso, Egreso, Traslado, Pago</label>
              </div>
            </div>
          </div>
          <hr>
          <app-panel-base title="Información de la solicitud de descarga (Recibidos)">
            <div class="panel-content">
              <components-ui-date-box [helpLine]="'Fecha Inicial en la que se van a consultar los XMLS en el SAT.'"
                [enabled]="!saving" label='Del' formControlName="FechaInicio" [tabIndex]="1" [widthLabelColumns]="2"
                [widthTextColumns]="3" [required]="true" #txtFechaInicio />

              <components-ui-date-box [helpLine]="'Fecha Final en la que se van a consultar los XMLS en el SAT.'"
                [enabled]="!saving" label='Al' formControlName="FechaFin" [tabIndex]="2" [widthLabelColumns]="2"
                [widthTextColumns]="3" [required]="true" />

              <components-ui-text-box [enabled]="!saving"
                helpLine="RFC del Proveedor del cual queremos descargar sus XMLS, tal cual está en la constancia de situación fiscal, 12-13 caracteres"
                [tabIndex]="3" [maxLength]="13" [upperCase]="true" [label]="'RFC Proveedor'" [dataType]="EDT.RFC"
                formControlName="RfcEmisor" [widthTextColumns]="2" [widthLabelColumns]="2" />

              <components-ui-text-box [enabled]="!saving" (keydown.enter)="solicitar()"
                helpLine="UUID de CFDI a buscar, solo se descarga uno solo, 36 caracteres." [tabIndex]="4"
                [maxLength]="40" [upperCase]="true" [label]="'UUID del CFDI'" formControlName="UUID"
                [widthTextColumns]="3" [widthLabelColumns]="2" />


              <div (click)="solicitar()" *ngIf="!saving" class="btn btn-green" style="margin-left: 170px;width:195px;">
                <i class="fa fa-circle-play"></i>
                Solicitar descarga
              </div>
            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>
</app-container-base>
