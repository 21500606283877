<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="Caja" title="Cajas POS" icon="fa fa-angles-down"
  subTitle="Administra la configuración de Cajas para el Punto de Venta">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Caja'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="Caja" />
          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="2"
            [widthLabelColumns]="2" [required]="true" #txtNombre />

          <component-ui-combo-box [label]="'Sucursal'" formControlName="Sucursal" [tabIndex]="3"
            [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedItem($event, 'Sucursal')" [listProperty]="'ClaveNombre'" />

          <components-ui-number-box [tabIndex]="4" [label]="'Fondo fijo'" formControlName="FondoFijo"
            [widthTextColumns]="2" [widthLabelColumns]="2" [required]="true" />

          <components-ui-text-box [tabIndex]="5" [label]="'Código Cajero SEGURITEC'" formControlName="CodigoCajero" [widthTextColumns]="2"
            [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
