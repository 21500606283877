import { ComboBoxEntity } from './../../../component-ui/interfaces/combo-text.interface';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';



import { CuentasPorCobrarService } from '../../services/cxc.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './reporte-estado-de-cuenta-cxc.component.html',
  selector: 'reporte-estado-de-cuenta-cxc',
  styleUrls: ['./reporte-estado-de-cuenta-cxc.component.css']

})
export class ReporteEstadoDeCuentaCXCComponent implements OnInit {

  private utilsService = inject(UtilsService);
  private fb = inject(FormBuilder);
  private mService = inject(ModalService);
  private cxcService = inject(CuentasPorCobrarService);
  private reportsService = inject(ReportsService);


  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Opc1: false,
    Num1: 0,
    Num2: 0,
    Num3: 0,
    Num4: 0,
    Num5: 0,
    Num6: 0,
    Num7: 0,
    Num8: 0,
    Num9: 0,
  });


  @Input()
  idCuenta: any;

  @Input()
  idCliente: any;

  @Input()
  anio: ComboBoxEntity = {} as ComboBoxEntity;






  listaMeses: any[] = [];
  listaordenamiento: any[] = [{ Id: 1, Clave: 1, Nombre: "Poliza-Serie" },
  { Id: 2, Clave: 2, Nombre: "Fecha" }]

  public filtrosReporte: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Estado de cuenta de CXC',
      NombreExcel: 'Estado de cuenta de CXC.xlsx'
    };

  checkTodoElAnio: boolean = false;

  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;

  ordenamientoSeleccionado?: ComboBoxEntity;



  get getMes1(): ComboBoxEntity | null {
    return this.mes1Seleccionado!;

  }

  get getMes2(): ComboBoxEntity | null {
    return this.mes2Seleccionado!;
  }


  get getOrdenamiento(): ComboBoxEntity | null {
    return this.ordenamientoSeleccionado!;
  }

  get rangoMeses(): string {
    return this.utilsService.getEtiquetaRangoPorFechas(this.myForm.value.Num1, this.myForm.value.Num2, 1).toUpperCase();
  }


  ngOnInit(): void {

    //this.myForm.get('NombreReporte')?.setValue('Estado de cuenta');
    this.listaMeses = this.utilsService.getListaMeses();
    // this.listaordenamiento = this.getListaOrdenamiento();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
    this.myForm.get('Num1')?.setValue(mesItem.Clave);
    this.myForm.get('Num2')?.setValue(mesItem.Clave);
    const ordenActual = this.listaordenamiento.find(p => p.Clave == 1);
    this.ordenamientoSeleccionado = ordenActual;
    this.myForm.get('Num3')?.setValue(ordenActual.Id);
    this.myForm.get('Num7')?.setValue(this.idCliente.Id);
    this.myForm.get('Num8')?.setValue(this.anio.Id);
    this.myForm.get('Num9')?.setValue(this.idCuenta);
    this.myForm.get('NombreReporte')?.setValue('ESTADO DE CUENTA DE CUENTAS POR COBRAR ' + this.rangoMeses);

  }

  changeOpc(value: boolean) {
    this.checkTodoElAnio = value;
  }


  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Num1') {
      this.mes1Seleccionado = entity;
    } else if (field == 'Num2') {
      this.mes2Seleccionado = entity;
    } else if (field == 'Num3') {
      this.ordenamientoSeleccionado = entity;
    }
    this.myForm.controls[field].setValue(entity.Clave);
    this.myForm.get('NombreReporte')?.setValue('ESTADO DE CUENTA DE CUENTAS POR COBRAR ' + this.rangoMeses);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  accept() {
    if (!this.myForm.value.Opc1) {
      if (this.myForm.value.Num1 > this.myForm.value.Num2) {
        Swal.fire({ icon: 'info', text: 'Debe especificar un rango de meses válido.' });
        return;
      }
    } else {
      this.myForm.get('Num1')?.setValue(1);
      this.myForm.get('Num2')?.setValue(12);
    }



    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/CuentasPorCobrar/ReporteEstadoCuentaPorCliente');
  }




}
