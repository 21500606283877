import { Component, OnDestroy, ViewEncapsulation, OnInit } from '@angular/core';

import 'lity';
import { AppSettings } from '../../../services/app-settings.service';

@Component({
  selector: 'extra-profile',
  templateUrl: './extra-profile.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./extra-profile.css']
})

export class ExtraProfilePage implements OnDestroy, OnInit {
  constructor(public appSettings: AppSettings) {
  }

  userName: string = "usuario";
  ngOnInit() {
    this.appSettings.appContentClass = 'p-0';
    let userStorage = localStorage.getItem("userLogged");

    if (userStorage) {
      let user = JSON.parse(userStorage);
      this.userName = user ? user.nombreUsuario : "";
    }
  }

  ngOnDestroy() {
    this.appSettings.appContentClass = '';
  }
}
