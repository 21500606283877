import { Component, Input, OnInit } from '@angular/core';
import { startOfDay } from 'date-fns';
import { ModalService } from 'src/app/service/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-seleccionar-tree',
  templateUrl: './seleccionar-familia-page.component.html',
  styles: [
  ]
})
export class SeleccionarTreeComponent implements OnInit {

  @Input()
  public type: string = "";
  @Input()
  public onlyLevelOne: boolean = false;
  @Input()
  public multiSelect: boolean = false;

  selectedItem: any = null;
  selectedItems: any[] = [];
  constructor(private mService: ModalService) { }

  ngOnInit(): void {

  }

  onSelectedItemMultiple(items: any[]) {
    this.selectedItems = items;
  }

  onSelectedItem(item: any) {
    this.selectedItem = item;
  }
  accept() {
    if (!this.selectedItem && !this.multiSelect) {
      Swal.fire({ text: "Debe de seleccionar un elemento.", icon: 'info', })
      return;
    }

    if (this.selectedItems.length == 0 && this.multiSelect) {
      Swal.fire({ text: "Debe de seleccionar por lo menos un elemento.", icon: 'info', })
      return;
    }
    if (!this.multiSelect) {
      this.mService.closeModal(this.selectedItem);
    } else {
      this.mService.closeModal(this.selectedItems);
    }
  }

  closeModal() {
    this.mService.closeModal(null);
  }

}
