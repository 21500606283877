import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import Swal from 'sweetalert2';
import { trim } from 'lodash';

@Component({
  selector: 'app-marca-orden-terminada-page',
  templateUrl: './marca-orden-terminada-page.component.html',
  styles: [
  ]
})
export class MarcaOrdenTerminadaPageComponent implements OnInit {

  @Input()
  public idOrden: number = 0;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @ViewChild('text')
  public text!: ElementRef<HTMLElement>;
  private eventsService = inject(EventsService);
  constructor(private fServices: FacturacionService) {
  }

  accept() {
    const t: any = this.text;
    const value = t.nativeElement.value.trim();
    if (value) {
      Swal.fire({
        title: "¿Está seguro de marcar como terminada?",
        showDenyButton: true,
        confirmButtonText: "Terminar",
        denyButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.marcarTerminada(value);
        }
      })
    } else {
      Swal.fire({
        text: "Indique el motivo",
        icon: 'error',
      });
    }
  }

  marcarTerminada(motivo: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.marcarOrdenTerminada(this.idOrden,motivo).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.onClose.emit(true);
      } else {
        Swal.fire({
          text: `Ocurrio un error: ${result.message}`,
          icon: 'error',
        });
      }
    })
  }

  closeModal() {
    this.onClose.emit();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.text.nativeElement.focus();
    }, 150);
  }
}
