import { Empresa } from "src/app/configuracion/interfaces/empresa.interface";
import { Sucursal } from "src/app/configuracion/interfaces/sucursal.interface";
import { Usuario } from "src/app/configuracion/interfaces/usuario.interface";

export interface PresupuestoVentaVendedor {
  Id: number;
  Empresa: Empresa | null;
  Anio: number;
  Periodo: number;
  Nombre: string;
  MetaDelMes: number;
  UsuarioElabora: Usuario
  Detalle: PresupuestoVentaVendedorDetalle[] | null;
  Baja: boolean;
  Eliminado: boolean;
}

export interface PresupuestoVentaVendedorDetalle {
  Id: number;
  Vendedor: Usuario | null;
  Clave?: string;
  Nombre?: string;
  Meta: number;
}

export interface PresupuestoVentaSucursal {
  Id: number;
  Empresa: Empresa | null;
  Anio: number;
  Periodo: number;
  Nombre: string;
  UsuarioElabora: Usuario
  MetaDelMes: number;
  Detalle: PresupuestoVentaSucursalDetalle[] | null;
  Baja: boolean;
  Eliminado: boolean;
}

export interface PresupuestoVentaSucursalDetalle {
  Id: number;
  Sucursal: Sucursal | null;
  Clave?: string;
  Nombre?: string;
  Meta: number;
}

export enum TipoPresupuesto {
  vendedores = 1,
  sucursales = 2,
}


export interface PresupuestoVentaSucursalDTO {
  mes: number;
  sucursal: string;
  presupuesto: number;
  venta: number;
}

export interface PresupuestoVentaVendedorDTO {
  mes: number;
  vendedor: string;
  vendedorId: number;
  presupuesto: number;
  venta: number;
}
