import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { PredefinidoProveedor } from '../../interfaces/predefinidos.interface';
import { ClaseCompra, ClaseProveedor, ClasificacionProveedor, CondicionPagoProveedor, TipoProveedor } from 'src/app/compras/interfaces/clasecompra.interface';
import { c_FormaPago } from 'src/app/ventas/interfaces/formapago.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';

@Component({
  selector: 'app-predefinidos-proveedores-page',
  templateUrl: './predefinidos-proveedores-page.component.html',
  styles: [
  ]
})
export class PredefinidosProveedoresPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: true,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    CondicionDePago: [{} as CondicionPagoProveedor],
    FormaDePago: [{} as c_FormaPago],
    Moneda: [{} as Moneda],
    ClaseCompra: [{} as ClaseCompra],
    Clase: [{} as ClaseProveedor],
    Clasificacion: [{} as ClasificacionProveedor],
    Tipo: [{} as TipoProveedor],
    Empresa: [{} as Empresa],
    Baja: false,

  })

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onItemSearched(entity: PredefinidoProveedor): void {
    if (entity) {
      this.myForm.reset(entity);
      console.log(entity)
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: PredefinidoProveedor) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("PredefinidoProveedor").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }


  selectCombo(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

}
