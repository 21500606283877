import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Empresa } from '../../../interfaces/empresa.interface';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseComponent } from 'src/app/component-ui/components/container-base/container-base.component';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Validators } from 'ngx-editor';
import { validateEvents } from 'angular-calendar/modules/common/util/util';
import { ListaPrecio } from 'src/app/ventas/interfaces/listaprecio.interface';
import { UtilsService } from '../../../../service/utils.service';
import { UserLogged } from 'src/app/auth/interfaces';

@Component({
  selector: 'app-sucursal-page',
  templateUrl: './sucursal-page.component.html',
  styleUrls: ['./sucursal-page.component.css'],
})
export class SucursalPageComponent implements OnInit {

  public itemsCombo: ComboBoxEntity[] = [];
  ActivarDireccion: boolean = false
  filterQuerySucursal: string = "";


  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Nombre: ['', Validators.required],
    Alias: ['', Validators.required],
    Clave: [0],
    Empresa: [{} as Empresa],
    UsaDireccionEmpresa: [false],
    UsarSegundoNombreProducto: [false],
    Encargado: [{} as Usuario],
    UsuarioRecibeTraspaso: [{} as Usuario],
    ListaPrecio: [{} as ListaPrecio],
    Direccion: [{} as Direccion],
    Asientos: []
  })

  usuarioActual!: UserLogged;



  @ViewChild('txtEmpresa')
  public txtEmpresa!: ElementRef<TextBoxComponent>;

  constructor(private fb: FormBuilder, private container: ContainerBaseService, private servicioCongfig: ConfiguracionService, private utilsService: UtilsService) { }
  ngOnInit(): void {

    // this.usuarioActual = this.utilsService.getUserLogged();
    // this.filterQuerySucursal = "Empresa.Id = " + this.usuarioActual.empresa?.numero;

  }

  onItemSearched(entity: Sucursal): void {
    if (entity) {
      this.myForm.reset(entity);
      if (entity.Id == 0) {
        this.container.getLastEntity("Empresa").subscribe(item => {
          this.myForm.get('Empresa')?.setValue(item);
          this.focus("txtNombre");
        })

      }
    }
  }

  getActivarDireccion(): boolean {
    return !this.myForm.controls["UsaDireccionEmpresa"].value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtEmpresa;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onChangueEntity(ent: any) {
    this.myForm.reset(ent);
    this.focus("txtNombre");
  }
  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {
    //Aquí valido mi pantalla, que tenga lo que tenga que tener

    if (this.myForm.controls["UsaDireccionEmpresa"].value) {
      this.myForm.controls["Direccion"].setValue(null);
      console.log("se cambiuo");
    }
    param.callback(true);
  }

  onAfterSave(entity: Sucursal) {
    this.myForm.reset(entity);
  }

  selectUsuario(entity: ComboBoxEntity) {
    this.myForm.controls["UsuarioRecibeTraspaso"].setValue(entity);
  }

  selectEmpresa(entity: ComboBoxEntity) {
    this.myForm.controls["Empresa"].setValue(entity);
  }

  selectEncargado(entity: ComboBoxEntity) {
    this.myForm.controls["Encargado"].setValue(entity);
  }

  selectListaPrecio(entity: ComboBoxEntity) {
    this.myForm.controls["ListaPrecio"].setValue(entity);
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

}
