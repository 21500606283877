import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { EventsService } from 'src/app/service/events.service';
import { ConsignadoProveedor } from '../../interfaces/compra.interface';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-consignado-compra-page',
  templateUrl: './consignado-compra-page.component.html',
  styles: [
  ]
})
export class ConsignadoCompraPageComponent {

  private eventsService = inject(EventsService);
  public GuiColumnAlign = GuiColumnAlign;
  @Input()
  public idProveedor: number = 0;
  @Input()
  public nombreProveedor: string = "";
  idConsignadoEdicion: number =0;
  indexEditing: number=0;

  @ViewChild('modalConisgnado')
  public modalConisgnado!: ElementRef<HTMLElement>;

  lista: ConsignadoProveedor[] = [];
  constructor(private ms: ModalService, private fs: FacturacionService) {

  }

  ngOnInit(): void {
    if (this.idProveedor > 0) {
      this.cargar();
    }
  }

  cargar() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.getConsignadosProveedores(this.idProveedor).subscribe((result) => {
      if (result.success) {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.lista = JSON.parse(result.message);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  edit(index: number) {
    this.idConsignadoEdicion = this.lista[index].Id;
    setTimeout(() => {
        this.addEditConsignado();
    }, 150);
  }

  delete(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: "¿Desea eliminar la Dirección de Entrega?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.fs.deleteConsignadosProveedor(this.lista[index].Id).subscribe((result) => {
            if (result.success) {
              this.lista.splice(index, 1);
              this.lista = [...this.lista];
            } else {
              this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
            }
          })
        }
      }
    });
  }

  addEditConsignado() {
    const b: any = this.modalConisgnado;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.cargar();
      }
      this.idConsignadoEdicion = 0;
    }, 'xl')

  }

  accept() {
    this.ms.closeModal(null);
  }
}
