import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { FacturacionService } from '../../services/facturacion.service';
import * as moment from 'moment';
import { en } from '@fullcalendar/core/internal-common';
import { EventsService } from 'src/app/service/events.service';
import { TipoDeCambio } from '../../interfaces/venta.interface';
import Swal from 'sweetalert2';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';

@Component({
  selector: 'app-tipo-de-cambio-page',
  templateUrl: './tipo-de-cambio-page.component.html',
  styles: [
  ]
})
export class TipoDeCambioPageComponent implements OnInit {
  private eventsService = inject(EventsService);
  menuItems: PanelBaseDropDownMenu[] = [
    { Id: 'dof', Nombre: 'Ver en DOF' },
    { Id: 'tcdof', Nombre: 'Obtener de DOF' },
  ]
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Empresa: [{} as Empresa],
    Fecha: [Date],
    Moneda: [{} as Moneda, Validators.required],
    TipoCambio: [0, Validators.required],
    TipoCambioCompra: [0, Validators.required]
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('cboMoneda')
  public cboMoneda!: ElementRef<HTMLElement>;


  // @ViewChild('cboPrueba')
  // public cboPrueba!: ElementRef<ComboBoxComponent>;

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private fs: FacturacionService) {


  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
    if (this.myForm.value.Fecha) {
      this.buscar(entity);
    }
  }

  blurFecha(value: any) {
    if (this.myForm.value.Moneda) {
      this.buscar(this.myForm.value.Moneda);
    }
  }

  buscar(moneda: any) {
    if (this.myForm.value.Fecha) {
      const strFecha = moment(this.myForm.value.Fecha).format("DD/MM/YYYY");
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fs.ObtenerTipoDeCambio(moneda.Id, strFecha).subscribe((result) => {
        if (result.success) {
          if (result.message) {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            const ent = JSON.parse(result.message);
            this.myForm.reset(ent);
          } else {
            this.containerService.getEmptyEntity("TipoDeCambio").subscribe((newi) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              newi.Fecha = this.myForm.value.Fecha;
              newi.Moneda = moneda;
              this.myForm.reset(newi);
            })
          }
        }
      })
    }
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "save": this.save(); break;
    }
  }


  save() {

  }

  onDelete() {
    this.init();
  }

  init() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.containerService.getLastEntity("TipoDeCambio").subscribe((ent) => {
      if (!ent) {
        this.inicializar();
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.myForm.reset(ent);
      }
    })
  }

  clicMenu(value: string) {
    if (value == "dof") {
      window.open("https://dof.gob.mx/indicadores.php#gsc.tab=0");
    } else {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      const t = this.myForm.value.Fecha;
      this.fs.obtenerTipoCambioDof(t.getFullYear(), t.getMonth() + 1, t.getDate()).subscribe((tc) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.myForm.controls["TipoCambio"].setValue(tc);
      });
    }

  }

  ngOnInit() {
    this.init();
  }

  inicializar() {
    this.containerService.getEmptyEntity("TipoDeCambio").subscribe((newi) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      newi.Fecha = new Date();
      this.myForm.reset(newi);
      this.enviarFoco();
    })
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    if (ent.TipoCambio <= 0) {
      Swal.fire({ text: 'Indique un Tipo de Cambio válido.', icon: 'error', })
      param.callback(false);
      return;
    }
    param.callback(true);
  }

  onAfterSave(entity: TipoDeCambio) {
    this.myForm.reset(entity);
  }


  enviarFoco() {
    setTimeout(() => {
      const t: any = this.cboMoneda;
      t.tagInput.nativeElement.focus();
    }, 150);
  }

}
