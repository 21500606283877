<!-- BEGIN login -->
<div class="login login-with-news-feed">
  <!-- BEGIN news-feed -->
  <div class="news-feed">
    <div class="news-image" style="background-image: url(/assets/img/login-bg/business.jpg)"></div>
    <div class="news-caption">
      <img class="logo-complit-negativo" src="../../../../assets/img/logo/SW-COMPLIT-negativo.png" alt="logo.png">
      <p>
        No es un sueño, es realidad y se llama COMPLIT
      </p>
    </div>
  </div>
  <!-- END news-feed -->

  <!-- BEGIN login-container -->
  <div class="login-container">
    <!-- BEGIN login-header -->
    <div class="login-header mb-30px">
      <div class="brand">
        <div class="d-flex align-items-center">
          <img class="logo-complit-positivo" src="../../../../assets/img/logo/SW-COMPLIT-positivo.png" alt="logo.png">
        </div>
        <small>Información de valor para tu empresa</small>
      </div>
      <div class="icon">
        <i class="fa fa-sign-in-alt"></i>
      </div>
    </div>
    <!-- END login-header -->

    <!-- BEGIN login-content -->
    <div class="login-content">
      <div *ngIf="mensajeError" class="alert alert-danger fade show">{{mensajeError}}</div>
      <form autocomplete="off" [formGroup]="frmLogin" (ngSubmit)="login()" class="fs-13px">
        <div class="form-floating mb-15px">
          <span class="p-float-label">
            <p-dropdown styleClass="w-100 fw-600" formControlName="baseDatos" [options]="listaBd" inputId="float-label"
              optionLabel="etiqueta">
            </p-dropdown>
            <label for="float-label">Ambiente de trabajo</label>
          </span>
        </div>
        <div class="form-floating mb-15px">
          <input type="text" formControlName="nombreUsuario" class="form-control h-45px fs-13px" placeholder="Usuario"
            id="emailAddress" />
          <label for="emailAddress" class="d-flex align-items-center fs-13px text-gray-600">Usuario</label>
        </div>
        <div class="form-floating mb-15px">
          <input type="password" formControlName="password" class="form-control h-45px fs-13px" placeholder="Password"
            id="password" />
          <label for="password" class="d-flex align-items-center fs-13px text-gray-600">Password</label>
        </div>
        <div class="form-check mb-30px">
          <input class="form-check-input" formControlName="recordar" type="checkbox" value="1" id="rememberMe" />
          <label class="form-check-label" for="rememberMe">
            Recordarme en este equipo
          </label>
        </div>
        <div class="mb-15px">
          <button [disabled]="frmLogin.invalid" type="submit"
            class="btn btn-theme d-block h-45px w-100 btn-lg fs-14px">Ingresar</button>
        </div>

        <hr class="bg-gray-600 opacity-2" />
        <div class="text-gray-600 text-center mb-0">
          &copy; Complit <br>
          Última Actualización: {{dateLastUpdate}} <br>
          Todos los derechos reservados
        </div>
      </form>
    </div>
    <!-- END login-content -->
  </div>
  <!-- END login-container -->
</div>
<!-- END login -->

<!-- BEGIN Agregar base datos -->
<agregar-ambiente [visible]="visibleAddBdModal" (ambienteGuardado)="ambienteGuardado()" />
<!-- END agregar base datos -->

<!-- BEGIN Reparar base datos -->
<actualizar-base-datos [ambiente]="this.frmLogin.get('baseDatos')?.value" [visible]="visibleActualizarBDModal"
  [puedeActualizar]="puedeActualizarBD" (ambienteActualizado)="ambienteActualizado($event)" />
<!-- END Reparar base datos -->
