<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <label *ngIf="showLabel"
    class="col-sm-{{widthLabelColumns}} col-form-label {{(topLabel ? 'text-start height-label':'text-right')}} {{(IsRequired?'text-red': '')}}">
    <i *ngIf="IsRequired" class="fa fa-asterisk text-red fs-10px me-2"></i>{{label}}{{
    label.length>0?separator:''}} {{label.length==0?"&nbsp;":""}}</label>
  <div class="d-flex col-sm-{{widthTextColumns}}">
    <div class="input-group">
      <input tooltipClass="complit-tooltip"
        style="text-align: right;border-radius: 0px;border:0px;border-bottom: 1px solid #000000;" (focus)="tomoFoco()"
        (keydown)="keypress($event)" [tabindex]="tabIndex" (blur)="blurControl(txtTagNumber.value)" [(ngModel)]="value"
        maxLength="{{maxLength}}" class="form-control form-control-sm {{IsRequired}}" type="number"
        placeholder="{{placeHolder}}" #txtTagNumber [disabled]="!this.enabled" (keypress)="OnKeypress($event)" />
      <div (click)="onClick()"
        style="height: 22px;padding: 2px;color:#ffa500;width: 30px;border-radius: 0px; border: 0px;border-bottom: 1px solid #000000;z-index: 1;"
        *ngIf="enableIcon" class="btn btn-white"><i class="fa fa-search fa-lg"></i></div>
    </div>
  </div>
</div>
