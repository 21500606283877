<div *ngIf="getAccesos; else empty" class="p-1" style="margin-left: 1px;margin-top:-4px;">
  <ul class="bg-light nav nav-tabs" role="tablist">
    <li *ngIf="permisosTableros?.verTableroVentas" class="nav-item" role="presentation"><a href="#dashcomplit"
        data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab"><span class="d-sm-none">Tab
          1</span><span class="d-sm-block d-none">COMPLIT
          Dashboard</span></a></li>
    <!-- <li class="nav-item" role="presentation"><a href="#dashefisco" data-bs-toggle="tab" class="nav-link"
        aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-none">Tab 3</span><span
          class="d-sm-block d-none">eFisco PowerBI</span></a></li> -->
    <li *ngIf="permisosTableros?.verTableroPowerBI" class="nav-item" role="presentation"><a href="#dashbi"
        data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-none">Tab
          3</span><span class="d-sm-block d-none">Microsoft Power BI</span></a></li>
    <li *ngIf="permisosTableros?.verTableroGastos" class="nav-item" role="presentation"><a href="#gastos"
        data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-none">Tab
          3</span><span class="d-sm-block d-none">Gastos</span></a></li>
    <li *ngIf="permisosTableros?.verTableroMisVentas" class="nav-item" role="presentation"><a href="#misventas"
        data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-none">Tab
          3</span><span class="d-sm-block d-none">Mis Ventas</span></a></li>
  </ul>

  <div class="tab-content panel rounded-0 p-1 m-0">
    <div id="dashcomplit" class="tab-pane fade active show" role="tabpanel" *ngIf="permisosTableros?.verTableroVentas">
      <div class="p-2 bg-light ">
        <ol class="breadcrumb float-xl-end">
          <li class="breadcrumb-item"><a href="javascript:;">Principal</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Dashboard General</a></li>
        </ol>
        <div class="d-sm-flex align-items-center mb-3">
          <div (click)="open($event)"
            class="ngx-daterangepicker-action btn btn-inverse me-2 text-truncate d-flex align-items-center">
            <i class="fa fa-calendar fa-fw text-white-transparent-5 ms-n1 me-1"></i>
            <input type="text" class="form-control w-200px shadow-none" readonly placeholder="Seleccione la fecha"
              ngxDaterangepickerMd [locale]="locale" [opens]="'right'" [alwaysShowCalendars]="true" [ranges]="ranges"
              [showDropdowns]="true" [showCustomRangeLabel]="true" [showClearButton]="false" [showCancel]="true"
              [(ngModel)]="selected" (ngModelChange)="ngModelChange($event)" />
            <b class="caret ms-1 opacity-5"></b>
          </div>
        </div>
        <!-- <h1 class="page-header">VENTAS <small>Ventas, margenes de utilidad entre otros</small></h1> -->



        <div class="row">
          <div class="col-lg-12">
            <div class="widget rounded">
              <div class="widget-header">
                <h4 class="widget-header-title">Ventas en General y Margenes De Utilidad (día actual)</h4>
              </div>
              <div class="d-flex">
                <div class="flex-1 widget-chart-content">
                  <div id="nv-stacked-area-chart" style="height: 108%">
                    <div class="row">
                      <div class="col-xl-3 col-md-6">
                        <div class="widget widget-stats bg-gradient-success widget-dash">
                          <div class="stats-icon"><i class="fa fa-dollar-sign text-light"></i></div>
                          <div class="stats-info">
                            <div class="stats-title">VENTAS SIN ANTICIPOS (Factura - Notas Crédito)</div>
                            <div class="stats-number">${{totalFacturado | number:'1.2'}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6">


                        <div class="widget widget-stats bg-gradient-orange widget-dash">
                          <div class="stats-icon"><i class="fa fa-dollar-sign text-light"></i></div>
                          <div class="stats-info">
                            <div class="stats-title">FACTURACIÓN DE ANTICIPOS</div>
                            <div class="stats-number">${{anticiposFacturados | number:'1.2'}}</div>
                          </div>
                        </div>

                      </div>
                      <div class="col-xl-3 col-md-6">

                        <div class="widget widget-stats bg-gradient-green  widget-dash">
                          <div class="stats-icon"><i class="fa fa-percent text-light"></i></div>
                          <div class="stats-info">
                            <div class="stats-title">MARGEN DE UTILIDAD GENERAL</div>
                            <div class="stats-number">0.00</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6">
                        <div class="widget widget-stats bg-gradient-green widget-dash">
                          <div class="stats-icon"><i class="fa fa-scale-balanced text-light"></i></div>
                          <div class="stats-info">
                            <div class="stats-title">KILOS VENDIDOS</div>
                            <div class="stats-number">0.00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-12">
            <div class="widget rounded">
              <div class="widget-header">
                <h4 class="widget-header-title">Ventas Por Familias De Producto (día actual)</h4>
              </div>
              <div class="d-flex">
                <div class="flex-1 widget-chart-content">
                  <div id="nv-stacked-area-chart" style="height: 108%">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row" *ngIf="familias.length>0; else noFamilias;">
                          <div class="col-xl-3 col-md-6" *ngFor="let item of familias">
                            <div class="widget widget-stats bg-gradient-primary widget-dash">
                              <div class="stats-icon"><i class="fa fa-{{item.icono}} text-light"></i></div>
                              <div class="stats-info">
                                <h4>{{item.nombre | uppercase}}</h4>
                                <h5>{{(item.sonKilosTablero ? 'KILOS': item.textoUnidadMedidaTablero) | uppercase}}:
                                  0.00 </h5>
                                <h5>Importe: 0.00 </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <ng-template #noFamilias>
          <div class="row">
            <div class="card bg-dark border-0 text-white m-2">
              <div class="card-body">
                <h4 class="card-title">No hay familias de productos configuradas</h4>
                <p class="card-text">Vaya a Inventarios - Catálogos - Familias y configuré lo que se mostrará aquí.</p>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="row">
          <div class="col-lg-12">
            <div class="widget rounded">
              <div class="widget-header">
                <h4 class="widget-header-title">Ventas Mensuales (Ventas - Notas de Crédito) NO ANTICIPOS</h4>
              </div>
              <div class="d-flex">
                <div class="flex-1 widget-chart-content">
                  <div id="nv-stacked-area-chart" style="height: 108%">
                    <div class="row">
                      <div class="col-lg-12" *ngIf="chartMensual.series">
                        <apx-chart [series]="chartMensual.series" [chart]="chartMensual.chart"
                          [colors]="chartMensual.colors" [legend]="chartMensual.legend" [xaxis]="chartMensual.xaxis"
                          [yaxis]="chartMensual.yaxis" [dataLabels]="chartMensual.dataLabels" [grid]="chartMensual.grid"
                          [stroke]="chartMensual.stroke" [title]="chartMensual.title"
                          [plotOptions]="chartMensual.plotOptions" [fill]="chartMensual.fill"
                          [tooltip]="chartMensual.tooltip"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="widget rounded">
              <div class="widget-header">
                <h4 class="widget-header-title">Análisis de Ventas Por Sucursal</h4>
              </div>
              <div class="d-flex">
                <div class="flex-1 widget-chart-content">
                  <!-- <div class="progress h-20px w-100 mb-0 me-2">
                    <div [class]="'progress-bar progress-bar-striped progress-bar-animated  bg-success'"
                    [style]="'width: '+getPorcentajePresSucs+'%;'">
                      {{getPorcentajePresSucs}}% Cumplido
                    </div>
                  </div> -->
                  <div id="nv-stacked-area-chart" style="height: 108%">
                    <div class="row">
                      <div class="col-lg-12" *ngIf="tienePresupuestoSucursales && chart2Options?.series  != undefined">
                        <apx-chart [series]="chart2Options.series" [chart]="chart2Options.chart"
                          [colors]="chart2Options.colors" [legend]="chart2Options.legend" [xaxis]="chart2Options.xaxis"
                          [yaxis]="chart2Options.yaxis" [dataLabels]="chart2Options.dataLabels"
                          [grid]="chart2Options.grid" [stroke]="chart2Options.stroke" [title]="chart2Options.title"
                          [plotOptions]="chart2Options.plotOptions" [fill]="chart2Options.fill"
                          [tooltip]="chart2Options.tooltip"></apx-chart>
                      </div>
                      <div class="note alert-primary" *ngIf="!tienePresupuestoSucursales">
                        <div class="note-icon"><i class="fas fa-bullhorn"></i></div>
                        <div class="note-content">
                          <h4><b>No ha configurado los presupuestos por sucursal!</b></h4>
                          <p>
                            Vaya al menú de <b>Ventas - Presupuestos</b> y configure
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="tienePresupuestoSucursales" class="p-3" style="width: 30%;">
                  <!-- <div style="height: 250px;">
                    <ngx-charts-pie-chart [scheme]="pieChartColor" [results]="pieChartData" [legend]="false"
                      [explodeSlices]="false" [labels]="true" [doughnut]="false">
                    </ngx-charts-pie-chart>
                  </div> -->
                  <div class="card border-0 text-truncate mb-3 bg-gray-800 text-white h-100">
                    <div class="card-body">
                      <div class="mb-3 text-gray-500">
                        <b class="mb-3">TOTALES</b>
                      </div>
                      <div class="d-flex align-items-center mb-1">
                        <h2 class="text-white mb-0">{{getPorcentajePresSucs|number:'1.2-2'}}% de 100%</h2>
                      </div>
                      <div class="mb-4 text-gray-500 ">
                        <i class="fa fa-circle-info"></i> No contiene anticipos
                      </div>
                      <div class="d-flex mb-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-circle text-success fs-8px me-2"></i>
                          Total Vendido
                        </div>
                        <div class="d-flex align-items-center ms-auto">
                          <div class="w-50px text-end ps-2 fw-bold">{{getPorcentajePresSucs|number:'1.2-2'}}%</div>
                        </div>
                      </div>

                      <div class="d-flex mb-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-circle text-danger fs-8px me-2"></i>
                          Pendiente
                        </div>
                        <div class="d-flex align-items-center ms-auto">
                          <div class="w-50px text-end ps-2 fw-bold">{{(100-getPorcentajePresSucs)|number:'1.2-2'}}%
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="widget rounded">
              <div class="widget-header">
                <h4 class="widget-header-title">Análisis de Ventas Por Vendedores</h4>
              </div>
              <div class="d-flex">
                <div class="flex-1 widget-chart-content">
                  <div id="nv-stacked-area-chart" style="height: 108%">
                    <div class="row">
                      <div class="col-lg-12" *ngIf="tienePresupuestoVendedores && chartVendedores?.series != undefined">
                        <apx-chart [series]="chartVendedores.series" [chart]="chartVendedores.chart"
                          [colors]="chartVendedores.colors" [legend]="chartVendedores.legend"
                          [xaxis]="chartVendedores.xaxis" [yaxis]="chartVendedores.yaxis"
                          [dataLabels]="chartVendedores.dataLabels" [grid]="chartVendedores.grid"
                          [stroke]="chartVendedores.stroke" [title]="chartVendedores.title"
                          [plotOptions]="chartVendedores.plotOptions" [fill]="chartVendedores.fill"
                          [tooltip]="chartVendedores.tooltip"></apx-chart>
                      </div>
                      <div class="note alert-primary" *ngIf="!tienePresupuestoVendedores">
                        <div class="note-icon"><i class="fas fa-bullhorn"></i></div>
                        <div class="note-content">
                          <h4><b>No ha configurado los presupuestos por Vendedor!</b></h4>
                          <p>
                            Vaya al menú de <b>Ventas - Presupuestos</b> y configure
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="tienePresupuestoVendedores" class="p-3" style="width: 30%;">
                  <div class="card border-0 text-truncate mb-3 bg-gray-800 text-white h-100">
                    <div class="card-body">
                      <div class="mb-3 text-gray-500">
                        <b class="mb-3">TOTALES</b>
                      </div>
                      <div class="d-flex align-items-center mb-1">
                        <h2 class="text-white mb-0">{{getPorcentajePresVen|number:'1.2-2'}}% de 100%</h2>
                      </div>
                      <div class="mb-4 text-gray-500 ">
                        <i class="fa fa-circle-info"></i> No contiene anticipos
                      </div>
                      <div class="d-flex mb-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-circle text-success fs-8px me-2"></i>
                          Total Vendido
                        </div>
                        <div class="d-flex align-items-center ms-auto">
                          <div class="w-50px text-end ps-2 fw-bold">{{getPorcentajePresVen|number:'1.2-2'}}%</div>
                        </div>
                      </div>

                      <div class="d-flex mb-2">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-circle text-danger fs-8px me-2"></i>
                          Pendiente
                        </div>
                        <div class="d-flex align-items-center ms-auto">
                          <div class="w-50px text-end ps-2 fw-bold">{{(100-getPorcentajePresVen)|number:'1.2-2'}}%</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div id="dashbi" class="tab-pane fade" role="tabpanel">
      <iframe id="pwr-bi" style="height: 80vh;width: 80vw;" *ngIf="permisosTableros?.urlPowerBI"
        [src]="permisosTableros.urlPowerBISanitizer" frameborder="1"></iframe>
      <div class="note alert-warning fade show" *ngIf="!permisosTableros?.urlPowerBI">
        <div class="note-icon">
          <i class="fa fa-lightbulb"></i>
        </div>
        <div class="note-content">
          <h1> No hay tablero configurado</h1>
        </div>
      </div>
      <!-- https://app.powerbi.com/view?r=eyJrIjoiOWQ3ZmYyNGItNmY0ZS00MzM1LWFiZGQtYTUzNTZkZjk3N2E1IiwidCI6IjZkNjVlMDRlLWNkMDgtNDY2Ni1iYzA1LTU2NzllOTI2NTUxMCJ9&pageName=ReportSection3e9b84eab94341724a73 -->
    </div>
    <div id="dashefisco" class="tab-pane fade" role="tabpanel">
      <!-- <iframe id="video1" style="height: 80vh;width: 80vw;"
        src="https://app.powerbi.com/links/0cIIAI3t1d?ctid=8fcd0362-6bab-49cf-963b-03a64cfd24f0&pbi_source=linkShare"
        frameborder="1"></iframe> -->
    </div>
    <div id="gastos" class="tab-pane fade" role="tabpanel">
      <h1 class="page-header">Gastos <small>Autorización de gastos...</small></h1>
    </div>
    <div id="misventas" class="tab-pane fade" role="tabpanel">
      <div class="p-1">
        <ol class="breadcrumb float-xl-end">
          <li class="breadcrumb-item"><a href="javascript:;">Principal</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">MIS VENTAS</a></li>
        </ol>
        <h1 class="page-header">MIS VENTAS <small>Ventas comparadas contra el presupuesto asignado al vendedor</small>
        </h1>
        <div class="row">
          <div class="col-lg-12">
            <!-- <apx-chart [id]="'chartMV'" [series]="chartMisVentas.series" [chart]="chartMisVentas.chart"
            [colors]="chartMisVentas.colors" [legend]="chartMisVentas.legend" [xaxis]="chartMisVentas.xaxis"
            [yaxis]="chartMisVentas.yaxis" [dataLabels]="chartMisVentas.dataLabels" [grid]="chartMisVentas.grid"
            [stroke]="chartMisVentas.stroke" [title]="chartMisVentas.title"
            [plotOptions]="chartMisVentas.plotOptions" [fill]="chartMisVentas.fill"
            [tooltip]="chartMisVentas.tooltip"></apx-chart> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #empty>
  <img src="../../../../assets/img/cover/cover-scrum-board.png"
    style="width: 360px; position: absolute;right: 0px;  bottom: 0px;">

</ng-template>
