<app-container-base [hasCounter]="false" icon="fa fa-clipboard-list" [hasBar]="false" subTitle="Registro de actividad">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Filtrar movimientos">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <component-ui-combo-box [label]="'Usuario'" [tabIndex]="1" [zeroMask]="2" entityName="Usuario"
                    formControlName="usuario" (onSelectedItem)="selectedCombo($event,'usuario')" [widthTextColumns]="5"
                    [widthLabelColumns]="4" [inputProperty]="'ClaveNombre'" [listProperty]="'ClaveNombre'" #txtusuario />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <components-ui-date-box label='Fecha inicio' formControlName="fecha1" [tabIndex]="2"
                    [widthLabelColumns]="8" [widthTextColumns]="3" [required]="true"
                    (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
                </div>
                <div class="col-md-4">
                  <components-ui-date-box label='Fecha Fin' formControlName="fecha2" [tabIndex]="3"
                    [widthLabelColumns]="6" [widthTextColumns]="6" [required]="true"></components-ui-date-box>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-3">
                  <a (click)="buscar()" class="btn btn-orange ms-5 mt-2"  style=" width: 100px;"><i
                     class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-magnifying-glass')}}"></i>      Buscar </a>
               </div>

               <div class="col-md-3">
                 <a (click)="imprimir()" class="btn btn-orange ms-10 mt-2" style="padding-left:7px; width: 100px;"><i
                     class="fas fa-print"></i> Imprimir </a>
               </div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Registros de actividades">
        <div class="panel-content">
          <ng-scrollbar style="height: 455px;width: 1300px;">
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="header">
                  <th class="w-150px">Pantalla</th>
                  <th class="w-80px">Acción</th>
                  <th class="w-100px">Usuario</th>
                  <th class="w-300px">Observaciones</th>
                  <th class="w-100px text-center">Fecha-Hora</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let u of listaActividades" class="table-info">
                  <td>{{u.pantalla}}</td>
                  <td>{{u.accionEjecutada}}</td>
                  <td>{{u.usuarioNombre}}</td>
                  <td>{{u.observaciones}}</td>
                  <td class="text-center">{{u.fecha}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
