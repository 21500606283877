<app-panel-base title="Cancelar documento">
  <div class="panel-content">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content" style="overflow-x: hidden;">
        <form [formGroup]="myFormCancel" autocomplete="off">
          <components-ui-text-box [tabIndex]="1" formControlName="MotivoCancelacion" [maxlength]="2000" label="Motivo"
            [widthLabelColumns]="3" [widthTextColumns]="9" #motivoCancelacion/>
          <component-ui-combo-box [tabIndex]="2" (onSelectedItem)="onSelectedItem($event)" [enabled]="requiresSAT"
            formControlName="MotivoSAT" label="Motivo SAT" entityName="c_MotivoCancelacion" [widthLabelColumns]="3"
            [widthTextColumns]="9" [widthRightColumns]="0" />
          <components-ui-text-box [tabIndex]="3" *ngIf="myFormCancel.value.MotivoSAT.Id == 1" formControlName="UUID"
            [maxlength]="2000" label="Sustituye" [widthLabelColumns]="3" [widthTextColumns]="9" />
          <div style="text-align: right; margin-top: 5px;">
            <div (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</div>
            <div (click)="closeModal()" class="btn btn-danger">Cancelar</div>
          </div>
        </form>
      </div>
    </app-container-base>
  </div>
</app-panel-base>
