<app-panel-base title="Observaciones">
  <div class="panel-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
        <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
        <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" formControlName="Comentario"
          [placeholder]="''"></ngx-editor>
      </div>
    </form>
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
      <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
    </div>
  </div>
</app-panel-base>
