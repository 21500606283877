import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { FacturacionService } from '../../services/facturacion.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { ModalService } from 'src/app/service/modal.service';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { ComboBoxEntity } from '../../../component-ui/interfaces/combo-text.interface';
import { SucursalPageComponent } from 'src/app/configuracion/pages/sucursales-page/sucursal-page/sucursal-page.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { Venta, VentaConcepto } from '../../interfaces/venta.interface';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-copiar-comprobantes-page',
  templateUrl: './copiar-comprobantes-page.component.html',
  styleUrls: ['./copiar-comprobantes-page.component.css']
})
export class CopiarComprobantesPageComponent implements OnInit {

  searching = false;


  @ViewChild('cboSucursal')
  public cboSucursal!: ElementRef<ComboBoxComponent>;
  @ViewChild('cboDocumento')
  public cboDocumento!: ElementRef<ComboBoxComponent>;
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolio')
  public txtFolio!: ElementRef<TextBoxComponent>;
  private eventsService = inject(EventsService);
  @Input()
  empresa: Empresa | null = null;

  @Input()
  canCopy: string = "";

  listasucs: ComboBoxEntity[] = [];
  listaSeries: ComboBoxEntity[] = [];
  documentos: Venta[] = [];

  private readonly baseUrl: string = environment.baseUrlApi;

  private newEntityObj = {
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    DocumentoVenta: [{} as DocumentoVenta, Validators.required],
    Serie: [{} as SerieVenta, Validators.required],
    SerieString: [''],
    Folio: [0, [Validators.required, Validators.min(1)]],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  constructor(private fb: FormBuilder, private cService: ContainerBaseService, private fs: FacturacionService, private http: HttpClient, private fServices: FacturacionService, private mService: ModalService) {
  }

  get getItemsSucs(): ComboBoxEntity[] {
    return this.listasucs;
  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  getDate(date: any) {
    return moment(date).format("DD/MM/YYYY")
  }

  enterFolio(e: any) {
    e.target.blur();
    setTimeout(() => {
      this.searchDocument()
    }, 20);
  }

  ngOnInit(): void {
    const params = new HttpParams().set("entidad", "Sucursal").set("filterQuery", `Empresa.Id = ${this.empresa!.Id}`);
    this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Base/ObtenerListaCombo`, { params }).subscribe((lista) => {
      lista.map((item, index) => item.ClaveNombre = `${item.Clave} ${item.Nombre}`)
      this.listasucs = lista;
      let suc: ComboBoxEntity | null = null;
      if (lista.length == 1) {
        suc = lista[0];
      }
      this.myForm.reset({
        Empresa: this.empresa,
        Sucursal: suc,
        Documento: null,
        Serie: null,
        SerieString: '',
        Folio: 0
      });

      if (lista.length == 1) {
        setTimeout(() => {
          const el: any = this.cboDocumento;
          el.tagInput.nativeElement.focus();
        }, 250);
      } else {
        setTimeout(() => {
          const el: any = this.cboSucursal;
          el.tagInput.nativeElement.focus();
        }, 250);
      }

    });


  }

  get getFilter() {

    if (this.canCopy) {
      return `Clave in(${this.canCopy})`;
    }

    return "";
  }


  closeModal() {
    this.mService.closeModal(null);
  }

  accept() {
    if (this.documentos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: 'Primero debe de indicar los Documentos a copiar.', cancelButton: false });
      return;
    }
    let conceptos: VentaConcepto[] = [];
    this.documentos.forEach((venta) => {
      venta.Conceptos.forEach((concepto) => {
        conceptos = [...conceptos, concepto];
      });
    });
    //TODO: validar los costos actuales y colocarlos
    conceptos.map((concepto) => {
      concepto.Id = 0;
      concepto.TieneToken = false;
      concepto.IdToken = 0;
      concepto.UnidadNombre = concepto.Unidad!.Nombre;
      if (concepto.Impuestos) {
        concepto.Impuestos.map((imp) => {
          imp.Id = 0;
        });
      }
      if (concepto.Conversiones) {
        concepto.Conversiones.map((imp) => {
          imp.Id = 0;
        });
      }
    });
    const idsCopy = this.documentos.map((obj) => obj.Id).join(',')
    this.mService.closeModal({ conceptos, idsCopy });
  }

  deleteRow(index: number) {
    this.documentos.splice(index, 1);
    this.documentos = [...this.documentos];
  }

  get getSubtotal(): number {
    let s: number = 0;
    this.documentos.forEach((item) => s += item.SubTotal);
    return s;
  }

  get getIva(): number {
    let s: number = 0;
    this.documentos.forEach((item) => s += item.TotalIvaTrasladado);
    return s;
  }

  get getTotal(): number {
    let s: number = 0;
    this.documentos.forEach((item) => s += item.Total);
    return s;
  }

  searchDocument() {
    if (this.myForm.valid) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.searching = true;
      const ent = this.myForm.value;
      this.fServices.findEntityByParamsToCopy(ent.Empresa.Id, ent.Sucursal.Id, ent.DocumentoVenta.Id, ent.SerieString, ent.Folio).subscribe((venta) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.searching = false;
        if (venta) {
          const count = this.documentos.filter(P => P.Id == venta.Id).length;
          if (count == 0) {
            this.documentos = [...this.documentos, venta];
          }
          this.myForm.controls['Folio'].setValue(0);
          setTimeout(() => {
            const el: any = this.txtFolio;
            el.tagInput.nativeElement.focus();
          }, 50);
        } else {
          this.eventsService.publish('home:showAlert', { message: 'No existe el documento o se encuentra cancelado.', cancelButton: false });
          this.myForm.controls['Folio'].setValue(0);
          return;
        }
      });
    }
  }

  onSelectedItem(ent: any, type: string) {
    this.myForm.controls[type].setValue(ent);

    if (type == "Serie") {
      this.myForm.controls['SerieString'].setValue(ent.Serie);
    }

    if (type == 'DocumentoVenta') {
      this.fServices.getSeries(this.myForm.value.Empresa.Id, this.myForm.value.Sucursal.Id, ent.Id).subscribe((lista) => {
        if (lista.length > 0) {
          this.listaSeries = lista;
          if (lista.length == 1) {
            this.myForm.controls['Serie'].setValue(lista[0]);
            this.myForm.controls['SerieString'].setValue(lista[0].Serie);
            setTimeout(() => {
              const el: any = this.txtFolio;
              el.tagInput.nativeElement.focus();
            }, 250);
          } else {
            setTimeout(() => {
              const el: any = this.cboSerie;
              el.tagInput.nativeElement.focus();
            }, 250);
          }
        } else {
          this.listaSeries = [];
          this.myForm.controls['Serie'].setValue(null);
        }
      });
    }

  }
}
