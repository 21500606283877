import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { ConfigWebTicketPageComponent } from './pages/config-web-ticket-page/config-web-ticket-page.component';

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
  ],
  exports: [],
  declarations: [
        ConfigWebTicketPageComponent
  ],
  providers: [],
})
export class WebTicketModule { }
