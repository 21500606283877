<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <label class="col-sm-{{widthLabelColumns}} col-form-label {{(topLabel ? 'text-left height-label':'tb-right')}}"><i *ngIf="IsRequired" class="fa fa-asterisk text-red fs-10px me-2"></i>{{label}}{{
    label.length>0?separator:''}} {{label.length==0?"&nbsp;":""}}</label>
  <div style="width:119px;">
    <div class="d-flex">
      <input tooltipClass="complit-tooltip" name="d2" style="border-radius: 0px;padding-left: 5px;border:0px;border-bottom: 1px solid #000000;" #d2="ngbDatepicker" #c2="ngModel"  (focus)="focusFunction()"
        [(ngModel)]="value" maxLength="{{maxLength}}" [tabIndex]="tabIndex" [disabled]="!enabled" ngbDatepicker
        class="form-control form-control-sm date-picker" placeholder="{{placeHolder}}" #txtTag
        (keypress)="onKeyPress($event)" (blur)="onBlur()" (dateSelect)="changeDate($event)"
        (keydown)="onKeyDownFecha($event)" />
      <div (click)="d2.toggle()" class="date-box-trigger">
        <i class="fas fa-calendar fa-lg"></i>
      </div>
    </div>
  </div>
</div>
