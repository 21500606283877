<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Configuración de CRM">
  <div class="screen-content" style="width: 800px;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Configuración para la prospectación">
        <div class="panel-content">
          <components-ui-text-box [maxLength]="5" [label]="'No. de Prospectos Mensuales'" formControlName="NumeroProspectosMensuales"
            [tabIndex]="1" [widthTextColumns]="2" [widthLabelColumns]="3" #txtNumProspectos />
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
