<app-container-base [hasCounter]="false" [hasBar]="!selectedItem" [setIsNew]="getIsNew" [entity]="myForm"
  icon="fa fa-vault" [activeButtons]="activeButtons" (onClickBarButton)="onClickBarButton($event)"
  subTitle="Captura de Caja Chica">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información de la Caja Chica">
        <div class="panel-content">

          <component-ui-combo-box [enabled]="!selectedItem" [label]="'Tipo'" formControlName="tipo" [tabIndex]="1"
            [zeroMask]="2" entityName="TipoCajaChica" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="onSelectedItem($event,'tipo')" [listProperty]="'ClaveNombre'"
            #cboTipo />

          <components-ui-date-box [enabled]="!selectedItem" label="Fecha" (onChangeValue)="onChangeValue($event)"
            [tabIndex]="2" formControlName="fecha" [widthLabelColumns]="2" [widthTextColumns]="6" />

          <components-ui-text-box [enabled]="!selectedItem" (keydown.enter)="enterDescripcion()" [tabIndex]="3"
            [label]="'Descripción'" formControlName="nombre" [widthTextColumns]="4" [widthLabelColumns]="2"
            [required]="true" #txtNombre />

        </div>
      </app-panel-base>
      <app-panel-base title="Detalle de gastos del día" id="divCajaChica">
        <div class="panel-content">
          <gui-grid [localization]="localization" [rowStyle]="rowStyle" [autoResizeWidth]="false" [source]="detalle" [rowHeight]="20"
            [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">
            <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
              <ng-template let-item="item" let-index="index">
                <i (click)="deleteRow(index)" *ngIf="item.proveedor" class="fa fa-rectangle-xmark"
                  style="color:red;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="No." [width]="30" [field]="'orden'" [cellEditing]="false">
              <ng-template let-item="item">
                <span *ngIf="item.proveedor">
                  {{item.orden}}
                </span>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Proveedor" [width]="150" [cellEditing]="false">
              <ng-template let-item="item">
                <span *ngIf="item.proveedor">
                  {{item.proveedor.nombre ? item.proveedor.nombre : item.proveedor.Nombre}}
                </span>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Concepto" [width]="250" [field]="'concepto'" [cellEditing]="false" />
            <gui-grid-column header="Referencia" [width]="150" [field]="'referencia'" />
            <gui-grid-column header="Clase" [width]="90">
              <ng-template let-item="item">
                {{item.clase ? (item.clase?.Nombre ? item.clase?.Nombre : item.clase?.nombre): ''}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Subtotal" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.subtotal != 0 ? (item.subtotal | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="IVA" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.iva != 0 ? (item.iva | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="IVA Ret" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.iVARetenido != 0 ? (item.iVARetenido | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="ISR Ret" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.iSRRetenido != 0 ? (item.iSRRetenido | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="IL Tras" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.impLocalTrasladado != 0 ? (item.impLocalTrasladado | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="IL Ret" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.impLocalRetenido != 0 ? (item.impLocalRetenido | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Total" [width]="90" [align]="GuiColumnAlign.RIGHT">
              <ng-template let-item="item">
                {{item.total != 0 ? (item.total | number:'1.2'):""}}
              </ng-template>
            </gui-grid-column>
          </gui-grid>
        </div>
      </app-panel-base>
      <div style="text-align: right;" class="card border-0 text-truncate mb-3 bg-light">
        <div class="card-body">
          <div class="mb-3"><b class="mb-3">Resumen</b><span class="ms-2"><i placement="top"
                class="fa fa-info-circle"></i></span></div>
          <hr>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center ms-auto">
              <div class="d-flex align-items-center"><i class="fa fa-circle text-success fs-8px me-2"></i> Número de
                registros:
              </div>
              <div style="text-align: right;" class="w-50px text-end ps-2 fw-bold">{{ getCount }}</div>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center ms-auto">
              <div class="d-flex align-items-center"><i class="fa fa-circle text-success fs-8px me-2"></i> Subtotal:
              </div>
              <div style="text-align: right;" class="w-50px text-end ps-2 fw-bold">{{getSubtotal | number:'1.2'}}</div>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center ms-auto">
              <div class="d-flex align-items-center"><i class="fa fa-circle text-success fs-8px me-2"></i> IVA:
              </div>
              <div style="text-align: right;" class="w-50px text-end ps-2 fw-bold">{{getIva | number:'1.2'}}</div>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex align-items-center ms-auto">
              <div class="d-flex align-items-center"><i class="fa fa-circle text-success fs-8px me-2"></i> Total:
              </div>
              <div style="text-align: right;" class="w-50px text-end ps-2 fw-bold">{{getTotal | number:'1.2'}}</div>
            </div>
          </div>
        </div>
      </div>

    </form>
    <app-ctrl-edicion-caja *ngIf="selectedItem" [item]="getSelectedItem" (onCloseEdition)="onCloseEdit($event)" />
  </div>
</app-container-base>
