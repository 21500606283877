import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../services/alert-base.service';
import { Alert } from '../../interfaces/alert.interface';
import { NumberBoxComponent } from '../number-box/number-box.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, AfterViewInit {

  cargado: boolean = false;

  @Input()
  public config: Alert = { message: '' }
  @ViewChild('btnAcceptAlert')
  public btnAcceptAlert!: ElementRef<HTMLElement>;

  @ViewChild('txtCancelar')
  public txtCancelar!: ElementRef<HTMLElement>;


  // const txt: any = this.txtCliente;
  //   txt.tagInput.nativeElement.focus()


  ngOnInit(): void {

  }

  constructor(private alerts: AlertService) {

  }
  ngAfterViewInit(): void {
    if (!this.cargado) {
      if (this.config.cancelButton) {
        setTimeout(() => {
          const btn: any = this.txtCancelar;
          btn.nativeElement.focus();
        }, 250);
      } else {
        if (this.config.acceptButton) {
          setTimeout(() => {
            const btn: any = this.btnAcceptAlert;
            btn.nativeElement.focus();
          }, 250);
        }
      }
    }
  }

  accept() { this.alerts.onClicAccept(); }
  no() { this.alerts.onClicNo(); }
  cancel() { this.alerts.onClicCancel(); }

}
