<app-container-base subTitle="Apertura de cajas" [hasBar]="false" [hasCounter]="false" icon=" fas fa-list-check">
  <div class="screen-content p-1 bg-light" style="flex-direction: column;border: 1px solid #d2d2d2; cursor: pointer;">
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb mb-2">
          <li class="breadcrumb-item"><a href="javascript:;">Ventas</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">POS</a></li>
          <li class="breadcrumb-item active">Apertura de Cajas</li>
        </ol>
        <h1 class="page-header mb-0">
          Cajas disponibles
        </h1>
      </div>
      <div class="ms-auto">
        <a (click)="getBoxes()" class="btn btn-success btn-rounded px-4 rounded-pill"><i
            class="fa fa-arrow-rotate-left fa-lg me-2 ms-n2 text-success-900"></i> Actualizar</a>
      </div>
    </div>
    <hr>
    <ng-scrollbar style="height: 80vh; margin-bottom: 35px;">
      <div class="row">
        <div (click)="selectBox(item)" *ngFor="let item of boxes" class="col-md-4">
          <div class="widget widget-stats bg-{{item.color}}"
            style="height: 200px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
            <div class="stats-icon"><i class="fa fa-circle-check"></i></div>
            <div class="stats-info">
              <p *ngIf="item.turno>0">Turno: {{item.turno}}</p>
              <p>Caja: {{ item.nombre | uppercase}}</p>
              <p>Estado: {{ item.estado.nombre | uppercase}}</p>
              <p *ngIf="item.encargado">Encargado: {{ item.encargado | uppercase}}</p>
            </div>
            <div class="stats-link" style="bottom: 15px;position: absolute;width: 100%;padding-right: 26px;"><a
                href="javascript:;">Modificar <i class="fa fa-arrow-alt-circle-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>

    <ng-template #popupApertura let-modal let-c="close">
      <div class="card text-center border-0" cdkDrag
        style="width: 600px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Apertura de Caja</div>
        <form [formGroup]="myForm" autocomplete="off">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-1">
                <div class="modal-pos-product-info">
                  <app-panel-base title="Información de apertura de caja">
                    <div class="panel-content">
                      <div style="text-align: center;font-size: 25px;" class="fw-bold">{{myForm.value.Caja?.Nombre}}
                      </div>
                      <hr>
                      <component-ui-combo-box [label]="'Encargado'" formControlName="Encargado" [tabIndex]="1"
                        [zeroMask]="2" entityName="Usuario" [widthTextColumns]="2" [widthLabelColumns]="4"
                        [widthRightColumns]="6" (onSelectedItem)="selectedItem($event, 'Encargado')" [required]="true"
                        [listProperty]="'ClaveNombre'" />

                      <component-ui-combo-box [filterQuery]="'Id == 1'" [label]="'Estado'" formControlName="Estado"
                        [tabIndex]="2" [zeroMask]="2" entityName="EstadoCaja" [widthTextColumns]="2"
                        [widthLabelColumns]="4" [widthRightColumns]="6" [required]="true"
                        (onSelectedItem)="selectedItem($event, 'Estado')" [listProperty]="'ClaveNombre'" />


                      <components-ui-number-box [tabIndex]="3" [label]="'Fondo fijo'" formControlName="FondoFijo"
                        [widthTextColumns]="4" [widthLabelColumns]="4" [required]="true" />

                      <components-ui-date-box label="Fecha" [tabIndex]="4" formControlName="Fecha" [enabled]="false"
                        [widthLabelColumns]="4" [widthTextColumns]="4" [required]="true" />

                    </div>
                  </app-panel-base>
                  <div class="row gx-3">
                    <div class="col-4">
                      <a (click)="close()" href="javascript:;"
                        class="btn btn-danger fs-14px rounded-3 fw-bold mb-0 d-block py-3"
                        data-bs-dismiss="modal">Cancelar</a>
                    </div>
                    <div class="col-8">
                      <a (click)="accept()" href="javascript:;" data-bs-dismiss="modal"
                        class="btn btn-green fs-14px rounded-3 fw-bold d-flex justify-content-center align-items-center py-3 m-0 {{getIsValid ? '': 'disabled'}}">Abrir
                        Caja<i class="fa fa-lock-open ms-3"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-template>

    <ng-template #popupEditar let-modal let-c="close">
      <div class="card text-center border-0" cdkDrag
        style="width: 600px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Cerrar/Bloquear Caja</div>
        <form [formGroup]="myForm" autocomplete="off">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-1">
                <div class="modal-pos-product-info">
                  <app-panel-base title="Información de apertura de caja">
                    <div class="panel-content">
                      <div style="text-align: center;font-size: 25px;" class="fw-bold">{{myForm.value.Caja?.Nombre}}
                      </div>
                      <hr>
                      <component-ui-combo-box
                        [filterQuery]="myForm.value.Estado.Id == 4 ? 'Id == 1 or Id == 2':'Id == 2 or Id == 4'"
                        [label]="'Estado'" formControlName="EstadoNuevo" [tabIndex]="1" [zeroMask]="2"
                        entityName="EstadoCaja" [widthTextColumns]="2" [widthLabelColumns]="4" [widthRightColumns]="6"
                        [required]="true" (onSelectedItem)="selectedItem($event, 'EstadoNuevo')"
                        [listProperty]="'ClaveNombre'" />

                      <div *ngIf="myForm.value.EstadoNuevo?.Id == 2" class="card-header h6 mb-1 bg-none p-2 text-orange">
                        <i class="fa fa-cube fa-lg fa-fw me-1"></i>
                        Información final para el Cierre de Caja
                      </div>

                      <component-ui-combo-box *ngIf="myForm.value.EstadoNuevo?.Id == 2" [label]="'Supervisor'"
                        formControlName="Supervisor" [tabIndex]="2" [zeroMask]="2" entityName="Usuario"
                        [widthTextColumns]="2" [widthLabelColumns]="4" [widthRightColumns]="6" [required]="true"
                        (onSelectedItem)="selectedItem($event, 'Supervisor')" [listProperty]="'ClaveNombre'" />

                      <component-ui-check-box *ngIf="myForm.value.EstadoNuevo?.Id == 2"
                        [label]="'¿Se revisó documentación y se validó?'" formControlName="AutorizadoCierre"
                        [tabIndex]="3" [widthTextColumns]="1" [widthLabelColumns]="7" />


                    </div>
                  </app-panel-base>
                  <div class="row gx-3">
                    <div class="col-4">
                      <a (click)="close()" href="javascript:;"
                        class="btn btn-danger fs-14px rounded-3 fw-bold mb-0 d-block py-3"
                        data-bs-dismiss="modal">Cancelar</a>
                    </div>
                    <div class="col-8">
                      <a (click)="acceptEdit()" href="javascript:;" data-bs-dismiss="modal"
                        class="btn btn-green fs-14px rounded-3 fw-bold d-flex justify-content-center align-items-center py-3 m-0 ">Cambiar
                        Estado de la Caja<i class="fa fa-share-from-square ms-3"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-template>

  </div>
</app-container-base>
