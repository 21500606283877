import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { ContabilidadService } from '../../services/contabilidad.service';

@Component({
  selector: 'reporte-estado-de-cuenta-contable-page',
  templateUrl: './reporte-estado-de-cuenta-contable-page.component.html',
  styles: [
  ]
})
export class ReporteEstadoDeCuentaContablePageComponent implements OnInit {

  private utilsService = inject(UtilsService);
  private fb = inject(FormBuilder);
  private mService = inject(ModalService);
  private reportsService = inject(ReportsService);
  private contabilidadService = inject(ContabilidadService);
  loading: boolean = false;

  listaAnios: any[] = [];
  anioSeleccionado?: ComboBoxEntity;

  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Num1:0,
    Num2:0,
    Num3:0,
    Num4:0,
    Dato1:''
  });


  @Input()
  idEmpresa: number=0;

  @Input()
  cuenta: string="";

  listaMeses: any[] = [];

  public filtrosReporte: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Reporte estado de cuenta contable',
      NombreExcel: 'Estado de cuenta.xlsx'
    };




  checkTodoElAnio: boolean = false;

  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  ordenamientoSeleccionado?: ComboBoxEntity;

  get getMes1(): ComboBoxEntity | null {
    return this.mes1Seleccionado!;
  }

  get getMes2(): ComboBoxEntity | null {
    return this.mes2Seleccionado!;
  }


  get getOrdenamiento(): ComboBoxEntity | null {
    //return this.myForm.get('Num3')?.value;
    return this.ordenamientoSeleccionado!;
  }



  get selectAnioManualItem(): ComboBoxEntity | null {
    return this.anioSeleccionado!;
  }


  ngOnInit(): void {


    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;

    // this.myForm.get('Num1')?.setValue(mesItem.Clave);
    // this.myForm.get('Num2')?.setValue(mesItem.Clave);
    // this.myForm.get('Num8')?.setValue(this.anio.Id);

    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        this.loading = false;
        this.loading = false;
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('NombreReporte')?.setValue('ESTADO DE CUENTA CONTABLE ' + this.rangoFechas);
      }
    })



  }

  changeOpc(value: boolean) {
    this.checkTodoElAnio = value;
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {

    if (field == 'mes1') {
      this.mes1Seleccionado = entity;
    } else if (field == 'mes2') {
      this.mes2Seleccionado = entity;

    } else if (field == 'mes2') {
      this.anioSeleccionado = entity;
    }
    this.myForm.get('NombreReporte')?.setValue('ESTADO DE CUENTA CONTABLE ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    let result = "";
    let fecha1: Date = new Date(this.anioSeleccionado!.Id, this.mes1Seleccionado!.Id - 1, 1);
    let fecha2: Date = new Date(this.anioSeleccionado!.Id, this.mes2Seleccionado!.Id - 1, 1);

    result = this.utilsService.getEtiquetaRangoPorFechas(fecha1, fecha2, 1);
    return result.toUpperCase();
    return "";
  }



  closeModal() {
    this.mService.closeModal(null);
  }

  accept() {

    this.myForm.get('Num1')?.setValue(this.idEmpresa);
    this.myForm.get('Num2')?.setValue(this.mes1Seleccionado!.Id);
    this.myForm.get('Num3')?.setValue(this.mes2Seleccionado!.Id);
    this.myForm.get('Num4')?.setValue(this.anioSeleccionado!.Id);
    this.myForm.get('Dato1')?.setValue(this.cuenta);

    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteEstadoCuentaContable');
  }






}
