import { Component, OnInit, NgModule, EventEmitter, ViewChild, ElementRef, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Mes, NivelEnviar } from '../../interfaces/tipopoliza.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventListenersModifier } from '@popperjs/core/lib/modifiers/eventListeners';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CuentaContable } from '../../interfaces/cuentacontable.interface';
import { Result } from 'src/app/auth/interfaces';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-xml-catalogo-cuentas',
  templateUrl: './xml-catalogo-cuentas.component.html',
  styles: [
  ]
})
export class XmlCatalogoCuentasComponent implements OnInit {
  private eventsService = inject(EventsService);
  @ViewChild('txtAnio')
  public txtAnio!: ElementRef<NumberBoxComponent>;
  private readonly baseUrl: string = environment.baseUrlApi;
  rfcActual: string = "";
  public myForm: FormGroup = this.fb.group({
    Anio: [0],
    Mes: [{} as Mes],
    Nivel: [{} as NivelEnviar],
  })
  constructor(private fb: FormBuilder, private reportsService: ReportsService, private http: HttpClient) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      const date = new Date();
      this.myForm.reset({
        Anio: date.getFullYear(),
        Mes: { Id: date.getMonth() + 1, Clave: date.getMonth() + 1, Nombre: this.getListaMeses()[date.getMonth()].Nombre.toUpperCase(), ClaveNombre: '' },
        Nivel: { Id: 1, Clave: 1, Nombre: 'Primer Nivel', ClaveNombre: '' }
      });

      const txt: any = this.txtAnio;
      txt.tagInput.nativeElement.focus()
      this.getRfc();
    }, 100);
  }

  getListaMeses(): any[] {
    const lista = [{ Id: 1, Clave: 1, Nombre: "Enero" },
    { Id: 2, Clave: 2, Nombre: "Febrero" },
    { Id: 3, Clave: 3, Nombre: "Marzo" },
    { Id: 4, Clave: 4, Nombre: "Abril" },
    { Id: 5, Clave: 5, Nombre: "Mayo" },
    { Id: 6, Clave: 6, Nombre: "Junio" },
    { Id: 7, Clave: 7, Nombre: "Julio" },
    { Id: 8, Clave: 8, Nombre: "Agosto" },
    { Id: 9, Clave: 9, Nombre: "Septiembre" },
    { Id: 10, Clave: 10, Nombre: "Octubre" },
    { Id: 11, Clave: 11, Nombre: "Noviembre" },
    { Id: 12, Clave: 12, Nombre: "Diciembre" }]
    return lista;
  }

  getRfc() {
    this.http.get<Result>(`${this.baseUrl}/Config/ObtenerRfcEmpresaSesion`).subscribe((result) => {
      this.rfcActual = result.message.toUpperCase();
    });
  }

  public filtrosCuentasContables: ReportFilter = {
    ReportHeader: {
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Cuentas Contables'
    } as ReportHeader,
    NombreReporte: 'CATÁLOGO DE CUENTAS CONTABLES',
    TituloVisor: 'Cuentas Contables',
    NombreExcel: 'CuentasContables.xlsx',
  };

  generar() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let nivel = this.myForm.value.Nivel.Id;
    if (nivel == 3) { nivel = 0; }
    this.http.get(`${this.baseUrl}/Contabilidad/GenerarXmlCatalogoCuentas?numeroNivel=${nivel}&anio=${this.myForm.value.Anio}&mes=${this.myForm.value.Mes.Id}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (d) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${this.rfcActual}${this.myForm.value.Anio}${(this.myForm.value.Mes.Id.toString().padStart(2, '0'))}CT.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      }

    })



  }


  print() {
    this.reportsService.printReport(this.filtrosCuentasContables, '/Contabilidad/ReporteCuentasContables', false);
  }

  verificarErrores() {
    this.reportsService.printReportWithoutFilters('/Contabilidad/ObtenerCuentasConError', "Cuentas Contables con Error");
    // this.saving = true;
    // return this.http.get<CuentaContable[]>(`${this.baseUrl}/Contabilidad/ObtenerCuentasConError`).subscribe((cuentas) => {
    //   this.saving = false;
    //   console.log(cuentas);
    // });
  }

  selectedCombo(ent: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(ent);
  }
}
