<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-arrow-trend-up" [hasBar]="false"
  subTitle="Mantenimiento de Máximos, Mínimos y Puntos de Reorden">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div class="col-sm-6">
          <app-panel-base title="Filtros de búsqueda">
            <div class="panel-content">
              <components-ui-text-box [enableIcon]="true" [maxLength]="5000" [tabIndex]="1"
                [label]="'Clasificación producto'" formControlName="Clasificacion" [widthTextColumns]="9"
                [widthLabelColumns]="3" #txtClase (onClicIcon)="clickBuscar('ClasificacionProducto', 'Clasificacion')"
                (keydown.f2)="clickBuscar('ClasificacionProducto', 'Clasificacion')" />

              <components-ui-text-box [enableIcon]="true" [maxLength]="5000" [tabIndex]="2" [label]="'Tipo producto'"
                formControlName="Tipo" [widthTextColumns]="9" [widthLabelColumns]="3"
                (onClicIcon)="clickBuscar('TipoProducto', 'Tipo')"
                (keydown.f2)="clickBuscar('TipoProducto', 'Tipo')" />

              <components-ui-text-box [enableIcon]="true" [maxLength]="5000" [tabIndex]="3" [label]="'Códigos'"
                formControlName="Codigos" [widthTextColumns]="9" [widthLabelColumns]="3"
                (onClicIcon)="clickBuscar('Producto', 'Codigos')"
                (keydown.f2)="clickBuscar('Producto', 'Codigos')" />

              <components-ui-text-box [enableIcon]="true" [maxLength]="5000" [tabIndex]="4" [label]="'Excluir códigos'"
                formControlName="ExcluirCodigos" [widthTextColumns]="9" [widthLabelColumns]="3"
                (onClicIcon)="clickBuscar('Producto', 'ExcluirCodigos')"
                (keydown.f2)="clickBuscar('Producto', 'ExcluirCodigos')"  />

              <div class="form-group row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-9" style="text-align: right;">
                  <div (click)="searchPrices()" style="margin-right: 15px;" class="btn btn-primary">Buscar</div>
                </div>
              </div>

            </div>
          </app-panel-base>
        </div>
        <div class="col-sm-6">
          <app-panel-base title="Importacion/Exportación" style="height:189px;">
            <div class="panel-content">
              <div class="btn-group-vertical" style="margin-top:10px;">
                <div (click)="searchFile()" class="btn btn-primary mb-1">Importar CSV</div>
                <div (click)="exportData()" class="btn btn-primary {{source.length>0?'':'disabled'}}">Exportar a CSV
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
      </div>

      <app-panel-base title="Carga de máximos, minimos y puntos de reorden">
        <div class="panel-content">
          <div style="min-height: 250px;width:1035px;">
            <div class="alert alert-success" style="background-color: #348fe273 !important; padding: 4px;">
              <strong>NOTAS:</strong>
              Una vez listados los productos puede agregar la información de máximo, mínimo y puntos de reorden
              <a class="alert-link" (click)="downloadFileExample()" style="cursor: pointer;">Descargar archivo csv de
                ejemplo</a>.
            </div>
            <div id="divCargaPrecios">
              <gui-grid *ngIf="source.length>0; else vacio" [autoResizeWidth]="false" [loading]="loading" [width]="1035"
                [source]="source" [maxHeight]="250" [rowHeight]="20" [virtualScroll]="true" [rowSelection]="false"
                [theme]="'fabric'" (sourceEdited)="sourceEdited($event)" (cellEditSubmitted)="cellEditSubmitted()"
                (cellEditEntered)="cellEditEntered($event)" [cellEditing]="cellEditing">
                <gui-grid-column header="Sucursal" [width]="100" [field]="'Descripcion'" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.sucursal.clave}} - {{item.sucursal.nombre}}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Código" [width]="100" [field]="'Descripcion'" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.producto.clave}}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column header="Descripción" [width]="200" [field]="'Descripcion'" [cellEditing]="false">
                  <ng-template let-item="item">
                    {{item.producto.nombre}}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column [align]="GuiColumnAlign.RIGHT" header="Máximo" [width]="100" [field]="'maximo'"
                  [cellEditing]="true" [type]="'number'">
                  <ng-template let-value="value">
                    {{value > 0 ? (value | number:'1.2-6'): ''}}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column [align]="GuiColumnAlign.RIGHT" header="Mínimo" [width]="100" [field]="'minimo'"
                  [cellEditing]="true" [type]="'number'">
                  <ng-template let-value="value">
                    {{value > 0 ? (value | number:'1.2-6'): ''}}
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column [align]="GuiColumnAlign.RIGHT" header="Reorden" [width]="100" [field]="'puntoReorden'"
                  [cellEditing]="true" [type]="'number'">
                  <ng-template let-value="value">
                    {{value > 0 ? (value | number:'1.2-6'): ''}}
                  </ng-template>
                </gui-grid-column>
              </gui-grid>
            </div>
            <div class="alert alert-success"
              style="background-color: #348fe273 !important; margin-top: 3px;padding: 4px;text-align: right;">
              <strong>Número de registros:{{ source.length | number }}</strong>
            </div>
            <div class="form-group row" *ngIf="source.length>0;">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-9" style="text-align: right;">
                <div (click)="guardar()" style="margin-right: 0px; margin-top: 5px;" class="btn btn-primary">Guardar
                </div>
              </div>
            </div>
          </div>

          <ng-template #vacio>
            <div class="card bg-light border-0 text-dark" style="width: 1035px; height: 250px;">
              <div class="card-body">
                <h3 class="card-title" style="text-align: center;">No hay elementos para mostrar</h3>
                <p class="card-text" style="text-align: center;">Coloque los filtros y presione el botón buscar o bien
                  importe los registros.</p>
              </div>
            </div>
          </ng-template>
        </div>
      </app-panel-base>
      <input (change)="onchange($event)" [accept]="'.csv,.CSV'" tabindex="0" id="fileUpload" type="file"
        style="display: none;" #txtFile />
    </form>
  </div>
</app-container-base>

<ng-template #ctrlBusqueda let-modal let-c="close">
  <app-data-search titulo="Búsqueda" [columns]="columns" [entityName]="entityName" [filter]="filter"
    [propertys]="propertys" orders="Clave|asc" />
</ng-template>
