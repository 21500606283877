import { Component } from '@angular/core';

@Component({
  selector: 'screen-loading',
  templateUrl: './screen-loading.component.html',
  styleUrls: [ './screen-loading.component.css'  ]
})
export class ScreenLoadingComponent {

}
