import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { AcumuladoSinAnticiposMensual, AnticipoDisponibleDTO, CfdiEstadoSAT, ExistenciaProducto, HistoricoVenta, Venta, VentaRelacionDTO } from '../interfaces/venta.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ClasificacionProducto, Producto } from 'src/app/Inventarios/interfaces/producto.interface';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { AperturaCaja, Caja } from '../interfaces/caja.interface';
import { ConsultaProduccion, OrdenManufactura } from 'src/app/Manufactura/interfaces/manufacura.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { FacturaPendienteRep, ReciboElectronicoPago } from 'src/app/Inventarios/interfaces/rep.interface';
import { ConstanciaRetencion } from '../interfaces/retenciones.interface';
import { ConceptosCarga, EntregaNuestra } from '../interfaces/cargaentrega.interface';
import { PresupuestoVentaSucursalDTO, PresupuestoVentaVendedorDTO } from '../interfaces/presupuestos.interface';
import { OrdenCarga } from '../interfaces/ordencarga.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import * as moment from 'moment';
import { PrecioDTO } from '../interfaces/precio.interface';
import { TokenPrecio } from 'src/app/configuracion/interfaces/tokens.interface';
import { SolicitudCancelacionInternaDTO } from '../interfaces/solicitudcancelacioninterna.interface';

@Injectable({ providedIn: 'root' })
export class FacturacionService {
  constructor(private http: HttpClient, private reportsService: ReportsService) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;
  private eventsService = inject(EventsService);
  printDocument(pdf: ArrayBuffer) {
    let blob = new Blob([pdf], {
      type: 'application/pdf'
    });

    const blobUrl = window.URL.createObjectURL((blob));
    const iframe: any = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  base64ToArrayBuffer(base64: string) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }


  getTokensPorUsuario(idUsuario: number): Observable<number> {
    const params = new HttpParams().set("idUsuario", idUsuario);
    return this.http.get<number>(`${this.baseUrl}/Ventas/GetTokensPorUsuario`, { params })
  }

  verificarEstadoSAT(idVenta: number): Observable<Result> {
    const params = new HttpParams().set("idVenta", idVenta);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/VerificarEstadoSAT`, { params })
  }

  imprimirDocumentoVenta(id: number, noValido: boolean = false) {
    const params = new HttpParams()
      .set("idVenta", id)
      .set("mostrarNoValido", noValido);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirDocumento`, { params });
  }

  rechazarSolicitudInterna(idSolicitud: number, motivoRechazo: string): Observable<Result> {
    const params = new HttpParams()
      .set("idSolicitud", idSolicitud)
      .set("motivoRechazo", motivoRechazo);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/RechazarSolicitudInterna`, { params });
  }

  obtenerSolicitudesCancelacionInternas(idEmp: number, idSuc: number): Observable<SolicitudCancelacionInternaDTO[]> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucusal", idSuc);
    return this.http.get<SolicitudCancelacionInternaDTO[]>(`${this.baseUrl}/Ventas/ObtenerSolicitudesCancelacionInternas`, { params });
  }

  existeProductoEnLista(idEmp: number, idSuc: number, idProd: number, idLista: number): Observable<Result> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucusal", idSuc)
      .set("idProducto", idProd)
      .set("idLista", idLista);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ExisteProductoEnLista`, { params });
  }


  enviarSolicitudCancelacion(idVenta: number, motivoCancelacion: string, idMotivoSAT: number, uuidSustituye: string, fechaCancelacion: string): Observable<Result> {
    const params = new HttpParams()
      .set("idVenta", idVenta)
      .set("motivoCancelacion", motivoCancelacion)
      .set("idMotivoSAT", idMotivoSAT)
      .set("uuidSustituye", uuidSustituye)
      .set("fechaCancelacion", fechaCancelacion);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EnviarSolicitudCancelacionInterna`, { params });
  }


  eliminarPrecio(idPrecio: number): Observable<Result> {
    const params = new HttpParams()
      .set("idPrecio", idPrecio);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EliminarPrecio`, { params });
  }

  insertarProductoEnLista(idEmp: number, idSuc: number, idProd: number, listas: string): Observable<Result> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucusal", idSuc)
      .set("idProducto", idProd)
      .set("listas", listas);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/InsertarProductoEnLista`, { params });
  }

  obtenerListasProducto(idEmp: number, idSuc: number, idProd: number): Observable<number[]> {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucusal", idSuc)
      .set("idProducto", idProd);
    return this.http.get<number[]>(`${this.baseUrl}/Ventas/ObtenerListasProducto`, { params });
  }

  saveConsignados(json: string): Observable<Result> {
    const params = new HttpParams().set("json", json);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/GuardarConsignado`, { params });
  }

  cancelarUUID(uuid: string): Observable<Result> {
    const params = new HttpParams().set("uuid", uuid);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/CancelarSATUUID`, { params });
  }

  saveConsignadosProveedor(json: string): Observable<Result> {
    const params = new HttpParams().set("json", json);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/GuardarConsignadoProveedor`, { params });
  }

  getListasPrecios(): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerListasPrecios`);
  }

  getConsignados(idCliente: number, text: string): Observable<Result> {
    const params = new HttpParams().set("idCliente", idCliente).set("buscar", text);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerConsignadosPorCliente`, { params });
  }

  getConsignadosProveedores(idProveedor: number): Observable<Result> {
    const params = new HttpParams().set("idProveedor", idProveedor);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerConsignadosPorProveedor`, { params });
  }

  getConsignadoPorId(idConsignado: number): Observable<Result> {
    const params = new HttpParams().set("idConsignado", idConsignado);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerConsgnadoPorId`, { params });
  }

  getConsignadoProveedorPorId(idConsignado: number): Observable<Result> {
    const params = new HttpParams().set("idConsignado", idConsignado);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerConsgnadoProveedorPorId`, { params });
  }

  deleteConsignados(idConsignado: number): Observable<Result> {
    const params = new HttpParams().set("idConsignado", idConsignado);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EliminarConsignado`, { params });
  }

  deleteConsignadosProveedor(idConsignado: number): Observable<Result> {
    const params = new HttpParams().set("idConsignado", idConsignado);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EliminarConsignadoProveedor`, { params });
  }

  getOrdenCargaPorFolio(idSucursal: number, folio: number): Observable<OrdenCarga> {
    const params = new HttpParams().set("folio", folio)
      .set("idSucursal", idSucursal);
    return this.http.get<OrdenCarga>(`${this.baseUrl}/Ventas/BuscarOrdenCargaPorFolio`, { params });
  }

  getHistoricoOrdenes(fechaInicio: string, fechaFin: string, clientes: string, factura: string, esOrden: boolean): Observable<OrdenManufactura[]> {
    const params = new HttpParams().set("fechaInicio", fechaInicio)
      .set("fechaFin", fechaFin)
      .set("clientes", clientes)
      .set("factura", factura)
      .set("esOrden", esOrden);
    return this.http.get<OrdenManufactura[]>(`${this.baseUrl}/Manufactura/BuscarHistoricoOrdenes`, { params });
  }

  getPresupuestosVendedores(anio: number, mes: number): Observable<PresupuestoVentaVendedorDTO[]> {
    const params = new HttpParams().set("anio", anio).set("mes", mes);
    return this.http.get<PresupuestoVentaVendedorDTO[]>(`${this.baseUrl}/Ventas/ObtenerPresupuesosVendedoresPorAnioMes`, { params });
  }

  getPresupuestosSucursales(anio: number, mes: number): Observable<PresupuestoVentaSucursalDTO[]> {
    const params = new HttpParams().set("anio", anio).set("mes", mes);
    return this.http.get<PresupuestoVentaSucursalDTO[]>(`${this.baseUrl}/Ventas/ObtenerPresupuesosPorAnioMes`, { params });
  }

  generarProduccion(idOrden: number, jsonConceptos: string, ref: string, comentario: string): Observable<Result> {

    const params = new HttpParams().set("idOrden", idOrden)
      .set("jsonConceptos", jsonConceptos)
      .set("referencia", ref)
      .set("comentario", comentario);
    return this.http.get<Result>(`${this.baseUrl}/Manufactura/GenerarProduccion`, { params });
  }

  saveRep(ent: ReciboElectronicoPago): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarRep`, ent)
  }

  saveRet(ent: ConstanciaRetencion): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarRet`, ent)
  }

  getTableroOrdenes(): Observable<OrdenManufactura[]> {
    return this.http.get<OrdenManufactura[]>(`${this.baseUrl}/Manufactura/ObtenerTablero`);
  }

  getFamiliasTablero(): Observable<FamiliaProducto[]> {
    return this.http.get<FamiliaProducto[]>(`${this.baseUrl}/Ventas/ObtenerFamiliasTablero`);
  }

  CancelarRetencion(idRetencion: number, motivoCancelacion: string, claveCancelacion: number, uuidSustituye: string): Observable<Result> {
    const params = new HttpParams().set("idRetencion", idRetencion)
      .set("motivoCancelacion", motivoCancelacion)
      .set("claveCancelacion", claveCancelacion)
      .set("uuidSustituye", uuidSustituye);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/CancelarRetencion`, { params });
  }

  ObtenerFacturasReps(idEmpresa: number, idCliente: number): Observable<FacturaPendienteRep[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idCliente", idCliente);
    return this.http.get<FacturaPendienteRep[]>(`${this.baseUrl}/Ventas/ObtenerFacturasREP`, { params });
  }

  ObtenerTipoDeCambio(idMoneda: number, fecha: string): Observable<Result> {
    const params = new HttpParams().set("idMoneda", idMoneda).set("fecha", fecha);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerTipoDeCambio`, { params });
  }

  actualizarDireccionEntrega(json: string, idOrden: number): Observable<Result> {
    const params = new HttpParams().set("json", json).set("idOrden", idOrden);
    return this.http.get<Result>(`${this.baseUrl}/Manufactura/ActualizarDireccionEntrega`, { params });
  }


  getFacturado(idEmpresa: number, anio: number, mes: number, dia: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("anio", anio).set("mes", mes).set("dia", dia);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerTotalFacturado`, { params });
  }

  getAnticipoFacturado(idEmpresa: number, anio: number, mes: number, dia: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("anio", anio).set("mes", mes).set("dia", dia);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerTotalAnticipoFacturado`, { params });
  }

  getProductsByClass(idEmpresa: number, idClasificacion: number): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idClasificacion", idClasificacion);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/ObtenerProductosPorClasificaciones`, { params });
  }

  getNextFolioRet(idEmpresa: number, serie: string): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa)
      .set("serie", serie);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerSiguienteFolioRet`, { params });
  }

  getNextFolioRep(idEmpresa: number, idSucursal: number, serie: string): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa)
      .set("idSucursal", idSucursal)
      .set("serie", serie);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerSiguienteFolioRep`, { params });
  }

  printRep(idRep: number, cb: any) {
    const params = new HttpParams().set("idRep", idRep);
    this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirRep`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.printDocument(this.base64ToArrayBuffer(result.message));
        cb && cb();
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })
  }

  getNextFolio(idEmpresa: number, idSucursal: number, idDocto: number, serie: string): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa)
      .set("idSucursal", idSucursal)
      .set("idDocumento", idDocto)
      .set("serie", serie);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerSiguienteFolio`, { params });
  }

  getProductsByFamily(idEmpresa: number, idSucursal: number, idFamilia: number): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal).set("idFamilia", idFamilia);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/ObtenerProductosPorFamilia`, { params });
  }

  eliminarProductoCompuestoDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/EliminarDetalleProductoComposicion`, { params });
  }

  eliminarProductoRelacionadoDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/EliminarDetalleProductoRelacionado`, { params });
  }

  getPosSeries(idEmpresa: number, idSucursal: number): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerSeriePOs`, { params });
  }

  getOpenBox(idSucursal: number, idUsuario: number, fecha: string): Observable<Result> {
    const params = new HttpParams().set("idSucursal", idSucursal).set("idUsuario", idUsuario).set("fecha", fecha);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerCajaAbierta`, { params });
  }

  getProductsFamily(idEmpresa: number, idPadre: number): Observable<FamiliaProducto[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idPadre", idPadre);
    return this.http.get<FamiliaProducto[]>(`${this.baseUrl}/Inventarios/ObtenerFamilias`, { params });
  }

  getProductsClases(idEmpresa: number): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/ObtenerClasificaciones`, { params });
  }

  getBalance(idCliente: number, idEmpresa: number): Observable<AnticipoDisponibleDTO[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idCliente", idCliente);
    return this.http.get<AnticipoDisponibleDTO[]>(`${this.baseUrl}/Ventas/ObtenerFacturasConSaldo`, { params });
  }

  setDocumentPrinted(id: number, printed: boolean): Observable<Result> {
    const params = new HttpParams().set("id", id).set("impreso", printed);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/MarcarComoImpreso`, { params });
  }

  puedeReimprimir(idUsuario: number) {
    const params = new HttpParams().set("idUsuario", idUsuario);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/PuedeReImprimir`, { params });
  }

  obtenerRelaciones(idVenta: number) {
    const params = new HttpParams().set("idVenta", idVenta);
    return this.http.get<VentaRelacionDTO[]>(`${this.baseUrl}/Ventas/ObtenerRelacionesVenta`, { params });
  }


  getUUID(idEmp: number, idSuc: number, idDocto: number, serie: string, folio: number) {
    const params = new HttpParams().set("idEmp", idEmp)
      .set("idSuc", idSuc)
      .set("idDocto", idDocto)
      .set("serie", serie)
      .set("folio", folio)
    return this.http.get<Result>(`${this.baseUrl}/Ventas/BuscarUUID`, { params });
  }

  getDoctoType(idDocto: number) {
    const params = new HttpParams().set("idDocto", idDocto);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerTipoComprobante`, { params });
  }

  imprimirOrdenManufactura(idOrden: number) {
    let filtroOrden: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      TituloVisor: 'Reporte de orden de manufactura',
      NombreExcel: 'Reporte de orden de manufactura.xlsx',
      FilterOptions: [
        { Campo: 'cheq.Id', Etiqueta: 'Tipo caja chica', Tipo: TypeFilter.number },
      ]
    };

    let reportHeader: ReportHeader = {
      NombreReporte: 'ORDEN DE MANUFACTURA',
      Num1: idOrden
    }
    let filtro: FilterOptions | undefined = filtroOrden.FilterOptions?.find(p => p.Campo == 'cheq.Id');
    if (filtro) {
      filtro!.Valor = String(idOrden);
    }
    filtroOrden.ReportHeader = reportHeader;
    this.reportsService.printReport(filtroOrden, '/Ventas/ImprimirOrdenManufactura');
  }

  initPriceMigracion(fileName: string) {
    const params = new HttpParams().set("fileName", fileName);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/IniciarCargaPrecios`, { params });
  }

  iniciarMigracionMaximosMinimos(fileName: string) {
    const params = new HttpParams().set("fileName", fileName);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/IniciarCargaMaximosMinimos`, { params });
  }

  getCustomerAdvanceBalance(idEmp: number, idCliente: number) {
    const params = new HttpParams().set("idEmpresa", idEmp).set("idCliente", idCliente);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerAnticipoDisponible`, { params });
  }

  marcarOrdenTerminada(idOrden: number, motivo: string) {
    const params = new HttpParams()
      .set("idOrden", idOrden)
      .set("motivo", motivo);
    return this.http.get<Result>(`${this.baseUrl}/Manufactura/MarcarOrdenTerminada`, { params });
  }

  getPreciosLista() {
    return this.http.get<PrecioDTO[]>(`${this.baseUrl}/Ventas/ConsultarListaDTO`);
  }

  getTokenPrecio(precioOriginal: number, precioFinal: number, diferencia: number, serieFolio: string) {
    const params = new HttpParams()
      .set("precioOriginal", precioOriginal)
      .set("precioFinal", precioFinal)
      .set("diferencia", diferencia)
      .set("serieFolio", serieFolio);
    return this.http.get<TokenPrecio>(`${this.baseUrl}/Ventas/ObtenerTokenPrecio`, { params });
  }

  getTokenCosto(precioOriginal: number, precioFinal: number, diferencia: number, serieFolio: string) {
    const params = new HttpParams()
      .set("precioOriginal", precioOriginal)
      .set("precioFinal", precioFinal)
      .set("diferencia", diferencia)
      .set("serieFolio", serieFolio);
    return this.http.get<TokenPrecio>(`${this.baseUrl}/Ventas/ObtenerTokenPrecioCosto`, { params });
  }


  getOrdenManufactura(idEmp: number, idSuc: number, serie: string, folio: number) {
    const params = new HttpParams().set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("serie", serie)
      .set("folio", folio);
    return this.http.get<OrdenManufactura>(`${this.baseUrl}/Manufactura/BuscarOrden`, { params });
  }

  getProducciones(idOrden: number) {
    const params = new HttpParams().set("idOrden", idOrden);
    return this.http.get<ConsultaProduccion[]>(`${this.baseUrl}/Manufactura/ConsultarProducciones`, { params });
  }

  getSeriesManufactura(esOrden: boolean) {
    const params = new HttpParams().set("esOrden", esOrden);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Manufactura/ObtenerListaSeries`, { params });
  }

  getSeriesRep(idEmp: number, idSuc: number) {
    const params = new HttpParams().set("idEmpresa", idEmp).set("idSucursal", idSuc);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Ventas/ObtenerListaSeriesRep`, { params });
  }

  getSeriesRet(idEmp: number) {
    const params = new HttpParams().set("idEmpresa", idEmp);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Ventas/ObtenerListaSeriesRet`, { params });
  }

  getSeries(idEmp: number, idSuc: number, idDocto: number) {
    const params = new HttpParams().set("idEmpresa", idEmp).set("idSucursal", idSuc).set("idDocumento", idDocto);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Ventas/ObtenerListaSeries`, { params });
  }

  canPrint(id: number, idVenta: number): Observable<Result> {
    const params = new HttpParams().set("idDocumentoVenta", id).set("idVenta", idVenta);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/PuedeImprimirPdf`, { params });
  }

  obtenerCorreosCliente(idCliente: number): Observable<Result> {
    const params = new HttpParams().set("idCliente", idCliente);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerCorreoPorCliente`, { params });
  }


  ObtenerHistoricoCliente(idEmpresa: number, idCliente: number, idDocumento: number, fechaInicio: string, fechaFin: string) {
    const params = new HttpParams().set("idEmpresa", idEmpresa)
      .set("idCliente", idCliente)
      .set("idDocumento", idDocumento)
      .set("fechaInicio", fechaInicio)
      .set("fechaFin", fechaFin);
    return this.http.get<HistoricoVenta[]>(`${this.baseUrl}/Ventas/ObtenerHistorico`, { params });
  }

  ObtenerFechaPorSucursal(id: number) {
    const params = new HttpParams().set("idSucursal", id);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerFechaPorSucursal`, { params });
  }


  ObetenerAplicaEstimulo(idEmpresa: number, idSucursal: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal);
    return this.http.get<boolean>(`${this.baseUrl}/Fiscal/ObetenerAplicaEstimulo`, { params });
  }

  VerificarCodigosPostales() {
    return this.http.get<void>(`${this.baseUrl}/Fiscal/VerificarCodigosPostales`);
  }

  obtenerPendientesEntregar(idVenta: number = 0, idTraspaso: number = 0, idRemision: number = 0, serie: string = "", folio: number = 0, consulta: boolean = false) {
    const params = new HttpParams()
      .set("idVenta", idVenta)
      .set("idTraspaso", idTraspaso)
      .set("idRemision", idRemision)
      .set("serie", serie)
      .set("folio", folio)
      .set("consulta", consulta);
    return this.http.get<ConceptosCarga[]>(`${this.baseUrl}/Ventas/ObtenerPendientesEntregar`, { params });
  }

  ObtenerDetalleProductos(id: number, tipo: number) {
    const params = new HttpParams().set("id", id).set("tipo", tipo);
    return this.http.get<EntregaNuestra[]>(`${this.baseUrl}/Ventas/ObtenerDetalleProductos`, { params });
  }

  guardarOrdenCarga(ent: any): Observable<Result> {
    const params = new HttpParams().set("orden", ent);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/GuardarOrdenCarga`, { params });
  }

  obtenerOrdenesDeCargaEntregadas(folio: number): Observable<any> {
    const params = new HttpParams().set("folio", folio);
    return this.http.get<any>(`${this.baseUrl}/Ventas/BuscarOrdenCargaEntregada`, { params });
  }

  ObtenerOrdenCargaPorTipo(id: number, idVenta: number, idTraspaso: number, idRemision: number): Observable<any> {
    const params = new HttpParams()
      .set("idOrden", id)
      .set("idVenta", idVenta)
      .set("idTraspaso", idTraspaso)
      .set("idRemision", idRemision);
    return this.http.get<any>(`${this.baseUrl}/Ventas/ObtenerOrdenCargaPorTipo`, { params });
  }

  busquedaProductos(filtro: string, idCliente: number): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro).set("idCliente", idCliente);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/BuscarProductos`, { params });
  }

  ordenCargaDevolverProductos(orden: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Ventas/OrdenCargaDevolverProductos`, orden);
  }

  obtenerOrdenesDeCargaPendientes(): Observable<OrdenCarga[]> {
    return this.http.get<OrdenCarga[]>(`${this.baseUrl}/Ventas/ObtenerOrdenesCargaPendientes`);
  }

  sendEmail(id: number, correos: string) {
    const params = new HttpParams().set("idVenta", id).set("correos", correos);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EnviarPorEmail`, { params });
  }

  sendRepEmail(id: number, correos: string = "") {
    const params = new HttpParams().set("idRep", id).set("correos", correos);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EnviarRepPorEmail`, { params });
  }

  tieneRepPagoRelacionado(idRep: number) {
    const params = new HttpParams().set("idRep", idRep);
    return this.http.get<boolean>(`${this.baseUrl}/Ventas/TienePagoRelacionado`, { params });
  }

  obtenerTipoCambioDof(anio: number, mes: number, dia: number) {
    const params = new HttpParams()
      .set("anio", anio)
      .set("mes", mes)
      .set("dia", dia);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerTipoCambioDOF`, { params });
  }

  obtenerTipoCambioVentas(fecha: string) {
    const params = new HttpParams()
      .set("fecha", fecha);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerTipoCambioVentas`, { params });
  }

  obtenerTipoCambioCompras(fecha: string) {
    const params = new HttpParams()
      .set("fecha", fecha);
    return this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerTipoCambioCompras`, { params });
  }

  utilizaSegundoNombre(idSucursal: number) {
    const params = new HttpParams().set("idSucursal", idSucursal);
    return this.http.get<boolean>(`${this.baseUrl}/Config/UtilizaSegundoNombre`, { params });
  }


  cancelarPagoCXC(e: any, idPago: number, idRep: number, cb: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date = moment(e.Fecha).format("DD/MM/YYYY HH:mm:ss");
    const params = new HttpParams()
      .set("idPago", idPago)
      .set("idRep", idRep)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("idMotivoSAT", e.MotivoSAT ? e.MotivoSAT.Id : 0)
      .set("fechaCancelacion", date)
      .set("esDevolucionCheque", e.EsDevolucionCheque)
      .set("uuidSustituye", e.UUID);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorCobrar/CancelarPago`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        cb && cb();
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  verifyStatusRep(idRep: number, cb: any) {
    const params = new HttpParams().set("idRep", idRep);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/VerificarEstadoSATRep`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const r: CfdiEstadoSAT = JSON.parse(result.message);
        if (r.EsCancelable == "No cancelable") {
          this.eventsService.publish('home:showAlert', { message: `El documento no es Cancelable, verifique que no esté relacionado a otro CFDI.`, cancelButton: false });
          return;
        }
        if (r.Estado == "No Encontrado") {
          this.eventsService.publish('home:showAlert', { message: `El CFDI no fue encontrado en los servidores del SAT, por favor intente de nuevo mas tarde.`, cancelButton: false });
          return;
        }
        cb && cb(r);
        // this.estadoSAT = r;
        // this.openCancel();
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se pudo consultar el estatus del CFDI ¿desea continuar con la cancelación?", onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              const r: CfdiEstadoSAT = JSON.parse(result.message);
              cb && cb(r);
            }
          }
        });
      }
    });
  }

  findRetById(id: number) {
    const params = new HttpParams()
      .set("id", id);
    return this.http.get<ConstanciaRetencion>(`${this.baseUrl}/Ventas/ObtenerRetPorId`, { params });
  }

  findEntityById(id: number) {
    const params = new HttpParams()
      .set("id", id);
    return this.http.get<Venta>(`${this.baseUrl}/Ventas/ObtenerVentaPorId`, { params });
  }

  findEntityByParamsToCopy(idEmp: number, idSuc: number, idDocto: number, serie: string, folio: number) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("idDoct", idDocto)
      .set("serie", serie)
      .set("folio", folio)
    return this.http.get<Venta>(`${this.baseUrl}/Ventas/ObtenerVentaPorParametrosCopiar`, { params });
  }

  findEntityByParams(idEmp: number, idSuc: number, idDocto: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("idDoct", idDocto)
      .set("serie", serie)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<Venta>(`${this.baseUrl}/Ventas/ObtenerVentaPorParametros`, { params });
  }

  findRetByParams(idEmp: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("serie", serie)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<ConstanciaRetencion>(`${this.baseUrl}/Ventas/ObtenerRetPorParametros`, { params });
  }

  findRepByParams(idEmp: number, idSuc: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("serie", serie)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<ReciboElectronicoPago>(`${this.baseUrl}/Ventas/ObtenerRepPorParametros`, { params });
  }

  getExistencia(idProducto: number, idSucursal: number, idAlmacen: number) {
    const params = new HttpParams().set("idProducto", idProducto)
      .set("idSucursal", idSucursal)
      .set("idAlmacen", idAlmacen);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerExistencia`, { params });
  }

  getExistenciaAlmacenCargaEntrega(idProducto: number, idSucursal: number, idAlmacen: number) {
    const params = new HttpParams().set("idProducto", idProducto)
      .set("idSucursal", idSucursal)
      .set("idAlmacen", idAlmacen);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerExistenciaCargaEntrega`, { params });
  }


  getTickets(idEmp: number, idSuc: number, dateBeg: string, dateEnd: string) {
    const params = new HttpParams().set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("fechaIncio", dateBeg)
      .set("fechaFin", dateEnd);
    return this.http.get<Venta[]>(`${this.baseUrl}/Ventas/ObtenerTicketsFacturar`, { params });
  }

  downloadFilesRet(id: number) {
    const params = new HttpParams().set("idVenta", id);//.set("responseType", "arraybuffer");
    return this.http.get(`${this.baseUrl}/Ventas/DescargarArchivosRet?idRep=${id}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      console.log(d);
      if (d) {
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  downloadFilesRep(id: number) {
    const params = new HttpParams().set("idVenta", id);//.set("responseType", "arraybuffer");
    return this.http.get(`${this.baseUrl}/Ventas/DescargarArchivosRep?idRep=${id}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      console.log(d);
      if (d) {
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  obtenerTicket(idVenta: number) {
    const params = new HttpParams().set("idVenta", idVenta);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerTicketImpresion`, { params });
  }


  imprimeTicket(idSerie: number) {
    const params = new HttpParams().set("idSerie", idSerie);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/EsImpresionTicket`, { params });
  }

  imprimirDocumento(id: number) {
    const params = new HttpParams().set("idVenta", id);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirDocumento`, { params });
  }

  downloadFiles(id: number) {
    const params = new HttpParams().set("idVenta", id);//.set("responseType", "arraybuffer");
    return this.http.get(`${this.baseUrl}/Ventas/DescargarArchivos?idVenta=${id}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      console.log(d);
      if (d) {
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert('Please disable your Pop-up blocker and try again.');
        }
      }

    })
  }

  obtenerNumeroLoteTerminal(idUsuario: number, fecha: string, idTerminal: number): Observable<string> {
    const params = new HttpParams().set("idUsuario", idUsuario).set("fecha", fecha).set("idTerminal", idTerminal);
    return this.http.get<string>(`${this.baseUrl}/Ventas/ObtenerNumeroLote`, { params });
  }

  // printEntity(entity: string): Observable<Result> {
  //   const params = new HttpParams().set("entidad", entity)
  //   return this.http.get<Result>(`${this.baseUrl}/Base/ImprimirEntidad`,{params});
  // }



  // ReporteDiarioDeVentas(reportFilter: ReportFilter): Observable<Result> {
  //   return this.http.post<any>(`${this.baseUrl}/Ventas/ReporteDiarioDeVentas`, reportFilter);
  // }


  // ReporteVerificadorVentas(reportFilter: ReportFilter): Observable<Result> {
  //   return this.http.post<any>(`${this.baseUrl}/Ventas/ReporteVerificadorVentas`, reportFilter);
  // }

  ReporteAnticiposfacturados(reportFilter: ReportFilter): Observable<Result> {
    return this.http.post<any>(`${this.baseUrl}/Ventas/ReporteAnticiposFacturados`, reportFilter);
  }

  getCuentasBancariasClientes(clienteId: number) {
    return this.http.get(`${this.baseUrl}/Ventas/GetCuentasBancariasClientes?clienteId=${clienteId}`);
  }

  ObtenerVentasMensuales(idEmpresa: number, anio: number): Observable<AcumuladoSinAnticiposMensual[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("anio", anio);
    return this.http.get<AcumuladoSinAnticiposMensual[]>(`${this.baseUrl}/Ventas/ObtenerAcumuladosMensuales`, { params });
  }
}
