import { Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { AppSettings } from '../../../services/app-settings.service';



@Component({
  selector: 'email-detail',
  templateUrl: './email-detail.html'
})

export class EmailDetailPage implements OnDestroy, OnInit {
  public isCollapsed = true;

  constructor(public appSettings: AppSettings) {
  }

  ngOnInit() {
    this.appSettings.appContentFullHeight = true;
    this.appSettings.appContentClass = 'p-0';
  }

  ngOnDestroy() {
    this.appSettings.appContentFullHeight = false;
    this.appSettings.appContentClass = '';
  }
}
