<div  class="card text-center border-0" cdkDrag
  [style]="getStyle">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar/editar mercancía
  </div>
  <div class="card-body bg-light" (keydown)="keydown($event)">

    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">
        <div class="card-header h6 mb-1 bg-none p-2 text-orange">
          <i class="fa fa-truck-ramp-box fa-lg fa-fw me-1"></i>
          Datos de la mercancía a envíar
        </div>
        <form [formGroup]="myForm" autocomplete="off">
          <components-ui-text-box (keydown.f2)="abrirBusquedaClaves()" [tabIndex]="tabIndex+1" [maxLength]="1000"
            [label]="'Descripción'" formControlName="Descripcion" [widthTextColumns]="8" [widthLabelColumns]="4"
            #txtDescripcion />
          <div class="d-flex">
            <div style="width: 267px;">
              <components-ui-text-box [enabled]="false" [tabIndex]="tabIndex+2" [maxLength]="20"
                [label]="'Clave Prod Serv'" formControlName="BienesTransp" [widthTextColumns]="5"
                [widthLabelColumns]="7" />
            </div>
            <div style="margin-top: 3px;">
              <i (click)="abrirBusquedaClaves()" class="fas fa-magnifying-glass fa-lg" style="cursor:pointer;color: orange;"></i>
            </div>
          </div>
          <components-ui-number-box (keydown.enter)="verificarAbrirUnidades()" [tabIndex]="tabIndex+3" [maxLength]="10"
            [label]="'Cantidad'" formControlName="Cantidad" [widthTextColumns]="2" [widthLabelColumns]="4"
            #txtCantidad />

          <div class="d-flex">
            <div style="width: 267px;">
              <components-ui-text-box [enabled]="false" [tabIndex]="tabIndex+4" [maxLength]="20"
                [label]="'Clave unidad'" formControlName="ClaveUnidad" [widthTextColumns]="5" [widthLabelColumns]="7" />
            </div>
            <div style="margin-top: 3px;">
              <i (click)="abrirBusquedaUnidades()" class="fas fa-magnifying-glass fa-lg" style="cursor:pointer;color: orange;"></i>
            </div>
          </div>

          <components-ui-number-box [tabIndex]="tabIndex+5" [maxLength]="10" [label]="'Peso KG'"
            formControlName="PesoEnKG" [widthTextColumns]="2" [widthLabelColumns]="4" #txtPeso />

          <component-ui-check-box [enabled]="!myForm.value.MaterialPeligroso" [tabIndex]="tabIndex+6"
            [widthLabelColumns]="4" formControlName="ColocarMaterialPeligroso" [widthTextColumns]="1"
            [label]="'Colocar NO en material peligroso'" />

          <component-ui-check-box (keydown.enter)="accept()" [enabled]="!myForm.value.ColocarMaterialPeligroso" [tabIndex]="tabIndex+7"
            [widthLabelColumns]="4" formControlName="MaterialPeligroso" [widthTextColumns]="1"
            [label]="'Es material peligroso'" />
        </form>
      </div>
    </div>

  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div *ngIf="!saving" style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8] Aceptar
      </div>
      <div *ngIf="!saving" (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
    </div>
  </div>
  <ng-template #busquedaProductos let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Claves de Producto y Servicio" [columns]="getColumns"
      [entityName]="'c_ClaveProdServ'" [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'"
      #busquedaProductos />
  </ng-template>

  <ng-template #busquedaUnidades let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Unidades de Medida" [columns]="getColumns" [entityName]="'c_Unidad'"
      [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busquedaUnidades />
  </ng-template>
</div>
