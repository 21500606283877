import { Component, ElementRef, ViewChild, inject, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';


@Component({
  selector: 'app-balanza-comprobacion-page',
  templateUrl: './balanza-comprobacion-page.component.html'
})
export class BalanzaComprobacionPageComponent implements OnInit, AfterViewInit{

  private utils = inject(UtilsService);
  private contabilidadService = inject(ContabilidadService);
  private eventsService = inject(EventsService)


  listaMeses: any[] = [];
  listaAnios: any[] = [];
  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  anioSeleccionado?: ComboBoxEntity;
  loading: boolean = false;
  menuId: string = "balanza-comprobacion";


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-Cuenta', Orden: 'empClave,sucClave' }]


  public filtrosReporte: ReportFilter =
    {
      menuId: '',
      EsContabilidad:true,
      ReportHeader: {} as ReportHeader,
      Desglose: 'Rango de meses 4 columnas',
      DesgloseLista: [{ Nombre: 'Rango de meses 4 columnas' },{Nombre: 'Rango de meses 6 columnas'}],
      NombreReporte: '',
      TituloVisor: 'Balanza de comprobacion',
      NombreExcel: 'Balanza de comprobacion.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'mpactual.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mpactual.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Mes1:0,
    Mes2:0,
    Anio1:0
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }
  ngAfterViewInit(): void {

    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
    this.myForm.get('Mes1')?.setValue(mesItem.Clave);
    this.myForm.get('Mes2')?.setValue(mesItem.Clave);
  }

  ngOnInit(): void {

    this.focus('txtNombreReporte');
    this.listaMeses = this.utils.getListaMeses();

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.loading = false;
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }

        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('Anio1')?.setValue(this.anioSeleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('BALANZA DE COMPROBACIÓN '+ this.rangoFechas);

        this.filtrosReporte.menuId = this.menuId;
      }
    })
  }

  get rangoFechas() {
    let fecha1:Date = new Date(this.anioSeleccionado?.Id!,this.mes1Seleccionado?.Id!-1,1);
    let fecha2:Date = new Date(this.anioSeleccionado?.Id!,this.mes1Seleccionado?.Id!-1,1);
    return this.utils.getEtiquetaRangoPorFechas(fecha1, fecha2, 2).toUpperCase();

  }

  onChangeFecha(event: any) {
    this.myForm.get('NombreReporte')?.setValue('BALANZA DE COMPROBACIÓN ' + this.rangoFechas);
  }


  reportHeaderChange(value:any){
    let a: any = 0;
    if (value) {
      if (value.Mes1 && value.Mes2) {
        let mesItem: any = this.listaMeses.find(p => p.Id == value.Mes1);
        this.mes1Seleccionado = mesItem;
        mesItem = this.listaMeses.find(p => p.Id == value.Mes2);
        this.mes2Seleccionado = mesItem;
      }
      if (value.Anio1) {
        let anioItem: any = this.listaAnios.find(p => p.Id == value.Anio1);
        this.anioSeleccionado = anioItem;
      }
    }
    this.myForm.get('Anio1')?.setValue(this.anioSeleccionado!.Clave);
    this.myForm.get('Mes1')?.setValue(this.mes1Seleccionado!.Clave);
    this.myForm.get('Mes2')?.setValue(this.mes2Seleccionado!.Clave);
    this.myForm.get('NombreReporte')?.setValue('BALANZA DE COMPROBACIÓN '+ this.rangoFechas);

  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  get getMes1(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('BALANZA DE COMPROBACIÓN ' + this.rangoFechas);
    return this.mes1Seleccionado!;
  }

  get getMes2(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('BALANZA DE COMPROBACIÓN ' + this.rangoFechas);
    return this.mes2Seleccionado!;
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteBalanzaComprobacion');
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Mes1') {
      this.mes1Seleccionado = entity;
      this.mes2Seleccionado = entity;
    } else if (field == 'Mes2') {
      this.mes2Seleccionado = entity;
    } else if (field == 'Anio1') {
      this.anioSeleccionado = entity;
    }
    this.myForm.controls[field].setValue(entity.Clave);
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('BALANZA DE COMPROBACIÓN ' + this.rangoFechas);
    return this.anioSeleccionado!;
  }


 }
