<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <ng-container *ngIf="label.length > 0">

    <label (click)="onClickLink()"
      class="col-sm-{{widthLabelColumns}} {{(topLabel ? 'text-start height-label':'tb-right')}} col-form-label {{(enableLink?'tb-link text-orange':( IsRequired?'text-red': ''))}}">
      <i *ngIf="IsRequired" class="fa fa-asterisk text-red fs-10px me-2"></i>
      {{label}}
      {{ label.length>0?separator:''}}
      {{label.length==0?"&nbsp;":""}}
    </label>
  </ng-container>
  <div class="d-flex col-sm-{{widthTextColumns}}">
    <div class="input-group">
      <input tooltipClass="complit-tooltip" [style]="getStyle" (focus)="onFocus($event)" [tabindex]="tabIndex"
        (keyup)="onKeyUp($event)" spellcheck="true" [(ngModel)]="value" (change)="onChangeTextValue($event)"
        (blur)="blurControl(txtTag.value)" maxLength="{{maxLength}}" (keypress)="onKeyPress($event)"
        (keydown)="onKeyDown($event)"
        class="form-control form-control-sm {{IsUpperCase}} {{IsDataTypeValid}} {{(IsRequired?'bottom-red': '')}}"
        type="text" placeholder="{{placeHolder}}" #txtTag [disabled]="!this.enabled"
        [ngbTypeahead]="entidadBusqueda ? buscarEntidad: ''" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
        [focusFirst]="false" />
      <div (click)="onClick()"
        style="height: 22px;padding: 2px;color:#ffa500;width: 30px;border-radius: 0px;border-radius: 0px; border: 0px;border-bottom: 1px solid #000000;"
        *ngIf="enableIcon || this.Entidad.length> 0 " class="btn btn-white"><i class="{{classIcon}} fa-lg"></i></div>
    </div>
  </div>
</div>

<ng-template #content let-modal let-c="close" *ngIf="this.Entidad.length > 0">
  <app-data-search titulo="Búsqueda" [columns]="getColumns" [entityName]="getEntityName" [filter]="getFilter"
    [propertys]="getPropertys" [orders]="getOrders" #busqueda />
</ng-template>

<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight class="highlight-clave"
    [result]="(entidadBusqueda === 'Producto' ? r.Clave : (r.Clave | padStart : 9 : '0'))" [term]="t"></ngb-highlight>
  <ngb-highlight class="highlight-clave" [result]="r.Nombre" [term]="t"></ngb-highlight>
</ng-template>
