<div class="card text-center border-0" cdkDrag
  style="z-index:99;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; position: absolute;top:183px;width: 760px;left:160px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Historico de Ordenes de Producción
    </span>
  </div>
  <div class="card-body bg-light" style="min-height: 400px; max-height: 400px;">
    <ng-scrollbar style="height: 320px;margin-top: 35px;">
      <div class="timeline">
        <div class="timeline-item">
          <div class="timeline-time">
            <span class="time">{{getDate(itemHistorico.fechaMovimiento)}}</span>
          </div>
          <div class="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
        </div>
        <div class="timeline-content">
          <div class="timeline-header">
            <div class="username" style="text-align: left;">
              <a href="javascript:;">
                {{itemHistorico.comentarios}} Usuario: {{itemHistorico.usuario.nombre}}
              </a>
            </div>
          </div>
        </div>

        <div *ngFor="let orden of lista" class="timeline-item">
          <div class="timeline-time">
            <span class="time">{{getDate(orden.fechaMovimiento)}}</span>
          </div>
          <div class="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          <div class="timeline-content">
            <div class="timeline-header">
              <div class="username" style="text-align: left;">
                <div class="invoice-company text-red">
                  {{orden.fechaCancelacion ? ('CANCELADO el ' + getDate(orden.fechaCancelacion) + ' '
                  + ' Usuario: ' + orden.usuarioCancela.nombre ):''}}
                </div>
                <div class="invoice-company text-red">
                  {{orden.fechaCancelacion ? ('Motivo: ' + orden.motivoCancelacion):''}}
                </div>
                <a href="javascript:;">
                  {{'Orden: ' + orden.serieString + orden.folio}}
                  <br />
                  <div [innerHTML]="getListaHtml(orden.comentarios)"></div>
                </a>
              </div>
              <div>
                <a *ngIf="!orden.fechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"
                  (click)="openCancel(orden.id)"><i class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i>
                  Cancelar</a>
                <a class="btn btn-sm btn-white mb-10px" (click)="imprimirOrden(orden.id)"><i
                    class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
                  Imprimir</a>
              </div>
            </div>
            <div class="timeline-body">
              <div class="mb-3">
                <app-panel-base title="Conceptos">
                  <div class="panel-content">
                    <table class="blueTable" style="width:500px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 30px;">Clave</th>
                          <th style="width: 100px;">Descripción</th>
                          <th style="width: 30px;">Cantidad</th>
                          <th style="width: 30px;">Medida</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
                          *ngFor="let item of orden.productos; let index = index;">
                          <td class="text-dark" style="width:250px">
                            {{item.claveProd}}
                          </td>
                          <td class="text-dark" style="width:80px">
                            {{item.productoProducido}}
                          </td>
                          <td class="text-dark" style="width:30px;padding-right:5px;text-align: right;">
                            {{item.cantidad|number:'1.2-6'}}
                          </td>
                          <td class="text-dark" style="width:70px;">
                            {{item.unidad.nombre}}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </app-panel-base>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
    <!-- -->
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
  <ng-template #modalCancelOrdenProduccion let-modal let-c="close">
    <opcion-cancelacion-orden-produccion-page [idOrden]="this.idOrdenCancelar" />
  </ng-template>
</div>
