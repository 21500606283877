import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RubroService {

  constructor(private http: HttpClient) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;

  getNextClave(idEmpresa: number, nivel: number, idPadre: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel).set("idPadre", idPadre);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerSiguienteClaveRubro`, { params });
  }

  getNextClaveER(idEmpresa: number, nivel: number, idPadre: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel).set("idPadre", idPadre);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerSiguienteClaveRubroER`, { params });
  }

  deleteAccounts(idPadre: number): Observable<void> {
    const params = new HttpParams().set("idPadre", idPadre);
    return this.http.get<void>(`${this.baseUrl}/Contabilidad/EliminarCuentasPorRubro`, { params });
  }

  deleteAccountsER(idPadre: number): Observable<void> {
    const params = new HttpParams().set("idPadre", idPadre);
    return this.http.get<void>(`${this.baseUrl}/Contabilidad/EliminarCuentasPorRubroER`, { params });
  }

  getLongLevel(idEmpresa: number, nivel: number): number {
    return 2;
    // const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel);
    // return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerLongitudNivelRubro`, { params });
  }


}
