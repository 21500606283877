import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ConsultaHistoricoResguardo } from '../../interfaces/venta.interface';
import * as moment from 'moment';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-historico-remisiones-page',
  templateUrl: './historico-remisiones-page.component.html',
  styles: [
  ]
})
export class HistoricoRemisionesPageComponent implements OnInit {

  @Input()
  public lista: any[] = [];

  @Input()
  public SerieFolio: string = '';


  indexEditing:number = -1;
  private reportsService = inject(ReportsService)

  public filtroRemisiones: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte ',
      NombreExcel: 'Reporte de Remisión.xlsx',
      FilterOptions: [
        { Campo: 'rem.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  constructor(private modals: ModalService) {
  }
  ngOnInit(): void {
    console.log(this.lista)
  }

  verPantalla(id: number) {
    this.modals.closeModal(id);
  }

  imprimirRemisiones(idRemision: number) {

    let listaString: string = "";

    if (idRemision == 0) {
      this.lista.forEach(item => {
        if (listaString.length > 0) {
          listaString += "," + item.idRemision;
        } else {
          listaString += item.idRemision;
        }
      });
    } else {

      listaString = String(idRemision);
    }

    let reportHeader: ReportHeader = {
      NombreReporte: 'LISTADO DE REMISIONES/DEVOLUCIONES ' + this.SerieFolio,
      Opc2: true,
      Opc1: true
    }
    let filtro: FilterOptions | undefined = this.filtroRemisiones.FilterOptions?.find(p => p.Campo == 'rem.Id');
    if (filtro) {
      filtro!.Valor = listaString;
    }
    this.filtroRemisiones.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroRemisiones, '/Resguardo/VerificadorDeRemisionesPorResguardo');
  }

  getDate(fecha: any): string {
    return moment(fecha).format("DD/MM/YYYY")
  }

  getHour(fecha: any): string {
    return moment(fecha).format("HH:mm:ss")
  }

  closeModal() {
    this.modals.closeModal(null);
  }
}
