import { ComboBoxEntity } from './../../../component-ui/interfaces/combo-text.interface';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Puesto } from '../../interfaces/puesto.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfiguracionService } from '../../service/configuracion.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface'; import Swal from 'sweetalert2';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { Validators } from 'ngx-editor';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/service/modal.service';
import { DataSearchComponent } from 'src/app/component-ui/components/data-search/data-search.component';

@Component({
  selector: 'app-puesto-page',
  templateUrl: './puesto-page.component.html',

})
export class PuestoPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]]
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: Puesto | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;


  // @ViewChild('cboPrueba')
  // public cboPrueba!: ElementRef<ComboBoxComponent>;

  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService) { }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Puesto) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: Puesto): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Puesto").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }



}
