import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { CuentaCxpPageComponent } from './pages/cuenta-cxp-page/cuenta-cxp-page.component';
import { ConceptoCxpPageComponent } from './pages/concepto-cxp-page/concepto-cxp-page.component';
import { AntiguedadSaldosCxpPageComponent } from './pages/reports/antiguedad-saldos-cxp-page/antiguedad-saldos-cxp-page.component';
import { RelacionesAnaliticasCxpPageComponent } from './pages/reports/relaciones-analiticas-cxp-page/relaciones-analiticas-cxp-page.component';
import { VerificadorCargosAbonosCXPComponent } from './pages/reports/verificador-cargos-abonos-cxp/verificador-cargos-abonos-cxp.component';
import { EstadoCuentaCxpPageComponent } from './pages/estado-cuenta-cxp-page/estado-cuenta-cxp-page.component';
import { ReporteEstadoDeCuentaCxpComponent } from './pages/reporte-estado-de-cuenta-cxp/reporte-estado-de-cuenta-cxp.component';
import { TiposMovimientosCxpPageComponent } from './pages/tipos-movimientos-cxp-page/tipos-movimientos-cxp-page.component';
import { CtrlEdicionRangosAntiguedadSaldosCxcPageComponent } from './pages/ctrl-edicion-rangos-antiguedad-saldos-cxp-page/ctrl-edicion-rangos-antiguedad-saldos-cxp-page.component';



@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    DateBoxComponent,
    GuiGridModule,
  ],
  exports: [],
  declarations: [
    CuentaCxpPageComponent,
    ConceptoCxpPageComponent,
    AntiguedadSaldosCxpPageComponent,
    RelacionesAnaliticasCxpPageComponent,
    VerificadorCargosAbonosCXPComponent,
    EstadoCuentaCxpPageComponent,
    ReporteEstadoDeCuentaCxpComponent,
    TiposMovimientosCxpPageComponent,
    CtrlEdicionRangosAntiguedadSaldosCxcPageComponent

  ],
  providers: [],
})
export class CuentasPorPagarModule { }
