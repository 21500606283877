import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CodigoPostal } from '../interfaces/codigopostal.interface';
import { environment } from 'src/environments/environment';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentsUiService {

  baseUrl = environment.baseUrlApi;

  constructor(private http: HttpClient) {

  }

  getPostalCodes(codigo: number):Observable<string> {

       return this.http.get<string>(`${this.baseUrl}/config/ObtenerCodigoPostal/${codigo}`);

      // const options = {
      //     method: 'GET',
      //     headers: {
      //       'content-type': 'application/json',
      //     }
      //   };


      //  return this.http.get(`https://postali.app/codigo-postal/${codigo}.json`,options);


      // fetch(`https://postali.app/codigo-postal/${codigo}.json`)
      // .then(response=> {
      //     console.log(response)
      // })
      // .then(
      //   console.log
      //   );




  }




}
