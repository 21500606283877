import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { TextBoxComponent } from '../text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from '../../services/container-base.service';
import { ModalService } from 'src/app/service/modal.service';
import { orderBy } from 'lodash';
import { PosKitchenOrderPage } from '../../../home/pages/pos/kitchen-order/kitchen-order';


@Component({
  selector: 'app-data-search',
  templateUrl: './data-search.component.html',
  styleUrls: ['./data-search.component.css']
})
export class DataSearchComponent implements OnInit, AfterViewInit {

  //esto es para la busqueda
  searching: boolean = false;
  selected = [];
  @ViewChild('txtBuscar')
  public txtBuscar!: ElementRef<TextBoxComponent>;

  @ViewChild(DatatableComponent) table!: DatatableComponent;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  rows: any = []
  idUnico: string = '';

  @Input()
  public isMobile: boolean = false;
  @Input()
  public entityName: string = "";
  @Input()
  public propertys: string = "";
  @Input()
  public orders: string = "";
  @Input()
  public filter: string = "";
  @Input()
  public columns: any;
  @Input()
  public titulo: string = "";
  selectedIndex: number = -1;
  constructor(private containerService: ContainerBaseService, public mService: ModalService) {


  }
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    setTimeout(() => {
      const empty = document.querySelector(".empty-row.ng-star-inserted");
      if (empty) empty.remove();
      this.buscar();
    }, 1);
    this.restarHeight(window.innerHeight);
  }

  close() {
    this.mService.closeModal(null);
  }

  onDblClick(sel: any) {
    this.mService.closeModal(sel);
  }

  ordenar(campo: string) {
    //this.lastOrder = campo;
    //let order = this.rows.filter((P: any) => P.prop == campo)[0];
    let ordenado = orderBy(this.rows, [campo], ["asc"]);
    //order.order == "asc" ? order.order = "desc" : order.order = "asc";
    //this.lastOrderAsc = order.order;
    this.rows = [...ordenado];
  }

  onSelect(obj: any) {
    this.selected = obj.selected;
  }

  click(i: number) {
    this.selectedIndex = i;
    const txt: any = this.txtBuscar;
    txt.nativeElement.focus();
  }

  navegar(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.rows.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    } else if (e.keyCode != 13) {
      this.selectedIndex = -1;
    }
    if (row) {
      row.scrollIntoView({ block: "center" });
    }

  }

  buscar() {
    if (this.selectedIndex >= 0) {
      this.mService.closeModal(this.rows[this.selectedIndex]);
      return;
    }

    this.searching = true;
    const txt: any = this.txtBuscar;
    this.containerService.searchItemsByFields(this.entityName, txt.nativeElement.value, this.propertys, this.filter, this.orders).subscribe((result) => {
      this.searching = false;
      this.rows = result;
      this.selectedIndex = -1;
      txt.nativeElement.focus();

      // setTimeout(() => {
      //   const renglones = document.querySelectorAll(".datatable-row-center.datatable-row-group.ng-star-inserted");
      //   renglones.forEach((renglon) => {
      //     renglon.addEventListener("dblclick", () => {
      //       const sel = this.selected[0];
      //       this.mService.closeModal(sel);
      //     });
      //   })

      //   const footer = document.querySelector(".datatable-footer-inner");
      //   const nodata = document.querySelector(".empty-row.ng-star-inserted");
      //   if (footer) { footer.remove(); }
      //   if (nodata) { nodata.remove(); }
      // }, 1);
    });
  }

  @HostListener('window:resize', ['$event'])
  onChangeHeight(event: any) {
    this.restarHeight(event.target.innerHeight);
  }

  scrollHeight: number = 0;
  restarHeight(innerHeight: number) {
    if (this.isMobile) {
      let cantidadRestarTop = 170;
      this.scrollHeight = innerHeight - cantidadRestarTop;
    }
  }

  get scrollbarStyle(): string {
    return `margin-top: 10px; height: ${this.scrollHeight}px`;
  };

  selectMobile(item: any) {
    this.mService.closeModal(item);
  }
}
