import { Component } from '@angular/core';

@Component({
  selector: 'app-historico-documento-page',
  templateUrl: './historico-documento-page.component.html',
  styles: [
  ]
})
export class HistoricoDocumentoPageComponent {

}
