import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { ConceptoCxp } from '../../interfaces/cuentacxp.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import Swal from 'sweetalert2';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';

@Component({
  selector: 'app-concepto-cxp-page',
  templateUrl: './concepto-cxp-page.component.html',
  styles: [
  ]
})
export class ConceptoCxpPageComponent {
  public itemsCombo: ComboBoxEntity[] = [];

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Abreviacion: ['', [Validators.required]],
    Carga: [false],
    Abona: [false],
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: ConceptoCxp | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;


  constructor(private fb: FormBuilder, private containerService: ContainerBaseService) {
  }



  onBeforeSave(param: EventReturn) {

    const ent: ConceptoCxp = this.myForm.value;

    if(!ent.Carga  && !ent.Abona){
      Swal.fire({ icon: 'error', text: 'Indique si el Concepto carga o abona.' });
      param.callback(false);
      return;
    }

    if(ent.Carga  && ent.Abona){
      Swal.fire({ icon: 'error', text: 'Solo puede Cargar o Abonar, no ambas, verifique.' });
      param.callback(false);
      return;
    }

    param.callback(true);
  }

  onAfterSave(entity: ConceptoCxp) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: ConceptoCxp): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("ConceptoCxp").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
}
