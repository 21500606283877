<app-panel-base title="Cancelar documento" cdkDrag>
  <div class="panel-content" cdkDragHandle>
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content" style="overflow-x: hidden;">
        <form [formGroup]="myFormCancel" autocomplete="off">
          <components-ui-date-box formControlName="Fecha" [tabIndex]="1" formControlName="Fecha" label="Fecha"
            [widthLabelColumns]="3" [widthTextColumns]="3" #txtFecha />

          <component-ui-combo-box [tabIndex]="2" (onSelectedItem)="onSelectedItem($event)" formControlName="TipoPoliza"
            label="Tipo Póliza" entityName="TipoPoliza" [widthLabelColumns]="3" [widthTextColumns]="9"
            [widthRightColumns]="0" />

          <components-ui-number-box [tabIndex]="3" formControlName="Folio" [maxlength]="10" label="Número"
            [widthLabelColumns]="3" [widthTextColumns]="9" />

          <div style="text-align: right; margin-top: 5px;">
            <div (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</div>
            <div (click)="closeModal()" class="btn btn-danger">Cancelar</div>
          </div>
        </form>
      </div>
    </app-container-base>
  </div>
</app-panel-base>
