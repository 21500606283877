import { Component, ElementRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { AppVariablesService } from 'src/app/home/services/app-variables.service';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CRMService } from '../../services/crm.service';
import { Visita } from 'src/app/crm/interfaces/visita.interface';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import * as mapboxgl from 'mapbox-gl';
import { error } from 'jquery';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('modalEventCalendar') public modalEventCalendar!: ElementRef<HTMLElement>;

  private appVariablesService = inject(AppVariablesService);
  private crmService = inject(CRMService);
  private modalService = inject(ModalService);
  private utilsService = inject(UtilsService);

  appVariables = this.appVariablesService.getAppVariables();
  date = new Date();
  currentYear = this.date.getFullYear();
  defaultMonth = this.date.getMonth() + 1;
  currentMonth = (this.defaultMonth < 10) ? '0' + this.defaultMonth : this.defaultMonth;
  visitas: any[] = [];
  titleCalendar: string = "";
  mostrarMap: boolean = false;
  fullAddress: string = "";
  map: mapboxgl.Map | undefined;
  marker: mapboxgl.Marker | undefined;

  @HostListener('window:resize', ['$event'])
  onChangeHeight(event: any) {
    this.calcularHeight(event.target.innerHeight);
  }

  heightCalendar: number = 0;
  calcularHeight(innerHeight: number) {
    let cantidadRestarTop = 131;
    this.heightCalendar = innerHeight - cantidadRestarTop;
    let cCalendar = <HTMLElement>document.querySelector(".fc.fc-media-screen");
    setTimeout(() => {
      cCalendar.style.height = this.heightCalendar + "px";
    }, 100);
  }

  ngOnInit() {
    this.appVariablesService.variablesReload.subscribe(() => {
      this.appVariables = this.appVariablesService.getAppVariables();
      this.reloadCalendar();
    });
    this.utilsService.isLoad(true);
    this.crmService.getVisitas().subscribe({
      next: (visitas: Visita[]) => {
        if (visitas.length > 0) {
          let visitasTerminadas = visitas.filter(x => x.fechaHoraSalida);
          let proximasVisitas = visitas.filter(x => x.fechaHoraProximaVisita);
          let visitaEnProceso = visitas.find(x => !x.fechaHoraSalida) as any;

          if (visitaEnProceso) {
            this.visitas =
              [...this.visitas,
              {
                title: visitaEnProceso!.asunto,
                start: visitaEnProceso!.fechaHoraLlegada,
                end: visitaEnProceso!.fechaHoraSalida,
                color: '#ff5b57',
                extendedProps: { tipoVisita: 1, visita: visitaEnProceso, visitado: (visitaEnProceso.cliente ? visitaEnProceso.cliente.nombre : visitaEnProceso.prospecto.nombre) }
              }]
          }

          for (const v of visitasTerminadas as any) {
            this.visitas = [
              ...this.visitas,
              {
                id: v.id,
                title: v.asunto,
                start: v.fechaHoraLlegada,
                end: v.fechaHoraSalida,
                color: '#288728',
                extendedProps: { tipoVisita: 2, visita: v, visitado: (v.cliente ? v.cliente.nombre : v.prospecto.nombre) }
              }
            ];
          }
          for (const v of proximasVisitas as any) {
            this.visitas = [
              ...this.visitas,
              {
                id: v.id,
                title: "Visitar a " + (v.cliente ? v.cliente.nombre : v.prospecto.nombre),
                start: v.fechaHoraProximaVisita,
                color: '#348fe2',
                extendedProps: { tipoVisita: 3, visita: v, visitado: (v.cliente ? v.cliente.nombre : v.prospecto.nombre) }
              }
            ];
          }
        }

        setTimeout(() => {
          this.reloadCalendar();
        }, 100);
        this.utilsService.isLoad(false);
        this.headerCalendar()
      },
      error: (err: Error) => console.error(err)
    })
  }

  ngAfterViewInit() {
    // var containerEl = this.externalEvents.nativeElement;
    // new Draggable(containerEl, {
    //   itemSelector: '.fc-event',
    //   eventData: function (eventEl) {
    //     return {
    //       title: eventEl.innerText,
    //       color: eventEl.getAttribute('data-color')
    //     };
    //   }
    // });

    // setTimeout(() => {
    //   window.dispatchEvent(new Event('resize'));
    // }, 0);
  }

  reloadCalendar() {
    this.calcularHeight(window.innerHeight);
    this.calendarOptions = this.getCalendarOptions(this.visitas);
  }

  eventSelecionado: any = {
    title: '',
    color: '#ffffff',
    visita: {},
    tipoVisita: 0
  };


  tokenMapBox: string = 'pk.eyJ1IjoiamVzdXNlc3Bpbm96YSIsImEiOiJjbHk1Zno1amIwYWduMmlxMm8xMHQ1b3FmIn0.dDcxBB7TRFzRpmhudbY0SA';
  lng: number = 0;
  lat: number = 0;
  lngMkr: number = 0;
  latMkr: number = 0;
  calendarOptions: CalendarOptions = this.getCalendarOptions(this.visitas);
  getCalendarOptions(events: any): CalendarOptions {
    return {
      locale: 'es',
      height: this.heightCalendar,
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interactionPlugin],
      headerToolbar: {
        left: 'title',
        center: 'dayGridMonth,timeGridWeek,timeGridDay',
        right: 'prev,next,today'
      },
      buttonText: {
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día'
      },
      editable: false,
      droppable: false,
      themeSystem: 'bootstrap',
      views: {
        timeGrid: {
          eventLimit: 6
        }
      },
      dateClick: (arg: any) => { },
      eventClick: (info: any) => {
        //extendedProps.tipoVisita( 1: En Proceso, 2: Terminada, 3: Próxima )
        this.eventSelecionado = {
          title: info.event.title,
          color: info.event.backgroundColor,
          visita: info.event.extendedProps.visita,
          visitado: info.event.extendedProps.visitado,
          tipoVisita: info.event.extendedProps.tipoVisita,
          fechaInicio: info.event.extendedProps.tipoVisita === 3 ? info.event.extendedProps.visita.fechaHoraProximaVisita : info.event.extendedProps.visita.fechaHoraLlegada,
          fechaFin: info.event.extendedProps.visita.fechaHoraSalida
        }
        this.lat = info.event.extendedProps.visita.latitud;
        this.lng = info.event.extendedProps.visita.longitud;
        this.latMkr = info.event.extendedProps.visita.latitud;
        this.lngMkr = info.event.extendedProps.visita.longitud;
        this.modalService.openModal(this.modalEventCalendar, () => {
          this.mostrarMap = false;
        }, 'lg', false, true);
        setTimeout(() => {
          this.loadMap();
        }, 300);
      },
      events: events,
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }
    };
  }

  close() {
    this.modalService.closeModal(null);
  }

  headerCalendar() {
    let headerCalendar = <HTMLElement>document.querySelector(".fc-header-toolbar");
    headerCalendar.style.display = "none";

    let headerTitle = <HTMLElement>document.querySelector(".fc-toolbar-title");
    this.titleCalendar = headerTitle.innerText;

    setTimeout(() => {
      let eventElem = <HTMLElement>document.querySelector(".fc-event");
      if (eventElem) {
        eventElem.style.cursor = 'pointer';
      }
    }, 100);
  }


  //next = 1, prev = 2, mes = 3, semana = 4, día = 5
  navHeader(typeNavHeader: number = 0) {
    let btnClass = "";
    switch (typeNavHeader) {
      case 1:
        btnClass = ".fc-next-button";
        break;
      case 2:
        btnClass = ".fc-prev-button";
        break;
      case 3:
        btnClass = ".fc-dayGridMonth-button";
        break;
      case 4:
        btnClass = ".fc-timeGridWeek-button";
        break;
      case 5:
        btnClass = ".fc-timeGridDay-button";
        break;
      default:
        btnClass = ".fc-today-button"
        break;
    }
    let btnNextPrevMonth = <HTMLElement>document.querySelector(btnClass);
    btnNextPrevMonth.click();
    this.headerCalendar();
    this.getCalendarOptions(this.visitas)
  }

  loadMap() {
    this.crmService.getAddresFromLngLat(this.lng, this.lat, this.tokenMapBox).subscribe({
      next: (resp: any) => {
        this.fullAddress = resp.features[0].properties.full_address;
        this.map = new mapboxgl.Map({
          accessToken: this.tokenMapBox,
          container: 'map',
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [this.lng, this.lat],
          zoom: 15,
        })
          .addControl(new mapboxgl.NavigationControl({
            visualizePitch: true
          }))
          .addControl(new mapboxgl.FullscreenControl())
          .addControl(
            new mapboxgl.GeolocateControl({
              showAccuracyCircle: false,
              fitBoundsOptions: { zoom: 15 }
            }));

        this.marker = new mapboxgl.Marker({
          color: '#ff5a00',
          draggable: false
        }).setLngLat([this.lngMkr, this.latMkr])
          .setPopup(new mapboxgl.Popup().setHTML(`
            <h3>Dirección</h3>
            <h5 class='text-center'>${this.fullAddress}</h5>
            `))
          .addTo(this.map!);
        this.marker.togglePopup();
        setTimeout(() => {
          this.map!.resize();
        }, 300);
      },
      error: (err: Error) => console.error(err)
    });
  }

  flyToMarker() {
    this.map!.flyTo({
      center: [this.lng, this.lat],
      zoom: 15,
      essential: true
    });
    let removedPopup = this.marker!.getPopup();
    removedPopup.remove();
    removedPopup.addTo(this.map!);
  }
}
