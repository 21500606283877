import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { c_MotivoCancelacion } from 'src/app/ventas/interfaces/venta.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-opcion-cancelacion-retencion-page',
  templateUrl: './opcion-cancelacion-retencion-page.component.html',
  styles: [
  ]
})
export class OpcionCancelacionRetencionPageComponent implements OnInit {

  private eventsService = inject(EventsService);

  @ViewChild('motivoCancelacion')
  public motivoCancelacion!: ElementRef<ComboBoxComponent>;

  requiresSAT: boolean = true;
  private readonly baseUrl: string = environment.baseUrlApi;


  public myFormCancel: FormGroup = this.fb.group({
    MotivoCancelacion: ['', Validators.required],
    MotivoSAT: [{} as c_MotivoCancelacion],
    UUID: ['']
  })

  constructor(private fServices: FacturacionService,
    private fb: FormBuilder,
    private mService: ModalService) {
  }

  accept() {
    if (!this.myFormCancel.value.MotivoCancelacion) {
      this.eventsService.publish('home:showAlert', { message: "Indique el motivo de cancelación.", cancelButton: false });
      return;
    }
    if (this.requiresSAT) {
      if (!this.myFormCancel.value.MotivoSAT) {
        this.eventsService.publish('home:showAlert', { message: "Indique el motivo de cancelación del SAT.", cancelButton: false });
        return;
      }
      if (this.myFormCancel.value.MotivoSAT.Id == 1) {
        if (!this.myFormCancel.value.UUID) {
          this.eventsService.publish('home:showAlert', { message: "Indique el UUID que sustituye.", cancelButton: false });
          return;
        }
      }
    }
    this.mService.closeModal(this.myFormCancel.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      MotivoCancelacion: '',
      MotivoSAT: { Id: 0 }
    };
    this.myFormCancel.reset(ent);
    const txt: any = this.motivoCancelacion;
    txt.tagInput.nativeElement.focus()
  }

  onSelectedItem(entity: any) {
    this.myFormCancel.controls["MotivoSAT"].setValue(entity);
    if (entity.Id != 1) {
      const ent = this.myFormCancel.value;
      ent.UUID = "";
      this.myFormCancel.reset(ent);
    }
  }



}
