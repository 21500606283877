import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { VentaFormulacion } from '../../interfaces/venta.interface';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-formulacion-page',
  templateUrl: './formulacion-page.component.html',
  styles: [
  ]
})
export class FormulacionPageComponent implements OnInit {

  private eventsService = inject(EventsService);

  @ViewChild('txtGrosor')
  public txtGrosor!: ElementRef<TextBoxComponent>;

  @ViewChild('txtPiezas')
  public txtPiezas!: ElementRef<TextBoxComponent>;

  @Input()
  public formulacion: VentaFormulacion | null = null;
  public myForm: FormGroup = this.fb.group({
    GrosorFormulacion: [0],
    AnchoFormulacion: [0],
    LargoFormulacion: [0],
    PiezasFormulacion: [0],
    DivisorFormulacion: [0],
  })

  constructor(private fb: FormBuilder, private ms: ModalService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      const t: any = this.txtGrosor;
      t.tagInput.nativeElement.focus();
    }, 100);
    if (this.formulacion) {
      this.myForm.reset(this.formulacion);
    }
  }

  accept() {
    const t: any = this.txtPiezas;
    t.tagInput.nativeElement.blur();
    setTimeout(() => {

      if (this.myForm.value.GrosorFormulacion <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Grosor.`, cancelButton: false });
        return;
      }

      if (this.myForm.value.AnchoFormulacion <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Ancho.`, cancelButton: false });
        return;
      }

      if (this.myForm.value.LargoFormulacion <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Largo.`, cancelButton: false });
        return;
      }

      if (this.myForm.value.PiezasFormulacion <= 0) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el número de piezas.`, cancelButton: false });
        return;
      }

      this.ms.closeModal(this.myForm.value);
    }, 20);
  }

  close() {
    this.ms.closeModal(null);
  }

}
