<div class="error">
  <div class="error-code">NO DISPONIBLE</div>
  <div class="error-content">
    <div class="error-message">No podemos mostrarte esta pantalla...</div>
    <div class="error-desc mb-4">
      La captura de visitas es únicamente válida <br>
      para los Vendedores foraneos a través de celular o tablet.
    </div>
    <div>
      <a (click)="cerrar()" class="btn btn-danger px-3">Cerrar</a>
    </div>
  </div>
</div>
