import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { environment } from 'src/environments/environment';
import { UsuarioContecado } from '../interfaces/usuario-conectado.interface';
import { ReportFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { RegistroActividad } from '../interfaces/registro-actividad.interface';

@Injectable({
  providedIn: 'root'
})
export class SistemaService {

  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient) {

  }

  actualizarClaveProdServ(): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/Fiscal/ActualizarClaveProdServ`);
  }

  sendActivosMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionActivosFijos`, { params });
  }

  sendFamiliaProductosMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionFamiliasProductos`, { params });
  }

  sendVehiculosMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionVehiculos`, { params });
  }

  sendUsuariosMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionUsuarios`, { params });
  }

  sendConsignadoClienteMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionConsignadoClientes`, { params });
  }

  sendConsignadoProveedorMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionConsignadoProveedores`, { params });
  }

  sendProductoUnidadMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionProductosUnidades`, { params });
  }
  sendProductoComposicionMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionProductosComposicion`, { params });
  }

  sendCargaEntregaMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionCargaEntrega`, { params });
  }









  sendProveedoresMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionProveedores`, { params });
  }


  sendVerificarFamiliaNiveles(): Observable<Result> {
    const params = new HttpParams();
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/VerificarMigracionFamiliasProductos`, { params });
  }




  sendContaPolicyMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionPolizasContables`, { params });
  }

  sendContaAccountsMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionCuentasContables`, { params });
  }

  sendCustomerMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionClientes`, { params });
  }

  sendCxpMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionSaldosCXP`, { params });
  }

  sendCxcMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionSaldosCXC`, { params });
  }

  sendProductMigration(fileName: string, deleteItems: boolean): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName).set("borrarRegistros", deleteItems);
    return this.http.get<Result>(`${this.baseUrl}/Utilerias/IniciarMigracionProductos`, { params });
  }

  getUsuariosConectados(): Observable<UsuarioContecado[]> {
    return this.http.get<UsuarioContecado[]>(`${this.baseUrl}/Config/ObtenerUsuariosConectados`);
  }

  eliminarUsuariosConectados(): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/Config/EliminarUsuariosConectados`);
  }

  sendCloseSession(sesion: UsuarioContecado): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Config/CerrarSesionUsuario`, sesion)
  }

  sendMessage(mensaje: string): Observable<Result> {
    const params = new HttpParams().set("mensaje", mensaje);
    return this.http.get<Result>(`${this.baseUrl}/Config/EnviarMensajeUsuarios`, { params });
  }

  consultarActividad(report: ReportFilter): Observable<RegistroActividad[]> {
    return this.http.post<RegistroActividad[]>(`${this.baseUrl}/Config/ConsultarActividad`, report);
  }
}
