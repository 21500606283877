<div class="card text-center border-0" cdkDrag
  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;width:900px;height: 700px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Captura de Pago
  </div>

  <app-container-base subTitle="" [containerInnerHeight]="611" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
    [hasCounter]="false">
    <div class="screen-content">
      <form [formGroup]="myForm" autocomplete="off">
        <app-panel-base title="Información global del pago">
          <div class="panel-content">
            <components-ui-date-box [enabled]="!bloquear" [required]="true" formControlName="FechaPago"
              label="Fecha del Pago" [tabIndex]="101" [widthLabelColumns]="3" [widthTextColumns]="6" />

            <components-ui-number-box [enabled]="!bloquear" [required]="true" formControlName="Monto"
              label="Monto del Pago" [formatoMoneda]="true" [tabIndex]="102" [widthLabelColumns]="3" [widthTextColumns]="2" />

            <component-ui-combo-box [enabled]="!bloquear" [required]="true" [label]="'Forma de Pago'"
              formControlName="FormaPagoP" [tabIndex]="103" [zeroMask]="2" entityName="c_FormaPago"
              [widthTextColumns]="2" [widthLabelColumns]="3" [widthRightColumns]="7"
              (onSelectedItem)="onSelectItem($event, 'FormaPagoP')" [listProperty]="'ClaveNombre'" />

            <components-ui-text-box [enabled]="!bloquear" [required]="true" formControlName="NumeroOperacion"
              label="Núm Operación" [tabIndex]="104" [widthLabelColumns]="3" [widthTextColumns]="6" />

            <component-ui-combo-box [enabled]="!bloquear" [required]="true" [label]="'Moneda'" formControlName="MonedaP"
              [tabIndex]="105" [zeroMask]="2" entityName="c_Moneda" [extras]="'ClaveSAT as ClaveSAT'"
              [widthTextColumns]="2" [widthLabelColumns]="3" [widthRightColumns]="7"
              (onSelectedItem)="onSelectItem($event, 'MonedaP')" [listProperty]="'ClaveNombre'" />

            <components-ui-number-box [enabled]="getEnableTC" formControlName="TipoCambioP" label="Tipo Cambio"
              [tabIndex]="106" [widthLabelColumns]="3" [widthTextColumns]="2" />

            <div class="row mt-10px">
              <div class="col-md-8">
                <cuentas-pagos-cxc [tabIndex]="107" (selectCuentaOrdenante)="cambioCtaOrdenante($event)"
                  (selectCuentaBeneficiaria)="cambioCtaBeneficiaria($event)" [clienteId]="IdCliente"
                  [empresaId]="info.empresa!.numero" />
              </div>
            </div>

          </div>
        </app-panel-base>
        <app-panel-base title="Seleccionado en pagos">
          <div class="panel-content">
            <components-ui-number-box [enabled]="false" [formatoMoneda]="true" formControlName="MontoSeleccionado" label="Monto Seleccionado"
              [widthLabelColumns]="10" [widthTextColumns]="2" />
          </div>
        </app-panel-base>
        <app-panel-base title="Seleccione los CFDIS a agregar al Pago" [style]="'max-height:150px;min-height:150px;'">
          <div class="panel-content">
            <gui-grid (sourceEdited)="sourceEdited($event)" [autoResizeWidth]="false" [loading]="buscando" [width]="800"
              [source]="listaFacturas" [rowHeight]="20" [cellEditing]="true" [rowSelection]="false" [theme]="'fabric'"
              [maxHeight]="150">
              <gui-grid-column header="Suc" fied="sucursal" [width]="120" [cellEditing]="false">
                <ng-template let-item="item">
                  <i class="align-middle {{item.seleccionado ? 'text-green fas fs-17px fa-square-check':'fs-15px far fa-square-full'}}"
                  (click)="marcar(item)"></i>
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Suc" fied="sucursal" [width]="120" [cellEditing]="false">
                <ng-template let-item="item">
                  {{item.sucursal}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="SerieFolio" field="serieFolio" [width]="100" [cellEditing]="false" />
              <gui-grid-column header="Emisión" field="fecha" [width]="120" [cellEditing]="false" />
              <gui-grid-column header="Total" field="total" [width]="100" [cellEditing]="false"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-value="value">
                  {{(value | number:'1.2-6')}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Saldo" field="saldo" [width]="100" [cellEditing]="false"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-value="value">
                  {{(value | number:'1.2-6')}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="Pagar" field="pagar" [width]="100" [cellEditing]="true" [type]="'number'"
                [align]="GuiColumnAlign.RIGHT">
                <ng-template let-value="value">
                  {{(value | number:'1.2-6')}}
                </ng-template>
              </gui-grid-column>
              <gui-grid-column header="# Reps" field="numRepAplicados" [width]="50" [cellEditing]="false" />
            </gui-grid>
          </div>
        </app-panel-base>
      </form>
    </div>
  </app-container-base>



  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
    </div>
  </div>
</div>
