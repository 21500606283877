import { InventarioCabecera } from './../../interfaces/inventario.interface';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, type OnInit, ElementRef, Input, Output, EventEmitter, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClaseGastoCajaChica } from 'src/app/Finanzas/interfaces/tipotransferencia.interface';
import { FinanzasService } from 'src/app/Finanzas/services/finanzas.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Proveedor } from 'src/app/compras/interfaces/proveedores.interface';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { Producto } from '../../interfaces/producto.interface';
import { Almacen, ConceptoAlmacen } from 'src/app/home/interfaces/almacen.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { id } from '@swimlane/ngx-charts';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'ctrl-edicion-producto-compuesto-page',
  templateUrl: './ctrl-edicion-producto-compuesto-page.component.html',
})
export class CtrlEdicionProductoCompuestoPageComponent implements OnInit {

  @ViewChild('txtProducto')
  public txtProducto!: ElementRef<any>;

  @ViewChild('txtAlmacen')
  public txtAlmacen!: ElementRef<any>;

  @ViewChild('txtConcepto')
  public txtConceptoAlmacen!: ElementRef<any>;

  @ViewChild('txtUnidades')
  public txtUnidades!: ElementRef<any>;
  // @ViewChild('txtTotal')
  // public txtTotal!: ElementRef<any>;
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;

  @ViewChild('busquedaAlmacen')
  public ctrlbusquedaAlmacen!: ElementRef<HTMLElement>;

  @ViewChild('busquedaConceptoAlmacen')
  public ctrlbusquedaConceptoAlmacen!: ElementRef<HTMLElement>;

  @Input()
  public item!: any | null;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();

  @Input()
  public producto!: any | null;


  private readonly baseUrl: string = environment.baseUrlApi;
  searchConfigurationProducto: SearchConfiguration | null = null;
  searchConfigurationAlmacen: SearchConfiguration | null = null;
  searchConfigurationConceptoAlmacen: SearchConfiguration | null = null;

  info: UserLogged = {} as UserLogged;
  addingcfdi: boolean = false;
  fechaActual: Date = new Date();
  private vtasService = inject(FacturacionService)
  private eventsService = inject(EventsService);
  private http = inject(HttpClient);

  public myForm: FormGroup = this.fb.group({
    id: [0],
    Producto: [{} as Producto],
    Cantidad: [],
    Almacen: [{} as Almacen],
    ConceptoSalida: [{} as ConceptoAlmacen],
  })

  constructor(private fb: FormBuilder, private ms: ModalService, private utileService: UtilsService) { }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.searchConfigurationAlmacen = this.ms.GetSearchConfiguration("Almacen", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.searchConfigurationConceptoAlmacen = this.ms.GetSearchConfiguration("ConceptoAlmacen", `Empresa.Id = ${this.info.empresa!.numero} && EsSalida=true`);


    setTimeout(() => {
      const t: any = this.txtProducto;
       if (this.item) {
        const ss=0;
      //   if (this.item.clase) {
      //     if (this.item.clase.id) {
      //       this.item.clase.Id = this.item.clase.id;
      //       this.item.clase.Clave = this.item.clase.clave;
      //       this.item.clase.Nombre = this.item.clase.nombre;
      //     }
      //   }
      //   if (this.item.proveedor) {
      //     if (this.item.proveedor.Clave) {
      //       t.tagInput.nativeElement.value = this.item.proveedor.Clave;
      //     } else {
      //       t.tagInput.nativeElement.value = this.item.proveedor.clave;
      //     }
      //     this.myForm.reset(this.item);
      //   }
       }//todo
      t.tagInput.nativeElement.focus();
    }, 150);
  }


  selectedItem(ent: ComboBoxEntity) {
    this.myForm.controls["clase"].setValue(ent);
  }

  blurProducto(value: any) {
    if (value.after != value.before) {
      if (value.after) {

        // const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero).set("idCliente", this.myForm.value.Cliente.Id).set("clave", value);
        // return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe(prod => {
        //   if (prod) {
        //     this.myForm.get('producto')?.setValue(prod);
        //   } else {

        //     Swal.fire({
        //       text: "No se encontró el Producto indicado.",
        //       icon: 'error',
        //     }).then(() => {

        //     })
        //   }
        // });
        //  this.vtasService.b(this.info.empresa!.numero, value.after).subscribe((prov) => {
        //    if (prov) {
        //      this.myForm.controls["proveedor"].setValue(prov);
        //      const t: any = this.txtConcepto;
        //      t.tagInput.nativeElement.focus();
        //    } else {
        //      const t: any = this.txtProducto;
        //      t.tagInput.nativeElement.value = "";
        //      Swal.fire({ text: `No se encontró el proveedor indicado.`, icon: 'error', }).then(() => {
        //        setTimeout(() => {
        //          t.tagInput.nativeElement.focus();
        //        }, 150);
        //      })
        //    }
        //  })
      } else {
        this.myForm.controls["Producto"].setValue(null);
      }
    }
  }





  // searchProduct(value: string) {
  //   if (!value) {
  //     if (this.myForm.value.Total > 0 && this.navigateColumns) {
  //       this.save();
  //     }
  //     return;
  //   };
  //   this.loading = true;
  //   const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero).set("idCliente", this.myForm.value.Cliente.Id).set("clave", value);
  //   return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
  //     this.loading = false;
  //     if (producto) {
  //       let item = this.source[this.indexEditing];
  //       item.Producto = producto;
  //       item.NoIdentificacion = producto.Clave;
  //       item.Descripcion = producto.Nombre;
  //       item.Cantidad = 0;
  //       item.DescuentoPorcentaje = 0;
  //       item.DescuentoImporte = 0;
  //       item.ValorUnitario = producto.Precio;
  //       item.Unidad = producto.Unidad;
  //       item.UnidadNombre = producto.Unidad.Nombre;
  //       item.ClaveUnidad = producto.Unidad.Clave;
  //       item.ClaveProdServ = producto.ClaveSAT;
  //       this.source = [...this.source];
  //       this.showDescription = producto.ActualizarDescrpcion;
  //       this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);
  //       if (producto.Inventariable && producto.Almacen) {
  //         this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => { this.existencia = existencia; });
  //       }

  //       if (!producto.ActualizarDescrpcion) {
  //         if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0) {
  //           this.conversiones = producto.OtrasUnidades;
  //           this.unidadFinal = item.UnidadNombre;
  //           this.openConvertions((e: any) => this.aceptConversions(e));
  //         } else {
  //           this.conversiones = [];
  //           this.unidadFinal = '';
  //           debugger
  //           this.initEditor(this.indexEditing, 3);
  //         }
  //       } else {
  //         this.navigateColumns = true;
  //         setTimeout(() => {
  //           this.txtDescription.nativeElement.focus();
  //         }, 150);
  //       }
  //       this.calcTax();
  //     } else {
  //       Swal.fire({
  //         text: "No se encontró el Producto indicado.",
  //         icon: 'error',
  //       }).then(() => {
  //         this.source[this.indexEditing].NoIdentificacion = "";
  //         this.source = [...this.source];
  //         this.initEditor(this.indexEditing, 1);
  //         // setTimeout(() => {
  //         //   const elem: any = document.activeElement;
  //         //   elem.value = '';

  //         // }, 250);

  //         //reset row
  //       })
  //     }
  //   })
  // }

  blurConceptoAlmacen(value: any) {
    if (value.after != value.before) {
      if (value.after) {
        //  this.vtasService.b(this.info.empresa!.numero, value.after).subscribe((prov) => {
        //    if (prov) {
        //      this.myForm.controls["proveedor"].setValue(prov);
        //      const t: any = this.txtConcepto;
        //      t.tagInput.nativeElement.focus();
        //    } else {
        //      const t: any = this.txtProducto;
        //      t.tagInput.nativeElement.value = "";
        //      Swal.fire({ text: `No se encontró el proveedor indicado.`, icon: 'error', }).then(() => {
        //        setTimeout(() => {
        //          t.tagInput.nativeElement.focus();
        //        }, 150);
        //      })
        //    }
        //  })
      } else {
        this.myForm.controls["ConceptoSalida"].setValue(null);
      }
    }
  }

  blurAlmacen(value: any) {
    if (value.after != value.before) {
      if (value.after) {
        //  this.vtasService.b(this.info.empresa!.numero, value.after).subscribe((prov) => {
        //    if (prov) {
        //      this.myForm.controls["proveedor"].setValue(prov);
        //      const t: any = this.txtConcepto;
        //      t.tagInput.nativeElement.focus();
        //    } else {
        //      const t: any = this.txtProducto;
        //      t.tagInput.nativeElement.value = "";
        //      Swal.fire({ text: `No se encontró el proveedor indicado.`, icon: 'error', }).then(() => {
        //        setTimeout(() => {
        //          t.tagInput.nativeElement.focus();
        //        }, 150);
        //      })
        //    }
        //  })
      } else {
        this.myForm.controls["Almacen"].setValue(null);
      }
    }
  }

  openProdSearch() {
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Producto"].setValue(e);
        const t: any = this.txtProducto;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtUnidades;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtProducto;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  openConceptoAlmacenSearch() {
    const b: any = this.ctrlbusquedaConceptoAlmacen;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["ConceptoSalida"].setValue(e);
        const t: any = this.txtConceptoAlmacen;
        t.tagInput.nativeElement.value = e.Clave;
        // setTimeout(() => {
        //   const t2: any = this.txtConcepto;
        //   t2.tagInput.nativeElement.focus();
        // }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtConceptoAlmacen;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  openAlmacenSearch() {
    const b: any = this.ctrlbusquedaAlmacen;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Almacen"].setValue(e);
        const t: any = this.txtAlmacen;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtConceptoAlmacen;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtAlmacen;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }


  get getFilter(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.filter;
    }
    return "";
  }

  get getAlmacenFilter(): any {
    if (this.searchConfigurationAlmacen) {
      return this.searchConfigurationAlmacen.filter;
    }
    return "";
  }


  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getAlmacenColumns(): any {
    if (this.searchConfigurationAlmacen) {
      return this.searchConfigurationAlmacen.columns;
    }
    return [];
  }

  get getConceptoAlmacenColumns(): any {
    if (this.searchConfigurationConceptoAlmacen) {
      return this.searchConfigurationConceptoAlmacen.columns;
    }
    return [];
  }


  get getConceptoAlmacenFilter(): any {
    if (this.searchConfigurationConceptoAlmacen) {
      return this.searchConfigurationConceptoAlmacen.filter;
    }
    return "";
  }

  get getConceptoAlmacenPropertys(): string {
    if (this.searchConfigurationConceptoAlmacen) {
      let props = "";
      this.searchConfigurationConceptoAlmacen.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }



  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  get getAlmacenPropertys(): string {
    if (this.searchConfigurationAlmacen) {
      let props = "";
      this.searchConfigurationAlmacen.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }


  get getNombreConceptoAlmacen(): string {
    if (this.myForm.value.ConceptoSalida) {
      if (this.myForm.value.ConceptoSalida.Nombre) {
        return this.myForm.value.ConceptoSalida.Nombre;
      }
    }
    return "";
  }


  get getNombreAlmacen(): string {
    if (this.myForm.value.Almacen) {
      if (this.myForm.value.Almacen.Nombre) {
        return this.myForm.value.Almacen.Nombre;
      }
    }
    return "";
  }




  get getNombreProducto(): string {
    if (this.myForm.value.Producto) {
      if (this.myForm.value.Producto.Nombre) {
        return this.myForm.value.Producto.Nombre;
      }
    }
    return "";
  }


  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  openCfdi() {
    this.addingcfdi = true;
  }

  closeModal() {
    this.onCloseEdition.emit(null);
  }

  accept() {

    // const t: any = this.txtTotal;
    // t.tagInput.nativeElement.blur();

    const ent = this.myForm.value;

    let idProd = 0, idConcAlm,idAlm = 0;

    if (ent.Producto) {
      if (ent.Producto.Id > 0) { idProd = ent.Producto.Id; }
    }

    if (ent.Producto && this.producto){
      if (ent.Producto.Id == this.producto.Id){
        this.eventsService.publish('home:showAlert', { message: "No puede utilizar el Producto que esta editando como componente del mismo Producto.", cancelButton: false });
        return;
      }
    }


    if (ent.Almacen) {
      if (ent.Almacen.Id > 0) { idAlm = ent.Almacen.Id; }
    }

    if (ent.ConceptoSalida) {
      if (ent.ConceptoSalida.Id > 0) { idConcAlm = ent.ConceptoSalida.Id; }
    }

    if (idProd == 0) {
      Swal.fire({ text: 'Debe de indicar un Producto.', icon: 'error', })
      return;
    }
    if (idAlm == 0) {
      Swal.fire({ text: 'Debe de indicar un Almacen.', icon: 'error', })
      return;
    }

    if (idConcAlm == 0) {
      Swal.fire({ text: 'Debe de indicar un Concepto de Salida de Almacen.', icon: 'error', })
      return;
    }
    this.onCloseEdition.emit(ent)
  }
}
