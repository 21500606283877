import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { Alert, AlertResponse } from '../interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private ms: ModalService) { }
  public abierto: boolean = false;

  Show(alert: any, m: Alert) {
    if (this.abierto) return;
    this.abierto = true;

    setTimeout(() => {
      this.ms.openModal(alert, (e: AlertResponse) => {
        m.onConfirm && m.onConfirm(e);
      })
    }, 150);
  }

  onClicAccept() { this.abierto = false; this.ms.closeModal({ isAccept: true, isCancel: false }) }
  onClicNo() { this.abierto = false; this.ms.closeModal({ isAccept: false, isCancel: false, isNo: true }) }
  onClicCancel() {
    this.abierto = false;
    this.ms.closeModal({ isAccept: false, isCancel: true, isNo: false })
  }




}
