import { Component, inject } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'page-blank',
  templateUrl: './page-blank.html'
})

export class PageBlank {

}
