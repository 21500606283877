<app-panel-base title="Opciones del menú...">
    <div class="panel-content">
     <ng-container *ngFor="let item of listItem; let i = index">
        <div class="form-group row">
          <!-- <input  [disabled]="!this.enabled" [tabindex]="tabIndex"  (click)="onclick(item.Id)" class="form-check-input combo-box" type="checkbox" value="" [(ngModel)]="item.Value" #tagChk> -->
          <input  [disabled]="!this.enabled" [tabindex]="tabIndex" ng-click="valor()" (click)="onclick(item)" class="form-check-input combo-box" type="checkbox" value="" [(ngModel)]="item.Value" #tagChk>
          <label class="col-sm-{{widthLabelColumns}} col-form-label tb-right"  [ngStyle]="{'font-weight':getStyle(i),'text-decoration-line': getStyleTexto(i)  }" (click)="onSelect(i)" >
              {{item.Nombre}}
            </label>
        </div>
     </ng-container>
  </div>
</app-panel-base>

