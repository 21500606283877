<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="Sucursal"
  title="Sucursales" icon="fa fa-building" subTitle="Administra la configuración de sucursales...">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box class="combo-id" [label]="'Sucursal'" formControlName="Id" [tabIndex]="2"
            [isNewItem]="getIsNew" [zeroMask]="2" [widthTextColumns]="1" [widthLabelColumns]="2" [widthRightColumns]="6"
            (onChangueEntity)="onChangueEntity($event)" [setItems]="getItemsCombo" [isCatalog]="true"
            entityName="Sucursal" [listProperty]="'ClaveNombre'"></component-ui-combo-box>
          <components-ui-text-box label='Nombre' [required]="true" formControlName="Nombre" [tabIndex]="3"
            [widthTextColumns]="4" [widthLabelColumns]="2" #txtEmpresa />
          <components-ui-text-box label='Alias' [required]="true" formControlName="Alias" [tabIndex]="4"
            [widthTextColumns]="4" [widthLabelColumns]="2" #txtEmpresa />
          <component-ui-combo-box label='Gerente' entityName="Usuario" [tabIndex]="5" formControlName="Encargado"
            [listProperty]="'ClaveNombre'" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectEncargado($event)" />
          <component-ui-combo-box label='Recibe traspasos' entityName="Usuario" [tabIndex]="6"
            formControlName="UsuarioRecibeTraspaso" [listProperty]="'ClaveNombre'" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectUsuario($event)" />
          <component-ui-combo-box label='Lista de precios' entityName="ListaPrecio" [tabIndex]="7"
            formControlName="ListaPrecio" [listProperty]="'ClaveNombre'" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectListaPrecio($event)" />
          <component-ui-check-box label='Usar dirección de empresa' [widthLabelColumns]="2"
            formControlName="UsaDireccionEmpresa" [widthTextColumns]="4" [tabIndex]="8" />
          <component-ui-check-box helpLine="Usará en las búsquedas de productos, el segundo nombre"
            label='Usar Segundo Nombre Producto' [widthLabelColumns]="2" formControlName="UsarSegundoNombreProducto"
            [widthTextColumns]="4" [tabIndex]="9" />
        </div>
      </app-panel-base>

      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Dirección
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#prod-default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>

      </ul>

      <div class="tab-content panel rounded-0 p-1 me-2 ms-2 mt-0">
        <div id="prod-default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <component-ui-address formControlName="Direccion" [tabIndex]="9"
            [enabled]="getActivarDireccion()"></component-ui-address>
        </div>
        <div id="prod-default-tab-2" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">

              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />

            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>
</app-container-base>
