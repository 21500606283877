import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { RegistroActividad } from '../../interfaces/registro-actividad.interface';
import { SistemaService } from '../../services/sistema.service';
import { Subscription } from 'rxjs';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';
import Swal from 'sweetalert2';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'registro-actividad',
  templateUrl: './registro-actividad-page.component.html',
  styleUrls: ['./registro-actividad-page.component.scss'],

})
export class RegistroActividadComponent implements OnInit {


  private fb = inject(FormBuilder);
  private servicioSistema = inject(SistemaService);
  public listaActividades: RegistroActividad[] = [];
  public searching: boolean = false;
  public reportsService = inject(ReportsService);

  @ViewChild('txtusuario')
  public ctrlUsuario!: ElementRef<HTMLElement>;


  public filtrosActividad: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: 'Reporte de actividades',
      TituloVisor: 'Reporte de actividades',
      NombreExcel: 'Reporte de actividades.xlsx'
    };

  activeButtons: ActiveButtons = {
    all: false,
  }


  public myForm: FormGroup = this.fb.group({
    fecha1: new Date(),
    fecha2: new Date(),
    NombreReporte: '',
    Num1: 0
  });


  ngOnInit(): void {
    this.focus();
  }

  focus() {
    setTimeout(() => {
      this.ctrlUsuario.nativeElement.focus();
    }, 100);
  }

  onChangeFecha(value: any) {
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    this.myForm.controls['Num1'].setValue(entity.Id);


  }

  buscar() {
    if (this.myForm.get('fecha1')?.value > this.myForm.get('fecha2')?.value) {
      Swal.fire({ icon: 'info', text: 'El rango de fecha es inválido, favor de revisar.' });
      return;
    }

    this.searching = true;
    this.filtrosActividad.ReportHeader = this.myForm.value;
    this.servicioSistema.consultarActividad(this.filtrosActividad).subscribe((result: RegistroActividad[]) => {
      this.listaActividades = result;
      this.searching = false;
    })
  }

  imprimir() {
    this.filtrosActividad.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosActividad, '/Config/ReporteActividad');
  }
}
