import { Component, inject, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { VentaRelacionDTO } from '../../interfaces/venta.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-venta-relaciones-page',
  templateUrl: './venta-relaciones-page.component.html',
  styles: [
  ]
})
export class VentaRelacionesPageComponent implements OnInit {
  private eventsService = inject(EventsService);
  @Input()
  public idVenta: number = 0;
  public items: any[] = [];

  constructor(private ms: ModalService, private fs: FacturacionService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.cargar();
    }, 100);
  }

  cargar() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerRelaciones(this.idVenta).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (lista.length > 0) {
        this.items = lista;
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró ningúna relación con otros documentos o módulos.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.closeModal();
          }
        });
      }
    })
  }

  closeModal() { this.ms.closeModal(null); }

}
