import { Empresa } from './../../../configuracion/interfaces/empresa.interface';
import { BootstrapOptions, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { MovimientoPoliza, MovimientoPolizaCabecera, MovimientoPolizaComprobantesNacionales, PolizaEdicion } from '../../interfaces/movimientopoliza.interface';
import { GuiCellEdit, GuiColumnAlign, GuiLocalization, GuiRowClass, GuiRowStyle, GuiSearching, GuiSorting } from '@generic-ui/ngx-grid';
import { ContabilidadService } from '../../services/contabilidad.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'src/app/service/modal.service';
import { TipoPoliza } from '../../interfaces/tipopoliza.interface';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { aN } from '@fullcalendar/core/internal-common';
import * as moment from 'moment';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import { addHours } from 'date-fns';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { trim } from 'lodash';
import { ReportsService } from '../../../service/reports.service';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ActivoFijoDocumento } from '../../interfaces/activofijo.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-captura-poliza-page',
  templateUrl: './captura-poliza-page.component.html',
  styleUrls: ['./captura-poliza-page.component.scss']
})
export class CapturaPolizaPageComponent implements OnInit {
  @ViewChild('cboTipoPoliza') public cboTipoPoliza!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtConcepto') public txtConcepto!: ElementRef<TextBoxComponent>;
  @ViewChild('txtFecha') public txtFecha!: ElementRef<DateBoxComponent>;
  @ViewChild('ctrlBusquedaPoliza') public ctrlBusquedaPoliza!: ElementRef<HTMLElement>;
  @ViewChild('ctrlEdicionPoliza') public ctrlEdicionPoliza!: ElementRef<HTMLElement>;
  @ViewChild('ctrlAgregarCfdi') public ctrlAgregarCfdi!: ElementRef<HTMLElement>;
  @ViewChild('ctrlEdicionEncabezado') public ctrlEdicionEncabezado!: ElementRef<HTMLElement>;
  private eventsService = inject(EventsService);
  PolizaEdicion: PolizaEdicion | null = null;
  blockControls: boolean = false;
  editing: boolean = false;
  addingcfdi: boolean = false;
  esPlantilla: boolean = false;
  selectedMonth = -1;
  info: UserLogged = {} as UserLogged;
  saving: boolean = false;
  esEnterConcepto: boolean = false;
  selectedIndex: number = -1;
  selectedItem: MovimientoPoliza | null = null;
  divConta: string = "divConta" + Math.random();
  activeButtons: ActiveButtons = {
    new: true,
    delete: true,
    return: false,
    save: false,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa, Validators.required],
    TipoPoliza: [{} as TipoPoliza, Validators.required],
    Fecha: [Date],
    Folio: [0],
    ConceptoGeneral: [''],
    UsuarioAlta: [{} as Usuario],
    UsuarioModifica: [{} as Usuario],
  })


  listaDetalles: MovimientoPoliza[] = []
  public GuiColumnAlign = GuiColumnAlign;
  localization: GuiLocalization = {
    'translation': {
      'sourceEmpty': 'No se hay registros para mostrar',

    }
  };
  searching: GuiSearching = { enabled: true };
  sorting: GuiSorting = { enabled: true };
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;

  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: MovimientoPoliza, index: number) => {
      this.indexEditing = index;
      if (!item.cuenta) { return false; }
      return true;
    },

    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }


  rowStyle: GuiRowStyle = {
    styleFunction: (data: MovimientoPoliza, index: number) => {
      if (data.modificado) {
        return 'color: red; font-weight: bold;';
      }
      return '';
    }
  };

  sourceEdited(e: any) {

    let editar = false;

    if ((e.after.conceptoDetalle != e.before.conceptoDetalle) ||
      (e.after.referencia != e.before.referencia) ||
      (e.after.importe != e.before.importe)) {
      editar = true;
    }

    if (editar) {
      setTimeout(() => {
        this.listaDetalles = [...this.listaDetalles];
        this.saving = true;
        if (!this.listaDetalles[this.indexEditing].importe) {
          this.listaDetalles[this.indexEditing].importe = 0;
        }
        const json = JSON.stringify(this.listaDetalles[this.indexEditing]);
        this.containerService.saveEntity("MovimientoPoliza", json).subscribe((result: Result) => {
          if (result.success) {
            const ent: any = JSON.parse(result.message);
            this.contaServices.getPolicyById(ent.Id).subscribe((mp) => {
              mp.modificado = true;
              this.listaDetalles[this.indexEditing] = mp;
              this.listaDetalles = [...this.listaDetalles];
              this.saving = false;
            })
          } else {
            this.saving = false;
            this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
          }
        });
      }, 250);

    } else {
      this.listaDetalles = [...this.listaDetalles];
    }
  }

  initEditor(row: number, col: number) {
    const elem: any = this.getElemEditor(row, col);
    if (elem) {
      setTimeout(() => {
        elem.firstElementChild?.click();
      }, 250);
    }
  }

  getElemEditor(row: number, col: number) {
    const elem: any = document.querySelector(`[ng-reflect-column-index='${col}'][ng-reflect-row-index='${row}']`);
    return elem;
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.newEntity(); break;
      case "save": break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
      case "print":
        this.imprimirPoliza();
        break;
    }
  }

  newEntity() {
    this.blockControls = false;
    this.info = this.utilService.getUserLogged();
    this.myForm.reset({
      Id: 0,
      Empresa: { Id: this.info.empresa?.numero },
      TipoPoliza: this.myForm.value.TipoPoliza,
      Fecha: this.myForm.value.Fecha,
      Folio: 0,
      ConceptoGeneral: '',
      UsuarioAlta: null,
      UsuarioModifica: null,
    })
    this.setSelectedMont(this.myForm.value.Fecha.getMonth() + 1);
    this.resetSource(null);
    this.contaServices.getNextPolicyNumber(this.info.empresa!.numero, this.myForm.value.Fecha.getMonth() + 1, this.myForm.value.Fecha.getFullYear(), this.myForm.value.TipoPoliza.Id).subscribe((folio) => {
      this.myForm.controls["Folio"].setValue(folio);
      const txt: any = this.txtConcepto;
      txt.tagInput.nativeElement.focus();
    });
  }

  deleteRow(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el asiento contable?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          const obj: any = this.listaDetalles[index];
          this.saving = true;
          let idEliminar = 0;
          if (obj.id) idEliminar = obj.id;
          if (obj.Id) idEliminar = obj.Id;
          this.contaServices.deletePolicyDetail(idEliminar).subscribe((result) => {
            this.saving = false;
            if (!result.success) {
              this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
            } else {
              this.listaDetalles.splice(index, 1);
              this.listaDetalles = [...this.listaDetalles];
            }
          });
        }
      }
    });
  }

  get getComprobantesNacionales(): string {
    let uuids = "";
    if (this.selectedItem) {
      if (this.selectedItem!.comprobantesNacionales) {
        this.selectedItem!.comprobantesNacionales.forEach((item: MovimientoPolizaComprobantesNacionales) => {
          if (item.uuid) {
            uuids += `${item.uuid},`;
          }
        });
      }
    }
    if (uuids.length > 0) {
      uuids = uuids.substring(0, uuids.length - 1);
    }
    return uuids;
  }

  get getTitleCfdi(): string {
    if (this.indexEditing >= 0) {
      const ed = { ...this.listaDetalles[this.indexEditing] };
      return `#${ed.orden} ${ed.claveCuentaNivelGlobal} ${ed.conceptoDetalle}`
    }

    return "";
  }

  addCfdi(index: number) {
    if (this.addingcfdi) return;
    this.selectedItem = this.listaDetalles[index];
    this.addingcfdi = true;
    this.indexEditing = index;


    const b: any = this.ctrlAgregarCfdi;
    this.ms.openModal(b, (e: DescargaCfdi[]) => {
      this.onCloseCfdi(e);
    });

  }

  editarEncabezado() {
    this.PolizaEdicion = {
      Empresa: this.myForm.value.Empresa,
      TipoPoliza: this.myForm.value.TipoPoliza,
      Fecha: this.myForm.value.Fecha,
      Folio: this.myForm.value.Folio,
    };
    const b: any = this.ctrlEdicionEncabezado;
    this.ms.openModal(b, (poliza: PolizaEdicion) => {
      if (poliza) {
        this.eventsService.publish('home:isLoading', { isLoading: true });

        this.listaDetalles.map((item: MovimientoPoliza, index: number) => {
          item.folio = poliza.Folio;
          item.fecha = poliza.Fecha;
          item.fecha = new Date(Date.UTC(item.fecha.getFullYear(), item.fecha.getMonth(), item.fecha.getDate(), 15, 0, 0));
          item.tipoPoliza = poliza.TipoPoliza;
          return item;
        })
        this.saving = true;
        this.contaServices.saveRange(this.listaDetalles).subscribe((result) => {
          this.saving = false;
          if (!result.success) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
          } else {
            const i: MovimientoPoliza = this.listaDetalles[0];
            this.contaServices.getPolicyByNumber(this.info.empresa!.numero, moment(moment(i.fecha).toDate()).format("DD/MM/YYYY"), i.tipoPoliza!.Id, i.folio).subscribe((lista) => {
              this.saving = false;
              this.eventsService.publish('home:isLoading', { isLoading: false });
              Swal.fire({ position: 'center', icon: 'success', title: 'Se editó el encabezado correctamente', showConfirmButton: false, timer: 2000 })
              lista.map((item: MovimientoPoliza, index: number) => {
                item.index = index + 1
                return item;
              });
              this.blockControls = true;
              const i: MovimientoPoliza = lista[0];
              this.myForm.controls['Id'].setValue(i.id);
              this.myForm.controls['ConceptoGeneral'].setValue(i.conceptoGeneral);
              this.myForm.controls['TipoPoliza'].setValue(i.tipoPoliza);
              this.myForm.controls['Folio'].setValue(i.folio);
              this.myForm.controls['Fecha'].setValue(moment(i.fecha).toDate());
              this.myForm.controls['UsuarioAlta'].setValue(i.usuarioAlta);
              this.myForm.controls['UsuarioModifica'].setValue(i.usuarioModifica);
              this.resetSource(lista);
              this.focoConcepto();
            });
          }
        });


      }
    });

  }


  navigate(type: string) {
    this.saving = true;
    this.contaServices.getPolicyNavigation(this.info.empresa!.numero, this.myForm.value.Fecha.getFullYear(), this.selectedMonth, this.myForm.value.TipoPoliza.Id, this.myForm.value.Folio, type).subscribe((lista) => {
      this.saving = false;
      lista.map((item: MovimientoPoliza, index: number) => {
        item.index = index + 1
        return item;
      })
      if (lista.length > 0) {
        this.blockControls = true;
        const i: MovimientoPoliza = lista[0];
        this.myForm.controls['Id'].setValue(i.id);
        this.myForm.controls['Folio'].setValue(i.folio);
        this.myForm.controls['Fecha'].setValue(moment(i.fecha).toDate());
        this.myForm.controls['ConceptoGeneral'].setValue(i.conceptoGeneral);
        this.myForm.controls['UsuarioAlta'].setValue(i.usuarioAlta);
        this.myForm.controls['UsuarioModifica'].setValue(i.usuarioModifica);
      } else {
        this.myForm.controls['Id'].setValue(0);
        this.myForm.controls['ConceptoGeneral'].setValue('');
        this.myForm.controls["UsuarioAlta"].setValue(null)
        this.myForm.controls["UsuarioModifica"].setValue(null)
        this.blockControls = false;
      }
      this.resetSource(lista);
      this.focoConcepto();
    })
  }

  get getPeriod(): number {
    if (this.myForm.value.Fecha) {
      return parseInt(moment(this.myForm.value.Fecha).format("YYYYMM"))
    }
    return 0;
  }

  changueMont(month: number, e: any) {
    if (month != this.selectedMonth) {

      this.indexEditing = -1;
      this.saving = false;
      this.addingcfdi = false;
      this.selectedItem = null;
      this.editing = false;
      if (!this.myForm.value.TipoPoliza) return;
      const idTipo = this.myForm.value.TipoPoliza.Id;
      const folio = this.myForm.value.Folio;
      this.saving = true;
      this.contaServices.getPolicyByNumberYearMonth(this.info.empresa!.numero, this.myForm.value.Fecha.getFullYear(), month, idTipo, folio).subscribe((lista) => {
        this.saving = false;
        lista.map((item: MovimientoPoliza, index: number) => {
          item.index = index + 1
          return item;
        })
        if (lista.length > 0) {
          this.blockControls = true;
          const i: MovimientoPoliza = lista[0];
          this.myForm.controls['Id'].setValue(i.id);
          this.myForm.controls['Fecha'].setValue(moment(i.fecha).toDate());
          this.myForm.controls['ConceptoGeneral'].setValue(i.conceptoGeneral);
          this.myForm.controls['UsuarioAlta'].setValue(i.usuarioAlta);
          this.myForm.controls['UsuarioModifica'].setValue(i.usuarioModifica);
        } else {
          this.myForm.controls['Id'].setValue(0);
          this.myForm.controls['ConceptoGeneral'].setValue('');
          this.myForm.controls["UsuarioAlta"].setValue(null)
          this.myForm.controls['Fecha'].setValue(new Date(this.myForm.value.Fecha.getFullYear(), month - 1, 1));
          this.myForm.controls["UsuarioModifica"].setValue(null)
          this.blockControls = false;
        }
        this.resetSource(lista);
        this.focoConcepto();
      })
    }


    this.setSelectedMont(month);
  }

  setSelectedMont(index: number) {
    this.selectedMonth = index;
  }

  verPlantillas() {
    this.esPlantilla = true;
    setTimeout(() => {
      this.openSearch();
    }, 150);
  }

  marcarPlantilla() {
    let ids: string = '';
    this.listaDetalles.filter((P => P.cuenta)).forEach((item) => {
      ids += `${item.id},`;
    })
    if (ids.length > 0) {
      this.saving = true;
      ids = ids.substring(0, ids.length - 1);
      this.contaServices.markAsTemplate(ids, true).subscribe(() => {
        this.eventsService.publish('home:showAlert', { message: 'Se marcó la póliza como plantilla, podrá copiarla desde el menú de opciones.', cancelButton: false });
        this.saving = false;
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: 'No hay asientos contables en la póliza.', cancelButton: false });
    }
  }

  getIsMonthSelected(index: number): string {
    return this.selectedMonth == index ? 'nav-link active' : 'nav-link ';
  }

  resetSource(details: MovimientoPoliza[] | null, continueEditing: boolean = false) {

    if (details) {
      this.listaDetalles = [...details.filter(P => P.cuenta)];
    } else {
      this.listaDetalles = []
    }
    const length = this.listaDetalles.length;
    //for (let index = 0; index < length + 1; index++) {
    this.listaDetalles = [...this.listaDetalles, {
      id: 0,
      index: length + 1,
      empresa: null,
      tipoPoliza: null,
      fecha: new Date(),
      periodo: 0,
      ejercicio: 0,
      folio: 0,
      orden: 0,
      conceptoGeneral: '',
      diferencia: 0,
      usuarioAlta: null,
      usuarioModifica: null,
      //Detalle de la póliza
      cuenta: null,
      claveCuentaNivel1: '',
      claveCuentaNivel2: '',
      claveCuentaNivel3: '',
      claveCuentaNivel4: '',
      claveCuentaNivelGlobal: '',
      nombreCuentaNivel1: '',
      nombreCuentaNivel2: '',
      nombreCuentaNivel3: '',
      nombreCuentaNivel4: '',
      claveCentroCostoNivel1: '',
      claveCentroCostoNivel2: '',
      claveCentroCostoNivel3: '',
      claveCentroCostoNivel4: '',
      claveCentroCostoNivelGlobal: '',
      centroCosto: null,
      tipoMovimiento: false, //true = Abono, false = cargo
      importe: 0,
      importeCargo: 0,
      importeAbono: 0,
      conceptoDetalle: '',
      referencia: '',
      uuids: '',
      eliminado: false,
      modificado: false,
      esPlantilla: false,
      idDiot: 0,
      comprobantesNacionales: [],
      baja: false,
    }];
    //}

    setTimeout(() => {
      const rows = document.getElementById(this.divConta)!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
      if (rows) {
        rows.forEach((item, index) => {
          item.addEventListener("dblclick", () => {
            this.onDblClickRow(index);
          });
        })
      }

      if (continueEditing) {
        this.initEdit(this.selectedIndex + 1);
      } else {
        this.editing = false;
        this.selectedIndex = -1;
        this.selectedItem = null;
      }

    }, 100);

  }



  get getSelectedItem(): MovimientoPoliza {
    return { ...this.selectedItem! };
  }

  rowClass: GuiRowClass = {
    classFunction: (data: MovimientoPoliza, index: number) => {

      if (data.comprobantesNacionales?.length > 0) {
        return 'poliza-uuids';
      }
      return '';
    }
  };

  onCloseCfdi(lista: DescargaCfdi[] | null) {
    this.addingcfdi = false;
    if (lista) {
      if (lista.length > 0) {
        this.saving = true;
        //let uuids = '';
        //uuids = uuids.substring(0, uuids.length - 1).trim();
        this.listaDetalles[this.indexEditing].comprobantesNacionales = [];
        lista.forEach((item) => {
          this.listaDetalles[this.indexEditing].comprobantesNacionales = [...this.listaDetalles[this.indexEditing].comprobantesNacionales, {
            id: 0,
            uuid: item.uuid,
            rfcEmisor: item.emisorRfc,
            serieFolio: item.serieFolio,
            subTotal: item.subtotal,
            iva: item.ivaTrasladado,
            isrRetenido: item.isrRetenido,
            ivaRetenido: item.ivaRetenido,
            total: item.total,
          }]
        })
        const json = JSON.stringify(this.listaDetalles[this.indexEditing]);
        this.containerService.saveEntity("MovimientoPoliza", json).subscribe((result: Result) => {
          if (result.success) {
            const ent: any = JSON.parse(result.message);
            this.contaServices.getPolicyById(ent.Id).subscribe((mp) => {
              mp.modificado = true;
              this.listaDetalles[this.indexEditing] = mp;
              this.listaDetalles = [...this.listaDetalles];
              this.saving = false;
              this.selectedItem = null;
            })
          } else {
            this.saving = false;
            this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
          }
          this.indexEditing = -1;
        });

      }
    } else {
      this.selectedIndex = -1;
      this.selectedItem = null;
    }

  }

  blurConcepto(values: any) {
    if (values.after) {
      if (values.after != values.before) {
        this.listaDetalles.map((item) => {
          item.conceptoGeneral = values.after;
          return item;
        });
        let ids: string = '';
        this.listaDetalles.filter((P => P.cuenta)).forEach((item) => {
          ids += `${item.id},`;
        })
        if (ids.length > 0) {
          ids = ids.substring(0, ids.length - 1);
          this.contaServices.setConceptoGeneral(ids, values.after).subscribe(() => {
            if (this.esEnterConcepto) { this.esEnterConcepto = false; this.initEdit(0); }
          })
        } else {
          if (this.esEnterConcepto) { this.esEnterConcepto = false; this.initEdit(0); }
        }
      } else {
        if (this.esEnterConcepto) { this.esEnterConcepto = false; this.initEdit(0); }
      }
    } else {
      if (this.esEnterConcepto) { this.esEnterConcepto = false; this.initEdit(0); }
    }
  }

  enterConcepto() {
    this.esEnterConcepto = true;
    const txt: any = this.txtConcepto;
    txt.tagInput.nativeElement.blur();
  }

  initEdit(index: number) {
    const txt: any = this.txtConcepto;
    txt.tagInput.nativeElement.blur();
    setTimeout(() => {
      this.selectedIndex = index;
      const sel: any = this.listaDetalles[this.selectedIndex];
      if (!sel.empresa) {
        sel.empresa = { id: this.info.empresa?.numero };
        sel.tipoPoliza = { id: this.myForm.value.TipoPoliza.Id };
        sel.fecha = this.myForm.value.Fecha;
        sel.conceptoGeneral = this.myForm.value.ConceptoGeneral;
        sel.conceptoDetalle = sel.conceptoGeneral;
        sel.folio = this.myForm.value.Folio;
      }
      this.selectedItem = sel;
      this.editing = true;
      // const el = document.querySelector(`[ng-reflect-index='${this.selectedIndex}']`);
      // if (el) {
      //   el.scrollIntoView({ block: "center" });
      // }
      const b: any = this.ctrlEdicionPoliza;
      this.ms.openModal(b, (e: MovimientoPoliza) => {
        this.onCloseEdit(e);
      });

    }, 100);
  }

  onCloseEdit(mov: MovimientoPoliza): void {
    if (!mov) {
      this.editing = false;
      this.selectedIndex = -1;
      this.selectedItem = null;
      this.resetSource(this.listaDetalles);
      this.focoConcepto();
    } else {
      const indexSel = this.selectedIndex;
      this.listaDetalles[this.selectedIndex] = mov;
      this.resetSource(this.listaDetalles, false);
      this.myForm.controls["Id"].setValue(mov.id);
      this.editing = false;
      let ids: string = '';
      this.listaDetalles.filter((P => P.cuenta)).forEach((item) => {
        ids += `${item.id},`;
      })
      if (ids.length > 0) {
        ids = ids.substring(0, ids.length - 1);
        this.contaServices.setPolicyUser(ids).subscribe(() => {
          this.enviarMensajeCuadra(indexSel);
        })
      } else {
        this.initEdit(indexSel + 1);
      }
    }
  }

  enviarMensajeCuadra(index: number) {
    let cargos: number = 0;
    let abonos: number = 0;
    this.listaDetalles.forEach((item) => {
      if (!item.tipoMovimiento) {
        cargos += parseFloat(String(item.importe));
      } else {
        abonos += parseFloat(String(item.importe));
      }
    })

    if (cargos != 0 && abonos != 0) {
      if (cargos == abonos) {
        this.eventsService.publish('home:showAlert', {
          message: 'Póliza capturada correctamente.',
          textAccept: 'Continuar',
          textCancel: 'Imprimir',
          onConfirm: (r: AlertResponse) => {
            if (r.isAccept) {
              this.focoConcepto();
            } else {
              this.imprimirPoliza();
            }
          }
        });
      } else {
        this.initEdit(index + 1);
      }
    } else {
      this.initEdit(index + 1);
    }
  }

  onDblClickRow(index: number) {
    if (this.editing || this.addingcfdi) return;
    this.editing = true;
    this.initEdit(index);
  }


  private reportsService = inject(ReportsService);

  constructor(private contaServices: ContabilidadService,
    private fb: FormBuilder,
    private utilService: UtilsService,
    private containerService: ContainerBaseService,
    public appSettings: AppSettings,
    private http: HttpClient,
    private ms: ModalService) {
  }

  resetEntity(sendFocus: boolean, date: Date | null) {
    this.blockControls = false;
    this.info = this.utilService.getUserLogged();
    this.myForm.reset({
      Id: 0,
      Empresa: { Id: this.info.empresa?.numero },
      Fecha: date == null ? this.myForm.value.Fecha : date,
      Folio: 0,
      ConceptoGeneral: '',
      UsuarioAlta: null,
      UsuarioModifica: null,
    })

    this.setSelectedMont(this.myForm.value.Fecha.getMonth() + 1);
    this.resetSource(null);
    sendFocus && setTimeout(() => {
      const txt: any = this.txtFecha;
      txt.tagInput.nativeElement.focus();
      txt.tagInput.nativeElement.setSelectionRange(0, 0)
    }, 150);
  }

  onChangeDate(date: any | null) {
    this.setSelectedMont(date ? date.getMonth() + 1 : -1);
  }

  get getDiff(): number {
    return this.getCargos - this.getAbonos;
  }

  get getAbonos(): number {
    let t: number = 0;
    this.listaDetalles.forEach((item: MovimientoPoliza) => { if (item.cuenta && item.tipoMovimiento) t += parseFloat(String(item.importe)); });
    return parseFloat(t.toFixed(2));
  }

  get getCargos(): number {
    let t: number = 0;
    this.listaDetalles.forEach((item: MovimientoPoliza) => { if (item.cuenta && !item.tipoMovimiento) t += parseFloat(String(item.importe)); });
    return parseFloat(t.toFixed(2));
  }

  get getListCount(): number {
    return this.listaDetalles.filter((P => P.cuenta)).length;
  }

  ngOnInit(): void {
    this.resetEntity(true, new Date());
    this.appSettings.appSidebarCollapsed = true;
    setTimeout(() => {

      const el = document.querySelector('.gui-search-bar');
      if (el) {
        el.classList.add("marginsearch");
      }
    }, 250);
  }

  openSearch(esCopiar: boolean = false) {
    const b: any = this.ctrlBusquedaPoliza;
    const ent = this.myForm.value;
    this.ms.openModal(b, (e: MovimientoPolizaCabecera) => {
      if (e) {
        this.saving = true;
        this.contaServices.getPolicyByNumber(e.empresaId, moment(moment(e.fecha).toDate()).format("DD/MM/YYYY"), e.idTipoPoliza, e.folio).subscribe((lista) => {
          this.saving = false;
          lista.map((item: MovimientoPoliza, index: number) => {
            if (this.esPlantilla || esCopiar) {
              item.id = 0;
              item.esPlantilla = false;
              item.folio = ent.Folio;
              item.fecha = ent.Fecha;

              item.fecha = new Date(Date.UTC(item.fecha.getFullYear(), item.fecha.getMonth(), item.fecha.getDate(), 15, 0, 0));

              item.tipoPoliza = ent.TipoPoliza;
              item.usuarioAlta = null;
              item.usuarioModifica = null;
              this.changueMont(item.fecha.getMonth() + 1, null);
            } else {
              item.fecha = moment(item.fecha).toDate();
            }
            item.index = index + 1
            return item;
          })

          this.blockControls = true;
          const i: any = lista[0];
          this.myForm.controls['Id'].setValue(i.id);
          this.myForm.controls['ConceptoGeneral'].setValue(i.conceptoGeneral);
          this.myForm.controls['Folio'].setValue(i.folio);
          this.myForm.controls['Fecha'].setValue(moment(i.fecha).toDate());
          this.myForm.controls['UsuarioAlta'].setValue(i.usuarioAlta);
          this.myForm.controls['UsuarioModifica'].setValue(i.usuarioModifica);
          this.resetSource(lista);
          this.focoConcepto();
          this.selectedMonth = i.fecha.getMonth() + 1;
          if (this.esPlantilla || esCopiar) {
            this.saving = true;
            this.contaServices.saveRange(lista).subscribe((result) => {
              this.saving = false;
              if (!result.success) {
                this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
              } else {
                this.contaServices.getPolicyByNumber(this.info.empresa!.numero, moment(moment(i.fecha).toDate()).format("DD/MM/YYYY"), this.myForm.value.TipoPoliza.Id, this.myForm.value.Folio).subscribe((lista) => {
                  this.saving = false;
                  lista.map((item: MovimientoPoliza, index: number) => {
                    item.index = index + 1
                    return item;
                  })

                  this.blockControls = true;
                  const i: MovimientoPoliza = lista[0];

                  this.myForm.controls['Id'].setValue(i.id);
                  this.myForm.controls['ConceptoGeneral'].setValue(i.conceptoGeneral);
                  this.myForm.controls['Folio'].setValue(i.folio);
                  this.myForm.controls['Fecha'].setValue(moment(i.fecha).toDate());
                  this.myForm.controls['UsuarioAlta'].setValue(i.usuarioAlta);
                  this.myForm.controls['UsuarioModifica'].setValue(i.usuarioModifica);
                  this.resetSource(lista);
                  this.focoConcepto();
                });
              }
            })
          }
          this.esPlantilla = false;
        })
      } else {
        this.esPlantilla = false;
      }
    }, 'lg')
  }

  onDelete() {
    this.saving = true;
    let ids: string = '';
    this.listaDetalles.filter((P => P.cuenta)).forEach((item) => {
      ids += `${item.id},`;
    })
    if (ids.length > 0) {
      ids = ids.substring(0, ids.length - 1);
      this.contaServices.deletePolicy(ids).subscribe((result) => {
        this.saving = false;
        if (result.success) {
          this.eventsService.publish('home:showAlert', {
            message: 'Se eliminó la póliza.',
            cancelButton: false,
            onConfirm: (r: AlertResponse) => {
              this.newEntity();
            }
          });
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });

        }
      });
    }
  }

  blurFolio(values: any) {
    if (values.after != values.before) {

      const idTipo = this.myForm.value.TipoPoliza.Id;
      const folio = this.myForm.value.Folio;
      this.saving = true;
      this.contaServices.getPolicyByNumber(this.info.empresa!.numero, moment(this.myForm.value.Fecha).format("DD/MM/YYYY"), idTipo, folio).subscribe((lista) => {
        this.saving = false;
        lista.map((item: MovimientoPoliza, index: number) => {
          item.index = index + 1
          return item;
        })
        if (lista.length > 0) {
          this.blockControls = true;
          const i: MovimientoPoliza = lista[0];
          this.myForm.controls['Id'].setValue(i.id);
          this.myForm.controls['Fecha'].setValue(moment(i.fecha).toDate());
          this.myForm.controls['ConceptoGeneral'].setValue(i.conceptoGeneral);
          this.myForm.controls['UsuarioAlta'].setValue(i.usuarioAlta);
          this.myForm.controls['UsuarioModifica'].setValue(i.usuarioModifica);
        } else {
          this.myForm.controls['Id'].setValue(0);
          this.myForm.controls['ConceptoGeneral'].setValue('');
          this.myForm.controls["UsuarioAlta"].setValue(null)
          this.myForm.controls["UsuarioModifica"].setValue(null)
          this.blockControls = false;
        }
        this.resetSource(lista);
        this.focoConcepto();
      })
    }
  }

  onSelectedItem(value: any, type: string) {
    if (type == "TipoPoliza") {
      if (value?.Id != this.myForm.value.TipoPoliza?.Id) {
        this.resetEntity(false, null);
        this.myForm.controls[type].setValue(value);
        const mes = this.myForm.value.Fecha.getMonth() + 1;
        const anio = this.myForm.value.Fecha.getFullYear();
        this.saving = true;
        this.contaServices.getNextPolicyNumber(this.info.empresa!.numero, mes, anio, value.Id).subscribe((folio) => {
          this.saving = false;
          this.myForm.controls["Folio"].setValue(folio);
          this.focoConcepto();
        })
      }
    }
  }

  focoConcepto() {
    setTimeout(() => {
      const txt: any = this.txtConcepto;
      txt.tagInput.nativeElement.focus()
      txt.tagInput.nativeElement.select();
    }, 150);
  }

  imprimirPoliza() {
    if (this.myForm.value.TipoPoliza) {

      if (this.getDiff != 0) {
        this.eventsService.publish('home:showAlert', {
          message: "La Póliza está descuadrada, verifique.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.enviarImprimir();
          }
        });
      } else {
        this.enviarImprimir();
      }
    } else {
      this.eventsService.publish('home:showAlert', { message: 'Debe seleccionar una póliza válida.', cancelButton: false });
    }
  }
  enviarImprimir() {
    let body = {
      idEmpresa: this.info.empresa!.numero,
      fecha: moment(this.myForm.value.Fecha).format("DD/MM/YYYY"),
      idTipoPoliza: this.myForm.value.TipoPoliza.Id,
      folio: this.myForm.value.Folio
    };
    this.reportsService.printReportWithBody(body, "/contabilidad/reportepoliza", "Impresión de Póliza")
  }
}
