import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'balance-general-page',
  templateUrl: './balance-general-page.component.html'
})
export class BalanceGeneralPageComponent implements AfterViewInit {


  @ViewChild('txtFileConfiguracion')
  public txtFileConfiguracion!: ElementRef<HTMLInputElement>;

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<HTMLElement>;;

  //* injects
  private utilsService = inject(UtilsService);
  private contabilidadService = inject(ContabilidadService);
  private fb = inject(FormBuilder)
  private reportsService = inject(ReportsService)
  private eventsService = inject(EventsService)
  private http = inject(HttpClient);

  //* variables globales
  listaMeses: any[] = [];
  listaAnios: any[] = [];
  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  anio1Seleccionado?: ComboBoxEntity;
  anio2Seleccionado?: ComboBoxEntity;
  loadComplete: boolean = false;
  file: any;
  mesActual: number = 0;
  private readonly baseUrl: string = environment.baseUrlApi;
  esReporteComparativo: boolean = false;
  reportePdf: string = "";
  menuId: string = "balance-general";

  public filtrosReporte: ReportFilter =
    {
      EsContabilidad:true,
      menuId:'',
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Balance',
      NombreExcel: 'Balance.xlsx',
      FilterOptions: [
        { Campo: 'mpactual2.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mpactual2.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Opc1: false,
    Opc2: false,
    Opc3: false,
    Opc4: false,
    Opc5: false,
    Mes1:0,
    Mes2:0,
    Anio1:0,
    Anio2:0
  });

  ngAfterViewInit(): void {
    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
  }

  ngOnInit(): void {

    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);


    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);

    this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;


    this.myForm.get('Mes1')?.setValue(mesItem.Clave);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anio1Seleccionado = this.listaAnios[0];
        if (this.listaAnios.length > 1) {
          this.anio2Seleccionado = this.listaAnios[1];
        } else {
          this.anio2Seleccionado = this.listaAnios[0];
        }

        this.myForm.get('Anio2')?.setValue(this.anio2Seleccionado!.Clave);
        this.myForm.get('Anio1')?.setValue(this.anio1Seleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
        this.filtrosReporte.menuId = this.menuId;
      }
    })
  }


  reportHeaderChange(value: any) {
    let a: any = 0;
    if (value) {
      if (value.Mes1 && value.Mes2) {
        let mesItem: any = this.listaMeses.find(p => p.Id == value.Mes1);
        this.mes1Seleccionado = mesItem;
        mesItem = this.listaMeses.find(p => p.Id == value.Mes2);
        this.mes2Seleccionado = mesItem;
      }
      if (value.Anio1) {
        let anioItem: any = this.listaAnios.find(p => p.Id == value.Anio1);
        this.anio1Seleccionado = anioItem;
      }
    }
    this.myForm.get('Mes1')?.setValue(this.mes1Seleccionado!.Clave);
    this.myForm.get('Mes2')?.setValue(this.mes2Seleccionado!.Clave);
    this.myForm.get('Anio1')?.setValue(this.anio1Seleccionado!.Clave);
    this.myForm.get('Anio2')?.setValue(this.anio2Seleccionado!.Clave);
    this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
  }

  clicComparativo(value: boolean) {
    this.esReporteComparativo = value;
  }

  getListaMeses(): ComboBoxEntity[] {
    return this.listaMeses
  }



  get getMes1(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechasComparativo);
    }
    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes1Seleccionado) {
      result = this.mes1Seleccionado;
    }
    return result;
  }

  get getMes2(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechasComparativo);
    }
    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes2Seleccionado) {
      result = this.mes2Seleccionado;
    }
    return result;
  }

  get rangoFechas() {
    let result = "";
    if (!this.anio1Seleccionado) return result;
    if (!this.mes1Seleccionado) return result;

    const diaUltimo: number = this.utilsService.getUltimoDiaMes(this.anio1Seleccionado.Id, this.mes1Seleccionado.Id);

    result = diaUltimo + " DE " + this.utilsService.getMes(this.mes1Seleccionado!.Id) + " DE " + this.anio1Seleccionado.Clave;
    return result.toUpperCase();
  }

  get rangoFechasComparativo() {
    let result = "";
    if (!this.anio1Seleccionado) return result;
    if (!this.mes1Seleccionado) return result;
    if (!this.anio2Seleccionado) return result;
    if (!this.mes2Seleccionado) return result;

    let diaUltimo: number = this.utilsService.getUltimoDiaMes(this.anio1Seleccionado.Id, this.mes1Seleccionado.Id);
    result = diaUltimo + " DE " + this.utilsService.getMes(this.mes1Seleccionado!.Id) + " DE " + this.anio1Seleccionado.Clave;
    diaUltimo = this.utilsService.getUltimoDiaMes(this.anio2Seleccionado.Id, this.mes2Seleccionado.Id);
    result += " contra " + diaUltimo + " DE " + this.utilsService.getMes(this.mes2Seleccionado!.Id) + " DE " + this.anio2Seleccionado.Clave;
    return result.toUpperCase();
  }



  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  get selectAnio1ManualItem(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechasComparativo);
    }
    return this.anio1Seleccionado!;
  }

  get selectAnio2ManualItem(): ComboBoxEntity | null {
    if (!this.esReporteComparativo) {
      this.myForm.get('NombreReporte')?.setValue('ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechas);
    } else {
      this.myForm.get('NombreReporte')?.setValue('COMPARATIVO DE ESTADO DE POSICIÓN FINANCIERA AL ' + this.rangoFechasComparativo);
    }
    return this.anio2Seleccionado!;
  }


  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Mes1') {
      this.mes1Seleccionado = entity;
      this.mes2Seleccionado = entity;
    } else if (field == 'Anio1') {
      this.anio1Seleccionado = entity;
    } else if (field == 'Mes2') {
      this.mes2Seleccionado = entity;
    } else if (field == 'Anio2') {
      this.anio2Seleccionado = entity;
    }
    this.myForm.controls[field].setValue(entity.Clave);
  }



  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.http.post<any>(`${this.baseUrl}/Contabilidad/ReporteBalance`, this.filtrosReporte).subscribe({
      next: result => {
        if (result.success) {
          let res: number = result.message.indexOf("<mensaje>");
          let mensaje: string = "";
          if (res != -1) {
            mensaje = result.message.substring(res);
            this.reportePdf = result.message.substring(0, res);
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.eventsService.publish('home:showAlert', {
              message: mensaje,
              textAccept: 'Aceptar',
              cancelButton: false,
              onConfirm: (data: AlertResponse) => {
                this.eventsService.publish('home:isLoading', { isLoading: true });
                this.filtrosReporte.ReportHeader = this.myForm.value;
                this.reportsService.printReportFromBase64String(this.filtrosReporte, '/Contabilidad/ReporteBalance', false, this.reportePdf);
                this.eventsService.publish('home:isLoading', { isLoading: false });
              }
            });
          } else {
            this.reportePdf = result.message;
            this.filtrosReporte.ReportHeader = this.myForm.value;
            this.reportsService.printReportFromBase64String(this.filtrosReporte, '/Contabilidad/ReporteBalance', false, this.reportePdf);
            this.eventsService.publish('home:isLoading', { isLoading: false });
          }
        }
      }
    })
  }
}
