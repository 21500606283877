import { Component, ElementRef, QueryList, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { ReportFilter, ReportFilterContentList, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionReporteSaldos } from 'src/app/cuentas-por-cobrar/interfaces/saldocxc.interface';
import { CuentasPorCobrarService } from 'src/app/cuentas-por-cobrar/services/cxc.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'antiguedad-saldos-cxp-page',
  templateUrl: './antiguedad-saldos-cxp-page.component.html',
  styles: [
  ]
})
export class AntiguedadSaldosCxpPageComponent {


  //*injects
  private reportsService = inject(ReportsService)
  private modalService = inject(ModalService)
  fechaDelReporte: Date = new Date();

  menuId: string = "antiguedadsaldoscxp";


  @ViewChild('ctrlRangos')
  public ctrlRangos!: QueryList<ElementRef>;
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  //*configuracion de filtros del reporte
  public desgloseLista: ReportFilterContentList[] = [{ Nombre: 'a Detalle' },
  { Nombre: 'a Totales' },
  { Nombre: 'Analisis de vencimientos' }]

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Numérico', Orden: 'empclave,succlave,provclave' },
  { Nombre: 'Alfabético', Orden: 'empclave,succlave,provNombre' }]

  public luegoPorLista: ReportFilterOrderList[] = [{ Nombre: 'Ninguno', Orden: '' },
  { Nombre: 'Fecha de emisión', Orden: 'Fechaemision' },
  { Nombre: 'Fecha de vencimiento', Orden: 'FechaVencimiento' }]

  public filtrosReporte: ReportFilter =
    {
      menuId :this.menuId,
      ReportHeader: {} as ReportHeader,
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      DesgloseLista: this.desgloseLista,
      NombreReporte: '',
      TituloVisor: 'Antigüedad de saldos',
      NombreExcel: 'Antigüedad de saldos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cuentacxp.Clave', Etiqueta: 'Cuenta de cxp.', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxp" },
        { Campo: 'clase.clave', Etiqueta: 'Clase de compra.', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseCompra" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'usu.Clave', Etiqueta: 'usuario elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
      ],
    };

  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: null,
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
    Opc5: [false],
    Opc6: [false],
    Opc7: [false],
    Opc8: [false],
    Num1: [0],
    Num2: [0],
    Num3: [0]
  });

  menuItems: PanelBaseDropDownMenu[] = [{ Id: 'columnas', Nombre: 'Configurar Rangos De Vencimiento de Cuentas por Pagar' }]


  constructor(private fb: FormBuilder, private utils: UtilsService, private containerService: ContainerBaseService, private router: Router, private cxcService: CuentasPorCobrarService) { }

  ngOnInit(): void {
    this.setNombreReporte();
    this.focus('txtNombreReporte');
    let fecha1: Date = new Date();
  }

  setNombreReporte() {
    this.myForm.get('NombreReporte')?.setValue('ANÁLISIS DE ANTIGÜEDAD DE SALDOS DE CUENTAS POR PAGAR AL ' + this.utils.Date2Str(this.fechaDelReporte, 5).toUpperCase());
  }

  onChangeValue(value: any) {
    if (value) {
      this.fechaDelReporte = value;
      this.setNombreReporte();
    }
  }

  clicMenu(value: string) {
    if (value == "columnas") {
      this.mostrarRangos();
    }
  }


  mostrarRangos() {
    this.modalService.openModal(this.ctrlRangos, (e: any) => {
      this.onCloseDetalle(e)
    }, "sm");
  }

onCloseDetalle(item:ConfiguracionReporteSaldos) {
   }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onclickMayor(value: any, opcion: string) {

    if (this.myForm.get('Fecha2')?.value == null) this.myForm.get('Fecha2')?.setValue(new Date());

    this.myForm.get('Opc1')?.setValue(opcion == 'opc1');
    this.myForm.get('Opc2')?.setValue(opcion == 'opc2');
    this.myForm.get('Opc3')?.setValue(opcion == 'opc3');
  }



  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/CuentasPorPagar/ReporteAntiguedadDeSaldos');
  }


}
