<div class="card text-center border-0" cdkDrag style="width: 900px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Copiar Documentos de Ventas</div>
  <div class="card-body bg-light p-1">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <form [formGroup]="myForm" class="bg-white" autocomplete="off">
          <app-panel-base title="Filtros de búsqueda">
            <div class="panel-content">
              <div class="card border-0 mb-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-2">
                      <component-ui-combo-box style="margin-right: 5px;" formControlName="Sucursal"
                        [inputProperty]="'Nombre'" [setItemsList]="getItemsSucs" [topLabel]="true" label="Sucursal"
                        [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12"
                        (onSelectedItem)="onSelectedItem($event,'Sucursal')" [widthRightColumns]="0" #cboSucursal />
                    </div>
                    <div class="col-lg-2">
                      <component-ui-combo-box style="margin-right: 5px;" [filterQuery]="getFilter"
                        entityName="DocumentoVenta" formControlName="DocumentoVenta" [topLabel]="true" label="Documento"
                        [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12"
                        (onSelectedItem)="onSelectedItem($event,'DocumentoVenta')" [widthRightColumns]="0"
                        #cboDocumento />
                    </div>
                    <div class="col-lg-2">
                      <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Serie"
                        [topLabel]="true" label='Serie' [inputProperty]="'Serie'" [setItemsList]="getItemsSerie"
                        [tabIndex]="3" [widthLabelColumns]="12" (onSelectedItem)="onSelectedItem($event,'Serie')"
                        [widthTextColumns]="12" [widthRightColumns]="0" #cboSerie />
                    </div>
                    <div class="col-lg-2">
                      <components-ui-number-box style="width:15%;" [topLabel]="true" formControlName="Folio"
                        label='Folio' (keydown.enter)="enterFolio($event)" [tabIndex]="4" [widthLabelColumns]="12"
                        [widthTextColumns]="12" #txtFolio />
                    </div>
                    <div class="col-lg-4">
                      <a (click)="searchDocument()" class="btn btn-blue me-5px mt-2"><i
                          class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-magnifying-glass')}}"></i> Buscar</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-panel-base>
          <app-panel-base title="Documentos">
            <div class="panel-content">
              <div class="table-responsive mb-3"
                style="max-height: 250px; height: 250px; overflow-y:auto; border: 1px solid;">
                <table class="table table-sm table-hover">
                  <thead style="background-color: #348fe273">
                    <tr>
                      <th>
                      </th>
                      <th>Serie/Folio</th>
                      <th>Fecha</th>
                      <th>Cliente</th>
                      <th style="text-align:right;">SubTotal</th>
                      <th style="text-align:right;">IVA</th>
                      <th style="text-align:right;">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of documentos; let i = index">
                      <td>
                        <i (click)="deleteRow(i)" style="color:red;cursor:pointer;"
                          class="fas fa-rectangle-xmark fa-lg"></i>
                      </td>
                      <td>{{item.SerieString}}-{{item.Folio}}</td>
                      <td>{{getDate(item.FechaEmision)}}</td>
                      <td>{{item.Cliente ? item.Cliente.Nombre:''}}</td>
                      <td style="text-align:right;">{{item.SubTotal | number:'1.2-6'}}</td>
                      <td style="text-align:right;">{{item.TotalIvaTrasladado | number:'1.2-6'}}</td>
                      <td style="text-align:right;">{{item.Total | number:'1.2-6'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="text-align: right; margin-top: 5px;margin-bottom: 5px;">
                <h4>Subtotal: {{getSubtotal | number:'1.2-6'}}</h4>
                <h4>IVA: {{getIva | number:'1.2-6'}}</h4>
                <h4>Total: {{getTotal | number:'1.2-6'}}</h4>
              </div>
            </div>
          </app-panel-base>
          <div style="text-align: right; margin-top: 5px;margin-bottom: 5px;">
            <div (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</div>
            <div (click)="closeModal()" class="btn btn-danger">Cancelar</div>
          </div>
        </form>
      </div>
    </app-container-base>
  </div>
</div>
