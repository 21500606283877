<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Configuración a tomar en los Traspasos">
  <div class="screen-content" style="width: 50%;margin: auto;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="row p-1">
        <div id="bsSpyContent" class="col-xl-8">

          <div id="notifications" class="mb-4 pb-3">
            <h4><span class="iconify" data-icon="solar:lightbulb-bolt-bold-duotone"></span>Cancelación </h4>
            <p>Configure las opciones para la cancelación de Traspasos.</p>
            <div class="card">
              <div class="list-group list-group-flush fw-bold">
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Concepto de cancelación para Entrada</div>
                    <div class="text-body text-opacity-60">Seleccione el concepto con el que los componentes saldrán de nuevo al inventario.</div>
                    <component-ui-combo-box [label]="''" [filterQuery]="'EsSalida = true'" formControlName="ConceptoCancelacionEntrada" inputProperty="Nombre"
                      [tabIndex]="1" [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="10"
                      [widthLabelColumns]="0" [widthRightColumns]="0"
                      (onSelectedItem)="selectedCombo($event,'ConceptoCancelacionEntrada')" [listProperty]="'ClaveNombre'" />
                  </div>
                </div>
              </div>
              <div class="list-group list-group-flush fw-bold">
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Concepto de cancelación para Salida</div>
                    <div class="text-body text-opacity-60">Seleccione el concepto con el que los componentes ingresarán
                      de nuevo al inventario.</div>
                    <component-ui-combo-box [label]="''" [filterQuery]="'EsEntrada = true'" formControlName="ConceptoCancelacionSalida" inputProperty="Nombre"
                      [tabIndex]="1" [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="10"
                      [widthLabelColumns]="0" [widthRightColumns]="0"
                      (onSelectedItem)="selectedCombo($event,'ConceptoCancelacionSalida')" [listProperty]="'ClaveNombre'" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</app-container-base>

