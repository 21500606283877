<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  [getLast]="false" subTitle="Captura de Inventario Fisico" (onClickBarButton)="onClickBarButton($event)"
  [activeButtons]="activeButtons">
  <div class="screen-content" style="height: 100%;width:1000px;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base *ngIf="sourceAlmacen.length>0 && cords" title="Búsqueda de Almacenes"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:450px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Almacén</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTabla($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceAlmacen; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base *ngIf="sourceConcepto.length>0 && cords" title="Búsqueda de Almacenes"
        style="position: absolute; z-index:9;margin-left:200px;margin-top:80px;width:450px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Conceptos</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaConcepto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceConcepto; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:250px;margin-top:80px;width:850px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;" class="pt-0 pb-0">Familia</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;text-align: center;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Familia!.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <div class="d-flex" style="margin-right:10px;">
        <app-panel-base title="Información de la Captura de Inventario Físico" [style]="'height:130px;width:700px'">
          <div class="panel-content d-flex flex-column" style="height: 40%;">
            <div class="mb-3">
              <div class="d-flex">
                <component-ui-combo-box style="width:30%; margin-right: 5px;" formControlName="TipoMovimiento"
                  [topLabel]="true" label="Tipo Movimiento" [inputProperty]="'Nombre'" [zeroMask]="3"
                  [entityName]="'TipoMovimientoAlmacen'" [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12"
                  (onSelectedItem)="selectTipoCaptura($event)" [widthRightColumns]="0" [required]="true"
                  #TipoMovimientoInv [enabled]="getBlockCombos" helpLine="Tipo de captura, nos sirve como agrupador." />
                <components-ui-number-box style="width:15%; margin-right: 10px;" [topLabel]="true"
                  formControlName="Codigo" label='Folio' [tabIndex]="3" [widthLabelColumns]="12" [widthTextColumns]="12"
                  (blur)="blurFolio($event)" #txtFolioMovInv
                  helpLine="Folio a emitir o Folio a buscar, indique el número de folio para buscar o bien, presione el botón Nuevo de la barra de botones." />
                <div style="width:25%"></div>
                <components-ui-date-box label="Emisión" [tabIndex]="4" formControlName="Fecha" [required]="true"
                  style="width:15%; margin-right: 10px;" [widthLabelColumns]="12" [widthTextColumns]="12"
                  (KeyDown.enter)="enterFecha($event)" (KeyDown.tab)="tabFecha($event)" [topLabel]="true"
                  #txtFechaEmision helpLine="Es la Fecha del movimiento de Inventario" />
              </div>
              <!-- {{this.sourceAlmacen|json}} -->
            </div>

            <p style="margin-bottom: 0px !important;" *ngIf="myForm.value.Automatico" class="text-center text-red">
              Movimiento automático, no puede ser
              modificado.</p>

          </div>

        </app-panel-base>

        <app-panel-base title="Opciones" [style]="'height:130px;width:232px'" *ngIf="this.myForm.value.Id > 0 ">
          <div class="panel-content">
            <div class="d-flex" style="width:100%;flex-direction: column;">
              <div *ngIf="!myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-1"><i
                  class="fa fa-circle-check t-plus-1 text-success"></i> Vigente</div>
              <div *ngIf="myForm.value.FechaCancelacion" class="btn btn-sm btn-white"><i
                  class="fa fa-circle-xmark t-plus-1 text-danger"></i> Cancelado</div>
              <div (click)="openCancel()" *ngIf="!myForm.value.FechaCancelacion" class="btn btn-sm btn-white">
                <i class="fa fa-calendar-xmark t-plus-1 text-danger"></i> Cancelar
              </div>
            </div>
          </div>
        </app-panel-base>

      </div>

      <app-panel-base title="Conceptos" [style]="'width:1180px; height:480px;'">
        <div class="panel-content">
          <ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 65px;text-align:right; padding-right:4px;">Almacén</th>
                  <th style="width: 65px;text-align:right;padding-right:4px;">Concepto</th>
                  <th style="width: 30px;text-align:center;padding-right:4px;">T</th>
                  <th style="width: 65px;">Producto</th>
                  <th style="width: 200px;">Descripción</th>
                  <th style="width: 60px;min-width: 60px;max-width: 60px;text-align:right;padding-right:2px;">Cantidad
                  </th>
                  <th style="width: 100px;">Unidad</th>
                  <th style="width: 150px;">Conversión</th>
                  <th style="width: 80px;text-align:right;padding-right:4px;">Costo</th>
                  <th style="width: 70px;text-align:left;">Serie</th>
                  <th style="width: 70px;text-align:right;padding-right:4px;">Folio</th>
                  <th style="width: 100px;">Referencia</th>
                  <th style="width: 80px;text-align:right;padding-right:4px;">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                  (keydown)="keyDownRow($event, index)" *ngFor="let item of source; let index = index;">
                  <td style="width: 60px;min-width:60px;max-width: 60px;">
                    <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Almacen')"
                      (keydown.escape)="escapeAlmacen($event)" (keydown.arrowup)="up($event,0)"
                      (keydown)="navegarAlmacen($event)" (keydown.arrowdown)="down($event,0)"
                      (keydown.enter)="enterAlmacen($event)"
                      class="grid-editor text-dark" [id]="'txt_'+index+'_0' + idUnico"
                      (focus)="focusAlmacen(index, $event, item)" (blur)="blurAlmacen($event)" type="text"
                      entidadBusqueda="Almacen" (keydown.f2)="buscarCatalogo($event,'Almacen')"
                      [value]="item.Almacen ? item.Almacen.Clave: ''" />
                  </td>
                  <td style="width: 60px;min-width:60px;max-width: 60px;">
                    <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Concepto')"
                      (keydown)="navegarConcepto($event)" (keydown.escape)="escapeConcepto($event)"
                      (keydown.arrowup)="up($event,1)" (keydown.arrowdown)="down($event,1)"
                      (keydown.enter)="enterConcepto($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_1' + idUnico" (focus)="focusConcepto(index, $event, item)"
                      (blur)="blurConcepto($event)" type="text" [value]="item.Concepto ? item.Concepto.Clave: ''" />
                  </td>
                  <td>
                    <span style="margin-left: 3px;">{{item.Tipo}}</span>
                  </td>
                  <td style="width: 60px;min-width:60px;max-width: 60px;">
                    <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Producto')"
                      (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,3)"
                      (keydown)="navegarProducto($event)" (keydown.arrowdown)="down($event,3)"
                      (keydown.enter)="enterProducto($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_3' + idUnico" (focus)="focusProducto(index, $event, item)"
                      (blur)="blurProducto($event)" type="text" [value]="item.Producto ? item.Producto.Clave: ''" />
                  </td>
                  <td>
                    <span style="margin-left: 3px;">{{item.ProdDescripcion}}</span>
                  </td>
                  <td style="width: 60px;min-width:60px;max-width: 60px;"><input *ngIf="item.Producto"
                      [disabled]="loading" (keydown)="keyDownCantidad($event)" (keydown.escape)="escapeCantidad($event)"
                      (keydown.arrowup)="up($event,5)" (keydown.arrowdown)="down($event,5)"
                      (keydown.enter)="enterCantidad($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_5' + idUnico" (blur)="blurCantidad($event)"
                      (focus)="setIndexEdit(index, $event, item)" type="number"
                      [style]="'width:60px;text-align:right;padding-right:5px;'"
                      [value]="item.Producto ? item.Cantidad: 0" />
                  </td>
                  <td class="text-dark">
                    <span style="margin-left: 3px;"> {{item.UnidadDescripcion}}</span>
                  </td>
                  <td>
                    <a *ngIf="item.TextoConversiones" class="alert-link text-dark" (click)="conversionClick(index)"
                      style="cursor:pointer;margin-left: 3px;">{{item.TextoConversiones}} </a>
                  </td>
                  <td>
                    <input (keydown.arrowup)="up($event,8)" (keydown.arrowdown)="down($event,8)" [disabled]="loading"
                      (keydown.escape)="escapeCosto($event)" *ngIf="item.Producto" (keydown.enter)="enterCosto($event)"
                      class="grid-editor text-dark" [id]="'txt_'+index+'_8' + idUnico" (blur)="blurCosto($event)"
                      (focus)="setIndexEdit(index, $event, item)" type="number"
                      [style]="'width:80px;text-align:right;padding-right:5px;'"
                      [value]="item.Producto ? item.Costo: 0" />
                  </td>
                  <td>
                    <input style="width: 70px;min-width:70px;max-width: 70px;" (keydown.arrowup)="up($event,9)"
                      (keydown.arrowdown)="down($event,9)" [disabled]="loading" (keydown.escape)="escapeSerie($event)"
                      *ngIf="item.Producto" (keydown.enter)="enterSerie($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_9' + idUnico" (blur)="blurSerie($event)"
                      (focus)="setIndexEdit(index, $event, item)" type="text" [style]="'width:70px;text-align:right;'"
                      [value]="item.Producto ? item.SerieOrigina: ''" />
                  </td>
                  <td>
                    <input style="width: 70px;min-width:70px;max-width: 70px;" (keydown.arrowup)="up($event,10)"
                      (keydown.arrowdown)="down($event,10)" [disabled]="loading" (keydown.escape)="escapeFolio($event)"
                      *ngIf="item.Producto" (keydown.enter)="enterFolio($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_10' + idUnico" (blur)="blurFolioOrigina($event)"
                      (focus)="setIndexEdit(index, $event, item)" type="number"
                      [style]="'width:70px;text-align:right;height: 22px;'"
                      [value]="item.Producto ? item.FolioOrigina: 0" />
                  </td>
                  <td>
                    <input style="width: 100px;min-width:100px;max-width: 700px;" (keydown.arrowup)="up($event,11)"
                      (keydown.arrowdown)="down($event,11)" [disabled]="loading"
                      (keydown.escape)="escapeReferencia($event)" *ngIf="item.Producto"
                      (keydown.enter)="enterReferencia($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_11' + idUnico" (blur)="blurReferencia($event)"
                      (focus)="setIndexEdit(index, $event, item)" type="text" [style]="'width:70px;text-align:left;'"
                      [value]="item.Producto ? item.Referencia: ''" />
                  </td>
                  <td class="text-lg-end pe-1">
                    {{item.TotalCosto > 0 ? (item.TotalCosto | number:'1.2-6'):''}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
          <div class="row form-group">
            <div style="display: flex;padding-top: 5px;">
              <div style="width:80%">
                <div class="mt-2"></div>
                <label class="text-left height-label">Observaciones:</label>
                <div style="display: flex; justify-content: flex-start;width:90%;">
                  <textarea maxlength="1000" formControlName="Observaciones" class="w-100" rows="2" cols="50"></textarea>
                </div>
              </div>
              <!-- <div style="width:90%;justify-content: flex-end;">
                <form [formGroup]="myFormTotales">
                  <components-ui-text-box label='Registros' formControlName="TotalRegistros" [tabIndex]="3"
                    [widthTextColumns]="2" [enabled]="false" [alinearDerecha]="true" [widthLabelColumns]="10"
                    [maxLength]="70" />
                </form>
              </div> -->
              <table class="table table-borderless table-sm fw-bold m-0 mt-2" style="width:39%;">
                <tbody>
                  <tr>
                    <td class="pb-2" colspan="2"><b>Registros</b></td>
                    <td class="text-end pb-2 bg-success-subtle ">
                      <b>{{this.totalRegistros}}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2" colspan="2"><b>Total</b></td>
                    <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                      <b>${{this.totalImporte|number:'1.2-6'}}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <hr class="m-0">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row form-group">

          </div>
          <div class="d-flex">
            <div style="width:20%;">
              <div class="widget-list mb-0" style="display: flex; justify-content: flex-start;"
                *ngIf="myForm.value.UsuarioCaptura">
                <div class="widget-list-item">
                  <div class="widget-list-media">
                    <i class="fas fa-user-check fa-lg text-green"></i>
                  </div>
                  <div class="widget-list-content">
                    <h4 class="widget-list-title">{{myForm.value.UsuarioCaptura.Nombre}}</h4>
                    <p class="widget-list-desc">Usuario elaboró</p>
                  </div>
                </div>
                <div class="widget-list-item" *ngIf="myForm.value.UsuarioCancela">
                  <div class="widget-list-media">
                    <i class="fas fa-user-xmark fa-lg text-red"></i>
                  </div>
                  <div class="widget-list-content">
                    <h3 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h3>
                    <p class="widget-list-desc">Usuario cancela</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </app-panel-base>

      <div class="cancelled-header-inventario animate__animated animate__bounceInDown"
        *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate + " = " + myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
    </form>
  </div>

  <ng-template #modalCancelarInventario let-modal let-c="close">
    <app-opcion-cancelacion-traspaso-page />
  </ng-template>

  <ng-template #ctrlConversionesInventario let-modal let-c="close">
    <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
  </ng-template>


</app-container-base>
