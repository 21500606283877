<div (keydown)="keydown($event)" class="card text-center border-0" cdkDrag [style]="getStyle">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar/editar ubicación
  </div>
  <div class="card-body bg-light" (keydown)="keydown($event)">

    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">
        <div class="card-header h6 mb-1 bg-none p-2 text-orange">
          <i class="fa fa-truck-ramp-box fa-lg fa-fw me-1"></i>
          Datos de la salida o llegada
        </div>
        <app-container-base subTitle="" [containerInnerHeight]="490" [esPopup]="true" [hasHeader]="false"
          [hasBar]="false" [hasCounter]="false">
          <div class="screen-content">
            <form [formGroup]="myForm" autocomplete="off">
              <component-ui-check-box (onClick)="onClick($event, 1)" [tabIndex]="tabIndex" [widthLabelColumns]="4"
                formControlName="EsOrigen" [widthTextColumns]="1" [label]="'Origen'" #chkOrigen />
              <component-ui-check-box [enabled]="!esOrigen" (onClick)="onClick($event, 2)" [tabIndex]="tabIndex+1"
                [widthLabelColumns]="4" formControlName="EsDestino" [widthTextColumns]="1" [label]="'Destino'" />

              <components-ui-date-box formControlName="FechaHoraSalidaLlegada" label="Fecha" [tabIndex]="tabIndex+2"
                [widthLabelColumns]="4" />
              <components-ui-time-box label="Hora" formControlName="Hora" [tabIndex]="tabIndex+3" [widthTextColumns]="3"
                [widthLabelColumns]="4" />
              <components-ui-number-box [tabIndex]="tabIndex+4" [maxLength]="10" [label]="'Distancia (kmts)'"
                formControlName="DistanciaRecorrida" [widthTextColumns]="2" [widthLabelColumns]="4" />

              <components-ui-text-box [maxLength]="500" [upperCase]="true" [label]="'Nombre Rem/Dest'"
                formControlName="NombreRemitenteDestinatario" [tabIndex]="tabIndex+5" [widthTextColumns]="6"
                [widthLabelColumns]="4" />

              <components-ui-text-box [tabIndex]="tabIndex+6" [maxLength]="13" [upperCase]="true" [required]="true"
                [label]="'RFC Rem/Dest'" [dataType]="EDT.RFC" formControlName="RFCRemitenteDestinatario"
                [widthTextColumns]="6" [widthLabelColumns]="4" />

            </form>
            <div class="card-header h6 mb-1 bg-none p-2 text-orange">
              <i class="fa fa-map-location fa-lg fa-fw me-1"></i>
              Domicilio de salida o llegada
            </div>
            <form [formGroup]="formDomicilio" autocomplete="off">
              <components-ui-text-box [enabled]="false" [tabIndex]="tabIndex+7" [maxLength]="15" [label]="'País'"
                formControlName="Pais" [widthTextColumns]="2" [widthLabelColumns]="4" />

              <components-ui-number-box [enabled]="!saving" (blur)="blurCP($event)" [tabIndex]="tabIndex+8"
                [maxLength]="10" [label]="'Código postal'" formControlName="CodigoPostal" [widthTextColumns]="2"
                [widthLabelColumns]="4" />

              <components-ui-text-box [enableIcon]="true" (onClicIcon)="abrirBusquedaEstados()" [enabled]="!saving"
                [tabIndex]="tabIndex+9" [maxLength]="100" [label]="'Estado'" formControlName="Estado"
                (keydown.f2)="abrirBusquedaEstados()" [widthTextColumns]="3" [widthLabelColumns]="4" #txtEstado />

              <components-ui-text-box [enableIcon]="true" (onClicIcon)="abrirBusquedaMunicipios()" [enabled]="!saving"
                [tabIndex]="tabIndex+10" [maxLength]="100" [label]="'Municipio'" formControlName="Municipio"
                (keydown.f2)="abrirBusquedaMunicipios()" [widthTextColumns]="8" [widthLabelColumns]="4" />

              <components-ui-text-box [enableIcon]="true" (onClicIcon)="abrirBusquedaLocalidades()" [enabled]="!saving"
                [tabIndex]="tabIndex+11" [maxLength]="15" [label]="'Localidad'" formControlName="Localidad"
                (keydown.f2)="abrirBusquedaLocalidades()" [widthTextColumns]="8" [widthLabelColumns]="4" />

              <components-ui-text-box [enableIcon]="true" (onClicIcon)="abrirBusquedaColonias()" [enabled]="!saving"
                (keydown.f2)="abrirBusquedaColonias()" [tabIndex]="tabIndex+12" [maxLength]="200" [label]="'Colonia'"
                formControlName="Colonia" [widthTextColumns]="8" [widthLabelColumns]="4" />

              <components-ui-text-box [enabled]="!saving" [tabIndex]="tabIndex+13" [maxLength]="200" [label]="'Calle'"
                formControlName="Calle" [widthTextColumns]="8" [widthLabelColumns]="4" />

              <components-ui-text-box [enabled]="!saving" [tabIndex]="tabIndex+14" [maxLength]="15"
                [label]="'Número exterior'" formControlName="NumeroExterior" [widthTextColumns]="8"
                [widthLabelColumns]="4" />

              <components-ui-text-box [enabled]="!saving" [tabIndex]="tabIndex+15" [maxLength]="15"
                [label]="'Número interior'" formControlName="NumeroInterior" [widthTextColumns]="8"
                [widthLabelColumns]="4" />

              <components-ui-text-box [enabled]="!saving" [tabIndex]="tabIndex+16" [maxLength]="100"
                [label]="'Referencia'" formControlName="Referencia" (keydown.enter)="accept()" [widthTextColumns]="8"
                [widthLabelColumns]="4" />
            </form>
          </div>
        </app-container-base>
      </div>
    </div>
  </div>
  <ng-template #busquedaLocalidades let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Localidades" [columns]="getColumns" [entityName]="'c_Localidad'"
      [filter]="getFilterLocalidades" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busquedaLocalidades />
  </ng-template>
  <ng-template #busquedaEstados let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Estados" [columns]="getColumns" [entityName]="'c_Estado'"
      [filter]="getFilterEstado" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busquedaEstados />
  </ng-template>
  <ng-template #busquedaMunicipios let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Municipios" [columns]="getColumns" [entityName]="'c_Municipio'"
      [filter]="getFilterMunicipio" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busquedaMunicipios />
  </ng-template>
  <ng-template #busquedaColonias let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Colonias" [columns]="getColumns" [entityName]="'c_Colonia'"
      [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busquedaColonias />
  </ng-template>

  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div *ngIf="!saving" style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>[F8]
        Aceptar
      </div>
      <div *ngIf="!saving" (click)="closeModal()" class="btn btn-danger" #txtCancelar>[F9] Cancelar</div>
    </div>
  </div>
</div>
