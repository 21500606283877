<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Chofer"
  title="Choferes" icon="fa fa-user-check" subTitle="Choferes para Carga de documentos, Traslados...">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Chofer'"
            entityName="Chofer" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="100" />


          <component-ui-combo-box [label]="'Sucursal'" [required]="true" formControlName="SucursalPertenece"
            [tabIndex]="3" [zeroMask]="3" [filterQuery]="getFilter" entityName="Sucursal" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedItem($event, 'SucursalPertenece')"
            [listProperty]="'ClaveNombre'" />

        </div>
      </app-panel-base>

      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#chofer-default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Generales
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#chofer-default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Licencia</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#chofer-default-tab-3" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Carta Porte</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#chofer-default-tab-4" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
      </ul>

      <div class="tab-content panel rounded-0 p-1 me-2 ms-2 mt-0">
        <div id="chofer-default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Generales">
            <div class="panel-content">
              <component-ui-combo-box [label]="'Tipo'" [required]="true" formControlName="Tipo" [tabIndex]="4"
                [zeroMask]="2" entityName="TipoChofer" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedItem($event, 'Tipo')"
                [listProperty]="'ClaveNombre'" />

              <components-ui-text-box [label]="'Anotaciones'" formControlName="Anotaciones" [tabIndex]="5"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />

              <components-ui-text-box [label]="'Certificaciones'" formControlName="Certificaciones" [tabIndex]="6"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />

              <components-ui-text-box [label]="'Habilidades'" formControlName="Habilidades" [tabIndex]="7"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />

              <component-ui-file-upload-box (onChange)="onChangeFile($event,1)" [text]="myForm.value.ExpedienteNombre"
                [label]="'Expediente'" [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2" />
            </div>
          </app-panel-base>
        </div>
        <div id="chofer-default-tab-2" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Datos de licencia">
            <div class="panel-content">
              <components-ui-text-box [label]="'Licencia'" formControlName="Licencia" [tabIndex]="9"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="100" />

              <components-ui-date-box [label]="'Vence licencia'" formControlName="FechaVencimientoLicencia"
                [tabIndex]="10" [widthTextColumns]="3" [widthLabelColumns]="2" />

            </div>
          </app-panel-base>
        </div>
        <div id="chofer-default-tab-3" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Carta Porte">
            <div class="panel-content">
              <components-ui-text-box [tabIndex]="11" [maxLength]="13" [upperCase]="true" [label]="'RFC'"
                [dataType]="EDT.RFC" formControlName="Rfc" [widthTextColumns]="2" [widthLabelColumns]="2" />

              <component-ui-combo-box [label]="'Figura'" formControlName="Figura" [tabIndex]="12" [zeroMask]="0"
                entityName="c_FiguraTransporte" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedItem($event, 'Figura')" [listProperty]="'ClaveNombre'" />
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-circle-info fa-lg fa-fw"></i>
                Solo indique si el chofer entra o sale del país
              </div>
              <components-ui-text-box [label]="'NumRegIdTrib'" formControlName="NumRegIdTribFigura" [tabIndex]="13"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

              <components-ui-text-box [label]="'País residencia'" formControlName="PaisResidencia" [tabIndex]="14"
                [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

            </div>
          </app-panel-base>
        </div>

        <div id="chofer-default-tab-4" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">

              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />

            </div>
          </app-panel-base>
        </div>
      </div>





    </form>
  </div>
</app-container-base>
