<app-container-base subTitle="Usuarios Conectados" [hasBar]="false" icon="fas fa-users" [hasCounter]="false">
  <div class="screen-content">
    <app-panel-base title="Listado de usuarios conectados a COMPLIT">
      <div class="panel-content">
        <div (click)="sendMessage()" class="btn btn-success m-1">
          <i class="fas fa-message"></i>
          Enviar Mensaje Para Todos
        </div>
        <div class="btn btn-danger m-1" (click)="cerrarTodasSesiones()">
          <i class="fas fa-users-slash"></i>
          Cerrar Todas Las Sesiones
        </div>
        <div class="btn btn-info m-1" (click)="getSesionesConectados()">
          <i class="fas fa-rotate"></i>
          Actualizar Sesiones
        </div>

        <ng-scrollbar class="scroll">
          <table class="table table-bordered table-sm">
            <thead>
              <tr class="header">
                <th class="text-center w-20px">Clave</th>
                <th class="w-300px">Nombre</th>
                <th class="w-200px">Nombre de usuario</th>
                <th class="w-400px">Empresa</th>
                <th class="w-400px">Sucursal</th>
                <th class="text-center w-200px">Hora de inicio de sesión</th>
                <th class="text-center w-150px">Eliminar sesión</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let u of usuariosContectados"
                class="{{usuarioLoggeado.nombreUsuario === u.usuario.login ? 'table-info': ''}}">
                <td class="text-center">{{u.usuario.clave}}</td>
                <td>{{u.usuario.nombre}}</td>
                <td>{{u.usuario.login}}</td>
                <td>{{u.empresa.clave +' '+u.empresa.nombre}}</td>
                <td>{{u.sucursal.clave +' '+u.sucursal.nombre}}</td>
                <td class="text-center">{{u.horaInicioSesion | date: 'dd/MM/yyyy hh:mm:ss a'}}</td>
                <td class="text-center padding-for-icon">
                  <button [disabled]="usuarioLoggeado.nombreUsuario === u.usuario.login" type="button"
                    (click)="cerrarSesion(u)" class="btn btn-danger btn-icon btn-sm">
                    <i class="fas fa-trash-can trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-scrollbar>
      </div>
    </app-panel-base>
  </div>
</app-container-base>
