import { Component, EventEmitter, Input, Output, forwardRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColorPickerComponent),
    }],
})
export class ColorPickerComponent {

  private frmBuilder = inject(FormBuilder);

  @Input()
  public disabled: boolean = false;
  @Input()
  public color: string = '#FF8300'

  @Output()
  public onChangeColor: EventEmitter<any> = new EventEmitter();
  @Output()
  public onBlurText: EventEmitter<any> = new EventEmitter();


  public frmColor: FormGroup = this.frmBuilder.group({
    textValue: [''],

  })

  ngOnInit() {
    if (this.disabled) {
      this.frmColor.controls['textValue'].disable();
    } else {
      this.frmColor.controls['textValue'].enable();
    }
  }

  onChange = (_: any) => { }
  onTouch = () => { }

  writeValue(value: any) {
    if (value) {
      this.frmColor.controls["textValue"].setValue(value.text);
      this.color = value.color;
    } else {
      this.frmColor.reset();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleChange($event: ColorEvent) {
    this.color = $event.color.hex;
    let text = this.frmColor.value.textValue
    this.onChangeColor.emit({ color: $event.color.hex, text });
  }

  blurControl(value: string) {
    let objt = { color: this.color, text: value };
    this.writeValue(objt);
    this.onBlurText.emit(objt);
  }

}
