import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthResponse, RefreshToken } from '../interfaces';
import Swal from 'sweetalert2'
import { ready } from 'jquery';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EventsService } from 'src/app/service/events.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);
  private eventsService = inject(EventsService);
  constructor() { }
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  refreshingToken: boolean = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ambiente = "";
    let req: any = request;
    if (this.authService.getToken()) {
      ambiente = this.authService.getAmbienteId();
      req = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.authService.getToken()}`,
          ambienteId: ambiente,
          hostFrontEnd: window.location.host
        }
      })
    }

    req.body = this.convert(_.cloneDeep(req.body));
    //console.log(req.body);
    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            console.log(this.authService.getToken());
            console.log(this.authService.getRefreshToken());
            console.log(ambiente);

            if (!this.refreshingToken)
              if (!request.url.toLocaleLowerCase().includes("api/autorizacion/autenticar") && !request.url.toLocaleLowerCase().includes("api/baseDatos")) {
                this.refreshingToken = true;
                return this.handleUnAuthorizedError(req, next);
              }
          }
          return throwError(() => err);
        }));
  }

  isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._isoDateFormat.test(value);
    } return false;
  }

  convert(body: any) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        const minsDif = value.getTimezoneOffset();
        value.setMinutes(value.getMinutes() - minsDif)
        body[key] = value;
      } else if (typeof value === 'object') {
        this.convert(value);
      }
    }
    return body;
  }

  handleUnAuthorizedError(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getToken();
    const refreshToken = this.authService.getRefreshToken();

    let tokenApiModel: RefreshToken = { accessToken: token, refreshToken };
    return this.authService.refreshToken(tokenApiModel)
      .pipe(
        switchMap((data: AuthResponse) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.refreshingToken = false;
          this.authService.setRefreshToken(data.refreshToken);
          this.authService.setToken(data.accessToken);
          req = req.clone({
            setHeaders: { Authorization: `Bearer ${data.accessToken}` }
          })
          return next.handle(req);
        }),
        catchError((err) => {
          return throwError(() => {
            this.refreshingToken = false;
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (this.authService.getToken()) {
              Swal.fire({
                title: 'Sesión Expirada',
                text: "Iniciar sesión de nuevo",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.authService.logout().subscribe();
                }
              });
            }
          });
        })
      )
  }
}
