<ng-container>
  <div class="widget-chat widget">
    <div *ngIf="hasHeader" class="widget-chat-header">
      <div class="widget-list">
        <a class="widget-list-item">
          <div ngbTooltip="Ver ayuda en video" container="body" class="widget-list-media icon">
            <i class="fa fa-play bg-red text-white"></i>
          </div>
          <div class="widget-list-media icon">
            <i class="fa {{icon}} bg-orange text-white"></i>
          </div>
          <div class="widget-list-content">
            <h4 class="widget-list-title">{{subTitle | titlecase}}</h4>
            <div class="no-registros">
              <!-- <input (change)="setZoom($event)" type="range" min="0" max="100" [value]="getZoomValue"> -->
              <h4 *ngIf="hasCounter" class="widget-list-title" style="font-size: 18px;">Registros: {{count | number}}
              </h4>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div (keydown)="keydown($event)" class="widget-chat-body alto-pantalla" data-scrollbar="true" id="{{idComponent}}"
      [style]="getStyle">
      <div *ngIf="isNewItem" class="widget-list rounded ">
        <a class="widget-list-item">
          <div class="widget-list-media icon">
            <i class="fa fa-plus bg-yellow text-dark"></i>
          </div>
          <div class="widget-list-content">
            <span class="menu-label">ALTA DE NUEVO REGISTRO, capture la información solicitada, <span
                class="text-red">los campos con un </span> <i class="fa fa-asterisk text-red fs-10px me-2"></i> <span
                class="text-red">en color rojo son obligatorios</span></span>
          </div>
        </a>
      </div>
      <div #container class="animate__animated animate__fadeIn animate__faster">
        <ng-content *ngIf="!isLoading; else loader" select=".screen-content">
        </ng-content>
      </div>
      <ng-template #loader>
        <screen-loading />
      </ng-template>
    </div>
    <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda" [columns]="getColumns" [entityName]="getEntityName" [filter]="getFilter"
        [propertys]="getPropertys" [orders]="getOrders" #busqueda />
    </ng-template>
    <div class="widget-input buttons-bar" [style]="getZoom">
      <span *ngIf="!isMobile && !esPopup" class="lineadeayuda badge rounded-0">
      </span>
      <bar-base *ngIf="hasBar && !isLoading" [activeButtons]="activeButtons" [isNewItem]="isNewItem" [saving]="saving"
        (onClickButton)="onClickButton($event)" />
    </div>

  </div>
</ng-container>
