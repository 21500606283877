
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from '../interfaces/usuario.interface';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Rol } from '../interfaces/rol.interface';
import { Puesto } from '../interfaces/puesto.interface';
import { environment } from 'src/environments/environment';
import { Result } from 'src/app/auth/interfaces';
import { map } from 'jquery';



@Injectable({ providedIn: 'root' })
export class ConfiguracionService {
  private readonly baseUrl: string = environment.baseUrlApi;
  public RegExRfc: string = "([a-zA-ZñÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([a-zA-Z\\d]{2})([aA\\d])";
  public RegExCurp: string = "([a-zA-Z][aeiouxAEIOUX][a-zA-Z]{2}\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])[HMhm](?:AS|B|as|b[CScs]|C|c[CLMSHclmsh]|D|d[FGfg]|G|g[TRtr]|HG|JC|M|hg|jc|m[CNScns]|N|n[ETLetl]|OC|PL|Q|oc|pl|q[TRtr]|[sS][PLRplr]|T[CSLcsl]|vz|VZ|yn|YN|zs|ZS)[B-DF-HJ-NP-TV-Z-b-df-hj-np-tv-z]{3}[a-zA-Z\\d])(\\d)";
  public RegExEmail: string = "([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})";
  constructor(private http: HttpClient) { }


  ObtenerUsuarios(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${this.baseUrl}/config/obtenerusuarios`);
  }
  ObtenerUsuario(id: number): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.baseUrl}/config/obtenerusuario/${id}`);
  }

  GuardarUsuario(usuario: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.baseUrl}/config/GuardarUsuario`, JSON.stringify(usuario), { headers: { 'Content-Type': 'application/json' } });
  }

  EliminarUsuario(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/config/EliminarUsuario/${id}`);
  }

  ObtenerRoles(): Observable<Rol[]> {
    return this.http.get<Rol[]>(`${this.baseUrl}/config/obtenerroles`);
  }

  // puestos ///
  ObtenerPuestos(): Observable<Puesto[]> {
    return this.http.get<Puesto[]>(`${this.baseUrl}/config/obtenerpuestos`);
  }

  GuardarPuesto(puesto: Puesto): Observable<Puesto> {
    return this.http.post<Puesto>(`${this.baseUrl}/config/GuardarPuesto`, JSON.stringify(puesto), { headers: { 'Content-Type': 'application/json' } });
  }

  ObtenerPuesto(id: number): Observable<Puesto> {
    return this.http.get<Puesto>(`${this.baseUrl}/config/obtenerpuesto/${id}`);
  }

  EliminarPuesto(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/config/EliminarPuesto/${id}`);
  }

  validaUsuarioExistente(id: number, usuario: string): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/config/ValidarUsuarioExistente/${id}/${usuario}`);
  }

  solicitaCambiarPassword(idUsuario: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/config/SolicitaCambiarPassword/${idUsuario}/`)
  }

  cambiarPassword(password: string, numeroUsuario: number): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/config/CambiarPassword`, { password, numeroUsuario });
  }

  obtenerImagenPerfil(ruta: string): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/config/obtenerimagenperfil/${ruta}`);
  }

  busquedaSucursales(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/Config/BuscarSucursales`, { params });
  }

  busquedaVendedores(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/Config/BuscarVendedores`, { params });
  }


}




