
<div cdkDrag style="background-color: var(--bs-app-header-input-bg);padding: 15px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" class="modal-fam">
  <div cdkDragHandle style="cursor: move; text-align: center;" cdkDragHandle class="card-header fw-bold">Búsqueda  </div>
  <ng-scrollbar style="height:340px">
    <components-ui-tree-view (onEscape)="closeModal()" [onlyLevelOne]="onlyLevelOne" [multiSelect]="multiSelect" (onSelectedItemMultiple)="onSelectedItemMultiple($event)" (onSelectedItem)="onSelectedItem($event)" [type]="type"></components-ui-tree-view>
  </ng-scrollbar>
  <div class="mt-2" style="text-align: right;">
    <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
    <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
  </div>
</div>
