import { Component, ElementRef, ViewChild } from '@angular/core';
import { MotivoDiferencia } from '../../interfaces/producto.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';

@Component({
  selector: 'app-motivo-diferencia-page',
  templateUrl: './motivo-diferencia-page.component.html',
  styles: [
  ]
})
export class MotivoDiferenciaPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];

  private newEntityObj = {
    Empresa: [null],
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]]
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;


  // @ViewChild('cboPrueba')
  // public cboPrueba!: ElementRef<ComboBoxComponent>;

  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService) {


  }



  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: MotivoDiferencia) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: MotivoDiferencia): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("MotivoDiferencia").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }



}
