import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

export interface DetalleDepreciacion {
  periodo: number;
  mensual: number;
  acumulada: number;
}

@Component({
  selector: 'app-calculadora-depreciacion-page',
  templateUrl: './calculadora-depreciacion-page.component.html',
  styles: [
  ]
})

export class CalculadoraDepreciacionPageComponent implements OnInit {

  mostrarCalculo: boolean = false;
  depreciacionMensual: number = 0;
  lista: DetalleDepreciacion[] = [];

  @ViewChild('txtValorActivo')
  public txtValorActivo!: ElementRef<HTMLElement>;

  public myForm: FormGroup = this.fb.group({
    Valor: [0],
    Residual: [0],
    Anios: [0],
  })

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.focus();
  }

  focus() {
    setTimeout(() => {
      this.txtValorActivo.nativeElement.focus();
    }, 250);
  }

  newCalc() {
    this.lista = [];
    this.mostrarCalculo = false;
    this.depreciacionMensual = 0;
    this.myForm.reset({
      Valor: 0,
      Residual: 0,
      Anios: 0,
    });
    this.focus();
  }

  calc() {
    const ent = this.myForm.value;
    if (ent.Valor <= 0) {
      Swal.fire({ text: `Indique el valor del activo.`, icon: 'info', })
      return false;
    }

    if (ent.Residual <= 0) {
      Swal.fire({ text: 'Indique el valor residual del activo.', icon: 'info', })
      return false;
    }

    if (ent.Anios <= 0) {
      Swal.fire({ text: 'Indique la vida útil en años.', icon: 'info', })
      return false;
    }

    const periodos: number = ent.Anios * 12;
    const totalDepreciar: number = ent.Valor - ent.Residual;

    this.depreciacionMensual = totalDepreciar / periodos;

    for (let index = 1; index <= periodos; index++) {
      this.lista = [...this.lista, { periodo: index, mensual: this.depreciacionMensual, acumulada: this.depreciacionMensual * index }];
    }

    this.mostrarCalculo = true;
    return;
  }

}
