import { CuentaBancaria } from './../../../Finanzas/interfaces/c_banco.interface';
import { Component, inject, Input, OnInit } from '@angular/core';
import { VentaFormaPagoDatosTransferencia } from '../../interfaces/venta.interface';
import { EventsService } from 'src/app/service/events.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-datos-transferencia-page',
  templateUrl: './datos-transferencia-page.component.html',
  styles: [
  ]
})
export class DatosTransferenciaPageComponent implements OnInit {


  @Input()
  public item: VentaFormaPagoDatosTransferencia | null = null;
  @Input()
  public idSucursal: number = 0;



  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Banco: [null],
    CuentaBancaria: [null],
    FechaDeposito: [new Date()],
    ImporteAplicar: [0],
    ImporteOriginal: [0],
    ReferenciaBancaria: [''],
    Referencia: [''],
  })

  constructor(private ms: ModalService, private fb: FormBuilder, private containerService: ContainerBaseService, private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.myForm.reset({ ...this.item })
    }, 150);
  }

  onSelectedItem(entity: any, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  accept() {
    const ent = this.myForm.value;
    if (!ent.Banco) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar el Banco.`, cancelButton: false });
      return;
    }
    if (!ent.CuentaBancaria) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar la Cuenta Bancaria.`, cancelButton: false });
      return;
    }
    if (!ent.FechaDeposito) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Fecha de la Transferencia.`, cancelButton: false });
      return;
    }

    if (ent.ImporteAplicar <= 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el importe a aplicar.`, cancelButton: false });
      return;
    }

    if (ent.ImporteOriginal <= 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el importe a original de la transferencia, se utiliza más cuando de una sola transferencia se pagan varias facturas.`, cancelButton: false });
      return;
    }

    if (!ent.ReferenciaBancaria) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la referencia bancaria.`, cancelButton: false });
      return;
    }

    if (ent.ImporteAplicar > ent.ImporteOriginal) {
      this.eventsService.publish('home:showAlert', { message: `El importe aplicado no puede ser a la cantidad original de la Transferencia.`, cancelButton: false });
      return;
    }

    this.ms.closeModal(ent);
  }

  closeModal() {
    this.ms.closeModal(null);
  }

}
