import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class CentroCostoService {
  constructor(private http: HttpClient) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;

  getNextClave(idEmpresa: number, nivel: number, idPadre: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel).set("idPadre", idPadre);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerSiguienteClaveCentroCosto`, { params });
  }

  getLongLevel(idEmpresa: number, nivel: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerLongitudNivelCentroCosto`, { params });
  }

}
