<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="DocumentoVenta" title="Documentos de Ventas" icon="fa fa-newspaper"
  subTitle="Administra la configuración de Documentos de Ventas">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Documento de Venta'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="3" entityName="DocumentoVenta" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="3" [required]="true" [maxLength]="150" #txtNombre />

          <components-ui-text-box [tabIndex]="3" [label]="'Imprimir'" formControlName="TextoImprimir"
            [widthTextColumns]="4" [widthLabelColumns]="3" [required]="true" [maxLength]="100" />

          <component-ui-combo-box [label]="'Clasificación'" [required]="true" formControlName="Clasificacion"
            [tabIndex]="4" [zeroMask]="2" entityName="ClasificacionVenta" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Clasificacion')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Tipo de Comprobante'" [required]="true" formControlName="TipoDeComprobante"
            [tabIndex]="5" [zeroMask]="0" entityName="c_TipoDeComprobante" [widthTextColumns]="1"
            [widthLabelColumns]="3" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoDeComprobante')"
            [listProperty]="'ClaveNombre'" />

          <components-ui-number-box [maxLength]="3" [label]="'Vigencia en horas'" formControlName="HorasVigencia"
            [tabIndex]="6" [widthTextColumns]="1" [widthLabelColumns]="3" />

          <components-ui-text-box [tabIndex]="7" [label]="'Documentos que puede copiar'"
            formControlName="DocumentosPuedeCopiar" [widthTextColumns]="4" [widthLabelColumns]="3" [maxLength]="200" />


          <component-ui-check-box [label]="'Es Carta Porte de Traslado'" formControlName="EsParaCartaPorte"
            [tabIndex]="8" [widthTextColumns]="3" [widthLabelColumns]="3" />

          <component-ui-check-box [label]="'No reimprimir'" formControlName="NoPermitirReimpresion" [tabIndex]="9"
            [widthTextColumns]="3" [widthLabelColumns]="3" />

        </div>
      </app-panel-base>


      <app-panel-base title="Resguardos">
        <div class="panel-content">
          <component-ui-check-box [tabIndex]="10" [widthLabelColumns]="3" formControlName="LlevaBackOrder"
            helpLine="El sistema aplicará el proceso de resguardo de la mercancía al momento de elaborar el comprobante, las salidas de meercancía se harán a través de una remisión."
            [label]="'Aplicar proceso Resguardo'" />

          <component-ui-check-box [tabIndex]="11" [widthLabelColumns]="3" formControlName="NoPreguntarResguardo"
            helpLine="El sistema asumirá que siempre será resguardo." [label]="'¿No preguntar?'" />

          <components-ui-text-box [enabled]="myForm.value.LlevaBackOrder && !myForm.value.NoPreguntarResguardo"
            placeHolder="¿La mercancía se entrega en este momento?" [tabIndex]="12" [label]="'Pregunta'"
            formControlName="TextoPregunta" [widthTextColumns]="4" [widthLabelColumns]="3" [maxLength]="200" />

          <components-ui-text-box [enabled]="myForm.value.LlevaBackOrder && !myForm.value.NoPreguntarResguardo"
            placeHolder="Si, el cliente se lleva la mercancía" [tabIndex]="13"
            [label]="'Texto Afirmación 1 (no resguardo)'" formControlName="TextoAfirmacion1" [widthTextColumns]="4"
            [widthLabelColumns]="3" [maxLength]="200" />

          <components-ui-text-box [enabled]="myForm.value.LlevaBackOrder  && !myForm.value.NoPreguntarResguardo"
            placeHolder="No, nosotros se la llevamos" [tabIndex]="14" [label]="'Texto Afirmación 2 (si resguardo)'"
            formControlName="TextoAfirmacion2" [widthTextColumns]="4" [widthLabelColumns]="3" [maxLength]="200" />

          <component-ui-check-box [label]="'Copiar requerido'"
            [enabled]="myForm.value.LlevaBackOrder  && !myForm.value.NoPreguntarResguardo"
            formControlName="ObligarCopiar" [tabIndex]="15" [widthTextColumns]="3" [widthLabelColumns]="3" />

          <components-ui-text-box [tabIndex]="16" [label]="'Mensaje imprimir'"
            [enabled]="myForm.value.LlevaBackOrder  && !myForm.value.NoPreguntarResguardo"
            formControlName="MensajeDocumento" [widthTextColumns]="4" [widthLabelColumns]="3" [maxLength]="1000" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-scale-balanced fa-lg fa-fw me-1"></i>
            Comportamiento del Inventario en caso de ser RESGUARDO
          </div>

          <component-ui-check-box
            helpLine="Al hacer el documento y ser de resguardo, no se moverá nada del inventario, esto se hará hasta la remisión"
            [tabIndex]="17" [widthLabelColumns]="3" formControlName="NoMoverInventarioBackOrder"
            [enabled]="myForm.value.LlevaBackOrder" [label]="'No mover el Inventario'" />

          <component-ui-combo-box [enabled]="!myForm.value.NoMoverInventarioBackOrder && myForm.value.LlevaBackOrder"
            [required]="myForm.value.LlevaBackOrder && !myForm.value.NoMoverInventarioBackOrder"
            [label]="'Tipo de Movimiento'" formControlName="TipoMovimientoAlmacenResguardo" [tabIndex]="18"
            [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoMovimientoAlmacenResguardo')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [enabled]="!myForm.value.NoMoverInventarioBackOrder && myForm.value.LlevaBackOrder"
            [required]="myForm.value.LlevaBackOrder && !myForm.value.NoMoverInventarioBackOrder"
            [label]="'Almacén Entrada'" formControlName="AlmacenReguardo" [tabIndex]="19" [zeroMask]="2"
            entityName="Almacen" [widthTextColumns]="1" [widthLabelColumns]="3" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'AlmacenReguardo')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [enabled]="!myForm.value.NoMoverInventarioBackOrder && myForm.value.LlevaBackOrder"
            [required]="myForm.value.LlevaBackOrder && !myForm.value.NoMoverInventarioBackOrder"
            [label]="'Concepto Entrada Almacén'" formControlName="ConceptoAlmacenResguardo" [tabIndex]="20"
            [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ConceptoAlmacenResguardo')"
            [listProperty]="'ClaveNombre'" />

        </div>
      </app-panel-base>

      <app-panel-base title="Cuentas Por Cobrar">
        <div class="panel-content">

          <component-ui-combo-box [label]="'Concepto de CXC'" formControlName="ConceptoCxc" [tabIndex]="21"
            [zeroMask]="2" entityName="ConceptoCxc" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ConceptoCxc')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Tipo de Movimiento'" formControlName="TipoMovimientoCxc" [tabIndex]="22"
            [zeroMask]="2" entityName="TipoMovimientoCXC" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoMovimientoCxc')"
            [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>

      <app-panel-base title="Inventario">
        <div class="panel-content">
          <component-ui-combo-box [label]="'Tipo movimiento'" formControlName="TipoMovimientoAlmacen" [tabIndex]="23"
            [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoMovimientoAlmacen')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Almacén'" formControlName="Almacen" [tabIndex]="24" [zeroMask]="2"
            entityName="Almacen" [widthTextColumns]="1" [widthLabelColumns]="3" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Almacen')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Concepto almacén'" formControlName="ConceptoAlmacen" [tabIndex]="25"
            [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ConceptoAlmacen')"
            [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>
      <app-panel-base title="Control de la Carga y Entrega">
        <div class="panel-content">
          <component-ui-check-box [tabIndex]="26" [widthLabelColumns]="3" formControlName="SolicitarEntregaNuestra"
            helpLine="Activa la opción para que los Documentos de Ventas, puedan tener el control del módulo de Entregas"
            [label]="'Mostrar opción para entrega nuestra'" />


          <component-ui-combo-box [enabled]="myForm.value.SolicitarEntregaNuestra"
            [required]="myForm.value.SolicitarEntregaNuestra" [label]="'Tipo movimiento'"
            formControlName="TipoMovimientoAlmacenCargaEntrega" [tabIndex]="27" [zeroMask]="2"
            entityName="TipoMovimientoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'TipoMovimientoAlmacenCargaEntrega')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [enabled]="myForm.value.SolicitarEntregaNuestra"
            [required]="myForm.value.SolicitarEntregaNuestra" [label]="'Almacén'" formControlName="AlmacenCargaEntrega"
            [tabIndex]="28" [zeroMask]="2" entityName="Almacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'AlmacenCargaEntrega')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [enabled]="myForm.value.SolicitarEntregaNuestra"
            [required]="myForm.value.SolicitarEntregaNuestra" [label]="'Concepto almacén'"
            formControlName="ConceptoAlmacenCargaEntrega" [tabIndex]="29" [zeroMask]="2" entityName="ConceptoAlmacen"
            [widthTextColumns]="1" [widthLabelColumns]="3" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'ConceptoAlmacenCargaEntrega')" [listProperty]="'ClaveNombre'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
