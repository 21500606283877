import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html'
})

export class PanelComponent implements AfterViewInit {
  @ViewChild('panelFooter', { static: false }) panelFooter: any;

  //#region Inputs
  title: string = "";
  variant: string = "";
  noBody: boolean = false;
  // noButton: boolean = false;
  headerClass: string = "";
  bodyClass: string = "";
  footerClass: string = "";
  panelClass: string = "";
  //#endregion

  expand = false;
  reload = false;
  collapse = false;
  remove = false;
  showFooter = false;

  @Input()
  public noButton: boolean = false;

  ngAfterViewInit() {

    setTimeout(() => {
      this.showFooter = (this.panelFooter) ? this.panelFooter.nativeElement && this.panelFooter.nativeElement.children.length > 0 : false;
    });
  }

  panelExpand() {
    this.expand = !this.expand;
  }
  panelReload() {
    this.reload = true;

    setTimeout(() => {
      this.reload = false;
    }, 1500);
  }
  panelCollapse() {
    this.collapse = !this.collapse;
  }
  panelRemove() {
    this.remove = !this.remove;
  }
}
