import { Component, EventEmitter, Input, Output } from '@angular/core';
import { startOfDay } from 'date-fns';
import { EmailInboxPage } from '../../../home/pages/email/inbox/email-inbox';
import { ButtonParam } from '../../interfaces/container-base.interface';

@Component({
  selector: 'bar-button',
  templateUrl: './bar-button.component.html',
  styleUrls: ['./bar-button.component.css']
})
export class BarButtonComponent {

  @Input()
  public icono: string = "";
  @Input()
  public name: string = "";
  @Input()
  public toolTip: string = "";

  @Output()
  public onClickButton: EventEmitter<ButtonParam> = new EventEmitter();

  public onButtonClic() {
    this.onClickButton.emit({ name: this.name });
  }

  get getColor(): string {
    return this.name == "delete" ? "text-red" : "text-dark";
  }

}
