import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Menu } from 'src/app/components/interfaces/menu.interface';
import { ModalService } from 'src/app/service/modal.service';


@Component({
  selector: 'app-busqueda-menu-page',
  templateUrl: './busqueda-menu-page.component.html',
  styles: [
  ]
})
export class BusquedaMenuPageComponent implements OnInit {
  public txtBuscar!: ElementRef<HTMLInputElement>;

  @Input()
  public menu: any[] = [];
  pantallas: Menu[] = [];
  results: Menu[] = [];
  index = 0;

  ngOnInit(): void {
    if (this.menu.length > 0) {

      this.pantallas = [];
      this.menu.forEach((menu: Menu) => {

        if (menu.submenu) {
          menu.submenu.forEach((smenu: Menu) => {
            if (!smenu.submenu) {
              this.pantallas = [...this.pantallas, { ...smenu, root: `${menu.title}` }]

            } else {
              smenu.submenu.forEach((ssmenu) => {
                this.pantallas = [...this.pantallas, { ...ssmenu, root: `${menu.title} - ${smenu.title}` }]
              });
            }
          })
        }
      })
    }
  }

  constructor(private ms: ModalService) { }

  focusRow(index: number) {
    const row = document.getElementById(`rows-${index}`);
    if (row) { row.scrollIntoView({ block: "center" }); }
  }

  keydown(key: any) {
    if (key.keyCode == 27) {
      this.ms.closeModal(null);
    }
    if (key.keyCode == 13) {
      if (this.index >= 0 && this.results.length > 0) {
        this.clickMenu(this.results[this.index]);
      }
    }
    if (key.key == "ArrowDown") {
      if (this.index + 1 < (this.results.length)) {
        this.index += 1;
        this.focusRow(this.index);
      }
    }

    if (key.key == "ArrowUp") {
      if (this.index > 0) {
        this.index -= 1;
        this.focusRow(this.index);
      }
    }
  }

  appSidebarSearch(e: any) {
    var targetValue = e.target.value;
    targetValue = targetValue.toLowerCase();
    if (targetValue.length >= 1) {
      targetValue = targetValue.replaceAll('á', 'a');
      targetValue = targetValue.replaceAll('é', 'e');
      targetValue = targetValue.replaceAll('í', 'i');
      targetValue = targetValue.replaceAll('ó', 'o');
      targetValue = targetValue.replaceAll('ú', 'u');

      this.results = [];
      this.pantallas.forEach((menu) => {

        let title = menu.title.toLowerCase();
        title = title.replaceAll('á', 'a');
        title = title.replaceAll('é', 'e');
        title = title.replaceAll('í', 'i');
        title = title.replaceAll('ó', 'o');
        title = title.replaceAll('ú', 'u');

        if (title.indexOf(targetValue) != -1) {
          this.results = [...this.results, {
            ...menu
          }];
        }
        //if (this.results.length == 0) { this.index = 0; }
      });
    } else {
      this.results = [];
      this.index = 0;
    }
  }

  clickMenu(menu: Menu) {
    this.ms.closeModal(menu);
  }


}
