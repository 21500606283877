import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';

import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { UtilsService } from 'src/app/service/utils.service';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionReporteSaldos } from '../../interfaces/saldocxc.interface';
import { environment } from 'src/environments/environment';
import { EventsService } from 'src/app/service/events.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'ctrl-edicion-rangos-antiguedad-saldos',
  templateUrl: './ctrl-edicion-rangos-antiguedad-saldos-cxc.component.html'
})
export class CtrlEdicionRangosAntiguedadSaldosCxcComponent {

  //* injects
  public utilsService = inject(UtilsService);
  private mService = inject(ModalService)
  private fb = inject(FormBuilder);
  private cd = inject(ChangeDetectorRef)
  private http = inject(HttpClient);
  private eventsService = inject(EventsService);
  private cbService = inject(ContainerBaseService);



  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;

  public myForm: FormGroup = this.fb.group({
    id: 0,
    empresa: {} as Empresa,
    textoPeriodo1: "",
    textoPeriodo2: "",
    textoPeriodo3: "",
    textoPeriodo4: "",
    periodo1Del: 0,
    periodo2Del: 0,
    periodo3Del: 0,
    periodo4Del: 0,
    periodo1Al: 0,
    periodo2Al: 0,
    periodo3Al: 0,
    periodo4Al: 0,
    esParaCxc:true
  });

  private readonly baseUrl: string = environment.baseUrlApi;

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<ConfiguracionReporteSaldos[]>(`${this.baseUrl}/CuentasPorCobrar/ObtenerConfiguracionAntiguedadSaldos`, {}).subscribe((result) => {
      if (result) {
        this.myForm.reset(result);
        this.eventsService.publish('home:isLoading', { isLoading: false });
      }
    });
    this.cd.detectChanges();
  }


  closeModal() {
    this.mService.closeModal(null);
  }

  aceptar() {
    if (!this.myForm.valid) {
      this.eventsService.publish('home:showAlert', {
        message: "Debe indicar todos los datos de cada rango.", cancelButton: false, onConfirm: (data: AlertResponse) => {

        }
      });
      return;
    }


    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.myForm.get('esParaCxc')?.setValue(true);
    const entity: any = this.myForm.value;
    this.cbService.saveEntity("ConfiguracionReporteSaldos", JSON.stringify(entity), false)
      .subscribe((item: any) => {
        this.mService.closeModal(entity);
        this.eventsService.publish('home:isLoading', { isLoading: false });
      });
  }
}


