import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Injectable, Input, LOCALE_ID, Output, ViewChild, forwardRef } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerModule,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CustomDatepickerI18nService, I18n } from '../../services/custom-datepicker-i18n.service';



/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
// @Injectable()
// export class CustomAdapter extends NgbDateAdapter<Date> {
//   readonly DELIMITER = '-';

//   fromModel(date: Date): NgbDateStruct | null {


//     return (date && date.getFullYear) ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : null;
//   }

//   toModel(date: NgbDateStruct): Date | null {
//     return date ? new Date(date.year, date.month - 1, date.day) : null;
//   }

// }

/**
//  * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
//  */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';


  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day.toString().padStart(2, '0') + this.DELIMITER + date.month.toString().padStart(2, '0') + this.DELIMITER + date.year.toString().padStart(4, '0') : '';
  }
}

@Component({
  standalone: true,
  selector: 'components-ui-date-box',
  templateUrl: './date-box.component.html',
  styleUrls: ['./date-box.component.css'],
  imports: [NgbDatepickerModule, FormsModule, JsonPipe, BrowserModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateBoxComponent),
    },
    // { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: LOCALE_ID, useValue: "es-MX" },
    I18n,
    { provide: LOCALE_ID, useValue: "es-MX" },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }
  ]
})

export class DateBoxComponent {

  value?: NgbDateStruct | null;

  isDisabled = false;

  hora: number[] = [0, 0, 0];



  onChange = (value: Date | null) => { };
  onTouched = () => { };
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 10;
  @Input()
  public required: boolean = false;
  @Input()
  public enabled: boolean = true;
  @Input()
  public placeHolder: string = "";
  @Input()
  public tabIndex: number = -1;
  @Input()
  public topLabel: boolean = false;
  @Input()
  public helpLine: string = "";
  @Output()
  public onChangeValue = new EventEmitter();

  @Output()
  public onKeyDown: EventEmitter<any> = new EventEmitter();









  @ViewChild('txtTag')
  public tagInput!: ElementRef<HTMLInputElement>;

  constructor(private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public formatter: NgbDateParserFormatter) { }

  get IsRequired(): string {
    if (this.tagInput) {
      if (this.tagInput.nativeElement.value) return "";
    }
    return this.required ? "tb-obligate" : "";
  }



  writeValue(value: any): void {
    if (value) {
      if (typeof (value) == 'object') {
        //value = `${value.getFullYear()}-${value.getMonth()}-${value.getDate()}`; //new Date(Entidad.FechaCancelacion.getFullYear(), Entidad.FechaCancelacion.getMonth(), Entidad.FechaCancelacion.getDate());
        this.value = {
          day: value.getDate(),
          year: value.getFullYear(),
          month: value.getMonth() + 1
        }
        return;
      }

      if (typeof (value) == 'function') {
        return;
      }



      let arr = String(value).substring(0, 10).split('-');
      //if (arr.length === 3) {
      this.value = {
        day: parseInt(arr[2]),
        year: parseInt(arr[0]),
        month: parseInt(arr[1])
      }

      arr = String(value).substring(11).split(':');

      this.hora[0] = parseInt(arr[0]);
      this.hora[1] = parseInt(arr[1]);
      this.hora[2] = parseInt(arr[2]);
      let fecha1:Date = new Date(this.value.year,this.value.month-1, this.value.day);
      this.onChange(fecha1);
      this.onChangeValue.emit(fecha1);


    } else {
      this.value = null;
    }
  }

  focusFunction() {
    this.tagInput.nativeElement.classList.add("pulse-date");
    setTimeout(() => {
      this.tagInput.nativeElement.selectionStart = 0;
      this.tagInput.nativeElement.selectionEnd = 0;
    }, 5);

    let pes = document.querySelectorAll('.lineadeayuda');
    if (this.helpLine) {
      pes.forEach((elem: any) => { elem.innerHTML = this.helpLine; });
    } else {

      pes.forEach((elem: any) => { elem.innerHTML = "" });
    }


  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
  changeDate(value: NgbDate) {
    const fecha = new Date(value.year, value.month - 1, value.day);
    this.onChange(fecha);
    this.onChangeValue.emit(fecha);
  }

  validarDatoLostFocus(): string {

    var tmp: string = '';
    var dia: number = 0;
    var mes: number = 0;
    var anio: number = 0;
    var arr: any
    var fechaOut: string;

    tmp = this.tagInput.nativeElement.value;

    if (tmp==""){
      return "";
    }

    dia = parseInt(tmp);
    arr = tmp.split('/');
    if (arr.length > 1) {
      mes = parseInt(arr[1]);
    }

    if (arr.length > 2) {
      anio = parseInt(arr[2]);
    }

    if (anio === 0 || isNaN(anio)) {
      anio = new Date().getFullYear();
    }
    if (dia === 0 || isNaN(dia)) {
      dia = new Date().getDate();
    }

    if (mes === 0 || isNaN(mes)) {
      mes = new Date().getMonth() + 1;
    }

    if (anio < 100) {
      anio = anio + anio > 50 ? 1900 : 2000;
    }

    const fechaFmt: string = new Date(anio, mes - 1, dia).toLocaleDateString('en-GB');

    fechaOut = dia.toString().padStart(2, '0') + '/' + (mes).toString().padStart(2, '0') + '/' + anio.toString().padStart(4, '0');

    if (this.tagInput.nativeElement.value !== fechaOut) {
      return fechaFmt;
    } else {
      return '';
    }
  }

  posicionCursor() {
    var pos!: number | null;
    pos = 0;
    pos = this.tagInput.nativeElement.selectionStart;

    if (pos! < 3) {
      this.tagInput.nativeElement.selectionStart = 2;
      this.tagInput.nativeElement.selectionEnd = 2;
    } else if (pos! < 6) {
      this.tagInput.nativeElement.selectionStart = 5;
      this.tagInput.nativeElement.selectionEnd = 5;
    } else {
      this.tagInput.nativeElement.selectionStart = 9;
      this.tagInput.nativeElement.selectionEnd = 9;
    }
  }

  onKeyPress(e: any) {

    var selstart: any;
    var key: any;
    var posicion: any;
    key = String.fromCharCode(e.keyCode);

    selstart = this.tagInput.nativeElement.selectionStart;

    if (key === "") {
    }

    if (selstart !== this.tagInput.nativeElement.selectionEnd) {
      // this.value = "";
      //todo
    } else if (this.tagInput.nativeElement.selectionEnd! > selstart) {
      this.tagInput.nativeElement.selectionEnd = selstart;
    }

    if (key === " ") {
      // this.value = "";
      //todo
      return;
    } else if (key === "/") {
      this.posicionCursor();
      return;
    } else if ((e.keyCode < 48 || e.keyCode > 57)) {
      e.preventDefault();
      return;
    }

    switch (selstart) {
      case 0:
        if (key > "3") key = "0" + key + "/ ";
        break;
      case 1:
        if (key === "0" && this.tagInput.nativeElement.value.substring(0, 1) === "0") {
          key = "";
        } else if (key > "1" && this.tagInput.nativeElement.value.substring(0, 1) === "3") {
          key = "";
        } else {
          key = key + "/"
        }
        break;
      case 2:
        key = "/"
        break;
      case 3:
        if (key > "1") key = "0" + key + "/";
        break;
      case 4:
        if (key > "2" && this.tagInput.nativeElement.value.substring(3, 4) === "1") {
          key = "";
        } else {
          key = key + "/";
        }
        break;
      case 5:
        key = "/"
        break;
      case 6:
        if (key !== "1" && key !== "2")
          if (key === "0") {
            key = "200";
          } else {
            key = "19" + key;
          }
        break;
      case 7:
        if (this.tagInput.nativeElement.value.substring(6, 7) === "1") {
          if (key !== "9") {
            posicion = this.tagInput.nativeElement.selectionStart;
            this.tagInput.nativeElement.selectionStart = posicion - 1;
            key = "201" + key;
          }
        } else if (this.tagInput.nativeElement.value.substring(6, 7) === "2" && key !== "0") {
          key = "";
        }
        break;
      case 8:
      case 9:
        break;
      default:
        key = "";
    }

    if (key.length > 0) {
      this.tagInput.nativeElement.setSelectionRange(this.tagInput.nativeElement.selectionStart, this.tagInput.nativeElement.selectionStart + key.length);
      this.tagInput.nativeElement.setRangeText(key);
      this.tagInput.nativeElement.setSelectionRange(this.tagInput.nativeElement.selectionStart + key.length, this.tagInput.nativeElement.selectionStart + key.length);
    }
    e.preventDefault();
  }

  onBlur() {
    this.tagInput.nativeElement.classList.remove("pulse-date");
    this.tagInput.nativeElement.setSelectionRange(0, 1);


    var fechaOut = this.validarDatoLostFocus();

    if (fechaOut !== "") {
      if (this.tagInput.nativeElement.value === "") {
        // var fecha = fechaOut.split('/');
        // this.value = { day: parseInt(fecha[0]), month: parseInt(fecha[1]), year: parseInt(fecha[2]) };
        if (this.required) {
          this.tagInput.nativeElement.value = fechaOut;
        }
      } else {
        this.tagInput.nativeElement.value = fechaOut;
      }
    }
    // this.value =  this.convFecha( this.tagInput.nativeElement.value);

    if (this.tagInput.nativeElement.value != "") {
      this.value = {
        day: this.convFecha(this.tagInput.nativeElement.value).getDate(),
        month: this.convFecha(this.tagInput.nativeElement.value).getMonth() + 1,
        year: this.convFecha(this.tagInput.nativeElement.value).getFullYear()
      }
    } else {
      this.value = null;
    }

    //              = this.convFecha(this.tagInput.nativeElement.value).getDate();
    // this.value!.month = this.convFecha(this.tagInput.nativeElement.value).getMonth();
    // this.value!.year = this.convFecha(this.tagInput.nativeElement.value).getFullYear();

    // const result = this.value ? this.value.year.toString().padStart(4, '0') + '-' + (this.value.month + 1).toString().padStart(2, '0') + '-' + this.value.day.toString().padStart(2, '0') : null;
    if (this.value) {
      let fecha1: any;
      if (this.hora[0] != 0 || this.hora[1] != 0 || this.hora[2] != 0) {
        fecha1 = new Date(this.value!.year, this.value!.month - 1, this.value!.day, this.hora[0], this.hora[1], this.hora[2]);
      } else {
        fecha1 = new Date(this.value!.year, this.value!.month - 1, this.value!.day);
      }

      this.onChange(fecha1);
      this.onChangeValue.emit(fecha1);
    } else {
      this.onChange(null);
      this.onChangeValue.emit(null);
    }
  }


  convFecha(value: string): Date {
    const arr = value.split('/');
    const fecha: Date = new Date(parseInt(arr[2]), parseInt(arr[1]) - 1, parseInt(arr[0]));
    return fecha;
  }


  onKeyDownFecha(e: any) {

    var key: string = e.key;
    var selstart: number = this.tagInput.nativeElement.selectionStart!;
    var pos: number = 0;

    if (key.toLowerCase() === "backspace") {
      if (selstart > 1) {
        pos = this.tagInput.nativeElement.value.lastIndexOf('/', selstart - 2);
        pos++;
        this.tagInput.nativeElement.setSelectionRange(pos, pos);

      } else if (selstart > 0) {
        this.tagInput.nativeElement.selectionStart = 0;
      }
      e.preventDefault();
    } else if (key.toLowerCase() === "delete") {
      this.value = null;

    }
    this.onKeyDown.emit(e)
  }
}

