import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-devoluciones-cargaentrega',
  templateUrl: './verificador-devoluciones-cargaentrega.component.html',
})
export class VerificadorDevolucionesCargaentregaComponent {


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private utilsService= inject(UtilsService);
  private fb =  inject(FormBuilder);
  private reportsService = inject(ReportsService);

  menuId: string = "verificadorordenescargadevoluciones";


  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: 'empClave,sucClave,doc.Clave' },
    { Nombre: 'Vehiuculo', Orden: 'empClave,sucClave,doc.Clave' },
    { Nombre: 'Cliente', Orden: 'empClave,sucClave,doc.Clave' },
    { Nombre: 'Vendedor', Orden: 'empClave,sucClave,doc.Clave' }
  ]

  public filtros: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificador de Ordenes de carga',
      NombreExcel: 'Verificador de Ordenes de carga.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'veh.Clave', Etiqueta: 'Vehículo', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Unidad" },
        { Campo: 'chof.Clave', Etiqueta: 'Chofer', Tipo: TypeFilter.number, Entidad: "Chofer" },
        { Campo: 'vend.Clave', Etiqueta: 'Vendedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'vta.seriestring', Etiqueta: 'Serie Factura', Tipo: TypeFilter.text,  },
        { Campo: 'vta.folio', Etiqueta: 'Folio Factura', Tipo: TypeFilter.number,  },
        { Campo: 'v.Vigente', Etiqueta: 'Vigente', Tipo: TypeFilter.boolean, ListaItems: [{ Nombre: 'Sin selección' }, { Nombre: 'Vigentes' }, { Nombre: 'No vigentes' }] },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor() { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
      let fecha = new Date();
      this.myForm.get('Fecha1')?.setValue(fecha);
      this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE PRODUCTOS DEVUELTOS EN CARGA Y ENTREGA ' + this.rangoFechas);
      this.focus('txtNombreReporte');
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE PRODUCTOS DEVUELTOS EN CARGA Y ENTREGA ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this.utilsService.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }

  incluirTraspasosEntreSUcursales(value: boolean) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  changeselec(event: any) {
    this.filtros = event;
  }

  clickBoton(event: string) {
    this.filtros.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtros, '/Ventas/ReporteVerificadorDevolucionesOrdenCarga');
  }


 }
