import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ConsultaProduccion } from '../../interfaces/manufacura.interface';
import * as moment from 'moment';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import listPlugin from '@fullcalendar/list';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-producciones-por-orden-page',
  templateUrl: './producciones-por-orden-page.component.html',
  styles: [
  ]
})
export class ProduccionesPorOrdenPageComponent implements OnInit {

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  public lista: ConsultaProduccion[] = [];
  @Input()
  public idOrden: number = 0;


  indexEditing:number =-1;
  margen:string = "300px";
  public appSettings = inject(AppSettings);

  constructor(private fService: FacturacionService) {
  }
  ngOnInit(): void {
    this.fService.getProducciones(this.idOrden).subscribe((lista) => {
      this.lista = lista;
    });
    if (this.appSettings.appSidebarMinified){
      this.margen= "100px";
    }
  }

  getDate(fecha: any): string {
    return moment(fecha).format("DD/MM/YYYY HH:mm:ss")
  }

  closeModal() {
    this.onClose.emit();
  }
}
