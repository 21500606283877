import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { TIpoNotaCredito, VentaAfectaNotaCredito } from '../../interfaces/venta.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { af } from 'date-fns/locale';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { createEmptyEventStore } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-datos-nota-credito-page',
  templateUrl: './datos-nota-credito-page.component.html',
  styles: [
  ]
})
export class DatosNotaCreditoPageComponent implements OnInit {

  @ViewChild('cboTipo')
  public cboTipo!: ElementRef<TextBoxComponent>;
  @ViewChild('txtFolio')
  public txtFolio!: ElementRef<TextBoxComponent>;
  private eventsService = inject(EventsService);
  @ViewChild('modalAfecta')
  public modalAfecta!: ElementRef<HTMLElement>;

  @Input()
  public totalNC: number = 0;

  @Input()
  public idEmpresa: number = 0;
  @Input()
  public serie: string = "";
  @Input()
  public esFacturaMigrada: boolean = false;
  @Input()
  public uUIDOriginaNotaCredito: string = "";
  @Input()
  public folio: number = 0;
  @Input()
  public tipoNotaCredito: any = null;
  @Input()
  public sucursalOriginaNotaCredito: any = null;
  @Input()
  public tipoRelacionNotaCredito: any = null;
  @Input()
  public DocumentoVentaOrigina: any = null;
  @Input()
  public afecta: VentaAfectaNotaCredito[] = [];

  itemEdicion: any = null;

  public myForm: FormGroup = this.fb.group({
    SucursalOriginaNotaCredito: [null],
    TipoNotaCredito: [null],
    EsFacturaMigrada: [false],
    TipoRelacionNotaCredito: [null],
    DocumentoVentaOrigina: [null],
    Serie: [''],
    UUIDOriginaNotaCredito: [''],
    Folio: [0],
    Afecta: [[]]
  })

  constructor(private fb: FormBuilder, private ms: ModalService, private fServices: FacturacionService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      const t: any = this.cboTipo;
      t.tagInput.nativeElement.focus();
    }, 100);
    this.myForm.reset({
      Serie: this.serie,
      Folio: this.folio,
      Afecta: !this.afecta ? [] : this.afecta,
      EsFacturaMigrada: this.esFacturaMigrada,
      SucursalOriginaNotaCredito: this.sucursalOriginaNotaCredito,
      UUIDOriginaNotaCredito: this.uUIDOriginaNotaCredito,
      TipoRelacionNotaCredito: this.tipoRelacionNotaCredito,
      DocumentoVentaOrigina: this.DocumentoVentaOrigina,
      TipoNotaCredito: this.tipoNotaCredito
    });
  }

  accept(esFolio: boolean = false) {
    const t: any = this.txtFolio;
    t.tagInput.nativeElement.blur();
    setTimeout(() => {
      if (!this.myForm.value.TipoNotaCredito) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Tipo de Nota de Crédito.`, cancelButton: false });
        return;
      }
      if (!this.myForm.value.TipoRelacionNotaCredito) {
        this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Tipo de Relación de CFDI.`, cancelButton: false });
        return;
      }

      if (!this.myForm.value.EsFacturaMigrada) {
        if (!this.myForm.value.DocumentoVentaOrigina) {
          this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Documento de Venta que Origina.`, cancelButton: false });
          return;
        }

        if (!this.myForm.value.Serie) {
          this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Serie de la Factura que origina la Nota de Crédito.`, cancelButton: false });
          return;
        }

        if (this.myForm.value.Folio == 0) {
          this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Folio de la Factura que origina la Nota de Crédito.`, cancelButton: false });
          return;
        }
        this.searchCfdi(() => {
          this.cerrarTodo();
        })
      } else {
        if (this.myForm.value.UUIDOriginaNotaCredito?.length == 0) {
          this.eventsService.publish('home:showAlert', { message: `Debe de indicar el UUID de la Factura que origina la Nota de Crédito.`, cancelButton: false });
          return;
        }
        this.cerrarTodo();
      }
    }, 50);
  }

  deleteRow(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el registro?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.afecta.splice(index, 1);
          this.afecta = [...this.afecta];
        }
      }
    });


  }

  get getCapturado(): number {
    let afecta = this.afecta;
    if (!afecta) afecta = [];
    let capturado = 0;

    afecta.forEach((i) => {
      capturado += i.Importe;
    })

    return capturado;
  }

  cerrarTodo() {
    let afecta = this.afecta;
    if (!afecta) afecta = [];
    let capturado = 0;

    afecta.forEach((i) => {
      capturado += i.Importe;
    })


    if (capturado == 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'No ha capturado información para Cuentas Por Cobrar, ¿Desea Continuar?',
        onConfirm: (r: AlertResponse) => {
          if (r.isAccept) {
            this.ms.closeModal(this.myForm.value);
          }
        }
      });
    } else {
      if (capturado != this.totalNC) {
        this.eventsService.publish('home:showAlert', { message: `El total capturado: ${capturado} es diferente al Total de la Nota de Crédito: ${this.totalNC}`, cancelButton: false });
      } else {
        this.myForm.controls["Afecta"].setValue([...this.afecta]);
        this.ms.closeModal(this.myForm.value);
      }
    }
  }

  agregarEditar(index: number) {
    const b: any = this.modalAfecta;
    if (index >= 0) {
      this.itemEdicion = { ... this.afecta[index] };

    } else {
      this.itemEdicion = null;
    }
    this.ms.openModal(b, (e: any) => {
      if (e) {
        debugger
        if (!this.afecta) this.afecta = [];
        if (index >= 0) {
          this.afecta[index] = { ...e };
          this.afecta = [...this.afecta];
        } else {
          this.afecta = [...this.afecta, { ...e }]
        }
      }
    });
  }


  searchCfdi(cb: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.getUUID(this.idEmpresa, this.myForm.value.SucursalOriginaNotaCredito.Id, this.myForm.value.DocumentoVentaOrigina.Id, this.myForm.value.Serie, this.myForm.value.Folio).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.myForm.controls["UUIDOriginaNotaCredito"].setValue(result.message)
        cb();
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  selectedCombo(ent: any, tipo: string) {
    this.myForm.controls[tipo].setValue(ent);
  }

  close() {
    this.ms.closeModal(null);
  }
}
