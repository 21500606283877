import { Component, ElementRef, ViewChild } from '@angular/core';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from '../../../configuracion/service/configuracion.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Impuesto } from '../../interfaces/impuesto.interface';
import { IdiomaMoneda, Moneda, c_Moneda } from '../../../configuracion/interfaces/moneda.interface';
import { Validators } from 'ngx-editor';

@Component({
  selector: 'app-monedas-page',
  templateUrl: './monedas-page.component.html',
  styles: [
  ]
})
export class MonedasPageComponent {

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public itemsCombo: ComboBoxEntity[] = [];

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: ['', Validators.required],
    Abreviacion: ['', Validators.required],
    NombreSingular: [''],
    NombrePlurar: [''],
    LeyendaCentavos: [''],
    MonedaSAT: [{} as c_Moneda, Validators.required],
    Idioma: [{} as IdiomaMoneda, Validators.required],
    EsExtranjera: false,
    Baja: false
  })

  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService,) {
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {

    const ent = this.myForm.value;
    // if ((ent.Retiene && ent.Traslada) || (!ent.Retiene && !ent.Traslada)) {
    //   Swal.fire({ icon: 'error', text: 'Solo indique una opción, Traslada o Retiene' }).then(() => { });
    //   param.callback(false);
    //   return;
    // }
    param.callback(true);
  }

  onItemSearched(entity: Moneda): void {
    if (entity) {
      this.myForm.reset(entity);
      console.log(entity)
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: Moneda) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Moneda").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  selectedMoneda(entity: ComboBoxEntity) {
    this.myForm.controls["MonedaSAT"].setValue(entity);
  }

  selectedIdioma(entity: ComboBoxEntity) {
    this.myForm.controls["Idioma"].setValue(entity);
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
