import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { UtilsService } from 'src/app/service/utils.service';
import { PresupuestoService } from '../../services/presupuesto.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EmpresaSucursalDto, UserLogged } from 'src/app/auth/interfaces';
import { PresupuestoVentaVendedor } from '../../interfaces/presupuestos.interface';
import { PanelComponent } from '../../../components/panel/panel.component';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'ctrl-edicion-copiar-presupuestos',
  templateUrl: './ctrl-edicion-copiar-presupuestos.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtrlEdicionCopiarPresupuestosComponent {
  public GuiColumnAlign = GuiColumnAlign;

  // injects
  public utilsServide = inject(UtilsService);
  private modalService = inject(ModalService);


  //  eventos y parametros del componente


  @Input()
  public tipo: number = 1;

  lista: any[] = [];


  // Dependencias
  private utilsService = inject(UtilsService)
  private presupuestoService = inject(PresupuestoService)
  private cd = inject(ChangeDetectorRef)

  //  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  usuarioActual: UserLogged = {} as UserLogged;

  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.usuarioActual = this.utilsService.getUserLogged();

    this.presupuestoService.getPresupuestos(this.empresaActual?.clave!, 0, 0, this.tipo).subscribe((lista) => {
      this.lista = lista;

      this.lista.map(p => {
        p.Nombre = this.utilsService.getMes(p.Periodo);
      })

      this.cd.detectChanges();
    });
  }

  keydown(e: any) {
    if (e.code == "F8") {
      /// this.accept();
    } else if (e.code == "F9") {
      ///this.closeModal();
    }
  }

  seleccionar(reg: PresupuestoVentaVendedor) {
    this.modalService.closeModal(reg);
  }

  closeModal() {
    this.modalService.closeModal(null);
  }

  get getEncabezado() {
    let encab = "";
    if (this.tipo == 2) {
      encab = 'Presupuestos por Sucursal';
    } else {
      encab = 'Presupuestos por Vendedor';
    }
    return encab;
  }

}
