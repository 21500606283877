<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" (keydown)="keydown($event)">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Datos para la DIOT</div>
  <app-container-base subTitle="" [containerInnerHeight]="515" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
    [hasCounter]="false">
    <div class="screen-content">
      <form [formGroup]="myForm" autocomplete="off">
        <app-panel-base title="Datos DIOT">
          <div class="panel-content">

            <components-ui-number-box (keydown.escape)="escape()" (blur)="blurProveedor($event)" [tabIndex]="4" [maxLength]="15"
              (onClicIcon)="openProvSearch()" (keydown.f2)="openProvSearch()" [label]="'Proveedor'" [enableIcon]="true"
              [widthTextColumns]="3" [widthLabelColumns]="4" #txtProveedor />
            <div class="form-group row">
              <div class="col-sm-4" style="text-align: right;">
                Nombre:
              </div>
              <div class="col-sm-8 text-truncate" style="text-align: left;">
                {{getNombreProveedor | uppercase}}
              </div>
            </div>
            <components-ui-date-box [required]="true" label="Fecha" [tabIndex]="5" formControlName="fecha"
              [widthLabelColumns]="4" [widthTextColumns]="3" />

            <components-ui-text-box (blur)="blurSerieFolio($event)" formControlName="factura" [tabIndex]="6"
              [maxLength]="150" [label]="'Factura'" [widthTextColumns]="3" [widthLabelColumns]="4" #txtSerieFolio />

            <components-ui-text-box (keydown.f2)="openCfdi()" (onClicIcon)="openCfdi()" formControlName="uuid"
              [tabIndex]="7" [maxLength]="40" [enabled]="false" [label]="'UUID'" [widthTextColumns]="8"
              [enableIcon]="true" [widthLabelColumns]="4" />



            <components-ui-number-box [formatoMoneda]="true" formControlName="subtotal" [tabIndex]="8" [maxLength]="15"
              [label]="'Subtotal'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="subtotalGravaIVA" [tabIndex]="9"
              [maxLength]="15" [label]="'Subtotal IVA'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="subtotalExento" [tabIndex]="10"
              [maxLength]="15" [label]="'Subtotal Exento'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="subtotalTasaCero" [tabIndex]="11"
              [maxLength]="15" [label]="'Subtotal Tasa 0'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="ivaRetenido" [tabIndex]="12"
              [maxLength]="15" [label]="'IVA Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />

            <components-ui-number-box [formatoMoneda]="true" formControlName="isrRetenido" [tabIndex]="13"
              [maxLength]="15" [label]="'ISR Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="impLocalTrasladado" [tabIndex]="14"
              [maxLength]="15" [label]="'Imp Loc Trasladado'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="impLocalRetenido" [tabIndex]="15"
              [maxLength]="15" [label]="'Imp Loc Retenido'" [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box [formatoMoneda]="true" formControlName="iva" [tabIndex]="16" [maxLength]="15"
              [label]="'IVA'" [widthTextColumns]="3" [widthLabelColumns]="4" />

            <component-ui-combo-box formControlName="factor" filterQuery="Clave != 2" [required]="true" label='Factor IVA'
              [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'factor')" entityName="c_TipoFactor"
              [tabIndex]="17" [widthLabelColumns]="4" [widthTextColumns]="3" [widthRightColumns]="5" />

            <components-ui-number-box formControlName="tasa" [tabIndex]="18" [maxLength]="5" [label]="'Tasa IVA'"
              [widthTextColumns]="3" [widthLabelColumns]="4" />
            <components-ui-number-box (keydown.enter)="enterTotal()" [formatoMoneda]="true" formControlName="total"
              [tabIndex]="19" [maxLength]="15" [label]="'Total'" [widthTextColumns]="3" [widthLabelColumns]="4"
              #txtTotal />

          </div>
        </app-panel-base>
        <div style="text-align: right; margin-top: 5px;">
          <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Agregar</div>
          <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
        </div>
        <ng-template #content let-modal let-c="close">
          <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
            [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #ctrlBusqueda />
        </ng-template>
        <ng-template let-modal let-c="close" #ctrlAgregarCfdi>
          <app-ctrl-buscador-cfdi [rfcEmisor]="myForm.value.proveedor.rfc" [mostrarAgregados]="true" [uuidsAgregados]="myForm.value.uuid"
            [title]="'CFDI a relacionar'" [anio]="fechaPoliza!.getFullYear()" [mes]="fechaPoliza!.getMonth()"
            [setIsNew]="true" />
        </ng-template>
      </form>

    </div>
  </app-container-base>
</div>
