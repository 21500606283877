<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <app-panel-base title="Otras unidades del producto">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <div *ngFor="let item of items; let i = index" class="input-group mb-3">
          <div class="input-group-text">{{item.Unidad.Nombre}}</div>
          <input id="conv-{{i}}" (keydown)="keyDown($event, i)" formControlName="valorConversion{{(i+1)}}" type="number"
            class="form-control">
          <div class="input-group-text">{{unidadFinal}} = {{item.Conversion}}</div>
        </div>
      </form>
      <div style="text-align: right; margin-top: 5px;">
        <h5>Total {{unidadFinal}}: {{ getTotal | number:'1.2-6'}}</h5>
        <button id="btnAcConv" tabindex="20" (click)="accept()" class="btn btn-success"
          style="margin-right: 5px;">Aceptar</button>
        <button tabindex="25" (click)="closeModal()" class="btn btn-danger">Cancelar</button>
      </div>
    </div>
  </app-panel-base>
</div>
