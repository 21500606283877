import { Component, ElementRef, Inject, QueryList, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterContentList, ReportFilterListItem, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import Swal from 'sweetalert2';
import { CuentasPorCobrarService } from '../../../services/cxc.service';
import { ReportsService } from 'src/app/service/reports.service';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ConfiguracionReporteSaldos } from 'src/app/cuentas-por-cobrar/interfaces/saldocxc.interface';
import { EventsService } from 'src/app/service/events.service';
import { data } from 'jquery';

@Component({
  selector: 'app-antiguedad-saldos-page',
  templateUrl: './antiguedad-saldos-cxc-page.component.html',
  styles: [
  ]
})
export class AntiguedadSaldosPageComponent {

  private reportsService = inject(ReportsService)
  private modalService = inject(ModalService)
  private eventsService = inject(EventsService);


  fechaDelReporte: Date = new Date();
  menuId: string = "antiguedadsaldoscxc";

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  @ViewChild('ctrlRangos')
  public ctrlRangos!: QueryList<ElementRef>;


  public desgloseLista: ReportFilterContentList[] = [{ Nombre: 'a Detalle', Id: 1 },
  { Nombre: 'a Totales', Id: 2 },
  { Nombre: 'Análisis de vencimientos', Id: 3 }]

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Numérico', Orden: 'empclave,succlave,cliclave,Abreviacion,FechaEmision' },
  { Nombre: 'Alfabético', Orden: 'empclave,succlave,cliNombre,Abreviacion,FechaEmision' }]

  public luegoPorLista: ReportFilterOrderList[] = [{ Nombre: 'Ninguno', Orden: '' },
  { Nombre: 'Fecha de emisión', Orden: 'Fechaemision' },
  { Nombre: 'Fecha de vencimiento', Orden: 'FechaVencimiento' }]

  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      DesgloseLista: this.desgloseLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Antigüedad de saldos',
      NombreExcel: 'Antigüedad de saldos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cuentacxc.Clave', Etiqueta: 'Cuenta de Cxc.', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxc" },
        { Campo: 'clase.clave', Etiqueta: 'Clase de venta.', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseVenta" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'ven.Clave', Etiqueta: 'Vendedor factura', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'ven.Clave', Etiqueta: 'Vendedor master', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
      ],
    };

  menuItems: PanelBaseDropDownMenu[] = [{ Id: 'columnas', Nombre: 'Configurar Rangos De Vencimiento de Cuentas por Cobrar' }]


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: null,
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
    Opc5: [false],
    Opc6: [false],
    Opc7: [false],
    Opc8: [false],
    Num1: [0],
    Num2: [0],
    Num3: [0]
  });

  constructor(private fb: FormBuilder, private utils: UtilsService, private containerService: ContainerBaseService, private router: Router, private cxcService: CuentasPorCobrarService) { }

  ngOnInit(): void {
    this.setNombreReporte();
    this.focus('txtNombreReporte');
    this.myForm.get('Fecha1')?.setValue(new Date())
  }

  setNombreReporte() {
    this.myForm.get('NombreReporte')?.setValue('ANÁLISIS DE ANTIGÜEDAD DE SALDOS DE CUENTAS POR COBRAR AL ' + this.utils.Date2Str(this.fechaDelReporte, 5).toUpperCase());
  }

  onChangeValue(value: any) {
    if (value) {
      this.fechaDelReporte = value;
      this.setNombreReporte();
    }
  }

  clicMenu(value: string) {
    if (value == "columnas") {
      this.mostrarRangos();
    }
  }


  mostrarRangos() {
    this.modalService.openModal(this.ctrlRangos, (e: any) => {
      this.onCloseDetalle(e)
    }, "sm");
  }

  onCloseDetalle(item: ConfiguracionReporteSaldos) {
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onclickMayor(value: any, opcion: string) {

    if (this.myForm.get('Fecha2')?.value == null) {
      this.myForm.get('Fecha2')?.setValue(new Date());
    }

    this.myForm.get('Opc1')?.setValue(opcion == 'opc1');
    this.myForm.get('Opc2')?.setValue(opcion == 'opc2');
    this.myForm.get('Opc3')?.setValue(opcion == 'opc3');

    if (!this.myForm.get('Opc1')?.value && this.myForm.get('Opc2')?.value && !this.myForm.get('Opc3')?.value) {
      this.myForm.get('Fecha2')?.setValue(null);
    }
  }



  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    if (this.myForm.get('Fecha2')?.value != null) {
      if (!this.myForm.get('Opc1')?.value && !this.myForm.get('Opc2')?.value && !this.myForm.get('Opc3')?.value) {
        Swal.fire({ icon: 'info', text: 'Debe indicar una opción de comparación para la fecha de vencimiento, ejemplo: mayor, menor o igual.' });
        return;
      }
    }
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/CuentasPorCobrar/ReporteAntiguedadDeSaldos');
  }
}
