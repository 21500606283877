import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FinanzasService } from '../../services/finanzas.service';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EmpresaSucursalDto, UserLogged } from 'src/app/auth/interfaces';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';

@Component({
  selector: 'app-diot-generacion',
  templateUrl: './diot-generacion.component.html'
})
export class DiotGeneracionComponent implements AfterViewInit, OnInit {

  private utilsService = inject(UtilsService);
  private eventsService = inject(EventsService);
  private finanzasService = inject(FinanzasService);
  private contabilidadService = inject(ContabilidadService);
  private fServices = inject(FacturacionService);


  listaMeses: any[] = [];
  listaAnios: any[] = [];

  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  anioSeleccionado?: ComboBoxEntity;
  generado: boolean = false;
  menuId: string = "diot-generacion";

  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;



  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  //Serie-Folio-Estatus
  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-TipoComprobante', Orden: 'empClave,sucClave,doc.Clave,c.FechaEmision,c.id' }]
  private reportsService = inject(ReportsService)

  public myForm: FormGroup = this.fb.group({
    Anio1: 0,
    Mes1: 0,
    Mes2: 0,
    SubTotalIVAGrav: 0,
    SubTotalIVATasa0: 0,
    SubtotalExentoIVA: 0,
    TotalIVARetenido: 0,
    SubTotalNoAcreditable: 0,
    Total:0
  });





  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }
  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      DesgloseLista: [{ Nombre: 'a Detalle' }],
      NombreReporte: 'Declaración de Informormativa de Operaciones con Terceros (DIOT)',
      TituloVisor: 'Reporte de Diot',
      NombreExcel: 'Reporte de Diot.xlsx',
      FilterOptions: []
    }


  constructor(private fb: FormBuilder, private utils: UtilsService) { }

  ngAfterViewInit(): void {
    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
  }


  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.empresaActual = this.info.empresa;

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
    this.myForm.get('Mes1')?.setValue(mesItem.Clave);
    this.myForm.get('Mes2')?.setValue(mesItem.Clave);
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('Anio1')?.setValue(this.anioSeleccionado!.Clave);
      }
    })



  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    //  this.filtrosDiarioVentas = event;
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Mes1') {
      this.mes1Seleccionado = entity;
    } else if (field == 'Mes2') {
      this.mes2Seleccionado = entity;
    }
    this.myForm.controls[field].setValue(entity.Clave);
  }

  clickBoton(event: string) {

  }

  get getMes1(): ComboBoxEntity | null {
    return this.mes1Seleccionado!;
  }

  get getMes2(): ComboBoxEntity | null {
    return this.mes2Seleccionado!;
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    return this.anioSeleccionado!;
  }

  get getPartidas(): number {

    return 0;
  }

  get getCantidad() {

    return 0;
  }


  Imprimir() {

    this.filtrosReporte.ReportHeader = this.myForm.value;


    let ultimoDia:number = this.utils.getUltimoDiaMes(this.anioSeleccionado?.Id!,this.mes2Seleccionado?.Id!);
    '01/' + this.mes1Seleccionado?.Id.toString().padStart(2,'0') + "/" + this.anioSeleccionado?.Id
    let rango:string = '01/' + this.mes1Seleccionado?.Id.toString().padStart(2,'0') + "/" + this.anioSeleccionado?.Id + " al "  + ultimoDia.toString().padStart(2,"0") + "/" + this.mes2Seleccionado?.Id.toString().padStart(2,"0")+ "/" + this.anioSeleccionado?.Id;

    this.filtrosReporte.ReportHeader.NombreReporte  =  this.filtrosReporte.NombreReporte + " del " + rango;
    this.reportsService.printReport(this.filtrosReporte, '/Fiscal/ImprimirDiot');
  }

  generarContabilidad() {
    if (this.mes1Seleccionado!.Id > this.mes2Seleccionado!.Id) {
      this.eventsService.publish('home:showAlert', {
        message: 'El rango de meses seleccionado es inválido.',
        onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    this.finanzasService.generarDiot(this.mes1Seleccionado?.Id!, this.mes2Seleccionado?.Id!, this.anioSeleccionado?.Id!).subscribe((result: any) => {
      if (result) {

        this.myForm.get('SubTotalIVAGrav')?.setValue(result.subTotalIVAGravado);
        this.myForm.get('SubTotalIVATasa0')?.setValue(result.subTotalIVATasa0);
        this.myForm.get('SubtotalExentoIVA')?.setValue(result.subTotalExentoIVA);
        this.myForm.get('TotalIVARetenido')?.setValue(result.totalIVARetenido);
        this.myForm.get('SubTotalNoAcreditable')?.setValue(result.subTotalNoAcreditable);

        this.myForm.get('Total')?.setValue(result.subTotalIVAGravado + result.subTotalIVATasa0 + result.subTotalExentoIVA + result.totalIVARetenido + result.subTotalNoAcreditable);



        this.generado = true;
        let file: any = this.fServices.base64ToArrayBuffer(result.archivo)
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        let blob = new Blob([file], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        //a.download = `${this.rfcActual}${this.myForm.value.Anio}${(this.myForm.value.Mes.Id.toString().padStart(2, '0'))}CT.zip`;
        a.download = `${this.info.empresa?.rfc}_${this.mes1Seleccionado?.Id}_${this.mes2Seleccionado?.Id}.zip`
        a.click();
        window.URL.revokeObjectURL(url);
      }
    })
  };

}
