<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="ConceptoCxc"
  title="Conceptos de CXC" icon="fa fa-rectangle-list" subTitle="Administra los Conceptos de CXC">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'ConceptoCxc'" entityName="ConceptoCxc" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="100" />

          <components-ui-text-box [label]="'Alias'" [required]="true" formControlName="Alias" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="20" />

          <component-ui-check-box [tabIndex]="4" [widthLabelColumns]="2" formControlName="Carga" [widthTextColumns]="7"
            [label]="'Carga'" />

          <component-ui-check-box [tabIndex]="5" [widthLabelColumns]="2" formControlName="Abona" [widthTextColumns]="7"
            [label]="'Abona'" />

          <component-ui-check-box label='Baja' [widthLabelColumns]="2" formControlName="Baja" [widthTextColumns]="6"
            [tabIndex]="6" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
