import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CartaPorteMercancia } from '../../interfaces/cartaporte.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CartaPorteService } from '../../services/cartaporte.service';
import Swal from 'sweetalert2';
import { ModalService } from 'src/app/service/modal.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';

@Component({
  selector: 'app-ctrl-edicion-mercancia',
  templateUrl: './ctrl-edicion-mercancia.component.html',
  styles: [
  ]
})
export class CtrlEdicionMercanciaComponent implements OnInit {
  @ViewChild('txtDescripcion')
  public txtDescripcion!: ElementRef<any>;
  @ViewChild('txtCantidad')
  public txtCantidad!: ElementRef<any>;
  @ViewChild('txtPeso')
  public txtPeso!: ElementRef<any>;

  @ViewChild('busquedaProductos')
  public busquedaProductos!: ElementRef<HTMLElement>;
  @ViewChild('busquedaUnidades')
  public busquedaUnidades!: ElementRef<HTMLElement>;

  saving: boolean = false;
  @Input()
  public tabIndex: number = 0;
  @Input()
  public item!: CartaPorteMercancia;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();
  searchConfiguration: SearchConfiguration | null = null;
  @Input()
  public colocarMargen: boolean = true;

  ngOnInit(): void {
    this.searchConfiguration = this.ms.GetSearchConfiguration("c_ClaveProdServ", '');
    if (this.item) {
      let tieneId = false;
      if (this.item?.Id >= 0) {
        tieneId = true;
      }
      if (tieneId) {
        this.myForm.reset(this.item);
        this.focus();
      } else {
        this.containerService.getEmptyEntity("CartaPorteMercancia").subscribe((elem) => {
          this.myForm.reset(elem);
          this.focus();
        });
      }
    } else {
      this.containerService.getEmptyEntity("CartaPorteMercancia").subscribe((elem) => {
        this.myForm.reset(elem);
        this.focus();
      });
    }
  }

  focus() {
    setTimeout(() => {
      const elem: any = this.txtDescripcion;
      elem.tagInput.nativeElement.focus();
    }, 150);
  }

  constructor(private fb: FormBuilder, private cpService: CartaPorteService, private ms: ModalService, private containerService: ContainerBaseService) {

  }

  get getStyle(): string {
    let t = "width: 540px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
    return t;
  }

  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  abrirBusquedaUnidades() {
    const b: any = this.busquedaUnidades;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["ClaveUnidad"].setValue(e.Clave);
        setTimeout(() => {
          const elem: any = this.txtPeso;
          elem.tagInput.nativeElement.focus();
        }, 150);
      }
    }, 'lg')
  }

  verificarAbrirUnidades() {
    if (!this.myForm.value.ClaveUnidad) {
      this.abrirBusquedaUnidades();
    }
  }

  abrirBusquedaClaves() {
    const b: any = this.busquedaProductos;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["BienesTransp"].setValue(e.Clave);
        this.myForm.controls["Descripcion"].setValue(e.Nombre);
        setTimeout(() => {
          const elem: any = this.txtCantidad;
          elem.tagInput.nativeElement.focus();
        }, 150);
      }
    }, 'lg')
  }

  get getColumns(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getFilter(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.filter;
    }
    return "";
  }


  public myForm: FormGroup = this.fb.group({
    Id: [0],
    ClaveArticuloInterno: [''],
    BienesTransp: [''],
    ClaveSTCC: [''],
    Descripcion: [''],
    Cantidad: [0],
    ClaveUnidad: [''],
    Unidad: [''],
    Dimensiones: [''],
    ColocarMaterialPeligroso: [false],
    MaterialPeligroso: [false],
    CveMaterialPeligroso: [''],
    Embalaje: [''],
    DescripEmbalaje: [''],
    SectorCOFEPRIS: [''],
    NombreIngredienteActivo: [''],
    NomQuimico: [''],
    DenominacionGenericaProd: [''],
    DenominacionDistintivaProd: [''],
    Fabricante: [''],
    FechaCaducidad: [null],
    LoteMedicamento: [''],
    FormaFarmaceutica: [''],
    CondicionesEspTransp: [''],
    RegistroSanitarioFolioAutorizacion: [''],
    PermisoImportacion: [''],
    FolioImpoVUCEM: [''],
    NumCAS: [''],
    RazonSocialEmpImp: [''],
    NumRegSanPlagCOFEPRIS: [''],
    DatosFabricante: [''],
    DatosFormulador: [''],
    DatosMaquilador: [''],
    UsoAutorizado: [''],
    PesoEnKG: [0],
    ValorMercancia: [0],
    Moneda: [''],
    FraccionArancelaria: [''],
    UUIDComercioExt: [''],
    Mercancias: [null],
  })

  accept() {

    if (!this.myForm.value.Descripcion) {
      Swal.fire({ text: "Indique una descripción.", icon: 'info', })
      return false;
    }
    if (!this.myForm.value.BienesTransp) {
      Swal.fire({ text: "Indique una Clave de Producto.", icon: 'info', })
      return false;
    }

    if (this.myForm.value.Cantidad <= 0) {
      Swal.fire({ text: "Indique una Cantidad.", icon: 'info', })
      return false;
    }

    if (!this.myForm.value.ClaveUnidad) {
      Swal.fire({ text: "Indique una Clave de Unidad.", icon: 'info', })
      return false;
    }

    if (this.myForm.value.PesoEnKG <= 0) {
      Swal.fire({ text: "Indique el Peso en KG.", icon: 'info', })
      return false;
    }
    this.onCloseEdition.emit(this.myForm.value)
    this.ms.closeModal(this.myForm.value);
    return;
  }

  closeModal() {
    this.onCloseEdition.emit(null)
    this.ms.closeModal(null);
  }

}
