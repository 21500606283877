import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { JwtInterceptor } from './auth/interceptors/jwt.interceptor';
import { HomeModule } from './home/home.module';
import { AuthModule } from './auth/auth.module';
import { ConfiguracionModule } from './configuracion/configuracion.module';
import { ComponentUiModule } from './component-ui/component-ui.module';
import { MatMenuModule } from '@angular/material/menu';
import { VentasModule } from './ventas/ventas.module';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { InventarioModule } from './Inventarios/inventario.module';
import { SistemaModule } from './sistema/sistema.module';
import { CuentasPorCobrarModule } from './cuentas-por-cobrar/cuentasporcobrar.module';
import { WebTicketModule } from './webticket/webticket.module';
import { EfiscoModule } from './efisco/efisco.module';
import { FinanzasModule } from './Finanzas/fianzas.module';
import { CuentasPorPagarModule } from './cuentas-por-pagar/cuentasporpagar.module';
import { ComprasModule } from './compras/compras.module';
import { ContabilidadModule } from './contabilidad/contabilidad.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ManufacturaModule } from './Manufactura/manufactura.module';
import { ResguardosModule } from './resguardos/resguardos.module';
import { CrmModule } from './crm/crm.module';
import { MobileModule } from './mobile/mobile.module';
import { DirectivesModule } from './directives/directives.module';


import localMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { TokensModule } from './tokens/tokens.module';
import { ServiceWorkerModule } from '@angular/service-worker';
registerLocaleData(localMx, 'es-MX')

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    HomeModule,
    ConfiguracionModule,
    VentasModule,
    MobileModule,
    HttpClientModule,
    ComponentUiModule,
    MatMenuModule,
    InventarioModule,
    SistemaModule,
    CuentasPorCobrarModule,
    DirectivesModule,
    WebTicketModule,
    EfiscoModule,
    FinanzasModule,
    CuentasPorPagarModule,
    ComprasModule,
    ContabilidadModule,
    ManufacturaModule,
    ResguardosModule,
    CrmModule,
    TokensModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    NgbModalConfig,
    NgbModal,
    Title, {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover'
      }
    }, {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var title = 'Complit | ' + ((this.route.snapshot.firstChild?.firstChild?.firstChild?.data['title']) || "Inicio");
        this.titleService.setTitle(title);
      }
    });
  }
}
