import { Component, ElementRef, OnInit, QueryList, ViewChild, inject } from '@angular/core';
import Swal from 'sweetalert2';
import { MaximoMinimo } from '../../interfaces/maximo.interface';
import { environment } from 'src/environments/environment';
import { GuiCellEdit, GuiColumnAlign } from '@generic-ui/ngx-grid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Result } from 'src/app/auth/interfaces/result.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-maximos-minimos-page',
  templateUrl: './maximos-minimos-page.component.html',
  styles: [
  ]
})
export class MaximosMinimosPageComponent implements OnInit {

  @ViewChild('ctrlBusqueda')
  public ctrlBusqueda!: QueryList<ElementRef>;

  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  saving: boolean = false;
  loading: boolean = false;
  source: Array<MaximoMinimo> = [];
  file: any;
  private eventsService = inject(EventsService);
  private modalService = inject(ModalService);
  private readonly baseUrl: string = environment.baseUrlApi;

  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;

  @ViewChild('txtClase')
  public txtClase!: ElementRef<any>;

  public GuiColumnAlign = GuiColumnAlign;
  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: any, index: number) => {
      this.indexEditing = index;
      return true;
    },

    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }

  sourceEdited(e: any) {

  }

  setProperty(index: number, value: number, prop: string) {
    this.source.map((item: any, i) => {
      if (i >= index) {
        item[prop] = value;
      }
    })
    this.source = [...this.source];
  }

  cellEditEntered(e: any) {
    setTimeout(() => {
      this.setColumnEditor();
    }, 100);
  }

  setColumnEditor() {
    const elems: any = document.getElementById("divCargaPrecios")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement, indexRow: number) => {
      renglon.childNodes.forEach((nodeRow: any) => {
        let colNum = -1;
        nodeRow.childNodes.forEach((nodeColumna: any) => {
          if (nodeColumna.childNodes && nodeColumna.className) {
            colNum++;
            nodeColumna.childNodes.forEach((nodoCelda: any) => {
              if (nodoCelda.className == "gui-cell-edit-mode ng-star-inserted") {
                this.columnEditing = colNum;
                const elem: any = nodeColumna.childNodes[1].childNodes[0];
                elem.addEventListener("keydown", (e: any) => {
                  this.navigateColumns = (e.keyCode == 13);
                  if (e.ctrlKey && e.keyCode == 74) {
                    e.preventDefault();
                    if (this.columnEditing == 4) {
                      this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'maximo');
                    }
                    if (this.columnEditing == 5) {
                      this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'minimo');
                    }
                    if (this.columnEditing == 6) {
                      this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'puntoReorden');
                    }
                  }

                });
              }
            });
          }
        });
      })
    });
  }

  cellEditSubmitted() {
    if (this.navigateColumns) {
      switch (this.columnEditing) {
        case 3: this.initEditor(this.indexEditing, 6); break;
        case 6: this.initEditor(this.indexEditing, 7); break;
        case 7: this.indexEditing++; this.initEditor(this.indexEditing, 1); break;
      }
      this.navigateColumns = false;
    }
  }

  initEditor(row: number, col: number) {
    const elem: any = this.getElemEditor(row, col);
    if (elem) {
      setTimeout(() => {
        elem.firstElementChild?.click();
      }, 100);
    }
  }

  getElemEditor(row: number, col: number) {

    let elem: any = null;
    const elems: any = document.getElementById("divCargaPrecios")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement) => {
      renglon.childNodes.forEach((nodeRow: any, indexRow: number) => {
        if (indexRow == row && !elem) {
          let colNum = -1;
          nodeRow.childNodes.forEach((nodeColumna: any) => {
            if (nodeColumna.childNodes && nodeColumna.className && !elem) {
              colNum++;
              if (col == colNum) {
                elem = nodeColumna;
              }
            }
          });
        }
      })
    });
    return elem;
  }

  public myForm: FormGroup = this.fb.group({
    Clasificacion: [''],
    Tipo: [''],
    Codigos: [''],
    ExcluirCodigos: [''],
  });

  constructor(private fServices: FacturacionService,
    private fb: FormBuilder,
    private fus: FileUploadService,
    private http: HttpClient,
    private uService: UtilsService) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtClase;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onSelectedItem(ent: any, type: string) {
    this.myForm.controls[type].setValue(ent);
  }

  searchFile() {
    this.txtFile.nativeElement.click();
  }

  guardar() {
    if (this.source.length == 0) {
      Swal.fire({ text: `No hay información para guardar, verifique.`, icon: 'info', })
      return;
    }
    this.saving = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarListaPrecio`, this.source).subscribe((result) => {
      this.saving = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        Swal.fire({ text: `Se actualizó la información.`, icon: 'success', })
      } else {
        Swal.fire({ text: result.message, icon: 'error', })
      }
    });
  }

  downloadFileExample() {
    this.saving = true;
    return this.http.get(`${this.baseUrl}/Ventas/DecargarEjemploMaximos`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.saving = false;
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          Swal.fire({
            text: 'Tienes bloqueadas las descargas de esta página, habilitalas.',
            icon: 'info',
          })
        }
      }

    })
  }


  searchPrices() {
    //Ventas/ConsultarListas
    this.saving = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const ent = this.myForm.value;
    const params = new HttpParams().set("clasificacion", ent.Clasificacion)
      .set("tipo", ent.Tipo)
      .set("codigos", ent.Codigos)
      .set("excluirCodigos", ent.ExcluirCodigos)
    this.http.get<MaximoMinimo[]>(`${this.baseUrl}/Inventarios/ConsultarMaximosMinimos`, { params }).subscribe((lista) => {
      this.saving = false;
      this.source = lista;
      this.eventsService.publish('home:isLoading', { isLoading: false });
    })
  }

  exportData() {
    this.uService.exportarCSV("MaximosMinimosReorden", this.convertToCSV());
  }

  convertToCSV(): string {
    const array = this.source;
    let str = 'Empresa, Sucursal, Producto, Maximo, Minimo, PuntoReorden';
    str += "\r\n";
    this.source.forEach((item: any, index) => {
      str += `${item.empresa.clave},${item.sucursal.clave},${item.producto.clave},${item.maximo},${item.minimo},${item.puntoReorden}\r\n`;
    });
    return str;
  }

  sendImportData() {
    this.fServices.iniciarMigracionMaximosMinimos(this.file.name).subscribe((result) => {
      this.saving = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        Swal.fire({ text: `Se actualizó la información.`, icon: 'success', })
      } else {
        Swal.fire({ text: result.message, icon: 'error', })
      }
    })
  }

  onchange(event: any) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null;
    if (this.file) {
      this.saving = true;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fus.upload(this.file, 0, 'Utilerias/UploadFile', () => {
        this.sendImportData();
      });
    }
  }


  columns: any;
  filter: any;
  entityName: string = "";
  propertys: string = "";
  clickBuscar(entidad: string, controlName: string) {
    let searchConfiguration = this.modalService.GetSearchConfiguration(entidad, "");



    this.entityName = entidad;
    this.columns = this.createColumns(searchConfiguration.columns)
    this.propertys = this.createProperties(searchConfiguration);
    this.filter = searchConfiguration.filter;
    this.modalService.openModal(this.ctrlBusqueda, (e: any) => {
      if (e) {
        let value = this.createValue(e.Clave, controlName)
        this.myForm.controls[controlName].setValue(value);
      }
    }, "sm");
  }

  createColumns(colums: any) {
    colums.map((x: any) => {
      if (x.prop === "Nombre") {
        x.width = 195;
      }
      return x
    });
    return colums;
  }

  createProperties(config: any) {
    if (config) {
      let props = "";
      config.propertys.forEach((prop: any) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  createValue(value: string, controlName: string) {
    let currentValue = String(this.myForm.controls[controlName].value);
    if (currentValue.length > 0) {
      currentValue += ",";
    }
    currentValue += String(value);
    return currentValue;
  }
}
