import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LevelTextBoxComponent } from 'src/app/component-ui/components/level-text-box/level-text-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { PolizaAutomaticaDetalle, TipoConceptoPoliza } from '../../interfaces/polizaautomatica.interface';
import { ContabilidadService } from '../../services/contabilidad.service';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { c_Banco } from 'src/app/Finanzas/interfaces/c_banco.interface';
import { ClaseVenta } from 'src/app/ventas/interfaces/claseventa.interface';
import { ClaseCompra } from 'src/app/compras/interfaces/clasecompra.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import Swal from 'sweetalert2';
import { CuentaContable } from '../../interfaces/cuentacontable.interface';

@Component({
  selector: 'app-ctrl-edicion-poliza-automatica-page',
  templateUrl: './ctrl-edicion-poliza-automatica-page.component.html',
  styles: [
  ]
})
export class CtrlEdicionPolizaAutomaticaPageComponent implements OnInit {

  searching: boolean = false;
  @ViewChild('txtCuenta')
  public txtCuenta!: ElementRef<TextBoxComponent>;
  // @ViewChild('txtConcepto')
  // public txtConcepto!: ElementRef<TextBoxComponent>;

  @Input()
  public idEmpresa: number = 0;
  @Input()
  public tabIndex: number = 0;
  @Input()
  public item!: PolizaAutomaticaDetalle;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();

  public myForm: FormGroup = this.fb.group({
    Orden: [0],
    Sucursal: [{} as Sucursal],
    Banco: [{} as c_Banco],
    ClaseVenta: [{} as ClaseVenta],
    ClaseCompra: [{} as ClaseCompra],
    CuentaContable: [''],
    CentroCosto: [''],
    TipoConceptoCargo: [{} as TipoConceptoPoliza],
    TipoConceptoAbono: [{} as TipoConceptoPoliza],
    EsComentario: [false],
    InHabilitada: [false],
    ColocarEnNegativo: [false],
    Detalle: [false],
    ConceptoDetalle: [''],
    Referencia: [''],
  })


  constructor(private contaService: ContabilidadService, private mService: ModalService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtCuenta;
      txt.tagInput.nativeElement.focus()
      this.myForm.reset(this.item);
    }, 150);
  }

  selectedCombo(ent: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(ent);
  }

  accept() {

    if (!this.myForm.value.EsComentario) {
      if (!this.myForm.value.CuentaContable) {
        Swal.fire({ text: 'Indique la Cuenta Contable.', icon: 'info', })
        return false;
      }

      if (!this.myForm.value.TipoConceptoAbono && !this.myForm.value.TipoConceptoCargo) {
        Swal.fire({ text: 'Indique el Tipo de Concepto de Cargo o Abono.', icon: 'info', })
        return false;
      }

      if (this.myForm.value.TipoConceptoAbono && this.myForm.value.TipoConceptoCargo) {
        Swal.fire({ text: 'Solo indique un Tipo de Concepto de Cargo o uno de Abono.', icon: 'info', })
        return false;
      }
    }

    if (this.myForm.value.EsComentario && !this.myForm.value.ConceptoDetalle) {
      Swal.fire({ text: 'Indique el comentario en el campo concepto.', icon: 'info', })
      return false;
    }

    this.onCloseEdition.emit(this.myForm.value)

    return;
  }

  closeModal() {
    this.onCloseEdition.emit(null)
  }

}
