<app-container-base [activeButtons]="activeButtons" (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="PlantillaUbicacion" title="Plantillas" icon="fa fa-building" subTitle="Plantillas para Carta Porte">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Plantilla'" entityName="PlantillaUbicacion" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

        </div>
      </app-panel-base>

      <app-complemento-carta-porte-page (onAccept)="onAccept($event)" [EsPlantilla]="true" [setCartaPorteCatalogo]="getCartaPorte" />

    </form>
  </div>
</app-container-base>
