<div class="card text-center border-0" cdkDrag style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Alta/Edición de Dirección de entrega
  </div>
  <app-container-base subTitle="" [containerInnerHeight]="420" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
    [hasCounter]="false">
    <div class="screen-content">
      <form [formGroup]="myForm" autocomplete="off">
        <app-panel-base [camelCase]="false" title="Datos de la Dirección de Entrega">
          <div class="panel-content">

            <components-ui-text-box
              helpLine="Nombre de la empresa según la constancia de situación fiscal, 500 caracteres." [maxLength]="500"
              [upperCase]="true" [required]="true" [label]="'Nombre'" formControlName="Nombre" [tabIndex]="200"
              [widthTextColumns]="8" [widthLabelColumns]="2" #txtNombre />
            <components-ui-text-box
              helpLine="RFC de la empresa, tal cual está en la constancia de situación fiscal, 12-13 caracteres"
              [tabIndex]="201" [maxLength]="13" [upperCase]="true" [required]="true" [label]="'RFC'"
              [dataType]="EDT.RFC" formControlName="Rfc" [widthTextColumns]="4" [widthLabelColumns]="2" />

            <component-ui-address formControlName="Direccion" [tabIndex]="202" [CodigoPostalRequerido]="false" />

          </div>
        </app-panel-base>
        <div style="text-align: right; margin-top: 5px;">
          <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar
          </div>
          <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
        </div>
      </form>
    </div>
  </app-container-base>
</div>
