<app-container-base subTitle="Antigüedad de saldos de Cuentas por Pagar" (onClickBarButton)="clickBoton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" icon=" fas fa-hand-holding-dollar">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales"  [itemsMenuDropDown]="menuItems" (onClicMenu)="clicMenu($event)">
        <div class="panel-content">
          <div class="row">

              <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />

          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha al' formControlName="Fecha1" [tabIndex]="2" [widthLabelColumns]="6" [widthTextColumns]="4" (onChangeValue)="onChangeValue($event)" [required]="true"  ></components-ui-date-box>
            </div>
          </div>
          <div>
            <div class="row">
              <!-- <div class="col-md-4">
                <component-ui-check-box label='Solo clientes con crédito excedido' formControlName="Opc4" [tabIndex]="4" [widthLabelColumns]="7" [widthTextColumns]="1" [tabIndex]="3" />
              </div> -->
              <div class="col-md-4">
                <component-ui-check-box label='Resumen de vencimientos' formControlName="Opc5" [tabIndex]="4" [widthLabelColumns]="6" [widthTextColumns]="2"  />
              </div>
              <div class="col-md-3">
                <component-ui-check-box label='En moneda nacional' formControlName="Opc8" [tabIndex]="4"
                  [widthLabelColumns]="7" [widthTextColumns]="1" [tabIndex]="3" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <component-ui-check-box label='Solo negativos' formControlName="Opc6" [tabIndex]="5" [widthLabelColumns]="6" [widthTextColumns]="1"  />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-number-box [tabIndex]="6" [maxLength]="4" label='Días vencidos del' [widthLabelColumns]="6" [widthTextColumns]="2" formControlName="Num1"  />
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <components-ui-number-box [tabIndex]="7" [maxLength]="4" label='al' [widthLabelColumns]="6" [widthTextColumns]="6" formControlName="Num2" />
                </div>
                <div class="col-md-6">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha vencimiento' formControlName="Fecha2"  [tabIndex]="8"  [widthLabelColumns]="6" [widthTextColumns]="4" ></components-ui-date-box>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-3">
                  <component-ui-check-box label='Mayor' formControlName="Opc1" [tabIndex]="9" [widthLabelColumns]="6" [widthTextColumns]="2" (onClick)="onclickMayor($event,'opc1')" />
                </div>
                <div class="col-md-3">
                  <component-ui-check-box label='Igual' formControlName="Opc2" [tabIndex]="10" [widthLabelColumns]="6" [widthTextColumns]="2" (onClick)="onclickMayor($event,'opc2')" />
                </div>
                <div class="col-md-3">
                  <component-ui-check-box label='Menor' formControlName="Opc3" [tabIndex]="11" [widthLabelColumns]="6" [widthTextColumns]="2" (onClick)="onclickMayor($event,'opc3')" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="12"   (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"  ></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
    <ng-template #ctrlRangos let-modal let-c="close">
      <ctrl-edicion-rangos-antiguedad-saldos-cxp></ctrl-edicion-rangos-antiguedad-saldos-cxp>
    </ng-template>
  </div>
</app-container-base>
