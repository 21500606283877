import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslationFeatureModule } from '@generic-ui/ngx-grid/feature/l10n/translation.feature-module';
import { BaseDatosService } from '../../services/base-datos.service';
import { ConexionBaseDatos, Result } from '../../interfaces';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'actualizar-base-datos',
  templateUrl: './actualizar-base-datos.component.html',
  styleUrls: ['./actualizar-base-datos.component.scss']
})
export class ActualizarBaseDatosComponent {
  private basedatosService = inject(BaseDatosService);
  @Input()
  ambiente!: ConexionBaseDatos;
  @Input()
  puedeActualizar: boolean = true;
  @Input()
  visible: boolean = false;

  @Output()
  public ambienteActualizado: EventEmitter<boolean> = new EventEmitter();

  actualizando: boolean = false;
  actualizado: boolean = false;
  mensaje: string = "";

  actualizar() {
    if (this.puedeActualizar) {
      this.actualizando = true;
      this.basedatosService.actualizarBD(this.ambiente).subscribe((result: Result) => {
        console.log(result);
        this.actualizando = false;
        this.actualizado = true;
        this.mensaje = result.message;
        setTimeout(() => {
          this.visible = false;
          this.ambienteActualizado.emit(true);
          this.reset();
        }, 3000);
      }, (err: Error) => {
        this.actualizado = true;
        this.mensaje = err.message;
        this.reset();
      });
    } else {
      this.ambienteActualizado.emit(false);
    }
  }

  reset() {
    this.actualizando = false;
    this.actualizado = false;
    this.mensaje = "";
  }
}
