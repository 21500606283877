import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { InventarioService } from '../../services/inventario.service';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { RecepcionTraspaso } from '../../interfaces/traspaso.interface';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import Swal from 'sweetalert2';
import { EventsService } from 'src/app/service/events.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { retry } from 'rxjs';

@Component({
  selector: 'app-recepcion-traspaso-page',
  templateUrl: './recepcion-traspaso-page.component.html',
  styles: [
  ]
})
export class RecepcionTraspasoPageComponent implements OnInit {

  private newEntityObj = {
    SucursalEnvia: [{} as Sucursal, [Validators.required]],
    SucursalRecibe: [{} as Sucursal, [Validators.required]],
    Todos: [false]
  };
  public filtroTraspasos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de Traspasos',
      NombreExcel: 'Reporte de Traspasos.xlsx',
      FilterOptions: [
        { Campo: 'tras.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };
  private eventsService = inject(EventsService);
  private reportsService = inject(ReportsService)
  public GuiColumnAlign = GuiColumnAlign;
  public lista: RecepcionTraspaso[] = [];
  procesando: boolean = false;
  //public lista: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  public myForm: FormGroup = this.fb.group(this.newEntityObj);


  @ViewChild('cboEnvia')
  public cboEnvia!: ElementRef<ComboBoxComponent>;

  constructor(private fb: FormBuilder, private is: InventarioService) {


  }

  clicTodos(sel: boolean) {

    this.lista.map((i) => {
      i.seleccionado = sel;
    });

  }

  selected(ent: ComboBoxEntity, t: string) {
    this.myForm.controls[t].setValue(ent);
  }

  focus() {
    setTimeout(() => {
      const t: any = this.cboEnvia;
      t.tagInput.nativeElement.focus();
    }, 500);
  }

  print(item: RecepcionTraspaso) {

    if (this.myForm.value.Id == 0) {
      Swal.fire({
        text: "Es necesario que primero guarde el Traspaso antes de poder imprimirlo, por favor verifique.",
        icon: 'warning',
      });
      return;
    }


    let reportHeader: ReportHeader = {
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: `TRASPASO ${item.serieFolio}`,
      Dato1: '',
      Opc1: true,
      Opc2: true
    }
    let filtro: FilterOptions | undefined = this.filtroTraspasos.FilterOptions?.find(p => p.Campo == 'tras.Id');
    if (filtro) {
      filtro!.Valor = String(item.idTraspaso);
    }
    this.filtroTraspasos.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroTraspasos, '/Inventarios/VerificadorDeTraspasos');

  }

  seleccionar(item: RecepcionTraspaso) {
    this.lista.map((i) => {
      if (i.idTraspaso == item.idTraspaso) {
        i.seleccionado = !i.seleccionado;
      }
      return i;
    });
  }

  buscar() {
    const ent = this.myForm.value;
    if (!ent.SucursalRecibe || ent.SucursalRecibe?.Id == 0) {
      Swal.fire({ text: 'Indique la Sucursal que recibe.', icon: 'info', })
      return;
    }
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.is.obtenerTraspasosPendientes(ent.SucursalRecibe.Id).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.lista = lista;
    })

  }

  recibir() {
    const sels = this.lista.filter(P => P.seleccionado).length;
    if (sels == 0) {
      Swal.fire({ text: 'Seleccione los Traspasos a recibir.', icon: 'info', })
      return;
    }

    Swal.fire({
      title: "¿Está seguro de hacer la recepción de los Traspasos seleccionados?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.procesar();
      }
    })
  }

  procesar() {
    const sels = this.lista.filter(P => P.seleccionado);
    this.procesando = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let ids = "";
    sels.forEach((item) => {
      ids += `${item.idTraspaso},`;
    })
    ids = ids.substring(0, ids.length - 1);
    this.is.procesarRecepcionTraspasos(ids).subscribe((result) => {
      this.procesando = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        Swal.fire({ text: "Se recibieron los traspasos", icon: 'success', });
        this.buscar();
      } else {
        Swal.fire({ text: result.message, icon: 'error', })
      }
    })
  }

  ngOnInit(): void {
    this.focus();
  }

}
