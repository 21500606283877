<!-- BEGIN panel -->
<div class="panel" [ngClass]="[ (this.expand) ? 'panel-expand': '', (this.reload) ? 'panel-loading': '', (this.panelClass) ? this.panelClass : '', (this.variant) ? 'panel-' + this.variant : 'panel-inverse' ]" *ngIf="!this.remove">
  <!-- BEGIN panel-heading -->
  <div class="panel-heading" [ngClass]="[ (this.headerClass) ? this.headerClass : '' ]">
    <h4 class="panel-title" *ngIf="this.title">{{ title }}</h4>
    <ng-content select="[header]"></ng-content>
    <div class="panel-heading-btn" *ngIf="!this.noButton">
      <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" (click)="panelExpand()"><i class="fa fa-expand"></i></a>
      <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" (click)="panelReload()"><i class="fa fa-redo"></i></a>
      <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" (click)="panelCollapse()"><i class="fa fa-minus"></i></a>
      <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" (click)="panelRemove()"><i class="fa fa-times"></i></a>
    </div>
  </div>
  <!-- END panel-heading -->

  <!-- BEGIN before-body -->
  <ng-content select="[beforeBody]"></ng-content>
  <!-- END before-body -->

  <!-- BEGIN panel-body -->
  <div class="panel-body" [ngClass]="[ (this.collapse) ? 'd-none': '', (this.bodyClass) ? this.bodyClass : '' ]" *ngIf="!this.noBody">
    <ng-content></ng-content>
    <div class="panel-loader" *ngIf="this.reload"><span class="spinner spinner-sm"></span></div>
  </div>
  <!-- END panel-body -->

  <!-- BEGIN no-body -->
  <ng-content select="[noBody]"></ng-content>
  <!-- END no-body -->

  <!-- BEGIN outside-body -->
  <ng-content select="[outsideBody]"></ng-content>
  <!-- END outside-body -->

  <!-- BEGIN panel-footer -->
  <div class="panel-footer" #panelFooter [hidden]="!showFooter" [ngClass]="[ (this.footerClass) ? this.footerClass : '' ]">
    <ng-content select="[footer]"></ng-content>
  </div>
  <!-- END panel-footer -->

  <!-- BEGIN after-footer -->
  <ng-content select="[afterFooter]"></ng-content>
  <!-- END after-footer -->
</div>
<!-- END panel -->
