import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'historico-paquetes.page',
  templateUrl: './historico-paquetes.page.component.html',
  styleUrls: ['./historico-paquetes.page.component.scss'],
})
export class HistoricoPaquetesPageComponent {

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }
  private fb = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  detalles: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Completos", Clave: 1, ClaveNombre: "01 Completos" },
    { Id: 2, Nombre: "Incompletos", Clave: 2, ClaveNombre: "02 Incompletos" },
    { Id: 3, Nombre: "Todos", Clave: 3, ClaveNombre: "03 Todos" },
  ];

  public frmHistoricoPaquetesLotes: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: 'REPORTE DE HISTÓRICO DE PAQUETES/LOTES',
    Num1: [this.detalles[2].Id],
  });

  detalleSeleccionado?: ComboBoxEntity;

  ngOnInit() {
    setTimeout(() => {
      this.detalleSeleccionado = this.detalles[2];
    }, 0);
  }

  selectedCombo(entity: any, tipo: string) {
    this.detalleSeleccionado = entity;
    this.frmHistoricoPaquetesLotes.get('Num1')?.setValue(this.detalleSeleccionado?.Id);
  }

  get selectDetalleItem(): ComboBoxEntity | null {
    return this.detalleSeleccionado!;
  }

  public filtrosHistoricoPaquetesLotes: ReportFilter =
    {
      ReportHeader: {
        NombreReporte: 'REPORTE DE EXISTENCIAS DE PAQUETES/LOTES'
      } as ReportHeader,
      TituloVisor: 'Existencias de Paquetes/Lotes',
      NombreExcel: 'ExistenciasPaqueteslotes.xlsx',
      FilterOptions: [
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", HelpLine: "Claves de Sucursales a consultar separadas por coma (1,2,3)." },
        { Campo: 'alm.Clave', Etiqueta: 'Almacen', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Almacen", HelpLine: "Claves de Almacenes a consultar separadas por coma (1,2,3)." },
        { Campo: 'paq.FacturaFechaProveedor', Etiqueta: 'Factura Proveedor', Tipo: TypeFilter.text, HelpLine: "Número de la factura del Proveedor que se quiere buscar (B-2665)." },
        { Campo: 'paq.Identificador', Etiqueta: 'N° Paquete', Tipo: TypeFilter.text, HelpLine: "Número del Paquete/Lote que se quiere buscar (20240427-2665-1-0)." },
      ]
    };

  clickBoton() {
    this.filtrosHistoricoPaquetesLotes.ReportHeader = this.frmHistoricoPaquetesLotes.value;
    this.reportsService.printReport(this.filtrosHistoricoPaquetesLotes, '/Inventarios/ReporteHistoricoPaquetesLotes');
  }

  changeselec(event: any) {
    this.filtrosHistoricoPaquetesLotes = event;
  }
}


