import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { UserLogged } from 'src/app/auth/interfaces';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { SerieReguardo } from '../../interfaces/serie.interface';
import { Almacen, ConceptoAlmacen, TipoMovimientoAlmacen } from 'src/app/home/interfaces/almacen.interface';

@Component({
  selector: 'app-series-resguardos-page',
  templateUrl: './series-resguardos-page.component.html',
  styles: [
  ]
})
export class SeriesResguardosPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];

  filter = ``
  info: UserLogged = {} as UserLogged;
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Serie: ['', [Validators.required]],
    Empresa: [{} as Empresa, [Validators.required]],
    Sucursal: [{} as Sucursal, [Validators.required]],
    Almacen: [{} as Almacen],
    ConceptoSalida: [{} as ConceptoAlmacen],
    TipoMovimientoAlmacen: [{} as TipoMovimientoAlmacen],
    Formato: [''],
    Copias: [''],
    TextoImprimir: [''],
    UsaFoliosPreImpresos: [false],
    UltimoFolioPreimpreso: [0],
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private utilsService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero} and Sucursal.Id = ${this.info.sucursal!.numero}`
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: SerieReguardo) {

    this.myForm.reset(entity);
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("SerieReguardo").subscribe((elem) => {
      elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }


  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  onItemSearched(ent: any): void {
    if (ent) {
      ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      this.filter = `Empresa.Id = ${this.info.empresa?.numero} and Sucursal.Id = ${this.info.sucursal?.numero}`
      this.myForm.reset(ent);
      this.focus('txtNombre')
    }
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.onItemSearched(ent);
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
}
