import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { FamiliaProducto } from '../../interfaces/familia.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { Result } from 'src/app/auth/interfaces';
import { FamiliaService } from '../../services/familia.service';
import { ReportsService } from 'src/app/service/reports.service';
import { EventsService } from 'src/app/service/events.service';

@Component({
  templateUrl: './familias.component.html',
  styleUrls: ['./familias.component.scss']
})
export class FamiliasComponent implements OnInit {

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;
  private eventsService = inject(EventsService);
  longClave: number = 0;
  info: any;
  file: any;
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  selectedTree: any = null;
  entityAfterSave: any = null;
  reload: boolean = false;
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    id: [0],
    clave: [0],
    nombre: [''],
    claveGeneral: [''],
    numeroNivel: [0],
    orden: [0],
    abreviacion: [''],
    icono: [''],
    empresaId: [0],
    claveNivel1: [0],
    claveNivel2: [0],
    claveNivel3: [0],
    claveNivel4: [0],
    claveNivel5: [0],
    porcentajeComisionNormal: [0],
    porcentajeComisionTonelada: [0],
    importeComisionTonelada: [0],
    padre: [{} as FamiliaProducto],
    isExpanded: [false],
    noMostrarPOS: [false],
    mostrarTablero: [false],
    textoUnidadMedidaTablero: [''],
    sonKilosTablero: [false],
    hijos: [{} as FamiliaProducto[]]
  });

  private reportsService = inject(ReportsService);
  constructor(private fus: FileUploadService,
    private http: HttpClient,
    private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private famService: FamiliaService,
    private utileService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
  }

  onLoad() {
    this.reload = false;
    this.entityAfterSave = null;
  }

  get getReload(): boolean {
    return this.reload;
  }

  get getEntityAfterSave(): FamiliaProducto {
    return this.entityAfterSave;
  }


  actualizaFamilias() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.famService.actualizarFamilias().subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        alert("Termino de actualizar")
      } else {
        alert("Error al actualizar")
      }
    });
  }

  addSubFamily() {
    let level = this.selectedTree.numeroNivel + 1;
    let idPadre = this.selectedTree.id;
    this.famService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.famService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("FamiliaProducto").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = level;
          elem.padre = { id: idPadre };
          this.myForm.reset(elem);
          setTimeout(() => {
            this.focus("txtNombre")
          }, 150);
        })
      })
    });
  }


  addFamily() {

    let level = 1;
    let idPadre = 0;
    if (this.selectedTree) { level = this.selectedTree.numeroNivel; }

    this.famService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.famService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("FamiliaProducto").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = 1;
          this.myForm.reset(elem);

          this.focus("txtNombre")

        })
      })
    })
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 250);
  }

  download() {
    return this.http.get(`${this.baseUrl}/Inventarios/DescargarFamilias`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          Swal.fire({
            text: 'Tienes bloqueadas las descargas de esta página, habilitalas.',
            icon: 'info',
          })
        }
      }

    })
  }

  get showAddFamily(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.padre) {
        return false;
      }
    }
    return true;
  }

  get showAddSubFam(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.numeroNivel <= 2) {
        return true;
      }
    }
    return false;
  }

  onDelete() {
    this.selectedTree = null;
    this.entityAfterSave = null;
    this.reload = true;
  }

  onchange(event: any) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null;
    if (this.file) {
      this.saving = true;
      this.fus.upload(this.file, 0, 'Utilerias/UploadFile', () => {
        this.sendImportData();
      });
    }
  }

  searchFile() {
    this.txtFile.nativeElement.click();
  }

  sendImportData() {
    const params = new HttpParams().set("fileName", this.file.name);
    this.http.get<Result>(`${this.baseUrl}/Inventarios/IniciarCargaFamilias`, { params }).subscribe((result) => {
      this.saving = false;
      if (result.success) {
        Swal.fire({ text: `Se actualizaron las familias.`, icon: 'success', })
      } else {
        Swal.fire({ text: result.message, icon: 'info', })
      }
    })
  }

  onSelectedItem(item: FamiliaProducto) {
    this.selectedTree = { ...item };
    this.myForm.reset(item);
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 150);
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    param.callback(true);
  }

  onAfterSave(entity: FamiliaProducto) {
    this.myForm.reset(entity);
    this.selectedTree = null;
    this.entityAfterSave = null;
    this.reload = true;
  }


  imprimir(event: any) {
    if (event === "print") {
      this.reportsService.printReportWithoutFilters('/Inventarios/ReporteFamilias', "Familias");
    }
  }

}
