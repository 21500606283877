import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-inventario-page',
  templateUrl: './verificador-inventario-page.component.html',
})
export class VerificadorInventarioPageComponent {

  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);
  private _utils = inject(UtilsService);

  menuId: string = "verificadormovinventario";



  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public filtrosKardex: ReportFilter =
    {
      menuId: this.menuId,
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Kardex'
      } as ReportHeader,
      OrdenadoLista: [{ Nombre: "Ninguno", Orden: "" },{ Nombre: "Cuenta", Orden: "fam.Clave" }, { Nombre: "Empresa-Sucursal", Orden: "clasif.Clave" }],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Kardex',
      TituloVisor: 'Verificador de Movs. Inventario',
      NombreExcel: 'Verificador de Movs. Inventario.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'alm.Clave', Etiqueta: 'Almacén', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Almacen" },
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'conc.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoAlmacen" },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily },
        { Campo: 'inv.Folio', Etiqueta: 'Folio', Tipo: TypeFilter.number},
      ]
    };

  public frmReporte: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: "",
    Orden: []
  });


  ngOnInit() {
    this.frmReporte.get('NombreReporte')?.setValue('VERIFICADOR DE MOVS. DE INVENTARIO ' + this.rangoFechas);
    this.focus();
  }


  onChangeFecha(value: any) {
    this.frmReporte.get('NombreReporte')?.setValue('VERIFICADOR DE MOVS. DE INVENTARIO ' + this.rangoFechas);
  }


  focus() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

  get rangoFechas(): string {
    return this._utils.getEtiquetaRangoPorFechas(this.frmReporte.value.Fecha1, this.frmReporte.value.Fecha2, 2).toUpperCase();
  }

  changeselec(event: any) {
    this.filtrosKardex = event;
  }

  imprimir(event: any) {
    this.filtrosKardex.ReportHeader = this.frmReporte.value;
    this.reportsService.printReport(this.filtrosKardex, '/Inventarios/VerificadorMovimientos');
  }


}
