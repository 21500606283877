import { Component, OnInit, inject } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {


  private eventsService = inject(EventsService);
  isLoading: boolean = false;

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.eventsService.subscribe(('home:isLoading'), (data) => {
      this.isLoading = data.isLoading
    });
  }
}
