<app-container-base [activeButtons]="activeButtons" [hasCounter]="false" (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="PredefinidoProveedor" title="Predefinidos de Proveedores" icon="fa fa-angles-down"
  subTitle="Configure los predefinidos de Proveedores, para guardar al descargar CFDIS recibidos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [enabled]="false" [label]="'Predefinido'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="PredefinidoProveedor" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
        </div>
      </app-panel-base>
      <app-panel-base title="Predefinidos">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [label]="'Condición de pago'" formControlName="CondicionDePago"
            [tabIndex]="3" [zeroMask]="2" entityName="CondicionPagoProveedor" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'CondicionDePago')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Forma de pago'" formControlName="FormaDePago"
            [tabIndex]="4" [zeroMask]="2" entityName="c_FormaPago" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'FormaDePago')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Moneda'" formControlName="Moneda" [tabIndex]="5"
            [zeroMask]="2" entityName="Moneda" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectCombo($event, 'Moneda')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'ClaseCompra'" formControlName="ClaseCompra" [tabIndex]="6"
            [zeroMask]="2" entityName="ClaseCompra" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'ClaseCompra')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Clase'" formControlName="Clase" [tabIndex]="7"
            [zeroMask]="2" entityName="ClaseProveedor" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'Clase')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Clasificacion'" formControlName="Clasificacion"
            [tabIndex]="8" [zeroMask]="2" entityName="ClasificacionProveedor" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'Clasificacion')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Tipo'" formControlName="Tipo" [tabIndex]="9"
            [zeroMask]="2" entityName="TipoProveedor" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'Tipo')" [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
