<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="TipoImpuesto"
  title="Tipos de Impuestos" icon="fa fa-note-sticky" subTitle="Administra la configuración de los Tipos de Impuestos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Tipo Impuesto'"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="TipoImpuesto" />
          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
          <components-ui-text-box [tabIndex]="3" [label]="'Impresión'" formControlName="Imprimir" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" />
        </div>
      </app-panel-base>
      <app-panel-base title="Configuración">
        <div class="panel-content row">
          <component-ui-combo-box [required]="true" [label]="'Factor'" formControlName="Factor" [tabIndex]="4"
            [zeroMask]="2" entityName="c_TipoFactor" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedFactor($event)" [listProperty]="'ClaveNombre'" />
          <component-ui-combo-box [required]="true" [label]="'Tipo'" formControlName="Tipo" [tabIndex]="5"
            [zeroMask]="2" entityName="c_Impuesto" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedTipo($event)" [listProperty]="'ClaveNombre'" />
          <component-ui-combo-box [required]="true" [label]="'Objeto Imp'" formControlName="ObjetoImpuesto"
            [tabIndex]="6" [zeroMask]="2" [widthList]="400" entityName="c_ObjetoImp" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedObjeto($event)" [listProperty]="'ClaveNombre'" />
          <components-ui-number-box [maxLength]="12" [label]="'Tasa'" formControlName="TasaCuota" [tabIndex]="7"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <component-ui-check-box [tabIndex]="8" [widthLabelColumns]="2" formControlName="Traslada"
            [label]="'¿Tasladado?'" />
          <component-ui-check-box [tabIndex]="9" [widthLabelColumns]="2" formControlName="Retiene"
            [label]="'¿Retenido?'" />
            <component-ui-check-box [tabIndex]="10" [widthLabelColumns]="2" formControlName="EsImpuestoLocal"
            [label]="'¿Es impuesto local?'" />
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
