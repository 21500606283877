<app-container-base subTitle="Balanza Mensual" (onClickBarButton)="clickBoton($event)" [activeButtons]="activeButtons"
  icon=" fas fa-scale-balanced" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <ng-template #loader>
            <screen-loading />
          </ng-template>
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
                [widthLabelColumns]="2" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Num1')" [selectManualItem]="getMes1"
                [setItemsList]="listaMeses" [listProperty]="'Nombre'" label="Del mes" [tabIndex]="2"
                [inputProperty]="'Nombre'" [widthLabelColumns]="6" [widthTextColumns]="4" #comboMes1  [enabled]="!esBalanzaCierre" />
            </div>
            <div class="col-md-2">
              <component-ui-combo-box label='Año' [tabIndex]="3" [zeroMask]="2"
                [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="6"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Num2')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" #comboAnio />
            </div>
            <div class="col-md-3">
              <component-ui-check-box label='Balanza de cierre' formControlName="Opc1"
              [tabIndex]="4" [widthLabelColumns]="6" [widthTextColumns]="6"  (onClick)="onClickCierre($event)" />
            </div>

            <div class="row">
              <div class="col-md-6">
                <component-ui-combo-box label='Nivel a enviar' [tabIndex]="4" [zeroMask]="2"
                  [selectManualItem]="getNivelaenviar" [setItemsList]="this.listaNivelAEnviar" [widthTextColumns]="6"
                  [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Num3')" [inputProperty]="'Nombre'"
                  [listProperty]="'Nombre'" #comboAnio />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <component-ui-combo-box label='Tipo de Envío' [tabIndex]="5" [zeroMask]="2"
                  [selectManualItem]="getTipoEnvio" [setItemsList]="this.listaTipoEnvio" [widthTextColumns]="6"
                  [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Num4')" [inputProperty]="'Nombre'"
                  [listProperty]="'Nombre'" #comboAnio />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <components-ui-date-box label='Fecha Mod. Balance' formControlName="Fecha1" [tabIndex]="6"
                  [widthLabelColumns]="4" [widthTextColumns]="3" [required]="true"></components-ui-date-box>
              </div>
              <div class="col-md-6">
                <div class="ms-auto"><a (click)="descargarxml()" class="btn btn-primary px-4"><i
                      class="fas fa-download fa-lg me-2 ms-n2 text-success-900"></i>Descargar archivo XML</a></div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <!-- <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="6"
            (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
        </div>
      </app-panel-base> -->
    </form>
  </div>
</app-container-base>
