<div class="pos pos-with-menu pos-with-sidebar" id="pos" style="top: 0px;" [ngClass]="{
    'pos-sidebar-mobile-toggled': posMobileSidebarToggled
  }">
  <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
  <!-- BEGIN pos-menu -->
  <div class="pos-menu" style="top:45px;background-color: #2d353c; margin-left: 15px;padding-left:0px;bottom: 27px;">
    <div class="logo" style="color:#ffffff">
      <a routerLink="/">
        <div class="logo-img"><i class="fa fa-barcode"></i></div>
        <div class="logo-text">COMPLIT POS</div>
      </a>
    </div>

    <div class="nav-container">
      <ng-scrollbar style="height:80vh;">
        <ul class="nav nav-tabs bg-transparent">
          <a (click)="setIsSelected(item.nombre, item.id)" *ngFor="let item of  familias" style="max-width: 146px;" class="btn btn-lg btn-primary m-1 d-block">
            <span class="d-flex align-items-center text-left">
              <i class="fa fa-fw fa-{{item.icono}} me-1 text-black"></i>
              <span>
                <span class="d-block"><b>{{item.nombre | uppercase}}</b></span>
              </span>
            </span>
          </a>
          <!-- <li *ngFor="let item of  familias" class="nav-item">
            <a (click)="setIsSelected(item.nombre, item.id)"
              class="nav-link {{selectedButton == item.nombre ? 'active':''}}" href="javascript:;" data-filter="all">
              <div class="nav-icon"><i class="fa fa-fw fa-{{item.icono}}"></i></div>
              <div class="nav-text">{{item.nombre}}</div>
            </a>
          </li> -->
        </ul>
      </ng-scrollbar>
    </div>
  </div>
  <!-- END pos-menu -->

  <!-- BEGIN pos-content -->

  <div class="pos-content d-flex bg-light"
    style="flex-direction: column;margin-right:-19px;border: 1px solid #d2d2d2;margin-left: 11px;margin-top: 4px;padding-bottom: 18px;">
    <ng-scrollbar style="height: 80vh; margin-bottom: 35px;">
      <div class="pos-header" style="position: sticky;">
        <div class="logo">
          <a>
            <div class="logo-img"><i class="fa fa-ticket-simple fs-2"></i></div>
            <div class="logo-text">Transacción: {{myForm.value.SerieString}}-{{myForm.value.Folio}}</div>
          </a>
        </div>
        <div class="time" id="time">[{{myForm.value.Caja.Nombre}}] {{myForm.value.Cliente.Clave}} -
          {{myForm.value.Cliente.Nombre}}</div>
        <div class="nav" style="cursor:pointer;">
          <div class="nav-item">
            <a class="nav-link">
              <i class="fa fa-chalkboard-user nav-icon"></i>
            </a>
          </div>
          <!-- <div class="nav-item">
            <a routerLink="/pos/table-booking" class="nav-link">
              <i class="far fa-calendar-check nav-icon"></i>
            </a>
          </div>
          <div class="nav-item">
            <a routerLink="/pos/menu-stock" class="nav-link">
              <i class="fa fa-chart-pie nav-icon"></i>
            </a>
          </div> -->
        </div>
      </div>


      <div class="pos-content-container h-100">
        <div class="product-row">
          <div (click)="setIsSelected(item.nombre, item.id)" *ngFor="let item of subfamilias" class="product-container"
            data-type="meat">
            <a href="javascript:;" class="product" style="height: 130px;">
              <div class="widget widget-stats bg-blue" style="height: 130px;">
                <div class="stats-icon"><i class="fa fa-{{item.icono}}"></i></div>
                <div class="stats-info">
                  <h4>Sub familia</h4>
                  <p>{{item.nombre | uppercase}}</p>
                </div>
              </div>
            </a>
          </div>

          <div *ngFor="let item of listProducts" class="product-container" data-type="meat">
            <a href="javascript:;" class="product {{item.Baja ? 'not-available':''}}">
              <div data-bs-toggle="modal" data-bs-target="#modalPos" (click)="selectProduct(item)"
                *ngIf="item.ImagenBase64" class="img" style="background-image: url('{{item.ImagenBase64}}')"></div>
              <div (click)="selectProduct(item)" data-bs-toggle="modal" data-bs-target="#modalPos"
                *ngIf="!item.ImagenBase64" class="img" style="background-image: url(/assets/img/pos/notfound.jpg)">
              </div>
              <div class="text">
                <div data-bs-toggle="modal" data-bs-target="#modalPos" (click)="selectProduct(item)" class="title">
                  [{{item.Clave}}] {{item.Nombre}}</div>
                <div data-bs-toggle="modal" data-bs-target="#modalPos" (click)="selectProduct(item)" class="desc">
                  {{item.DetalleDelProducto}}</div>
                <div style="display: flex;">
                  <div style="width: 50%;">
                    <div class="price">${{item.Precio | number:'1.2-6'}}</div>
                  </div>
                  <div style="text-align: right;width: 50%;">
                    <a (click)="searchProduct(item.Clave, item.CantidadPOS)" href="javascript:;" class="btn btn-success"
                      style="width: 49%;"><i class="fa fa-plus"></i></a>
                  </div>
                </div>
              </div>
              <div (click)="lectorFocus()" *ngIf="item.Baja" class="not-available-text">
                <div>No Disponible</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </ng-scrollbar>
    <div style="width:100%">
      <input (keydown.f8)="ShowPayments()" (keydown.enter)="searchProduct()" #txtLector type="text"
        class="form-control fs-30px" style="text-align: center;border-radius: 0px; border-style: dashed;"
        placeholder="Lea o ingrese código de barras" />
    </div>
  </div>

  <!-- END pos-content -->

  <!-- BEGIN pos-sidebar -->
  <div class="pos-sidebar" style="top:30px;background: transparent;">
    <div class="h-100 d-flex flex-column p-0">
      <div (mwlClick)="deleteAll()" *ngIf="listItems.length>0" class="btn btn-primary d-block"
        style="border-radius: 0px;">Limpiar todo</div>
      <div class="pos-sidebar-nav">
        <ul class="nav nav-tabs nav-fill" role="tablist">
          <li class="nav-item" role="presentation"><a href="javascript:;" data-bs-toggle="tab"
              data-bs-target="#newOrderTab" class="nav-link active" aria-selected="true" role="tab">Captura
              ({{listItems.length}})</a>
          </li>
          <li class="nav-item" role="presentation"><a href="javascript:;" data-bs-toggle="tab"
              data-bs-target="#orderHistoryTab" class="nav-link" aria-selected="false" role="tab" tabindex="-1">
              Facturar ticket</a></li>
        </ul>
      </div>
      <div class="pos-sidebar-body tab-content" id="ticketList" data-scrollbar="true" data-height="100%"
        style="border: 1px dashed;">
        <div class="tab-pane fade h-100 show active" id="newOrderTab" role="tabpanel">
          <div class="pos-table">
            <div *ngFor="let item of listItems; let i = index;" class="row pos-table-row">
              <div class="col-9">
                <div class="pos-product-thumb">
                  <div *ngIf="item.Producto!.ImagenBase64; else notfound" class="img"
                    style="background-image: url('{{item.Producto!.ImagenBase64}}')"></div>
                  <ng-template #notfound>
                    <div *ngIf="!item.Producto!.ImagenBase64" class="img"
                      style="background-image: url(/assets/img/pos/notfound.jpg)"></div>
                  </ng-template>

                  <div class="info">
                    <div class="title">[{{item.Producto!.Clave}}] {{item.Producto!.Nombre}}</div>
                    <div class="single-price"> {{item.Cantidad | number:'1.2-6'}} X ${{item.ValorUnitario |
                      number:'1.2-6'}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 total-price">${{item.Importe | number:'1.2-6'}}</div>
              <div class="d-flex">
                <div style="width:80%;margin-left: 20%;">
                  <div class="input-group qty">
                    <div class="input-group-append">
                      <a (click)="setQuantityAdded(false,i)" href="javascript:;" class="btn btn-default"><i
                          class="fa fa-minus"></i></a>
                    </div>
                    <input type="number" disabled class="form-control" value={{item.Cantidad}} />
                    <div class="input-group-prepend">
                      <a (click)="setQuantityAdded(true,i)" href="javascript:;" class="btn btn-default"><i
                          class="fa fa-plus"></i></a>
                    </div>
                  </div>
                </div>
                <div style="width:20%; text-align: right;">
                  <a (click)="deleteItem(i)" href="javascript:;" style="width:36px; margin-top: 9px;"
                    class="btn btn-danger d-flex align-items-center"><i class="fa fa-trash"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="orderHistoryTab" class="tab-pane fade h-100" role="tabpanel">
          <div class="col-md-12 p-3">
            <label class="form-label">Folio</label>
            <input class="form-control" type="number" />
          </div>
          <div class="btn btn-primary d-block  mb-3" style="margin-left: 15px;margin-right: 15px;">
            Buscar
          </div>

          <div style="border: 1px dashed; height: 250px;margin-left: 15px;margin-right: 15px;padding: 5px;">
            <h4 class="text-green">Folio: 525</h4>
            <h4 class="text-dark">Total: $540.00</h4>
            <hr />
            <div class="btn btn-primary d-block  mb-3" style="margin-left: 15px;margin-right: 15px;">
              Seleccionar Cliente
            </div>
          </div>
        </div>
      </div>
      <div class="pos-sidebar-footer" style="margin-bottom: 43px;border-radius: 0px;">
        <!-- <div class="d-flex align-items-center mb-2">
          <div>Subtotal</div>
          <div class="flex-1 text-end h6 mb-0">$0.00</div>
        </div>
        <div class="d-flex align-items-center">
          <div>IVA</div>
          <div class="flex-1 text-end h6 mb-0">$0.00</div>
        </div> -->
        <hr class="opacity-1 my-10px">
        <div class="d-flex align-items-center mb-2">
          <div>Subtotal</div>
          <div class="flex-1 text-end h4 mb-0">{{getSubTotal|number:'1.2-6'}}</div>
        </div>
        <div class="d-flex align-items-center mb-2">
          <div>IVA</div>
          <div class="flex-1 text-end h4 mb-0">{{getImps|number:'1.2-6'}}</div>
        </div>
        <div class="d-flex align-items-center mb-2">
          <div>Total</div>
          <div class="flex-1 text-end h4 mb-0">${{getTotal | number:'1.2'}}</div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <!-- <a href="javascript:;" class="btn btn-default rounded-3 text-center me-10px w-70px"><i
              class="fa fa-receipt d-block fs-18px my-1"></i> Cobrar</a> -->
          <a href="javascript:;" (click)="ShowPayments()" class="btn btn-theme rounded-3 text-center flex-1"><i
              class="fa fa-shopping-cart d-block fs-18px my-1"></i>
            Guardar [F8]</a>
        </div>
      </div>
    </div>
  </div>
  <!-- END pos-sidebar -->
</div>
<!-- END pos -->

<div class="modal modal-pos fade" style="margin-top:250px;" id="modalPos">
  <div class="modal-dialog modal-lg bg-white">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="modal-pos-product">
          <div class="modal-pos-product-img">
            <div *ngIf="selectedProduct?.Producto?.ImagenBase64; else notfound" class="img"
              style="background-image: url('{{selectedProduct?.Producto?.ImagenBase64}}')"></div>
            <ng-template #notfound>
              <div *ngIf="!selectedProduct?.Producto?.ImagenBase64" class="img"
                style="background-image: url(/assets/img/pos/notfound.jpg)"></div>
            </ng-template>
          </div>
          <div class="modal-pos-product-info">
            <div class="fs-4 fw-bold">[{{selectedProduct?.Producto?.Clave}}] {{selectedProduct?.Producto?.Nombre}}
            </div>
            <div class="fs-6 text-body text-opacity-50 mb-2">
              {{selectedProduct?.Producto?.DetalleDelProducto}}
            </div>
            <div class="fs-5 fw-bolder mb-3">${{selectedProduct?.Producto?.Precio | number:'1.2-6'}}</div>
            <div class="option-row">
              <div class="d-flex mb-3">
                <a (click)="setQuantity(false)" href="javascript:;" class="btn btn-default d-flex align-items-center"><i
                    class="fa fa-minus"></i></a>
                <input type="number" class="form-control w-60px fw-bold fs-5 px-0 mx-2 text-center border-0" name="qty"
                  value={{selectedProduct?.Cantidad}}>
                <a (click)="setQuantity(true)" href="javascript:;" class="btn btn-default d-flex align-items-center"><i
                    class="fa fa-plus"></i></a>
              </div>
            </div>
            <hr />
            <!-- <div class="mb-3">
              <div class="fw-bold fs-6">Size</div>
              <div class="option-list">
                <div class="option">
                  <input type="radio" id="size3" name="size" class="option-input" checked />
                  <label class="option-label" for="size3">
                    <span class="option-text">Small</span>
                    <span class="option-price">+0.00</span>
                  </label>
                </div>
                <div class="option">
                  <input type="radio" id="size1" name="size" class="option-input" />
                  <label class="option-label" for="size1">
                    <span class="option-text">Large</span>
                    <span class="option-price">+3.00</span>
                  </label>
                </div>
                <div class="option">
                  <input type="radio" id="size2" name="size" class="option-input" />
                  <label class="option-label" for="size2">
                    <span class="option-text">Medium</span>
                    <span class="option-price">+1.50</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="fw-bold fs-6">Add On</div>
              <div class="option-list">
                <div class="option">
                  <input type="checkbox" name="addon[sos]" value="true" class="option-input" id="addon1" />
                  <label class="option-label" for="addon1">
                    <span class="option-text">More BBQ sos</span>
                    <span class="option-price">+0.00</span>
                  </label>
                </div>
                <div class="option">
                  <input type="checkbox" name="addon[ff]" value="true" class="option-input" id="addon2" />
                  <label class="option-label" for="addon2">
                    <span class="option-text">Extra french fries</span>
                    <span class="option-price">+1.00</span>
                  </label>
                </div>
                <div class="option">
                  <input type="checkbox" name="addon[ms]" value="true" class="option-input" id="addon3" />
                  <label class="option-label" for="addon3">
                    <span class="option-text">Mushroom soup</span>
                    <span class="option-price">+3.50</span>
                  </label>
                </div>
                <div class="option">
                  <input type="checkbox" name="addon[ms]" value="true" class="option-input" id="addon4" />
                  <label class="option-label" for="addon4">
                    <span class="option-text">Lemon Juice (set)</span>
                    <span class="option-price">+2.50</span>
                  </label>
                </div>
              </div>
            </div>
            <hr /> -->
            <div class="row gx-3">
              <div class="col-4">
                <a (click)="cancelModal()" href="javascript:;"
                  class="btn btn-danger fs-14px rounded-3 fw-bold mb-0 d-block py-3"
                  data-bs-dismiss="modal">Cancelar</a>
              </div>
              <div class="col-8">
                <a (click)="accept()" href="javascript:;" data-bs-dismiss="modal"
                  class="btn btn-green fs-14px rounded-3 fw-bold d-flex justify-content-center align-items-center py-3 m-0">Agregar<i
                    class="fa fa-plus ms-3"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-pos fade {{getShowPayments}}" [style]="getStyleModal" id="modalPayments">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body p-3 bg-white">
        <div class="row">
          <div *ngFor="let item of payments" class="col-xl-3 col-md-6">
            <div (click)="save(item)" class="btn btn-primary d-block mb-3">{{item.Nombre}}</div>
          </div>
        </div>
        <hr />
        <div class="row gx-3">
          <div class="col-4">
            <a (click)="cancelPayments()" href="javascript:;"
              class="btn btn-danger fs-14px rounded-3 fw-bold mb-0 d-block py-3"
              data-bs-dismiss="modalPayments">Cancelar</a>
          </div>
          <!-- <div class="col-8">
            <a  href="javascript:;"
              class="btn btn-green fs-14px rounded-3 fw-bold d-flex justify-content-center align-items-center py-3 m-0">Guardar</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
