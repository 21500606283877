<div class="panel panel-inverse" style="height:100vh;">
  <div class="panel-heading">
    <h4 class="panel-title">Chat Empresarial</h4>
  </div>
  <div class="panel-body bg-light" style="height:75%;">
    <ng-scrollbar style="height: 100%;">
      <div class="ng-scrollbar-wrapper" position="native" track="vertical" appearance="compact" visibility="hover"
        deactivated="false" dir="ltr" pointereventsmethod="viewport" verticalused="true" horizontalused="false"
        isverticallyscrollable="true" ishorizontallyscrollable="false" verticalhovered="false">
        <div class="ng-scroll-viewport-wrapper">
          <div scrollviewport="" hidenativescrollbar="" class=" ng-native-scrollbar-hider ng-scroll-viewport "
            style="--native-scrollbar-size: -0px;">
            <div class="ng-scroll-content">
              <div class="chats">
                <div class="chats-item start"><span class="date-time">yesterday 11:23pm</span><a href="javascript:;"
                    class="name">Sowse Bawdy</a><a href="javascript:;" class="image"><img alt=""
                      src="https://seantheme.com/color-admin/admin/assets/img/user/user-12.jpg"></a>
                  <div class="message"> Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat.
                    Praesent mattis interdum arcu eu feugiat. </div>
                </div>
                <div class="chats-item end"><span class="date-time">08:12am</span><a href="javascript:;"
                    class="name"><span class="badge bg-blue">ADMIN</span> Me</a><a href="javascript:;"
                    class="image"><img alt=""
                      src="https://seantheme.com/color-admin/admin/assets/img/user/user-13.jpg"></a>
                  <div class="message"> Nullam posuere, nisl a varius rhoncus, risus tellus hendrerit neque.
                  </div>
                </div>
                <div class="chats-item start"><span class="date-time">09:20am</span><a href="javascript:;"
                    class="name">Neck Jolly</a><a href="javascript:;" class="image"><img alt=""
                      src="https://seantheme.com/color-admin/admin/assets/img/user/user-10.jpg"></a>
                  <div class="message"> Euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                  </div>
                </div>
                <div class="chats-item start"><span class="date-time">11:15am</span><a href="javascript:;"
                    class="name">Shag Strap</a><a href="javascript:;" class="image"><img alt=""
                      src="https://seantheme.com/color-admin/admin/assets/img/user/user-14.jpg"></a>
                  <div class="message"> Nullam iaculis pharetra pharetra. Proin sodales tristique sapien
                    mattis placerat. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar><!---->
  </div><!---->
  <div class="panel-footer" *ngIf="enviarMensajeChat">
    <form novalidate="" name="send_message_form" data-id="message-form">
      <div class="input-group">
        <input type="text" name="message" placeholder="Escribe y presiona enter" class="form-control">
      </div>
    </form><!---->
  </div>
</div><!---->
