import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokensService } from '../../services/tokens.service';
import { TokenPrecio } from 'src/app/configuracion/interfaces/tokens.interface';
import { Result } from 'src/app/auth/interfaces';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../service/utils.service';



@Component({
  selector: 'app-precios.page',
  templateUrl: './token-precios.page.component.html',
  styleUrls: ['./token-precios.page.component.scss']
})
export class TokenPreciosPageComponent {
  private frmBuilder = inject(FormBuilder);
  private tokenService = inject(TokensService);
  private utilsService = inject(UtilsService);


  token: TokenPrecio = {
    sucursal: { Id: 0 },
    usuarioUsa: { Id: 0 }
  } as TokenPrecio
  public frmTokenPrecios: FormGroup = this.frmBuilder.group({
    Token: [1],
    Sucursal: [[Validators.required]],
    Usuario: [[Validators.required]],
    Contrasena: ['', [Validators.required]],
    EsAbajoCosto: [false]
  });

  ngOnInit() {

  }

  selectedCombo(event: any, entidad: string) {
    if (entidad === "sucursal") {
      this.token = { ...this.token, sucursal: { Id: event.Id } }
    } else {
      this.token = { ...this.token, usuarioUsa: { Id: event.Id } as any }
    }
  }

  generarToken() {
    this.token = {
      ...this.token,
      token: this.frmTokenPrecios.controls["Token"].value.toString(),
      usuarioGenera: {
        ...this.token.usuarioGenera,
        Contrasena: this.frmTokenPrecios.controls["Contrasena"].value
      } as any,
      esAbajoCosto: this.frmTokenPrecios.controls["EsAbajoCosto"].value
    }

    this.utilsService.isLoad(true);
    this.tokenService.generarTokenPrecio(this.token).subscribe({
      next: (response: Result) => {
        Swal.fire({ text: response.message, icon: response.success ? 'success' : 'error', confirmButtonText: "Aceptar" });
        if (response.success) {
          this.frmTokenPrecios.controls["Contrasena"].setValue("");
          this.frmTokenPrecios.controls["Token"].setValue("1");
        }
        this.utilsService.isLoad(false);
      },
      error: (err: Error) => {
        console.error(err)
        this.utilsService.isLoad(false);
      }
    })
  }
}
