<div class="card text-center border-0" style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;" cdkDrag>
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Direcciones de entrega de {{nombreProveedor |
    uppercase }}
  </div>
  <div class="w-100 ">
    <span class="float-end mt-1">
      <a (click)="addEditConsignado()" class="btn btn-sm btn-white me-1 "><i class="fa fa-folder-plus t-plus-1 text-green fa-fw fa-lg"></i>
        Agregar dirección</a>
    </span>
  </div>

  <app-panel-base title="Listado de direcciones de entrega" style="height: 350px;">
    <div class="panel-content">
      <ng-scrollbar style="height: 120px; width:1090px; border-bottom: 1px solid #AAAAAA;">
        <table class="blueTable" style="width:1080px;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th style="width: 20px;"></th>
              <th style="width: 20px;"></th>
              <th style="width: 120px;">Nombre</th>
              <th style="width: 50px;">RFC</th>
              <th style="width: 300px;">Dirección</th>
            </tr>
          </thead>
          <tbody>
            <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
              *ngFor="let item of lista; let index = index;">
              <td style="text-align: center;">
                <i (click)="delete(index)"  class="fa fa-rectangle-xmark fa-1x"
                  style="color:red;font-weight: bold; cursor: pointer;"></i>
              </td>
              <td style="text-align: center;">
                <i (click)="edit(index)"  class="fa fa-pencil fa-1x"
                  style="color:rgb(255, 166, 0);font-weight: bold; cursor: pointer;"></i>
              </td>
              <td class="text-dark" style="width:250px">
                {{ item.Nombre | uppercase }}
              </td>
              <td class="text-dark" style="width:80px">
                {{ item.Rfc | uppercase}}
              </td>
              <td class="text-dark" style="width:70px;">
                {{item.Direccion!.Calle}} {{item.Direccion!.NumeroExterior}}, {{item.Direccion!.Colonia}}
                {{item.Direccion!.Localidad}}, {{item.Direccion!.Estado}} {{item.Direccion!.Pais}}, CP:
                {{item.Direccion!.CodigoPostal}}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-scrollbar>

      <!-- <gui-grid [autoResizeWidth]="false" [source]="lista" [width]="1080" [virtualScroll]="true" [rowHeight]="20" [rowSelection]="false"
        [theme]="'fabric'" [maxHeight]="250">
        <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
          <ng-template let-item="item" let-index="index">
            <i (click)="delete(index)" class="fa fa-rectangle-xmark" style="color:red;font-weight: bold; cursor: pointer;"></i>
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
          <ng-template let-item="item" let-index="index">
            <i (click)="edit(index)" class="fa fa-pencil" style="color:orange;font-weight: bold; cursor: pointer;"></i>
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.LEFT" header="Nombre" [width]="270" [cellEditing]="false">
          <ng-template let-item="item">
            {{ item.Nombre | uppercase }}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.LEFT" header="RFC" [width]="110" [cellEditing]="false">
          <ng-template let-item="item">
            {{ item.Rfc | uppercase}}
          </ng-template>
        </gui-grid-column>
        <gui-grid-column [align]="GuiColumnAlign.LEFT" header="Dirección" [width]="600" [cellEditing]="false">
          <ng-template let-item="item">
            {{item.Direccion.Calle}} {{item.Direccion.NumeroExterior}}, {{item.Direccion.Colonia}}
            {{item.Direccion.Localidad}}, {{item.Direccion.Estado}} {{item.Direccion.Pais}}, CP:
            {{item.Direccion.CodigoPostal}}
          </ng-template>
      </gui-grid> -->
    </div>
  </app-panel-base>

  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-danger" #txtAceptar>Cerrar </div>

    </div>
  </div>

  <ng-template #modalConisgnado let-modal let-c="close">
    <app-alta-consignado-compra-page [idProveedor]="idProveedor" [idConsignado]="idConsignadoEdicion" />
  </ng-template>

</div>
