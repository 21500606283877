import { Component, inject, Input, OnInit } from '@angular/core';
import { FacturacionService } from '../../services/facturacion.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'src/app/service/modal.service';
import { c_TipoRelacion } from '../../interfaces/venta.interface';
import { MapType } from '@angular/compiler';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { SucursalPageComponent } from '../../../configuracion/pages/sucursales-page/sucursal-page/sucursal-page.component';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';

import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-cfdi-relacionado-page',
  templateUrl: './cfdi-relacionado-page.component.html',
  styles: [
  ]
})


export class CfdiRelacionadoPageComponent implements OnInit {
  private eventsService = inject(EventsService);
  @Input()
  idEmpresa: number = 0;

  @Input()
  soloUUID: boolean = false;

  ngOnInit(): void {

  }

  searching: boolean = false;
  listaSeries: ComboBoxEntity[] = [];

  public myForm: FormGroup = this.fb.group({
    TipoRelacion: [{} as c_TipoRelacion],
    Sucursal: [{} as Sucursal],
    DocumentoVenta: [{} as DocumentoVenta],
    Serie: [{} as SerieVenta],
    Folio: [0],
    UUIDS: [''],
  })

  constructor(private fServices: FacturacionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private mService: ModalService) {
  }

  onSelectedItem(ent: any, type: string) {
    switch (type) {
      case "TipoRelacion":
        this.myForm.controls[type].setValue(ent);
        break;

      case "Sucursal":
        this.myForm.controls[type].setValue(ent);
        break;

      case "DocumentoVenta":
        this.myForm.controls[type].setValue(ent);
        this.getSeries(ent.Id);
        break;

      case "Serie":
        this.myForm.controls[type].setValue(ent);
        break;

    }
  }

  accept() {
    if (!this.myForm.value.TipoRelacion) {
      this.eventsService.publish('home:showAlert', { message: 'Indique el Tipo de Relación.', cancelButton: false });
      return;
    }

    if (this.myForm.value.TipoRelacion.Id == 0 || !this.myForm.value.TipoRelacion.Id) {
      this.eventsService.publish('home:showAlert', { message: 'Indique el Tipo de Relación.', cancelButton: false });
      return;
    }

    if (this.myForm.value.UUIDS.length > 0) {
      this.mService.closeModal(this.myForm.value);
    } else {
      this.eventsService.publish('home:showAlert', { message: 'No ha indicado ningún CFDI a relacionar.', cancelButton: false });
    }
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  searchCfdi() {
    this.searching = true;
    this.fServices.getUUID(this.idEmpresa, this.getEntity.Sucursal.Id, this.getEntity.DocumentoVenta.Id, this.getEntity.Serie.Serie, this.getEntity.Folio).subscribe((result) => {
      this.searching = false;
      if (result.success) {
        const ent = this.myForm.value;
        let uuids = ent.UUIDS;
        if (uuids.length == 0) {
          uuids += result.message;
        } else {
          if (!uuids.includes(result.message)) {
            uuids = uuids + ',' + result.message;
          }
        }
        ent.UUIDS = uuids;
        ent.Folio = 0;
        this.myForm.reset(ent);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  get getEntity() {
    return this.myForm.value;
  }

  getSeries(idDocumento: number) {

    this.fServices.getSeries(this.idEmpresa, this.myForm.value.Sucursal.Id, idDocumento).subscribe((lista) => {
      this.listaSeries = lista;
    })

  }

}
