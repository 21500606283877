<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; position: absolute;top:183px;width: 540px;left: 360px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{'Indique el motivo' | uppercase}}
    </span>
  </div>
  <div class="card-body">
    <textarea style="width: 100%; height: 100%; padding: 5px;" name="w3review" rows="4" cols="50" #text></textarea>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="accept()" class="btn btn-success me-1">Aceptar</div>
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>
