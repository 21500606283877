import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ConceptoOrdenManufactura } from '../../interfaces/manufacura.interface';
import Swal from 'sweetalert2';
import { EventsService } from 'src/app/service/events.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { Editor } from 'ngx-editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Result } from 'src/app/auth/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generacion-orden-page',
  templateUrl: './generacion-orden-page.component.html',
  styleUrls: ['./generacion-orden-page.component.scss']
})
export class GeneracionOrdenPageComponent implements OnInit {

  @ViewChild('txtRef')
  public txtRef!: ElementRef<TextBoxComponent>;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  public lista: any[] = [];
  @Input()
  public idOrden: number = 0;
  private eventsService = inject(EventsService);
  private http =  inject(HttpClient);
  private fb = inject(FormBuilder);
  generando: boolean = false;
  indexEditing: number = 0;
  esEscapeCantidad: boolean = false;
  enviarOtroRenglon: boolean = false;
  enviarFocoCantidad: boolean = false;
  idUnico: string = '';
  colCantidad = 6;
  editor: Editor = new Editor;
  html: string = "";
  private readonly baseUrl: string = environment.baseUrlApi;




  public myForm: FormGroup = this.fb.group({
    Observaciones: [''],
  });


  constructor(private fServices: FacturacionService) { }
  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    if (this.lista) {
      this.lista = this.lista.filter(P => P.cantidadPendiente > 0);
    }
    setTimeout(() => {
      const t: any = this.txtRef;
      t.tagInput.nativeElement.focus();
    }, 150);
  }

  //* control dfe flujo del grid

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.lista[this.indexEditing].producto!.noDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }

  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: any = this.lista[this.indexEditing];
    e.target.value = item.producir ? item.producir : 0;
  }

  focusCantidad(index: number, e: any, item: any) {

    let c = this.getLastItem();
    if (index > 0 && index > (c - 1)) {
      return;
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  enterCantidad(e: any) {

    let pendiente: number = this.lista[this.indexEditing].cantidadPendiente;
    let producir: number = parseInt(e.target.value);

    if (producir <= 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad a producir no debe ser menor o gual a cero, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          this.enviarFocoCantidad = true;
          this.initEditor(this.indexEditing, this.colCantidad);
          return;
        }
      });
      return;
    }

    if (producir > pendiente) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad máxima a producir es de ${pendiente}, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          this.enviarFocoCantidad = true;
          this.sendFocus(e);
          return;
        }
      });
      return;
    }

    let c = this.getLastItem();
    if (this.indexEditing >= (c - 1)) {
      e.target.blur();
      return;
    }
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const producir = parseFloat(e.target.value);
    let item = this.lista[this.indexEditing];
    if (parseFloat(String(item.producir)) == producir) {
      if (this.enviarOtroRenglon) {
        this.sendFocus(null);
      }
      return;
    };
    item.producir = producir;
    this.lista[this.indexEditing] = { ...item };
    this.lista = [...this.lista];
    this.sendFocus(null);
  }

  getLastItem(): number {
    return this.lista.filter(P => P.producto).length;
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  setIndexEdit(index: number, e: any, item: any) {
    //todo revisar este codigo y ver como aplica con el nuevo grid
    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  sendFocus(e: any) {
    setTimeout(() => {
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
      }
      if (this.enviarOtroRenglon) {
        if (this.indexEditing < this.lista.length - 1) {
          this.indexEditing = this.indexEditing + 1;
          this.initEditor(this.indexEditing, this.colCantidad);
        } else {
          if (e) {
            e.target.classList.remove("focus-editor-grid");
          }
        }
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }


  up(e: any, celda: number) {
    e.preventDefault();
    if (this.indexEditing > 0) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      this.initEditor(this.indexEditing, this.colCantidad);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      this.enviarOtroRenglon = false;
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.enviarOtroRenglon = true;
        e.target.blur();
      }
    }
  }

  accept() {
    const t: any = this.txtRef;
    const ref = t.tagInput.nativeElement.value.trim();
    let sumaPendiente: number = 0;
    let sumaProducir: number = 0;
    this.lista.forEach((item: ConceptoOrdenManufactura) => { sumaPendiente += parseFloat(String(item.cantidadPendiente)) });
    this.lista.forEach((item: ConceptoOrdenManufactura) => { sumaProducir += parseFloat(String(item.producir)) });

    if (sumaProducir > sumaPendiente) {
      this.eventsService.publish('home:showAlert', {
        message: `La cantidad a producir [${sumaProducir}] es mayor a la pendiente [${sumaPendiente}], favor de verificar.`,
        onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    if (sumaProducir <= 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'La cantidad a producir es menor o igual a cero.',
        onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    if (!ref) {
      this.eventsService.publish('home:showAlert', {
        message: 'Debe de indicar una referencia.',
        cancelButton: false,
        onConfirm: (data: AlertResponse) => {
        }
      });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de procesar la orden?',
      cancelButton: true,
      textAccept: "Sí, Procesar",
      textCancel: "Cancelar",
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.procesarOrden();
        }
      }
    });
  }

  procesarOrden() {
    const t: any = this.txtRef;
    const ref = t.tagInput.nativeElement.value.trim();
    this.generando = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let cons = [...this.lista.filter(P => P.producir > 0)];
    cons.map((item: any) => {
      item.producto = { id: item.producto.id }
      return item;
    });
    let json = JSON.stringify(cons);
    this.fServices.generarProduccion(this.idOrden, json, ref, this.myForm.value.Observaciones).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      Swal.fire({
        text: `${result.message}`,
        icon: (result.success ? 'success' : 'error'),
        timer: 1000
      }).then(() => {
        this.onClose.emit(true);
        const params = new HttpParams().set("idOrden", this.myForm.value.id);
        this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirOrdenManufactura`, { params }).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          if (result.success) {
            this.downloadPdf(result.message);
          } else {
            this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
          }
        });
      })
    });
  }

  closeModal() {
    this.onClose.emit(false);
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }

}
