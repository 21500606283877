import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ResguardoService } from '../../services/resguardo.servuice';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ModalService } from 'src/app/service/modal.service';
import Swal from 'sweetalert2';
import { trim } from 'lodash';
import { ReportsService } from '../../../service/reports.service';
import { EventsService } from 'src/app/service/events.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cancela-remision-page',
  templateUrl: './cancela-remision-page.component.html',
  styles: [
  ]
})
export class CancelaRemisionPageComponent implements OnInit {

  @ViewChild('txtMotivo')
  public txtMotivo!: ElementRef<TextBoxComponent>;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  public idRemision: number = 0;
  private eventsService = inject(EventsService);
  cancelando: boolean = false;
  public myForm: FormGroup = this.fb.group({
    Fecha: [Date, Validators.required],
    MotivoCancelacion: ['', Validators.required],
  })

  constructor(private rServices: ResguardoService, private fb: FormBuilder, private mService: ModalService) {
  }

  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      MotivoCancelacion: '',
    };
    this.myForm.reset(ent);
    setTimeout(() => {
      const t: any = this.txtMotivo;
      t.tagInput.nativeElement.focus();
    }, 150);
  }

  accept() {
    if (!this.myForm.value.Fecha) {
      this.eventsService.publish('home:showAlert', { message: 'Indique la Fecha.', cancelButton: false });
      return;
    }
    const motivo = this.myForm.value.MotivoCancelacion.trim();
    if (!motivo) {
      this.eventsService.publish('home:showAlert', { message: 'Indique el Motivo de Cancelación.', cancelButton: false });
      return;
    }
    this.cancelando = true;
    let fechaC = moment(this.myForm.value.Fecha).format("DD/MM/YYYY ") + moment(new Date()).format("HH:mm:ss");

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.rServices.cancelarRemision(this.idRemision,
                                    fechaC,
                                    this.myForm.value.MotivoCancelacion).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (!result.success) {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
      this.mService.closeModal(result.success);
    });

  }



  closeModal() {
    this.mService.closeModal(false);
  }

}
