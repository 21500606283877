<app-container-base subTitle="Generar Token" [hasBar]="false" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmTokenPrecios" autocomplete="off">
      <app-panel-base title="Datos Generales">
        <div class="panel-content">
          <div class="col-md-4">
            <components-ui-number-box [aceptaPunto]="false" label='Número de Tokens' formControlName="Token"
              [tabIndex]="1" [required]="true" [widthLabelColumns]="4" [widthTextColumns]="8" [maxLength]="2"
              #txtToken />
          </div>
          <div class="col-md-4">
            <component-ui-combo-box [required]="true" label='Sucursal' formControlName="Sucursal" [tabIndex]="2"
              inputProperty="ClaveNombre" entityName="Sucursal" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="6" #txtSucursal (onSelectedItem)="selectedCombo($event, 'sucursal')"
              helpLine="Sucursal en la cual se usará el Token." />
          </div>
          <div class="col-md-4">
            <component-ui-combo-box [required]="true" label='Usuario' formControlName="Usuario" [tabIndex]="3"
              inputProperty="ClaveNombre" entityName="Usuario" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="6" #txtUsuario (onSelectedItem)="selectedCombo($event, 'usuario')"
              helpLine="Usuario que usará el Token." />
          </div>
          <div class="col-md-4">
            <components-ui-password-box [required]="true" label='Contraseña' formControlName="Contrasena" [tabIndex]="4"
              [widthLabelColumns]="4" [widthTextColumns]="8" #txtPassword
              helpLine="Contraseña del usuario que genera el Token (Usuario en sesión actual)." />
          </div>
          <div class="col-md-4">
            <component-ui-check-box [tabIndex]="5" [widthLabelColumns]="4" formControlName="EsAbajoCosto"
              label="Vender abajo del costo" />
          </div>
          <div class="col-md-4 ">
            <button [disabled]="frmTokenPrecios.invalid || (token.sucursal!.Id === 0 || token.usuarioUsa!.Id === 0)"
              class="btn btn-success float-end me-15px" type="button" (click)="generarToken()">
              <i class="fas fa-unlock-keyhole"></i>
              Generar
            </button>
          </div>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
