
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { CxcCabecera, CxcDetalle } from '../../interfaces/cxccabecera';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { environment } from 'src/environments/environment';
import { EventsService } from 'src/app/service/events.service';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType, GuiRowDetail } from '@generic-ui/ngx-grid';
import { ModalService } from 'src/app/service/modal.service';
import { Observable } from 'rxjs';
import { ReportsService } from 'src/app/service/reports.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConceptoCxc, CuentaCxc, TipoMovimientoCXC } from '../../interfaces/cuentacxc.interface';
import { UtilsService } from 'src/app/service/utils.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { CuentasPorCobrarService } from '../../services/cxc.service';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';


@Component({
  selector: 'app-movimientoscxc-page',
  templateUrl: './movimientoscxc-page.component.html',
})
export class MovimientoscxcPageComponent {


  //*referencia a elementos de la pantalla
  @ViewChild('tipoMovCxc')
  public tipoMov!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioMovCXC')
  public txtFolio!: ElementRef<TextBoxComponent>;
  @ViewChild('busquedaClientesMovCxc')
  public ctrlBusquedaClientes!: ElementRef<HTMLElement>;
  @ViewChild('busquedaCuentaMovCxc')
  public ctrlBusquedaCuenta!: ElementRef<HTMLElement>;
  @ViewChild('busquedaConceptoMovCxc')
  public ctrlBusquedaConcepto!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelarMovCxc')
  public modalCancelarMovCxc!: ElementRef<HTMLElement>;
  @ViewChild('txtObservaciones')
  public txtObservaciones!: ElementRef<HTMLElement>;
  @ViewChild('gridMovimientos')
  public gridMovimientos!: ElementRef<any>;

  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  usuarioActual: UserLogged = {} as UserLogged;
  tipoMovimientoActual: ComboBoxEntity = {} as ComboBoxEntity;
  tipoCapturaActual: ComboBoxEntity = {} as ComboBoxEntity;
  listaSeries: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  showDescription: boolean = false;
  loading: boolean = false;
  downloading: boolean = false;
  file: any = null;
  oldFolio: number = 0;
  saving: boolean = false;
  buscandoFolio: boolean = false;
  blockCombos: boolean = false;

  //* injects
  private eventsService = inject(EventsService);
  private cxcService = inject(CuentasPorCobrarService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  private cd = inject(ChangeDetectorRef)
  private http = inject(HttpClient);


  //* variables del grid
  source: Array<CxcDetalle> = [];
  public GuiColumnAlign = GuiColumnAlign;
  public GuiCellView = GuiCellView;
  public GuiDataType = GuiDataType;
  searchConfigurationCliente: SearchConfiguration | null = null;
  searchConfigurationCuentaCxc: SearchConfiguration | null = null;
  searchConfigurationConceptoCxc: SearchConfiguration | null = null;
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;



  public filtroMovCxc: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de movimiento de cxc',
      NombreExcel: 'Reporte de movimiento de cxc.xlsx',
      FilterOptions: [
        { Campo: 'cab.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  public myFormTotales: FormGroup = this.fb.group({
    TotalRegistros: [''],
  });

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    TipoMovimiento: [{} as TipoMovimientoCXC, Validators.required],
    Codigo: [0],
    Observaciones: [''],
    UsuarioCaptura: [null],
    UsuarioCancela: [null],
    MotivoCancelacion: '',
    Fecha: null,
    Anio: 0,
    FechaCancelacion: null,
    Detalle: [[] as CxcDetalle[]],
    Eliminado: false,
    Baja: false,
  })

  rowDetail: GuiRowDetail = {
    enabled: false,
    template: (item) => {
      if (!item.Producto) return "Sin contenido disponible";
      if (item.Producto.Id == 0) return "Sin contenido disponible";
      return `
        <app-panel-base title="Descripción">
            <div class="panel-content">
                sdkjfsjkdfhskjdfhskjdfhkj
            </div>
        </app-panel-base>
        `;
    }
  };


  constructor() {
  }

  ngAfterViewInit(): void {
    let elem: CxcCabecera = this.myForm.value;
    elem.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
    elem.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
    elem.Fecha = new Date();
    elem.Anio = elem.Fecha.getFullYear();
    setTimeout(() => {
      this.myForm.reset(elem);
      const tipoMov: any = this.tipoMov;
      tipoMov.tagInput.nativeElement.focus();
    }, 150);
  }

  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.usuarioActual = this.utilsService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa?.numero}`);
    this.searchConfigurationCuentaCxc = this.ms.GetSearchConfiguration("CuentaCxc", `Empresa.Id = ${this.info.empresa?.numero}`);
    this.searchConfigurationConceptoCxc = this.ms.GetSearchConfiguration("ConceptoCxc", `Empresa.Id = ${this.info.empresa?.numero}`);
    this.sourceReset();
  }

  //* ///////////////////////////////////////////////////
  //* 1.- metodos de inicializacion de la pantalla y grid

  get getExtras() {
    return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  }

  get getClienteColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getFilterCliente(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get permiteEliminar() {
    return !(this.myForm.value.FechaCancelacion);
  }


  openCancel() {
    const b: any = this.modalCancelarMovCxc;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }

  get getBlockCombos() {
    return !this.blockCombos;
  }



  get getClientePropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })
      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }


  get getCuentaColumns(): any {
    if (this.searchConfigurationCuentaCxc) {
      return this.searchConfigurationCuentaCxc.columns;
    }
    return [];
  }

  get getFilterCuenta(): any {
    if (this.searchConfigurationCuentaCxc) {
      return this.searchConfigurationCuentaCxc.filter;
    }
    return "";
  }


  get getCuentaPropertys(): string {
    if (this.searchConfigurationCuentaCxc) {
      let props = "";
      this.searchConfigurationCuentaCxc.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })
      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }


  get getConceptoColumns(): any {
    if (this.searchConfigurationConceptoCxc) {
      return this.searchConfigurationConceptoCxc.columns;
    }
    return [];
  }

  get getFilterConcepto(): any {
    if (this.searchConfigurationConceptoCxc) {
      return this.searchConfigurationConceptoCxc.filter;
    }
    return "";
  }


  get getConceptoPropertys(): string {
    if (this.searchConfigurationConceptoCxc) {
      let props = "";
      this.searchConfigurationConceptoCxc.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })
      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  //* 2.- metodos de control cambio para los campos principales
  selectTipoMovto(entity: ComboBoxEntity) {
    this.myForm.controls["TipoMovimiento"].setValue(entity);
    this.tipoMovimientoActual = entity;
    const ent: CxcCabecera = this.myForm.value;
    this.getNextFolio(ent).subscribe(folio => {
      ent.Codigo = folio;
      this.blockCombos = true;
      this.setEntity(ent);
      setTimeout(() => {
        const txtFolio: any = this.txtFolio;
        txtFolio.tagInput.nativeElement.focus();
      }, 100);
    });
  }

  onKeyPressFolio(value: any) {
    if (value.keyCode == 13) {
        const txtFolio: any = this.txtObservaciones;
        txtFolio.nativeElement.focus();
    }
  }

   onKeyDownObs(e: any) {
    if (e.keyCode == 9) {
      this.indexEditing = 0;
      this.initEditor(this.indexEditing, 1);
    }
  }




  get permiteGuardar() {
    return (this.myForm.value.Id > 0 && !this.myForm.value.FechaCancelacion) || this.myForm.value.Id == 0;
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }



  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date2 = new Date();
    const d = { year: e.Fecha.getFullYear(), month: e.Fecha.getMonth(), day: e.Fecha.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
    e.Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
    const date = moment(e.Fecha).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorCobrar/CancelarCxcCabecera`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        Swal.fire({
          text: result.message, icon: 'info',
        })
      }
    });
  }

  //* 3.- metodos de control de flujo del grid
  //* ////////////////////////////////////////////






  sourceReset(detalle: any = []) {
    if (!detalle) {
      this.source = [];
    } else {
      this.source = detalle;
    }

    this.source!.map((item: CxcDetalle) => {
      if (item.Cliente) {
        item.ClienteClave = item.Cliente!.Clave.toString();
        item.ClienteNombre = item.Cliente!.Nombre;
      } else {
        item.ClienteNombre = '';
      }
      if (item.Cuenta) {
        item.CuentaClave = item.Cuenta.Clave.toString()
        item.CuentaNombre = item.Cuenta.Nombre;
      } else {
        item.CuentaNombre = '';
      }

      if (item.Concepto) {
        item.ConceptoClave = item.Concepto.Clave.toString()
        item.ConceptoNombre = item.Concepto.Nombre;
      } else {
        item.ConceptoNombre = '';
      }
    });

    let totalRegs = 1;
    if (this.source.length < 20) {
      totalRegs = 20;
    }

    for (let index = 0; index < totalRegs; index++) {
      this.source = [...this.source, this.estructuraVacia()]
    }
  }

  estructuraVacia(): any {
    return {
      Id: 0,
      Tipo: '',
      ImporteCargo: 0,
      ImporteAbono: 0,
      SerieOrigina: '',
      FolioOrigina: 0,
      SerieAfecta: '',
      FolioAfecta: 0,
      FechaVencimiento: null,
      Cliente: null,
      ClienteClave: 0,
      Cuenta: null,
      CuentaClave: 0,
      CuentaNombre: '',
      Concepto: null,
      ConceptoClave: 0,
      ConceptoNombre: '',
      Usuario: null,
      UUID: '',
      Eliminado: false,
      Baja: false,
    };
  }

  cellEditSubmitted() {
    if (this.navigateColumns) {
      switch (this.columnEditing) {
        case 3:
          if (this.source[this.indexEditing].Cuenta) {
            this.navigateColumns && this.initEditor(this.indexEditing, 5);
          }
          break;
        case 5:
          if (this.source[this.indexEditing].Concepto) {
            this.navigateColumns && this.initEditor(this.indexEditing, 7);
          }
          break;
        case 7:
          this.navigateColumns && this.initEditor(this.indexEditing, 8);
          break;
        case 8:
          this.navigateColumns && this.initEditor(this.indexEditing, 9);
          break;
        case 10:
          this.navigateColumns && this.initEditor(this.indexEditing, 11);
          break;
        case 11:
          this.navigateColumns && this.initEditor(this.indexEditing, 12);
          break;
        case 12:
          this.indexEditing++; this.initEditor(this.indexEditing, 1);
          break;
      }
      this.navigateColumns = false;
    }
  }

  cellEditEntered(e: any) {
    setTimeout(() => {
      this.setColumnEditor();
    }, 100);
  }


  setColumnEditor() {
    const elems: any = document.getElementById("divMovCxc")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement, indexRow: number) => {
      renglon.childNodes.forEach((nodeRow: any) => {
        let colNum = -1;
        nodeRow.childNodes.forEach((nodeColumna: any) => {
          if (nodeColumna.childNodes && nodeColumna.className) {
            colNum++;
            nodeColumna.childNodes.forEach((nodoCelda: any) => {
              if (nodoCelda.className == "gui-cell-edit-mode ng-star-inserted") {
                this.columnEditing = colNum;//parseInt(col);
                // if (this.columnEditing == 1) {
                //   this.existencia = 0;
                // }
                const elem: any = nodeColumna.childNodes[1].childNodes[0];
                elem.addEventListener("keydown", (e: any) => {

                  this.navigateColumns = (e.keyCode == 13);
                  if (this.columnEditing == 1 && e.keyCode == 113) {
                    this.abrirBusquedaClientes();
                  }
                  if (this.columnEditing == 3 && e.keyCode == 113) {
                    this.abrirBusquedaCuentas();
                  }
                  if (this.columnEditing == 5 && e.keyCode == 113) {
                    this.abrirBusquedaConceptos();
                  }

                  //* que pasa si presiono Esc  cuando estoy editando en el grid
                  if (e.keyCode == 27) {
                    switch (this.columnEditing) {
                      case 12: this.initEditor(this.indexEditing, 11); break;
                      case 11: this.initEditor(this.indexEditing, 10); break;
                      case 10: this.initEditor(this.indexEditing, 9); break;
                      case 9: this.initEditor(this.indexEditing, 8); break;
                      case 8: this.initEditor(this.indexEditing, 7); break;
                      case 7: this.initEditor(this.indexEditing, 5); break;
                      case 5: this.initEditor(this.indexEditing, 3); break;
                      case 3: this.initEditor(this.indexEditing, 1); break;
                    }
                  }
                });
              }
            });
          }
        });
      })
    });
    if (this.columnEditing > 1) {
      if (!this.source[this.indexEditing].Cliente || this.source[this.indexEditing].Cliente?.Id == 0) {
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  abrirBusquedaClientes() {
    const b: any = this.ctrlBusquedaClientes;
    this.ms.openModal(b, (e: any) => {
      this.buscarCliente(e.Clave);
    }, 'xl')
  }

  abrirBusquedaCuentas() {
    const b: any = this.ctrlBusquedaCuenta;
    this.ms.openModal(b, (e: any) => {
      this.buscarCuenta(e.Clave);
    }, 'xl')
  }

  abrirBusquedaConceptos() {
    const b: any = this.ctrlBusquedaConcepto;
    this.ms.openModal(b, (e: any) => {
      this.buscarConcepto(e.Clave);
    }, 'xl')
  }







  deleteRow(indx: number) {
    Swal.fire({
      title: '¿Desea eliminar el Producto?.',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const idEliminar = this.source[indx].Id;
        if (idEliminar > 0) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.cxcService.eliminarCxcDetalle(idEliminar).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: CxcCabecera = this.myForm.value;
            ent.Detalle?.splice(indx, 1);
            this.myForm.reset(ent);
            this.cd.detectChanges();
          })
        } else {
          this.source.splice(indx, 1);
          this.source = [...this.source];
          this.calcTotales();
          let ent: CxcCabecera = this.myForm.value;
          ent.Detalle?.splice(indx, 1);
          this.myForm.reset(ent);
          this.cd.detectChanges();
        }
      }
    });
  }


  /*Grid events */
  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: CxcDetalle, index: number) => {
      this.indexEditing = -1;
      //todo

      if (this.myForm.value.Id > 0 && this.myForm.value.FechaCancelacion) {
        return false;
      }

      if (index > 0) {
        if (!this.source[index - 1].Cliente) {
          return false;
        }
        if (this.source[index - 1].Cliente!.Id == 0) {
          return false;
        }
      }

      this.indexEditing = index;

      return true;
    },
    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }


  buscarCliente(value: string) {
    this.loading = true;
    const params = new HttpParams().set("entidad", "Cliente").set("clave", value).set("filtro", "");
    return this.http.get<Cliente>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((cliente) => {
      this.loading = false;
      if (cliente) {
        let item = this.source[this.indexEditing];
        item.Cliente = cliente;
        item.ClienteClave = cliente.Clave.toString();
        item.ClienteNombre = cliente.Nombre;
        this.sourceReset(this.source);
        this.initEditor(this.indexEditing, 3);
        this.navigateColumns = true;
      } else {
        Swal.fire({
          text: "No se encontró el Cliente indicado.",
          icon: 'error',
        }).then(() => {
          this.source[this.indexEditing].ClienteClave = "";
          this.source[this.indexEditing].ClienteNombre = "";
          this.source[this.indexEditing].Cliente = null;
          this.source = [...this.source];
          this.initEditor(this.indexEditing, 1);
        })
      }
    })
  }

  buscarCuenta(value: number) {
    this.loading = true;
    const params = new HttpParams().set("entidad", "CuentaCxc").set("clave", value).set("filtro", "");
    return this.http.get<CuentaCxc>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((cuenta) => {
      this.loading = false;
      if (cuenta) {
        let item = this.source[this.indexEditing];
        item.Cuenta = cuenta;
        item.CuentaClave = cuenta.Clave.toString();
        item.CuentaNombre = cuenta.Nombre;
        this.sourceReset(this.source);
        this.initEditor(this.indexEditing, 5);
        this.navigateColumns = true;
      } else {
        Swal.fire({
          text: "No se encontró la Cuenta indicada.",
          icon: 'error',
        }).then(() => {
          this.source[this.indexEditing].CuentaClave = "";
          this.source[this.indexEditing].Cuenta = null;
          this.source[this.indexEditing].CuentaNombre = "";
          this.source = [...this.source];
          this.initEditor(this.indexEditing, 3);

        })
      }
    })
  }

  buscarConcepto(value: number) {
    this.loading = true;
    const params = new HttpParams().set("entidad", "ConceptoCxc").set("clave", value).set("filtro", "");
    return this.http.get<ConceptoCxc>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((concepto) => {
      this.loading = false;
      if (concepto) {
        let item = this.source[this.indexEditing];
        item.Concepto = concepto;
        item.ConceptoClave = concepto.Clave.toString();
        item.ConceptoNombre = concepto.Nombre;
        this.sourceReset(this.source);
        this.initEditor(this.indexEditing, 7);
        this.navigateColumns = true;
      } else {
        Swal.fire({
          text: "No se encontró el Concepto indicado.",
          icon: 'error',
        }).then(() => {
          this.source[this.indexEditing].ConceptoClave = "";
          this.source[this.indexEditing].Concepto = null;
          this.source[this.indexEditing].ConceptoNombre = "";
          this.source = [...this.source];
          this.initEditor(this.indexEditing, 5);

        })
      }
    })
  }


  sourceEdited(e: any) {
    if (this.columnEditing == 1) {
      let valor: number = +e.after.ClienteClave;
      if ((e.after.ClienteClave != e.before.ClienteClave)) {
        this.buscarCliente(e.after.ClienteClave);
      } else {
        if (valor) {
          this.initEditor(this.indexEditing, 3);
        } else {
          const el = this.source[this.indexEditing];
          if (el.CuentaClave.length > 0) {
            this.source.splice(this.indexEditing, 1);
            this.source = [...this.source];
          }
        }
      }
    }

    if (this.columnEditing == 3) {
      let valor: number = +e.after.CuentaClave;
      if ((e.after.CuentaClave != e.before.CuentaClave) && valor > 0) {
        this.buscarCuenta(valor);
      } else {
        if (valor == 0) {
          this.source[this.indexEditing].Cuenta = null;
          this.source[this.indexEditing].CuentaNombre = "";
          this.source[this.indexEditing].CuentaClave = "";
          //this.initEditor(this.indexEditing, 3);
        }
      }
    }

    if (this.columnEditing == 5) {
      let valor: number = +e.after.ConceptoClave;
      if ((e.after.ConceptoClave != e.before.ConceptoClave) && valor > 0) {
        this.buscarConcepto(valor);
      } else {
        if (valor == 0) {
          this.source[this.indexEditing].Concepto = null;
          this.source[this.indexEditing].ConceptoClave = "";
          this.source[this.indexEditing].ConceptoNombre = "";
          //this.initEditor(this.indexEditing, 5);
        }
      }
    }

    if (this.columnEditing == 7) {
      let str: string = e.after.SerieOrigina;
      this.source[this.indexEditing].SerieOrigina = str.toUpperCase();

    }



    if (this.columnEditing == 9) {
      let valor: number = +e.after.ImporteCargo;
      if (valor > 0) {
        this.source[this.indexEditing].ImporteAbono = 0;
        this.initEditor(this.indexEditing, 11);
      } else {
        this.initEditor(this.indexEditing, 10);
      }


    }
    if (this.columnEditing == 10) {
      let valor: number = +e.after.ImporteAbono;
      if ((!this.source[this.indexEditing].ImporteCargo || this.source[this.indexEditing].ImporteCargo == 0) && valor == 0) {
        Swal.fire({
          title: 'Debe indicar un importe para el Cargo o el Abono, por favor verifique.',
          icon: 'info'
        }).then((result) => {
          this.initEditor(this.indexEditing, 10);
        });
        return;
      }
      if (valor > 0) {
        this.source[this.indexEditing].ImporteCargo = 0;
      }
    }

    if (this.columnEditing == 11) {
      let str: string = e.after.SerieAfecta;
      this.source[this.indexEditing].SerieAfecta = str.toUpperCase();
      //this.navigateColumns && this.initEditor(this.indexEditing, 12);
    }

    this.calcTotales();
  }


  calcTotales() {
    let ent: CxcCabecera = this.myForm.value;
    let regs: number = 0;

    this.source.map((concepto, index) => {
      if (concepto.Cliente) {
        if (concepto.Cliente.Id > 0) {
          regs++;
        }
      }
    });

    this.myFormTotales.get('TotalRegistros')!.setValue(regs);
    this.myForm.reset(ent);
    this.source = [...this.source];
  }





  initEditor(row: number, col: number) {
    const elem: any = this.getElemEditor(row, col);
    if (elem) {
      setTimeout(() => {
        elem.firstElementChild?.click();
      }, 250);
    }
  }


  getElemEditor(row: number, col: number) {

    let elem: any = null;
    const elems: any = document.getElementById("divMovCxc")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement) => {
      renglon.childNodes.forEach((nodeRow: any, indexRow: number) => {
        if (indexRow == row && !elem) {
          let colNum = -1;
          nodeRow.childNodes.forEach((nodeColumna: any) => {
            if (nodeColumna.childNodes && nodeColumna.className && !elem) {
              colNum++;
              if (col == colNum) {
                elem = nodeColumna;
              }
            }
          });
        }
      })
    });

    //const elem: any = document.getElementById("divFacturacion")!.querySelector(`[ng-reflect-column-index='${col}'][ng-reflect-row-index='${row}']`);
    return elem;
  }

  //* 4.- matodos de consulta a el backend


  get esNuevo() {
    return this.myForm.value.Id == 0;
  }




  findEntityByParamsNavigate(type: string) {
    let ent: CxcCabecera = this.myForm.value;
    const params = new HttpParams()
      .set("anio", ent.Anio)
      .set("idTipoMovto", ent.TipoMovimiento!.Id)
      .set("folio", ent.Codigo)
      .set("tipo", type);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<CxcCabecera>(`${this.baseUrl}/CuentasPorCobrar/ObtenerCxcCabeceraNavegacion`, { params }).subscribe((entity) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(entity);
    });
  }

  setEntity(entity: CxcCabecera) {
    if (!entity) {
      this.new();
    } else {
      let ent: CxcCabecera = this.myForm.value;
      ent = { ...entity };
      this.sourceReset(ent.Detalle);
      this.calcTotales();
      this.myForm.reset(ent);
      this.cd.detectChanges();
    }
  }

  new() {
    const elem: CxcCabecera = this.myForm.value;
    this.cService.getEmptyEntity("CxcCabecera").subscribe((ent: CxcCabecera) => {
      ent.Detalle = [];
      this.sourceReset();
      ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
      ent.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
      ent.Fecha = new Date();
      ent.Anio = ent.Fecha.getFullYear();
      ent.TipoMovimiento = { Id: this.tipoMovimientoActual.Id, Clave: this.tipoMovimientoActual.Clave, Nombre: this.tipoMovimientoActual.Nombre } as TipoMovimientoCXC;
      this.getNextFolio(ent).subscribe((folio: number) => {
        ent.Codigo = folio;
        this.setEntity(ent);
        const txtFolio: any = this.txtFolio;
        txtFolio.tagInput.nativeElement.focus();
      });
    });
  }




  getNextFolio(ent: CxcCabecera): Observable<number> {
    const anio: number = ent.Anio;
    const params = new HttpParams().set("anio", anio).set("idTipoMovto", ent.TipoMovimiento!.Id);
    return this.http.get<number>(`${this.baseUrl}/CuentasPorCobrar/ObtenerSiguienteClave`, { params });
  }





  // openConvertions(cb: any) {
  //   const b: any = this.ctrlConversiones;
  //   this.ms.openModal(b, (e: any) => {
  //     cb && cb(e);
  //   })
  // }


  // isValidConcepts(throwMessage: boolean = true): boolean {


  //   const conceptos: TraspasoDetalle[] = [...this.source.filter(P => P.Cantidad > 0)];

  //   let count = conceptos.filter(P => P.Cantidad>0).length;

  //   if (count > 1 && throwMessage) {
  //     Swal.fire({ text: `No puede haber conceptos con cantidad menor o igual a cero, verifique.`, icon: 'info', })
  //     return false;
  //   }
  //   return true;
  // }


  save() {

    if (this.saving) return;
    // this.myForm.get('Clave')!.setValue(this.myForm.value.Folio);

    if (!this.permiteGuardar) {
      Swal.fire({ text: `No es posible realizar modificaciones a la captura de Movimientos de Cuentas por Cobrar.`, icon: 'error', })
      return;
    }




    let ent: CxcCabecera = this.myForm.value;

    if (this.myForm.value.Id == 0) {
      ent.UsuarioCaptura = { Id: this.usuarioActual.numero, Clave: this.usuarioActual.numero, Nombre: this.usuarioActual.nombreUsuario } as Usuario;
      ent.Fecha = new Date();
      ent.Anio = ent.Fecha.getFullYear();
    }

    ent.Detalle = this.source.filter(P => P.Cliente != null);
    if (ent.Detalle.length == 0) {
      Swal.fire({ text: `Debe de indicar por lo menos un Movimiento de Cuentas por Pagar`, icon: 'error', })
      return;
    }

    let detalle: any = ent.Detalle.filter(p => p.Cliente == null);
    if (detalle.length > 0) {
      Swal.fire({ text: `Debe indicar el Cliente en el detalle del movimiento.`, icon: 'error', })
      return;
    }

    detalle = ent.Detalle.filter(p => p.Cuenta == null);

    if (detalle.length > 0) {
      Swal.fire({ text: `Debe indicar la Cuenta en la captura de movimientos.`, icon: 'error', })
      return;
    }

    detalle = ent.Detalle.filter(p => p.Concepto == null);

    if (detalle.length > 0) {
      Swal.fire({ text: `Debe indicar el Concepto en la captura de movimiento.`, icon: 'error', })
      return;
    }

    detalle = ent.Detalle.filter(p => p.ImporteAbono == 0 && p.ImporteCargo == 0);

    if (detalle.length > 0) {
      Swal.fire({ text: `Debe indicar un importe de Cargo o Abono en el detalle del movimiento.`, icon: 'error', })
      return;
    }








    ent.Detalle.map(item => {
      if (item.Id == 0) {
        if (item.ImporteCargo > 0) {
          item.Tipo = "C";
        } else {
          item.Tipo = "A";
        }
      }
    })

    // if (ent.Detalle.filter((P: any) => P.Cantidad <= 0).length > 0) {
    //   Swal.fire({ text: `No puede haber Productos con un conteo menor o igual a cero, por favor verifique.`, icon: 'error', })
    //   return;
    // }
    this.saving = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/CuentasPorCobrar/GuardarCxcCabecera`, ent).subscribe((result) => {
      this.saving = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: CxcCabecera = JSON.parse(result.message);
        this.myForm.reset(t);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
  }



  acceptDescription() {
    //this.source[this.indexEditing].Descripcion = this.myForm.value.DescripcionAdicional;
    this.showDescription = false;
    this.source = [...this.source];

    this.navigateColumns && this.initEditor(this.indexEditing, 3);

  }

  cancelDescription() {
    this.showDescription = false;
    this.navigateColumns && this.initEditor(this.indexEditing, 1);
  }

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new();
        this.blockCombos = false;
        break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.findEntityByParamsNavigate(button); break;
      case "left": this.findEntityByParamsNavigate(button); break;
      case "right": this.findEntityByParamsNavigate(button); break;
      case "last": this.findEntityByParamsNavigate(button); break;
      case "return": this.findEntityByParams();
        this.blockCombos = false;
        setTimeout(() => {
          const txtFolio: any = this.txtFolio;
          txtFolio.tagInput.nativeElement.focus();
        }, 100);
        break;
    }
  }


  print() {

    if (this.myForm.value.Id == 0) {
      Swal.fire({
        text: "Es necesario que primero guarde su captura antes de poder imprimirlo, por favor verifique.",
        icon: 'warning',
      });
      return;
    }


    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE MOVIMIENTOS DE CUENTAS POR COBRAR',
      Dato1: '',
      Opc1: true,
    }
    let filtro: FilterOptions | undefined = this.filtroMovCxc.FilterOptions?.find(p => p.Campo == 'cab.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroMovCxc.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroMovCxc, '/CuentasPorCobrar/ReporteCargosyAbonosCXC');

  }



  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent: CxcCabecera = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let anio: number = ent.Anio;
    this.cxcService.obtenerCxcCabecera(anio, ent.TipoMovimiento!.Id, ent.Codigo).subscribe((movto) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(movto);
    });
  }

}
