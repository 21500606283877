<div class="list-group-item d-flex border-top-0">
  <div class="me-3 fs-16px"><i class="fa fa-calculator text-gray-500 fa-fw"></i></div>
  <div class="flex-fill">
    <div class="fs-14px lh-12 mb-2px fw-bold text-dark">Calculadora de depreciación por el método de línea recta</div>
    <div class="mb-1 fs-12px" style="width: 600px;">
      <div class="text-gray-600 flex-1">
        <p>En el valor del activo a ingresar digite el valor total del activo según las normas contables y/o fiscales,
          que
          puede incluir el valor de la compra más otras erogaciones capitalizables.</p>
        <p>
          En el valor residual ingrese el valor que se ha calculado como residual para el activo a depreciar. Si no hay
          valor residual deje el campo en cero.
        </p>
        <p>
          Digite la vida útil del activo en años, que es el periodo durante el cual se espera que el activo contribuya a
          generar beneficios a la empresa, y será el tiempo durante el cual se calculará y distribuirá la depreciación.
        </p>
        <p>
          El resultado genera una tabla de depreciación mensual, con una columna que mostrará la depreciación acumulada
          mes a mes. Además, genera un resumen indicando el valor que se deprecia mensual y anualmente, y el número de
          meses de depreciación.
        </p>
      </div>
    </div>
    <hr class="mb-10px bg-gray-600">
    <form [formGroup]="myForm" autocomplete="off" *ngIf="!mostrarCalculo">

      <div class="p-3" style="width:600px; border: 1px dashed;">
        <div class="input-group mb-3">
          <div class="input-group-text">$</div><input placeholder="Valor del activo" formControlName="Valor"
            type="number" class="form-control" #txtValorActivo>

        </div>
        <div class="input-group mb-3">
          <div class="input-group-text">$</div><input placeholder="Valor residual" formControlName="Residual"
            type="number" class="form-control">

        </div>
        <div class="input-group mb-3">
          <div class="input-group-text"></div><input placeholder="Vida útil en años" formControlName="Anios"
            type="number" class="form-control">
        </div>
        <div (click)="calc()" class="btn btn-success d-block"> Calcular </div>
      </div>
    </form>
    <div class="p-3" style="width:600px; border: 1px dashed;" *ngIf="mostrarCalculo">
      <div class="panel-body ng-star-inserted">
        <div class="table-responsive">
          <table class="table table-bordered mb-0 table-striped">
            <thead>
              <tr>
                <th style="text-align: center;background-color: rgba(255, 166, 0, 0.856);color: #ffff;" colspan="2">
                  RESUMÉN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Depreciación mensual:</td>
                <td style="text-align: right;">{{depreciacionMensual | number: '1.2'}}</td>
              </tr>
              <tr>
                <td>Depreciación anual:</td>
                <td style="text-align: right;">{{(depreciacionMensual*12) | number: '1.2'}}</td>
              </tr>
              <tr>
                <td>Número de periodos a depreciar:</td>
                <td style="text-align: right;">{{myForm.value.Anios * 12}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div (click)="newCalc()" class="btn btn-success d-block"> Nuevo cálculo </div>
    </div>


    <div class="p-3" style="width:600px; border: 1px dashed;" *ngIf="mostrarCalculo">
      <div class="panel-body ng-star-inserted">
        <div class="table-responsive">
          <ng-scrollbar style="height: 450px;">
            <table class="table table-bordered mb-0 table-striped">
              <thead>
                <tr>
                  <th style="text-align: center;background-color: rgba(255, 166, 0, 0.856);color: #ffff;" colspan="3">
                    TABLA DE DEPRECIACIÓN</th>
                </tr>
                <tr>
                  <th> Periodo</th>
                  <th> Depreciación mensual</th>
                  <th> Depreciación acumulada</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of lista">
                  <td>{{item.periodo}}</td>
                  <td>{{item.mensual | number: '1.2'}}</td>
                  <td>{{item.acumulada | number: '1.2'}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </div>
      <div (click)="newCalc()" class="btn btn-success d-block"> Nuevo cálculo </div>
    </div>
  </div>
</div>
