import { Component, Input, OnInit } from '@angular/core';
import { ExistenciaProducto, ExistenciaProductoPaquete } from 'src/app/ventas/interfaces/venta.interface';
import { ModalService } from '../../service/modal.service';
import { GuiLocalization } from '@generic-ui/ngx-grid';
import { InventarioService } from '../../Inventarios/services/inventario.service';

@Component({
  selector: 'app-ctrl-existencias-page',
  templateUrl: './ctrl-existencias-page.component.html',
  styles: [
  ]
})
export class CtrlExistenciasPageComponent implements OnInit {

  lista: ExistenciaProducto[] = [];
  listaPaquetes: ExistenciaProductoPaquete[] = [];
  total: number = 0;
  localization: GuiLocalization = {
    'translation': {
      'sourceEmpty': 'No hay elementos para mostrar',
    }
  };

  @Input()
  idEmpresa: number = 0;

  @Input()
  idProducto: number = 0;

  @Input()
  manejaPaquetes: boolean = false;

  @Input()
  producto: string = '';

  constructor(private ms: ModalService, private is: InventarioService) {

  }

  seleccionar(sel: ExistenciaProductoPaquete) {
    this.ms.closeModal(sel);
  }

  ngOnInit(): void {
    if (!this.manejaPaquetes) {
      this.is.ObtenerExistencas(this.idEmpresa, this.idProducto, 0).subscribe((lista) => {
        this.lista = lista;
        lista.forEach((item) => {
          this.total += item.existencia;
        })
      })
    } else {
      this.is.ObtenerExistenciasPaquetes(this.idEmpresa, this.idProducto).subscribe((lista) => {
        this.listaPaquetes = lista;
        lista.forEach((item) => {
          this.total += item.existencia;
        })
      })
    }
  }

  closeModal() {
    this.ms.closeModal(null);
  }

}
