<app-container-base [hasCounter]="false" [searchItemsCombo]="false" [hasBar]="false" title="Factura Global de tickets"
  icon="fa fa-book-atlas" subTitle="Emisión de Factura Global de Tickets">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información de los tickets" style="width:750px;">
        <div class="panel-content">
          <div class="card border-0 mb-0">
            <div class="card-header h6 mb-0 bg-none p-0">
              <i class="fa fa-building fa-lg fa-fw text-dark text-opacity-50 me-1"></i> Empresa y Sucursal para
              realizar la Factura Global de Tickets
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Empresa"
                    [topLabel]="true" label="Empresa" [entityName]="'Empresa'" [tabIndex]="1" [widthLabelColumns]="12"
                    [widthTextColumns]="12" (onSelectedItem)="onSelectedItem($event,'Empresa')"
                    [inputProperty]="'Nombre'" [widthRightColumns]="0" #cboEmpresa />
                </div>
                <div class="col-lg-4">
                  <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Sucursal"
                    [inputProperty]="'Nombre'" [setItemsList]="getItemsSucs" [topLabel]="true" label="Sucursal"
                    [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12"
                    (onSelectedItem)="onSelectedItem($event,'Sucursal')" [widthRightColumns]="0" #cboSucursal />
                </div>
                <div class="col-lg-4">
                  <components-ui-date-box [widthLabelColumns]="12" label="Fecha de Emisión" [tabIndex]="3"
                    formControlName="FechaEmision" [topLabel]="true" />
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0 mb-0">
            <div class="card-header h6 mb-0 bg-none p-0">
              <i class="fa fa-calendar-check fa-lg fa-fw text-dark text-opacity-50 me-1"></i> Filtro de fechas para
              buscar tickets no facturados
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <components-ui-date-box [widthLabelColumns]="12" label="Desde" [tabIndex]="4"
                    formControlName="FechaDesde" [topLabel]="true" />
                </div>
                <div class="col-lg-4">
                  <components-ui-date-box [widthLabelColumns]="12" label="Hasta" [tabIndex]="5"
                    formControlName="FechaHasta" [topLabel]="true" />
                </div>
                <div class="col-lg-4" style="text-align: right;">
                  <a (click)="searchTickets()" class="btn btn-blue me-5px mt-2"><i
                      class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-magnifying-glass')}}"></i> Buscar
                    tickets</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Tickets" style="width:750px;">
        <div class="panel-content">
          <div class="table-responsive mb-3"
            style="max-height: 250px; height: 250px; overflow-y:auto; border: 1px solid;">
            <table class="table table-sm table-hover">
              <thead style="background-color: #348fe273;">
                <tr>
                  <th>
                    <div class="form-check">
                      <input (click)="markAll()" type="checkbox" class="form-check-input" id="product1" [checked]="selectAll">
                    </div>
                  </th>
                  <th>Serie/Folio</th>
                  <th>Fecha</th>
                  <th style="text-align:right;">SubTotal</th>
                  <th style="text-align:right;">IVA</th>
                  <th style="text-align:right;">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of tickets; let i = index">
                  <td class="w-10px align-middle">
                    <div class="form-check">
                      <input (click)="mark(i)" type="checkbox" class="form-check-input" [checked]="item.Seleccionado" id="product1">
                    </div>
                  </td>
                  <td>{{item.SerieString}}-{{item.Folio}}</td>
                  <td>{{getDate(item.FechaEmision)}}</td>
                  <td style="text-align:right;">{{item.SubTotal | number:'1.2-6'}}</td>
                  <td style="text-align:right;">{{item.TotalIvaTrasladado | number:'1.2-6'}}</td>
                  <td style="text-align:right;">{{item.Total | number:'1.2-6'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-12" style="text-align: right;">
            <a (click)="proceed()" class="btn btn-success me-5px mt-2"><i
                class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-circle-check')}}"></i> Emitir Factura</a>
          </div>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
