<div class="card text-center border-0" cdkDrag
  [style]="getStyle">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar Tipo Figura
  </div>
  <div class="card-body bg-light">

    <div class="tab-content panel rounded-0 p-3 m-0">
      <div class="tab-pane fade active show" role="tabpanel">
        <div class="card-header h6 mb-1 bg-none p-2 text-orange">
          <i class="fa fa-user-tag fa-lg fa-fw me-1"></i>
          Seleccione una Figura
        </div>
        <component-ui-combo-box [widthList]="500" [filterQuery]="'Figura != null'" [tabIndex]="6"
          [entityName]="'Chofer'" [widthTextColumns]="12" [widthLabelColumns]="2" [inputProperty]="'Nombre'"
          [widthRightColumns]="0" (onSelectedItem)="selectedCombo($event)" [listProperty]="'ClaveNombre'" />
        <form [formGroup]="myForm" autocomplete="off">
          <div class="card-header h6 mb-3 bg-none p-2" style="margin-top: 5px;">
            <i class="fa fa-circle-info fa-lg fa-fw me-1"></i>
            Información de la Figura
          </div>
          <div class="input-group mb-3">
            <div class="input-group-text w-100px">RFC</div><input type="text" readonly formControlName="RFCFigura"
              class="form-control">
          </div>
          <div class="input-group mb-3">
            <div class="input-group-text w-100px">Nombre</div><input type="text" readonly formControlName="NombreFigura"
              class="form-control">
          </div>
          <div class="input-group mb-3">
            <div class="input-group-text w-100px">Licencia</div><input type="text" readonly formControlName="NumLicencia"
              class="form-control">
          </div>
          <div class="input-group mb-3">
            <div class="input-group-text w-100px">Tipo</div><input type="text" readonly formControlName="TipoNombre"
              class="form-control">
          </div>
        </form>
      </div>
    </div>



  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div *ngIf="!saving" style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar
      </div>
      <div *ngIf="!saving" (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
    </div>
  </div>
</div>
