import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { CartaPorteService } from '../../services/cartaporte.service';
import { testEventRepositoryIsEmptyOnStart } from '@generic-ui/hermes';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { Cliente } from '../../interfaces/cliente.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { CartaPorte, CartaPorteMercancia, Mercancia, TipoFigura, Ubicacion } from '../../interfaces/cartaporte.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import * as moment from 'moment';
import { Tipo } from '../../interfaces/tipodeimpuesto.interface';
import { Unidad } from '../../interfaces/unidad.interface';
import { EventsService } from 'src/app/service/events.service';
import { EventListenersModifier } from '@popperjs/core/lib/modifiers/eventListeners';

@Component({
  selector: 'app-carta-porte-page',
  templateUrl: './carta-porte-page.component.html',
  styles: [
  ]
})
export class CartaPortePageComponent implements OnInit {

  @ViewChild('cboComp')
  public cboComp!: ElementRef<any>;

  @ViewChild('cboVehiculo')
  public cboVehiculo!: ElementRef<any>;

  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;
  private eventsService = inject(EventsService);

  saving: boolean = false;
  soloNacional: boolean = false;
  info: UserLogged = {} as UserLogged;
  blockCombos: boolean = false;
  listaComprobantes: ComboBoxEntity[] = [];
  listaSeries: ComboBoxEntity[] = [];

  listaUbicaciones: Ubicacion[] = [];
  ubicacionActual: Ubicacion | null = null;
  selectedIndexUbicacion: number = -1;
  editandoUbicacion: boolean = false;
  clickEditarUbicacion: boolean = false;

  listaMercancias: CartaPorteMercancia[] = [];
  mercanciaActual: CartaPorteMercancia | null = null;
  selectedIndexMercancia: number = -1;
  editandoMercancia: boolean = false;
  clickEditarMercancia: boolean = false;

  listaFiguras: TipoFigura[] = [];
  figuraActual: TipoFigura | null = null;
  selectedIndexFigura: number = -1;
  editandoFigura: boolean = false;
  clickEditarFigura: boolean = false;

  public GuiColumnAlign = GuiColumnAlign;


  get getSelectedMercancia(): CartaPorteMercancia {
    return { ...this.mercanciaActual! };
  }

  get getSelectedUbicacion(): Ubicacion {
    return { ...this.ubicacionActual! };
  }

  get getSelectedTipoFigura(): TipoFigura {
    return { ...this.figuraActual! };
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    Documento: [{} as DocumentoVenta],
    SerieString: [''],
    Serie: [{} as SerieVenta],
    Folio: [0],
    Cliente: [{} as Cliente],
    Elabora: [{} as Usuario],
    FechaEmision: [Date],
    Version: [''],
    IdCCP: [''],
    RegimenAduanero: [''],
    TranspInternac: [false],
    EntradaMerc: [false],
    SalidaMerc: [false],
    EntradaSalidaMerc: [''],
    PaisOrigenDestino: [''],
    ViaTransporte: [''],
    TipoTransporte: [0],
    TotalDistRec: [0],
    EsRegistroISTMO: [false],
    RegistroISTMO: [''],
    UbicacionPoloOrigen: [''],
    UbicacionPoloDestino: [''],
    Ubicaciones: [[] as Ubicacion[]],
    Mercancias: [{} as Mercancia],
    Figuras: [[] as TipoFigura[]],
  })

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: this.myForm.value.Id == 0,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  constructor(private cpService: CartaPorteService,
    private utileService: UtilsService,
    private fb: FormBuilder,
    private fServices: FacturacionService,
    private cService: ContainerBaseService) {

  }

  onCloseEditUbicacion(mov: Ubicacion): void {
    if (!mov) {
      this.editandoUbicacion = false;
      this.selectedIndexUbicacion = -1;
      this.ubicacionActual = null;
      this.resetUbicaciones(this.listaUbicaciones, false);
    } else {
      this.listaUbicaciones[this.selectedIndexUbicacion] = mov;
      this.myForm.controls["Ubicaciones"].setValue(this.listaUbicaciones.filter(P => P.IDUbicacion));
      this.resetUbicaciones(this.listaUbicaciones, true);
      this.setTotalDistancia();
      this.editandoUbicacion = false;
      setTimeout(() => {
        this.AgregarUbicacion();
      }, 100);
    }
  }

  selectedCombo(ent: ComboBoxEntity) {
    if (ent) {
      this.cpService.getUnidad(ent.Id).subscribe((result) => {
        let u: Unidad = JSON.parse(result.message)
        if (!u.Aseguradora) {
          Swal.fire({ text: `El Vehículo no tiene asignada una aseguradora.`, icon: 'error', })
          return;
        }
        if (!u.NumPoliza) {
          Swal.fire({ text: 'El Vehículo no tiene asignado un número de póliza.', icon: 'error', })
          return;
        }
        const mercancia: Mercancia = {
          Id: 0,
          PesoBrutoTotal: 0,
          UnidadPeso: '',
          PesoNetoTotal: 0,
          NumTotalMercancias: 0,
          CargoPorTrasacion: 0,
          Mercancias: [],//CartaPorteMercancia[],
          AutoTransporte: {
            Id: 0,
            PermSCT: u.TipoPermisoSCT.Clave,
            NumPermisoSCT: u.NumPersmisoSCT,
            IdentificacionVehicular: {
              Id: 0,
              ConfigVehicular: u.ConfigAutotransporte,
              PlacaVM: u.Placas,
              PesoBrutoVehicular: u.PesoBrutoVehicular,
              AnioModeloVM: u.Anio
            },
            Seguros: [{
              Id: 0,
              AseguraRespCivil: u.Aseguradora,
              PolizaRespCivil: u.NumPoliza,
              AseguraMedAmbiente: '',
              PolizaMedAmbiente: '',
              AseguraCarga: '',
              PolizaCarga: '',
              PrimaSeguro: 0,
              AutoTransporte: null
            }],
            Remolques: [],
          },
          Eliminado: false,
          Baja: false
        }

        this.myForm.controls["Mercancias"].setValue(mercancia);


      })
    }
  }

  // keydown(e: any) {
  //   if (!this.ubicacionActual && !this.figuraActual && !this.mercanciaActual) {
  //     if (e.code == "F2") {
  //       e.preventDefault();
  //       this.AgregarUbicacion();
  //     } else if (e.code == "F3") {
  //       e.preventDefault();
  //       this.AgregarMercancia();
  //     } else if (e.code == "F4") {
  //       e.preventDefault();
  //       this.AgregarFigura();
  //     }
  //   }
  // }

  onCloseEditFigura(mov: TipoFigura): void {
    this.editandoFigura = false;
    if (!mov) {
      this.selectedIndexFigura = -1;
      this.figuraActual = null;
      this.resetFiguras(this.listaFiguras, false);
    } else {
      const num = this.listaFiguras.filter(P => P.NumLicencia == mov.NumLicencia).length;
      if (num == 0) {
        mov.Id = 0;
        this.listaFiguras[this.selectedIndexFigura] = mov;
        this.myForm.controls["Figuras"].setValue(this.listaFiguras.filter(P => P.NumLicencia));
        this.resetFiguras(this.listaFiguras, true);
      } else {
        this.resetFiguras(this.listaFiguras, false);
      }
    }
  }

  onCloseEditMercancia(mov: CartaPorteMercancia): void {
    this.editandoMercancia = false;
    if (!mov) {
      this.selectedIndexMercancia = -1;
      this.mercanciaActual = null;
      this.resetMercancias(this.listaMercancias, false);
    } else {
      this.listaMercancias[this.selectedIndexMercancia] = mov;
      let m = this.myForm.value.Mercancias;
      m.Mercancias = this.listaMercancias.filter(P => P.BienesTransp);
      this.myForm.controls["Mercancias"].setValue(m);
      this.resetMercancias(this.listaMercancias, true);
    }
  }

  editarUbicacion(index: number) {
    this.clickEditarUbicacion = true;
    this.editandoUbicacion = true;
    this.selectedIndexUbicacion = index;
    this.ubicacionActual = { ...this.listaUbicaciones[index] };
  }
  editarMercancia(index: number) {
    this.clickEditarMercancia = true;
    this.editandoMercancia = true;
    this.selectedIndexMercancia = index;
    this.mercanciaActual = { ...this.listaMercancias[index] };
  }

  deleteRowUbicacion(index: number) {

    Swal.fire({
      title: '¿Desea eliminar la ubicación?',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.listaUbicaciones.splice(index, 1);
        this.listaUbicaciones = [...this.listaUbicaciones];
        this.setTotalDistancia();
      }
    })

  }

  deleteRowFigura(index: number) {

    Swal.fire({
      title: '¿Desea eliminar la figura?',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.listaFiguras.splice(index, 1);
        this.listaFiguras = [...this.listaFiguras];
      }
    })

  }

  deleteRowMercancia(index: number) {

    Swal.fire({
      title: '¿Desea eliminar la mercancía?',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.listaMercancias.splice(index, 1);
        this.listaMercancias = [...this.listaMercancias];
      }
    })

  }

  resetFiguras(details: TipoFigura[] | null, agregar: boolean = false) {
    if (details) {
      this.listaFiguras = [...details];
    } else {
      this.listaFiguras = []
    }
    if (agregar && !this.clickEditarFigura) {
      this.listaFiguras = [...this.listaFiguras, this.nuevaFigura()];
    }

    this.editandoFigura = false;
    this.selectedIndexFigura = -1;
    this.figuraActual = null;
    this.clickEditarFigura = false;

    setTimeout(() => {
      const rows = document.getElementById("divFiguras")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
      if (rows) {
        rows.forEach((item, index) => {
          item.classList.remove("editing-row");
        })
      }
    }, 100);

  }

  resetUbicaciones(details: Ubicacion[] | null, agregar: boolean = false) {
    if (details) {
      this.listaUbicaciones = [...details];
    } else {
      this.listaUbicaciones = []
    }
    if (agregar && !this.clickEditarUbicacion) {
      this.listaUbicaciones = [...this.listaUbicaciones, this.nuevaUbicacion()];
    }

    this.editandoUbicacion = false;
    this.selectedIndexUbicacion = -1;
    this.ubicacionActual = null;
    this.clickEditarUbicacion = false;

    setTimeout(() => {
      const rows = document.getElementById("divUbicacion")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
      if (rows) {
        rows.forEach((item, index) => {
          item.classList.remove("editing-row");
        })
      }
    }, 100);

  }

  resetMercancias(details: CartaPorteMercancia[] | null, agregar: boolean = false) {
    if (details) {
      this.listaMercancias = [...details];
    } else {
      this.listaMercancias = []
    }
    if (agregar && !this.clickEditarMercancia) {
      this.listaMercancias = [...this.listaMercancias, this.nuevaMercancia()];
    }

    this.editandoMercancia = false;
    this.selectedIndexMercancia = -1;
    this.mercanciaActual = null;
    this.clickEditarMercancia = false;

    setTimeout(() => {
      const rows = document.getElementById("divMercancias")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
      if (rows) {
        rows.forEach((item, index) => {
          item.classList.remove("editing-row");
        })
      }
    }, 100);

  }

  setTotalDistancia() {
    setTimeout(() => {

      let suma = 0;
      this.listaUbicaciones.forEach((item) => {
        if (item.DistanciaRecorrida) { suma += parseFloat(String(item.DistanciaRecorrida)); }
      })
      this.myForm.controls["TotalDistRec"].setValue(suma);
    }, 100);
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.cpService.obtenerTipoCartaPorte().subscribe((result) => {
      this.soloNacional = result.success;
      this.getComps();
    })
  }

  onSelectedItem(ent: ComboBoxEntity, type: string) {

  }

  initEditMercancia(index: number) {
    setTimeout(() => {

      this.selectedIndexMercancia = index;
      const sel: any = this.listaMercancias[this.selectedIndexMercancia];
      this.mercanciaActual = sel;
      this.editandoMercancia = true;
      const el = document.getElementById("divMercancias")!.querySelector(`[ng-reflect-index='${this.selectedIndexMercancia}']`);
      if (el) {
        el.classList.add("editing-row");
        el.scrollIntoView({ block: "center" });
      }
    }, 100);
  }

  initEditUbicacion(index: number) {
    setTimeout(() => {

      this.selectedIndexUbicacion = index;
      const sel: any = this.listaUbicaciones[this.selectedIndexUbicacion];
      this.ubicacionActual = sel;
      this.editandoUbicacion = true;
      const el = document.getElementById("divUbicacion")!.querySelector(`[ng-reflect-index='${this.selectedIndexUbicacion}']`);
      if (el) {
        el.classList.add("editing-row");
        el.scrollIntoView({ block: "center" });
      }
    }, 100);
  }

  initEditFiguras(index: number) {
    setTimeout(() => {
      this.selectedIndexFigura = index;
      const sel: any = this.listaFiguras[this.selectedIndexFigura];
      this.figuraActual = sel;
      this.editandoFigura = true;
      const el = document.getElementById("divFiguras")!.querySelector(`[ng-reflect-index='${this.selectedIndexFigura}']`);
      if (el) {
        el.classList.add("editing-row");
        el.scrollIntoView({ block: "center" });
      }
    }, 100);
  }

  AgregarFigura() {
    this.initEditFiguras(this.listaFiguras.length - 1);
  }

  AgregarMercancia() {
    if (!this.myForm.value.Mercancias) {
      Swal.fire({ text: "Primero indique el vehículo.", icon: 'info', })
      return;
    }
    this.initEditMercancia(this.listaMercancias.length - 1);
  }

  AgregarUbicacion() {
    this.initEditUbicacion(this.listaUbicaciones.length - 1);
  }

  nuevaMercancia(): CartaPorteMercancia {
    return {
      Id: 0,
      ClaveArticuloInterno: '',
      BienesTransp: '',
      ClaveSTCC: '',
      Descripcion: '',
      Cantidad: 0,
      ClaveUnidad: '',
      Unidad: '',
      Dimensiones: '',
      ColocarMaterialPeligroso: false,
      MaterialPeligroso: false,
      CveMaterialPeligroso: '',
      Embalaje: '',
      DescripEmbalaje: '',
      SectorCOFEPRIS: '',
      NombreIngredienteActivo: '',
      NomQuimico: '',
      DenominacionGenericaProd: '',
      DenominacionDistintivaProd: '',
      Fabricante: '',
      FechaCaducidad: null,
      LoteMedicamento: '',
      FormaFarmaceutica: '',
      CondicionesEspTransp: '',
      RegistroSanitarioFolioAutorizacion: '',
      PermisoImportacion: '',
      FolioImpoVUCEM: '',
      NumCAS: '',
      RazonSocialEmpImp: '',
      NumRegSanPlagCOFEPRIS: '',
      DatosFabricante: '',
      DatosFormulador: '',
      DatosMaquilador: '',
      UsoAutorizado: '',
      PesoEnKG: 0,
      ValorMercancia: 0,
      Moneda: '',
      FraccionArancelaria: '',
      UUIDComercioExt: '',
    };
  }

  nuevaFigura(): TipoFigura {
    return {
      Id: 0,
      Tipo: null,
      TipoNombre: '',
      RFCFigura: '',
      NumLicencia: '',
      NombreFigura: '',
      NumRegIdTribFigura: '',
      ResidenciaFiscalFigura: null,
      Partes: null,
    };
  }

  nuevaUbicacion(): Ubicacion {
    return {
      Id: 0,
      EsOrigen: false,
      EsDestino: false,
      EsAltura: false,
      EsCabotaje: false,
      TipoUbicacion: '',
      IDUbicacion: '',
      RFCRemitenteDestinatario: '',
      NombreRemitenteDestinatario: '',
      NumRegIdTrib: '',
      ResidenciaFiscal: null,
      NumEstacion: null,
      NombreEstacion: '',
      NavegacionTrafico: '',
      FechaHoraSalidaLlegada: new Date(),
      Hora: `${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`,
      TipoEstacion: null,
      DistanciaRecorrida: 0,
      Domicilio: null,
    };
  }

  getFecha(item: Ubicacion) {

    if (item.FechaHoraSalidaLlegada) {
      return `${moment(item.FechaHoraSalidaLlegada).format("DD/MM/YYYY")} ${item.Hora}`
    }
    return "";
  }

  getComps() {
    this.cpService.getComps(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaComprobantes = lista;
        this.listaComprobantes.map(P => { P.ClaveNombre = `${String(P.Clave).padStart(3, '0')} ${P.Nombre}` });
        if (lista.length == 1) {
          this.getSeries(lista[0].Id);
        } else {
          this.saving = false;
          const t: any = this.cboComp;
          setTimeout(() => {
            t.tagInput.nativeElement.focus();
          }, 100);
        }
      } else {
        this.saving = false;
        Swal.fire({
          text: "No se encontró ningún Documento para Carta Porte configurado.",
          icon: 'error',
        })
      }
    })
  }

  downloadFiles() {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.cpService.downloadFiles(this.myForm.value.Id, () => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
      });
    } else {
      Swal.fire({
        text: "El documento no se ha guardado.", icon: 'error',
      })
    }
  }

  getSeries(idDocumento: number) {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.fServices.getSeries(this.info.empresa!.numero, this.info.sucursal!.numero, idDocumento).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          this.getEmptyEntity(this.listaSeries[0].Id, idDocumento);
        } else {
          this.saving = false;
          setTimeout(() => {
            txt.tagInput.nativeElement.focus()
          }, 150);
        }
      }
    })
  }

  getEmptyEntity(idSerie: number = 0, idDocto: number = 0) {
    this.saving = true;
    this.cService.getEmptyEntity("CartaPorte").subscribe((ent) => {
      this.initializeEntity(ent, idSerie, idDocto)
    })
  }

  initializeEntity(ent: any, idSerie: number = 0, idDocto: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero };
    if (idSerie > 0) {
      ent.Serie = { Id: idSerie };
      ent.SerieString = this.listaSeries.filter((P) => P.Id == idSerie)[0].Serie;
    }
    ent.Documento = { Id: idDocto };

    this.getNextFolio(ent);
  }


  getNextFolio(ent: CartaPorte) {
    this.cpService.obtenerSiguienteFolio(ent.Empresa!.Id, ent.Sucursal!.Id, ent.Documento!.Id, ent.SerieString).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  new() {
    this.blockCombos = false;
    const ent: CartaPorte = this.myForm.value;
    this.getEmptyEntity(ent.Serie!.Id, ent.Documento!.Id);

  }

  onClickBarButton(button: string): void {

    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      // case "first": this.navigate(button); break;
      // case "left": this.navigate(button); break;
      // case "right": this.navigate(button); break;
      // case "last": this.navigate(button); break;
    }
  }
  save() {
    let ent: CartaPorte = this.myForm.value;
    if (ent.Id > 0) {
      return;
    }
    if (!ent.Mercancias) {
      Swal.fire({ text: "Debe de indicar el Vehículo donde se enviará la mercancía.", icon: 'info', })
      return false;
    }

    if (ent.Mercancias.Mercancias.length == 0) {
      Swal.fire({ text: "Debe de indicar las Mercancías.", icon: 'info', })
      return false;
    }

    if (!ent.Folio || ent.Folio == 0) {
      Swal.fire({ text: "Debe de indicar un folio.", icon: 'info', })
      return false;
    }

    if (!ent.Ubicaciones || ent.Ubicaciones?.length == 0) {
      Swal.fire({ text: "Debe de indicar las ubicaciones.", icon: 'info', })
      return false;
    }

    if (!ent.Figuras || ent.Figuras?.length == 0) {
      Swal.fire({ text: "Debe de indicar las figuras.", icon: 'info', })
      return false;
    }
    ent.Cliente = null;
    ent.Elabora = null;
    ent.Mercancias.PesoBrutoTotal = this.getPesoTotal;
    ent.Mercancias.NumTotalMercancias = this.getNumeroMercancias;
    if (ent.Mercancias.AutoTransporte.Remolques?.length == 0) {
      ent.Mercancias.AutoTransporte.Remolques = null;
    }
    ent.FechaEmisionString = moment(ent.FechaEmision).format("DD/MM/YYYY HH:mm");
    this.eventsService.publish('home:isLoading', { isLoading: true });
    console.log(ent)
    this.cpService.saveCartaPorte(ent).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const v: CartaPorte = JSON.parse(result.message);
        this.myForm.reset(v);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
          this.print(() => {
            //   this.sendEmail();
          });
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
    return;
  }


  print(cb: any = null) {
    let ent = this.myForm.value;
    if (ent.Id == 0) {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      Swal.fire({ text: `Primero debe de guardar el Documento.`, icon: 'error', })
      return;
    }
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.cpService.print(ent.Id).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.fServices.printDocument(this.fServices.base64ToArrayBuffer(result.message));
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'error', })
      }
    })
  }

  get getNumeroMercancias(): number {
    return this.listaMercancias.filter(P => P.BienesTransp).length
  }
  get getPesoTotal(): number {
    let suma: number = 0;
    this.listaMercancias.forEach((item) => {
      suma += item.PesoEnKG;
    })
    return suma;
  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  getDate(ent: CartaPorte) {
    ent.FechaEmision = new Date();
    this.saving = false;
    this.myForm.reset(ent);
    this.listaUbicaciones = [];
    this.listaFiguras = [];
    this.listaMercancias = [];
    this.resetUbicaciones(this.listaUbicaciones, true);
    this.resetFiguras(this.listaFiguras, true);
    this.resetMercancias(this.listaMercancias, true);
    const t: any = this.cboVehiculo;
    setTimeout(() => {
      t.tagInput.nativeElement.focus();
    }, 100);
  }


  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  get getItemsComps(): ComboBoxEntity[] {
    return this.listaComprobantes;
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent = this.myForm.value;
    this.saving = true;
    this.cpService.findEntityByParams(ent.Empresa.Id, ent.Sucursal.Id, ent.Documento.Id, ent.SerieString, ent.Folio).subscribe((cartaPorte: any) => {
      this.saving = false;
      this.setEntity(cartaPorte);
    });
  }

  setEntity(venta: CartaPorte) {
    if (!venta) {
      this.new();
    } else {
      let ent: CartaPorte = { ...venta };
      this.resetMercancias(ent.Mercancias.Mercancias);
      this.resetFiguras(ent.Figuras);
      this.resetUbicaciones(ent.Ubicaciones);
      this.myForm.reset(ent);
    }
  }

}
