import { Component, inject } from '@angular/core';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';

@Component({
  selector: 'app-vistas-crm-page',
  templateUrl: './vistas-crm-page.component.html',
  styles: [
  ]
})
export class VistasCrmPageComponent {
  private tabsNavService = inject(TabsNavService);

  cerrar() {
    this.tabsNavService.closeCurrentTab();
  }
}
