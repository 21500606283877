import { Component, ElementRef, inject, Injectable, LOCALE_ID, numberAttribute, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { CustomDatepickerI18nService, I18n } from 'src/app/component-ui/services/custom-datepicker-i18n.service';
import { Prospecto } from 'src/app/crm/interfaces/prospecto.interface';
import { Visita } from 'src/app/crm/interfaces/visita.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { CRMService } from '../../services/crm.service';
import { EventsService } from 'src/app/service/events.service';
import Swal from 'sweetalert2';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { subDays } from 'date-fns';
import { Result } from 'src/app/auth/interfaces';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';


  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day.toString().padStart(2, '0') + this.DELIMITER + date.month.toString().padStart(2, '0') + this.DELIMITER + date.year.toString().padStart(4, '0') : '';
  }
}

@Component({
  selector: 'app-visitas',
  templateUrl: './visitas.component.html',
  styleUrls: ['./visitas.component.scss'],
  providers: [
    I18n,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }
  ]
})
export class VisitasComponent {
  visitado: any = { id: 0, clave: "Clave", nombre: "Prospecto/Cliente", rfc: "RFC", }
  visita: Visita = { id: 0 } as Visita;
  date: Date = new Date();
  clock: HTMLSpanElement | null = null;
  tiempoTranscurrido: HTMLSpanElement | null = null;
  ctxMenuEvidencias: MenuItem[] | undefined;
  public itemsCombo: ComboBoxEntity[] = [];

  private modalService = inject(ModalService);
  private utilsService = inject(UtilsService);
  private crmService = inject(CRMService)
  private fb = inject(FormBuilder);


  public visitaForm: FormGroup = this.fb.group({
    fechaProximaVisita: ['', Validators.required],
    asunto: ['', Validators.required],
    comentarios: [''],
  });

  constructor() {
  }

  ngOnInit() {
    this.getVisitaActiva();
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
    clearInterval(this.tiempoTranscurridoId);
  }

  timerId: any = 0;
  initReloj() {
    this.timerId = setInterval(() => {
      this.clock = document.getElementById('clock');
      this.date = new Date();
      this.clock!.innerText = this.padLeft(2, '0', this.date.getDate()) + "/" + this.padLeft(2, '0', (this.date.getMonth() + 1)) + "/" + this.date.getFullYear() + " " + this.date.toLocaleTimeString('es-MX', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    }, 1000);
  }

  tiempoTranscurridoId: any = 0;
  initTiempoVisita() {
    this.tiempoTranscurridoId = setInterval(() => {
      this.tiempoTranscurrido = document.getElementById('tiempo-transcurrido');
      let today = new Date();
      let fechaInicio = new Date(this.visita.fechaHoraLlegada);
      // get total seconds between the times
      let delta = Math.abs(fechaInicio.getTime() - today.getTime()) / 1000;

      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      let seconds = Math.floor(delta % 60);  // in theory the modulus is not required


      let dias: string = days === 0 ? "" : (days + (days === 1 ? " día, " : " días, "));
      let horas: string = hours === 0 ? "" : (hours + (hours === 1 ? " hora, " : " horas, "));
      let minutos: string = minutes === 0 ? "" : (minutes + (minutes === 1 ? " minuto " : " minutos "));
      let segundos: string = seconds === 0 ? "0 segundos " : seconds + " segundos ";
      this.tiempoTranscurrido!.innerText = minutes === 0 ? segundos : (dias + horas + minutos);
      this.visita.duracionMinutos = (hours * 60) + minutes

      // console.log(diffMins + ":" + diffMs)
    }, 1000);
  }

  onDateSelect(fechaSeleccionada: NgbDate) {
    let dia = this.padLeft(2, '0', fechaSeleccionada.day);
    let mes = this.padLeft(2, '0', fechaSeleccionada.month);
    this.visita.fechaHoraProximaVisita = new Date(fechaSeleccionada.year, (fechaSeleccionada.month - 1), fechaSeleccionada.day, 0, 0, 0, 0);
  }
  padLeft(maxLength: number, fillString: string, value: string | number) {
    return value.toString().padStart(maxLength, fillString);
  }

  getVisitaActiva() {
    this.utilsService.isLoad(true);
    this.crmService.getVisitaActiva().subscribe({
      next: (resp: Visita) => {
        this.utilsService.isLoad(false);
        this.visita = resp;
        if (this.visita.id === 0) {
          clearInterval(this.tiempoTranscurridoId);
          this.initReloj();
          this.searchConfiguration = this.modalService.GetSearchConfiguration("VisitasSearchMobile", "");
          this.visitaForm.controls['asunto'].enable();
          this.visitaForm.reset();
          this.visitado = { id: 0, clave: "Clave", nombre: "Prospecto/Cliente", rfc: "RFC", };
        } else {
          this.visitaForm.controls['asunto'].disable();
          this.visitaForm.controls['asunto'].setValue(this.visita.asunto);
          let isCliente: boolean = (this.visita.cliente as any).id > 0;
          this.visitado = isCliente ? this.visita.cliente : this.visita.prospecto;
          clearInterval(this.timerId);
          this.initTiempoVisita();
        }
      },
      error: (err: Error) => {
        console.error(err);
        this.utilsService.isLoad(false);
      }
    })
  }

  guardarVisita() {
    if (this.visita.id === 0) {
      this.iniciarVisita();
    } else {
      this.terminarVisita();
    }
  }

  iniciarVisita() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pstn: GeolocationPosition) => {
        if (pstn.coords) {
          this.visita = {
            ...this.visita,
            asunto: this.visitaForm.controls["asunto"].value,
            fechaHoraLlegada: new Date(),
            latitud: pstn.coords.latitude.toString(),
            longitud: pstn.coords.longitude.toString(),
          }
          if (this.visita.asunto === "") {
            this.showAlert('Debe indicar el asunto de la visita.');
            return;
          }
          this.guardaEntidad(this.visita)
        }
      }, (err: GeolocationPositionError) => {
        Swal.fire({
          title: "Permiso de geolocalización",
          text: "Debe permitir la geolocalización para iniciar la visita.",
          icon: 'warning',
        }).then((result: any) => {
          this.guardarVisita();
        })
      }, { timeout: 10000 });
    }
  }

  terminarVisita() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pstn: GeolocationPosition) => {
        if (pstn.coords) {
          if (this.visitaForm.controls["fechaProximaVisita"].value === "" || this.time.includes("00:00")) {
            this.showAlert('Debe seleccionar la Fecha y Hora de la próxima visita.');
            return;
          } else if (this.evidencias.length === 0) {
            this.showAlert('Debe seleccionar al menos una evidencia.');
            return;
          }
          this.visita.latitudFinalizoVisita = pstn.coords.latitude.toString();
          this.visita.longitudFinalizoVisita = pstn.coords.longitude.toString();
          this.visita.fechaHoraSalida = new Date();
          this.visita.observaciones = this.visitaForm.controls["comentarios"].value ? this.visitaForm.controls["comentarios"].value : "";
          this.guardaEntidad(this.visita);
        }
      }, (err: GeolocationPositionError) => {
        Swal.fire({
          title: "Permiso de geolocalización",
          text: "Debe permitir la geolocalización para finalizar la visita.",
          icon: 'warning',
        }).then((result: any) => {
          this.guardarVisita();
        })
      }, { timeout: 10000 });
    }
  }


  guardaEntidad(visita: Visita) {
    this.utilsService.isLoad(true);
    this.crmService.guardarVisita(visita).subscribe({
      next: (resp: any) => {
        this.getVisitaActiva();
        if (resp.id > 0 && this.evidencias.length > 0) {
          let files = this.filesCaptured.concat(this.filesSelected);
          this.crmService.uploadEvidencias(files, resp.id, (resp: Result) => {
            console.log(resp);
            this.filesSelected = [];
            this.filesCaptured = [];
            this.evidencias = [];
            this.time = "00:00";
            this.meridian = "AM";
          });
        }
        this.utilsService.isLoad(false);
      }, error: (err: Error) => {
        console.error(err);
        this.utilsService.isLoad(false);
      }
    })
  }

  showAlert(message: string) {
    Swal.fire({ icon: 'warning', text: message }).then(() => { });
  }
  //#region TimePicker

  time: string = "00:00";
  meridian: string = "AM";
  hours: string = "00";
  minutes: string = "00";
  @ViewChild('hoursInput') hoursInput!: ElementRef<HTMLInputElement>;
  @ViewChild('minutesInput') minutesInput!: ElementRef<HTMLInputElement>;
  focusInput(event: any) {
    event.target.select()
  }

  blurHour(event: any) {
    if (Number(event.target.value) > 12) {
      this.hours = "12"
    }
    this.setValueTime();
  }

  blurMinute(event: any) {
    if (Number(event.target.value) > 59) {
      this.minutes = "59"
    }
    this.setValueTime();
  }

  cambioMeridian() {
    this.meridian = this.meridian === "AM" ? "PM" : "AM";
    this.setValueTime();
  }

  sumarValor(isHour: boolean) {
    if (isHour) {
      let oldValue = Number(this.hoursInput.nativeElement.value);
      oldValue = oldValue == 12 ? 0 : oldValue;
      this.hoursInput.nativeElement.value = this.padLeft(2, "0", (oldValue + 1));
    } else {
      let oldValue = Number(this.minutesInput.nativeElement.value);
      oldValue = oldValue == 59 ? -1 : oldValue;
      this.minutesInput.nativeElement.value = this.padLeft(2, "0", (oldValue + 1));
    }
    this.setValueTime();
  }

  restarValor(isHour: boolean) {
    if (isHour) {
      let oldValue = Number(this.hoursInput.nativeElement.value);
      oldValue = oldValue <= 1 ? 13 : oldValue;
      this.hoursInput.nativeElement.value = this.padLeft(2, "0", (oldValue - 1));
    } else {
      let oldValue = Number(this.minutesInput.nativeElement.value);
      oldValue = oldValue == 0 ? 60 : oldValue;
      this.minutesInput.nativeElement.value = this.padLeft(2, "0", (oldValue - 1));
    }
    this.setValueTime();
  }

  setValueTime() {
    this.time = this.hoursInput.nativeElement.value + ":" + this.minutesInput.nativeElement.value + " " + this.meridian;

    let hora = Number(this.hoursInput.nativeElement.value);
    hora = this.meridian === "PM" && Number(hora) > 0 ? hora + 12 : hora;
    this.visita.fechaHoraProximaVisita?.setHours(hora)
    this.visita.fechaHoraProximaVisita?.setMinutes(Number(this.minutesInput.nativeElement.value))
    this.visita.fechaHoraProximaVisita?.setSeconds(0);
    this.visita.fechaHoraProximaVisita?.setMilliseconds(0);

  }
  //#endregion

  //#region Busqueda Prospecto/Cliente
  @ViewChild('ctrlBusqueda')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  searchConfiguration: SearchConfiguration | null = null;

  get getColumns(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.columns;
    }
    return [];
  }

  get getFilter(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.filter;
    }
    return "";
  }

  get getPropertys(): string {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })
      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  entidadBusqueda: string = "";
  openSearch(entidad: string) {
    this.entidadBusqueda = entidad;
    const b: any = this.ctrlBusqueda;
    this.modalService.openModal(b, (e: any) => {
      if (e) {
        this.visitado = { id: e.Id, nombre: e.Nombre, rfc: e.Rfc.toUpperCase() }
        if (entidad.toLocaleLowerCase() === "cliente") {
          this.visita!.cliente = { Id: e.Id } as Cliente;
          this.visita.prospecto = null;
        } else {
          this.visita!.prospecto = { Id: e.Id } as Prospecto;
          this.visita.cliente = null;
        }
      }
    }, 'lg')
  }
  //#endregion

  //#region Cargado de Evidencias
  evidencias: any[] = [];
  filesSelected: File[] = [];
  onChangeFileSelected(event: any) {
    let files = event.target.files.length > 0 ? event.target.files : null;
    if (files) {
      for (const file of files) {
        this.filesSelected.push(file);
        let type = this.getTypeFile(file.type);
        this.evidencias.push({
          name: file.name,
          size: this.convertSize(file.size),
          icon: type.icon,
          type: file.type,
          color: type.color
        });
      }
    }
  }


  filesCaptured: File[] = [];
  onChangeFileCapture(event: any, typeFile: string) {
    let files = event.target.files.length > 0 ? event.target.files : null;
    if (files) {
      for (const file of files) {
        let newFile = this.createNewFile(file);
        this.filesCaptured.push(newFile);
        let fileCapture = newFile;
        let type = this.getTypeFile(fileCapture.type);
        this.evidencias.push({
          name: fileCapture.name,
          size: this.convertSize(fileCapture.size),
          icon: type.icon,
          type: fileCapture.type,
          color: type.color
        });
      }
    }
  }

  convertSize(size: any) {
    let fSExt = new Array('bytes', 'kb', 'mb', 'gb'),
      i = 0; while (size > 900) { size /= 1024; i++; }
    let exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[i];
    return exactSize;
  }

  getTypeFile(type: string) {
    if (type.split("/")[0].toLowerCase() === "image") {
      return { icon: "image", color: "bg-orange" };
    } else if (type.split("/")[0].toLowerCase() === "video") {
      return { icon: "video", color: "bg-red-600" };
    } else if (type.toLowerCase() === "application/pdf") {
      return { icon: "file-pdf", color: "bg-red" }
    } else if (type.toLowerCase() === "text/csv") {
      return { icon: "file-csv", color: "bg-green-600" };
    } else if (type.toLowerCase() === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || type.toLocaleLowerCase() === "application/vnd.ms-excel") {
      return { icon: "file-excel", color: "bg-green" };
    } else {
      return { icon: "file", color: "bg-blue" };
    }
  }

  createNewFile(file: File): File {
    let name = "";
    if (file.type.includes('video/')) {
      let filteredFilesVideoLength = this.filesCaptured.filter(x => x.type.includes('video/')).length;
      name = "Video_Evidencia_" + Number(filteredFilesVideoLength + 1);
    } else {
      let filteredFilesImageLength = this.filesCaptured.filter(x => x.type.includes('image/')).length;
      name = "Foto_Evidencia_" + Number(filteredFilesImageLength + 1);
    }
    let blob = file.slice(0, file.size);
    return new File([blob], name, { type: file.type });
  }
  //#endregion


  //#region ContextMenu

  //#endregion
}
