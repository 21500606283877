import { Component } from '@angular/core';

@Component({
  selector: 'app-utilerias-complit-page',
  templateUrl: './utilerias-complit-page.component.html',
  styleUrls: ['./utilerias-complit-page.component.scss']
})
export class UtileriasComplitPageComponent {

}
