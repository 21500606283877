import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticatedGuard, isNotAuthenticatedGuard } from './auth/guards';
import { ReportesPageComponent } from './home/pages/reportes.page/reportes.page.component';
import { ErrorPage } from './home/pages/error/error';



const routes: Routes = [

  {
    path: 'auth',
    canActivate: [isNotAuthenticatedGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    canActivate: [isAuthenticatedGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'mobile',
    canActivate: [isAuthenticatedGuard],
    loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule)
  },
  { path: 'reportes', component: ReportesPageComponent, data: { title: 'Reportes' } },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', component: ErrorPage }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule { }
