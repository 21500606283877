import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, inject, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppMenuService } from 'src/app/home/services/app-menus.service';
import { MatMenuTrigger, MatMenuItem } from '@angular/material/menu';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import * as qz from 'qz-tray';

@Component({
  selector: 'tabs-nav',
  templateUrl: './tabs-nav.component.html',
  styleUrls: ['./tabs-nav.component.scss']
})
export class TabsNavComponent implements OnInit {

  private titleService = inject(Title);
  private menuService = inject(AppMenuService);
  private eventsService = inject(EventsService);
  private utilsService = inject(UtilsService);

  @Input() pages: any[] = [];
  @Input() pagesminimized: any[] = [];
  @Output() pagesminimizedChange = new EventEmitter<any>();
  @Output() pagesChange = new EventEmitter<any>();


  @Output()
  onCloseTab: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  altKeyPressed: boolean = false;
  @Input()
  favoritos: any[] = [];
  @Output() favoritosChange = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger)
  contextMenu!: MatMenuTrigger;

  constructor() {

  }

  ngOnInit() {
    this.eventsService.subscribe(('home:closeCurrentTab'), (data) => {
      let idxCurrentTab = this.pages.findIndex(t => t.active);
      let currentTab = this.pages[idxCurrentTab];
      this.closeTab(currentTab, idxCurrentTab);
    })


    this.eventsService.subscribe(('home:selectTab'), (data) => {
      this.selectTab(data.tab, data.isAlredyOpen);
    })

    this.eventsService.subscribe(('home:changeTabTitle'), (data) => {
      this.pages.forEach((t: any) => {
        if (t.url === data.url && t.active) {
          t.title = data.tabTitle;
        }
      });
    });


    this.eventsService.subscribe(('home:getIsCurrentTab'), (data) => {
      let foundTab = this.getSearchIsOpenTab(data.tabName);
      data.callBack(foundTab ? (foundTab.url === data.tabName) : false);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["favoritos"]) {
      for (let i = 0; i < this.pages.length; i++) {
        const p = this.pages[i];
        p.isFav = this.favoritos.filter(f => f.menu === p.url).length > 0;
      }
    }
  }

  getSearchIsOpenTab(url: string): any {
    return this.pages.find(t => t.url === url);
  }

  closeTab(tab: any, index: number) {
    if (tab) {
      if (tab.url === "facturacion") {
        if (qz.websocket.isActive()) {
          qz.websocket.disconnect().then().catch((err: Error) => console.error(err));
        }
      }

      this.pages.splice(index, 1);
      this.pagesChange.emit(this.pages);
      if (tab.active) {
        this.pages.forEach((t) => (t.active = false));
        if (this.pages.length > 0) {
          if (index === 0) {
            this.pages[index].active = true;
          } else {
            this.pages[index - 1].active = true;
          }
        }
      }

      let tabActive = this.pages.find(p => p.active);
      let title = tabActive ? tabActive.title : "Inicio";
      this.titleService.setTitle('Complit | ' + (title || "Inicio"));
      if (this.pages.length == 0) {
        this.pagesminimized = [];
        this.pagesminimizedChange.emit([]);
      }
    }
  };

  selectTab(tab: any, isAlredyOpen: boolean = true) {
    this.guardandoFav = false;
    var title = 'Complit | ' + (tab.title || "Inicio");
    if (isAlredyOpen) {
      this.pages.forEach((t) => (t.active = false));
      tab.active = true;
    } else {
      this.pages.forEach((t) => (t.active = t.url === tab.url));
    }
    this.titleService.setTitle(title);
    this.pagesminimized = [];
    this.pagesminimizedChange.emit(this.pagesminimized);
    this.clearHelpLine();
  }

  clearHelpLine() {
    let pes = document.querySelectorAll('.lineadeayuda');
    pes.forEach((elem: any) => { elem.innerHTML = "" });
  }

  guardandoFav: boolean = false;
  guardarFav(tab: any, idx: number) {
    if (!this.guardandoFav) {
      this.guardandoFav = true;
      if (!tab.active) return;
      let guardar = !tab.isFav;
      let userLogged = this.utilsService.getUserLogged();
      this.menuService.guardarMenuFavorito(userLogged.nombreUsuario, tab.url, guardar).subscribe(resp => {
        this.favoritos = resp.map((p: any) => {
          let itemMenu = this.menuService.getComponentPages().find(x => x.url === p.menu);
          p = { ...p, title: itemMenu?.title }
          return p;
        });
        this.favoritosChange.emit(this.favoritos);
        this.pages[idx].isFav = resp.filter((f: any) => f.menu === tab.url).length > 0;
        this.guardandoFav = false;
      });
    }
  }

  minimizeAll() {
    if (this.pagesminimized.length == 0) {
      this.pagesminimized = [1];
      this.titleService.setTitle('Complit | Dashboard');
    } else {
      this.pagesminimized = [];
    }
    this.pagesminimizedChange.emit(this.pagesminimized);

  }

  closeAll() {
    this.eventsService.publish('home:showAlert', {
      message: `¿Seguro que desea cerrar todas las pantallas abiertas? (${this.pages.length})`, onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.pages = [];
          this.pagesChange.emit(this.pages);
          this.pagesminimized = [];
          this.pagesminimizedChange.emit(this.pagesminimized);
        }
      }
    });
  }
}
