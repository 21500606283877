import { Injectable, inject } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';

@Injectable({
  providedIn: 'root'
})
export class TabsNavService {

  private eventsService = inject(EventsService);

  constructor() { }

  closeCurrentTab() {
    this.eventsService.publish('home:closeCurrentTab');
  }

  getIsOpenTab(tabName: string, callBack: any = null) {
    this.eventsService.publish('home:getIsCurrentTab', { tabName, callBack });
  }

  selectTab(tab: any) {
    this.eventsService.publish('home:selectTab', { tab, isAlredyOpen: false });
  }
}
