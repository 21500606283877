<app-panel-base title="Formas de Pago">
  <div class="panel-content">
    <h5>Indique las Formas de Pago del Documento</h5>
    <gui-grid [autoResizeWidth]="false" [width]="450" [source]="source" [maxHeight]="150" [rowHeight]="30"
      [virtualScroll]="false" [rowSelection]="false" [theme]="'fabric'" (sourceEdited)="sourceEdited($event)"
      (cellEditSubmitted)="cellEditSubmitted()" [cellEditing]="cellEditing" [maxHeight]="190">
      <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
        <ng-template let-item="item" let-index="index">
          <i (click)="deleteRow(index)" *ngIf="item.Importe>0 && !blockPayment" class="fa fa-rectangle-xmark"
            style="color:red;font-weight: bold; cursor: pointer;"></i>
        </ng-template>
      </gui-grid-column>
      <gui-grid-column header="Importe" [cellEditing]="!blockPayment" [width]="80" [field]="'Importe'" [type]="'number'"
        [align]="GuiColumnAlign.RIGHT">
        <ng-template let-value="value">
          {{value > 0 ? (value | number:'1.2-6'): ''}}
        </ng-template>
      </gui-grid-column>
      <gui-grid-column header="Forma de Pago" [cellEditing]="false" [width]="250" [field]="'FormaPagoNombre'"
        [type]="'string'">
        <ng-template let-item="item" let-index="index">
          <select [disabled]="this.blockPayment" (change)="selectPayment(index, $event)" class="form-select form-select-sm" name="pagos" id="fps"
            *ngIf="item.FormaPago">
            <option *ngFor="let itemr of this.payments" selected="{{(itemr.Id == (item.FormaPago ? item.FormaPago.Id:0))?'selected':''}}" value="{{itemr.Id}}">{{itemr.Nombre}}</option>
          </select>
        </ng-template>
      </gui-grid-column>

    </gui-grid>
    <div style="text-align: right; margin-top: 5px;">
      <h5>Total capurado: {{getTotalCapturado |number:'1.2-6'}}</h5>
      <hr>
      <h5>Total documento: {{total|number:'1.2-6'}}</h5>
      <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
      <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
    </div>
  </div>
  <!-- <div class="formaspago panel-content">
    <button *ngFor="let item of payments" (click)="clickButton(item)"
      class="btn btn-{{(item.Clave == selectedPayment.Clave ? 'green':'orange')}} fs-15px formapago"> {{item.Clave}} -
      {{item.Nombre}}</button>
  </div> -->
</app-panel-base>
