import { Injectable, inject } from '@angular/core';
import { Result, UserLogged } from '../auth/interfaces';
import { ComboBoxEntity } from '../component-ui/interfaces/combo-text.interface';
import { DateEditTemplateComponent } from '@generic-ui/ngx-grid/feature/composition/src/column/edit/template/date-edit-template.component';
import { EventsService } from './events.service';
import { MenuItem } from '../component-ui/interfaces/checkboxlist.interface';
import * as moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import listPlugin from '@fullcalendar/list';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private eventsService = inject(EventsService);
  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient) { }

  tieneAccesoPantalla(numPantalla: number) {
    const params = new HttpParams().set("idPantalla", numPantalla);
    return this.http.get<Result>(`${this.baseUrl}/Config/TieneAccesoPantalla`, { params })
  }

  round(n: number) {
    return Math.round((n + Number.EPSILON) * 100) / 100
  }

  NumerosaLetras(cantidad: number) {

    let numero: string = String(cantidad);
    cantidad = parseFloat(String(numero));

    if (cantidad == 0) {
      return "CERO con 00/100 ";
    } else {
      let ent = cantidad.toString().split(".");
      let arreglo = this.separar_split(ent[0]);
      let longitud = arreglo.length;

      switch (longitud) {
        case 1:
          numero = this.unidades(arreglo[0]);
          break;
        case 2:
          numero = this.decenas(arreglo[0], arreglo[1]);
          break;
        case 3:
          numero = this.centenas(arreglo[0], arreglo[1], arreglo[2]);
          break;
        case 4:
          numero = this.unidadesdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3]);
          break;
        case 5:
          numero = this.decenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4]);
          break;
        case 6:
          numero = this.centenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5]);
          break;
      }
      let n: any = ent[1];
      ent[1] = isNaN(n) ? '00' : ent[1];

      return numero + " con " + ent[1] + "/100";
    }
  }

  unidades(unidad: number) {
    var unidades = Array('UN ', 'DOS ', 'TRES ', 'CUATRO ', 'CINCO ', 'SEIS ', 'SIETE ', 'OCHO ', 'NUEVE ');


    return unidades[unidad - 1];
  }

  decenas(decena: number, unidad: number) {
    var diez = Array('ONCE ', 'DOCE ', 'TRECE ', 'CATORCE ', 'QUINCE', 'DIECISEIS ', 'DIECISIETE ', 'DIECIOCHO ', 'DIECINUEVE ');
    var decenas = Array('DIEZ ', 'VEINTE ', 'TREINTA ', 'CUARENTA ', 'CINCUENTA ', 'SESENTA ', 'SETENTA ', 'OCHENTA ', 'NOVENTA ');

    if (decena == 0 && unidad == 0) {
      return "";
    }

    if (decena == 0 && unidad > 0) {
      return this.unidades(unidad);
    }

    if (decena == 1) {
      if (unidad == 0) {
        return decenas[decena - 1];
      } else {
        return diez[unidad - 1];
      }
    } else if (decena == 2) {
      if (unidad == 0) {
        return decenas[decena - 1];
      }
      else if (unidad == 1) {
        return "VEINTI" + "UNO";
      }
      else {
        return "VEINTI" + this.unidades(unidad);
      }
    } else {

      if (unidad == 0) {
        return decenas[decena - 1] + " ";
      }
      if (unidad == 1) {
        return decenas[decena - 1] + " Y " + "UNO";
      }

      return decenas[decena - 1] + " Y " + this.unidades(unidad);
    }
  }

  centenas(centena: number, decena: number, unidad: number) {
    var centenas = Array("CIENTO ", "DOSCIENTOS ", "TRESCIENTOS ", "CUATROCIENTOS ", "QUINIENTOS ", "SEISCIENTOS ", "SETECIENTOS ", "OCHOCIENTOS ", "NOVECIENTOS ");

    if (centena == 0 && decena == 0 && unidad == 0) {
      return "";
    }
    if (centena == 1 && decena == 0 && unidad == 0) {
      return "CIEN ";
    }

    if (centena == 0 && decena == 0 && unidad > 0) {
      return this.unidades(unidad);
    }

    if (decena == 0 && unidad == 0) {
      return centenas[centena - 1] + "";
    }

    if (decena == 0) {
      var numero = centenas[centena - 1] + "" + this.decenas(decena, unidad);
      return numero.replace(" Y ", " ");
    }
    if (centena == 0) {

      return this.decenas(decena, unidad);
    }

    return centenas[centena - 1] + "" + this.decenas(decena, unidad);

  }

  unidadesdemillar(unimill: number, centena: number, decena: number, unidad: number) {
    var numero = this.unidades(unimill) + " MIL " + this.centenas(centena, decena, unidad);
    numero = numero.replace("UN  MIL ", "MIL ");
    if (unidad == 0) {
      return numero.replace(" Y ", " ");
    } else {
      return numero;
    }
  }

  decenasdemillar(decemill: number, unimill: number, centena: number, decena: number, unidad: number) {
    var numero = this.decenas(decemill, unimill) + " MIL " + this.centenas(centena, decena, unidad);
    return numero;
  }

  centenasdemillar(centenamill: number, decemill: number, unimill: number, centena: number, decena: number, unidad: number) {

    let numero = '';
    numero = this.centenas(centenamill, decemill, unimill) + " MIL " + this.centenas(centena, decena, unidad);
    return numero;
  }

  separar_split(texto: string) {
    var contenido = new Array();
    for (var i = 0; i < texto.length; i++) {
      contenido[i] = texto.substr(i, 1);
    }
    return contenido;
  }


  getUserLogged(): UserLogged {
    let user = localStorage['userLogged'];
    if (user) {
      let obj = JSON.parse(user);
      if (!obj.empresa) { obj.empresa = { numero: 0 }; }
      if (!obj.sucursal) { obj.sucursal = { numero: 0 }; }
      return obj;
    } else {
      return { ambienteId: 0, nombreUsuario: "", nombreCompletoUsuario: "", clave: 0, numero: 0, sucursal: { numero: 0, clave: 0, nombre: "", rfc: "", alias: "" }, empresa: { numero: 0, clave: 0, nombre: "", rfc: "", alias: "" } };
    }
  }

  sleep(ms: number) {
    var esperarHasta = new Date().getTime() + ms;
    while (new Date().getTime() < esperarHasta) continue;
  }

  getUserLoggedEmpresa(): ComboBoxEntity {
    let empresa: ComboBoxEntity = {} as ComboBoxEntity;
    let user = localStorage['userLogged'];
    if (user) {
      let obj = JSON.parse(user);
      empresa.Id = obj.empresa.numero;
      empresa.Clave = obj.empresa.clave;
      empresa.Nombre = obj.empresa.nombre;
      return empresa;
    } else {
      return empresa;
    }
  }

  setUserLogged(user: UserLogged) {
    let userStr = JSON.stringify(user);
    localStorage.setItem('userLogged', userStr);
  }

  exportarCSV(nombreArchivo = "GridExportacion", fileContent: string) {
    const csv = fileContent;
    const a = document.createElement('a');
    a.textContent = 'download';
    a.download = `${nombreArchivo}.csv`;
    a.href = 'data:text/csv;charset=utf-8,' + escape(csv);
    document.body.appendChild(a);
    a.click();
  }

  Date2Str(value: Date, option: number): string {
    let iday: number = value.getDate();
    let imonth: number = value.getMonth() + 1;
    let iyear: number = value.getFullYear();
    let iyear2: number = parseInt(iyear.toString().substring(2, 4));

    let outDate: string = '';

    switch (option) {
      case 0:  // 250523
        outDate = iday.toString().padStart(2, '0') + imonth.toString().padStart(2, '0') + iyear2.toString().padStart(2, '0');
        break;
      case 1:  // 25052023
        outDate = iday.toString().padStart(2, '0') + imonth.toString().padStart(2, '0') + iyear2.toString().padStart(4, '0');
        break;
      case 2:  // 25/01/23
        outDate = iday.toString().padStart(2, '0') + '/' + imonth.toString().padStart(2, '0') + '/' + iyear2.toString().padStart(2, '0');
        break;
      case 3:  // 25/05/2025
        outDate = iday.toString().padStart(2, '0') + '/' + imonth.toString().padStart(2, '0') + '/' + iyear.toString().padStart(4, '0');
        break;
      case 4: // 20 Feb 2023
        outDate = iday.toString().padStart(2, '0') + ' de ' + this.getMes(imonth).substring(0, 3) + ' ' + iyear.toString().padStart(4, '0');
        break;
      case 5: // 25 de septiembre de 2025
        outDate = iday.toString().padStart(2, '0') + ' de ' + this.getMes(imonth) + ' ' + iyear.toString().padStart(4, '0');
        break;
      case 6: //  septiembre de 2025
        outDate = this.getMes(imonth) + ' ' + iyear.toString().padStart(4, '0');
        break;
      case 7: //  20 de Feb <-- sin año
        outDate = iday.toString().padStart(2, '0') + ' de ' + this.getMes(imonth).substring(0, 3);
        break;
      case 8: //  2024-12-30 <-- sin año
        outDate = iyear.toString().padStart(4, '0') + '-' + imonth.toString().padStart(2, '0') + '-' + iday.toString().padStart(2, '0');
        break;


    }
    return outDate;
  }

  getMes(value: number): string {
    let sdate = "";
    switch (value) {
      case 1:
        sdate = "Enero"
        break;
      case 2:
        sdate = "Febrero"
        break;
      case 3:
        sdate = "Marzo"
        break;
      case 4:
        sdate = "Abril"
        break;
      case 5:
        sdate = "Mayo"
        break;
      case 6:
        sdate = "Junio"
        break;
      case 7:
        sdate = "Julio"
        break;
      case 8:
        sdate = "Agosto"
        break;
      case 9:
        sdate = "Septiembre"
        break;
      case 10:
        sdate = "Octubre"
        break;
      case 11:
        sdate = "Noviembre"
        break;
      case 12:
        sdate = "Diciembre"
        break;
    }

    return sdate;
  }

  esNumero(value: string): boolean {
    let regexp: boolean = /^[0-9]+$/.test(value);
    return regexp;
  }

  AbreviarUniadesSAT(clave: string, nombre: string): string {
    let result: string = "";
    switch (clave.toUpperCase()) {
      case "58":
        result = "Kg";
        break;
      case "SM3":
        result = "M3";
        break;
      case "28":
        result = "Kg/M2";
        break;
      case "H87":
        result = "PZA";
        break;
      default:
        result = nombre;
        break;
    }
    return result.toUpperCase();
  }

  getEtiquetaRangoPorFechas(fecha1: any, fecha2: any, tipo: number): string {
    let rango: string = '';
    let fecha: Date = new Date()

    if (typeof (fecha1) == "number") {
      fecha1 = new Date(fecha.getFullYear(), fecha1 - 1, 1);
      fecha2 = new Date(fecha.getFullYear(), fecha2 - 1, 1);
    }

    if (typeof (fecha1) === "string") {
      // fecha1 = new Date( fecha1.substring(0,4), fecha1 - 1, 1);
      // fecha2 = new Date(fecha.getFullYear(), fecha2 - 1, 1);
    }



    let anio1: number = fecha1.getFullYear();
    let mes1: number = fecha1.getMonth() + 1;
    let anio2: number = fecha2.getFullYear();
    let mes2: number = fecha2.getMonth() + 1;


    if (tipo == 0) {
      tipo = 1;
    }
    switch (tipo) {
      case 1: // RANGO DE MESES PARA NOMBRE DEL REPORTE, ejemplo:  DE ENERO A FEBRERO 2023
        if (mes1 != mes2 || anio1 != anio2) {
          rango = 'de ' + this.getMes(mes1) + ' ' + (anio1 == anio2 ? '' : anio1.toString() + ' ').toString() + 'a ' + this.getMes(mes2) + ' ' + anio2.toString();;
        } else {
          rango = 'a ' + this.getMes(mes1) + ' ' + anio1.toString();
        }
        break;
      case 2:
        if (fecha1.getDate() != fecha2.getDate() || fecha1.getMonth() != fecha2.getMonth() || fecha1.getFullYear() != fecha2.getFullYear()) {
          rango = 'del ' + fecha1.getDate().toString().padStart(2, '0') + (fecha1.getMonth() == fecha2.getMonth() ? '' : ' ' + this.getMes(fecha1.getMonth() + 1).substring(0, 3).toUpperCase()) + ' ' + (anio1 == anio2 ? '' : anio1.toString() + ' ').toString() + 'al ' + this.Date2Str(fecha2, 4);
        } else {
          rango = 'al ' + this.Date2Str(fecha1, 4)
        }
        break;
    }
    return rango;
  }

  getListaMarcasAutos(): MenuItem[] {
    const lista = [
      {
        Id: 1, Nombre: "Acura", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
          {
            Id: 11, Nombre: "Integra", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: "1.5L Turbo DOHC VTEC 4cyl", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'Acura Integra 1.5L Turbo DOHC VTEC 4cyl' }
            ]
          },
          {
            Id: 12, Nombre: "MDX 2WD", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: "3.5L FI SOHC 6cyl", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'Acura MDX 2WD 3.5L FI SOHC 6cyl' }
            ]
          },
          {
            Id: 13, Nombre: "Integra Type S", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: "2.0L DI Turbo DOHC 4cyl", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'Acura Integra Type S 2.0L DI Turbo DOHC 4cyl' }
            ]
          }
        ]
      },
      {
        Id: 2, Nombre: "Audi", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
          {
            Id: 21, Nombre: "A1 Sportback Ego (Mex)", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: "4 Cylinders  1.5L Turbo DOHC(Mex)", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'Audi A1 Sportback Ego (Mex) 4 Cylinders  1.5L Turbo DOHC(Mex)' }
            ]
          },
          {
            Id: 22, Nombre: "A1 Sportback S Line (Mex)", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: " 2.0L Turbo DOHC(Mex) 4cyl", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'Audi A1 Sportback S Line (Mex) 4 Cylinders 2.0L Turbo DOHC(Mex) 4cyl' }
            ]
          },
          {
            Id: 23, Nombre: "A1 Sportback Urban (Mex)", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: "2.0L FI Turbo DOHC 4cyl", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'Audi A1 Sportback Urban (Mex) 4 Cylinders 2.0L FI Turbo DOHC 4cyl' }
            ]
          }
        ]
      },
      {
        Id: 3, Nombre: "BMW", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
          {
            Id: 11, Nombre: "i118 (mex)", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [
              { Id: 12, Nombre: "1.5L Turbo DOHC(Mex) 3cyl", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [], NombreFinal: 'BMW i118 (mex) 1.5L Turbo DOHC(Mex) 3cyl' }
            ]
          },


        ]
      },
      { Id: 4, Nombre: "Buick", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 5, Nombre: "Chevrolet", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 6, Nombre: "Crysler", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 7, Nombre: "Cupra", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 8, Nombre: "Fiat", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 9, Nombre: "Ford", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 10, Nombre: "GMC", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 11, Nombre: "Honda", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] },
      { Id: 12, Nombre: "Infititi", Seleccionado: false, IsExpanded: false, Guardar: false, Eliminar: false, Icono: '', Img: '', SubMenus: [] }]
    return lista;
  }

  getUltimoDiaMes(anio: number, mes: number): number {
    // Crea una fecha para el primer día del siguiente mes
    const primerDiaSiguienteMes = new Date(anio, mes, 1);
    // Resta un día para obtener el último día del mes actual
    const ultimoDia: Date = new Date(primerDiaSiguienteMes.getTime() - 1);
    return ultimoDia.getDate();
  }

  setHelpLine(valor: string) {
    let pes = document.querySelectorAll('.lineadeayuda');
    pes.forEach((elem: any) => { elem.innerHTML = valor; });
  }

  // IsExpanded: boolean;
  // Guardar: boolean;
  // Eliminar: boolean;
  // Nombre: string;
  // Icono: string;
  // Img: string;
  // SubMenus: MenuItem[] | null;



  getListaMeses(): any[] {
    const lista = [{ Id: 1, Clave: 1, Nombre: "Enero" },
    { Id: 2, Clave: 2, Nombre: "Febrero" },
    { Id: 3, Clave: 3, Nombre: "Marzo" },
    { Id: 4, Clave: 4, Nombre: "Abril" },
    { Id: 5, Clave: 5, Nombre: "Mayo" },
    { Id: 6, Clave: 6, Nombre: "Junio" },
    { Id: 7, Clave: 7, Nombre: "Julio" },
    { Id: 8, Clave: 8, Nombre: "Agosto" },
    { Id: 9, Clave: 9, Nombre: "Septiembre" },
    { Id: 10, Clave: 10, Nombre: "Octubre" },
    { Id: 11, Clave: 11, Nombre: "Noviembre" },
    { Id: 12, Clave: 12, Nombre: "Diciembre" }]
    return lista;
  }



  base64ToBlob(b64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  isLoad(load: boolean = true) {
    this.eventsService.publish('home:isLoading', { isLoading: load });
  }

  padLeft(maxLength: number, fillString: string, value: string | number) {
    return value.toString().padStart(maxLength, fillString);
  }
}
