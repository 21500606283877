<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="ClaseVenta"
  title="Clases de Ventas" icon="fa fa-circle-dot" subTitle="Administra la configuración de Clases de Ventas">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Clase de Venta'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="ClaseVenta" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
        </div>
      </app-panel-base>
      <app-panel-base title="Configuración">
        <div class="panel-content row">
          <component-ui-combo-box [label]="'Tipo'" [required]="true" formControlName="Tipo" [tabIndex]="3"
            [zeroMask]="2" entityName="TipoVenta" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedTipo($event)" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Contado/Crédito'" [required]="true" formControlName="ContadoCredito"
            [tabIndex]="4" [zeroMask]="2" entityName="FormaVenta" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCdo($event)" [listProperty]="'ClaveNombre'" />

            <component-ui-combo-box [label]="'Cuenta de CXC'" formControlName="CuentaCxc"
            [tabIndex]="5" [zeroMask]="2" entityName="CuentaCxc" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCuenta($event)" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Método de Pago'" [required]="true" formControlName="Metodo" [tabIndex]="6"
            [zeroMask]="2" entityName="MetodoPago" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" [widthList]="350" (onSelectedItem)="selectedMetodo($event)" [listProperty]="'ClaveNombre'" />

            <component-ui-combo-box [label]="'Condición'" formControlName="CondicionPago" [tabIndex]="7"
            [zeroMask]="2" entityName="CondicionPago" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" [widthList]="350" (onSelectedItem)="selectedCondicion($event)" [listProperty]="'ClaveNombre'" />

            <components-ui-text-box [tabIndex]="8" [label]="'Observaciones'" [maxlength]="50" formControlName="Observaciones"
            [widthTextColumns]="4" [widthLabelColumns]="2" />

            <component-ui-check-box
            helpLine="Cuando se capture un Documento de Venta, no se permitirá ingresar al campo descuento"
            [tabIndex]="9" [widthLabelColumns]="2" formControlName="NoPermitirDescuento"
            [widthTextColumns]="7" [label]="'No permitir descuento'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
