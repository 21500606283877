<div cdkDrag class="data-search card border-1 terminales">
  <div class="card-header" cdkDragHandle>
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    <h5>Terminales Bancarias</h5>
  </div>
  <div class="card-body bg-white-100 p-0">
    <button type="button" (click)="agregarEditar()" class="btn btn-success m-10px">
      <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
      Agregar
    </button>

    <table class="blueTable">
      <thead style="position: sticky;top:0;">
        <tr>
          <th class="w-20 text-end">Importe</th>
          <th class="w-20 text-center">Tipo</th>
          <th class="w-20 text-center">Banco</th>
          <th class="w-20 text-center">Cuenta Bancaria</th>
          <th class="w-20 text-center">Referencia</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let r of terminales; let i = index">
          <td class="text-end">{{r.importe | number: '1.2'}}</td>
          <td class="text-center">{{r.tipoRetiro.Nombre}}</td>
          <td class="text-center">{{r.banco.Nombre}}</td>
          <td class="text-center">{{r.cuentaBancaria.Nombre}}</td>
          <td class="text-center">{{r.referencia}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- <ng-template #modalAgregarRetiroCaja let-modal let-c="close">
  <div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
    <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Retiro de Caja</div>
    <div class="card-body bg-white-100">
      <form [formGroup]="agregarRetiroCajaFrm" autocomplete="off">
        <div class="row mb-5px">
          <div class="col">
            <components-ui-number-box [required]="true" [tabIndex]="1" [maxLength]="10" label="Importe"
              formControlName="Importe" [topLabel]="true" [widthTextColumns]="12" [widthLabelColumns]="12" />
          </div>
          <div class="col">
            <component-ui-combo-box label="Tipo" [tabIndex]="2" formControlName="Tipo"
              entityName="TipoDepositoCajaGeneral" [topLabel]="true" [widthTextColumns]="12" [widthLabelColumns]="12"
              [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'TipoRetiro')" [required]="true"
              [listProperty]="'ClaveNombre'" />
          </div>
        </div>

        <div class="row mb-5px">
          <div class="col">
            <component-ui-combo-box label="Banco" [tabIndex]="3" formControlName="Banco" entityName="c_Banco"
              [topLabel]="true" [widthTextColumns]="12" [widthLabelColumns]="12" [inputProperty]="'Nombre'"
              (onSelectedItem)="onSelectedItem($event,'Banco')" [required]="true" [listProperty]="'ClaveNombre'" />
          </div>
          <div class="col">
            <component-ui-combo-box label="Cuenta Bancaria" [tabIndex]="4" formControlName="CuentaBancaria"
              entityName="CuentaBancaria" [topLabel]="true" [widthTextColumns]="12" [widthLabelColumns]="12"
              [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'CuentaBancaria')" [required]="true"
              [listProperty]="'ClaveNombre'" />
          </div>
        </div>
        <components-ui-text-box label="Referencia" [required]="true" formControlName="Referencia" [topLabel]="true"
          [tabIndex]="5" [widthTextColumns]="12" [widthLabelColumns]="12" [maxLength]="100" />
      </form>
      <hr>
      <div style="text-align: right;">
        <button type="button" (click)="accept()" class="btn btn-success me-5px">Aceptar</button>
        <button type="button" (click)="close()" class="btn btn-danger" #txtCancelar>Cancelar</button>
      </div>
    </div>
  </div>
</ng-template> -->
