import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { FacturacionService } from '../../services/facturacion.service';
import { ConceptosCarga } from '../../interfaces/cargaentrega.interface';
import { ModalService } from 'src/app/service/modal.service';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { OrdenCarga, OrdenCargaConcepto } from '../../interfaces/ordencarga.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';




@Component({
  selector: 'carga-entrega-page',
  templateUrl: './carga-entrega-page.component.html',
  styleUrls: ['./carga-entrega-page.component.scss'],

})
export class CargaEntregaPageComponent {


  //*referencia a elementos de la pantalla
  @ViewChild('cboVehiculo')
  public cboVehiculo!: ElementRef<ComboBoxComponent>;

  @ViewChild('ctrlBusqueda')
  public ctrlBusqueda!: QueryList<ElementRef>;

  @ViewChild('ctrlRegresarProducto')
  public ctrlRegresarProducto!: QueryList<ElementRef>;


  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  listaSeries: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  showDescription: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  mostrarDetalle: boolean = false;
  facturaEditando: ConceptosCarga | null = null;
  facturaIdEditanto: string = "";
  tipoFacturas: number = 1;
  listaFacturas: ConceptosCarga[] = [];
  listaOrdenCarga: any[] = [];
  listaConceptos: OrdenCargaConcepto[] = [];
  mostrarDetalleSoloConsulta: boolean = false;
  filtroAyudante1: string = "";
  filtroAyudante2: string = "";
  filtroAyudante3: string = "";
  filtroAyudante4: string = "";
  valorEnviado: any;
  indexEditing: number = 0;
  EsCancelacion: boolean = false;


  //* injects
  private eventsService = inject(EventsService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private cd = inject(ChangeDetectorRef)
  private http = inject(HttpClient);
  private fService = inject(FacturacionService);
  private reportsService = inject(ReportsService);
  private modalService = inject(ModalService);
  private cb = inject(ContainerBaseService);

  public filtroOrdenCarga: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      TituloVisor: 'Impresión de Orden de Carga',
      Ordenado: 'det.IdVenta,det.IdTraspaso,det.IdRemision',
      NombreReporte: '',
      NombreExcel: '',
      FilterOptions: [
        { Campo: 'Ord.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };


  menuItems: PanelBaseDropDownMenu[] = [{ Id: 'regresar', Nombre: 'Regresar Productos', Icono: "fas fa-reply" },
  { Id: 'cancelar', Nombre: 'Cancelar Salida por Venta', Icono: "far fa-circle-xmark" }]



  public myForm: FormGroup = this.fb.group({
    id: 0,
    empresa: null,
    sucursal: null,
    serie: '',
    folio: 0,
    unidad: null,
    odometroSalida: 0,
    odometroEntrada: 0,
    capacidadCarga: 0,
    cargado: 0,
    disponible: 0,
    usuarioElabora: null,
    usuarioCancela: null,
    chofer: null,
    ayudante: null,
    ayudante2: null,
    ayudante3Id: null,
    ayudante3: null,
    ayudante4Id: null,
    ayudante4: null,
    odometro: 0,
    fechaEmision: '',
    fechaCancelacion: null,
    enCarga: false,
    entregado: false,
    conceptos: this.fb.array([]),
    baja: false,
    eliminado: false
  });

  constructor() {
  }

  ngAfterViewInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.cargarDatos();
    this.filtroAyudante1 = " SucursalPertenece.Id == " + this.info.sucursal?.numero + " and Tipo.Clave == 3";
    this.filtroAyudante2 = " SucursalPertenece.Id == " + this.info.sucursal?.numero + " and Tipo.Clave == 3";
    this.filtroAyudante3 = " SucursalPertenece.Id == " + this.info.sucursal?.numero + " and Tipo.Clave == 3";
    this.filtroAyudante4 = " SucursalPertenece.Id == " + this.info.sucursal?.numero + " and Tipo.Clave == 3";
    this.cb.obtenerValorPropiedad("Usuario", "Rol.PuedeCancelarSalidaPorVenta", this.info.numero).subscribe((value) => {
      if (value.toLowerCase() != "true") {
        this.menuItems = [];
      }
    });


  }
  //* 1.- metodos de inicializacion de la pantalla y carga
  ngOnInit(): void {




    //menuItems

  }

  cargarDatos() {
    this.listaConceptos = [];
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.obtenerPendientesEntregar().subscribe((listaFacturas: ConceptosCarga[]) => {
      let facturaId: string = "";
      listaFacturas.forEach((factura: ConceptosCarga) => {
        //facturaId++;
        facturaId = factura.idVenta + "_" + factura.idTraspaso + "_" + factura.idRemision + "_" + factura.serieFolio;
        factura.facturaId = facturaId;
        factura.detalleProductos.forEach(p => {
          p.cantidadOriginal = p.cantidad;
          p.totalKiloOriginal = p.totalKilo;
          p.facturaId = facturaId
        })
      })
      this.listaFacturas = listaFacturas;
      this.listaFacturas.map(item => {
        item.direccionCompleta = item.direccion;
        item.direccion = item.direccion.substring(0, 30);
        let indx: number = item.direccionCompleta.indexOf("CP:");
        let cp: string = "";
        if (indx >= 0) {
          cp = item.direccionCompleta.substring(indx + 3);
          item.ciudad = item.ciudad + " CP: " + cp;
          item.CodigoPostal = cp;
        }
      })
      this.fService.obtenerOrdenesDeCargaPendientes().subscribe((lista: OrdenCarga[]) => {
        this.listaOrdenCarga = lista;
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const tipoMov: any = this.cboVehiculo;
        tipoMov.tagInput.nativeElement.focus();
      })
    })
  }



  //* 2.- Control de flujo de la pantalla
  mostrarComprobantes(tab: string) {
    if (tab === "ventas") {
      this.tipoFacturas = 1;
    } else if (tab === "traspasos") {
      this.tipoFacturas = 2;
    } else if (tab === "remisiones") {
      this.tipoFacturas = 3;
    }
  }

  getDireccion(factura: ConceptosCarga): string {
    return factura.direccionCompleta;
  }

  selectVehiculo(entity: any) {
    if (entity) {
      this.myForm.controls["unidad"].setValue(entity);
      this.myForm.get('capacidadCarga')!.setValue(entity.CapacidadCarga);
      this.myForm.get('cargado')!.setValue(0);
      this.myForm.get('disponible')!.setValue(0);
      this.myForm.get('chofer')!.setValue(entity.Chofer);
      this.myForm.get('ayudante')!.setValue(entity.Ayudante);
    }
    this.calcTotales();
  }

  clicMenu(value: string) {
    if (value == "regresar") {
      this.EsCancelacion = false;
      this.modalService.openModal(this.ctrlRegresarProducto, (e: any) => {
        this.onCloseDetalle(e)
      }, "sm");
    }
    if (value == "cancelar") {
      this.EsCancelacion = true;
      this.modalService.openModal(this.ctrlRegresarProducto, (e: any) => {
        this.onCloseDetalle(e)
      }, "sm");
    }


  }

  //*3.- metodo getter para la presentacion de pantalla

  getNombreDestino(item: any): string {
    let result: string = "";
    if (item.cliente.nombre) {
      result = item.cliente.nombre;
    } else {
      result = "SUC.DEST.: " + item.sucursalDestino.nombre;
    }
    return result;
  }


  getEncabezadoClienteTraspaso() {
    let result: string = "";
    if (this.tipoFacturas == 2) {
      result = "Sucursal";
    } else {
      result = "Cliente";
    }
    return result;
  }

  getListaFacturasFiltradas() {
    if (this.tipoFacturas == 1) {
      return this.listaFacturas.filter(p => p.tipo == "V")
    } else if (this.tipoFacturas == 2) {
      return this.listaFacturas.filter(p => p.tipo == "T")
    } else if (this.tipoFacturas == 3) {
      return this.listaFacturas.filter(p => p.tipo == "R")
    }
    return this.listaFacturas;
  }

  selectFactura(factura: ConceptosCarga) {


    factura.seleccionado = !factura.seleccionado
    if (factura.seleccionado) {
      this.facturaEditando = factura;
      //todo
      this.facturaIdEditanto = factura.facturaId;
      this.modalService.openModal(this.ctrlBusqueda, (e: any) => {
        this.onCloseDetalle(e)
      }, "sm");
    } else {
      factura.cantidadTotalCargaPorcentaje = "0%";
      factura.cantidadTotalCargada = 0;
      this.facturaEditando = null;
      factura.editado = false;
      //todo
      this.facturaIdEditanto = "";
      factura.detalleProductos.forEach(element => {
        let index = this.listaConceptos.findIndex(el => el.id === element.id);
        if (index !== -1) {
          this.listaConceptos.splice(index, 1);
        }
        this.listaConceptos.pop
      });
      //todo
      this.listaConceptos = this.listaConceptos.filter(f => f.facturaId != factura.facturaId);
    }
    this.calcTotales();
  }

  calcTotales() {
    let capacidad: number = 0;
    if (this.myForm.value.capacidadCarga) {
      capacidad = this.myForm.get('capacidadCarga')!.value ?? 0;
    }


    let cargado: number = 0;
    let disponible: number = 0;
    this.listaFacturas.forEach(f => {
      if (f.seleccionado) {
        cargado += f.kilosCarga;
      }
    })
    disponible = capacidad - cargado;
    this.myForm.get('cargado')?.setValue(cargado);
    this.myForm.get('disponible')?.setValue(disponible);
  }

  porcentajeKilos(item: ConceptosCarga) {
    let total: number = (item.kilosCarga / item.kilos) * 100;
    return total + "%";
  }

  // mostrarDetalles(item: ConceptosCarga) {
  //   this.facturaEditando = item;
  //   this.facturaIdEditanto = item.facturaId;
  //   this.modalService.openModal(this.ctrlBusqueda, (e: any) => {
  //     this.onCloseDetalle(e)
  //   }, "sm");
  // }

  onCloseDetalle(item: ConceptosCarga) {
    if (item) {
      item.seleccionado = true;
      item.editado = true;
      item.cantidadTotalCargada = item.cantidadTotalCarga
      item.cantidadTotalCargaPorcentaje = (item.cantidadTotalCargada / item.cantidadTotal * 100) + "%";
      //todo
      let index = this.listaFacturas.findIndex(p => p.facturaId == item.facturaId);
      this.listaFacturas.splice(index, 1, item);
      item.detalleProductos.forEach(conc => {
        let conceptoExiste = this.listaConceptos.find(p => p.id == conc.id)
        if (!conceptoExiste) {
          this.listaConceptos.push(conc);
        }
      })
    } else {
      //todo
      let itemfactura = this.listaFacturas.find(p => p.facturaId == this.facturaIdEditanto);
      if (itemfactura) {
        itemfactura.cantidadTotalCargaPorcentaje = "0%";
        itemfactura.cantidadTotalCargada = 0;
        setTimeout(() => {
          itemfactura!.seleccionado = false;
        }, 100);
      }
      this.cd.detectChanges();
    }
    this.calcTotales();
    this.mostrarDetalle = false;
  }

  onCloseDetalleRegresarProducto(item: ConceptosCarga) {
    if (item) {

    } else {

    }
    // this.calcTotales();
    // this.mostrarDetalle = false;
  }


  get getlistaConceptos(): ConceptosCarga {
    return this.facturaEditando!;
  }

  // get getMovtoSeleccionado(): string {
  //   return this.facturaIdEditanto;
  // }

  getSeleccionado(item: ConceptosCarga): boolean {
    return item.seleccionado;
  }


  get filtroVehiculos() {
    return "SucursalPertenece.Id = " + this.info.sucursal?.numero;
  }

  get filtroChofer() {
    return "SucursalPertenece.Id = " + this.info.sucursal?.numero + " and (Tipo.Clave == 1 || Tipo.Clave == 2 )";
  }

  selectChofer(valor: any, campo: string) {
    this.myForm.get(campo)?.setValue(valor);
  }

  selectAyudante(entity: any, valor: number) {
    let result: string = "";
    let ayuda1 = 0;
    let ayuda2 = 0;
    let ayuda3 = 0;
    let ayuda4 = 0;

    if (valor == 1) {
      this.myForm.get('ayudante')?.setValue(entity);
    } else if (valor == 2) {
      this.myForm.get('ayudante2')?.setValue(entity);
    } else if (valor == 3) {
      this.myForm.get('ayudante3')?.setValue(entity);
    } else if (valor == 4) {
      this.myForm.get('ayudante4')?.setValue(entity);
    }


    if (this.myForm.get('ayudante')?.value) {
      ayuda1 = this.myForm.value.ayudante.Id;
    }
    if (this.myForm.get('ayudante2')?.value) {
      ayuda2 = this.myForm.value.ayudante2.Id;
    }
    if (this.myForm.get('ayudante3')?.value) {
      ayuda3 = this.myForm.value.ayudante3.Id;
    }
    if (this.myForm.get('ayudante4')?.value) {
      ayuda4 = this.myForm.value.ayudante4.Id;
    }

    this.filtroAyudante2 = ` Tipo.Clave == 3 and Id != ${ayuda1} and Id != ${ayuda3} and Id !=${ayuda4} and SucursalPertenece.Id == ${this.info.sucursal?.numero ?? '0'}`
    this.filtroAyudante3 = ` Tipo.Clave == 3 and Id != ${ayuda1} and Id != ${ayuda2} and Id != ${ayuda4} and SucursalPertenece.Id == ${this.info.sucursal?.numero ?? '0'}`
    this.filtroAyudante4 = ` Tipo.Clave == 3 and Id != ${ayuda1} and Id != ${ayuda2} and Id != ${ayuda3} and SucursalPertenece.Id == ${this.info.sucursal?.numero ?? '0'} `
    this.filtroAyudante1 = ` Tipo.Clave == 3 and Id != ${ayuda2} and Id != ${ayuda3} and Id !=${ayuda4} and SucursalPertenece.Id == ${this.info.sucursal?.numero ?? '0'}`
  }


  getFiltroaAyudante(numero: number): string {
    let filtro: string = "";
    let idAyudante: number = 0;
    for (let i = 1; i < 4; i++) {
      if (numero != i) {
        if (i = 1) {
          idAyudante = this.myForm.get('ayudante')?.value ?? 0;
        } else {
          idAyudante = this.myForm.get('ayudante' + i)!.value ?? 0;
        }
      }
      if (filtro.length > 0) {
        filtro = filtro + ` and  Id not in (${1})`
      }
    }
    return filtro;
  }



  //* 3.- metodos para consultar el backend
  save() {
    if (this.saving) return;


    if (this.listaConceptos.length == 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Si",
        cancelButton: false,
        message: 'No ha seleccionado productos para cargar el vehículo.',
        onConfirm: (data: AlertResponse) => { }
      })
      return;
    }

    let ent: OrdenCarga = this.myForm.value;

    let conceptos: any = this.listaFacturas;

    if (!ent.unidad) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Si",
        cancelButton: false,
        message: 'Debe seleccionar un Vehículo.',
        onConfirm: (data: AlertResponse) => { }
      })
      return;
    }


    if (!ent.chofer) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Si",
        cancelButton: false,
        message: 'Debe seleccionar el Chofer del Vehículo.',
        onConfirm: (data: AlertResponse) => { }
      })
      return;
    }



    ent.empresa = { Id: this.info.empresa?.numero!, Clave: this.info.empresa?.clave!, Nombre: this.info.empresa?.nombre! } as Empresa;
    ent.sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
    ent.conceptos = this.listaConceptos;
    ent.odometroSalida = 0;
    ent.odometroEntrada = 0;
    ent.fechaEmision = new Date();

    ent.conceptos.map((item: any) => {
      item.fechaEmision = new Date();
      item.id = 0;
    })


    this.saving = true;
    this.valorEnviado = ent;
    //this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarOrdenCarga`, ent).subscribe((result) => {
      if (result.success) {
        let ordenId: number = parseInt(result.message);
        this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarFormasPagoEntegaNuestra`, this.listaFacturas.filter(f => f.seleccionado == true)).subscribe((result) => {
          if (result.success) {
            this.saving = false;
            this.myForm.reset();
            this.myForm.get('id')?.setValue(0);
            this.myForm.get('enCarga')?.setValue(false);
            this.myForm.get('entregado')?.setValue(false);
            this.myForm.get('baja')?.setValue(false);
            this.myForm.get('eliminado')?.setValue(false);
            this.eventsService.publish('home:isLoading', { isLoading: false });
            Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
              this.eventsService.publish('home:isLoading', { isLoading: true });
              let reportHeader: ReportHeader = {
                NombreReporte: '',
                Dato1: ''
              }
              let filtro: FilterOptions | undefined = this.filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'Ord.Id');
              if (filtro) {
                filtro!.Valor = String(ordenId);
              }
              this.filtroOrdenCarga.ReportHeader = reportHeader;
              this.reportsService.printReport(this.filtroOrdenCarga, '/Ventas/ImprimirOrdenCarga', false, () => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                this.cargarDatos();
              });
            });
          }
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
  }

  getIdOrdencarga(b: any) {
    //todo
    return "ordencarga" + b.id
  }

  consultarDetalle(orden: any, c: ConceptosCarga) {
    this.mostrarDetalleSoloConsulta = true
    //todo
    this.facturaEditando = c;
    this.facturaEditando.idOrdenCarga = orden.id;
    this.facturaIdEditanto = c.idVenta + "_" + c.idTraspaso + "_" + c.idRemision;
    this.modalService.openModal(this.ctrlBusqueda, (e: any) => {
      this.mostrarDetalle = false;
      this.mostrarDetalleSoloConsulta = false;
    }, "sm");
  }


  aliminarFacturaOrdenCarga(orden: any, item: any, indx: number) {
    this.eventsService.publish('home:showAlert', {
      textAccept: "Si, Eliminar",
      message: '¿Desea Eliminar la factura de la Orden de Carga?',
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          const params = new HttpParams().set("idVenta", item.idVenta).set("idTraspaso", item.idTraspaso).set("idRemision", item.idRemision).set("idOrden", orden.id)
          this.http.get<any>(`${this.baseUrl}/Ventas/EliminarFacturaOrdenCarga`, { params }).subscribe(item => {
            this.fService.obtenerOrdenesDeCargaPendientes().subscribe((lista: OrdenCarga[]) => {
              this.listaOrdenCarga = lista;
              this.eventsService.publish('home:isLoading', { isLoading: false });
            })
          })
        }
      }
    });



    // Swal.fire({
    //   title: '¿Desea Eliminar la factura de la Orden de Carga?',
    //   showDenyButton: true,
    //   confirmButtonText: 'Si, eliminar',
    //   denyButtonText: `Cancelar`,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.eventsService.publish('home:isLoading', { isLoading: true });
    //     const params = new HttpParams().set("idVenta", item.idVenta).set("idTraspaso", item.idTraspaso).set("idRemision", item.idRemision).set("idOrden", orden.id)
    //     this.http.get<any>(`${this.baseUrl}/Ventas/EliminarFacturaOrdenCarga`, { params }).subscribe(item => {
    //       this.fService.obtenerOrdenesDeCargaPendientes().subscribe((lista: OrdenCarga[]) => {
    //         this.listaOrdenCarga = lista;
    //         this.eventsService.publish('home:isLoading', { isLoading: false });
    //       })
    //     })
    //   }
    // });
  }

  generarOrdenSalida(item: any) {

    this.eventsService.publish('home:showAlert', {
      textAccept: "Si",
      textCancel: "No",
      message: '¿Se Cargaron todos los Productos?',
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          const fecha = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
          const params = new HttpParams().set("id", item.id).set("fecha", fecha);
          this.http.get<any>(`${this.baseUrl}/Ventas/GenerarOrdenSalida`, { params }).subscribe(result => {
            if (result.success) {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              Swal.fire({ position: 'center', icon: 'success', title: 'Las Salidas por Venta se generaron correctamente', showConfirmButton: false, timer: 1000 }).then(() => {

                this.fService.obtenerOrdenesDeCargaPendientes().subscribe((lista: OrdenCarga[]) => {

                  let reportHeader: ReportHeader = {
                    NombreReporte: '',
                    Dato1: ''
                  }
                  let filtro: FilterOptions | undefined = this.filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'Ord.Id');
                  if (filtro) {
                    filtro!.Valor = String(item.id);
                  }
                  this.filtroOrdenCarga.ReportHeader = reportHeader;
                  this.reportsService.printReport(this.filtroOrdenCarga, '/Ventas/ImprimirFormatoOrdenCarga', false, () => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                  });
                  this.listaOrdenCarga = lista;
                });
              });



            }
          })


        }
      }
    });

  }

  //*4 impresion

  imprimirOrden(b: any) {
    let reportHeader: ReportHeader = {
      NombreReporte: '',
      Dato1: ''
    }
    let filtro: FilterOptions | undefined = this.filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'Ord.Id');
    if (filtro) {
      filtro!.Valor = String(b.id);
    }
    this.filtroOrdenCarga.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroOrdenCarga, '/Ventas/ImprimirOrdenCarga');
  }
}
