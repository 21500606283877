
<app-container-base subTitle="Verificador de compras" (onClickBarButton)="clickBoton($event)" [activeButtons]="activeButtons"
  icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-7">
              <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
                [widthLabelColumns]="4" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="Fecha1" [tabIndex]="2"
                [widthLabelColumns]="7" [widthTextColumns]="3" [required]="true"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="3" [required]="true"></components-ui-date-box>
            </div>
          </div>

          <div>
            <div class="row">
              <div class="col-md-4">
                <component-ui-check-box  label='No Incluir cargos extras'  formControlName="Opc1" [tabIndex]="4"
                [widthLabelColumns]="7" [widthTextColumns]="1" (onClick)="onclicSinAnticipos($event)" />
              </div>
            </div>
          </div>

          <div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosDiarioVentas" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
