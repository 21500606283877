<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-money-bill-1" [activeButtons]="activeButtons"
  (onClickBarButton)="onClickBarButton($event)" subTitle="Emitir Documentos de Compras">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:700px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important; width:670px;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:150px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:70px;text-align: center;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <div class="card border-0 mb-0" style="height: 20px;background-color: var(--bs-app-header-input-bg);">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2" style="text-align: left;">
              <div class="mb-0 d-md-flex fw-bold">
                <div class="ms-md-0 mt-md-0 mt-0 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Archivos <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item cursor-pinter" (click)="sendEmail()">Envíar por correo</a>
                  </div>
                </div>
                <div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Acciones <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item cursor-pinter" (click)="openCopy()">Copiar</a>
                    <a class="dropdown-item cursor-pinter">Agregar Datos de Importación</a>
                    <a [class]="'dropdown-item cursor-pinter ' + (myForm.value.UUIDFactura ? 'bg-success text-white':'')"
                      (click)="openCfdi(false)">Agregar XML de Factura</a>
                    <a [class]="'dropdown-item cursor-pinter ' + (myForm.value.UUIDNotaCredito ? 'bg-primary text-white':'')" (click)="openCfdi(true)">Agregar XML de Nota de Crédito</a>
                    <a class="dropdown-item cursor-pinter" *ngIf="myForm.value.TieneBackOrder"
                      (click)="openHistoricoBackOrder()">
                      Ver Por Entregar</a>
                    <!-- <a class="dropdown-item">Aplicar anticipos</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="invoice" style="width: 1045px;background-color: var(--bs-app-header-input-bg);">
          <div class="invoice-header">
            <div class="invoice-from">
              <app-panel-base title="Selección de Documento" [style]="'height:260px;'">
                <div class="panel-content d-flex flex-column" style="height: 100%;">
                  <div style="height: 80%;">
                    <div class="d-flex">
                      <component-ui-combo-box style="width:15%; margin-right: 5px;"
                        [extras]="'TipoDeComprobante.Clave as TipoComp, Clasificacion.Clave as Clasificacion, SolicitaInfoVehiculos as SolicitarInfoVehiculos, '"
                        formControlName="DocumentoCompra" [enabled]="!blockCombos" [topLabel]="true" label="Documento"
                        [inputProperty]="'Nombre'" [zeroMask]="3" [setItemsList]="getItemsComps" [tabIndex]="1"
                        [widthList]="500" [widthLabelColumns]="12" [widthTextColumns]="12"
                        (onSelectedItem)="onSelectedItem($event,'DocumentoCompra')" [widthRightColumns]="0" #cboComp />

                      <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Serie"
                        [topLabel]="true" label='Serie' [enabled]="!blockCombos" [inputProperty]="'Serie'"
                        [widthList]="500" [setItemsList]="getItemsSerie" [tabIndex]="2" [widthLabelColumns]="12"
                        [widthTextColumns]="12" [widthRightColumns]="0"
                        (onSelectedItem)="onSelectedItem($event,'Serie')" #cboSerie />

                      <components-ui-number-box style="width:15%;" [topLabel]="true" formControlName="Folio"
                        (blur)="blurFolio($event)" label='Folio' [tabIndex]="3" [widthLabelColumns]="12"
                        [widthTextColumns]="12" />
                    </div>
                    <div class="d-flex mt-1">
                      <div style="width:46%;margin-left:7px;">
                        <components-ui-text-box (blur)="blurProv($event)" [placeHolder]="'F2= buscar'"
                          (onClickIcon)="clicIcon($event)" label="Proveedor" [widthLabelColumns]="8" [tabIndex]="4"
                          (keydown.enter)="enterProv()" (keydown.f2)="openCustomerSearch()" [widthTextColumns]="4"
                          entidadBusqueda="Proveedor" (elementoSeleccionado)="elementoSeleccionado($event)"
                          #txtProveedor />
                      </div>
                      <div *ngIf="myForm.value.Proveedor?.Id>0" style="width:53%;text-align: left;margin: auto;">
                        <div class="fs-13px lh-12 mb-2px fw-bold text-dark">[{{this.myForm.value.Proveedor.Rfc |
                          uppercase}}]
                          {{
                          this.myForm.value.Proveedor.Nombre | uppercase}}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="width:46%;margin-left:7px;">
                        <component-ui-combo-box inputProperty="Nombre" entityName="ConsignadoProveedor" [tabIndex]="5"
                          [filterQuery]="getFiltroConsignado" (onSelectedItem)="onSelectedItem($event,'Consignado')"
                          (onClicLink)="clicConsignado()" [enableLink]="true" label="Entregar en"
                          [widthLabelColumns]="8" formControlName="Consignado" listProperty="Nombre"
                          [widthTextColumns]="4" [widthRightColumns]="0" #cboConsignado />
                      </div>
                      <div style="width:53%;text-align: left;margin: auto;" *ngIf="myForm.value.Consignado">
                        <div class="fs-13px lh-12 mb-2px fw-bold text-dark">{{ myForm.value.Consignado.Nombre|
                          uppercase}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </app-panel-base>
              <!-- <app-panel-base *ngIf="myForm.value.CargosAdicionales?.length>0" title="Cargos Adicionales"
                style="width:1200px;">
                <div class="panel-content">

                  <gui-grid [rowStyle]="rowStyle" [autoResizeWidth]="false" [width]="1100"
                    [source]="myForm.value.CargosAdicionales" [maxHeight]="150" [rowHeight]="20" [virtualScroll]="true"
                    [rowSelection]="false" [theme]="'fabric'" [cellEditing]="false">
                    <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
                      <ng-template let-item="item" let-index="index">
                        <i (click)="deleteCargos(index)" class="fa fa-rectangle-xmark"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
                      <ng-template let-item="item" let-index="index">
                        <i (click)="clicCargoAdicional(index)" class="fa fa-pencil text-yellow" style="font-weight: bold; cursor: pointer;"></i>
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Tipo" [width]="80" [type]="'string'">
                      <ng-template let-item="item" let-index="index">
                        {{item.Tipo.Nombre}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Proveedor" [width]="150" [type]="'string'">
                      <ng-template let-item="item" let-index="index">
                        {{(item.Proveedor ? (item.Proveedor.Nombre| uppercase): '')}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Moneda" [width]="80" [type]="'string'">
                      <ng-template let-item="item" let-index="index">
                        {{item.Moneda.Nombre}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Tipo C" [width]="80" [type]="'string'" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-item="item" let-index="index">
                        {{item.TipoCambio | number:'1.2-6'}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Subtotal" [width]="80" [type]="'string'" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-item="item" let-index="index">
                        {{item.Subtotal | number:'1.2-6'}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="IVA" [width]="80" [type]="'string'" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-item="item" let-index="index">
                        {{item.IVA | number:'1.2-6'}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Total" [width]="80" [type]="'string'" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-item="item" let-index="index">
                        {{item.Total | number:'1.2-6'}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="¿Prorrateado?" [width]="100" [type]="'string'"
                      [align]="GuiColumnAlign.CENTER">
                      <ng-template let-item="item" let-index="index">
                        {{ item.NoProrratear ? 'No': 'Si'}}
                      </ng-template>
                    </gui-grid-column>
                  </gui-grid>


                </div>
              </app-panel-base> -->
              <app-panel-base title="Datos Adicionales" style="width:1200px;">
                <div class="panel-content">
                  <div class="d-flex" style="flex-direction: row;">

                    <components-ui-text-box style="width:100px;margin-right: 5px;" [topLabel]="true" [tabIndex]="13"
                      [label]="'Folio Factura'" formControlName="FacturaProveedor" [widthTextColumns]="12"
                      [widthLabelColumns]="12" />

                    <components-ui-date-box style="width:100px;" [tabIndex]="14" [topLabel]="true" label="Fecha Factura"
                      formControlName="FechaFacturaProveedor" [widthLabelColumns]="12" [widthTextColumns]="12" />

                    <components-ui-date-box style="width:100px;" [tabIndex]="15" [topLabel]="true"
                      label="Fecha Estimada" formControlName="FechaEstimadaEntrega" [widthLabelColumns]="12"
                      [widthTextColumns]="12" />

                    <component-ui-combo-box [tabIndex]="16" style="width:100px;margin-right: 5px;" [topLabel]="true"
                      [label]="'Paquetería'" formControlName="Paqueteria" [zeroMask]="3" [inputProperty]="'Nombre'"
                      entityName="Paqueteria" [widthTextColumns]="12" [widthLabelColumns]="12" [widthRightColumns]="0"
                      (onSelectedItem)="onSelectedItem($event,'Paqueteria')" [listProperty]="'ClaveNombre'" />

                    <components-ui-text-box (keydown.enter)="enterGuia(false)" [tabIndex]="17" style="width:100px;"
                      [topLabel]="true" [label]="'No Guía'" formControlName="NumeroGuia" [widthTextColumns]="12"
                      [widthLabelColumns]="12" #txtNoDeGuia/>
                  </div>

                  <div *ngIf="getMostrarVehiculo" class="d-flex" style="flex-direction: row;">
                    <component-ui-combo-box style="width:100px;" [tabIndex]="18" [topLabel]="true" [label]="'Vehículo'"
                      [required]="getMostrarVehiculo" formControlName="Vehiculo" [zeroMask]="3" entityName="Unidad"
                      [widthTextColumns]="12" [widthLabelColumns]="12" [widthRightColumns]="0"
                      [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'Vehiculo')"
                      [listProperty]="'ClaveNombre'" />

                    <component-ui-combo-box style="width:100px;margin-left: 5px;" [tabIndex]="19" [topLabel]="true"
                      [label]="'Chofer'" formControlName="Chofer" [zeroMask]="3" entityName="Chofer"
                      [widthTextColumns]="12" [widthLabelColumns]="12" [widthRightColumns]="0"
                      [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'Chofer')"
                      [listProperty]="'ClaveNombre'" />

                    <component-ui-combo-box style="width:100px;margin-left: 5px;" [tabIndex]="20" [topLabel]="true"
                      [label]="'Tipo Servicio'" [required]="true" formControlName="TipoServicio" [zeroMask]="3"
                      [inputProperty]="'Nombre'" entityName="TipoServicioUnidad" [widthTextColumns]="12"
                      [widthLabelColumns]="12" [widthRightColumns]="0"
                      (onSelectedItem)="onSelectedItem($event,'TipoServicio')" [listProperty]="'ClaveNombre'" />

                    <component-ui-combo-box style="width:100px;margin-left: 5px;" [tabIndex]="21" [topLabel]="true"
                      [label]="'Tipo Gasto'" [required]="true" formControlName="TipoGastoUnidad" [zeroMask]="3"
                      [inputProperty]="'Nombre'" entityName="TipoGastoUnidades" [widthTextColumns]="12"
                      [widthLabelColumns]="12" [widthRightColumns]="0"
                      (onSelectedItem)="onSelectedItem($event,'TipoGastoUnidad')" [listProperty]="'ClaveNombre'" />

                    <components-ui-number-box style="width:100px;" [tabIndex]="22" [topLabel]="true"
                      [label]="'Odometro'" [required]="true" formControlName="Odometro" [widthTextColumns]="12"
                      [widthLabelColumns]="12" />

                    <component-ui-check-box style="width:100px;margin-top:22px;" [tabIndex]="23" [label]="'Prev'"
                      formControlName="Preventivo" [widthTextColumns]="4" [widthLabelColumns]="8" />

                    <component-ui-check-box style="width:100px;margin-top:22px;" [tabIndex]="24" [label]="'Corr'"
                      formControlName="Correctivo" [widthTextColumns]="4" [widthLabelColumns]="8" />

                    <component-ui-check-box (keydown.enter)="enterGuia(true)" style="width:100px;margin-top:22px;"
                      [tabIndex]="25" [label]="'Mant'" formControlName="Mantenimiendo" [widthTextColumns]="4"
                      [widthLabelColumns]="8" #chkMantenimiento/>
                  </div>
                  <div class="fact-totales" style="width: 1200px; margin-top:20px;">
                    <div class="invoice-detail">
                      <div id="divcompras">

                        <ng-scrollbar style="height: 300px; width:1190px; border-bottom: 1px solid #AAAAAA;">
                          <table class="blueTable" style="width:1180px;">
                            <thead style="position: sticky;top:0;">
                              <tr>
                                <th style="width: 30px;"></th>
                                <th style="width: 60px;">Producto</th>
                                <th style="width: 180px;">Descripción</th>
                                <th style="width: 60px;text-align:right;padding-right:1px;">Cantidad
                                </th>
                                <th style="width: 80px;">Unidad</th>
                                <th style="width: 120px;">Conversión</th>
                                <th style="width: 60px;">Costo</th>
                                <th style="width: 60px;">Ult.Comp</th>
                                <th style="width: 50px;">Dscto</th>
                                <th style="width: 70px;">Paquete/Lote</th>
                                <th style="width: 60px;">Cargos</th>
                                <th style="width: 65px;">Costo Final</th>
                                <th style="width: 60px;">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                                (keydown)="keyDownRow($event, index)" *ngFor="let item of source; let index = index;">
                                <td style="text-align: center;">
                                  <i (click)="deleteRow(index)" *ngIf="item.Producto"
                                    class="fa fa-rectangle-xmark fa-1x" style="color:red;font-weight: bold; cursor: pointer;"></i>
                                </td>
                                <td style="width: 60px;">
                                  <input [disabled]="loading" (keyup)="buscarProducto($event)"
                                    (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,1)"
                                    (keydown)="navegarProductos($event)" (keydown.arrowdown)="down($event,1)"
                                    (keydown.enter)="enterProducto($event)" class="grid-editor text-dark"
                                    (keydown.tab)="enterProducto($event)"
                                    [id]="'txt_'+index+'_1' + idUnico" (focus)="focusProducto(index, $event, item)"
                                    (blur)="blurProducto($event,index)" type="text" [value]="item.Producto ? item.Producto.Clave: ''" />
                                </td>
                                <td>
                                  <span (click)="changueDescription(index, item)"
                                [class]="item.Producto?.ActualizarDescrpcion ? 'alert-link link':'grid-editor text-dark'">{{item.Descripcion}}</span>
                                </td>
                                <td style="width:60px;"><input *ngIf="item.Producto"
                                    [disabled]="loading" (keydown)="keyDownCantidad($event)"
                                    (keydown.escape)="escapeCantidad($event)" (keydown.arrowup)="up($event,3)"
                                    (keydown.arrowdown)="down($event,3)" (keydown.enter)="enterCantidad($event)"
                                    class="grid-editor text-dark" [id]="'txt_'+index+'_3' + idUnico" (blur)="blurCantidad($event)"
                                    (focus)="setIndexEdit(index, $event, item)" type="number"
                                    [style]="'width:100%;text-align:right;'" [value]="item.Producto ? item.Cantidad: 0" />
                                </td>
                                <td class="text-dark">
                                  <span style="margin-left: 3px;"> {{item.UnidadNombre}}</span>
                                </td>
                                <td>
                                  <span *ngIf="item.TextoConversiones" (click)="conversionClick(index)"
                                   class='alert-link link'>{{item.TextoConversiones}}</span>
                                  <!-- <a *ngIf="item.TextoConversiones" class="alert-link text-dark" (click)="conversionClick(index)"
                                    style="cursor:pointer;margin-left: 3px;">{{item.TextoConversiones}} </a> -->
                                </td>
                                <td style="width: 60px;">
                                  <input (keydown.arrowup)="up($event,6)" (keydown.arrowdown)="down($event,6)" [disabled]="loading"
                                    (keydown.escape)="escapeCosto($event)" *ngIf="item.Producto" (keydown.enter)="enterCosto($event)"
                                    class="grid-editor text-dark" [id]="'txt_'+index+'_6' + idUnico" (blur)="blurCosto($event)"
                                    (focus)="setIndexEdit(index, $event, item)" type="number"
                                    [style]="'width:70px;text-align:right;padding-right:1px;'"
                                    [value]="item.Producto ? item.CostoUnitario: 0" />
                                </td>
                                <td style="width: 60px;" class="text-lg-end pe-1">
                                  {{item.CostoUltimaCompra ? (item.CostoUltimaCompra | number:'1.2-6'):''}}
                                </td>
                                <td style="width: 50px;">
                                  <input (keydown.arrowup)="up($event,8)" (keydown.arrowdown)="down($event,8)" [disabled]="loading"
                                    (keydown.escape)="escapeDescuento($event)" *ngIf="item.Producto" (keydown.enter)="enterDescuento($event)"
                                    class="grid-editor text-dark" [id]="'txt_'+index+'_8' + idUnico" (blur)="blurDescuento($event)"
                                    (focus)="setIndexEdit(index, $event, item)" type="number"
                                    [style]="'width:100%;text-align:right;padding-right:1px;'"
                                    [value]="item.Producto ? item.DescuentoPorcentaje: 0" />
                                </td>
                                <td>
                                  <input style="width: 70px;" (keydown.arrowup)="up($event,9)"
                                    (keydown.arrowdown)="down($event,9)" [disabled]="loading"
                                    (keydown.escape)="escapePaquete($event)" *ngIf="item.Producto"
                                    (keydown.enter)="enterPaquete($event)" class="grid-editor text-dark"
                                    [id]="'txt_'+index+'_9' + idUnico" (blur)="blurPaquete($event)"
                                    (focus)="setIndexEdit(index, $event, item,9)" type="text"
                                    [style]="'width:100%;text-align:left;'"
                                    [value]="item.Producto ? item.NumeroLotePaquete: ''" />
                                </td>
                                <td class="text-lg-end pe-1">
                                  {{item.CostoProrreateado ? (item.CostoProrreateado | number:'1.2-6'):''}}
                                </td>
                                <td class="text-lg-end pe-1">
                                  {{item.CostoFinal ? (item.CostoFinal | number:'1.2-6'):''}}
                                </td>
                                <td style="width: 60px;">
                                  <input (keydown.arrowup)="up($event,12)" (keydown.arrowdown)="down($event,12)" [disabled]="loading"
                                    (keydown.escape)="escapeSubTotal($event)" *ngIf="item.Producto" (keydown.enter)="enterSubTotal($event)"
                                    class="grid-editor text-dark" [id]="'txt_'+index+'_12' + idUnico" (blur)="blurSubtotal($event)"
                                    (focus)="setIndexEdit(index, $event, item)" type="number"
                                    [style]="'width:100%;text-align:right;padding-right:1px;'"
                                    [value]="item.Producto ? item.Importe: 0" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-scrollbar>

                        <!-- <gui-grid [rowStyle]="rowStyle" [autoResizeWidth]="false" [loading]="showDescription||loading"
                          [width]="1200" [source]="source" [maxHeight]="150" [rowHeight]="20" [virtualScroll]="true"
                          [rowSelection]="false" [theme]="'fabric'" (sourceEdited)="sourceEdited($event)"
                          (cellEditSubmitted)="cellEditSubmitted()" (cellEditEntered)="cellEditEntered($event)"
                          [cellEditing]="cellEditing" [maxHeight]="190">
                          <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
                            <ng-template let-item="item" let-index="index">
                              <i (click)="deleteRow(index)" *ngIf="item.Producto?.Id>0" class="fa fa-rectangle-xmark"
                                style="color:red;font-weight: bold; cursor: pointer;"></i>
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Producto" [width]="110" [field]="'NoIdentificacion'"
                            [type]="'string'">
                          </gui-grid-column>
                          <gui-grid-column header="Descripción" [width]="205" [field]="'Descripcion'"
                            [cellEditing]="false">
                            <ng-template let-item="item" let-index="index">
                              <span (click)="changueDescription(index, item)"
                                [class]="item.Producto?.ActualizarDescrpcion ? 'alert-link link':''">{{item.Descripcion}}</span>
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Cantidad" [width]="80" [field]="'Cantidad'" [type]="'number'"
                            [align]="GuiColumnAlign.RIGHT">
                            <ng-template let-value="value">
                              {{value > 0 ? (value | number:'1.2-6'): ''}}
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Unidad" [width]="100" [field]="'UnidadNombre'"
                            (formatter)="cellFormatter($event,'unidad')" [cellEditing]="false" />
                          <gui-grid-column header="Conversión" [width]="80" [cellEditing]="false"
                            [field]="'TextoConversiones'" [type]="'number'" [align]="GuiColumnAlign.CENTER">
                            <ng-template let-value="value" let-index="index">
                              <a *ngIf="value" class="alert-link text-blue" (click)="conversionClick(index)"
                                style="cursor:pointer;">{{value}} </a>
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Costo" [width]="90" [field]="'CostoUnitario'"
                            [view]="GuiCellView.ITALIC" [type]="'number'" [align]="GuiColumnAlign.RIGHT">
                            <ng-template let-value="value">
                              {{value > 0 ? (value | number:'1.2-6'): ''}}
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Ult Comp" [width]="90" [field]="'CostoUltimaCompra'"
                            [view]="GuiCellView.ITALIC" [type]="'number'" [cellEditing]="false"
                            [align]="GuiColumnAlign.RIGHT">
                            <ng-template let-value="value" let-item="item">
                              {{value > 0 ? (value | number:'1.2-6'): ( item.Producto?.Id>0?'0.00': '')}}
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Descto" [width]="70" [field]="'DescuentoPorcentaje'"
                            [type]="'number'" [align]="GuiColumnAlign.RIGHT">
                            <ng-template let-value="value" let-item="item">
                              {{value > 0 ? (value | number:'1.2-6'): ( item.Producto?.Id>0?'0.00': '')}}
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Paquete/Lote" [width]="130" [field]="'NumeroLotePaquete'" />
                          <gui-grid-column header="Cargos" [width]="90" [field]="'CostoProrreateado'"
                            [cellEditing]="false" [align]="GuiColumnAlign.RIGHT">
                            <ng-template let-value="value" let-item="item">
                              {{value > 0 ? (value | number:'1.2-6'): ( item.Producto?.Id>0?'0.00': '')}}
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Costo Final" [width]="90" [field]="'CostoFinal'"
                            [cellEditing]="false" [align]="GuiColumnAlign.RIGHT">
                            <ng-template let-value="value">
                              {{value > 0 ? (value | number:'1.2-6'): ''}}
                            </ng-template>
                          </gui-grid-column>
                          <gui-grid-column header="Subtotal" [width]="100" [field]="'Importe'" [cellEditing]="false"
                            [align]="GuiColumnAlign.RIGHT" [cellEditing]="false">
                            <ng-template let-value="value">
                              {{value > 0 ? (value | number:'1.2-6'): ''}}
                            </ng-template>
                          </gui-grid-column>
                        </gui-grid> -->
                        <div class="card-body">
                          <table class="table table-borderless table-sm fw-bold m-0">
                            <tbody>
                              <tr>
                                <td colspan="3">
                                  <hr class="m-0">
                                </td>
                              </tr>
                              <tr>
                                <td class="w-150px">Subtotal</td>
                                <td></td>
                                <td class="text-end">${{myForm.value.SubTotal | number:'1.2'}}</td>
                              </tr>
                              <tr>
                                <td>IVA</td>
                                <td></td>
                                <td class="text-end">${{myForm.value.TotalIvaTrasladado | number:'1.2'}}</td>
                              </tr>
                              <tr *ngIf="myForm.value.TotalIvaRetenido>0">
                                <td class="text-danger ">IVA Retenido</td>
                                <td></td>
                                <td class="text-end">${{myForm.value.TotalIvaRetenido| number:'1.2'}}</td>
                              </tr>
                              <tr *ngIf="myForm.value.TotalIsrRetenido>0">
                                <td class="text-danger ">ISR Retenido</td>
                                <td></td>
                                <td class="text-end">${{myForm.value.TotalIsrRetenido| number:'1.2'}}</td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <hr class="m-0">
                                </td>
                              </tr>
                              <!-- <tr>
                                <td>Shipping Fee</td>
                                <td>promo code: <u class="text-success">FREESHIPPING</u> $1120.00</td>
                                <td class="text-end">$0.00</td>
                              </tr> -->
                              <tr>
                                <td class="pb-2" colspan="2"><b>Total</b></td>
                                <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                                  <b>${{myForm.value.Total |
                                    number:'1.2-6'}}</b>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <hr class="m-0">
                                </td>
                              </tr>
                              <tr>
                                <td class="w-150px">Suma Cantidad</td>
                                <td>
                                  <a class="text-primary text-decoration-none">{{getCantidad |
                                    number:'1.2-6'}}</a>
                                </td>
                                <td class="text-end"></td>
                              </tr>
                              <tr>
                                <td class="pt-2 pb-2" nowrap="">
                                  Total de importe prorrateado
                                </td>
                                <td class="pt-2 pb-2">
                                  <a class="text-primary text-decoration-none">${{gettotalCargoProrrateado | number:
                                    '1.2-6'}}</a>
                                </td>
                                <td class="pt-2 pb-2 text-end"></td>
                              </tr>
                              <tr>
                                <td class="pb-2" colspan="2"><b></b></td>
                                <td class="pt-2 pb-2 text-end">
                                  <a (click)="clicCargoAdicional()"
                                    class="btn btn-success btn-rounded px-4 rounded-pill"><i
                                      class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Agregar Cargo</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr *ngIf="myForm.value?.CargosAdicionales?.length>0">
                          <div *ngIf="myForm.value?.CargosAdicionales?.length>0">
                            <ng-scrollbar style="height: 60px; width:1105px; border-bottom: 1px solid #AAAAAA;">
                              <table class="blueTable" style="width:1050px;">
                                <thead style="position: sticky;top:0;">
                                  <tr>
                                    <th style="width: 30px;"></th>
                                    <th style="width: 20px;"></th>
                                    <th style="width: 60px;">Tipo</th>
                                    <th style="width: 120px;">Proveedor</th>
                                    <th style="width: 70px;">Moneda</th>
                                    <th style="width: 40px;min-width: 40px;max-width: 40px;text-align:right;padding-right:2px;">
                                      Tipo C.
                                    </th>
                                    <th style="width: 60px;min-width: 60px;max-width: 60px;text-align:right;padding-right:2px;">
                                      SubTotal
                                    </th>
                                    <th style="width: 60px;min-width: 60px;max-width: 60px;text-align:right;padding-right:2px;">
                                      IVA
                                    </th>
                                    <th style="width: 60px;min-width: 60px;max-width: 60px;text-align:right;padding-right:2px;">
                                      Total
                                    </th>
                                    <th style="width: 70px;">Prorrateado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                                    *ngFor="let item of myForm.value?.CargosAdicionales; let index = index;">
                                    <td style="text-align: center;">
                                      <i (click)="deleteCargos(index)"  class="fa fa-rectangle-xmark fa-1x"
                                        style="color:red;font-weight: bold; cursor: pointer;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                      <i (click)="clicCargoAdicional(index)"  class="fa fa-pencil fa-1x"
                                        style="color:rgb(255, 166, 0);font-weight: bold; cursor: pointer;"></i>
                                    </td>
                                    <td>
                                      <span style="margin-left: 3px;">{{item.Tipo.Nombre}}</span>
                                    </td>
                                    <td>
                                      <span style="margin-left: 3px;">{{item.Proveedor?item.Proveedor.Nombre:''}}</span>
                                    </td>
                                    <td>
                                      <span style="margin-left: 3px;">{{item.Moneda.Nombre}}</span>
                                    </td>
                                    <td class="text-lg-end pe-1">
                                      <span>{{item.TipoCambio| number:'1.2-6'}}</span>
                                    </td>
                                    <td class="text-lg-end pe-1">
                                      <span>{{item.Subtotal| number:'1.2-6'}}</span>
                                    </td>
                                    <td class="text-lg-end pe-1">
                                      <span>{{item.IVA| number:'1.2-6'}}</span>
                                    </td>
                                    <td class="text-lg-end pe-1">
                                      <span>{{item.Total| number:'1.2-6'}}</span>
                                    </td>
                                    <td>
                                      <span style="margin-left: 3px;">{{item.NoProrratear ? 'No': 'Si'}}</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-scrollbar>
                            <!-- <gui-grid [rowStyle]="rowStyle" [autoResizeWidth]="false" [width]="1100"
                              [source]="myForm.value.CargosAdicionales" [maxHeight]="150" [rowHeight]="20"
                              [virtualScroll]="true" [rowSelection]="false" [theme]="'fabric'" [cellEditing]="false">
                              <gui-grid-column [cellEditing]="false" header="" [width]="30"
                                [align]="GuiColumnAlign.CENTER">
                                <ng-template let-item="item" let-index="index">
                                  <i (click)="deleteCargos(index)" class="fa fa-rectangle-xmark"
                                    style="color:red;font-weight: bold; cursor: pointer;"></i>
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column [cellEditing]="false" header="" [width]="30"
                                [align]="GuiColumnAlign.CENTER">
                                <ng-template let-item="item" let-index="index">
                                  <i (click)="clicCargoAdicional(index)" class="fa fa-pencil text-yellow"
                                    style="font-weight: bold; cursor: pointer;"></i>
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="Tipo" [width]="80" [type]="'string'">
                                <ng-template let-item="item" let-index="index">
                                  {{item.Tipo.Nombre}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="Proveedor" [width]="150" [type]="'string'">
                                <ng-template let-item="item" let-index="index">
                                  {{(item.Proveedor ? (item.Proveedor.Nombre| uppercase): '')}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="Moneda" [width]="80" [type]="'string'">
                                <ng-template let-item="item" let-index="index">
                                  {{item.Moneda.Nombre}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="Tipo C" [width]="80" [type]="'string'"
                                [align]="GuiColumnAlign.RIGHT">
                                <ng-template let-item="item" let-index="index">
                                  {{item.TipoCambio | number:'1.2-6'}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="Subtotal" [width]="80" [type]="'string'"
                                [align]="GuiColumnAlign.RIGHT">
                                <ng-template let-item="item" let-index="index">
                                  {{item.Subtotal | number:'1.2-6'}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="IVA" [width]="80" [type]="'string'"
                                [align]="GuiColumnAlign.RIGHT">
                                <ng-template let-item="item" let-index="index">
                                  {{item.IVA | number:'1.2-6'}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="Total" [width]="80" [type]="'string'"
                                [align]="GuiColumnAlign.RIGHT">
                                <ng-template let-item="item" let-index="index">
                                  {{item.Total | number:'1.2-6'}}
                                </ng-template>
                              </gui-grid-column>
                              <gui-grid-column header="¿Prorrateado?" [width]="100" [type]="'string'"
                                [align]="GuiColumnAlign.CENTER">
                                <ng-template let-item="item" let-index="index">
                                  {{ item.NoProrratear ? 'No': 'Si'}}
                                </ng-template>
                              </gui-grid-column>
                            </gui-grid> -->
                          </div>
                        </div>
                        <hr *ngIf="myForm.value.Id>0">
                        <div *ngIf="myForm.value.Id>0" class="widget-list mb-0" style="width: 800px; display: flex;">
                          <div class="widget-list-item" style="width:50%;">
                            <div class="widget-list-media">
                              <i class="fas fa-user-check fa-2x text-green"></i>
                            </div>
                            <div class="widget-list-content">
                              <h4 class="widget-list-title">{{myForm.value.UsuarioAlta.Nombre}}</h4>
                              <p class="widget-list-desc">Usuario elaboró documento</p>
                            </div>
                          </div>
                          <div *ngIf="myForm.value.UsuarioCancela" class="widget-list-item" style="width:50%;">
                            <div class="widget-list-media">
                              <i class="fas fa-user-xmark fa-2x text-red"></i>
                            </div>
                            <div class="widget-list-content">
                              <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                              <p class="widget-list-desc">Usuario canceló documento</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--
                      -->
                  </div>


                </div>
              </app-panel-base>

            </div>
            <div class="invoice-date d-flex" style="flex-direction: row;">
              <app-panel-base title="Opciones" [style]="'height:260px;width:250px'">
                <div class="panel-content">
                  <components-ui-date-box label="Emisión" [tabIndex]="5" formControlName="FechaEmision"
                    [widthLabelColumns]="6" [widthTextColumns]="6" #txtFechaEmision />

                  <component-ui-combo-box [inputProperty]="'Nombre'" formControlName="Clase" [tabIndex]="6"
                    [entityName]="'ClaseCompra'" [filterQuery]="getFilterClase"
                    (onSelectedItem)="onSelectedItem($event,'Clase')" label="Clase" [widthTextColumns]="6"
                    [widthLabelColumns]="6" [widthRightColumns]="0" #cboClase />

                  <component-ui-combo-box [inputProperty]="'Nombre'" formControlName="Departamento" [tabIndex]="7"
                    [entityName]="'Departamento'" [filterQuery]="getFilterClase"
                    (onSelectedItem)="onSelectedItem($event,'Departamento')" label="Departamento" [widthTextColumns]="6"
                    [widthLabelColumns]="6" [widthRightColumns]="0" />

                  <component-ui-combo-box [required]="this.afectaAlmacen" [inputProperty]="'Nombre'"
                    formControlName="Almacen" [tabIndex]="8" [entityName]="'Almacen'"
                    (onSelectedItem)="onSelectedItem($event,'Almacen')" label="Almacén" [widthTextColumns]="6"
                    [widthLabelColumns]="6" [widthRightColumns]="0" />

                  <component-ui-combo-box [enabled]="false" formControlName="Condicion" [inputProperty]="'Nombre'"
                    [tabIndex]="9" [entityName]="'CondicionPago'" [extras]="'Tipo.Id as TipoId, Dias as Dias'"
                    label="Condición" (onSelectedItem)="onSelectedItem($event,'Condicion')" [widthTextColumns]="6"
                    [widthLabelColumns]="6" [widthRightColumns]="0" />

                  <components-ui-date-box [tabIndex]="10" [enabled]="false" label="Vencimiento"
                    formControlName="FechaVencimiento" [widthLabelColumns]="6" [widthTextColumns]="6" />
                  <div class="d-flex">
                    <component-ui-combo-box style="width:50%; margin-right: 2px;" formControlName="Moneda"
                      [topLabel]="true" [extras]="'EsExtranjera as EsExtranjera'" label='Moneda'
                      [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'Moneda')" entityName="Moneda"
                      [tabIndex]="11" [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0" />

                    <components-ui-number-box style="width:50%;" [topLabel]="true" formControlName="TipoCambio"
                      label='Tipo Cambio' [tabIndex]="12" [widthLabelColumns]="12" [enabled]="esMonedaExtranjera"
                      [widthTextColumns]="12" />
                  </div>
                </div>
              </app-panel-base>
              <app-panel-base title="Capturas extras" [style]="'height:260px;width:173px'">
                <div class="panel-content">
                  <div class="btn-group-vertical">
                    <div tabindex="0" (click)="openPaymentsSearch()" class="btn btn-orange "
                      style='margin-bottom: 5px;'>
                      Forma de
                      pago</div>
                    <div (click)="openComments()" class="btn btn-orange" style='margin-bottom: 5px;'>Comentarios</div>
                  </div>
                  <div *ngIf="myForm.value.Id>0" class="d-flex" style="flex-direction: row;">
                    <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">
                      <div *ngIf="myForm.value.Estado" class="btn btn-sm btn-white mb-10px me-1"><i
                          class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                      <div *ngIf="!myForm.value.Estado" class="btn btn-sm btn-white mb-10px me-1"><i
                          class="fa fa-calendar-xmark t-plus-1 text-success fa-fw fa-lg"></i> Cancelado</div>
                      <div (click)="canCancel()" *ngIf="myForm.value.Estado" class="btn btn-sm btn-white mb-10px me-1">
                        <i class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar
                      </div>
                    </div>
                  </div>
                </div>
              </app-panel-base>
            </div>
          </div>




          <!-- <div class="invoice-price" style="width:1200px;">
            <div class="totales-impuestos">
              <div style="display: flex;">
                <h5 style="width:70%;" class="subtotales">Cantidad Inventario:</h5>
                <h5 style="width:30%;" class="subtotales">{{getCantidad | number:'1.2-6'}}</h5>
              </div>
            </div>
            <div class="totales-impuestos"
              *ngIf="myForm.value.SubTotalGrabaIvaTrasladado == 0 && myForm.value.SubTotalGrabaIvaCero==0&&myForm.value.SubTotalGrabaIvaExento==0">
              <div style="display: flex;">
                <h5 style="width:70%;" class="subtotales">SUBTOTAL:</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.SubTotal | number:'1.2-6'}}</h5>
              </div>
            </div>
            <div class="totales-impuestos" *ngIf="myForm.value.SubTotalGrabaIvaTrasladado>0">
              <div style="display: flex;">
                <h5 style="width:70%;" class="subtotales">SUBTOTAL para IVA:</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.SubTotalGrabaIvaTrasladado | number:'1.2-6'}}
                </h5>
              </div>
            </div>
            <div class="totales-impuestos" *ngIf="myForm.value.SubTotalGrabaIvaCero>0">
              <div style="display: flex;">
                <h5 style="width:70%;" class="subtotales">SUBTOTAL para IVA (0%):</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.SubTotalGrabaIvaCero | number:'1.2-6'}}
                </h5>
              </div>
            </div>
            <div class="totales-impuestos" *ngIf="myForm.value.SubTotalGrabaIvaExento>0">
              <div style="display: flex;">
                <h5 style="width:70%;" class="subtotales">SUBTOTAL para EXENTO:</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.SubTotalGrabaIvaExento | number:'1.2-6'}}
                </h5>
              </div>
            </div>
            <div class="totales-impuestos">
              <div style="display: flex;">
                <h5 style="width:70%;" class="subtotales">IVA:</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.TotalIvaTrasladado | number:'1.2-6'}}</h5>
              </div>
            </div>
            <div class="totales-impuestos">
              <div style="display: flex;" *ngIf="myForm.value.TotalIsrRetenido>0">
                <h5 style="width:70%;" class="subtotales">ISR Retenido:</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.TotalIsrRetenido | number:'1.2-6'}}</h5>
              </div>
            </div>
            <div class="totales-impuestos">
              <div *ngIf="myForm.value.TotalIvaRetenido>0" style="display: flex;">
                <h5 style="width:70%;" class="subtotales">IVA Retenido:</h5>
                <h5 style="width:30%;" class="subtotales">${{myForm.value.TotalIvaRetenido | number:'1.2-6'}}</h5>
              </div>
            </div>
            <div class="totales-impuestos">
              <div *ngIf="myForm.value.SubTotalGrabaIvaExento == 0">
                <h5 style="width:70%;" class="subtotales"></h5>
              </div>
            </div>
            <div class="totales-impuestos">
              <div *ngIf="myForm.value.SubTotalGrabaIvaCero == 0">
                <h5 style="width:70%;" class="subtotales"></h5>
              </div>
            </div>
            <div class="totales-impuestos total text-dark">
              <div style="display: flex;">
                <h3 style="width:70%;text-align: right;" class="subtotales">TOTAL:</h3>
                <h3 style="width:30%;" class="subtotales">${{myForm.value.Total | number:'1.2-6'}}</h3>
              </div>
            </div>
          </div> -->


          <div *ngIf="myForm.value.Observaciones" class="widget-list"
            style="background-color:var(--bs-app-header-input-bg);margin-left: -15px;margin-top:4px;width: 1200px;padding-bottom: 0px;height: 55px;">
            <div class="widget-list-item">
              <div class="widget-list-media">
                <i class="fas fa-comment fa-2x text-blue"></i>
              </div>
              <div class="widget-list-content" style="cursor:pointer;" (click)="openComments()">
                <h4 class="widget-list-title">Comentarios</h4>
                <p class="widget-list-desc" [innerHtml]="myForm.value.Observaciones"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="additional-desc" *ngIf="showDescription">
        <app-panel-base title="Descripción">
          <div class="form-group panel-content">
            <textarea class="form-control" formControlName="DescripcionAdicional" rows="10" #txtDescription></textarea>
            <div style="text-align: right; margin-top: 5px;">
              <button (click)="acceptDescription()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
              <button (click)="cancelDescription()" class="btn btn-danger">Cancelar</button>
            </div>
          </div>
        </app-panel-base>
      </div>

      <div class="cancelled-header-compras animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate + " = " + myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
    </form>
  </div>

  <ng-template let-modal let-c="close" #ctrlAgregarCfdi>
    <app-ctrl-buscador-cfdi [rfcEmisor]="myForm.value.Proveedor.Rfc" [mostrarAgregados]="true"
      [uuidsAgregados]="getAgregados" [title]="'CFDI a relacionar'" [anio]="myForm.value.FechaEmision!.getFullYear()"
      [mes]="myForm.value.FechaEmision!.getMonth()" [setIsNew]="true" />
  </ng-template>

  <ng-template #busquedaProductos let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
      [propertys]="getProductsPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
  </ng-template>

  <ng-template #content let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
      [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
  </ng-template>

  <ng-template #modalFormaPago let-modal let-c="close">
    <app-compra-forma-pago-page [formasPago]="myForm.value.FormasPago" [total]="myForm.value.Total" />
  </ng-template>

  <ng-template #comentarioGlobal let-modal let-c="close">
    <app-compra-comentario-global-page [html]="myForm.value.Observaciones" />
  </ng-template>

  <ng-template #modalCargoAdicional let-modal let-c="close">
    <app-agregar-cargo-adicional-compra-page [item]="cargoEditar"  [tabIndex]="26"/>
  </ng-template>

  <ng-template #modalCancelOptiones let-modal let-c="close">
    <app-compra-opcion-cancelacion-page />
  </ng-template>

  <ng-template #modalConisgnado let-modal let-c="close">
    <app-consignado-compra-page [idProveedor]="myForm.value.Proveedor.Id"
      [nombreProveedor]="myForm.value.Proveedor.Nombre" />
  </ng-template>

  <ng-template #copiarComprobante let-modal let-c="close">
    <app-copiar-compra-page [afectaBackOrder]="afectaBackOrder" [canCopy]="documentosPuedeCopiar"
      [empresa]="myForm.value.Empresa" />
  </ng-template>

  <ng-template #pendienteEntregar let-modal let-c="close">
    <app-pendiente-entregar-compra-page [idCompra]="myForm.value.Id" />
  </ng-template>

  <!--



  <ng-template #cfdiRelacionado let-modal let-c="close">
    <app-cfdi-relacionado-page [idEmpresa]="myForm.value.Empresa.Id" />
  </ng-template>


  <ng-template #anticiposDisponibles let-modal let-c="close">
    <app-anticipos-disponibles-page [totalAplicar]="myForm.value.Total" [idEmpresa]="myForm.value.Empresa.Id"
      [idCliente]="myForm.value.Cliente.Id" />
  </ng-template>


-->
  <ng-template #ctrlConversiones let-modal let-c="close">
    <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
  </ng-template>

</app-container-base>
