import { Component, Input, OnInit, inject } from '@angular/core';
import { HistoricoVenta } from '../../interfaces/venta.interface';
import { GuiLocalization } from '@generic-ui/ngx-grid';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from '../../services/facturacion.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import * as moment from 'moment';

@Component({
  selector: 'app-ctrl-historico-cliente-page',
  templateUrl: './ctrl-historico-cliente-page.component.html',
  styles: [
  ]
})
export class CtrlHistoricoClientePageComponent implements OnInit {

  private eventsService = inject(EventsService);
  lista: HistoricoVenta[] = [];
  total: number = 0;
  localization: GuiLocalization = {
    'translation': {
      'sourceEmpty': 'No hay elementos para mostrar',
    }
  };

  @Input()
  nombreCliente: string = "";
  @Input()
  idEmpresa: number = 0;

  @Input()
  idCliente: number = 0;

  public myForm: FormGroup = this.fb.group({
    DocumentoVenta: [{} as DocumentoVenta],
    FechaInicio: [Date],
    FechaFin: [Date],
  });

  constructor(private ms: ModalService, private fs: FacturacionService, private fb: FormBuilder) {
    this.myForm.reset({
      DocumentoVenta: null,
      FechaInicio: new Date(),
      FechaFin: new Date()
    });
  }

  selectedCombo(ent: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(ent);
  }

  ngOnInit(): void {
    // this.is.ObtenerExistencas(this.idEmpresa, this.idProducto, 0).subscribe((lista) => {
    //   this.lista = lista;
    //   lista.forEach((item) => {
    //     this.total += item.existencia;
    //   })
    // })
  }

  ver(id: number) {
    this.ms.closeModal({ ver: true, copiar: false, id: id });
  }

  copiar(id: number) {
    this.ms.closeModal({ ver: false, copiar: true, id: id });
  }

  buscar() {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let idDocto = 0;
    if (ent.DocumentoVenta?.Id > 0) {
      idDocto = ent.DocumentoVenta.Id;
    }
    this.fs.ObtenerHistoricoCliente(this.idEmpresa, this.idCliente, idDocto, moment(ent.FechaInicio).format("YYYYMMDD"), moment(ent.FechaFin).format("YYYYMMDD")).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.lista = lista;
    })
  }

  closeModal() {
    this.ms.closeModal(null);
  }

}
