import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NonNullableFormBuilder } from '@angular/forms';

import { HttpClient, HttpParams } from '@angular/common/http';
import { ModalService } from 'src/app/service/modal.service';
import { Validators } from 'ngx-editor';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { Result } from 'src/app/auth/interfaces';
import { c_MotivoCancelacion } from 'src/app/ventas/interfaces/venta.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';

@Component({
  selector: 'app-compra-opcion-cancelacion-page',
  templateUrl: './compra-opcion-cancelacion-page.component.html',
  styleUrls: []
})
export class CompraOpcionCancelacionPageComponent implements OnInit {

  private readonly baseUrl: string = environment.baseUrlApi;


  public myForm: FormGroup = this.fb.group({
    Fecha: [Date, Validators.required],
    MotivoCancelacion: ['', Validators.required]
  })

  constructor(private fServices: FacturacionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private mService: ModalService) {
  }

  accept() {

    if (!this.myForm.value.MotivoCancelacion) {
      Swal.fire({ text: `Indique el motivo de cancelación.`, icon: 'error', })
      return;
    }

    this.mService.closeModal(this.myForm.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      MotivoCancelacion: '',
      MotivoSAT: { Id: 0 }
    };
    this.myForm.reset(ent);
  }



}
