import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-clientes-por-vendedor',
  templateUrl: './clientes-por-vendedor.component.html',
  styleUrls: ['./clientes-por-vendedor.component.scss']
})
export class ClientesPorVendedorComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  public frmClientesVendedor: FormGroup = this.frmBuilder.group({
    NombreReporte: ['Clientes por Vendedor', Validators.required],
    Num1: [this.ordenSeleccionado?.Id]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  ordenSeleccionado?: ComboBoxEntity;
  orden: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Por Clave", Clave: 1, ClaveNombre: "01 Por Clave" },
    { Id: 2, Nombre: "Por Nombre", Clave: 2, ClaveNombre: "02 Por Nombre" },
  ];

  get selectOrdenItem(): ComboBoxEntity | null {
    return this.ordenSeleccionado!;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      this.ordenSeleccionado = this.orden[0];
    }, 300);
  }

  public filtrosClientesVendedor: ReportFilter =
    {
      menuId: "clientesPorVendedor",
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Clientes por Vendedor'
      } as ReportHeader,
      NombreReporte: 'Clientes por Vendedor',
      TituloVisor: 'Clientes por Vendedor',
      NombreExcel: 'Clientes_Por_Vendedor.xlsx',
      FilterOptions: [
        { Campo: 'v.Clave', Etiqueta: 'Vendedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
      ]
    };

  imprimir(event: any) {
    this.filtrosClientesVendedor.ReportHeader = this.frmClientesVendedor.value;
    this.reportsService.printReport(this.filtrosClientesVendedor, '/Ventas/ReporteClientesPorVendedor');
  }

  changeselec(event: any) {
    this.filtrosClientesVendedor = event;
  }

  selectedCombo(entity: any, tipo: string) {
    this.ordenSeleccionado = entity;
    this.frmClientesVendedor.get('Num1')?.setValue(this.ordenSeleccionado?.Id);
  }
}
