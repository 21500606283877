import { AutoTransporte, CartaPorte } from './../../interfaces/cartaporte.interface';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { CartaPorteMercancia, Mercancia, TipoFigura, Ubicacion } from '../../interfaces/cartaporte.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CartaPorteService } from '../../services/cartaporte.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from '../../services/facturacion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { Cliente } from '../../interfaces/cliente.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { Unidad } from '../../interfaces/unidad.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import * as moment from 'moment';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import Swal from 'sweetalert2';
import { en, J } from '@fullcalendar/core/internal-common';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

@Component({
  selector: 'app-complemento-carta-porte-page',
  templateUrl: './complemento-carta-porte-page.component.html',
  styles: [
  ]
})
export class ComplementoCartaPortePageComponent implements OnInit {
  private readonly baseUrl: string = environment.baseUrlApi;
  public GuiColumnAlign = GuiColumnAlign;
  soloNacional: boolean = false;
  info: UserLogged = {} as UserLogged;
  blockCombos: boolean = false;
  listaComprobantes: ComboBoxEntity[] = [];
  listaSeries: ComboBoxEntity[] = [];
  file: any = null;
  listaUbicaciones: Ubicacion[] = [];
  ubicacionActual: Ubicacion | null = null;
  selectedIndexUbicacion: number = -1;
  editandoUbicacion: boolean = false;
  clickEditarUbicacion: boolean = false;

  listaMercancias: CartaPorteMercancia[] = [];
  mercanciaActual: CartaPorteMercancia | null = null;
  selectedIndexMercancia: number = -1;
  editandoMercancia: boolean = false;
  clickEditarMercancia: boolean = false;

  listaFiguras: TipoFigura[] = [];
  figuraActual: TipoFigura | null = null;
  selectedIndexFigura: number = -1;
  editandoFigura: boolean = false;
  clickEditarFigura: boolean = false;

  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;
  @ViewChild('ctrlMercancia')
  public ctrlMercancia!: ElementRef<HTMLElement>;
  @ViewChild('ctrlUbicacion')
  public ctrlUbicacion!: ElementRef<HTMLElement>;
  @ViewChild('ctrlFiguras')
  public ctrlFiguras!: ElementRef<HTMLElement>;


  @Output()
  public onAccept = new EventEmitter();

  @Input()
  public EsPlantilla: boolean = false;
  @Input()
  public CartaPorte: CartaPorte | null = null;
  @Input()
  set setCartaPorteCatalogo(cp: CartaPorte | null) {
    if (cp) {
      const jsonActual = this.CartaPorte ? JSON.stringify(this.CartaPorte) : "";
      const jsonNuevo = JSON.stringify(cp);
      if (jsonActual != jsonNuevo) {
        this.CartaPorte = { ...cp };
        this.myForm.reset({ ...cp })
        this.listaFiguras = [...cp.Figuras];
        this.listaMercancias = [...cp.Mercancias.Mercancias];
        this.listaUbicaciones = [...cp.Ubicaciones];
      }
    }
  };
  private eventsService = inject(EventsService);
  menuItems: PanelBaseDropDownMenu[] = [
    { Id: 'importar', Nombre: 'Importar JSON' },
    { Id: 'exportar', Nombre: 'Exportar JSON' }
  ]

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [null],
    Sucursal: [null],
    Documento: [null],
    SerieString: [''],
    Serie: [null],
    Folio: [0],
    Cliente: [null],
    Elabora: [null],
    FechaEmision: [null],
    FechaCancelacion: [null],
    Version: [''],
    IdCCP: [''],
    FechaEmisionString: [''],
    RegimenAduanero: [''],
    TranspInternac: [false],
    EntradaMerc: [false],
    SalidaMerc: [false],
    EntradaSalidaMerc: [''],
    PaisOrigenDestino: [''],
    ViaTransporte: [''],
    TipoTransporte: [0],
    TotalDistRec: [0],
    EsRegistroISTMO: [false],
    RegistroISTMO: [''],
    UbicacionPoloOrigen: [''],
    UbicacionPoloDestino: [''],
    Ubicaciones: [[] as Ubicacion[]],
    Mercancias: [{} as Mercancia],
    Figuras: [[] as TipoFigura[]],
  })

  constructor(private cpService: CartaPorteService,
    private utileService: UtilsService,
    private fb: FormBuilder,
    private http: HttpClient,
    private ms: ModalService,
    private fus: FileUploadService,
    private cService: ContainerBaseService) {
  }

  clicMenuPanel(value: string) {
    if (value == "exportar") {
      this.exportar();
    } else {
      this.importar();
    }
  }

  importar() {
    document.getElementById("fileExport")!.click();
  }

  guardarPlantilla() {

    let ent: any = { ...this.myForm.value };
    if (!ent.Mercancias) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Vehículo donde se enviará la mercancía.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }

    if (this.listaMercancias.length > 0) {
      ent.Mercancias.Mercancias = this.listaMercancias.filter(P => P.Cantidad > 0);
    }

    if (ent.Mercancias.Mercancias.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar las Mercancías.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }

    if (!ent.Ubicaciones || ent.Ubicaciones?.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar las ubicaciones.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }

    if (!ent.Figuras || ent.Figuras?.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar las figuras.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }
    ent.Mercancias.PesoBrutoTotal = this.getPesoTotal;
    ent.Mercancias.NumTotalMercancias = this.getNumeroMercancias;
    if (ent.Mercancias.AutoTransporte.Remolques?.length == 0) {
      ent.Mercancias.AutoTransporte.Remolques = null;
    }
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de agregar como plantilla?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          Swal.fire({
            title: "Indique el nombre de la plantilla",
            input: "text",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Guardar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.cpService.savePlantilla(result.value, JSON.stringify(ent)).subscribe((result) => {
                  if (result.success) {
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 });
                  } else {
                    this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
                  }
                })
              } else {
                this.eventsService.publish('home:showAlert', { message: "Debe de indicar un nombre de plantilla.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
              }
            }
          });
        }
      }
    });
    return;
  }

  documentSelected(e: any) {
    const f = e.target.files[0];
    var reader = new FileReader();
    reader.onload = ((theFile) => {
      return function (e) {
        const r: any = e.target!.result;
        let ent = JSON.parse(r);
      };
    })(f);
    if (f) { reader.readAsText(f); }
  }


  exportar() {
    const csv = JSON.stringify(this.myForm.value)
    const a = document.createElement('a');
    a.textContent = 'download';
    a.download = "cartaporteexport.txt";
    a.href = 'data:text/txt;charset=utf-8,' + escape(csv);
    document.body.appendChild(a);
    a.click();
  }

  selectPlantilla(ent: any) {

    const json = ent.JSON;
    let entidad: CartaPorte = JSON.parse(json);
    entidad.Id = 0;
    entidad.Figuras.map((f) => { f.Id = 0; return f; })
    if (entidad.Mercancias) {
      entidad.Mercancias.Id = 0;
      if (entidad.Mercancias.AutoTransporte) {
        entidad.Mercancias.AutoTransporte.IdentificacionVehicular.Id = 0;
        entidad.Mercancias.AutoTransporte.Id = 0;
        entidad.Mercancias.AutoTransporte.Seguros.map((i) => { i.Id = 0; return i; });
      }
      entidad.Mercancias.Mercancias.map((i) => { return i.Id = 0; });
    }
    entidad.Ubicaciones.map((i: Ubicacion) => {
      if (i.Domicilio) { i.Domicilio.Id = 0; }
      return i.Id = 0;
    });
    this.myForm.reset(entidad);
    this.listaFiguras = [...entidad.Figuras];
    this.listaMercancias = [...entidad.Mercancias.Mercancias];
    this.listaUbicaciones = [...entidad.Ubicaciones];
    this.resetMercancias(this.listaMercancias, true);
    this.resetUbicaciones(this.listaUbicaciones, true);
    this.resetFiguras(this.listaFiguras, true);
  }

  selectedCombo(ent: ComboBoxEntity) {
    if (ent) {
      this.cpService.getUnidad(ent.Id).subscribe((result) => {
        let u: Unidad = JSON.parse(result.message)
        if (!u.Aseguradora) {
          this.eventsService.publish('home:showAlert', { message: "El Vehículo no tiene asignada una aseguradora.", cancelButton: false, icon: 'fa-circle-info text-blue' });
          return;
        }
        if (!u.NumPoliza) {
          this.eventsService.publish('home:showAlert', { message: "El Vehículo no tiene asignado un número de póliza.", cancelButton: false, icon: 'fa-circle-info text-blue' });
          return;
        }
        let mercancia: Mercancia = {
          Id: 0,
          PesoBrutoTotal: 0,
          UnidadPeso: '',
          PesoNetoTotal: 0,
          NumTotalMercancias: 0,
          CargoPorTrasacion: 0,
          Mercancias: [],//CartaPorteMercancia[],
          AutoTransporte: {
            Id: 0,
            PermSCT: u.TipoPermisoSCT.Clave,
            NumPermisoSCT: u.NumPersmisoSCT,
            IdentificacionVehicular: {
              Id: 0,
              ConfigVehicular: u.ConfigAutotransporte,
              PlacaVM: u.Placas,
              PesoBrutoVehicular: u.PesoBrutoVehicular,
              AnioModeloVM: u.Anio
            },
            Seguros: [{
              Id: 0,
              AseguraRespCivil: u.Aseguradora,
              PolizaRespCivil: u.NumPoliza,
              AseguraMedAmbiente: '',
              PolizaMedAmbiente: '',
              AseguraCarga: '',
              PolizaCarga: '',
              PrimaSeguro: 0,
              AutoTransporte: null
            }],
            Remolques: [],
          },
          Eliminado: false,
          Baja: false
        }
        if (u.TipoRemolque) {
          mercancia.AutoTransporte.Remolques = [{
            SubTipoRem: u.TipoRemolque.Clave,
            Placa: u.PlacasRemolque
          }]
        }

        this.myForm.controls["Mercancias"].setValue(mercancia);


      })
    }
  }

  AgregarUbicacion() {
    if (!this.myForm.value.Mercancias) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el vehículo.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return;
    }
    this.initEditUbicacion(-1);
  }

  initEditUbicacion(index: number) {
    this.selectedIndexUbicacion = index;
    const sel: any = this.listaUbicaciones[this.selectedIndexUbicacion];
    this.ubicacionActual = sel;
    this.editandoUbicacion = true;
    const b: any = this.ctrlUbicacion;
    this.ms.openModal(b, (e: any) => {
      this.onCloseEditUbicacion(e);
    }, 'md')
  }

  onCloseEditUbicacion(mov: Ubicacion): void {
    if (!mov) {
      this.editandoUbicacion = false;
      this.selectedIndexUbicacion = -1;
      this.ubicacionActual = null;
      this.resetUbicaciones(this.listaUbicaciones, false);
    } else {
      if (this.selectedIndexUbicacion == -1) {
        this.selectedIndexUbicacion = this.listaUbicaciones.length - 1;
      }
      if (mov.Id == null) { mov.Id = 0; }
      this.listaUbicaciones[this.selectedIndexUbicacion] = mov;
      this.myForm.controls["Ubicaciones"].setValue(this.listaUbicaciones.filter(P => P.IDUbicacion));
      this.resetUbicaciones(this.listaUbicaciones, true);
      this.setTotalDistancia();
      this.editandoUbicacion = false;
      setTimeout(() => {
        this.AgregarUbicacion();
      }, 100);
    }
  }

  resetUbicaciones(details: Ubicacion[] | null, agregar: boolean = false) {
    if (details) {
      this.listaUbicaciones = [...details];
    } else {
      this.listaUbicaciones = []
    }
    if (agregar && !this.clickEditarUbicacion) {
      this.listaUbicaciones = [...this.listaUbicaciones, this.nuevaUbicacion()];
    }

    this.editandoUbicacion = false;
    this.selectedIndexUbicacion = -1;
    this.ubicacionActual = null;
    this.clickEditarUbicacion = false;

  }

  resetFiguras(details: TipoFigura[] | null, agregar: boolean = false) {
    if (details) {
      this.listaFiguras = [...details];
    } else {
      this.listaFiguras = []
    }
    if (agregar && !this.clickEditarFigura) {
      this.listaFiguras = [...this.listaFiguras, this.nuevaFigura()];
    }

    this.editandoFigura = false;
    this.selectedIndexFigura = -1;
    this.figuraActual = null;
    this.clickEditarFigura = false;
  }

  resetMercancias(details: CartaPorteMercancia[] | null, agregar: boolean = false) {
    if (details) {
      this.listaMercancias = [...details];
    } else {
      this.listaMercancias = []
    }
    if (agregar && !this.clickEditarMercancia) {
      this.listaMercancias = [...this.listaMercancias, this.nuevaMercancia()];
    }

    this.editandoMercancia = false;
    this.selectedIndexMercancia = -1;
    this.mercanciaActual = null;
    this.clickEditarMercancia = false;
  }

  nuevaUbicacion(): Ubicacion {
    return {
      Id: 0,
      EsOrigen: false,
      EsDestino: false,
      EsAltura: false,
      EsCabotaje: false,
      TipoUbicacion: '',
      IDUbicacion: '',
      RFCRemitenteDestinatario: '',
      NombreRemitenteDestinatario: '',
      NumRegIdTrib: '',
      ResidenciaFiscal: null,
      NumEstacion: null,
      NombreEstacion: '',
      NavegacionTrafico: '',
      FechaHoraSalidaLlegada: new Date(),
      Hora: `${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`,
      TipoEstacion: null,
      DistanciaRecorrida: 0,
      Domicilio: null,
    };
  }

  nuevaMercancia(): CartaPorteMercancia {
    return {
      Id: 0,
      ClaveArticuloInterno: '',
      BienesTransp: '',
      ClaveSTCC: '',
      Descripcion: '',
      Cantidad: 0,
      ClaveUnidad: '',
      Unidad: '',
      Dimensiones: '',
      ColocarMaterialPeligroso: false,
      MaterialPeligroso: false,
      CveMaterialPeligroso: '',
      Embalaje: '',
      DescripEmbalaje: '',
      SectorCOFEPRIS: '',
      NombreIngredienteActivo: '',
      NomQuimico: '',
      DenominacionGenericaProd: '',
      DenominacionDistintivaProd: '',
      Fabricante: '',
      FechaCaducidad: null,
      LoteMedicamento: '',
      FormaFarmaceutica: '',
      CondicionesEspTransp: '',
      RegistroSanitarioFolioAutorizacion: '',
      PermisoImportacion: '',
      FolioImpoVUCEM: '',
      NumCAS: '',
      RazonSocialEmpImp: '',
      NumRegSanPlagCOFEPRIS: '',
      DatosFabricante: '',
      DatosFormulador: '',
      DatosMaquilador: '',
      UsoAutorizado: '',
      PesoEnKG: 0,
      ValorMercancia: 0,
      Moneda: '',
      FraccionArancelaria: '',
      UUIDComercioExt: ''
    };
  }

  nuevaFigura(): TipoFigura {
    return {
      Id: 0,
      Tipo: null,
      TipoNombre: '',
      RFCFigura: '',
      NumLicencia: '',
      NombreFigura: '',
      NumRegIdTribFigura: '',
      ResidenciaFiscalFigura: null,
      Partes: null,
    };
  }

  searchFile() {
    this.txtFile.nativeElement.click();
  }

  AgregarMercancia() {
    if (!this.myForm.value.Mercancias) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el vehículo.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return;
    }
    this.initEditMercancia(-1);
  }

  initEditMercancia(index: number) {

    this.selectedIndexMercancia = index;
    if (index >= 0) {
      const sel: any = this.listaMercancias[this.selectedIndexMercancia];
      this.mercanciaActual = sel;
    }

    const b: any = this.ctrlMercancia;
    this.ms.openModal(b, (e: any) => {
      this.onCloseEditMercancia(e);
    }, 'md')

    this.editandoMercancia = true;
  }

  deleteRowMercancia(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: "¿Desea eliminar la mercancía?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.listaMercancias.splice(index, 1);
          this.listaMercancias = [...this.listaMercancias];
        }
      }
    });

  }

  onCloseEditMercancia(mov: CartaPorteMercancia): void {
    this.editandoMercancia = false;
    if (!mov) {
      this.selectedIndexMercancia = -1;
      this.mercanciaActual = null;
      this.resetMercancias(this.listaMercancias, false);
    } else {
      if (this.selectedIndexMercancia == -1) {
        this.selectedIndexMercancia = this.listaMercancias.length - 1;
      }
      if (mov.Id == null) { mov.Id = 0; }
      this.listaMercancias[this.selectedIndexMercancia] = mov;
      let m = this.myForm.value.Mercancias;
      m.Mercancias = this.listaMercancias.filter(P => P.BienesTransp);
      this.myForm.controls["Mercancias"].setValue(m);
      this.resetMercancias(this.listaMercancias, true);
      this.mercanciaActual = null;
    }
  }


  setTotalDistancia() {
    setTimeout(() => {
      let suma = 0;
      this.listaUbicaciones.forEach((item) => {
        if (item.DistanciaRecorrida) { suma += parseFloat(String(item.DistanciaRecorrida)); }
      })
      this.myForm.controls["TotalDistRec"].setValue(suma);
    }, 250);
  }

  editarUbicacion(index: number) {
    this.clickEditarUbicacion = true;
    this.editandoUbicacion = true;
    this.selectedIndexUbicacion = index;
    this.ubicacionActual = { ...this.listaUbicaciones[index] };
    this.initEditUbicacion(index);
  }
  editarMercancia(index: number) {
    this.clickEditarMercancia = true;
    this.editandoMercancia = true;
    this.selectedIndexMercancia = index;
    this.mercanciaActual = { ...this.listaMercancias[index] };
    this.initEditMercancia(index);
  }

  deleteRowUbicacion(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: "¿Desea eliminar la ubicación?", cancelButton: false, onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.listaUbicaciones.splice(index, 1);
          this.listaUbicaciones = [...this.listaUbicaciones];
          this.setTotalDistancia();
        }
      }
    });
  }

  onchange(event: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.file = event.target.files.length > 0 ? event.target.files[0] : null
    this.fus.upload(this.file, 0, 'Utilerias/UploadFile', () => {
      this.getMercancias();
    });
  }

  getMercancias() {
    const params = new HttpParams().set("fileName", this.file.name);
    this.http.get<Result>(`${this.baseUrl}/Ventas/ObtenerMercanciasImportacion`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        let mcias: CartaPorteMercancia[] = JSON.parse(result.message);
        this.listaMercancias = [...mcias];
        this.resetMercancias(this.listaMercancias, true);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })
  }

  initEditFiguras(index: number) {
    this.selectedIndexFigura = index;
    const sel: any = this.listaFiguras[this.selectedIndexFigura];
    this.figuraActual = sel;

    this.editandoFigura = true;
    const b: any = this.ctrlFiguras;
    this.ms.openModal(b, (e: any) => {
      this.onCloseEditFigura(e);
    }, 'md')
  }

  AgregarFigura() {
    if (!this.myForm.value.Mercancias) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el vehículo.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return;
    }
    this.initEditFiguras(this.listaFiguras.length - 1);
  }


  deleteRowFigura(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: "¿Desea eliminar la Figura?", cancelButton: false, onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.listaFiguras.splice(index, 1);
          this.listaFiguras = [...this.listaFiguras];
          this.resetFiguras(this.listaFiguras, this.listaFiguras.length == 0);
        }
      }
    });
  }

  onCloseEditFigura(mov: TipoFigura): void {
    this.editandoFigura = false;
    if (!mov) {
      this.selectedIndexFigura = -1;
      this.figuraActual = null;
      this.resetFiguras(this.listaFiguras, false);
    } else {
      const num = this.listaFiguras.filter(P => P.NumLicencia == mov.NumLicencia).length;
      if (num == 0) {
        mov.Id = 0;
        this.listaFiguras[this.selectedIndexFigura] = mov;
        this.myForm.controls["Figuras"].setValue(this.listaFiguras.filter(P => P.NumLicencia));
        this.resetFiguras(this.listaFiguras, true);
      } else {
        this.resetFiguras(this.listaFiguras, false);
      }
    }
  }


  get getTransporteDatos(): string {
    let t = "";
    const ent = this.myForm.value;
    if (ent.Mercancias) {
      if (ent.Mercancias.AutoTransporte) {
        t += `Placas: ${ent.Mercancias.AutoTransporte.IdentificacionVehicular.PlacaVM} | ${ent.Mercancias.AutoTransporte.IdentificacionVehicular.ConfigVehicular.Nombre}`;
      }
    }
    return t;
  }

  get getMercanciasDatos(): string {
    let t = "";
    const ent = this.myForm.value;
    if (ent.Mercancias?.Mercancias) {
      t = `Total de conceptos de mercancías: ${this.listaMercancias.length - 1}`;
    }
    return t;
  }

  get getFigurasDatos(): string {
    let t = "";
    const ent = this.myForm.value;
    if (ent.Figuras) {
      ent.Figuras.forEach((item: TipoFigura) => {
        t += `Nombre: ${item.NombreFigura}, Licencia: ${item.NumLicencia}`;
      });
    }
    return t;
  }
  get getUbicacionesDatos(): string {
    let t = "";
    const ent = this.myForm.value;
    if (ent.Ubicaciones) {
      ent.Ubicaciones.forEach((item: Ubicacion) => {
        if (item.EsOrigen) {
          t += `Origen: CP: ${item.Domicilio?.CodigoPostal}, Colonia: ${item.Domicilio?.Colonia}`;
        }
      });
    }
    return t;
  }

  get getPorcentaje(): number {
    let total: number = 0;
    const ent: CartaPorte = this.myForm.value;
    if (ent.Mercancias) {
      if (ent.Mercancias.AutoTransporte) {
        total += 25;
      }
    }

    if (ent.Ubicaciones?.length > 0) {
      total += 25;
    }
    if (ent.Mercancias) {
      if (ent.Mercancias.Mercancias) {
        if (ent.Mercancias.Mercancias.length > 0) {
          total += 25;
        }
      }
    }

    if (ent.Figuras?.length > 0) {
      total += 25;
    }

    return total;
  }

  get getSelectedMercancia(): CartaPorteMercancia {
    return { ...this.mercanciaActual! };
  }

  get getSelectedUbicacion(): Ubicacion {
    return { ...this.ubicacionActual! };
  }

  get getSelectedTipoFigura(): TipoFigura {
    return { ...this.figuraActual! };
  }

  get getNumeroMercancias(): number {
    return this.listaMercancias.filter(P => P.BienesTransp).length
  }
  get getPesoTotal(): number {
    let suma: number = 0;
    this.listaMercancias.forEach((item) => {
      suma += item.PesoEnKG;
    })
    return suma;
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    if (!this.CartaPorte) {
      this.getEmptyEntity();
    } else {
      this.myForm.reset({ ...this.CartaPorte })
      this.listaFiguras = [...this.CartaPorte.Figuras];
      this.listaMercancias = [...this.CartaPorte.Mercancias.Mercancias];
      this.listaUbicaciones = [...this.CartaPorte.Ubicaciones];
      this.resetUbicaciones(this.listaUbicaciones, true);
      this.resetFiguras(this.listaFiguras, true);
      this.resetMercancias(this.listaMercancias, true);
    }
  }

  getDate(item: Ubicacion) {
    if (item.IDUbicacion) {
      return moment(item.FechaHoraSalidaLlegada).format("DD/MM/YYYY HH:mm");
    }
    return "";
  }

  getEmptyEntity() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.cService.getEmptyEntity("CartaPorte").subscribe((ent) => {
      this.initializeEntity(ent)
    })
  }

  initializeEntity(ent: any, idSerie: number = 0, idDocto: number = 0) {
    this.eventsService.publish('home:isLoading', { isLoading: false });
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero };
    ent.FechaEmision = new Date();
    ent.Folio = 1;
    this.myForm.reset(ent);
    this.listaUbicaciones = [];
    this.listaFiguras = [];
    this.listaMercancias = [];
    this.resetUbicaciones(this.listaUbicaciones, true);
    this.resetFiguras(this.listaFiguras, true);
    this.resetMercancias(this.listaMercancias, true);
  }

  accept() {
    let ent: any = this.myForm.value;
    if (ent.Id > 0) {
      return;
    }
    if (!ent.Mercancias) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Vehículo donde se enviará la mercancía.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }

    if (this.listaMercancias.length > 0) {
      ent.Mercancias.Mercancias = this.listaMercancias.filter(P => P.Cantidad > 0);
    }


    if (this.listaUbicaciones.length > 0) {
      ent.Ubicaciones = this.listaUbicaciones.filter(P => P.IDUbicacion);
      ent.Ubicaciones.map((i: Ubicacion) => {
        if (i.DistanciaRecorrida == null) {
          i.DistanciaRecorrida = 0;
        }
        if (i.EsDestino == null) {
          i.EsDestino = false;
        }
        if (i.EsOrigen == null) {
          i.EsOrigen = false;
        }
        return i;
      })


    }

    if (ent.Mercancias.Mercancias.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar las Mercancías.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }

    if (!ent.Ubicaciones || ent.Ubicaciones?.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar las ubicaciones.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }

    if (!ent.Figuras || ent.Figuras?.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar las figuras.", cancelButton: false, icon: 'fa-circle-info text-blue' });
      return false;
    }
    ent.Mercancias.PesoBrutoTotal = this.getPesoTotal;
    ent.Mercancias.NumTotalMercancias = this.getNumeroMercancias;
    if (ent.Mercancias.AutoTransporte.Remolques?.length == 0) {
      ent.Mercancias.AutoTransporte.Remolques = null;
    }

    if (!this.EsPlantilla) {
      this.ms.closeModal(ent);
    } else {
      this.onAccept.emit(ent);
    }
    return;
  }

  closeModal() {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de cerrar la captura de la Carta Porte? la información capturada se perderá.", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.ms.closeModal(null);
        }
      }
    });


  }

}
