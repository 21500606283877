import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import * as Tagify from '@yaireo/tagify';
import { CajaChica, CajaChicaDetalle, TipoCajaChica } from '../../interfaces/tipotransferencia.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { subDays } from 'date-fns';
import { EventsService } from 'src/app/service/events.service';
import Swal from 'sweetalert2';
import { GuiColumnAlign, GuiLocalization, GuiRowStyle } from '@generic-ui/ngx-grid';
import { FinanzasService } from '../../services/finanzas.service';
import * as moment from 'moment';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';


@Component({
  selector: 'app-caja-chica-page',
  templateUrl: './caja-chica-page.component.html',
  styles: [
  ]
})
export class CajaChicaPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  private reportsService = inject(ReportsService);


  public GuiColumnAlign = GuiColumnAlign;
  info: UserLogged = {} as UserLogged;
  detalle: CajaChicaDetalle[] = [];
  selectedIndex: number = -1;
  editing: boolean = false;
  selectedItem: CajaChicaDetalle | null = null;

  rowStyle: GuiRowStyle = {
    styleFunction: (data: CajaChicaDetalle, index: number) => {
      if (data.uuid) {
        return 'background-color:#e3f9c2e0; font-weight: bold;';
      }
      return '';
    }
  };

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }

  private newEntityObj = {
    id: [0],
    clave: [0],
    nombre: ['', [Validators.required]],
    empresa: [{} as Empresa],
    sucursal: [{} as Sucursal],
    fecha: [null],
    tipo: [{} as TipoCajaChica],
    usuarioAlta: [{} as Usuario],
    usuarioModifica: [{} as Usuario],
    detalle: [[] as CajaChicaDetalle[]],
    idPoliza: [0],
    contabilizado: [false],
    eliminado: [false],
    baja: [false],
  };


  public filtroCajaChica: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de caja chica',
      NombreExcel: 'Reporte de caja chica.xlsx',
      FilterOptions: [
        { Campo: 'caj.Id', Etiqueta: 'Tipo caja chica', Tipo: TypeFilter.number, Valor: '0' },
      ]
    };


    localization: GuiLocalization = {
	    'translation': {
          'sourceEmpty': 'No hay elementos para mostrar',
	    }
	};


  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('cboTipo')
  public cboTipo!: ElementRef<TextBoxComponent>;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private fService: FinanzasService, private utileService: UtilsService) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    setTimeout(() => {
      const t: any = this.cboTipo;
      t.tagInput.nativeElement.focus();
    }, 150);
  }

  deleteRow(index: number) {
    Swal.fire({
      title: '¿Desea eliminar el registro?',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const idEliminar = this.detalle[index].id;
        if (idEliminar > 0) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.fService.eliminarDetalle(idEliminar).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (result.success) {
              this.detalle.splice(index, 1);
              this.detalle.map((i, ix) => {
                i.orden = ix + 1;
                return i;
              })
              this.detalle = [...this.detalle];
            } else {
              Swal.fire({ text: result.message, icon: 'error', })
            }
          })
        } else {
          this.detalle.splice(index, 1);
          this.detalle.map((i, ix) => {
            i.orden = ix + 1;
            return i;
          })
          this.detalle = [...this.detalle];
        }
      }
    })
  }

  onSelectedItem(ent: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(ent);
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.imprimir(); break;
      case "first": break;
      case "left": break;
      case "right": break;
      case "last": break;
    }
  }


  imprimir() {

    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('fecha')?.value,
      Fecha2: this.myForm.get('fecha')?.value,
      NombreReporte: '',
      Dato1: '',
      Opc1: true

    }
    let filtro: FilterOptions | undefined = this.filtroCajaChica.FilterOptions?.find(p => p.Campo == 'caj.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('id')?.value);
    }
    this.filtroCajaChica.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroCajaChica, '/Tesoreria/ReporteVerificadorCajaChica');


  }
  new() {
    this.containerService.getEmptyEntity("CajaChica").subscribe((ent) => {
      this.resetSource(null);
      this.myForm.reset(ent);
      const t: any = this.cboTipo;
      t.tagInput.nativeElement.focus();
    })
  }

  save() {
    let ent = this.myForm.value;
    let idTipo = 0;

    if (ent.tipo) {
      if (ent.tipo.Id > 0) { idTipo = ent.tipo.Id; }
      if (ent.tipo.id > 0) { idTipo = ent.tipo.id }
    }

    if (idTipo == 0) {
      Swal.fire({ icon: 'error', text: "Debe de indicar un Tipo de Caja Chica." });
      return;
    }
    if (!ent.nombre) {
      Swal.fire({ icon: 'error', text: "Debe de indicar una descripción." });
      return;
    }
    ent.detalle = this.detalle.filter(P => P.proveedor);
    if (!ent.detalle || ent.detalle?.length == 0) {
      Swal.fire({ icon: 'error', text: "Debe de indicar los detalles de la Caja Chica." });
      return;
    }

    ent.empresa = { Id: this.info.empresa?.numero };
    ent.sucursal = { Id: this.info.sucursal?.numero };

    let idProv = 0;

    if (ent.proveedor) {
      if (ent.proveedor.Id > 0) { idProv = ent.proveedor.Id; }
      if (ent.proveedor.id > 0) { idProv = ent.proveedor.id; }
    }
    ent.proveedor = { Id: idProv };
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.containerService.saveEntity("CajaChica", JSON.stringify({ ...ent })).subscribe((item) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (item.success) {
        const d = moment(ent.fecha).toDate();
        this.onChangeValue(d);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 })
      } else {
        Swal.fire({ icon: 'error', text: item.message }).then(() => { });
      }
    })
  }

  resetSource(details: CajaChicaDetalle[] | null, continueEditing = false) {
    if (details) {
      this.detalle = [...details.filter(P => P.proveedor)];
    } else {
      this.detalle = []
    }
    const length = this.detalle.length;
    this.detalle = [...this.detalle, {
      id: 0,
      orden: 0,
      proveedor: null,
      clase: null,
      concepto: '',
      serieFolioFactura: '',
      referencia: '',
      uuid: '',
      subtotal: 0,
      iva: 0,
      iVARetenido: 0,
      iSRRetenido: 0,
      impLocalTrasladado: 0,
      impLocalRetenido: 0,
      total: 0,
    }];

    setTimeout(() => {
      //const rows = document.getElementById("divCajaChica")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
      const rows: any = document.getElementById("divCajaChica")!.querySelectorAll('.gui-content')[0].childNodes;
      if (rows) {
        rows.forEach((item: any, index: number) => {
          if (item.classList) {
            item.classList.remove("editing-row");
            item.classList.remove("pulse-row");
            item.addEventListener("dblclick", () => {
              this.onDblClickRow(index);
            });
          }
        })
      }

      if (continueEditing) {
        this.initEdit(this.selectedIndex + 1);
      } else {
        this.editing = false;
        this.selectedIndex = -1;
        this.selectedItem = null;
      }

    }, 100);
  }

  onDblClickRow(index: number) {
    if (this.editing) return;
    //const rows = document.getElementById("divCajaChica")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
    const rows: any = document.getElementById("divCajaChica")!.querySelectorAll('.gui-content')[0].childNodes;
    if (rows) {
      rows.forEach((item: any, index: number) => {
        if (item.classList) {
          item.classList.remove("editing-row");
          item.classList.remove("pulse-row");
        }
      })
    }

    this.initEdit(index);
  }

  get getCount(): number {

    if (this.detalle.length > 0) {
      return this.detalle.length - 1;
    }

    return 0;
  }

  initEdit(index: number) {
    setTimeout(() => {
      this.selectedIndex = index;
      const sel: any = this.detalle[this.selectedIndex];
      this.selectedItem = sel;
      this.editing = true;
      const rows: any = document.getElementById("divCajaChica")!.querySelectorAll('.gui-content')[0].childNodes;
      if (rows) {
        rows.forEach((item: any, indexT: number) => {
          if (item.classList && indexT == index) {
            item.classList.add("editing-row");
            item.classList.add("pulse-row");
            item.scrollIntoView({ block: "center" });
          }
        })
      }
    }, 100);
  }

  get getIsNew(): boolean {
    return this.myForm.value.id == 0;
  }

  onNewCatalog(tipo: any, fecha: Date) {

    let elem: CajaChica = {
      id: 0,
      clave: 0,
      nombre: '',
      empresa: null,
      sucursal: null,
      fecha: fecha,
      tipo: tipo,
      usuarioAlta: null,
      usuarioModifica: null,
      detalle: [],
      idPoliza: 0,
      contabilizado: false,
      eliminado: false,
      baja: false,
    };

    this.myForm.reset(elem);
    this.resetSource(elem.detalle);
    const t: any = this.txtNombre;
    t.tagInput.nativeElement.focus();

  }

  get getSelectedItem(): CajaChicaDetalle | null {
    return this.selectedItem;
  }

  get getSubtotal(): number {
    let s = 0;
    this.detalle.forEach((i) => { if (i.proveedor) { s += i.subtotal; } })
    return s;
  }

  get getIva(): number {
    let s = 0;
    this.detalle.forEach((i) => { if (i.proveedor) { s += i.iva; } })
    return s;
  }

  get getTotal(): number {
    let s = 0;
    this.detalle.forEach((i) => { if (i.proveedor) { s += i.total; } })
    return s;
  }

  onChangeValue(value: Date) {
    const ent = this.myForm.value;
    let idTipo = ent.tipo["Id"] ? ent.tipo.Id : ent.tipo.id;
    if (idTipo > 0 && value) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fService.buscarCajaChica(idTipo, moment(value).format("DD/MM/YYYY")).subscribe((cajachica) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (cajachica) {
          this.myForm.reset(cajachica);
          this.resetSource(cajachica.detalle);
        } else {
          this.onNewCatalog(ent.tipo, value);
        }
      });
    }
  }
  enterDescripcion() {
    this.initEdit(0);
  }

  onCloseEdit(mov: CajaChicaDetalle): void {
    if (!mov) {
      this.editing = false;
      this.selectedIndex = -1;
      this.selectedItem = null;
      this.resetSource(this.detalle, false);
      setTimeout(() => {
        const t: any = this.txtNombre;
        t.tagInput.nativeElement.focus();
      }, 100);
    } else {
      this.detalle[this.selectedIndex] = mov;
      this.detalle.map((i, index) => { i.orden = index + 1; return i; })
      this.resetSource(this.detalle, true);
      this.editing = false;
      this.selectedItem = null
      setTimeout(() => {
        this.selectedIndex++;
        this.initEdit(this.selectedIndex);
      }, 100);

    }
  }

}
