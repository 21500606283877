import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { ModalService } from 'src/app/service/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-direccion-entrega-page',
  templateUrl: './direccion-entrega-page.component.html',
  styles: [
  ]
})
export class DireccionEntregaPageComponent implements OnInit {

  @Input()
  Direccion: Direccion | null = null;
  @Input()
  activar: boolean = false;

  private newEntityObj = {
    Direccion: [{} as Direccion, Validators.required],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  constructor(private fb: FormBuilder, private ms: ModalService) { }

  ngOnInit(): void {
    if (this.Direccion) {
      this.myForm.reset({ Direccion: { ...this.Direccion } })
    }
  }

  accept() {
    const dir: Direccion = this.myForm.value.Direccion;

    if (!dir.Pais) { Swal.fire({ text: 'Indique el País', icon: 'info', }); return; }
    if (!dir.Estado) { Swal.fire({ text: 'Indique el Estado', icon: 'info', }); return; }
    if (!dir.Calle) { Swal.fire({ text: 'Indique la Calle', icon: 'info', }); return; }
    if (!dir.CodigoPostal) { Swal.fire({ text: 'Indique el Código Postal', icon: 'info', }); return; }
    if (!dir.Localidad) { Swal.fire({ text: 'Indique la Localidad', icon: 'info', }); return; }
    if (!dir.Municipio) { Swal.fire({ text: 'Indique el Municipio', icon: 'info', }); return; }
    if (!dir.NumeroExterior) { Swal.fire({ text: 'Indique el Número Exterior', icon: 'info', }); return; }

    if (dir.CodigoPostal) {
      dir.CodigoPostal = String(dir.CodigoPostal);
    }
    this.ms.closeModal(dir);
  }

  closeModal() {
    this.ms.closeModal(null);
  }

}
