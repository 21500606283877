<div class="widget-input-container bg-light">
  <div class="widget-input-divider" *ngIf="isNewItem"></div>
  <bar-button [toolTip]="'Nuevo [ALT + N]'"  *ngIf="!isNewItem && activeButtons.new" (onClickButton)="clicBoton($event)" name="new" icono="fa-plus" />
  <div class="widget-input-divider" *ngIf="!isNewItem && activeButtons.new"></div>
  <bar-button [toolTip]="'Eliminar [ALT + X]'"  *ngIf="!isNewItem && activeButtons.delete" (onClickButton)="clicBoton($event)" name="delete" icono="fa-trash-can" />
  <div class="widget-input-divider" *ngIf="!isNewItem && activeButtons.return"></div>
  <bar-button [toolTip]="'Regresar [ALT + R]'" *ngIf="activeButtons.return"  (onClickButton)="clicBoton($event)" name="return" icono="fa-arrow-rotate-left" />
  <div class="widget-input-divider" *ngIf="activeButtons.return"></div>
  <bar-button [toolTip]="'Guardar [ALT + G]'"  *ngIf="!saving && activeButtons.save" (onClickButton)="clicBoton($event)" name="save" icono="fa-floppy-disk" />
  <div class="widget-input-divider" *ngIf="!saving && activeButtons.save"></div>
  <bar-button [toolTip]="'Primero [ALT + P]'"  *ngIf="!isNewItem  && activeButtons.first"  (onClickButton)="clicBoton($event)" name="first" icono="fa-angles-left" />
  <div class="widget-input-divider" *ngIf="!isNewItem  && activeButtons.first"></div>
  <bar-button [toolTip]="'Anterior [ALT + A]'"  *ngIf="!isNewItem  && activeButtons.left" (onClickButton)="clicBoton($event)" name="left" icono="fa-angle-left" />
  <div class="widget-input-divider" *ngIf="!isNewItem  && activeButtons.left"></div>
  <bar-button [toolTip]="'Siguiente [ALT + S]'"  *ngIf="!isNewItem  && activeButtons.right" (onClickButton)="clicBoton($event)" name="right" icono="fa-angle-right" />
  <div class="widget-input-divider" *ngIf="!isNewItem  && activeButtons.right"></div>
  <bar-button [toolTip]="'Último [ALT + U]'"  *ngIf="!isNewItem && activeButtons.last" (onClickButton)="clicBoton($event)" name="last" icono="fa-angles-right" />
  <div class="widget-input-divider" *ngIf="!isNewItem && activeButtons.last"></div>
  <bar-button [toolTip]="'Último [ALT + B]'"  *ngIf="!isNewItem && activeButtons.search" (onClickButton)="clicBoton($event)" name="search" icono="fab fa-sistrix" />
  <div class="widget-input-divider" *ngIf="!isNewItem && activeButtons.search"></div>
  <bar-button [toolTip]="'Imprimir [ALT + I]'" *ngIf="activeButtons.print" (onClickButton)="clicBoton($event)" name="print" icono="fa-print" />
  <div class="widget-input-divider" *ngIf="!isNewItem && activeButtons.search"></div>
  <bar-button [toolTip]="'Excel [ALT + E]'" *ngIf="activeButtons.export" (onClickButton)="clicBoton($event)" name="export" icono="fas fa-file-excel" />
  <div class="widget-input-divider" *ngIf="activeButtons.exit"></div>
  <bar-button [toolTip]="'Salir [ALT + W]'" *ngIf="activeButtons.exit" (onClickButton)="clicBoton($event)" name="exit" icono="fas fa-door-open" />
</div>
