<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="SerieReguardo"
  title="Series de Remisiones de Resguardos" icon="fa fa-object-ungroup"
  subTitle="Administra la configuración de Series de Remisiones de Resguardos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie para las Remisiones de Resguardos">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="3" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieReguardo" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="4" [required]="true"
            helpLine="Nombre de la Serie de Resguardos, sirve para identificarla" [widthTextColumns]="3"
            [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            helpLine="Serie a utilizar, 10 letras, ejemplo = REM" [tabIndex]="5" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxlength]="20" />

          <components-ui-text-box [label]="'Texto que imprime'" [required]="true" formControlName="TextoImprimir"
            [maxLength]="50" helpLine="Indique el texto que imprimirá en el encabezado superior derecho del formato."
            [tabIndex]="6" [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Copias'" formControlName="Copias" [maxLength]="100" [tabIndex]="7"
            helpLine="Copias a imprimir, se indican separados por una coma, ejemplo: Original, Copia, Almacen"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Formato'" formControlName="Formato" [maxLength]="100" [tabIndex]="8"
            helpLine="Nombre del formato en el que se va a imprimir" [widthTextColumns]="3" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
      <app-panel-base title="Folios PreImpresos">
        <div class="panel-content">
          <component-ui-check-box helpLine="Indica si al emitir la remisión, solicitará el número de folio pre impreso"
            [tabIndex]="9" [widthLabelColumns]="2" formControlName="UsaFoliosPreImpresos" [widthTextColumns]="7"
            [label]="'Usa Folio PreImpresos'" />

          <components-ui-number-box [enabled]="myForm.value.UsaFoliosPreImpresos" [helpLine]="'Último folio pre impreso que se usó'" [maxLength]="6"
            [label]="'Último Folio'" formControlName="UltimoFolioPreimpreso" [tabIndex]="10" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
        </div>
      </app-panel-base>
      <app-panel-base title="Inventario">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [label]="'Tipo de Movimiento'" formControlName="TipoMovimientoAlmacen"
            [tabIndex]="11" [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'TipoMovimientoAlmacen')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Almacén Salida'" formControlName="Almacen"
            [tabIndex]="12" [zeroMask]="2" entityName="Almacen" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Almacen')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Concepto Salida Almacén'"
            formControlName="ConceptoSalida" [tabIndex]="13" [zeroMask]="2" entityName="ConceptoAlmacen"
            [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'ConceptoSalida')" [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>
    </form>
  </div>

</app-container-base>
