<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-caravan" subTitle="Carta Porte Traslado">
  <div id="contentCP" class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <form [formGroup]="myForm" autocomplete="off">
      <div class="card border-0 mb-0" style="height: 20px;background-color: var(--bs-app-header-input-bg);">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2" style="text-align: left;">
              <div class="mb-0 d-md-flex fw-bold">
                <div class="ms-md-0 mt-md-0 mt-0 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Archivos <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a (click)="downloadFiles()" class="dropdown-item">Descargar XML y PDF</a>
                    <a class="dropdown-item">Envíar por correo</a>
                  </div>
                </div>
                <div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Acciones <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item">Copiar</a>
                    <!-- <a class="dropdown-item">Relaciones con otros CFDI</a> -->
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <app-panel-base title="Selección de Documento" style="margin-top:20px !important;">
        <div class="panel-content d-flex flex-column">
          <div class="d-flex">
            <component-ui-combo-box style="width:150px; margin-right: 5px;"
              [extras]="'TipoDeComprobante.Clave as TipoComp, Clasificacion.Clave as Clasificacion'"
              formControlName="Documento" [enabled]="!blockCombos" [topLabel]="true" label="Documento"
              [inputProperty]="'Nombre'" [zeroMask]="3" [setItemsList]="getItemsComps" [tabIndex]="1"
              [widthLabelColumns]="12" [widthTextColumns]="12" (onSelectedItem)="onSelectedItem($event,'Documento')"
              [widthRightColumns]="0" #cboComp />

            <component-ui-combo-box formControlName="Serie" style="width:150px; margin-right: 5px;" [topLabel]="true"
              label='Serie' [enabled]="!blockCombos" [inputProperty]="'Serie'" [setItemsList]="getItemsSerie"
              [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0"
              (onSelectedItem)="onSelectedItem($event,'Serie')" #cboSerie />

            <components-ui-number-box formControlName="Folio" style="width:150px;margin-right: 5px;" [topLabel]="true"
              (blur)="blurFolio($event)" label='Folio' [tabIndex]="3" [widthLabelColumns]="12"
              [widthTextColumns]="12" />

            <components-ui-date-box formControlName="FechaEmision" style="width:150px;" label="Emisión" [tabIndex]="4"
              [widthLabelColumns]="6" [topLabel]="true" [widthTextColumns]="12" />
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales del Complemento Carta Porte">
        <div class="panel-content">

          <div *ngIf="soloNacional" class="card-header h6 mb-1 bg-none p-2">
            <i class="fa fa-info fa-lg fa-fw me-1"></i>
            La información para capturar datos de salida/entrada del país e ISTMO se han ocultado.
          </div>

          <component-ui-check-box *ngIf="!soloNacional" [label]="'Transporte internacional'" [tabIndex]="5"
            [widthTextColumns]="2" [widthLabelColumns]="2" />

          <component-ui-combo-box *ngIf="!soloNacional" [widthList]="800" [label]="'Regimen Aduanero'" [tabIndex]="6"
            entityName="c_RegimenAduanero" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="onSelectedItem($event,'RegimenAduanero')" [listProperty]="'ClaveNombre'" />

          <component-ui-check-box *ngIf="!soloNacional" [label]="'Entran o salen del país'" [tabIndex]="7"
            [widthTextColumns]="2" [widthLabelColumns]="2" />

          <component-ui-combo-box *ngIf="!soloNacional" [widthList]="400" [label]="'País origen/destino'" [tabIndex]="8"
            entityName="c_Pais" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="onSelectedItem($event,'PaisOrigenDestino')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box *ngIf="!soloNacional" [widthList]="250" [label]="'Vía entrada/salida'" [tabIndex]="9"
            entityName="c_CveTransporte" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="onSelectedItem($event,'ViaTransporte')" [listProperty]="'ClaveNombre'" />

          <components-ui-number-box [enabled]="false" formControlName="TotalDistRec" [maxLength]="9"
            [label]="'Total distancia (kmts)'" [tabIndex]="10" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <div *ngIf="!soloNacional" class="card-header h6 mb-1 bg-none p-2">
            <i class="fa fa-registered fa-lg fa-fw me-1"></i>
            Registro ISTMO (Polos de Desarrollo para el Bienestar del istmo de Tehuantepec)
          </div>
          <component-ui-check-box *ngIf="!soloNacional" [label]="'Registro ISTMO'" [tabIndex]="10"
            [widthTextColumns]="2" [widthLabelColumns]="2" />

          <component-ui-combo-box *ngIf="!soloNacional" [widthList]="250" [label]="'Polo origen'" [tabIndex]="11"
            entityName="c_RegistroISTMO" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="onSelectedItem($event,'ViaTransporte')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box *ngIf="!soloNacional" [widthList]="250" [label]="'Polo destino'" [tabIndex]="12"
            entityName="c_RegistroISTMO" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="onSelectedItem($event,'ViaTransporte')" [listProperty]="'ClaveNombre'" />

        </div>
      </app-panel-base>
      <app-panel-base title="Transporte" id="divTransportes">
        <div class="panel-content">
          <div class="mb-3" *ngIf="myForm.value.Id ==0">
            <component-ui-combo-box [widthList]="500" [filterQuery]="'UtilizarParaEntregas = true'"
              [entityName]="'Unidad'" label="Seleccione el vehículo" [widthTextColumns]="2" [widthLabelColumns]="2"
              [inputProperty]="'Nombre'" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event)"
              [listProperty]="'ClaveNombre'" #cboVehiculo/>
          </div>
          <div class="card border-0 mb-4" *ngIf="myForm.value.Mercancias?.AutoTransporte">
            <div class="card-body fw-bold">
              <h4>Datos del vehículo</h4>
              <br>Placas: {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.PlacaVM}}<br>
              Modelo: {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.AnioModeloVM}}<br>
              Peso bruto: {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.PesoBrutoVehicular |
              number:'1.2'}}<br>
              Config: {{myForm.value.Mercancias?.AutoTransporte.IdentificacionVehicular.ConfigVehicular.Clave}}<br>
              Permiso SCT: {{myForm.value.Mercancias?.AutoTransporte.PermSCT}}<br>
              Num Permiso: {{myForm.value.Mercancias?.AutoTransporte.NumPermisoSCT}}<br>
              <hr>
              <h4>Datos de la aseguradora</h4>
              <br>Aseguradora: {{myForm.value.Mercancias?.AutoTransporte.Seguros[0].AseguraRespCivil}}<br>
              Modelo: {{myForm.value.Mercancias?.AutoTransporte.Seguros[0].PolizaRespCivil}}<br>
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Ubicaciones" id="divUbicacion">
        <div class="panel-content">
          <div class="mb-3" *ngIf="myForm.value.Id ==0">
            <div class="ms-auto"><a (click)="AgregarUbicacion()" class="btn btn-primary px-4"><i
                  class="fa fa-map-pin fa-lg me-2 ms-n2 text-success-900"></i>Agregar ubicación</a></div>
          </div>
          <gui-grid [source]="listaUbicaciones" [autoResizeWidth]="false" [width]="800" [virtualScroll]="true"
            [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">

            <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                <i (click)="deleteRowUbicacion(index)" *ngIf="item.TipoUbicacion" class="fa fa-rectangle-xmark"
                  style="color:red;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                <i (click)="editarUbicacion(index)" *ngIf="item.TipoUbicacion" class="fa fa-pencil"
                  style="color:orange;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [align]="GuiColumnAlign.CENTER" header="Tipo" [width]="100" [cellEditing]="false">
              <ng-template let-item="item">
                {{ item.TipoUbicacion }}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [field]="'IDUbicacion'" header="Id" [width]="150" [cellEditing]="false" />
            <gui-grid-column [field]="'FechaHoraSalidaLlegada'" header="Fecha" [width]="200" [cellEditing]="false">
              <ng-template let-item="item">
                {{ item.TipoUbicacion ? getFecha(item) :'' }}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Distancia" [width]="100" [cellEditing]="false">
              <ng-template let-item="item">
                {{ item.DistanciaRecorrida > 0 ? (item.DistanciaRecorrida | number:'1.2-6') :'' }}
              </ng-template>
            </gui-grid-column>
          </gui-grid>
        </div>
      </app-panel-base>
      <app-panel-base title="Mercancías">
        <div class="panel-content" id="divMercancias">
          <div class="mb-3" *ngIf="myForm.value.Id ==0">
            <div class="ms-auto"><a (click)="AgregarMercancia()" class="btn btn-primary px-4"><i
                  class="fa fa-truck-ramp-box fa-lg me-2 ms-n2 text-success-900"></i>Agregar mercancía</a></div>
          </div>
          <div class="mb-1 fs-12px">
            <div class="text-gray-600 flex-1">No total de mercancías: {{getNumeroMercancias | number}}</div>
            <div class="text-gray-600 flex-1">Peso bruto total: {{getPesoTotal | number:'1.2-6'}}</div>
          </div>
          <gui-grid [source]="listaMercancias" [autoResizeWidth]="false" [width]="800" [virtualScroll]="true"
            [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">

            <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                <i (click)="deleteRowMercancia(index)" *ngIf="item.BienesTransp" class="fa fa-rectangle-xmark"
                  style="color:red;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                <i (click)="editarMercancia(index)" *ngIf="item.BienesTransp" class="fa fa-pencil"
                  style="color:orange;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [field]="'BienesTransp'" header="BienesTransp" [width]="100" [cellEditing]="false" />
            <gui-grid-column [field]="'Descripcion'" header="Descripción" [width]="150" [cellEditing]="false" />
            <gui-grid-column [field]="'Cantidad'" header="Cantidad" [width]="100" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                {{ item.BienesTransp ? (item.Cantidad | number:'1.2-6') : "" }}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [field]="'ClaveUnidad'" header="Unidad" [width]="100" [cellEditing]="false" />
            <gui-grid-column [field]="'PesoEnKG'" header="Peso KG" [width]="100" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                {{ item.BienesTransp ? (item.PesoEnKG | number:'1.2-6') : "" }}
              </ng-template></gui-grid-column>
            <gui-grid-column [field]="'MaterialPeligroso'" header="Peligroso" [width]="100" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                {{ item.BienesTransp ? (item.MaterialPeligroso ? "Si":"No") : "" }}
              </ng-template>
            </gui-grid-column>
          </gui-grid>
        </div>
      </app-panel-base>
      <app-panel-base title="Figuras de transporte" id="divFiguras">
        <div class="panel-content">
          <div class="mb-3" *ngIf="myForm.value.Id ==0">
            <div class="ms-auto"><a (click)="AgregarFigura()" class="btn btn-primary px-4"><i
                  class="fa fa-user-plus fa-lg me-2 ms-n2 text-success-900"></i>Agregar figura</a></div>
          </div>
          <gui-grid [source]="listaFiguras" [autoResizeWidth]="false" [width]="800" [virtualScroll]="true"
            [rowHeight]="20" [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">

            <gui-grid-column [align]="GuiColumnAlign.CENTER" header="" [width]="50" [cellEditing]="false">
              <ng-template let-item="item" let-index="index">
                <i (click)="deleteRowFigura(index)" *ngIf="item.RFCFigura" class="fa fa-rectangle-xmark"
                  style="color:red;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column [field]="'RFCFigura'" header="RFC" [width]="100" [cellEditing]="false" />
            <gui-grid-column [field]="'NumLicencia'" header="Licencia" [width]="150" [cellEditing]="false" />
            <gui-grid-column [field]="'NombreFigura'" header="Nombre" [width]="250" [cellEditing]="false" />
          </gui-grid>
        </div>
      </app-panel-base>

    </form>
    <div *ngIf="editandoUbicacion">
      <app-ctrl-edicion-ubicacion [esOrigen]="listaUbicaciones.length==1" [tabIndex]="50" [item]="getSelectedUbicacion"
        (onCloseEdition)="onCloseEditUbicacion($event)" />
    </div>
    <div *ngIf="editandoFigura">
      <app-ctrl-edicion-tipo-figura [tabIndex]="100" [item]="getSelectedTipoFigura"
        (onCloseEdition)="onCloseEditFigura($event)" />
    </div>
    <div *ngIf="editandoMercancia">
      <app-ctrl-edicion-mercancia [tabIndex]="150" [item]="getSelectedMercancia"
        (onCloseEdition)="onCloseEditMercancia($event)" />
    </div>
  </div>
</app-container-base>
