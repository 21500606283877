import { Component, Input, OnInit, inject } from '@angular/core';
import { CompraService } from '../../services/compras.service';
import { ModalService } from 'src/app/service/modal.service';
import { PendienteEntregarCompra } from '../../interfaces/compra.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-pendiente-entregar-compra-page',
  templateUrl: './pendiente-entregar-compra-page.component.html',
  styles: [
  ]
})
export class PendienteEntregarCompraPageComponent implements OnInit {
  private eventsService = inject(EventsService);
  private reportsService = inject(ReportsService);
  @Input()
  public idCompra: number = 0;

  lista: PendienteEntregarCompra[] = [];

  constructor(private compraService: CompraService, private mService: ModalService) {
  }

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.compraService.getPendienteEntregar(this.idCompra).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.lista = lista;
    })
  }

  get getPorcenjate(): number {
    let ordenado: number = 0;
    let entregado: number = 0;
    this.lista.forEach((i) => {
      ordenado += i.ordenado;
      entregado += i.entregado;
    });

    if (entregado > 0) {
      return (entregado / ordenado) * 100;
    }
    return 0;
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  imprimirHistorico() {
    this.reportsService.printReportWithBody(this.idCompra,'/Compras/HistoricoEntregasProveedor',"Histórico de Entregas de Proveedor");
  }
}
