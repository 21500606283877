import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ComboBoxEntity } from './../../../component-ui/interfaces/combo-text.interface';
import { ContabilidadService } from '../../services/contabilidad.service';
import { CuentasPorPagarService } from 'src/app/cuentas-por-pagar/services/cxp.service';
import { GuiColumnAlign, GuiSearching } from '@generic-ui/ngx-grid';
import { ItemEstadoCuentaContable, ItemEstadoCuentaContableMovimientos } from '../../interfaces/polizaautomatica.interface';
import { ModalService } from 'src/app/service/modal.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { EventsService } from 'src/app/service/events.service';



@Component({
  selector: 'estado-de-cuenta-contable-page',
  templateUrl: './estado-de-cuenta-contable-page.component.html',
  styleUrls: ['./estado-de-cuenta-contable-page.component.css']
})
export class EstadoDeCuentaContablePageComponent implements OnInit {


  selectedMonth = -1;

  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  @ViewChild('crtlCuenta')
  public ctrlCuenta!: ElementRef;

  @ViewChild('gridEdoCta')
  public gridEdoCta!: ElementRef<HTMLElement>;

  @ViewChild('reporteEdoCuenta')
  public reporteEdoCuenta!: ElementRef<HTMLElement>;


  private ms = inject(ModalService);
  private contabilidadService = inject(ContabilidadService);
  private utilsService = inject(UtilsService);
  private fb = inject(FormBuilder);
  private eventsService = inject(EventsService);

  listaAnios: any[] = [];
  anioSeleccionado?: ComboBoxEntity;
  empresa?: ComboBoxEntity;
  cuenta: string = '';

  searching: boolean = false;

  searchingMes: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  public GuiColumnAlign = GuiColumnAlign;


  proveedorSeleccionado: any = { Id: 0 };
  nombreEmpresa: string = "";
  nombreProveedor: string = "";
  usuarioLoggeado!: UserLogged;
  listaMeses: ItemEstadoCuentaContable[] = []
  listaMovimientos: ItemEstadoCuentaContableMovimientos[] = []
  searchConfigurationProveedor!: SearchConfiguration;

  totalCargos: number = 0;
  totalAbonos: number = 0;
  totalCargosMes: number = 0;
  totalAbonosMes: number = 0;

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }



  public myForm: FormGroup = this.fb.group({
    empresa: [{} as Empresa],
  });




  constructor() {
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'empresa') {
      this.empresa = entity;
    } else if (field == 'anio') {
      this.anioSeleccionado = entity;
    }
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    return this.anioSeleccionado!;
  }

  get selectEmpresaManualItem(): any {
    return this.empresa;
  }

  onBlurLevelText(value: any) {
    this.cuenta = value
  }

  buscar() {
    if (this.empresa!.Id == 0) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar la empresa.' });
      return;
    }

    if (this.cuenta.length == 0) {
      Swal.fire({ icon: 'info', text: 'Debe indicar una cuenta.' });
      return;
    }
    this.getEstadoDeCuenta(this.empresa!.Id, this.cuenta, this.anioSeleccionado!.Id);
  }



  getEstadoDeCuenta(idEmpresa: number, cuenta: string, anio: number) {
    this.searching = true;
    this.totalCargos =0;
    this.totalAbonos =0;
    this.contabilidadService.getEstadoDeCuenta(idEmpresa, cuenta, this.anioSeleccionado!.Id).subscribe(result => {
      this.listaMeses = result;
      this.searching = false;
      this.listaMeses.forEach(item=>{
        this.totalCargos += item.cargos;
        this.totalAbonos += item.abonos;
      })
    })
  }


  get getFilter(): any {
    if (this.searchConfigurationProveedor) {
      return this.searchConfigurationProveedor.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationProveedor) {
      return this.searchConfigurationProveedor.columns;
    }
    return [];
  }

  ngOnInit(): void {




    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.usuarioLoggeado = this.utilsService.getUserLogged();
    this.nombreEmpresa = this.usuarioLoggeado.empresa!.nombre;
    this.searchConfigurationProveedor = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.usuarioLoggeado.empresa!.numero}`);
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('empresa')?.setValue(this.utilsService.getUserLoggedEmpresa())
        this.empresa = this.utilsService.getUserLoggedEmpresa();
      }
    })
  }

  // focus(field: string) {
  //   setTimeout(() => {
  //     const txt: any = this.txtNombreReporte;
  //     txt.tagInput.nativeElement.focus()
  //   }, 100);
  // }


  clickBoton(event: string) {
    if (this.empresa!.Id == 0) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar la empresa.' });
      return;
    }


    if (this.cuenta.length == 0) {
      Swal.fire({ icon: 'info', text: 'Debe indicar una cuenta.' });
      return;
    }






    const b: any = this.reporteEdoCuenta;
    this.ms.openModal(b, (e: any) => {
      if (e != null && e != undefined) {

      }
    }, 'md')
  }

  changeMonth(value: number) {
    this.selectedMonth = value;
    this.searchingMes[value] = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.totalCargosMes=0;
    this.totalAbonosMes=0;
    this.contabilidadService.getEstadoDeCuentaMovimientosPorMes(this.empresa!.Id, this.cuenta, this.selectedMonth, this.selectedMonth, this.anioSeleccionado!.Id).subscribe(result => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.listaMovimientos = result;
      this.searchingMes[value] = false;
      this.listaMovimientos.forEach(item=>{
        this.totalCargosMes+=item.debe;
        this.totalAbonosMes+=item.haber;
      })
    })
  }

  getIsMonthSelected(index: number) {
    return this.selectedMonth == index ? 'nav-link active' : 'nav-link ';
  }

  getisSearching(index: number) {
    return this.searchingMes[index];
  }

}
