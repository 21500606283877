import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Result } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-alta-terminales-bancarias-page',
  templateUrl: './alta-terminales-bancarias-page.component.html',
  styles: [
  ]
})
export class AltaTerminalesBancariasPageComponent implements OnInit {

  @Input()
  public item: any = null;
  @Input()
  public idSucursal: number = 0;

  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Banco: [null],
    CuentaBancaria: [null],
    NumeroTerminal: [''],
    Baja: [false],
  })

  constructor(private ms: ModalService, private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.item) {
        this.myForm.reset({
          Id: 0,
          Clave: 0,
          Nombre: '',
          Banco: null,
          CuentaBancaria: null,
          NumeroTerminal: '',
          Baja: false
        })
      } else {
        this.myForm.reset({ ...this.item })
      }
    }, 150);
  }

  onSelectedItem(entity: any, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  accept() {
    const ent = this.myForm.value;
    if (!ent.Banco) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar un Banco.`, cancelButton: false });
      return;
    }
    if (!ent.CuentaBancaria) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Cuenta Bancaria.`, cancelButton: false });
      return;
    }
    if (!ent.NumeroTerminal) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Número de la Terminal.`, cancelButton: false });
      return;
    }
    ent.Sucursal = { Id: this.idSucursal }
    let json = JSON.stringify(ent);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.containerService.saveEntity("TerminalBancaria", json).subscribe((result: Result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.ms.closeModal(true);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });

  }

  closeModal() {
    this.ms.closeModal(null);
  }


}
