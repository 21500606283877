import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType, GuiRowDetail } from '@generic-ui/ngx-grid';
import { UserLogged } from 'src/app/auth/interfaces';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Almacen, ConceptoAlmacen, TipoMovimientoAlmacen } from 'src/app/home/interfaces/almacen.interface';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { ConfiguracionCargaEntrega, ConfiguracionCargaEntregaSucursalesFolios } from '../../interfaces/cargaentrega.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { CargaentregaService } from '../../services/cargaentrega.service';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-config-carga-entrega-page',
  templateUrl: './config-carga-entrega-page.component.html',
  styles: [
  ]
})



export class ConfigCargaEntregaPageComponent {

  // variables de referencia e elementos HTML
  @ViewChild('cboConceptoCancelacion')
  public cboConceptoCancelacion!: ElementRef<ComboBoxComponent>;


  info: UserLogged = {} as UserLogged;
  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }
  //* injects
  private eventsService = inject(EventsService);
  private cargaEntregaService = inject(CargaentregaService);
  public appSettings = inject(AppSettings);
  private configService = inject(ConfiguracionService)
  private utilsService = inject(UtilsService)
  private http = inject(HttpClient);



  //* variables para el grid
  // nvariables para el grid
  sourceGrid: Array<ConfiguracionCargaEntregaSucursalesFolios> = [];
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  loading: boolean = false;
  public GuiColumnAlign = GuiColumnAlign;
  public GuiCellView = GuiCellView;
  public GuiDataType = GuiDataType;

  sourceSucursales: any[] = [];
  cords: any = null;
  selectedIndex: number = -1;
  idUnico: string = '';



  esEscapeSucursal: boolean = false;
  esEnterSucursal: boolean = false;
  enviarFocoSucursal: boolean = false;
  enviarFocoFolio: boolean = false;
  esenterFolio: boolean = false;
  esEscapeFolio: boolean = false;
  busquedaAbierta: boolean = false;
  enviarOtroRenglon: boolean = false;
  busquedaSucursalAbierta: boolean = false;

  // Ambiente desarrollo o produccion
  private readonly baseUrl: string = environment.baseUrlApi;





  //* variables del flujo de la pantalla



  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    ConceptoSalida: [{} as ConceptoAlmacen],
    ConceptoCancelacion: [{} as ConceptoAlmacen],
    TipoMovimientoSalida: [{} as TipoMovimientoAlmacen],
    AlmacenSalida: [{} as Almacen],
    SucursalesFolios: [[] as ConfiguracionCargaEntregaSucursalesFolios[]],
    CopiasFormatoEntrega: [''],
    ConceptoEntradaPorTraspaso: [{} as ConceptoAlmacen],
    ConceptoEntradaPorRemision: [{} as ConceptoAlmacen],
    Eliminado: [false],
    Baja: [false],
  })

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private utileService: UtilsService) {
  }

  selectedCombo(ent: any, type: string) {
    this.myForm.controls[type].setValue(ent);
  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "save": this.save(); break;
      //case "print": this.print(); break;
    }
  }

  //*Metodos control de flujo del Grid

  //* columna 1 sucursal col1

  escapeSucursal(e: any) {
    e.preventDefault();
    this.esEscapeSucursal = true;
    let item: ConfiguracionCargaEntregaSucursalesFolios = this.sourceGrid[this.indexEditing];
    e.target.value = item.Sucursal?.Clave ? item.Sucursal?.Clave : "";
    if (this.indexEditing > 0) {
      this.indexEditing = this.indexEditing - 1;
      this.initEditor(this.indexEditing, 1);
    } else {
      const txt: any = this.cboConceptoCancelacion;
      txt.tagInput.nativeElement.focus()
    }
  }

  focusSucursal(index: number, e: any, item: ConfiguracionCargaEntregaSucursalesFolios) {
    this.setIndexEdit(index, e, item);
    if (e.target.value == "") {
      this.buscarSucursal(e);
      e.preventDefault();
      return;
    }
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  enterSucursal(e: any) {
    this.esEnterSucursal = true;
    this.enviarFocoFolio = true;
    let value = '';
    if (this.sourceSucursales.length > 0) {
      value = this.sourceSucursales[this.selectedIndex].Clave;
    } else {
      value = e.target.value.trim();
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }


  blurSucursal(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    if (!this.esEscapeSucursal) {
      let found = false;
      if (e.target.value.length > 0) {
        this.sourceGrid.forEach((element, idx) => {
          if (element.Sucursal?.Clave == parseInt(e.target.value) && index != idx) {
            this.eventsService.publish('home:showAlert', {
              textAccept: "Aceptar",
              cancelButton: false,
              message: 'No puede repetir la Sucursal en la asignación de folios, por favor verifique.',
              onConfirm: (data: AlertResponse) => {
                let item: ConfiguracionCargaEntregaSucursalesFolios = this.sourceGrid[this.indexEditing];
                e.target.value = item.Sucursal?.Clave ? item.Sucursal?.Clave : "";
                this.sourceGrid = [...this.sourceGrid];
                this.initEditor(this.indexEditing, 1);
              }
            });
            found = true;
          }
        });
      }

      if (found) {
        return;
      }
    }


    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeSucursal) {
        this.esEnterSucursal = false;
        this.esEscapeSucursal = false;
        return;
      }
      let item = { ...this.sourceGrid[index] };
      if (item.Sucursal) {
        if (!e.target.value) {
          e.target.value = item.Sucursal.Clave;
          this.enviarFocoSucursal = true;
          this.sendFocus();
          return;
        }

        if (item.Sucursal.Clave != parseInt(e.target.value)) {
          this.searchSucursal(e.target.value);
        } else {
          if (this.enviarFocoFolio) {
            this.sendFocus();
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchSucursal(e.target.value);
        }else{
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterSucursal = false;
      this.esEscapeSucursal = false;
    }, 100);
  }

  buscarSucursal(e: any) {
    // if (e.target.value == "" || !isNaN(e.target.value)) {
    //   this.cords = null;
    //   this.sourceSucursal = [];
    //   return;
    // }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }

    if (e.keyCode == "40") {
      e.preventDefault();
      if (this.selectedIndex < this.sourceSucursales.length - 1) {
        this.selectedIndex = this.selectedIndex + 1;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
      }
    }
    if (row) {
      row.scrollIntoView({ block: "center" });
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 147;

    if (this.appSettings.appSidebarMinified) {
      cords.left = cords.left - 70;
    } else {
      cords.left = cords.left - 290;
    }
    this.cords = cords;
    let valorBuscar: string = e.target.value;

    this.configService.busquedaSucursales(valorBuscar).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceSucursales = lista;
      if (this.sourceSucursales.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }


  searchSucursal(value: string) {  //*revisar

    // if (!value) {
    //   if (this.myForm.value.Total > 0 && this.navigateColumns) {
    //     this.Guardar();
    //   }
    //   return;
    // };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }

    let item = { ...this.sourceGrid[this.indexEditing] };
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.searchSucursalDetalle(parseFloat(value)).subscribe(sucursal => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (sucursal) {
        let item = this.sourceGrid[this.indexEditing];
        item.Sucursal = sucursal;
        item.FolioSalida = 0;
        this.sourceGridReset(this.sourceGrid);
        setTimeout(() => {
          this.initEditor(this.indexEditing, 3);
        }, 50);
        this.navigateColumns = true;
      } else {
        // this.sourceGrid = [...this.sourceGrid];
        //     if (e) {
        //       e.target.value = item.Clave;
        //     }
        //     this.initEditor(this.indexEditing, 1);
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: 'No se encontró la Sucursal indicada.',
          onConfirm: (data: AlertResponse) => {
            this.sourceGrid = [...this.sourceGrid];
            this.initEditor(this.indexEditing, 1);
            // this.sourceGrid = [...this.sourceGrid];
            // if (e) {
            //   e.target.value = item.Clave;
            // }
            // this.initEditor(this.indexEditing, 1);
          }
        });
      }
    });
  }

  searchSucursalDetalle(value: number): Observable<any> { //*revisar
    const params = new HttpParams().set("entidad", "Sucursal").set("clave", value);
    return this.http.get<Sucursal>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params });
  }

  // * columna 2 folio col2

  escapeFolio(e: any) {
    let item: ConfiguracionCargaEntregaSucursalesFolios = this.sourceGrid[this.indexEditing];
    e.target.value = item.FolioSalida ? item.FolioSalida : 0;
    this.initEditor(this.indexEditing, 1);
  }

  enterFolio(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }


  // blurPrecio22(e: any) {
  //   e.target.classList.remove("focus-editor-grid");
  //   const precio = parseFloat(e.target.value);
  //   let item = { ...this.sourceGrid[this.indexEditing] };
  //   this.sourceGrid[this.indexEditing] = { ...item };
  //   this.sourceGrid = [...this.sourceGrid];
  // }


  blurFolio(e: any) {

    e.target.classList.remove("focus-editor-grid");
    const meta = parseFloat(e.target.value);
    let item: ConfiguracionCargaEntregaSucursalesFolios = { ...this.sourceGrid[this.indexEditing] };
    if (!item.Sucursal) { return; }
    if (meta != item.FolioSalida) {
      this.sourceGrid[this.indexEditing].FolioSalida = meta;
      this.sourceGrid = [...this.sourceGrid];
      this.indexEditing = this.indexEditing + 1;
      this.initEditor(this.indexEditing, 1);
      this.enviarOtroRenglon = false;
    } else {
      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }
  }

  navegarSucursal(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceSucursales.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        e.scrollIntoView({ block: "center" });
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoFolio) {
        this.enviarFocoFolio = false;
        this.initEditor(this.indexEditing, 3);
      }

      if (this.enviarFocoSucursal) {
        this.enviarFocoSucursal = false;
        this.initEditor(this.indexEditing, 1);
      }


      if (this.enviarOtroRenglon) {
        this.enviarOtroRenglon = false;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }, 50);
  }


  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }


  deleteRow(indx: number) {
    const idEliminar = this.sourceGrid[indx].Id;
    this.eventsService.publish('home:showAlert', {
      message: `¿Desea eliminar el Presupuesto la Sucursal? <br> ${idEliminar > 0 ? "La información no se podrá recuperar." : ""}`,
      textAccept: "Si, eliminar",
      textCancel: "Cancelar",
      cancelButton: true,
      onConfirm: (data: AlertResponse) => {
        // if (data.isAccept) {
        //   if (idEliminar > 0) {
        //     this.eventsService.publish('home:isLoading', { isLoading: true });
        //     this.presupuestoService.eliminarPresupuestoDetalle(idEliminar, TipoPresupuesto.sucursales).subscribe((result) => {
        //       this.eventsService.publish('home:isLoading', { isLoading: false });
        //       this.sourceGrid.splice(indx, 1);
        //       this.sourceGrid = [...this.sourceGrid];
        //       this.calcTotales();
        //       let ent: PresupuestoVentaSucursal = this.myForm.value;
        //       ent.Detalle?.splice(indx, 1);
        //       this.myForm.reset(ent);
        //       this.cd.detectChanges();
        //     })
        //   } else {
        //     this.sourceGrid.splice(indx, 1);
        //     this.sourceGrid = [...this.sourceGrid];
        //     this.calcTotales();
        //     let ent: PresupuestoVentaSucursal = this.myForm.value;
        //     ent.Detalle?.splice(indx, 1);
        //     this.myForm.reset(ent);
        //     this.cd.detectChanges();
        //   }
        // }
      }
    });


  }



  up(e: any, celda: number) {
    if (this.sourceSucursales.length > 0) { return; }
    e.preventDefault();
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeSucursal = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeSucursal = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  getLastItem(): number {
    return this.sourceGrid.filter(P => P.Sucursal && P.FolioSalida > 0).length;
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeSucursal = true;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  setIndexEdit(index: number, e: any, item: ConfiguracionCargaEntregaSucursalesFolios) {
    /*Esto es lo nuevo*/
    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }
  sourceGridReset(detalle: any = []) {
    if (!detalle) {
      this.sourceGrid = [];
    } else {
      this.sourceGrid = detalle;
    }

    this.sourceGrid.map(p => {
      if (p.Sucursal) {
        p.Nombre = p.Sucursal?.Nombre!;
        p.Clave = p.Sucursal?.Clave!.toString();
        p.Sucursal = { Id: p.Sucursal!.Id, Clave: p.Sucursal!.Clave, Nombre: p.Sucursal!.Nombre };
        p.FolioSalida = p.FolioSalida * 1;
      }
    });
    let totalRegs = this.sourceGrid.length;
     if (this.sourceGrid.length < 30) {
       totalRegs = 30;
     }
    for (let index = 0; index < totalRegs; index++) {
      this.sourceGrid = [...this.sourceGrid, this.estructuraVacia()];

    }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  estructuraVacia() {
    return {
      Id: 0,
      Sucursal: null,
      Nombre: '',
      Clave: '',
      FolioSalida: 0,
      Empresa: null,
      Eliminado: false,
      Baja: false
    }
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  setEntity(config: ConfiguracionCargaEntrega, enviarFoco: boolean = false) {
    if (!config) {
      //this.nuevo();
    } else {
      let ent = this.myForm.value;
      ent = { ...config };
      this.sourceGridReset(ent.SucursalesFolios);
      this.myForm.reset(ent);
    }
  }

  sourceGridEdited(e: any) {
    if (this.columnEditing == 2) {
      if (e.after.folioSalida != e.before.folioSalida) {
        let item = this.sourceGrid[this.indexEditing];
        item.FolioSalida = e.after.folioSalida
        this.sourceGridReset(this.sourceGrid);
      }
    }
  }


  save() {
    let ent = this.myForm.value;
    ent.SucursalesFolios = this.sourceGrid.filter(P => P.Sucursal?.Id! > 0);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    ent.Empresa = { Id: this.info.empresa?.numero };
    this.containerService.saveEntity("ConfiguracionCargaEntrega", JSON.stringify(ent)).subscribe((result) => {
      if (result.success) {
        this.containerService.getLastEntity("ConfiguracionCargaEntrega", `Empresa.Id = ${this.info.empresa?.numero}`).subscribe((ent:ConfiguracionCargaEntrega) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 })
          if (ent) {
            this.myForm.reset(ent);
            this.sourceGridReset(ent.SucursalesFolios);
          }
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        Swal.fire({ icon: 'error', text: result.message }).then(() => { });
      }
    })
  }


  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.info = this.utileService.getUserLogged();
    this.cargaEntregaService.getConfiguracion().subscribe((ent) => {
      if (!ent) {
        this.containerService.getEmptyEntity("ConfiguracionCargaEntrega").subscribe((newE) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.setEntity(ent);
          //this.myForm.reset(newE);
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.setEntity(ent);
        //this.myForm.reset(ent);
      }
    });
  }
}
