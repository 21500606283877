<ng-template #tipRFC>
  <div class="info-data"> {{(usuarioLoggeado.empresa?.rfc| uppercase)}}</div>
</ng-template>
<ng-template #tipEmpresa>
  <div class="info-data"> {{usuarioLoggeado.empresa?.nombre || 'Seleccionar Empresa'}}</div>
</ng-template>
<ng-template #tipSucursal>
  <div class="info-data"> {{usuarioLoggeado.sucursal?.nombre || 'Seleccionar Sucursal'}}</div>
</ng-template>
<div class="menu sticky-suc-emp">
  <!-- <div class="menu-item bg-black" style="height: 1px;"></div> -->
  <div class="menu-item bg-light" [ngbTooltip]="(appSettings.appSidebarMinified) ? tipRFC: ''"
    tooltipClass="tooltip-sel-empresa" [openDelay]="50" [closeDelay]="50" placement="end" container="body">
    <a class="menu-link text-dark  hover-emp" (click)="abrirSelectSucursal()">
      <div class="menu-icon-img"><img class="rfc-card" src="../../../assets/img/logo/rfc-card.png" alt=""></div>
      <div class="menu-text">{{(usuarioLoggeado.empresa?.rfc| uppercase)}}</div>
    </a>
  </div>
  <!-- <div class="menu-item bg-black" style="height: 1px;"></div> -->
  <div class="menu-item bg-light hover-emp" [ngbTooltip]="(appSettings.appSidebarMinified) ? tipEmpresa : ''"
    [openDelay]="50" [closeDelay]="50" placement="end" container="body" tooltipClass="tooltip-sel-empresa">
    <a class="menu-link text-dark" (click)="abrirSelectSucursal()">
      <div class="menu-icon"><i class="fas fa-city fa-lg"></i></div>
      <div class="menu-text">{{(usuarioLoggeado.empresa?.nombre || 'Seleccionar Empresa') | ellipsis: 30 }}</div>
    </a>
  </div>
  <!-- <div class="menu-item bg-black" style="height: 1px;"></div> -->
  <div class="menu-item bg-light hover-emp" [ngbTooltip]="(appSettings.appSidebarMinified) ? tipSucursal: ''"
    [openDelay]="50" [closeDelay]="50" placement="end" container="body" tooltipClass="tooltip-sel-empresa">
    <a class="menu-link text-dark" (click)="abrirSelectSucursal()">
      <div class="menu-icon"><i class="fas fa-building fa-lg"></i></div>
      <div class="menu-text">{{(usuarioLoggeado.sucursal?.nombre || 'Seleccionar Sucursal')| ellipsis: 30}}</div>
    </a>
  </div>
  <!-- <div class="menu-item bg-black" style="height: 1px;"></div> -->
</div>
<ng-template #seleccionEmpresaSucursal let-modal let-c="close">
  <div cdkDrag class=" bg-light animate__animated animate__backInDown animate__faster"
    style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
    <div class="modal-header">
      <h4 cdkDragHandle class="modal-title">Seleccionar Empresa/Sucursal de trabajo</h4>
    </div>
    <div class="modal-body {{(empresas.length > 0 || sucursales.length > 0) ? 'h-450px' : ''}}">
      <div class="row h-100" *ngIf="(empresas.length > 0 || sucursales.length > 0); else avisoSinPermisos">
        <div class="col-6">
          <h4>Empresas</h4>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Buscar Empresa" [(ngModel)]="inputEmpresas"
              (input)="onSearchEmpresas()" />
            <span class="input-group-text"><i class="fas fa-magnifying-glass"></i></span>
          </div>
          <div class=" container-scroll">
            <ng-scrollbar>
              <ul class="list-group">
                <li id="item-emp-{{e.id}}"
                  class="bg-light list-group-item  {{empresaSeleccionada === e ? 'select': ''}}"
                  *ngFor="let e of empresasFiltradas" aria-current="true" (click)="clickEmpresa(e)">
                  <div class="row">
                    <div class="col-11">
                      <div class="text-dark">
                        {{e.nombre|uppercase}}
                      </div>
                    </div>
                    <div class="col-1 m-auto">
                      <i *ngIf="empresaSeleccionada === e" class="far fa-circle-check fa-lg green float-end"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-scrollbar>
          </div>
        </div>
        <div class="col-6">
          <h4>Sucursales</h4>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Buscar Sucursal" [(ngModel)]="inputSucursales"
              (input)="onSearchSucursales()" />
            <span class="input-group-text"><i class="fas fa-magnifying-glass"></i></span>
          </div>
          <div class=" container-scroll">
            <ng-scrollbar>
              <ul class="list-group">
                <li id="item-suc-{{s.id}}"
                  class="bg-light list-group-item {{sucursalSeleccionada === s ? 'select': ''}}"
                  *ngFor="let s of sucursalesFiltradas" aria-current="true" (click)="clickSucursal(s)">
                  <div class="row">
                    <div class="col-11 text-center">
                      <div class="text-break">
                        {{s.nombre.toUpperCase()}}
                      </div>
                    </div>
                    <div class="col-1 m-auto">
                      <i *ngIf="sucursalSeleccionada === s" class="far fa-circle-check fa-lg green"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-scrollbar>
          </div>
        </div>
      </div>
      <ng-template #avisoSinPermisos>
        <div class="note alert-warning m-0 text-center">
          <div class="note-icon"><i class="fas fa-circle-info"></i></div>
          <div class="note-content">
            <h3><b>No tiene asignados permisos a ninguna Empresa/Sucursal.</b></h3>
            <h3><b>Verifique con su administrador de COMPLIT.</b></h3>
            <!-- <p> ... </p> -->
          </div>
        </div>
      </ng-template>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="aceptar(true)"
        *ngIf="(empresas.length > 0 || sucursales.length > 0)">Aceptar</button>
      <button type="button" class="btn btn-danger " (click)="logout()"
        *ngIf="(empresas.length == 0 || sucursales.length == 0)">Cerrar Sesión</button>
      <button type="button" class="btn btn-danger" (click)="aceptar(false)"
        *ngIf="this.usuarioLoggeado.empresa?.numero &&  this.usuarioLoggeado.empresa?.numero">Cancelar</button>
    </div>
  </div>
</ng-template>
