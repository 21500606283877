import { Component, inject, OnInit } from '@angular/core';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { SolicitudCancelacionInternaDTO } from '../../interfaces/solicitudcancelacioninterna.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { EventsService } from 'src/app/service/events.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cancelacion-documentos-ventas-page',
  templateUrl: './cancelacion-documentos-ventas-page.component.html',
  styles: [
  ]
})
export class CancelacionDocumentosVentasPageComponent implements OnInit {

  activeButtons: ActiveButtons = {
    all: false,
  }
  private eventsService = inject(EventsService);
  info: UserLogged = {} as UserLogged;
  source: any[] = [];
  constructor(private fs: FacturacionService, private utileService: UtilsService) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.cargarCancelaciones();
  }

  cancelar(item: any){
    this.eventsService.publish('home:showAlert', {
      message: `¿Está seguro de <b>CANCELAR EL DOCUMENTO <span>${item.serieString}${item.folio}</span></b>?`, onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {

        }
      }
    });
  }

  rechazarCancelacion(id: number) {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de <b>RECHAZAR LA CANCELACIÓN AL USUARIO</b>?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          Swal.fire({
            title: "Indique el MOTIVO del Rechazo",
            input: "text",
            inputAttributes: { autocapitalize: "off" },
            showCancelButton: true,
            confirmButtonText: "Rechazar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.eventsService.publish('home:isLoading', { isLoading: true });
                this.fs.rechazarSolicitudInterna(id, result.value).subscribe((result) => {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  if (result.success) {
                    this.cargarCancelaciones();
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se rechazó correctamente', showConfirmButton: false, timer: 2000 })
                  } else {
                    this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
                  }
                })


              } else {
                this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Motivo de rechazo.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
              }
            }
          });
        }
      }
    });

  }

  liberar(idVenta: number, idSol: number) {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de <b>CANCELAR SOLAMENTE EL INVENTARIO</b>?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          //this.eventsService.publish('home:isLoading', { isLoading: true });

        }
      }
    });

  }

  print(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.imprimirDocumentoVenta(id, true).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.fs.printDocument(this.fs.base64ToArrayBuffer(result.message));
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    });


  }

  cargarCancelaciones() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerSolicitudesCancelacionInternas(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      console.log(lista);
      this.source = lista;
    });
  }

}
