<form [formGroup]="cuentasFrm" autocomplete="off">
  <div class="row">
    <div class="col-md-6">
      <div class="card-header h6 mb-1 bg-none p-2 text-orange">
        <i class="fa fa-circle-info fa-lg fa-fw"></i>
        Cuenta beneficiaria
      </div>
      <!-- Empresa -->
      <component-ui-combo-box [required]="requiredCuentaBeneficiaria" label="Cuenta"
        formControlName="cuentaBeneficiaria" [tabIndex]="10" entityName="CuentaBancaria"
        filterQuery="Empresa.Id = {{empresaId}}" [widthTextColumns]="8" [widthLabelColumns]="4"
        inputProperty="ClaveNombre" [extras]="'Banco.Rfc as RfcBanco'" [tabIndex]="tabIndex"
        (onSelectedItem)="selectedCombo($event,'cuentaBeneficiaria')" [extras]="extrasCuentaBeneficiaria"
        [enabled]="!disabledBeneficiaria" />
    </div>
    <div class="col-md-6">
      <div class="card-header h6 mb-1 bg-none p-2 text-orange">
        <i class="fa fa-circle-info fa-lg fa-fw"></i>
        Cuenta ordenante
      </div>
      <!-- Cliente -->
      <component-ui-combo-box label="Cuenta" entityName="CuentaBancariaCliente" filterQuery="Cliente.Id = {{clienteId}}"
        formControlName="cuentaOrdenante" [tabIndex]="10" [widthTextColumns]="8" inputProperty="ClaveNombre"
        [widthLabelColumns]="4" [extras]="extrasCuentaOrdenante" [tabIndex]="tabIndex+1"
        (onSelectedItem)="selectedCombo($event,'cuentaOrdenante')" [enabled]="!disabledOrdenante" />
    </div>
  </div>
</form>
