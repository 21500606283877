import { Component, OnInit } from '@angular/core';
import { MovimientoBancario } from '../../interfaces/movimientobancario.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CuentaBancaria } from '../../interfaces/c_banco.interface';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-buscar-movimientos-bancarios-page',
  templateUrl: './buscar-movimientos-bancarios-page.component.html',
  styles: [
  ]
})
export class BuscarMovimientosBancariosPageComponent implements OnInit {

  private newEntityObj = {
    Cuenta: [{} as CuentaBancaria],
    FechaInicio: [Date],
    FechaFin: [Date],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);
  constructor(private http: HttpClient, private fb: FormBuilder,) { }

  lista: MovimientoBancario[] = [];
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;

  ngOnInit(): void {
    this.myForm.reset({
      Cuenta: null,
      FechaInicio: new Date(),
      FechaFin: new Date(),
    })
  }

  selectedCombo(ent: ComboBoxEntity, type: string) { this.myForm.controls[type].setValue(ent); }

  get getMovimientos(): number {
    return this.lista.filter(P => P.esNuevo).length;
  }

  getBalance() {
    const ent = this.myForm.value;
    if (!ent.Cuenta || ent.Cuenta?.Id == 0) {
      Swal.fire({ text: 'Indique una Cuenta Bancaria', icon: 'error', })
      return;
    }

    if (!ent.FechaInicio) {
      Swal.fire({ text: 'Indique una Fecha Inicio', icon: 'error', })
      return;
    }

    if (!ent.FechaFin) {
      Swal.fire({ text: 'Indique una Fecha Fin', icon: 'error', })
      return;
    }
    this.lista = [];
    this.saving = true;
    const params = new HttpParams().set("idCuenta", ent.Cuenta.Id).set("strfi", moment(ent.FechaInicio).format("DD/MM/YYYY")).set("strff", moment(ent.FechaFin).format("DD/MM/YYYY"));
    this.http.get<MovimientoBancario[]>(`${this.baseUrl}/Tesoreria/ObtenerMovimientosPorCuentaBancaria`, { params }).subscribe((lista) => {
      this.saving = false;
      this.lista = lista;
    });
  }

}
