
<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="SerieConstanciaRetencion" title="Series de Constancias de Pagos y Retenciones" icon="fa fa-registered"
  subTitle="Administra las Series para poder emitir Constancias de Pagos y Retenciones">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Serie'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="2" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieConstanciaRetencion" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="3" [required]="true"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            [tabIndex]="4" [widthTextColumns]="3" [widthLabelColumns]="2" [maxlength]="20" />
        </div>
      </app-panel-base>
    </form>
  </div>

</app-container-base>
