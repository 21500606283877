<app-container-base subTitle="Carga de Estados de Cuenta Para Conciliación" [hasBar]="false" [hasCounter]="false"
  icon=" fas fa-bars-staggered">
  <div class="screen-content">
    <div class="d-flex">
      <div style="width:950px;">
        <app-panel-base [title]="'Carga de estados de cuenta para la conciliacion bancaria'" [camelCase]="false">
          <div class="panel-content">
            <div class="d-flex mb-3">
              <a>
                <i class="fas fa-cog fa-5x"></i>
              </a>
              <div class="ps-3">
                <div class="email-from text-dark fs-14px mb-3px fw-bold">
                  Configuración de conciliación
                </div>
                <div class="mb-3px"><i class="fa fa-clock fa-fw"></i> <span class="text-black">{{titulo}}</span></div>
              </div>
            </div>
            <div class="d-flex">
              <app-panel-base [title]="'Saldos'" style="width: 450px;height:220px;">
                <div class="panel-content">
                  <h5>{{ seleccionado == 1 ? "ANTERIORES": (seleccionado == 2 ? "NOSOTROS": "ELLOS")}}</h5>
                  <hr>
                  <components-ui-number-box [maxLength]="15" [label]="'Saldo Anterior'" [tabIndex]="1"
                    [widthTextColumns]="4" [widthLabelColumns]="3" />
                  <div class="d-flex" style="flex-direction: row;">
                    <div style="width: 239px;margin-left: 7px;">
                      <components-ui-number-box [maxLength]="15" [label]="'Cargos'" [tabIndex]="2"
                        [widthTextColumns]="7" [widthLabelColumns]="5" />
                    </div>
                    <div style="width: 200px;">
                      <components-ui-number-box [maxLength]="15" [label]="''" separator="" [enabled]="false"
                        [widthTextColumns]="10" [widthLabelColumns]="1" />
                    </div>
                  </div>
                  <div class="d-flex" style="flex-direction: row;">
                    <div style="width: 239px;margin-left: 7px;">
                      <components-ui-number-box [maxLength]="15" [label]="'Abonos'" [tabIndex]="3"
                        [widthTextColumns]="7" [widthLabelColumns]="5" />
                    </div>
                    <div style="width: 200px;">
                      <components-ui-number-box [maxLength]="15" [label]="''" separator="" [enabled]="false"
                        [widthTextColumns]="10" [widthLabelColumns]="1" />
                    </div>
                  </div>

                  <components-ui-number-box [maxLength]="15" [label]="'Saldo Actual'" [tabIndex]="4"
                    [widthTextColumns]="4" [widthLabelColumns]="3" />
                </div>
              </app-panel-base>
              <app-panel-base [title]="'Opciones'" style="width: 450px;margin-left: 20px;height:220px;">
                <div class="panel-content">
                  <ul class="fa-ul">
                    <!-- <li style="cursor:pointer;" class="mb-1">
                      <span class="fa-li"><i class="fas fa-download text-primary "></i></span>
                      Respaldar
                    </li>
                    <li style="cursor:pointer;" class="mb-1">
                      <span class="fa-li"><i class="fas fa-upload text-success"></i></span>
                      Recuperar
                    </li> -->
                    <li style="cursor:pointer;" class="mb-1">
                      <span class="fa-li"><i class="fas fa-print text-blue "></i></span>
                      Imprimir
                    </li>
                    <li style="cursor:pointer;">
                      <span class="fa-li"><i class="fas fa-magnifying-glass text-danger "></i></span>
                      Buscar
                    </li>
                    <hr *ngIf="seleccionado > 1">
                    <li *ngIf="seleccionado == 2" style="cursor:pointer;">
                      <span class="fa-li"><i class="fas fa-upload text-success "></i></span>
                      Importar Movimientos de Contabilidad
                    </li>
                    <li style="cursor:pointer;" *ngIf="seleccionado == 3">
                      <span class="fa-li"><i class="fas fa-upload text-success "></i></span>
                      Importar Movimientos desde un Archivo
                    </li>
                  </ul>
                </div>
              </app-panel-base>
            </div>
          </div>
        </app-panel-base>
        <app-panel-base title="Movimientos">
          <div class="panel-content">
            <ul class="nav nav-tabs m-2" role="tablist">
              <li class="nav-item" role="presentation">
                <a (click)="seleccionar(1)" href="#cliente-default-tab-1" data-bs-toggle="tab" class="nav-link active"
                  aria-selected="true" role="tab">
                  <span class="d-sm-block d-none">
                    <i class="fa fa-clock fa-fw me-1"></i>ANTERIORES
                  </span>
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a (click)="seleccionar(2)" href="#cliente-default-tab-2" data-bs-toggle="tab" class="nav-link"
                  aria-selected="false" role="tab">
                  <span class="d-sm-block d-none"><i class="fa fa-users fa-fw me-1"></i>NOSOTROS</span>
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a (click)="seleccionar(3)" href="#cliente-default-tab-3" data-bs-toggle="tab" class="nav-link"
                  aria-selected="false" role="tab">
                  <span class="d-sm-block d-none "><i class="fa fa-user-tie fa-fw me-1"></i>ELLOS</span>
                </a>
              </li>
            </ul>
          </div>
        </app-panel-base>
      </div>
      <app-panel-base title="Procesos extras" style="width: 240px;margin-left: 5px; height: 500px;">
        <div class="panel-content">
          <ul class="fa-ul">
            <li style="cursor:pointer;" class="mb-1 link">
              <span class="fa-li"><i class="fas fa-1 text-success "></i></span>
              Configuración de Conciliación
            </li>
            <li style="cursor:pointer;" class="mb-1 link">
              <span class="fa-li"><i class="fas fa-2 text-success"></i></span>
              Conciliar de forma automática
            </li>
            <li style="cursor:pointer;" class="mb-1 link">
              <span class="fa-li"><i class="fas fa-3 text-success "></i></span>
              Conciliar de forma manual
            </li>
            <li style="cursor:pointer;" class="mb-1 link">
              <span class="fa-li"><i class="fas fa-4 text-success "></i></span>
              Imprimir resumen de conciliación
            </li>
            <hr>
            <li style="cursor:pointer;" class="mb-1 link">
              <span class="fa-li"><i class="fas fa-5 text-success "></i></span>
              Borrar los movimientos del periodo
            </li>
            <li style="cursor:pointer;" class="mb-1 link">
              <span class="fa-li"><i class="fas fa-6 text-success "></i></span>
              Des-conciliar los movimientos del periodo
            </li>
            <li style="cursor:pointer;" class="link">
              <span class="fa-li"><i class="fas fa-7 text-success "></i></span>
              Volver a cargar los movimientos del periodo
            </li>
          </ul>
        </div>
      </app-panel-base>
    </div>

  </div>
</app-container-base>
