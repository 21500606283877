<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <label  class="col-sm-{{widthLabelColumns}} {{(topLabel ? 'text-left height-label':'tb-right')}} col-form-label {{(enableLink?'tb-link text-blue':( IsRequired?'text-red': ''))}}"><i *ngIf="IsRequired" class="fa fa-asterisk text-red fs-10px me-2"></i>{{label}}{{separator}}</label>
  <div class="col-sm-{{widthTextColumns}}">
    <input (focus)="focus()" tooltipClass="complit-tooltip"  style="border-radius: 0px;border:0px;border-bottom: 1px solid #000000;" [tabindex]="tabIndex" (blur)="blurControl(txtTagPas.value)" [(ngModel)]="value" maxLength="{{maxLength}}"
    class="form-control form-control-sm {{(IsRequired?'bottom-red': '')}}" type="password"
    (change)="onChangeTextValue($event)"  placeholder="{{placeHolder}}" #txtTagPas [disabled]="!this.enabled" />
  </div>
  <div class="col-auto">
    <span id="passwordHelpInline" class="form-text">
      {{notes}}
    </span>
  </div>
</div>
