<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Formulación</div>
  <div class="card-body bg-light">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <form [formGroup]="myForm" autocomplete="off">

          <app-panel-base [title]="'Cálculo de formula'">
            <div class="panel-content">
              <components-ui-number-box [maxLength]="9" [label]="'Grosor'" [required]="true" (keydown.escape)="close()"
                formControlName="GrosorFormulacion" [tabIndex]="1" [widthTextColumns]="3" [widthLabelColumns]="4"
                #txtGrosor />
              <components-ui-number-box [maxLength]="9" [label]="'Ancho'" [required]="true"
                formControlName="AnchoFormulacion" [tabIndex]="2" [widthTextColumns]="3" [widthLabelColumns]="4" />
              <components-ui-number-box [maxLength]="9" [label]="'Largo'" [required]="true"
                formControlName="LargoFormulacion" [tabIndex]="3" [widthTextColumns]="3" [widthLabelColumns]="4" />
              <components-ui-number-box [maxLength]="9" [label]="'Piezas'" [required]="true"
                formControlName="PiezasFormulacion" [tabIndex]="4" [widthTextColumns]="3" [widthLabelColumns]="4"
                (keydown.enter)="accept()" #txtPiezas/>
              <div style="text-align: right; margin-top: 5px;">
                <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
                <div (click)="close()" class="btn btn-danger" #txtCancelar>Cancelar</div>
              </div>
            </div>
          </app-panel-base>
        </form>


      </div>

    </app-container-base>

  </div>
</div>
