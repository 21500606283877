import { CompilerOptions, Injectable } from '@angular/core';
import { ComboBoxEntity, TriggerParams } from '../interfaces/combo-text.interface';
import { compileNgModule } from '@angular/compiler';
import { startOfDay } from 'date-fns';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComboBoxService {

  private _items: any[] = [];

  private readonly baseUrl: string = environment.baseUrlApi;
  private _filteredItems: any[] = [];
  private _isOpen: boolean = false;

  constructor(private http: HttpClient) {

  }

  triggerClick(params: TriggerParams) {
    this.createItemsList(params);
  }



  isOpen() {
    const lant = document.getElementById('div-combo-box');
    return lant ? true : false;
  }

  getItemsLength(): number {
    return this._items.length;
  }

  getItems(): ComboBoxEntity[] {
    return [...this._items];
  }

  hide() {
    const lant = document.getElementById('div-combo-box');
    this._filteredItems = [];
    lant?.remove();
  }



  private clickItem(item: ComboBoxEntity, params: TriggerParams, index: number) {
    item.Index = index;
    if (params) { params.onSelected(item); }
    this.hide();
  }

  private createItemsList(params: TriggerParams) {
    let _items = params.listData;
    if (_items!.length > 0) {
      const lant = document.getElementById('div-combo-box');
      if (!lant) {
        let b1 = document.createElement("virtual-scroller");
        b1.id = 'div-combo-box';
        b1.className = "combo-box-list";//
        b1.setAttribute('style', `width:${params.width}px;
                                  margin-left: ${params.coords.left}px;
                                  margin-top: ${params.coords.top}px;`);
        _items!.forEach((item: any, index) => {
          let ditem = document.createElement("div");
          ditem.id = `item-cbo-id${index}`;
          ditem.innerText = item[params.listProperty];
          ditem.addEventListener('mousedown', (e) => {
            e.preventDefault();
          });
          ditem.addEventListener('click', (e) => {
            this.clickItem(item, params, index);
          });
          //ditem.setAttribute("style","background-color:var(--bs-app-header-input-bg)");
          if (index == params.selectedIndex) {
            ditem.className = 'combo-box-selected-item text-dark';
          } else {
            ditem.className = 'combo-box-item text-dark bg-light';
          }
          b1.appendChild(ditem);
        });

        //document.getElementsByTagName('app-root')[0].appendChild(b1);
        document.body.appendChild(b1);
        if (params.selectedIndex >= 0) {
          document.getElementById(`item-cbo-id${params.selectedIndex}`)?.scrollIntoView();
        }

        const elems = document.querySelectorAll(".widget-chat-body.alto-pantalla");
        if (elems) {
          setTimeout(() => {
            elems.forEach((element) => {
              element.addEventListener("scroll", (event) => {
                const lant = document.getElementById('div-combo-box');
                if (lant) { lant.remove();}
              });
            });
          }, 150);
        }
      } else {
        lant.remove();
      }
    }
  }
}
