import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';


import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { CuentaCxc, ItemEstadoCuentaCXC } from '../../interfaces/cuentacxc.interface';
import { CuentasPorCobrarService } from '../../services/cxc.service';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { ModalService } from 'src/app/service/modal.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import Swal from 'sweetalert2';
import { Cliente } from '../../../ventas/interfaces/cliente.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ReportsService } from 'src/app/service/reports.service';
import { EventsService } from 'src/app/service/events.service';


@Component({
  selector: 'app-estado-cuenta-cxc-page',
  templateUrl: './estado-cuenta-cxc-page.component.html',
})

export class EstadoCuentaCxcPageComponent implements OnInit {

  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  @ViewChild('cuenta')
  public ctrlCuenta!: ElementRef<HTMLElement>;

  @ViewChild('gridEdoCta')
  public gridEdoCta!: ElementRef<HTMLElement>;

  @ViewChild('reporteEdoCuenta')
  public reporteEdoCuenta!: ElementRef<HTMLElement>;

//* injects
  private ms = inject(ModalService);
  private cxcService = inject(CuentasPorCobrarService);
  private utilsService = inject(UtilsService);
  private fb = inject(FormBuilder);
  private containerBaseService = inject(ContainerBaseService)
  private eventsService = inject(EventsService);


  listaAnios: any[] = [];
  anioSeleccionado?: ComboBoxEntity;

  public GuiColumnAlign = GuiColumnAlign;

  loading: boolean = false;
  clienteSeleccionado: any = { Id: 0 };
  nombreEmpresa: string = "";
  nombreCliente: string = "";
  usuarioLoggeado!: UserLogged;
  listaMeses: ItemEstadoCuentaCXC[] = []
  searchConfigurationCliente!: SearchConfiguration;

  public myForm: FormGroup = this.fb.group({
    anio: 0,
    cuenta: [{} as CuentaCxc],
    clienteClave: 0,
    clienteId: 0,
    reporteHeader: {} as ReportHeader,
    cliente: [{} as Cliente]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public filtroPptos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Verificador de Ppto por vendedor',
      NombreExcel: 'Verificador de Ppto por vendedor.xlsx',
      FilterOptions: [
        { Campo: 'cta.Clave', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };


  constructor() {
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    this.myForm.controls[field].setValue(entity);
    this.anioSeleccionado = this.myForm.get('anio')?.value;

    const cliente:Cliente = this.myForm.get('cliente')?.value;
    const cuenta:CuentaCxc  = this.myForm.get('cuenta')?.value;
    const anio :number = this.myForm.get('anio')?.value;
    if (cliente.Id && cuenta.Id){
      this.getEstadoDeCuenta(cliente.Id, cuenta.Id, anio);
    }
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    return this.anioSeleccionado!;
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  ngOnInit(): void {

    this.usuarioLoggeado = this.utilsService.getUserLogged();
    this.nombreEmpresa = this.usuarioLoggeado.empresa!.nombre;
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.usuarioLoggeado.empresa!.numero}`);
    this.focus('cuenta');

    this.cxcService.obtenerListaDeAniosconMovimientosDeCXC().subscribe(result => {
      if (result) {
        this.loading = false;
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
      }
    })
  }

  focus(field: string) {
    setTimeout(() => {
      let txt: any
      if (field == 'cuenta') {
        txt = this.ctrlCuenta;
      } else if (field == 'grid') {
        txt = this.gridEdoCta;
      }
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  buscarClientePorClave() {
    const provId: any = this.myForm.get('clienteClave')?.value;
    if (provId) {
      this.containerBaseService.getEntityByClave("Cliente", provId).subscribe(cliente => {
        this.clienteSeleccionado = cliente;
        this.getEstadoDeCuenta(cliente.Id, this.myForm.get('cuenta')?.value.Id, this.anioSeleccionado!.Id);
        this.myForm.controls['clienteClave'].setValue(cliente.Clave);
        this.myForm.controls['clienteId'].setValue(cliente.Id);
        this.myForm.controls['cliente'].setValue(cliente);
      });
    }

  }

  buscarCliente() {
    if (this.myForm.value.cuenta == null) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar una cuenta de cxc.' });
      return;
    }

    if (this.myForm.value.anio.Id == 0) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar una año para consultar el estado de cuenta.' });
      return;
    }




    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (cliente: any) => {
      if (cliente) {
        this.loading = true;
        this.clienteSeleccionado = cliente;
        this.nombreCliente = `${cliente.Clave} ${cliente.Nombre}`;
        this.getEstadoDeCuenta(cliente.Id, this.myForm.get('cuenta')?.value.Id, this.anioSeleccionado!.Id);
        this.myForm.controls['clienteClave'].setValue(cliente.Clave);
        this.myForm.controls['clienteId'].setValue(cliente.Id);
        this.myForm.controls['cliente'].setValue(cliente);
      }
    }, 'lg')
  }

  getEstadoDeCuenta(cliente: number, cuenta: number, anio: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.cxcService.getEstadoDeCuenta(cliente, cuenta, this.myForm.value.anio.Id).subscribe(result => {
      this.listaMeses = result;
      this.focus('grid');
      this.eventsService.publish('home:isLoading', { isLoading: false });
    })
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "print": this.print(); break;
    }
  }







  print() {
     if (this.clienteSeleccionado.Id == 0) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar un cliente.' });
      return;
    }
    const b: any = this.reporteEdoCuenta;
    this.ms.openModal(b, (e: any) => {
      if (e != null && e != undefined) {
        this.myForm.controls["reportHeader"].setValue(e);
      }
    }, 'md')
  }


}
