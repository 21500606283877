<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="tiposRetirosFrm" entityName="TipoDepositoCajaGeneral"
  title="Tipos de Retiros" icon="fa fa-building" subTitle="Administra la configuración de los tipos de retiros...">
  <div class="screen-content">
    <form [formGroup]="tiposRetirosFrm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" label="Tipo de retiro"
            entityName="TipoDepositoCajaGeneral" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
