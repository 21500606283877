<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <app-panel-base title="CFDI relacionado">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <component-ui-combo-box [tabIndex]="1" formControlName="TipoRelacion" [entityName]="'c_TipoRelacion'"
          [widthLabelColumns]="3" label="Relación" [widthList]="400" [widthTextColumns]="4" [widthRightColumns]="4"
          (onSelectedItem)="onSelectedItem($event,'TipoRelacion')" />

        <component-ui-combo-box *ngIf="!soloUUID" [tabIndex]="2" formControlName="Sucursal" [entityName]="'Sucursal'"
          filterQuery="Empresa.Id = {{idEmpresa}}" [widthLabelColumns]="3" label="Sucursal" [widthTextColumns]="4"
          [widthRightColumns]="4" (onSelectedItem)="onSelectedItem($event,'Sucursal')" />

        <component-ui-combo-box *ngIf="!soloUUID" [tabIndex]="3" formControlName="DocumentoVenta"
          (onSelectedItem)="onSelectedItem($event,'DocumentoVenta')" [entityName]="'DocumentoVenta'"
          filterQuery='(TipoDeComprobante.Clave = "I" or TipoDeComprobante.Clave = "E" or TipoDeComprobante.Clave = "T")'
          [widthLabelColumns]="3" label="Documento" [widthTextColumns]="4" [widthRightColumns]="4" />

        <component-ui-combo-box *ngIf="!soloUUID" [tabIndex]="4"
          [enabled]="myForm.value.Sucursal.Id>0 && myForm.value.DocumentoVenta.Id>0" [setItemsList]="listaSeries"
          [inputProperty]="'Serie'" formControlName="Serie" (onSelectedItem)="onSelectedItem($event,'Serie')"
          [widthLabelColumns]="3" label="Serie" [widthTextColumns]="4" [widthRightColumns]="4" />

        <components-ui-number-box *ngIf="!soloUUID" [tabIndex]="5"
          [enabled]="myForm.value.Sucursal.Id>0 && myForm.value.DocumentoVenta.Id>0 && myForm.value.Serie.Id>0"
          formControlName="Folio" [widthLabelColumns]="3" label="Folio" [widthTextColumns]="4" />

        <div *ngIf="!soloUUID" style="text-align: right; margin-bottom: 4px;">
          <a (click)="searchCfdi()" class="btn btn-default me-5px"><i
              class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-magnifying-glass')}}"></i> Buscar CFDI</a>
        </div>

        <components-ui-text-box [tabIndex]="6"
          [enabled]="(myForm.value.Sucursal.Id>0 && myForm.value.DocumentoVenta.Id>0 && myForm.value.Serie.Id>0) || soloUUID"
          formControlName="UUIDS" [widthLabelColumns]="3" label="UUID's" [widthTextColumns]="9" />

        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
        </div>
      </form>
    </div>
  </app-panel-base>
</div>
