<app-container-base subTitle="Recepción de Traspasos que se encuentran en tránsito al destino" [hasBar]="false"
  [hasCounter]="false" icon=" fas fa-list-check">
  <div class="screen-content">
    <div>
      <form [formGroup]="myForm" autocomplete="off">
        <div class="col-md-12">
          <app-panel-base title="Listado de traspasos en tránsito, listos para recibirse">
            <div class="panel-content">
              <div class="alert alert-yellow alert-dismissible fade show m-2">
                <strong>NOTA:</strong>
                Seleccione los Traspasos que están en tránsito y de clic en el botón <b>Recibir</b> para hacer la
                entrada de inventario en la Sucursal destino
              </div>
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-filter fa-lg fa-fw me-1"></i>
                Seleccione los filtros de búsqueda
              </div>
              <!-- <component-ui-combo-box formControlName="SucursalEnvia" helpLine="Sucursal emisora del trapaso"
                [required]="true" [label]="'Sucursal Origen'" [tabIndex]="1" [zeroMask]="2" entityName="Sucursal"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8" [listProperty]="'ClaveNombre'"
                #cboEnvia /> -->

              <component-ui-combo-box formControlName="SucursalRecibe" helpLine="Sucursal receptora del trapaso"
                [required]="true" [label]="'Sucursal Destino'" [tabIndex]="2" [zeroMask]="2" entityName="Sucursal"
                [widthTextColumns]="2" [widthLabelColumns]="2" (onSelectedItem)="selected($event, 'SucursalRecibe')"
                [widthRightColumns]="8" [listProperty]="'ClaveNombre'" #cboEnvia />

              <component-ui-check-box [enabled]="lista.length>0" helpLine="Selecciona todos" [tabIndex]="3"
                [widthLabelColumns]="2" [widthTextColumns]="2" [label]="'Todos'" (onClick)="clicTodos($event)"
                formControlName="Todos" />

              <div style="margin-top: 5px; margin-left: 245px;">
                <div (click)="buscar()" tabindex="25" class="btn btn-primary">Buscar</div>
              </div>

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-list-check fa-lg fa-fw me-1"></i>
                Resultados de búsqueda
              </div>
              <gui-grid [autoResizeWidth]="false" [width]="780" [loading]="procesando" [source]="lista" [maxHeight]="400" [rowHeight]="20"
                [virtualScroll]="true" [rowSelection]="false" [theme]="'fabric'">
                <gui-grid-column index-column="0" [cellEditing]="false" header="" [width]="50"
                  [align]="GuiColumnAlign.CENTER">
                  <ng-template let-item="item">
                    <input type="checkbox" (click)="seleccionar(item)" [checked]="item.seleccionado"
                      class="form-check-input">
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column index-column="0" [cellEditing]="false" header="" [width]="50"
                  [align]="GuiColumnAlign.CENTER">
                  <ng-template let-item="item" let-index="index">
                    <div (click)="print(item)" class="btn btn-success btn-xs">Ver</div>
                  </ng-template>
                </gui-grid-column>
                <gui-grid-column index-column="1" header="Envía" [width]="110" field='sucursalEnvia' />
                <gui-grid-column index-column="1" header="Recibe" [width]="110" field='sucursalRecibe' />
                <gui-grid-column header="Emisión" [width]="100" field='fechaEmision' [cellEditing]="false" />
                <gui-grid-column header="Serie y Folio" [width]="120" field='serieFolio' />
                <gui-grid-column header="Cantidad" [width]="120" field='totalCantidad' [align]="GuiColumnAlign.RIGHT" />
                <gui-grid-column header="No Conceptos" [width]="120" field='totalRegistros'
                  [align]="GuiColumnAlign.RIGHT" />
              </gui-grid>
              <hr>
              <h5 style="width: 780px;text-align: right;">
                Total de registros: {{lista.length}}
              </h5>

              <div style="text-align: left; margin-top: 5px;" *ngIf="lista.length && !procesando">
                <div (click)="recibir()" tabindex="25" class="btn btn-green">Recibir</div>
              </div>
            </div>
          </app-panel-base>
        </div>
      </form>
    </div>
  </div>
</app-container-base>
