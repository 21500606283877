<app-container-base [hasBar]="!editing" (onItemsComboSearched)="onItemsComboSearched($event)"
  (onItemSearched)="onItemSearched($event)" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  [entity]="myForm" entityName="PolizaAutomatica" title="Pólizas automáticas" icon="fa fa-code-pull-request"
  [printManual]="true" (onClickBarButton)="imprimir($event)"
  subTitle="Administra la configuración de las Pólizas automáticas">
  <div class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box [enabled]="!editing" (onNewCatalog)="onNewCatalog($event)"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew"
            [setItems]="getItemsCombo" [label]="'Clave póliza'" entityName="PolizaAutomatica" [tabIndex]="1"
            [listProperty]="'ClaveNombre'" [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [enabled]="!editing" [label]="'Nombre'" [required]="true" formControlName="Nombre"
            [tabIndex]="2" [widthTextColumns]="5" [widthLabelColumns]="2" #txtNombre />

        </div>
      </app-panel-base>

      <div class="card border-0 mb-1" style="width: 100%;">
        <div class="card-body">

          <component-ui-combo-box [enabled]="!editing" [required]="true" [inputProperty]="'Nombre'"
            formControlName="TipoPoliza" [tabIndex]="3" [entityName]="'TipoPoliza'"
            (onSelectedItem)="onSelectedItem($event,'TipoPoliza')" label="Tipo" [widthTextColumns]="1"
            [widthLabelColumns]="2" [widthRightColumns]="0" #cboTipoPoliza />

          <components-ui-text-box [enabled]="!editing" [required]="true" label='Concepto general de la póliza'
            formControlName="ConceptoGeneral" [tabIndex]="4" [maxLength]="500" [widthLabelColumns]="2"
            [widthTextColumns]="5" #txtConcepto />

          <component-ui-combo-box [enabled]="!editing" [required]="true" [inputProperty]="'Nombre'"
            [listProperty]="'Nombre'" formControlName="Modulo" [tabIndex]="5" [entityName]="'PolizaAutomaticaModulo'"
            (onSelectedItem)="onSelectedItem($event,'Modulo')" label="Módulo" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="0" />


          <components-ui-text-box [enabled]="!editing" *ngIf="getDoctoEnabled" [label]="'Tipo de Documento'"
            formControlName="TipoDocumentoVentaCompra" [maxLength]="500" [tabIndex]="6" [widthTextColumns]="5"
            [widthLabelColumns]="2" />

          <components-ui-text-box [enabled]="!editing" *ngIf="getCuentasEnabled" [label]="'Cuentas'"
            formControlName="Cuentas" [tabIndex]="7" [maxLength]="500" [widthTextColumns]="9" [widthLabelColumns]="2" />

          <components-ui-text-box [enabled]="!editing" [label]="'Conceptos'" *ngIf="getConceptosEnabled"
            formControlName="Conceptos" [maxLength]="500" [tabIndex]="8" [widthTextColumns]="9"
            [widthLabelColumns]="2" />

          <components-ui-text-box [enabled]="!editing" *ngIf="getAlmacenEnabled" [label]="'Almacenes'"
            formControlName="Almacenes" [maxLength]="500" [tabIndex]="9" [widthTextColumns]="9"
            [widthLabelColumns]="2" />

          <component-ui-check-box [enabled]="!editing" *ngIf="getDoctoEnabled" [tabIndex]="10" [widthLabelColumns]="2"
            formControlName="SoloAnticipos" [widthTextColumns]="7" [label]="'Solo leer anticipos'" />

          <component-ui-check-box [enabled]="!editing" *ngIf="getDoctoEnabled" [tabIndex]="11" [widthLabelColumns]="2"
            formControlName="ExluirAnticipos" [widthTextColumns]="7" [label]="'Excluir anticipos'" />

          <component-ui-check-box [enabled]="!editing" [tabIndex]="12" [widthLabelColumns]="2"
            formControlName="DisponibleParaRegenerar" [widthTextColumns]="8" [label]="'Disponible para generar'" />

          <component-ui-combo-box [enabled]="!editing" [inputProperty]="'Nombre'" formControlName="SiguientePoliza"
            [tabIndex]="13" [entityName]="'PolizaAutomatica'" [filterQuery]='getFilterNext'
            (onSelectedItem)="onSelectedItem($event,'SiguientePoliza')" label="Siguiente Póliza" [widthTextColumns]="2"
            [widthLabelColumns]="2" [widthRightColumns]="0" />

        </div>
      </div>
      <div class="card border-0 mb-1" style="width: 100%;">
        <div class="card-body">
          <gui-grid [autoResizeWidth]="false" [rowStyle]="rowStyle" [source]="listaDetalles" [rowHeight]="20"
            [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">
            <gui-grid-column [cellEditing]="false" header="" [width]="30" [align]="GuiColumnAlign.CENTER">
              <ng-template let-item="item" let-index="index">
                <i (click)="deleteRow(index)" *ngIf="item.CuentaContable || item.EsComentario"
                  class="fa fa-rectangle-xmark" style="color:red;font-weight: bold; cursor: pointer;"></i>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="No." [width]="30" [field]="'orden'" [cellEditing]="false">
              <ng-template let-item="item">
                <span *ngIf="item.CuentaContable || item.EsComentario">
                  {{item.Orden}}
                </span>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Cuenta Contable" [width]="150" [field]="'CuentaContable'" [cellEditing]="false" />
            <gui-grid-column header="Centro Costo" [width]="150" [field]="'CentroCosto'" [cellEditing]="false" />
            <gui-grid-column header="Concepto" [width]="150" [field]="'ConceptoDetalle'" />
            <gui-grid-column header="Referencia" [width]="150" [field]="'Referencia'" />
            <gui-grid-column header="Sucursal" [width]="100">
              <ng-template let-item="item">
                {{(item.Sucursal ? item.Sucursal.Nombre : '')}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Clase Venta" [width]="130">
              <ng-template let-item="item">
                {{(item.ClaseVenta ? item.ClaseVenta.Nombre : '')}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Clase Compra" [width]="130">
              <ng-template let-item="item">
                {{(item.ClaseCompra ? item.ClaseCompra.Nombre : '')}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Banco" [width]="100">
              <ng-template let-item="item">
                {{(item.Banco ? item.Banco.Nombre : '')}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Valor Cargo" [width]="200">
              <ng-template let-value="value" let-item="item">
                {{(item.TipoConceptoCargo ? item.TipoConceptoCargo.Nombre : '')}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Valor Abono" [width]="200">
              <ng-template let-value="value" let-item="item">
                {{(item.TipoConceptoAbono ? item.TipoConceptoAbono.Nombre : '')}}
              </ng-template>
            </gui-grid-column>
          </gui-grid>
        </div>
      </div>


      <div *ngIf="editing">
        <app-ctrl-edicion-poliza-automatica-page [tabIndex]="50" [item]="getSelectedItem"
          (onCloseEdition)="onCloseEdit($event)" />
      </div>

    </form>
  </div>
</app-container-base>
