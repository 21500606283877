<div class="bg-white" style="padding: 5px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <app-panel-base title="Ordenado Vs Entregado">
    <div class="panel-content">
      <div style="margin-bottom: 5px; text-align: right;">
        <span>
          <a class="btn btn-sm btn-white me-1" (click)="imprimirHistorico()">
            <i class="fa fa-file-pdf t-plus-1 text-red fa-fw fa-lg"></i>
            Imprimir Histórico
          </a>
        </span>
      </div>
      <div class="flex-grow-1">
        <div class="progress h-5px rounded-pill bg-white-transparent-1">
          <div class="progress-bar progress-bar-striped bg-green" [style]="'width: '+getPorcenjate+'%;'"></div>
        </div>
      </div>
      <div class="table-responsive mb-3" style="max-height: 220px; height: 220px; overflow-y:auto; border: 1px solid;">
        <table class="table table-sm table-hover">
          <thead style="background-color: #fd8204; position: sticky;top:0;color: #ffffff;">
            <tr>
              <th>Producto</th>
              <th>Unidad</th>
              <th style="text-align:right;">Cantidad</th>
              <th style="text-align:right;">Entregado</th>
              <th style="text-align:right;">Pendiente</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of lista; let i = index">
              <td class="text-truncate">{{item.claveProducto + ' - ' + item.nombreProducto}}</td>
              <td class="text-truncate">{{item.claveUnidad + ' - ' + item.nombreUnidad}}</td>
              <td style="text-align:right;">{{item.ordenado| number:'1.2-6'}}</td>
              <td style="text-align:right;">{{item.entregado| number:'1.2-6'}}</td>
              <td style="text-align:right;">{{item.pendiente | number:'1.2-6'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </app-panel-base>
  <div style="text-align: right; margin-top: 5px;margin-bottom: 5px;">
    <div (click)="closeModal()" class="btn btn-danger">Cerrar</div>
  </div>
</div>
