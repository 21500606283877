import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentsUiService } from '../../services/components-ui.service';

@Component({
  selector: 'components-ui-password-box',
  templateUrl: './password-box.component.html',
  styleUrls: ['./password-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordBoxComponent),
      multi: true,
    }
  ]
})
export class PasswordBoxComponent implements AfterViewInit, ControlValueAccessor, DoCheck {

  @Input()
  public enableLink: boolean = false;
  @Input()
  public topLabel: boolean = false;
  @Input()
  public placeHolder: string = "";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 100;
  @Input()
  public tabIndex: number = 1;
  // @Input()
  // public mapping: string = "";
  @Input()
  public notes: string = "";
  @Input()
  public required: boolean = false;

  @Input()
  public enabled: boolean = true;
  @Input()
  public helpLine: string = "";

  @Output()
  onChangeValue = new EventEmitter<string>();


  @ViewChild('txtTagPas')
  public tagInput!: ElementRef<HTMLInputElement>;


  value: string = "";


  get IsRequired(): string {
    if (this.tagInput) {
      if (this.tagInput.nativeElement.value) return "";
    }
    return this.required ? "tb-obligate" : "";
  }

  onChange = (value: string) => {
    this.onChangeValue.emit(value)
  };

  onChangeTextValue(value: any) {
    this.onChangeValue.emit(value);
  }

  onTouched = () => { };

  private isDisabled = false;
  inputField = new FormControl();
  constructor(private compsService: ComponentsUiService, private changeDetectorRef: ChangeDetectorRef,) {

  }
  ngDoCheck(): void {
  }
  ngAfterViewInit(): void {
    this.inputField.valueChanges.subscribe(value => {
      this.onChange(value!);
    });

    this.setDisabledState(this.isDisabled);
  }

  blurControl(value: string) {
    this.tagInput.nativeElement.classList.remove("pulse-text");
    this.writeValue(value);
  }
  focus() {
    let pes = document.querySelectorAll('.lineadeayuda');
    if (this.helpLine) {
      pes.forEach((elem: any) => { elem.innerHTML = this.helpLine; });
    } else {
      pes.forEach((elem: any) => { elem.innerHTML = "" });
    }
    this.tagInput.nativeElement.classList.add("pulse-text");
  }

  writeValue(val: string): void {
    this.inputField.setValue(val);
    this.value = val;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
