import { Component } from '@angular/core';
import { GuiSorting } from '@generic-ui/ngx-grid';

@Component({
  selector: 'app-almacen-cfdi-page',
  templateUrl: './almacen-cfdi-page.component.html',
  styles: [
  ]
})
export class AlmacenCfdiPageComponent {

  sorting: GuiSorting = {
    enabled: true
};

  numbers = Array(20000).fill(1)
}
