import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { UtilsService } from 'src/app/service/utils.service';
import { ContabilidadService } from '../../services/contabilidad.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportsService } from 'src/app/service/reports.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { EventsService } from 'src/app/service/events.service';
import { HttpClient } from '@angular/common/http';
import { NumericDictionary } from 'lodash';
import { EmpresaSucursalDto, UserLogged } from 'src/app/auth/interfaces';

@Component({
  selector: 'app-xml-balanza-mensual-page',
  templateUrl: './xml-balanza-mensual-page.component.html',
  styles: [
  ]
})
export class XmlBalanzaMensualPageComponent implements OnInit, AfterViewInit {


  @ViewChild('txtFileConfiguracion')
  public txtFileConfiguracion!: ElementRef<HTMLInputElement>;


  //* injects
  private utilsService = inject(UtilsService);
  private contabilidadService = inject(ContabilidadService);
  private fb = inject(FormBuilder)
  private reportsService = inject(ReportsService)
  private eventsService = inject(EventsService)
  private http = inject(HttpClient);

  //* variables globales
  listaMeses: any[] = [];
  listaAnios: any[] = [];
  listaNivelAEnviar: any[] = [];
  listaTipoEnvio: any[] = [];
  mes1Seleccionado?: ComboBoxEntity;
  anioSeleccionado?: ComboBoxEntity;
  nivelEnviarSeleccionado?: ComboBoxEntity;
  tipoEnvioSeleccionado?: ComboBoxEntity;
  loadComplete: boolean = false;
  file: any;
  mesActual: number = 0;
  private readonly baseUrl: string = environment.baseUrlApi;
  esBalanzaCierre: boolean = false;
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;

  get getMes1(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE BALANZA XML SAT AL ' + this.rangoFechas);
    let result: ComboBoxEntity = {} as ComboBoxEntity;
    if (this.mes1Seleccionado) {
      result = this.mes1Seleccionado;
    }
    return result;
  }




  get rangoFechas() {
    let result = "";
    if (!this.anioSeleccionado) return result;
    if (!this.mes1Seleccionado) return result;

    const diaUltimo: number = this.utilsService.getUltimoDiaMes(this.anioSeleccionado.Id, this.mes1Seleccionado.Id);


    result = diaUltimo + " DE " + this.utilsService.getMes(this.mes1Seleccionado!.Id) + " DE " + this.anioSeleccionado.Clave;
    return result.toUpperCase();
  }



  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<HTMLElement>;;

  public filtrosReporte: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Balanza Mensual',
      NombreExcel: 'Balanza Mensual.xlsx',
    };

  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Fecha1: new Date(),
    Fecha2: new Date(),
    Num1: 0,
    Num2: 0,
    Num3: 0,
    Num4: 0,
    Opc1: false
  });







  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }


  ngAfterViewInit(): void {

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);

    this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;

  }


  ngOnInit(): void {



    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);


    this.info = this.utilsService.getUserLogged();
    this.empresaActual = this.info.empresa;

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);

    this.mesActual = mesItem;
    this.mes1Seleccionado = mesItem;


    this.myForm.get('Num1')?.setValue(mesItem.Clave);


    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }

        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('Num2')?.setValue(this.anioSeleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE BALANZA XML SAT AL ' + this.rangoFechas);

        this.listaNivelAEnviar.push({ Id: 1, Nombre: "Todos los Niveles", Clave: 1 },
          { Id: 2, Nombre: "Hasta 2do Nivel", Clave: 2 });
        this.nivelEnviarSeleccionado = this.listaNivelAEnviar[0];
        this.myForm.get('Num3')?.setValue(this.anioSeleccionado!.Clave);

        this.listaTipoEnvio.push({ Id: 1, Nombre: "N -> Normal", Clave: 1 },
          { Id: 2, Nombre: "C -> Complementaria", Clave: 2 });

        this.tipoEnvioSeleccionado = this.listaTipoEnvio[0];
        this.myForm.get('Num4')?.setValue(this.anioSeleccionado!.Clave);
      }
    })
  }

  descargarxml() {
    let nivel = 0;
    if (this.nivelEnviarSeleccionado?.Clave == 2) {
      nivel = 2
    }
    let anio: number = this.anioSeleccionado!.Id;
    let mes: number = this.mes1Seleccionado!.Id;
    let tipoenvio: number = this.tipoEnvioSeleccionado!.Id;
    let fechaMod: Date = this.myForm.get('Fecha1')?.value;
    const fechaStr: string = this.utilsService.Date2Str(fechaMod, 8);
    let balanceCierre: number = 0;
    let mesNombre: number = mes;
    if (this.esBalanzaCierre) {
      balanceCierre = 1;
      mesNombre = 13;
    }
    let tipoenvioLetra: string = "N";
    if (tipoenvio == 2 ){
      tipoenvioLetra = 'C';
    }


    let archivoNombre: string = this.empresaActual!.rfc + String(anio) + mesNombre.toString().padStart(2, '0') +'B'+ tipoenvioLetra;




    this.http.get(`${this.baseUrl}/Contabilidad/DescargarArchivoBalanzaMensualXML?numeroNivel=${nivel}&anio=${anio}&mes=${mes}&balanceDeCierre=${balanceCierre}&tipoenvio=${tipoenvio}&fechaEnvioCompl=${fechaStr}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (d) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        let blob = new Blob([d], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = archivoNombre;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    })
  }



  get getNivelaenviar(): ComboBoxEntity | null {
    return this.nivelEnviarSeleccionado!;
  }

  get getTipoEnvio(): ComboBoxEntity | null {
    return this.tipoEnvioSeleccionado!;
  }

  onClickCierre(value: boolean) {
    if (value) {
      this.esBalanzaCierre = true;
    } else {
      this.esBalanzaCierre = false;
    }
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    if (this.esBalanzaCierre) {
      this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE BALANZA DE CIERRE ' + this.anioSeleccionado?.Id);
    } else {
      this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE BALANZA XML SAT AL ' + this.rangoFechas);
    }
    return this.anioSeleccionado!;
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Num1') {
      this.mes1Seleccionado = entity;
    } else if (field == 'Num2') {
      this.anioSeleccionado = entity;
    } else if (field == 'Num3') {
      this.nivelEnviarSeleccionado = entity;
    } else if (field == 'Num4') {
      this.tipoEnvioSeleccionado = entity;
    }
    this.myForm.controls[field].setValue(entity.Clave);
  }



  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteBalanzaElectronicaSAT');
  }

  CargarConfiguracion() {


  }





}
