import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokensService } from '../../services/tokens.service';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { EventsService } from 'src/app/service/events.service';
import { BaseService } from 'src/app/service/base.service';
import { UtilsService } from 'src/app/service/utils.service';
import { TokenCredito } from 'src/app/configuracion/interfaces/tokens.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import Swal from 'sweetalert2';
import { Result } from 'src/app/auth/interfaces';

@Component({
  selector: 'app-token-credito.page',
  templateUrl: './token-credito.page.component.html',
  styleUrls: ['./token-credito.page.component.scss']
})
export class TokenCreditoPageComponent {
  private frmBuilder = inject(FormBuilder);
  private tokenService = inject(TokensService);
  private modalService = inject(ModalService)
  private eventsService = inject(EventsService);
  private baseService = inject(BaseService);
  private utilsService = inject(UtilsService);

  searchConfigurationCliente: SearchConfiguration | null = null;

  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<TextBoxComponent>;
  @ViewChild('txtImporte')
  public txtImporte!: ElementRef<NumberBoxComponent>;

  @ViewChild('ctrlBusqueda')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  token: TokenCredito = {
    sucursal: { Id: 0 },
    usuarioUsa: { Id: 0 },
    cliente: { Id: 0 },
    importeAutorizado: 0
  } as TokenCredito

  public frmTokenCreditos: FormGroup = this.frmBuilder.group({
    Sucursal: [[Validators.required]],
    Usuario: [[Validators.required]],
    Cliente: ['', [Validators.required]],
    Importe: [0, [Validators.required]],
    Contrasena: ['', [Validators.required]],
  });

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })
      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  ngOnInit() {
    let usuario = this.utilsService.getUserLogged();
    this.searchConfigurationCliente = this.modalService.GetSearchConfiguration("Cliente", `Empresa.Id = ${usuario.empresa!.numero}`);
  }

  generarToken() {
    this.token = {
      ...this.token,
      usuarioGenera: {
        ...this.token.usuarioGenera,
        Contrasena: this.frmTokenCreditos.controls["Contrasena"].value
      } as any,
      importeAutorizado: this.frmTokenCreditos.controls["Importe"].value
    }

    if (this.token.importeAutorizado <= 0) {
      Swal.fire({ text: "El importe autorizado debe ser mayor a cero.", icon: 'error', confirmButtonText: "Aceptar" });
      return;
    }

    this.tokenService.generarTokenCredito(this.token).subscribe({
      next: (response: Result) => {
        Swal.fire({ text: response.message, icon: response.success ? 'success' : 'error', confirmButtonText: "Aceptar" });
        if (response.success) {
          this.frmTokenCreditos.reset();
        }
      },
      error: (err: Error) => {
        console.error(err)
      }
    })
  }

  selectedCombo(event: any, entidad: string) {
    if (entidad === "sucursal") {
      this.token = { ...this.token, sucursal: { Id: event.Id } }
    } else {
      this.token = { ...this.token, usuarioUsa: { Id: event.Id } as any }
    }
  }

  searchCustomer(clave: string) {
    let usuario = this.utilsService.getUserLogged();
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.baseService.getClientePorClave(usuario.empresa!.numero, Number(clave)).subscribe((cliente: Cliente) => {
      if (cliente) {
        setTimeout(() => {
          this.frmTokenCreditos.controls["Cliente"].setValue(cliente.Clave + " " + cliente.Nombre);
        }, 0);
        const txtImporte: any = this.txtImporte;
        txtImporte.tagInput.nativeElement.focus();
        this.token = { ...this.token, cliente: { Id: cliente.Id } as any }
      }
      this.eventsService.publish('home:isLoading', { isLoading: false });
    })
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusqueda;
    this.modalService.openModal(b, (e: any) => {
      this.searchCustomer(e.Clave);
    }, 'lg')
  }

  elementoSeleccionado(elemento: any) {
    this.searchCustomer(elemento.Clave);
  }

  enterCliente() {
    const txt: any = this.txtCliente;
    if (txt.tagInput.nativeElement.value) {
      let value = txt.tagInput.nativeElement.value.split(" ")[0];
      this.searchCustomer(value);
    } else {
      // setTimeout(() => {
      //   const txtImporte: any = this.txtImporte;
      //   txtImporte.tagInput.nativeElement.focus();
      // }, 0);
    }
  }
}
