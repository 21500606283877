import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { CuentaBancaria, c_Banco } from '../../interfaces/c_banco.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Providers } from '../../interfaces/prometo.interface';

@Component({
  selector: 'app-cuentas-bancarias-page',
  templateUrl: './cuentas-bancarias-page.component.html',
  styles: [
  ]
})
export class CuentasBancariasPageComponent {
  saving: boolean = false;
  showBanks: boolean = false;
  list: Providers[] = [];
  public itemsCombo: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Moneda: [''],
    CodigoBancoPrometeo: [''],
    Usuario: [''],
    Password: [''],
    Banco: [{} as c_Banco],
    NumeroCuenta: [''],
    Baja: [false]
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: CuentaBancaria | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private http: HttpClient) {
  }



  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: CuentaBancaria) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: CuentaBancaria): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  closeBanks() {
    this.list = [];
    this.showBanks = false;
  }
  selectBank(b: Providers) {
      this.myForm.controls["CodigoBancoPrometeo"].setValue(b.code);
      this.closeBanks();
  }

  getBanks() {
    this.saving = true;
    this.http.get<Providers[]>(`${this.baseUrl}/Tesoreria/ObtenerBancos`).subscribe((lista) => {
      this.saving = false;
      if (lista.length > 0) {
        this.list = lista;
        this.showBanks = true;
      }
    });
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("CuentaBancaria").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }


  selectedItem(ent: any, tipo: string) {
    this.myForm.controls[tipo].setValue(ent);
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }


}
