<app-container-base subTitle="Proceso de cancelación interno de la empresa" [hasCounter]="false"
  [activeButtons]="activeButtons" icon="fa-envelope">
  <div class="screen-content">
    <form autocomplete="off">

      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#cancelacion-default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true"
            role="tab">
            <span class="d-sm-block d-none">
              Por Cancelar
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#cancelacion-default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Consultar Cancelaciones</span>
          </a>
        </li>
      </ul>
      <div class="tab-content panel rounded-0 me-2 ms-2 mt-0">
        <div id="cancelacion-default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Listado de Documentos listos para aceptar o rechazar su cancelación interna">
            <div class="panel-content">
              <div class="pt-2 pb-2 text-start" style="width:100%;">
                <a (click)="cargarCancelaciones()" class="btn btn-success btn-rounded px-4 rounded-pill"><i
                    class="fa fa-arrow-rotate-left fa-lg me-2 ms-n2 text-success-900"></i>Actualizar</a>
              </div>
              <div style="min-height: 500px; max-height: 601px;border: 1px solid;">
                <ng-scrollbar style="height: 490px; width:1000px; border-bottom: 1px solid #AAAAAA;">
                  <table class="blueTable" style="width:1000px;">
                    <thead style="position: sticky;top:0;">
                      <tr>
                        <th style="width: 60px;text-align:center;">Sucursal</th>
                        <th style="width: 60px;text-align:center;">Documento</th>
                        <th style="width: 80px;text-align:center;">SerieFolio</th>
                        <th style="width: 70px;text-align:center;">Emisión</th>
                        <th style="width: 80px;text-align:right;padding-right: 5px;">Total</th>
                        <th style="width: 100px;text-align:center;">Solicita</th>
                        <th style="width: 90px;text-align:center;">Fecha Solicita</th>
                        <th style="width: 100px;">Motivo Interno</th>
                        <th style="width: 100px;text-align:center;">Motivo SAT</th>
                        <th style="width: 100px;text-align:center;">Sustituye</th>
                        <th style="width:40px;text-align:center;">CAN</th>
                        <th style="width:40px;text-align:center;">RECH</th>
                        <th style="width:40px;text-align:center;">IMP</th>
                        <th style="width:40px;text-align:center;">INV</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of source; let index = index;">
                        <td style="text-align:center;">{{item.sucursal.nombre}}</td>
                        <td style="text-align:center;">{{item.documento.nombre}}</td>
                        <td style="text-align:center;">{{item.serieString}}{{item.folio}}</td>
                        <td style="text-align:center;">{{item.fechaEmision}}</td>
                        <td style="text-align:right;padding-right: 5px;">{{item.total | number:'1.2'}}</td>
                        <td><span
                            [ngbTooltip]="item.usuarioSolicitaCancelacion.nombre">{{item.usuarioSolicitaCancelacion.nombre}}</span>
                        </td>
                        <td style="text-align:center;">{{item.fechaSolicitoCancelacion}}</td>
                        <td><span
                            [ngbTooltip]="item.motivoCancelacionSolicitud">{{item.motivoCancelacionSolicitud}}</span>
                        </td>
                        <td style="text-align:center;">{{(item.motivoCancelacionSAT ?
                          (item.motivoCancelacionSAT.nombre):'N/A')}}</td>
                        <td>{{item.uuidSustituye}}</td>
                        <td style="text-align:center;"><a (click)="cancelar(item)" ngbTooltip="Procesar Cancelación"
                            class="btn btn-success btn-icon btn-circle btn-sm"><i class="fa fa-thumbs-up"></i></a></td>
                        <td style="text-align:center;"><a (click)="rechazarCancelacion(item.id)" ngbTooltip="Rechazar Cancelación"
                            class="btn btn-yellow btn-icon btn-circle btn-sm"><i class="fa fa-hand"></i></a></td>
                        <td style="text-align:center;"><a (click)="print(item.idVenta)" ngbTooltip="Imprimir"
                            class="btn btn-blue btn-icon btn-circle btn-sm"><i class="fa fa-print"></i></a></td>
                        <td style="text-align:center;"><a (click)="liberar(item.idVenta, item.id)" ngbTooltip="Liberar Inventario"
                            class="btn btn-orange btn-icon btn-circle btn-sm"><i class="fa fa-cart-arrow-down"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-scrollbar>
              </div>
            </div>
          </app-panel-base>
        </div>
        <div id="cancelacion-default-tab-2" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Listado de Documentos ya cancelados o rechazados">
            <div class="panel-content">

            </div>
          </app-panel-base>
        </div>
      </div>


    </form>
  </div>
</app-container-base>
