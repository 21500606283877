<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Rol"
  title="Puestos" icon="fa fa-building" subTitle="Administra la configuración de roles..." [printManual]="true"
  (onClickBarButton)="clickBarButton($event)">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <div class="form-group row">
            <div class="col-md-4">
              <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
                [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
                [label]="'Rol'" entityName="Rol" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="3"
                [widthLabelColumns]="6" />
            </div>
            <div class="col-md-4">
              <component-ui-check-box label='Baja' [widthLabelColumns]="2" formControlName="Baja" [widthTextColumns]="4"
                [tabIndex]="2" />
            </div>
          </div>
          <components-ui-text-box label='Nombre' [required]="true" formControlName="Nombre" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <components-ui-text-box label='Documentos Ventas' formControlName="ComprobantesVentasPermisos"
            [enableIcon]="true" [Entidad]="'DocumentoVenta'" [tabIndex]="4" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxLength]="500" />

          <components-ui-text-box label='Documentos Compras' formControlName="ComprobantesVentasCompras" [tabIndex]="5"
            [enableIcon]="true" [Entidad]="'DocumentoCompra'" [widthTextColumns]="3" [widthLabelColumns]="2"
            [maxLength]="500" />

          <components-ui-text-box label='Series Ventas' formControlName="SeriesVentas" [enableIcon]="true"
            [Entidad]="'SerieVenta'" [tabIndex]="6" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="500" />

          <components-ui-text-box label='Series Compras' formControlName="SeriesCompras" [enableIcon]="true"
            [Entidad]="'SerieCompra'" [tabIndex]="7" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="500" />

          <component-ui-check-box label='Es gerente supervisor' [widthLabelColumns]="2"
            formControlName="EsGerenteSupervisor" [widthTextColumns]="4" [tabIndex]="8" />
        </div>
      </app-panel-base>

      <app-panel-base title="Permisos asignados">
        <div class="panel-content">

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-money-bill fa-lg fa-fw me-1"></i>
            Ventas
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='Reimprimir documentos' [widthLabelColumns]="8"
                formControlName="ReimprimirComprobantesVentas" [widthTextColumns]="4" [tabIndex]="9" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='Bloquear folios' [widthLabelColumns]="8" formControlName="BloquearFolio"
                [widthTextColumns]="4" [tabIndex]="10" />
            </div>
            <div class="col-md-3">
              <component-ui-check-box label='Desbloquear cortes' [widthLabelColumns]="7"
                formControlName="DesbloquearCortes" [widthTextColumns]="4" [tabIndex]="11" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='Permitir Cancelar' [widthLabelColumns]="8"
                formControlName="PermitirCancelarVentas" [widthTextColumns]="4" [tabIndex]="10" />
            </div>
          </div>
          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fas fa-bus-simple fa-lg fa-fw me-1"></i>
            Entregas a domicilio
          </div>
          <div class="col-md-3">
            <component-ui-check-box label='Puede Cancelar Salidas por Ventas' [widthLabelColumns]="8" formControlName="PuedeCancelarSalidaPorVenta"
              [widthTextColumns]="4" [tabIndex]="10"  helpLine="Habilite esta opción si permitirá cancelar Salidas por Venta en el Módulo de Entregas a Domicilio"/>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-user-clock fa-lg fa-fw me-1"></i>
            Cuentas Por Cobrar
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='No activar opciones crédito' [widthLabelColumns]="8"
                formControlName="NoActivarOpcionesCredito" [widthTextColumns]="4" [tabIndex]="12" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-truck-ramp-box fa-lg fa-fw me-1"></i>
            Inventarios
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='No ver costos' [widthLabelColumns]="8" formControlName="NoVerCostos"
                [widthTextColumns]="4" [tabIndex]="13" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-scale-unbalanced-flip fa-lg fa-fw me-1"></i>
            Contabilidad
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='Reemplazar pólizas' [widthLabelColumns]="8"
                formControlName="ReemplazarPolizas" [widthTextColumns]="4" [tabIndex]="14" />
            </div>
            <div class="col-md-3">
              <component-ui-check-box label='No aplica fecha proceso' [widthLabelColumns]="7"
                formControlName="NoAplicarFechaProceso" [widthTextColumns]="4" [tabIndex]="15" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-chart-pie fa-lg fa-fw me-1"></i>
            Tableros
          </div>


          <component-ui-check-box label='Ver Tablero General' [widthLabelColumns]="2" formControlName="VerTableroVentas"
            [widthTextColumns]="4" [tabIndex]="16" />
          <component-ui-check-box label='Ver Tablero de PowerBI' [widthLabelColumns]="2"
            formControlName="VerTableroPowerBI" [widthTextColumns]="4" [tabIndex]="16" />

          <components-ui-text-box label='URL' [enabled]="myForm.value.VerTableroPowerBI" formControlName="UrlPowerBI"
            [tabIndex]="17" [widthTextColumns]="10" [widthLabelColumns]="2" [maxLength]="1000" />
          <component-ui-check-box label='Ver Tablero de Gastos' [widthLabelColumns]="2"
            formControlName="VerTableroGastos" [widthTextColumns]="4" [tabIndex]="18" />

          <component-ui-check-box label='Ver Tablero de Mis Ventas' [widthLabelColumns]="2"
            formControlName="VerTableroMisVentas" [widthTextColumns]="4" [tabIndex]="19" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-chart-pie fa-lg fa-fw me-1"></i>
            Contabilidad
          </div>

          <component-ui-check-box label='Ver Tablero Estado Resultados' [widthLabelColumns]="2"
            formControlName="VerEstadoResultados" [widthTextColumns]="4" [tabIndex]="20" />

          <component-ui-check-box label='Ver Tablero Balance' [widthLabelColumns]="2" formControlName="VerBalance"
            [widthTextColumns]="4" [tabIndex]="21" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-chart-pie fa-lg fa-fw me-1"></i>
            Chat
          </div>
          <component-ui-check-box label='Ver Chat' [widthLabelColumns]="2" formControlName="VerChat"
            [widthTextColumns]="4" [tabIndex]="22" />

          <component-ui-check-box label='Puede envíar mensaje' [enabled]="myForm.value.VerChat" [widthLabelColumns]="2"
            formControlName="EnviarMensajesChat" [widthTextColumns]="4" [tabIndex]="23" />


        </div>


      </app-panel-base>
      <app-panel-base title="Permisos al menú de COMPLIT">
        <div class="panel-content">
          <a (click)="todos(true)" class="btn btn-primary">Todos</a>
          <a (click)="todos(false)" class="btn btn-primary" style="margin-left: 5px;">Ninguno</a>
          <div class="tree-container-rol bg-white">
            <ng-scrollbar style="height: 450px;width: 100%;">
              <div *ngFor="let item of menus; let i = index" style="padding-bottom: 5px;">
                <i (click)="onSelect(item)" [class]="getCaret(item)"
                  style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                <input (click)="seleccionar(item)" [checked]="item.Seleccionado" class="form-check-input"
                  type="checkbox" style="margin-left: 4px;" />
                <i *ngIf="item.Icono" style="margin-left: 5px; width:18px;text-align: center;"
                  class="fas fa-{{item.Icono}} fa-lg"></i>
                <a *ngIf="item.Img"><img style="width: 17px;margin-left: 6px;" src="{{item.Img}}"></a>
                <span class="text-dark tree-item" (click)="onSelect(item)"
                  style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item.Nombre}}</span>

                <div *ngIf="item.IsExpanded" class="animate__animated animate__fadeIn" style="padding-left: 50px;">
                  <div *ngFor="let item2 of item.SubMenus;">
                    <i (click)="onSelect(item2)" [class]="getCaret(item2, item2.SubMenus!.length==0)"
                      style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                    <input (click)="seleccionar(item2)" [checked]="item2.Seleccionado" class="form-check-input mb-1"
                      type="checkbox" style="margin-left: 4px;" />
                    <span class="text-dark tree-item" (click)="onSelect(item2)"
                      style="margin-left: 5px;padding-top: 3px;cursor: pointer;width:350px;">{{item2.Nombre}}</span>
                    <i *ngIf="item2.SubMenus!.length==0" class="fas fa-lg fa-fw fa-trash"
                      style="margin-left:4px;cursor: pointer;"></i>
                    <i *ngIf="item2.SubMenus!.length==0" class="fas fa-lg fa-fw fa-floppy-disk"
                      style="margin-left:4px;cursor: pointer;"></i>
                    <div *ngIf="item2.IsExpanded" class="animate__animated animate__fadeIn" style="padding-left: 50px;">
                      <div *ngFor="let item3 of item2.SubMenus;">
                        <i *ngIf="item3.SubMenus!.length>0"
                          style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                        <input (click)="seleccionar(item3)" [checked]="item3.Seleccionado" class="form-check-input mb-1"
                          type="checkbox" style="margin-left: 4px;" />
                        <span class="text-dark tree-item"
                          style="margin-left: 5px;padding-top: 3px;cursor: pointer;width: 350px;">{{item3.Nombre}}</span>
                        <i *ngIf="item3.SubMenus!.length==0" class="fas fa-lg fa-fw fa-trash"
                          style="margin-left:4px;cursor: pointer;"></i>
                        <i *ngIf="item3.SubMenus!.length==0" class="fas fa-lg fa-fw fa-floppy-disk"
                          style="margin-left:4px;cursor: pointer;"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>


        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
