<div id="sidebar-right" class="app-sidebar app-sidebar-end animate__animated animate__bounceInRight"
  [attr.data-bs-theme]="getMode">

  <div class="p-1">
    <div class="col-lg-12">
      <app-chat-page [enviarMensajeChat]="enviarMensajeChat" />
    </div>
  </div>

</div>
<div class="app-sidebar-bg app-sidebar-end">

</div>
<div class="app-sidebar-mobile-backdrop app-sidebar-end">
  <a href="javascript:;" (click)="toggleAppSidebarEndMobile()" class="stretched-link"></a>
</div>
