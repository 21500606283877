import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Editor } from 'ngx-editor';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-compra-comentario-global-page',
  templateUrl: './compra-comentario-global-page.component.html',
  styles: [
  ]
})
export class CompraComentarioGlobalPageComponent implements OnInit {

  public myForm: FormGroup = this.fb.group({
    Comentario: ['']
  });

  @Input()
  html: string = "";
  editor: Editor = new Editor;

  constructor(
    private fb: FormBuilder,
    private mService: ModalService) {
  }

  ngOnInit(): void {
    this.myForm.controls["Comentario"].setValue(this.html);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  accept() {
    this.mService.closeModal(this.myForm.value.Comentario);
  }

}
