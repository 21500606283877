import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FacturacionService } from '../../services/facturacion.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ModalService } from 'src/app/service/modal.service';
import { GuiCellEdit, GuiColumnAlign } from '@generic-ui/ngx-grid';
import { Precio } from '../../interfaces/precio.interface';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { onErrorResumeNext } from 'rxjs';
import Swal from 'sweetalert2';
import { ListaPrecio } from '../../interfaces/listaprecio.interface';
import { environment } from 'src/environments/environment';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import * as moment from 'moment';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-carga-lista-precio',
  templateUrl: './carga-lista-precio.component.html',
  styles: [
  ]
})
export class CargaListaPrecioComponent implements OnInit {
  private eventsService = inject(EventsService);
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  saving: boolean = false;
  loading: boolean = false;
  source: Array<any> = [];
  file: any;
  enviarFocoArribaCostoRep: boolean = false;
  enviarFocoAbajoCostoRep: boolean = false;

  enviarFocoArribaDivisor: boolean = false;
  enviarFocoAbajoDivisor: boolean = false;

  enviarFocoArribaPrecioUtil: boolean = false;
  enviarFocoAbajoPrecioUtil: boolean = false;

  enviarFocoArribaPrecioMinimo: boolean = false;
  enviarFocoAbajoPrecioMinimo: boolean = false;

  enviarFocoArribaPrecioMaximo: boolean = false;
  enviarFocoAbajoPrecioMaximo: boolean = false;

  enviarFocoDivisor: boolean = false;
  enviarFocoCostoRep: boolean = false;
  enviarFocoPrecioUtil: boolean = false;
  enviarFocoPrecioMinimo: boolean = false;
  enviarFocoPrecioMaximo: boolean = false;

  info: UserLogged = {} as UserLogged;
  private readonly baseUrl: string = environment.baseUrlApi;
  idUnico: string = '';
  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;

  @ViewChild('txtClase')
  public txtClase!: ElementRef<any>;

  @ViewChild('txtCostoKilo')
  public txtCostoKilo!: ElementRef<any>;

  @ViewChild('modalAgregar')
  public modalAgregar!: ElementRef<HTMLElement>;


  blurcostoreposicion(e: any) {
    e.target.classList.remove("focus-editor-grid");
    let index = this.indexEditing;
    if (this.enviarFocoArribaCostoRep) {
      index = index + 1;
    }
    if (this.enviarFocoAbajoCostoRep) {
      index = index - 1;
    }
    let item = this.source[index];
    let costoRep = parseFloat(e.target.value);
    if (!costoRep) {
      e.target.value = "0.00"
      costoRep = 0;
    }

    item.costoReposicion = costoRep;
    this.source[index] = { ...item };
    this.source = [...this.source];
    if (this.enviarFocoArribaCostoRep || this.enviarFocoAbajoCostoRep) {
      this.enviarFocoArribaCostoRep = false;
      this.enviarFocoAbajoCostoRep = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 4);
      }, 50);
    }
    if (this.enviarFocoDivisor) {
      this.enviarFocoDivisor = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 5);
      }, 50);
    }
  }

  openAgregar() {
    const b: any = this.modalAgregar;
    this.ms.openModal(b, (e: any) => {

    }, 'sm')
  }

  blurdivisor(e: any) {
    e.target.classList.remove("focus-editor-grid");
    let index = this.indexEditing;
    if (this.enviarFocoArribaDivisor) {
      index = index + 1;
    }
    if (this.enviarFocoAbajoDivisor) {
      index = index - 1;
    }
    let item = this.source[index];
    let divisor = parseFloat(e.target.value);
    if (!divisor) {
      e.target.value = "0.00"
      divisor = 0;
    }

    item.divisor = divisor;
    this.source[index] = { ...item };
    this.source = [...this.source];
    if (this.enviarFocoArribaDivisor || this.enviarFocoAbajoDivisor) {
      this.enviarFocoArribaDivisor = false;
      this.enviarFocoAbajoDivisor = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 5);
      }, 50);
    }

    if (this.enviarFocoCostoRep) {
      this.enviarFocoCostoRep = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 4);
      }, 50);
    }
    if (this.enviarFocoPrecioUtil) {
      this.enviarFocoPrecioUtil = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 6);
      }, 50);
    }


  }

  blurprecioutilidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    let index = this.indexEditing;
    if (this.enviarFocoArribaPrecioUtil) {
      index = index + 1;
    }
    if (this.enviarFocoAbajoPrecioUtil) {
      index = index - 1;
    }
    let item = this.source[index];
    let precioUtilidad = parseFloat(e.target.value);
    if (!precioUtilidad) {
      e.target.value = "0.00"
      precioUtilidad = 0;
    }

    item.precioUtilidad = precioUtilidad;
    this.source[index] = { ...item };
    this.source = [...this.source];
    if (this.enviarFocoArribaPrecioUtil || this.enviarFocoAbajoPrecioUtil) {
      this.enviarFocoArribaPrecioUtil = false;
      this.enviarFocoAbajoPrecioUtil = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 6);
      }, 50);
    }

    if (this.enviarFocoDivisor) {
      this.enviarFocoDivisor = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 5);
      }, 50);
    }
    if (this.enviarFocoPrecioMinimo) {
      this.enviarFocoPrecioMinimo = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 7);
      }, 50);
    }
  }

  blurpreciomimo(e: any) {
    e.target.classList.remove("focus-editor-grid");
    let index = this.indexEditing;
    if (this.enviarFocoArribaPrecioMinimo) {
      index = index + 1;
    }
    if (this.enviarFocoAbajoPrecioMinimo) {
      index = index - 1;
    }
    let item = this.source[index];
    let precioMinimo = parseFloat(e.target.value);
    if (!precioMinimo) {
      e.target.value = "0.00"
      precioMinimo = 0;
    }

    item.precioMinimo = precioMinimo;
    this.source[index] = { ...item };
    this.source = [...this.source];
    if (this.enviarFocoArribaPrecioMinimo || this.enviarFocoAbajoPrecioMinimo) {
      this.enviarFocoArribaPrecioMinimo = false;
      this.enviarFocoAbajoPrecioMinimo = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 7);
      }, 50);
    }

    if (this.enviarFocoPrecioUtil) {
      this.enviarFocoPrecioUtil = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 6);
      }, 50);
    }

    if (this.enviarFocoPrecioMaximo) {
      this.enviarFocoPrecioMaximo = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 8);
      }, 50);
    }

  }

  blurpreciomaximo(e: any) {
    e.target.classList.remove("focus-editor-grid");
    let index = this.indexEditing;
    if (this.enviarFocoArribaPrecioMaximo) {
      index = index + 1;
    }
    if (this.enviarFocoAbajoPrecioMaximo) {
      index = index - 1;
    }
    let item = this.source[index];
    let precioMaximo = parseFloat(e.target.value);
    if (!precioMaximo) {
      e.target.value = "0.00"
      precioMaximo = 0;
    }

    item.precioMaximo = precioMaximo;
    this.source[index] = { ...item };
    this.source = [...this.source];
    if (this.enviarFocoArribaPrecioMaximo || this.enviarFocoAbajoPrecioMaximo) {
      this.enviarFocoArribaPrecioMaximo = false;
      this.enviarFocoAbajoPrecioMaximo = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 8);
      }, 50);
    }

    if (this.enviarFocoPrecioMinimo) {
      this.enviarFocoPrecioMinimo = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 7);
      }, 50);
    }
    if (this.enviarFocoCostoRep) {
      this.enviarFocoCostoRep = false;
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 4);
      }, 50);
    }

  }

  deleteRow(index: number, idEliminar: number) {
    const nombreLista = this.source[index].listaPrecio?.nombre;
    const nombreProducto = this.source[index].producto?.nombre;
    this.eventsService.publish('home:showAlert', {
      message: `¿Desea eliminar?<br><b style='color: red; font-weight: bold;'>Producto: ${nombreProducto}</b><br><b style='color: red; font-weight: bold;'>Lista de Precio${nombreLista}</b>`,
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.fServices.eliminarPrecio(idEliminar).subscribe((result) => {
            if (result.success) {
              this.searchPrices();
            } else {
              this.eventsService.publish('home:showAlert', { message: `Ocurrio un error: ${result.message}`, cancelButton: false });
              this.eventsService.publish('home:isLoading', { isLoading: false });
            }
          });

        }
      }
    });


  }

  keyDownCostoRep(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "74") {
      e.preventDefault();
      let costoRep = parseFloat(e.target.value);
      if (!costoRep) {
        e.target.value = "0.00"
        costoRep = 0;
      }
      this.source.map((item: Precio, i) => {
        if (i >= index) {
          item.costoReposicion = costoRep;
        }
        return item;
      })
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 4);
      }, 50);
    }
  }

  keyDownDivisor(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "74") {
      e.preventDefault();
      let divisor = parseFloat(e.target.value);
      if (!divisor) {
        e.target.value = "0.00"
        divisor = 0;
      }
      this.source.map((item: Precio, i) => {
        if (i >= index) {
          item.divisor = divisor;
        }
        return item;
      })
      this.calcularPrecios();
      setTimeout(() => {
        this.initEditor(this.indexEditing, 5);
      }, 50);
    }
  }


  calcularPrecios() {
    this.source.map((item: Precio) => {
      if (item.costoReposicion > 0 && item.divisor > 0) {
        item.precioUtilidad = item.precioMaximo = item.precioMinimo = Number((item.costoReposicion / item.divisor).toFixed(2));
      }
      return item;
    })

    this.source = [...this.source];
  }

  up(e: any, celda: number, esEscape: boolean = false) {
    if (this.source.length > 0) {
      let row = null;
      if (e.keyCode == "38" || esEscape) {
        e.preventDefault();
        if (this.indexEditing > 0) {
          this.indexEditing = this.indexEditing - 1;
          row = document.getElementById(`row-search${this.idUnico}_${this.indexEditing}`)
        }
      }
      if (row) {
        row.scrollIntoView({ block: "center" });
        if (celda == 4) {
          this.enviarFocoArribaCostoRep = true;
        } else if (celda == 5) {
          this.enviarFocoArribaDivisor = true;
        } else if (celda == 6) {
          this.enviarFocoArribaPrecioUtil = true;
        } else if (celda == 7) {
          this.enviarFocoArribaPrecioMinimo = true;
        } else if (celda == 8) {
          this.enviarFocoArribaPrecioMaximo = true;
        }
        e.target.blur();
      }
      return;
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    if (this.source.length > 0) {
      let row = null;
      if (e.keyCode == "40") {
        //e.preventDefault();
        if (this.indexEditing < this.source.length - 1) {
          this.indexEditing = this.indexEditing + 1;
          row = document.getElementById(`row-search${this.idUnico}_${this.indexEditing}`)
        }
      }
      if (row) {
        row.scrollIntoView({ block: "center" });

        if (celda == 4) {
          this.enviarFocoAbajoCostoRep = true;
        } else if (celda == 5) {
          this.enviarFocoAbajoDivisor = true;
        } else if (celda == 6) {
          this.enviarFocoAbajoPrecioUtil = true;
        } else if (celda == 7) {
          this.enviarFocoAbajoPrecioMinimo = true;
        } else if (celda == 8) {
          this.enviarFocoAbajoPrecioMaximo = true;
        }
        e.target.blur();
      }
      return;
    }
  }

  entercostoreposicion(e: any) {
    this.enviarFocoDivisor = true;
    e.target.blur();
  }

  enterdivisor(e: any) {
    this.enviarFocoPrecioUtil = true;
    e.target.blur();
    // this.initEditor(this.indexEditing, 6);
  }

  enterprecioutilidad(e: any) {
    this.enviarFocoPrecioMinimo = true;
    e.target.blur();
  }
  enterpreciomimo(e: any) {
    this.enviarFocoPrecioMaximo = true;
    e.target.blur();
  }

  enterpreciomaximo(e: any) {
    if ((this.indexEditing + 1) < this.source.length) {
      this.indexEditing += 1;
      this.enviarFocoCostoRep = true;
      e.target.blur();
    }
  }

  setIndexEdit(index: number, e: any, item: Precio) {
    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  escapecostoreposicion(e: any) {
    if (this.indexEditing == 0) {
      setTimeout(() => {
        const txt: any = this.txtCostoKilo;
        txt.tagInput.nativeElement.focus()
      }, 100);
    } else {
      this.up(e, 4, true);
    }
  }

  escapeprecioutilidad(e: any) {
    this.enviarFocoDivisor = true;
    e.target.blur();
  }

  escapepreciomimo(e: any) {
    this.enviarFocoPrecioUtil = true;
    e.target.blur();
  }
  escapepreciomaximo(e: any) {
    this.enviarFocoPrecioMinimo = true;
    e.target.blur();
  }

  escapedivisor(e: any) {
    this.enviarFocoCostoRep = true;
    e.target.blur();
  }

  setProperty(index: number, value: number, prop: string) {
    this.source.map((item: any, i) => {
      if (i >= index) {
        item[prop] = value;
      }
    })
    this.source = [...this.source];
  }


  setColumnEditor() {
    const elems: any = document.getElementById("divCargaPrecios")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement, indexRow: number) => {
      renglon.childNodes.forEach((nodeRow: any) => {
        let colNum = -1;
        nodeRow.childNodes.forEach((nodeColumna: any) => {
          if (nodeColumna.childNodes && nodeColumna.className) {
            colNum++;
            nodeColumna.childNodes.forEach((nodoCelda: any) => {
              if (nodoCelda.className == "gui-cell-edit-mode ng-star-inserted") {
                this.columnEditing = colNum;
                const elem: any = nodeColumna.childNodes[1].childNodes[0];
                elem.addEventListener("keydown", (e: any) => {
                  this.navigateColumns = (e.keyCode == 13);
                  // if (e.ctrlKey && e.keyCode == 74) {
                  //   e.preventDefault();
                  //   if (this.columnEditing == 5) {
                  //     this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'costoReposicion');
                  //   }
                  //   if (this.columnEditing == 6) {
                  //     this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'divisor');
                  //   }
                  //   if (this.columnEditing == 7) {
                  //     this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'protector');
                  //   }
                  //   if (this.columnEditing == 8) {
                  //     this.setProperty(this.indexEditing, parseFloat(elem.children[0].children[0].value), 'precioBase');
                  //   }
                  // }

                });
              }
            });
          }
        });
      })
    });
  }

  initEditor(row: number, col: number) {
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  getElemEditor(row: number, col: number) {

    let elem: any = null;
    const elems: any = document.getElementById("divCargaPrecios")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement) => {
      renglon.childNodes.forEach((nodeRow: any, indexRow: number) => {
        if (indexRow == row && !elem) {
          let colNum = -1;
          nodeRow.childNodes.forEach((nodeColumna: any) => {
            if (nodeColumna.childNodes && nodeColumna.className && !elem) {
              colNum++;
              if (col == colNum) {
                elem = nodeColumna;
              }
            }
          });
        }
      })
    });

    //const elem: any = document.getElementById("divFacturacion")!.querySelector(`[ng-reflect-column-index='${col}'][ng-reflect-row-index='${row}']`);
    return elem;
  }

  public myForm: FormGroup = this.fb.group({
    LP: [{} as ListaPrecio],
    Clasificacion: [''],
    IdEmpresa: [0],
    Tipo: [''],
    Familia1: [''],
    Familia2: [''],
    Familia3: [''],
    Familia4: [''],
    Familia5: [''],
    Codigos: [''],
    ExcluirCodigos: [''],
    ListaPrecio: [''],
    CostoKilo: [0]
  });

  constructor(private fServices: FacturacionService,
    private fb: FormBuilder,
    private fus: FileUploadService,
    private utileService: UtilsService,
    private http: HttpClient,
    private ms: ModalService, private uService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.myForm.controls["IdEmpresa"].setValue(this.info.empresa?.numero);
    this.idUnico = String(new Date().getTime() * 10000);
    setTimeout(() => {
      const txt: any = this.txtClase;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueCuenta(cuenta: FamiliaProducto) {
    this.myForm.controls["Familia1"].setValue("");
    this.myForm.controls["Familia2"].setValue("");
    this.myForm.controls["Familia3"].setValue("");
    this.myForm.controls["Familia4"].setValue("");
    this.myForm.controls["Familia5"].setValue("");
    if (cuenta) {
      const claves = cuenta.claveGeneral.split(' ');
      claves.forEach((element: any, index: number) => {
        this.myForm.controls["Familia" + (index + 1)].setValue(element);
      });
    }
  }



  onSelectedItem(ent: any, type: string) {
    this.myForm.controls[type].setValue(ent);
  }

  searchFile() {
    this.txtFile.nativeElement.click();
  }

  get getEnabled(): boolean {
    if (!this.myForm.value.LP) { return false; }
    if (this.myForm.value.LP.Id == 0) { return false; }
    if (!this.myForm.value.LP["Id"]) { return false; }

    console.log(this.myForm.value.LP)
    return true;
  }
  savePrices() {
    if (this.source.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `No hay precios para actualizar, verifique`, cancelButton: false });
      return;
    }
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarListaPrecio`, this.source).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {

        Swal.fire({ position: 'center', icon: 'success', title: 'Se actualizaron las listas', showConfirmButton: false, timer: 2000 }).then(() => {
          setTimeout(() => { this.searchPrices(); }, 50);
        });
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  downloadFileExample() {
    this.saving = true;
    return this.http.get(`${this.baseUrl}/Ventas/DecargarEjemploListas`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.saving = false;
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          this.eventsService.publish('home:showAlert', { message: "Tienes bloqueadas las descargas de esta página, habilitalas.", cancelButton: false });
        }
      }

    })
  }

  getDate(f: any) {
    return moment(f).format("DD/MM/YYYY");
  }

  enterKilo() {
    if (this.source.length == 0) {
      this.searchPrices();
    } else {
      this.indexEditing = 0;
      setTimeout(() => {
        this.initEditor(this.indexEditing, 4);
      }, 50);
    }
  }

  searchPrices() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.saving = true;
    this.indexEditing = -1;
    const ent = this.myForm.value;
    const params = new HttpParams().set("clasificacion", ent.Clasificacion)
      .set("tipo", ent.Tipo)
      .set("codigos", ent.Codigos)
      .set("excluirCodigos", ent.ExcluirCodigos)
      .set("listaPrecio", ent.ListaPrecio)
      .set("familia1", ent.Familia1)
      .set("familia2", ent.Familia2)
      .set("familia3", ent.Familia3)
      .set("familia4", ent.Familia4)
      .set("familia5", ent.Familia5);
    this.http.get<Precio[]>(`${this.baseUrl}/Ventas/ConsultarListas`, { params }).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.saving = false;
      this.source = lista;
      if (lista.length > 0) {
        this.indexEditing = 0;
        setTimeout(() => {
          this.initEditor(this.indexEditing, 4);
        }, 50);
      }
    })
  }

  exportData() {
    this.uService.exportarCSV("ListaPrecios", this.convertToCSV());
  }

  convertToCSV(): string {
    const array = this.source;
    let str = 'Empresa, Sucursal, Lista, Producto, Descripcion, CostoRep, Divisor, PrecioUtilidad, PrecioMinimo, PrecioMaximo, Global';
    str += "\r\n";
    this.source.forEach((item: any, index) => {
      str += `${item.empresa.clave},${item.sucursal.clave},${item.listaPrecio.clave},${item.producto.clave},${item.producto.nombre},${item.costoReposicion},${item.divisor},${item.precioUtilidad},${item.precioMinimo},${item.precioMaximo},${item.global}\r\n`;
    });
    return str;
  }

  sendImportData() {
    this.fServices.initPriceMigracion(this.file.name).subscribe((result) => {
      this.saving = false;
      if (result.success) {
        Swal.fire({ text: `Se actualizaron los precios.`, icon: 'success', })
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })
  }

  onchange(event: any) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null;
    if (this.file) {
      this.saving = true;
      this.fus.upload(this.file, 0, 'Utilerias/UploadFile', () => {
        this.sendImportData();
      });
    }
  }

}
