import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { SistemaService } from '../../services/sistema.service';
import { UsuarioContecado } from '../../interfaces/usuario-conectado.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import Swal from 'sweetalert2';
import { EventsService } from 'src/app/service/events.service';
import { SignalrService } from '../../../service/signalr.service';

@Component({
  selector: 'app-usuarios-conectados-page',
  templateUrl: './usuarios-conectados-page.component.html',
  styleUrls: ['./usuarios-conectados-page.component.scss'],
})
export class UsuariosConectadosPageComponent implements OnInit {

  private utilsService = inject(UtilsService);
  usuarioLoggeado!: UserLogged;

  usuariosContectados: UsuarioContecado[] = [];
  private sistemaService = inject(SistemaService);
  private eventsService = inject(EventsService);
  private signalrService = inject(SignalrService);
  ngOnInit(): void {
    this.getSesionesConectados();
    this.usuarioLoggeado = this.utilsService.getUserLogged();
    this.signalrService.addRefreshListSessionsListener();
    this.signalrService.subscribeToRefreshSessions(this.usuarioLoggeado.empresa!.numero);
    this.eventsService.subscribe(('home:refreshSessions'), (data) => {
      this.getSesionesConectados();
    });
  }

  sendMessage() {
    Swal.fire({
      title: "Escriba el Mensaje",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Envíar",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          this.sistemaService.sendMessage(result.value).subscribe(() => {
          })
        }
      }
    });
  }

  getSesionesConectados() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.sistemaService.getUsuariosConectados().subscribe((usuarios: UsuarioContecado[]) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.usuariosContectados = usuarios;
    });
  }

  cerrarSesion(sesion: UsuarioContecado) {
    Swal.fire({
      title: 'Eliminar Sesión',
      text: "¿Seguro que desea eliminar la sesión del usuario?",
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        sesion = {
          ...sesion,
          empresa: { id: sesion.empresa.id, nombre: sesion.empresa.nombre, clave: sesion.empresa.clave },
          sucursal: { id: sesion.sucursal.id, nombre: sesion.sucursal.nombre, clave: sesion.sucursal.clave },
          usuario: { id: sesion.usuario.id, login: sesion.usuario.login, clave: sesion.usuario.clave, nombre: sesion.usuario.nombre }
        }
        this.sistemaService.sendCloseSession(sesion).subscribe(() => {
          this.getSesionesConectados();
        });
      }
    });
  }

  cerrarTodasSesiones() {
    Swal.fire({
      title: 'Eliminar Sesiones',
      text: "¿Seguro que desea eliminar la sesión de todos los usuarios conectados?",
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.sistemaService.eliminarUsuariosConectados().subscribe({
          next: (result) => {
            console.log(result.message);
            this.getSesionesConectados();
          }, error: (err: Error) => {
            console.error(err);
          }
        });
      }
    });
  }
}
