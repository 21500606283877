<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; width: 720px; position: fixed;left: 30%;top:30%;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{this.tituloPantalla}}
    </span>
  </div>
  <form [formGroup]="myForm" autocomplete="off">
    <app-panel-base [title]="this.tituloPanel">
      <div class="panel-content">
        <div class="row">
          <div class="d-flex">
            <components-ui-text-box style="width:20%; margin-right:10px;" helpLine="" [maxLength]="500"
              [upperCase]="true" label='Serie' formControlName="serie" [tabIndex]="50" [topLabel]="true"
              [enabled]="false" [widthTextColumns]="12" [widthLabelColumns]="5" />
            <components-ui-number-box style="width:20%; " [topLabel]="true" formControlName="folio" label='Folio'
              [tabIndex]="51" [widthLabelColumns]="5" [widthTextColumns]="12" helpLine="" #txtFolioSalida />
            <div class="col-7 align-items-start text-end w-120px" #btnBuscarSalida>
              <a (click)="buscarSalida()" class="btn btn-blue mt-2"><i class="fa fa-magnifying-glass"></i>
                Buscar </a>
            </div>
          </div>
          <div *ngIf="this.factura && this.factura.conceptos.length > 0" style="text-align: left;margin: auto;">
            <div class="fs-13px lh-12 mb-2px fw-bold text-dark text-truncate">
              Serie y Folio Carga y entrega: {{this.factura.serie}}{{this.factura.folio}}
            </div>
            <div class="fs-13px lh-12 mb-2px fw-bold text-dark text-truncate">
              Fecha emision: {{this.factura.fechaEmision}}
            </div>
            <div class="fs-13px lh-12 mb-2px fw-bold text-dark text-truncate">
              Usuario elabora: {{this.usuarioElabora}}
            </div>
          </div>


        </div>
        <div class="mt-2">
          <ng-scrollbar style="height: 150px; width:810px; border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable" style="width:780px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 30px;">Eliminar</th>
                  <th style="width: 30px;">Cantidad</th>
                  <th style="width: 60px;">Unidad Medida</th>
                  <th style="width: 180px;">Producto</th>
                </tr>
              </thead>
              <tbody>
                <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                  *ngFor="let item of sourceGrid; let index = index;">
                  <td style="text-align: center;">
                    <i (click)="deleteRow(index)"
                      *ngIf="item.producto?.id>0 && !this.SoloConsulta && !this.EsCancelacion"
                      class="fa fa-rectangle-xmark fa-1x" style="color:red;font-weight: bold; cursor: pointer;"></i>
                  </td>
                  <td style="width: 60px;min-width:60px;max-width: 60px;">
                    <input (keydown)="keyDownCantidad($event)" (keydown.escape)="escapeCantidad($event)"
                      (keydown.arrowup)="up($event,1)" (keydown.arrowdown)="down($event,1)"
                      (keydown.enter)="enterCantidad($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_1' + idUnico" (blur)="blurCantidad($event)" type="number"
                      (focus)="focusCantidad(index, $event, item)" [style]="'width:100%;text-align:right;'"
                      [value]="item.producto ? item.cantidad: 0" />
                  </td>
                  <td class="text-dark">
                    <span style="margin-left: 3px;"> {{item.unidadMedidaDescripcion}}</span>
                  </td>
                  <td class="text-dark">
                    <span>{{item.productoNombre != "" ? item.productoNombre:""}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
        <div class="text-end mt-3">
          <button type="button" (click)="aceptar()" [tabIndex]="52"
            class="btn me-1 btn-success w-100px">Aceptar</button>
          <button type="button" (click)="closeModal()" [tabIndex]="53" class="btn btn-danger w-100px">Cancelar</button>
        </div>
      </div>
    </app-panel-base>
  </form>

  <ng-template #modalCancelSalidaPorVenta let-modal let-c="close">
    <opcion-cancelacion-salidaporventa />
  </ng-template>

</div>
