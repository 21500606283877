import { Component, ElementRef, HostListener, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { CustomDatepickerI18nService, I18n } from 'src/app/component-ui/services/custom-datepicker-i18n.service';
import { Prospecto } from 'src/app/crm/interfaces/prospecto.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { CustomDateParserFormatter } from '../visitas/visitas.component';
import { UtilsService } from 'src/app/service/utils.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { MedioEntradaProspecto } from '../../../crm/interfaces/prospecto.interface';
import { ComponentsUiService } from 'src/app/component-ui/services/components-ui.service';
import { Direccion } from '../../../configuracion/interfaces/direccion.interface';
import { EventsService } from 'src/app/service/events.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prospectos-mobile-page',
  templateUrl: './prospectos-mobile-page.component.html',
  styleUrls: ['./prospectos-mobile-page.component.scss'],
  providers: [
    I18n,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }
  ]
})
export class ProspectosMobilePageComponent {
  @ViewChild('ctrlBusquedaModal')
  public ctrlBusquedaModal!: ElementRef<HTMLElement>;

  @ViewChild('ctrlCodigoPostal')
  public ctrlCodigoPostal!: ElementRef<HTMLElement>;

  @ViewChild('ctrlEstados')
  public ctrlEstados!: ElementRef<HTMLElement>;

  entidad: Prospecto | any | undefined = { Id: 0 };
  searchingCP: boolean = false;
  cpList: any[] = [];
  estadosRepublica: any[] = [];

  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);
  private containerBaseService = inject(ContainerBaseService);
  private utilsService = inject(UtilsService);
  private componentsUiService = inject(ComponentsUiService);
  private eventsService = inject(EventsService);
  searchConfiguration: SearchConfiguration | null = null;

  public prospectoForm: FormGroup = this.fb.group({
    nombre: ['', Validators.required],
    propietario: [{ value: '', disabled: true }],
    correos: ['', Validators.required],
    numeroMovil: ['', Validators.required],
    medioEntrada: ['', Validators.required],
    estadoProspecto: ['', Validators.required],
    fechaAlta: [{ value: '', disabled: true }],
    observaciones: ['', Validators.required],
    dadoBaja: [false],
    cargo: [],
    RFC: [],
    razonSocial: [],
    pais: [{ value: '', disabled: true }],
    codigoPostal: ['', Validators.required],
    calle: [],
    noExterior: [],
    noInterior: [],
    colonia: [],
    ciudad: [],
    municipio: [],
    estado: [],
    telefono: [],
    referencias: [],
  });

  info: UserLogged = {} as UserLogged;

  lastClave: number = 0;
  ngOnInit() {
    this.getEstadosRepublica();
    this.info = this.utilsService.getUserLogged();
    this.containerBaseService.getNextNumber("Prospecto").subscribe({
      next: (last: string) => {
        this.lastClave = Number(last);
        this.initFormNewEntity()
      },
      error: (err: Error) => console.error(err)
    })

    this.containerBaseService.getCounter("Prospecto").subscribe({
      next: (count: number) => {
      },
      error: (err: Error) => console.error(err)
    })

    this.restarHeight(window.innerHeight);
  }

  initFormNewEntity() {
    let fechaAltaDate = new Date();
    let dia = this.padLeft(2, '0', fechaAltaDate.getDate());
    let mes = this.padLeft(2, '0', (fechaAltaDate.getMonth() + 1));
    let anio = fechaAltaDate.getFullYear();

    this.prospectoForm.reset({
      clave: this.lastClave,
      propietario: `${this.utilsService.padLeft(2, "0", this.info.clave)} ${this.info.nombreUsuario}`,
      fechaAlta: `${dia}/${mes}/${anio}`,
      pais: 'México'
    });

    this.entidad = {
      ...this.entidad,
      FechaAlta: fechaAltaDate,
      Sucursal: { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre, ClaveNombre: `${this.info.sucursal!.clave} ${this.info.sucursal!.nombre}` },
      Propietario: { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario }
    };
  }

  get getColumns(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })
      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  get getFilter(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.filter;
    }
    return "";
  }

  padLeft(maxLength: number, fillString: string, value: string | number) {
    return value.toString().padStart(maxLength, fillString);
  }

  entidadBusqueda: string = "";
  openSearch(entidad: string) {
    this.searchConfiguration = this.modalService.GetSearchConfiguration(entidad, "");
    this.entidadBusqueda = entidad;
    this.modalService.openModal(this.ctrlBusquedaModal, (e: any) => {
      if (e) {
        this.containerBaseService.getEntityById(entidad, e.Id).subscribe({
          next: (resp: any) => {
            let valueControl = `${this.utilsService.padLeft(2, "0", resp.Clave)} ${resp.Nombre}`;

            switch (entidad) {
              case "MedioEntradaProspecto":
                this.entidad.MedioEntrada = { Id: resp.Id };
                this.prospectoForm.controls['medioEntrada'].setValue(valueControl);
                break;
              case "EstadoProspecto":
                this.entidad.Estado = { Id: resp.Id };
                this.prospectoForm.controls['estadoProspecto'].setValue(valueControl);
                break;
              default:
                this.setInfoProspecto(resp);
                break;
            }
          }, error: (err: Error) => console.error(err)
        })
      }
    }, 'lg')
  }

  setInfoProspecto(infoEntidad: any) {
    let fechaAltaDate = (new Date(infoEntidad.FechaAlta));
    let dia = this.padLeft(2, '0', fechaAltaDate.getDate());
    let mes = this.padLeft(2, '0', (fechaAltaDate.getMonth() + 1));
    let anio = fechaAltaDate.getFullYear();

    this.entidad = infoEntidad;
    let frmEntity = {
      nombre: infoEntidad.Nombre,
      propietario: `${this.utilsService.padLeft(2, "0", infoEntidad.Propietario!.Clave,)} ${infoEntidad.Propietario!.Nombre}`,
      correos: infoEntidad.Correos,
      numeroMovil: infoEntidad.NumeroMovil,
      medioEntrada: `${this.utilsService.padLeft(2, "0", infoEntidad.MedioEntrada!.Clave)} ${infoEntidad.MedioEntrada!.Nombre}`,
      estadoProspecto: `${this.utilsService.padLeft(2, "0", infoEntidad.Estado!.Clave)} ${infoEntidad.Estado!.Nombre}`,
      fechaAlta: `${dia}/${mes}/${anio}`,
      observaciones: infoEntidad.Observaciones,
      dadoBaja: infoEntidad.Baja,
      cargo: infoEntidad.Cargo,
      RFC: infoEntidad.Rfc,
      razonSocial: infoEntidad.NombreEmpresa,
      pais: infoEntidad.Direccion?.Pais,
      codigoPostal: infoEntidad.Direccion?.CodigoPostal,
      calle: infoEntidad.Direccion?.Calle,
      noExterior: infoEntidad.Direccion?.NumeroExterior,
      noInterior: infoEntidad.Direccion?.NumeroInterior,
      colonia: infoEntidad.Direccion?.Colonia,
      ciudad: infoEntidad.Direccion?.Localidad,
      municipio: infoEntidad.Direccion?.Municipio,
      estado: infoEntidad.Direccion?.Estado,
      telefono: infoEntidad.Direccion?.Telefono,
      referencias: infoEntidad.Direccion?.Descripcion,
    }
    this.prospectoForm.reset(frmEntity);
  }

  searchCP(cp: any) {
    let codigo = cp.target.value;
    if (codigo) {
      this.entidad = {
        ...this.entidad,
        Direccion: { CodigoPostal: codigo }
      };
      this.modalService.openModal(this.ctrlCodigoPostal, (respModal: any) => {
      }, 'lg')
      this.searchingCP = true;
      this.componentsUiService.getPostalCodes(Number(codigo)).subscribe({
        next: (resp: any) => {
          this.searchingCP = false;
          this.cpList = resp;
        }, error: (err: Error) => {
          this.searchingCP = false;
          console.error(err)
        }
      })
    }
  }


  close() {
    this.cpList = [];
    this.modalService.closeModal(null);
  }

  selectCP(cp: any) {
    console.log(cp)
    this.prospectoForm.controls["municipio"].setValue(cp['d_mnpio']);
    this.prospectoForm.controls["ciudad"].setValue(cp['d_ciudad']);
    this.prospectoForm.controls["colonia"].setValue(cp['d_asenta']);
    this.prospectoForm.controls["estado"].setValue(cp['d_estado']);
    this.close();
  }

  openSearchEstados() {
    this.modalService.openModal(this.ctrlEstados, (respModal: any) => {
    }, 'lg')
  }

  getEstadosRepublica() {
    this.containerBaseService.getItemsByName('Estado')
      .subscribe(estados => {
        estados.map(p => {
          p.ClaveNombre = `${p.Clave} ${p.Nombre}`
        })
        this.estadosRepublica = estados;
      });
  }

  selectEstado(e: any) {
    this.prospectoForm.controls["estado"].setValue(e['Nombre']);
    this.close();
  }

  nuevo() {
    this.entidad = { Id: 0 };
    this.initFormNewEntity();
  }

  guardar() {
    this.entidad.Clave = this.entidad.Id > 0 ? this.entidad.Clave : this.lastClave;
    this.entidad.Nombre = this.prospectoForm.controls["nombre"].value;
    this.entidad.Correos = this.prospectoForm.controls["correos"].value;
    this.entidad.NumeroMovil = this.prospectoForm.controls["numeroMovil"].value;
    this.entidad.Observaciones = this.prospectoForm.controls["observaciones"].value;
    this.entidad.Baja = this.prospectoForm.controls["dadoBaja"].value ? this.prospectoForm.controls["dadoBaja"].value : false;
    this.entidad.Cargo = this.prospectoForm.controls["cargo"].value;
    this.entidad.Rfc = this.prospectoForm.controls["RFC"].value
    this.entidad.Rfc = this.entidad.Rfc ? this.entidad.Rfc.toUpperCase() : this.entidad.Rfc;
    this.entidad.NombreEmpresa = this.prospectoForm.controls["razonSocial"].value;
    this.entidad = {
      ...this.entidad,
      Direccion: {
        ...this.entidad.Direccion,
        Pais: this.prospectoForm.controls["pais"].value,
        CodigoPostal: this.prospectoForm.controls["codigoPostal"].value,
        Calle: this.prospectoForm.controls["calle"].value,
        NumeroExterior: this.prospectoForm.controls["noExterior"].value,
        NumeroInterior: this.prospectoForm.controls["noInterior"].value,
        Colonia: this.prospectoForm.controls["colonia"].value,
        Localidad: this.prospectoForm.controls["ciudad"].value,
        Municipio: this.prospectoForm.controls["municipio"].value,
        Estado: this.prospectoForm.controls["estado"].value,
        Telefono: this.prospectoForm.controls["telefono"].value,
        Descripcion: this.prospectoForm.controls["referencias"].value,
      }
    };
    this.containerBaseService.saveEntity("Prospecto", JSON.stringify(this.entidad)).subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp.success) {
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 });
          this.nuevo();
        } else {
          this.showAlert(resp.message);
        }
      },
      error: (err: Error) => {
        console.error(err);
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.showAlert('Ocurrió un error la guardar.');
      }
    });
  }


  showAlert(message: string) {
    Swal.fire({ icon: 'warning', text: message }).then(() => { });
  }
  //#region AutoHeight para buscador de Códigos Postales

  @HostListener('window:resize', ['$event'])
  onChangeHeight(event: any) {
    this.restarHeight(event.target.innerHeight);
  }

  scrollHeight: number = 0;
  restarHeight(innerHeight: number) {
    let cantidadRestarTop = 140;
    this.scrollHeight = innerHeight - cantidadRestarTop;
  }

  get scrollbarStyle(): string {
    return `margin-top: 10px; height: ${this.scrollHeight}px`;
  };

  //#endregion
}
