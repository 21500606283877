import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsuarioPageComponent } from './pages/usuario-page/usuario-page.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgbDatepicker, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../components/components.module';
import { PuestoPageComponent } from './pages/puesto-page/puesto-page.component';
import { EmpresaPageComponent } from './pages/empresa-page/empresa-page.component';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { SucursalPageComponent } from './pages/sucursales-page/sucursal-page/sucursal-page.component';
import { TiposDeImpuestosPageComponent } from '../ventas/pages/tipos-de-impuestos-page/tipos-de-impuestos-page.component';
import { ImpuestosPageComponent } from '../ventas/pages/impuestos-page/impuestos-page.component';
import { MonedasPageComponent } from '../ventas/pages/monedas-page/monedas-page.component';
import { RolPageComponent } from './pages/rol-page/rol-page.component';
import { TreeModule } from 'primeng/tree';
import { NgxEditorModule } from 'ngx-editor';
import { Select2Module } from 'ng-select2-component';
import { ScrollBar } from '@ng-bootstrap/ng-bootstrap/util/scrollbar';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DepartamentosPageComponent } from './pages/departamentos-page/departamentos-page.component';
import { PaqueteriasPageComponent } from './pages/paqueterias-page/paqueterias-page.component';
import { BloqueoModulosPageComponent } from './pages/bloqueo-modulos-page/bloqueo-modulos-page.component';


@NgModule({
  declarations: [
    UsuarioPageComponent,
    PuestoPageComponent,
    EmpresaPageComponent,
    SucursalPageComponent,
    TiposDeImpuestosPageComponent,
    ImpuestosPageComponent,
    MonedasPageComponent,
    RolPageComponent,
    DepartamentosPageComponent,
    PaqueteriasPageComponent,
    BloqueoModulosPageComponent,
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    DateBoxComponent,
    TreeModule,
    NgxDaterangepickerMd.forRoot(),
    NgxEditorModule,
    Select2Module,
    NgbDatepicker,
    NgScrollbar,
    ImageCropperModule
  ],
})
export class ConfiguracionModule { }
