import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, NgModule, ViewChild, ElementRef, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';
import { Caja, CajaConsulta, EstadoCaja } from '../../interfaces/caja.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import * as moment from 'moment';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import Swal from 'sweetalert2';
import { ModalService } from 'src/app/service/modal.service';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-apertura-caja-page',
  templateUrl: './apertura-caja-page.component.html',
  styles: [
  ]
})
export class AperturaCajaPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  info: UserLogged | null = null;
  boxes: CajaConsulta[] = [];
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  @ViewChild('popupApertura')
  public popupApertura!: ElementRef<HTMLElement>;
  @ViewChild('popupEditar')
  public popupEditar!: ElementRef<HTMLElement>;

  public myForm: FormGroup = this.fb.group({
    Caja: [{} as Caja],
    Encargado: [{} as Usuario],
    Supervisor: [null],
    AutorizadoCierre: [false],
    Sucursal: [{} as Sucursal],
    Estado: [{} as EstadoCaja],
    EstadoNuevo: [{} as EstadoCaja],
    FondoFijo: [0],
    Fecha: [new Date()],
  })



  public constructor(private utileService: UtilsService, private ms: ModalService,
    private fb: FormBuilder, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.getBoxes();
  }

  actualizarCaja(box: CajaConsulta) {

    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idSucursal", this.info!.sucursal!.numero)
      .set("idCaja", this.myForm.value.Caja.Id)
      .set("idEstadoNuevo", this.myForm.value.EstadoNuevo.Id)
      .set("idEstadoAnterior", this.myForm.value.Estado.Id)
      .set("idCorteCaja", box.idCorteCaja);
    this.http.get<Result>(`${this.baseUrl}/Ventas/ActualizarCaja`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.cancelModal();
        this.getBoxes();
      } else {
        Swal.fire({ text: `Ocurrio un error: ${result.message}`, icon: 'info', })
      }
    });

    this.cancelModal();
  }

  selectBox(box: CajaConsulta) {
    if (box.estado.id == 1 || box.estado.id == 4) {
      this.myForm.controls["Caja"].setValue({ Id: box.idCaja, Nombre: box.nombre });
      this.myForm.controls["Estado"].setValue({ Id: box.estado.id, Clave: box.estado.clave, Nombre: box.estado.nombre });
      this.myForm.controls["FondoFijo"].setValue(box.fondoFijo);
      const b: any = this.popupEditar;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.actualizarCaja(box);
        } else {
          this.ms.closeModal(false);
        }
      });

      return;
    } else {
      const b: any = this.popupApertura;
      this.myForm.controls["Caja"].setValue({ Id: box.idCaja, Nombre: box.nombre });
      this.myForm.controls["Estado"].setValue({ Id: box.estado.id, Clave: box.estado.clave, Nombre: box.estado.nombre });
      this.myForm.controls["FondoFijo"].setValue(box.fondoFijo);
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.setBoxStatus();
        } else {
          this.cancelModal();
        }
      });
    }
  }

  setBoxStatus() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idCaja", this.myForm.value.Caja.Id)
      .set("idEncargado", this.myForm.value.Encargado.Id)
      .set("idEstado", this.myForm.value.Estado.Id)
      .set("fondoFijo", this.myForm.value.FondoFijo)
      .set("fecha", moment(this.myForm.value.Fecha).format("DD/MM/YYYY"));
    this.http.get<Result>(`${this.baseUrl}/Ventas/AbrirCaja`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        if (this.myForm.value.Estado.Id == 1) {

          Swal.fire({ position: 'center', icon: 'success', title: 'Caja abierta correctamente', showConfirmButton: false, timer: 1000 });

        }
        this.getBoxes();
      } else {
        this.eventsService.publish('home:showAlert', { message: `Ocurrio un error: ${result.message}`, cancelButton: false });
      }
    });
  }

  get getFilterUser(): string {
    return `EsCajero = true and SucursalPertenece.Id = ${this.info!.sucursal!.numero}`;
  }

  selectedItem(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

  get getIsValidEdit(): boolean {

    return false;
  }

  get getIsValid(): boolean {

    if (this.myForm.value.Encargado?.Id > 0 &&
      this.myForm.value.Estado?.Id == 1 &&
      this.myForm.value.FondoFijo > 0 &&
      this.myForm.value.Fecha &&
      !this.saving
    ) {
      return true;

    }

    return false;
  }

  acceptEdit() {
    if (this.myForm.value.Estado?.Id > 0) {


      if (this.myForm.value.EstadoNuevo.Id == 2 && !this.myForm.value.Supervisor) {
        this.eventsService.publish('home:showAlert', { message: `Indique el usuario que Supervisó el Cierre final de la Caja.`, cancelButton: false });
        return;
      }

      if (this.myForm.value.EstadoNuevo.Id == 2 && !this.myForm.value.AutorizadoCierre) {
        this.eventsService.publish('home:showAlert', { message: `Indique que se revisó toda la documentación de la caja.`, cancelButton: false });
        return;
      }

      this.eventsService.publish('home:showAlert', {
        message: `¿Está seguro de cambiar el Estado de la Caja?`,
        onConfirm: (r: AlertResponse) => {
          if (r.isAccept) {
            setTimeout(() => {
              this.ms.closeModal(true);
            }, 100);
          }
        }
      });

    } else {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar un Estado para la Caja`, cancelButton: false });
    }
  }

  accept() {
    this.ms.closeModal(true);
  }

  close() {
    this.ms.closeModal(false);
  }


  cancelModal() {
    this.myForm.controls["Caja"].setValue(null);
    this.myForm.controls["Estado"].setValue(null);
    this.myForm.controls["EstadoNuevo"].setValue(null);
    this.myForm.controls["FondoFijo"].setValue(0);
  }

  getBoxes() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.myForm.controls["Sucursal"].setValue({ Id: this.info!.sucursal!.numero, Clave: this.info!.sucursal!.clave, Nombre: this.info!.sucursal!.nombre });
    const params = new HttpParams().set("idSucursal", this.info!.sucursal!.numero);
    return this.http.get<CajaConsulta[]>(`${this.baseUrl}/Ventas/ObtenerCajas`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.boxes = result;
    });

  }

}
