import { Component, Input } from '@angular/core';
import { ColumnaGrid } from '../../interfaces/grid.interface';

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.css']
})
export class DatagridComponent {


  @Input()
  public columnas: ColumnaGrid[] = [];

  @Input()
  public source: any[] = [];


}
