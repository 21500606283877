import { Component, OnInit } from '@angular/core';
import { Account } from '../../interfaces/prometo.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-saldos-bancos-page',
  templateUrl: './saldos-bancos-page.component.html',
  styles: [
  ]
})
export class SaldosBancosPageComponent implements OnInit {

  lista: Account[] = [];
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {

  }


  getBalance() {
    this.saving = true;
    this.http.get<Account[]>(`${this.baseUrl}/Tesoreria/ObtenerSaldosBancos`).subscribe((lista) => {
      this.saving = false;
      this.lista = lista;
    });
  }

}
