<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="TipoPoliza"
  title="Tipos de Pólizas" icon="fa fa-manat-sign" subTitle="Administra la configuración de Tipos de Pólizas">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Tipo de póliza'" entityName="TipoPoliza" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre />

          <components-ui-text-box [label]="'Abreviación'" [maxLength]="2" [required]="true" formControlName="Abreviacion" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2"  />

            <component-ui-check-box [tabIndex]="4" [widthLabelColumns]="2"  formControlName="Ajuste"
            [label]="'Es Póliza de ajustes'" />

            <component-ui-check-box [tabIndex]="5" [widthLabelColumns]="2"  formControlName="EsApertura"
            [label]="'Es Póliza de apertura'" />

            <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="2"  formControlName="EsCierre"
            [label]="'Es Póliza de cierre'" />

            <component-ui-check-box [tabIndex]="7" [widthLabelColumns]="2"  formControlName="Baja"
            [label]="'¿Dar de baja?'" />

        </div>
      </app-panel-base>

    </form>
  </div>
</app-container-base>
