<app-container-base [hasCounter]="false" icon="fa fa-file-import" [hasBar]="false" subTitle="Importación de registros">
  <div class="screen-content" style="height: 100%;">
    <span *ngIf="downloading" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div class="d-flex">
      <div style="width:50%;">
        <a (click)="back()" class="btn btn-primary me-5px {{actualStep>1 &&!importing?'':'disabled'}}"><i
            class="fa  fa-arrow-alt-circle-left"></i>Anterior</a>
      </div>
      <div style="width:50%;text-align: right;">
        <a (click)="next()"
          class="btn btn-primary me-5px {{(actualStep>1 && actualStep <3) &&!importing?'': (catalogoseleccionado>0?'':'disabled')}}"><i
            class="fa  fa-arrow-alt-circle-right"></i>Siguiente</a>
      </div>
    </div>

    <div class="nav-wizards-container">
      <nav class="nav nav-wizards-1 mb-2">
        <!-- completed -->
        <div class="nav-item col">
          <a class="nav-link {{actualStep== 1?'active':'completed'}}">
            <div class="nav-no">1</div>
            <div class="nav-text">Selección de catálogo</div>
          </a>
        </div>

        <div class="nav-item col">
          <a class="nav-link {{actualStep == 2 ?'active':(actualStep == 3?'completed':'disabled')}}">
            <div class="nav-no">2</div>
            <div class="nav-text">Seleccionar archivo</div>
          </a>
        </div>

        <div class="nav-item col">
          <a class="nav-link  {{actualStep== 3?'active': (actualStep == 4 ? 'completed': 'disabled')}}">
            <div class="nav-no">3</div>
            <div class="nav-text">Importando</div>
          </a>
        </div>

        <div class="nav-item col">
          <a class="nav-link disabled">
            <div class="nav-no">4</div>
            <div class="nav-text">Terminado</div>
          </a>
        </div>

      </nav>
    </div>

    <div class="card" *ngIf="actualStep==1">
      <div class="card-body">
        <div id="privacyAndSecurity" class="mb-4 pb-3">
          <h4 class="d-flex align-items-center mb-2 mt-3">
            <i class="fa fa-database fs-20px me-1"></i>
            Catálogos disponibles para migrar a la base de datos
          </h4>
          <p>Seleccione el catálogo que desea importar.</p>
          <div class="card">
            <div class="list-group list-group-flush fw-bold">
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 1 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Clientes</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Clientes')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(1)">Seleccionar</a></div>
              </div>
              <div
                class="list-group-item d-flex align-items-center  {{catalogoseleccionado == 9 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Familias Productos</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Familias')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(9)">Seleccionar</a></div>
              </div>
              <div
                class="list-group-item d-flex align-items-center  {{catalogoseleccionado == 2 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Productos</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Productos')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(2)">Seleccionar</a></div>
              </div>


              <div
              class="list-group-item d-flex align-items-center  {{catalogoseleccionado == 14 ? 'selected-type':''}}">
              <div class="flex-fill">
                <div>Productos Unidades</div>
                <div class="text-body text-opacity-60 d-flex align-items-center">
                  <span (click)="downloadFile('ProductosUni')" style="cursor:pointer;"
                    class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                    <i class="fa fa-download fs-12px me-1"></i>
                    <span class="text-dark">Descargar ejemplo de archivo</span>
                  </span>
                </div>
              </div>
              <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                  (click)="selectToImport(14)">Seleccionar</a></div>
            </div>


            <div
            class="list-group-item d-flex align-items-center  {{catalogoseleccionado == 15 ? 'selected-type':''}}">
            <div class="flex-fill">
              <div>Productos Composición</div>
              <div class="text-body text-opacity-60 d-flex align-items-center">
                <span (click)="downloadFile('ProductosComp')" style="cursor:pointer;"
                  class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                  <i class="fa fa-download fs-12px me-1"></i>
                  <span class="text-dark">Descargar ejemplo de archivo</span>
                </span>
              </div>
            </div>
            <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                (click)="selectToImport(15)">Seleccionar</a></div>
          </div>


              <div
                class="list-group-item d-flex align-items-center  {{catalogoseleccionado == 3 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Saldos Cuentas Por Cobrar</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('SaldosCXC')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(3)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 4 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Proveedores</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Proveedores')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(4)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 5 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Saldos Cuentas Por Pagar</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('SaldosCXP')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(5)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 6 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Cuentas Contables</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('CuentasContables')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(6)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 7 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Pólizas Contables</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Polizas')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(7)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 8 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Activos Fijos</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('ActivosFijos')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(8)">Seleccionar</a></div>
              </div>
              <div
                class="list-group-item d-flex align-items-center {{catalogoseleccionado == 10 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Vehiculos</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Vehiculos')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(10)">Seleccionar</a></div>
              </div>
              <div
                class="list-group-item d-flex align-items-center {{catalogoseleccionado == 11 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Usuarios</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('Usuarios')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(11)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 12 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Consignados Clientes</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('ConsignadosClie')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(12)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 13 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Consignados Proveedores</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('ConsignadosProv')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(13)">Seleccionar</a></div>
              </div>
              <div class="list-group-item d-flex align-items-center {{catalogoseleccionado == 16 ? 'selected-type':''}}">
                <div class="flex-fill">
                  <div>Carga y entrega</div>
                  <div class="text-body text-opacity-60 d-flex align-items-center">
                    <span (click)="downloadFile('CargaEntrega')" style="cursor:pointer;"
                      class="bg-opacity-10 ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
                      <i class="fa fa-download fs-12px me-1"></i>
                      <span class="text-dark">Descargar ejemplo de archivo</span>
                    </span>
                  </div>
                </div>
                <div><a data-bs-toggle="modal" class="btn btn-secondary w-100px"
                    (click)="selectToImport(16)">Seleccionar</a></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="actualStep==2">
      <div class="card-body">
        <div id="privacyAndSecurity" class="mb-4 pb-3">
          <h4 class="d-flex align-items-center mb-2 mt-3">
            <i class="fa fa-upload fs-20px me-1"></i>
            Seleccione el archivo a importar para el catálogo de {{getCatalogName}}
          </h4>
          <p>Archivo en formato .CSV</p>
          <div class="card">
            <div>
              <form class="dropzone {{getFileName ? 'selected':''}} needsclick" (click)="searchFile()"
                style="cursor: pointer;">
                <div class="dz-message needsclick">
                  <div *ngIf="getFileName == ''; else seleccionado">
                    <b>click</b> para buscar en PC.<br />
                    <span class="dz-note needsclick">
                      No hay ningún archivo seleccionado
                    </span>
                  </div>
                  <ng-template #seleccionado>
                    <i class="fas fa-file-excel text-green fa-2x"></i>{{getFileName}}
                    <span class="dz-note needsclick">
                      archivo seleccionado
                    </span>
                  </ng-template>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="actualStep==3">
      <div class="card-body">
        <div id="privacyAndSecurity" class="mb-4 pb-3">
          <h4 class="d-flex align-items-center mb-2 mt-3">
            <i class="fa fa-user-check fs-20px me-1"></i>
            Opciones antes de iniciar la importación de registros
          </h4>
          <p>{{importing ?'Importación iniciada':'Verifique muy bien antes de continuar.'}}</p>
          <div *ngIf="!importing; else importando">
            <hr>
            <form [formGroup]="myForm" autocomplete="off">
              <component-ui-check-box [label]="'Eliminar los registros antes de importar'" formControlName="eliminar" />
            </form>
            <hr>
            <a (click)="importCatalog()" class="btn btn-primary d-block {{importing?'disabled':''}}">Iniciar Importación
              de [{{getCatalogName}}]</a>
          </div>
          <ng-template #importando>
            <hr>
            <i class="fas fa-cog fa-spin fa-5x"></i>
            <span>Espere un momento los registros se están importando (toma solo unos segundos)</span>
            <hr>
          </ng-template>
        </div>
      </div>
    </div>

    <input (change)="onchange($event)" [accept]="'.csv,.CSV'" id="fileUpload" type="file" style="display: none;"
      #txtFile />
  </div>
</app-container-base>
