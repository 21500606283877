import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from '../../../configuracion/service/configuracion.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Usuario } from '../../../configuracion/interfaces/usuario.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Tipo, TipoImpuesto, ObjetoImpuesto } from '../../interfaces/tipodeimpuesto.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import Swal from 'sweetalert2';
import { c_TipoFactor } from '../../interfaces/venta.interface';

@Component({
  selector: 'app-tipos-de-impuestos-page',
  templateUrl: './tipos-de-impuestos-page.component.html',
})

export class TiposDeImpuestosPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Imprimir: [''],
    Factor: [{} as c_TipoFactor, Validators.required],
    Tipo: [{} as Tipo, Validators.required],
    ObjetoImpuesto: [{} as ObjetoImpuesto, Validators.required],
    TasaCuota: [0],
    Traslada: true,
    Retiene: false,
    EsImpuestoLocal: false
  })


  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService,) {
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    console.log(items)
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {

    const ent = this.myForm.value;
    if ((ent.Retiene && ent.Traslada) || (!ent.Retiene && !ent.Traslada)) {
      Swal.fire({ icon: 'error', text: 'Solo indique una opción, Traslada o Retiene' }).then(() => { });
      param.callback(false);
      return;
    }


    param.callback(true);
  }

  selectedObjeto(entity: ComboBoxEntity) {
    this.myForm.controls["ObjetoImpuesto"].setValue(entity);
  }

  selectedFactor(entity: ComboBoxEntity) {
    this.myForm.controls["Factor"].setValue(entity);
  }

  selectedTipo(entity: ComboBoxEntity) {
    this.myForm.controls["Tipo"].setValue(entity);
  }

  onItemSearched(entity: TipoImpuesto): void {
    if (entity) {
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: TipoImpuesto) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("TipoImpuesto").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }


}
