import { Component, Input, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductoUnidad } from 'src/app/Inventarios/interfaces/producto.interface';
import { ModalService } from 'src/app/service/modal.service';
import { VentaConcepto, VentaConceptoConversion } from '../../interfaces/venta.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-conversiones-page',
  templateUrl: './conversiones-page.component.html',
  styles: [
  ]
})
export class ConversionesPageComponent implements OnInit {

  @Input()
  public items: ProductoUnidad[] = []
  @Input()
  public unidadFinal: string = '';

  total: number = 0;

  public myForm: FormGroup = this.fb.group({
    valorConversion1: [0],
    valorConversion2: [0],
    valorConversion3: [0],
    valorConversion4: [0],
    valorConversion5: [0],
    valorConversion6: [0],
    valorConversion7: [0],
    valorConversion8: [0],
    valorConversion9: [0],
    valorConversion10: [0],
  });

  ngOnInit(): void {
  }

  constructor(private mService: ModalService, private fb: FormBuilder) {

  }

  keyDown(e: any, i: number) {
    if (e.keyCode == 13) {
      e.preventDefault();
      const el = document.getElementById(`conv-${i + 1}`);
      if (el) {
        el.focus();
      } else {
        document.getElementById("btnAcConv")?.focus();
      }
    } else if (e.keyCode == 27) {
      e.preventDefault();
      const el = document.getElementById(`conv-${i - 1}`);
      el && el.focus();
    }
  }

  get getLabel(): string {
    let t = '';
    const ent = this.myForm.value;
    this.items.forEach((conversion, index) => {
      const value: number = ent['valorConversion' + (index + 1)];
      if (value != 0) {
        t += `${parseFloat(value.toFixed(2))} - ${conversion.Unidad.Nombre}\r\n`;
      }
    })
    return t;
  }

  get getTotal(): number {
    let t = 0;

    const ent = this.myForm.value;

    this.items.map((conversion, index) => {
      const value = ent['valorConversion' + (index + 1)];
      const conver = parseFloat((value / conversion.Cantidad).toFixed(2));
      t += conver;
      conversion.Conversion = conver;

    })
    return t;
  }

  accept() {
    let numconversiones: number = 0;
    let t: VentaConceptoConversion[] = [];
    const ent = this.myForm.value;
    this.items.forEach((conversion, index) => {
      const value = ent['valorConversion' + (index + 1)];
      if (value != 0) {
        numconversiones++;
        t = [...t, { Id: 0, NombreUnidad: conversion.Unidad.Nombre, CantidadUnidadConversion: value, CantidadUnidadConcepto: parseFloat((value / conversion.Cantidad).toFixed(2)) }]
      }
    })
    if (numconversiones > 1) {
      Swal.fire({ text: `Solo puede indicar una sola Unidad de conversión`, icon: 'info', });
      return;
    }

    this.mService.closeModal({ cantidad: this.getTotal, conversiones: this.getLabel, items: t });
  }

  closeModal() {
    this.mService.closeModal(null);
  }


}
