import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'catalogo-clientes',
  templateUrl: './catalogo-clientes-page.component.html',
  styleUrls: ['./catalogo-clientes-page.component.scss'],
})
export class CatalogoClientesPageComponent {

  itemOrdene: any;


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private fb = inject(FormBuilder);
  private reportsService = inject(ReportsService)
  private eventsService = inject(EventsService);
  menuId: string = "impresion-catalogo-clientes";
  filtrosReportePredefinidos:ReportFilter = {} as ReportFilter


  ngOnInit(): void {

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.filtrosReporte.ReportHeader = this.frmPrintCatalogoClientes.value;
    this.reportsService.getUserFilter(this.filtrosReporte).subscribe((result: ReportFilter) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        this.filtrosReportePredefinidos = result;
        this.frmPrintCatalogoClientes.reset(result.ReportHeader)
      }
      this.frmPrintCatalogoClientes.get('NombreReporte')?.setValue('CATÁLOGO DE CLIENTES AL ' + moment().format("DD/MM/YYYY"));
      this.focus('txtNombreReporte');
    })



  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public frmPrintCatalogoClientes: FormGroup = this.fb.group({
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  selectedCombo(entity: any, tipo: string) {

  }

  clickBoton(event: any) {
    this.filtrosReporte.ReportHeader =  this.frmPrintCatalogoClientes.value;
    this.reportsService.printReport(this.filtrosReporte, '/Ventas/ReporteCatalogoClientes');

  }

  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Reporte de Diario de venta',
      NombreExcel: 'Diario de venta.xlsx',
      OrdenadoLista: [
        { Nombre: "Por Nombre", Orden: "c.Nombre" },
        { Nombre: "Por Clave", Orden: "c.Clave" }
      ],
      FilterOptions: [
        { Campo: 'e.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'c.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'c.Nombre', Etiqueta: 'Nombre', Tipo: TypeFilter.text },
        { Campo: 'tc.Clave', Etiqueta: 'Tipo Cliente', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "TipoCliente" },
        { Campo: 'cc.Clave', Etiqueta: 'Clase Cliente', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "ClaseCliente" },
        { Campo: 'u.Clave', Etiqueta: 'Vendedor', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'cp.Id', Entidad: "CondicionPago", Etiqueta: 'Condición de Pago', Tipo: TypeFilter.entityNumber },
        { Campo: 'c.CreditoSuspendido', Etiqueta: 'Crédito Bloqueado', Tipo: TypeFilter.boolean, ListaItems: [{ Nombre: 'Sin selección' }, { Nombre: 'Si' }, { Nombre: 'No' }] }
      ]
    };

  changeselec(event: any) {
    this.filtrosReporte = event;
  }
}
