import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { PolizaAutomatica, PolizaAutomaticaDetalle, PolizaAutomaticaModulo } from '../../interfaces/polizaautomatica.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { TipoPoliza } from '../../interfaces/tipopoliza.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { GuiColumnAlign, GuiRowStyle, GuiSearching, GuiSorting } from '@generic-ui/ngx-grid';
import Swal from 'sweetalert2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-poliza-automatica-page',
  templateUrl: './poliza-automatica-page.component.html',
  styleUrls: []
})
export class PolizaAutomaticaPageComponent {

  selectedIndex: number = -1;
  editing: boolean = false;
  saving: boolean = false;
  public GuiColumnAlign = GuiColumnAlign;
  searching: GuiSearching = { enabled: true };
  sorting: GuiSorting = { enabled: true };
  selectedItem: PolizaAutomaticaDetalle | null = null;
  listaDetalles: PolizaAutomaticaDetalle[] = []
  public itemsCombo: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;


  rowStyle: GuiRowStyle = {
    styleFunction: (data: PolizaAutomaticaDetalle, index: number) => {
      if (data.EsComentario) {
        return 'background-color: #99df99;';
      }
      if (data.InHabilitada) {
        return 'background-color: #dddddd;font-style: italic;';
      }
      return '';
    }
  };

  private newEntityObj = {
    Id: [0],
    Empresa: [{} as Empresa],
    TipoPoliza: [{} as TipoPoliza, Validators.required],
    Clave: [0],
    Nombre: ['', Validators.required],
    ConceptoGeneral: ['', Validators.required],
    TipoDocumentoVentaCompra: [''],
    Cuentas: [''],
    Conceptos: [''],
    Almacenes: [''],
    Modulo: [{} as PolizaAutomaticaModulo],
    SiguientePoliza: [{} as PolizaAutomatica],
    SoloAnticipos: [false],
    ExluirAnticipos: [false],
    DisponibleParaRegenerar: [false],
    UsuarioAlta: [{} as Usuario],
    UsuarioModifica: [{} as Usuario],
    Asientos: [[] as PolizaAutomaticaDetalle[]],
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);


  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;


  private reportsService = inject(ReportsService);
  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private http: HttpClient) {
  }

  onBeforeSave(param: EventReturn) {
    this.myForm.controls["Asientos"].setValue(this.listaDetalles.filter(P => P.CuentaContable || P.EsComentario));
    const ent = this.myForm.value;
    if (!ent.TipoPoliza || ent.TipoPoliza?.Id == 0) {
      Swal.fire({ text: 'Indique el tipo de póliza.', icon: 'info', })
      param.callback(false);
      return;
    }

    if (!ent.ConceptoGeneral) {
      Swal.fire({ text: 'Indique el concepto general.', icon: 'info', })
      param.callback(false);
      return;
    }

    if (!ent.Asientos || ent.Asientos?.length == 0) {
      Swal.fire({ text: 'Indique los asientos de la póliza.', icon: 'info', })
      param.callback(false);
      return;
    }

    param.callback(true);
  }

  onAfterSave(entity: PolizaAutomatica) {
    this.myForm.reset(entity);
    this.resetSource(entity.Asientos);
  }

  onSelectedItem(value: any, type: string) {
    this.myForm.controls[type].setValue(value);
  }

  onItemSearched(entity: PolizaAutomatica): void {
    if (entity) {
      this.myForm.reset(entity);
      this.resetSource(entity.Asientos);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("PolizaAutomatica").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.resetSource(elem.Asientos);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  resetSource(details: PolizaAutomaticaDetalle[] | null, continueEditing: boolean = false) {
    if (details) {
      this.listaDetalles = [...details.filter(P => P.CuentaContable || P.EsComentario)];
    } else {
      this.listaDetalles = []
    }
    const length = this.listaDetalles.length;
    //for (let index = 0; index < length + 1; index++) {
    this.listaDetalles = [...this.listaDetalles, {
      Id: 0,
      Orden: length + 1,
      Sucursal: null,
      Banco: null,
      ClaseVenta: null,
      ClaseCompra: null,
      CuentaContable: '',
      CentroCosto: '',
      TipoConceptoCargo: null,
      TipoConceptoAbono: null,
      EsComentario: false,
      InHabilitada: false,
      ColocarEnNegativo: false,
      Detalle: false,
      ConceptoDetalle: '',
      Referencia: '',
      Padre: null,
    }];
    //}

    setTimeout(() => {
      const rows = document.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
      if (rows) {
        rows.forEach((item, index) => {
          item.classList.remove("editing-row");
          item.addEventListener("dblclick", () => {
            this.onDblClickRow(index);
          });
        })
      }

      if (continueEditing) {
        this.initEdit(this.selectedIndex + 1);
      } else {
        this.editing = false;
        this.selectedIndex = -1;
        this.selectedItem = null;
      }

    }, 100);
  }

  onDblClickRow(index: number) {
    if (this.editing) return;
    const rows = document.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
    if (rows) {
      rows.forEach((item, index) => {
        item.classList.remove("editing-row");
      })
    }
    const el = document.querySelector(`[ng-reflect-index='${index}']`);
    if (el) {
      el.classList.add("editing-row");
    }
    this.initEdit(index);
  }


  initEdit(index: number) {
    setTimeout(() => {

      this.selectedIndex = index;
      const sel: any = this.listaDetalles[this.selectedIndex];
      this.selectedItem = sel;
      this.editing = true;
      const el = document.querySelector(`[ng-reflect-index='${this.selectedIndex}']`);
      if (el) {
        el.classList.add("editing-row");
        el.scrollIntoView({ block: "center" });
      }
    }, 100);
  }


  onCloseEdit(mov: PolizaAutomaticaDetalle): void {
    if (!mov) {
      this.editing = false;
      this.selectedIndex = -1;
      this.selectedItem = null;
      this.resetSource(this.listaDetalles);
    } else {
      this.listaDetalles[this.selectedIndex] = mov;
      this.resetSource(this.listaDetalles, true);
      this.editing = false;
    }
  }

  deleteRow(index: number) {
    Swal.fire({
      title: '¿Desea eliminar el asiento contable?',
      showDenyButton: true,
      confirmButtonText: 'Si, eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const idEliminar = this.listaDetalles[index].Id;
        if (idEliminar > 0) {
          this.saving = true;
          this.deleteDetail(idEliminar, () => {
            this.saving = false;
            this.listaDetalles.splice(index, 1);
            this.listaDetalles.map((i, ix) => {
              i.Orden = ix + 1;
              return i;
            })
            this.listaDetalles = [...this.listaDetalles];
          });
        } else {
          this.listaDetalles.splice(index, 1);
          this.listaDetalles.map((i, ix) => {
            i.Orden = ix + 1;
            return i;
          })
          this.listaDetalles = [...this.listaDetalles];
        }
      }
    })
  }

  deleteDetail(id: number, cb: any) {
    const params = new HttpParams().set("id", id);
    this.http.get<void>(`${this.baseUrl}/Contabilidad/EliminarDetalle`, { params }).subscribe(() => { cb(); })
  }


  get getSelectedItem(): PolizaAutomaticaDetalle {
    return { ...this.selectedItem! };
  }

  get getAnticipoEnabled(): boolean {
    if (this.myForm.value.Modulo) {
      return this.myForm.value.Modulo.Id == 1 || this.myForm.value.Modulo.Id == 2;
    }

    return false;
  }

  get getConceptosEnabled(): boolean {

    if (this.myForm.value.Modulo) {
      return this.myForm.value.Modulo.Id == 3 || this.myForm.value.Modulo.Id == 4 || this.myForm.value.Modulo.Id == 5 || this.myForm.value.Modulo.Id == 6 || this.myForm.value.Modulo.Id == 9;
    }
    return false;
  }

  get getCuentasEnabled(): boolean {

    if (this.myForm.value.Modulo) {
      return this.myForm.value.Modulo.Id == 3 || this.myForm.value.Modulo.Id == 4 || this.myForm.value.Modulo.Id == 9;
    }
    return false;
  }

  get getAlmacenEnabled(): boolean {

    if (this.myForm.value.Modulo) {
      return this.myForm.value.Modulo.Id == 5 || this.myForm.value.Modulo.Id == 6;
    }
    return false;
  }

  get getDoctoEnabled(): boolean {

    if (this.myForm.value.Modulo) {
      return this.myForm.value.Modulo.Id == 1 || this.myForm.value.Modulo.Id == 2 || this.myForm.value.Modulo.Id == 7 || this.myForm.value.Modulo.Id == 8;
    }
    return false;
  }

  get getFilterNext(): string {
    if (this.myForm.value) {
      return `Id != ${this.myForm.value.Id}`;
    }

    return "";
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  imprimir(event: any) {
    if (event === "print") {
      this.reportsService.printReportWithBody(this.myForm.value, '/Contabilidad/ReportePolizaAutomatica', "Póliza Automática");
    }
  }
}
