import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfiguracionCargaEntrega } from '../interfaces/cargaentrega.interface';

@Injectable({
  providedIn: 'root'
})
export class CargaentregaService {

  private readonly baseUrl: string = environment.baseUrlApi;


  constructor(private http: HttpClient) { }

  getConfiguracion(): Observable<ConfiguracionCargaEntrega> {

    return this.http.get<ConfiguracionCargaEntrega>(`${this.baseUrl}/Ventas/ObtenerConfiguracionCargaEntrega`, {  });
  }


}
