import { ComboBoxEntity } from './../../../component-ui/interfaces/combo-text.interface';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { ClaseVenta, MetodoPago } from '../../interfaces/claseventa.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Venta } from '../../interfaces/venta.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ComboBoxComponent } from '../../../component-ui/components/combo-box/combo-box.component';
import { FloatSubMenuComponent } from '../../../components/float-sub-menu/float-sub-menu.component';
import Swal from 'sweetalert2';
import { FacturacionService } from '../../services/facturacion.service';
import * as moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Result } from 'src/app/auth/interfaces';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-factura-global-page',
  templateUrl: './factura-global-page.component.html',
  styleUrls: ['./factura-global-page.component.scss']
})

export class FacturaGlobalPageComponent implements OnInit {



  constructor(private fb: FormBuilder, private cService: ContainerBaseService, private fs: FacturacionService, private http: HttpClient, private fServices: FacturacionService) {
  }

  @ViewChild('cboEmpresa')
  public cboEmpresa!: ElementRef<ComboBoxComponent>;
  @ViewChild('cboSucursal')
  public cboSucursal!: ElementRef<ComboBoxComponent>;
  private eventsService = inject(EventsService);
  sucursales: ComboBoxEntity[] = []
  tickets: Venta[] = []
  searching: boolean = false;
  selectAll: boolean = true;
  private readonly baseUrl: string = environment.baseUrlApi;

  private newEntityObj = {
    Id: [0],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    FechaEmision: [new Date()],
    FechaDesde: [new Date()],
    FechaHasta: [new Date()],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  ngOnInit(): void {
    setTimeout(() => {
      const el: any = this.cboEmpresa;
      el.tagInput.nativeElement.focus();
    }, 10);
    this.init();
  }

  init() {
    const ent = {
      Id: 0,
      Empresa: { Id: 0 },
      Sucursal: { Id: 0 },
      FechaEmision: new Date(),
      FechaDesde: new Date(),
      FechaHasta: new Date(),
    };
    this.myForm.reset(ent);
  }

  getDate(date: any) {
    return moment(date).format("DD/MM/YYYY")
  }

  get getItemsSucs(): ComboBoxEntity[] {
    return this.sucursales;
  }

  searchTickets() {

    const ent = this.myForm.value;

    if (!ent.Empresa || ent.Empresa?.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar una Empresa.`, cancelButton: false });
      return;
    }

    if (!ent.Sucursal || ent.Sucursal?.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar una Sucursal.`, cancelButton: false });
      return;
    }

    if (!ent.FechaEmision) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la fecha de emisión.`, cancelButton: false });
      return;
    }

    if (!ent.FechaDesde) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la fecha desde.`, cancelButton: false });
      return;
    }

    if (!ent.FechaHasta) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la fecha hasta`, cancelButton: false });
      return;
    }

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.getTickets(ent.Empresa.Id, ent.Sucursal.Id, moment(ent.FechaDesde).format("DD/MM/YYYY"), moment(ent.FechaHasta).format("DD/MM/YYYY")).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.tickets = lista;
    })

  }

  mark(index: number) {
    this.tickets[index].Seleccionado = !this.tickets[index].Seleccionado;
    this.tickets = [...this.tickets];
  }

  markAll() {
    this.selectAll = !this.selectAll;
    this.tickets.map((item) => item.Seleccionado = this.selectAll)
  }

  proceed() {

    const ent = this.myForm.value;

    if (!ent.Empresa || ent.Empresa?.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar una Empresa.`, cancelButton: false });
      return;
    }

    if (!ent.Sucursal || ent.Sucursal?.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar una Sucursal.`, cancelButton: false });
      return;
    }

    if (!ent.FechaEmision) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la fecha de emisión.`, cancelButton: false });
      return;
    }

    if (!ent.FechaDesde) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la fecha desde.`, cancelButton: false });
      return;
    }

    if (!ent.FechaHasta) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la fecha hasta.`, cancelButton: false });
      return;
    }

    const select = this.tickets.filter(P => P.Seleccionado);
    if (select.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `No ha seleccionado ningún ticket.`, cancelButton: false });
      return;
    }

    let tickets = "";
    select.forEach((item) => {
      tickets += `${item.Id},`;
    });
    tickets = tickets.substring(0, tickets.length - 1);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idEmpresa", ent.Empresa.Id)
      .set("idSucursal", ent.Sucursal.Id)
      .set("idsTickets", tickets)
      .set("fechaEmision", moment(ent.FechaEmision).format("DD/MM/YYYY"));
    this.http.get<Result>(`${this.baseUrl}/Ventas/CrearFacturaGlobal`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.tickets = [];
        const idFact = parseInt(result.message);
        this.print(idFact);
      } else {
        Swal.fire({ text: result.message, icon: 'error', })
      }
    });
  }

  print(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idVenta", id);
    this.http.get<Result>(`${this.baseUrl}/Ventas/ImprimirDocumento`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.fServices.printDocument(this.fServices.base64ToArrayBuffer(result.message));
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    })
  }

  onSelectedItem(ent: any, type: string) {
    this.myForm.controls[type].setValue(ent);

    if (type == "Empresa") {
      this.cService.getItemsByName("Sucursal").subscribe((lista) => {
        lista.map((item, index) => item.ClaveNombre = `${item.Clave} ${item.Nombre}`)
        this.sucursales = lista;

        setTimeout(() => {
          const el: any = this.cboSucursal;
          el.tagInput.nativeElement.focus();
        }, 10);

      });
    }
  }



}
