import { Component, ElementRef, ViewChild } from '@angular/core';
import { EstadoProspecto } from '../../interfaces/prospecto.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';

@Component({
  selector: 'app-estoados-prospecto-page',
  templateUrl: './estoados-prospecto-page.component.html',
  styles: [
  ]
})
export class EstoadosProspectoPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]]
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: EstadoProspecto) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: EstadoProspecto): void {

    if (entity) {
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("EstadoProspecto").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
