import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ClaseVenta, FormaVenta, MetodoPago, TipoVenta } from '../../interfaces/claseventa.interface';
import { CondicionPago } from '../../interfaces/condicionpago.interface';
import { CuentaCxc } from 'src/app/cuentas-por-cobrar/interfaces/cuentacxc.interface';

@Component({
  selector: 'app-clase-venta-page',
  templateUrl: './clase-venta-page.component.html',
  styles: [
  ]
})
export class ClaseVentaPageComponent {

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  public itemsCombo: ComboBoxEntity[] = [];

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Tipo: [{} as TipoVenta, Validators.required],
    Metodo: [{} as MetodoPago, Validators.required],
    ContadoCredito: [{} as FormaVenta, Validators.required],
    CuentaCxc: [{} as CuentaCxc],
    CondicionPago: [{} as CondicionPago],
    Observaciones: [''],
    NoPermitirDescuento:[false],
    Baja: false,
  })

  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {

    const ent = this.myForm.value;
    param.callback(true);
  }

  onItemSearched(entity: ClaseVenta): void {
    if (entity) {
      this.myForm.reset(entity);
      console.log(entity)
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: ClaseVenta) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Moneda").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  selectedTipo(entity: ComboBoxEntity) {
    this.myForm.controls["Tipo"].setValue(entity);
  }


  selectedCuenta(entity: ComboBoxEntity) {
    this.myForm.controls["CuentaCxc"].setValue(entity);
  }
  selectedCdo(entity: ComboBoxEntity) {
    this.myForm.controls["ContadoCredito"].setValue(entity);
  }

  selectedMetodo(entity: ComboBoxEntity) {
    this.myForm.controls["Metodo"].setValue(entity);
  }

  selectedCondicion(entity: ComboBoxEntity) {
    this.myForm.controls["CondicionPago"].setValue(entity);
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
