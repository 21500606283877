<div class="card text-center border-0" cdkDrag style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{'Búsqueda de facturas de resguardo' | titlecase}}
    </span>
  </div>
  <div class="card-body">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <form [formGroup]="myForm" autocomplete="off">
          <app-panel-base title="Filtros de búsqueda">
            <div class="panel-content">
              <component-ui-combo-box [required]="true" helpLine="Documento a emitir: Factura, Pedido, Cotización, etc."
                [extras]="'TipoDeComprobante.Clave as TipoComp, Clasificacion.Clave as Clasificacion'"
                formControlName="DocumentoVenta" label="Documento" [inputProperty]="'Nombre'" [zeroMask]="3"
                [setItemsList]="getItemsComps" [tabIndex]="1" [widthLabelColumns]="4" [widthTextColumns]="4"
                (onSelectedItem)="onSelectedItem($event,'DocumentoVenta')" [widthRightColumns]="0" #cboComp />
              <component-ui-combo-box [required]="true" formControlName="Serie"
                helpLine="Serie a emitir, depende del Documento seleccionado." label='Serie' [inputProperty]="'Serie'"
                [setItemsList]="getItemsSerie" [tabIndex]="2" [widthLabelColumns]="4" [widthTextColumns]="4"
                [widthRightColumns]="0" (onSelectedItem)="onSelectedItem($event,'Serie')" #cboSerie />
              <components-ui-text-box [required]="true" [tabIndex]="3" [label]="'Folio'" formControlName="Folio"
                [widthTextColumns]="6" (keydown.enter)="buscar($event)" [widthLabelColumns]="4" />
              <a (click)="buscar()" class="btn btn-orange mt-3"><i class="fa fa-search fa-fw fa-lg "></i>Buscar</a>
            </div>
          </app-panel-base>
          <!-- <app-panel-base title="Registro encontrado" style="height: 280px;">
            <div class="panel-content">
              <div class="card text-center w-100" *ngIf="lista.length>0">
                <div class="card-header fw-bold">
                  {{lista[0].cliente.clave}} {{lista[0].cliente.nombre}}
                </div>
                <div class="card-body">

                </div>
              </div>
            </div>
          </app-panel-base> -->
        </form>
      </div>
    </app-container-base>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="closeModal()" class="btn btn-danger">Cerrar</div>
    </div>
  </div>
</div>
