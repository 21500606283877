<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Edición de Asiento Contable</div>
  <app-panel-base title="Indique la información solicitada">
    <div class="panel-content">

      <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
        [hasCounter]="false">
        <div class="screen-content">
          <form [formGroup]="myForm" autocomplete="off">

            <component-ui-combo-box [label]="'Póliza'" [tabIndex]="1" formControlName="Poliza"
              entityName="PolizaAutomatica" [widthTextColumns]="8" [widthLabelColumns]="4" [widthRightColumns]="0"
              [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'Poliza')" [required]="true"
              [listProperty]="'ClaveNombre'" #cboPoliza />

            <components-ui-text-box [label]="'Código Contable'" [required]="true" formControlName="CodigoContable"
              [tabIndex]="2" [widthTextColumns]="8" [widthLabelColumns]="4" [maxLength]="100" />

          </form>
          <hr>
          <div style="text-align: right; margin-top: 5px;">
            <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
            <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
          </div>
        </div>
      </app-container-base>

    </div>
  </app-panel-base>
</div>
