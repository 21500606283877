<!-- <app-container-base [hasCounter]="false" icon="fa fa-money-bill-1" [hasBar]="true" [activeButtons]="activeButtons"
  (onClickBarButton)="clickBoton($event)" subTitle="Estado de cuenta por cliente" style="--bs-modal-width:700px" > -->
  <div class="screen-content" >
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Estado de cuenta del cliente" style="width:600px">
        <div class="panel-content" >
          <div class="card border-0 mb-0">
            <div class="card-header h6 mb-0 bg-none p-0">
              <i class="fa fa-circle-info fa-lg fa-fw text-blue text-opacity-50 me-1"></i>Impresión de estado de cuenta del cliente
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="100"
                    [widthLabelColumns]="3" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5" style="margin-left: 4px;">
                  <component-ui-combo-box  (onSelectedItem)="selectedCombo($event,'Num1')" [selectManualItem]="getMes1"
                    [setItemsList]="listaMeses" listProperty='Nombre' label="Del mes" [tabIndex]="101" [enabled]="!this.checkTodoElAnio"
                    [widthLabelColumns]="7" [widthTextColumns]="4" />
                </div>
                <div class="col-md-3">
                  <div class="d-flex mt-0 mt-0">
                    <component-ui-combo-box  (onSelectedItem)="selectedCombo($event,'Num2')" [selectManualItem]="getMes2"
                      listProperty='Nombre' [setItemsList]="listaMeses" label="al" [tabIndex]="102" [enabled]="!this.checkTodoElAnio"
                      [widthLabelColumns]="3" [widthTextColumns]="7" />
                  </div>
                </div>
                <div class="col-md-3">
                  <component-ui-check-box [label]="'Todo el año'" formControlName="Opc1" [tabIndex]="103"
                    [widthTextColumns]="1" [widthLabelColumns]="8"  (onClick)="changeOpc($event)"  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 labelCombo">
                    <component-ui-combo-box  (onSelectedItem)="selectedCombo($event,'Num3')" [selectManualItem]="getOrdenamiento"
                      listProperty='Nombre' [setItemsList]="listaordenamiento" label="al" [tabIndex]="104"  [inputProperty]="'Nombre'"
                      [widthLabelColumns]="3" [widthTextColumns]="4" />
                </div>
              </div>
            </div>
          </div>

          <div style="text-align: right; margin-top: 5px;">
            <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;" [tabIndex]="105">Imprimir</button>
            <button (click)="closeModal()" class="btn btn-danger" [tabIndex]="106">Salir</button>
          </div>
        </div>

      </app-panel-base>
    </form>
  </div>
<!-- </app-container-base> -->
