import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Validators } from 'ngx-editor';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ComboBoxEntity, Coordinates } from './../../../component-ui/interfaces/combo-text.interface';
import { Empresa } from './../../../configuracion/interfaces/empresa.interface';
import { Impuesto } from 'src/app/ventas/interfaces/impuesto.interface';
import { ClasificacionProducto, Producto, ProductoComposicion, ProductoRelacionado, ProductoUbicacion, TipoProducto, Unidad, ProductoSeleccionado } from '../../interfaces/producto.interface';
import { ContainerBaseService } from '../../../component-ui/services/container-base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { FamiliaProducto } from '../../interfaces/familia.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { Almacen } from 'src/app/home/interfaces/almacen.interface';
import { EmpresaSucursalDto, UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { EventsService } from 'src/app/service/events.service';
import { FacturacionService } from '../../../ventas/services/facturacion.service';
import { Observable } from 'rxjs';
import { MenuItem } from 'src/app/component-ui/interfaces/checkboxlist.interface';
import { ExistenciaProductoPaquete } from 'src/app/ventas/interfaces/venta.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';



@Component({
  selector: 'producto-page',
  templateUrl: './producto-page.component.html',
  styleUrls: ['./producto-page.component.css']
  //changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProductoPageComponent implements OnInit, AfterViewInit {

  // listaUnidades: ComboBoxEntity[] = [];
  // listaOtrasUnidades: ProductoUnidad[] = [];
  public filter: string = "";
  public itemsCombo: ComboBoxEntity[] = [];
  public searchConfigurationProducto: SearchConfiguration = this.ms.GetSearchConfiguration("Producto", ``);

  @ViewChild('busquedaProductos')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('ctrlExistencias')
  public ctrlExistencias!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductosRelacionados')
  public ctrlBusquedaProductoRelacionado!: ElementRef<HTMLElement>;

  @ViewChild('busquedaProductosRelacionados')
  public busquedaProdEquivalente!: ElementRef<HTMLElement>;



  // @ViewChild('gridRelacionado', { static: true })
  // gridRelacionadoComponent!: GuiGridComponent;


  @ViewChild('seleccionarFamilia')
  public ctrlseleccionarFamilia!: ElementRef<HTMLElement>;

  @ViewChild('clasificacion')
  public ctrlclasificacion!: ElementRef<HTMLElement>;

  @ViewChild('txtClave')
  public txtClave!: ElementRef<TextBoxComponent>;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;

  @ViewChild('busquedaProdServ')
  public ctrlBusquedaProdServ!: ElementRef<HTMLElement>;



  listaCompuestos: ProductoComposicion[] = [];
  CompuestoActual: ProductoComposicion | null = null;

  listaRelacionados: ProductoRelacionado[] = [];
  RelacionadoActual: ProductoRelacionado | null = null;


  sourceRelacionados: Array<ProductoRelacionado> = [];

  //* variables para el grid
  sourceProducts: any[] = [];
  esEscapeProducto: boolean = false;
  esEnterProducto: boolean = false
  enviarFocoProducto: boolean = false
  enviarOtroRenglon: boolean = false;
  cords: any = null;
  idUnico: string = '';
  colProducto: number = 2;


  selectedIndex: number = -1;
  editando: boolean = false;
  clickEditar: boolean = false;
  //public GuiColumnAlign = GuiColumnAlign;
  searchConfiguration: SearchConfiguration | null = null;
  loading: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  indexEditing: number = -1;
  imagenProducto: any = null;
  saving: boolean = false;
  file: any = null;
  buscandoProducto: boolean = false;

  private eventsService = inject(EventsService);
  private FacturacionService = inject(FacturacionService);
  private UtilsService = inject(UtilsService);
  private fServices = inject(FacturacionService)

  listaMarcas: MenuItem[] = [];

  // public GuiCellView = GuiCellView;
  // public GuiDataType = GuiDataType;
  columnEditing: number = -1;
  navigateColumns: boolean = false;

  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  usuarioActual: UserLogged = {} as UserLogged;







  editing: boolean = false;


  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: ['', Validators.required],
    Nombre: ['', Validators.required],
    SegundoNombre: ['', Validators.required],
    Empresa: [{} as Empresa, Validators.required],
    Impuesto: [{} as Impuesto],
    CodigoBarras: [''],
    CuentaPredial: [''],
    ActualizarDescrpcion: [false],
    ManejaPaquetesLotes: [false],
    ManejaFechaCaducidad: [false],
    SeProrrateaEnCompras: [false],
    Clasificacion: [{} as ClasificacionProducto],
    Tipo: [{} as TipoProducto],
    Inventariable: [false],
    RutaImagen: [''],
    ImagenBase64: [''],
    DetalleDelProducto: [''],
    PermitirNegativos: [false],
    PorcentajeUtilidad: [0],
    ActualizarDescrpcionBajoDemanda: [false],
    NoDecimalesCantidad: [false],
    UnidadCompra: [null],
    ActivaFormulacion: [false],
    ActivaFormulacionPieCubico: [false],
    ActivaFormulacionMetroCuadrado: [false],
    ActivaFormulacionImpresion: [false],
    ProveedorCompra: [null],
    ProveedorCompra2: [null],
    DivisorFormulacion: [0],
    ConversionCompraVenta: [0],
    Compuesto: [false],
    SolicitaNumeroPedimento: [false],
    SolicitaSeries: [false],
    EsParaUsoEmpresa: [false],
    EsParaAnticipo: [false],
    Unidad: [{} as Unidad, Validators.required],
    EsAgape: [false],
    ProductoEquivalente: [{} as Producto],
    Familia: [{} as FamiliaProducto],
    Almacen: [{} as Almacen, Validators.required],
    ClaveSAT: ['', Validators.required],
    ClaveSATNombre: ['', Validators.required],
    Ubicaciones: [{} as ProductoUbicacion[]],
    Compuestos: [{} as ProductoComposicion[]],
    Relacionados: [{} as ProductoRelacionado[]],
    AplicaVentas: [false],
    AplicaCompras: [false],
    OtrasUnidades: this.fb.array([]),
    Eliminado: [false],
    ParaFacturaGlobal: [false],
    FichaTecnica: [''],
    CantidadPOS: [0],
    Baja: [false],
    SeleccionActual: '',
    Asientos: [[]],
    AnioSeleccionActual: '',
    ProductoEquivalenteNombre: '',
    ProductoEquivalenteClave: ''
  });
  info: UserLogged = {} as UserLogged;
  //OtrasUnidades: this.fb.array([]),
  //private cd: ChangeDetectorRef
  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private ms: ModalService, private http: HttpClient, private sanitizer: DomSanitizer, private fileService: FileUploadService, private utilsService: UtilsService) { }
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utilsService.getUserLogged();
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.usuarioActual = this.utilsService.getUserLogged();
    this.searchConfiguration = this.ms.GetSearchConfiguration("c_ClaveProdServ", '');
    this.info = this.utilsService.getUserLogged();
    this.listaMarcas = this.utilsService.getListaMarcasAutos();

  }

  searchFile() {
    this.txtFile.nativeElement.click();
  }

  // marcas

  onSelect(item: any) {
    item.IsExpanded = !item.IsExpanded;
    this.listaMarcas = [...this.listaMarcas];
  }

  seleccionar(item: MenuItem) {
    item.Seleccionado = !item.Seleccionado;
    if (item.NombreFinal) {
      this.myForm.get('SeleccionActual')?.setValue(item.NombreFinal);
    } else {
      this.myForm.get('SeleccionActual')?.setValue("");
      this.myForm.get('AnioSeleccionActual')?.setValue("");

    }

    if (item.SubMenus) {
      item.SubMenus.map((item2) => {
        item2.Seleccionado = item.Seleccionado

        if (item2.SubMenus) {
          item2.SubMenus.map((item3) => {
            item3.Seleccionado = item.Seleccionado;
          });
        }

        return item2;
      });
    }
    this.listaMarcas = [...this.listaMarcas];
  }


  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  openExistencias() {

    const b: any = this.ctrlExistencias;
    this.ms.openModal(b, (e: ExistenciaProductoPaquete | null) => {

    }, 'xl')

  }

  getCaret(item: MenuItem, lastLevel: boolean = false): string {
    let ddclass: string = `fas fa-${(item.IsExpanded ? 'caret-down' : 'caret-right')}  fa-lg`;
    if (lastLevel) {
      ddclass = " mleft";
    }
    return ddclass;
    //return "";
  }

  //* control de flujo grid para ProdRelacionados


  buscarCatalogo(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value)) {
      this.cords = null;
      this.sourceProducts = [];
      return;
    }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Cliente) { return }
    this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProducts = lista;
      if (this.sourceProducts.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });

  }


  navegarProductos(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
    }
  }


  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, this.colProducto);
    }
  }

  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }

    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeProducto = true;
        this.indexEditing = this.indexEditing + 1;
        this.sourceRelacionados = [...this.sourceRelacionados, {
          Id: 0,
          Producto: null,
          NoIdentificacion: '',
          Descripcion: ''
        }];
        this.initEditor(this.indexEditing, this.colProducto);
      }
    }
  }

  getLastItem(): number {
    return this.sourceRelacionados.filter(P => P.Producto).length;
  }


  escapeProducto(e: any) {
    let index = this.indexEditing;
    this.esEscapeProducto = true;
    let item: ProductoRelacionado = this.sourceRelacionados[index];
    e.target.value = item.Producto?.Clave ? item.Producto?.Clave : "";
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.sourceRelacionados[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        //this.initEditor(index, 1);
        this.enviarFocoProducto = true;
        this.sendFocus();
      } else {
        //todo a donde se enviara el foco
        // const txt: any = this.esGlobalcheckBox;
        // txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }

  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarOtroRenglon = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  focusProducto(index: number, e: any, item: ProductoRelacionado) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }




  setIndexEdit(index: number, e: any, item: ProductoRelacionado) {

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  blurProducto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let item = { ...this.sourceRelacionados[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }

        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.enviarOtroRenglon) {
            this.sendFocus();
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 100);
  }

  searchProduct(value: string) {
    if (!value.trim()) {
      return;
    };

    this.loading = true;
    const params = new HttpParams().set("idEmpresa", this.info.empresa?.numero!).set("idSucursal", this.info.sucursal?.numero!).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        let item = this.sourceRelacionados[this.indexEditing];
        item.Producto = { Id: producto.Id, Nombre: producto.Nombre, Clave: producto.Clave } as Producto;
        item.NoIdentificacion = producto.Clave;
        item.Descripcion = producto.Nombre;
        this.sourceProducts = [];
        this.sendFocus();
      } else {
        Swal.fire({
          text: "No se encontró el Producto indicado.",
          icon: 'error',
        }).then(() => {
          this.sourceRelacionados[this.indexEditing].NoIdentificacion = "";
          this.sourceRelacionados = [...this.sourceRelacionados];
          this.initEditor(this.indexEditing, this.colProducto);
        })
      }
    })
  }



  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoProducto) {
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarFocoProducto = false;
      }

      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  sourceReset(productos: any = []) {
    if (!productos) {
      this.sourceRelacionados = [];
    } else {
      this.sourceRelacionados = productos;
    }

    this.sourceRelacionados.map(p => {
      if (p.Producto) {
        p.Descripcion = p.Producto?.Nombre!;
        p.NoIdentificacion = p.Producto?.Clave!;
        p.Producto = { Id: p.Producto!.Id, Clave: p.Producto!.Clave, Nombre: p.Producto!.Nombre } as Producto;
      }
    });
    let totalRegs = 1;
    if (this.sourceRelacionados.length < 8) {
      totalRegs = 8;
    }
    for (let index = 0; index < totalRegs; index++) {
      this.sourceRelacionados = [...this.sourceRelacionados, {
        Id: 0,
        Producto: null,
        NoIdentificacion: '',
        Descripcion: ''
      }]
    }
  }



  // sourceRelacionadoEdited(e: any) {
  //   if (this.columnEditing == 1) {
  //     if ((e.after.NoIdentificacion != e.before.NoIdentificacion) && e.after.NoIdentificacion != "") {

  //       if (e.after.NoIdentificacion == this.myForm.value.Clave) {
  //         Swal.fire({
  //           text: "No es posible capturar el mismo Producto como producto relacionaso.",
  //           icon: 'error',
  //         }).then(() => {
  //           this.sourceRelacionados[this.indexEditing].NoIdentificacion = "";
  //           this.sourceRelacionados = [...this.sourceRelacionados];
  //           this.initEditor(this.indexEditing, 1);
  //         })
  //         return;
  //       }

  //       this.searchProductRelacionado(e.after.NoIdentificacion).subscribe(producto => {
  //         if (producto) {
  //           let item = this.sourceRelacionados[this.indexEditing];
  //           item.Producto = producto;
  //           item.NoIdentificacion = producto.Clave;
  //           item.Descripcion = producto.Nombre;
  //           this.sourceReset(this.sourceRelacionados);
  //           this.indexEditing++;
  //           this.initEditor(this.indexEditing, 1);
  //         } else {
  //           Swal.fire({
  //             text: "No se encontró el Producto indicado.",
  //             icon: 'error',
  //           }).then(() => {
  //             this.sourceRelacionados[this.indexEditing].NoIdentificacion = "";
  //             this.sourceRelacionados = [...this.sourceRelacionados];
  //             this.initEditor(this.indexEditing, 1);
  //           })
  //         }

  //       });
  //     } else {
  //       if (e.after.NoIdentificacion) {
  //         this.indexEditing++;
  //         this.sourceReset(this.sourceRelacionados);
  //         this.navigateColumns && this.initEditor(this.indexEditing, 1);
  //       }
  //     }
  //   }
  // }

  // cellRelacionadoEditEntered(e: any) {
  //   setTimeout(() => {
  //     this.setColumnEditor();
  //   }, 100);
  // }


  // initEditor(row: number, col: number) {
  //   const elem: any = this.getElemEditor(row, col);
  //   if (elem) {
  //     setTimeout(() => {
  //       elem.firstElementChild?.click();
  //     }, 100);
  //   }
  // }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }


  searchProductRelacionado(value: string): Observable<any> {
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params });
  }

  // setColumnEditor() {
  //   const elems: any = document.getElementById("divRelacionados")!.querySelectorAll('.gui-content');
  //   elems.forEach((renglon: HTMLElement, indexRow: number) => {
  //     renglon.childNodes.forEach((nodeRow: any) => {
  //       let colNum = -1;
  //       nodeRow.childNodes.forEach((nodeColumna: any) => {
  //         if (nodeColumna.childNodes && nodeColumna.className) {
  //           colNum++;
  //           nodeColumna.childNodes.forEach((nodoCelda: any) => {
  //             if (nodoCelda.className == "gui-cell-edit-mode ng-star-inserted") {
  //               this.columnEditing = colNum;//parseInt(col);
  //               const elem: any = nodeColumna.childNodes[1].childNodes[0];
  //               elem.addEventListener("keydown", (e: any) => {

  //                 this.navigateColumns = (e.keyCode == 13);
  //                 if (this.columnEditing == 1 && e.keyCode == 113) {
  //                   this.abrirBuscarProductoRelacionado();
  //                 }

  //                 if (e.keyCode == 27) {
  //                   switch (this.columnEditing) {
  //                     // case 6: this.initEditor(this.indexEditing, 6); break;
  //                     // case 5: this.initEditor(this.indexEditing, 3); break;
  //                     //case 1: this.initEditor(this.indexEditing, 1); break;
  //                   }
  //                 }

  //               });
  //             }
  //           });
  //         }
  //       });
  //     })
  //   });
  //   if (this.columnEditing > 1) {
  //     if (!this.sourceRelacionados[this.indexEditing].Producto || this.sourceRelacionados[this.indexEditing].Producto?.Id == 0) {
  //       this.initEditor(this.indexEditing, 1);
  //     }
  //   }
  // }

  // abrirBuscarProductoRelacionado() {
  //   const b: any = this.ctrlBusquedaProductoRelacionado;
  //   this.ms.openModal(b, (e: any) => {
  //     this.searchProductRelacionado(e.Clave).subscribe(producto => {
  //       if (producto) {
  //         let item = this.sourceRelacionados[this.indexEditing];
  //         item.Producto = producto;
  //         item.NoIdentificacion = producto.Clave;
  //         item.Descripcion = producto.Nombre;
  //         this.sourceRelacionados = [...this.sourceRelacionados];
  //         this.sourceReset(this.sourceRelacionados);
  //         this.cd.detectChanges();
  //       } else {
  //         Swal.fire({
  //           text: "No se encontró el Producto indicado.",
  //           icon: 'error',
  //         }).then(() => {
  //           this.sourceRelacionados = [...this.sourceRelacionados];
  //           this.initEditor(this.indexEditing, 1);
  //         })
  //       }
  //     });
  //   }, 'lg');
  // }




  /*Grid events */
  // cellEditing: GuiCellEdit = {
  //   enabled: true,
  //   rowEdit: (value: any, item: ProductoRelacionado, index: number) => {
  //     this.indexEditing = -1;
  //     if (this.myForm.value.Id == 0) {
  //       this.eventsService.publish('home:showAlert', { message: `Para poder capturar productos relacionados, primero debe guardar el Producto.`, cancelButton: false });

  //       return false;

  //     }
  //     if (index > 0) {
  //       if (!this.sourceRelacionados[index - 1].Producto) {
  //         return false;
  //       }
  //       if (this.sourceRelacionados[index - 1].Producto!.Id == 0) {
  //         return false;
  //       }
  //     }
  //     this.indexEditing = index;
  //     return true;
  //   },
  //   cellEdit: (value: any, item: any, index: number) => {
  //     return true;
  //   }
  // }


  deleteRowRelacionado(indx: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar de la lista el Producto relacionado.?',
      textAccept: "Si, eliminar",
      textCancel: "Cancelar",
      icon: "fa-circle-question text-blue",
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          const idEliminar = this.sourceRelacionados[indx].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.FacturacionService.eliminarProductoRelacionadoDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.sourceRelacionados.splice(indx, 1);
              this.sourceRelacionados = [...this.sourceRelacionados];
            })
          } else {
            this.sourceRelacionados.splice(indx, 1);
            this.sourceRelacionados = [...this.sourceRelacionados];
          }
        }
      }
    });
  }

  keypressGrid(e: any) {
    console.log();
  }

  deleteRowCompuesto(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el Producto que compone?',
      textAccept: "Si, eliminar",
      textCancel: "Cancelar",
      icon: "fa-circle-question text-blue",
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          const idEliminar = this.listaCompuestos[index].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.FacturacionService.eliminarProductoCompuestoDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              if (result.success) {
                this.listaCompuestos.splice(index, 1);
                this.listaCompuestos = [...this.listaCompuestos];
              } else {
                this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
              }
            })
          } else {
            this.listaCompuestos.splice(index, 1);
            this.listaCompuestos = [...this.listaCompuestos];
          }
          this.listaCompuestos.splice(index, 1);
          this.listaCompuestos = [...this.listaCompuestos];
        }
      }
    });
    // Swal.fire({
    //   title: '¿Desea eliminar el Producto que compone?',
    //   showDenyButton: true,
    //   confirmButtonText: 'Si, eliminar',
    //   denyButtonText: `Cancelar`,
    // }).then((result) => {
    //   if (result.isConfirmed) {

    //   }
    // });
  }



  resetCompuestos(details: ProductoComposicion[] | null, continueEditing = false) {

    if (details) {
      this.listaCompuestos = [...details.filter(p => p.Producto)];
    } else {
      this.listaCompuestos = []
    }
    const length = this.listaCompuestos.length;
    this.listaCompuestos = [...this.listaCompuestos, {
      Id: 0,
      Producto: null,
      Cantidad: 0,
      Almacen: null,
      ConceptoSalida: null,
    }];

    setTimeout(() => {
      //const rows: any = document.getElementById("divCompuestos")!.querySelectorAll('.gui-content')[0].childNodes;
      // if (rows) {
      //   rows.forEach((item: any, index: number) => {
      //     if (item.classList) {
      //       item.classList.remove("editing-row");
      //       item.addEventListener("dblclick", () => {
      //         this.onDblClickRow(index);
      //       });
      //     }
      //   })
      // }
      if (continueEditing) {
        this.initEdit(this.selectedIndex + 1);
      } else {
        // this.editing = false;
        // this.selectedIndex = -1;
        // this.selectedItem = null;

        this.editando = false;
        this.selectedIndex = -1;
        this.CompuestoActual = null;
        //this.clickEditar = false;

      }


    }, 100);
  }



  // resetRelacionados(details: ProductoRelacionado[] | null,continueEditing = false) {

  //   if (details) {
  //     this.listaRelacionados = [...details.filter(p=>p.Producto)];
  //   } else {
  //     this.listaRelacionados = []
  //   }
  //   // if (!this.clickEditar) {
  //   //   this.listaCompuestos = [...this.listaCompuestos, {
  //   //     Id: 0,
  //   //     Producto: null,
  //   //     Cantidad: 0,
  //   //     Almacen: null,
  //   //     ConceptoSalida: null,
  //   //   }
  //   //   ];
  //   // }
  //   const length = this.listaRelacionados.length;
  //   this.listaRelacionados = [...this.listaRelacionados, {
  //     Id: 0,
  //     Producto: null
  //   }];

  //   setTimeout(() => {
  //     const rows: any = document.getElementById("divRelacionados")!.querySelectorAll('.gui-content')[0].childNodes;
  //     if (rows) {
  //       rows.forEach((item: any, index: number) => {
  //         if (item.classList) {
  //           item.classList.remove("editing-row");
  //           item.addEventListener("dblclick", () => {
  //             this.onDblClickRelacionadosRow(index);
  //           });
  //         }
  //       })
  //     }
  //     if (continueEditing) {
  //       this.initEdit(this.selectedIndex + 1);
  //     } else {
  //       // this.editing = false;
  //       // this.selectedIndex = -1;
  //       // this.selectedItem = null;
  //       this.editando = false;
  //       this.selectedIndex = -1;
  //       this.CompuestoActual = null;
  //       //this.clickEditar = false;
  //     }
  //   }, 100);
  // }








  onDblClickRow(index: number) {
    if (this.editando) return;
    // //const rows = document.getElementById("divCajaChica")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
    // const rows: any = document.getElementById("divCompuestos")!.querySelectorAll('.gui-content')[0].childNodes;
    // if (rows) {
    //   rows.forEach((item: any, index: number) => {
    //     if (item.classList) {
    //       item.classList.remove("editing-row");
    //       item.classList.remove("pulse-row");
    //     }
    //   })
    // }

    this.initEdit(index);
  }




  // onDblClickRelacionadosRow(index: number) {
  //   if (this.editando) return;
  //   //const rows = document.getElementById("divCajaChica")!.querySelectorAll(".gui-row.gui-structure-row.ng-star-inserted");
  //   const rows: any = document.getElementById("divRelacionados")!.querySelectorAll('.gui-content')[0].childNodes;
  //   if (rows) {
  //     rows.forEach((item: any, index: number) => {
  //       if (item.classList) {
  //         item.classList.remove("editing-row");
  //         item.classList.remove("pulse-row");
  //       }
  //     })
  //   }

  //   this.initEdit(index);
  // }


  initEdit(index: number) {
    setTimeout(() => {
      this.selectedIndex = index;
      const sel: any = this.listaCompuestos[this.selectedIndex];
      this.CompuestoActual = sel;
      this.editando = true;
      // const rows: any = document.getElementById("divCompuestos")!.querySelectorAll('.gui-content')[0].childNodes;
      // if (rows) {
      //   rows.forEach((item: any, indexT: number) => {
      //     if (item.classList && indexT == index) {
      //       item.classList.add("editing-row");
      //       item.classList.add("pulse-row");
      //       item.scrollIntoView({ block: "center" });
      //     }
      //   })
      // }
    }, 100);
  }

  async onChangeFile(event: any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imagenProducto = event.target.files[0];
      this.myForm.controls["RutaImagen"].setValue(file.name);
      let result: any = await this.toBase64(file);
      this.imagenProducto = result;
      this.myForm.controls["ImagenBase64"].setValue(result);
    } else {
    }

  }

  get imagePath() {

    if (this.myForm.value.ImagenBase64) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.myForm.value.ImagenBase64);
    } else {
      return "";
    }

  }

  get getFileName(): string {
    return this.file ? this.file.name : '';
  }


  onchange(event: any) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null;
  }


  toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  get OtrasUnidades(): FormArray {
    return this.myForm.get('OtrasUnidades') as FormArray;
  }

  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`;
      });

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }




  // Id: number;
  // Cantidad: number;
  // Unidad: c_Unidad;
  // EsParaKilos: boolean;
  // ProductoPadre: Producto;
  // Conversion: number;
  onBeforeSave(param: EventReturn) {
    if (!this.myForm.value.Familia) {
      this.eventsService.publish('home:showAlert', { message: `No se ha indicado la Familia del producto.`, cancelButton: false });
      param.callback(false);
      return;
    }

    if (this.myForm.value.UnidadCompra) {
      if (this.myForm.value.UnidadCompra.Id != this.myForm.value.Unidad.Id) {
        if (this.myForm.value.ConversionCompraVenta == 0) {
          this.eventsService.publish('home:showAlert', { message: `Debe de indicar el número de unidades de venta que representa una de compra.`, cancelButton: false });
          param.callback(false);
          return;
        }
      } else {
        this.myForm.get('ConversionCompraVenta')?.setValue(1);
      }
    }

    if (this.myForm.value.Familia.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `No se ha indicado la Familia del producto.`, cancelButton: false });
      param.callback(false);
      return;
    }

    let found: boolean = false;
    let item: ProductoRelacionado[] = [];
    let itemDuplicado: ProductoRelacionado;
    this.sourceRelacionados.forEach(el => {
      if (el.NoIdentificacion != "") {
        item = this.sourceRelacionados.filter(p => p.NoIdentificacion == el.NoIdentificacion);
        if (item) {
          if (item.length > 1) {
            itemDuplicado = el;
            found = true;
          }
        }
      }
    })


    if (found) {
      this.eventsService.publish('home:showAlert', { message: `No es posible capturar un producto relacionado dos veces, por favor verifique, producto duplicado` + itemDuplicado!.NoIdentificacion, cancelButton: false });
      return;
    }


    if (this.myForm.get('SegundoNombre')?.value == "") {
      this.myForm.get('SegundoNombre')?.setValue(this.myForm.get('Nombre')?.value);
    }





    this.OtrasUnidades.controls.forEach(item => {
      if (item.value.Id == null) {
        item.value.Id = 0;
      }

      if (item.value.EsParaKilos == null) {
        item.value.EsParaKilos = false;
      }
    });
    const compuestos = this.listaCompuestos.filter(P => P.Producto);
    this.myForm.controls["Compuestos"].setValue(compuestos);

    const relacionados = this.sourceRelacionados.filter(P => P.Producto);
    this.myForm.controls["Relacionados"].setValue(relacionados);

    if (this.myForm.value.Compuesto && compuestos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar los Productos componentes.`, cancelButton: false });
      param.callback(false);
      return;
    }




    param.callback(true);
  }

  onClickAplicaVentas(value: boolean) {
    //  this.myForm.get('AplicaCompras')?.setValue(!value);
  }

  onClickAplicaCompras(value: boolean) {
    //this.myForm.get('AplicaVentas')?.setValue(!value);
  }

  //  onChangeCantidad(value:any,item: ProductoUnidad) {
  //  }
  //  onChangeesParaKilos(event:any,item: ProductoUnidad) {
  //  }
  selectedComboOtras(entity: any, item: any) {
    item.Unidad = entity;
  }

  get getFilterProducto(): any {
    // if (this.searchConfigurationProducto) {
    //   return this.searchConfigurationProducto.filter;
    // }
    return "";
  }


  onItemSearched(ent: Producto): void {
    if (ent) {
      this.resetCompuestos(ent.Compuestos);
      this.sourceReset(ent.Relacionados)



      if (ent.Empresa) {
        this.filter = `Empresa.Id = ${ent.Empresa.Id}`;
      } else {
        this.filter = '';
        if (this.myForm.value) {
          if (this.myForm.value.Empresa) {
            if (!this.myForm.value.Empresa.Id) {
              this.containerService.getLastEntity("Empresa").subscribe(empresa => {
                ent.Empresa = empresa;
                this.myForm.get('Empresa')?.setValue(empresa);
                this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id}`;
              });
            } else {
              ent.Empresa = this.myForm.value.Empresa;
              this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id}`;
            }
          }
        }
      }

      while (this.OtrasUnidades.length !== 0) {
        this.OtrasUnidades.removeAt(0)
      }

      // this.OtrasUnidades.controls.forEach(item => {
      //   this.OtrasUnidades.removeAt(0);
      // });


      this.myForm.reset(ent);
      if (this.myForm.value.ProductoEquivalente) {
        this.myForm.controls["ProductoEquivalenteClave"].setValue(this.myForm.value.ProductoEquivalente.Clave);
        this.myForm.controls["ProductoEquivalenteNombre"].setValue(this.myForm.value.ProductoEquivalente.Nombre);
      } else {
        this.myForm.controls["ProductoEquivalenteClave"].setValue("");
        this.myForm.controls["ProductoEquivalenteNombre"].setValue("");
      }

      if (ent.Relacionados == null) {
        ent.Relacionados = [];

      }

      this.sourceReset(ent.Relacionados);


      if (ent.OtrasUnidades) {
        ent.OtrasUnidades.forEach(x => {
          let item = this.fb.group({
            Id: x.Id,
            Cantidad: x.Cantidad,
            EsParaKilos: x.EsParaKilos,
            Unidad: x.Unidad,
            ProductoPadre: x.ProductoPadre,
          });

          this.OtrasUnidades.push(item);
        });
      }


      // this.myFormArray = this.fb.group({
      //   OtrasUnidades: this.fb.array(
      //     this.listaOtrasUnidades.map(x=>{
      //         this.fb.group({
      //         Id: this.fb.control(x.Id),
      //         Cantidad: this.fb.control(x.Cantidad),
      //         Unidades: this.fb.control(x.Unidad),
      //         EsParaKilos: this.fb.control(x.EsParaKilos)
      //     })
      //     }
      //   ))
      // })
      if (ent.Clave === "") {
        this.focus('txtClave');
      } else {
        this.focus('txtNombre');
      }
    } else {

      let txt: any = this.txtClave;
      if (!txt) txt = "1";
      this.onNewCatalog(txt.value);
    }
  }




  isValidField(field: string) {
    return this.myForm.controls[field].errors;
  }
  agregaUnidad() {
    // let item: ProductoUnidad = {Id:0,Cantidad:0,Unidad:{}  as c_Unidad,EsParaKilos:false };
    // this.listaOtrasUnidades.push(item);

    let product: any = {
      Id: this.myForm.get('Id')?.value
    } as Producto;

    let item = this.fb.group({
      Id: 0,
      Cantidad: 0,
      EsParaKilos: false,
      Unidad: null,
      ProductoPadre: {}
    });

    this.OtrasUnidades.push(item);
  }

  onChangueEntity(ent: any) {
    this.myForm.reset(ent);
    if (this.myForm.value.ProductoEquivalente) {
      this.myForm.controls["ProductoEquivalenteClave"].setValue(this.myForm.value.ProductoEquivalente.Clave);
      this.myForm.controls["ProductoEquivalenteNombre"].setValue(this.myForm.value.ProductoEquivalente.Nombre);
    } else {
      this.myForm.controls["ProductoEquivalenteClave"].setValue("");
      this.myForm.controls["ProductoEquivalenteNombre"].setValue("");
    }

    while (this.OtrasUnidades.length !== 0) {
      this.OtrasUnidades.removeAt(0)
    }

    this.resetCompuestos(ent.Compuestos);
    this.sourceReset(ent.Relacionados)



    if (ent.OtrasUnidades) {
      ent.OtrasUnidades.forEach((x: any) => {
        let item = this.fb.group({
          Id: x.Id,
          Cantidad: x.Cantidad,
          EsParaKilos: x.EsParaKilos,
          Unidad: x.Unidad,
          ProductoPadre: x.ProductoPadre,
        });

        this.OtrasUnidades.push(item);
      });
    }


    this.focus("txtNombre");
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  enterField(e: any) {
    const txt: any = this.txtClave;
    txt.tagInput.nativeElement.blur();
  }

  get getEnableText(): boolean {
    return true;
  }

  onFocus() {
    this.buscandoProducto = true;
  }

  eliminarProductoUnidad(index: number) {

    this.OtrasUnidades.removeAt(index);
  }

  blur(values: any) {
    const txt: any = this.txtClave;
    this.buscandoProducto = false;
    if (!txt.value) {
      this.containerService.getLastEntity("Producto", this.filter).subscribe((ent) => {
        this.onChangueEntity(ent);
      });
      return;
    }
    if (values.before != values.after) {
      this.searchByField(txt.value);
    }
  }

  itemSeleccionado(elemento: any) {
    this.searchByField(elemento.Clave);
  }

  searchByField(value: number) {
    this.containerService.getEntityByClave("Producto", value.toString(), this.filter).subscribe((ent) => {
      if (ent) {
        this.onChangueEntity(ent);
      } else {
        this.containerService.getEmptyEntity("Producto")
          .subscribe((ent) => {
            ent.OtrasUnidades = [];
            ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
            this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id}`;
            this.onChangueEntity({ ...ent, Clave: value });

          });
      }
    });
  }

  onNewCatalog(next: string) {
    this.containerService.getEmptyEntity("Producto")
      .subscribe((elem: any) => {
        if (!next) next = "1";
        if (next === "") next = "1";
        elem.Clave = next;
        elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
        this.myForm.reset(elem);
        if (this.myForm.value.ProductoEquivalente) {
          this.myForm.controls["ProductoEquivalenteClave"].setValue(this.myForm.value.ProductoEquivalente.Clave);
          this.myForm.controls["ProductoEquivalenteNombre"].setValue(this.myForm.value.ProductoEquivalente.Nombre);
        } else {
          this.myForm.controls["ProductoEquivalenteClave"].setValue("");
          this.myForm.controls["ProductoEquivalenteNombre"].setValue("");
        }
        this.myForm.value.ProductoEquivalente.Clave
        this.resetCompuestos(elem.Compuestos);
        this.sourceReset(elem.Relacionados)
      });
  }


  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.get(tipo)!.setValue(entity);
  }

  get getIsNew(): any {
    return this.myForm.get("Id")?.value == 0;
  }

  focus(field: string) {
    setTimeout(() => {
      let txt: any;
      if (field === 'txtNombre') {
        txt = this.txtNombre;
      }
      else {
        txt = this.txtClave;
      }

      txt.tagInput.nativeElement.focus();
    }, 100);
  }


  onAfterSave(entity: Producto) {
    this.saving = false;
    this.myForm.reset(entity);
    if (this.myForm.value.ProductoEquivalente) {
      this.myForm.controls["ProductoEquivalenteClave"].setValue(this.myForm.value.ProductoEquivalente.Clave);
      this.myForm.controls["ProductoEquivalenteNombre"].setValue(this.myForm.value.ProductoEquivalente.Nombre);
    } else {
      this.myForm.controls["ProductoEquivalenteClave"].setValue("");
      this.myForm.controls["ProductoEquivalenteNombre"].setValue("");
    }
    this.sourceReset(entity.Relacionados);
    this.resetCompuestos(entity.Compuestos);

  }

  abrirSelecionarFamilia() {
    const b: any = this.ctrlseleccionarFamilia;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Familia"].setValue(e);
      }
    }, 'widthfamilia');
  }

  get getFamilyName(): string {

    if (this.myForm.value.Familia) {
      if (this.myForm.value.Familia.Id > 0 || this.myForm.value.Familia.id) {
        if (this.myForm.value.Familia.Nombre) return `${this.myForm.value.Familia.ClaveGeneral} ${this.myForm.value.Familia.Nombre}`;
        if (this.myForm.value.Familia.nombre) return `${this.myForm.value.Familia.claveGeneral} ${this.myForm.value.Familia.nombre}`;
      }
    }

    return "No ha seleccionado una familia";
  }

  // abrirBuscarProducto() {
  //   const b: any = this.ctrlBusqueda;
  //   this.ms.openModal(b, (e: any) => {
  //     this.searchProduct(e.Id);
  //   }, 'lg');
  // }





  abrirBusquedaProductoEquivalente() {
    const b: any = this.busquedaProdEquivalente;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        if (this.myForm.value.Id) {
          if (e.Id == this.myForm.value.Id) {
            this.eventsService.publish('home:showAlert', { message: "No puede seleccionar el mismo Producto como Producto equivalente, por favor verifique.", cancelButton: false });
            return;
          }
        }
        this.myForm.controls["ProductoEquivalente"].setValue(e);
        this.myForm.controls["ProductoEquivalenteClave"].setValue(e.Clave);
        this.myForm.controls["ProductoEquivalenteNombre"].setValue(e.Nombre);
      }
    }, 'lg');
  }


  abrirBusquedaClaves() {
    const b: any = this.ctrlBusquedaProdServ;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["ClaveSAT"].setValue(e.Clave);
        this.myForm.controls["ClaveSATNombre"].setValue(e.Nombre);
        setTimeout(() => {
          const elem: any = this.ctrlclasificacion;
          elem.tagInput.nativeElement.focus();
        }, 150);
      }
    }, 'lg')
  }

  get getColumns(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.columns;
    }
    return [];
  }

  get getFilter(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.filter;
    }
    return "";
  }

  get getPropertys(): string {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }


  get getSelectedCompuestoActual(): ProductoComposicion | null {
    return this.CompuestoActual;
  }


  // onCloseEdit(mov: CajaChicaDetalle): void {
  //   if (!mov) {
  //     this.editing = false;
  //     this.selectedIndex = -1;
  //     this.selectedItem = null;
  //     this.resetSource(this.detalle, false);
  //     setTimeout(() => {
  //       const t: any = this.txtNombre;
  //       t.tagInput.nativeElement.focus();
  //     }, 100);
  //   } else {
  //     this.detalle[this.selectedIndex] = mov;
  //     this.detalle.map((i, index) => { i.orden = index + 1; return i; })
  //     this.resetSource(this.detalle, true);
  //     this.editing = false;
  //     this.selectedItem = null
  //     setTimeout(() => {
  //       this.selectedIndex++;
  //       this.initEdit(this.selectedIndex);
  //     }, 100);

  //   }
  // }


  onCloseEdit(mov: ProductoComposicion): void {
    if (!mov) {
      this.editing = false;
      this.selectedIndex = -1;
      this.CompuestoActual = null;
      this.resetCompuestos(this.listaCompuestos, false);
      setTimeout(() => {
        // const t: any = this.txtNombre;
        // t.tagInput.nativeElement.focus();
      }, 100);
    } else {
      this.listaCompuestos[this.selectedIndex] = mov;
      this.resetCompuestos(this.listaCompuestos, true);
      this.editing = false;
      this.CompuestoActual = null
      setTimeout(() => {
        this.selectedIndex++;
        this.initEdit(this.selectedIndex);
      }, 100);

    }
  }


  getElemEditor(row: number, col: number) {
    const elem: any = document.querySelector(`[ng-reflect-column-index='${col}'][ng-reflect-row-index='${row}']`);
    return elem;
  }


  @ViewChild('modalImpresionProductos')
  public modalImpresionProductos!: ElementRef<HTMLElement>;


  clickButtonBar(event: any) {
    console.log(event);
    if (event === "print") {
      this.ms.openModal(this.modalImpresionProductos, (e: any) => {
        if (e) { }
      }, 'lg')
    }
  }
}
