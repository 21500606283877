<div class="border-avatar">
  <div class="circle {{size}}" [ngStyle]="{'background-color':  circleColor }">
    <img *ngIf="photoUrl" src="{{photoUrl}}" class="animate__animated animate__fadeIn" (load)="onLoad($event)">
    <div *ngIf="!photoUrl" class="initials">
      {{ initials }}
    </div>
    <span *ngIf="loadSpinner" class="loader"></span>
  </div>
</div>
<button (click)="openFile()" *ngIf="isImgProfile" type="button" class="btn btn-gray btn-icon btn-edit-img">
  <i class="fas fa-pencil-alt"></i>
</button>

<input class="fileInputPhoto" (change)="onchange($event)" accept=".jpg, .jpeg, .png" type="file" #imgFile>

<ng-template #cropperImage let-modal let-c="close">
  <app-panel-base title="Foto de Perfil">
    <div class="panel-content">
      <div class="cropper-wrapper">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
          [containWithinAspectRatio]="false" [aspectRatio]="1/1" [cropperMinWidth]="128" [onlyScaleDown]="true"
          [roundCropper]="true" [(transform)]="transform" [alignImage]="'center'"
          [style.display]="showCropper ? null : 'none'" [allowMoveImage]="allowMoveImage" [hidden]="hidden"
          imageAltText="Alternative image text" output="blob" format="png" (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
        <div *ngIf="loading" class="loader">Loading...</div>
      </div>
      <input type="range" min="0" max="20" value="0" (input)="changeZoom($event)" />
      <!-- <img class="circle" [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" /> -->
      <div style="text-align: right; margin-top: 5px;">
        <button (click)="accept()" class="btn btn-success me-5px">Aceptar</button>
      </div>
    </div>
  </app-panel-base>
</ng-template>
