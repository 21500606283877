<app-panel-base title="Cancelar documento">
  <div class="panel-content">
    <form [formGroup]="myForm" autocomplete="off">
      <components-ui-date-box [tabIndex]="1" formControlName="Fecha" label="Fecha" [widthLabelColumns]="3" [widthTextColumns]="3" [required]="true"/>
      <components-ui-text-box [tabIndex]="2" formControlName="MotivoCancelacion" [maxlength]="2000" label="Motivo" [widthLabelColumns]="3" [widthTextColumns]="9" />
      <div style="text-align: right; margin-top: 5px;">
        <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
        <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
      </div>
    </form>
  </div>
</app-panel-base>
