import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, computed, inject } from '@angular/core';
import { Ubicacion, UbicacionDomicilio } from '../../interfaces/cartaporte.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { intlFormatDistanceWithOptions } from 'date-fns/fp';
import { CartaPorteService } from '../../services/cartaporte.service';
import Swal from 'sweetalert2';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { CodigoPostal } from '../../../component-ui/interfaces/codigopostal.interface';
import { CanActivateFn } from '@angular/router';
import { EnumDataType } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventsService } from 'src/app/service/events.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';

@Component({
  selector: 'app-ctrl-edicion-ubicacion',
  templateUrl: './ctrl-edicion-ubicacion.component.html',
  styles: [
  ]
})
export class CtrlEdicionUbicacionComponent implements OnInit {
  public EDT = EnumDataType;
  @ViewChild('chkOrigen')
  public chkOrigen!: ElementRef<any>;
  @ViewChild('txtEstado')
  public txtEstado!: ElementRef<any>;
  @ViewChild('busquedaLocalidades')
  public busquedaLocalidades!: ElementRef<HTMLElement>;
  @ViewChild('busquedaEstados')
  public busquedaEstados!: ElementRef<HTMLElement>;
  @ViewChild('busquedaColonias')
  public busquedaColonias!: ElementRef<HTMLElement>;
  @ViewChild('busquedaMunicipios')
  public busquedaMunicipios!: ElementRef<HTMLElement>;
  @Input()
  public colocarMargen: boolean = true;
  @Input()
  public esOrigen: boolean = false;
  @Input()
  public tabIndex: number = 0;
  @Input()
  public item!: Ubicacion;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();

  saving: boolean = false;
  buscando: boolean = false;

  ngOnInit(): void {
    this.searchConfiguration = this.ms.GetSearchConfiguration("c_Colonia", '');
    setTimeout(() => {
      if (this.item) {
        let tieneId = false;
        if (this.item?.Id >= 0) {
          tieneId = true;
        }
        if (tieneId) {
          if (this.item && this.esOrigen) { this.item.EsOrigen = this.esOrigen; }
          if (!this.item.EsOrigen) { this.item.EsDestino = true; }

          this.myForm.reset(this.item);

        } else {
          this.containerService.getEmptyEntity("Ubicacion").subscribe((elem) => {
            elem.FechaHoraSalidaLlegada = new Date();
            this.myForm.reset(elem);
            this.focus();
          });
        }
        if (this.item.Domicilio) {
          this.formDomicilio.reset(this.item.Domicilio);
        }
      }else{
        this.containerService.getEmptyEntity("Ubicacion").subscribe((elem) => {
          elem.FechaHoraSalidaLlegada = new Date();
          this.myForm.reset(elem);
          this.focus();
        });
      }
    }, 150);
  }

  focus() {
    setTimeout(() => {
      const elem: any = this.chkOrigen;
      elem.tagInput.nativeElement.focus();
    }, 150);
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    EsOrigen: [false],
    EsDestino: [false],
    TipoUbicacion: [''],
    IDUbicacion: [''],
    RFCRemitenteDestinatario: [''],
    NombreRemitenteDestinatario: [''],
    FechaHoraSalidaLlegada: [Date],
    Hora: [''],
    DistanciaRecorrida: [0],
    Domicilio: [{} as UbicacionDomicilio],
  })


  private eventsService = inject(EventsService);
  public formDomicilio: FormGroup = this.fb.group({
    Id: [0],
    Calle: [''],
    NumeroExterior: [''],
    NumeroInterior: [''],
    Colonia: [''],
    Localidad: [''],
    Referencia: [''],
    Municipio: [''],
    Estado: [''],
    Pais: ['MEX'],
    CodigoPostal: [''],
  })
  searchConfiguration: SearchConfiguration | null = null;
  constructor(private fb: FormBuilder, private cpService: CartaPorteService, private ms: ModalService, private containerService: ContainerBaseService) {
  }

  get getColumns(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.columns;
    }
    return [];
  }

  get getStyle(): string {
    let s = "width: 540px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
    return s;
  }

  get getPropertys(): string {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getFilter(): any {
    return `CodigoPostal = "${this.formDomicilio.value.CodigoPostal}"`;
  }

  get getFilterMunicipio(): any {
    return `Estado = "${this.formDomicilio.value.Estado}"`;
  }

  get getFilterEstado(): any {
    return `Pais = "${this.formDomicilio.value.Pais}"`;
  }
  get getFilterLocalidades(): any {
    return `Estado = "${this.formDomicilio.value.Estado}"`;
  }



  abrirBusquedaEstados() {
    if (this.formDomicilio.value.Estado) {
      const b: any = this.busquedaEstados;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.formDomicilio.controls["Estado"].setValue(e.Clave);
          // setTimeout(() => {
          //   const elem: any = this.txtPeso;
          //   elem.tagInput.nativeElement.focus();
          // }, 150);
        }
      }, 'lg')
    }
  }

  abrirBusquedaLocalidades() {
    if (this.formDomicilio.value.Estado) {
      const b: any = this.busquedaLocalidades;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.formDomicilio.controls["Localidad"].setValue(e.Clave);
          // setTimeout(() => {
          //   const elem: any = this.txtPeso;
          //   elem.tagInput.nativeElement.focus();
          // }, 150);
        }
      }, 'lg')
    }
  }

  abrirBusquedaMunicipios() {
    if (this.formDomicilio.value.Estado) {
      const b: any = this.busquedaMunicipios;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.formDomicilio.controls["Municipio"].setValue(e.Clave);
          // setTimeout(() => {
          //   const elem: any = this.txtPeso;
          //   elem.tagInput.nativeElement.focus();
          // }, 150);
        }
      }, 'lg')
    }
  }

  abrirBusquedaColonias() {
    if (this.formDomicilio.value.CodigoPostal) {
      const b: any = this.busquedaColonias;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.formDomicilio.controls["Colonia"].setValue(e.Clave);
          // setTimeout(() => {
          //   const elem: any = this.txtPeso;
          //   elem.tagInput.nativeElement.focus();
          // }, 150);
        }
      }, 'lg')
    }
  }

  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }


  onClick(checked: boolean, type: number) {
    if (type == 1) {
      if (checked) {
        this.myForm.controls["EsDestino"].setValue(!checked);
      } else {
        setTimeout(() => {
          this.myForm.controls["EsOrigen"].setValue(true);
          this.myForm.controls["EsDestino"].setValue(false);
        }, 50);
      }
    } else {
      this.myForm.controls["EsOrigen"].setValue(!checked);
    }
  }

  blurCP(values: any) {

    if (values.after != values.before) {
      this.saving = true;
      this.cpService.getCodigoPostal(values.after).subscribe((codigoPostal) => {
        this.saving = false;
        if (codigoPostal) {
          this.formDomicilio.controls["Estado"].setValue(codigoPostal.estado);
          this.formDomicilio.controls["Localidad"].setValue(codigoPostal.localidad);
          this.formDomicilio.controls["Municipio"].setValue(codigoPostal.municipio);
        } else {
          this.formDomicilio.controls["Estado"].setValue("");
          this.formDomicilio.controls["Localidad"].setValue("");
          this.formDomicilio.controls["Municipio"].setValue("");
        }
        setTimeout(() => {
          const elem: any = this.txtEstado;
          elem.tagInput.nativeElement.focus();
        }, 50);
      })
    }
  }

  accept() {

    if (!this.myForm.value.FechaHoraSalidaLlegada) {
      this.eventsService.publish('home:showAlert', { message: `Indique fecha y hora.`, cancelButton: false });
      return false;
    }
    if (this.myForm.value.EsDestino && this.myForm.value.DistanciaRecorrida == 0) {
      this.eventsService.publish('home:showAlert', { message: `Indique la distancia recorrida.`, cancelButton: false });
      return false;
    }
    if (!this.formDomicilio.value.Pais) {
      this.eventsService.publish('home:showAlert', { message: `Indique el país.`, cancelButton: false });
      return false;
    }
    if (!this.formDomicilio.value.CodigoPostal) {
      this.eventsService.publish('home:showAlert', { message: `Indique el código postal.`, cancelButton: false });
      return false;
    }
    if (!this.formDomicilio.value.Estado) {
      this.eventsService.publish('home:showAlert', { message: `Indique el estado.`, cancelButton: false });
      return false;
    }
    let form = this.myForm.value;
    form.TipoUbicacion = form.EsOrigen ? "Origen" : "Destino";
    if (!form.IDUbicacion) {
      form.IDUbicacion = (form.EsOrigen ? "OR" : "DE") + (Math.floor(Math.random() * 999999).toString().padStart(6, '0'));
    }
    form.Domicilio = this.formDomicilio.value;
    this.onCloseEdition.emit(form)
    this.ms.closeModal(form);
    return;
  }

  closeModal() {
    this.onCloseEdition.emit(null)
    this.ms.closeModal(null);
  }

}
