<app-container-base [setIsNew]="getIsNew" [hasBar]="!abrirListado" [hasCounter]="false" [entity]="myForm"
  icon="fa fa-address-book" [activeButtons]="activeButtons" (onClickBarButton)="onClickBarButton($event)"
  subTitle="Emisión de Remisiones para Resguardos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div style="width:1000px;">
          <div class="d-flex" style="height: 265px;">
            <div style="width:632px;margin-right:10px;height: 255px;">
              <app-panel-base title="Selección de la serie de remisión">
                <div class="panel-content">
                  <div class="d-flex">
                    <component-ui-combo-box [enabled]="!abrirListado" style="width:20%; margin-right: 5px;"
                      formControlName="Serie" [topLabel]="true" label='Serie' [inputProperty]="'Serie'"
                      [setItemsList]="getItemsSerie" [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12"
                      [widthRightColumns]="0" (onSelectedItem)="onSelectedItem($event,'Serie')" #cboSerie />

                    <components-ui-number-box [enabled]="!abrirListado" style="width:20%;" [topLabel]="true"
                      formControlName="Folio" (blur)="blurFolio($event)" label='Folio' [tabIndex]="2"
                      [widthLabelColumns]="12" [widthTextColumns]="12" #txtFolio />

                    <components-ui-number-box [enabled]="!abrirListado" [enableIcon]="true"
                      style="width:20%;margin-left: 4px;" [topLabel]="true" label='Cliente' [tabIndex]="3"
                      [widthLabelColumns]="12" [widthTextColumns]="12" (keydown.enter)="enterCliente()"
                      (blur)="blurCustomer($event)" (keydown.f2)="openCustomerSearch()"
                      (onClicIcon)="openCustomerSearch()" #txtCliente />
                    <h6 class="mt-4" style="width: 55%;margin-left: 5px;">
                      {{
                      myForm.value.Cliente?.Id>0 ? (myForm.value.Cliente.Clave + " - " +
                      (myForm.value.Cliente.Nombre|uppercase) + " ["+myForm.value.Cliente.Rfc+"]"): ''
                      }}
                    </h6>
                  </div>
                  <div class="text-end">
                    <a class="btn btn-orange " (click)="onBuscarFacturasResguardo()"> <i
                        class="fas fa-magnifying-glass me-2"></i>Buscar Por Factura</a>
                  </div>
                </div>
              </app-panel-base>
              <app-panel-base title="Comentarios" style="height: 157px;">
                <div class="panel-content">
                  <textarea maxlength="1000" formControlName="Comentarios" class="w-100" rows="5" cols="50"
                    #txtComentarios></textarea>
                </div>
              </app-panel-base>
            </div>
            <app-panel-base (onClicIcon)="onClicEdit()" title="Dirección de entrega" [activeIconEdit]="true"
              [toolTipIcon]="'Editar dirección de entrega'" style="width:350px;height: 255px;">
              <div class=" panel-content">
                <address *ngIf="myForm.value.DireccionEntrega">
                  <strong class="text-dark">Recibe: {{myForm.value.DireccionEntrega.NombreRecibe | titlecase}}</strong>
                  <hr>
                  {{myForm.value.DireccionEntrega.Calle}} No. {{myForm.value.DireccionEntrega.NumeroExterior}}
                  {{myForm.value.DireccionEntrega.NumeroInterior ? ', Interior: ':''}}
                  {{myForm.value.DireccionEntrega.NumeroInterior}}<br>
                  {{myForm.value.DireccionEntrega.Localidad}}, {{myForm.value.DireccionEntrega.Estado}}<br>
                  {{myForm.value.DireccionEntrega.Pais}}, C.P. {{myForm.value.DireccionEntrega.CodigoPostal}}<br>
                  Teléfono: <a
                    href="tel:+52{{myForm.value.DireccionEntrega.Telefono}}">{{myForm.value.DireccionEntrega.Telefono}}</a>
                  <br> Referencias:
                  {{myForm.value.DireccionEntrega.Descripcion}}
                  <!-- <a class="btn btn-success d-block mb-10px me-2"><i
                      class="fa fa-map-pin t-plus-1 text-white fa-fw fa-lg"></i>
                    Ver En Mapa</a> -->
                </address>
              </div>
            </app-panel-base>
          </div>
          <app-panel-base title="Conceptos" style="height: 350px;">
            <div class="panel-content">
              <div class="p-2" style="height: 296px; width: 968px; border: 1px solid #e2e2e2;border-radius: 3px;">
                <ng-scrollbar style="height: 150px; width:950px; border-bottom: 1px solid #AAAAAA;">
                  <table class="blueTable" style="width:940px;">
                    <thead style="position: sticky;top:0;">
                      <tr>
                        <th style="width: 40px;"></th>
                        <th style="width: 70px;">Producto</th>
                        <th style="width: 220px;">Descripción</th>
                        <th style="width: 70px;text-align:right">Pendiente</th>
                        <th style="width: 70px;text-align:right">Entregar</th>
                        <th style="width: 70px;text-align:right">Saldo</th>
                        <th style="width: 180px;">Unidad</th>
                        <th style="width: 250px;">Conversiones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                        *ngFor="let item of lista; let index = index;">
                        <td style="text-align: center;">
                          <i (click)="deleteRow(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-lg"
                            style="color:red;font-weight: bold; cursor: pointer;"></i>
                        </td>
                        <td class="text-dark" style="width:70px;text-align:right;padding-right:5px;">
                          <span>{{item.Producto.Clave}}</span>
                        </td>
                        <td class="text-dark">
                          <span>{{item.Descripcion}}</span>
                        </td>
                        <td style="width: 70px;text-align:right;padding-right:5px" class="text-dark">
                          <span>{{item.CantidadAnterior|number:'1.2-6'}}</span>
                        </td>
                        <td style="width: 70px;">
                          <input (keydown)="keyDownCantidad($event)" (keydown.escape)="escapeCantidad($event)"
                            (keydown.arrowup)="up($event,1)" (keydown.arrowdown)="down($event,1)"
                            (keydown.enter)="enterCantidad($event)" class="grid-editor text-dark"
                            [id]="'txt_'+index+'_1' + idUnico" (blur)="blurCantidad($event)" type="number"
                            (focus)="focusCantidad(index, $event, item)"
                            [style]="'width:100%;text-align:right; padding-right:5px'"
                            [value]="item.Producto ? item.Cantidad: 0" />
                        </td>
                        <td style="width: 70px;text-align:right;padding-right:5px" class="text-dark">
                          <span>{{item.CantidadSaldo|number:'1.2-6'}}</span>
                        </td>
                        <td class="text-dark">
                          <span>{{item.Unidad.Nombre}}</span>
                        </td>
                        <td>
                          <a *ngIf="item.TextoConversiones" class="text-dark"
                            style="margin-left: 3px;">{{item.TextoConversiones}} </a>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </ng-scrollbar>
                <hr>
                <h3 style="text-align: right;">Total Cantidad: {{getCantidad|number:'1.2-6'}}</h3>
              </div>
            </div>
          </app-panel-base>
          <app-panel-base title="Usuarios" *ngIf="myForm.value.Id>0">
            <div class="panel-content">
              <div class="widget-list mb-0" style="margin-left:-15px;display: flex;">
                <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.UsuarioElabora">
                  <div class="widget-list-media">
                    <i class="fas fa-user-check fa-2x text-green"></i>
                  </div>
                  <div class="widget-list-content">
                    <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre | titlecase}}</h4>
                    <p class="widget-list-desc">Usuario elaboró documento</p>
                  </div>
                </div>
                <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.UsuarioCancela">
                  <div class="widget-list-media">
                    <i class="fas fa-user-xmark fa-2x text-red"></i>
                  </div>
                  <div class="widget-list-content">
                    <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre | titlecase}}</h4>
                    <p class="widget-list-desc">Usuario canceló documento</p>
                  </div>
                </div>
              </div>
            </div>
          </app-panel-base>

        </div>
        <div style="width:250px;">
          <app-panel-base title="Más información">
            <div class="panel-content">
              <components-ui-date-box label="Emisión" [tabIndex]="4" formControlName="FechaEmision"
                [widthLabelColumns]="6" [widthTextColumns]="6" />

              <components-ui-date-box [enabled]="false" label="Cancelación" [tabIndex]="5"
                *ngIf="myForm.value.FechaCancelacion" formControlName="FechaCancelacion" [widthLabelColumns]="6"
                [widthTextColumns]="6" />

              <component-ui-check-box [tabIndex]="6" [widthLabelColumns]="6" formControlName="EntregaNuestra"
                [widthTextColumns]="6" [label]="'Entrega nuestra'" />
            </div>
          </app-panel-base>
          <app-panel-base title="Folio pre impreso" *ngIf="usarFoliosPreImpresos">
            <div class="panel-content">
              <h3 style="text-align: center;text-decoration: underline;cursor: pointer;">{{folioPreImpresio}}</h3>
            </div>
          </app-panel-base>
          <app-panel-base title="Factura a Remisionar" *ngIf="myForm.value.Cliente?.Id>0">
            <div class="panel-content">
              <div (click)="getResguardos()" class="btn btn-sm btn-white mb-10px me-2 w-100">
                {{myForm.value.SerieFolioVenta ?
                myForm.value.SerieFolioVenta: 'Clic para buscar'}}</div>
            </div>
          </app-panel-base>
          <app-panel-base title="Opciones especiales" *ngIf="myForm.value.Id>0 && !myForm.value.FechaCancelacion">
            <div class="panel-content">
              <div (click)="cancelar()" class="btn btn-sm btn-white mb-10px me-2 w-100"><i
                  class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
            </div>
          </app-panel-base>

        </div>
      </div>

      <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>

      <!-- <div class="cancelled-header animate__animated animate__bounceInDown pulse text-center" style="width:500px;"
        *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled" *ngIf="myForm.value.FechaCancelacion">CANCELADO</div>
        <h3 class="text-blue text-center" *ngIf="myForm.value.FechaCancelacion">{{myForm.value.MotivoCancelacion}}</h3>
      </div> -->
    </form>
    <ng-template #ctrlBusquedaFacturaResguardo let-modal let-c="close">
      <ctrl-busqueda-facturas-resguardo (onClose)="onCloseBusquedaFacturaResguardo($event)" />
    </ng-template>
    <ng-template #modalCancelacion let-modal let-c="close">
      <app-cancela-remision-page [idRemision]="myForm.value.Id" />
    </ng-template>
    <ng-template #modalListado let-modal let-c="close">
      <app-listado-resguardos-page [idCliente]="myForm.value.Cliente.Id" />
    </ng-template>
    <ng-template #modalDireccion let-modal let-c="close">
      <app-direccion-entrega-page [Direccion]="direccionentrega" />
    </ng-template>
    <ng-template #content let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'"
        [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
    </ng-template>
    <ng-template #ctrlConversiones let-modal let-c="close">
      <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
    </ng-template>


  </div>
</app-container-base>
